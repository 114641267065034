import { v4 as uuidv4 } from "uuid";

export function getSnapshotRosterInfo(snapshotName, rosterInView) {
  const employees = rosterInView.Employees.map((employee) => {
    return {
      id: employee.id,
      globalEmployeeID: employee.id,
      Days: employee.Days,
      DaysRecurring: employee.DaysRecurring,
      Allocations: employee.Allocations,
      AllocationsRecurring: employee.AllocationsRecurring,
      History: employee.History,
      RuleValues: employee.RuleValues,
      RosteredAllocations: employee.RosteredAllocations,
      name: employee.name,
      skills: employee.skills,
      shifts: employee.shifts,
      externalID: employee.externalID,
      areas: employee.areas || "",
    };
  });

  const rosterInfo = {
    id: uuidv4(),
    locationID: rosterInView.locationID,
    name: snapshotName,
    numDays: rosterInView.numDays,
    startDate: rosterInView.startDate,
    Employees: employees,
    Demands: rosterInView.Demands,
    Shifts: rosterInView.Shifts,
    ShiftGroups: rosterInView.ShiftGroups,
    CustomRules: rosterInView.CustomRules,
    Skills: rosterInView.Skills,
    Tasks: rosterInView.Tasks,
    TaskBlocks: rosterInView.TaskBlocks,
    Areas: rosterInView.Areas,
    Statistics: rosterInView.Statistics,
    ColorCodes: rosterInView.ColorCodes,
    isPublished: false,
    isSnapshot: true,
    snapshotStartDate: `T${rosterInView.startDate}`,
  };

  return rosterInfo;
}
