/**
 * Conerts "camelCase" to "Camel Case"
 */
export function camelToNormal(str) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function capitalizeFirstLetter(inputString) {
  if (typeof inputString !== "string") {
    return inputString; // Return unchanged if it's not a string
  }

  if (inputString.length === 0) {
    return inputString; // Return unchanged if it's an empty string
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function convertToTitleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

export function parseNestedJSON(jsonString) {
  // Parse the JSON string
  const parsed = JSON.parse(jsonString);

  // Recursively parse nested JSON strings
  if (typeof parsed === "string") {
    try {
      // Attempt to parse the string as JSON
      return parseNestedJSON(parsed);
    } catch (e) {
      // If it's not valid JSON, return the original string
      return parsed;
    }
  } else if (typeof parsed === "object") {
    for (const key in parsed) {
      if (typeof parsed[key] === "string") {
        try {
          // Attempt to parse nested JSON strings
          parsed[key] = parseNestedJSON(parsed[key]);
        } catch (e) {
          // If it's not valid JSON, keep the original string
          continue;
        }
      }
    }
  }

  return parsed;
}

export function trimAndLowerCase(string) {
  return string.trim().toLowerCase();
}

const canvas = document.createElement("canvas");
export function calculateStringWidthInPx(string, font = "13px Arial") {
  const context = canvas.getContext("2d");

  context.font = font;

  return context.measureText(string).width;
}

export function encodeUrlParamString(str) {
  return str.replace(/\+/g, "%2B");
}

export function isNumberString(str) {
  // Regular expression to match strings that are made of digits and optionally a single dot
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(str);
}

export function removeCustomSuffix(str, suffix) {
  const suffixLength = suffix.length;

  if (str.length >= suffixLength && str.slice(-suffixLength) === suffix) {
    return str.slice(0, -suffixLength);
  }
  return str;
}

export function removeExtensionFromFileName(fileName) {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) return fileName;
  return fileName.substring(0, lastDotIndex);
}
