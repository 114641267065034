import styles from "./DesktopSideTab.module.css";
import logo from "../../../../../assets/images/RLlogo.png";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import ScheduleSideTab from "../ScheduleSideTab/ScheduleSideTab";
import PreferencesSideTab from "../PreferencesSideTab/PreferencesSideTab";
import LeaveSideTab from "../LeaveSideTab/LeaveSideTab";
import OpenShiftsSideTab from "../OpenShiftsSideTab/OpenShiftsSideTab";
import {
  EDIT_LEAVE_TAB_STATUS,
  useEditLeaveTabStatus,
} from "../../../hooks/useEditLeaveTabStatus";
import { useEffect, useState } from "react";
import UpdateRequestTab from "../../mobile/UpdateRequestTab/UpdateRequestTab";

const SideTabWrapper = ({ children, selectedDate, fillSidebar }) => {
  if (fillSidebar) {
    return <div className={styles.container}>{children}</div>;
  }

  return (
    <div className={`${styles.container} ${styles.containerPadding}`}>
      {selectedDate && (
        <div className={styles.header}>
          <p className={styles.selectedDateLabel}>
            {selectedDate.toFormat("readable")}
          </p>
        </div>
      )}
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        <img className={styles.logo} src={logo} alt="RosterLab logo"></img>
      </div>
    </div>
  );
};

const DesktopSideTab = ({
  currentViewData,
  setSelectedDate,
  showOpenShifts,
  toggleOpenShifts,
  allocations,
  myOpenShifts,
  userEmployee,
  updateOpenShifts,
  preferences,
  preferencesRecurring,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
  updatePreference,
  defaultPreferenceLevel,
  availableShifts,
  availableShiftGroups,
  requests,
  deleteRequestById,
  leaveKeywordsDict,
  addNewRequest,
  employeeLeaveCounts,
  locationStartDate,
  plan,
  notesToDisplay,
}) => {
  const { selectedDate, contentViewType } = currentViewData;
  const { openLeaveTab, closeLeaveTab, tabStatus, selectedLeave } =
    useEditLeaveTabStatus(contentViewType);

  const [showAllRequests, setShowAllRequests] = useState(false);

  useEffect(() => {
    closeLeaveTab();
  }, [selectedDate, closeLeaveTab]);

  const getSideTabComponent = () => {
    if (showOpenShifts) {
      return (
        <OpenShiftsSideTab
          toggleOpenShifts={toggleOpenShifts}
          myOpenShifts={myOpenShifts}
          userEmployee={userEmployee}
          updateOpenShifts={updateOpenShifts}
        />
      );
    }
    switch (contentViewType) {
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule:
        return (
          <ScheduleSideTab
            currentViewData={currentViewData}
            toggleOpenShifts={toggleOpenShifts}
            allocations={allocations}
            myOpenShifts={myOpenShifts}
            userEmployee={userEmployee}
            updateOpenShifts={updateOpenShifts}
            notesToDisplay={notesToDisplay}
          />
        );
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences:
        return (
          <PreferencesSideTab
            currentViewData={currentViewData}
            setSelectedDate={setSelectedDate}
            preferences={preferences}
            preferencesRecurring={preferencesRecurring}
            numberOfEmployees={numberOfEmployees}
            preferenceNumbers={preferenceNumbers}
            preferenceRecurringNumbers={preferenceRecurringNumbers}
            updatePreference={updatePreference}
            defaultPreferenceLevel={defaultPreferenceLevel}
            availableShifts={availableShifts}
            availableShiftGroups={availableShiftGroups}
            locationStartDate={locationStartDate}
          />
        );
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave:
        if (
          [EDIT_LEAVE_TAB_STATUS.update, EDIT_LEAVE_TAB_STATUS.create].includes(
            tabStatus
          )
        ) {
          const isForCreation = tabStatus === EDIT_LEAVE_TAB_STATUS.create;
          return (
            <UpdateRequestTab
              closeTab={closeLeaveTab}
              isForCreation={isForCreation}
              selectedDate={selectedDate}
              selectedLeave={isForCreation ? null : selectedLeave}
              deleteRequest={isForCreation ? () => {} : deleteRequest}
              addNewRequest={isForCreation ? addNewRequest : () => {}}
              leaveKeywordsDict={leaveKeywordsDict}
              setSelectedDate={setSelectedDate}
              requests={requests}
              plan={plan}
            />
          );
        }
        return (
          <LeaveSideTab
            currentViewData={currentViewData}
            requests={requests}
            openLeaveTab={openLeaveTab}
            employeeLeaveCounts={employeeLeaveCounts}
            showAllRequests={showAllRequests}
            setShowAllRequests={setShowAllRequests}
            leaveKeywordsDict={leaveKeywordsDict}
          />
        );
      default:
        return <></>;
    }
  };

  const deleteRequest = (requestID) => {
    deleteRequestById(userEmployee, requestID);
  };

  const fillSidebar = [
    EDIT_LEAVE_TAB_STATUS.update,
    EDIT_LEAVE_TAB_STATUS.create,
  ].includes(tabStatus);

  return (
    <SideTabWrapper
      selectedDate={showOpenShifts ? null : selectedDate}
      fillSidebar={fillSidebar}
    >
      {getSideTabComponent()}
    </SideTabWrapper>
  );
};

export default DesktopSideTab;
