import { KEYWORD_ALL } from "../../../constants/keywords";
import { updateGlobalEmployee } from "../../../graphql/mutations";
import {
  allElementsAreEmptyStr,
  generateUniqueKioskCode,
  twoArraysAreEqual,
} from "../../../utils";
import { graphqlErrorHandler } from "../../../utils/queryUtils/generalQueryUtil";

const existingKioskCodes = [];

const updateFieldsForClassicView = (employee) => {
  const { ruleValues, skills, shifts } = employee;

  const updatedEmployeeData = {};

  if (!ruleValues) {
    updatedEmployeeData.ruleValues = [];
  }

  if (shifts === null) {
    updatedEmployeeData.shifts = "";
  }

  if (skills === null) {
    updatedEmployeeData.skills = "";
  }

  return updatedEmployeeData;
};

const getUpdatedEmployeeFields = (
  employee,
  shifts,
  tasks,
  taskBlocks,
  preferencesRecurringShouldBeTwoWeeks
) => {
  const {
    email,
    Requests,
    Preferences,
    PreferencesRecurring,
    PublishedAllocations,
    DaysRecurring,
    TimeEntries,
    FTE,
    salary,
    startDate,
    finishDate,
    code,
    externalID,
    areas,
  } = employee;

  const updatedEmployeeData = {};

  if (!code) {
    const uniqueKioskCode = generateUniqueKioskCode(existingKioskCodes);
    if (uniqueKioskCode) {
      existingKioskCodes.push(uniqueKioskCode);
      updatedEmployeeData.code = uniqueKioskCode;
    }
  }

  if (areas == null) {
    updatedEmployeeData.areas = KEYWORD_ALL;
  }

  if (Preferences === null) {
    updatedEmployeeData.Preferences = [];
  }

  let updatedPreferencesRecurringTemp = PreferencesRecurring;

  if (PreferencesRecurring === null || PreferencesRecurring.length === 0) {
    updatedPreferencesRecurringTemp = preferencesRecurringShouldBeTwoWeeks
      ? Array(14).fill("")
      : Array(7).fill("");
    updatedEmployeeData.PreferencesRecurring = updatedPreferencesRecurringTemp;
  }

  // Migration from DaysRecurring to PreferencesRecurring
  if (
    DaysRecurring &&
    PreferencesRecurring &&
    !allElementsAreEmptyStr(DaysRecurring) &&
    !twoArraysAreEqual(DaysRecurring, PreferencesRecurring)
  ) {
    let hasPreferencesRecurringUpdated = false;
    let updatedPreferencesRecurring = Array(14).fill("");
    for (const idx in PreferencesRecurring) {
      updatedPreferencesRecurring[idx] = PreferencesRecurring[idx];
    }

    for (const idx in DaysRecurring) {
      if (PreferencesRecurring[idx] === "") {
        hasPreferencesRecurringUpdated = true;
        updatedPreferencesRecurring[idx] = DaysRecurring[idx];
      }
    }

    if (hasPreferencesRecurringUpdated) {
      updatedEmployeeData.PreferencesRecurring = updatedPreferencesRecurring;
      updatedPreferencesRecurringTemp = updatedPreferencesRecurring;
      updatedEmployeeData.DaysRecurring = Array(14).fill("");
    }
  }

  // Check if PreferencesRecurring needs to be fixed to two weeks
  if (
    preferencesRecurringShouldBeTwoWeeks &&
    PreferencesRecurring.length !== 14
  ) {
    const twoWeeksPreferencesRecurring = Array(14).fill("");
    for (const idx in updatedPreferencesRecurringTemp) {
      twoWeeksPreferencesRecurring[idx] = updatedPreferencesRecurringTemp[idx];
    }
    updatedPreferencesRecurringTemp = twoWeeksPreferencesRecurring;
    updatedEmployeeData.PreferencesRecurring = twoWeeksPreferencesRecurring;
  }

  if (Requests === null) {
    updatedEmployeeData.Requests = [];
  }

  if (email === null) {
    updatedEmployeeData.email = "";
  }

  if (externalID === null) {
    updatedEmployeeData.externalID = "";
  }

  if (startDate === null) {
    updatedEmployeeData.startDate = "1970-01-01";
  }

  if (finishDate === null) {
    updatedEmployeeData.finishDate = "2038-01-01";
  }

  if (FTE == null) {
    updatedEmployeeData.FTE = 0.0;
  }

  if (salary == null) {
    updatedEmployeeData.salary = 0.0;
  }

  if (!PublishedAllocations) {
    updatedEmployeeData.PublishedAllocations = [];
  }

  // TODO: refigure this out. Makes me very nervous because if convertAllocationInNameFormToShortIdForm,
  // then it leads to catastrophic data loss. ALso potentially expensive as it has to run every single time for
  // every single allocation of every single employee. Very inefficient.
  // if (PublishedAllocations) {
  //   const subTasks = getSubtasks(tasks, taskBlocks);

  //   const namesToEntityShortIdsDicts = buildNamesToEntityShortIdsDicts(
  //     shifts,
  //     tasks,
  //     subTasks
  //   );

  //   let hasPublishedAllocationUpdated = false;
  //   const updatedPublishedAllocations = PublishedAllocations.map(
  //     (allocation) => {
  //       const { draftAllocation, publishedAllocation } = allocation;
  //       let convertedDraftAllocation = null;
  //       let convertedPublishedAllocation = null;

  //       if (draftAllocation) {
  //         convertedDraftAllocation = convertAllocationInNameFormToShortIdForm(
  //           draftAllocation,
  //           namesToEntityShortIdsDicts
  //         );
  //       }
  //       if (publishedAllocation) {
  //         convertedPublishedAllocation =
  //           convertAllocationInNameFormToShortIdForm(
  //             publishedAllocation,
  //             namesToEntityShortIdsDicts
  //           );
  //       }

  //       if (
  //         (convertedDraftAllocation &&
  //           convertedDraftAllocation !== draftAllocation) ||
  //         (convertedPublishedAllocation &&
  //           convertedPublishedAllocation !== publishedAllocation)
  //       ) {
  //         hasPublishedAllocationUpdated = true;
  //       }
  //       return {
  //         ...allocation,
  //         ...(convertedDraftAllocation && {
  //           draftAllocation: convertedDraftAllocation,
  //         }),
  //         ...(convertedPublishedAllocation && {
  //           publishedAllocation: convertedPublishedAllocation,
  //         }),
  //       };
  //     }
  //   );

  //   if (hasPublishedAllocationUpdated) {
  //     updatedEmployeeData.PublishedAllocations = updatedPublishedAllocations;
  //   }
  // }

  if (!TimeEntries) {
    updatedEmployeeData.TimeEntries = [];
  }

  return updatedEmployeeData;
};

export const fixGlobalEmployees = async (location, globalEmployees) => {
  const twoWeeksPrefRecurringExists = globalEmployees.some(
    ({ PreferencesRecurring }) => PreferencesRecurring.length === 14
  );
  const oneWeekPrefRecurringExists = globalEmployees.some(
    ({ PreferencesRecurring }) => PreferencesRecurring.length === 14
  );

  let preferencesRecurringShouldBeTwoWeeks = false;

  if (twoWeeksPrefRecurringExists && oneWeekPrefRecurringExists) {
    preferencesRecurringShouldBeTwoWeeks = true;
  }

  const { isScheduleView, Shifts, Tasks, TaskBlocks } = location;

  let hasFixed = false;

  const kioskCodes = globalEmployees
    .map(({ code }) => code)
    .filter((code) => Boolean(code));

  existingKioskCodes.push(...kioskCodes);

  const updatePromises = [];

  for (const emp of globalEmployees) {
    let updatedEmployeeFields = getUpdatedEmployeeFields(
      emp,
      Shifts,
      Tasks,
      TaskBlocks,
      preferencesRecurringShouldBeTwoWeeks
    );

    if (!isScheduleView) {
      const classicViewUpdatedFields = updateFieldsForClassicView(emp);
      updatedEmployeeFields = {
        ...updatedEmployeeFields,
        ...classicViewUpdatedFields,
      };
    }

    hasFixed = Object.keys(updatedEmployeeFields).length !== 0;
    if (hasFixed) {
      const updatePromise = updateGlobalEmployeeToDatabase(
        emp.id,
        updatedEmployeeFields,
        emp._version
      ).then(() => {
        console.log("fixGlobalEmployees: global employee has been fixed");
        return { ...emp, ...updatedEmployeeFields };
      });

      updatePromises.push(updatePromise);
    } else {
      // Even if there are no updates, push the original employee data to maintain the list's integrity.
      updatePromises.push(Promise.resolve(emp));
    }
  }

  // Wait for all update operations to complete
  const updatedEmployees = await Promise.all(updatePromises);

  if (hasFixed) {
    return updatedEmployees;
  }
  return null;
};

export async function updateGlobalEmployeeToDatabase(
  id,
  updatedEmployeeFields,
  _version
) {
  // Check if fieldsUpdated is not empty
  if (Object.keys(updatedEmployeeFields).length > 0) {
    return graphqlErrorHandler(
      updateGlobalEmployee,
      {
        input: {
          id,
          ...updatedEmployeeFields,
          _version,
        },
      },
      (data) => data.updateGlobalEmployee,
      null,
      "updateGlobalEmployeeToDatabase"
    );
  } else {
    return null;
  }
}
