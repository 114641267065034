import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./UpgradeBanner.module.css";
import { openDemoBookingWindow } from "../../../../utils";

const UpgradeBanner = ({
  title,
  descriptions,
  customComponent,
  showBookDemoButton = true,
}) => {
  return (
    <div className={styles.bannerContainer}>
      <FontAwesomeIcon icon={faCircleArrowUp} className={styles.unlockIcon} />
      <div className={styles.banner}>
        <p className={styles.bannerTitle}>{title}</p>
        {descriptions.map((d, idx) => (
          <p key={idx} className={styles.bannerDesc}>
            {d}
          </p>
        ))}
        {customComponent}
        <div className={styles.buttons}>
          {showBookDemoButton && (
            <button
              className={styles.bookDemoBtn}
              onClick={openDemoBookingWindow}
            >
              Book a demo for RosterLab Premium
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradeBanner;
