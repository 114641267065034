import { DateTime } from "../dataTypesUtils/DateTime";

export const getActualNumDays = (startDate, numDays) => {
  if (numDays >= 0) {
    return numDays;
  }

  const dateNextMonth = new DateTime(startDate).addMonths(1);

  const days = DateTime.getDifferenceInDays(
    new DateTime(startDate).date,
    dateNextMonth
  );

  return days;
};

export const getPreviousNumDays = (startDate, numDays) => {
  if (numDays >= 0) {
    return numDays;
  }

  const dateNextMonth = new DateTime(startDate).subtractMonths(1);

  const days = DateTime.getDifferenceInDays(
    dateNextMonth,
    new DateTime(startDate).date
  );

  return days;
};

export const prototypeRosterIsMonthView = (numDays) => {
  return !Number.isInteger(numDays / 7);
};

export const getActualInitialStartDate = (
  startDate,
  numDays,
  isScheduleView
) => {
  if (
    (isScheduleView && numDays >= 0) ||
    (!isScheduleView && !prototypeRosterIsMonthView(numDays))
  )
    return startDate;

  const initialStartDate = DateTime.getPreviousMonday(new DateTime(startDate));

  return initialStartDate;
};

function getDayDifference(
  locationStartDate,
  locationDefaultNumDays,
  rosterStartDate,
  reverse = false
) {
  const patternStart = getActualInitialStartDate(
    locationStartDate ? locationStartDate : rosterStartDate,
    locationDefaultNumDays,
    locationDefaultNumDays !== null
  );
  const outputStart = new DateTime(rosterStartDate);

  if (reverse) {
    return DateTime.getDifferenceInDays(outputStart, patternStart);
  } else {
    return DateTime.getDifferenceInDays(patternStart, outputStart);
  }
}

export function generatePatternOutput(
  pattern,
  rosterStartDate,
  numDays,
  locationStartDate,
  locationDefaultNumDays
) {
  if (pattern.length === numDays) return pattern; //No need to extrapolate a pattern if the values are the same for every day

  const dayDifference = getDayDifference(
    locationStartDate,
    locationDefaultNumDays,
    rosterStartDate
  );
  const output = [];

  for (let i = 0; i < numDays; i++) {
    const offset = Math.abs(Math.floor(dayDifference / pattern.length)) + 1;
    const patternIndex =
      (dayDifference + i + offset * pattern.length) % pattern.length;
    output.push(pattern[patternIndex]);
  }

  return output;
}

export function generatePatternOutputReverse(
  pattern,
  rosterStartDate,
  numDays,
  locationStartDate,
  locationDefaultNumDays
) {
  const dayDifference = getDayDifference(
    locationStartDate,
    locationDefaultNumDays,
    rosterStartDate,
    true
  );
  const output = [];

  for (let i = 0; i < numDays; i++) {
    const offset = Math.abs(Math.floor(dayDifference / numDays)) + 1;
    const patternIndex = ((dayDifference + offset * numDays) % numDays) + i;
    output.push(pattern[patternIndex]);
  }

  return output;
}
