import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { components } from "react-select";
import { forwardRef } from "react";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../../../../utils/agGridUtils/customCellStyles";
import { parseInputValue } from "../DropdownMultiSelector/DropdownMultiSelector";

const Option = ({ label, ...props }) => {
  return (
    <components.Option label={label} {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          onChange={() => null}
          checked={props.isSelected}
          style={{ marginRight: "10px" }}
        />
        <label style={{ margin: "0" }}>{label}</label>
      </div>
    </components.Option>
  );
};

const DropdownCreatableMultiSelector = forwardRef(
  (
    { options, width, createNewCandidate, customFormatCreateLabel, ...props },
    ref
  ) => {
    const [selected, setSelected] = useState(
      parseInputValue(props.value, options)
    );
    const refInput = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (refInput.current) {
          refInput.current.focus();
        }
      }, 100);
    }, []);

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        // Format: "shift1, shift2, shift3" (string)
        getValue() {
          const selectedNames = selected.map((item) => item.value);
          return selectedNames.join(", ");
        },

        isPopup() {
          return true;
        },
      };
    });

    const defaultFormatCreateLabel = (userInput) => `Create "${userInput}"`;
    const formatCreateLabel = customFormatCreateLabel
      ? customFormatCreateLabel
      : defaultFormatCreateLabel;

    return (
      <div style={{ width: `${width}px`, minWidth: "150px" }}>
        <CreatableSelect
          options={options}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          styles={{
            ...customStyles,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          onChange={(selectedInputs) => {
            setSelected(selectedInputs);
          }}
          onCreateOption={(input) => {
            setSelected((prevSelected) => [
              ...prevSelected,
              { label: input, value: input },
            ]);
            createNewCandidate(input);
          }}
          ref={refInput}
          menuIsOpen={true}
          value={selected}
          components={{ Option }}
          formatCreateLabel={formatCreateLabel}
          maxMenuHeight={250}
        />
      </div>
    );
  }
);

export default DropdownCreatableMultiSelector;
