import { Switch, Route, useRouteMatch } from "react-router-dom";
import DashboardContainer from "../features/allRosters/components/DashboardContainer/DashboardContainer";

const ClassicViewRoutes = ({
  toggleCreateLocationModal,
  locations,
  locationID,
  headerType,
  location,
  globalEmployees,
}) => {
  let { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/dashboard`}>
        <DashboardContainer
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          headerType={headerType}
          location={location}
          globalEmployees={globalEmployees}
        />
      </Route>
    </Switch>
  );
};

export default ClassicViewRoutes;
