// LandscapePrompt.js
import { useState, useEffect } from "react";
import styles from "./LandscapeEnforcer.module.css";

const LandscapePrompt = ({ active }) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(screen.orientation.type.includes("portrait"));
    };

    const checkDismissed = () => {
      const dismissed = localStorage.getItem("landscapePromptDismissed");
      setIsDismissed(dismissed === "true");
    };

    checkOrientation(); // Initial checks
    checkDismissed();

    const handleOrientationChange = () => {
      checkOrientation();
    };

    screen.orientation.addEventListener("change", handleOrientationChange);
    return () => {
      screen.orientation.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  const handleDismiss = () => {
    setIsDismissed(true);
    localStorage.setItem("landscapePromptDismissed", "true");
  };

  if (!active || !isPortrait || isDismissed) {
    return null;
  }

  return (
    <div className={styles.landscapePrompt}>
      <div className={styles.landscapePromptContent}>
        <button className={styles.closeButton} onClick={handleDismiss}>
          ×
        </button>
        <p>
          Please rotate your device to landscape mode for the best experience.
        </p>
        <div className={styles.rotateIcon}>⤾</div>
      </div>
    </div>
  );
};

export default LandscapePrompt;
