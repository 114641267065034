import { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import * as contentful from "contentful";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import "./HelpContentful.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./HelpContentful.module.css";
import { useLocation as useRouteLocation } from "react-router-dom";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";

const all_pages = [
  { name: "Dashboard", route: "Dashboard" },
  { name: "Sidebar", route: "Sidebar" },
  { name: "Employees", route: "Employees" },
  { name: "Shift Types & Shift Groups", route: "Shifts" },
  { name: "Rules", route: "Rules" },
  { name: "Staffing Demands", route: "Demands" },
  { name: "History", route: "History" },
  { name: "Fixed Shifts/Leave", route: "fixedshifts" },
  { name: "Preferences", route: "preferences" },
  { name: "Solutions", route: "Solutions" },
];

const Accordion = ({ title, data }) => {
  const [accordionActive, setAccordionActive] = useState(false);

  const accordionStyle = accordionActive ? "accordion active" : "accordion";
  const panelStyle = accordionActive ? "panel active" : "panel";

  const chevron = accordionActive ? (
    <FontAwesomeIcon icon={faChevronDown} className={"chevron active"} />
  ) : (
    <FontAwesomeIcon icon={faChevronDown} className={"chevron inactive"} />
  );

  return (
    <>
      <button
        className={accordionStyle}
        onClick={() => setAccordionActive(!accordionActive)}
      >
        {title}
        {chevron}
      </button>
      <div className={panelStyle}>
        <br />
        {documentToReactComponents(data)}
      </div>
    </>
  );
};

const renderOptions = (url) => {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if ("fields" in node.data.target) {
          return (
            <Accordion
              title={node.data.target.fields.title}
              data={node.data.target.fields.field}
            />
          );
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        if ("fields" in node.data.target) {
          return (
            <Link to={url + "/" + node.data.target.fields.route}>
              {node.content[0].value}
            </Link>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        if (children[0] !== "\n") {
          return (
            <>
              <h3 className={styles.header2}>{children[0]}</h3>
              <hr />
            </>
          );
        }
      },
    },
  };
};

const HelpSection = (props) => {
  const [page, setPage] = useState(null);
  let { url } = useRouteMatch();

  useEffect(() => {
    const client = contentful.createClient({
      space: "i63jkkturl0e",
      accessToken: "Adzv2SdOXCSprbZB5dvfirpXg4UG9jwLlxwEgNoBXew",
    });

    client
      .getEntries({
        "fields.route": props.route,
        content_type: "helpSection",
      })
      .then((entry) => {
        setPage(entry.items.map((item) => item.fields));
      })
      .catch((error) => console.log(error));
  }, [props.route]);

  if (!page) {
    return "Loading...";
  }

  // render the fetched Contentful data
  return (
    <div>
      <h1 className={styles.mainHeader}>{page[0].title}</h1>
      {documentToReactComponents(page[0].content, renderOptions(url))}
    </div>
  );
};

function HelpContentful() {
  let { url } = useRouteMatch();
  const { pathname } = useRouteLocation();
  const currentPage = pathname.split("/").pop();

  // render the fetched Contentful data
  return (
    <div className={"contentful"}>
      <Switch>
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <div className={styles.sidebarList}>
              <ul>
                {all_pages.map((pageDetails, idx) => (
                  <Link key={idx} to={url + "/" + pageDetails.route}>
                    <li
                      className={
                        currentPage === pageDetails.route && styles.activeTab
                      }
                    >
                      {pageDetails.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.rightContent}>
            {all_pages.map((pageDetails, idx) => (
              <Route key={idx} exact path={`${url}/${pageDetails.route}`}>
                <HelpSection route={pageDetails.route} />
              </Route>
            ))}
          </div>
        </div>
      </Switch>
    </div>
  );
}

export default withHeader(HelpContentful);
