import RosterRule from "./RosterRule";

export const getRosterRulesDict = (shifts, shiftGroups, rules?) => {
  let rosterRules: RosterRule[] = [
    new RosterRule(
      "onstretchLength;Minimum;Critical",
      "higher",
      "necessary",
      ["I require ", " to work at least ", " days in a row"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "onstretchLength;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work at most ", " days in a row"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "onstretchLength;Equal;Critical",
      "both",
      "necessary",
      ["I require ", " to work at most ", " days in a row"],
      (value) => value,
      rules
    ),
    new RosterRule(
      "hoursOnRow;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work less than ", " hours in a row"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Week;Minimum;Critical",
      "higher",
      "necessary",
      ["I require ", " to work at least ", " days per week"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Week;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work at most ", " days per week"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Fortnight;Minimum;Critical",
      "higher",
      "necessary",
      ["I require ", " to work at least ", " days per fortnight"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Fortnight;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work at most ", " days per fortnight"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "timeBetweenShifts;Critical",
      "higher",
      "necessary",
      ["I require ", " to have more than a", " hour gap between shifts"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "numHours;Week;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work at most ", " hours per week"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "numHours;Week;Minimum;Critical",
      "higher",
      "necessary",
      ["I require ", " to work at least ", " hours per week"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "numHours;Fortnight;Maximum;Critical",
      "lower",
      "necessary",
      ["I require ", " to work at most ", " hours per fortnight"],
      (value) => value,
      rules
    ),
    new RosterRule(
      "numHours;Fortnight;Minimum;Critical",
      "higher",
      "necessary",
      ["I require ", " to work at least ", " hours per fortnight"],
      (value) => value,
      rules
    ),
    new RosterRule(
      "numHours;Fortnight;Equal;Critical",
      "both",
      "necessary",
      ["I require ", " to work at exactly ", " hours per fortnight"],
      (value) => value,
      rules
    ),
    new RosterRule(
      "onstretchLength;Minimum;Medium",
      "higher",
      "preference",
      ["I prefer ", " to work at least ", " days in a row"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "onstretchLength;Maximum;Medium",
      "lower",
      "preference",
      ["I prefer ", " to work at most ", " days in a row"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "onstretchLength;Equal;Medium",
      "both",
      "necessary",
      ["I require ", " to work at most ", " days in a row"],
      (value) => value,
      rules
    ),
    new RosterRule(
      "hoursOnRow;Maximum;Medium",
      "lower",
      "preference",
      ["I prefer ", " to work less than ", " hours in a row"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Week;Minimum;Medium",
      "higher",
      "preference",
      ["I prefer ", " to work at least ", " days per week"],
      (value) => value + 1,
      rules
    ),
    new RosterRule(
      "numDaysOn;Week;Maximum;Medium",
      "lower",
      "preference",
      ["I prefer ", " to work at most ", " days per week"],
      (value) => value - 1,
      rules
    ),
    new RosterRule(
      "timeBetweenShifts;Medium",
      "higher",
      "preference",
      ["I prefer ", " to have more than a ", " hour gap between shifts"],
      (value) => value + 1,
      rules
    ),
  ];

  const shiftAndShiftGroupEntities = shifts
    .map((shift) => ({ shortId: shift.shortId, name: shift.name }))
    .concat(
      shiftGroups.map((shiftGroup) => ({
        shortId: shiftGroup.shortId,
        name: shiftGroup.name,
      }))
    );

  shiftAndShiftGroupEntities.forEach((entity) => {
    rosterRules = rosterRules.concat([
      new RosterRule(
        `numShifts;Week;Minimum;Critical;${entity.shortId}`,
        "higher",
        "necessary",
        ["I require", "to work at least ", `${entity.name} shifts per week`],
        (value) => value + 1,
        rules
      ),
      new RosterRule(
        `numShifts;Week;Maximum;Critical;${entity.shortId}`,
        "lower",
        "necessary",
        ["I require", "to work at most ", `${entity.name} shifts per week`],
        (value) => value - 1,
        rules
      ),
      new RosterRule(
        `numShiftsRow;Minimum;Critical;true;${entity.shortId}`,
        "higher",
        "necessary",
        ["I require", "to work at least ", `${entity.name} shifts in a row`],
        (value) => value + 1,
        rules
      ),
      new RosterRule(
        `numShiftsRow;Maximum;Critical;true;${entity.shortId}`,
        "lower",
        "necessary",
        ["I require", "to work at most ", `${entity.name} shifts in a row`],
        (value) => value - 1,
        rules
      ),
      new RosterRule(
        `daysOffAfterShift;Critical;${entity.shortId}`,
        "lower",
        "necessary",
        [
          "I require",
          "to work at least ",
          `days off after a ${entity.name} shift`,
        ],
        (value) => value - 1,
        rules
      ),
    ]);

    shiftAndShiftGroupEntities.forEach((entity2) => {
      if (entity.shortId === entity2.shortId) return;
      rosterRules.push(
        new RosterRule(
          `shiftChanges;${entity.shortId};${entity2.shortId};Critical;Roster`,
          "none",
          "necessary",
          [
            "I require ",
            ` to not work ${entity.name} followed by ${entity2.name}`,
          ],
          () => 0,
          rules
        )
      );
      rosterRules.push(
        new RosterRule(
          `shiftChanges;${entity.shortId};${entity2.shortId};Medium;Roster`,
          "none",
          "preference",
          [
            "I prefer ",
            ` to not work ${entity.name} followed by ${entity2.name}`,
          ],
          () => 0,
          rules
        )
      );
    });
  });

  /**
   * This was causing maximum call stack size error so is changed to -------
   */
  // const rosterRulesDict: { string: RosterRule } = Object.assign(
  //   {},
  //   ...rosterRules.map((x) => ({ [x.template]: x }))
  // );
  /**
   * -------
   */

  /**
   * This----
   */
  const rosterRulesDict = {};
  rosterRules.forEach((x) => (rosterRulesDict[x.template] = x));
  /**
   *----
   */

  return rosterRulesDict;
};

// TODO: Dynamically generate rosterRulesDict

const getLabelsForRuleStats = (
  shiftNames: string[],
  shiftGroupNames: string[]
) => {
  return [
    ...shiftNames,
    ...shiftGroupNames,
    "Weekends off",
    "total",
    "shift group hours",
  ];
};

export const getMinMaxRuleValuesForStatsCol = (
  rules: any[],
  employees: any[],
  shiftNames: string[],
  shiftGroupNames: string[]
) => {
  const statsLabels = getLabelsForRuleStats(shiftNames, shiftGroupNames);

  const minCounts = {};
  const maxCounts = {};

  statsLabels.forEach((name) => {
    let minInd1 = -1;
    let minInd2 = -1;

    if (shiftNames.includes(name) || shiftGroupNames.includes(name)) {
      minInd1 = rules.findIndex(
        (rule) => rule.template === `numShifts;Roster;Minimum;Critical;${name}`
      );
      minInd2 = rules.findIndex(
        (rule) => rule.template === `numHours;Roster;Minimum;Critical;${name}`
      );
    } else if (name === "Weekends off") {
      minInd1 = rules.findIndex(
        (rule) => rule.template === `minimumWeekends;Critical`
      );
    } else if (name === "total") {
      minInd1 = rules.findIndex(
        (rule) => rule.template === `numHours;Roster;Minimum;Critical`
      );
    }

    if (minInd1 !== -1) {
      minCounts[name] = employees.map(
        (employee) => employee.RuleValues[minInd1]
      );
    }
    if (minInd2 !== -1) {
      minCounts[name + " hours"] = employees.map(
        (employee) => employee.RuleValues[minInd2]
      );
    }

    let maxInd1 = -1;
    let maxInd2 = -1;

    if (shiftNames.includes(name) || shiftGroupNames.includes(name)) {
      maxInd1 = rules.findIndex(
        (rule) => rule.template === `numShifts;Roster;Maximum;Critical;${name}`
      );
      maxInd2 = rules.findIndex(
        (rule) => rule.template === `numHours;Roster;Maximum;Critical;${name}`
      );
    } else if (name === "total") {
      maxInd1 = rules.findIndex(
        (rule) => rule.template === `numHours;Roster;Maximum;Critical`
      );
    }

    if (maxInd1 !== -1) {
      maxCounts[name] = employees.map(
        (employee) => employee.RuleValues[maxInd1]
      );
    }

    if (maxInd2 !== -1) {
      maxCounts[name + " hours"] = employees.map(
        (employee) => employee.RuleValues[maxInd2]
      );
    }
  });

  return { minCounts, maxCounts };
};

export const getRuleNamesFromCustomRules = (
  rules,
  shifts,
  shiftGroups,
  tasks
) => {
  const names = rules.map(({ template, name }) => {
    let ruleName = name;
    const separatedTemplate = template.split(";");
    const lastValue = separatedTemplate[separatedTemplate.length - 1];

    const targetShift = shifts.find(({ shortId }) => shortId === lastValue);
    const targetShiftGroup = shiftGroups.find(
      ({ shortId }) => shortId === lastValue
    );
    const targetTask = tasks.find(({ shortId }) => shortId === lastValue);

    let entityName = null;
    if (targetShift) entityName = targetShift.name;
    if (targetShiftGroup) entityName = targetShiftGroup.name;
    if (targetTask) entityName = targetTask.name;

    if (entityName) {
      ruleName = ruleName.replace(` ${lastValue} `, ` ${entityName} `);
    }

    return {
      name,
      formattedName: ruleName,
    };
  });

  return names;
};
