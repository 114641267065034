import { DateTime } from "../../../../../utils";
import styles from "./MonthCalendarCellWrapper.module.css";

const MonthCalendarCellWrapper = ({
  date,
  selectedDate,
  setSelectedDate,
  children,
  openBottomTab,
  toggleBottomTab,
}) => {
  const dayLabel = date.getDateNum();
  const dateString = date.toFormat("AWS");
  const displayedMonth = selectedDate.getMonth();

  const today = new DateTime();
  const isToday = today.toFormat("AWS") === dateString;
  const isSelectedDate = selectedDate.toFormat("AWS") === dateString;
  const isCurrentMonth = date.getMonth() === displayedMonth;

  return (
    <div
      className={`${styles.container} ${isSelectedDate && styles.selected} ${
        !isCurrentMonth && styles.outsideCurrentMonth
      }`}
      onClick={() => {
        setSelectedDate(date);
        if (date.toFormat("AWS") !== selectedDate.toFormat("AWS")) {
          openBottomTab();
        } else {
          toggleBottomTab();
        }
      }}
    >
      <span className={`${styles.dayLabel} ${isToday && styles.today}`}>
        {dayLabel}
      </span>
      <div className={styles.cellContent}>{children}</div>
    </div>
  );
};

export default MonthCalendarCellWrapper;
