import { useCallback, useMemo } from "react";
import DataEntryTable from "../../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import {
  DateTime,
  allocationValueFormatter,
  exportGridToExcel,
  getDatesColumnDefs,
  getLongestAllocationStringInRowData,
  getNames,
  getReservedHeaderRowStyle,
  getRowCountStatusBar,
  onFilterTextBoxChanged,
} from "../../../../../utils";
import { CustomToolTip } from "../../../../grid/components/toolTip/toolTip";
import { getDayColumnNameRegex } from "../../../../../utils/generalUtils/regex";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import { useLiveCalendarColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import AllocationCellRenderer from "../../../../grid/components/AllocationCellRenderer/AllocationCellRenderer";
import { getColorCodedCells } from "../../../../rosterProblems/rosteredAllocations/service/styleGetters";

const LiveCalendarGrid = ({
  locationID,
  employeesData,
  areas,
  shiftGroups,
  shifts,
  colorCodes,
  tasks,
  taskBlocks,
  shortNames,
  numDays,
  startDate,
  employeesAllocationNotes,
  gridApi,
  setGridApi,
  doesAreaFilterPass,
  isExternalFilterPresent,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskNames = useMemo(() => getNames(tasks), [tasks]);

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useLiveCalendarColumnWidthStore();

  const datesColumnDefs = useMemo(() => {
    if (!employeesData) {
      return [];
    }

    let datesColumnDefs = getDatesColumnDefs(numDays, startDate);

    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekColIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayColIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              const date = new DateTime(startDate)
                .addDays(Number(colDef.field.substring(1)) - 1)
                .toFormat("AWS");

              return {
                ...colDef,
                tooltipComponent: "tooltipComponent",
                tooltipComponentParams: {
                  shiftNames,
                  shiftGroupNames,
                  taskNames,
                  shifts,
                  shiftGroups,
                  tasks,
                  taskBlocks,
                  areas,
                  ...(employeesAllocationNotes && { employeesAllocationNotes }),
                  customKeywordsUtilObj,
                  date,
                },
                cellRenderer: "allocationCellRenderer",
                cellRendererParams: {
                  ...(employeesAllocationNotes && {
                    employeesAllocationNotes,
                  }),
                  date,
                },
                tooltipValueGetter: (params) => ({ value: params.value }),
                editable: false,
                valueGetter: (params) => {
                  const dayColNameRegex = getDayColumnNameRegex();
                  const originalVal = params.data[params.colDef.field];

                  if (originalVal === null || originalVal === undefined) {
                    return originalVal;
                  }
                  if (
                    !params.data.id.startsWith("reserved") &&
                    params.colDef.field.match(dayColNameRegex)
                  ) {
                    const shiftSkillPair = originalVal.split("-");
                    const shift = shiftSkillPair[0].trim();
                    const skill = shiftSkillPair[1]
                      ? shiftSkillPair[1].trim()
                      : null;

                    if (skill) {
                      return [shift, skill].join("-");
                    }
                    return shift;
                  }
                  return originalVal;
                },
                valueFormatter: (params) =>
                  allocationValueFormatter(
                    params,
                    shortNames,
                    shortIdsToEntityNamesDicts
                  ),
                width:
                  colDef.headerName === "Sa" || colDef.headerName === "Su"
                    ? weekendColWidth
                    : weekdayColWidth,
                cellStyle: (params) => {
                  if (shortNames.includes(params.value)) {
                    return {
                      color: "#cb0bd2",
                    };
                  }

                  return {
                    ...getColorCodedCells(
                      params,
                      colorCodes,
                      shiftGroups,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  };
                },
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayColGroup${dayColIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekColIdx}`,
          children: customWeekColDefs,
        };
      }
    );
    return customDatesColumnDefs;
  }, [
    weekdayColWidth,
    weekendColWidth,
    startDate,
    colorCodes,
    employeesData,
    numDays,
    shiftGroups,
    shifts,
    shortNames,
    taskBlocks,
    tasks,
    employeesAllocationNotes,
    shiftGroupNames,
    shiftNames,
    taskNames,
    areas,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
  ]);

  const basicColumns = [
    {
      field: "name",
      pinned: "left",
      editable: false,
    },
  ];

  const columnDefs = [
    {
      headerName: "Employees",
      children: basicColumns,
    },
    ...datesColumnDefs,
  ];

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  return (
    <div>
      <ActionBar
        locationID={locationID}
        searchBarSettings={{
          tableName: "calendar",
          onFilterInputChanged,
        }}
        adjustWidthSettings={{
          longestStr,
          weekdayColWidth,
          weekendColWidth,
          isWDayWEndSeparate,
          setWeekdayColWidth,
          setWeekendColWidth,
          setIsWDayWEndSeparate,
          tableName: "calendar",
          saveToDatabase: false,
          disableSetForAllPages: true,
        }}
        exportSettings={{
          exportToExcel,
        }}
      />
      <DataEntryTable
        customStyle={{
          height: "calc(100vh - 160px)",
        }}
        columnDefs={columnDefs}
        rowData={employeesData}
        setGridApiToParent={setGridApi}
        components={{
          tooltipComponent: CustomToolTip,
          allocationCellRenderer: AllocationCellRenderer,
        }}
        tooltipShowDelay={0}
        tooltipHideDelay={null}
        context={{
          shiftGroups,
          shifts,
          areas,
        }}
        getRowStyle={getReservedHeaderRowStyle}
        statusBar={{
          statusPanels: [...getRowCountStatusBar().statusPanels],
        }}
        columnHoverHighlight={true}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; font-weight: bold; font-size: 24px;">The schedule admin has not published the schedule for this period.</span>'
        }
        doesExternalFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
      />
    </div>
  );
};

export default LiveCalendarGrid;
