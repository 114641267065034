import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo } from "react";
import styles from "./RosterToolbar.module.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useModal from "../../../../../hooks/useModal";
import AddSkillMixModal from "./AddSkillMixModal/AddSkillMixModal";
import AddShiftGroupModal from "./AddShiftGroupModal/AddShiftGroupModal";
import { getDefaultDisplayedStatistics } from "../../../../../constants";
import {
  filterShiftGroupsByAreas,
  filterShiftsByAreas,
  getEntityNameByShortId,
  getShortIds,
} from "../../../../../utils";
import { useRosterModelQuery } from "../../../../../hooks/modelQueryHooks/useRosterModelQuery";
import LoadingPage from "../../../../loading/components/LoadingPage/LoadingPage";
import { getNewShiftGroupsTemplate } from "../../../../../utils/queryUtils/rosterDataGetters";
import useStandardDataContainer, {
  getAllStandardUpdateFunctions,
} from "../../../../../hooks/modelQueryHooks/useStandardDataContainer";
import {
  getLocationAreaFiltersSettings,
  useSettingsModelQuery,
} from "../../../../../hooks/modelQueryHooks/useSettingsModelQuery";

const getToggledStatistics = (originalStatistics, value, key) => {
  let statisticsValue;
  const existingStatisticsValue = originalStatistics[key];

  if (existingStatisticsValue.some((item) => _.isEqual(item, value))) {
    statisticsValue = existingStatisticsValue.filter(
      (item) => !_.isEqual(item, value)
    );
  } else {
    statisticsValue = [...existingStatisticsValue, value];
  }
  const updatedStatistics = {
    ...originalStatistics,
    [key]: statisticsValue,
  };
  return updatedStatistics;
};

const COUNTS_BY = {
  day: "day",
  employee: "employee",
};

const SHIFT_GROUP_STAT_TYPES = {
  counts: "counts",
  hours: "hours",
};

export const ScheduleToolbar = (props) => {
  const locationID = props.context.locationID;
  const rosterID = props.context.rosterID;

  const { roster, isQueryLoading } = useRosterModelQuery({
    isScheduleView: true,
    locationID,
    rosterID,
  });
  const { customKeywords, updateFields } = useStandardDataContainer({
    isScheduleView: true,
    locationID,
    rosterID,
  });
  const { updateStatistics, addShiftGroup } = getAllStandardUpdateFunctions(
    roster,
    updateFields
  );

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <RosterToolbar
      {...props}
      statistics={roster.Statistics}
      shifts={roster.Shifts}
      shiftGroups={roster.ShiftGroups}
      skills={roster.Skills}
      addShiftGroup={addShiftGroup}
      updateStatistics={updateStatistics}
      customKeywords={customKeywords}
      locationID={locationID}
    ></RosterToolbar>
  );
};

export const PrototypeToolbar = (props) => {
  const locationID = props.context.locationID;
  const rosterID = props.context.rosterID;
  const { roster, isQueryLoading } = useRosterModelQuery({
    isScheduleView: false,
    locationID,
    rosterID,
  });
  const { updateFields, customKeywords } = useStandardDataContainer({
    isScheduleView: false,
    locationID: props.context.location.id,
    rosterID: props.context.rosterID,
  });
  const { updateStatistics, addShiftGroup } = getAllStandardUpdateFunctions(
    roster,
    updateFields
  );

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <RosterToolbar
      {...props}
      statistics={roster.Statistics}
      shifts={roster.Shifts}
      shiftGroups={roster.ShiftGroups}
      skills={roster.Skills}
      addShiftGroup={addShiftGroup}
      updateStatistics={updateStatistics}
      customKeywords={customKeywords}
      locationID={locationID}
    ></RosterToolbar>
  );
};

const RosterToolbar = (props) => {
  const {
    locationID,
    statistics,
    shifts,
    shiftGroups,
    skills,
    addShiftGroup,
    updateStatistics,
    customKeywords,
  } = props;

  const { settings, isLoading: isSettingsLoading } = useSettingsModelQuery();

  const areaFilter = useMemo(
    () => getLocationAreaFiltersSettings(settings, locationID),
    [settings, locationID]
  );

  const areaFilteredShiftGroups = useMemo(
    () => filterShiftGroupsByAreas(areaFilter, shiftGroups),
    [shiftGroups, areaFilter]
  );

  const areaFilteredShifts = useMemo(
    () => filterShiftsByAreas(areaFilter, shifts, shiftGroups),
    [areaFilter, shifts, shiftGroups]
  );

  const shiftShortIds = useMemo(() => getShortIds(shifts), [shifts]);
  const shiftGroupShortIds = useMemo(
    () => getShortIds(shiftGroups),
    [shiftGroups]
  );
  const skillShortIds = useMemo(() => getShortIds(skills), [skills]);

  const [countsBy, setCountsBy] = useState(COUNTS_BY.day);
  const [shiftGroupStatsType, setShiftGroupStatsType] = useState(
    SHIFT_GROUP_STAT_TYPES.counts
  );

  const [employeeShiftCountsStatus, setEmployeeShiftCountsStatus] = useState(
    statistics.employeeShiftCountsToShow
  );
  const [employeeShiftHoursStatus, setEmployeeShiftHoursStatus] = useState(
    statistics.employeeHoursToShow
  );

  const [dayShiftHoursStatus, setDayShiftHoursStatus] = useState(
    statistics.dayHoursToShow
  );

  const [dayShiftCountsStatus, setDayShiftCountsStatus] = useState(
    statistics.dayShiftCountsToShow
  );
  const [dayShiftSkillCountsToShow, setDayShiftSkillCountsToShow] = useState(
    statistics.dayShiftSkillCountsToShow
  );

  const [alCountsStatus, setAlCountsStatus] = useState(
    statistics.leaveCountsToShow || []
  );

  const [
    dayShiftSkillToggleDisplayedStatus,
    setDayShiftSkillToggleDisplayedStatus,
  ] = useState(statistics.dayShiftSkillToggleDisplayed);

  const [otherSettings, setOtherSettings] = useState(
    statistics.otherSettings ? statistics.otherSettings : []
  );

  const {
    isShowing: addShiftGroupModalIsShowing,
    toggle: toggleShiftGroupModal,
  } = useModal();

  const { isShowing: addSkillMixModalIsShowing, toggle: toggleSkillMixModal } =
    useModal();

  // Working button producer
  const displayPanelButtons = (entities, propertyStatus, toggler) => {
    return entities.map(({ name, shortId }, idx) => {
      const isActive = propertyStatus.includes(shortId) ? true : false;
      return (
        <button
          key={idx}
          onClick={() => toggler(shortId)}
          className={`${styles.toggleBtn} ${isActive && styles.active}`}
        >
          {name}
        </button>
      );
    });
  };

  const hideAllStats = () => {
    updateStatistics(getDefaultDisplayedStatistics());
  };

  const unselectAllToggles = () => {
    setEmployeeShiftCountsStatus([]);
    setEmployeeShiftHoursStatus([]);
    setDayShiftHoursStatus([]);
    setDayShiftCountsStatus([]);
    setDayShiftSkillCountsToShow([]);
    setOtherSettings([]);
    setDayShiftSkillToggleDisplayedStatus([]);
    hideAllStats();
  };

  const addNewShiftGroup = async (shiftGroupName, shifts) => {
    const shiftGroup = getNewShiftGroupsTemplate({
      name: shiftGroupName,
      shifts: shifts.join(", "),
      existingShiftGroups: shiftGroups,
      numItems: 1,
    })[0];
    await addShiftGroup(shiftGroup);
  };

  const toggleDayShiftCount = async (shiftName) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      shiftName,
      "dayShiftCountsToShow"
    );
    setDayShiftCountsStatus(updatedStatistics.dayShiftCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleEmployeeShiftCount = async (shiftName) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      shiftName,
      "employeeShiftCountsToShow"
    );
    setEmployeeShiftCountsStatus(updatedStatistics.employeeShiftCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleEmployeeShiftHours = async (shiftName) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      shiftName,
      "employeeHoursToShow"
    );
    setEmployeeShiftHoursStatus(updatedStatistics.employeeHoursToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleDayShiftHours = async (shiftName) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      shiftName,
      "dayHoursToShow"
    );
    setDayShiftHoursStatus(updatedStatistics.dayHoursToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleEmployeeTotalShiftCount = async () => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "overall",
      "employeeShiftCountsToShow"
    );
    setEmployeeShiftCountsStatus(updatedStatistics.employeeShiftCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleDayTotalShiftCount = async () => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "overall",
      "dayShiftCountsToShow"
    );
    setDayShiftCountsStatus(updatedStatistics.dayShiftCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleDayShiftSkillCount = async (
    shiftName,
    skillName,
    shouldAddButton = false
  ) => {
    const shiftSkillPair = { shift: shiftName, skill: skillName };
    let updatedStatistics = getToggledStatistics(
      statistics,
      shiftSkillPair,
      "dayShiftSkillCountsToShow"
    );

    if (shouldAddButton) {
      updatedStatistics = getToggledStatistics(
        updatedStatistics,
        shiftSkillPair,
        "dayShiftSkillToggleDisplayed"
      );
      setDayShiftSkillToggleDisplayedStatus(
        updatedStatistics.dayShiftSkillToggleDisplayed
      );
    }

    setDayShiftSkillCountsToShow(updatedStatistics.dayShiftSkillCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleEmployeeTotalHours = async () => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "overall",
      "employeeHoursToShow"
    );
    setEmployeeShiftHoursStatus(updatedStatistics.employeeHoursToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleDayTotalHours = async () => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "overall",
      "dayHoursToShow"
    );
    setDayShiftHoursStatus(updatedStatistics.dayHoursToShow);
    updateStatistics(updatedStatistics);
  };

  const toggleWeekends = async () => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "weekends",
      "otherSettings"
    );
    setOtherSettings(updatedStatistics.otherSettings);
    updateStatistics(updatedStatistics);
  };

  const togglePreferences = async (severity) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      "preferences" + severity,
      "otherSettings"
    );
    setOtherSettings(updatedStatistics.otherSettings);
    updateStatistics(updatedStatistics);
  };

  const toggleAlCount = async (alType) => {
    const updatedStatistics = getToggledStatistics(
      statistics,
      alType,
      "leaveCountsToShow"
    );
    setAlCountsStatus(updatedStatistics.leaveCountsToShow);
    updateStatistics(updatedStatistics);
  };

  const closeToolBar = () => {
    props.api.closeToolPanel();
  };

  const getShiftGroupButtons = () => {
    if (countsBy === COUNTS_BY.employee) {
      if (shiftGroupStatsType === SHIFT_GROUP_STAT_TYPES.counts) {
        return displayPanelButtons(
          areaFilteredShiftGroups,
          employeeShiftCountsStatus,
          toggleEmployeeShiftCount
        );
      } else {
        return displayPanelButtons(
          areaFilteredShiftGroups,
          employeeShiftHoursStatus,
          toggleEmployeeShiftHours
        );
      }
    } else {
      if (shiftGroupStatsType === SHIFT_GROUP_STAT_TYPES.counts) {
        return displayPanelButtons(
          areaFilteredShiftGroups,
          dayShiftCountsStatus,
          toggleDayShiftCount
        );
      } else {
        return displayPanelButtons(
          areaFilteredShiftGroups,
          dayShiftHoursStatus,
          toggleDayShiftHours
        );
      }
    }
  };

  if (isSettingsLoading) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <AddSkillMixModal
        isShowing={addSkillMixModalIsShowing}
        hide={toggleSkillMixModal}
        shiftShortIds={shiftShortIds.concat(shiftGroupShortIds)}
        shifts={shifts}
        skillShortIds={skillShortIds}
        skills={skills}
        addNewSkillMix={(shiftName, skillName) => {
          toggleDayShiftSkillCount(shiftName, skillName, true);
        }}
      />
      <AddShiftGroupModal
        isShowing={addShiftGroupModalIsShowing}
        hide={toggleShiftGroupModal}
        shifts={shifts}
        shiftGroups={shiftGroups}
        addNewShiftGroup={addNewShiftGroup}
      />
      <div className={styles.top}>
        <button className={styles.closeBtn} onClick={closeToolBar}>
          <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
        </button>
      </div>
      <p className={styles.title}>Modify what stats to show</p>
      <div className={styles.unselectBtnContainer}>
        <button className={styles.unselectBtn} onClick={unselectAllToggles}>
          Unselect All
        </button>
      </div>
      {/*  New UI  */}
      <p className={styles.countsByLabel}>Counts By</p>
      <div className={styles.btnsGroup}>
        <button
          className={`${styles.toggleBtn} ${
            countsBy === COUNTS_BY.day ? styles.active : null
          }`}
          onClick={() => {
            setCountsBy(COUNTS_BY.day);
          }}
        >
          Day
        </button>
        <button
          className={`${styles.toggleBtn} ${
            countsBy === COUNTS_BY.employee ? styles.active : null
          }`}
          onClick={() => {
            setCountsBy(COUNTS_BY.employee);
          }}
        >
          Employee
        </button>
      </div>
      <p className={styles.subTitle}>General Counts:</p>
      <div className={styles.btnsGroup}>
        {countsBy === COUNTS_BY.employee ? (
          <button
            onClick={toggleEmployeeTotalHours}
            className={`${styles.toggleBtn} ${
              employeeShiftHoursStatus.includes("overall") && styles.active
            }`}
          >
            Total hours
          </button>
        ) : (
          <button
            onClick={toggleDayTotalHours}
            className={`${styles.toggleBtn} ${
              dayShiftHoursStatus.includes("overall") && styles.active
            }`}
          >
            Total hours
          </button>
        )}
        {countsBy === COUNTS_BY.employee ? (
          <button
            onClick={toggleEmployeeTotalShiftCount}
            className={`${styles.toggleBtn} ${
              employeeShiftCountsStatus.includes("overall") && styles.active
            }`}
          >
            Total shifts
          </button>
        ) : (
          <button
            onClick={toggleDayTotalShiftCount}
            className={`${styles.toggleBtn} ${
              dayShiftCountsStatus.includes("overall") && styles.active
            }`}
          >
            Total shifts
          </button>
        )}
        {countsBy === COUNTS_BY.employee && (
          <>
            <button
              onClick={toggleWeekends}
              className={`${styles.toggleBtn} ${
                otherSettings.includes("weekends") && styles.active
              }`}
            >
              Weekends off
            </button>
            {["", "Critical", "High", "Low"].map((severity, idx) => (
              <button
                key={severity + "" + idx}
                onClick={() => togglePreferences(severity)}
                className={`${styles.toggleBtn} ${
                  otherSettings.includes("preferences" + severity) &&
                  styles.active
                }`}
              >
                {severity} {severity ? "p" : "P"}references
              </button>
            ))}
          </>
        )}
      </div>
      <p className={styles.subTitle}>Shift Counts:</p>
      <div className={styles.btnsGroup}>
        {countsBy === COUNTS_BY.employee
          ? displayPanelButtons(
              areaFilteredShifts,
              employeeShiftCountsStatus,
              toggleEmployeeShiftCount
            )
          : displayPanelButtons(
              areaFilteredShifts,
              dayShiftCountsStatus,
              toggleDayShiftCount
            )}
      </div>
      <p className={styles.subTitle}>Shift Group Counts or Hours:</p>
      <div className={styles.btnsGroup}>
        {getShiftGroupButtons()}
        <button
          onClick={toggleShiftGroupModal}
          className={`${styles.toggleBtn}  ${styles.createNew}`}
        >
          (Add new)
        </button>
      </div>
      <div className={styles.countHoursSeparator}>
        <hr />
      </div>
      <div className={styles.btnsGroup}>
        <button
          className={`${styles.toggleBtn} ${
            shiftGroupStatsType === SHIFT_GROUP_STAT_TYPES.counts
              ? styles.active
              : null
          }`}
          onClick={() => setShiftGroupStatsType(SHIFT_GROUP_STAT_TYPES.counts)}
        >
          Counts
        </button>
        <button
          className={`${styles.toggleBtn} ${
            shiftGroupStatsType === SHIFT_GROUP_STAT_TYPES.hours
              ? styles.active
              : null
          }`}
          onClick={() => setShiftGroupStatsType(SHIFT_GROUP_STAT_TYPES.hours)}
        >
          Hours
        </button>
      </div>
      <hr className={styles.mixSeparator} />
      {countsBy === COUNTS_BY.employee && (
        <>
          <p className={styles.subTitle}>Leave Counts:</p>
          <div className={styles.btnsGroup}>
            {customKeywords.leaveKeywords.map((alType, idx) => (
              <button
                key={idx}
                onClick={() => toggleAlCount(alType)}
                className={`${styles.toggleBtn} ${
                  alCountsStatus.includes(alType) ? styles.active : null
                }`}
              >
                {alType}
              </button>
            ))}
          </div>
        </>
      )}
      {countsBy === COUNTS_BY.day && (
        <>
          <p className={styles.subTitle}>Skill Mix - Customised Counts:</p>
          <div className={styles.btnsGroup}>
            {dayShiftSkillToggleDisplayedStatus.map((obj, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => toggleDayShiftSkillCount(obj.shift, obj.skill)}
                  className={`${styles.toggleBtn} ${
                    dayShiftSkillCountsToShow.find(
                      (pair) =>
                        pair.shift === obj.shift && pair.skill === obj.skill
                    )
                      ? styles.active
                      : null
                  }`}
                >
                  {getEntityNameByShortId(shifts, obj.shift) +
                    "(" +
                    getEntityNameByShortId(skills, obj.skill) +
                    ")"}
                </button>
              );
            })}
            <button
              onClick={toggleSkillMixModal}
              className={`${styles.toggleBtn} ${styles.createNew}`}
            >
              (Add new)
            </button>
          </div>
        </>
      )}
    </div>
  );
};
