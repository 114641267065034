import { useRef } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import styles from "./ShadowButton.module.css";

const ShadowButton = ({
  children,
  labelColor,
  backgroundColor,
  onClick,
  onBlockedClick = () => {},
  shadow,
  border,
  hoverColor,
  borderRadius,
  isBlocked = false,
  fontSize = null,
  height = null,
  dataTestId,
}) => {
  const btnRef = useRef();
  const buttonStyle = {
    backgroundColor,
    border,
    color: labelColor,
    boxShadow: shadow,
    borderRadius: borderRadius ? borderRadius : "0",
    fontSize: fontSize ? fontSize : "14px",
    height: height ? height : "auto",
  };

  const handleBtnHoverIn = useCallback(
    (node) => {
      node.style.backgroundColor = hoverColor;
      node.style.color = "white";
    },
    [hoverColor]
  );

  const handleBtnHoverOut = useCallback(
    (node) => {
      node.style.backgroundColor = backgroundColor;
      node.style.color = labelColor;
    },
    [backgroundColor, labelColor]
  );

  useEffect(() => {
    const btnNode = btnRef.current;

    if (hoverColor) {
      btnNode.addEventListener("mouseover", () => handleBtnHoverIn(btnNode));
      btnNode.addEventListener("mouseout", () => handleBtnHoverOut(btnNode));
    }

    return () => {
      if (hoverColor) {
        btnNode.removeEventListener("mouseover", () =>
          handleBtnHoverIn(btnNode)
        );
        btnNode.removeEventListener("mouseout", () =>
          handleBtnHoverOut(btnNode)
        );
      }
    };
  }, [handleBtnHoverIn, handleBtnHoverOut, hoverColor]);

  return (
    <button
      ref={btnRef}
      className={`${styles.btn} ${isBlocked && styles.blocked}`}
      onClick={(e) => {
        if (isBlocked) {
          onBlockedClick(e);
          return;
        }
        onClick(e);
      }}
      style={buttonStyle}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
};

export default ShadowButton;
