import BasicButton from "../BasicButton/BasicButton";

const ModalReactButtons = ({
  positiveSettings = {
    label: "OK",
    color: "#009EC4",
    hoverColor: "#1f91b7",
    onClick: () => {},
    customStyle: {
      borderRadius: "5px",
      width: "160px",
      fontSize: "1.2rem",
    },
  },
  negativeSettings = {
    label: "Close",
    color: "#009EC4",
    hoverColor: "#009EC4",
    onClick: () => {},
    customStyle: {
      borderRadius: "5px",
      width: "160px",
      fontSize: "1.2rem",
    },
  },

  btnsWrapperCustomStyle = {
    position: "relative",
    width: "100%",
  },
}) => {
  return (
    <div style={btnsWrapperCustomStyle}>
      <BasicButton
        color={positiveSettings.color}
        onClick={positiveSettings.onClick}
        hoverColor={positiveSettings.hoverColor}
        customStyle={positiveSettings.customStyle}
      >
        {positiveSettings.label}
      </BasicButton>
      <BasicButton
        borderColor={negativeSettings.color}
        hoverColor={negativeSettings.hoverColor}
        onClick={negativeSettings.onClick}
        customStyle={negativeSettings.customStyle}
      >
        {negativeSettings.label}
      </BasicButton>
    </div>
  );
};

export default ModalReactButtons;
