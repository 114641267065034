/**
 * Scope: Single location (location fields, global employees, roster collections)
 */
import {
  getGlobalEmployee,
  globalEmployeeByLocation,
  rosterByLocationAndSnapshotStartDate,
  updateOpenShifts,
} from "../../graphql/queries";
import {
  createGlobalEmployee,
  createRoster,
  deleteGlobalEmployee,
  deleteRoster,
  updateGlobalEmployee,
} from "../../graphql/mutations";
import { getRosterModelById } from "./rosterQuery";
import { OpenShiftsAlreadyFulfilledError } from "../../errors/errors";
import { queueTask } from "../performanceUtils/queue";
import { getLocationModelsByOwner } from "./appQuery";
import { graphqlErrorHandler } from "./generalQueryUtil";
import {
  getCustomKeywordsDataFromLocation,
  getRosterIdByPeriodStartDate,
  interpretCustomKeywordsData,
} from "./locationDataGetters";
import { createRosterForScheduleView } from "../../features/scheduleView/service/scheduleViewRoster";
import {
  getCurrentAuthUser,
  isUserInCollaboratorGroup,
  isUserInCoordinatorGroup,
  shouldUseCustomAuthLambda,
} from "../../features/auth/service/auth";
import {
  customGlobalEmployeeByLocation,
  getGlobalEmployeeAppUser,
  getGlobalEmployeeJustVersion,
  getGlobalEmployeeVersion,
  getLocationDefault,
  getLocationForEmployeeApp,
  getLocationForKiosk,
  getLocationFrontendSettings,
  getLocationVersion,
  getLocationWithoutRosters,
  getMinimalLocation,
  globalEmployeeByLocationForEmployeeApp,
  globalEmployeeByLocationForKiosk,
  rosterDetailsByLocation,
  simplifiedRosterByLocationAndSnapshotStartDate,
} from "../graphqlUtils/customQueries";
import { updateLocationAdmin } from "../graphqlUtils/customMutations";

export async function getLocationModelById(id) {
  const locationPromise = graphqlErrorHandler(
    getLocationDefault,
    {
      id,
    },
    (data) => data.getLocation,
    null,
    "getLocationModelById"
  );

  const nonSnapshotRostersPromise = getRostersBySnapshotStatus(id, false, true);

  const [location, nonSnapshotRosters] = await Promise.all([
    locationPromise,
    nonSnapshotRostersPromise,
  ]);

  location.Rosters = { items: nonSnapshotRosters };

  return location;
}

export async function getLocationAndEmployeeModels(locationID) {
  let [location, globalEmployees] = await Promise.all([
    getLocationModelById(locationID),
    getGlobalEmployeeModelsByLocationId(locationID),
  ]);

  if (!location) {
    return null;
  }

  location.Employees = {
    items: globalEmployees,
  };

  return location;
}

export async function getLocationVersionById(id) {
  return graphqlErrorHandler(
    getLocationVersion,
    {
      id,
    },
    (data) => data.getLocation._version,
    null,
    "getLocationVersionById"
  );
}

export async function createRosterModel(rosterInfo, customOwner = null) {
  const user = await getCurrentAuthUser();
  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(user);

  const canOwnRoster =
    !isUserInCoordinatorGroup(user) && !isUserInCollaboratorGroup(user);

  if (!canOwnRoster && !customOwner) {
    throw new Error(
      "Current logged in user cannot own roster model. Provide a `customOwner` that matches the owner of the location"
    );
  }

  return graphqlErrorHandler(
    createRoster,
    {
      input: {
        ...rosterInfo,
        ...(shouldUseCustomAuthMode && {
          // custom auth lambda requires manual owner insertion
          owner: customOwner || user.session.accessToken.payload.sub,
        }),
      },
    },
    (data) => data.createRoster,
    null,
    "createRosterModel"
  );
}

export async function createRosterModelWithStartDateValidation(
  locationID,
  rosterInfo
) {
  let createdRoster = null;
  const task = async (locationID, rosterInfo) => {
    const rosters = await getRostersByLocationId(locationID);
    const rosterStartDates = rosters
      .filter((roster) => !roster._deleted)
      .map((roster) => roster.startDate);

    const startDate = rosterInfo.startDate;

    if (rosterStartDates.includes(startDate)) {
      throw new Error(`Roster with ${startDate} startDate already exists!`);
    }
    createdRoster = await createRosterModel(rosterInfo);
  };

  await queueTask(task, locationID, rosterInfo);
  return createdRoster;
}

export async function getGlobalEmployeeModelById(id, query) {
  return graphqlErrorHandler(
    query ? query : getGlobalEmployee,
    {
      id,
    },
    (data) => data.getGlobalEmployee,
    null,
    "getGlobalEmployeeModelById"
  );
}

async function getGlobalEmployeeVersionById(id) {
  return graphqlErrorHandler(
    getGlobalEmployeeVersion,
    {
      id,
    },
    (data) => data.getGlobalEmployee._version,
    null,
    "getGlobalEmployeeVersionById"
  );
}

export async function getNumberGlobalEmployeeModels(sub, username) {
  let locations = await getLocationModelsByOwner(sub, username);

  // map locations to a list of promise
  let globalEmployeePromises = locations
    .filter((location) => location.name != "Demo Location")
    .map((location) =>
      getGlobalEmployeeModelsByLocationId(
        location.id,
        customGlobalEmployeeByLocation,
        "globalEmployeeByLocation"
      )
    );

  // execute all promises concurrently
  let globalEmployeeModels = await Promise.all(globalEmployeePromises);

  // get number of employees per location
  const globalEmployeesNumPerLocation = globalEmployeeModels
    .filter((employees) => employees.length > 0)
    .map((employees) => {
      const locationName = locations.find(
        (location) => location.id === employees[0].locationID
      ).name;
      return {
        locationName,
        numberOfEmployees: employees.length,
      };
    });

  // flatten the result array and get the length
  const totalEmployees = globalEmployeeModels.flat().length;
  return { totalEmployees, globalEmployeesNumPerLocation };
}

export async function createGlobalEmployeeModels(
  globalEmployeesInfo,
  customOwner = null
) {
  const promises = globalEmployeesInfo.map((info) =>
    createGlobalEmployeeModel(info, customOwner)
  );
  const createdGlobalEmployees = await Promise.all(promises);
  return createdGlobalEmployees;
}

export async function createGlobalEmployeeModel(
  globalEmployeeInfo,
  customOwner = null
) {
  const locationID = globalEmployeeInfo.locationID;
  const user = await getCurrentAuthUser();
  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(user);

  return graphqlErrorHandler(
    createGlobalEmployee,
    {
      input: {
        ...globalEmployeeInfo,
        locationID,
        ...(shouldUseCustomAuthMode && {
          owner: customOwner || user.session.accessToken.payload.sub,
        }),
      },
    },
    (data) => data.createGlobalEmployee,
    null,
    "createGlobalEmployeeModel"
  );
}

export async function updateGlobalEmployeeModel(globalEmployeeInfo) {
  const task = async (globalEmployeeInfo) => {
    const _version = await getGlobalEmployeeVersionById(globalEmployeeInfo.id);

    return graphqlErrorHandler(
      updateGlobalEmployee,
      {
        input: {
          id: globalEmployeeInfo.id,
          ...globalEmployeeInfo,
          _version,
        },
      },
      (data) => data.updateGlobalEmployee,
      null,
      "updateGlobalEmployeeModel"
    );
  };

  return await queueTask(task, globalEmployeeInfo);
}

export async function updateGlobalEmployeeModelsQueue(globalEmployeesInfos) {
  const task = async (globalEmployees) => {
    const updatePromises = globalEmployees.map(async (globalEmployeeInfo) => {
      const _version = await getGlobalEmployeeVersionById(
        globalEmployeeInfo.id
      );
      return graphqlErrorHandler(
        updateGlobalEmployee,
        {
          input: {
            id: globalEmployeeInfo.id,
            ...globalEmployeeInfo,
            _version,
          },
        },
        (data) => data.updateGlobalEmployee,
        null,
        "updateGlobalEmployeeModelsQueue"
      );
    });
    return await Promise.all(updatePromises);
  };

  return await queueTask(task, globalEmployeesInfos);
}

export async function updateGlobalEmployeeModels(globalEmployeesInfo) {
  const promises = globalEmployeesInfo.map((info) =>
    updateGlobalEmployeeModel(info)
  );
  const updatedGlobalEmployees = await Promise.all(promises);
  return updatedGlobalEmployees;
}

export async function deleteGlobalEmployeeModel(globalEmployeeID) {
  const originalGlobalEmployee = await getGlobalEmployeeModelById(
    globalEmployeeID,
    getGlobalEmployeeJustVersion
  );
  const _version = originalGlobalEmployee._version;
  return graphqlErrorHandler(
    deleteGlobalEmployee,
    {
      input: {
        id: globalEmployeeID,
        _version,
      },
    },
    (data) => data.deleteGlobalEmployee,
    null,
    "deleteGlobalEmployeeModel"
  );
}

export async function deleteGlobalEmployeeModels(globalEmployeeIDs) {
  const deletePromises = globalEmployeeIDs.map((id) =>
    deleteGlobalEmployeeModel(id)
  );
  const deletedGlobalEmployees = await Promise.all(deletePromises);
  return deletedGlobalEmployees;
}

export async function deleteRosterModel(rosterID) {
  const originalRoster = await getRosterModelById(rosterID);
  const _version = originalRoster._version;
  return graphqlErrorHandler(
    deleteRoster,
    {
      input: {
        id: rosterID,
        _version,
      },
    },
    (data) => data.deleteRoster,
    null,
    "deleteRosterModel"
  );
}

export async function updateLocationModel(locationID, updatedFields) {
  const task = async (locationID, updatedFields) => {
    const _version = await getLocationVersionById(locationID);
    return graphqlErrorHandler(
      updateLocationAdmin,
      {
        input: {
          id: locationID,
          ...updatedFields,
          _version,
        },
      },
      (data) => data.updateLocation,
      null,
      "updateLocationModel"
    );
  };
  return await queueTask(task, locationID, updatedFields);
}

export async function getEntitiesByParentId(
  query,
  parentIDKey,
  parentID,
  queryFunctionName,
  dataGetter,
  filter = null,
  variables = {}
) {
  const entities = [];
  let token = null;

  if (filter === null) {
    filter = {
      _deleted: {
        ne: true, // "ne" stands for "not equal"
      },
    };
  }

  async function fetchEntities(nextToken) {
    const fetchedData = await graphqlErrorHandler(
      query,
      {
        [parentIDKey]: parentID,
        ...(nextToken && { nextToken }),
        ...variables,
      },
      dataGetter,
      filter,
      queryFunctionName
    );

    token = fetchedData.nextToken ? fetchedData.nextToken : null;

    const fetchedEntities = fetchedData.items;
    entities.push(...fetchedEntities);
    return nextToken;
  }

  await fetchEntities(token);
  while (token) {
    await fetchEntities(token);
  }
  return entities.filter((entity) => !entity._deleted);
}

export async function getRostersByLocationId(locationID) {
  return await getEntitiesByParentId(
    rosterDetailsByLocation,
    "locationID",
    locationID,
    "rosterDetailsByLocation",
    (data) => data.rosterByLocation
  );
}

export async function getGlobalEmployeeModelsByLocationId(locationID) {
  return await getEntitiesByParentId(
    globalEmployeeByLocation,
    "locationID",
    locationID,
    "globalEmployeeByLocation",
    (data) => data.globalEmployeeByLocation
  );
}

export async function getLocationOnly(locationID) {
  return graphqlErrorHandler(
    getMinimalLocation,
    {
      id: locationID,
    },
    (data) => data.getLocation,
    null,
    "getLocationOnly"
  );
}

export async function getLocationAndEmployeesById(
  locationID,
  isForEmployeeApp = false,
  targetEmployeeEmail = ""
) {
  let response;
  try {
    response = await graphqlErrorHandler(
      isForEmployeeApp ? getLocationForEmployeeApp : getLocationWithoutRosters,
      {
        id: locationID,
      },
      (data) => data.getLocation,
      null,
      "getLocationAndEmployeesById"
    );
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
  let employees;
  if (isForEmployeeApp) {
    employees = await getGlobalEmployeeModelsByLocationId(
      locationID,
      globalEmployeeByLocationForEmployeeApp
    );
  } else {
    employees = await getGlobalEmployeeModelsByLocationId(locationID);
  }

  if (isForEmployeeApp && targetEmployeeEmail) {
    const targetEmployee = employees.find(
      (emp) => emp.email.toLowerCase() === targetEmployeeEmail.toLowerCase()
    );

    const newEmployee = await getGlobalEmployeeModelById(
      targetEmployee.id,
      getGlobalEmployeeAppUser
    );

    employees = employees.map((employee) => {
      // Check if the current employee's ID matches the one you want to replace
      if (employee.id === newEmployee.id) {
        // Return the new employee object to replace the old one
        return newEmployee;
      }
      // Otherwise, return the current employee object unchanged
      return employee;
    });
  }

  const location = response;

  return {
    employees: employees.filter((emp) => !emp._deleted),
    location,
  };
}

export async function getLocationAndEmployeesForKiosk(locationID) {
  let location;
  try {
    location = await graphqlErrorHandler(
      getLocationForKiosk,
      {
        id: locationID,
      },
      (data) => data.getLocation,
      null,
      "getLocationForKiosk"
    );
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }

  const employees = await getGlobalEmployeeModelsByLocationId(
    locationID,
    globalEmployeeByLocationForKiosk
  );

  return {
    employees: employees.filter((emp) => !emp._deleted),
    location,
  };
}

export async function getLocationFrontendSettingsByLocationID(id) {
  return graphqlErrorHandler(
    getLocationFrontendSettings,
    { id },
    (data) => data.getLocation.frontendSettings,
    null,
    "getLocationFrontendSettingsByLocationID"
  );
}

export async function updateEmployeeOpenShifts(
  locationID,
  employeeID,
  openShiftID,
  state
) {
  const data = await graphqlErrorHandler(
    updateOpenShifts,
    {
      locationID,
      employeeID,
      openShiftID,
      state,
    },
    (data) => data,
    null,
    "updateEmployeeOpenShifts",
    true
  );
  const responseObj = JSON.parse(data.updateOpenShifts);
  if (responseObj.statusCode === 409) {
    throw new OpenShiftsAlreadyFulfilledError(
      "Open shifts are already fulfilled"
    );
  }

  const body = JSON.parse(responseObj.body);
  return body;
}

export async function createRosterModelForMainStreamScheduleView(
  location,
  globalEmployees,
  numDays,
  startDate
) {
  if (!location) {
    throw new Error("Need to provide base location model");
  }

  if (getRosterIdByPeriodStartDate(location, startDate)) {
    throw new Error("Roster model already exists for that period");
  }

  const annualLeaveKeyword = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  ).annualLeaveKeyword;

  const rosterInfo = createRosterForScheduleView(
    location.id,
    numDays,
    startDate,
    globalEmployees,
    annualLeaveKeyword
  );

  const newRoster = await createRosterModel(rosterInfo, location.owner);
  return newRoster;
}

export async function getRostersBySnapshotStatus(
  locationID,
  isSnapshot,
  shouldReturnFullRosterFields = false
) {
  const prefix = isSnapshot ? "T" : "F";

  const query = shouldReturnFullRosterFields
    ? rosterByLocationAndSnapshotStartDate
    : simplifiedRosterByLocationAndSnapshotStartDate;

  const rosters = await getEntitiesByParentId(
    query,
    "locationID",
    locationID,
    "rosterByLocationAndSnapshotStartDate",
    (data) => data.rosterByLocationAndSnapshotStartDate,
    null,
    {
      locationID,
      snapshotStartDate: {
        beginsWith: prefix,
      },
    }
  );

  return rosters;
}

// startDate and finishDate is inclusive
export async function getRostersBySnapshotStartDateRange(
  locationID,
  isSnapshot,
  fromDate,
  toDate,
  shouldReturnFullRosterFields = false
) {
  const prefix = isSnapshot ? "T" : "F";
  const fromDateKey = `${prefix}${fromDate}`;
  const toDateKey = `${prefix}${toDate}`;

  const query = shouldReturnFullRosterFields
    ? rosterByLocationAndSnapshotStartDate
    : simplifiedRosterByLocationAndSnapshotStartDate;

  const rosters = await getEntitiesByParentId(
    query,
    "locationID",
    locationID,
    "rosterByLocationAndSnapshotStartDate",
    (data) => data.rosterByLocationAndSnapshotStartDate,
    null,
    {
      locationID,
      snapshotStartDate: {
        between: [fromDateKey, toDateKey],
      },
    }
  );

  return rosters;
}
