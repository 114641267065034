import styles from "./Step.module.css";
import { useWizard } from "react-use-wizard";
import StepTemplate from "./StepTemplate";
import StepTitle from "./StepTitle";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

// Calculate people working each day based on staffingFrequency and staffingNumber
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const getStaffingNumbers = (
  minStaffingNumber,
  maxStaffingNumber,
  staffingFrequency
) => {
  const minStaffingData = days.map((day) => {
    let peopleWorking = 0;
    switch (staffingFrequency) {
      case "everyday":
        peopleWorking = minStaffingNumber;
        break;
      case "weekdays":
        if (!["Saturday", "Sunday"].includes(day)) {
          peopleWorking = minStaffingNumber;
        }
        break;
      case "weekends":
        if (["Saturday", "Sunday"].includes(day)) {
          peopleWorking = minStaffingNumber;
        }
        break;
      default:
        peopleWorking = 0;
    }
    return peopleWorking;
  });

  const maxStaffingData = days.map((day) => {
    let peopleWorking = 0;
    switch (staffingFrequency) {
      case "everyday":
        peopleWorking = maxStaffingNumber;
        break;
      case "weekdays":
        if (!["Saturday", "Sunday"].includes(day)) {
          peopleWorking = maxStaffingNumber;
        }
        break;
      case "weekends":
        if (["Saturday", "Sunday"].includes(day)) {
          peopleWorking = maxStaffingNumber;
        }
        break;
      default:
        peopleWorking = 0;
    }
    return peopleWorking;
  });

  return [minStaffingData, maxStaffingData];
};

const Step4 = ({ data, updateData, numEmployees }) => {
  const { nextStep, previousStep } = useWizard();
  const [isInvalidDemandValue, setIsInvalidDemandValue] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isInteger = Number.isInteger(parseFloat(value));

    if (isInteger) {
      setIsInvalidDemandValue(false);
    } else {
      setIsInvalidDemandValue(true);
    }

    updateData({ [name]: Number(value) });
  };

  const [minStaffingData, maxStaffingData] = getStaffingNumbers(
    data.minStaffingNumber,
    data.maxStaffingNumber,
    data.staffingFrequency
  );

  const minMoreThanEmployees =
    data.minStaffingNumber >= numEmployees ||
    data.maxStaffingNumber >= numEmployees;
  const minMoreThanMax = data.minStaffingNumber > data.maxStaffingNumber;

  return (
    <StepTemplate
      nextName="Next"
      previousStep={previousStep}
      nextStep={nextStep}
      shouldBlock={
        minMoreThanEmployees || minMoreThanMax || isInvalidDemandValue
      }
    >
      <div className={`${styles.container} ${styles.singleContentContainer}`}>
        <StepTitle
          fontAwesomeIcon={faListCheck}
          text={"Step 3/4 - Generating your first roster"}
        />
        <h1 className={styles.title}>Create your first staffing demands</h1>
        <div className={`${styles.paragraphs} ${styles.stepFourParagraphs}`}>
          <p className={`${styles.paragraph} ${styles.blueText}`}>
            Staffing demands is where you can specify how many people you need
            to work. You can also specify in your requirements if you need
            people on a specific shift, or to do a specific task, or to have a
            specific skill. You can even combine these requirements. Let&apos;s
            start with a simple demand.
          </p>
        </div>
        <div className={styles.requirementInputs}>
          I need between
          <div>
            <input
              id="minStaffingNumber"
              name="minStaffingNumber"
              type="number"
              min="1"
              value={data.minStaffingNumber}
              onChange={handleChange}
            />
          </div>
          and
          <div>
            <input
              id="maxStaffingNumber"
              name="maxStaffingNumber"
              type="number"
              min="1"
              value={data.maxStaffingNumber}
              onChange={handleChange}
            />
          </div>
          staff on &quot;{data.shiftName}&quot; shift{" "}
          <div>
            <select
              id="staffingFrequency"
              name="staffingFrequency"
              value={data.staffingFrequency}
              onChange={handleChange}
            >
              <option value="">Select Frequency</option>
              <option value="everyday">Every Day</option>
              <option value="weekdays">Weekdays Only</option>
              <option value="weekends">Weekends Only</option>
            </select>
          </div>
        </div>
        {isInvalidDemandValue && (
          <p className={styles.redText}>Please enter an integer number</p>
        )}
        {/* Table displaying staffing data */}
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Shift Name</th>
              <th>Type</th>
              <th>Importance</th>
              {days.map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.shiftName || "Default Shift"}</td>
              <td>Minimum</td>
              <td>Critical</td>
              {minStaffingData.map((count, index) => (
                <td key={index}>{count}</td>
              ))}
            </tr>
            <tr>
              <td>{data.shiftName || "Default Shift"}</td>
              <td>Maximum</td>
              <td>High</td>
              {maxStaffingData.map((count, index) => (
                <td key={index}>{count}</td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className={`${styles.paragraphs} ${styles.stepFourParagraphs}`}>
          <p className={styles.paragraph}>
            Tip: You can customise each demand to be unique for each day. Just
            make sure you have a higher maximum for every minimum.
          </p>

          {minMoreThanEmployees && (
            <p className={`${styles.paragraph} ${styles.redText}`}>
              <b>
                You can&apos;t have a demand for more staff than you have (
                {numEmployees}).
              </b>
            </p>
          )}
          {minMoreThanMax && (
            <p className={`${styles.paragraph} ${styles.redText}`}>
              <b>
                Your maximum staffing number must be more than the minimum for
                every day.
              </b>
            </p>
          )}
        </div>
      </div>
    </StepTemplate>
  );
};

export default Step4;
