import { forwardRef } from "react";
import styles from "./MonthCalendar.module.css";
import {
  getMonthCalendarContent,
  getMonthCalendarWithoutNextMonthDates,
} from "../../../../../utils/generalUtils/calendar";
import { DateTime } from "../../../../../utils";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import ScheduleCell from "../ScheduleCell/ScheduleCell";
import PreferencesCell from "../PreferencesCell/PreferencesCell";
import LeaveCell from "../LeaveCell/LeaveCell";
import {
  checkDateFulfilsRecurringPreference,
  getApprovedLeaveOnDay,
} from "../../../service/employeeAppUtil";
import MonthCalendarCellWrapper from "../MonthCalendarCellWrapper/MonthCalendarCellWrapper";

const MonthCalendar = forwardRef(
  (
    {
      month,
      nextMonth,
      year,
      currentViewData,
      setSelectedDate,
      isBottomTabOpened,
      openBottomTab,
      toggleBottomTab,
      allocations,
      myOpenShifts,
      userEmployee,
      location,
      preferences,
      preferencesRecurring,
      requests,
      leaveCodes,
      employeeLeaveCounts,
      monthType,
      leaveKeywordsDict,
      rowHeight,
      cellWidth,
      notesToDisplay,
    },
    ref
  ) => {
    const { selectedDate, contentViewType } = currentViewData;

    const fullCalendarContent = getMonthCalendarContent(year, month);
    const calendarContent =
      monthType === "next-month"
        ? fullCalendarContent
        : getMonthCalendarWithoutNextMonthDates(year, month, nextMonth);

    const getCellComponent = () => {
      switch (contentViewType) {
        case EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule:
          return ScheduleCell;
        case EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences:
          return PreferencesCell;
        case EMPLOYEE_APP_CONTENT_VIEW_TYPE.whosOnLeave:
          return LeaveCell;
      }
    };

    const Cell = getCellComponent();

    return (
      <>
        {calendarContent.map((row, idx) => {
          const isFirstRow = idx === 0;
          return (
            <div
              key={idx}
              className={styles.row}
              style={{
                height: "16.666%",
              }}
              ref={isFirstRow ? ref : null}
            >
              {row.map((date) => {
                const allocation = allocations.find(
                  (allo) => allo.date === date.toFormat("AWS")
                );

                const openShiftsOnDay = myOpenShifts.filter((openShift) => {
                  if (new DateTime().isAfter(openShift.date)) {
                    const state = openShift.employeeStates.find(
                      (s) => s.employeeID === userEmployee.id
                    );
                    if (state.state === "pending") {
                      return false;
                    }
                  }
                  return openShift.date === date.toFormat("AWS");
                });

                const targetRecurringPreference =
                  checkDateFulfilsRecurringPreference(
                    location.startDate,
                    date,
                    preferencesRecurring
                  );

                const targetPreference = preferences.find(
                  (preference) => preference.date === date.toFormat("AWS")
                );

                const approvedLeave = getApprovedLeaveOnDay(
                  date,
                  requests,
                  leaveCodes
                );

                let approvedLeaveShortName = null;
                if (approvedLeave) {
                  const matchingLeaveCode = leaveCodes.find(
                    (code) => code.longname === approvedLeave.request
                  );
                  if (matchingLeaveCode) {
                    approvedLeaveShortName = matchingLeaveCode.shortname;
                  }
                }

                const note =
                  notesToDisplay && date.toFormat("AWS") in notesToDisplay
                    ? notesToDisplay[date.toFormat("AWS")]
                    : "";

                return (
                  <div key={date.toFormat("AWS")} className={styles.dayCell}>
                    <MonthCalendarCellWrapper
                      date={date}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      openBottomTab={openBottomTab}
                      toggleBottomTab={toggleBottomTab}
                    >
                      <Cell
                        allocation={allocation}
                        openShiftsOnDay={openShiftsOnDay}
                        employeeID={userEmployee.id}
                        locationID={location.id}
                        isBottomTabOpened={isBottomTabOpened}
                        preferenceOnDay={targetPreference}
                        recurringPreferenceOnDay={targetRecurringPreference}
                        requests={requests}
                        employeeLeaveCounts={employeeLeaveCounts}
                        date={date}
                        leaveKeywordsDict={leaveKeywordsDict}
                        rowHeight={rowHeight}
                        cellWidth={cellWidth}
                        approvedLeaveShortName={approvedLeaveShortName}
                        note={note}
                      />
                    </MonthCalendarCellWrapper>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }
);

export default MonthCalendar;
