import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./RuleTemplateOptionItem.module.css";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

const RuleTemplateOptionItem = ({ ruleTemplateName, ruleSet }) => (
  <div className={styles.option}>
    <span>{ruleTemplateName}</span>
    {ruleSet && (
      <div className={styles.info}>
        <FontAwesomeIcon icon={faCircleQuestion} className={styles.infoIcon} />
        <p className={styles.description}>{ruleSet.description}</p>
      </div>
    )}
  </div>
);

export default RuleTemplateOptionItem;
