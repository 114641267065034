import {
  arrayIsSuperset,
  arraysIntersect,
  cacheFnWithImmutableArgs,
  getEntityByShortId,
  getShiftTaskByShortId,
  hasCommonItems,
  strToArrCommaSeparated,
} from "../../../utils";
import {
  getCombinedSubtasks,
  getSingleEnumeratedSubtasks,
  getSubtasks,
} from "./rosterUtils";
import {
  KEYWORD_ALL,
  KEYWORD_NA,
  KEYWORD_NO_TASK,
  KEYWORD_OFF,
} from "../../../constants/keywords";
import {
  extractEntityShortIdsFromAllocation,
  toAllocationString,
} from "../../../utils/modelUtils/allocation";

export const employeeFulfilsArea = (
  employee,
  area,
  shiftGroups,
  customKeywordsUtilObj
) => {
  const employeeAreas = strToArrCommaSeparated(employee.areas);
  const employeeSkills = strToArrCommaSeparated(employee.skills);
  const employeeShifts = strToArrCommaSeparated(employee.shifts);

  const areaSkills = strToArrCommaSeparated(area.skills);
  const areaShifts = strToArrCommaSeparated(area.shifts);

  if (
    employeeAreas.length > 0 &&
    !employeeAreas.includes(KEYWORD_ALL) &&
    !employeeAreas.includes(area.shortId)
  ) {
    return false;
  }

  if (areaSkills.length > 0 && !areaSkills.includes(KEYWORD_ALL)) {
    if (!hasCommonItems(areaSkills, employeeSkills)) {
      return false;
    }
  }

  if (
    !employeeShifts.includes(KEYWORD_ALL) &&
    areaShifts.length > 0 &&
    !areaShifts.includes(KEYWORD_ALL)
  ) {
    for (const areaShift of areaShifts) {
      const shiftGroupInArea = shiftGroups.find(
        ({ shortId }) => areaShift === shortId
      );

      if (shiftGroupInArea) {
        for (const employeeShift of employeeShifts) {
          if (employeeShift === areaShift) {
            return true;
          }
          const isAllowed = allocationFulfilsShiftGroup(
            shiftGroupInArea,
            null,
            employeeShift,
            null,
            employee.skills,
            customKeywordsUtilObj
          );

          if (isAllowed) {
            return true;
          }
        }
      } else {
        const isAllowed = employeeShifts.some((employeeShift) => {
          const shiftGroupInEmployee = shiftGroups.find(
            ({ shortId }) => employeeShift === shortId
          );

          if (shiftGroupInEmployee) {
            if (
              allocationFulfilsShiftGroup(
                shiftGroupInEmployee,
                null,
                areaShift,
                null,
                employee.skills,
                customKeywordsUtilObj
              )
            ) {
              return true;
            }
          }
          return areaShift === employeeShift;
        });

        if (isAllowed) {
          return true;
        }
      }
    }
    return false;
  } else {
    return true;
  }
};

export const allocationFulfilsShiftGroup = (
  shiftGroup,
  area_worked = null,
  shift_worked,
  task_worked,
  employee_skills = null,
  customKeywordsUtilObj
) => {
  shift_worked = shift_worked ?? "";
  task_worked = task_worked ?? "";
  area_worked = area_worked ?? "";

  const { leaveKeywords } = customKeywordsUtilObj;
  if ([...leaveKeywords, KEYWORD_NA].includes(shift_worked)) {
    return false;
  }

  const shift_group_areas = strToArrCommaSeparated(shiftGroup.areas);
  const shift_group_shifts = shiftGroup.shifts
    ? strToArrCommaSeparated(shiftGroup.shifts)
    : [KEYWORD_ALL];
  const shift_group_skills = strToArrCommaSeparated(shiftGroup.skills);
  const shift_group_tasks = shiftGroup.tasks
    ? strToArrCommaSeparated(shiftGroup.tasks).map((task) =>
        task === KEYWORD_NO_TASK ? "" : task
      )
    : [KEYWORD_ALL];

  if (area_worked) {
    if (
      shift_group_areas.length > 0 &&
      !shift_group_areas.includes(KEYWORD_ALL) &&
      !shift_group_areas.includes(area_worked)
    ) {
      return false;
    }

    if (
      shift_group_areas.includes(area_worked) &&
      shift_group_shifts.length === 0
    ) {
      return true;
    }
  }

  if (shift_group_skills.length > 0) {
    if (employee_skills != null) {
      const employee_skills_list = strToArrCommaSeparated(employee_skills);
      const fulfils_skills = shift_group_skills.some((element) =>
        employee_skills_list.includes(element)
      );

      if (!fulfils_skills) return false;
    }
  }

  const shiftGroupContainsShift =
    (shift_group_shifts.includes(KEYWORD_ALL) &&
      shift_worked &&
      shift_worked !== KEYWORD_OFF) ||
    shift_group_shifts.includes(shift_worked);

  const shiftGroupContainsTask =
    shift_group_tasks.includes(KEYWORD_ALL) ||
    shift_group_tasks.includes(task_worked);

  const isShiftInversed = shiftGroup.inversed;
  const isTaskInversed = shiftGroup.skillsInversed; // field name is misleading but it is what it is

  if (isShiftInversed) {
    if (!isTaskInversed) {
      if (shift_worked === "") {
        if (!shift_group_shifts.includes(KEYWORD_OFF)) return true;
        else {
          return false;
        }
      } else if (!shiftGroupContainsShift && shiftGroupContainsTask) {
        return true;
      } else {
        return false;
      }
    } else {
      if (shift_worked === "") {
        if (!shift_group_shifts.includes(KEYWORD_OFF)) return true;
      } else if (!shiftGroupContainsShift && !shiftGroupContainsTask)
        return true;
      else {
        return false;
      }
    }
  } else {
    if (!isTaskInversed) {
      if (
        (shiftGroupContainsShift && shiftGroupContainsTask) ||
        (shift_group_shifts.includes(KEYWORD_OFF) && shift_worked === "")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        (shiftGroupContainsShift && !shiftGroupContainsTask) ||
        (shift_group_shifts.includes(KEYWORD_OFF) && shift_worked === "")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const allocationFulfilsShift = (shift_worked, preference) => {
  return (
    (preference && shift_worked === preference) ||
    (preference === KEYWORD_OFF && shift_worked === "")
  );
};

const allocationFulfilsTask = (task_worked, preferred_task) => {
  return preferred_task && task_worked === preferred_task.shortId;
};

const allocationFulfilsShiftTask = (
  shift_worked: any,
  task_worked: any,
  preferred_shift_task: any[]
) => {
  return (
    preferred_shift_task &&
    shift_worked === preferred_shift_task[0].name &&
    task_worked === preferred_shift_task[1].name
  );
};

export const getNoTaskSubtaskLabels = (taskBlocks) => {
  return taskBlocks.map((taskBlock) => "no task " + taskBlock.shortId);
};

export const getNoTaskSubtaskNames = (taskBlocks) => {
  return taskBlocks.map((taskBlock) => "no task " + taskBlock.name);
};

export const getNoTaskSubtasks = (taskBlocks) => {
  return taskBlocks.map((taskBlock) => ({
    name: "no task " + taskBlock.name,
    shortId: "no task " + taskBlock.shortId,
  }));
};

const buildTaskBlockTasks = cacheFnWithImmutableArgs(
  (tasks, taskBlocks, shiftGroups) => {
    let newShiftGroups = JSON.parse(JSON.stringify(shiftGroups));
    // Create subtasks
    let subtasks = getSubtasks(tasks, taskBlocks);

    // Create enumerated tasks
    let enumeratedTasks = getCombinedSubtasks(tasks, taskBlocks);

    // Tasks = Enumerated tasks
    let oldTasks = [...tasks];
    tasks = [...enumeratedTasks, ...getSingleEnumeratedSubtasks(subtasks)];

    const noTaskNames = getNoTaskSubtasks(taskBlocks);

    // Change tasks to enumerated tasks and subtasks
    newShiftGroups = newShiftGroups.map((shiftGroup) => {
      const oldTasks = strToArrCommaSeparated(shiftGroup["tasks"]);
      const newTasks = [];

      enumeratedTasks.forEach((enumeratedTask) => {
        if (
          arraysIntersect(oldTasks, enumeratedTask.taskShortIds) ||
          arrayIsSuperset(oldTasks, enumeratedTask.subtaskShortIds)
        ) {
          newTasks.push(enumeratedTask.shortId);
        }
      });

      subtasks.forEach((subtask) => {
        if (
          oldTasks.includes(subtask.taskShortId) ||
          (oldTasks.includes(subtask.shortId) &&
            arraysIntersect(
              oldTasks.filter((task) => task.includes("no task ")),
              noTaskNames.filter(
                (task) => task != "no task " + subtask.blockShortId
              )
            ))
        ) {
          newTasks.push(subtask.shortId);
        }
      });

      if (
        oldTasks.filter((task) => task.includes("no task ")).length >= 2 &&
        !newTasks.includes(KEYWORD_NO_TASK)
      ) {
        newTasks.push(KEYWORD_NO_TASK);
      }

      shiftGroup["tasks"] = newTasks.join(",");

      return shiftGroup;
    });

    subtasks.forEach((subtask) => {
      newShiftGroups.push({
        shortId: subtask.shortId,
        name: subtask.name,
        shifts: KEYWORD_OFF,
        inversed: true,
        skills: "",
        tasks: tasks
          .filter((enumTask) => enumTask.taskNames.includes(subtask.taskName))
          .map((task) => task.shortId)
          .join(","),
        skillsInversed: false,
      });
    });

    // Add tasks to shiftGroups
    oldTasks.forEach((oldTask) => {
      newShiftGroups.push({
        shortId: oldTask.shortId,
        name: oldTask.name,
        shifts: KEYWORD_OFF,
        inversed: true,
        skills: "",
        tasks: tasks
          .filter((enumTask) => enumTask.taskNames.includes(oldTask))
          .map((task) => task.shortId)
          .join(","),
        skillsInversed: false,
      });
    });

    return [tasks, newShiftGroups];
  }
);

// The original allocationFulfilsShiftGroup doesn't work with taskblocks TODO: replace all usages with this function
export const allocationFulfilsShiftGroup2 = (
  allocation: string,
  areas,
  shiftGroupName: string,
  shifts: any[],
  shiftGroups: any[],
  tasks: any[],
  taskBlocks: any[],
  employee_skills: any,
  shortIdsToEntityNamesDicts: any,
  customKeywordsUtilObj: any
) => {
  return allocationFulfilsPreference(
    allocation,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    shiftGroupName,
    employee_skills,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );
};

export const allocationFulfilsPreference = cacheFnWithImmutableArgs(
  (
    allocation: string,
    shifts: any,
    shiftGroups: any,
    tasks: any,
    taskBlocks: any,
    preferenceCellValue: string,
    employee_skills: any,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  ) => {
    const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
      extractEntityShortIdsFromAllocation(
        allocation,
        shortIdsToEntityNamesDicts
      );

    const {
      areaShortId: preferenceAreaShortId,
      shiftShortId: preferenceShiftShortId,
      shiftGroupShortId: preferenceShiftGroupShortId,
      taskShortId: preferenceTaskShortId,
      enumeratedTaskShortId: preferenceEnumeratedTaskShortId,
    } = extractEntityShortIdsFromAllocation(
      preferenceCellValue,
      shortIdsToEntityNamesDicts
    );

    const allocationWithoutArea = toAllocationString(
      null,
      shiftShortId,
      taskShortId,
      enumeratedTaskShortId
    );

    const preferenceAllocation =
      preferenceShiftGroupShortId ||
      toAllocationString(
        preferenceAreaShortId,
        preferenceShiftShortId,
        preferenceTaskShortId,
        preferenceEnumeratedTaskShortId
      );
    const preferenceWithoutArea =
      preferenceShiftGroupShortId ||
      toAllocationString(
        null,
        preferenceShiftShortId,
        preferenceTaskShortId,
        preferenceEnumeratedTaskShortId
      );

    if (!allocation && preferenceCellValue === KEYWORD_OFF) return true;

    if (allocation) {
      if (allocation === preferenceAllocation) return true;
    }

    // if allocation area is different to preference area, doesn't fulfill.

    if (preferenceAreaShortId) {
      if (areaShortId !== preferenceAreaShortId) {
        return false;
      }
      if (allocationWithoutArea === preferenceWithoutArea) {
        return true;
      }
    }

    if (!taskBlocks || taskBlocks.length > 0) {
      [tasks, shiftGroups] = buildTaskBlockTasks(
        tasks,
        taskBlocks,
        shiftGroups
      );
    }

    const shiftGroup = getEntityByShortId(preferenceWithoutArea, shiftGroups);
    const task = getEntityByShortId(preferenceWithoutArea, tasks);
    const shiftTaskName = getShiftTaskByShortId(
      preferenceWithoutArea,
      shifts,
      tasks
    );

    if (shiftGroup) {
      const shiftGroupAreas = strToArrCommaSeparated(shiftGroup.areas);

      if (
        shiftGroupAreas.length > 0 &&
        !shiftGroupAreas.includes(KEYWORD_ALL) &&
        areaShortId &&
        !shiftGroupAreas.includes(areaShortId)
      ) {
        return false;
      }

      const shiftsInGroup = strToArrCommaSeparated(shiftGroup.shifts);
      const areasInGroup = strToArrCommaSeparated(shiftGroup.areas);
      if (
        areaShortId &&
        shiftsInGroup.length === 0 &&
        areasInGroup.includes(areaShortId)
      ) {
        return true;
      }
    }

    if (
      shiftGroup &&
      allocationFulfilsShiftGroup(
        shiftGroup,
        areaShortId,
        shiftShortId,
        taskShortId || enumeratedTaskShortId,
        employee_skills,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }

    if (
      shiftTaskName &&
      allocationFulfilsShiftTask(
        shiftShortId,
        taskShortId || enumeratedTaskShortId,
        shiftTaskName
      )
    ) {
      return true;
    }

    if (
      task &&
      allocationFulfilsTask(taskShortId || enumeratedTaskShortId, task)
    ) {
      return true;
    }

    if (allocationFulfilsShift(shiftShortId, preferenceWithoutArea)) {
      return true;
    }

    return false;
  }
);

export const preferenceOrFixedShiftMet = (
  mergedPreferencesOrFixedShift: [string],
  d: number,
  value: any,
  areas,
  shifts: any,
  shiftGroups: any,
  tasks: any,
  taskBlocks: any,
  employeeSkills: string,
  shortIdsToEntityNamesDicts: any,
  customKeywordsUtilObj: any
) => {
  var preference = mergedPreferencesOrFixedShift[d].replace(/[!?]/, "");

  if (preference === "") {
    return false;
  }

  return allocationFulfilsPreference(
    value,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    preference,
    employeeSkills,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );
};
