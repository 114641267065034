import styles from "./Layout.module.css";
const Layout = ({
  children,
  title,
  subtitle,
  headerRight,
  isSubheading,
  isGridFullScreen = false,
  headerNext,
  hideHeader = false,
}) => {
  const HeaderRight = headerRight;
  const HeaderNext = headerNext;
  return (
    <div
      className={`${styles.container} ${
        isGridFullScreen ? styles.fullScreenGrid : ""
      }`}
    >
      <div className={styles["inner-container"]}>
        {isGridFullScreen || hideHeader ? null : (
          <div className={`${isSubheading ? styles.subheader : styles.header}`}>
            <div className={styles.headerGroup}>
              {title && (
                <h1
                  className={`${
                    isSubheading ? styles.subheading : styles.title
                  }`}
                  data-testid="layout-title"
                >
                  {title}
                </h1>
              )}
              {headerNext && <HeaderNext />}
            </div>
            {headerRight && <HeaderRight />}
          </div>
        )}

        {subtitle && <h6 className={styles.subtitle}>{subtitle}</h6>}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
