import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styles from "./BasicButton.module.css";

const BasicButton = ({
  children,
  onClick,
  color,
  borderColor,
  hoverColor,
  dataTestId,
  isBlocked = false,
  title = "",
  hoverlabelColor,
  customStyle = {
    borderRadius: "20px",
  },
}) => {
  const btnRef = useRef();
  const buttonStyle = {
    borderColor: borderColor && borderColor,
    color: borderColor && borderColor,
    backgroundColor: !borderColor && color,
  };

  const handleBasicBtnHoverIn = useCallback(
    (node) => {
      node.style.backgroundColor = hoverColor;
    },
    [hoverColor]
  );

  const handleBasicBtnHoverOut = useCallback(
    (node) => {
      node.style.backgroundColor = buttonStyle.backgroundColor;
    },
    [buttonStyle.backgroundColor]
  );

  const handleBorderBtnHoverIn = useCallback(
    (node) => {
      node.style.backgroundColor = hoverColor;
      if (hoverlabelColor) {
        node.style.color = hoverlabelColor;
      } else {
        node.style.color = "white";
      }
    },
    [hoverColor, hoverlabelColor]
  );
  const handleBorderBtnHoverOut = useCallback(
    (node) => {
      node.style.backgroundColor = "white";
      node.style.color = buttonStyle.color;
    },
    [buttonStyle.color]
  );

  useEffect(() => {
    const btnNode = btnRef.current;
    if (!isBlocked) {
      if (borderColor && hoverColor) {
        btnNode.addEventListener("mouseover", () =>
          handleBorderBtnHoverIn(btnNode)
        );
        btnNode.addEventListener("mouseout", () =>
          handleBorderBtnHoverOut(btnNode)
        );
      } else if (hoverColor) {
        btnNode.addEventListener("mouseover", () =>
          handleBasicBtnHoverIn(btnNode)
        );
        btnNode.addEventListener("mouseout", () =>
          handleBasicBtnHoverOut(btnNode)
        );
      }
    }

    return () => {
      if (!isBlocked) {
        if (borderColor && hoverColor) {
          btnNode.removeEventListener("mouseover", () =>
            handleBorderBtnHoverIn(btnNode)
          );
          btnNode.removeEventListener("mouseout", () =>
            handleBorderBtnHoverOut(btnNode)
          );
        } else if (hoverColor) {
          btnNode.removeEventListener("mouseover", () =>
            handleBasicBtnHoverIn(btnNode)
          );
          btnNode.removeEventListener("mouseout", () =>
            handleBasicBtnHoverOut(btnNode)
          );
        }
      }
    };
  }, [
    hoverColor,
    buttonStyle.backgroundColor,
    borderColor,
    handleBasicBtnHoverIn,
    handleBasicBtnHoverOut,
    handleBorderBtnHoverIn,
    handleBorderBtnHoverOut,
    isBlocked,
  ]);

  return (
    <button
      className={`${borderColor ? styles.borderBtn : styles.btn} ${
        isBlocked && styles.blocked
      }`}
      onClick={isBlocked ? () => {} : onClick}
      style={{ ...buttonStyle, ...customStyle }}
      ref={btnRef}
      data-testid={dataTestId}
      data-tour={dataTestId}
      title={title}
    >
      {children}
    </button>
  );
};

export default BasicButton;
