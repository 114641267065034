import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "../../../../utils";
import styles from "./SchedulePeriodSwitcher.module.css";
import { useToggleShow } from "../../../../hooks/useToggleShow";
import { debounce } from "lodash";

export const getLabel = (periodNum) => {
  if (periodNum > 0) {
    return `Next schedule period +${periodNum}`;
  }
  if (periodNum < 0) {
    return `Previous schedule period ${periodNum}`;
  }
  return "Current schedule period";
};

const SchedulePeriodSwitcher = ({
  startDate,
  finishDate,
  onForward,
  onBackward,
  shouldDisableLeftButton,
  shouldDisableRightButton,
  periodNum,
  dropdownItems,
  readOnly = false,
}) => {
  const debouncedOnForward = debounce(onForward, 200);
  const debouncedOnBackward = debounce(onBackward, 200);

  const displayedStartDate = new DateTime(startDate).toFormat("displayed-full");
  const displayedFinishDate = new DateTime(finishDate).toFormat(
    "displayed-full"
  );
  const { isOpen, toggleDropdown, selectorRef, selectorTriggerRef } =
    useToggleShow();

  return (
    <div className={styles.container}>
      <div className={styles.datesWrapper}>
        <p className={styles.date} data-testid="date-range-start-date">
          {displayedStartDate}
        </p>
        <p className={styles.date} data-testid="date-range-finish-date">
          {displayedFinishDate}
        </p>
      </div>
      <div className={styles.switcher} ref={selectorTriggerRef}>
        {!readOnly && (
          <button
            className={`${styles["date-arrow"]} ${
              shouldDisableLeftButton ? styles.disabledBtn : ""
            }`}
            onClick={debouncedOnBackward}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>
        )}
        <div className={styles.period}>
          <button
            className={styles.toggleButton}
            onClick={readOnly ? () => {} : toggleDropdown}
            style={{
              cursor: readOnly ? "auto" : "pointer",
            }}
          >
            {getLabel(periodNum)}
          </button>
          {!readOnly && isOpen && (
            <ul className={styles.dropdown} ref={selectorRef}>
              {dropdownItems.map((item, idx) => (
                <li
                  className={styles.dropdownItem}
                  key={idx}
                  onClick={() => {
                    toggleDropdown();
                    item.onClick();
                  }}
                >
                  <span> {item.label}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        {!readOnly && (
          <button
            className={`${styles["date-arrow"]} ${
              shouldDisableRightButton ? styles.disabledBtn : ""
            }`}
            onClick={debouncedOnForward}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SchedulePeriodSwitcher;
