import { useEffect } from "react";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./GenerateLocked.module.css";
import { useUserStore } from "../../../../globalStore/appStore";

const GenerateLocked = ({ rosterID, isScheduleView }) => {
  const { isPaidPlan } = useUserStore();
  const redirectTo = useRedirect();

  useEffect(() => {
    if (isPaidPlan) {
      if (isScheduleView) {
        redirectTo({ pageName: PAGE_NAMES.main });
        return;
      }
      redirectTo({ pageName: PAGE_NAMES.employeesSkills, rosterID });
    }
  }, [isScheduleView, rosterID, isPaidPlan, redirectTo]);
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Generate Roster</h1>
      <UpgradeBanner
        title={"Unlock Generate Roster with RosterLab AI plans!"}
        descriptions={[
          "Scheduling is like solving a giant sudoku, except all numbers have feelings. Dealing with everyone's unique situations while making sure fairness and enough people on each day and each shift is extremely hard.",
          "That's why RosterLab developed the AI tool to consider everything for you. Why not sit back and take a rest while our AI makes you a fresh roster that's union compliant, safe, fair, and fast!",
        ]}
        showUpgradeButton={true}
        showBookDemoButton={true}
      />
    </div>
  );
};

export default GenerateLocked;
