import { useEffect } from "react";
import { useRouteQuery } from "../../../../hooks/useRouteQuery";
import {
  getCurrentAuthUser,
  userHasDashboardAccess,
} from "../../../auth/service/auth";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import BasicLoadingText from "../../../../components/elements/BasicLoadingText/BasicLoadingText";

const ShareRosterAuthCheck = () => {
  let query = useRouteQuery();
  const shareID = query.get("id");
  const redirect = useRedirect();

  useEffect(() => {
    getCurrentAuthUser()
      .then((user) => {
        const dashboardAccess = userHasDashboardAccess(user);

        if (!dashboardAccess) {
          redirect({ pageName: PAGE_NAMES.main });
          return;
        }

        redirect({
          pageName: PAGE_NAMES.directSharedRoster,
          rosterID: shareID,
        });
      })
      .catch(() => {
        // user not logged in
        redirect({
          pageName: PAGE_NAMES.directSharedRoster,
          rosterID: shareID,
          params: {
            showSignUp: true,
          },
        });
      });
  }, [redirect, shareID]);

  return <BasicLoadingText />;
};

export default ShareRosterAuthCheck;
