import styles from "./StepTemplate.module.css";
import BasicButton from "../../BasicButton/BasicButton";

const StepTemplate = ({
  children,
  previousStep,
  nextStep,
  backName = "Back",
  nextName = "Next",
  shouldBlock,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div onSubmit={handleSubmit} className={styles.container}>
      <div className={styles.content}>{children}</div>
      <div className={styles.buttons}>
        {backName && (
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            customStyle={{
              borderRadius: "10px",
              fontWeight: "bold",
              fontSize: "20px",
              minWidth: "140px",
            }}
            onClick={previousStep}
          >
            {backName}
          </BasicButton>
        )}
        {nextName && (
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            customStyle={{
              borderRadius: "10px",
              fontWeight: "bold",
              minWidth: "140px",
              fontSize: "20px",
            }}
            onClick={nextStep}
            isBlocked={shouldBlock}
          >
            {nextName}
          </BasicButton>
        )}
      </div>
    </div>
  );
};

export default StepTemplate;
