import { buildShortIdsToEntityNamesDicts } from "../../features/rosterProblems/service/rosterUtils";
import { strToArrCommaSeparated } from "../generalUtils/array";
import { removeSuffix } from "../generalUtils/general";
import { convertAllocationInShortIdFormToNameForm } from "../modelUtils/allocation";
import { isPreferenceSuffixed } from "../modelUtils/generalModelUtil";
import { convertShortIdsCellValueToEntityNames } from "./grid";

export const shiftAndShiftGroupShortIdValueFormatter = (
  params,
  shifts,
  shiftGroups
) => {
  const value = params.value;
  const targetShift = shifts.find(({ shortId }) => shortId === value);
  const targetShiftGroup = shiftGroups.find(({ shortId }) => shortId === value);

  if (targetShift) {
    return targetShift.name;
  }

  if (targetShiftGroup) {
    return targetShiftGroup.name;
  }

  return value;
};

export const allocationValueFormatter = (
  params,
  predefinedKeywords,
  shortIdsToEntityNamesDicts
) => {
  const value = params.value;

  if (predefinedKeywords.includes(value)) {
    return value;
  }

  const nameForm = convertAllocationInShortIdFormToNameForm(
    value,
    shortIdsToEntityNamesDicts
  );

  return nameForm || value;
};

export const preferencesValueFormatter = (
  params,
  predefinedKeywords,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills
) => {
  let value = params.value ? params.value : "";
  if (isPreferenceSuffixed(value)) {
    value = removeSuffix(value, 1);
  }

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  if (predefinedKeywords.includes(value)) {
    return value;
  }

  const targetShiftGroup = shiftGroups.find(({ shortId }) => shortId === value);

  if (targetShiftGroup) {
    return targetShiftGroup.name;
  }

  const nameForm = convertAllocationInShortIdFormToNameForm(
    value,
    shortIdsToEntityNamesDicts
  );

  return nameForm || value;
};

export function defaultFilterValueGetter(
  params,
  columnName,
  entities,
  keywords = []
) {
  return strToArrCommaSeparated(params.data[columnName]).map((value) => {
    const matchingKeyword = keywords.find((keyword) => keyword === value);
    if (matchingKeyword) {
      return matchingKeyword;
    }
    return convertShortIdsCellValueToEntityNames(value, entities);
  });
}
