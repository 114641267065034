import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ShiftsGrid.module.css";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import Layout from "../../../../../components/layouts/Layout/Layout";
import { useMemo } from "react";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import Checkbox from "../../../../../components/elements/AggridCheckbox/AggridCheckbox";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import {
  DateTime,
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  createModestContextMenu,
  defaultBooleanValueSetter,
  defaultFilterValueGetter,
  dropdownOptionsValueSetter,
  finishTimeGetter,
  getReservedShiftsRowStyle,
  isNightShift,
  onFilterTextBoxChanged,
  startTimeGetter,
  suppressEnterKey,
  timeSetter,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../../../globalStore/appStore";
import { PLAN } from "../../../../auth/service/auth";
import NightShiftUpgradeCell from "../../../../grid/components/NightShiftUpgradeCell/NightShiftUpgradeCell";
import ConditionalCellRenderer from "../../../../../components/elements/ConditionalCellRenderer/ConditionalCellRenderer";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import GridCellWithPlaceholderText from "../../../../grid/components/GridCellWithPlaceholderText/GridCellWithPlaceholderText";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { isValidShiftName } from "../../../../../utils/validationUtils/sharedValidations";

const ShiftsGrid = ({
  shiftsData,
  taskNames,
  isSaving,
  setGridApiToParent,
  addData: addNewShifts,
  updateData,
  shiftGroupNames,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateShifts,
  removeData: removeShifts,
  gridApi,
  reorderShiftsData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  predefinedShiftKeywords,
  reservedShiftKeywords,
  reservedShiftSchema,
  customTopComponent = null,
  getDataFromGrid,
  setColumnApiToParent,
  columnApi,
  isScheduleView,
  skills,
  shiftsWarnings,
  shortIdsToEntityNamesDicts,
  rosterName,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const { plan } = useUserStore();
  const { manuallyStepThroughTour } = useOnBoardingStore();
  useEffect(() => {
    if (columnApi && !isScheduleView) {
      columnApi.setColumnVisible("adminUseOnly", false);
    }
  }, [columnApi, isScheduleView]);

  const [selectedRows, setSelectedRows] = useState([]);
  const getContextMenuItems = (params) => {
    if (
      params.node &&
      Object.keys(reservedShiftDict).includes(params.node.id)
    ) {
      return createModestContextMenu();
    }
    return createContextMenuWithDelete(() => {
      removeShifts(true);
    });
  };

  const reservedShiftDict = Object.fromEntries(
    reservedShiftSchema.map((shift) => [shift.id, shift])
  );

  let skillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const checkNameIsDuplicate = useCallback(
    (name) => {
      const names = [];
      if (gridApi) {
        gridApi.forEachNode((node) => {
          if (!predefinedShiftKeywords.includes(node.data.name)) {
            names.push(node.data.name);
          }
        });
      }
      return names.includes(name);
    },
    [gridApi, predefinedShiftKeywords]
  );

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent = shiftsWarnings && shiftsWarnings.length > 0;

  const columnDefs = useMemo(
    () => [
      {
        suppressMovable: true,
        field: "name",
        suppressSizeToFit: true,
        width: isScheduleView ? width / 7 : width / 6,
        minWidth: 120,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Shift name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            reservedShiftKeywords
              .map((word) => word.toLowerCase())
              .includes(params.newValue.toLowerCase())
          ) {
            showWarningPopupIfNoOthersShowing(
              `${reservedShiftKeywords.join(", ")} are reserved names`,
              "Please use another name"
            );
            return false;
          }

          if (checkNameIsDuplicate(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }

          if ([...taskNames, ...shiftGroupNames].includes(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Shift names must be different to any shift group or task names",
              "Please use another name"
            );
            return false;
          }

          if (params.newValue.length >= 40) {
            showWarningPopupIfNoOthersShowing(
              "Shift names cannot be longer than 40 characters",
              "Please use shorter name"
            );
            return false;
          }

          if (!isValidShiftName(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Please use another name",
              `Shift names cannot include '-' (hyphen), ',' (comma), '\\' (backslash), or ';' (semicolon)`
            );
            return false;
          }
          params.data.name = params.newValue.trim();
          return true;
        },
        checkboxSelection: (params) => {
          const unselectableNames = predefinedShiftKeywords;
          if (unselectableNames.includes(params.data.name)) {
            return false;
          }
          return true;
        },
        headerCheckboxSelection: true,
        rowDrag: (params) => {
          if (params.data.id.startsWith("reserved")) {
            return false;
          }
          return true;
        },
      },
      {
        suppressMovable: true,
        field: "startTime",
        valueGetter: (params) => {
          if (Object.keys(reservedShiftDict).includes(params.data.id)) {
            return reservedShiftDict[params.data.id].description;
          } else {
            return startTimeGetter(params);
          }
        },
        valueSetter: timeSetter,
        suppressSizeToFit: true,
        width: isScheduleView ? width / 7 : width / 6,
        colSpan: (params) => {
          if (Object.keys(reservedShiftDict).includes(params.data.id)) {
            return 7;
          }
          return 1;
        },
        cellStyle: (params) => {
          if (Object.keys(reservedShiftDict).includes(params.data.id)) {
            return {
              textAlign: "center",
            };
          }
        },
      },
      {
        suppressMovable: true,
        field: "finishTime",
        valueGetter: finishTimeGetter,
        valueSetter: timeSetter,
        suppressSizeToFit: true,
        width: isScheduleView ? width / 7 : width / 6,
      },
      {
        suppressMovable: true,
        field: "duration",
        sortable: true,
        editable: false,
        width: isScheduleView ? width / 7 : width / 6,
        valueFormatter: (params) => {
          const data = params.node.data;
          const { startTime, finishTime } = data;
          const hoursDiff = DateTime.getTimeDifferenceInHours(
            startTime,
            finishTime
          );
          return hoursDiff;
        },
      },
      {
        suppressMovable: true,
        headerName: "Skills",
        field: "skill",
        width: isScheduleView ? width / 7 : width / 6,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            skillOptions,
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skill", skills),
        suppressKeyboardEvent: suppressEnterKey,
        cellRenderer: "gridCellWithPlaceholderText",
        cellRendererParams: {
          defaultValue: "none required",
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: skillOptions,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (shiftsWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                shiftsWarnings
              );
            }
          },
        },
      },
      {
        suppressMovable: true,
        field: "fulfilsDemand",
        cellRenderer: "nightShiftConditionalRenderer",
        suppressSizeToFit: true,
        width: isScheduleView ? width / 7 : width / 6,
        valueSetter: defaultBooleanValueSetter,
        editable: (params) => {
          if (plan === PLAN.MID) {
            const { startTime, finishTime } = params.data;
            if (isNightShift(startTime, finishTime)) {
              return false;
            }
          }
          return true;
        },
      },
      {
        suppressMovable: true,
        headerName: "A.I. assigned",
        field: "autoAssigned",
        suppressSizeToFit: true,
        cellRenderer: "nightShiftConditionalRenderer",
        width: isScheduleView ? width / 7 : width / 6,
        valueSetter: defaultBooleanValueSetter,
        editable: (params) => {
          if (plan === PLAN.MID) {
            const { startTime, finishTime } = params.data;
            if (isNightShift(startTime, finishTime)) {
              return false;
            }
          }
          return true;
        },
      },
      {
        width: isScheduleView ? width / 7 - 2 : width / 6 - 2,
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "adminUseOnly",
        cellRenderer: "checkboxRenderer",
        valueSetter: defaultBooleanValueSetter,
      },
    ],
    [
      width,
      checkNameIsDuplicate,
      shiftGroupNames,
      taskNames,
      predefinedShiftKeywords,
      reservedShiftKeywords,
      reservedShiftDict,
      isScheduleView,
      plan,
      shiftsWarnings,
      skillOptions,
      skills,
      shortIdsToEntityNamesDicts,
    ]
  );

  return (
    <Layout
      title={isScheduleView ? `Shifts` : `Shifts - ${rosterName}`}
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/shifts-shift-groups"}
        />
      )}
      headerRight={
        isScheduleView
          ? null
          : () => (
              <AddRowButton
                rowName={"Shift"}
                addSingle={() => {
                  if (!isCellEditing) addNewShifts(1);
                }}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addNewShifts(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt(`How many shifts should I add?`, "1")
                      );
                      addNewShifts(num);
                    },
                  },
                ]}
                partialButtonTestId="add-shifts-button"
                postAddAction={() => {
                  manuallyStepThroughTour(0);
                }}
              />
            )
      }
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles.topLine}>
          <div className={styles.left}>{customTopComponent}</div>
          <div className={styles.right}>
            {isScheduleView && (
              <AddRowButton
                rowName={"Shift"}
                addSingle={() => addNewShifts(1)}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addNewShifts(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt(`How many shifts should I add?`, "1")
                      );
                      addNewShifts(num);
                    },
                  },
                ]}
                partialButtonTestId="add-shifts-button"
                postAddAction={() => {
                  manuallyStepThroughTour(0);
                }}
              />
            )}
          </div>
        </div>
        <ActionBar
          searchBarSettings={{
            tableName: "shifts",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateShifts,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeShifts(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
        />
        <DataEntryTable
          columnDefs={columnDefs}
          rowData={shiftsData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderShiftsData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          setGridApiToParent={setGridApiToParent}
          components={{
            checkboxRenderer: Checkbox,
            dropdownMultiSelector: DropdownMultiSelector,
            gridCellWithPlaceholderText: GridCellWithPlaceholderText,
            nightShiftConditionalRenderer: (props) => {
              const shiftData = props.data;
              let shouldShowUpgradeLabel = false;
              if (
                shiftData &&
                plan === PLAN.MID &&
                isNightShift(shiftData.startTime, shiftData.finishTime)
              ) {
                shouldShowUpgradeLabel = true;
              }
              return (
                <ConditionalCellRenderer
                  RendererA={Checkbox}
                  RendererB={NightShiftUpgradeCell}
                  shouldShowB={shouldShowUpgradeLabel}
                  {...props}
                />
              );
            },
          }}
          defaultColDef={{
            editable: (params) => {
              if (Object.keys(reservedShiftDict).includes(params.data.id)) {
                return false;
              } else {
                return true;
              }
            },
            filterParams: { newRowsAction: "keep" },
          }}
          enableRowDragAnimation={!isSaving}
          getRowStyle={(params) =>
            getReservedShiftsRowStyle(params, Object.keys(reservedShiftDict))
          }
          tableName="shifts"
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          setColumnApiToParent={setColumnApiToParent}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
        />
        <p className={styles["name-line"]}>
          <span className={styles.emph}>Number of shifts:</span>
          {` ${shiftsData.length}`}
        </p>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(shiftsWarnings)}
          />
        </div>
      )}
    </Layout>
  );
};

export default ShiftsGrid;
