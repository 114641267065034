import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./UserDropdownItem.module.css";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { PAGE_NAMES, useRedirect } from "../../../utils/routeUtils/redirect";
import { useLocation as useRouteLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom-v5-compat";
import { PLAN } from "../../../features/auth/service/auth";
import { capitalizeFirstLetter } from "../../../utils";

const UserDropdownItem = ({
  signOut,
  name,
  email,
  acronym,
  isMobile,
  isEmployeeView,
  percentageComplete,
  setIsCheckListOpen,
  toggleDropdown,
  isScheduleView,
  plan,
}) => {
  const redirectTo = useRedirect();
  const { pathname } = useRouteLocation();
  const [searchParams] = useSearchParams();

  const getPageName = () => {
    if (pathname === "/billing") {
      const queryParam = searchParams.get("tab");
      if (queryParam === "plan") {
        return "plan";
      }
      if (queryParam === "billing") {
        return "billing";
      }
    }
    return null;
  };

  return (
    <div className={`${styles.wrapper} ${isMobile ? styles.mobile : null}`}>
      <div className={styles.section}>
        <div className={styles.profileWrapper}>
          <div className={styles.profileContainer}>
            <span className={styles.profile}>{acronym}</span>
          </div>
          <div className={styles.userInfoContainer}>
            <p className={styles.name}>{name}</p>
            <p className={styles.email}>{email}</p>
            {!isEmployeeView && (
              <p className={styles.type}>
                {[PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)
                  ? `${capitalizeFirstLetter(plan)} Access`
                  : "Admin Access"}
              </p>
            )}
          </div>
        </div>
      </div>
      {isScheduleView &&
        plan !== PLAN.AI &&
        plan !== PLAN.EMPLOYEE &&
        plan !== PLAN.COORDINATOR &&
        plan !== PLAN.COLLABORATOR && (
          <div className={styles.section}>
            <div
              className={styles.progressWrapper}
              onClick={() => {
                toggleDropdown();
                setIsCheckListOpen(true);
              }}
            >
              <p className={styles.sectionLabel}>
                Set up your RosterLab account
              </p>
              <div className={styles.progressDesc}>
                <span>Progress...</span>
                <span>{Math.round(percentageComplete)}%</span>
              </div>
              <div className={styles.progressBar}>
                <div
                  className={styles.progressBarFill}
                  style={{
                    width: `${percentageComplete}%`,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      <div className={styles.section}>
        <div className={styles.planWrapper}>
          {plan !== PLAN.AI &&
            plan !== PLAN.COORDINATOR &&
            plan !== PLAN.COLLABORATOR &&
            plan !== PLAN.EMPLOYEE &&
            plan !== PLAN.LITE && (
              <>
                <button
                  className={`${styles.redirectButton} ${
                    getPageName() === "billing" && styles.active
                  }`}
                  onClick={() => {
                    redirectTo({
                      pageName: PAGE_NAMES.billing,
                      query: {
                        tab: "billing",
                      },
                    });
                  }}
                >
                  Account & Billing
                </button>
                <button
                  className={`${styles.redirectButton} ${
                    getPageName() === "plan" && styles.active
                  }`}
                  onClick={() => {
                    redirectTo({
                      pageName: PAGE_NAMES.billing,
                      query: {
                        tab: "plan",
                      },
                    });
                  }}
                >
                  Upgrade my plan
                  <FontAwesomeIcon icon={faFire} />
                </button>
              </>
            )}
          <button
            className={styles.redirectButton}
            onClick={() => {
              window.open(
                plan === PLAN.EMPLOYEE
                  ? "https://help.rosterlab.com/using-rosterlab-as-a-team-member"
                  : "https://help.rosterlab.com/"
              );
            }}
          >
            RosterLab Help Guides
          </button>
        </div>
      </div>
      <div className={styles.signOutWrapper}>
        <button onClick={signOut}>Sign out</button>
      </div>
    </div>
  );
};

export default UserDropdownItem;
