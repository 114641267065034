import {
  faBook,
  faCalendar,
  faClock,
  faRobot,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { PAGE_NAMES } from "../../../utils/routeUtils/redirect";
import { containsAllElements, deepCopyObject } from "../../../utils";

/**
 * [Glossary]
 *
 * Section: a 'section' is the collapsible part of the Check list side bar UI.
 * For example, the entire "Set up employees for your roster" part would be a 'section'.
 *
 * Activity: an 'activity' is a single tour.
 * For example, The combination of 'Add Employees' and 'Invite employees to their view rosters' would form an 'activity'
 * because they form a single tour that flows. However, 'Add Rules' is a single activity itself as it is a single tour.
 * You can think of as ~going through an activity to complete task(s)~
 *
 * Task: a 'task' is a todo item in inside the section. A 'task' can be a single tour or a partial tour.
 * For example, 'Add Employees' item is a 'task'.
 */

const ONBOARDING_SECTION_ID = {
  employeeSetup: "section-setup-employees",
  createShifts: "section-create-shifts",
  addRulesAndStaffingNumbers: "section-add-rules-and-staffing-numbers",
  addPreferences: "section-add-preferences",
  scheduleMyRoster: "section-schedule-my-roster",
  useAiGenerate: "section-use-ai-generate",
};

const ONBOARDING_ACTIVITY_ID = {
  employeeSetup: "activity-employees-setup",
  createShifts: "activity-create-shifts",
  addRules: "activity-add-rules",
  addStaffingNumbers: "activity-staffing-numbers",
  addPreferences: "activity-add-preferences",
  addLiveCountStatistics: "activity-add-live-counts-and-statistics",
  publishSchedules: "activity-publish-schedules",
  generateRoster: "activity-generate-roster",
};

export const ONBOARDING_TASK_ID = {
  addEmployees: "task-add-employees",
  inviteEmployees: "task-invite-employees",
  createShifts: "task-create-shifts",
  addRules: "task-add-rules",
  addStaffingNumbers: "task-add-staffing-numbers",
  addPreferences: "task-add-preferences",
  addLiveCountStatistics: "task-add-live-counts-and-statistics",
  publishSchedules: "task-publish-schedules",
  bookDemo: "task-book-demo",
  generateRoster: "task-generate-roster",
};

export function getCompletableOnboardingTaskIDs() {
  const completableOnboardingTaskIDs = deepCopyObject(ONBOARDING_TASK_ID);
  delete completableOnboardingTaskIDs.generateRoster;
  return completableOnboardingTaskIDs;
}

export function getOnboardingSectionIDs() {
  return Object.values(ONBOARDING_SECTION_ID);
}

export function getOnboardingTaskIDs() {
  return Object.values(ONBOARDING_TASK_ID);
}

export function getNextIncompleteOnboardingSection(
  completedOnboardingTasks,
  plan
) {
  let nextSection = null;
  for (const section of getOnboardingSections(plan)) {
    const tasksToBeCompleted = section.tasksToBeCompleted;
    if (!containsAllElements(tasksToBeCompleted, completedOnboardingTasks)) {
      nextSection = section;
      break;
    }
  }

  if (!nextSection) {
    return null;
  }

  return nextSection.id;
}

const baseOnboardingSections = [
  {
    id: ONBOARDING_SECTION_ID.employeeSetup,
    header: {
      title: "Set up employees for your roster",
      fontAwesomeIcon: faUsers,
    },
    bodies: [
      {
        description:
          "People are the key. Let's get started by adding your employees.",
        todos: [
          {
            taskID: ONBOARDING_TASK_ID.addEmployees,
            activityID: ONBOARDING_ACTIVITY_ID.employeeSetup,
            title: "Add Employees",
            pageName: PAGE_NAMES.scheduleViewEmployeesSkills,
            newTab: false,
            tourStartIndex: 0,
          },
          {
            taskID: ONBOARDING_TASK_ID.inviteEmployees,
            activityID: ONBOARDING_ACTIVITY_ID.employeeSetup,
            title:
              "Invite an employee to view their rosters (you can put your own email here to try it out)",
            pageName: PAGE_NAMES.scheduleViewEmployeesSkills,
            newTab: false,
            tourStartIndex: 1,
          },
        ],
        button: {
          activityID: ONBOARDING_ACTIVITY_ID.employeeSetup,
          label: "Add Employees",
          pageName: PAGE_NAMES.scheduleViewEmployeesSkills,
          newTab: false,
          tourStartIndex: 0,
          completedTaskIdOnClick: null,
        },
      },
    ],
    tasksToBeCompleted: [
      ONBOARDING_TASK_ID.addEmployees,
      ONBOARDING_TASK_ID.inviteEmployees,
    ],
  },
  {
    id: ONBOARDING_SECTION_ID.createShifts,
    header: {
      title: "Create shifts for your schedule",
      fontAwesomeIcon: faClock,
    },
    bodies: [
      {
        description:
          "You will need to create some shifts to assign to your employees!",
        todos: [],
        button: {
          activityID: ONBOARDING_ACTIVITY_ID.createShifts,
          label: "Add Shifts",
          pageName: PAGE_NAMES.scheduleViewShifts,
          newTab: false,
          tourStartIndex: 0,
          completedTaskIdOnClick: null,
        },
      },
    ],
    tasksToBeCompleted: [ONBOARDING_TASK_ID.createShifts],
  },
  {
    id: ONBOARDING_SECTION_ID.addRulesAndStaffingNumbers,
    header: {
      title: "Add more rules and staffing demands to shape your schedule",
      fontAwesomeIcon: faBook,
    },
    bodies: [
      {
        description:
          "Rules help guide how each of your employees should be rostered. Staffing requirements specify the demands for shifts, tasks and skills on each day. Let's create some!",
        todos: [
          {
            taskID: ONBOARDING_TASK_ID.addRules,
            activityID: ONBOARDING_ACTIVITY_ID.addRules,
            title: "Add Rules",
            pageName: PAGE_NAMES.scheduleViewRules,
            newTab: false,
            tourStartIndex: 0,
          },
          {
            taskID: ONBOARDING_TASK_ID.addStaffingNumbers,
            activityID: ONBOARDING_ACTIVITY_ID.addStaffingNumbers,
            title: "Add Staffing Requirements",
            pageName: PAGE_NAMES.scheduleViewDemands,
            newTab: false,
            tourStartIndex: 0,
          },
        ],
        button: null,
      },
    ],
    tasksToBeCompleted: [
      ONBOARDING_TASK_ID.addRules,
      ONBOARDING_TASK_ID.addStaffingNumbers,
    ],
  },
  {
    id: ONBOARDING_SECTION_ID.addPreferences,
    header: {
      title: "Add staff preferences",
      fontAwesomeIcon: faUser,
    },
    bodies: [
      {
        description:
          "Here you can add one-off as well as recurring preferences for which shifts/shift groups/days off your employees would like.",
        todos: [],
        button: {
          activityID: ONBOARDING_ACTIVITY_ID.addPreferences,
          label: "Add Preferences",
          pageName: PAGE_NAMES.scheduleViewPreferences,
          newTab: false,
          tourStartIndex: 0,
          completedTaskIdOnClick: null,
        },
      },
    ],
    tasksToBeCompleted: [ONBOARDING_TASK_ID.addPreferences],
  },
  {
    id: ONBOARDING_SECTION_ID.useAiGenerate,
    header: {
      title: "Use AI to generate your schedules",
      fontAwesomeIcon: faRobot,
    },
    bodies: [
      {
        description: (
          <>
            <p>
              <b>RosterLab Premium</b> allows for you to use our rostering AI to
              generate schedules with complex rules and staffing requirements
              automatically. We also provide premium support and onboarding.
              Contact us for a demo today.
            </p>
          </>
        ),
        todos: [],
        button: {
          label: "Book a demo today",
          pageName: "https://meetings.rosterlab.com/meetings/daniel-ge/demo",
          newTab: true,
          tourStartIndex: 0,
          completedTaskIdOnClick: ONBOARDING_TASK_ID.bookDemo,
        },
      },
    ],
    tasksToBeCompleted: [
      ONBOARDING_TASK_ID.generateRoster,
      ONBOARDING_TASK_ID.bookDemo,
    ],
  },
  {
    id: ONBOARDING_SECTION_ID.scheduleMyRoster,
    header: {
      title: "Write your schedule in “My Roster”",
      fontAwesomeIcon: faCalendar,
    },
    bodies: [
      {
        description:
          "This is where you can make manual edits to your roster. The dropdown in each cell contains all the shifts and shift-tasks you have created.",
        todos: [
          {
            taskID: ONBOARDING_TASK_ID.addLiveCountStatistics,
            activityID: ONBOARDING_ACTIVITY_ID.addLiveCountStatistics,
            title: "Add live counts/statistics",
            pageName: PAGE_NAMES.scheduleViewLocation,
            newTab: false,
            tourStartIndex: 0,
          },
          {
            taskID: ONBOARDING_TASK_ID.publishSchedules,
            activityID: ONBOARDING_ACTIVITY_ID.publishSchedules,
            title: "Publish your schedules",
            pageName: PAGE_NAMES.scheduleViewLocation,
            newTab: false,
            tourStartIndex: 0,
          },
        ],
        button: null,
      },
    ],
    tasksToBeCompleted: [
      ONBOARDING_TASK_ID.addLiveCountStatistics,
      ONBOARDING_TASK_ID.publishSchedules,
    ],
  },
];

export const getOnboardingSections = () => {
  return baseOnboardingSections;
};

export const onBoardingActivities = [
  {
    id: ONBOARDING_ACTIVITY_ID.employeeSetup,
    steps: [
      {
        target: "[data-tour=add-employees-button]",
        title: "Add an employee",
        content:
          "You can add a single employee or add multiple employees at once",
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        customData: {
          shouldCompleteOnAction: true,
        },
        hideBackButton: true,
        hideFooter: true,
      },
      {
        target: ".ag-cell-value[col-id=email]",
        title: "Double-click the cell to add emails to people",
        content:
          "Put your email address to test out what employees can see, or invite your employees to the staff mobile app.",
        disableScrolling: true,
        placement: "bottom",
        spotlightClicks: true,
        customData: {
          shouldCompleteOnAction: false,
        },
      },
      {
        target: "[col-id=name][role=gridcell]",
        title: "Select employee(s) and invite them to their own accounts",
        content: (
          <p>
            <b>Click on the checkbox</b> to invite your employees to enable
            their staff view.
          </p>
        ),
        disableScrolling: true,
        placement: "bottom",
        spotlightClicks: true,
        customData: {
          shouldCompleteOnAction: false,
        },
      },
      {
        target: "[data-tour=employee-access-button]",
        title: "Manage Employee access",
        content:
          "You can invite users, resend invitation links, or deactivate users here.",
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        customData: {
          shouldCompleteOnAction: true,
        },
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.createShifts,
    steps: [
      {
        target: "[data-tour=add-shifts-button]",
        title: "Add a shift",
        content:
          "You can create shifts that are assignable to your staff here.",
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        customData: {
          shouldCompleteOnAction: true,
        },
        hideFooter: true,
      },
      {
        target: "[data-testid=shifts-grid]",
        title: "How to use shifts",
        content: (
          <div>
            <p>
              There are some <b>pre-defined shifts</b> that you can use straight
              away.
            </p>
            <br />
            <p>
              For the <b>shift you have just created</b>, double click the cell
              to edit the shift names, and start & finish time.
            </p>
          </div>
        ),
        disableScrolling: true,
        placement: "bottom",
        spotlightClicks: true,
        customData: {
          shouldCompleteOnAction: false,
        },
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.addRules,
    steps: [
      {
        target: "[data-tour=manage-rules-btn]",
        title: "Add a rule",
        content: (
          <div>
            <p>
              Add, edit, and delete rules by clicking the “Manage Rules” button.
              You will see reminders on the &quot;My Roster&quot; table when
              rules that you have set are violated.
            </p>
            <br />
            <p>
              Tip: Rule values can be set individually in the rules table after
              adding a rule.
            </p>
          </div>
        ),
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        customData: {
          shouldCompleteOnAction: true,
        },
        hideFooter: true,
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.addStaffingNumbers,
    steps: [
      {
        target: "[data-tour=add-demands-button]",
        title: "Add a staffing requirement",
        content:
          "Add how many people are needed for each day by shifts, skills, or tasks.",
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        customData: {
          shouldCompleteOnAction: true,
        },
        hideFooter: true,
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.addPreferences,
    steps: [
      {
        locale: {
          last: "OK",
          next: "OK",
        },
        content: (
          <div>
            <p>
              You can use this as a digital notebook for preferred working
              patterns for each person. By adding staff preferences here, you
              will get <b>reminders</b> on the &quot;My Roster&quot; page{" "}
              <b>when preferences are not being granted.</b>
            </p>
            <br />
            <p>
              For example, Sally prefers to work the Morning shift every Monday.
              You can put this information in the recurring patterns table.
            </p>
            <br />
            <p>
              For <b>one-off preference</b>, simply put the request into the
              overview table (against by date).
            </p>
          </div>
        ),
        placement: "center",
        target: "body",
        customData: {
          shouldCompleteOnAction: false,
        },
        hideBackButton: true,
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.addLiveCountStatistics,
    steps: [
      {
        title: "Add dynamic shift/hour counts",
        content: (
          <div>
            <p>
              Click here to add shift or hours counts by employees or by day
              here. You can also add customised shift + skill counts for skill
              mix.
            </p>
            <br />
            <p>
              This can be extremely helpful to figure out if you&apos;re under
              or over-staffed on certain days.
            </p>
          </div>
        ),
        target: "[data-tour=my-roster-grid] .ag-side-button-button",
        customData: {
          shouldCompleteOnAction: true,
        },
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        hideFooter: true,
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.publishSchedules,
    steps: [
      {
        locale: {
          last: "OK",
          next: "OK",
        },
        title: "Publish your final schedule",
        content: (
          <div>
            <p>
              Once you&apos;re happy with your roster, publish it to all your
              staff so they can view their working schedules.
            </p>
            <br />
            <p>
              Don&apos;t forget to invite them to register for the staff app
              first!
            </p>
          </div>
        ),
        target: "[data-tour=publish-schedule-button]",
        customData: {
          shouldCompleteOnAction: false,
        },
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        disableScrolling: true,
        hideBackButton: true,
      },
    ],
  },
  {
    id: ONBOARDING_ACTIVITY_ID.generateRoster,
    steps: [
      {
        target: "[data-tour=generate-roster-nav-btn]",
        title: "Generate a roster",
        content: "Click here to generate a roster",
        spotlightClicks: true,
        disableBeacon: true,
        placement: "bottom",
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        customData: {
          shouldCompleteOnAction: true,
        },
      },
    ],
  },
];
