type EntityType = "Skill" | "Task" | "TaskBlock" | "Shift" | "ShiftGroup";

export function generateShortId(
  type: EntityType,
  entities: { shortId: string }[]
) {
  const typesAndPrefix = [
    { type: "Skill", prefix: "k" },
    { type: "Task", prefix: "t" },
    { type: "TaskBlock", prefix: "b" },
    { type: "Shift", prefix: "s" },
    { type: "ShiftGroup", prefix: "g" },
    { type: "Area", prefix: "a" },
  ];
  const allowedTypes = typesAndPrefix.map(({ type }) => type);

  if (!allowedTypes.includes(type)) {
    throw new Error(`shortId is not supported for ${type} type entities`);
  }

  const prefix = typesAndPrefix.find((info) => info.type === type).prefix;

  const entityShortIds = entities
    .filter((entity) => entity.shortId)
    .map((entity) => entity.shortId);

  // remove prefix
  const entityShortIdNumbers = entityShortIds
    .map((shortId) => Number(shortId.substring(prefix.length)))
    .filter((number) => !isNaN(number));

  const nextId = entityShortIdNumbers.length
    ? Math.max(...entityShortIdNumbers) + 1
    : 1;

  return `${prefix}${nextId}`;
}

export function generateShortIds(
  type: EntityType,
  entities: { shortId: string }[],
  numNewIds: number
) {
  let entitiesCopy = [...entities];
  let shortIdsList = [];

  for (let i = 0; i < numNewIds; i++) {
    let shortId = generateShortId(type, entitiesCopy);
    entitiesCopy.push({ shortId });
    shortIdsList.push(shortId);
  }

  return shortIdsList;
}

export const addShortIdToEntities = (entities: any[]) => {
  return entities.map((entity) => {
    if (!entity.shortId) {
      return {
        ...entity,
        shortId: entity.name,
      };
    }
    return entity;
  });
};
