import { useState } from "react";
import styles from "./InputBox.module.css";

const InputBox = ({ placeholder, customStyle = {}, onChange, value }) => {
  const [firstClick, setFirstClick] = useState(true);

  const getBorderColor = () => {
    if (!firstClick && !value) {
      return "red";
    }
    return null;
  };

  return (
    <input
      onBlur={() => setFirstClick(false)}
      value={value}
      style={{
        ...customStyle,
        ...(getBorderColor() && { borderColor: getBorderColor() }),
      }}
      placeholder={placeholder}
      className={styles.input}
      type="text"
      onChange={onChange}
    />
  );
};

export default InputBox;
