import { DateTime, capitalizeFirstLetter } from "../../../../utils";
import styles from "./LeaveListItem.module.css";

const LeaveListItem = ({
  leaveRequest,
  onViewMore,
  customContainerStyle = {},
}) => {
  const { request: allocation, startDate, finishDate, state } = leaveRequest;
  const leaveLabel = capitalizeFirstLetter(allocation.toLowerCase());
  let stateLabel = "Approved";
  let statusColor = "#10D4C2";
  let statusLabelColor = "black";

  switch (state) {
    case "Pending":
      stateLabel = "Pending for approval";
      statusColor = "#fcf264";
      break;
    case "Denied":
      stateLabel = "Denied";
      statusColor = "red";
      statusLabelColor = "white";
      break;
  }

  let duration = `${new DateTime(startDate).toFormat(
    "simple-dow-day-month"
  )} - ${new DateTime(finishDate).toFormat("simple-dow-day-month")}`;
  if (startDate === finishDate) {
    duration = new DateTime(startDate).toFormat("simple-dow-day-month");
  }

  return (
    <div className={styles.leave} style={customContainerStyle}>
      <p className={styles.leaveTitle}>{leaveLabel}</p>
      <p className={styles.leaveDateRange}>{duration}</p>
      <div className={styles.leaveBottomWrapper}>
        <span
          className={styles.leaveStatus}
          style={{
            backgroundColor: statusColor,
            color: statusLabelColor,
          }}
        >
          {stateLabel}
        </span>
        <button
          className={styles.viewMoreButton}
          onClick={() => onViewMore(leaveRequest)}
        >
          View more
        </button>
      </div>
    </div>
  );
};
export default LeaveListItem;
