import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime, extractDayNumberFromDayString } from "../../../../../utils";
import { SHIFT_VIEW_ANY_LABEL } from "../RosterTableShiftView/GridBuildingUtils";
import ShiftViewWeekSwitcher from "../ShiftViewWeekSwitcher/ShiftViewWeekSwitcher";
import styles from "../RosterTableShiftView/RosterTableShiftView.module.css";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useToggleShow } from "../../../../../hooks/useToggleShow";

const NumWeeksViewSwitcher = ({ numWeeksPerView, updateNumWeeksPerView }) => {
  const {
    isOpen,
    toggleDropdown,
    selectorRef: dropdownTriggerRef,
    selectorTriggerRef: dropdownRef,
  } = useToggleShow();

  const updateView = (value) => {
    updateNumWeeksPerView(value);
    toggleDropdown();
  };

  return (
    <div className={styles.numWeeksViewSwitcher}>
      <button
        className={styles.numWeeksSwitchOpener}
        ref={dropdownTriggerRef}
        onClick={toggleDropdown}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {isOpen && (
        <ul
          ref={dropdownRef}
          className={`${styles.numWeeksViewOptions} ${styles.fullBorder}`}
        >
          <li
            className={`${
              numWeeksPerView === 1 && styles.selectedNumWeeksView
            } ${styles.numWeeksViewOption}`}
          >
            <button onClick={() => updateView(1)}>Weekly</button>
          </li>
          <li
            className={`${
              numWeeksPerView === 2 && styles.selectedNumWeeksView
            } ${styles.numWeeksViewOption}`}
          >
            <button onClick={() => updateView(2)}>2-weekly</button>
          </li>
        </ul>
      )}
    </div>
  );
};

const ShiftViewGridHeader = ({
  areas,
  taskBlocks,
  columnData,
  daysArray,
  startDate,
  isSingleColumn,
  switchNumIntervalInView,
  numIntervalInView,
  numWeeksPerView,
  updateNumWeeksPerView,
  minBlockWidth,
  minFullColumnWidth,
  totalNumWeeks,
}) => {
  return (
    <div
      className={`${styles.header} ${styles.borderBottom} ${styles.boldText}`}
    >
      <div
        className={`${styles.fixedBlockWidth} ${styles.fixedBlock} ${styles.borderRight} ${styles.whiteBackground} ${styles.fixedHeaderLeftCorner}`}
      >
        <div
          className={`${styles.fixedHeaderLeftCornerTopRow} ${styles.centerContent}`}
        >
          <NumWeeksViewSwitcher
            numWeeksPerView={numWeeksPerView}
            updateNumWeeksPerView={updateNumWeeksPerView}
          />
          <ShiftViewWeekSwitcher
            switchNumIntervalInView={switchNumIntervalInView}
            numIntervalInView={numIntervalInView}
            numWeeksPerView={numWeeksPerView}
            totalNumWeeks={totalNumWeeks}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
          className={`${styles.borderTop}`}
        >
          {areas.length > 0 && (
            <div
              className={`${styles.borderRight} ${styles.rowMinHeight} ${styles.centerContent}`}
              style={{
                width: "150px",
                height: "100%",
                textAlign: "center",
              }}
            >
              Areas
            </div>
          )}
          <div
            className={`${styles.rowMinHeight} ${styles.centerContent}`}
            style={{ width: "150px", height: "100%", textAlign: "center" }}
          >
            Allocation
          </div>
        </div>
      </div>
      {daysArray.map((day) => {
        const dayNumber = extractDayNumberFromDayString(day);
        const date = new DateTime(startDate).addDays(dayNumber - 1);
        const readableDate = date.toFormat("day-month-readable");
        const dow = date.toFormat("DOW");

        const columnDataInTaskBlockNameForm = columnData.map((columnName) => {
          const targetTaskBlock = taskBlocks.find(
            ({ shortId }) => shortId === columnName
          );
          if (targetTaskBlock) {
            return targetTaskBlock.name;
          }
          return columnName;
        });

        return (
          <div
            key={day}
            style={{
              minWidth: `${minFullColumnWidth}px`,
            }}
          >
            <div
              className={`${styles.centerContent} ${styles.fillParentWidth} ${styles.rowMinHeight} ${styles.borderRight}`}
            >
              {readableDate}
            </div>
            <div
              className={`${styles.centerContent} ${styles.fillParentWidth} ${styles.borderRight} ${styles.rowMinHeight}`}
            >
              {dow}
            </div>
            {isSingleColumn ? (
              <div
                className={`${styles.rowMinHeight} ${styles.fillParentWidth} ${styles.blockColumnsContainer} ${styles.borderRight}`}
              ></div>
            ) : (
              <div
                className={`${styles.fillParentWidth} ${styles.blockColumnsContainer} ${styles.borderTop}`}
              >
                {columnDataInTaskBlockNameForm
                  .filter((column) => column !== SHIFT_VIEW_ANY_LABEL) // no need to display "any" column header
                  .map((column, blockColIdx) => (
                    <div
                      className={`${styles.blockColumnWidth} ${styles.centerContent} ${styles.borderRight} ${styles.rowMinHeight} ${styles.blockName}`}
                      key={`${column}-${blockColIdx}`}
                      style={{
                        minWidth: `${minBlockWidth}px`,
                      }}
                    >
                      <span
                        className={`${styles.blockNameLabel} ${styles.centerText}`}
                      >
                        {column}
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShiftViewGridHeader;
