import { useCallback, useEffect, useMemo, useState } from "react";
import { TABLE_NAMES } from "../../../../../constants";
import {
  deepCopyObject,
  getAllocationOptions,
  getAllRosterDataFromGrid,
  getDayColumns,
  getFullEmployeeAllocationsSummary,
  getScheduleRosterDataFromGrid,
  inferredShiftDemandsToShiftDemandsInfo,
  parseEmployeeModelToRosterGridFormat,
} from "../../../../../utils";
import RosterGrid from "../../../rosteredAllocations/components/RosterGrid/RosterGrid";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
} from "../../../service/rosterUtils";
import styles from "./IndRosterContainer.module.css";
import { getInvalidCellsFromRules } from "../../../../rules/service/invalidCellsGetter";

const IndRosterContainer = ({
  rosterID,
  locationID,
  isSaving,
  selectedEmployee,
  prevEmployees,
  startDate,
  numDays,
  areas,
  skills,
  tasks,
  taskBlocks,
  subTasks,
  demands,
  rules,
  shifts,
  shiftNames,
  shiftGroups,
  refreshShiftGroups,
  refreshRosterStatistics,
  colorCodes,
  isMainStream,
  taskNames,
  updateMyRoster,
  customKeywordsUtilObj,
  location,
  areaFilter,
  displayAreaInAllocationCell,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [invalidCellListWithReasons, setInvalidCellListWithReasons] = useState(
    []
  );

  const { annualLeaveKeyword, predefinedShiftOptions, reservedShiftKeywords } =
    customKeywordsUtilObj;

  const getDataFromGrid = (gridApi) => {
    const rosterColumns = getDayColumns(numDays);
    const newEmployee = isMainStream
      ? getScheduleRosterDataFromGrid(
          gridApi,
          rosterColumns,
          deepCopyObject(prevEmployees),
          startDate,
          numDays,
          annualLeaveKeyword
        )[0]
      : getAllRosterDataFromGrid(gridApi, rosterColumns)[0];
    return newEmployee;
  };

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const namesToEntityShortIdsDicts = useMemo(
    () =>
      buildNamesToEntityShortIdsDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const employeesData = useMemo(() => {
    return parseEmployeeModelToRosterGridFormat(
      [selectedEmployee],
      isMainStream
    );
  }, [selectedEmployee, isMainStream]);

  const shiftDemands = useMemo(() => {
    return inferredShiftDemandsToShiftDemandsInfo(
      shifts,
      shiftGroups,
      skills,
      demands,
      numDays,
      startDate,
      location.startDate,
      location.defaultNumDays
    );
  }, [
    shifts,
    shiftGroups,
    skills,
    demands,
    numDays,
    startDate,
    location.startDate,
    location.defaultNumDays,
  ]);

  const allocationOptions = useMemo(() => {
    return getAllocationOptions(
      areas,
      shifts,
      taskBlocks,
      tasks,
      shiftGroups,
      predefinedShiftOptions,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
      areaFilter
    );
  }, [
    areas,
    shifts,
    shiftGroups,
    taskBlocks,
    tasks,
    predefinedShiftOptions,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
    areaFilter,
  ]);

  const preferencesInfo = useMemo(
    () =>
      getFullEmployeeAllocationsSummary(
        [selectedEmployee],
        TABLE_NAMES.ROSTER_PREFERENCES,
        location.startDate,
        startDate,
        location.defaultNumDays
      ),
    [selectedEmployee, location, startDate]
  );

  const fixedShiftsInfo = useMemo(
    () =>
      getFullEmployeeAllocationsSummary(
        [selectedEmployee],
        TABLE_NAMES.ROSTER_FIXED_SHIFTS,
        location.startDate,
        startDate,
        location.defaultNumDays
      ),
    [selectedEmployee, location, startDate]
  );

  const setInvalidCellLists = useCallback(() => {
    let invalidCells = [];
    if (gridApi) {
      invalidCells = getInvalidCellsFromRules(
        rules,
        areas,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        [selectedEmployee],
        numDays,
        gridApi,
        predefinedShiftOptions,
        customKeywordsUtilObj,
        shortIdsToEntityNamesDicts
      );
    }
    setInvalidCellListWithReasons(invalidCells);
  }, [
    gridApi,
    rules,
    areas,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    selectedEmployee,
    numDays,
    predefinedShiftOptions,
    customKeywordsUtilObj,
    shortIdsToEntityNamesDicts,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setInvalidCellLists();
    }, 200);
  }, [setInvalidCellLists]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>My Roster</h1>
      <RosterGrid
        numDays={numDays}
        employeesData={employeesData}
        updateData={updateMyRoster}
        setGridApiToParent={setGridApi}
        context={{
          areas,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          demands,
          taskNames,
          refreshShiftGroups,
          refreshRosterStatistics,
          rosterID,
          invalidCellListWithReasons,
          shiftDemands,
          preferencesInfo,
          fixedShiftsInfo,
          startDate,
          locationID,
        }}
        setColumnApiToParent={() => {}}
        areas={areas}
        skills={skills}
        shifts={shifts}
        tasks={tasks}
        taskBlocks={taskBlocks}
        subTasks={subTasks}
        startDate={startDate}
        invalidCellListWithReasons={invalidCellListWithReasons}
        preferencesInfo={preferencesInfo}
        fixedShiftsInfo={fixedShiftsInfo}
        shiftGroups={shiftGroups}
        shiftNames={shiftNames}
        weekendColWidth={100}
        weekdayColWidth={100}
        allocationOptions={allocationOptions}
        getContextMenuItems={() => {}}
        includeEmployeesInfoColumnDefs={false}
        showStatusBar={false}
        showSideBar={false}
        customGridHeight={`140px`}
        customGridMinHeight={`140px`}
        predefinedShiftOptions={predefinedShiftOptions}
        colorCodes={colorCodes}
        annualLeaveKeyword={annualLeaveKeyword}
        reservedShiftKeywords={reservedShiftKeywords}
        getDataFromGrid={getDataFromGrid}
        isMainStream={isMainStream}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
        customKeywordsUtilObj={customKeywordsUtilObj}
        displayAreaInAllocationCell={displayAreaInAllocationCell}
      />
      <p className={styles["saving-line"]}>
        {isSaving ? "saving..." : "saved"}
      </p>
    </div>
  );
};

export default IndRosterContainer;
