import create from "zustand";
import { calculateWarnings } from "../features/warnings/service/validators/warningsCalculator";
import { ALL_WARNING_FIELDS } from "../constants/warnings";

export const useWarningsStore = create((set, get) => ({
  warnings: null,
  updateWarnings: (rosterInView, fieldsToCalculate, customKeywords) => {
    const warnings = calculateWarnings(
      rosterInView,
      fieldsToCalculate,
      customKeywords,
      get().warnings
    );
    set({ warnings });
  },
  updateFullWarnings: (rosterInView, customKeywords) => {
    const warnings = calculateWarnings(
      rosterInView,
      ALL_WARNING_FIELDS,
      customKeywords,
      get().warnings
    );
    set({ warnings });
  },
}));
