import {
  nextMonday as nextMondayExtPkg,
  nextTuesday as nextTuesdayExtPkg,
  nextWednesday as nextWednesdayExtPkg,
  nextThursday as nextThursdayExtPkg,
  nextFriday as nextFridayExtPkg,
  nextSaturday as nextSaturdayExtPkg,
  nextSunday as nextSundayExtPkg,
  addDays as addDaysExtPkg,
  isSameDay as isSameDayExtPkg,
  differenceInDays as differenceInDaysExtPkg,
  isWeekend as isWeekendExtPkg,
  getDay as getDayExtPkg,
  format as formatExtPkg,
  previousMonday as previousMondayExtPkg,
  differenceInHours,
  nextMonday,
  isMonday,
  addDays,
  getMonth,
  differenceInMinutes,
  parse,
  getYear,
  getDaysInMonth,
  differenceInMonths,
} from "date-fns";
import moment from "moment";
import { isDateStringRegex } from "../generalUtils/regex";
import { isString } from "../generalUtils/string";
import "moment/locale/en-gb";

export class DateTime {
  /**
   * @param {*} date - accepts all possible JavaScript Date constructor argument and DateTime object
   * @param {*} shouldSetCurrentTime - if true, preserve time information in the argument
   */
  constructor(date, shouldSetCurrentTime = false) {
    if (date instanceof DateTime) {
      this.date = new Date(date.date);
    } else if (isString(date) && isDateStringRegex(date)) {
      this.date = DateTime.createDateWithoutTimeZone(date);
    } else {
      this.date = new Date(date);
    }

    if (!date) {
      this.date = new Date();
    }

    if (!shouldSetCurrentTime) {
      this.setTimeToMidNight();
    }
  }

  static MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  static getMonthIndex(monthname) {
    const monthIndex = DateTime.MONTH_NAMES.findIndex(
      (month) => month === monthname
    );
    return monthIndex;
  }

  static getNextMonth(monthName) {
    if (monthName === "December") {
      return "January";
    }

    const monthIndex = DateTime.MONTH_NAMES.findIndex(
      (month) => month === monthName
    );
    return DateTime.MONTH_NAMES[monthIndex + 1];
  }

  static getMonthFromDate = (date) => {
    const jsDate = new DateTime(date).getDate();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[jsDate.getMonth()];
    return monthName;
  };

  static getYearFromDate = (date) => {
    const jsDate = new DateTime(date).getDate();
    return jsDate.getFullYear();
  };

  static numToDoWString = (num) => {
    switch (num) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        throw new Error("Please enter integer between 0 - 6");
    }
  };

  static getNearstMonday(date) {
    const dateTime = new DateTime(date);
    if (isMonday(dateTime.date)) {
      return dateTime;
    }
    return new DateTime(nextMonday(dateTime.date));
  }

  static createDateWithoutTimeZone(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  static addDaysToDate(baseDate, offset) {
    const date = new DateTime(baseDate, true);
    return new DateTime(addDaysExtPkg(date.date, offset));
  }

  static getDifferenceInDays(lowerBoundDate, upperBoundDate) {
    const minDate = new DateTime(lowerBoundDate, false);
    const maxDate = new DateTime(upperBoundDate, false);
    return differenceInDaysExtPkg(maxDate.date, minDate.date);
  }

  static getDifferenceInMonths(lowerBoundDate, upperBoundDate) {
    const minDate = new DateTime(lowerBoundDate, false);
    const maxDate = new DateTime(upperBoundDate, false);
    return differenceInMonths(maxDate.date, minDate.date);
  }

  static getAllDatesBetween(
    lowerBoundDate,
    upperBoundDate,
    isMinDateInclusive = true,
    isMaxDateInclusive = true
  ) {
    const minDate = new DateTime(lowerBoundDate, false);
    const maxDate = new DateTime(upperBoundDate, false);
    const jsMinDateObj = minDate.date;
    const jsMaxDateObj = maxDate.date;
    const datesBetween = [];

    if (jsMinDateObj > jsMaxDateObj) {
      return datesBetween;
    }
    for (
      let date = jsMinDateObj;
      date < addDaysExtPkg(jsMaxDateObj, 1);
      date = addDaysExtPkg(date, 1)
    ) {
      datesBetween.push(new DateTime(date, false));
    }

    if (!isMaxDateInclusive) {
      if (isSameDayExtPkg(jsMinDateObj, datesBetween[0].date)) {
        datesBetween.pop();
      }
    }

    if (datesBetween.length !== 0 && !isMinDateInclusive) {
      if (isSameDayExtPkg(jsMinDateObj, datesBetween[0].date)) {
        datesBetween.splice(0, 1);
      }
    }

    return datesBetween;
  }

  static getAllDateStringsBetween(
    lowerBoundDate,
    upperBoundDate,
    isMinDateInclusive = true,
    isMaxDateInclusive = true
  ) {
    return DateTime.getAllDatesBetween(
      lowerBoundDate,
      upperBoundDate,
      isMinDateInclusive,
      isMaxDateInclusive
    ).map((date) => date.toFormat("AWS"));
  }

  static isStartAndFinishDateOrderValid(startDate, finishDate) {
    const convertedStartDate = new DateTime(startDate, false);
    const convertedFinishDate = new DateTime(finishDate, false);

    if (convertedStartDate.date > convertedFinishDate.date) {
      return false;
    }
    return true;
  }

  static isOverlapingDateRangePair(
    startDate1,
    finishDate1,
    startDate2,
    finishDate2
  ) {
    const convertedStartDate1 = new DateTime(startDate1, false);
    const convertedFinishDate1 = new DateTime(finishDate1, false);
    const convertedStartDate2 = new DateTime(startDate2, false);
    const convertedFinishDate2 = new DateTime(finishDate2, false);

    if (
      convertedStartDate1.date <= convertedStartDate2.date &&
      convertedStartDate2.date <= convertedFinishDate1.date
    ) {
      return true;
    }
    if (
      convertedStartDate1.date <= convertedFinishDate2.date &&
      convertedFinishDate2.date <= convertedFinishDate1.date
    ) {
      return true;
    }
    if (
      convertedStartDate2.date < convertedStartDate1.date &&
      convertedFinishDate1.date < convertedFinishDate2.date
    ) {
      return true;
    }
    return false;
  }

  static isValidDateString(dateString) {
    return !isNaN(Date.parse(dateString));
  }

  /**
   * @param {*} dateRanges [{from: date, to: date}]
   */
  static getOverlappingDateRanges(dateRanges) {
    const convertedDateRages = dateRanges.map((range, idx) => {
      return {
        ...range,
        index: idx,
        from: new DateTime(range.from, false),
        to: new DateTime(range.to, false),
      };
    });
    const overlappingRanges = [];

    for (let i = 0; i < dateRanges.length; i++) {
      for (let j = 0; j < dateRanges.length; j++) {
        if (i !== j) {
          const isOverlapping = DateTime.isOverlapingDateRangePair(
            convertedDateRages[i].from.date,
            convertedDateRages[i].to.date,
            convertedDateRages[j].from.date,
            convertedDateRages[j].to.date
          );
          if (isOverlapping) {
            overlappingRanges.push(convertedDateRages[i]);
            overlappingRanges.push(convertedDateRages[j]);
          }
        }
      }
    }

    const uniqueOverlappingRangesByID = [
      ...new Map(
        overlappingRanges.map((range) => [range.index, range])
      ).values(),
    ];

    const resultingOverlappingRanges = uniqueOverlappingRangesByID.map(
      (range) => {
        delete range.index;
        return range;
      }
    );

    return resultingOverlappingRanges;
  }

  static getOverlappingDatesGivenTwoRangesInfo(
    startDateOne,
    finishDateOne,
    startDateTwo,
    finishDateTwo
  ) {
    const startDateOneObj = new DateTime(startDateOne);
    const finishDateOneObj = new DateTime(finishDateOne);
    const startDateTwoObj = new DateTime(startDateTwo);
    const finishDateTwoObj = new DateTime(finishDateTwo);

    const overlapStart = startDateOneObj.isAfter(startDateTwoObj)
      ? startDateOneObj
      : startDateTwoObj;
    const overlapFinish = finishDateOneObj.isBefore(finishDateTwoObj)
      ? finishDateOneObj
      : finishDateTwoObj;

    const overlappingDates = DateTime.getAllDateStringsBetween(
      overlapStart,
      overlapFinish
    );

    return overlappingDates;
  }

  static getTimeDiffConsecutiveShifts = (
    shift1StartTimeStr,
    shift1EndTimeStr,
    shift2StartTimeStr
  ) => {
    var shift1StartTime = moment(shift1StartTimeStr, [
      "HH:mm:ss.sss",
      "HH:mm:ss",
      "h:mm a",
      "hh:mm a",
    ]);
    var shift1EndTime = moment(shift1EndTimeStr, [
      "HH:mm:ss.sss",
      "HH:mm:ss",
      "h:mm a",
      "hh:mm a",
    ]);
    var shift2StartTime = moment(shift2StartTimeStr, [
      "HH:mm:ss.sss",
      "HH:mm:ss",
      "h:mm a",
      "hh:mm a",
    ]);

    var duration = moment.duration(shift2StartTime.diff(shift1EndTime));
    var numHours = duration.asHours();
    if (shift1StartTime > shift1EndTime) {
      //It's a night shift
      return numHours;
    } else {
      //It's a day shift
      return numHours + 24;
    }
  };

  static getTimeDiff(
    startTimeStr,
    finishTimeStr,
    isRounded = true,
    isNonNegative = true
  ) {
    var timeStart = moment(startTimeStr, [
      "HH:mm:ss.sss",
      "HH:mm:ss",
      "h:mm a",
      "hh:mm a",
    ]);
    var timeEnd = moment(finishTimeStr, [
      "HH:mm:ss.sss",
      "HH:mm:ss",
      "h:mm a",
      "hh:mm a",
    ]);
    var duration = moment.duration(timeEnd.diff(timeStart));
    var numHours = duration.asHours();

    if (isNonNegative && numHours < 0) {
      numHours = 24 + numHours;
    }

    if (isRounded) {
      return parseInt(numHours);
    }

    return numHours;
  }

  // Converts HH:MM:... format to HH.MM comparable number format
  // For example, 12:30 -> 12.3 & 06:15 -> 6.15
  static convertTimeToNumberRepresentation(timeString) {
    return Number(timeString.slice(0, 5).replace(":", "."));
  }

  // For example, converts a number such as 12.30 to a time string withe a specific format
  static convertFloatToTime(floatTimeString, format) {
    const HH_mm = floatTimeString.replace(".", ":");
    return DateTime.getFormattedTime(HH_mm, format);
  }

  static timeCache = new Map();

  static getFormattedTime(timeString, format, bypassCache = false) {
    // Create a unique key for each combination of timeString and format
    const cacheKey = `${timeString}|${format}`;

    // Check if the result is already cached
    if (this.timeCache.has(cacheKey) && !bypassCache) {
      return this.timeCache.get(cacheKey); // Return the cached result
    }

    // If not cached, compute the result
    const newTime = moment(timeString, [
      "h:mm a",
      "hh:mm a",
      "H:mm",
      "HH:mm",
      "h a",
      "H",
      "HH:mm:ss.sss",
    ]);

    let formattedTime;
    switch (format) {
      case "AWS":
        formattedTime = newTime.format("HH:mm:ss.sss");
        break;
      case "readable":
        formattedTime = newTime.format("h:mma");
        break;
      case "basic":
        formattedTime = newTime.format("HH:mm");
        break;
      case "12hr":
        formattedTime = newTime.format("h:mm A");
        break;
      default:
        formattedTime = newTime.format("HH:mm:ss.sss");
    }

    // Cache the newly computed result
    this.timeCache.set(cacheKey, formattedTime);

    return formattedTime;
  }

  static getFormattedDate(date, format) {
    const convertedDate = new DateTime(date, false);
    switch (format) {
      case "AWS": {
        return formatExtPkg(convertedDate.date, "yyyy-MM-dd");
      }
      case "displayed-full": {
        return formatExtPkg(convertedDate.date, "dd/MM/yyyy");
      }
      case "displayed-mid": {
        return formatExtPkg(convertedDate.date, "dd/MM/yy");
      }
      case "displayed-short": {
        return formatExtPkg(convertedDate.date, "dd/MM");
      }
      case "multi-line": {
        return formatExtPkg(convertedDate.date, "E \n dd \n MMM");
      }
      case "weekday": {
        return formatExtPkg(convertedDate.date, "EEEE");
      }
      case "readable": {
        return formatExtPkg(convertedDate.date, "EEEE, MMMM d");
      }
      case "readable-month-last": {
        return formatExtPkg(convertedDate.date, "EEEE, d MMMM");
      }
      case "simple": {
        return formatExtPkg(convertedDate.date, "E d/M");
      }
      case "DOW": {
        return formatExtPkg(convertedDate.date, "E");
      }
      case "simple-dow-day-month": {
        return formatExtPkg(convertedDate.date, "E, d MMM");
      }
      case "day-month-readable": {
        return formatExtPkg(convertedDate.date, "dd MMM");
      }
      case "day-month-year-readable": {
        return formatExtPkg(convertedDate.date, "dd MMM yyyy");
      }
      case "month-year-readable": {
        return formatExtPkg(convertedDate.date, "MMM yyyy");
      }
      case "full-month-year-readable": {
        return formatExtPkg(convertedDate.date, "MMMM yyyy");
      }
      case "DOW day": {
        return formatExtPkg(convertedDate.date, "E dd");
      }
      case "dd": {
        return formatExtPkg(convertedDate.date, "dd");
      }
      case "longDate": {
        return formatExtPkg(convertedDate.date, "E d MMMM, yyyy");
      }
      case "version-date": {
        return formatExtPkg(convertedDate.date, "dd.MMM.yyyy");
      }
      case "dd/MMM/yyyy - E": {
        return formatExtPkg(convertedDate.date, "dd/MMM/yyyy - E");
      }
      default: {
        throw new Error("Unsupported date format");
      }
    }
  }

  // This function might can be replaced with a better one?
  static displayedDateToISO(displayedDateStr) {
    const date = parse(displayedDateStr, "dd/MM/yyyy", new Date());
    let formattedDate = "";
    try {
      formattedDate = formatExtPkg(date, "yyyy-MM-dd");
    } catch (e) {
      formattedDate = new DateTime().toFormat("AWS");
    }
    return formattedDate;
  }

  static getNextMonday(date) {
    let dateObj;
    if (!date) {
      dateObj = new DateTime(null, false);
    } else {
      dateObj = new DateTime(date, false);
    }

    return new DateTime(nextMondayExtPkg(dateObj.date));
  }

  static getFirstMondayOfWeekContainingDate(date) {
    const dateObj = new DateTime(date, false);
    const dow = dateObj.getDayOfWeek("string");
    if (dow === "Monday") {
      return new DateTime(date);
    }
    if (dow !== "Monday") {
      return DateTime.getPreviousMonday(dateObj);
    }
  }

  static getPreviousMonday(date) {
    let dateObj;
    if (!date) {
      dateObj = new DateTime(null, false);
    } else {
      dateObj = new DateTime(date, false);
    }
    return previousMondayExtPkg(dateObj.date);
  }

  static isSameDay(date1, date2) {
    const convertedDate1 = new DateTime(date1, false);
    const convertedDate2 = new DateTime(date2, false);
    return isSameDayExtPkg(convertedDate1.date, convertedDate2.date);
  }

  static getTimeDifferenceInHours(timeBefore, timeAfter) {
    const time1 = DateTime.getFormattedTime(timeBefore, "basic");
    const time2 = DateTime.getFormattedTime(timeAfter, "basic");

    const time1Info1 = time1.split(":");
    const time1Info2 = time2.split(":");

    const date1 = new Date("2022/1/1");
    date1.setHours(Number(time1Info1[0]), Number(time1Info1[1]), 0);
    let date2 = new Date("2022/1/1");
    date2.setHours(Number(time1Info2[0]), Number(time1Info2[1]), 0);

    if (date2 <= date1) {
      date2 = addDays(date2, 1);
    }

    const diff = differenceInHours(date2, date1);
    return diff;
  }

  static getTimeDifferenceInMinutes(timeBefore, timeAfter) {
    const time1 = DateTime.getFormattedTime(timeBefore, "basic");
    const time2 = DateTime.getFormattedTime(timeAfter, "basic");

    const time1Info1 = time1.split(":");
    const time1Info2 = time2.split(":");

    const date1 = new Date("2022/1/1");
    date1.setHours(Number(time1Info1[0]), Number(time1Info1[1]), 0);
    let date2 = new Date("2022/1/1");
    date2.setHours(Number(time1Info2[0]), Number(time1Info2[1]), 0);

    if (date2 < date1) {
      date2 = addDays(date2, 1);
    }

    const diff = differenceInMinutes(date2, date1);
    return diff;
  }

  static convertMinutesIntegerToHoursFloat(minutes) {
    return minutes / 60;
  }

  static getUserLocale() {
    return window.navigator.userLanguage || window.navigator.language;
  }

  static convertLocaleToISO(dateStr) {
    const userLocale = DateTime.getUserLocale();
    const gb = ["en-gb", "en-GB", "en-nz", "en-NZ", "en-SG", "en-SG", "en-AU"];
    let locale;
    if (gb.includes(userLocale)) {
      locale = "en-GB";
    } else {
      locale = "en";
    }
    moment.locale(locale);
    const localeData = moment.localeData();
    const format = localeData.longDateFormat("L");
    return moment(dateStr, [format, "YYYY-MM-DD"]).format("YYYY-MM-DD");
  }

  static groupConsecutiveDateStrings(dateArray) {
    if (dateArray.length === 0) {
      return [];
    }

    const sortedDates = dateArray.sort(); // Sort the dates in ascending order
    const groups = [[]]; // Initialize an array of groups, starting with an empty group

    for (let i = 0; i < sortedDates.length; i++) {
      const currentDate = new DateTime(sortedDates[i]).getDate();
      const previousDate = new DateTime(
        groups[groups.length - 1][groups[groups.length - 1].length - 1]
      ).getDate();

      if (currentDate - previousDate === 86400000 || i === 0) {
        // Dates are consecutive or it's the first date in the array
        groups[groups.length - 1].push(sortedDates[i]);
      } else {
        // Dates are not consecutive, start a new group
        groups.push([sortedDates[i]]);
      }
    }

    return groups;
  }

  static isDateString(str) {
    // Regular expression to match date string in YYYY-MM-DD format
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateRegex.test(str)) {
      const date = new Date(str);
      if (isNaN(date.getTime())) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  static getLatestDateFromDateArr(dates) {
    let latestDate = null;
    for (const index in dates) {
      const date = new DateTime(dates[index]);
      if (index === 0) {
        latestDate = date;
        continue;
      }

      if (date.isAfter(latestDate)) {
        latestDate = date;
      }
    }

    return latestDate;
  }

  static convertDateNumberToDateTime(dateNumber) {
    const date = new Date(dateNumber * 1000);
    return new DateTime(date);
  }

  static getTimeStringOfDate(date) {
    // Create a new Date object
    let base = new Date();
    if (date) {
      base = new DateTime(date, true).getDate();
    }

    // Convert milliseconds to human-readable format
    let hours = base.getHours();
    let minutes = base.getMinutes();
    let seconds = base.getSeconds();

    // Add leading zeros if needed
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    const currentTimeString = hours + ":" + minutes + ":" + seconds;
    return currentTimeString;
  }

  static getTimeFormatter(date) {
    const dateObj = new DateTime(date, true).date;
    const hours = ("0" + (dateObj.getHours() % 12 || 12)).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);
    const seconds = ("0" + dateObj.getSeconds()).slice(-2);
    const period = dateObj.getHours() >= 12 ? "pm" : "am";
    return `${hours}:${minutes}:${seconds}${period}`;
  }

  static getCurrentTimeFormatted() {
    const date = new Date();
    const hours = ("0" + (date.getHours() % 12 || 12)).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const period = date.getHours() >= 12 ? "pm" : "am";
    return `${hours}:${minutes}:${seconds}${period}`;
  }

  static checkTodayIsIncludedInPeriod(startDate, numDays) {
    const finishDate = new DateTime(startDate)
      .addDays(numDays - 1)
      .toFormat("AWS");

    return new DateTime().isDateBetweenTwoDates(
      startDate,
      finishDate,
      true,
      true
    );
  }

  static getFirstDateOfCurrentMonth() {
    const today = new Date();
    const date = new Date(today.getFullYear(), today.getMonth(), 1);
    return new DateTime(date);
  }

  static getLastDateOfCurrentMonth() {
    const today = new Date();
    const date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return new DateTime(date);
  }

  getDaysInMonth() {
    return getDaysInMonth(this.date);
  }

  getDate() {
    return this.date;
  }

  getDay(offset = 0) {
    let dateAfter = new DateTime(this.date);
    dateAfter.date.setDate(dateAfter.date.getDate() + offset);
    return moment(dateAfter.date).format("D");
  }

  addDays(offset) {
    this.date.setDate(this.date.getDate() + offset);
    return this;
  }

  subtractDays(offset) {
    this.date.setDate(this.date.getDate() - offset);
    return this;
  }

  getDateNum() {
    return this.date.getDate();
  }

  getMonth() {
    const monthNum = getMonth(this.date);
    return DateTime.MONTH_NAMES[monthNum];
  }

  getYear() {
    return getYear(this.date);
  }

  addMonths(offset) {
    this.date.setMonth(this.date.getMonth() + offset);
    return this;
  }

  subtractMonths(offset) {
    this.date.setMonth(this.date.getMonth() - offset);
    return this;
  }

  getStartDateOfMonth() {
    const year = this.date.getFullYear();
    const month = this.date.getMonth();
    const startDateOfMonth = new Date(year, month, 1);
    return new DateTime(startDateOfMonth, false);
  }

  getLastDateOfMonth() {
    const year = this.date.getFullYear();
    const month = this.date.getMonth();
    const lastDateOfMonth = new Date(year, month + 1, 0);
    return new DateTime(lastDateOfMonth, false);
  }

  setTimeToMidNight() {
    this.date = new Date(this.date.setHours(0, 0, 0, 0));
  }

  isDateBetweenTwoDates(
    lowerBoundDate,
    upperBoundDate,
    isMinDateInclusive,
    isMaxDateInclusive
  ) {
    const minDate = new DateTime(lowerBoundDate, false);
    const maxDate = new DateTime(upperBoundDate, false);

    if (isMinDateInclusive && isMaxDateInclusive) {
      if (this.date >= minDate.date && this.date <= maxDate.date) {
        return true;
      }
      return false;
    }
    if (isMinDateInclusive) {
      if (this.date >= minDate.date && this.date < maxDate.date) {
        return true;
      }
      return false;
    }
    if (isMaxDateInclusive) {
      if (this.date > minDate.date && this.date <= maxDate.date) {
        return true;
      }
      return false;
    }
    if (this.date > minDate.date && this.date < maxDate.date) {
      return true;
    }
    return false;
  }

  isWeekend() {
    return isWeekendExtPkg(this.date);
  }

  getDayOfWeek(format) {
    const dowNum = getDayExtPkg(this.date);
    const dowString = DateTime.numToDoWString(dowNum);

    switch (format) {
      case "dd": {
        return dowString.slice(0, 2);
      }
      case "ddd": {
        return dowString.slice(0, 3);
      }
      case "number": {
        return dowNum;
      }
      case "numberWithMondayAs0": {
        const dowMap = [6, 0, 1, 2, 3, 4, 5];
        return dowMap[dowNum];
      }
      case "string": {
        return dowString;
      }
      default: {
        return dowNum;
      }
    }
  }

  toFormat(format) {
    return DateTime.getFormattedDate(this.date, format);
  }

  getTime() {
    return this.date.getTime();
  }

  isAfter(date) {
    const convertedDate = new DateTime(date, false);
    return this.date > convertedDate.date;
  }

  isBefore(date) {
    const convertedDate = new DateTime(date, false);
    return this.date < convertedDate.date;
  }

  isSameOrAfter(date) {
    const convertedDate = new DateTime(date, false);
    return this.date >= convertedDate.date;
  }

  isSameOrBefore(date) {
    const convertedDate = new DateTime(date, false);
    return this.date <= convertedDate.date;
  }

  // dow in string: "Monday", ...
  getDateWithNearestDow(dow, includeCurrentDate) {
    if (includeCurrentDate && this.getDayOfWeek("string") === dow) {
      return new DateTime(this.getDate());
    }

    let date;
    switch (dow) {
      case "Monday":
        date = nextMondayExtPkg(this.getDate());
        break;
      case "Tuesday":
        date = nextTuesdayExtPkg(this.getDate());
        break;
      case "Wednesday":
        date = nextWednesdayExtPkg(this.getDate());
        break;
      case "Thursday":
        date = nextThursdayExtPkg(this.getDate());
        break;
      case "Friday":
        date = nextFridayExtPkg(this.getDate());
        break;
      case "Saturday":
        date = nextSaturdayExtPkg(this.getDate());
        break;
      case "Sunday":
        date = nextSundayExtPkg(this.getDate());
        break;
    }
    return new DateTime(date);
  }
}
