import { useMemo, useState } from "react";
import styles from "./AreaFilter.module.css";
import Select from "react-select";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import {
  arraysHaveSameContent,
  convertToShortIdNameOptionForm,
  getAreaOptions,
  getOptionValues,
} from "../../../utils";
import { useSettingsModelQuery } from "../../../hooks/modelQueryHooks/useSettingsModelQuery";
import { KEYWORD_ALL } from "../../../constants/keywords";

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "30px",
    height: "30px",
    width: "200px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  menu: (provided) => ({
    ...provided,
    width: "450px",
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    width: "100%",
    height: "30px",
    lineHeight: "30px",
    padding: "0 10px",
    verticalAlign: "middle",
    fontSize: "14px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "400px",
  }),
};

const CheckboxOption = (props) => {
  const { label } = props;
  return (
    <components.Option label={label} {...props}>
      <div className={styles.checkboxOption}>
        <input
          type="checkbox"
          onChange={() => null}
          checked={props.isSelected}
        />
        <label>{label}</label>
      </div>
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    values = values.length === 1 ? values[0].props.children : `Multiple`;
  }

  return (
    <components.ValueContainer {...props}>
      <div className={styles.valueContainer}>
        <FontAwesomeIcon icon={faMapLocation} className={styles.icon} />
        <div className={styles.text}>{values}</div>
        {input}
      </div>
    </components.ValueContainer>
  );
};

const getSelectedOptions = (
  selectedInputs,
  actionMeta,
  selectAllOption,
  areaOptions
) => {
  const { action, option } = actionMeta;
  const selectedInputValues = getOptionValues(selectedInputs);

  if (action === "select-option") {
    if (option.value === selectAllOption.value) {
      return [selectAllOption];
    }
  }

  if (action === "deselect-option") {
    if (option.value === selectAllOption.value) {
      return [];
    }

    if (selectedInputValues.includes(selectAllOption.value)) {
      return areaOptions.filter(({ value }) => option.value !== value);
    }
  }

  return selectedInputs;
};

export default function AreaFilter({
  areas,
  onAreaFilterChanged,
  containerStyle = {},
  onMenuClose = null,
  defaultValue = null,
}) {
  const { settings, isLoading } = useSettingsModelQuery();

  const { allOption: selectAllOption, areaOptions } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (!defaultValue || defaultValue.includes(KEYWORD_ALL)) {
      return [selectAllOption];
    }

    return convertToShortIdNameOptionForm(
      areas.filter(({ shortId }) => defaultValue.includes(shortId))
    );
  });
  const options = useMemo(
    () => [selectAllOption, ...areaOptions],
    [areaOptions, selectAllOption]
  );

  if (areas.length == 0) return <></>;

  return (
    <div className={styles.container} style={{ ...containerStyle }}>
      {settings && !isLoading && (
        <Select
          options={options}
          placeholder={"Filter by area"}
          styles={{
            ...customStyles,
          }}
          value={selectedOptions}
          isMulti
          isClearable={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option: ({ ...props }) => (
              <CheckboxOption {...props} selectAllOption={selectAllOption} />
            ),
            ValueContainer,
          }}
          onMenuClose={onMenuClose}
          isSearchable={false}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(selectedInputs, actionMeta) => {
            const selected = getSelectedOptions(
              selectedInputs,
              actionMeta,
              selectAllOption,
              areaOptions
            );
            setSelectedOptions(selected);

            const prevSelectedAreaShortIds = getOptionValues(selectedOptions);
            const selectedAreaShortIds = getOptionValues(selected);

            if (
              !arraysHaveSameContent(
                prevSelectedAreaShortIds,
                selectedAreaShortIds
              )
            ) {
              onAreaFilterChanged(selectedAreaShortIds);
              return;
            }
          }}
          isOptionSelected={(option, selectedOptions) => {
            const selectedValues = getOptionValues(selectedOptions);
            if (selectedValues.includes(selectAllOption.value)) {
              return true;
            }
            if (selectedValues.includes(option.value)) {
              return true;
            }

            return false;
          }}
        />
      )}
    </div>
  );
}
