import React from "react";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import withSideBar from "../../../../components/layouts/hoc/withSideBar/withSideBar";
import styles from "./GridsLayout.module.css";

const GridsLayout = ({ GridComponents, rosterID, customStyles, ...props }) => {
  return (
    <div className={styles.wrapper} style={customStyles ? customStyles : {}}>
      {GridComponents.map((Component, idx) => {
        return (
          <React.Fragment key={idx}>
            <Component rosterID={rosterID} {...props} />
            {GridComponents.length > 1 && (
              <div className={styles.separator}></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const RosterGridsLayoutWithSidebar = withHeader(
  withSideBar(GridsLayout)
);
export const RosterGridsLayoutWithoutSidebar = withHeader(GridsLayout);
export default GridsLayout;
