import {
  DateTime,
  deepCopyObject,
  getPreferenceSuffix,
  sortByDateField,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useDataMutation } from "./useDataMutation";
import { updateGlobalEmployeeModel } from "../../utils/queryUtils/locationQuery";

export function useEmployeeApplicationMutation(locationID) {
  const queryClient = useQueryClient();
  const mutation = useDataMutation({
    queryKey: ["employeeLocation", locationID],
    queryClient,
    mutationFn: (mutateFnParam) => {
      updateGlobalEmployeeModel(mutateFnParam.newEmployee);
      return mutateFnParam.newLocationData;
    },
    getExpectedResultingData: (mutateFnParam) => mutateFnParam.newLocationData,
  });

  const updateSingleEmployee = useCallback(
    (employee) => {
      const employeeId = employee.id;
      const locationData = queryClient.getQueryData([
        "employeeLocation",
        locationID,
      ]);
      if (locationData) {
        const employees = locationData.employees || [];
        const employeeIndex = employees.findIndex(
          (employee) => employee.id === employeeId
        );
        const newLocationData = JSON.parse(JSON.stringify(locationData));
        if (employeeIndex !== -1) {
          newLocationData.employees[employeeIndex] = {
            ...newLocationData.employees[employeeIndex],
            ...employee,
          };
        }
        return newLocationData;
      }
      return null;
    },
    [locationID, queryClient]
  );

  const updateGlobalPreferencesToEmployee = useCallback(
    (oldEmployee, updatedInfo) => {
      const locationData = queryClient.getQueryData([
        "employeeLocation",
        locationID,
      ]);
      const locationStartDate = locationData.location.startDate;

      const newEmployee = updateGlobalPreferencesToEmployeeTransformer(
        deepCopyObject(oldEmployee),
        updatedInfo,
        locationStartDate
      );

      const newLocationData = updateSingleEmployee(newEmployee);
      mutation.mutate({
        newEmployee,
        newLocationData,
      });
    },
    [mutation, updateSingleEmployee, queryClient, locationID]
  );

  const deleteRequestById = useCallback(
    (oldEmployee, requestID) => {
      const newEmployee = deleteRequestByIdTransformer(
        deepCopyObject(oldEmployee),
        requestID
      );
      const newLocationData = updateSingleEmployee(newEmployee);
      mutation.mutate({
        newEmployee,
        newLocationData,
      });
    },
    [mutation, updateSingleEmployee]
  );
  const addRequest = useCallback(
    (oldEmployee, requestInfo) => {
      const newEmployee = addRequestTransformer(
        deepCopyObject(oldEmployee),
        requestInfo
      );
      const newLocationData = updateSingleEmployee(newEmployee);
      mutation.mutate({
        newEmployee,
        newLocationData,
      });
    },
    [mutation, updateSingleEmployee]
  );
  const addRegistrationToGlobalEmployee = useCallback(
    (oldEmployee, registration) => {
      let registrationsList = oldEmployee.registrations
        ? [...oldEmployee.registrations]
        : [];

      if (registrationsList.includes(registration)) {
        return;
      }

      const newEmployee = addRegistrationToGlobalEmployeeTransformer(
        deepCopyObject(oldEmployee),
        registration
      );
      const newLocationData = updateSingleEmployee(newEmployee);
      mutation.mutate({
        newEmployee,
        newLocationData,
      });
    },
    [mutation, updateSingleEmployee]
  );

  return {
    addRegistrationToGlobalEmployee,
    addRequest,
    deleteRequestById,
    updateGlobalPreferencesToEmployee,
    isMutationLoading: mutation.isLoading,
  };
}

const updateGlobalPreferencesToEmployeeTransformer = (
  globalEmployee,
  updatedInfo,
  locationStartDate
) => {
  const defaultSuffix = getPreferenceSuffix(updatedInfo.defaultPreferenceLevel);
  const selectedSpecificPreference = updatedInfo.selectedSpecificPreference;
  const selectedRecurringPreference = updatedInfo.selectedRecurringPreference;

  const targetDateObj = new DateTime(updatedInfo.date);
  const targetDate = targetDateObj.toFormat("AWS");
  const targetDow = targetDateObj.getDayOfWeek("number");
  const targetDowString = targetDateObj.getDayOfWeek("string");
  const specificPreferenceIsSpecial = updatedInfo.specialSpecificPreference;

  let preferenceInfo = {
    date: targetDate,
    allocation: specificPreferenceIsSpecial
      ? `${selectedSpecificPreference}*`
      : `${selectedSpecificPreference}${
          selectedSpecificPreference !== "" ? defaultSuffix : ""
        }`,
  };

  const recurringPreferenceIsSpecial = updatedInfo.specialRecurringPreference;

  let preferenceRecurringInfo = {
    date: targetDate,
    allocation: recurringPreferenceIsSpecial
      ? `${selectedRecurringPreference}*`
      : `${selectedRecurringPreference}${
          selectedRecurringPreference !== "" ? defaultSuffix : ""
        }`,
  };

  const originalPreference = globalEmployee.Preferences.filter(
    (preference) => preference.date === targetDate
  );

  if (originalPreference.length > 0) {
    const preferenceObject = preferenceStringToObject(
      originalPreference[0].allocation
    );
    if (
      preferenceObject.preference === selectedSpecificPreference &&
      preferenceObject.severity !== "Low" &&
      specificPreferenceIsSpecial
    ) {
      preferenceInfo = null;
    }
  }

  const correctedDow = (targetDow + 6) % 7;
  const recurringPreferenceObject = preferenceStringToObject(
    globalEmployee.PreferencesRecurring[correctedDow]
  );

  if (
    recurringPreferenceObject.preference === selectedRecurringPreference &&
    recurringPreferenceObject.severity ===
      preferenceStringToObject(preferenceRecurringInfo.allocation).severity &&
    recurringPreferenceIsSpecial
  ) {
    preferenceRecurringInfo = null;
  }

  const originalPreferences = JSON.parse(
    JSON.stringify(globalEmployee.Preferences)
  );
  const originalPrefsWithoutNewPref = originalPreferences.filter(
    (pref) => pref.date !== targetDate
  );

  if (preferenceInfo) {
    if (preferenceInfo.allocation === "") {
      globalEmployee.Preferences = originalPrefsWithoutNewPref;
    } else {
      const updatedPreferences = originalPrefsWithoutNewPref.concat([
        preferenceInfo,
      ]);
      sortByDateField(updatedPreferences, "date", true);
      globalEmployee.Preferences = updatedPreferences;
    }
  }

  const originalRecurringPreferences = JSON.parse(
    JSON.stringify(globalEmployee.PreferencesRecurring)
  );
  const preferencesRecurringLength = globalEmployee.PreferencesRecurring.length;

  let newDowIdx = (targetDow + 6) % 7;

  if (preferencesRecurringLength === 14) {
    const firstDateOfPossibleRecurringPreference = new DateTime(
      locationStartDate
    ).getDateWithNearestDow(targetDowString, true);

    const dayOffset = Math.abs(
      DateTime.getDifferenceInDays(
        firstDateOfPossibleRecurringPreference,
        targetDateObj
      )
    );

    if (dayOffset % 7 === 0 && dayOffset % 14 !== 0) {
      newDowIdx = newDowIdx + 7;
    }
  }

  if (preferenceRecurringInfo) {
    originalRecurringPreferences[newDowIdx] =
      preferenceRecurringInfo.allocation;
    globalEmployee.PreferencesRecurring = originalRecurringPreferences;
  }

  return {
    PreferencesRecurring: globalEmployee.PreferencesRecurring,
    Preferences: globalEmployee.Preferences,
    id: globalEmployee.id,
  };
};

const addRegistrationToGlobalEmployeeTransformer = (
  globalEmployee,
  registration
) => {
  let registrationsList = globalEmployee.registrations
    ? [...globalEmployee.registrations]
    : [];

  const updatedRegistrations = [...registrationsList, registration];
  globalEmployee.registrations = updatedRegistrations;

  return { registrations: globalEmployee.registrations, id: globalEmployee.id };
};

const addRequestTransformer = (globalEmployee, requestInfo) => {
  globalEmployee.Requests.push({
    id: uuidv4(),
    ...requestInfo,
  });

  return { Requests: globalEmployee.Requests, id: globalEmployee.id };
};

const deleteRequestByIdTransformer = (globalEmployee, requestID) => {
  globalEmployee.Requests = globalEmployee.Requests.filter(
    (req) => req.id !== requestID
  );

  return { Requests: globalEmployee.Requests, id: globalEmployee.id };
};

const preferenceStringToObject = (preferenceString) => {
  if (preferenceString.endsWith("!")) {
    return {
      preference: preferenceString.slice(0, preferenceString.length - 1),
      severity: "Critical",
    };
  } else if (preferenceString.endsWith("?")) {
    return {
      preference: preferenceString.slice(0, preferenceString.length - 1),
      severity: "High",
    };
  } else if (preferenceString.endsWith("*")) {
    return {
      preference: preferenceString.slice(0, preferenceString.length - 1),
      severity: "Special",
    };
  }

  return {
    preference: preferenceString,
    severity: "Low",
  };
};
