import styles from "./ImportanceSelector.module.css";

const ImportanceSelector = ({ changePrefLevel }) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={`${styles.alloImportanceBtn} ${styles.critical}`}
        onClick={() => {
          changePrefLevel("critical");
        }}
      >
        Critical
      </button>
      <button
        className={`${styles.alloImportanceBtn} ${styles.high}`}
        onClick={() => {
          changePrefLevel("high");
        }}
      >
        High
      </button>
      <button
        className={`${styles.alloImportanceBtn} ${styles.normal}`}
        onClick={() => {
          changePrefLevel("normal");
        }}
      >
        Normal
      </button>
    </div>
  );
};
export default ImportanceSelector;
