import styles from "./PublishedCellColorToggler.module.css";
import { SwitchField } from "@aws-amplify/ui-react";

const PublishedCellColorToggler = ({
  showPublishedCellColor,
  setShowPublishedCellColor,
  labelStyle = {},
}) => {
  return (
    <div className={styles.container}>
      <SwitchField
        onChange={(e) => {
          setShowPublishedCellColor(e.target.checked);
        }}
        isChecked={showPublishedCellColor}
        label="Toggle colour coding"
        isLabelHidden={true}
        trackCheckedColor="#009EC6"
        style={{
          display: "flex",
        }}
      />
      <span className={styles.label} style={labelStyle}>
        Show what&apos;s published
      </span>
    </div>
  );
};

export default PublishedCellColorToggler;
