import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import {
  subscribeGlobalEmployeeUpdate,
  subscribeLocationChange,
} from "../../utils/queryUtils/observers";
import {
  getLocationAndEmployeesById,
  getLocationOnly,
} from "../../utils/queryUtils/locationQuery";
import { useRouteQuery } from "../useRouteQuery";
import { getFieldsFromLocation } from "../../utils/queryUtils/locationDataGetters";
import {
  getUserAttributes,
  getUserEmail,
} from "../../features/auth/service/auth";
import {
  syncNonAdminAppGlobalEmployee,
  syncNonAdminAppLocation,
} from "../../utils/modelUtils/sync";

export function useEmployeeApplicationQuery(user) {
  const queryClient = useQueryClient();
  const isInvalidLocationIDInURL = useRef(false);

  let urlQuery = useRouteQuery();
  const locationIDInURL = urlQuery.get("location-id");

  const { sub } = getUserAttributes(user);
  const locationsQuery = useQuery({
    queryKey: ["allLocations", sub],
    queryFn: () => getAllEmployeeLocations(user),
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled: !!user,
  });

  const locations = useMemo(() => {
    if (!locationsQuery.data) {
      return null;
    }
    return locationsQuery.data;
  }, [locationsQuery]);

  const locationID = useMemo(() => {
    if (!locations || locations.length === 0) {
      return null;
    }

    if (!locationIDInURL) {
      return locations[0].id;
    }

    const locationIDs = locations.map((location) => location.id);
    const matchingID = locationIDs.find((id) => id === locationIDInURL);
    if (!matchingID) {
      isInvalidLocationIDInURL.current = true;
      return null;
    }

    return matchingID;
  }, [locations, locationIDInURL]);

  const specificLocationQuery = useQuery({
    queryKey: ["employeeLocation", locationID],
    queryFn: () =>
      getLocationAndEmployeesById(locationID, true, getUserEmail(user)),
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled: !!locationID, // Query will not run until locationID is defined
  });

  const { location, employees } = useMemo(() => {
    if (!specificLocationQuery.data) {
      return {};
    }
    return specificLocationQuery.data;
  }, [specificLocationQuery]);

  const userEmployee = useMemo(() => {
    if (!employees || employees.length === 0) {
      return null;
    }
    const email = getUserEmail(user);
    const targetEmployee = employees.find(
      (emp) => emp.email.toLowerCase() === email.toLowerCase()
    );

    if (!targetEmployee) {
      return null;
    }

    return targetEmployee;
  }, [employees, user]);

  // Define debounced function outside of the subscription to avoid recreating on each event

  useEffect(() => {
    if (!locationID) {
      return;
    }

    const queryKey = ["employeeLocation", locationID];
    const locationSubscription = subscribeLocationChange((data) => {
      syncNonAdminAppLocation(queryKey, data);
    }, locationID);

    const employeeSubscription = subscribeGlobalEmployeeUpdate((data) => {
      syncNonAdminAppGlobalEmployee(queryKey, data);
    }, locationID);
    return async () => {
      (await employeeSubscription)?.unsubscribe();
      (await locationSubscription)?.unsubscribe();
    };
  }, [queryClient, locationID, userEmployee, location]);

  const locationFields = getFieldsFromLocation(location);

  const noLocationsExist =
    !locationsQuery.isLoading && (!locations || locations.length === 0);

  return {
    location,
    isQueryLoading:
      (specificLocationQuery.isLoading && !noLocationsExist) ||
      locationsQuery.isLoading,
    locations: locations ? locations : [],
    ...locationFields,
    employees: employees ? employees : [],
    isInvalidLocationIDInURL: isInvalidLocationIDInURL.current,
    updateIsInvalidLocationIDInURL: (bool) =>
      (isInvalidLocationIDInURL.current = bool),
    userEmployee,
  };
}

const isUuid = (value) => {
  const uuidv4Regex =
    /^([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;
  return uuidv4Regex.test(value);
};

const getAllEmployeeLocations = async (user) => {
  const locationPromises = [];
  const userGroups = user.session.accessToken.payload["cognito:groups"];

  if (!userGroups) return [];

  for (let i = 0; i < userGroups.length; i++) {
    const userGroup = userGroups[i];

    if (isUuid(userGroup)) {
      locationPromises.push(getLocationOnly(userGroup));
    }
  }

  const allLocations = (await Promise.all(locationPromises)).filter(
    (loc) => loc
  );

  return allLocations.filter((location) => location && !location._deleted);
};
