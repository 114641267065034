import {
  PLAN,
  getCurrentAuthUserEmail,
  getCurrentAuthUserType,
} from "../../auth/service/auth";
import {
  getRosterCheckErrorMsg,
  getRosterWarningErrorMsg,
} from "../../warnings/service/displayHelper/msgDisplayer";
import { warningChecksList } from "../../warnings/service/validators/rosterChecks";
import {
  checkRosterWarnings,
  createSolutionModel,
  deleteSolutionModel,
  generateRosterSolution,
  getRosterModelById,
} from "../../../utils/queryUtils/rosterQuery";
import { getLocationModelById } from "../../../utils/queryUtils/locationQuery";
import {
  getFieldsFromRoster,
  getReRosterSolutionInitialTemplate,
  getRosterSolutionInitialTemplate,
  getRosteringProblem,
} from "../../../utils/queryUtils/rosterDataGetters";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../../utils/queryUtils/locationDataGetters";
import {
  inferRosterStartDate,
  scheduleRosterToGeneratable,
} from "../../scheduleView/service/scheduleViewRoster";
import { getPlanDetails } from "../../../globalStore/appStore";
import {
  customConfirmAlert,
  customReminderAlert,
  customWarningAlert,
} from "../../confirm/service/confirm";
import {
  checkMidTierEmployeeRestriction,
  handleBillingRedirection,
} from "../../scheduleView/components/EmployeeNumberLimitModal/EmployeeNumberLimitModal";

import { getCompressedHolidayData } from "../../rosterProblems/rosteredAllocations/components/ReportGenerator/ReportGenerator";
import {
  DateTime,
  strToArrCommaSeparated,
  isNightShift,
  splitAllocationSuffix,
} from "../../../utils";
import { getActualNumDays } from "../../../utils/queryUtils/monthViewUtils";
import { getQueryClient } from "../../../hooks/modelQueryHooks/queryClientStore";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
  getSubtasks,
} from "../../rosterProblems/service/rosterUtils";
import { convertAllocationInShortIdFormToNameForm } from "../../../utils/modelUtils/allocation";
import { KEYWORD_ALL } from "../../../constants/keywords";

export const frontendSettingsToSolverSettings = (frontendSettings) => {
  const settings = frontendSettings.map(
    (frontendSetting) =>
      frontendSetting.name +
      ";" +
      (frontendSetting.values ? frontendSetting.values.join(";") : "")
  );
  return settings;
};

export const generateClassicRoster = async (rosterID, locationID, warnings) => {
  const roster = await getRosterModelById(rosterID);
  const location = await getLocationModelById(locationID);
  const customKeywordsUtilObj = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  );

  return generateRosterFromRoster(
    roster,
    [
      ...location.settings,
      ...frontendSettingsToSolverSettings(location.frontendSettings),
    ],
    warnings,
    customKeywordsUtilObj,
    location
  );
};

export const generateScheduleRoster = async (roster, locationID, warnings) => {
  const queryClient = getQueryClient();
  const location = queryClient.getQueryData(["location", locationID]);
  const customKeywordsUtilObj = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  );
  return generateRosterFromRoster(
    scheduleRosterToGeneratable(roster),
    [
      ...location.settings,
      ...frontendSettingsToSolverSettings(location.frontendSettings),
      ...getOptionalHistoricalData(location, roster),
    ],
    warnings,
    customKeywordsUtilObj,
    location
  );
};

export const getRosteringProblemFromRoster = (roster, locationSettings) => {
  let generatableRoster = scheduleRosterToGeneratable(roster);
  generatableRoster = replaceRosterShortIdValuesWithNames(generatableRoster);
  const rosteringProblem = getRosteringProblem(
    generatableRoster,
    locationSettings
  );
  return rosteringProblem;
};

export const generateReRoster = async (
  roster,
  location,
  startDate,
  finishDate,
  employeeNames,
  warnings,
  tasksOnly = false
) => {
  const locationSettings = [
    ...location.settings,
    `reroster;${startDate};${finishDate};${employeeNames.join(",")};${
      tasksOnly ? "tasksOnly" : ""
    }`,
  ];

  let rosterWarnings = warnings;

  const customKeywordsUtilObj = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  );

  const rerosterSolutionInfo = getReRosterSolutionInitialTemplate(roster.id);

  const subTasks = getSubtasks(roster.Tasks, roster.TaskBlocks);

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    roster.Areas || [],
    roster.Shifts,
    roster.ShiftGroups,
    roster.Tasks,
    subTasks,
    roster.Skills
  );

  rerosterSolutionInfo.employees = roster.Employees.map((emp) => {
    return {
      employeeID: emp.id,
      name: emp.name,
      skills: emp.skills,
      days: emp.RosteredAllocations.map((item) =>
        item === ""
          ? "-"
          : convertAllocationInShortIdFormToNameForm(
              item,
              shortIdsToEntityNamesDicts
            )
      ),
    };
  });

  return await generateRosterFromRoster(
    scheduleRosterToGeneratable(roster),
    [
      ...locationSettings,
      ...frontendSettingsToSolverSettings(location.frontendSettings),
    ],
    rosterWarnings,
    customKeywordsUtilObj,
    location,
    rerosterSolutionInfo
  );
};

function replaceRosterShortIdValuesWithNames(roster) {
  const {
    Areas,
    Employees,
    Skills,
    Tasks,
    TaskBlocks,
    Shifts,
    ShiftGroups,
    CustomRules,
    Demands,
  } = roster;

  const subTasks = getSubtasks(Tasks, TaskBlocks);

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    Areas,
    Shifts,
    ShiftGroups,
    Tasks,
    subTasks,
    Skills
  );

  const parse = (allocation) => {
    const [allocationWithoutSuffix, suffix] = splitAllocationSuffix(allocation);

    const targetShiftGroup = ShiftGroups.find(
      ({ shortId }) => shortId === allocationWithoutSuffix
    );
    if (targetShiftGroup) {
      return targetShiftGroup.name + (suffix ?? "");
    }
    const name = convertAllocationInShortIdFormToNameForm(
      allocation,
      shortIdsToEntityNamesDicts
    );
    return name || allocation + (suffix ?? ""); // name already has suffix included
  };
  const parseSkills = (skills) =>
    strToArrCommaSeparated(skills)
      .map((shortId) => {
        const targetSkill = Skills.find((s) => s.shortId === shortId);
        return targetSkill.name;
      })
      .join(", ");

  const parseAreas = (areas) =>
    strToArrCommaSeparated(areas)
      .map((shortId) => {
        if (shortId === KEYWORD_ALL) return KEYWORD_ALL; // Known keyword
        const targetArea = Areas.find((a) => a.shortId === shortId);
        return targetArea.name;
      })
      .join(", ");

  // Parsed Employees
  const employees = Employees.map((employee) => {
    const { skills, shifts, areas } = employee;

    return {
      ...employee,
      RosteredAllocations: employee.RosteredAllocations.map(parse),
      Days: employee.Days.map(parse),
      DaysRecurring: employee.DaysRecurring.map(parse),
      Allocations: employee.Allocations.map(parse),
      AllocationsRecurring: employee.AllocationsRecurring.map(parse),
      History: employee.History.map(parse),
      skills: parseSkills(skills),
      shifts: strToArrCommaSeparated(shifts).map(parse).join(", "),
      areas: parseAreas(areas),
    };
  });

  const tasks = Tasks.map((task) => {
    const { skills } = task;
    return {
      ...task,
      skills: parseSkills(skills),
    };
  });

  const shifts = Shifts.map((shift) => {
    const { skill } = shift;
    return {
      ...shift,
      skill: parseSkills(skill),
    };
  });

  const shiftGroups = ShiftGroups.map((group) => {
    const { areas, shifts, skills, tasks } = group;
    return {
      ...group,
      areas: parseAreas(areas),
      skills: parseSkills(skills),
      shifts: strToArrCommaSeparated(shifts).map(parse).join(", "),
      tasks: strToArrCommaSeparated(tasks).map(parse).join(", "),
    };
  });

  const allEntities = [...shifts, ...shiftGroups, ...tasks];
  const customRules = CustomRules.map((customRule) => {
    const { name, template } = customRule;

    const subTemplates = template.split(";");

    const referencedEntities = [];

    const correctedSubTemplates = subTemplates.map((subTemplate) => {
      const targetEntity = allEntities.find(
        ({ shortId }) => shortId === subTemplate
      );

      if (targetEntity) {
        referencedEntities.push(targetEntity);
        return targetEntity.name;
      }

      return subTemplate;
    });

    const correctedTemplate = correctedSubTemplates.join(";");

    let updatedName = name;
    for (const entity of referencedEntities) {
      updatedName = updatedName.replace(entity.shortId, entity.name);
    }

    return {
      name: updatedName,
      template: correctedTemplate,
    };
  });

  const demands = Demands.map((demand) => {
    const { areas, skills, tasks, shifts } = demand;
    return {
      ...demand,
      areas: parseAreas(areas),
      skills: parseSkills(skills),
      tasks: strToArrCommaSeparated(tasks).map(parse).join(", "),
      shifts: strToArrCommaSeparated(shifts).map(parse).join(", "),
    };
  });

  const areas = Areas.map((area) => {
    const { skills, shifts, tasks } = area;
    return {
      ...area,
      skills: parseSkills(skills),
      shifts: strToArrCommaSeparated(shifts).map(parse).join(", "),
      tasks: strToArrCommaSeparated(tasks).map(parse).join(", "),
    };
  });

  const updatedRoster = {
    ...roster,
    Employees: employees,
    Shifts: shifts,
    Tasks: tasks,
    ShiftGroups: shiftGroups,
    CustomRules: customRules,
    Demands: demands,
    Areas: areas,
  };

  return updatedRoster;
}

const generateRosterFromRoster = async (
  roster,
  locationSettings,
  rosterWarnings,
  customKeywordsUtilObj,
  location,
  rerosterSolutionInfo = null
) => {
  const { areas, skills, shifts, shiftGroups, tasks, subTasks } =
    getFieldsFromRoster(roster);

  const namesToEntityShortIdsDicts = buildNamesToEntityShortIdsDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const generatableRoster = replaceRosterShortIdValuesWithNames(roster);
  const { leaveKeywords } = customKeywordsUtilObj;
  const userType = await getCurrentAuthUserType();

  const planDetails = getPlanDetails();
  if (
    checkMidTierEmployeeRestriction(
      planDetails.plan,
      planDetails.isTrialInProgress,
      planDetails.totalGlobalEmployees,
      planDetails.maxGlobalEmployees
    )
  ) {
    handleBillingRedirection(
      planDetails.totalGlobalEmployees,
      planDetails.totalGlobalEmployees - planDetails.maxGlobalEmployees,
      planDetails.maxGlobalEmployees
    );

    return;
  }

  if (
    planDetails.plan === PLAN.MID &&
    roster.Shifts.filter((shift) =>
      isNightShift(shift.startTime, shift.finishTime)
    ).length > 0
  ) {
    await customReminderAlert({
      title: "You have night shifts configured",
      descriptions: [
        "Night shifts will not be generated in starter tier. Please contact RosterLab support to unlock generating night shifts.",
        "You may still assign them as fixed shifts.",
      ],
      okLabel: "Continue",
    });
  }

  if (
    !(userType !== undefined && userType.includes("internal")) &&
    roster.Solutions.items.filter(
      (s) => !s._deleted && s.percentageComplete < 100
    ).length > 0
  ) {
    customWarningAlert({
      title: "Cannot generate roster",
      okLabel: "OK",
      descriptions: ["You are already running a roster!"],
    });
    return false;
  }

  let errorMsg = getRosterWarningErrorMsg(rosterWarnings, generatableRoster); // This is the new function
  if (errorMsg === "") {
    errorMsg = getRosterCheckErrorMsg(
      generatableRoster,
      roster,
      customKeywordsUtilObj,
      namesToEntityShortIdsDicts
    );
  }

  if (errorMsg !== "") {
    customWarningAlert({
      title: "Cannot generate roster",
      okLabel: "OK",
      descriptions: [errorMsg],
    });
    return false;
  }

  const allWarnings = [];
  for (let i = 0; i < warningChecksList.length; i++) {
    let warning = warningChecksList[i](generatableRoster);
    if (warning !== "") {
      allWarnings.push(warning);
    }
  }
  if (allWarnings.length > 0) {
    let warningText = "Warnings:\n";
    allWarnings.forEach((warning) => {
      warningText += warning + "\n";
    });
    warningText += "Are you sure you want to continue?";

    if (
      !(await customConfirmAlert({
        title: "Please confirm",
        descriptions: [warningText],
        okLabel: "Continue",
        cancelLabel: "Cancel",
      }))
    ) {
      return false;
    }
  }

  const rosteringProblem = getRosteringProblem(
    generatableRoster,
    locationSettings,
    location.defaultNumDays === -1 ? location.startDate : null,
    location.defaultNumDays
  );

  const rosterSolutionInfo = getRosterSolutionInitialTemplate(
    generatableRoster.id
  );

  const requestNotifications = false; // Only Daniel is using this anyway

  locationSettings = [
    ...locationSettings,
    "leaveNames;" + leaveKeywords.join(";"),
  ];

  const rosteringSolution = await createSolutionModel(
    rosterSolutionInfo,
    location.owner
  );

  let rerosteringSolution = null;
  if (rerosterSolutionInfo) {
    rerosterSolutionInfo.rerosteredSolutionID = rosteringSolution.id;
    rerosteringSolution = await createSolutionModel(
      rerosterSolutionInfo,
      location.owner
    );
  }

  try {
    const aiRosterWarnings = JSON.parse(
      (await checkRosterWarnings(rosteringProblem, locationSettings)).data
        .checkRoster
    );

    const warningsText = (
      <>
        {aiRosterWarnings.warnings.map((warning) => (
          <p key={warning}>
            {warning}
            <br />
          </p>
        ))}
      </>
    );

    if (
      aiRosterWarnings.warnings.length > 0 &&
      !(await customConfirmAlert({
        title: "Are you sure this roster is correct?",
        descriptions: [warningsText],
        okLabel: "Continue",
        cancelLabel: "Cancel",
      }))
    ) {
      await deleteSolutionModel(rosteringSolution.id);
      if (rerosteringSolution) {
        await deleteSolutionModel(rerosteringSolution.id);
      }

      const queryClient = getQueryClient();
      queryClient.invalidateQueries(["roster", roster.id]);

      return false;
    }
  } catch (error) {
    console.error(error);
  }

  try {
    const email = await getCurrentAuthUserEmail();
    try {
      const result = generateRosterSolution(
        rosteringProblem,
        generatableRoster.id,
        rosteringSolution.id,
        email,
        "",
        requestNotifications,
        locationSettings,
        rerosteringSolution
      );

      if ((await result).data.asyncGenerateRoster === "Error") {
        customWarningAlert({
          title: "Cannot connect to rostering A.I.",
          descriptions: ["Unknown error! Please try again!"],
        });
        deleteSolutionModel(rosteringSolution.id);
        return true;
      }
      return true;
    } catch (err) {
      if (err.errors.length > 0) {
        if (err.errors[0].message === "Network Error") {
          customWarningAlert({
            title: "Network error",
            descriptions: ["Can not connect to network to generate roster!"],
          });
          deleteSolutionModel(rosteringSolution.id);
          return false;
        }
      }
      customWarningAlert({
        title: "Cannot connect to rostering A.I.",
        descriptions: ["Unknown error! Please try again!"],
      });
      deleteSolutionModel(rosteringSolution.id);
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getOptionalHistoricalData = (location, roster) => {
  const periodStartDate = inferRosterStartDate(
    location.startDate,
    roster.startDate,
    location.defaultNumDays
  );

  const periodFinishDate = DateTime.addDaysToDate(
    periodStartDate,
    getActualNumDays(periodStartDate, location.defaultNumDays) - 1
  ).toFormat("AWS");

  if (
    roster.CustomRules.some((rule) => {
      return rule.template === "daysBeforePublicHoliday";
    })
  ) {
    const employeesHolidayWorkedData = getCompressedHolidayData(
      location.Employees.items,
      periodStartDate,
      periodFinishDate,
      location.Shifts
    );

    return ["publicHolidayData;" + JSON.stringify(employeesHolidayWorkedData)];
  }

  return [];
};
