import { useMemo, useRef, useState } from "react";
import styles from "./EditRequestsModal.module.css";
import ShadowButton from "../../../../components/elements/ShadowButton/ShadowButton";
import { DateTime } from "../../../../utils";

const EditRequestsModal = ({
  requestId,
  handleClose,
  handleSave,
  requestsData,
}) => {
  const prevState = useRef();

  const request = useMemo(
    () => requestsData.find((request) => request.id === requestId),
    [requestsData, requestId]
  );
  const requestEmployeeName = request.employeeName;

  const [comment, setComment] = useState(request.adminComment);
  const [_state, setState] = useState(request.state);

  const allActiveRequests = useMemo(
    () =>
      requestsData.filter(
        (request) =>
          request.employeeName === requestEmployeeName &&
          (request.state === "Approved" || request.state === "Pending")
      ),
    [requestsData, requestEmployeeName]
  );

  const issues = useMemo(() => {
    const clashingRequests = allActiveRequests.filter((rq) => {
      return (
        new DateTime(request.startDate).date <=
          new DateTime(rq.finishDate).date &&
        new DateTime(request.finishDate).date >=
          new DateTime(rq.startDate).date &&
        rq.id !== request.id
      );
    });
    if (clashingRequests.length !== 0) {
      return "Request already clashes with another approved or pending request";
    }
    return null;
  }, [request, allActiveRequests]);

  const employeeComment = request.employeeComment;

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Leave request - {request.employeeName}</h2>
        <form className={styles.formData}>
          <div className={styles.fixedField}>
            {issues === null ? (
              <select
                name="name"
                value={_state}
                onChange={(e) =>
                  setState((prev) => {
                    prevState.current = prev;
                    return e.target.value;
                  })
                }
                required
                className={styles[_state]}
              >
                <option className={styles.Approved}>Approved</option>
                <option className={styles.Pending}>Pending</option>
                <option className={styles.Denied}>Denied</option>
              </select>
            ) : (
              <p className={`${styles.userState} ${styles[_state]}`}>
                {_state}
              </p>
            )}
          </div>
          <div className={`${styles.row} ${styles.rowOne}`}>
            <div className={styles.infoLine}>
              <span
                className={styles.emph}
              >{`Submited by ${request.submittedBy}: `}</span>
              <span>
                {new DateTime(request.submittedDate).toFormat("displayed-full")}
              </span>
            </div>
            <div className={styles.infoLine}>
              <span className={styles.emph}>{`Requested leave: `}</span>
              <span>{`${request.request} - ${new DateTime(
                request.startDate
              ).toFormat("displayed-full")} - ${new DateTime(
                request.finishDate
              ).toFormat("displayed-full")}`}</span>
            </div>
          </div>
          <div className={`${styles.row} ${styles.rowTwo}`}>
            <div className={`${styles.dateWrapper} ${styles.fromDate}`}>
              <span>From</span>
              <p>
                {new DateTime(request.startDate).toFormat("displayed-full")}
              </p>
            </div>
            <div className={`${styles.dateWrapper} ${styles.toDate}`}>
              <span>To</span>
              <p>
                {new DateTime(request.finishDate).toFormat("displayed-full")}
              </p>
            </div>
          </div>

          <div className={`${styles.row} ${styles.rowThree}`}>
            <div>
              {employeeComment !== null && (
                <span className={styles.emph}>Employee Comment</span>
              )}
              <i className={styles.comment}>{employeeComment}</i>
            </div>
          </div>
          <div className={`${styles.row} ${styles.rowFour}`}>
            {request.adminComment !== "" && (
              <span className={styles.emph}>Admin Comment</span>
            )}
            {request.adminComment === null && (
              <textarea
                className={styles.commentTextarea}
                value={comment ? comment : ""}
                onChange={(e) => setComment(e.target.value)}
                name="comment"
                type="text"
                label="Your comment"
              ></textarea>
            )}
            {request.adminComment !== null && (
              <i className={styles.comment}>{comment}</i>
            )}
          </div>
        </form>
        <p className={styles.issues}>{issues}</p>
        <div className={styles.buttons}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            isBlocked={issues ? true : false}
            onClick={() => {
              if (!issues) {
                handleSave(request, {
                  state: _state,
                  adminComment: comment,
                });
              }
            }}
          >
            Save
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            hoverColor="#219ec9"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={handleClose}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

export default EditRequestsModal;
