import styles from "./LiveShiftViewCalendarGrid.module.css";
import { useMemo } from "react";
import RosterTableShiftView from "../../../../rosterProblems/rosteredAllocations/components/RosterTableShiftView/RosterTableShiftView";
import { getEmployeeRosteredAllocations } from "../../../../scheduleView/service/scheduleViewRoster";
import { DateTime } from "../../../../../utils";

const LiveShiftViewCalendarGrid = ({
  locationID,
  employees,
  areas,
  shifts,
  shiftGroups,
  demands,
  tasks,
  taskBlocks,
  rules,
  scheduleStartDate,
  scheduleFinishDate,
  startDate,
  colorCodes,
  customKeywordsUtilObj,
  noEmployeesWithAllocatedShifts,
  hiddenRows,
  filteredShifts,
  numDays,
  employeesAllocationNotes,
  shortIdsToEntityNamesDicts,
}) => {
  const isTodayIncludedInCurrentPeriod = DateTime.checkTodayIsIncludedInPeriod(
    scheduleStartDate,
    numDays
  );

  const selectedShifts = useMemo(
    () => shifts.filter(({ shortId }) => filteredShifts.includes(shortId)),
    [shifts, filteredShifts]
  );
  const selectedShiftGroups = useMemo(
    () => shiftGroups.filter(({ shortId }) => filteredShifts.includes(shortId)),
    [shiftGroups, filteredShifts]
  );

  const { predefinedShiftOptions, leaveCodes } = customKeywordsUtilObj;

  const allDatesInSchedule = useMemo(
    () =>
      DateTime.getAllDatesBetween(
        scheduleStartDate,
        scheduleFinishDate,
        true,
        true
      ).map((date) => date.toFormat("AWS")),
    [scheduleStartDate, scheduleFinishDate]
  );

  const scheduleLength = allDatesInSchedule.length;

  const formattedEmployees = useMemo(() => {
    return employees.map((employee) => {
      const rosteredAllocations = getEmployeeRosteredAllocations(
        employee,
        leaveCodes,
        allDatesInSchedule
      );
      return {
        ...employee,
        RuleValues: employee.ruleValues,
        RosteredAllocations: rosteredAllocations,
        History: Array(14).fill(""),
      };
    });
  }, [employees, allDatesInSchedule, leaveCodes]);

  if (noEmployeesWithAllocatedShifts) {
    return (
      <div className={styles.overlayContainer}>
        <span className={styles.overlay}>
          The schedule admin has not published the schedule for this period.
        </span>
      </div>
    );
  }

  return (
    <RosterTableShiftView
      locationID={locationID}
      rosterID={null}
      isScheduleView={true}
      demands={demands}
      employees={formattedEmployees}
      numDays={scheduleLength}
      shifts={selectedShifts}
      shiftGroups={selectedShiftGroups}
      areas={areas}
      tasks={tasks}
      taskBlocks={taskBlocks}
      rules={rules}
      updateRosterTable={() => {}}
      startDate={scheduleStartDate}
      locationStartDate={startDate}
      predefinedShiftOptions={predefinedShiftOptions}
      toggleColorCodingModal={() => {}}
      colorCodes={colorCodes}
      readOnly={true}
      hiddenRows={hiddenRows}
      setHiddenShiftViewRows={() => {}}
      shouldDefaultViewIncludeToday={isTodayIncludedInCurrentPeriod}
      employeesAllocationNotes={employeesAllocationNotes}
      forceUpdateOnStartDateChange={true}
      shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      customKeywordsDict={customKeywordsUtilObj}
    />
  );
};

export default LiveShiftViewCalendarGrid;
