import styles from "./Step.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StepTitle = ({ text, fontAwesomeIcon }) => (
  <div className={styles.stepTitle}>
    <FontAwesomeIcon icon={fontAwesomeIcon} />
    {text}
  </div>
);

export default StepTitle;
