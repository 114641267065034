import styles from "./DuplicateButton.module.css";
import duplicateIcon from "../../../../assets/images/icons8-duplicate-24_1icons8-duplicate-24.png";

const DuplicateButton = ({ toggleDuplicateRosterModal, ...props }) => (
  <button
    className={styles.btn}
    onClick={() => toggleDuplicateRosterModal(props.data.id)}
  >
    <img className={styles.icon} alt="duplicate roster" src={duplicateIcon} />
  </button>
);

export default DuplicateButton;
