import create from "zustand";

export const useSolutionGenerationStore = create((set, get) => ({
  isSolutionGenerating: false,
  latestSolutionId: null,
  latestSolutionStatus: null,
  setLatestSolutionGenStatus: (model) => {
    if (!model) {
      set({
        isSolutionGenerating: false,
        latestSolutionStatus: null,
        latestSolutionId: null,
      });
      return;
    }

    if (model._deleted) {
      return;
    }

    if (model.id !== get().latestSolutionId) {
      set({
        latestSolutionId: model.id,
      });
    }

    if (model.status.startsWith("Solving - ")) {
      set({ isSolutionGenerating: true, latestSolutionStatus: model });
      return;
    }

    set({ isSolutionGenerating: false, latestSolutionStatus: model });
  },
}));
