import { doesEmployeeHaveLeaveOnDay } from "../../../utils";
import { checkEmployeeEligibility } from "../../rosterProblems/service/rosterUtils";

export const EMPLOYEE_OPTION_STATE = {
  accepted: "accepted",
  pending: "pending",
  declined: "declined",
  ineligible: "ineligible",
  notAsked: "not-asked",
  working: "working",
};

function isEmployeeEligibleForOpenShift(
  date,
  globalEmployee,
  shifts,
  shiftGroups,
  selectedAreaEntity = null,
  selectedShiftEntity,
  selectedSkillsEntities,
  selectedTaskEntity,
  customKeywordsUtilObj
) {
  const isLeaveOnDay = doesEmployeeHaveLeaveOnDay(globalEmployee, date);
  const doesPassFilterRequirement = checkEmployeeEligibility(
    globalEmployee,
    selectedAreaEntity,
    selectedSkillsEntities,
    selectedTaskEntity,
    selectedShiftEntity,
    shifts,
    shiftGroups,
    customKeywordsUtilObj
  );

  return !isLeaveOnDay && doesPassFilterRequirement;
}

function isEmployeeWorking(globalEmployee, date) {
  const allocationOnDay = globalEmployee.PublishedAllocations.find(
    (allocation) => allocation.date === date
  );

  if (!allocationOnDay) {
    return false;
  }

  if (
    allocationOnDay.draftAllocation ||
    (allocationOnDay.draftAllocation == null &&
      allocationOnDay.publishedAllocation)
  ) {
    return true;
  }
  return false;
}

function hasEmployeeAcceptedAnotherOpenShift(
  globalEmployee,
  allOpenShifts,
  selectedOpenShift = null,
  date
) {
  const otherOpenShiftsOnSameDay = allOpenShifts.filter((openShift) => {
    return (
      openShift.date === date &&
      (!selectedOpenShift || openShift.id !== selectedOpenShift.id)
    );
  });

  for (const otherOpenShift of otherOpenShiftsOnSameDay) {
    const targetEmployeeState = otherOpenShift.employeeStates.find(
      (state) => state.employeeID === globalEmployee.id
    );
    if (targetEmployeeState && targetEmployeeState.state === "accepted") {
      return true;
    }
  }
  return false;
}

export function getEmployeeStateForOpenShift(
  date,
  globalEmployee,
  shifts,
  shiftGroups,
  openShifts,
  selectedOpenShift = null,
  selectedAreaEntity = null,
  selectedShiftEntity,
  selectedSkillsEntities,
  selectedTaskEntity,
  customKeywordsUtilObj
) {
  if (selectedOpenShift) {
    const targetState = selectedOpenShift.employeeStates.find(
      (state) => state.employeeID === globalEmployee.id
    );
    const stateLabel = targetState?.state;
    if (EMPLOYEE_OPTION_STATE[stateLabel]) {
      return EMPLOYEE_OPTION_STATE[stateLabel];
    }
  }

  const employeeIsWorking = isEmployeeWorking(globalEmployee, date);
  const employeeHasAcceptedAnotherOpenShift =
    hasEmployeeAcceptedAnotherOpenShift(
      globalEmployee,
      openShifts,
      selectedOpenShift,
      date
    );

  if (employeeIsWorking || employeeHasAcceptedAnotherOpenShift) {
    return EMPLOYEE_OPTION_STATE.working;
  }

  const employeeIsEligibleForOpenShift = isEmployeeEligibleForOpenShift(
    date,
    globalEmployee,
    shifts,
    shiftGroups,
    selectedAreaEntity,
    selectedShiftEntity,
    selectedSkillsEntities,
    selectedTaskEntity,
    customKeywordsUtilObj
  );

  return employeeIsEligibleForOpenShift
    ? EMPLOYEE_OPTION_STATE.notAsked
    : EMPLOYEE_OPTION_STATE.ineligible;
}
