import { useQueryClient } from "@tanstack/react-query";
import { useDataMutation } from "./useDataMutation";
import {
  createGlobalEmployeeModels,
  createRosterModel,
  deleteGlobalEmployeeModels,
  updateGlobalEmployeeModel,
  updateGlobalEmployeeModelsQueue,
  updateLocationModel,
} from "../../utils/queryUtils/locationQuery";
import {
  deleteSolutionModels,
  updateRosterModel,
} from "../../utils/queryUtils/rosterQuery";
import { ACTION_TYPES } from "./useRosterModelMutation";
import { isObjectEmpty } from "../../utils";

export function useBareLocationMutation(locationID) {
  const queryClient = useQueryClient();

  const mutation = useDataMutation({
    queryKey: ["location", locationID],
    queryClient,
    mutationFn: async (mutateFnParam) => {
      const actionType = mutateFnParam.actionType;
      switch (actionType) {
        case ACTION_TYPES.createSnapshot: {
          const snapshotRoster = await createRosterModel(
            mutateFnParam.snapshotRosterInfo,
            mutateFnParam.customOwner
          );
          return snapshotRoster;
        }
        case ACTION_TYPES.createScheduleEmployees:
          updateLocationModel(locationID, {
            order: mutateFnParam.newLocation.order,
            completedOnboardingTasks:
              mutateFnParam.newLocation.completedOnboardingTasks,
          });
          return createGlobalEmployeeModels(
            mutateFnParam.newGlobalEmployees,
            mutateFnParam.locationOwner
          );
        case ACTION_TYPES.deleteScheduleEmployees:
          updateLocationModel(locationID, {
            order: mutateFnParam.newLocation.order,
            OpenShifts: mutateFnParam.newLocation.OpenShifts,
          });
          return deleteGlobalEmployeeModels(
            mutateFnParam.deletedGlobalEmployeeIDs
          );
        case ACTION_TYPES.updateGlobalEmployee: {
          const globalEmployeeUpdatedFields =
            mutateFnParam.globalEmployeeUpdatedFields;
          await updateGlobalEmployeeModel(globalEmployeeUpdatedFields);
          return globalEmployeeUpdatedFields;
        }
        case ACTION_TYPES.updateFields: {
          if (!isObjectEmpty(mutateFnParam.newLocationFields)) {
            await updateLocationModel(
              locationID,
              mutateFnParam.newLocationFields
            );
          }
          if (mutateFnParam.newEmployeesFields) {
            updateGlobalEmployeeModelsQueue(mutateFnParam.newEmployeesFields);
          }

          if (mutateFnParam.updatedOtherRosters) {
            const updateOtherRostersPromise = [];
            for (const updatedOtherRoster of mutateFnParam.updatedOtherRosters) {
              updateOtherRostersPromise.push(
                updateRosterModel(updatedOtherRoster.id, {
                  Employees: updatedOtherRoster.Employees,
                })
              );
            }
            if (updateOtherRostersPromise.length > 0) {
              await Promise.all(updateOtherRostersPromise);
            }
          }

          return mutateFnParam.newLocation;
        }
      }
    },
    getExpectedResultingData: (mutateFnParam) => {
      return mutateFnParam.newLocation;
    },
  });

  return { mutation, isLoading: mutation.isLoading };
}

export function useBareRosterMutation(rosterID) {
  const queryClient = useQueryClient();

  const mutation = useDataMutation({
    queryKey: ["roster", rosterID],
    queryClient,
    mutationFn: async (mutateFnParam) => {
      const actionType = mutateFnParam.actionType;
      switch (actionType) {
        case ACTION_TYPES.deleteRosterSolution:
          return deleteSolutionModels(mutateFnParam.deletedSolutionIDs);
        case ACTION_TYPES.updateFields:
          return await updateRosterModel(
            rosterID,
            mutateFnParam.newRosterFields
          );
      }
    },
    getExpectedResultingData: (mutateFnParam) => {
      return mutateFnParam.newRoster;
    },
  });

  return { mutation, isLoading: mutation.isLoading };
}
