import { useMemo } from "react";
import { getCustomKeywordsDataFromLocation } from "../../utils/queryUtils/locationDataGetters";
import { getRosterQueryFn, useBareLocationQuery } from "./useBareModelQueries";
import { useQueryClient } from "@tanstack/react-query";
import { getIds } from "../../utils";
import { useSearchParams } from "react-router-dom-v5-compat";
import { useUserStore } from "../../globalStore/appStore";

const getImpliedLocationID = (locations) => {
  if (locations && locations.length > 0) {
    const locationsExcludingDemo = locations.filter(
      (loc) => loc.name !== "Demo Location"
    );
    if (locationsExcludingDemo.length === 0) {
      return locations[0].id;
    }
    return locationsExcludingDemo[0].id;
  }
  return "";
};

export function useLocationQuery(
  locations,
  providedLocationID = "",
  shouldCache = false
) {
  const [searchParams] = useSearchParams();
  const queryLocationID = searchParams.get("location-id");

  const locationIDs = getIds(locations);
  let impliedLocationID = "";

  if (providedLocationID && locationIDs.includes(providedLocationID)) {
    impliedLocationID = providedLocationID;
  } else if (queryLocationID && locationIDs.includes(queryLocationID)) {
    impliedLocationID = queryLocationID;
  } else if (!queryLocationID && !providedLocationID) {
    impliedLocationID = getImpliedLocationID(locations);
  }

  const { location, globalEmployees, isLoading } =
    useBareLocationQuery(impliedLocationID);

  usePrefetchFirstNRosters(
    location?.isScheduleView ? 1 : 3,
    shouldCache,
    isLoading,
    location,
    globalEmployees
  );

  const customKeywordsData = useMemo(() => {
    if (location) {
      return getCustomKeywordsDataFromLocation(location);
    }
    return null;
  }, [location]);

  return {
    location,
    globalEmployees,
    isQueryLoading: isLoading,
    customKeywordsData,
  };
}

function usePrefetchFirstNRosters(
  N,
  shouldCache,
  isLoading,
  location,
  globalEmployees
) {
  const { plan } = useUserStore();
  const queryClient = useQueryClient();

  if (isLoading || !shouldCache) {
    return;
  }

  if (!location) {
    return;
  }

  let rostersToLoad = null;
  const isScheduleView = location?.isScheduleView;

  if (!isScheduleView) {
    const sortedRosters = location?.Rosters.items.sort((a, b) => {
      return Date.parse(b.createdAt) - Date.parse(a.createdAt);
    });

    rostersToLoad = sortedRosters?.slice(0, N);
  } else {
    const sortedRosters = location?.Rosters.items.sort((a, b) => {
      return Date.parse(a.startDate) - Date.parse(b.startDate);
    });

    const currentDate = new Date();
    let nextRosters = [];
    for (const roster of sortedRosters) {
      const rosterDate = Date.parse(roster.startDate);
      if (rosterDate > currentDate) {
        nextRosters.push(roster);
        if (nextRosters.length === N) {
          break;
        }
      }
    }

    rostersToLoad = nextRosters;
  }

  rostersToLoad.forEach((roster) => {
    const id = roster?.id;

    if (id) {
      queryClient.prefetchQuery({
        queryKey: ["roster", id],
        queryFn: () =>
          getRosterQueryFn(isScheduleView, id, globalEmployees, plan),
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
      });
    }
  });
}
