import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./RosterStatusPanel.module.css";

const RosterStatusPanel = ({ toggleExpandGrid }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  return (
    <button
      className={`${styles.actionBtn} ${styles.expandButton}`}
      onClick={() => {
        toggleExpandGrid();
        setIsFullScreen((prev) => !prev);
      }}
    >
      {isFullScreen ? (
        <>
          <FontAwesomeIcon icon={faCompress} className={styles.actionIcon} />
          Minimise View
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faExpand} className={styles.actionIcon} />
          Expand View
        </>
      )}
    </button>
  );
};

export default RosterStatusPanel;
