import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import RequestManagerModal from "../RequestManagerModal/RequestManagerModal";
import { v4 as uuidv4 } from "uuid";
import {
  DateTime,
  getAllRequestsFromGrid,
  getDuplicatedItemsInArray,
  getToBeDeletedRows,
  exportGridToCsv,
  getSelectedRows,
  compressRequests,
  flatOptions,
  getRequestOptions,
} from "../../../../../utils";
import {
  checkAllNamesAreFilledIn,
  checkAllRequestHasValidStartAndFinishDate,
  checkAllRequestsHasValidDateRange,
  checkOverlappingDateRangesInRequestManager,
} from "../../../../../utils/validationUtils/rosterValidations";
import { KEYWORD_OFF } from "../../../../../constants/keywords";

const sortRequests = (requests) => {
  const requestsCopy = [...requests];
  requestsCopy.sort(function (a, b) {
    if (a.employeeName < b.employeeName) {
      return -1;
    }
    if (a.employeeName > b.employeeName) {
      return 1;
    }
    return new DateTime(b.from).getDate() - new DateTime(a.from).getDate();
  });
  return requestsCopy;
};

const RequestManagerContainer = ({
  toggleRequestManager,
  employees,
  startDate,
  finishDate,
  applyRequests,
  overviewDataWithIndex,
  type,
  predefinedKeywords,
  enumeratedTasks,
  enumeratedShiftTasks,
  areas,
  skills,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const [requests, setRequests] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    const groupedAllocations = compressRequests(overviewDataWithIndex);
    const generatedRequests = groupedAllocations.map((item) => {
      return {
        id: uuidv4(),
        employeeName: item[0][0],
        from: new DateTime(startDate).addDays(item[0][1]).toFormat("AWS"),
        to: new DateTime(startDate)
          .addDays(item[item.length - 1][1])
          .toFormat("AWS"),
        shiftAndShiftGroup: item[0][2],
      };
    });

    const sortedRequests = sortRequests(generatedRequests);
    setRequests(sortedRequests);
  }, [startDate, overviewDataWithIndex]);

  const shiftOptions = useMemo(() => {
    if (type === "fixedShifts") {
      return getRequestOptions(
        predefinedKeywords,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts
      );
    } else {
      return getRequestOptions(
        [KEYWORD_OFF],
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts
      );
    }
  }, [
    predefinedKeywords,
    type,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    areas,
    enumeratedTasks,
    enumeratedShiftTasks,
    shortIdsToEntityNamesDicts,
  ]);

  const allowedOptions = useMemo(
    () => flatOptions(shiftOptions).map(({ value }) => value),
    [shiftOptions]
  );

  const setGridApiToParent = (gridApi) => {
    setGridApi(gridApi);
  };

  const [requestIssues, setRequestIssues] = useState({
    "Empty Name": "",
    "Invalid Date Ranges": "",
    "Invalid Date": "",
    "Invalid Allocations": [],
    "Overlapping Periods (employee names)": [],
    "Overlapping Periods (request IDs)": [],
  });

  const getRequestsIssues = useCallback(() => {
    if (gridApi) {
      let data = [];
      gridApi.forEachNode((rowNode) => {
        data = [...data, rowNode.data];
      });

      // Check for duplicated names
      const names = data.map((item) => item.employeeName);

      const shiftAndShiftGroups = data.map((item) =>
        item.shiftAndShiftGroup
          .replace("?", "")
          .replace("!", "")
          .replace("*", "")
      );

      const emptyNamesMsg = checkAllNamesAreFilledIn(names)
        ? ""
        : "Please fill in all employee names";

      const invalidShiftsAndShiftGroups = shiftAndShiftGroups.filter(
        (allocation) =>
          allocation !== "" && !allowedOptions.includes(allocation)
      );

      const invalidDateRangeMsg = checkAllRequestsHasValidDateRange(data)
        ? ""
        : "Finsh Date cannot be before Start Date";

      const invalidDateMsg = checkAllRequestHasValidStartAndFinishDate(
        data,
        startDate,
        finishDate
      )
        ? ""
        : "Dates must be within roster range";

      const nonUniqueNames = getDuplicatedItemsInArray(names);
      const dataToCheckOverlapingRanges = {};
      for (const item of data) {
        if (nonUniqueNames.includes(item.employeeName)) {
          dataToCheckOverlapingRanges[item.employeeName]
            ? dataToCheckOverlapingRanges[item.employeeName].push(item)
            : (dataToCheckOverlapingRanges[item.employeeName] = [item]);
        }
      }

      const overlappingRequests = checkOverlappingDateRangesInRequestManager(
        dataToCheckOverlapingRanges
      );

      const employeesWithOverlappingRequests = overlappingRequests.map(
        (item) => item[0].employeeName
      );

      return [
        emptyNamesMsg,
        invalidShiftsAndShiftGroups,
        invalidDateRangeMsg,
        invalidDateMsg,
        employeesWithOverlappingRequests,
        overlappingRequests,
      ];
    }
    return ["", [], "", "", "", "", [], []];
  }, [gridApi, startDate, finishDate, allowedOptions]);

  const updateRequestsIssues = useCallback(() => {
    const issues = getRequestsIssues();
    setRequestIssues({
      "Empty Name": issues[0],
      "Invalid Allocations": issues[1],
      "Invalid Date Ranges": issues[2],
      "Invalid Date": issues[3],
      "Overlapping Periods (employee names)": issues[4],
      "Overlapping Periods (request IDs)": issues[5],
    });
  }, [getRequestsIssues]);

  useEffect(() => {
    setTimeout(() => {
      updateRequestsIssues();
    }, 100);
  }, [requests, updateRequestsIssues]);

  const addNewRequest = (num) => {
    const defaultDate = new DateTime(startDate).toFormat("AWS");
    const newRequests = [];
    for (let i = 0; i < num; i++) {
      newRequests.push({
        id: uuidv4(),
        employeeName: "",
        shiftAndShiftGroup: "",
        from: defaultDate,
        to: defaultDate,
      });
    }

    setRequests((prevRequests) => [...newRequests, ...prevRequests]);
    setTimeout(() => {
      updateRequestsIssues();
    }, 100);
  };

  const removeRequests = (isFromContextMenu) => {
    const toDelete = isFromContextMenu
      ? getToBeDeletedRows(null, gridApi)
      : getSelectedRows(gridApi).map((item) => item.data);
    gridApi.applyTransaction({ remove: toDelete });

    const toDeleteIDs = toDelete.map((item) => item.id);
    setRequests((prevRequests) =>
      prevRequests.filter((item) => !toDeleteIDs.includes(item.id))
    );
  };

  const updateData = (newRequests) => {
    setRequests(newRequests);
  };

  const duplicateSelectedRequests = () => {
    const selectedRows = getSelectedRows(gridApi);
    const selectedRowIDs = selectedRows.map((node) => node.data.id);
    const requestsToAdd = [];

    for (let i = 0; i < requests.length; i++) {
      const originalRequest = requests[i];
      if (selectedRowIDs.includes(originalRequest.id)) {
        const newRequest = {
          id: uuidv4(),
          employeeName: originalRequest.employeeName,
          shiftAndShiftGroup: originalRequest.shiftAndShiftGroup,
          from: originalRequest.from,
          to: originalRequest.to,
        };
        requestsToAdd.push(newRequest);
      }
    }
    setRequests((prev) => {
      const updatedRequests = prev.concat(requestsToAdd);
      return sortRequests(updatedRequests);
    });
  };

  const submitRequets = () => {
    applyRequests(requests);
  };

  const handleCellValueChanged = () => {
    updateRequestsIssues();
  };

  const exportToCsv = useCallback(() => {
    exportGridToCsv(gridApi);
  }, [gridApi]);

  return (
    <div>
      {startDate && finishDate && (
        <RequestManagerModal
          toggleRequestManager={toggleRequestManager}
          requests={requests}
          employees={employees}
          addNewRequest={addNewRequest}
          removeRequests={removeRequests}
          updateData={updateData}
          onSubmitRequests={submitRequets}
          setGridApiToParent={setGridApiToParent}
          requestIssues={requestIssues}
          startDate={startDate}
          finishDate={finishDate}
          type={type}
          handleCellValueChanged={handleCellValueChanged}
          exportToCsv={exportToCsv}
          gridApi={gridApi}
          duplicateSelectedRequests={duplicateSelectedRequests}
          predefinedKeywords={predefinedKeywords}
          getDataFromGrid={getAllRequestsFromGrid}
          areas={areas}
          skills={skills}
          shifts={shifts}
          shiftGroups={shiftGroups}
          tasks={tasks}
          subTasks={subTasks}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          customKeywordsUtilObj={customKeywordsUtilObj}
          shiftOptions={shiftOptions}
        />
      )}
    </div>
  );
};

export default RequestManagerContainer;
