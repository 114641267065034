import { useMemo } from "react";
import {
  getAllTasksFromGrid,
  getDataInRows,
  getNames,
  parseTaskModelToTasksGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import TasksGrid from "../../../rosterProblems/components/Tasks/TasksGrid/TasksGrid";
import ModalViewer from "../../../../components/elements/Modal/ModalViewer";
import ScheduleTaskBlocksDataContainer from "../ScheduleTaskBlocksDataContainer/ScheduleTaskBlocksDataContainer";
import useModal from "../../../../hooks/useModal";
import { getNewTasksTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";

const ScheduleTasksDataContainer = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  isRoster,
  rosterID,
  customKeywordsData,
  periodNum,
  globalEmployees,
}) => {
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });
  const { warnings } = useWarningsStore();
  const { plan } = useUserStore();

  const {
    name,
    areas,
    tasks,
    taskBlocks,
    subTasks,
    shifts,
    shiftGroups,
    skills,
    numDays,
    isSnapshot,
  } = fields;
  const isMainStream = !isRoster && !isSnapshot;

  const applyTaskUpdates = (updatedTasks) => {
    const updatedRoster = { ...roster, Tasks: updatedTasks };
    updateFields(["Tasks"], updatedRoster, roster);
  };

  const { isShowing, toggle } = useModal();
  const { reservedShiftKeywords } =
    interpretCustomKeywordsData(customKeywordsData);

  const tasksWarnings = useMemo(() => {
    return warnings ? warnings.Tasks : null;
  }, [warnings]);

  const tasksData = useMemo(
    () => parseTaskModelToTasksGridFormat(tasks),
    [tasks]
  );

  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskBlockNames = useMemo(() => getNames(taskBlocks), [taskBlocks]);

  const addNewTasks = (numItems) =>
    addNewModels(
      "New Task",
      true,
      (option) => getNewTasksTemplate({ ...option, existingTasks: tasks }),
      numItems,
      tasks,
      applyTaskUpdates,
      false
    );
  const updateTasks = (newGridContent) =>
    updateModels(newGridContent, tasks, applyTaskUpdates);
  const duplicateTasks = (selectedTasksInfo) =>
    duplicateModels(
      selectedTasksInfo,
      [],
      tasks,
      applyTaskUpdates,
      "copy of ",
      "Task",
      true
    );

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "Task",
      roster,
      shortIdsToEntityNamesDicts
    );

  const removeTasks = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, tasks, applyTaskUpdates);
  const reorderTasksData = (params) =>
    reorderModelsData(params, getAllTasksFromGrid, tasks, applyTaskUpdates);

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  const TopControllerComponent = (
    <>
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"tasks"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </>
  );

  return (
    <>
      <GridActionHandler
        gridApi={gridApi}
        addNewItemToDB={addNewTasks}
        updateItemsToDB={updateTasks}
        duplicateItemsToDB={duplicateTasks}
        removeItemsFromDB={removeTasks}
        getDataFromGrid={getAllTasksFromGrid}
        getToBeDeletedItems={getToBeDeletedItems}
        parseSelectedRowsToDuplicableInfo={getDataInRows}
        getDeletionWarnings={getDeletionWarnings}
      >
        <TasksGrid
          tasksData={tasksData}
          numTasks={tasks.length}
          isSaving={isSaving}
          setGridApiToParent={setGridApi}
          gridApi={gridApi}
          tasksWarnings={tasksWarnings}
          skills={skills}
          reorderTasksData={reorderTasksData}
          taskBlockNames={taskBlockNames}
          shiftNames={shiftNames}
          shiftGroupNames={shiftGroupNames}
          reservedShiftKeywords={reservedShiftKeywords}
          toggleTaskBlocksModal={toggle}
          customTopComponent={TopControllerComponent}
          isScheduleView={!isRoster}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          rosterName={name}
          isMainStream={isMainStream}
        />
      </GridActionHandler>
      <ModalViewer
        isShowing={isShowing}
        firstBtnLabel="Done"
        onFirstBtnClick={() => {}}
        hide={toggle}
        modalWidth={"80vw"}
        noSecondButton={true}
      >
        <ScheduleTaskBlocksDataContainer
          location={location}
          locationID={locationID}
          periodStartDate={periodStartDate}
          isRoster={isRoster}
          rosterID={rosterID}
          reservedShiftKeywords={reservedShiftKeywords}
        />
      </ModalViewer>
    </>
  );
};

export default ScheduleTasksDataContainer;
