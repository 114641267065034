import { useMemo, useState } from "react";
import { DateTime } from "../../../../utils";
import styles from "./OpenShiftItem.module.css";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import LoadingSpinner from "../../../../components/elements/LoadingSpinner/LoadingSpinner";
import { getOpenShiftStatusInfo } from "../../../../utils/queryUtils/locationDataGetters";

const ChangeMyMindPopup = ({ isOpen, isAccepting, onClose, apply }) => {
  const [showLoading, setShowLoading] = useState(false);
  const handleSubmit = async () => {
    setShowLoading(true);
    await apply();
    setShowLoading(false);
    onClose();
  };

  const getMessageElement = () => {
    if (isAccepting) {
      return (
        <p className={styles.message}>
          The open shift you{" "}
          <span className={styles.declineColor}>DECLINED</span> earlier will now
          be picked up by you.
        </p>
      );
    }

    return (
      <p className={styles.message}>
        The open shift you have{" "}
        <span className={styles.acceptColor}>ACCEPTED</span> earlier will now
        open for others to pick up.
      </p>
    );
  };

  return (
    <ModalFrame open={isOpen} onClose={onClose}>
      <div className={styles.popup}>
        {showLoading && (
          <div className={styles.popupLoadingSpinner}>
            <LoadingSpinner color="#1459F4" />
          </div>
        )}
        <p className={styles.label}>Are you sure?</p>
        {getMessageElement()}
        <div className={styles.buttons}>
          <button className={styles.okButton} onClick={handleSubmit}>
            Yes
          </button>
          <button className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </ModalFrame>
  );
};

const OpenShiftItem = ({
  openShift,
  shouldAddTopSeparator,
  updateOpenShifts,
  employeeID,
  isDisplayedInDayView = false,
  customContainerStyle = {},
  customBottomPendingButtonsStyle = {},
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const isPastDateSelected = new DateTime().isAfter(openShift.date);

  const openShiftStatusInfo = useMemo(
    () => getOpenShiftStatusInfo(openShift),
    [openShift]
  );

  const openShiftState = useMemo(
    () =>
      openShift.employeeStates.find((state) => state.employeeID === employeeID)
        .state,
    [employeeID, openShift]
  );
  const isAccepted = openShiftState === "accepted";

  const accept = async () => {
    setShowLoading(true);
    await updateOpenShifts(openShift, true);
    setShowLoading(false);
  };

  const decline = async () => {
    setShowLoading(true);
    await updateOpenShifts(openShift, false);
    setShowLoading(false);
  };

  const changeMyMind = async () => {
    setShowLoading(true);
    if (openShiftState === "accepted") {
      await decline();
    }

    if (openShiftState === "declined") {
      await accept();
    }
    setShowLoading(false);
  };

  const getButtons = () => {
    if (isPastDateSelected) {
      return null;
    }
    if (openShiftState === "pending") {
      return (
        <div
          className={styles.openShiftButtons}
          style={customBottomPendingButtonsStyle}
        >
          <button className={styles.acceptButton} onClick={accept}>
            Accept
          </button>
          <button className={styles.declineButton} onClick={decline}>
            Decline
          </button>
        </div>
      );
    }

    return (
      <div className={styles.changeMyMindWrapper}>
        <p
          className={`${styles.status} ${
            isAccepted ? styles.acceptButton : styles.declineButton
          }`}
        >
          <b>Status:</b> {isAccepted ? "Accepted" : "Declined"}
        </p>
        <button
          className={styles.changeMyMindButton}
          onClick={() => setIsPopupOpen(true)}
        >
          Change my mind
        </button>
      </div>
    );
  };

  const getOpenShiftStatusComponent = () => {
    if (openShiftState !== "pending") {
      return null;
    }
    const { publishedNumber, numAccepted } = openShiftStatusInfo;
    let status;

    if (!isPastDateSelected && numAccepted < publishedNumber) {
      status = "Available";
    } else {
      status = "Not available";
    }

    return (
      <p className={styles.openShiftField}>
        <b>Status:</b> {status}
      </p>
    );
  };

  return (
    <>
      <ChangeMyMindPopup
        isOpen={isPopupOpen}
        isAccepting={!isAccepted}
        onClose={() => setIsPopupOpen(false)}
        apply={changeMyMind}
      />
      <div
        className={`${styles.openShift} ${
          shouldAddTopSeparator ? styles.firstElement : null
        }`}
        style={customContainerStyle}
      >
        <p className={styles.openShiftField}>
          <b>Date:</b>{" "}
          {new DateTime(openShift.date).toFormat("dd/MMM/yyyy - E")}
        </p>
        <p className={styles.openShiftField}>
          <b>Time:</b> {openShift.shiftTimeRange}
        </p>
        <p className={styles.openShiftField}>
          <b>Area:</b> {openShift.displayedArea}
        </p>
        <p className={styles.openShiftField}>
          <b>Shift:</b> {openShift.displayedShift}
        </p>
        <p className={styles.openShiftField}>
          <b>Task:</b> {openShift.displayedTask}
        </p>
        {getOpenShiftStatusComponent()}
        {getButtons()}
        {showLoading && (
          <div
            className={
              isDisplayedInDayView
                ? styles.simplifiedLoadingSpinner
                : styles.mainLoadingSpinner
            }
          >
            {!isDisplayedInDayView && (
              <p className={styles.updatingLabel}>Updating</p>
            )}
            <LoadingSpinner color="#1459F4" />
          </div>
        )}
      </div>
    </>
  );
};

export default OpenShiftItem;
