import styles from "./GridCellWithPlaceholderText.module.css";

const GridCellWithPlaceholderText = (props) => {
  if (props.value === "") {
    return <span className={styles.data} data-text={props.defaultValue}></span>;
  }

  const value =
    props.valueFormatted !== undefined ? props.valueFormatted : props.value;

  return <span>{value}</span>;
};

export default GridCellWithPlaceholderText;
