import { useMemo } from "react";
import PreferencesOverviewGrid from "../../../components/Preferences/PreferencesOverviewGrid/PreferencesOverviewGrid";

const IndPreferencesOverviewContainer = ({
  startDate,
  employeesData,
  dayColumns,
  updateData,
  setGridApiToParent,
  setColumnApiToParent,
  isSaving,
  warnings,
  changePrefLevel,
  enumeratedTasks,
  enumeratedShiftTasks,
  locationID,
  getDataFromGrid,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const overviewWarnings = useMemo(() => {
    return warnings ? warnings.Days : null;
  }, [warnings]);

  return (
    <PreferencesOverviewGrid
      startDate={startDate}
      employeesData={employeesData}
      dayColumns={dayColumns}
      setGridApiToParent={setGridApiToParent}
      toggleRequestManager={() => {}}
      updateData={updateData}
      setColumnApiToParent={setColumnApiToParent}
      isSaving={isSaving}
      shouldSpecifyWeeksInColHeader={true}
      overviewWarnings={overviewWarnings}
      weekdayColWidth={70}
      weekendColWidth={70}
      isWDayWEndSeparate={false}
      setWeekdayColWidth={() => {}}
      setWeekendColWidth={() => {}}
      setIsWDayWEndSeparate={() => {}}
      longestStr={""}
      isGlobal={false}
      changePrefLevel={changePrefLevel}
      approveSpecialRequests={() => {}}
      showManageBtn={false}
      showColAdjuster={false}
      showWarningBox={false}
      customStyle={{
        isSubheading: true,
        dataEntryTable: {
          height: "150px",
        },
      }}
      title={`Preferences Overview`}
      isIndividualView={true}
      getDateRangeSelector={() => {}}
      showActionBar={false}
      enumeratedTasks={enumeratedTasks}
      enumeratedShiftTasks={enumeratedShiftTasks}
      locationID={locationID}
      getDataFromGrid={getDataFromGrid}
      shifts={shifts}
      shiftGroups={shiftGroups}
      tasks={tasks}
      subTasks={subTasks}
      areas={areas}
      skills={skills}
      shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      customKeywordsUtilObj={customKeywordsUtilObj}
    />
  );
};

export default IndPreferencesOverviewContainer;
