import { DateTime } from "../dataTypesUtils/DateTime";

export const startTimeGetter = (params) => {
  if (params.data["startTime"]) {
    return DateTime.getFormattedTime(params.data["startTime"], "readable");
  }
};

export const finishTimeGetter = (params) => {
  if (params.data["finishTime"]) {
    return DateTime.getFormattedTime(params.data["finishTime"], "readable");
  }
};

export const startTimeFinishTimeDifferenceGetter = (
  startTime,
  finishTime,
  breakTime
) => {
  let start = new DateTime(
    "1970-01-01 " + DateTime.getFormattedTime(startTime, "AWS"),
    true
  )
    .getDate()
    .getTime();
  let end = new DateTime(
    "1970-01-01 " + DateTime.getFormattedTime(finishTime, "AWS"),
    true
  )
    .getDate()
    .getTime();
  let shiftHours = (end - start - breakTime * 60000) / 3600000;

  if (shiftHours < 0) {
    shiftHours += 24;
  }

  return shiftHours.toFixed(2);
};
