import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToggleShow } from "../../../hooks/useToggleShow";
import UserDropdownItem from "../UserDropdownItem/UserDropdownItem";
import styles from "./UserDropdown.module.css";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../globalStore/appStore";
import { getCompletableOnboardingTaskIDs } from "../../../features/onBoarding/service/onBoarding";

const UserDropdown = ({
  signOut,
  isMobile,
  isEmployeeView = false,
  location,
  isSnapshot = false,
}) => {
  const isScheduleView = location ? location.isScheduleView : false;
  const { setIsCheckListOpen } = useOnBoardingStore();
  const { userName: name, email, userInitials: acronym, plan } = useUserStore();
  const {
    isOpen,
    toggleDropdown,
    selectorRef: dropdownTriggerRef,
    selectorTriggerRef: dropdownRef,
  } = useToggleShow();

  const toggleUserDropdown = () => {
    setIsCheckListOpen(false);
    toggleDropdown();
  };

  const getTourCompletePercentage = () => {
    if (!location) {
      return null;
    }
    const completedOnboardingTasks = location.completedOnboardingTasks;
    if (!completedOnboardingTasks) {
      return null;
    }

    const completableOnboardingTaskIDs = getCompletableOnboardingTaskIDs();

    const percentageComplete =
      (completedOnboardingTasks.length /
        Object.values(completableOnboardingTaskIDs).length) *
      100;

    if (percentageComplete > 100) {
      return Math.max(100, percentageComplete);
    }

    return Math.round(percentageComplete);
  };

  const percentageComplete = getTourCompletePercentage();

  return (
    <>
      <div
        ref={dropdownTriggerRef}
        onClick={toggleUserDropdown}
        className={`${styles.wrapper} ${isSnapshot ? styles.snapshot : ""}`}
      >
        <p className={styles.acronym}>{acronym}</p>
        <div className={styles.chev}>
          {isOpen ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className={`${styles["dropdown-itemw-wrapper"]} ${
            isMobile ? styles.mobile : null
          }`}
          ref={dropdownRef}
        >
          <UserDropdownItem
            acronym={acronym}
            name={name}
            email={email}
            signOut={signOut}
            isMobile={isMobile}
            isEmployeeView={isEmployeeView}
            percentageComplete={percentageComplete}
            setIsCheckListOpen={setIsCheckListOpen}
            toggleDropdown={toggleDropdown}
            isScheduleView={isScheduleView}
            plan={plan}
          />
        </div>
      )}
    </>
  );
};

export default UserDropdown;
