import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "./LoadingSpinner.module.css";

const LoadingSpinner = ({ color }) => (
  <div className={styles.container}>
    <FontAwesomeIcon
      icon={faSpinner}
      style={{
        ...(color && { color }),
      }}
      className={styles.icon}
    />
  </div>
);

export default LoadingSpinner;
