import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./FixedShiftsLocked.module.css";

const FixedShiftsLocked = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>Fixed Shifts & Leave</h1>
    <UpgradeBanner
      title={"Unlock Fixed shifts & Leave with RosterLab AI plans!"}
      descriptions={[
        "Put through the leave requests or mandatory shifts and let the AI generate around those requests.",
      ]}
      showUpgradeButton={true}
      showBookDemoButton={true}
    />
  </div>
);

export default FixedShiftsLocked;
