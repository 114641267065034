import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./OpenShiftsSideTab.module.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState } from "react";
import { filterPastOpenShifts } from "../../../../../utils/queryUtils/locationDataGetters";
import OpenShiftItem from "../../OpenShiftItem/OpenShiftItem";

const OpenShiftsSideTab = ({
  toggleOpenShifts,
  myOpenShifts,
  userEmployee,
  updateOpenShifts,
}) => {
  const [shouldShowPastOpenShifts, setShouldShowPastOpenShifts] =
    useState(false);

  const displayedOpenShifts = useMemo(
    () =>
      shouldShowPastOpenShifts
        ? myOpenShifts
        : filterPastOpenShifts(myOpenShifts),
    [myOpenShifts, shouldShowPastOpenShifts]
  );

  return (
    <div className={styles.container}>
      <button className={styles.goBackButton} onClick={toggleOpenShifts}>
        <FontAwesomeIcon icon={faArrowLeft} /> Go back
      </button>
      <div className={styles.header}>
        <p className={styles.title}>Open Shifts</p>
        <button
          className={styles.toggleHistoryButton}
          onClick={() => setShouldShowPastOpenShifts(!shouldShowPastOpenShifts)}
        >
          {shouldShowPastOpenShifts ? "Hide History" : "Show History"}
        </button>
      </div>
      <div className={styles.openShifts}>
        {displayedOpenShifts.map((openShift, idx) => {
          return (
            <OpenShiftItem
              key={openShift.id}
              openShift={openShift}
              shouldAddTopSeparator={idx === 0}
              updateOpenShifts={updateOpenShifts}
              employeeID={userEmployee.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OpenShiftsSideTab;
