import styles from "./Step.module.css";
import { useWizard } from "react-use-wizard";
import Step1Video from "./step1.mp4";
import StepTemplate from "./StepTemplate";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import StepTitle from "./StepTitle";

const Step1 = () => {
  const { nextStep } = useWizard();

  return (
    <StepTemplate
      nextStep={nextStep}
      nextName="Let's get started"
      backName={null}
    >
      <div className={styles.container}>
        <div className={styles.leftItem}>
          <StepTitle fontAwesomeIcon={faClock} text={"2 mins"} />
          <h1 className={styles.title}>The AI Rostering Workflow</h1>
          <div className={`${styles.paragraphs} ${styles.stepOneParagraphs}`}>
            <p className={styles.paragraph}>
              <b>
                You enter your requirements, and we&apos;ll make you that
                roster!
              </b>
            </p>
            <p className={styles.paragraph}>
              For the initial set up, simply put in all your roster inputs, such
              as who your staff are (employees), what they can do (skills and
              rules), and when you need them (staffing demands).
            </p>
            <p className={styles.paragraph}>
              Don&apos;t worry if you&apos;ve missed any requirements. If the
              roster you&apos;ve generated is missing a detail, simply add in
              that requirement and then generate another roster!
            </p>
            <p className={styles.paragraph}>
              We&apos;ll also give you handy statistics about the generated
              rosters. That way you can be confident the rosters you publish are
              fit for purpose.
            </p>
          </div>
        </div>
        <div className={styles.rightItem}>
          <div className={styles.videoContainer}>
            <video className={styles.video} autoPlay muted>
              <source src={Step1Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </StepTemplate>
  );
};

export default Step1;
