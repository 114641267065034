import {
  DateTime,
  getShiftDurationString,
  getTimeDurationString,
} from "../../../../utils";
import styles from "./AllocationTimeframe.module.css";

const AllocationTimeframe = ({ allocation }) => {
  const { entityNames, entities } = allocation;
  const { shift, enumeratedTask } = entities;
  const { areaName, shiftName } = entityNames;
  const shiftDuration = getShiftDurationString(shift);

  return (
    <div className={styles.container}>
      {areaName && (
        <div className={`${styles.row} ${styles.area}`}>
          <span className={styles.label}>Area:</span>
          <span className={styles.timeFrame}>{areaName}</span>
        </div>
      )}
      {shiftName && (
        <div
          className={`${styles.row} ${styles.shift} ${
            !enumeratedTask && !areaName && styles.singleRow
          }`}
        >
          <span className={styles.label}>{shiftName}</span>
          <span className={styles.timeFrame}>{shiftDuration}</span>
        </div>
      )}
      {enumeratedTask &&
        enumeratedTask.subtaskDurations.map((info, idx) => (
          <div
            key={info.subtaskShortId}
            className={`${styles.row} ${
              idx === 0 ? styles.blockOne : styles.blockTwo
            }`}
          >
            <span className={styles.label}>{info.subtaskName}</span>
            <span className={styles.timeFrame}>
              {getTimeDurationString(
                DateTime.getTimeStringOfDate(info.startTime),
                DateTime.getTimeStringOfDate(info.finishTime)
              )}
            </span>
          </div>
        ))}
    </div>
  );
};

export default AllocationTimeframe;
