import styles from "./WarningDisplay.module.css";

const WarningDisplay = ({ title, displayedWarnings, bottomText = "" }) => {
  return (
    <div className={`${styles.container} ${styles["issues-container"]}`}>
      <span className={styles["issues-header"]}>{title}</span>
      <ul className={styles.issues}>
        {displayedWarnings.map((warning, idx) => (
          <li key={idx}>
            <span className={styles.emph}>{warning}</span>
          </li>
        ))}
      </ul>
      <span className={styles["issues-footer"]}>{bottomText}</span>
    </div>
  );
};

export default WarningDisplay;
