import _ from "lodash";

const debounceCache = {};

// debounceByKey function that replaces the function with the most recent call
export function debounceLastCallByKey(key, func, wait) {
  // If a debounced function for this key doesn't exist, create one
  if (!debounceCache[key]) {
    debounceCache[key] = _.debounce((...args) => {
      if (debounceCache[key].lastFunc) {
        debounceCache[key].lastFunc(...args);
      }
    }, wait);
  }

  // Store the latest function to be called when the debounce delay finishes
  debounceCache[key].lastFunc = func;

  // Return the existing debounced function, ensuring it keeps debouncing
  return debounceCache[key];
}
