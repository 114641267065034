import TwoOptionsSwitcher from "../../../../../components/elements/TwoOptionsSwitcher/TwoOptionsSwitcher";
import styles from "./CompareSolutionsToggle.module.css"; // Using CSS modules

const CompareSolutionsToggle = ({ onChange, isChecked, allowSwitch }) => {
  return (
    <div className={styles.compareSolutionsTable}>
      <div className={styles.solutionText}>Re-rostered Solution</div>
      {allowSwitch && (
        <TwoOptionsSwitcher
          onChange={onChange}
          isChecked={isChecked}
          uncheckedLabel="Before"
          checkedLabel="After"
        />
      )}
    </div>
  );
};

export default CompareSolutionsToggle;
