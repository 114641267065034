import styles from "./ImmutableRuleBlock.module.css";

const ImmutableRuleBlock = ({
  rule,
  ruleDescription,
  isRulesWithExceptionEnabled,
}) => {
  const canChangeDefaultValue = rule.canChangeDefaultValue;
  const inputs = rule.subrules[ruleDescription.subrule].inputs;

  let nounOptions = [];
  for (let i = 0; i < 4; i++) {
    let possibilities = new Set();
    if (i === 3) {
      rule.subrules
        .filter((subrule) => subrule.inputs[2] === inputs[2])
        .forEach((subrule) => possibilities.add(subrule.inputs[i]));
    } else if (i !== 3 && i !== 2) {
      rule.subrules
        .filter((subrule) => subrule.inputs[2] === inputs[2])
        .filter((subrule) => subrule.inputs[3] === inputs[3])
        .forEach((subrule) => possibilities.add(subrule.inputs[i]));
    } else {
      rule.subrules.forEach((subrule) => possibilities.add(subrule.inputs[i]));
    }

    let nounOption = [];

    for (let possibility of possibilities) {
      if (
        !isRulesWithExceptionEnabled &&
        possibility === "with exception" &&
        i === 0
      ) {
        continue;
      }
      nounOption.push({
        label: possibility,
        value: possibility,
      });
    }
    nounOptions.push(nounOption);
  }

  let valueInput = null;
  if (canChangeDefaultValue) {
    valueInput = (
      <span className={`${styles["alternative-text"]} ${styles.emph}`}>
        {ruleDescription.defaultValue}
      </span>
    );
  }

  let optionalInput = null;
  if (nounOptions[3].length > 1) {
    optionalInput = (
      <span className={`${styles["alternative-text"]} ${styles.emph}`}>
        {inputs[3]}
      </span>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles["sentence-text"]}>Employees</div>
      {nounOptions[0].length !== 1 && (
        <span className={`${styles["alternative-text"]} ${styles.emph}`}>
          {inputs[0]}
        </span>
      )}
      {nounOptions[0].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[0][0].value}
        </span>
      )}
      {nounOptions[1].length !== 1 && (
        <div className={styles.dropdownWrapper}>
          <span className={`${styles["alternative-text"]} ${styles.emph}`}>
            {inputs[1]}
          </span>
        </div>
      )}
      {nounOptions[1].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[1][0].value}
        </span>
      )}
      {valueInput}
      <div className={styles["sentence-text"]}>{rule.name} </div>
      {nounOptions[2].length !== 1 && (
        <div className={styles.dropdownWrapper}>
          <span className={`${styles["alternative-text"]} ${styles.emph}`}>
            {inputs[2]}
          </span>
        </div>
      )}
      {nounOptions[2].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[2][0].value}
        </span>
      )}
      {optionalInput}
    </div>
  );
};

export default ImmutableRuleBlock;
