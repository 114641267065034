import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";

export const configAggrid = () => {
  LicenseManager.setLicenseKey(
    "CompanyName=RosterLab,LicensedApplication=RosterLab,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032148,SupportServicesEnd=30_August_2023_[v2]_MTY5MzM1MDAwMDAwMA==4dd146035ac7e88afa16435224084fff"
  );

  // Register the required feature modules with the Grid
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    MenuModule,
    ExcelExportModule,
    RangeSelectionModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
    StatusBarModule,
  ]);
};
