import { generateClient } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

export async function generateGraphqlClient() {
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken?.toString();
  if (token) {
    let graphqlClient = generateClient({ authToken: token });
    return graphqlClient;
  } else {
    console.error("No valid idToken. Session: " + JSON.stringify(session));
    return null;
  }
}
