import styles from "./SimpleMobileHeader.module.css";

const SimpleMobileHeader = ({
  children,
  label,
  onLeftButtonClick,
  leftButtonContent,
}) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerLeft}>
        <button className={styles.leftButton} onClick={onLeftButtonClick}>
          {leftButtonContent}
        </button>
      </div>
      {label && <h1 className={styles.pageLabel}>{label}</h1>}
      {children && <div>{children}</div>}
    </header>
  );
};

export default SimpleMobileHeader;
