import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { components } from "react-select";
import { forwardRef } from "react";
import Select from "react-select";
import { strToArrCommaSeparated } from "../../../../utils";
import { customStyles } from "../../../../utils/agGridUtils/customCellStyles";

export const CheckboxOption = ({ label, ...props }) => {
  return (
    <components.Option label={label} {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          onChange={() => null}
          checked={props.isSelected}
          style={{ marginRight: "10px" }}
        />
        <label style={{ margin: "0", marginRight: "10px" }}>{label}</label>
      </div>
    </components.Option>
  );
};

export const parseInputValue = (
  inputStr,
  allOptions,
  selectNoneOption = false,
  multi = true
) => {
  const input = inputStr || "";

  if (input === "" && selectNoneOption) {
    return [selectNoneOption];
  }

  const idArr = strToArrCommaSeparated(input);
  const formattedInput = idArr.map((id) => {
    const option = allOptions.find((option) => option.value === id);
    const label = option ? option.label : id;
    return { label, value: id };
  });

  if (multi) return formattedInput;
  else {
    if (formattedInput.length > 0) return formattedInput[0];
    else return { label: "", value: "" };
  }
};

export const extractAllOptions = (options) => {
  if (options.length > 0 && options[0].label && options[0].value) {
    return options; // Already flat
  }
  return options.reduce(
    (acc, optionGroup) => [...acc, ...optionGroup.options],
    []
  );
};

const DropdownMultiSelector = forwardRef(
  (
    {
      options,
      width,
      selectNoneOption,
      selectAllOption,
      customIsOptionSelected,
      additionalOnSelectHandler,
      additionalOnDeselectHandler,
      isOptionDisabled,
      ...props
    },
    ref
  ) => {
    // parse current grid input to array of string
    // Format: ["shift1", "shift2", "shift3"]

    const allOptions = extractAllOptions(options);

    const value = props.value ? props.value : "";

    const [selected, setSelected] = useState(
      parseInputValue(value, allOptions, selectNoneOption)
    );
    const refInput = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (refInput.current) {
          refInput.current.focus();
        }
      }, 100);
    }, []);

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        // Format: "shift1, shift2, shift3" (string)
        getValue() {
          const selectedNames = selected.map((item) => item.value);

          if (
            selectAllOption &&
            selectedNames.includes(selectAllOption.value)
          ) {
            return selectAllOption.value;
          }
          return selectedNames.join(", ");
        },

        isPopup() {
          return true;
        },
      };
    });

    const isSelectAllSelected = () => {
      return selected.some((item) => item.value === selectAllOption.value);
    };

    const isSelectNoneSelected = () => {
      return selected.some((item) => item.value === selectNoneOption.value);
    };

    const defaultIsOptionSelected = (option) => {
      if (selectAllOption && isSelectAllSelected() && option.value !== "") {
        return true;
      }
      return selected.some(({ value }) => value === option.value);
    };

    const isOptionSelected = (option) => {
      if (customIsOptionSelected) {
        return customIsOptionSelected(option, selected);
      }
      return defaultIsOptionSelected(option);
    };

    const getValue = () => {
      return selected;
    };

    const getOptions = () => {
      // Switch label between "all" and "deselect all"
      if (selectAllOption) {
        const copyOptions = [...JSON.parse(JSON.stringify(options))];
        if (isSelectAllSelected()) {
          copyOptions[0].options[0]["label"] = "deselect all";
        } else {
          copyOptions[0].options[0]["label"] = selectAllOption.label;
        }
        return copyOptions;
      }

      return options;
    };

    return (
      <div style={{ width: `${width}px`, minWidth: "150px" }}>
        <Select
          options={getOptions()}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          styles={{
            ...customStyles,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          onChange={(selectedInputs, actionMeta) => {
            const { action, option } = actionMeta;

            switch (action) {
              case "select-option":
                if (additionalOnSelectHandler) {
                  const updatedSelected = additionalOnSelectHandler(
                    option,
                    selectedInputs
                  );
                  if (updatedSelected) {
                    setSelected([...updatedSelected]);
                    return;
                  }
                }
                if (
                  selectNoneOption &&
                  option.value === selectNoneOption.value
                ) {
                  setSelected([selectNoneOption]);
                  return;
                }

                if (selectAllOption && option.value === selectAllOption.value) {
                  setSelected([selectAllOption]);
                  return;
                }

                if (
                  selectAllOption &&
                  selectNoneOption &&
                  selectedInputs.filter(
                    (item) => item.value !== selectNoneOption.value
                  ).length === allOptions.length
                ) {
                  setSelected([selectAllOption]);
                  return;
                }

                if (
                  selectAllOption &&
                  selectedInputs.length === allOptions.length
                ) {
                  setSelected([selectAllOption]);
                  return;
                }

                if (selectNoneOption && isSelectNoneSelected()) {
                  setSelected(
                    selectedInputs.filter(
                      (item) => item.value !== selectNoneOption.value
                    )
                  );
                  return;
                }

                setSelected(selectedInputs);
                return;
              case "deselect-option":
                if (additionalOnDeselectHandler) {
                  const updatedSelected = additionalOnDeselectHandler(
                    option,
                    selectedInputs
                  );
                  if (updatedSelected) {
                    setSelected([...updatedSelected]);
                    return;
                  }
                }

                if (selectAllOption) {
                  if (option.value === selectAllOption.value) {
                    setSelected([]);
                    return;
                  }

                  if (isSelectAllSelected()) {
                    setSelected(
                      allOptions.filter((item) => {
                        if (selectAllOption) {
                          return (
                            item.value !== option.value &&
                            item.value !== selectAllOption.value
                          );
                        }
                        return item.value !== option.value;
                      })
                    );
                    return;
                  }

                  let toBeAppliedSelections = selectedInputs.filter(
                    (item) => item.value !== selectAllOption.value
                  );
                  if (selectNoneOption) {
                    toBeAppliedSelections = toBeAppliedSelections.filter(
                      (item) => item.value !== selectNoneOption.value
                    );
                  }
                  setSelected(toBeAppliedSelections);
                  return;
                }

                if (selectNoneOption) {
                  const selectedOptionWithoutNone = selectedInputs.filter(
                    (item) => item.value !== selectNoneOption.value
                  );
                  setSelected(selectedOptionWithoutNone);
                  return;
                }
                setSelected(selectedInputs);
                return;
              default:
                setSelected(selectedInputs);
                return;
            }
          }}
          ref={refInput}
          menuIsOpen={true}
          defaultValue={selected}
          components={{ Option: CheckboxOption }}
          value={getValue()}
          isOptionSelected={isOptionSelected}
          maxMenuHeight={250}
          isOptionDisabled={
            isOptionDisabled
              ? (...args) => isOptionDisabled(props.data, ...args)
              : undefined
          }
        />
      </div>
    );
  }
);

export default DropdownMultiSelector;
