import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * @returns URLSearchParams of current URL's query
 */
export function useRouteQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
