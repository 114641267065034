import { useEffect, useMemo, useState } from "react";
import {
  getAllEmployeesFromGrid,
  parseEmployeeModelToEmployeesGridFormat,
} from "../../../../../utils";
import EmployeesGrid from "../../../components/Employees/EmployeesGrid/EmployeesGrid";
import { hideColumnInGrid } from "../../../service/singleEmployeeViewUtils";

const IndSkillsAndShiftsContainer = ({
  isSaving,
  selectedEmployee,
  warnings,
  updateEmployeeWithFields,
  addSkillFromEmployeesTable,
  locationID,
  skills,
  shifts,
  shiftGroups,
  areas,
  isRoster,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);

  useEffect(() => {
    if (columnApi) {
      hideColumnInGrid([columnApi], ["name"]);
    }
  }, [columnApi]);

  const employeesData = useMemo(() => {
    if (!selectedEmployee) {
      return [];
    }
    return parseEmployeeModelToEmployeesGridFormat([selectedEmployee]);
  }, [selectedEmployee]);

  const employeesWarnings = useMemo(() => {
    return warnings ? warnings.Employees : null;
  }, [warnings]);

  const getDataFromGrid = (gridApi) => getAllEmployeesFromGrid(gridApi)[0];

  const updateEmployeesData = async (newEmployee) => {
    updateEmployeeWithFields(newEmployee, [
      "skills",
      "shifts",
      "areas",
      "externalID",
    ]);
  };

  const addSkill = async (skillName) => {
    addSkillFromEmployeesTable(skillName);
  };

  return (
    <EmployeesGrid
      employeesData={employeesData}
      numEmps={1}
      isSaving={isSaving}
      setGridApiToParent={setGridApi}
      addEmployee={() => {}}
      updateData={updateEmployeesData}
      exportToCsv={() => {}}
      duplicateSelectedEmployees={() => {}}
      employeesWarnings={employeesWarnings}
      updateSkillNameOptions={() => {}}
      removeEmployees={() => {}}
      gridApi={gridApi}
      showActionBar={false}
      showAddBtn={false}
      showRowCount={false}
      showWarningBox={false}
      customStyle={{
        isSubheading: true,
        dataEntryTable: {
          height: "80px",
        },
      }}
      suppressMenu={true}
      title={`Available Skills & Shifts`}
      setEmployeesColumnApiToParent={setColumnApi}
      minimalContextMenu={true}
      isIndividualView={true}
      createSkillFromEmployeesTable={addSkill}
      locationID={locationID}
      getDataFromGrid={getDataFromGrid}
      skills={skills}
      shifts={shifts}
      shiftGroups={shiftGroups}
      areas={areas}
      isScheduleView={!isRoster}
    />
  );
};

export default IndSkillsAndShiftsContainer;
