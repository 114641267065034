import { Hub } from "aws-amplify/utils";

let cancelAuthHub = null;

export const startAuthListener = (onEvent) => {
  cancelAuthHub = Hub.listen("auth", onEvent);
};

export const removeAuthListener = () => {
  if (cancelAuthHub) {
    cancelAuthHub();
    cancelAuthHub = null;
  }
};
