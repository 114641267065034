import { useCallback, useState } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalProps, setModalProps] = useState(null);

  const toggle = useCallback(
    (data) => {
      if (data) {
        if (isShowing) {
          setModalProps(null);
        } else {
          setModalProps(data);
        }
      }
      setIsShowing((prev) => !prev);
    },
    [isShowing]
  );

  return {
    isShowing,
    toggle,
    modalProps,
  };
};

export default useModal;
