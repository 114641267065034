import { memo } from "react";
import styles from "./LeaveCell.module.css";
import { getLeaveCountInfo } from "../../../service/employeeAppUtil";

const LeaveCell = memo(({ employeeLeaveCounts, leaveKeywordsDict, date }) => {
  const leaveCountInfo = getLeaveCountInfo(
    date,
    employeeLeaveCounts,
    leaveKeywordsDict
  );

  const leaveCodes = Object.values(leaveKeywordsDict);

  const createLeaveLabelComponents = () => {
    return leaveCodes.map((code) => {
      const targetLeave = leaveCountInfo.find(
        (info) => info.shortName === code
      );

      if (targetLeave) {
        return (
          <div
            key={targetLeave.label}
            className={`${styles[targetLeave.shortName]} ${
              styles.leaveNumberLabel
            }`}
          >
            {targetLeave.count}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={styles.cell}>
      <div className={styles.leaveCell}>{createLeaveLabelComponents()}</div>
    </div>
  );
});

export default LeaveCell;
