import { DateTime } from "../../../utils";

export const getRequestsForEmployeeInRosterRange = (
  employeeRequests,
  rosterStartDate,
  numDays,
  requestStatesToInclude,
  annualLeaveKeyword
) => {
  const dateRequests = Array(numDays).fill("");
  const rosterEndDate = new DateTime(rosterStartDate).addDays(numDays - 1);

  for (const request of employeeRequests) {
    const dates = DateTime.getAllDatesBetween(
      request.startDate,
      request.finishDate
    );
    for (const date of dates) {
      if (
        !date.isDateBetweenTwoDates(rosterStartDate, rosterEndDate, true, true)
      ) {
        continue;
      }

      if (!requestStatesToInclude.includes(request.state)) {
        continue;
      }
      const dateIdx = DateTime.getDifferenceInDays(rosterStartDate, date);
      if (request.request === "Annual leave") {
        dateRequests[dateIdx] = annualLeaveKeyword;
      }
    }
  }

  return dateRequests;
};
