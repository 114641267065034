import styles from "./AvailableShiftsListDisplayer.module.css";

const AvailableShiftsListDisplayer = ({
  employeeName,
  shiftNamesToDisplay,
}) => {
  if (shiftNamesToDisplay.length === 0) {
    return null;
  }
  return (
    <span className={styles.uniqueShiftsInfo}>
      Based on your shift groups, these are the shifts that {employeeName.name}{" "}
      can work:
      <span className={styles.emph}>{shiftNamesToDisplay.join(", ")}</span>
    </span>
  );
};

export default AvailableShiftsListDisplayer;
