import { useCallback, useState } from "react";
import styles from "./TaskBlocksGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import { useRef } from "react";
import { useMemo } from "react";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import Checkbox from "../../../../../components/elements/AggridCheckbox/AggridCheckbox";
import GridCellWithPlaceholderText from "../../../../grid/components/GridCellWithPlaceholderText/GridCellWithPlaceholderText";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultFilterValueGetter,
  dropdownOptionsValueSetter,
  finishTimeGetter,
  flatOptions,
  getNames,
  isInteger,
  onFilterTextBoxChanged,
  startTimeGetter,
  strToArrCommaSeparated,
  suppressEnterKey,
  timeSetter,
} from "../../../../../utils";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { isValidTaskName } from "../../../../../utils/validationUtils/sharedValidations";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

const TaskBlocksGrid = ({
  taskBlocksData,
  numTaskBlocks,
  isSaving,
  setGridApiToParent,
  addData: addNewTaskBlocks,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateTaskBlocks,
  taskBlocksWarnings,
  removeData: removeTaskBlocks,
  gridApi,
  reorderTaskBlocksData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  taskBlocks,
  reservedShiftKeywords,
  tasks,
  shifts,
  shiftGroups,
  getDataFromGrid,
  shortIdsToEntityNamesDicts,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeTaskBlocks(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const checkNameIsDuplicate = useCallback(
    (name) => {
      const names = [];
      if (gridApi) {
        gridApi.forEachNode((node) => {
          names.push(node.data.name);
        });
      }
      return names.includes(name);
    },
    [gridApi]
  );

  const taskNames = useMemo(() => getNames(tasks), [tasks]);

  const shiftAndShiftGroupNames = useMemo(
    () => [...getNames(shifts), ...getNames(shiftGroups)],
    [shifts, shiftGroups]
  );

  const selectAllTasksOption = useMemo(
    () => ({ label: KEYWORD_ALL, value: KEYWORD_ALL }),
    []
  );

  const taskOptions = useMemo(() => {
    return [
      {
        label: null,
        options: [selectAllTasksOption],
      },
      {
        label: "",
        options: convertToShortIdNameOptionForm(tasks),
      },
    ];
  }, [tasks, selectAllTasksOption]);

  const allTaskOptions = useMemo(() => {
    return convertToShortIdNameOptionForm(tasks);
  }, [tasks]);

  const getTaskBlockOptions = useMemo(() => {
    return (shortId) => {
      return convertToShortIdNameOptionForm(
        taskBlocks.filter((x) => x.shortId !== shortId)
      );
    };
  }, [taskBlocks]);

  const isWarningPresent = taskBlocksWarnings && taskBlocksWarnings.length > 0;

  const columnDefs = useMemo(
    () => [
      {
        suppressMovable: true,
        field: "name",
        suppressSizeToFit: true,
        width: 200,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "TaskBlock name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            reservedShiftKeywords
              .map((word) => word.toLowerCase())
              .includes(params.newValue.toLowerCase())
          ) {
            showWarningPopupIfNoOthersShowing(
              `${reservedShiftKeywords.join(", ")} are reserved names`,
              "Please use another name"
            );
            return;
          }

          if (checkNameIsDuplicate(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }

          if (!isValidTaskName(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Please use another name",
              `TaskBlock names cannot include '-' (hyphen) or ',' (comma)`
            );
            return false;
          }

          if (
            [...shiftAndShiftGroupNames, ...taskNames].includes(params.newValue)
          ) {
            showWarningPopupIfNoOthersShowing(
              "TaskBlock names must be different to any shift, shift group or task name",
              "Please use another name"
            );
            return false;
          }
          params.data.name = params.newValue.trim();
          return true;
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
      },
      {
        suppressMovable: true,
        field: "tasks",
        width: Math.max(300, width - 750 - 2),
        minWidth: 300,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            flatOptions(taskOptions),
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) => {
          const value = params.value;
          if (value === KEYWORD_ALL) {
            return KEYWORD_ALL;
          }
          return convertShortIdsCellValueToEntityNames(params.value, tasks);
        },
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "tasks", tasks, [KEYWORD_ALL]),
        suppressKeyboardEvent: suppressEnterKey,
        cellRenderer: "gridCellWithPlaceholderText",
        cellRendererParams: {
          defaultValue: "none required",
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: taskOptions,
          allowSelectAll: true,
          allOptions: allTaskOptions,
          selectAllOption: selectAllTasksOption,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (taskBlocksWarnings) {
              const taskBlockWarning = taskBlocksWarnings.find(
                ({ warningType }) => warningType === "invalid tasks"
              );
              if (params.value && taskBlockWarning) {
                const taskBlockNamesArr = strToArrCommaSeparated(params.value);
                for (const name of taskBlockNamesArr) {
                  if (taskBlockWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
      },
      {
        suppressMovable: true,
        field: "startTime",
        valueGetter: startTimeGetter,
        valueSetter: timeSetter,
        width: 130,
      },
      {
        suppressMovable: true,
        field: "finishTime",
        valueGetter: finishTimeGetter,
        valueSetter: timeSetter,
        width: 130,
      },
      {
        suppressMovable: true,
        field: "depth",
        valueSetter: (params) => {
          if (!params.newValue) {
            params.data.depth = 0;
            return true;
          }
          const value = Number(params.newValue.trim());
          if (isInteger(value)) {
            params.data.depth = value;
            return true;
          }
          showWarningPopupIfNoOthersShowing(
            "Please enter an integer number",
            `Depth must be an integer number`
          );
          return false;
        },
        width: 130,
      },
      {
        suppressMovable: true,
        field: "cantCombine",
        width: 160,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            getTaskBlockOptions(params.data.shortId),
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, taskBlocks),
        suppressKeyboardEvent: suppressEnterKey,
        cellRenderer: "gridCellWithPlaceholderText",
        cellRendererParams: {
          defaultValue: "none required",
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorSelector: (params) => {
          return {
            component: "dropdownMultiSelector",
            params: {
              options: getTaskBlockOptions(params.data.shortId),
            },
            popup: true,
          };
        },
      },
    ],
    [
      width,
      checkNameIsDuplicate,
      taskBlocksWarnings,
      taskOptions,
      taskNames,
      shiftAndShiftGroupNames,
      reservedShiftKeywords,
      allTaskOptions,
      selectAllTasksOption,
      getTaskBlockOptions,
      tasks,
      taskBlocks,
      shortIdsToEntityNamesDicts,
    ]
  );

  return (
    <Layout
      title="Task Blocks"
      headerRight={() => (
        <AddRowButton
          rowName="Task Block"
          addSingle={() => {
            if (!isCellEditing) addNewTaskBlocks(1);
          }}
          addOptions={[
            {
              label: "Add New",
              onClick: () => addNewTaskBlocks(1),
            },
            {
              label: "Add Multiple",
              onClick: () => {
                const num = parseInt(
                  prompt("How many taskBlocks should I add?", "1")
                );
                addNewTaskBlocks(num);
              },
            },
          ]}
        />
      )}
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles["above-grid"]}>
          <span className={styles.subtitle}>
            This table involves entering certain task blocks which specifies
            when certain sets of tasks are worked. For example you might
            separate your tasks into AM tasks and PM tasks.
          </span>
        </div>
        <ActionBar
          searchBarSettings={{
            tableName: "taskBlocks",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateTaskBlocks,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeTaskBlocks(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
        />
        <DataEntryTable
          customStyle={{ height: "300px" }}
          columnDefs={columnDefs}
          rowData={taskBlocksData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderTaskBlocksData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          components={{
            checkboxRenderer: Checkbox,
            dropdownMultiSelector: DropdownMultiSelector,
            gridCellWithPlaceholderText: GridCellWithPlaceholderText,
          }}
          enableRowDragAnimation={!isSaving}
          tableName="taskBlocks"
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
        />
        <p className={styles["num-line"]}>
          <span className={styles.emph}>Number of taskBlocks:</span>{" "}
          {numTaskBlocks}
        </p>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(taskBlocksWarnings)}
          />
        </div>
      )}
    </Layout>
  );
};

export default TaskBlocksGrid;
