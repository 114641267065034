import { DateTime } from "../dataTypesUtils/DateTime";

export function sortByDateField(
  arr,
  fieldName,
  isAscending,
  mutateOriginal = true
) {
  const targetArray = mutateOriginal ? arr : [...arr];
  targetArray.sort((a, b) => {
    const dateA = new DateTime(a[fieldName], true).getDate();
    const dateB = new DateTime(b[fieldName], true).getDate();
    return isAscending ? dateA - dateB : dateB - dateA;
  });
  return targetArray;
}

export function sortByAlphanumericField(arr, fieldName) {
  arr.sort(function (a, b) {
    return a[fieldName].localeCompare(b[fieldName]);
  });
}

export function sortByNumericField(array, fieldName) {
  array.sort((a, b) => a[fieldName] - b[fieldName]);
}
