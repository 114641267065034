import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ShiftViewWeekSwitcher.module.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function getWeeksInViewLabel(
  numWeeksPerView,
  numIntervalInView,
  totalNumWeeks
) {
  if (numWeeksPerView === 1) {
    return numIntervalInView;
  }

  if (numWeeksPerView === 2) {
    const startingWeekNumInView = numIntervalInView * 2 - 1;
    const endingWeekNumInView =
      startingWeekNumInView >= totalNumWeeks
        ? startingWeekNumInView
        : startingWeekNumInView + 1;

    if (startingWeekNumInView === endingWeekNumInView) {
      return startingWeekNumInView;
    }
    return `${startingWeekNumInView} - ${endingWeekNumInView}`;
  }

  return "";
}

const ShiftViewWeekSwitcher = ({
  switchNumIntervalInView,
  numIntervalInView,
  numWeeksPerView,
  totalNumWeeks,
}) => {
  const label = getWeeksInViewLabel(
    numWeeksPerView,
    numIntervalInView,
    totalNumWeeks
  );
  return (
    <div className={styles.weekSwitcher}>
      <button
        className={styles.switchButton}
        onClick={() => switchNumIntervalInView(false)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className={styles.weekLabel}>Week {label}</span>
      <button
        className={styles.switchButton}
        onClick={() => switchNumIntervalInView(true)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default ShiftViewWeekSwitcher;
