import { useQueryClient } from "@tanstack/react-query";
import { OpenShiftsAlreadyFulfilledError } from "../../../errors/errors";
import { updateEmployeeOpenShifts } from "../../../utils/queryUtils/locationQuery";
import { useEmployeeApplicationMutation } from "../../../hooks/modelQueryHooks/useEmployeeApplicationMutation";
import { DateTime } from "../../../utils";
import { customWarningAlert } from "../../confirm/service/confirm";

export function useEmployeeAppActions(location, userEmployee) {
  const queryClient = useQueryClient();

  const {
    addRegistrationToGlobalEmployee,
    updateGlobalPreferencesToEmployee,
    deleteRequestById,
    addRequest,
  } = useEmployeeApplicationMutation(location.id);

  const updateOpenShifts = async (openShift, shouldAccept) => {
    const updatedState = shouldAccept ? "accepted" : "declined";
    try {
      await updateEmployeeOpenShifts(
        location.id,
        userEmployee.id,
        openShift.id,
        updatedState
      );
    } catch (error) {
      if (error instanceof OpenShiftsAlreadyFulfilledError) {
        // This alert can be invoked somewhere else - with other UI code
        customWarningAlert({
          title: "Sorry!",
          descriptions: [
            "You're one step behind. This open shift has just been picked up by someone else! Keep an eye on other open shifts.",
          ],
        });
      }
    }
    queryClient.invalidateQueries({
      queryKey: ["employeeLocation", location.id],
    });
  };

  const addNewRequest = (startDate, finishDate, comment, selectedType) => {
    const requestInfo = {
      adminComment: null,
      employeeComment: comment,
      finishDate: finishDate.toFormat("AWS"),
      startDate: startDate.toFormat("AWS"),
      request: selectedType,
      state: "Pending",
      submittedBy: userEmployee.name,
      submittedDate: new DateTime(null, false).toFormat("AWS"),
    };
    addRequest(userEmployee, requestInfo);
  };

  const updatePreference = (updatedInfo) => {
    if (userEmployee) {
      updateGlobalPreferencesToEmployee(userEmployee, updatedInfo);
    }
  };

  return {
    updateOpenShifts,
    addRegistrationToGlobalEmployee,
    addNewRequest,
    deleteRequestById,
    updatePreference,
  };
}
