import { useCallback, useRef, useState } from "react";
import styles from "./RuleBuilderModalViewer.module.css";
import { Droppable, DragDropContext, Draggable } from "@hello-pangea/dnd";
import ShadowButton from "../../../../../components/elements/ShadowButton/ShadowButton";
import RuleSearcherModal from "../RuleSearcherModalContainer/RuleSearcherModalContainer";
import RuleBlock from "../RuleBlock/RuleBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import DropdownCollapsibleSelector from "../../../../../components/elements/DropdownCollapsibleSelector/DropdownCollapsibleSelector";
import NumRulesLimitInfo from "../../../../rules/components/NumRulesLimitInfo/NumRulesLimitInfo";
import { PLAN } from "../../../../auth/service/auth";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import { checkIsValidRuleValue } from "../../../../rules/service/rules";

const getWarnings = (ruleData) => {
  const errors = [];

  for (const singleRuleData of ruleData) {
    const { name, defaultValue, valueType, template, ruleCustomName } =
      singleRuleData;

    const isValid = checkIsValidRuleValue(defaultValue, valueType);

    if (!isValid) {
      errors.push({
        name,
        ruleCustomName,
        template,
        defaultValue,
        valueType,
      });
    }
  }

  return errors;
};

const parseRuleData = (
  rulesList,
  rules,
  isForNewRules = true,
  previousRulesInfo
) => {
  let ruleData = [];
  for (let i = 0; i < rulesList.length; i++) {
    const rule = rules.filter(
      (fullRule) => fullRule.name === rulesList[i].name
    )[0];
    const subrule = rule.subrules[rulesList[i].subrule];
    const defaultValue = rulesList[i].defaultValue;

    const ruleIndex = previousRulesInfo.findIndex(
      ({ id }) => rulesList[i].id === id
    );

    ruleData.push({
      name: rule.name,
      ruleCustomName: subrule.ruleName,
      template: subrule.ruleTemplate,
      defaultValue,
      initialDefaultValue: isForNewRules
        ? null
        : rulesList[i].initialDefaultValue,
      valueType: rule.valueType,
      rulePrevIndex: isForNewRules ? -1 : ruleIndex, // To remember the rule value index before updating the existing rules (Employee.ruleValues[ruleIndex])
    });
  }
  return ruleData;
};

const RuleBuilderModalViewer = ({
  addRule,
  addRules,
  updateCustomRules,
  rules,
  rulesList,
  setRulesList,
  handleClose,
  changeRuleValue,
  removeRule,
  setDefaultValue,
  selectedRule,
  setSelectedRule,
  existingRulesList,
  setExistingRulesList,
  isRulesWithExceptionEnabled,
  plan,
  previousRulesInfo,
}) => {
  const rulesContainerRef = useRef(null);
  const [ruleSearcherOpen, setRuleSearcherOpen] = useState(false);
  const [isNewRulesTab, setIsNewRulesTab] = useState(true);
  const [inputWarnings, setInputWarnings] = useState(null);

  const existingRuleInputs = existingRulesList.map(
    (ruleDescription, ruleInd) => {
      const rule = rules.filter(
        (fullRule) => fullRule.name === ruleDescription.name
      )[0];

      return (
        <Draggable
          draggableId={ruleDescription.id}
          index={ruleInd}
          key={ruleDescription.id}
        >
          {(provided) => (
            <RuleBlock
              provided={provided}
              innerRef={provided.innerRef}
              rule={rule}
              name={rule.name}
              formattedName={rule.formattedName}
              canChangeDefaultValue={
                rule.canChangeDefaultValue !== undefined &&
                rule.canChangeDefaultValue
              }
              defaultValue={ruleDescription.defaultValue}
              setDefaultValue={(value) =>
                setDefaultValue(ruleDescription.id, value, false)
              }
              changeRuleValue={(valueList) =>
                changeRuleValue(rule, ruleDescription.id, valueList, false)
              }
              inputs={rule.subrules[ruleDescription.subrule].inputs}
              removeRule={() => removeRule(ruleDescription.id, false)}
              initialDefaultValue={ruleDescription.initialDefaultValue}
              isChecked={rule.subrules[ruleDescription.subrule].isChecked}
              isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
              plan={plan}
              inputWarnings={
                inputWarnings ? inputWarnings.existingRuleDataErrors : null
              }
              calculateWarnings={calculateWarnings}
              menuPosition="fixed"
            />
          )}
        </Draggable>
      );
    }
  );

  const ruleInputs = rulesList.map((ruleDescription, ruleInd) => {
    const rule = rules.filter(
      (fullRule) => fullRule.name === ruleDescription.name
    )[0];

    return (
      <Draggable
        draggableId={ruleDescription.id}
        index={ruleInd}
        key={ruleDescription.id}
      >
        {(provided) => (
          <RuleBlock
            provided={provided}
            innerRef={provided.innerRef}
            rule={rule}
            name={rule.name}
            formattedName={rule.formattedName}
            canChangeDefaultValue={
              rule.canChangeDefaultValue !== undefined &&
              rule.canChangeDefaultValue
            }
            defaultValue={ruleDescription.defaultValue}
            setDefaultValue={(value) =>
              setDefaultValue(ruleDescription.id, value)
            }
            changeRuleValue={(valueList) =>
              changeRuleValue(rule, ruleDescription.id, valueList)
            }
            inputs={rule.subrules[ruleDescription.subrule].inputs}
            removeRule={() => removeRule(ruleDescription.id)}
            isChecked={rule.subrules[ruleDescription.subrule].isChecked}
            isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
            plan={plan}
            inputWarnings={inputWarnings ? inputWarnings.ruleDataErrors : null}
            calculateWarnings={calculateWarnings}
            menuPosition="fixed"
          />
        )}
      </Draggable>
    );
  });

  const calculateWarnings = useCallback(() => {
    const ruleData = parseRuleData(rulesList, rules, true, previousRulesInfo);
    const existingRuleData = parseRuleData(
      existingRulesList,
      rules,
      false,
      previousRulesInfo
    );

    const ruleDataErrors = getWarnings(ruleData);
    const existingRuleDataErrors = getWarnings(existingRuleData);

    if (ruleDataErrors.length > 0 || existingRuleDataErrors.length > 0) {
      setInputWarnings({
        ruleDataErrors,
        existingRuleDataErrors,
      });
    } else {
      setInputWarnings(null);
    }

    return {
      ruleDataErrors,
      existingRuleDataErrors,
    };
  }, [rules, rulesList, existingRulesList, previousRulesInfo]);

  const onDragEnd = (result, isForNewRules = true) => {
    if (!result.destination) {
      return;
    }

    const rulesListCopy = isForNewRules
      ? [...rulesList]
      : [...existingRulesList];
    var element = rulesListCopy[result.source.index];
    rulesListCopy.splice(result.source.index, 1);
    rulesListCopy.splice(result.destination.index, 0, element);
    if (isForNewRules) {
      setRulesList(rulesListCopy);
    } else {
      setExistingRulesList(rulesListCopy);
    }
  };

  if (ruleSearcherOpen) {
    return (
      <RuleSearcherModal
        title={"Rule searcher"}
        rules={rules}
        addRules={addRules}
        setRuleSearcherOpen={setRuleSearcherOpen}
      />
    );
  }

  const generalRules = rules.filter((rule) => rule.type === "general");
  const shiftRules = rules.filter((rule) => rule.type === "shift");
  const taskRules = rules.filter((rule) => rule.type === "task");
  const advancedRules = rules.filter((rule) => rule.type === "advanced");
  const generalRulesOptions = generalRules.map((rule) => ({
    label: rule.formattedName,
    value: rule.name,
  }));
  const shiftRulesOptions = shiftRules.map((rule) => ({
    label: rule.formattedName,
    value: rule.name,
  }));
  const taskRulesOptions = taskRules.map((rule) => ({
    label: rule.formattedName,
    value: rule.name,
  }));
  const advancedRulesOptions = advancedRules.map((rule) => ({
    label: rule.formattedName,
    value: rule.name,
  }));

  const options = [
    {
      label: "General rules",
      options: generalRulesOptions,
    },
    {
      label: "Shift related rules",
      options: shiftRulesOptions,
    },
    {
      label: "Task related rules",
      options: taskRulesOptions,
    },
    {
      label: "Advanced rules",
      options: advancedRulesOptions,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Create your own rules</h2>
        <div>
          <div className={styles["row-one"]}>
            <div className={styles["rule-selector-wrapper"]}>
              <span className={styles.description}>
                I&apos;d like to constrain on
              </span>
              <DropdownCollapsibleSelector
                options={options}
                onChange={(selectedInput) => {
                  setSelectedRule(selectedInput);
                }}
                defaultValue={selectedRule}
                customWrapperStyle={{
                  width: 250,
                }}
                id="rule-type-selector"
                menuPosition="fixed"
              />
              {plan === PLAN.MID && (
                <NumRulesLimitInfo
                  numRules={[...rulesList, ...existingRulesList].length}
                />
              )}
            </div>
            <div className={styles["function-btns"]}>
              <ShadowButton
                backgroundColor="#219ec9"
                hoverColor="#1f91b7"
                labelColor="white"
                border="none"
                shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
                onClick={() => {
                  addRule(selectedRule.value);
                  setIsNewRulesTab(true);
                }}
                borderRadius="10px"
                fontSize="16px"
                height="40px"
                dataTestId={"add-rule-btn"}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={styles.icon}
                  />
                  <span className={styles["upper-btn-label"]}>Add rule</span>
                </div>
              </ShadowButton>
              <ShadowButton
                backgroundColor="white"
                labelColor="#219ec9"
                hoverColor="#219ec9"
                border="1px solid #219ec9"
                shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
                onClick={() => setRuleSearcherOpen(true)}
                borderRadius="10px"
                fontSize="16px"
                height="40px"
              >
                <div>
                  <FontAwesomeIcon icon={faSearch} className={styles.icon} />
                  <span className={styles["upper-btn-label"]}>
                    Search all rules
                  </span>
                </div>
              </ShadowButton>
            </div>
          </div>
          <div className={styles["row-two"]}>
            <span className={styles["select-explanation"]}>
              *Shifts and shift groups are from the Roster shifts and Roster
              shift groups tables
            </span>
          </div>
        </div>
        <nav className={styles.tabs}>
          <div
            className={`${styles.tab} ${isNewRulesTab && styles.active}`}
            onClick={() => {
              setIsNewRulesTab(true);
            }}
          >
            New Rules
          </div>
          <div
            className={`${styles.tab} ${!isNewRulesTab && styles.active}`}
            onClick={() => {
              setIsNewRulesTab(false);
            }}
          >
            Existing Rules
          </div>
        </nav>
        <div className={styles["rules-container"]} ref={rulesContainerRef}>
          {isNewRulesTab ? (
            <DragDropContext onDragEnd={(result) => onDragEnd(result, true)}>
              <Droppable droppableId={"onlyDroppable"} index={0}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {ruleInputs}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <DragDropContext onDragEnd={(result) => onDragEnd(result, false)}>
              <Droppable droppableId={"onlyDroppable"} index={1}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {existingRuleInputs}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        <p className={styles.fixedDescription}>
          * Only whole numbers are allowed for entries. Number ranges are for
          information displaying. To customize rule values, please edit in the
          Rules table.
        </p>
        {plan === PLAN.LITE && (
          <p className={styles.explanation}>
            Only the unique rules from the new template you&apos;re selecting
            are shown in the &quot;New Rules&quot; tab.
          </p>
        )}
        {inputWarnings && <InputErrorDisplayer inputWarnings={inputWarnings} />}
        <div className={styles["lower-btns-container"]}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={async () => {
              const { ruleDataErrors, existingRuleDataErrors } =
                calculateWarnings();

              if (
                ruleDataErrors.length > 0 ||
                existingRuleDataErrors.length > 0
              ) {
                return;
              }

              const ruleData = parseRuleData(
                rulesList,
                rules,
                true,
                previousRulesInfo
              );
              const existingRuleData = parseRuleData(
                existingRulesList,
                rules,
                false,
                previousRulesInfo
              );

              const columnNames = [];
              ruleData.forEach((r) => columnNames.push(r.ruleCustomName));
              existingRuleData.forEach((r) =>
                columnNames.push(r.ruleCustomName)
              );

              const columnNamesSet = new Set(columnNames);
              if (columnNamesSet.size !== columnNames.length) {
                customWarningAlert({
                  title: "Warning",
                  descriptions: ["Cannot have duplicate rules!"],
                });
                return;
              }
              await updateCustomRules(ruleData, existingRuleData);
              handleClose();
            }}
          >
            OK
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            labelColor="#219ec9"
            hoverColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={() => handleClose()}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

const InputErrorDisplayer = ({ inputWarnings }) => {
  if (!inputWarnings) {
    return null;
  }

  const { ruleDataErrors, existingRuleDataErrors } = inputWarnings;

  return (
    <div className={styles.inputErrorDisplayer}>
      <p className={styles.inputErrorDisplayerLabel}>
        Some rules have invalid value. Please edit the following rules:
      </p>
      {ruleDataErrors.length > 0 && (
        <div className={styles.inputErrorDisplayerBlock}>
          <p>New Rules:</p>
          <p>{ruleDataErrors.map((data) => data.ruleCustomName).join(", ")}</p>
        </div>
      )}
      {existingRuleDataErrors.length > 0 && (
        <div className={styles.inputErrorDisplayerBlock}>
          <p>Existing Rules:</p>
          <p>
            {existingRuleDataErrors
              .map((data) => data.ruleCustomName)
              .join(", ")}
          </p>
        </div>
      )}
    </div>
  );
};

export default RuleBuilderModalViewer;
