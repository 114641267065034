import styles from "./RostersGrid.module.css";
import { AgGridReact } from "@ag-grid-community/react";
import DeleteButton from "../DeleteButton/DeleteButton";
import EditButton from "../EditButton/EditButton";
import DuplicateButton from "../DuplicateButton/DuplicateButton";
import DownloadRosterButton from "../DownloadRosterButton/DownloadRosterButton";
import {
  DateTime,
  defaultValueSetter,
  getRowId,
  insertEmptyStrOnPressBackspace,
  isInteger,
  parseRosterListToGridFormat,
  setDefaultSortModel,
  suppressDeleteAndBackspaceKey,
} from "../../../../utils";
import { showWarningPopupIfNoOthersShowing } from "../../../../utils/uiUtils/popup";
import {
  DatePickerEditor,
  DatePickerRenderer,
} from "../../../../components/elements/DatePicker/DatePicker";

const RostersGrid = ({
  downloadRoster,
  deleteRoster,
  rosters,
  toggleDuplicateRosterModal,
  updateRoster,
}) => {
  const WrapperDuplicateButtonRenderer = (props) => (
    <DuplicateButton
      {...props}
      toggleDuplicateRosterModal={toggleDuplicateRosterModal}
    />
  );

  const WrappedDeleteButtonRenderer = (props) => (
    <DeleteButton {...props} deleteRow={deleteRoster} />
  );

  const WrapperDownloadButtonRenderer = (props) => (
    <DownloadRosterButton {...props} downloadRoster={downloadRoster} />
  );

  const rowData = parseRosterListToGridFormat(rosters);

  const createdAtComparator = (valueA, valueB, nodeA, nodeB) => {
    const date1 = new DateTime(nodeA.data.createdAtFull).getDate();
    const date2 = new DateTime(nodeB.data.createdAtFull).getDate();

    if (date1 === date2) return 0;
    return date1 > date2 ? 1 : -1;
  };

  const columnDefs = [
    {
      field: "name",
      sortable: true,
      editable: true,
      minWidth: 250,
      suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
      valueGetter: (params) => {
        return params.data.name;
      },
      valueSetter: (params) => {
        const rosterID = params.data.id;
        const otherRosterNames = rosters
          .filter((roster) => roster.id !== rosterID)
          .map((roster) => roster.name);

        if (insertEmptyStrOnPressBackspace(params)) {
          return false;
        }

        if (!params.newValue.trim()) {
          return false;
        }

        if (params.newValue.trim().length >= 36) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Name",
            "Roster name is too long!"
          );
          return false;
        }

        if (params.newValue.includes(".")) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Name",
            "Roster name cannot have '.' in it. Please rename your roster."
          );
          return false;
        }

        if (otherRosterNames.includes(params.newValue.trim())) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Name",
            "Roster name already exists"
          );
          return false;
        }

        params.data.name = params.newValue.trim();
        return true;
      },
    },
    {
      headerName: "Duration (weeks)",
      field: "numWeeks",
      sortable: true,
      editable: true,
      suppressSizeToFit: true,
      width: 170,
      valueGetter: (params) => {
        return params.data.numWeeks;
      },
      valueSetter: (params) => {
        const numWeeks = parseInt(params.newValue);
        if (!isInteger(numWeeks)) {
          return false;
        }
        if (numWeeks > 20) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Duration",
            "Cannot roster for more than 16 weeks!"
          );
          return false;
        }
        if (numWeeks < 2) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Duration",
            "Minimum number of weeks is 2 weeks"
          );
          return false;
        }

        if (numWeeks % 2 === 1) {
          showWarningPopupIfNoOthersShowing(
            "Invalid Roster Duration",
            "Cannot roster for an uneven number of weeks!"
          );
          return false;
        }

        if (params.oldValue < numWeeks) {
          showWarningPopupIfNoOthersShowing(
            "Warning",
            "Remember to make sure your demands and fixed shifts are correct."
          );
        }
        params.data.numWeeks = numWeeks;
        return true;
      },
    },
    {
      field: "startDate",
      sortable: true,
      editable: true,
      suppressSizeToFit: true,
      width: 150,
      cellEditor: "datePickerEditor",
      cellEditorParams: {
        customStyle: {
          height: "40px",
          width: "150px",
          fontSize: "14px",
        },
      },
      cellEditorPopup: true,
      cellRenderer: "datePickerRenderer",
      valueGetter: (params) => {
        return params.data.startDate;
      },
      valueSetter: (params) => defaultValueSetter(params),
      suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
    },
    {
      field: "createdAt",
      sortable: true,
      comparator: createdAtComparator,
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "edit",
      cellRenderer: EditButton,
      suppressSizeToFit: true,
      width: 130,
    },
    {
      field: "duplicate",
      cellRenderer: WrapperDuplicateButtonRenderer,
      suppressSizeToFit: true,
      width: 130,
    },
    {
      field: "download",
      cellRenderer: WrapperDownloadButtonRenderer,
      suppressSizeToFit: true,
      width: 130,
    },
    {
      field: "delete",
      cellRenderer: WrappedDeleteButtonRenderer,
      suppressSizeToFit: true,
      width: 130,
    },
  ];

  return (
    <div
      className={`ag-theme-alpine ${styles["grid-container"]}`}
      data-testid="dashboard-grid"
    >
      <AgGridReact
        getRowId={getRowId}
        onGridReady={(params) => {
          setDefaultSortModel(params, [
            {
              colId: "createdAt",
              sort: "desc",
              sortIndex: 0,
            },
          ]);
        }}
        defaultColDef={{
          resizable: true,
          suppressMovable: true,
        }}
        onCellValueChanged={updateRoster}
        rowData={rowData}
        columnDefs={columnDefs}
        suppressColumnVirtualisation={true}
        components={{
          datePickerEditor: DatePickerEditor,
          datePickerRenderer: DatePickerRenderer,
        }}
        gridOptions={{ stopEditingWhenCellsLoseFocus: true }}
      ></AgGridReact>
    </div>
  );
};

export default RostersGrid;
