const ConditionalCellRenderer = ({
  RendererA,
  RendererB,
  shouldShowB,
  ...props
}) => {
  if (shouldShowB) {
    return <RendererB {...props} />;
  }
  return <RendererA {...props} />;
};

export default ConditionalCellRenderer;
