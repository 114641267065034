import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./OpenShiftsCellEditor.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowUp,
  faCirclePlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  DateTime,
  getOutOfScreenPixels,
  sortOpenShifts,
} from "../../../../utils";
import {
  getOpenShiftStatusInfo,
  separateAcceptedOpenShiftsAsEntity,
} from "../../../../utils/queryUtils/locationDataGetters";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import ConfirmBox from "../../../../components/elements/ConfirmBox/ConfirmBox";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
// import { PAGE_NAMES, useRedirect } from "../../../../routes/api/redirect";

export const getSelectedDate = (startDate, columnName) => {
  const dayIdx = Number(columnName.slice(1)) - 1;
  const date = new DateTime(startDate).addDays(dayIdx).toFormat("AWS");
  return date;
};

export const OpenShiftsCellRenderer = (props) => {
  return (
    <button
      className={styles.cellRenderer}
      onClick={() => {
        props.api.startEditingCell({
          rowIndex: props.rowIndex,
          colKey: props.column.colId,
          rowPinned: "top",
        });
      }}
    >
      {props.value}
    </button>
  );
};

const OpenShiftsCellEditor = forwardRef(
  (
    {
      openOpenShiftsModal,
      startDate,
      openShifts,
      deleteOpenShift,
      employeesData,
      ...props
    },
    ref
  ) => {
    const { shifts, tasks } = props.context;
    const { plan } = useUserStore();
    const innerRef = useRef();
    const [horizontalOverFlowOffset, setHorizontalOverFlowOffset] = useState(0);

    const [isOpenShiftDeleteConfirmOpen, setIsOpenShiftDeleteConfirmOpen] =
      useState(false);
    const [selectedOpenShiftID, setSelectedOpenShiftID] = useState(null);

    const openDeleteConfirm = (openShiftID) => {
      setSelectedOpenShiftID(openShiftID);
      setIsOpenShiftDeleteConfirmOpen(true);
    };

    const closeDeleteConfirm = () => {
      setSelectedOpenShiftID(null);
      setIsOpenShiftDeleteConfirmOpen(false);
    };

    const columnName = props.colDef.field;

    const selectedDate = getSelectedDate(startDate, columnName);
    const isPastDateSelected = new DateTime().isAfter(selectedDate);

    const handleOpen = (
      selectedOpenShiftID = null,
      selectedOpenShiftsDisplayedInfo = null
    ) => {
      openOpenShiftsModal(
        selectedDate,
        selectedOpenShiftID,
        selectedOpenShiftsDisplayedInfo
      );
    };

    const handleDeleteOpenShifts = async (selectedOpenShiftID) => {
      deleteOpenShift(selectedOpenShiftID);
      closeDeleteConfirm();
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return null;
        },
        isPopup() {
          return true;
        },
      };
    });

    const targetOpenShifts = useMemo(() => {
      const separatedOpenShift = separateAcceptedOpenShiftsAsEntity(
        openShifts
      ).filter((openShift) => openShift.date === selectedDate);
      sortOpenShifts(separatedOpenShift);
      return separatedOpenShift;
    }, [openShifts, selectedDate]);

    useEffect(() => {
      if (innerRef.current) {
        const offset = getOutOfScreenPixels(innerRef.current);
        setHorizontalOverFlowOffset(offset);
      }
    }, []);

    /**
     * Mid users - TBD
     */
    if (plan === PLAN.LITE) {
      return (
        <div className={styles.freeVersionAlertWrapper}>
          <div
            ref={innerRef}
            className={styles.freeVersionAlert}
            style={{
              left: `-${horizontalOverFlowOffset}px`,
            }}
          >
            <div className={styles.versionRow}>
              <div className={styles.versionRowGroup}>
                <FontAwesomeIcon
                  icon={faCircleArrowUp}
                  className={styles.upgradeIcon}
                />
                <p className={styles.planLabel}>Upgrade to Premium</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (targetOpenShifts.length === 0) {
      return (
        <div className={styles.adderWrapper}>
          <div
            className={styles.buttonWrapper}
            ref={innerRef}
            style={{
              left: `-${horizontalOverFlowOffset}px`,
            }}
          >
            {isPastDateSelected ? (
              <div className={styles.pastDateAlert}>
                You cannot create open shifts in the past.
              </div>
            ) : (
              <button
                className={styles.addOpenShiftsButton}
                onClick={() => handleOpen(null)}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className={styles.plusIcon}
                />
                Add New Open Shifts
              </button>
            )}
          </div>
        </div>
      );
    }

    const getStatusLabelComponent = (openShift) => {
      const { displayedInfo, isPublished } = openShift;
      const { isAccepted, employeeStates } = displayedInfo;

      const { publishedNumber, numDeclined, numPending } =
        getOpenShiftStatusInfo(displayedInfo);
      if (isAccepted) {
        const employeeID = employeeStates[0].employeeID;
        const employeeName = employeesData.find(
          (data) => data.id === employeeID
        ).name;
        return (
          <span className={`${styles.status} ${styles.accepted}`}>
            Accepted by {employeeName}
          </span>
        );
      }

      if (!isPublished) {
        return (
          <span className={`${styles.status} ${styles.notAsked}`}>
            Not Asked
          </span>
        );
      }

      // when all invited employees declines
      if (publishedNumber <= numDeclined && numPending === 0) {
        return (
          <span className={`${styles.status} ${styles.declined}`}>
            Declined
          </span>
        );
      }

      return (
        <span className={`${styles.status} ${styles.pending}`}>Pending</span>
      );
    };

    return (
      <div className={styles.statusWrapper}>
        <div
          className={styles.container}
          ref={innerRef}
          style={{
            left: `-${horizontalOverFlowOffset}px`,
          }}
        >
          <ModalFrame
            open={isOpenShiftDeleteConfirmOpen}
            onClose={closeDeleteConfirm}
          >
            <ConfirmBox
              heading="Are you sure?"
              description="Deleted open shifts will also be notified to your staff who have already picked up the shift."
              okLabel="Delete open shift"
              cancelLabel="Cancel"
              onOk={() => handleDeleteOpenShifts(selectedOpenShiftID)}
              onCancel={closeDeleteConfirm}
              customStyle={{
                width: "400px",
              }}
            />
          </ModalFrame>
          <table>
            <thead className={styles.tableHeader}>
              <tr>
                <th>Shift (Skill) - Task</th>
                <th>Open Shift</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {targetOpenShifts.map((option, idx) => {
                const { shift, task } = option;
                const targetShift = shifts.find(
                  ({ shortId }) => shortId === shift
                );
                const targetTask = tasks.find(
                  ({ shortId }) => shortId === task
                );

                const { displayedInfo } = option;
                return (
                  <tr key={idx}>
                    <td>{`${targetShift ? targetShift.name : ""}${
                      targetTask ? ` - ${targetTask.name}` : ""
                    }`}</td>
                    <td>
                      <span className={styles.numberCell}>
                        {displayedInfo.number}
                      </span>
                    </td>
                    <td>{getStatusLabelComponent(option)}</td>
                    <td>
                      <button
                        className={styles.containerButton}
                        onClick={() => {
                          handleOpen(option.id, displayedInfo);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.containerButton}
                        onClick={() => openDeleteConfirm(option.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!isPastDateSelected && (
            <button
              className={styles.addOpenShiftsButton}
              onClick={() => handleOpen()}
            >
              <FontAwesomeIcon
                icon={faCirclePlus}
                className={styles.plusIcon}
              />
              Add New Open Shifts
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default OpenShiftsCellEditor;
