import styles from "./SolutionsList.module.css";
import arrow from "../../../../../assets/icons/dropdownArrow.svg";
import {
  DateTime,
  deepCopyObject,
  sortByDateField,
} from "../../../../../utils";
import { useMemo } from "react";

const SolutionItem = ({ solution, solutionStatus, solutionIdex }) => {
  const progress = solutionStatus?.progress;
  const status = solutionStatus?.status;

  const dateSolved = new DateTime(solution.updatedAt).toFormat(
    "displayed-full"
  );
  const timeSolved = new DateTime(solution.updatedAt, true)
    .getDate()
    .toLocaleString()
    .split(",")[1];
  const statusArr = solution.status.split("-");
  const objectiveScore = (
    statusArr[1] ? statusArr[1].trim() : "obj: 0"
  ).replace("obj", "score");

  let label = `...`;

  if (status === "solved") {
    label = `Solved ${dateSolved} ${timeSolved} (${objectiveScore})`;
  } else if (status === "error") {
    label = `Error on ${dateSolved} ${timeSolved}`;
  } else if (status === "failed") {
    label = `Rule Conflicts ${dateSolved} ${timeSolved}`;
  } else if (status === "solving") {
    label = `Solving... ${progress}%`;
  }

  return (
    <option label={label} value={solutionIdex} key={solutionIdex}></option>
  );
};

const SolutionsList = ({ solutions, selected, onChange, statuses }) => {
  const sortedSolutions = useMemo(() => {
    const copiedSolutions = deepCopyObject(solutions);
    sortByDateField(copiedSolutions, "updatedAt", false);
    return copiedSolutions;
  }, [solutions]);

  return (
    <select
      name="solutions"
      id="solutions"
      value={selected}
      onChange={(event) => {
        onChange(parseInt(event.target.value));
      }}
      className={styles.solutionSelector}
      style={{ backgroundImage: `url(${arrow})` }}
    >
      {sortedSolutions.map((solution, idx) => {
        const solutionStatus = statuses.find(
          (status) => status.solutionID === solution.id
        );

        return (
          <SolutionItem
            key={solution.id}
            solutionIdex={idx}
            solution={solution}
            solutionStatus={solutionStatus}
          />
        );
      })}
    </select>
  );
};

export default SolutionsList;
