import { PLAN } from "../../../auth/service/auth";

export const AI_PLAN_SETTING_NAMES = [
  "shiftHoursMajorityDay",
  "nightsNextDayDayOn",
  "callRosterSmartLeave",
  "failOnFixedShifts",
  "cyclicRoster",
  "rulesWithException",
];

export function getFilteredSettingsByPlan(plan) {
  const filteredInterpretationSettings = filterSettingsByPlan(
    interpretationSettings,
    plan
  );
  const filteredDemandSettings = filterSettingsByPlan(demandSettings, plan);
  const filteredEmailSettings = filterSettingsByPlan(emailSettings, plan);
  const filteredLeaveManagementSettings = filterSettingsByPlan(
    leaveManagementSettings,
    plan
  );
  const filteredPreferencesApprovalSettings = filterSettingsByPlan(
    preferencesApprovalSettings,
    plan
  );
  const filteredAppSettings = filterSettingsByPlan(appSettings, plan);
  const filteredRulesManagementSettings = filterSettingsByPlan(
    rulesManagementSettings,
    plan
  );

  return {
    interpretationSettings: filteredInterpretationSettings,
    demandSettings: filteredDemandSettings,
    emailSettings: filteredEmailSettings,
    leaveManagementSettings: filteredLeaveManagementSettings,
    preferencesApprovalSettings: filteredPreferencesApprovalSettings,
    appSettings: filteredAppSettings,
    rulesManagementSettings: filteredRulesManagementSettings,
  };
}

export function filterSettingsByPlan(settings, plan) {
  return settings.filter((setting) => {
    if (
      plan !== PLAN.AI &&
      plan !== PLAN.COLLABORATOR &&
      AI_PLAN_SETTING_NAMES.includes(setting.name)
    ) {
      return false;
    }
    return true;
  });
}

// InterpetationSettings are also in the backend settings (that is in location.settings)
export const interpretationSettings = [
  {
    name: "shiftHoursMajorityDay",
    title:
      "Shift hours count on day on which majority of the hours lie (as opposed to start day)",
    type: "Interpretation",
  },
  {
    name: "nightsNextDayDayOn",
    title:
      "The end day of a night shift also counts as a day on (as opposed to a day off)",
    type: "Interpretation",
  },
  {
    name: "callRosterSmartLeave",
    title: "Call roster version of smart leave",
    type: "Interpretation",
  },
  {
    name: "failOnFixedShifts",
    title: "Fail when rules conflict with fixed shifts (advanced)",
    type: "Interpretation",
  },
  {
    name: "cyclicRoster",
    title: "Make the roster cyclic (advanced)",
    type: "Interpretation",
  },
  {
    name: "noMinimumHoursLeave",
    title: "No minimum hours per leave period",
    type: "Interpretation",
  },
  {
    name: "weekendFriday",
    title: "Weekend starts at 6pm on Friday",
    type: "Interpretation",
  },
  {
    name: "overGenerate",
    title: "Roster likely to end in undesirable patterns (advanced)",
    type: "Interpretation",
  },
  {
    name: "nightsPatternCustom",
    title: "Custom nights pattern (advanced)",
    type: "Interpretation",
  },
  {
    name: "fastSolve",
    title: "Solve faster with a worse solution for validation",
    type: "Interpretation",
  },
];

export const demandSettings = [
  {
    name: "shiftTaskOnlyDemands",
    title: "Do not consider start time and end time in staffing requirements",
    type: "Staffing Requirements",
  },
];

export const emailSettings = [
  {
    name: "emailAdminOnOpenShiftAccept",
    title: "Email admin when someone accepts an open shift",
    type: "Email",
  },
];

export const leaveManagementSettings = [
  {
    name: "showLeaveByDay",
    title: "Show leave hours assigned to each day",
    type: "Leave Management",
  },
  {
    name: "checkLeave",
    title: "Use RosterLab's leave management system",
    type: "Leave Management",
  },
];

export const preferencesApprovalSettings = [
  {
    name: "defaultPreferenceImportance",
    title:
      "Default set all preferences from employees as % level (Except for special preferences)",
    type: "GlobalPreferencesApprovalSysem",
    shouldHaveHeader: false,
    inputFormats: [
      {
        type: "select",
        options: ["normal", "high", "critical"],
        default: "normal",
      },
    ],
  },
  {
    name: "autoApprovedPreferenceLimit",
    title:
      "Approve all pending special requests button sets pending preferences as % level preferences",
    type: "GlobalPreferencesApprovalSysem",
    shouldHaveHeader: true,
    inputFormats: [
      {
        type: "select",
        options: ["high", "critical"],
        default: "high",
      },
    ],
  },
];

export const appSettings = [
  {
    name: "pasting blank inserts off",
    title: "Copy/paste blank cell inserts off",
    type: "App Settings",
  },
  {
    name: "locationAreas",
    title: "Show areas in this location",
    type: "App Settings",
  },
];

export const rulesManagementSettings = [
  {
    name: "rulesWithException",
    title: 'Enable the "with exception" option for my rules',
    type: "Rules Management",
  },
];

/**
 * Stores:
 *  - Whether the roster sidebar is default expanded/collapsed
 *  - Whether the default employees page is overview or individual
 */
export const uiStateSettings = [
  {
    name: "sideBarStatus",
    possibleValues: {
      expanded: "expanded",
      collapsed: "collapsed",
    },
  },
  {
    name: "defaultEmployeesPageView",
    possibleValues: {
      overview: "overview",
      individual: "individual",
    },
  },
  {
    name: "defaultMyRosterPageView",
    possibleValues: {
      employeeView: "employeeView",
      shiftView: "shiftView",
    },
  },
];

/**
 * Unclassified settings (maybe classify it later)
 */

export const hideAreaNameInAllocationsSetting = {
  name: "hideAreaNameInAllocations",
  title: "Hide area name in my roster table cells",
  type: "App Settings",
};
