import { useMemo, useState } from "react";
import styles from "./CreateRosterModal.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShadowButton from "../../../../components/elements/ShadowButton/ShadowButton";
import {
  createRosterUsingTemplate,
  getAllTemplateNames,
  NO_TEMPLATE,
} from "../../service/template";
import { DateTime } from "../../../../utils";
import { getNewRoster } from "../../../../utils/queryUtils/locationDataGetters";
import { customWarningAlert } from "../../../confirm/service/confirm";
import { WeekAndMonthSelect } from "../../../locations/components/CreateLocationModal/CreateLocationModal";
import { getDefaultNumDays } from "../../../../hooks/useCreateLocation";
import {
  isRosterDateValid,
  isRosterNameValid,
} from "../../../../utils/validationUtils/rosterValidations";

const CreateRosterModal = ({
  location,
  rosterNames,
  handleClose,
  locationID,
  createRoster,
}) => {
  const [rosterName, setRosterName] = useState("");
  const [rosteringPeriod, setRosteringPeriod] = useState("6w");
  const [startDate, setStartDate] = useState(DateTime.getNextMonday());
  const [selectedTemplate, setSelectedTemplate] = useState(
    () => getAllTemplateNames()[0]
  );

  const templates = useMemo(() => {
    return getAllTemplateNames();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Create a new roster</h2>
        <form>
          <div className={styles.field}>
            <label htmlFor="name">Please enter a roster name *</label>
            <input
              type="text"
              maxLength={256}
              name="name"
              placeholder="Roster name"
              value={rosterName}
              onChange={(e) => setRosterName(e.target.value)}
              required
              data-testid="new-roster-name-input"
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="weeks">How many weeks is the roster? *</label>
            <WeekAndMonthSelect
              rosteringPeriod={rosteringPeriod}
              setRosteringPeriod={setRosteringPeriod}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="templates">Choose your template for roster *</label>
            <select
              id="template"
              name="template"
              value={selectedTemplate}
              onChange={(e) => {
                setSelectedTemplate(e.target.value);
              }}
            >
              {templates.map((template, idx) => (
                <option key={idx} value={template}>
                  {template}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.field}>
            <label htmlFor="field">
              What is the start date for the roster? *
            </label>
            <DatePicker
              id="roster-date"
              calendarStartDay={1}
              selected={startDate.date}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setStartDate(new DateTime(date, false))}
            />
          </div>
        </form>
        <div className={styles.buttons}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={async () => {
              let warningMessage;
              if (rosterName === "") {
                warningMessage = "Please enter roster name";
              } else if (rosterName.includes(".")) {
                warningMessage =
                  "Roster name cannot have '.' in it. Please rename your roster.";
              } else if (rosterNames.includes(rosterName)) {
                warningMessage = "Roster name already exists";
              } else if (!isRosterDateValid(startDate.date)) {
                warningMessage = "Please enter a valid date";
              } else if (!isRosterNameValid(rosterName)) {
                warningMessage = "Roster name is too long";
              } else if (
                rosteringPeriod.endsWith("m") &&
                selectedTemplate !== NO_TEMPLATE
              ) {
                warningMessage =
                  "No month view available with roster templates";
              }

              if (warningMessage) {
                customWarningAlert({
                  title: "Warning",
                  descriptions: [warningMessage],
                });
                return;
              }
              if (selectedTemplate === NO_TEMPLATE) {
                const rosterStartDate = startDate.toFormat("AWS");
                const newRosterInfo = getNewRoster({
                  location,
                  name: rosterName,
                  rosteringPeriod,
                  startDate: rosterStartDate,
                  isSnapshot: false,
                  snapshotStartDate: `F${rosterStartDate}`,
                });
                createRoster(newRosterInfo);
                handleClose();
              } else {
                const rosterData = createRosterUsingTemplate(
                  selectedTemplate,
                  rosterName,
                  getDefaultNumDays(rosteringPeriod),
                  startDate,
                  locationID
                );
                await createRoster(rosterData);
                handleClose();
              }
            }}
          >
            Submit
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            hoverColor="#1f91b7"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={() => handleClose()}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

export default CreateRosterModal;
