import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import {
  subscribeGlobalEmployeeUpdate,
  subscribeLocationChange,
} from "../utils/queryUtils/observers";
import {
  getLocationAndEmployeesForKiosk,
  updateGlobalEmployeeModel,
} from "../utils/queryUtils/locationQuery";
import { useDataMutation } from "./modelQueryHooks/useDataMutation";
import { DateTime, deepCopyObject } from "../utils";
import { getUserGroups } from "../features/auth/service/auth";
import { getSubtasks } from "../features/rosterProblems/service/rosterUtils";
import {
  syncNonAdminAppGlobalEmployee,
  syncNonAdminAppLocation,
} from "../utils/modelUtils/sync";

export const KIOSK_CLOCK_ACTIONS = {
  clockIn: "Clocking In",
  clockOut: "Clocking Out",
};

export const KIOSK_RESPONSE_TYPE = {
  success: "success",
  error: "error",
  wrongCode: "wrongCode",
  notYetClockedIn: "notYetClockedIn",
  alreadyClockedIn: "alreadyClockedIn",
  alreadyClockedOut: "alreadyClockedOut",
};

export function useKioskQuery(user) {
  const queryClient = useQueryClient();
  const groups = getUserGroups(user) || [];
  const locationID = findFirstLocationFromKioskStrings(groups);

  const locationQuery = useQuery({
    queryKey: ["timesheetData", locationID],
    queryFn: () => getLocationAndEmployeesForKiosk(locationID),
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled: !!user,
  });

  const mutation = useDataMutation({
    queryKey: ["timesheetData", locationID],
    queryClient,
    mutationFn: (mutateFnParam) => {
      const dataToSave = {
        id: mutateFnParam.newEmployee.id,
        TimeEntries: mutateFnParam.newEmployee.TimeEntries,
      };

      updateGlobalEmployeeModel(dataToSave);
      return mutateFnParam.newLocationData;
    },
    getExpectedResultingData: (mutateFnParam) => mutateFnParam.newLocationData,
  });

  const { location, employees } = useMemo(() => {
    if (!locationQuery.data) {
      return {};
    }
    return locationQuery.data;
  }, [locationQuery]);

  useEffect(() => {
    if (!locationID) {
      return;
    }

    const queryKey = ["timesheetData", locationID];
    const locationSubscription = subscribeLocationChange((data) => {
      syncNonAdminAppLocation(queryKey, data);
    }, locationID);
    const employeeSubscription = subscribeGlobalEmployeeUpdate((data) => {
      syncNonAdminAppGlobalEmployee(queryKey, data);
    }, locationID);
    return async () => {
      (await employeeSubscription)?.unsubscribe();
      (await locationSubscription)?.unsubscribe();
    };
  }, [queryClient, locationID, location]);

  const submitCode = useCallback(
    (code, action) => {
      if (!location) {
        return {
          response: KIOSK_RESPONSE_TYPE.error,
        };
      }

      try {
        const oldEmployee = employees.find(
          (employee) => employee.code === code
        );

        if (!oldEmployee) {
          return {
            response: KIOSK_RESPONSE_TYPE.wrongCode,
          };
        }

        const now = new DateTime(new Date());
        const awsDate = now.toFormat("AWS");

        const allocation = oldEmployee.PublishedAllocations.find(
          (publishedAllocation) => publishedAllocation.date === awsDate
        );

        const oldTimeEntry = oldEmployee.TimeEntries.find(
          (timeEntry) => timeEntry.date === awsDate
        );

        let newTimeEntry;
        let copiedOldTimeEntry = false;
        if (oldTimeEntry) {
          newTimeEntry = deepCopyObject(oldTimeEntry);
          copiedOldTimeEntry = true;
        } else {
          newTimeEntry = {
            date: awsDate,
            approved: false,
            startTime: null,
            finishTime: null,
          };
        }

        const currentTime = getCurrentTimeString();

        switch (action) {
          case KIOSK_CLOCK_ACTIONS.clockIn: {
            if (newTimeEntry.startTime) {
              return {
                response: KIOSK_RESPONSE_TYPE.alreadyClockedIn,
                allocation: allocation ? allocation.publishedAllocation : "",
                employeeName: oldEmployee.name,
                time: newTimeEntry.startTime,
                timeEntry: newTimeEntry,
                action,
              };
            }
            newTimeEntry.startTime = currentTime;
            break;
          }
          case KIOSK_CLOCK_ACTIONS.clockOut: {
            if (!newTimeEntry.startTime) {
              return {
                response: KIOSK_RESPONSE_TYPE.notYetClockedIn,
                allocation: allocation ? allocation.publishedAllocation : "",
                employeeName: oldEmployee.name,
                time: null,
                timeEntry: newTimeEntry,
                action,
              };
            }

            if (newTimeEntry.finishTime) {
              return {
                response: KIOSK_RESPONSE_TYPE.alreadyClockedOut,
                allocation: allocation ? allocation.publishedAllocation : "",
                employeeName: oldEmployee.name,
                time: newTimeEntry.finishTime,
                timeEntry: newTimeEntry,
                action,
              };
            }
            newTimeEntry.finishTime = currentTime;
            break;
          }
          default:
            throw new Error("Unkown kiosk action");
        }

        const newEmployee = deepCopyObject(oldEmployee);

        if (copiedOldTimeEntry) {
          const index = newEmployee.TimeEntries.findIndex(
            (entry) => entry.date === awsDate
          );
          if (index !== -1) {
            newEmployee.TimeEntries[index] = newTimeEntry;
          }
        } else {
          newEmployee.TimeEntries.push(newTimeEntry);
        }

        const employeeIndex = employees.findIndex(
          (employee) => employee.id === newEmployee.id
        );
        const newLocationData = JSON.parse(JSON.stringify(locationQuery.data));
        if (employeeIndex !== -1) {
          newLocationData.employees[employeeIndex] = newEmployee;
        }

        mutation.mutate({
          newEmployee,
          newLocationData,
        });

        return {
          response: KIOSK_RESPONSE_TYPE.success,
          allocation: allocation ? allocation.publishedAllocation : "",
          employeeName: oldEmployee.name,
          time: currentTime,
          timeEntry: newTimeEntry,
          action,
        };
      } catch (error) {
        console.error(error);
        return {
          response: "error",
        };
      }
    },
    [employees, location, locationQuery, mutation]
  );

  const shifts = useMemo(() => (location ? location.Shifts : []), [location]);
  const shiftGroups = useMemo(
    () => (location ? location.ShiftGroups : []),
    [location]
  );
  const tasks = useMemo(() => (location ? location.Tasks : []), [location]);
  const taskBlocks = useMemo(
    () => (location ? location.TaskBlocks : []),
    [location]
  );
  const subTasks = useMemo(
    () => getSubtasks(tasks, taskBlocks),
    [tasks, taskBlocks]
  );
  const areas = useMemo(() => (location ? location.Areas : []), [location]);
  const skills = useMemo(() => (location ? location.Skills : []), [location]);

  return {
    submitCode,
    isLoading: locationQuery.isLoading || mutation.isLoading,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    areas,
    skills,
  };
}

function findFirstLocationFromKioskStrings(strings) {
  // Loop through each string in the array
  for (let string of strings) {
    // Check if the string ends with '_kiosk'
    if (string.endsWith("_kiosk")) {
      return string.replace("_kiosk", ""); // Return the first match
    }
  }
  return null; // Return null if no match is found
}

function getCurrentTimeString() {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  // Pad the hours and minutes with leading zeros if necessary
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");

  return `${hours}:${minutes}:00.000`;
}
