import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import styles from "./OpenShiftsLabelCellRenderer.module.css";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";

const OpenShiftsLabelCellRenderer = () => {
  const { plan } = useUserStore();

  return (
    <div className={styles.container}>
      <span className={styles.label}>Open Shifts</span>
      {plan === PLAN.LITE && (
        <FontAwesomeIcon
          icon={faCircleArrowUp}
          className={styles.upgradeIcon}
        />
      )}
    </div>
  );
};

export default OpenShiftsLabelCellRenderer;
