export function retrieveHiddenShiftViewRows(id) {
  const visibilityInfo = localStorage.getItem(`shiftViewHiddenRows-${id}`);
  const hiddenRowNames = JSON.parse(visibilityInfo);
  return hiddenRowNames ? hiddenRowNames : [];
}

export function saveHiddenShiftViewRows(id, hiddenRowNames) {
  localStorage.setItem(
    `shiftViewHiddenRows-${id}`,
    JSON.stringify(hiddenRowNames)
  );
}

/**
 * Global Employee table's column visibility
 */
export function retrieveGlobalEmployeesColumnOrder(locationID) {
  const order = localStorage.getItem(
    `globalEmployeesColumnOrder-${locationID}`
  );
  const orderArr = JSON.parse(order);
  return orderArr;
}

export function saveGlobalEmployeesColumnOrder(locationID, order) {
  localStorage.setItem(
    `globalEmployeesColumnOrder-${locationID}`,
    JSON.stringify(order)
  );
}

export function retrieveGlobalEmployeesColumnVisibility(locationID) {
  const visibilityInfo = localStorage.getItem(
    `globalEmployeesColumnVisibility-${locationID}`
  );
  const visibilityInfoArr = JSON.parse(visibilityInfo);
  return visibilityInfoArr;
}

export function saveGlobalEmployeesColumnVisibility(
  locationID,
  visibilityInfo
) {
  localStorage.setItem(
    `globalEmployeesColumnVisibility-${locationID}`,
    JSON.stringify(visibilityInfo)
  );
}
