import { get } from "aws-amplify/api";

export const getLiveCalendarNoAuth = async (locationID) => {
  const apiName = "RosterLabRestNoAuth";
  const path = `/livecalendar`;
  const options = {
    queryParams: {
      locationID,
    },
  };

  const response = await get({ apiName, path, options }).response;
  const json = await response.body.json();
  return json;
};
