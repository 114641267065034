import {
  DateTime,
  deepCopyObject,
  getShortIds,
  hasCommonItems,
  hasObjectChanged,
  removeDuplicateStrInArr,
  strToArrCommaSeparated,
} from "../../../utils";
import {
  getAllBlockTaskOptionRegex,
  getDayColumnNameRegex,
  getNoBlockTaskOptionRegex,
} from "../../../utils/generalUtils/regex";
import { parse } from "date-fns";
import {
  allocationFulfilsShiftGroup,
  employeeFulfilsArea,
} from "./preferencesAndFixedShifts";
import {
  extractEntityShortIdsFromAllocation,
  isEntityShortIdInAllocation,
} from "../../../utils/modelUtils/allocation";
import { KEYWORD_ALL, KEYWORD_NO_TASK } from "../../../constants/keywords";
import {
  ENTITY_REFERENCE_MAP_IN_ROSTER_IN_VIEW,
  GetAppNamesOfEntities,
} from "../../../constants/entityReference";

export const getWeekFromDayColFieldName = (cellValue) => {
  const dayColNameRegex = getDayColumnNameRegex();
  if (!cellValue.match(dayColNameRegex)) {
    return null;
  }

  const selectedWeek = Math.floor((Number(cellValue.slice(1)) - 1) / 7) + 1;
  return selectedWeek;
};

export const getMinAndMaxDemandValuesForStaffingLevelGrid = (
  shiftDemandsMinMaxPair,
  numDays
) => {
  const templateDemandValues = Array(numDays).fill("");
  const minDemandValues = shiftDemandsMinMaxPair.minDemandValues
    ? shiftDemandsMinMaxPair.minDemandValues
    : templateDemandValues;
  const maxDemandValues = shiftDemandsMinMaxPair.maxDemandValues
    ? shiftDemandsMinMaxPair.maxDemandValues
    : templateDemandValues;

  return {
    minDemandValues,
    maxDemandValues,
  };
};

export const getStaffingLevelModalDurationValue = (
  shiftDemandsMinMaxPair,
  shiftStartTime,
  shiftFinishTime
) => {
  const duration = shiftDemandsMinMaxPair.demandDuration
    ? shiftDemandsMinMaxPair.demandDuration
    : DateTime.getTimeDifferenceInHours(shiftStartTime, shiftFinishTime);
  return duration;
};

export const hasEmployeesInfoChanged = (prevEmpNames, currentEmpNames) => {
  const prevArr = deepCopyObject(prevEmpNames);
  const postArr = deepCopyObject(currentEmpNames);
  prevArr.sort();
  postArr.sort();

  return hasObjectChanged(prevArr, postArr);
};

let timeCache = {};

const parseTime = (time) => {
  if (timeCache[time]) {
    return timeCache[time];
  }

  const result = parse(time, "HH:mm:ss.SSS", new Date()).getTime();
  timeCache[time] = result;
  return result;
};

const addADay = (time) => {
  return time + 24 * 60 * 60 * 1000;
};

export const getSubtasks = (tasks, taskBlocks) => {
  if (!taskBlocks) return [];

  // Create a map where the key is the task's name and the value is the task itself
  const taskMap = tasks.reduce((acc, task) => {
    acc[task.name] = task;
    return acc;
  }, {});

  const subtasks = [];
  for (const block of Object.values(taskBlocks)) {
    let taskNames = "";
    if (block.tasks === KEYWORD_ALL) {
      taskNames = tasks.map((task) => task.name);
    } else {
      taskNames = block.tasks.split(",").map((name) => name.trim());
    }
    for (const taskName of taskNames) {
      // Look up the task in the map
      const task = taskMap[taskName];

      // If the task exists, create a subtask
      if (task) {
        const subtask = {
          shortId: `${task.shortId} ${block.shortId}`,
          name: `${task.name} ${block.name}`,
          taskShortId: task.shortId,
          taskName: task.name,
          blockShortId: block.shortId,
          blockName: block.name,
          startTime: parseTime(block.startTime),
          finishTime: parseTime(block.finishTime),
          maxDepth: block.depth,
          cantCombine: block.cantCombine.split(",").map((name) => name.trim()),
        };
        subtasks.push(subtask);
      }
    }
  }
  return subtasks;
};

const getCombinedSubtasksFromSubtasks = (subtasks) => {
  const combinedSubtasks = [];
  for (let i = 0; i < subtasks.length; i++) {
    for (let j = i + 1; j < subtasks.length; j++) {
      const combinedTask = combineSubtasks(subtasks[i], subtasks[j]);
      if (combinedTask !== null) {
        combinedSubtasks.push(combinedTask);
      }
    }
  }

  return combinedSubtasks;
};

const getAllSubtaskCombinations = (subtasks) => {
  const normalizedSubtasks = getSingleEnumeratedSubtasks(subtasks);
  return [...normalizedSubtasks, ...getCombinedSubtasksFromSubtasks(subtasks)];
};

export const getSingleEnumeratedSubtasks = (subtasks) => {
  const normalizedSubtasks = subtasks.map((subtask) => ({
    name: subtask.name, // deprecated
    shortId: subtask.shortId,
    startTime: subtask.startTime,
    finishTime: subtask.finishTime,
    subtaskNames: [subtask.name],
    subtaskShortIds: [subtask.shortId],
    blockShortIds: [subtask.blockShortId],
    taskShortIds: [subtask.taskShortId],
    taskNames: [subtask.taskName],
    subtaskDurations: [
      {
        subtaskShortId: subtask.shortId,
        subtaskName: subtask.name,
        startTime: subtask.startTime,
        finishTime: subtask.finishTime,
      },
    ],
  }));

  return normalizedSubtasks;
};

export const getCombinedSubtasks = (tasks, taskBlocks) => {
  if (!taskBlocks) return [];
  const subtasks = getSubtasks(tasks, taskBlocks);

  return getCombinedSubtasksFromSubtasks(subtasks);
};

export const getCombinedShiftSubtasks = (
  shifts,
  tasks,
  taskBlocks,
  existingCombinedSubtasks,
  existingSubtasks
) => {
  if (!taskBlocks) return [];

  const combinedSubtasks =
    existingCombinedSubtasks || getCombinedSubtasks(tasks, taskBlocks);
  const subtasks = existingSubtasks || getSubtasks(tasks, taskBlocks);
  const combinedShiftsSubtasks = [];
  for (const shift of shifts) {
    let [shiftStartTime, shiftFinishTime] = adjustTimes(shift);
    for (const combinedSubtask of combinedSubtasks) {
      if (
        shiftTimesCoversSubtask(
          combinedSubtask,
          shiftStartTime,
          shiftFinishTime
        )
      ) {
        // Combine the combinedSubtask and shift
        combinedShiftsSubtasks.push({
          shortId: shift.shortId + "-" + combinedSubtask.shortId,
          name: shift.name + "-" + combinedSubtask.name,
        });
      }
    }
    for (const subtask of subtasks) {
      if (shiftTimesCoversSubtask(subtask, shiftStartTime, shiftFinishTime)) {
        // Combine the combinedSubtask and shift
        combinedShiftsSubtasks.push({
          shortId: shift.shortId + "-" + subtask.shortId,
          name: shift.name + "-" + subtask.name,
        });
      }
    }
  }

  return combinedShiftsSubtasks;
};

const shiftTimesCoversSubtask = (subtask, shiftStartTime, shiftFinishTime) => {
  let [taskStartTime, taskFinishTime] = adjustTimes(subtask);

  return timeCovers(
    taskStartTime,
    taskFinishTime,
    shiftStartTime,
    shiftFinishTime
  );
};

const adjustTimes = (coverable) => {
  let taskStartTime =
    typeof coverable.startTime === "string"
      ? parseTime(coverable.startTime)
      : coverable.startTime;
  let taskFinishTime =
    typeof coverable.finishTime === "string"
      ? parseTime(coverable.finishTime)
      : coverable.finishTime;

  if (coverable.startTime > coverable.finishTime) {
    taskFinishTime = addADay(taskFinishTime);
  }
  return [taskStartTime, taskFinishTime];
};

const timeCovers = (
  toCoverStartTime,
  toCoverFinishTime,
  covererStartTime,
  covererFinishTime
) => {
  return (
    toCoverStartTime >= covererStartTime &&
    toCoverFinishTime <= covererFinishTime
  );
};

export const demandOverlapsSubtask = (subtask, demand) => {
  let [demandStartTime, demandFinishTime] = adjustTimes(demand);
  let [subtaskStartTime, subtaskFinishTime] = adjustTimes(subtask);
  return (
    timeCovers(
      subtaskStartTime,
      subtaskFinishTime,
      demandStartTime,
      demandFinishTime
    ) ||
    timeCovers(
      demandStartTime,
      demandFinishTime,
      subtaskStartTime,
      subtaskFinishTime
    )
  );
};

const combineSubtasks = (subtask1, subtask2) => {
  if (subtask2.blockName === subtask1.blockName) return null;
  if (subtask1.cantCombine.includes(subtask2.blockName)) return null;
  if (subtask2.cantCombine.includes(subtask1.blockName)) return null;
  if (subtask1.maxDepth <= 1 || subtask2.maxDepth <= 1) return null;

  if (
    subtask1.finishTime <= subtask2.startTime ||
    subtask1.startTime >= subtask2.finishTime
  ) {
    // Subtasks don't overlap, combine them
    let combinedTask;
    if (subtask2.startTime < subtask1.startTime) {
      combinedTask = {
        name: `${subtask2.name}/${subtask1.name}`,
        shortId: `${subtask2.shortId}/${subtask1.shortId}`,
        startTime: subtask2.startTime,
        finishTime: subtask1.finishTime,
        subtaskNames: [subtask2.name, subtask1.name],
        subtaskShortIds: [subtask1.shortId, subtask2.shortId],
        blockShortIds: [subtask2.blockShortId, subtask1.blockShortId],
        taskShortIds: [subtask2.taskShortId, subtask1.taskShortId],
        taskNames: [subtask2.taskName, subtask1.taskName],
        subtaskDurations: [
          {
            subtaskShortId: subtask1.shortId,
            subtaskName: subtask1.name,
            startTime: subtask1.startTime,
            finishTime: subtask1.finishTime,
          },
          {
            subtaskShortId: subtask2.shortId,
            subtaskName: subtask2.name,
            startTime: subtask2.startTime,
            finishTime: subtask2.finishTime,
          },
        ],
      };
    } else {
      combinedTask = {
        name: `${subtask1.name}/${subtask2.name}`,
        shortId: `${subtask1.shortId}/${subtask2.shortId}`,
        startTime: subtask1.startTime,
        finishTime: subtask2.finishTime,
        subtaskNames: [subtask1.name, subtask2.name],
        subtaskShortIds: [subtask1.shortId, subtask2.shortId],
        blockShortIds: [subtask1.blockShortId, subtask2.blockShortId],
        taskShortIds: [subtask1.taskShortId, subtask2.taskShortId],
        taskNames: [subtask1.taskName, subtask2.taskName],
        subtaskDurations: [
          {
            subtaskShortId: subtask1.shortId,
            subtaskName: subtask1.name,
            startTime: subtask1.startTime,
            finishTime: subtask1.finishTime,
          },
          {
            subtaskShortId: subtask2.shortId,
            subtaskName: subtask2.name,
            startTime: subtask2.startTime,
            finishTime: subtask2.finishTime,
          },
        ],
      };
    }
    return combinedTask;
  }

  // Subtasks overlap, don't combine them
  return null;
};

function createNameDictionaryFromList(items) {
  return items.reduce((acc, item) => {
    acc[item.name] = item.shortId;
    return acc;
  }, {});
}

function createNameObjectDictionaryFromList(items) {
  return items.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});
}

export function buildShortIdsToEntityNamesDicts(
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  skills
) {
  return {
    areaNamesDict: areas ? createDictionaryFromList(areas) : {},
    shiftNamesDict: shifts ? createDictionaryFromList(shifts) : {},
    shiftGroupNamesDict: shiftGroups
      ? createDictionaryFromList(shiftGroups)
      : {},
    taskNamesDict: tasks ? createDictionaryFromList(tasks) : {},
    enumeratedTaskNamesDict: subTasks
      ? createObjectDictionaryFromList(getAllSubtaskCombinations(subTasks))
      : {},
    skillNamesDict: skills ? createDictionaryFromList(skills) : {},
  };
}

export function buildNamesToEntityShortIdsDicts(
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  skills
) {
  return {
    areaNamesDict: areas ? createNameDictionaryFromList(areas) : {},
    shiftNamesDict: shifts ? createNameDictionaryFromList(shifts) : {},
    shiftGroupNamesDict: shiftGroups
      ? createNameDictionaryFromList(shiftGroups)
      : {},
    taskNamesDict: tasks ? createNameDictionaryFromList(tasks) : {},
    enumeratedTaskNamesDict: subTasks
      ? createNameObjectDictionaryFromList(getAllSubtaskCombinations(subTasks))
      : {},
    skillNamesDict: skills ? createNameDictionaryFromList(skills) : {},
  };
}

function createDictionaryFromList(items) {
  return items.reduce((acc, item) => {
    acc[item.shortId] = item.name;
    return acc;
  }, {});
}

function createObjectDictionaryFromList(items) {
  return items.reduce((acc, item) => {
    acc[item.shortId] = item;
    return acc;
  }, {});
}

export function addAreaToAllocation(areaShortId, allocationShortId) {
  return `${areaShortId}:${allocationShortId}`;
}

export function removeSingleShortIdsFromAllocation(
  allocation,
  shortIds,
  shortIdsToEntityNamesDicts
) {
  const {
    areaShortId,
    shiftShortId,
    shiftGroupShortId,
    taskShortId,
    enumeratedTaskShortId,
    suffix,
  } = extractEntityShortIdsFromAllocation(
    allocation,
    shortIdsToEntityNamesDicts
  );

  const resultingAreaShortId = shortIds.includes(areaShortId)
    ? null
    : areaShortId;

  const resultingShiftShortId = shortIds.includes(shiftShortId)
    ? null
    : shiftShortId;

  const resultingShiftGroupShortId = shortIds.includes(shiftGroupShortId)
    ? null
    : shiftGroupShortId;

  const resultingTaskShortId = shortIds.includes(taskShortId)
    ? null
    : taskShortId;

  const resultingEnumeratedTaskShortId = shortIds.some((shortId) =>
    enumeratedTaskShortId ? enumeratedTaskShortId.includes(shortId) : false
  )
    ? null
    : enumeratedTaskShortId;

  const areaPart = resultingAreaShortId;
  const shiftPart = resultingShiftGroupShortId || resultingShiftShortId;
  const taskPart = resultingTaskShortId || resultingEnumeratedTaskShortId;

  let resultingAllocation = shiftPart || "";
  if (taskPart) {
    resultingAllocation = resultingAllocation
      ? `${resultingAllocation}-${taskPart}`
      : taskPart || "";
  }

  if (areaPart && shiftPart) {
    resultingAllocation = `${areaPart}:${resultingAllocation}`;
  }

  return `${resultingAllocation}${suffix || ""}`;
}

export function getRosterStartDateAndFinishDate(roster) {
  const rosterStartDate = roster.startDate;
  const rosterFinishDate = DateTime.addDaysToDate(
    rosterStartDate,
    roster.numDays - 1
  ).toFormat("AWS");
  return { rosterStartDate, rosterFinishDate };
}

export function findEntityByShortId(entities, shortId) {
  if (!shortId) return null;
  const entityFound = entities.find((entity) => entity.shortId === shortId);
  return entityFound || null;
}

export function findMultipleEntitiesByShortIds(entities, shortIds) {
  if (!shortIds || shortIds.length === 0) return [];
  return entities.filter(({ shortId }) => shortIds.includes(shortId));
}

/**
 * Given an allocation, check if the allocation is workable in Area.
 * "allocation" may or may not have "[area]:" in it. If not, it is workable in any area.
 *
 * Possible allocation format:
 * - shift
 * - shift-task
 * - shift-task block
 * - shift-task block/task block
 * - all above with area: at the start
 * - area
 */

export function allocationFulfilsArea(
  area,
  shiftGroups,
  allocation,
  employeeSkills = null,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  const {
    areaShortId,
    enumeratedTaskShortId,
    shiftGroupShortId,
    shiftShortId,
    taskShortId,
  } = extractEntityShortIdsFromAllocation(
    allocation,
    shortIdsToEntityNamesDicts
  );

  const areaShifts = strToArrCommaSeparated(area.shifts);
  const areaSkills = strToArrCommaSeparated(area.skills);
  const areaTasks = strToArrCommaSeparated(area.tasks);

  if (areaSkills.length > 0) {
    if (employeeSkills != null) {
      const employeeSkillsArr = strToArrCommaSeparated(employeeSkills);
      const fulfilsSkills = areaSkills.some((skill) =>
        employeeSkillsArr.includes(skill)
      );
      if (!fulfilsSkills) return false;
    }
  }

  const isAreaPartAllowed = !areaShortId || area.shortId === areaShortId;
  const isShiftPartAllowed = checkShiftPartOfAllocationIsAllowed(
    { areaShortId, shiftGroupShortId, shiftShortId },
    allocation.split("-")[1] || "",
    shiftGroups,
    areaShifts,
    areaSkills,
    customKeywordsUtilObj
  );
  const isTaskPartAllowed = checkTaskPartOfAllocationIsAllowed(
    { enumeratedTaskShortId, taskShortId },
    areaTasks
  );

  return isAreaPartAllowed && isShiftPartAllowed && isTaskPartAllowed;
}

export function getAllocationsFulfillingArea(
  allocations,
  area,
  shiftGroups,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  return allocations.filter((allocation) =>
    allocationFulfilsArea(
      area,
      shiftGroups,
      allocation,
      null,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj
    )
  );
}

function checkShiftPartOfAllocationIsAllowed(
  shortIdsExtractedFromAllocation,
  allocationTaskPartShortId,
  shiftGroups,
  selectedShiftOptions,
  selectedSkillOptions,
  customKeywordsUtilObj
) {
  const { areaShortId, shiftGroupShortId, shiftShortId } =
    shortIdsExtractedFromAllocation;

  if (
    selectedShiftOptions.length === 0 ||
    selectedShiftOptions.includes(KEYWORD_ALL)
  ) {
    return true;
  }

  if (selectedShiftOptions.includes(shiftShortId)) {
    return true;
  }

  if (selectedShiftOptions.includes(shiftGroupShortId)) {
    return true;
  }

  const shiftGroupsInOptions = shiftGroups.filter(({ shortId }) =>
    selectedShiftOptions.includes(shortId)
  );

  for (const shiftGroup of shiftGroupsInOptions) {
    if (shiftShortId) {
      const doesFulfilShiftGroup = allocationFulfilsShiftGroup(
        shiftGroup,
        areaShortId,
        shiftShortId,
        allocationTaskPartShortId,
        selectedSkillOptions,
        customKeywordsUtilObj
      );
      if (doesFulfilShiftGroup) {
        return true;
      }
    }
  }

  return false;
}

function checkTaskPartOfAllocationIsAllowed(
  shortIdsExtractedFromAllocation,
  selectedTaskOptions
) {
  const { enumeratedTaskShortId, taskShortId } =
    shortIdsExtractedFromAllocation;

  // No restriction on task part of the allocation
  if (
    selectedTaskOptions.length === 0 ||
    selectedTaskOptions.includes(KEYWORD_ALL)
  ) {
    return true;
  }

  if (selectedTaskOptions.includes(taskShortId)) {
    return true;
  }

  if (selectedTaskOptions.includes(enumeratedTaskShortId)) {
    return true;
  }

  let subtasks = [];
  if (enumeratedTaskShortId) {
    subtasks = enumeratedTaskShortId.split("/");
  }

  if (
    subtasks.length > 0 &&
    subtasks.every((subtask) => selectedTaskOptions.includes(subtask))
  ) {
    return true;
  }

  const allXBlocks = [];
  const noXBlocks = [];

  const allBlockTaskOptionRegex = getAllBlockTaskOptionRegex();
  const noBlockTaskOptionRegex = getNoBlockTaskOptionRegex();

  for (const selectedTaskOption of selectedTaskOptions) {
    const allBlockTaskMatch = selectedTaskOption.match(allBlockTaskOptionRegex);
    if (allBlockTaskMatch) {
      allXBlocks.push(allBlockTaskMatch[1]);
      continue;
    }

    const noBlockTaskMatch = selectedTaskOption.match(noBlockTaskOptionRegex);
    if (noBlockTaskMatch) {
      noXBlocks.push(noBlockTaskMatch[1]);
    }
  }

  /**
   * all X task:
   * - `${any task} X` is possible
   * - `${any task} is possible
   * - You can have all tasks that have X block in it.
   * - "no task X" option is also included in "all X task"
   * - "all X task" implies you can't have any other task blocks unless specifically allowed
   */

  if (allXBlocks.length > 0) {
    if (taskShortId && !enumeratedTaskShortId) {
      return true;
    }
    if (allXBlocks.some((block) => enumeratedTaskShortId?.includes(block))) {
      return true;
    }
  }

  /**
   * no task X:
   * - You can't have X as block, unless unless specifically allowed
   */
  if (noXBlocks.some((block) => enumeratedTaskShortId?.includes(block))) {
    return false;
  }

  // Allocation CANNOT have any task part
  if (selectedTaskOptions.includes(KEYWORD_NO_TASK)) {
    return !taskShortId && !enumeratedTaskShortId;
  }

  return false;
}

export function checkCanAddShiftGroupToArea(shiftGroup, area) {
  const shiftGroupAreas = strToArrCommaSeparated(shiftGroup.areas);
  if (shiftGroupAreas.length === 0 || shiftGroupAreas.includes(area.shortId)) {
    return true;
  }
  return false;
}

export function isShiftGroupReferencedByAnyArea(shiftGroupShortId, areas) {
  return areas.some((area) =>
    strToArrCommaSeparated(area.shifts).includes(shiftGroupShortId)
  );
}

export function checkEmployeeEligibility(
  employee,
  checkedArea,
  checkedSkills,
  checkedTask,
  checkedShift,
  shifts,
  shiftGroups,
  customKeywordsUtilObj
) {
  const checkedAreaShortId = checkedArea ? checkedArea.shortId : "";
  const checkedSkillsShortIds = getShortIds(checkedSkills);
  const checkedShiftShortId = checkedShift ? checkedShift.shortId : "";
  const checkedTaskShortId = checkedTask ? checkedTask.shortId : "";

  const shiftShortIds = getShortIds(shifts);
  const shiftGroupShortId = getShortIds(shiftGroups);

  const employeeSkills = strToArrCommaSeparated(employee.skills);
  const employeeShifts = strToArrCommaSeparated(employee.shifts).filter(
    (shortId) => shiftShortIds.includes(shortId)
  );

  const employeeShiftGroups = strToArrCommaSeparated(employee.shifts).filter(
    (shortId) => shiftGroupShortId.includes(shortId)
  );

  const employeeShiftGroupEntities = shiftGroups.filter(({ shortId }) =>
    employeeShiftGroups.includes(shortId)
  );

  const canEmployeeWorkShiftTask =
    employeeShiftGroupEntities.length === 0 || !checkedShiftShortId
      ? true
      : employeeShiftGroupEntities.some((shiftGroup) => {
          return allocationFulfilsShiftGroup(
            shiftGroup,
            checkedAreaShortId,
            checkedShiftShortId,
            checkedTaskShortId,
            employee.skills,
            customKeywordsUtilObj
          );
        });

  const canEmployeeWorkArea =
    !checkedAreaShortId ||
    employeeFulfilsArea(
      employee,
      checkedArea,
      shiftGroups,
      customKeywordsUtilObj
    );

  const canEmployeeWorkSkills =
    checkedSkills.length === 0 ||
    employeeSkills.length === 0 ||
    hasCommonItems(employeeSkills, checkedSkillsShortIds);

  const canEmployeeWorkShift =
    !checkedShiftShortId ||
    employeeShifts.length === 0 ||
    employeeShifts.includes(KEYWORD_ALL) ||
    employeeShifts.includes(checkedShiftShortId);

  return (
    canEmployeeWorkArea &&
    canEmployeeWorkSkills &&
    canEmployeeWorkShift &&
    canEmployeeWorkShiftTask
  );
}

/**
 * Given entityShortId and entityType, check if the entity is referenced by any other entity.
 *
 */
function isEntityReferenced(
  entityShortId,
  entityType,
  rosterInView,
  shortIdsToEntityNamesDicts
) {
  if (!entityShortId) {
    return [];
  }

  const entityReferenceMap = ENTITY_REFERENCE_MAP_IN_ROSTER_IN_VIEW[entityType];
  const referencedBy = [];

  for (const [fieldType, subFields] of Object.entries(entityReferenceMap)) {
    const rosterInViewFieldValues = rosterInView[fieldType];
    const { stringFields, dayFields, customFields } = subFields;

    for (const fieldValue of rosterInViewFieldValues) {
      for (const stringField of stringFields) {
        const stringArray = strToArrCommaSeparated(fieldValue[stringField]);
        if (stringArray.includes(entityShortId)) {
          referencedBy.push(fieldType);
          break;
        }
      }

      for (const dayField of dayFields) {
        const allocations = fieldValue[dayField];
        // Check if the shortID is in the allocation
        const isReferenced = allocations.some((allocation) =>
          isEntityShortIdInAllocation(
            entityShortId,
            allocation,
            shortIdsToEntityNamesDicts
          )
        );
        if (isReferenced) {
          referencedBy.push(dayField);
        }
      }

      for (const customField of customFields) {
        if (customField === "template") {
          const template = fieldValue[customField];
          const subTemplates = template.split(";");
          const shortIdPart = subTemplates[subTemplates.length - 1];
          if (shortIdPart === entityShortId) {
            referencedBy.push(fieldType);
          }
        }
      }
    }
  }

  return GetAppNamesOfEntities(removeDuplicateStrInArr(referencedBy));
}

export function getEntityDeletionWarnings(
  toBeDeletedEntities,
  entityType,
  rosterInView,
  shortIdsToEntityNamesDicts
) {
  const referencedEntities = [];

  for (const entity of toBeDeletedEntities) {
    const referencedBy = isEntityReferenced(
      entity.shortId,
      entityType,
      rosterInView,
      shortIdsToEntityNamesDicts
    );
    if (referencedBy.length > 0) {
      referencedEntities.push({
        name: entity.name,
        referencedBy,
      });
    }
  }

  return referencedEntities.map(({ name, referencedBy }) => {
    return `${name} is referenced in ${referencedBy.join(", ")}`;
  });
}
