import { useRef, useState } from "react";
import {
  DateTime,
  convertSingleOptionToPropForm,
  convertToOptionPropForm,
  isNonNegativeInteger,
} from "../../../../utils";
import styles from "./StartTrialForm.module.css";
import { useUserSettingsUpdate } from "../../../../hooks/userHooks/useManageUserSettings";
import LoadingSpinner from "../../../../components/elements/LoadingSpinner/LoadingSpinner";
import InputBox from "../../../../components/elements/InputBox/InputBox";
import SelectBox from "../../../../components/elements/SelectBox/SelectBox";
import { useOnBoardingStore } from "../../../../globalStore/appStore";
import DatePicker from "react-datepicker";
import { WeekAndMonthSelect } from "../../../locations/components/CreateLocationModal/CreateLocationModal";
import { sendIndustryInfoToHubspot } from "../../../../utils/hubspotUtils/record";

const INDUSTRIES = [
  "Healthcare",
  "Call Centres",
  "Education",
  "Hospitality",
  "Supermarkets",
  "Transportation Companies",
  "Others",
];
const DEFAULT_LOCATION_NAME = "New Location";

const SelectedMonthLabel = ({ value, onClick }) => {
  return (
    <div className={styles.dateLabelContainer} onClick={onClick}>
      <span className={styles.dateLabel}>{value}</span>
    </div>
  );
};

const StartTrialForm = ({
  ruleTemplates,
  createNewScheduleViewLocation,
  locationNames,
  setShouldShowTrial,
}) => {
  const isStartTrialButtonDisabled = useRef(false);
  const { setIsCheckListOpen } = useOnBoardingStore();
  const { isLoading: isSubscriptionLoading } = useUserSettingsUpdate();

  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [numOfEmployees, setNumOfEmployees] = useState(1);
  const [rosteringPeriod, setRosteringPeriod] = useState("6w");
  const [startDate, setStartDate] = useState(DateTime.getNextMonday());
  const [selectedTemplate] = useState(ruleTemplates[0]);

  const updateNumOfEmployees = (e) => {
    const value = e.target.value;
    if (value < 1 || !value) {
      return;
    }

    const number = Number(value);
    setNumOfEmployees(Math.abs(number));
  };

  const isAllRequiredFieldFilled =
    Boolean(locationName) && Boolean(selectedIndustry);

  const getValidationErrorMessage = () => {
    if (!locationNames) {
      return "Location name already exists";
    }

    if (locationNames.includes(locationName)) {
      return "Location name already exists";
    }

    if (!selectedIndustry) {
      return "Please select industry";
    }

    if (!isNonNegativeInteger(numOfEmployees)) {
      return "Invalid number of employees";
    }

    return null;
  };

  const handleStartFreeTrial = async () => {
    if (isStartTrialButtonDisabled.current) {
      return;
    }
    isStartTrialButtonDisabled.current = true;

    const error = getValidationErrorMessage();
    if (error) {
      alert(error);
      return;
    }

    sendIndustryInfoToHubspot(selectedIndustry);

    /**
     * `createNewSubscription` needs to be finished before `createNewScheduleViewLocation`.
     * This is because `createNewScheduleViewLocation` needs stripe customer ID created from `createNewSubscription`
     */
    // await createNewSubscription(numOfEmployees); // This is for trial, not for free plan
    await createNewScheduleViewLocation(
      locationName,
      rosteringPeriod,
      startDate,
      numOfEmployees,
      selectedTemplate
    );
    setIsCheckListOpen(true);
    // setIsWizardOpen(true); // This is for trial, not for free plan
    setShouldShowTrial(false);
  };

  // const ruleTemplateOptions = ruleTemplates.map((name) => {
  //   const ruleSet = ruleSets.find((set) => set.name === name);
  //   return {
  //     value: name,
  //     label: (
  //       <RuleTemplateOptionItem ruleTemplateName={name} ruleSet={ruleSet} />
  //     ),
  //   };
  // });

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Which industry are you from?*</p>
          <SelectBox
            options={convertToOptionPropForm(Object.values(INDUSTRIES))}
            onChange={(selectedInput) =>
              setSelectedIndustry(selectedInput.value)
            }
            value={
              selectedIndustry
                ? convertSingleOptionToPropForm(selectedIndustry)
                : undefined
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Name of your scheduled location*</p>
          <InputBox
            placeholder={DEFAULT_LOCATION_NAME}
            onChange={(e) => setLocationName(e.target.value)}
            value={locationName}
          />
        </div>
        <div className={styles.col}>
          <p className={styles.label}>Number of employees*</p>
          <input
            className={styles.input}
            type="number"
            value={numOfEmployees}
            onChange={updateNumOfEmployees}
            min={1}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>How long are your schedules (weeks)*</p>
          <WeekAndMonthSelect
            rosteringPeriod={rosteringPeriod}
            setRosteringPeriod={setRosteringPeriod}
          />
        </div>
        <div className={styles.col}>
          <p className={styles.label}>Choose a start date for the roster*</p>
          <div className={styles.dateField}>
            <DatePicker
              selected={startDate.date}
              onChange={(date) => setStartDate(new DateTime(date))}
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date(2038, 0, 1)}
              dateFormat="dd/MM/yyyy"
              customInput={<SelectedMonthLabel />}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.row}>
        <div className={styles.col}>
          <p className={styles.label}>Select Rule Template</p>
          <Select
            options={ruleTemplateOptions}
            onChange={(selectedInput) => {
              if (
                selectedInput.value !== ruleTemplates[0] &&
                numOfEmployees < 1
              ) {
                setNumOfEmployees(1);
              }
              setSelectedTemplate(selectedInput.value);
            }}
            value={convertSingleOptionToPropForm(selectedTemplate)}
          />
        </div>
      </div> */}
      <button
        className={`${!isAllRequiredFieldFilled && styles.disabled} ${
          styles.submitButton
        }`}
        onClick={isAllRequiredFieldFilled ? handleStartFreeTrial : () => {}}
      >
        Get started for free
      </button>
      <div className={styles.loadingSpinnerContainer}>
        {isSubscriptionLoading && <LoadingSpinner />}
      </div>
    </div>
  );
};

export default StartTrialForm;
