import { KEYWORD_NA, KEYWORD_OFF } from "../../../constants/keywords";
import {
  DateTime,
  deepCopyObject,
  getShortIds,
  isNumberString,
  lowercaseFirstCharacter,
  memoizeFunction,
} from "../../../utils";
import SubRule from "../../../utils/dataTypesUtils/SubRule";
import { allocationFulfilsShiftGroup } from "../../rosterProblems/service/preferencesAndFixedShifts";
import { getRosterRulesDict } from "./ruleFunctions";

export const isReservedRuleValue = (ruleValue) => {
  if ([KEYWORD_NA].indexOf(ruleValue) > -1) {
    return true;
  }
  return false;
};

var cachedRules = [];
var cachedKey = null;

const generateCacheKey = (key, shifts, shiftGroups) => {
  const shiftsKey = shifts
    .map((s) => s.shortId)
    .sort()
    .join(",");
  const shiftGroupsKey = shiftGroups
    .map((sg) => sg.shortId)
    .sort()
    .join(",");
  return `${key}|${shiftsKey}|${shiftGroupsKey}`;
};

const getFormattedSubrules = (subrules) => {
  return subrules.map((subrule) => {
    return {
      inputs: subrule.get_inputs(),
      ruleTemplate: subrule.get_rule_template(),
      ruleName: subrule.get_rule_name(),
    };
  });
};

export const getStarterRules = () => {
  const rules = [];
  const daysOnSubrules = [];
  const hoursSubrules = [];

  ["per 2 weeks", "per week", "per roster"].forEach((period) => {
    ["work exactly", "work at most"].forEach((bound) => {
      ["must", "should"].forEach((severity) => {
        daysOnSubrules.push(new SubRule("numDaysOn", severity, bound, period));
        hoursSubrules.push(new SubRule("numHours", severity, bound, period));
      });
    });
  });

  rules.push({
    name: "days on",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: getFormattedSubrules(daysOnSubrules),
  });

  rules.push({
    name: "hours",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: getFormattedSubrules(hoursSubrules),
  });

  return rules;
};

//might want to make prohibited shifts default
const getRules = (shifts, shiftGroups, tasks = [], key = "") => {
  const cacheKey = generateCacheKey(key, shifts, shiftGroups);

  if (cachedKey === cacheKey) {
    return cachedRules;
  }

  const nightShiftNames = [];

  for (let i = 0; i < shifts.length; i++) {
    let startTime = new Date("2011-04-20T" + shifts[i].startTime);
    let finishTime = new Date("2011-04-20T" + shifts[i].finishTime);
    let nightBoundary = new Date("2011-04-20T01:00:00.00");

    if (
      startTime <= nightBoundary ||
      (startTime > finishTime && finishTime > nightBoundary)
    ) {
      nightShiftNames.push(shifts[i].name);
    }
  }

  const shiftAndOnstretchNames = [];
  for (let i = 0; i < shifts.length; i++) {
    shiftAndOnstretchNames.push({
      id: shifts[i].shortId,
      name: shifts[i].name,
      type: "shifts",
    });
  }

  for (let i = 0; i < shiftGroups.length; i++) {
    shiftAndOnstretchNames.push({
      id: shiftGroups[i].shortId,
      name: shiftGroups[i].name,
      type: "shift groups",
    });
  }

  shiftAndOnstretchNames.push({
    id: "nights (automatic)",
    name: "nights (automatic)",
    type: "shift groups",
  });

  const taskNames = [];
  for (const task of tasks) {
    taskNames.push({
      id: task.shortId,
      name: task.name,
    });
  }

  const rules = [];

  const skillsSubrules = [];
  const shiftTypeSubrules = [];
  const daysOnSubrules = [];
  const daysOffSubrules = [];
  const hoursSubrules = [];

  const mondayOnRules = [];
  const tuesdayOnRules = [];
  const wednesdayOnRules = [];
  const thursdayOnRules = [];
  const fridayOnRules = [];
  const saturdayOnRules = [];
  const sundayOnRules = [];

  let shiftsSubrules = {};
  let tasksSubrules = {};
  let otherShiftSubRules = {};

  const getWeekdaySubrule = (weekDayRules, dowNum, severity, bound, period) => {
    const dow = `${DateTime.numToDoWString(dowNum)}s`;
    weekDayRules.push(
      new SubRule("weekDayOn", severity, bound, period, null, "", dow)
    );
  };

  shiftAndOnstretchNames.forEach((shift) => {
    shiftsSubrules[shift.id] = [];
    otherShiftSubRules[shift.id] = [];
  });

  taskNames.forEach((task) => {
    tasksSubrules[task.id] = [];
  });

  ["per roster", "per 2 weeks", "per week", "per 2 weeks (offset)"].forEach(
    (period) => {
      ["work at least", "work at most", "work exactly"].forEach((bound) => {
        ["must", "should"].forEach((severity) => {
          hoursSubrules.push(new SubRule("numHours", severity, bound, period));
          shiftAndOnstretchNames.forEach((shift) => {
            hoursSubrules.push(
              new SubRule("numHours", severity, bound, period, shift)
            );
          });
        });
      });
    }
  );

  ["per roster", "per 2 weeks", "per week"].forEach((period) => {
    ["work at least", "work at most", "work exactly"].forEach((bound) => {
      ["must", "should"].forEach((severity) => {
        daysOnSubrules.push(new SubRule("numDaysOn", severity, bound, period));
        shiftAndOnstretchNames.forEach((shift) => {
          shiftsSubrules[shift.id].push(
            new SubRule("numShifts", severity, bound, period, shift)
          );
          shiftsSubrules[shift.id].push(
            new SubRule(
              "numShiftsDoubleCountSubtasks",
              severity,
              bound,
              period,
              shift
            )
          );
        });
        taskNames.forEach((task) => {
          tasksSubrules[task.id].push(
            new SubRule("numTasks", severity, bound, period, task)
          );
        });
      });
    });

    ["have at least", "have at most", "have exactly"].forEach((bound) => {
      ["must", "should", "must (if able)"].forEach((severity) => {
        daysOffSubrules.push(
          new SubRule("numDaysOff", severity, bound, period)
        );
        daysOffSubrules.push(
          new SubRule(
            "numDaysOff",
            severity,
            bound,
            period,
            null,
            null,
            "weekend"
          )
        );
      });
    });
  });

  ["per roster", "per 2 weeks", "per week"].forEach((period) => {
    ["work at least", "work at most", "work exactly"].forEach((bound) => {
      ["must (if able)"].forEach((severity) => {
        daysOnSubrules.push(new SubRule("numDaysOn", severity, bound, period));
        shiftAndOnstretchNames.forEach((shift) => {
          shiftsSubrules[shift.id].push(
            new SubRule("numShifts", severity, bound, period, shift)
          );
        });
      });
    });
  });

  ["per roster"].forEach((period) => {
    ["work proportionally"].forEach((bound) => {
      ["should"].forEach((severity) => {
        shiftAndOnstretchNames.forEach((shift) => {
          shiftsSubrules[shift.id].push(
            new SubRule("numShifts", severity, bound, period, shift)
          );
        });
      });
    });
  });

  [
    "per four weeks",
    "across all weekends",
    "per even week",
    "per rolling four weeks",
  ].forEach((period) => {
    ["work at least", "work at most", "work exactly"].forEach((bound) => {
      ["must", "should"].forEach((severity) => {
        shiftAndOnstretchNames.forEach((shift) => {
          shiftsSubrules[shift.id].push(
            new SubRule("numShifts", severity, bound, period, shift)
          );
        });
        daysOffSubrules.push(
          new SubRule("numDaysOff", severity, bound, period)
        );
      });
    });
  });

  ["per four weeks"].forEach((period) => {
    ["have at least", "have at most", "have exactly"].forEach((bound) => {
      ["must", "should"].forEach((severity) => {
        daysOffSubrules.push(
          new SubRule(
            "numDaysOff",
            severity,
            bound,
            period,
            null,
            null,
            "weekend"
          )
        );
      });
    });
  });

  shiftAndOnstretchNames.forEach((shift) => {
    otherShiftSubRules[shift.id].push(
      {
        inputs: ["must", "have at least", `per 2 weeks`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Minimum;Fortnight;${shift.id}`,
        ruleName: `Min ${shift.name} shifts or otherwise 0 per 2 weeks`,
      },
      {
        inputs: ["should", "have at least", `per 2 weeks`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Minimum;Fortnight;${shift.id}`,
        ruleName: `Preferred min ${shift.name} shifts or otherwise 0 per 2 weeks`,
      },
      {
        inputs: ["must", "have exactly", `per 2 weeks`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Equal;Fortnight;${shift.id}`,
        ruleName: `${shift.name} shifts or otherwise 0 per 2 weeks`,
      },
      {
        inputs: ["should", "have exactly", `per 2 weeks`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Equal;Fortnight;${shift.id}`,
        ruleName: `Preferred ${shift.name} shifts or otherwise 0 per 2 weeks`,
      },
      {
        inputs: ["must", "have at least", `per week`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Minimum;Week;${shift.id}`,
        ruleName: `Min ${shift.name} shifts or otherwise 0 per week`,
      },
      {
        inputs: ["should", "have at least", `per week`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Minimum;Week;${shift.id}`,
        ruleName: `Preferred min ${shift.name} shifts or otherwise 0 per week`,
      },
      {
        inputs: ["must", "have exactly", `per week`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Equal;Week;${shift.id}`,
        ruleName: `${shift.name} shifts or otherwise 0 per week`,
      },
      {
        inputs: ["should", "have exactly", `per week`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Equal;Week;${shift.id}`,
        ruleName: `Preferred ${shift.name} shifts or otherwise 0 per week`,
      },
      {
        inputs: ["must", "have at least", `per roster`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Minimum;Roster;${shift.id}`,
        ruleName: `Min ${shift.name} shifts or otherwise 0 per roster`,
      },
      {
        inputs: ["should", "have at least", `per roster`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Minimum;Roster;${shift.id}`,
        ruleName: `Preferred min ${shift.name} shifts or otherwise 0 per roster`,
      },
      {
        inputs: ["must", "have exactly", `per roster`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Critical;Equal;Roster;${shift.id}`,
        ruleName: `${shift.name} shifts or otherwise 0 per roster`,
      },
      {
        inputs: ["should", "have exactly", `per roster`, "or otherwise 0"],
        ruleTemplate: `numShiftsOrNothing;Medium;Equal;Roster;${shift.id}`,
        ruleName: `Preferred ${shift.name} shifts or otherwise 0 per roster`,
      }
    );
  });

  ["per roster"].forEach((period) => {
    ["work at least", "work at most", "work exactly"].forEach((bound) =>
      ["must", "should"].forEach((severity) => {
        [
          sundayOnRules,
          mondayOnRules,
          tuesdayOnRules,
          wednesdayOnRules,
          thursdayOnRules,
          fridayOnRules,
          saturdayOnRules,
        ].forEach((weekDayRules, dowNum) => {
          getWeekdaySubrule(weekDayRules, dowNum, severity, bound, period);
        });
      })
    );
  });

  ["per roster", "per 2 weeks", "per week"].forEach((period) => {
    ["must", "should"].forEach((severity) => {
      ["have at most"].forEach((bound) => {
        skillsSubrules.push(new SubRule("numSkills", severity, bound, period));
      });
    });
  });

  ["per 7 day period", "after on stretch start (7 days)"].forEach((period) => {
    ["must"].forEach((severity) => {
      ["work at most"].forEach((bound) => {
        shiftTypeSubrules.push(
          new SubRule("numShiftTypes", severity, bound, period)
        );
      });
    });
  });

  ["in a row"].forEach((period) => {
    ["must", "should", "with exception"].forEach((severity) => {
      ["have at least", "have at most", "have exactly"].forEach((bound) => {
        daysOffSubrules.push(
          new SubRule("offstretchLength", severity, bound, period)
        );
      });
      ["work at least", "work at most", "work exactly"].forEach((bound) => {
        ["", "containing a weekend"].forEach((day) => {
          daysOnSubrules.push(
            new SubRule("onstretchLength", severity, bound, period, null, day)
          );
        });
      });
    });
  });

  ["in a row"].forEach((period) => {
    ["must", "should", "must (if able)", "with exception"].forEach(
      (severity) => {
        ["work at least", "work at most", "work exactly"].forEach((bound) => {
          shiftAndOnstretchNames.forEach((shift) => {
            ["", "ignoring weekends"].forEach((day) => {
              shiftsSubrules[shift.id].push(
                new SubRule("numShiftsRow", severity, bound, period, shift, day)
              );
            });
            shiftsSubrules[shift.id].push(
              new SubRule(
                "numShiftsRow",
                severity,
                bound,
                period,
                shift,
                "",
                null,
                false
              )
            );
            if (
              ["must (if able)", "with exception"].includes(severity) ||
              ["work at most", "work exactly"].includes(bound)
            )
              return;
            shiftsSubrules[shift.id].push(
              new SubRule(
                "numShiftsWeekend",
                severity,
                bound,
                period,
                shift,
                "",
                null,
                false
              )
            );
            shiftsSubrules[shift.id].push(
              new SubRule(
                "numShiftsLongWeekend",
                severity,
                bound,
                period,
                shift,
                "",
                null,
                false
              )
            );
          });
        });

        if (severity === "must (if able)") return;
        ["work at most"].forEach((bound) => {
          if (severity === "with exception") return;
          hoursSubrules.push(
            new SubRule("hoursOnRow", severity, bound, period)
          );
        });
      }
    );
  });

  const minimumPreferencesSubrules = [];

  ["Critical", "High", "Low"].forEach((criticality) => {
    minimumPreferencesSubrules.push({
      inputs: [
        "must",
        "have at least",
        "fulfilled",
        `that are ${criticality.toLowerCase()}`,
      ],
      ruleTemplate: `minimumPreferences;Critical;${criticality}`,
      ruleName: `Min ${criticality.toLowerCase()} preferences`,
    });
    minimumPreferencesSubrules.push({
      inputs: [
        "should",
        "have at least",
        "fulfilled",
        `that are ${criticality.toLowerCase()}`,
      ],
      ruleTemplate: `minimumPreferences;High;${criticality}`,
      ruleName: `Preferred min ${criticality.toLowerCase()} preferences`,
    });
    minimumPreferencesSubrules.push({
      inputs: [
        "should",
        "have at most",
        "unfulfilled",
        `that are ${criticality.toLowerCase()}`,
      ],
      ruleTemplate: `maximumPreferences;High;${criticality}`,
      ruleName: `Preferred max unfulfilled ${criticality.toLowerCase()} preferences`,
    });
    minimumPreferencesSubrules.push({
      inputs: [
        "must",
        "have at most",
        "unfulfilled",
        `that are ${criticality.toLowerCase()}`,
      ],
      ruleTemplate: `maximumPreferences;Critical;${criticality}`,
      ruleName: `Max unfulfilled ${criticality.toLowerCase()} preferences`,
    });
    minimumPreferencesSubrules.push({
      inputs: [
        "must (if able)",
        "have at most",
        "unfulfilled",
        `that are ${criticality.toLowerCase()}`,
      ],
      ruleTemplate: `maximumPreferences;CriticalShould;${criticality}`,
      ruleName: `Max (if able) unfulfilled ${criticality.toLowerCase()} preferences`,
    });
  });

  shiftAndOnstretchNames.forEach((shift) => {
    minimumPreferencesSubrules.push({
      inputs: [
        "should",
        "have at most",
        "unfulfilled",
        `of ${shift.name} shifts`,
      ],
      ruleTemplate: `maximumPreferences;High;;${shift.id}`,
      ruleName: `Preferred max unfulfilled ${shift.name} preferences`,
    });
    minimumPreferencesSubrules.push({
      inputs: [
        "must",
        "have at most",
        "unfulfilled",
        `of ${shift.name} shifts`,
      ],
      ruleTemplate: `maximumPreferences;Critical;;${shift.id}`,
      ruleName: `Max unfulfilled ${shift.name} preferences`,
    });
  });

  rules.push({
    name: "preferences",
    formattedName: "preferences",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: minimumPreferencesSubrules,
  });

  rules.push({
    name: "days on",
    formattedName: "days on",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: getFormattedSubrules(daysOnSubrules),
  });

  const daysOffRule = {
    name: "days off",
    formattedName: "days off",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      ...getFormattedSubrules(daysOffSubrules),
      {
        inputs: [
          "must",
          "have at least",
          `after a night shift (${nightShiftNames.join()})`,
          "",
        ],
        ruleTemplate: "twoDaysOffAfterNight;Critical",
        ruleName: "Min days off after night shift",
      },
      {
        inputs: [
          "should",
          "have at least",
          `after a night shift (${nightShiftNames.join()})`,
          "",
        ],
        ruleTemplate: "twoDaysOffAfterNight;Medium",
        ruleName: "Preferred min days off after night shift",
      },
      {
        inputs: [
          "with exception",
          "have at least",
          `after a night shift (${nightShiftNames.join()})`,
          "",
        ],
        ruleTemplate: `twoDaysOffAfterNight;Mostly`,
        ruleName: `With exception min days off after night shift`,
      },
      {
        inputs: ["must", "have at least", `per weekend`, ""],
        ruleTemplate: "fullWeekends;Critical",
        ruleName: "Days off per weekend",
      },
      {
        inputs: ["should", "have at least", `per weekend`, ""],
        ruleTemplate: "fullWeekends;Medium",
        ruleName: "Preferred days off per weekend",
      },
      {
        inputs: ["must", "have at least", `after 3+ days on`, ""],
        ruleTemplate: "onOffPattern;Critical;3",
        ruleName: "Min days off after 3+ days on",
      },
      {
        inputs: ["must", "have at least", `after 4+ days on`, ""],
        ruleTemplate: "onOffPattern;Critical;4",
        ruleName: "Min days off after 4+ days on",
      },
      {
        inputs: ["must", "have at least", `after 5+ days on`, ""],
        ruleTemplate: "onOffPattern;Critical;5",
        ruleName: "Min days off after 5+ days on",
      },
      {
        inputs: ["must", "have at least", `after 6+ days on`, ""],
        ruleTemplate: "onOffPattern;Critical;6",
        ruleName: "Min days off after 6+ days on",
      },
      {
        inputs: ["must", "have at least", `after 3+ nights`, ""],
        ruleTemplate: "onOffPatternNights;Critical;3",
        ruleName: "Min days off after 3+ nights",
      },
      {
        inputs: ["must", "have at least", `after 4+ nights`, ""],
        ruleTemplate: "onOffPatternNights;Critical;4",
        ruleName: "Min days off after 4+ nights",
      },
      {
        inputs: ["must", "have at least", `after 5+ nights`, ""],
        ruleTemplate: "onOffPatternNights;Critical;5",
        ruleName: "Min days off after 5+ nights",
      },
      {
        inputs: ["must", "have at least", `after 6+ nights`, ""],
        ruleTemplate: "onOffPatternNights;Critical;6",
        ruleName: "Min days off after 6+ nights",
      },
    ],
  };
  rules.push(daysOffRule);

  rules.push({
    name: "hours",
    formattedName: "hours",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      ...getFormattedSubrules(hoursSubrules),
      {
        inputs: ["must", "have at least", "off between shifts", ""],
        ruleTemplate: "timeBetweenShifts;Critical",
        ruleName: "Min hours between shifts",
      },
      {
        inputs: ["must", "work exactly", "per shift", ""],
        ruleTemplate: "shiftLength",
        ruleName: "shiftLength",
      },
      {
        inputs: [
          "must",
          "have at least",
          `off after a night shift (${nightShiftNames.join()})`,
          "",
        ],
        ruleTemplate: "hoursOffAfterNight;Critical",
        ruleName: "Min hours off after night shift",
      },
    ],
  });

  const weekendRule = {
    name: "weekends off",
    formattedName: "weekends off",
    type: "general",
    valueType: "half_integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: ["must", "have at least", "per roster", ""],
        ruleTemplate: "minimumWeekends;Critical",
        ruleName: "Min weekends off",
      },
      {
        inputs: ["should", "have at least", "per roster", ""],
        ruleTemplate: "minimumWeekends;Medium",
        ruleName: "Min preferred weekends off",
      },
      {
        inputs: ["must (if able)", "have at least", "per roster", ""],
        ruleTemplate: "minimumWeekends;CriticalShould",
        ruleName: "Min (if able) weekends off",
      },
      {
        inputs: ["must", "have at least", "per rolling 2 weeks", ""],
        ruleTemplate: "minimumWeekends;Critical;RollingFortnight",
        ruleName: "Min weekends off per rolling 2 weeks",
      },
      {
        inputs: ["should", "have at least", "per rolling 2 weeks", ""],
        ruleTemplate: "minimumWeekends;Medium;RollingFortnight",
        ruleName: "Min preferred weekends off per rolling 2 weeks",
      },
      {
        inputs: ["must (if able)", "have at least", "per rolling 2 weeks", ""],
        ruleTemplate: "minimumWeekends;CriticalShould;RollingFortnight",
        ruleName: "Min (if able) weekends off per rolling 2 weeks",
      },
      {
        inputs: ["must", "have at least", "per rolling three weeks", ""],
        ruleTemplate: "minimumWeekends;Critical;RollingThreeWeeks",
        ruleName: "Min weekends off per rolling three weeks",
      },
      {
        inputs: ["should", "have at least", "per rolling three weeks", ""],
        ruleTemplate: "minimumWeekends;Medium;RollingThreeWeeks",
        ruleName: "Min preferred weekends off per rolling three weeks",
      },
      {
        inputs: [
          "must (if able)",
          "have at least",
          "per rolling three weeks",
          "",
        ],
        ruleTemplate: "minimumWeekends;CriticalShould;RollingThreeWeeks",
        ruleName: "Min (if able) weekends off per rolling three weeks",
      },
      {
        inputs: ["must", "have at least", "per rolling four weeks", ""],
        ruleTemplate: "minimumWeekends;Critical;RollingFourWeeks",
        ruleName: "Min weekends off per rolling four weeks",
      },
      {
        inputs: ["should", "have at least", "per rolling four weeks", ""],
        ruleTemplate: "minimumWeekends;Medium;RollingFourWeeks",
        ruleName: "Min preferred weekends off per rolling four weeks",
      },
      {
        inputs: [
          "must (if able)",
          "have at least",
          "per rolling four weeks",
          "",
        ],
        ruleTemplate: "minimumWeekends;CriticalShould;RollingFourWeeks",
        ruleName: "Min (if able) weekends off per rolling four weeks",
      },
    ],
  };

  for (let i = 0; i < shiftAndOnstretchNames.length; i++) {
    weekendRule.subrules.push(
      ...[
        {
          inputs: [
            "must",
            "have at least",
            "per roster",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Critical;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min weekends without ${shiftAndOnstretchNames[i].name} shifts`,
        },
        {
          inputs: [
            "should",
            "have at least",
            "per roster",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Medium;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min preferred weekends without ${shiftAndOnstretchNames[i].name} shifts`,
        },
        {
          inputs: [
            "must (if able)",
            "have at least",
            "per roster",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;CriticalShould;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min (if able) weekends without ${shiftAndOnstretchNames[i].name} shifts`,
        },
        {
          inputs: [
            "must",
            "have at least",
            "per rolling 2 weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Critical;RollingFortnight;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling 2 weeks`,
        },
        {
          inputs: [
            "should",
            "have at least",
            "per rolling 2 weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Medium;RollingFortnight;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min preferred weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling 2 weeks`,
        },
        {
          inputs: [
            "must (if able)",
            "have at least",
            "per rolling 2 weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;CriticalShould;RollingFortnight;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min (if able) weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling 2 weeks`,
        },
        {
          inputs: [
            "must",
            "have at least",
            "per rolling three weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Critical;RollingThreeWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling three weeks`,
        },
        {
          inputs: [
            "should",
            "have at least",
            "per rolling three weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Medium;RollingThreeWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min preferred weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling three weeks`,
        },
        {
          inputs: [
            "must (if able)",
            "have at least",
            "per rolling three weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;CriticalShould;RollingThreeWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min (if able) weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling three weeks`,
        },
        {
          inputs: [
            "must",
            "have at least",
            "per rolling four weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Critical;RollingFourWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling four weeks`,
        },
        {
          inputs: [
            "should",
            "have at least",
            "per rolling four weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;Medium;RollingFourWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min preferred weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling four weeks`,
        },
        {
          inputs: [
            "must (if able)",
            "have at least",
            "per rolling four weeks",
            `without ${shiftAndOnstretchNames[i].name} shifts`,
          ],
          ruleTemplate: `minimumWeekends;CriticalShould;RollingFourWeeks;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min (if able) weekends without ${shiftAndOnstretchNames[i].name} shifts per rolling four weeks`,
        },
      ]
    );
  }

  rules.push(weekendRule);

  rules.push({
    name: "long weekends",
    formattedName: "long weekends",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: ["must", "have at least", KEYWORD_OFF, "per roster"],
        ruleTemplate: "minimumLongWeekends;Critical",
        ruleName: "Min long weekends off",
      },
      {
        inputs: ["should", "have at least", KEYWORD_OFF, "per roster"],
        ruleTemplate: "minimumLongWeekends;Medium",
        ruleName: "Min preferred long weekends off",
      },
    ],
  });

  const shiftChangeRule = {
    name: "shift changes", //Employees should ideally not change between day/night/any shifts and day/night/any other shifts
    formattedName: "shift changes",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: [
          "must",
          "have at most",
          "in a roster",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Critical;Roster",
        ruleName: "Max shift changes",
      },
      {
        inputs: [
          "should",
          "have at most",
          "in a roster",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Medium;Roster",
        ruleName: "Preferable max shift changes",
      },
      {
        inputs: [
          "must",
          "have at most",
          "in a rolling 2 weeks",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Critical;RollingFortnight",
        ruleName: "Max shift changes in a rolling 2 weeks",
      },
      {
        inputs: [
          "should",
          "have at most",
          "in a rolling 2 weeks",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Medium;RollingFortnight",
        ruleName: "Preferable max shift changes in a rolling 2 weeks",
      },
      {
        inputs: [
          "must",
          "have at most",
          "in a week",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Critical;Week",
        ruleName: "Max shift changes in a week",
      },
      {
        inputs: [
          "should",
          "have at most",
          "in a week",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Medium;Week",
        ruleName: "Preferable max shift changes in a week",
      },
      {
        inputs: [
          "must",
          "have at most",
          "across all weekends",
          `from any shift to any other shift`,
        ],
        ruleTemplate: `shiftChanges;Critical;AcrossWeekends`,
        ruleName: `Max shift changes across all weekends`,
      },
      {
        inputs: [
          "should",
          "have at most",
          "across all weekends",
          `from any shift to any other shift`,
        ],
        ruleTemplate: `shiftChanges;Medium;AcrossWeekends`,
        ruleName: `Preferable max shift changes across all weekends`,
      },
      {
        inputs: [
          "must",
          "have at most",
          "in a roster (ignoring days off)",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Critical;Roster;true",
        ruleName: "Max shift changes ignoring offs",
      },
      {
        inputs: [
          "should",
          "have at most",
          "in a roster (ignoring days off)",
          "from any shift to any other shift",
        ],
        ruleTemplate: "shiftChanges;Medium;Roster;true",
        ruleName: "Preferable max shift changes ignoring offs",
      },
    ],
  };

  const sequentialChangeRule = {
    name: "sequential violations", //Employees should ideally not change between day/night/any shifts and day/night/any other shifts
    formattedName: "sequential violations",
    type: "advanced",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [],
  };

  const taskChangeSubrules = [];

  shiftAndOnstretchNames.forEach((shiftGroup) => {
    if (shiftGroup.type !== "shift groups") {
      return;
    }
    taskChangeSubrules.push({
      inputs: [
        "must",
        "have at most",
        `per roster`,
        `between tasks from ${shiftGroup.name}`,
      ],
      ruleTemplate: `taskChanges;Critical;Maximum;Roster;${shiftGroup.id}`,
      ruleName: `Max task changes from ${shiftGroup.name}`,
    });

    taskChangeSubrules.push({
      inputs: [
        "should",
        "have at most",
        `per roster`,
        `between tasks from ${shiftGroup.name}`,
      ],
      ruleTemplate: `taskChanges;Medium;Maximum;Roster;${shiftGroup.id}`,
      ruleName: `Prefer max task changes from ${shiftGroup.name}`,
    });
  });

  const taskChangeRele = {
    name: "task changes", //Employees should ideally not change between day/night/any shifts and day/night/any other shifts
    formattedName: "task changes",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: taskChangeSubrules,
  };

  rules.push(taskChangeRele);

  const teamsRule = {
    name: "team",
    formattedName: "team",
    type: "advanced",
    defaultValue: "team 1",
    subrules: [],
    valueType: "string",
  };

  const taskRule = {
    name: "tasks",
    formattedName: "tasks",
    type: "general",
    subrules: [],
    valueType: "integer",
    canChangeDefaultValue: true,
  };

  rules.push({
    name: "shifts worked with each skill",
    formattedName: "shifts worked with each skill",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: getFormattedSubrules(skillsSubrules),
  });

  rules.push({
    name: "types of shifts",
    formattedName: "types of shifts",
    type: "general",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: getFormattedSubrules(shiftTypeSubrules),
  });

  let unpaidMinutesSubrules = [
    {
      inputs: ["must", "have exactly", "", "during any shift"],
      ruleTemplate: `unpaidMinutes;Critical`,
      ruleName: `Unpaid minutes per shift`,
    },
  ];

  rules.push({
    name: "unpaid minutes",
    formattedName: "unpaid minutes",
    type: "general",
    subrules: unpaidMinutesSubrules,
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
  });

  teamsRule.subrules.push({
    inputs: ["should", "work in the same", "", "(min team size)"],
    ruleTemplate: `minTeamSize;Medium`,
    ruleName: `Prefer to work in a team`,
  });

  teamsRule.subrules.push({
    inputs: ["should", "work in the same", "", "(with other team members)"],
    ruleTemplate: `teamsNonLinear;Medium`,
    ruleName: `Prefer to work with other team members`,
  });

  for (let i = 0; i < shiftAndOnstretchNames.length; i++) {
    unpaidMinutesSubrules.push({
      inputs: [
        "must",
        "have exactly",
        "",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
      ],
      ruleTemplate: `unpaidMinutes;Critical;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Unpaid minutes in ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "must",
        "work at least",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "",
      ],
      ruleTemplate: `minTasksPerShift;Critical;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Min tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "must",
        "work at least",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "that are unique",
      ],
      ruleTemplate: `minUniqueTasksPerShift;Critical;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Min unique tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "should",
        "work at least",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "",
      ],
      ruleTemplate: `minTasksPerShift;Medium;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Preferred min tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "should",
        "work at least",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "that are unique",
      ],
      ruleTemplate: `minUniqueTasksPerShift;Medium;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Preferred min unique tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "must",
        "work at most",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "",
      ],
      ruleTemplate: `maxTasksPerShift;Critical;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Max tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "must",
        "work at most",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "that are unique",
      ],
      ruleTemplate: `maxUniqueTasksPerShift;Critical;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Max unique tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "should",
        "work at most",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "",
      ],
      ruleTemplate: `maxTasksPerShift;Medium;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Preferred max tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    taskRule.subrules.push({
      inputs: [
        "should",
        "work at most",
        `during a ${shiftAndOnstretchNames[i].name} shift`,
        "that are unique",
      ],
      ruleTemplate: `maxUniqueTasksPerShift;Medium;${shiftAndOnstretchNames[i].id}`,
      ruleName: `Preferred max unique tasks during ${shiftAndOnstretchNames[i].name} shift`,
    });

    daysOffRule.subrules.push(
      {
        inputs: [
          "must",
          "have at least",
          `after a ${shiftAndOnstretchNames[i].name} shift`,
          "",
        ],
        ruleTemplate: `daysOffAfterShift;Critical;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Min days off after ${shiftAndOnstretchNames[i].name} shift`,
      },
      {
        inputs: [
          "should",
          "have at least",
          `after a ${shiftAndOnstretchNames[i].name} shift`,
          "",
        ],
        ruleTemplate: `daysOffAfterShift;Medium;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Preferred min days off after ${shiftAndOnstretchNames[i].name} shift`,
      },
      {
        inputs: [
          "with exception",
          "have at least",
          `after a ${shiftAndOnstretchNames[i].name} shift`,
          "",
        ],
        ruleTemplate: `daysOffAfterShift;Mostly;${shiftAndOnstretchNames[i].id}`,
        ruleName: `With exception min days off after ${shiftAndOnstretchNames[i].name} shift`,
      }
    );

    for (let j = 0; j < shiftAndOnstretchNames.length; j++) {
      if (i === j) continue;

      sequentialChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "in a roster",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `sequentialShifts;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;Roster`,
        ruleName: `Max sequential ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} violations in a roster`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "in a roster",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;Roster`,
        ruleName: `Max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a roster`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "should",
          "have at most",
          "in a roster",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Medium;Roster`,
        ruleName: `Preferable max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a roster`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "in a rolling 2 weeks",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;RollingFortnight`,
        ruleName: `Max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a rolling 2 weeks`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "should",
          "have at most",
          "in a rolling 2 weeks",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Medium;RollingFortnight`,
        ruleName: `Preferable max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a rolling 2 weeks`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "in a week",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;Week`,
        ruleName: `Max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a week`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "should",
          "have at most",
          "in a week",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Medium;Week`,
        ruleName: `Preferable max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} in a week`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "across all weekends",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;AcrossWeekends`,
        ruleName: `Max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} across all weekends`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "should",
          "have at most",
          "across all weekends",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Medium;AcrossWeekends`,
        ruleName: `Preferable max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} across all weekends`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "should",
          "have at most",
          "in a roster (ignoring days off)",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Medium;Roster;true`,
        ruleName: `Preferable max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} ignoring offs`,
      });

      shiftChangeRule.subrules.push({
        inputs: [
          "must",
          "have at most",
          "in a roster (ignoring days off)",
          `from ${shiftAndOnstretchNames[i].name} to ${shiftAndOnstretchNames[j].name}`,
        ],
        ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id};Critical;Roster;true`,
        ruleName: `Max ${shiftAndOnstretchNames[i].name} -> ${shiftAndOnstretchNames[j].name} ignoring offs`,
      });
    }

    shiftChangeRule.subrules.push({
      inputs: [
        "must",
        "have at most",
        "in a roster",
        `from ${shiftAndOnstretchNames[i].name} to leave`,
      ],
      ruleTemplate: `shiftChanges;${shiftAndOnstretchNames[i].id};Leave;Critical;Roster`,
      ruleName: `Max ${shiftAndOnstretchNames[i].name} -> leave in a roster`,
    });

    shiftChangeRule.subrules.push({
      inputs: [
        "must",
        "have at most",
        "in a roster",
        `from leave to ${shiftAndOnstretchNames[i].name}`,
      ],
      ruleTemplate: `shiftChanges;Leave;${shiftAndOnstretchNames[i].id};Critical;Roster`,
      ruleName: `Max leave -> ${shiftAndOnstretchNames[i].name} in a roster`,
    });
  }

  for (let i = 0; i < shiftAndOnstretchNames.length; i++) {
    for (let x = 2; x < 5; x++) {
      otherShiftSubRules[shiftAndOnstretchNames[i].id].push({
        inputs: ["must", "not work", "in a row", `without ${x} days off`],
        ruleTemplate: `minDaysOffAfterShiftsRow;Critical;${x};${shiftAndOnstretchNames[i].id}`,
        ruleName: `Min days off after ${x} ${shiftAndOnstretchNames[i].name} shifts`,
      });

      otherShiftSubRules[shiftAndOnstretchNames[i].id].push({
        inputs: ["should", "not work", "in a row", `without ${x} days off`],
        ruleTemplate: `minDaysOffAfterShiftsRow;Medium;${x};${shiftAndOnstretchNames[i].id}`,
        ruleName: `Preferred min days off after ${x} ${shiftAndOnstretchNames[i].name} shifts`,
      });
    }

    for (let j = 0; j < shiftAndOnstretchNames.length; j++) {
      if (i === j) continue;

      otherShiftSubRules[shiftAndOnstretchNames[i].id].push({
        inputs: [
          "must",
          "work at most",
          "in an onstretch",
          `containing a ${shiftAndOnstretchNames[j].name} shift`,
        ],
        ruleTemplate: `onstretchSeparateShifts;Critical;${shiftAndOnstretchNames[i].id};${shiftAndOnstretchNames[j].id}`,
        ruleName: `No ${shiftAndOnstretchNames[i].name} with ${shiftAndOnstretchNames[j].name} shifts`,
      });
    }

    daysOffRule.subrules.push(
      {
        inputs: [
          "must",
          "have at least",
          `between ${shiftAndOnstretchNames[i].name} shifts`,
          "",
        ],
        ruleTemplate: `minDaysOffBetweenShifts;Critical;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Min days off between ${shiftAndOnstretchNames[i].name} shifts`,
      },
      {
        inputs: [
          "should",
          "have at least",
          `between ${shiftAndOnstretchNames[i].name} shifts`,
          "",
        ],
        ruleTemplate: `minDaysOffBetweenShifts;Medium;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Preferred min days off between ${shiftAndOnstretchNames[i].name} shifts`,
      },
      {
        inputs: [
          "must",
          "have at least",
          `between different ${shiftAndOnstretchNames[i].name} shifts`,
          "",
        ],
        ruleTemplate: `minDaysOffBetweenShiftsExclusive;Critical;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Min days off between different ${shiftAndOnstretchNames[i].name} shifts`,
      },
      {
        inputs: [
          "should",
          "have at least",
          `between different ${shiftAndOnstretchNames[i].name} shifts`,
          "",
        ],
        ruleTemplate: `minDaysOffBetweenShiftsExclusive;Medium;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Preferred min days off between different ${shiftAndOnstretchNames[i].name} shifts`,
      }
    );
  }

  rules.push(shiftChangeRule);
  rules.push(sequentialChangeRule);

  if (taskRule.subrules.length > 0) {
    rules.push(taskRule);
  }

  if (teamsRule.subrules.length > 0) {
    rules.push(teamsRule);
  }

  const taskTypesRule = {
    name: "task types",
    formattedName: "task types",
    type: "general",
    subrules: [],
    valueType: "integer",
    canChangeDefaultValue: true,
  };

  for (let i = 0; i < shiftAndOnstretchNames.length; i++) {
    if (shiftAndOnstretchNames[i].type === "shift groups") {
      taskTypesRule.subrules.push({
        inputs: [
          "must",
          "work at most",
          "in an on-stretch",
          "from " + shiftAndOnstretchNames[i].name + " tasks",
        ],
        ruleTemplate: `continuityOfCareTasks;${shiftAndOnstretchNames[i].id}`,
        ruleName: `Max task types from ${shiftAndOnstretchNames[i].name} in an on-stretch`,
      });
    }
  }

  if (taskTypesRule.subrules.length > 0) {
    rules.push(taskTypesRule);
  }

  // sort alphabetically
  rules.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

  for (let i = 0; i < shiftAndOnstretchNames.length; i++) {
    const targetShift = shifts.find(
      (s) => s.shortId === shiftAndOnstretchNames[i].name
    );
    const targetShiftGroup = shiftGroups.find(
      (s) => s.shortId === shiftAndOnstretchNames[i].name
    );

    const name = `"${shiftAndOnstretchNames[i].name}" shifts`;
    let formattedName = name;
    if (targetShift) {
      formattedName = `"${targetShift.name}" shifts`;
    }
    if (targetShiftGroup) {
      formattedName = `"${targetShiftGroup.name}" shifts`;
    }

    rules.push({
      name,
      formattedName,
      type: "shift",
      valueType: "integer",
      valueRange: [0, 100],
      canChangeDefaultValue: true,
      subrules: [
        ...getFormattedSubrules(shiftsSubrules[shiftAndOnstretchNames[i].id]),
        ...otherShiftSubRules[shiftAndOnstretchNames[i].id],
        {
          inputs: ["must", "work at most", "in a rolling 2 weeks", ""],
          ruleTemplate: `numRollingShifts;Maximum;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Max ${shiftAndOnstretchNames[i].name} shifts in a rolling 2 weeks`,
        },
        {
          inputs: ["must", "work at least", "in a rolling 2 weeks", ""],
          ruleTemplate: `numRollingShifts;Minimum;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Min ${shiftAndOnstretchNames[i].name} shifts in a rolling 2 weeks`,
        },
        {
          inputs: ["must", "work at most", "at the start of a on-stretch", ""],
          ruleTemplate: `startOnstretch;Critical;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Max ${shiftAndOnstretchNames[i].name} shifts at the start of a on-stretch`,
        },
        {
          inputs: ["must", "work at most", "at the end of a on-stretch", ""],
          ruleTemplate: `endOnstretch;Critical;${shiftAndOnstretchNames[i].id}`,
          ruleName: `Max ${shiftAndOnstretchNames[i].name} shifts at the end of a on-stretch`,
        },
      ],
    });
  }

  for (const task of taskNames) {
    const targetTask = tasks.find((t) => t.shortId === task.id);
    rules.push({
      name: `"${task.name}" tasks`,
      formattedName: `"${targetTask.name}" tasks`,
      type: "task",
      valueType: "integer",
      valueRange: [0, 100],
      canChangeDefaultValue: true,
      subrules: getFormattedSubrules(tasksSubrules[task.id]),
    });
  }

  rules.push({
    name: "weeks with 48 hours off",
    formattedName: "weeks with 48 hours off",
    type: "advanced",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: ["must", "have at least", "", ""],
        ruleTemplate: "minWeeks48HoursOff",
        ruleName: "Min weeks with 48 hours off",
      },
    ],
  });

  rules.push({
    name: "one change from day shifts to night shifts",
    formattedName: "one change from day shifts to night shifts",
    type: "advanced",
    defaultValue: "1",
    valueType: "integer",
    valueRange: [1, 1],
    subrules: [
      {
        inputs: ["must", "have at most", "per 2 weeks", ""],
        ruleTemplate: "numShiftChangesPeriod;Fortnight;Minimum",
        ruleName: "Max changes to nights per 2 weeks",
      },
      {
        inputs: ["should", "have at most", "per 2 weeks", ""],
        ruleTemplate: "numShiftChangesPeriod;Fortnight;Minimum;Medium",
        ruleName: "Prefer max changes to nights per 2 weeks",
      },
      {
        inputs: ["must", "have at most", "per roster", ""],
        ruleTemplate: "numShiftChangesPeriod;Roster;Minimum",
        ruleName: "Max changes to nights per roster",
      },
      {
        inputs: ["should", "have at most", "per roster", ""],
        ruleTemplate: "numShiftChangesPeriod;Roster;Minimum;Medium",
        ruleName: "Prefer max changes to nights per roster",
      },
      {
        inputs: ["must", "have at most", "per week", ""],
        ruleTemplate: "numShiftChangesPeriod;Week;Minimum",
        ruleName: "Max changes to nights per week",
      },
      {
        inputs: ["should", "have at most", "per week", ""],
        ruleTemplate: "numShiftChangesPeriod;Week;Minimum;Medium",
        ruleName: "Prefer max changes to nights per week",
      },
    ],
  });

  rules.push({
    name: "hours of leave per leave day",
    formattedName: "hours of leave per leave day",
    type: "advanced",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: ["must", "have", "", ""],
        ruleTemplate: "hoursLeavePerLeaveDay",
        ruleName: "Hours leave per leave day",
      },
    ],
  });

  rules.push({
    name: "worked days before public holiday",
    formattedName: "worked days before public holiday",
    type: "advanced",
    valueType: "integer",
    valueRange: [0, 100],
    canChangeDefaultValue: true,
    subrules: [
      {
        inputs: ["must", "have", "", ""],
        ruleTemplate: "daysBeforePublicHoliday",
        ruleName: "Public holiday optimiser",
      },
    ],
  });

  [
    mondayOnRules,
    tuesdayOnRules,
    wednesdayOnRules,
    thursdayOnRules,
    fridayOnRules,
    saturdayOnRules,
    sundayOnRules,
  ].forEach((dowRules, idx) => {
    const dowNum = (idx + 1) % 7;
    const name = `${lowercaseFirstCharacter(DateTime.numToDoWString(dowNum))}s`;
    rules.push({
      name,
      formattedName: name,
      type: "general",
      valueType: "integer",
      valueRange: [0, 16],
      canChangeDefaultValue: true,
      subrules: getFormattedSubrules(dowRules),
    });
  });

  const ruleTemplatesChecked = getRosterRulesDict(shifts, shiftGroups, []);

  for (let r = 0; r < rules.length; r++) {
    for (let s = 0; s < rules[r].subrules.length; s++) {
      rules[r].subrules[s].isChecked =
        rules[r].subrules[s].ruleTemplate in ruleTemplatesChecked ||
        rules[r].subrules[s].ruleTemplate.startsWith(
          "numShifts;Roster;Minimum;Critical;"
        ) ||
        rules[r].subrules[s].ruleTemplate.startsWith(
          "numShifts;Roster;Maximum;Critical;"
        );
    }
  }

  cachedRules = rules;
  cachedKey = cacheKey;

  return rules;
};

export const getFlatRules = (shifts, shiftGroups, tasks, cacheKey) => {
  const rules = getRules(shifts, shiftGroups, tasks, cacheKey);
  return getFlatRulesFromRules(rules);
};

const getFlatRulesFromRules = (rules) => {
  const flatRules = [];
  for (const rule of rules) {
    for (let s = 0; s < rule.subrules.length; s++) {
      flatRules.push(rule.subrules[s]);
    }
  }

  return flatRules;
};

export const getRulesNameDict = (shifts, shiftGroups, tasks) => {
  const rules = getRules(shifts, shiftGroups, tasks);
  const nameDict = {};
  for (let r = 0; r < rules.length; r++) {
    for (let s = 0; s < rules[r].subrules.length; s++) {
      nameDict[rules[r].subrules[s].ruleTemplate] =
        rules[r].subrules[s].ruleName;
    }
  }
  return nameDict;
};

const keywordsDict = {
  "work at most": "maximum",
  "work at least": "minimum",
};

const uselessKeywords = [
  "work",
  "have",
  "at",
  "in",
  "is",
  "the",
  "a",
  "an",
  "per",
];

const removeUselessKeyWords = memoizeFunction((word) => {
  for (let i = 0; i < uselessKeywords.length; i++) {
    var regex = new RegExp("\\b" + uselessKeywords[i] + "\\b", "g");
    word = word.replace(regex, "");
  }

  return word.trim();
});

export const reformatRules = (rules) => {
  const reformattedRules = [];

  rules.forEach((rule) => {
    rule.subrules.forEach((subrule, subruleIndex) => {
      reformattedRules.push({
        name: rule.name,
        subrule: subruleIndex,
        description: `Employees ${subrule.inputs[0]} ${subrule.inputs[1]} ____ ${rule.name} ${subrule.inputs[2]} ${subrule.inputs[3]}`,
        keywords: `${keywordsDict[subrule.inputs[1]]} ${subrule.inputs[0]} ${
          subrule.inputs[1]
        } ${rule.name} ${subrule.inputs[2]} ${subrule.inputs[3]}`,
        keywordsList: [
          keywordsDict[subrule.inputs[1]],
          removeUselessKeyWords(subrule.inputs[0]),
          removeUselessKeyWords(subrule.inputs[1]),
          removeUselessKeyWords(rule.name),
          removeUselessKeyWords(subrule.inputs[2]),
        ],
        defaultValue: rule.defaultValue === undefined ? "" : rule.defaultValue,
        subruleName: subrule.ruleName,
        valueType: rule.valueType,
      });
    });
  });

  return reformattedRules;
};

export const getKeywordsList = (reformattedRules) => {
  return reformattedRules.map((rule) => {
    return rule.keywordsList;
  });
};

const checkValueValidity = (roster, ruleInd, customRule, rules) => {
  let ruleName = customRule.name;
  let template = customRule.template;

  if (ruleName === "Min days off after night shift") {
    template = "twoDaysOffAfterNight;Critical";
  }

  let rule = rules.filter(
    (rule1) =>
      rule1.subrules.filter((subrule) => subrule.ruleTemplate === template)
        .length > 0
  );
  if (rule.length === 0) {
    return `Could not find rule: ${ruleName}`;
  }

  let valueType = rule[0].valueType;

  let allShiftNames = [];
  if (valueType === "shiftList") {
    allShiftNames = roster.Shifts.map((s) => s.name);
  }

  for (let e = 0; e < roster.Employees.length; e++) {
    let employee = roster.Employees[e];
    let value = employee.RuleValues[ruleInd];
    if (value === "" || value === KEYWORD_NA) {
      continue;
    }
    switch (valueType) {
      case "integer": {
        if (!/^-?\d+$/.test(value)) {
          return `Employee "${employee.name}" rule "${ruleName}" value is not an integer`;
        }
        let intValue = parseInt(value);
        if (
          rule.valueRange !== undefined &&
          (intValue < rule.valueRange[0] || intValue > rule.valueRange[1])
        ) {
          return `Employee "${employee.name}" rule "${ruleName}" value outside range`;
        }
        break;
      }
      case "boolean": {
        if (
          !(value.toLowerCase() === "true" || value.toLowerCase() === "false")
        ) {
          return `Employee "${employee.name}" rule "${ruleName}" value is not a boolean`;
        }
        break;
      }
      case "shiftList": {
        let shiftNames = value.split(",").map((t) => t.trim());

        for (let i = 0; i < shiftNames.length; i++) {
          if (!allShiftNames.includes(shiftNames[i])) {
            return `Employee "${employee.name}" rule "${ruleName}" value: ${shiftNames[i]} is not a shift`;
          }
        }
        break;
      }
      case "integerList": {
        break;
      }
      default: {
        break;
      }
    }
  }

  return "";
};

export const checkRosterRuleValidity = (roster) => {
  let rules = getRules(
    roster.Shifts,
    roster.ShiftGroups,
    roster.Tasks,
    roster.id
  );

  for (let r = 0; r < roster.CustomRules.length; r++) {
    let customRule = roster.CustomRules[r];
    let ruleError = checkValueValidity(roster, r, customRule, rules);
    if (ruleError !== "") {
      return ruleError;
    }
  }

  return "";
};

const calculateFTE1 = (roster, customRule, ruleIndex) => {
  let fte = 10e14;
  let templateArray = customRule.template.split(";");
  let ruleType = templateArray.shift();
  let optionValues = templateArray;

  if (!(ruleType === "numHours" || ruleType === "numDaysOn")) return 0;

  let period = -1;
  if (optionValues.includes("Roster")) {
    period = roster.numDays;
  } else if (optionValues.includes("Fortnight")) {
    period = 14;
  } else if (optionValues.includes("Week")) {
    period = 7;
  }

  let ruleFTE = 0;

  if (period !== -1) {
    if (ruleType === "numHours") {
      for (let e = 0; e < roster.Employees.length; e++) {
        let employeeFTE =
          parseInt(roster.Employees[e].RuleValues[ruleIndex]) *
          (roster.numDays / period);
        ruleFTE += employeeFTE;
      }
    } else if (ruleType === "numDaysOn") {
      let shiftLengthRuleIndex = roster.CustomRules.findIndex(
        () => customRule.template.split(";").shift() === "shiftLength"
      );

      if (shiftLengthRuleIndex !== -1) {
        for (let e = 0; e < roster.Employees.length; e++) {
          let shiftTimes = roster.Employees[e].RuleValues[shiftLengthRuleIndex]
            .replace(" ", "")
            .split("-")
            .map((shiftTime) => parseInt(shiftTime));
          let maxShiftHours = Math.max(...shiftTimes);
          let employeeFTE =
            parseInt(roster.Employees[e].RuleValues[ruleIndex]) *
            (roster.numDays / period) *
            maxShiftHours;
          ruleFTE += employeeFTE;
        }
      } else {
        let shiftHours = roster.Shifts.map((shift) =>
          DateTime.getTimeDiff(shift.startTime, shift.finishTime)
        );

        let maxShiftHours = Math.max(...shiftHours);
        for (let e = 0; e < roster.Employees.length; e++) {
          let employeeFTE =
            parseInt(roster.Employees[e].RuleValues[ruleIndex]) *
            (roster.numDays / period) *
            maxShiftHours;
          ruleFTE += employeeFTE;
        }
      }
    }

    if (ruleFTE < fte && ruleFTE > 0) {
      fte = ruleFTE;
    }
  }

  return fte;
};

export const calculateFTE = (roster) => {
  let totalEmployeeHours = 10e14;

  for (let r = 0; r < roster.CustomRules.length; r++) {
    let hours = calculateFTE1(roster, roster.CustomRules[r], r);

    if (hours < totalEmployeeHours && hours > 0) {
      totalEmployeeHours = hours;
    }
  }
};

export const gatherRulesData = (roster) => {
  let rules;
  if (roster === null) {
    rules = getRules([], [], [], "");
  } else {
    rules = getRules(
      roster.Shifts,
      roster.ShiftGroups,
      roster.Tasks,
      roster.id
    ); //Note: bit of a hack since not updating the state for aggrid.
  }
  return rules;
};

const getRuleTemplateContents = (ruleTemplate) => ruleTemplate.split(";");

const createEmployeesUnpaidMinutesTemplateObj = (employees, shifts) =>
  employees.reduce((template, emp) => {
    template[emp.id] = {
      "employee-name": emp.name,
      ...shifts.reduce((acc, s) => {
        acc[s.shortId] = null;
        return acc;
      }, {}),
    };
    return template;
  }, {});

const applyShiftsUnpaidMinutes = (
  singleEmployeeUnpaidMinutesInfo,
  shiftsUnpaidMinutes
) => {
  shiftsUnpaidMinutes.forEach((shiftUnpaidMinutesInfo) => {
    const shiftName = shiftUnpaidMinutesInfo.shortId;
    const unpaidMinutes = shiftUnpaidMinutesInfo.unpaidMinutes;
    singleEmployeeUnpaidMinutesInfo[shiftName] = unpaidMinutes;
  });
};

const setUnpaidMinutesForEmployee = (
  employee,
  rules,
  shifts,
  shiftGroups,
  employeeUnpaidMinutesInfo,
  customKeywordsUtilObj
) => {
  const allShiftsUnpaidMinutes = []; // For general unpaid minutes
  const shiftsInShiftGroupsUnpaidMinutes = [];
  const shiftsUnpaidMinutes = [];

  for (const ruleIdx in rules) {
    const unpaidMinutes = Number(employee.RuleValues[ruleIdx]);
    const templateContents = getRuleTemplateContents(rules[ruleIdx].template);

    if (templateContents[0] !== "unpaidMinutes") {
      continue;
    }

    const targetShiftOrGroupShortId = templateContents[2];
    const isGeneralUnpaidMinutesRule = targetShiftOrGroupShortId === undefined;

    // If general unpaid rule
    if (isGeneralUnpaidMinutesRule) {
      shifts.forEach((s) =>
        allShiftsUnpaidMinutes.push({
          shortId: s.shortId,
          unpaidMinutes,
        })
      );
      continue;
    }

    // If shift group unpaid rule
    if (getShortIds(shiftGroups).includes(targetShiftOrGroupShortId)) {
      const shiftGroupShortId = targetShiftOrGroupShortId;
      const shiftGroup = shiftGroups.find(
        (s) => s.shortId === shiftGroupShortId
      );

      shifts.forEach((shift) => {
        if (
          allocationFulfilsShiftGroup(
            shiftGroup,
            "",
            shift.shortId,
            "",
            employee.skills,
            customKeywordsUtilObj
          )
        ) {
          shiftsInShiftGroupsUnpaidMinutes.push({
            shortId: shift.shortId,
            unpaidMinutes,
          });
        }
      });

      continue;
    }

    // If shift unpaid rule
    if (getShortIds(shifts).includes(targetShiftOrGroupShortId)) {
      const shiftShortId = targetShiftOrGroupShortId;
      shiftsUnpaidMinutes.push({ shortId: shiftShortId, unpaidMinutes });
    }
  }

  // Apply general unpaid minutes
  applyShiftsUnpaidMinutes(employeeUnpaidMinutesInfo, allShiftsUnpaidMinutes);

  // Apply shift groups unpaid minutes
  applyShiftsUnpaidMinutes(
    employeeUnpaidMinutesInfo,
    shiftsInShiftGroupsUnpaidMinutes
  );

  // Apply shifts unpaid minutes
  applyShiftsUnpaidMinutes(employeeUnpaidMinutesInfo, shiftsUnpaidMinutes);
};

const getUnpaidMinutesForEmployees = (
  employees,
  rules,
  shifts,
  shiftGroups,
  customKeywordsUtilObj
) => {
  // #### Prepare template
  const employeesUnpaidMinutesInfo = createEmployeesUnpaidMinutesTemplateObj(
    employees,
    shifts
  );

  for (const employee of employees) {
    const employeeUnpaidMinutesInfo = employeesUnpaidMinutesInfo[employee.id];
    setUnpaidMinutesForEmployee(
      employee,
      rules,
      shifts,
      shiftGroups,
      employeeUnpaidMinutesInfo,
      customKeywordsUtilObj
    );
  }

  return employeesUnpaidMinutesInfo;
};

export const getShiftHoursForEmployees = (
  employees,
  rules,
  shifts,
  shiftGroups,
  customKeywordsUtilObj
) => {
  const employeesUnpaidMinutes = getUnpaidMinutesForEmployees(
    employees,
    rules,
    shifts,
    shiftGroups,
    customKeywordsUtilObj
  );
  const employeesShiftHours = deepCopyObject(employeesUnpaidMinutes);

  Object.keys(employeesShiftHours).forEach((employeeID) => {
    const employeeShiftHours = employeesShiftHours[employeeID];
    Object.keys(employeeShiftHours).forEach((shortId) => {
      if (shortId !== "employee-name") {
        const targetShift = shifts.find((s) => s.shortId === shortId);
        const shiftUnpaidMinutes = employeeShiftHours[shortId];

        const startTime = targetShift.startTime;
        const finishTime = targetShift.finishTime;

        if (shiftUnpaidMinutes === null) {
          employeeShiftHours[shortId] = DateTime.getTimeDifferenceInHours(
            startTime,
            finishTime
          );
        } else {
          let timeDifferenceInMinutes =
            DateTime.getTimeDifferenceInMinutes(startTime, finishTime) -
            employeeShiftHours[shortId];
          const timeDifferenceInHours =
            DateTime.convertMinutesIntegerToHoursFloat(timeDifferenceInMinutes);
          employeeShiftHours[shortId] = timeDifferenceInHours;
        }
      }
    });
  });

  return employeesShiftHours;
};

export const checkIsValidRuleValue = (value, valueType) => {
  // if rule value is empty, allow it
  if (value === "") {
    return true;
  }

  // if valueType is string, any value is allowed
  if (valueType === "string") {
    return true;
  }

  if (value.toLowerCase() === KEYWORD_NA.toLowerCase()) {
    return true;
  }

  if (valueType === "integer") {
    if (
      isNaN(parseInt(value)) ||
      parseFloat(value) < 0 ||
      !Number.isInteger(parseFloat(value)) ||
      !isNumberString(value)
    ) {
      return false;
    }
    return true;
  }

  if (valueType === "half_integer") {
    if (
      isNaN(parseFloat(value)) ||
      parseFloat(value) < 0 ||
      parseFloat(value) % 0.5 !== 0 ||
      !isNumberString(value)
    ) {
      return false;
    }
    return true;
  }

  return false;
};
