import { DateTime } from "../dataTypesUtils/DateTime";
import { strToArrCommaSeparated } from "../generalUtils/array";
import { trimAndLowerCase } from "../generalUtils/string";
import {
  convertAllocationInNameFormToShortIdForm,
  convertAllocationInShortIdFormToNameForm,
  extractEntityNamesFromAllocation,
} from "../modelUtils/allocation";
import { splitAllocationSuffix } from "../modelUtils/generalModelUtil";
import { showWarningPopupIfNoOthersShowing } from "../uiUtils/popup";

export const insertEmptyStrOnPressBackspace = (params) => {
  if (params.newValue === null) {
    params.data[params.column.colId] = "";
    return true;
  }
};

/**
 *  - Trims input
 *  - Backspace -> empty string
 */
export const defaultValueSetter = (params) => {
  if (insertEmptyStrOnPressBackspace(params)) {
    return true;
  }

  if (
    typeof params.data[params.column.colId] === "string" ||
    params.data[params.column.colId] instanceof String
  ) {
    params.data[params.column.colId] = params.newValue.toString().trim();
    return true;
  }

  params.data[params.column.colId] = params.newValue;
  return true;
};

export const defaultBooleanValueSetter = (params) => {
  let newValue = params.newValue;
  if (typeof newValue === "string" && newValue.toLowerCase() == "true")
    newValue = true;
  else if (typeof newValue === "string" && newValue.toLowerCase() == "false")
    newValue = false;

  if (typeof newValue !== "boolean") {
    return false;
  }

  params.data[params.column.colId] = newValue;
  return true;
};

export const timeSetter = (params) => {
  let newTime = DateTime.getFormattedTime(params.newValue, "readable");
  if (newTime === "Invalid date") {
    showWarningPopupIfNoOthersShowing(
      "Invalid time format",
      <>
        We accept the following formats:
        <br /> 15:00 <br />
        3 pm
        <br /> 15
        <br /> 3pm <br /> 3.00pm
      </>
    );
    return false;
  }

  params.data[params.column.colId] = DateTime.getFormattedTime(newTime, "AWS");
  return true;
};

/**
 * Convert name -> short id only if shift is involved in the allocation
 */
export const rosterGridValueSetter = (params, namesToEntityShortIdsDicts) => {
  if (!params.newValue || !params.newValue.trim()) {
    return defaultValueSetter(params);
  }

  const inputAllocation = params.newValue.trim();

  const { shiftNameMapsToEntity } = extractEntityNamesFromAllocation(
    inputAllocation,
    namesToEntityShortIdsDicts
  );
  if (!shiftNameMapsToEntity) {
    params.data[params.column.colId] = inputAllocation;
    return true;
  }

  const shortIdForm = convertAllocationInNameFormToShortIdForm(
    inputAllocation,
    namesToEntityShortIdsDicts
  );

  params.data[params.column.colId] = shortIdForm;
  return true;
};

export function allocationDropdownOptionsValueSetter(
  params,
  options,
  isStrict = true
) {
  if (!params.newValue || !params.newValue.trim()) {
    return defaultValueSetter(params);
  }

  const [cellValue, suffix] = splitAllocationSuffix(params.newValue);

  const allowedShortIdValues = options.map(({ value }) => value);

  if (allowedShortIdValues.includes(cellValue)) {
    params.data[params.column.colId] = cellValue + suffix;
    return true;
  }

  const matchingOption = options.find(
    ({ label }) => trimAndLowerCase(label) === trimAndLowerCase(cellValue)
  );

  if (!matchingOption) {
    if (isStrict) {
      showWarningPopupIfNoOthersShowing(`Invalid input`, cellValue);
      return false;
    }
    params.data[params.column.colId] = cellValue;
    return true;
  }

  params.data[params.column.colId] = matchingOption.value + suffix;
  return true;
}

export function dropdownOptionsValueSetter(
  params,
  options,
  shortIdsToEntityNamesDicts
) {
  const cellValue = params.newValue;
  const invalidValues = [];

  if (!cellValue || !cellValue.trim()) {
    return defaultValueSetter(params);
  }

  const allowedShortIdValues = options.map(({ value }) => value);
  const strArray = strToArrCommaSeparated(cellValue);

  const fixedStrArray = strArray
    .map((singleValue) => {
      if (allowedShortIdValues.includes(singleValue)) {
        return singleValue;
      }
      const matchingOption = options.find(
        ({ label }) => trimAndLowerCase(label) === trimAndLowerCase(singleValue)
      );
      if (matchingOption) {
        return matchingOption.value;
      }

      invalidValues.push(singleValue);
      return null;
    })
    .filter((singleValue) => singleValue !== null);

  if (invalidValues.length > 0) {
    const formattedInvalidValues = invalidValues.map((value) =>
      shortIdsToEntityNamesDicts
        ? convertAllocationInShortIdFormToNameForm(
            value,
            shortIdsToEntityNamesDicts
          )
        : value
    );

    showWarningPopupIfNoOthersShowing(
      `Invalid input(s)`,
      formattedInvalidValues.join(", ")
    );
    return false;
  }

  params.data[params.column.colId] = fixedStrArray.join(", ");
  return true;
}
