import { range, strToArrCommaSeparated } from "../generalUtils/array";
import { convertShortIDsToEntityNames } from "../modelUtils/generalModelUtil";
import {
  getDayColumnNameRegex,
  getRecurringDayColumnNameRegex,
} from "../generalUtils/regex";

export const getRowId = (params) => params.data.id;

export const redrawBottomPinnedRows = (gridApi) => {
  const rows = [];
  gridApi.forEachNode((node) => {
    if (node.rowPinned === "bottom") {
      rows.push(node);
    }
  });

  gridApi.redrawRows({ rowNodes: rows });
};

export const getRowCountStatusBar = () => {
  return {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
    ],
  };
};

/**
 * @param {*} gridApi
 * @param {*} level "normal", "high" (?), "critical" (!)
 */
export const changePrefImportanceLevel = (gridApi, level) => {
  let suffix = "";
  switch (level) {
    case "normal":
      setCellRange(gridApi, unlockAllCells);
      return;
    case "high":
      suffix = "?";
      break;
    case "critical":
      suffix = "!";
      break;
    default:
      return;
  }
  setCellRange(gridApi, lockShiftsOnly, suffix);
};

export const exportGridToCsv = (gridApi) => {
  gridApi.exportDataAsCsv();
};

export const exportGridToExcel = (gridApi) => {
  gridApi.exportDataAsExcel({
    processCellCallback: (params) => {
      const colDef = params.column.getColDef();
      if (colDef.valueFormatter) {
        const valueFormatterParams = {
          ...params,
          data: params.node.data,
          node: params.node,
          colDef: params.column.getColDef(),
        };
        return colDef.valueFormatter(valueFormatterParams);
      }
      return params.value;
    },
  });
};

export const getSelectedRows = (gridApi) => {
  if (!gridApi) {
    return [];
  }
  const selectedRows = gridApi.getSelectedNodes();
  return selectedRows;
};

/**
 * Get longest string in rowData excluding the keys provided
 */
export const getLongestAllocationStringInRowData = (rowData, keysToExclude) => {
  let longestAllocationTextLength = 0;
  let longestStr = "";

  for (const data of rowData) {
    for (const [key, value] of Object.entries(data)) {
      if (!keysToExclude.includes(key)) {
        const strLength = value?.length;
        if (strLength > longestAllocationTextLength) {
          longestAllocationTextLength = strLength;
          longestStr = value;
        }
      }
    }
  }
  return longestStr;
};

/**
 * Can receive either params or gridApi as arguments. Leave unprovided one null.
 */
export const getToBeDeletedRows = (params = null, gridApi = null) => {
  let cellRanges = gridApi
    ? gridApi.getCellRanges()
    : params.api.getCellRanges();

  let toDelete = [];
  const allRows = gridApi
    ? getAllDisplayedRows(gridApi)
    : getAllDisplayedRows(params.api);
  cellRanges.forEach((cellRange) => {
    let startRow = Math.min(
      cellRange.startRow.rowIndex,
      cellRange.endRow.rowIndex
    );
    let endRow = Math.max(
      cellRange.startRow.rowIndex,
      cellRange.endRow.rowIndex
    );
    for (let i = startRow; i <= endRow; i++) {
      toDelete.push(allRows[i]);
    }
  });

  return toDelete;
};

function getAllRowIndices(startRow, endRow) {
  // cell range start and end index depends on how you select the ranges, so we ensure that the startRowIndex is always less than the endRowIndex regardless of how the cell range was selected
  let startRowIndex = Math.min(startRow.rowIndex, endRow.rowIndex);

  let endRowIndex = Math.max(startRow.rowIndex, endRow.rowIndex);
  return range(endRowIndex - startRowIndex + 1, startRowIndex);
}

export function setCellRange(api, setFunction, type) {
  let cellRanges = api.getCellRanges();
  cellRanges.forEach((cells) => {
    // we only need the ids of the columns to set the data
    let colIds = cells.columns
      .map((col) => col.colId)
      .filter((colId) => {
        const dayColNameRegex = getDayColumnNameRegex();
        const recurringDayColNameRegex = getRecurringDayColumnNameRegex();
        return (
          colId.match(dayColNameRegex) ||
          colId.match(recurringDayColNameRegex) ||
          colId === "shiftAndShiftGroup"
        );
      });

    const rowIndices = getAllRowIndices(cells.startRow, cells.endRow);
    setFunction(api, rowIndices, colIds, type);
  });
}

function lockAllCells(api, employeeIndices, dayIndices, type, shiftsOnly) {
  unlockAllCells(api, employeeIndices, dayIndices);

  const toUpdate = [];

  api.forEachNodeAfterFilter((rowNode, index) => {
    if (employeeIndices.includes(index)) {
      const employeeData = rowNode.data;
      dayIndices.forEach((d) => {
        const currentVal = employeeData[d];
        if (currentVal === undefined || currentVal === "") {
          if (shiftsOnly === undefined || !shiftsOnly) {
            employeeData[d] = type;
          }
        } else if (!currentVal.endsWith(type)) {
          employeeData[d] = currentVal + type;
        }
      });
      toUpdate.push(employeeData);
    }
  });

  if (toUpdate.length > 0) {
    api.applyTransaction({ update: toUpdate });
  }
}

export function lockShiftsOnly(api, employeeIndices, dayIndices, type) {
  lockAllCells(api, employeeIndices, dayIndices, type, true);
}

export function unlockAllCells(api, employeeIndices, dayIndices) {
  const toUpdate = [];

  api.forEachNodeAfterFilter((rowNode, index) => {
    if (employeeIndices.includes(index)) {
      const employeeData = rowNode.data;

      dayIndices.forEach((d) => {
        const currentVal = rowNode.data[d];
        if (currentVal === undefined) {
          employeeData[d] = "";
        } else if (
          currentVal.endsWith("!") ||
          currentVal.endsWith("?") ||
          currentVal.endsWith("*")
        ) {
          employeeData[d] = currentVal.slice(0, -1);
        }
      });
      toUpdate.push(employeeData);
    }
  });

  if (toUpdate.length > 0) {
    api.applyTransaction({ update: toUpdate });
  }
}

export const setDefaultSortModel = (params, defaultSortModel) => {
  params.columnApi.applyColumnState({ state: defaultSortModel });
};

export const suppressEnterKey = (params) => {
  if (params.event.key === "Enter") {
    return true;
  }
  return false;
};

export function suppressDeleteAndBackspaceKey(params) {
  var KEY_DELETE = "Delete";
  var KEY_BACKSPACE = "Backspace";
  var event = params.event;
  var key = event.key;

  if (key === KEY_DELETE || key === KEY_BACKSPACE) {
    return true;
  } else {
    return false;
  }
}

export function getAllDisplayedRows(api) {
  let rowData = [];
  api.forEachNode((node) => {
    if (node.displayed) {
      rowData.push(node.data);
    }
  });
  return rowData;
}

export const getAllRows = (gridApi) => {
  let rowData = [];
  gridApi.forEachNode((node) => rowData.push(node.data));
  return rowData;
};

export const scrollGridToBottom = (gridApi, timeout) => {
  setTimeout(() => {
    scrollGridToBottomSync(gridApi);
  }, timeout);
};

const scrollGridToBottomSync = (gridApi) => {
  const displayedRowCount = gridApi.getDisplayedRowCount();
  if (displayedRowCount > 1) {
    gridApi.ensureIndexVisible(displayedRowCount - 1, "bottom");
  }
};

/**
 * used for calculating whether range selector value should be "week" or "fortnight"
 * in Fixed shift recurring table or Preferences recurring
 * @param {*} recurringData
 * @returns
 */
export const getRecurringPeriodLengthSelection = (employeesData) => {
  if (employeesData.length === 0) {
    return "week";
  }
  const recurrings = Object.keys(employeesData[0]);
  const recurringLength = recurrings.filter((key) =>
    key.startsWith("r")
  ).length;

  let periodSelection = "week";
  if (recurringLength === 14) {
    periodSelection = "fortnight";
  } else if (recurringLength === 7) {
    periodSelection = "week";
  }
  return periodSelection;
};

export const toolIcons = {
  "custom-stats": '<span class="ag-icon ag-icon-custom-stats"></span>',
};

export const sideBar = {
  toolPanels: [
    {
      id: "customStats",
      labelDefault: "SHIFT & SKILL COUNTS STATS",
      labelKey: "customStats",
      iconKey: "custom-stats",
      toolPanel: "rosterToolbar",
    },
  ],
  defaultToolPanel: null,
};

export const getSelectedRowIDs = (selectedRows) =>
  selectedRows.map((item) => item.data.id);

export const getDataInRows = (rows) => rows.map((item) => item.data);

export const getParsedDataInRows = (rows, numericFields = []) => {
  const gridData = getDataInRows(rows);
  const result = gridData.map((item) => {
    const obj = {};
    for (const prop in item) {
      obj[prop] = numericFields.includes(prop)
        ? Number(item[prop])
        : item[prop];
    }
    return obj;
  });
  return result;
};

export function convertShortIdsCellValueToEntityNames(
  shortIdsCellValue,
  entities
) {
  const shortIds = strToArrCommaSeparated(shortIdsCellValue);
  const names = convertShortIDsToEntityNames(shortIds, entities);
  return names.join(", ");
}
