import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import styles from "./WarningAlert.module.css";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export const BaseWarningAlert = ({
  show,
  proceed,
  title = "Are you sure to proceed?",
  okLabel = "OK",
  cancelLabel = "Cancel",
  showCancelBtn = false,
  children,
}) => {
  return (
    <ModalFrame
      open={show}
      onClose={() => proceed(false)}
      shouldCloseOnOutsideClick={true}
      customStyle={{ borderRadius: "6px" }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon
              className={styles.icon}
              icon={faTriangleExclamation}
            />
          </div>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{title}</p>
          {children}
        </div>
        <div className={styles.footer}>
          <button
            className={`${styles.button} ${styles.okBtn}`}
            onClick={() => proceed(true)}
          >
            {okLabel}
          </button>
          {showCancelBtn && (
            <button
              className={`${styles.button} ${styles.cancelBtn}`}
              onClick={() => proceed(false)}
            >
              {cancelLabel}
            </button>
          )}
        </div>
      </div>
    </ModalFrame>
  );
};

export const WarningAlert = ({
  show,
  proceed,
  title = "Are you sure to proceed?",
  descriptions,
  okLabel = "OK",
  cancelLabel = "Cancel",
  showCancelBtn = false,
}) => {
  return (
    <BaseWarningAlert
      show={show}
      proceed={proceed}
      title={title}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      showCancelBtn={showCancelBtn}
    >
      <div className={styles.descriptions}>
        {descriptions.map((description, idx) => (
          <div key={idx} className={styles.description}>
            {description}
          </div>
        ))}
      </div>
    </BaseWarningAlert>
  );
};

export const WarningAlertWithComponentDescriptions = ({
  show,
  proceed,
  title = "Are you sure to proceed?",
  descriptions: Descriptions,
  okLabel = "OK",
  cancelLabel = "Cancel",
  showCancelBtn = false,
}) => {
  return (
    <BaseWarningAlert
      show={show}
      proceed={proceed}
      title={title}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      showCancelBtn={showCancelBtn}
    >
      <div className={styles.descriptions}>
        {Descriptions.map((Description, idx) => (
          <Description
            key={idx}
            ok={() => proceed(true)}
            cancel={() => proceed(false)}
          />
        ))}
      </div>
    </BaseWarningAlert>
  );
};
