import { v4 as uuidv4 } from "uuid";
import { DateTime } from "../dataTypesUtils/DateTime";
import {
  getMissingElements,
  hasCommonItems,
  strToArrCommaSeparated,
} from "../generalUtils/array";
import { sortByDateField } from "../generalUtils/sort";
import { deepCopyObject } from "../generalUtils/general";
import { getNameOfUser } from "../../features/auth/service/auth";
import { KEYWORD_ALL } from "../../constants/keywords";

export function getPublishedGlobalEmployees(
  globalEmployees,
  publishStartDate,
  publishFinishDate,
  areaShortIdsToPublish = null
) {
  const publishedDates = DateTime.getAllDateStringsBetween(
    publishStartDate,
    publishFinishDate,
    true,
    true
  );

  const updatedGlobalEmployees = globalEmployees.map((employee) => {
    if (
      new DateTime(publishStartDate).isAfter(employee.finishDate) ||
      new DateTime(publishFinishDate).isBefore(employee.startDate)
    ) {
      return employee;
    }

    const employeeAreas = strToArrCommaSeparated(employee.areas);

    if (
      areaShortIdsToPublish &&
      areaShortIdsToPublish.length > 0 &&
      !hasCommonItems(areaShortIdsToPublish, employeeAreas) &&
      !employeeAreas.includes(KEYWORD_ALL)
    ) {
      return employee;
    }

    const existingAllocations = employee.PublishedAllocations;
    const existingAllocationDates = existingAllocations.map(
      (allocation) => allocation.date
    );
    const missingAllocationDates = getMissingElements(
      existingAllocationDates,
      publishedDates
    );
    const missingPublishedAllocations = missingAllocationDates.map((date) => ({
      date,
      draftAllocation: null,
      publishedAllocation: "",
      isOpenShift: false,
      note: null,
    }));
    const existingUpdatedAllocations = existingAllocations.map((allocation) => {
      if (publishedDates.includes(allocation.date)) {
        return {
          date: allocation.date,
          draftAllocation: null,
          publishedAllocation: !(allocation.draftAllocation == null)
            ? allocation.draftAllocation
            : allocation.publishedAllocation
            ? allocation.publishedAllocation
            : "",
          isOpenShift: allocation.isOpenShift ? true : false,
          note: allocation.note,
        };
      }
      return allocation;
    });

    const updatedAllocations = [
      ...missingPublishedAllocations,
      ...existingUpdatedAllocations,
    ];
    sortByDateField(updatedAllocations, "date", true);

    return {
      ...employee,
      PublishedAllocations: updatedAllocations,
    };
  });
  return updatedGlobalEmployees;
}

export const REQUEST_ADMIN_COMMENT_FOR_FIXED_SHIFTS_ADDED_REQUESTS =
  "Made via fixed shift page";

export const getGlobalEmployeesWithinPeriod = (
  globalEmployees,
  periodStartDate,
  periodFinishDate
) => {
  return globalEmployees.filter((employee) => {
    const { startDate, finishDate } = employee;
    return DateTime.isOverlapingDateRangePair(
      periodStartDate,
      periodFinishDate,
      startDate,
      finishDate
    );
  });
};
/**
 * Requests modifiers
 */

/**
 * This function compares previous and updated employees and returns updated (added or deleted) requests dates for each employee
 */
export const getUpdatedLeaveDates = (
  prevEmployees,
  updatedEmployees,
  columnStartDate,
  annualLeaveKeyword,
  studyKeyword
) => {
  const updatedRequestsInfo = [];

  for (const prevEmployee of prevEmployees) {
    const addedALDates = [];
    const deletedALDates = [];
    const addedSLDates = [];
    const deletedSLDates = [];

    const updatedEmployee = updatedEmployees.find(
      (emp) => emp.id === prevEmployee.id
    );
    const prevAllocations = prevEmployee.Allocations;
    const updatedAllocations = updatedEmployee.Allocations;

    for (const index in prevAllocations) {
      const date = new DateTime(columnStartDate)
        .addDays(Number(index))
        .toFormat("AWS");
      const prevAllocation = prevAllocations[index];
      const updatedAllocation = updatedAllocations[index];

      if (
        updatedAllocation === annualLeaveKeyword &&
        prevAllocation !== annualLeaveKeyword
      ) {
        addedALDates.push(date);
      } else if (
        prevAllocation === annualLeaveKeyword &&
        updatedAllocation !== annualLeaveKeyword
      ) {
        deletedALDates.push(date);
      }

      if (
        updatedAllocation === studyKeyword &&
        prevAllocation !== studyKeyword
      ) {
        addedSLDates.push(date);
      } else if (
        prevAllocation === studyKeyword &&
        updatedAllocation !== studyKeyword
      ) {
        deletedSLDates.push(date);
      }
    }

    updatedRequestsInfo.push({
      employeeID: prevEmployee.id,
      name: prevEmployee.name,
      addedALDates,
      deletedALDates,
      addedSLDates,
      deletedSLDates,
    });
  }

  return updatedRequestsInfo;
};

/**
 * This is the GlobalFixedShifts table version of getUpdatedLeaveDates
 */
export const getUpdatedLeaveDatesFromGlobalFixedShifts = (
  prevEmployeesData,
  updatedEmployeesData,
  annualLeaveKeyword,
  studyKeyword
) => {
  const updatedRequestsInfo = [];

  for (const prevEmployee of prevEmployeesData) {
    const addedALDates = [];
    const deletedALDates = [];
    const addedSLDates = [];
    const deletedSLDates = [];

    const updatedEmployee = updatedEmployeesData.find(
      (emp) => emp.id === prevEmployee.id
    );
    const prevAllocations = prevEmployee.Allocations;
    const updatedAllocations = updatedEmployee.Allocations;

    for (const prevAllocation of prevAllocations) {
      const date = prevAllocation.date;
      const updatedAllocation = updatedAllocations.find(
        (allo) => allo.date === date
      );

      const prevAllocationValue = prevAllocation.allocation
        ? prevAllocation.allocation
        : "";
      const updatedAllocationValue = updatedAllocation.allocation
        ? updatedAllocation.allocation
        : "";

      if (
        updatedAllocationValue === annualLeaveKeyword &&
        prevAllocationValue !== annualLeaveKeyword
      ) {
        addedALDates.push(date);
      } else if (
        prevAllocationValue === annualLeaveKeyword &&
        updatedAllocationValue !== annualLeaveKeyword
      ) {
        deletedALDates.push(date);
      }

      if (
        updatedAllocationValue === studyKeyword &&
        prevAllocationValue !== studyKeyword
      ) {
        addedSLDates.push(date);
      } else if (
        prevAllocationValue === studyKeyword &&
        updatedAllocationValue !== studyKeyword
      ) {
        deletedSLDates.push(date);
      }
    }

    updatedRequestsInfo.push({
      employeeID: prevEmployee.id,
      name: prevEmployee.name,
      addedALDates,
      deletedALDates,
      addedSLDates,
      deletedSLDates,
    });
  }
  return updatedRequestsInfo;
};

export const splitRequestsIntoSingleDateRequests = (requests) => {
  const allSingleDateRequests = [];

  for (const request of requests) {
    const { startDate, finishDate } = request;
    const allRequestDates = DateTime.getAllDateStringsBetween(
      startDate,
      finishDate,
      true,
      true
    );

    const singleDateRequests = allRequestDates.map((date) => ({
      ...request,
      id: uuidv4(),
      startDate: date,
      finishDate: date,
    }));
    allSingleDateRequests.push(...singleDateRequests);
  }

  return allSingleDateRequests;
};

/**
 * Given an array of single date requests (which means startDate === finishDate),
 * make them into lesser # of requests by grouping the requests with consecutive dates
 * The request fields should be same except for the startDate, finishDate, submittedDate, and id
 */
export const groupSingleDateRequests = (singleDateRequests) => {
  if (!singleDateRequests.length) {
    return [];
  }

  const baseRequest = singleDateRequests[0];

  const areAllRequestsMergable = singleDateRequests.every((request) => {
    const {
      adminComment,
      employeeComment,
      request: requestName,
      submittedBy,
      state,
    } = request;
    return (
      adminComment === baseRequest.adminComment &&
      employeeComment === baseRequest.employeeComment &&
      requestName === baseRequest.request &&
      submittedBy === baseRequest.submittedBy &&
      state === "Approved"
    );
  });

  if (!areAllRequestsMergable) {
    throw new Error("All single date requests must be the same");
  }

  const allDates = singleDateRequests.map(({ startDate }) => startDate);
  const groupedConsecutiveALDates =
    DateTime.groupConsecutiveDateStrings(allDates);

  const updatedRequests = [];

  for (const dateGroup of groupedConsecutiveALDates) {
    const startDate = dateGroup[0];
    const finishDate = dateGroup[dateGroup.length - 1];
    const newRequest = {
      id: uuidv4(),
      submittedBy: baseRequest.submittedBy,
      submittedDate: new DateTime().toFormat("AWS"), // Can I set this today?
      startDate,
      finishDate,
      request: baseRequest.request,
      state: baseRequest.state,
      employeeComment: baseRequest.employeeComment,
      adminComment: baseRequest.adminComment,
    };
    updatedRequests.push(newRequest);
  }
  return updatedRequests;
};

export const deleteApprovedRequestsFromFixedShifts = (
  approvedRequestsBeforeDelete,
  datesToDelete,
  requestName,
  submittedBy
) => {
  const resultingRequests = [];
  const producedDeniedRequests = [];

  for (const request of approvedRequestsBeforeDelete) {
    const { startDate, finishDate, request: targetRequestName } = request;

    if (targetRequestName !== requestName) {
      resultingRequests.push(request);
      continue;
    }

    const requestDates = DateTime.getAllDateStringsBetween(
      startDate,
      finishDate,
      true,
      true
    );

    if (!hasCommonItems(datesToDelete, requestDates)) {
      resultingRequests.push(request);
      continue;
    }

    const remainingRequestDates = requestDates.filter(
      (date) => !datesToDelete.includes(date)
    );
    const singleDateRequests = remainingRequestDates.map((date) => ({
      id: uuidv4(),
      submittedDate: new DateTime().toFormat("AWS"),
      submittedBy,
      startDate: date,
      finishDate: date,
      request: requestName,
      state: "Approved",
      employeeComment: "",
      adminComment: REQUEST_ADMIN_COMMENT_FOR_FIXED_SHIFTS_ADDED_REQUESTS,
    }));

    const groupedRequests = groupSingleDateRequests(singleDateRequests);
    resultingRequests.push(...groupedRequests);

    const shouldProduceDeniedRequest =
      request.adminComment !==
      REQUEST_ADMIN_COMMENT_FOR_FIXED_SHIFTS_ADDED_REQUESTS;

    if (shouldProduceDeniedRequest) {
      const deniedRequest = {
        ...request,
        state: "Denied",
      };
      producedDeniedRequests.push(deniedRequest);
    }
  }

  return {
    resultingRequests,
    producedDeniedRequests,
  };
};

export const addApprovedRequestsFromFixedShifts = (
  approvedRequestsBeforeAdd,
  datesToAdd,
  requestName,
  submittedBy
) => {
  // Final resulting requests
  let resultingRequests = deepCopyObject(approvedRequestsBeforeAdd);

  const newRequestTemplate = {
    request: requestName,
    state: "Approved",
    adminComment: REQUEST_ADMIN_COMMENT_FOR_FIXED_SHIFTS_ADDED_REQUESTS,
    employeeComment: "",
    submittedDate: new DateTime().toFormat("AWS"),
    submittedBy,
  };

  // Create requests
  const newSingleDateRequests = datesToAdd.map((date) => ({
    ...newRequestTemplate,
    id: uuidv4(),
    startDate: date,
    finishDate: date,
  }));

  const newRequests = groupSingleDateRequests(newSingleDateRequests);

  for (const newRequest of newRequests) {
    const mergableRequestStartDate = new DateTime(newRequest.finishDate)
      .addDays(1)
      .toFormat("AWS");
    const mergableRequestFinishDate = new DateTime(newRequest.startDate)
      .subtractDays(1)
      .toFormat("AWS");
    // get all existing requests that is mergable

    const unmergedRequests = [];
    const mergableRequests = [];

    for (const requestBefore of resultingRequests) {
      const hasMergableFields =
        requestBefore.request === newRequest.request &&
        requestBefore.state === newRequest.state &&
        requestBefore.adminComment === newRequest.adminComment &&
        requestBefore.employeeComment === newRequest.employeeComment &&
        requestBefore.submittedBy === newRequest.submittedBy;

      const isMergableDate =
        requestBefore.startDate === mergableRequestStartDate ||
        requestBefore.finishDate === mergableRequestFinishDate;

      if (hasMergableFields && isMergableDate) {
        mergableRequests.push(requestBefore);
        continue;
      }

      unmergedRequests.push(requestBefore);
    }

    if (mergableRequests.length > 0) {
      mergableRequests.push(newRequest);
    } else {
      unmergedRequests.push(newRequest);
    }

    const mergedRequests = groupSingleDateRequests(
      splitRequestsIntoSingleDateRequests(mergableRequests)
    );

    resultingRequests = [...unmergedRequests, ...mergedRequests];
    sortByDateField(resultingRequests, "startDate", true);
  }

  return resultingRequests;
};

/**
 * According to the `updatedLeaveDates` param (returned by getUpdatedLeaveDates function), this function udpates each employee's requests.
 * This function returns globalEmployees.
 */
export const modifyRequests = (
  user,
  prevEmployees,
  updatedLeaveDates,
  tableStartDate,
  tableFinishDate
) => {
  const employeesWithUpdatedRequests = [];
  for (const updatedEmployeeLeaveDates of updatedLeaveDates) {
    const {
      addedALDates,
      deletedALDates,
      addedSLDates,
      deletedSLDates,
      employeeID,
    } = updatedEmployeeLeaveDates;

    const employee = deepCopyObject(
      prevEmployees.find((emp) => emp.id === employeeID)
    );

    const originalRequests = employee.Requests ? employee.Requests : [];

    let updatedRequests = []; // will be used in final requests

    const previousApprovedRequests = []; // will be used in final requests
    const futureApprovedRequests = []; // will be used in final requests
    const pendingOrDeniedRequests = []; // will be used in final requests
    const approvedRequestsWithinDateRangeBeforeUpdate = [];

    for (const request of originalRequests) {
      const { startDate, finishDate, state } = request;
      if (state !== "Approved") {
        pendingOrDeniedRequests.push(request);
        continue;
      }

      if (new DateTime(finishDate).isBefore(tableStartDate)) {
        previousApprovedRequests.push(request);
        continue;
      }

      if (new DateTime(startDate).isAfter(tableFinishDate)) {
        futureApprovedRequests.push(request);
        continue;
      }

      approvedRequestsWithinDateRangeBeforeUpdate.push(request);
    }

    const nameOfUser = getNameOfUser(user);

    const resultsAfterALDelete = deleteApprovedRequestsFromFixedShifts(
      approvedRequestsWithinDateRangeBeforeUpdate,
      deletedALDates,
      "Annual leave",
      nameOfUser
    );

    updatedRequests = resultsAfterALDelete.resultingRequests;
    pendingOrDeniedRequests.push(
      ...resultsAfterALDelete.producedDeniedRequests
    );

    const resultsAfterSLDelete = deleteApprovedRequestsFromFixedShifts(
      updatedRequests,
      deletedSLDates,
      "Study Leave",
      nameOfUser
    );

    updatedRequests = resultsAfterSLDelete.resultingRequests;
    pendingOrDeniedRequests.push(
      ...resultsAfterSLDelete.producedDeniedRequests
    );

    updatedRequests = addApprovedRequestsFromFixedShifts(
      updatedRequests,
      addedALDates,
      "Annual leave",
      nameOfUser
    );

    updatedRequests = addApprovedRequestsFromFixedShifts(
      updatedRequests,
      addedSLDates,
      "Study Leave",
      nameOfUser
    );

    const resultingEmployeeRequests = [
      ...pendingOrDeniedRequests,
      ...previousApprovedRequests,
      ...updatedRequests,
      ...futureApprovedRequests,
    ];

    sortByDateField(resultingEmployeeRequests, "startDate", true);
    employeesWithUpdatedRequests.push({
      ...employee,
      Requests: resultingEmployeeRequests.map((request) => ({
        id: request.id,
        submittedBy: request.submittedBy,
        submittedDate: request.submittedDate,
        startDate: request.startDate,
        finishDate: request.finishDate,
        request: request.request,
        state: request.state,
        employeeComment: request.employeeComment,
        adminComment: request.adminComment,
      })),
    });
  }
  return employeesWithUpdatedRequests;
};

export const getToBeDeletedRequestsToBeAlerted = (
  requestsBeforeUpdate,
  deletedDates,
  requestName
) => {
  const requestDeletesToAlert = new Map();

  for (const date of deletedDates) {
    const requestToAlert = requestsBeforeUpdate.find(
      ({ startDate, finishDate, request, adminComment }) => {
        const isInRange = new DateTime(date).isDateBetweenTwoDates(
          startDate,
          finishDate,
          true,
          true
        );
        return (
          isInRange &&
          request === requestName &&
          adminComment !== REQUEST_ADMIN_COMMENT_FOR_FIXED_SHIFTS_ADDED_REQUESTS
        );
      }
    );

    if (requestToAlert && !requestDeletesToAlert.has(requestToAlert.id)) {
      requestDeletesToAlert.set(requestToAlert.id, requestToAlert);
    }
  }

  return Array.from(requestDeletesToAlert.values());
};

export const isEmailAlreadyTaken = (globalEmployees, email) => {
  const emails = globalEmployees.map(({ email }) => email);
  return emails.includes(email);
};

/**
 * If admin, return all notes.
 * If NOT an admin, return notes on published dates OR approved leave dates
 */

export const getEmployeesAllocationNotes = (employees, isAdmin = true) => {
  const results = {};
  employees.forEach((employee) => {
    const requests = employee.Requests;
    const approvedLeaveDays = getLeaveDates(requests, "Approved");

    const employeeNotes = {};
    for (const allocation of employee.PublishedAllocations) {
      const { date, note, publishedAllocation } = allocation;
      if (!note) {
        continue;
      }

      if (isAdmin) {
        employeeNotes[date] = note;
        continue;
      }

      if (approvedLeaveDays.includes(date) || publishedAllocation !== null) {
        employeeNotes[date] = note;
      }
    }

    results[employee.id] = employeeNotes;
  });
  return results;
};

export function getPublishedOnlyAllocations(employees) {
  const employeesAllocationsInfo = employees.map((employee) => {
    const allocations = employee.PublishedAllocations.filter(
      (allo) =>
        allo.draftAllocation === null && allo.publishedAllocation !== null
    ).map((allo) => ({
      date: allo.date,
      publishedAllocation: allo.publishedAllocation,
    }));
    return {
      employeeID: employee.id,
      publishedOnlyAllocations: allocations,
    };
  });
  return employeesAllocationsInfo;
}

export function getLeaveDates(requests, state) {
  const dates = [];
  for (const request of requests) {
    if (state === request.state) {
      const startDate = request.startDate;
      const finishDate = request.finishDate;
      const coveredDates = DateTime.getAllDateStringsBetween(
        startDate,
        finishDate,
        true,
        true
      );
      dates.push(...coveredDates);
    }
  }
  return dates;
}

export function getEmployeeNoteOnDay(
  employeeID,
  employeesAllocationNotes,
  date
) {
  if (!employeeID || !employeesAllocationNotes || !date) {
    return "";
  }

  const employeeNotes = employeesAllocationNotes[employeeID];
  if (!employeeNotes) {
    return "";
  }

  return employeeNotes[date];
}
