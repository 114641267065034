export const KEYWORD_OFF = "off";
export const KEYWORD_ALL = "all";
export const KEYWORD_NA = "NA";
export const KEYWORD_NO_TASK = "no task";
export const KEYWORD_ON = "on";
export const KEYWORD_STUDY = "Study";
export const KEYWORD_BAU = "BAU";
export const KEYWORD_ANY = "any";

export const SHORT_NAME_STUDY = "SL";
