import { useCallback, useState } from "react";
import styles from "./TasksGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import { useRef } from "react";
import { useMemo } from "react";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import Checkbox from "../../../../../components/elements/AggridCheckbox/AggridCheckbox";
import GridCellWithPlaceholderText from "../../../../grid/components/GridCellWithPlaceholderText/GridCellWithPlaceholderText";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultFilterValueGetter,
  defaultValueSetter,
  dropdownOptionsValueSetter,
  onFilterTextBoxChanged,
  suppressDeleteAndBackspaceKey,
  suppressEnterKey,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useUserStore } from "../../../../../globalStore/appStore";
import TaskBlockExplainerModal from "../../TaskBlockExplainerModal/TaskBlockExplainerModal";
import { customConfirmPopup } from "../../../../confirm/service/confirm";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { isValidTaskName } from "../../../../../utils/validationUtils/sharedValidations";

const TasksGrid = ({
  tasksData,
  numTasks,
  isSaving,
  setGridApiToParent,
  addData: addNewTasks,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateTasks,
  tasksWarnings,
  removeData: removeTasks,
  gridApi,
  reorderTasksData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  skills,
  shiftNames,
  shiftGroupNames,
  taskBlockNames,
  reservedShiftKeywords,
  toggleTaskBlocksModal,
  customTopComponent = null,
  getDataFromGrid,
  isScheduleView,
  shortIdsToEntityNamesDicts,
  rosterName,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const { isAIPlan } = useUserStore();
  const [selectedRows, setSelectedRows] = useState([]);
  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeTasks(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const checkNameIsDuplicate = useCallback(
    (name) => {
      const names = [];
      if (gridApi) {
        gridApi.forEachNode((node) => {
          names.push(node.data.name);
        });
      }
      return names.includes(name);
    },
    [gridApi]
  );

  const skillOptions = useMemo(() => {
    return convertToShortIdNameOptionForm(skills);
  }, [skills]);

  const isWarningPresent = tasksWarnings && tasksWarnings.length > 0;

  const columnDefs = useMemo(
    () => [
      {
        suppressMovable: true,
        field: "name",
        suppressSizeToFit: true,
        width: 200,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Task name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            reservedShiftKeywords
              .map((word) => word.toLowerCase())
              .includes(params.newValue.toLowerCase())
          ) {
            showWarningPopupIfNoOthersShowing(
              `${reservedShiftKeywords.join(", ")} are reserved names`,
              "Please use another name"
            );
            return false;
          }

          if (checkNameIsDuplicate(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }

          if (!isValidTaskName(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Please use another name",
              `Task names cannot include '-' (hyphen) or ',' (comma)`,
              200
            );
            return false;
          }
          if (
            [...shiftNames, ...shiftGroupNames, ...taskBlockNames].includes(
              params.newValue
            )
          ) {
            showWarningPopupIfNoOthersShowing(
              "Task names must be different to any shift, shift group, or task block names",
              "Please use another name"
            );
            return false;
          }
          params.data.name = params.newValue.trim();
          return true;
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
      },
      {
        suppressMovable: true,
        field: "description",
        valueSetter: defaultValueSetter,
        width: 300,
      },
      {
        suppressMovable: true,
        field: "includeGeneralStaffing",
        cellRenderer: "checkboxRenderer",
        width: 190,
        suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
      },
      {
        suppressMovable: true,
        headerName: "A.I. assigned",
        field: "autoAssigned",
        cellRenderer: "checkboxRenderer",
        width: 190,
        suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
      },
      {
        suppressMovable: true,
        field: "skills",
        width: width - 690 - 2,
        minWidth: 300,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            skillOptions,
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
        suppressKeyboardEvent: suppressEnterKey,
        cellRenderer: "gridCellWithPlaceholderText",
        cellRendererParams: {
          defaultValue: "none required",
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: skillOptions,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (tasksWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                tasksWarnings
              );
            }
          },
        },
      },
    ],
    [
      width,
      checkNameIsDuplicate,
      tasksWarnings,
      skillOptions,
      shiftNames,
      shiftGroupNames,
      reservedShiftKeywords,
      skills,
      taskBlockNames,
      shortIdsToEntityNamesDicts,
    ]
  );

  const tableDescription = (
    <span className={styles.subtitle}>
      This table involves entering certain tasks that your employees can work
      during their shift. Each shift can have a single task or no task.
    </span>
  );

  return (
    <>
      <Layout
        title={isScheduleView ? "Tasks" : `Tasks - ${rosterName}`}
        headerNext={() => (
          <GetStartedButton
            url={"https://help.rosterlab.com/employees-skills-and-tasks"}
            noPaddingBottom={true}
          />
        )}
      >
        <div className={styles.container} ref={componentRef}>
          {isScheduleView && (
            <div className={styles.upperTopLine}>{tableDescription}</div>
          )}
          <div className={styles.topLine}>
            <div className={styles.left}>
              {isScheduleView ? customTopComponent : tableDescription}
            </div>
            <div className={styles.right}>
              <AddRowButton
                rowName="Task"
                addSingle={() => {
                  if (!isCellEditing) addNewTasks(1);
                }}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addNewTasks(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt("How many tasks should I add?", "1")
                      );
                      addNewTasks(num);
                    },
                  },
                ]}
              />
            </div>
          </div>

          <ActionBar
            searchBarSettings={{
              tableName: "tasks",
              onFilterInputChanged,
            }}
            duplicateSelectedSettings={{
              selectedRows,
              duplicateSelectedRows: duplicateTasks,
            }}
            deleteSelectedSettings={{
              selectedRows,
              removeSelectedRows: () => removeTasks(false),
            }}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
            customComponents={[
              () => (
                <button
                  style={{
                    color: "#1D9EC9",
                    textDecoration: "underline",
                    background: "none",
                    border: "none",
                    padding: 0,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    isAIPlan
                      ? toggleTaskBlocksModal()
                      : customConfirmPopup(TaskBlockExplainerModal);
                  }}
                >
                  Task Blocks{" "}
                  {isAIPlan ? (
                    <></>
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircleArrowUp}
                      style={{
                        color: "#00d4aa",
                        marginRight: "3px",
                        size: "10px",
                      }}
                    />
                  )}
                </button>
              ),
            ]}
          />
          <DataEntryTable
            columnDefs={columnDefs}
            rowData={tasksData}
            updateData={updateData}
            getContextMenuItems={getContextMenuItems}
            onCellKeyDown={(params) => {
              if (handleKeyDownForUndoRedo) {
                handleKeyDownForUndoRedo(params.event);
              }
            }}
            gridOptions={{
              rowSelection: "multiple",
              suppressRowClickSelection: true,
              onSelectionChanged: (params) => {
                setSelectedRows(params.api.getSelectedNodes());
              },
              onRowDragEnd: (params) => {
                reorderTasksData(params);
              },
              onCellValueChanged: (params) => {
                if (triggerUndoRedoSnapshotCollection) {
                  triggerUndoRedoSnapshotCollection(params);
                }
              },
              suppressMoveWhenRowDragging: true,
            }}
            setGridApiToParent={setGridApiToParent}
            defaultColDef={{
              filterParams: { newRowsAction: "keep" },
            }}
            components={{
              checkboxRenderer: Checkbox,
              dropdownMultiSelector: DropdownMultiSelector,
              gridCellWithPlaceholderText: GridCellWithPlaceholderText,
            }}
            enableRowDragAnimation={!isSaving}
            tableName="tasks"
            shouldHaveCheckBoxLeftMargin={true}
            getDataFromGrid={getDataFromGrid}
            customStyle={{
              height: `${window.innerHeight - 370}px`,
              minHeight: `500px`,
            }}
            onCellEditingStarted={() => setIsCellEditing(true)}
            onCellEditingStopped={() => setIsCellEditing(false)}
          />
          <p className={styles["num-line"]}>
            <span className={styles.emph}>Number of tasks:</span> {numTasks}
          </p>
          <p className={styles["saving-line"]}>
            {isSaving ? "saving..." : "saved"}
          </p>
        </div>
        {isWarningPresent && (
          <div className={styles["warning-wrapper"]}>
            <WarningDisplay
              title="Following issues were found:"
              displayedWarnings={getDisplayedWarningsInfo(tasksWarnings)}
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default TasksGrid;
