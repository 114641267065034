// This needs to be a subset of the data returned from the mutation that we are subscribing too. Because the open shifts lambda only returns PublishedAllocations, then that's all we can check for.
export const onUpdateGlobalEmployeeByLocationIDCustom = /* GraphQL */ `
  subscription OnUpdateGlobalEmployeeByLocationID($locationID: ID!) {
    onUpdateGlobalEmployeeByLocationID(locationID: $locationID) {
      id
      name
      skills
      shifts
      externalID
      email
      FTE
      salary
      startDate
      finishDate
      ruleValues
      areas
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      AllocationsRecurring
      _version
    }
  }
`;

export const onDeleteGlobalEmployeeByLocationIDCustom = /* GraphQL */ `
  subscription OnDeleteGlobalEmployeeByLocationID($locationID: ID!) {
    onDeleteGlobalEmployeeByLocationID(locationID: $locationID) {
      id
      name
      _deleted
    }
  }
`;

export const onUpdateLocationByIdCustom = /* GraphQL */ `
  subscription OnUpdateLocationById($id: ID!) {
    onUpdateLocationById(id: $id) {
      id
      frontendSettings {
        name
        values
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
    }
  }
`;

export const onUpdateLocationByIdCustomAdmin = /* GraphQL */ `
  subscription OnUpdateLocationById($id: ID!) {
    onUpdateLocationById(id: $id) {
      id
      owner
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      _version
      _deleted
    }
  }
`;

export const onUpdateRosterByIdCustom = /* GraphQL */ `
  subscription OnUpdateRosterById($id: ID!) {
    onUpdateRosterById(id: $id) {
      id
      owner
      locationID
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
