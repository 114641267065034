import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getNames } from "../../../../../utils";
import { filterStringItemsByMatchingLowercaseInput } from "../../../../../utils/generalUtils/filter";
import styles from "./ShiftViewEmployeeListDropdown.module.css";
import { convertAllocationInShortIdFormToNameForm } from "../../../../../utils/modelUtils/allocation";
import { KEYWORD_NA, KEYWORD_OFF } from "../../../../../constants/keywords";

const ShiftViewEmployeeListDropdown = ({
  containerRef,
  employees,
  allocatedEmployeeIDs,
  handleCloseDropdown,
  handleEmployeeSelection,
  dayIndex,
  customKeywordsDict,
  rowName,
  shortIdsToEntityNamesDicts,
}) => {
  const { x: leftOffset, y: topOffset } =
    containerRef.current.getBoundingClientRect();
  const { annualLeaveKeyword, studyKeyword } = customKeywordsDict;

  const rosteredAllocationsInNameForm = useMemo(() => {
    return employees.map(({ id, RosteredAllocations }) => {
      const rosteredAllocationsNameForm = RosteredAllocations.map(
        (allocation) =>
          convertAllocationInShortIdFormToNameForm(
            allocation,
            shortIdsToEntityNamesDicts
          )
      );
      return {
        id,
        RosteredAllocations: rosteredAllocationsNameForm,
      };
    });
  }, [employees, shortIdsToEntityNamesDicts]);

  const dropdownRef = useRef(null);
  const [selectedEmployees, setSelectedEmployees] =
    useState(allocatedEmployeeIDs);
  const [searchInput, setSearchInput] = useState("");
  const [listedEmployees, setListedEmployees] = useState(employees);

  const handleOutsideClick = useCallback(
    (e) => {
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(e.target)) {
          handleCloseDropdown();
        }
      }
    },
    [handleCloseDropdown]
  );

  const filterEmployeesBySearchInput = useCallback(() => {
    const employeeNames = getNames(employees);
    const filteredEmployeeNames = filterStringItemsByMatchingLowercaseInput(
      employeeNames,
      searchInput
    );

    const filteredEmployees = filteredEmployeeNames.map((name) =>
      employees.find((employee) => employee.name === name)
    );
    setListedEmployees(filteredEmployees);
  }, [searchInput, employees]);

  useEffect(() => {
    filterEmployeesBySearchInput();
  }, [searchInput, filterEmployeesBySearchInput]);

  useEffect(() => {
    if (dropdownRef.current) {
      document.addEventListener("mouseup", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    const employeeID = e.target.id;
    if (isChecked && !selectedEmployees.includes(employeeID)) {
      handleEmployeeSelection(employeeID, isChecked, rowName);
      setSelectedEmployees((prev) => [...prev, employeeID]);
    }

    if (!isChecked) {
      handleEmployeeSelection(employeeID, isChecked, rowName);
      setSelectedEmployees((prev) => prev.filter((id) => id !== employeeID));
    }
  };

  return (
    <div
      className={styles.employeeListDropdown}
      ref={dropdownRef}
      style={{
        position: "fixed",
        top: `${topOffset}px`,
        left: `${leftOffset}px`,
      }}
    >
      <p className={styles.employeeListDropdownTitle}>Select Employees</p>
      <input
        className={styles.searchInput}
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <ul className={`${styles.employeesList} ${styles.borderBottom}`}>
        {listedEmployees.map((employee) => {
          const employeeName = employee.name;
          const allocations = rosteredAllocationsInNameForm.find(
            ({ id }) => id === employee.id
          ).RosteredAllocations;

          const allocation = allocations[dayIndex];
          let displayedAllocation =
            allocation === KEYWORD_OFF ? "" : allocation;
          const isNotSelectable = [
            KEYWORD_OFF,
            KEYWORD_NA,
            annualLeaveKeyword,
            studyKeyword,
          ].includes(allocation);

          return (
            <li
              key={employee.id}
              className={`${styles.employeeOption} ${
                isNotSelectable && styles.unselectableEmployeeOption
              }`}
            >
              <input
                id={employee.id}
                type="checkbox"
                className={styles.checkbox}
                checked={selectedEmployees.includes(employee.id)}
                onChange={handleCheck}
                disabled={isNotSelectable}
              />
              <label htmlFor={employee.id} className={styles.employeeLabel}>
                {employeeName}
                {displayedAllocation ? `(${displayedAllocation})` : ""}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ShiftViewEmployeeListDropdown;
