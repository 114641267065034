import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./MultiSelectEmployees.module.css";
import { KEYWORD_ALL } from "../../../constants/keywords";

const MultiSelectEmployees = ({
  employeeNames,
  selected,
  setSelected,
  isAllSelected,
  customStyle = { width: "180px", height: "auto" },
  disabledInputs = [],
}) => {
  const handleChangeEmployees = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === KEYWORD_ALL) {
      setSelected(
        selected.length === employeeNames.length ? [] : employeeNames
      );
      return;
    }
    setSelected(value);
  };

  return (
    <Select
      multiple
      value={selected}
      onChange={handleChangeEmployees}
      renderValue={(selected) => {
        if (selected.length === 1) return selected[0];
        else if (selected.length === employeeNames.length)
          return "All employees";
        else return `${selected.length}/${employeeNames.length} employees`;
      }}
      style={{
        ...customStyle,
        backgroundColor: "white",
      }}
      MenuProps={{ autoFocus: false }}
      sx={{
        ...customStyle,
        borderColor: "#219ec9",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#219ec9",
          borderWidth: "1px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#219ec9",
        },
      }}
    >
      <MenuItem
        value={KEYWORD_ALL}
        classes={{
          root: isAllSelected ? styles.selectedAll : "",
        }}
        disabled={disabledInputs.length > 0}
      >
        <ListItemIcon>
          <Checkbox
            classes={{ indeterminate: styles.indeterminateColor }}
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < employeeNames.length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: styles.selectAllText }}
          primary="Select All"
        />
      </MenuItem>
      {employeeNames.map((employeeName) => (
        <MenuItem
          key={employeeName}
          value={employeeName}
          disabled={disabledInputs.includes(employeeName)}
        >
          <ListItemIcon>
            <Checkbox checked={selected.indexOf(employeeName) > -1} />
          </ListItemIcon>
          <ListItemText primary={employeeName} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectEmployees;
