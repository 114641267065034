import { useRouteQuery } from "./useRouteQuery";
import { DateTime } from "../utils";

export function useScheduleRouteQuery() {
  const routeQuery = useRouteQuery();

  const date = routeQuery.get("date") || new DateTime().toFormat("AWS");
  const snapshotId = routeQuery.get("snapshot-id") || null;

  return {
    date,
    snapshotId,
  };
}
