/**
 * Warning messages for tables
 */
import {
  capitalizeFirstLetter,
  getNames,
  removeDuplicateStrInArr,
} from "../../../../utils";
import { errorChecksList } from "../validators/rosterChecks";

export const getDisplayedWarningsInfo = (warnings) => {
  const displayedWarnings = [];

  for (const {
    warningType,
    values,
    entities,
    displayedWarningType,
    extraInfo,
  } of warnings) {
    const shouldShowColon = !["missing names", "invalid Study leave"].includes(
      warningType
    );

    let message = `${
      displayedWarningType
        ? capitalizeFirstLetter(displayedWarningType)
        : capitalizeFirstLetter(warningType)
    }${shouldShowColon ? ":" : ""} `;
    if (extraInfo && extraInfo.allocationNames) {
      message += removeDuplicateStrInArr(extraInfo.allocationNames).join(", ");
    } else if (values) {
      message += removeDuplicateStrInArr(values).join(", ");
    } else if (entities) {
      message += removeDuplicateStrInArr(getNames(entities));
    }

    displayedWarnings.push(message);
  }

  return displayedWarnings;
};

/**
 * Warning messages for roster generation
 */
export const getRosterWarningErrorMsg = (rosterWarnings, roster) => {
  const {
    Employees,
    Skills,
    Shifts,
    ShiftGroups,
    CustomRules,
    Demands,
    // History, // Temporarily allow History warnings when generating
    Allocations,
    AllocationsRecurring,
    Days,
    DaysRecurring,
  } = rosterWarnings;

  // Employees warnings
  for (const warning of Employees) {
    const { warningType, entities, values } = warning;

    switch (warningType) {
      case "missing name":
        return "Please make sure to fill out all skill names. \n[Page: Skills]";
      case "duplicate names":
        return `Duplicate employee names found in your employees table: ${getNames(
          entities
        ).join(
          ","
        )}. \nPlease input unique name to each employee. \n[Page: Employees]`;
      case "invalid skills":
        return `Invalid skills in Employees table: ${values.join(
          ","
        )}. \nPlease input valid skills. \n[Page: Employees]`;
      case "invalid shifts":
        return `Invalid shifts in Employees table: ${values.join(
          ","
        )}. \nPlease input valid shifts. \n[Page: Employees]`;
    }
  }

  // Skills warnings
  for (const warning of Skills) {
    const { warningType, entities } = warning;

    switch (warningType) {
      case "missing name":
        return "Please make sure to fill out all skill names. \n[Page: Skills]";
      case "duplicate names":
        return `Duplicate skill names found in your skills table: ${getNames(
          entities
        ).join(
          ","
        )}. \nPlease input unique name to each skill. \n[Page: Skills]`;
    }
  }

  // Shifts warnings
  for (const warning of Shifts) {
    const { warningType, entities, values } = warning;

    switch (warningType) {
      case "missing name":
        return "Please make sure to fill out all skill names. \n[Page: Skills]";
      case "duplicate names":
        return `Duplicate skill names found in your skills table: ${getNames(
          entities
        ).join(
          ","
        )}. \nPlease input unique name to each skill. \n[Page: Skills]`;
      case "invalid skills":
        return `You have tried to put in the following skill(s) that are not in your skills table: ${values.join(
          ","
        )}. \nPlease change your input to a valid skill. You can refer to the bottom right corner for a list of valid skill(s). If this is a valid skill, please enter it into the skills table as well. \n[Page: Shift/Shift Groups]`;
      case "invalid names":
        return `Following shift names are invalid: ${values.join(
          ","
        )}. \nPlease change to another names. \n[Page: Shifts/Shift Groups]`;
    }
  }

  // Shift Groups warnings
  for (const warning of ShiftGroups) {
    const { warningType, entities, values } = warning;

    switch (warningType) {
      case "missing name":
        return "Please make sure to fill out all shift group names. \n[Page: Shift/Shift Groups]";
      case "duplicate names":
        return `Duplicate shift group names found in your shift groups table: ${getNames(
          entities
        ).join(
          ","
        )}. \nPlease input unique name to each skill. \n[Page: Skills]`;
      case "invalid skills":
        return `You have tried to put in the following skill(s) that are not in your skills table: ${values.join(
          ","
        )}. \nPlease change your input to a valid skill. You can refer to the bottom right corner for a list of valid skill(s). If this is a valid skill, please enter it into the skills table as well. \n[Page: Shift/Shift Groups]`;
      case "invalid shifts":
        return `You have tried to put in the following shift(s) that are not in your shifts table: ${values.join(
          ","
        )}. \nPlease change your input to a valid shift. You can refer to the bottom right corner for a list of valid skill(s). If this is a valid shifts, please enter it into the shifts table as well. \n[Page: Shift/Shift Groups]`;
      case "invalid names":
        return `Following shift group names are invalid: ${values.join(
          ","
        )}. \nPlease change to another names. \n[Page: Shifts/Shift Groups]`;
    }
  }

  // Rules warnings
  for (const warning of CustomRules) {
    const { warningType } = warning;

    switch (warningType) {
      case "invalid input":
        return `Some rule values in rules table are invalid.
        \nPlease make sure all rule values havve correct type. \n[Page: Rules]`;
      case "missing input":
        return `Some rule values in rules table are empty.
        \nPlease fill all rule values in rules table. \n[Page: Rules]`;
      case "too many rules":
        return `You have too many rules for a starter-tier user.
        \nPlease remove a rule or contact us to upgrade. \n[Page: Rules]`;
      case "value out of range":
        return `Some rule values are out of range.
        \nPlease make sure all rule values are within the range. \n[Page: Rules]`;
    }
  }

  // Demands warnings
  for (const warning of Demands) {
    const { warningType, values } = warning;

    switch (warningType) {
      case "invalid skills":
        return `You have tried to put in the following skill(s) that are not in your skills table: ${values.join(
          ","
        )}. \nPlease change your input to a valid skill. You can refer to the bottom right corner for a list of valid skill(s). If this is a valid skill, please enter it into the skills table as well. \n[Page: Staffing demands]`;
      case "invalid shifts":
        return `You have tried to put the following shift(s) that are not in your shifts table: ${values.join(
          ","
        )}. \nPlease change your input to a valid shift. If this is a valid shift, please enter it into the shifts table as well. \n[Page: Staffing demands]`;
      case "invalid tasks":
        return `You have tried to put the following task(s) that are not in your tasks table: ${values.join(
          ","
        )}. \nPlease change your input to a valid task. If this is a valid task, please enter it into the tasks table as well. \n[Page: Staffing demands]`;
    }
  }

  // Fixed Shifts warnings
  for (const warning of [
    // ...History, // Temporarily allow History warnings when generating
    ...Allocations,
    ...AllocationsRecurring,
    ...Days,
    ...DaysRecurring,
  ]) {
    const { warningType, displayedWarningType } = warning;
    switch (warningType) {
      // case "invalid History input": // Temporarily allow History warnings when generating
      //   return `${capitalizeFirstLetter(
      //     displayedWarningType
      //   )}. [Page: History]`;
      case "invalid Allocations input":
        return `${capitalizeFirstLetter(
          displayedWarningType
        )}. [Page: Fixed Shifts]`;
      case "invalid AllocationsRecurring input":
        return `${capitalizeFirstLetter(
          displayedWarningType
        )}. [Page: Fixed Shifts Recurring]`;
      case "invalid Days input":
        return `${capitalizeFirstLetter(
          displayedWarningType
        )}. [Page: Preferences]`;
      case "invalid DaysRecurring input":
        return `${capitalizeFirstLetter(
          displayedWarningType
        )}. [Page: Preferences Recurring]`;
    }
  }

  if (roster.Employees.length === 0) {
    return "Please add employees to the roster. \n[Page: Employees]";
  }

  if (roster.Demands.length === 0) {
    return "Please add demands to the roster. \n[Page: Staffing demands]";
  }

  return "";
};

export const getRosterCheckErrorMsg = (
  generatableRoster,
  rawRoster,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts
) => {
  for (const [key, checker] of Object.entries(errorChecksList)) {
    let error = "";
    if (key === "checkRosterRuleValidity") {
      // This is because rulesName is converted to use entity name (rather than entity shortId) in generatableRoster
      error = checker(
        rawRoster,
        customKeywordsUtilObj,
        namesToEntityShortIdsDicts
      );
    } else {
      error = checker(
        generatableRoster,
        customKeywordsUtilObj,
        namesToEntityShortIdsDicts
      );
    }

    if (error !== "") {
      return "Error: " + error;
    }
  }

  return "";
};
