import { useMemo } from "react";
import {
  getDataInRows,
  parseSkillModelToSkillsGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import SkillsGrid from "../../../rosterProblems/components/Skills/SkillsGrid/SkillsGrid";
import { getAllSkillsFromGrid } from "../../../../utils";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import { getNewSkillsTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";

const ScheduleSkillsDataContainer = ({ locationID, isRoster, rosterID }) => {
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });
  const { areas, shifts, shiftGroups, tasks, subTasks, skills } = fields;
  const { plan } = useUserStore();

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const applySkillUpdates = (updatedSkills) => {
    const updatedRoster = { ...roster, Skills: updatedSkills };
    updateFields(["Skills"], updatedRoster, roster);
  };

  const skillsData = useMemo(
    () => parseSkillModelToSkillsGridFormat(skills),
    [skills]
  );

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "Skill",
      roster,
      shortIdsToEntityNamesDicts
    );

  const addNewSkills = (numItems) =>
    addNewModels(
      "New Skill",
      true,
      (option) => getNewSkillsTemplate({ ...option, existingSkills: skills }),
      numItems,
      skills,
      applySkillUpdates
    );

  const updateSkills = (newGridContent) =>
    updateModels(newGridContent, skills, applySkillUpdates);

  const duplicateSkills = (selectedSkillsInfo) =>
    duplicateModels(
      selectedSkillsInfo,
      [],
      skills,
      applySkillUpdates,
      "copy of ",
      "Skill",
      true
    );

  const removeSkills = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, skills, applySkillUpdates);

  const reorderSkillsData = (params) =>
    reorderModelsData(params, getAllSkillsFromGrid, skills, applySkillUpdates);

  if (plan === PLAN.COORDINATOR) {
    return <></>;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewSkills}
      updateItemsToDB={updateSkills}
      duplicateItemsToDB={duplicateSkills}
      removeItemsFromDB={removeSkills}
      getDataFromGrid={getAllSkillsFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
      getDeletionWarnings={getDeletionWarnings}
    >
      <SkillsGrid
        skillsData={skillsData}
        numSkills={skills.length}
        isSaving={isSaving}
        setGridApiToParent={setGridApi}
        gridApi={gridApi}
        reorderSkillsData={reorderSkillsData}
      />
    </GridActionHandler>
  );
};

export default ScheduleSkillsDataContainer;
