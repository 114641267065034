/**
 * Validations that is shared across Locations and Rosters
 */
import { DateTime, getShiftHours } from "..";
import { isReservedRuleValue } from "../../features/rules/service/rules";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../queryUtils/locationDataGetters";

import { getLocationAndEmployeesById } from "../queryUtils/locationQuery";
import { getRosterModelById } from "../queryUtils/rosterQuery";

export const isValidSkillName = (name) => {
  return !name.includes(",");
};

export const isValidTaskName = (name) => {
  return !(name.includes("-") || name.includes(","));
};

export const isValidShiftName = (name) => {
  if (
    name.includes("-") ||
    name.includes(";") ||
    name.includes("\\") ||
    name.includes(",")
  ) {
    return false;
  }
  return true;
};

// ########## Rules Validators ##########

/**
 * Return false if there is invalid input(s) for rule values.
 * Invlid inputs include: negative number, non-numerical inputs
 * @param {*} rulesData current inputs in rules table per employees
 * @param {*} rules - rule information (columns in rules table) that is currently added to the rules table
 * @param {*} allRulesData - rule information about ALL rules that exists
 * @returns boolean
 */
export const checkInvalidRuleValues = (rulesData, rules, allRulesData) => {
  const rulesWithInvalidInput = [];

  if (rulesData.length === 0)
    return {
      rulesWithInvalidInput: [],
      rulesWithEmptyInput: [],
    };

  for (const key of Object.keys(rulesData[0])) {
    if (
      key !== "id" &&
      key !== "name" &&
      key !== "startDate" &&
      key !== "finishDate" &&
      key !== "exceptionName" &&
      key !== "employeeName"
    ) {
      // find template of the rule value based on its key
      const templateName = rules.find((item) => item.name === key)
        ? rules.find((item) => item.name === key).template
        : null;

      let ruleInputType;
      // find rule type based on the template
      for (const singleRuleData of allRulesData) {
        for (const subrule of singleRuleData.subrules) {
          if (templateName === subrule.ruleTemplate) {
            ruleInputType = singleRuleData.valueType;
          }
        }
      }

      if (ruleInputType === undefined) {
        ruleInputType = "string";
      }

      for (const rule of rulesData) {
        if (isReservedRuleValue(rule[key])) {
          continue;
        }

        // check if input value matches the type
        switch (ruleInputType) {
          case "integer":
            if (
              isNaN(parseInt(rule[key])) ||
              parseFloat(rule[key]) < 0 ||
              !Number.isInteger(parseFloat(rule[key]))
            ) {
              if (rule[key] !== "") {
                rulesWithInvalidInput.push({
                  correctType: "integer",
                  ruleName: key,
                  ruleValue: rule[key],
                  employeeName: rule["name"],
                });
              }
            }
            break;
          case "half_integer":
            if (
              isNaN(parseFloat(rule[key])) ||
              parseFloat(rule[key]) < 0 ||
              parseFloat(rule[key]) % 0.5 !== 0
            ) {
              if (rule[key] !== "") {
                rulesWithInvalidInput.push({
                  correctType: "half_integer",
                  ruleName: key,
                  ruleValue: rule[key],
                });
              }
            }
            break;
          case "string":
            break;
          default:
            break;
        }
      }
    }
  }

  const rulesWithEmptyInput = rulesData.filter((rule) => {
    for (const key of Object.keys(rule)) {
      if (
        key !== "id" &&
        key !== "name" &&
        key !== "startDate" &&
        key !== "finishDate" &&
        key !== "exceptionName" &&
        key !== "employeeName"
      ) {
        if (rule[key] === "" || rule[key] === undefined) {
          return true;
        }
      }
    }
    return false;
  });

  return {
    rulesWithInvalidInput,
    rulesWithEmptyInput,
  };
};

export const getRuleValueRange = (ruleTemplate, numDays, shifts) => {
  const subTemplates = ruleTemplate.split(";");

  if (subTemplates.includes("Proportional")) {
    return { minValue: 0, maxValue: 100 };
  }

  let minValue = null;
  let maxValue = null;

  if (["numShifts", "numDaysOff", "numDaysOn"].includes(subTemplates[0])) {
    minValue = 0;
    if (subTemplates[1] === "Week") maxValue = 7;
    if (subTemplates[1] === "Fortnight") maxValue = 14;
    if (subTemplates[1] === "Roster") maxValue = numDays;
  }

  if (subTemplates[0] === "numHours") {
    minValue = 0;

    const shiftShortId = subTemplates[4];
    const shift = shifts.find((shift) => shift.shortID === shiftShortId);

    let shiftHours = null;

    if (shift) {
      shiftHours = getShiftHours(shift);
    } else {
      const longestShiftHours = shifts.reduce((acc, shift) => {
        const shiftHours = getShiftHours(shift);
        return shiftHours > acc ? shiftHours : acc;
      }, null);
      shiftHours = longestShiftHours;
    }

    if (shiftHours) {
      if (subTemplates[1] === "Week") maxValue = shiftHours * 7;
      if (subTemplates[1] === "Fortnight") maxValue = shiftHours * 14;
      if (subTemplates[1] === "Roster") maxValue = shiftHours * numDays;
    }
  }

  return { minValue, maxValue };
};

export const getInvalidNumberOfRules = (customRules, isAITier) => {
  if (isAITier) return [];

  const invalidIndices = [];

  // Iterate over customRules to check their length
  for (let i = 0; i < customRules.length; i++) {
    if (customRules[i].length > 8) {
      invalidIndices.push(i); // Add the index to the invalidIndices array
    }
  }

  return invalidIndices;
};

// ########## Calendar Validators##########

export const checkFixedShiftsMatchCalendar = async (locationID, rosterID) => {
  // Get all approved requests
  const locationAndEmployees = await getLocationAndEmployeesById(locationID);
  const { location, employees: globalEmployees } = locationAndEmployees;
  const { leaveKeywords } = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  );

  const approvedRequests = globalEmployees.map((gEmp) => {
    const approved = [];
    for (const request of gEmp.Requests) {
      if (request.state === "Approved") {
        approved.push({
          requestName: request.request,
          startDate: request.startDate,
          finishDate: request.finishDate,
        });
      }
    }
    return {
      employeeID: gEmp.id,
      employeeName: gEmp.name,
      approvedRequests: approved,
    };
  });

  const roster = await getRosterModelById(rosterID);
  const employees = roster.Employees;
  const rosterStartDate = roster.startDate;
  const rosterFixedAL = employees.map((emp) => {
    const allocations = emp.Allocations.map((allo, idx) => {
      const allocationDate = new DateTime(rosterStartDate)
        .addDays(idx)
        .toFormat("AWS");
      return {
        requestName: allo,
        date: allocationDate,
      };
    });
    const allocationsAL = allocations.filter((allo) =>
      leaveKeywords.includes(allo.requestName)
    );
    return {
      employeeID: emp.id,
      employeeName: emp.name,
      fixedShifts: allocationsAL,
    };
  });

  // match requests and fixed shifts by employee name or id
  const rosterEmployeeNames = employees.map((emp) => emp.name);
  let employeesRequestsAndFixedAL = globalEmployees.map((gEmp) => {
    let matchingApprovedRequests;
    let matchingFixedAL;

    for (const request of approvedRequests) {
      if (
        request.employeeID === gEmp.id ||
        request.employeeName === gEmp.name
      ) {
        matchingApprovedRequests = request.approvedRequests;
      }
    }

    for (const allo of rosterFixedAL) {
      if (allo.employeeID === gEmp.id || allo.employeeName === gEmp.name) {
        matchingFixedAL = allo.fixedShifts;
      }
    }

    return {
      employeeID: gEmp.id,
      employeeName: gEmp.name,
      matchingApprovedRequests,
      matchingFixedAL,
    };
  });

  // remove employees that does not exist in the roster
  employeesRequestsAndFixedAL = employeesRequestsAndFixedAL.filter((item) =>
    rosterEmployeeNames.includes(item.employeeName)
  );

  // Detect if there is any approve requests missing in the fixed request page of the roster
  for (const infoPerEmployee of employeesRequestsAndFixedAL) {
    const fixedALDates = infoPerEmployee.matchingFixedAL.map(
      (item) => item.date
    );

    for (const request of infoPerEmployee.matchingApprovedRequests) {
      const startDate = new DateTime(request.startDate);
      const finishDate = new DateTime(request.finishDate);

      const datesInBetween = DateTime.getAllDatesBetween(
        startDate,
        finishDate
      ).map((date) => date.toFormat("AWS"));

      for (const date of datesInBetween) {
        if (
          new DateTime(date).date >= new DateTime(roster.startDate).date &&
          new DateTime(date).date <=
            DateTime.addDaysToDate(
              new DateTime(roster.startDate),
              roster.numDays - 1
            ).date &&
          !fixedALDates.includes(date)
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

export const checkAllShiftGroupsHasAdminUseOnlyInfo = (shiftGroups) => {
  const shiftGroupsWithoutAdminOnlyInfo = shiftGroups.filter(
    (group) => group.adminUseOnly === null
  );
  if (shiftGroupsWithoutAdminOnlyInfo.length > 0) {
    return false;
  }
  return true;
};
