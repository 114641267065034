import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import styles from "./DragDropList.module.css";

const DragDropList = ({
  selected,
  setSelected,
  shifts,
  shiftGroups,
  leaveKeywords,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const listRef = useRef(null);

  const handleDragStart = (e, index) => {
    setDraggedItem(selected[index]);
    e.dataTransfer.setData("text/plain", selected[index]);

    // set the drag image and its offset
    e.dataTransfer.setDragImage(e.target, 0, 0);
  };

  const handleDragOver = (event, index) => {
    const draggedOverItem = selected[index];

    // If the item is dragged over itself, ignore
    if (draggedItem === draggedOverItem) {
      return;
    }

    // Get the index of the dragged item
    const draggedIndex = selected.indexOf(draggedItem);

    // Create a new array with the items in the correct order
    const newSelected = [...selected];
    newSelected.splice(draggedIndex, 1);
    newSelected.splice(index, 0, draggedItem);

    setSelected(newSelected);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const getLabel = (value) => {
    let label = "";
    if (leaveKeywords.includes(value)) {
      label = value;
    }

    if (!label) {
      const targetShift = shifts.find(({ shortId }) => shortId === value);
      if (targetShift) {
        label = targetShift.name;
      }
    }

    if (!label) {
      const targetShiftGroup = shiftGroups.find(
        ({ shortId }) => shortId === value
      );
      if (targetShiftGroup) {
        label = targetShiftGroup.name;
      }
    }
    return label;
  };

  return (
    <div className={styles.dragDropList} ref={listRef}>
      {selected.map((item, index) => {
        return (
          <div
            key={index}
            className={styles.dragRow}
            style={{ backgroundColor: draggedItem === item ? "#eee" : "#fff" }}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faGrip}
                style={{
                  marginRight: "8px",
                  color: "#9F9F9F",
                  fontSize: "10px",
                }}
              />
              <span style={{ flex: 1 }}>{getLabel(item)}</span>
            </div>
            <button
              className={styles.removeButton}
              onClick={() =>
                setSelected(selected.filter((_, i) => i !== index))
              }
            >
              Remove
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default DragDropList;
