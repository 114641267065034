import { useMemo } from "react";
import Layout from "../../../../components/layouts/Layout/Layout";
import { getFilteredSettingsByPlan } from "./globalSettingsList";
import styles from "./GlobalSettings.module.css";
import SettingItem from "../SettingItem/SettingItem";
import AdvSettingItem from "../SettingItem/AdvSettingItem";
import LeaveSetting from "../SettingItem/LeaveSetting";
import { getNames } from "../../../../utils";
import ScheduleUploadDownload from "../../../scheduleView/components/ScheduleUploadDownload/ScheduleUploadDownload";
import { Redirect } from "react-router-dom";
import {
  checkCheckLeaveSettingEnabled,
  getFieldsFromLocation,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { useUserStore } from "../../../../globalStore/appStore";
import PublicHolidaySetting from "../SettingItem/PublicHolidaySetting";

const GlobalSettings = ({ location, customKeywordsData }) => {
  const { plan } = useUserStore();

  const {
    interpretationSettings,
    demandSettings,
    emailSettings,
    leaveManagementSettings,
    preferencesApprovalSettings,
    appSettings,
    rulesManagementSettings,
  } = getFilteredSettingsByPlan(plan);

  const {
    frontendSettings,
    settings: backendSettings,
    isScheduleView,
    defaultNumDays,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const frontendSettingNames = useMemo(
    () => getNames(frontendSettings),
    [frontendSettings]
  );

  const leaveSettingValues = useMemo(
    () =>
      frontendSettings.find((setting) => setting.name === "annualLeaveKeyword")
        .values,
    [frontendSettings]
  );

  const publicHolidayTimezone = useMemo(() => {
    const setting = frontendSettings.find(
      (setting) => setting.name === "publicHolidayTimezone"
    );
    return setting ? setting.values[0] : "None";
  }, [frontendSettings]);

  const { updateLocationFields, isMutationLoading } =
    useLocationMutation(location);
  const isSaving = isMutationLoading;

  const updateFrontendSettings = (settingName, values) => {
    // Check if the setting exists
    const settingIndex = frontendSettings.findIndex(
      (setting) => setting.name === settingName
    );

    let updatedFrontendSettings;

    if (settingIndex !== -1) {
      // Update existing setting
      updatedFrontendSettings = frontendSettings.map((setting, index) => {
        if (index === settingIndex) {
          return {
            name: settingName,
            values,
          };
        }
        return setting;
      });
    } else {
      // Add new setting
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: settingName,
          values,
        },
      ];
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };
    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  /**
   * ----- Code here can be improved -----
   * "checkLeave" setting toggle works differently
   * values: ["true] or ["false"]
   */
  const updateCheckLeaveSetting = () => {
    let updatedFrontendSettings;

    const checkLeaveSetting = frontendSettings.find(
      (setting) => setting.name === "checkLeave"
    );

    if (!checkLeaveSetting) {
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: "checkLeave",
          values: ["true"],
        },
      ];
    } else {
      const isCheckLeaveSettingEnabled =
        checkCheckLeaveSettingEnabled(frontendSettings);

      updatedFrontendSettings = frontendSettings.map((setting) => {
        if (setting.name === "checkLeave") {
          if (isCheckLeaveSettingEnabled) {
            return {
              ...setting,
              values: ["false"],
            };
          }
          return {
            ...setting,
            values: ["true"],
          };
        }
        return setting;
      });
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };
    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  const toggleFrontendSettings = (settingName, values) => {
    let updatedFrontendSettings;
    if (frontendSettingNames.includes(settingName)) {
      updatedFrontendSettings = frontendSettings.filter(
        (item) => item.name !== settingName
      );
    } else {
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: settingName,
          values: values ? values : [],
        },
      ];
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };
    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  const toggleBackendSettings = (settingName) => {
    let updatedBackendSettings;
    if (backendSettings.includes(settingName)) {
      updatedBackendSettings = backendSettings.filter(
        (item) => item != settingName
      );
    } else {
      updatedBackendSettings = [...backendSettings, settingName];
    }

    const updatedLocation = {
      ...location,
      settings: updatedBackendSettings,
    };

    updateLocationFields(["settings"], updatedLocation);
  };

  const basicFrontendSettings = useMemo(
    () => [
      ...leaveManagementSettings,
      ...rulesManagementSettings,
      ...appSettings,
      ...emailSettings,
      ...demandSettings,
    ],
    [
      leaveManagementSettings,
      rulesManagementSettings,
      appSettings,
      emailSettings,
      demandSettings,
    ]
  );

  // User can't access settings page of demo location
  if (location.name === "Demo Location") {
    const demoLocationID = location.id;
    return (
      <Redirect to={`schedule-view/roster?location-id=${demoLocationID}`} />
    );
  }

  return (
    <div className={`${styles.container}`}>
      <Layout title={isScheduleView ? "" : "Settings"}>
        <div className={styles["inner-container"]}>
          <div className={styles.settingsWrapper}>
            {interpretationSettings.map((setting, idx) => {
              const isEnabled = backendSettings.includes(setting.name);
              return (
                <div key={setting.name}>
                  {idx === 0 && (
                    <p className={`${styles.typeHeader} ${styles.headerSpace}`}>
                      {setting.type}
                    </p>
                  )}
                  <SettingItem
                    setting={setting}
                    toggleSettings={toggleBackendSettings}
                    isEnabled={isEnabled}
                    settingsAlwaysDisplayedTicked={[]}
                  />
                </div>
              );
            })}
            {basicFrontendSettings.map((setting, idx) => {
              let isEnabled = frontendSettingNames.includes(setting.name);
              // ----- just for checkLeave setting ---
              if (setting.name === "checkLeave") {
                const checkLeaveSetting = frontendSettings.find(
                  (setting) => setting.name === "checkLeave"
                );
                if (
                  checkLeaveSetting &&
                  checkLeaveSetting.values[0] === "false"
                ) {
                  isEnabled = false;
                } else {
                  isEnabled = true;
                }
              }
              //----------

              const shouldShowSectionTitle =
                idx === 0 ||
                basicFrontendSettings[idx].type !==
                  basicFrontendSettings[idx - 1].type;
              return (
                <div key={setting.name}>
                  {shouldShowSectionTitle && (
                    <p className={`${styles.typeHeader} ${styles.headerSpace}`}>
                      {setting.type}
                    </p>
                  )}
                  <SettingItem
                    setting={setting}
                    toggleSettings={toggleFrontendSettings}
                    isEnabled={isEnabled}
                    updateCheckLeaveSetting={updateCheckLeaveSetting}
                  />
                </div>
              );
            })}
            <p className={`${styles.typeHeader} ${styles.headerSpace}`}>
              Global Preferences Approval System
            </p>
            {preferencesApprovalSettings.map((setting, idx) => {
              const isEnabled = frontendSettingNames.includes(setting.name);
              const currentSetting = frontendSettings.find(
                (item) => item.name === setting.name
              );
              return (
                <div key={idx}>
                  <AdvSettingItem
                    setting={setting}
                    isEnabled={isEnabled}
                    toggleSettings={toggleFrontendSettings}
                    updateFrontendSettings={updateFrontendSettings}
                    currentSetting={currentSetting ? currentSetting : null}
                    defaultNumDays={defaultNumDays}
                  />
                </div>
              );
            })}
          </div>
          <LeaveSetting
            customKeywordsData={customKeywordsData}
            leaveSettingValues={leaveSettingValues}
            updateFrontendSettings={updateFrontendSettings}
          />
          <PublicHolidaySetting
            updateFrontendSettings={updateFrontendSettings}
            publicHolidayTimezone={publicHolidayTimezone}
          />
          {isScheduleView && <ScheduleUploadDownload location={location} />}
        </div>
        <div>{isSaving ? "saving..." : "saved"}</div>
      </Layout>
    </div>
  );
};

export default GlobalSettings;
