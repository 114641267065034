import { useState } from "react";
import EmployeeAppActionBar from "../EmployeeAppActionBar/EmployeeAppActionBar";
import styles from "./EmployeeAppDesktop.module.css";
import DesktopSideTab from "../DesktopSideTab/DesktopSideTab";
import DesktopCalendar from "../DesktopCalendar/DesktopCalendar";
import DesktopHeader from "../DesktopHeader/DesktopHeader";

const EmployeeAppDesktop = ({
  locations,
  location,
  currentViewData,
  setContentViewType,
  setSelectedDate,
  allocations,
  myOpenShifts,
  userEmployee,
  isUserAdmin,
  updateOpenShifts,
  preferences,
  preferencesRecurring,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
  updatePreference,
  defaultPreferenceLevel,
  requests,
  leaveCodes,
  employeeLeaveCounts,
  availableShifts,
  availableShiftGroups,
  deleteRequestById,
  leaveKeywordsDict,
  addNewRequest,
  plan,
  notesToDisplay,
}) => {
  const [showOpenShifts, setShowOpenShifts] = useState(false);
  const toggleOpenShifts = () => setShowOpenShifts(!showOpenShifts);
  const closeShowAllOpenShifts = () => setShowOpenShifts(false);

  return (
    <div className={styles.container}>
      <DesktopHeader
        location={location}
        locations={locations}
        isUserAdmin={isUserAdmin}
      />
      <div className={styles.content}>
        <EmployeeAppActionBar
          location={location}
          currentViewData={currentViewData}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          showOpenShifts={showOpenShifts}
          toggleOpenShifts={toggleOpenShifts}
          userEmployee={userEmployee}
          plan={plan}
        />
        <div className={styles.infoContent}>
          <div className={styles.calendar}>
            <DesktopCalendar
              location={location}
              currentViewData={currentViewData}
              setSelectedDate={setSelectedDate}
              allocations={allocations}
              myOpenShifts={myOpenShifts}
              userEmployee={userEmployee}
              preferences={preferences}
              preferencesRecurring={preferencesRecurring}
              requests={requests}
              leaveCodes={leaveCodes}
              employeeLeaveCounts={employeeLeaveCounts}
              leaveKeywordsDict={leaveKeywordsDict}
              closeShowAllOpenShifts={closeShowAllOpenShifts}
              notesToDisplay={notesToDisplay}
            />
          </div>
          <div className={styles.sideTab}>
            <DesktopSideTab
              currentViewData={currentViewData}
              setSelectedDate={setSelectedDate}
              showOpenShifts={showOpenShifts}
              toggleOpenShifts={toggleOpenShifts}
              allocations={allocations}
              myOpenShifts={myOpenShifts}
              userEmployee={userEmployee}
              updateOpenShifts={updateOpenShifts}
              preferences={preferences}
              preferencesRecurring={preferencesRecurring}
              numberOfEmployees={numberOfEmployees}
              preferenceNumbers={preferenceNumbers}
              preferenceRecurringNumbers={preferenceRecurringNumbers}
              updatePreference={updatePreference}
              defaultPreferenceLevel={defaultPreferenceLevel}
              availableShifts={availableShifts}
              availableShiftGroups={availableShiftGroups}
              requests={requests}
              deleteRequestById={deleteRequestById}
              leaveKeywordsDict={leaveKeywordsDict}
              addNewRequest={addNewRequest}
              employeeLeaveCounts={employeeLeaveCounts}
              locationStartDate={location.startDate}
              plan={plan}
              notesToDisplay={notesToDisplay}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAppDesktop;
