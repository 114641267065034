/**
 * Scope: All locations
 */
import {
  getLocation,
  manageSubscriptionSeats,
  settingsByOwner,
} from "../../graphql/queries";
import {
  createLocation,
  createSettings,
  deleteLocation,
  updateSettings,
} from "../../graphql/mutations";
import { graphqlErrorHandler } from "./generalQueryUtil";
import { parseNestedJSON } from "../generalUtils/string";
import {
  getCurrentAuthUser,
  getUserAttributes,
  shouldUseCustomAuthLambda,
} from "../../features/auth/service/auth";
import {
  customLocationByOwner,
  settingsVersionByOwner,
} from "../graphqlUtils/customQueries";

async function getLocationModelsByOwnerType(formattedSub) {
  const locations = [];
  let token = null;
  async function fetchLocations(nextToken, owner) {
    const fetchedData = await graphqlErrorHandler(
      customLocationByOwner,
      {
        owner: owner,
        filter: {
          _deleted: {
            ne: true, // "ne" stands for "not equal"
          },
        },
        ...(nextToken && { nextToken }),
      },
      (data) => data.locationByOwner,
      null,
      "getLocationModelsByOwnerType"
    );

    if (!fetchedData) {
      return null;
    }

    token = fetchedData.nextToken ? fetchedData.nextToken : null;

    const fetchedLocations = fetchedData.items;
    locations.push(...fetchedLocations);
    return nextToken;
  }

  await fetchLocations(token, formattedSub);
  while (token) {
    await fetchLocations(token, formattedSub);
  }
  return locations.filter((location) => !location._deleted);
}

export async function getLocationModelsByOwner(sub, username) {
  let locations1 = getLocationModelsByOwnerType(`${sub}::${username}`);
  let locations2 = getLocationModelsByOwnerType(`${username}`);
  return [...(await locations1), ...(await locations2)];
}

export async function createLocationModel(locationInfo) {
  const user = await getCurrentAuthUser();
  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(user);

  return graphqlErrorHandler(
    createLocation,
    {
      input: {
        ...locationInfo,
        ...(shouldUseCustomAuthMode && {
          owner: user.session.accessToken.payload.sub,
        }),
      },
    },
    (data) => data.createLocation,
    null,
    "createLocationModel"
  );
}

export async function deleteLocationModel(locationID) {
  const originalLocation = await getLocationModel(locationID);
  const _version = originalLocation._version;

  return graphqlErrorHandler(
    deleteLocation,
    {
      input: {
        id: locationID,
        _version,
      },
    },
    (data) => data.deleteLocation,
    null,
    "deleteLocation"
  );
}

export async function getLocationModel(locationID) {
  return graphqlErrorHandler(
    getLocation,
    {
      id: locationID,
    },
    (data) => data.getLocation,
    null,
    "getLocationModel"
  );
}

export async function createDefaultUserSettings() {
  const user = await getCurrentAuthUser();
  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(user);

  return graphqlErrorHandler(
    createSettings,
    {
      input: {
        requestsNotifications: false,
        ...(shouldUseCustomAuthMode && {
          owner: user.session.accessToken.payload.sub,
        }),
      },
    },
    (data) => data.createSettings,
    null,
    "createDefaultUserSettings"
  );
}

export async function fetchUserSettingsVersion(user) {
  if (!user) {
    return null;
  }

  const userSettings = await graphqlErrorHandler(
    settingsVersionByOwner,
    {
      owner: `${getUserAttributes(user).sub}::${
        getUserAttributes(user).username
      }`,
      filter: {
        _deleted: {
          ne: true,
        },
      },
    },
    (data) => data.settingsByOwner,
    null,
    "fetchUserSettings"
  );

  const settingItems = userSettings.items;
  if (settingItems.length === 0) {
    return null;
  }

  return settingItems[0];
}

export async function fetchUserSettings(user) {
  if (!user) {
    return null;
  }

  const userSettings = await graphqlErrorHandler(
    settingsByOwner,
    {
      owner: `${getUserAttributes(user).sub}::${
        getUserAttributes(user).username
      }`,
      filter: {
        _deleted: {
          ne: true,
        },
      },
    },
    (data) => data.settingsByOwner,
    null,
    "fetchUserSettings"
  );

  const settingItems = userSettings.items;
  if (settingItems.length === 0) {
    return null;
  }

  return settingItems[0];
}

export async function updateSettingsModel(id, updatedFields, _version) {
  await graphqlErrorHandler(
    updateSettings,
    {
      input: {
        ...updatedFields,
        id: id,
        _version,
      },
    },
    (data) => data.updateSettings,
    null,
    "updateSettingsToDatabase"
  );
}

async function updateNumSeatsInStripe(action, numSeats) {
  const response = await graphqlErrorHandler(
    manageSubscriptionSeats,
    {
      action: action,
      numberOfSeats: numSeats,
    },
    (data) => data,
    null,
    "updateNumSeatsInStripe",
    true
  );
  return response;
}

export async function getCheckoutSession(employeesCount = null) {
  const { manageSubscriptionSeats } = await updateNumSeatsInStripe(
    "checkout",
    employeesCount
  );
  const { body, statusCode } = parseNestedJSON(manageSubscriptionSeats);
  if (statusCode === 500) {
    return null;
  }

  const { checkoutSession } = body;
  return { checkoutSession };
}

export async function getBillingPortalSession(
  employeesCountToUpdateInBillingPortal = null
) {
  const { manageSubscriptionSeats } = await updateNumSeatsInStripe(
    "portal",
    employeesCountToUpdateInBillingPortal
  );
  const { body, statusCode } = parseNestedJSON(manageSubscriptionSeats);

  if (statusCode === 500) {
    return null;
  }

  const { billingPortalSession } = body;
  return { billingPortalSession };
}

export async function createNewStripeSubscription(employeesNumber) {
  const { manageSubscriptionSeats } = await updateNumSeatsInStripe(
    "createNewSubscription",
    employeesNumber
  );
  const { body, statusCode } = parseNestedJSON(manageSubscriptionSeats);

  if (statusCode === 500) {
    return;
  }

  const { subscriptionDetails } = body;
  return subscriptionDetails;
}

export async function updateStripeSubscription(employeesNumber) {
  const { manageSubscriptionSeats } = await updateNumSeatsInStripe(
    "updateSubscription",
    employeesNumber
  );
  const { body, statusCode } = parseNestedJSON(manageSubscriptionSeats);

  if (statusCode === 500) {
    return;
  }

  const { subscriptionItem } = body;
  return subscriptionItem;
}
