import styles from "./MobileBottomTab.module.css";
import logo from "../../../../../assets/images/logo_coloured.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PreferencesBottomTabContent from "../PreferencesBottomTabContent/PreferencesBottomTabContent";
import WhosOnLeaveBottomTabContent from "../WhosOnLeaveBottomTabContent/WhosOnLeaveBottomTabContent";
import ScheduleBottomTabContent from "../ScheduleBottomTabContent/ScheduleBottomTabContent";
import { useMemo } from "react";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";

const MobileBottomTab = ({
  selectedDate,
  closeBottomTab,
  contentViewType,
  preferences,
  preferencesRecurring,
  setSelectedDate,
  availableShifts,
  availableShiftGroups,
  defaultPreferenceLevel,
  updatePreference,
  setContentViewType,
  employeeLeaveCounts,
  allocations,
  locationStartDate,
  openLeaveTab,
  leaveCountInfo,
  myOpenShifts,
  employeeID,
  updateOpenShifts,
  approvedLeaveOnDay,
  email,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
  notesToDisplay,
}) => {
  const note = useMemo(
    () =>
      notesToDisplay && selectedDate.toFormat("AWS") in notesToDisplay
        ? notesToDisplay[selectedDate.toFormat("AWS")]
        : "",
    [notesToDisplay, selectedDate]
  );

  const getContentComponent = () => {
    switch (contentViewType) {
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule:
        return (
          <ScheduleBottomTabContent
            date={selectedDate}
            allocations={allocations}
            myOpenShifts={myOpenShifts}
            employeeID={employeeID}
            updateOpenShifts={updateOpenShifts}
            approvedLeaveOnDay={approvedLeaveOnDay}
            email={email}
            note={note}
          />
        );
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences:
        return (
          <PreferencesBottomTabContent
            date={selectedDate}
            preferences={preferences}
            preferencesRecurring={preferencesRecurring}
            setSelectedDate={setSelectedDate}
            availableShifts={availableShifts}
            availableShiftGroups={availableShiftGroups}
            defaultPreferenceLevel={defaultPreferenceLevel}
            updatePreference={updatePreference}
            locationStartDate={locationStartDate}
            numberOfEmployees={numberOfEmployees}
            preferenceNumbers={preferenceNumbers}
            preferenceRecurringNumbers={preferenceRecurringNumbers}
          />
        );
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.whosOnLeave:
        return (
          <WhosOnLeaveBottomTabContent
            date={selectedDate}
            setContentViewType={setContentViewType}
            employeeLeaveCounts={employeeLeaveCounts}
            openLeaveTab={openLeaveTab}
            leaveCountInfo={leaveCountInfo}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.closeButton} onClick={closeBottomTab}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {selectedDate.toFormat("readable")}
      </div>
      <div className={styles.content}>{getContentComponent()}</div>
      <div className={styles.footer}>
        <img className={styles.logo} src={logo} alt="rosterlab logo" />
      </div>
    </div>
  );
};

export default MobileBottomTab;
