import { useMemo } from "react";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import AreasGrid from "../../../rosterProblems/components/Areas/AreasGrid/AreasGrid";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import {
  getAllAreasFromGrid,
  getDataInRows,
  getShortIds,
  parseAreaModelToAreasGridFormat,
} from "../../../../utils";
import { getNoTaskSubtaskLabels } from "../../../rosterProblems/service/preferencesAndFixedShifts";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import { getNewAreasTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";

export default function ScheduleAreasDataContainer({
  location,
  locationID,
  rosterID,
  periodStartDate,
  periodFinishDate,
  isRoster,
  periodNum,
  globalEmployees,
}) {
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const areasWarnings = useMemo(() => {
    return warnings ? warnings.Areas : null;
  }, [warnings]);

  const {
    name,
    areas,
    tasks,
    shifts,
    skills,
    shiftGroups,
    subTasks,
    taskBlocks,
    numDays,
    isSnapshot,
  } = fields;
  const isMainStream = !isRoster && !isSnapshot;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const taskShortIds = useMemo(() => getShortIds(tasks), [tasks]);
  const subTaskShortIds = useMemo(() => getShortIds(subTasks), [subTasks]);
  const noTaskShortIds = useMemo(
    () => getNoTaskSubtaskLabels(taskBlocks),
    [taskBlocks]
  );

  const subtaskOptionValues = [...noTaskShortIds, ...subTaskShortIds];
  const areasData = useMemo(
    () => parseAreaModelToAreasGridFormat(areas),
    [areas]
  );

  const applyAreaUpdates = (updatedAreas) => {
    const updatedRoster = { ...roster, Areas: updatedAreas };
    updateFields(["Areas"], updatedRoster, roster);
  };

  const addNewArea = (numItems) =>
    addNewModels(
      "New Area",
      true,
      (option) => getNewAreasTemplate({ ...option, existingAreas: areas }),
      numItems,
      areas,
      applyAreaUpdates,
      false
    );

  const updateAreas = (newGridContent) => {
    updateModels(newGridContent, areas, applyAreaUpdates);
  };

  const duplicateAreas = (selectedAreasInfo) =>
    duplicateModels(
      selectedAreasInfo,
      [],
      areas,
      applyAreaUpdates,
      "copy of ",
      "Area",
      true
    );

  const removeAreas = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, areas, applyAreaUpdates);

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "Area",
      roster,
      shortIdsToEntityNamesDicts
    );

  const reorderAreasData = (params) =>
    reorderModelsData(params, getAllAreasFromGrid, areas, applyAreaUpdates);

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  const TopControllerComponent = (
    <div>
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"areas"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewArea}
      updateItemsToDB={updateAreas}
      duplicateItemsToDB={duplicateAreas}
      removeItemsFromDB={removeAreas}
      getDataFromGrid={getAllAreasFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
      getDeletionWarnings={getDeletionWarnings}
    >
      <AreasGrid
        areasData={areasData}
        numAreas={areas.length}
        isSaving={isSaving}
        setGridApiToParent={setGridApi}
        gridApi={gridApi}
        areasWarnings={areasWarnings}
        customTopComponent={TopControllerComponent}
        shifts={shifts}
        shiftGroups={shiftGroups}
        skills={skills}
        reorderAreasData={reorderAreasData}
        tasks={tasks}
        subTasks={subTasks}
        taskBlocks={taskBlocks}
        subtaskOptionValues={subtaskOptionValues}
        taskShortIds={taskShortIds}
        isScheduleView={!isRoster}
        areas={areas}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        rosterName={name}
      />
    </GridActionHandler>
  );
}
