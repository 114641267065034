import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import { useAppVersionStore } from "../globalStore/appStore";
import { isNewerVersionAvailable } from "../utils/appVersionUtils/versionCheck";
import { Capacitor } from "@capacitor/core";

let intervalID = null;
function runSetInterval(callback, interval) {
  callback();
  intervalID = setInterval(callback, interval);
}

const ONE_DAY_IN_MILLI_SECONDS = 24 * 60 * 60 * 1000;

export function useReloadToFetchLatestBuild() {
  const routeLocation = useLocation();
  const previousPathName = useRef(routeLocation.pathname);
  const { newerVersionExist, setNewerVersionExist } = useAppVersionStore();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      return;
    }

    if (intervalID) {
      return;
    }

    runSetInterval(() => {
      isNewerVersionAvailable().then((newerVersionDetected) => {
        if (newerVersionDetected) {
          setNewerVersionExist(true);
        }
      });
    }, ONE_DAY_IN_MILLI_SECONDS);

    return () => {
      intervalID && clearTimeout(intervalID);
    };
  }, [setNewerVersionExist]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      return;
    }

    if (
      newerVersionExist &&
      previousPathName.current !== routeLocation.pathname
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 0); // defer reload to next event cycle
    }
    previousPathName.current = routeLocation.pathname;
  }, [routeLocation, newerVersionExist]);
}
