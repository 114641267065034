import { useEffect, useMemo, useState } from "react";
import { TABLE_NAMES } from "../../../../constants";
import { useFixedShiftsColumnWidthStore } from "../../../../globalStore/columnWidthStore";
import {
  DateTime,
  convertEmployeeOverviewDataToIndexForm,
  convertManageRequestValuesToAllocations,
  duplicateArr,
  getAllFixedShiftsFromGrid,
  getDayColumns,
  getLongestAllocationStringInRowData,
  getNames,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  parseEmployeeModelToFixedShiftsGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import FixedShiftsOverviewGrid from "../../../rosterProblems/components/FixedShifts/FixedShiftsOverviewGrid/FixedShiftsOverviewGrid";
import FixedShiftsRecurringGrid from "../../../rosterProblems/components/FixedShifts/FixedShiftsRecurringGrid/FixedShiftsRecurringGrid";
import RequestManagerContainer from "../../../rosterProblems/components/RequestManager/RequestManagerContainer/RequestManagerContainer";
import FixedShiftsLocked from "../../../upgradePlan/components/FixedShiftsLocked/FixedShiftsLocked";
import styles from "./ScheduleFixedShiftsWrapper.module.css";
import useModal from "../../../../hooks/useModal";
import { updateEmployeesWithOldEmployeeData } from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import { useUserStore } from "../../../../globalStore/appStore";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import { appSettings } from "../../../locations/components/GlobalSettings/globalSettingsList";
import {
  getToBeDeletedRequestsToBeAlerted,
  getUpdatedLeaveDates,
  modifyRequests,
} from "../../../../utils/queryUtils/globalEmployeeDataGetters";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { getActualInitialStartDate } from "../../../../utils/queryUtils/monthViewUtils";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import { useCache } from "../../../../hooks/modelQueryHooks/useCache";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";

const ScheduleFixedShiftsWrapper = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  customKeywordsData,
  globalEmployees,
}) => {
  const { user, isPaidPlan, plan } = useUserStore();

  const [overviewGridApi, setOverviewGridApi] = useState();
  const [recurringGridApi, setRecurringGridApi] = useState(null);
  const { isShowing: isRequestManagerOpen, toggle: toggleRequestManager } =
    useModal();
  const customKeywordsUtilObj = interpretCustomKeywordsData(customKeywordsData);
  const { predefinedShiftOptions, annualLeaveKeyword, studyKeyword } =
    customKeywordsUtilObj;

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([overviewGridApi, recurringGridApi], locationID);

  const {
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const {
    name,
    employees,
    skills,
    tasks,
    shifts,
    shiftGroups,
    areas,
    numDays,
    startDate: rosterStartDate,
    frontendSettings,
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
    isSnapshot,
  } = fields;

  const isMainStream = !isRoster && !isSnapshot;
  const [cachedEmployees, updateCachedEmployees] = useCache(employees);

  const employeeNames = useMemo(() => getNames(employees), [employees]);

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  useEffect(() => {
    updateCachedEmployees(employees);
  }, [employees, updateCachedEmployees]);

  const shouldInsertOffOnPasteBlank = frontendSettings.find(
    (item) => item.name === appSettings[0].name
  )
    ? true
    : false;

  let startDate = periodStartDate;
  let finishDate = periodFinishDate;
  if (isMainStream) {
    startDate = rosterStartDate;
    finishDate = new DateTime(startDate).addDays(numDays - 1).toFormat("AWS");
  }

  const applyEmployeeUpdates = (updatedEmployees, updatedFields) => {
    const updatedRoster = {
      ...roster,
      Employees: updateEmployeesWithOldEmployeeData(
        employees,
        updatedEmployees
      ),
    };
    updateFields(["Employees"], updatedRoster, roster, updatedFields);
  };

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useFixedShiftsColumnWidthStore();

  const employeesData = useMemo(() => {
    return parseEmployeeModelToFixedShiftsGridFormat(employees, numDays);
  }, [employees, numDays]);

  const overviewWarnings = useMemo(() => {
    return warnings ? warnings.Allocations : null;
  }, [warnings]);

  const recurringWarnings = useMemo(() => {
    return warnings ? warnings.AllocationsRecurring : null;
  }, [warnings]);

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const dayColumns = getDayColumns(numDays);
  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);
  let recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const getFixedShiftsInGrid = (gridApi) => {
    let employeesInGrid;
    try {
      employeesInGrid = getAllFixedShiftsFromGrid(
        gridApi,
        dayColumns,
        recurringColumns
      );
    } catch (error) {
      console.error(error);
      return null;
    }
    return employeesInGrid;
  };

  const applyRequests = async (requests) => {
    const employeesData = getFixedShiftsInGrid(overviewGridApi);
    const newEmployees = convertManageRequestValuesToAllocations(
      requests,
      employeesData,
      "Allocations",
      startDate,
      finishDate
    );

    updateFixedShiftsOverview(newEmployees);
    toggleRequestManager();
  };

  const updateFixedShiftsOverview = async (newEmployees) => {
    if (isMainStream) {
      const updatedLeaveDates = getUpdatedLeaveDates(
        cachedEmployees.current,
        newEmployees,
        startDate,
        annualLeaveKeyword,
        studyKeyword
      );

      const leaveDeletionWarnings = getLeaveDeletionWarnings(
        updatedLeaveDates,
        employees
      );

      if (leaveDeletionWarnings.length > 0) {
        const warningMessages = generateWarningMessages(leaveDeletionWarnings);
        const response = await showLeaveDeletionConfirmation(warningMessages);

        if (!response) {
          // Revert grid to previous state
          const previousEmployees = parseEmployeeModelToFixedShiftsGridFormat(
            employees,
            numDays
          );
          overviewGridApi.applyTransaction({
            update: previousEmployees,
          });
          return;
        }
      }

      const modifiedEmployeeRequests = modifyRequests(
        user,
        cachedEmployees.current,
        updatedLeaveDates,
        startDate,
        finishDate
      );

      for (const e in newEmployees) {
        const employeeData = newEmployees[e];
        const employeeUpdatedRequestsData = modifiedEmployeeRequests.find(
          (emp) => emp.id === employeeData.id
        );
        employeeData.Requests = employeeUpdatedRequestsData.Requests;
      }

      updateCachedEmployees(newEmployees);
      applyEmployeeUpdates(newEmployees, ["Allocations", "Requests"]);
    } else {
      applyEmployeeUpdates(newEmployees, ["Allocations", "Requests"]);
    }
  };

  const updateFixedShiftsRecurring = async (newEmployees) => {
    const updatedCachedEmployees = cachedEmployees.current.map((employee) => {
      const targetEmployee = newEmployees.find((emp) => emp.id === employee.id);
      if (targetEmployee) {
        return {
          ...newEmployees,
          AllocationsRecurring: targetEmployee.AllocationsRecurring,
        };
      }
      return employee;
    });
    updateCachedEmployees(updatedCachedEmployees);
    applyEmployeeUpdates(newEmployees, ["AllocationsRecurring"]);
  };

  const getDataFromGrid = () => {
    let updatedEmployeesData = getFixedShiftsInGrid(overviewGridApi);
    const newEmployees = [];
    for (let i = 0; i < updatedEmployeesData.length; i++) {
      newEmployees.push({
        ...updatedEmployeesData[i],
      });
    }
    return newEmployees;
  };

  const updateRecurringSelection = async (selection) => {
    const dataInGrid = getFixedShiftsInGrid(recurringGridApi);
    let recurringData;

    if (selection === "fortnight") {
      recurringData = dataInGrid.map((rowData) => ({
        ...rowData,
        AllocationsRecurring: duplicateArr(rowData.AllocationsRecurring, 2),
      }));
    } else {
      recurringData = dataInGrid.map((rowData) => ({
        ...rowData,
        AllocationsRecurring: rowData.AllocationsRecurring.slice(0, 7),
      }));
    }

    applyEmployeeUpdates(recurringData, ["AllocationsRecurring"]);
  };

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (!isPaidPlan) {
    return <FixedShiftsLocked />;
  }

  const TopControllerComponent = (
    <>
      <div className={styles.topComponents}>
        <AreaFilter
          areas={areas}
          onAreaFilterChanged={onAreaFilterChanged}
          onMenuClose={saveAreaFilter}
          defaultValue={initialAreaFilterValue}
        />
        {isMainStream && (
          <TableSchedulePeriodSwitcher
            location={location}
            periodStartDate={periodStartDate}
            periodFinishDate={periodFinishDate}
            globalEmployees={globalEmployees}
            numDays={numDays}
            isSaving={isSaving}
            periodNum={periodNum}
            pageUrlSlug={"fixedshifts"}
            customContainerStyle={{}}
            createRosterModelForMainStreamInDifferentPeriod={
              createRosterModelForMainStreamInDifferentPeriod
            }
          />
        )}
      </div>
    </>
  );

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.wrapper}>
      {isRequestManagerOpen && (
        <RequestManagerContainer
          toggleRequestManager={toggleRequestManager}
          employees={employees}
          startDate={startDate}
          finishDate={finishDate}
          applyRequests={applyRequests}
          overviewDataWithIndex={convertEmployeeOverviewDataToIndexForm(
            getFixedShiftsInGrid(overviewGridApi),
            "Allocations"
          )}
          type="fixedShifts"
          enumeratedTasks={enumeratedTasks}
          enumeratedShiftTasks={enumeratedShiftTasks}
          predefinedKeywords={predefinedShiftOptions}
          areas={areas}
          skills={skills}
          shifts={shifts}
          shiftGroups={shiftGroups}
          tasks={tasks}
          subTasks={subTasks}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      )}
      <div className={styles.header}>
        <h1 className={styles.title} data-testid="heading">
          Fixed Shifts/Leave
          {isRoster && `- ${name}`}
        </h1>
        <GetStartedButton
          url={
            "https://help.rosterlab.com/fixed-shifts-leave-requests-and-preferences"
          }
        />
      </div>
      <GridActionHandler
        gridApi={overviewGridApi}
        updateItemsToDB={updateFixedShiftsOverview}
        getDataFromGrid={getDataFromGrid}
        undoRedoParams={{
          tableName: TABLE_NAMES.ROSTER_FIXED_SHIFTS,
          getCustomGridSnapshot: null,
        }}
        disableUndoRedo={isMainStream}
      >
        <FixedShiftsOverviewGrid
          locationID={locationID}
          startDate={startDate}
          isSaving={isSaving}
          employeesData={employeesData}
          dayColumns={dayColumns}
          toggleRequestManager={toggleRequestManager}
          setGridApiToParent={setOverviewGridApi}
          setColumnApiToParent={() => {}}
          overviewWarnings={overviewWarnings}
          weekdayColWidth={weekdayColWidth}
          weekendColWidth={weekendColWidth}
          isWDayWEndSeparate={isWDayWEndSeparate}
          setWeekdayColWidth={setWeekdayColWidth}
          setWeekendColWidth={setWeekendColWidth}
          setIsWDayWEndSeparate={setIsWDayWEndSeparate}
          enumeratedTasks={enumeratedTasks}
          enumeratedShiftTasks={enumeratedShiftTasks}
          gridApi={overviewGridApi}
          longestStr={longestStr}
          predefinedKeywords={predefinedShiftOptions}
          customTopComponent={TopControllerComponent}
          shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
          isScheduleView={!isRoster}
          shouldSpecifyWeeksInColHeader={true}
          isMainStream={isMainStream}
          areas={areas}
          shifts={shifts}
          shiftGroups={shiftGroups}
          tasks={tasks}
          subTasks={subTasks}
          employeeNames={employeeNames}
          doesAreaFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      </GridActionHandler>
      <div className={styles.separator}></div>
      {startDate && (
        <GridActionHandler
          gridApi={recurringGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updateFixedShiftsRecurring}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getDataFromGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.ROSTER_FIXED_SHIFTS,
            getCustomGridSnapshot: null,
          }}
          disableUndoRedo={isMainStream}
        >
          <FixedShiftsRecurringGrid
            locationID={locationID}
            startDate={getActualInitialStartDate(
              location.isScheduleView ? location.startDate : startDate,
              location.isScheduleView ? location.defaultNumDays : numDays,
              location.isScheduleView
            )}
            isSaving={isSaving}
            employeesData={employeesData}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            recurringColumns={recurringColumns}
            setGridApiToParent={setRecurringGridApi}
            recurringWarnings={recurringWarnings}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            recurringPeriodSelection={recurringPeriodSelection}
            gridApi={recurringGridApi}
            updateRecurringSelection={updateRecurringSelection}
            predefinedKeywords={predefinedShiftOptions}
            shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            employeeNames={employeeNames}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
          />
        </GridActionHandler>
      )}
    </div>
  );
};

export function getLeaveDeletionWarnings(updatedLeaveDates, employees) {
  let leaveDeletionWarnings = [];

  for (const updatedLeaveDate of updatedLeaveDates) {
    const { deletedALDates, deletedSLDates, employeeID } = updatedLeaveDate;
    const employee = employees.find((emp) => emp.id === employeeID);
    const requests = employee.Requests;

    const alDatesToAlert = getToBeDeletedRequestsToBeAlerted(
      requests,
      deletedALDates,
      "Annual leave"
    );
    const slDatesToAlert = getToBeDeletedRequestsToBeAlerted(
      requests,
      deletedSLDates,
      "Study leave"
    );

    if (alDatesToAlert.length > 0 || slDatesToAlert.length > 0) {
      leaveDeletionWarnings.push({
        employeeName: employee.name,
        annualLeave: alDatesToAlert,
        studyLeave: slDatesToAlert,
        deletedALDates,
        deletedSLDates,
      });
    }
  }

  return leaveDeletionWarnings;
}

// Function to generate warning messages
export function generateWarningMessages(leaveDeletionWarnings) {
  return leaveDeletionWarnings.map((warning) => {
    const {
      employeeName,
      annualLeave,
      studyLeave,
      deletedALDates,
      deletedSLDates,
    } = warning;
    let message = `${employeeName}`;

    if (annualLeave.length > 0) {
      const intersectingDates = getIntersectingDateRange(
        annualLeave,
        deletedALDates
      );
      if (intersectingDates) {
        message += ` (Annual Leave: ${intersectingDates})`;
      }
    }

    if (studyLeave.length > 0) {
      const intersectingDates = getIntersectingDateRange(
        studyLeave,
        deletedSLDates
      );
      if (intersectingDates) {
        message += ` (Study Leave: ${intersectingDates})`;
      }
    }

    return message;
  });
}

// Function to show confirmation alert
export async function showLeaveDeletionConfirmation(warningMessages) {
  return await customConfirmAlert({
    title: "Confirm Leave Deletion",
    descriptions: [
      "Are you sure you wish to decline leave for the following?",
      ...warningMessages,
    ],
  });
}

function getIntersectingDateRange(leaves, deletedDates) {
  const [deletedStart, deletedEnd] = [
    new Date(deletedDates[0]),
    new Date(deletedDates[deletedDates.length - 1]),
  ];

  let minIntersect = null;
  let maxIntersect = null;

  for (const leave of leaves) {
    const leaveStart = new Date(leave.startDate);
    const leaveEnd = new Date(leave.finishDate);

    if (leaveStart <= deletedEnd && leaveEnd >= deletedStart) {
      const intersectStart = new Date(Math.max(leaveStart, deletedStart));
      const intersectEnd = new Date(Math.min(leaveEnd, deletedEnd));

      if (!minIntersect || intersectStart < minIntersect) {
        minIntersect = intersectStart;
      }
      if (!maxIntersect || intersectEnd > maxIntersect) {
        maxIntersect = intersectEnd;
      }
    }
  }

  if (minIntersect && maxIntersect) {
    return `${minIntersect.toISOString().split("T")[0]} to ${
      maxIntersect.toISOString().split("T")[0]
    }`;
  }

  return null;
}

export default ScheduleFixedShiftsWrapper;
