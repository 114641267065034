import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./TourChecklistToggleButton.module.css";
import { useOnBoardingStore } from "../../../../globalStore/appStore";

const TourChecklistToggleButton = () => {
  const { isCheckListOpen, setIsCheckListOpen } = useOnBoardingStore();
  return (
    <button
      className={`${styles.button} ${isCheckListOpen && styles.opened}`}
      onClick={() => setIsCheckListOpen(!isCheckListOpen)}
    >
      <FontAwesomeIcon className={styles.icon} icon={faQuestionCircle} />
    </button>
  );
};

export default TourChecklistToggleButton;
