// Lower the priority #, the higher priority
import {
  DateTime,
  calculateStringWidthInPx,
  deepCopyObject,
  sortByNumericField,
} from "../../../utils";

export function getPublishedShiftCellEntities(allocation) {
  const cellEntities = [];

  if (!allocation) {
    return;
  }

  const { entities, entityNames } = allocation;
  const { shift, enumeratedTask } = entities;
  const { areaName, shiftName, taskName, subtaskNames } = entityNames;

  if (areaName) {
    cellEntities.push({
      name: "areaName",
      priority: 1,
      maxLines: 2,
      lines: 1,
      contents: [`${areaName}:`],
    });
  }

  if (shiftName) {
    cellEntities.push({
      name: "shiftName",
      priority: 2,
      maxLines: 2,
      lines: 1,
      contents: [shiftName],
    });
  }

  if (taskName) {
    cellEntities.push({
      name: "task",
      priority: 3,
      maxLines: 1,
      lines: 1,
      contents: [taskName],
    });
  }

  if (enumeratedTask) {
    cellEntities.push({
      name: "subtasks",
      priority: 4,
      maxLines: 2,
      lines: 1,
      contents: [...subtaskNames],
    });
  }

  if (!enumeratedTask && shift) {
    cellEntities.push({
      name: "shiftDuration",
      priority: 5,
      maxLines: 1,
      lines: 0,
      contents: [
        DateTime.getFormattedTime(shift.startTime, "readable"),
        DateTime.getFormattedTime(shift.finishTime, "readable"),
      ],
    });
  }

  return cellEntities;
}

function getPriorityOrderedEntities(entities) {
  const priorityOrderedEntities = deepCopyObject(entities);
  sortByNumericField(priorityOrderedEntities, "priority");
  return priorityOrderedEntities;
}

function getSumLinesHeightOfEntities(cellEntities, lineHeight) {
  const linesSum = cellEntities
    .map((entity) => entity.lines * entity.contents.length)
    .reduce((partialSum, a) => partialSum + a, 0);
  const linesSumHeight = linesSum * lineHeight;
  return linesSumHeight;
}

function getOrderedEntities(entityNamesInOrder, cellEntities) {
  const orderedEntities = [];
  for (const entityName of entityNamesInOrder) {
    const entity = cellEntities.find((ent) => ent.name === entityName);
    if (entity) {
      orderedEntities.push(entity);
    }
  }
  return orderedEntities;
}

export function getAdjustedEntities(
  cellEntities,
  maxHeight,
  lineHeight,
  cellWidth
) {
  const order = cellEntities.map((entity) => entity.name);

  const originalOrderEntities = deepCopyObject(cellEntities);
  const priorityOrderedEntities = getPriorityOrderedEntities(cellEntities);
  const totalEntitiesLength = cellEntities.length;

  const totalHeight = getSumLinesHeightOfEntities(cellEntities, lineHeight);

  if (totalHeight === maxHeight) {
    return originalOrderEntities;
  }

  // Height overflows. Needs to remove entitie(s)
  if (totalHeight > maxHeight) {
    // looping around each entity, remove one by one from the least important entity
    const tempPriorityEntities = deepCopyObject(priorityOrderedEntities);
    for (let i = 0; i < totalEntitiesLength; i++) {
      tempPriorityEntities.pop();
      const updatedTotalHeight = getSumLinesHeightOfEntities(
        tempPriorityEntities,
        lineHeight
      );

      if (updatedTotalHeight <= maxHeight) {
        return getOrderedEntities(order, tempPriorityEntities);
      }
    }

    return getOrderedEntities(order, tempPriorityEntities);
  }

  // Can take more spaces. Entities can have more lines.
  const tempPriorityEntities = deepCopyObject(priorityOrderedEntities);
  let cachedTempPriorityEntities = deepCopyObject(tempPriorityEntities);

  for (let i = 0; i < tempPriorityEntities.length; i++) {
    const entity = tempPriorityEntities[i];
    const currentLines = entity.lines;
    const maxLines = entity.maxLines;
    let occupiableLines = 0;
    entity.contents.forEach((content) => {
      const numLines = Math.ceil(calculateStringWidthInPx(content) / cellWidth);
      occupiableLines = occupiableLines + numLines;
    });

    for (let j = currentLines; j <= maxLines; j++) {
      if (j <= occupiableLines) {
        entity.lines = j;
      }
      const updatedTotalHeight = getSumLinesHeightOfEntities(
        tempPriorityEntities,
        lineHeight
      );

      if (updatedTotalHeight <= maxHeight) {
        cachedTempPriorityEntities = deepCopyObject(tempPriorityEntities);
      } else {
        return getOrderedEntities(order, cachedTempPriorityEntities);
      }
    }
  }

  return getOrderedEntities(order, cachedTempPriorityEntities);
}
