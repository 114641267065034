import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EmployeeAppMobileProfile.module.css";
import {
  faArrowLeft,
  faCircleUser,
  faDeleteLeft,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../../../assets/images/RLlogo.png";
import arrow from "../../../../../assets/images/solutionDropdown.svg";
import { useState } from "react";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";
import {
  PLAN,
  getNameOfUser,
  getUserEmail,
  handleDeleteAccount,
  logOut,
  userIsAdmin,
} from "../../../../auth/service/auth";
import ConfirmationDialog from "../../../../../components/elements/ConfirmationDialog/ConfirmationDialog";
import SimpleMobileHeader from "../SimpleMobileHeader/SimpleMobileHeader";
import { isNativeMobileApp } from "../../../../../globalStore/deviceInfoStore";
import { getLiveScheduleUrl } from "../../../service/employeeAppUtil";

const DELETE_ACCOUNT_CODE = "delete my account";

const EmployeeAppMobileProfile = ({
  user,
  userEmployee,
  locations,
  location,
  plan,
}) => {
  const [showDeleteAccountConfirm, setShowDeleteAccountConfirm] =
    useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const redirectTo = useRedirect();

  const name = getNameOfUser(user);
  const email = getUserEmail(user);
  const isAdmin = userIsAdmin(user);
  const onBackButtonClick = () => {
    redirectTo({
      pageName: PAGE_NAMES.mobileEmployeeViewSchedule,
      query: { "location-id": location.id },
    });
  };

  const getSubscriptionUrl = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `https://api.rosterlab.com/icalendar?userId=${userEmployee.id}&timezone=${timezone}`;
    return url;
  };

  const handleCopyUrl = async () => {
    const url = getSubscriptionUrl();
    try {
      await navigator.clipboard.writeText(url);
      setHasCopied(true);
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  return (
    <div className={styles.container}>
      {showDeleteAccountConfirm && (
        <ConfirmationDialog
          confirmLabel={"Delete account"}
          cancelLabel={"Cancel"}
          onConfirm={handleDeleteAccount}
          confirmationCode={DELETE_ACCOUNT_CODE}
          closeModal={() => setShowDeleteAccountConfirm(false)}
          message={`To confirm, type "${DELETE_ACCOUNT_CODE}" in the box below`}
          title="Are you sure?"
        />
      )}
      <SimpleMobileHeader
        label="Profile"
        onLeftButtonClick={onBackButtonClick}
        leftButtonContent={
          <FontAwesomeIcon className={styles.backArrow} icon={faArrowLeft} />
        }
      />
      <div className={styles.content}>
        <div className={styles.profileTop}>
          <FontAwesomeIcon className={styles.profileIcon} icon={faCircleUser} />
          <div className={styles.profileTitle}>{name}</div>
          {email == "icle701@aucklanduni.ac.nz" ? <p>Version: 2.0.7</p> : <></>}
        </div>
        <div className={styles.section}>
          <div className={styles.infoBox}>
            <div className={styles.singleLineField}>
              <span className={styles.fieldName}>Email:</span>
              <span>{email}</span>
            </div>
            {isAdmin && (
              <div className={styles.singleLineField}>
                <span className={styles.fieldName}>Access:</span>
                <span>Admin</span>
              </div>
            )}
            {plan !== PLAN.LITE && (
              <div className={styles.subscribeField}>
                <span>Subscribe to my calendar:</span>
                <div className={styles.subscribeFieldInput}>
                  <input
                    className={styles.subcribeLink}
                    value={getSubscriptionUrl()}
                    readOnly
                  />
                  <button
                    className={styles.copyLinkButton}
                    onClick={handleCopyUrl}
                  >
                    {hasCopied ? "Copied" : "Copy Link"}
                  </button>
                </div>
              </div>
            )}
            {plan !== PLAN.LITE && (
              <div className={styles.subscribeField}>
                <span>Live Schedule:</span>
                <div className={styles.subscribeFieldInput}>
                  <input
                    className={styles.subcribeLink}
                    value={getLiveScheduleUrl(location.id)}
                    readOnly
                  />
                  <button
                    className={styles.copyLinkButton}
                    onClick={() => {
                      window.open(getLiveScheduleUrl(location.id), "_blank");
                    }}
                  >
                    Open
                  </button>
                </div>
              </div>
            )}
            {plan !== PLAN.LITE &&
              plan !== PLAN.EMPLOYEE &&
              !isNativeMobileApp() && (
                <div className={styles.subscribeField}>
                  <span>
                    Switch view to edit schedule:{" "}
                    <button
                      className={styles.copyLinkButton}
                      onClick={() => {
                        redirectTo({
                          pageName: PAGE_NAMES.location,
                          query: {
                            "location-id": location.id,
                          },
                        });
                      }}
                    >
                      Open
                    </button>
                  </span>
                </div>
              )}
            {isAdmin && (
              <div className={styles.messages}>
                <p className={styles.mainMessage}>
                  * Please use a <b>tablet or bigger screen</b> for{" "}
                  <u>making schedules</u>. Mobile version is only available for
                  viewing published schedules and putting through requests*
                </p>
                <hr />
                <p className={styles.subMessage}>
                  You will have access to these pages as an admin over a bigger
                  screen: Schedules, Timesheet, Requests.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.infoBox}>
            <div className={styles.locationSelect}>
              <span className={styles.locationSelectorLabel}>
                Your Location
              </span>
              {locations.length > 0 && (
                <select
                  className={styles.locationSelector}
                  style={{ backgroundImage: `url(${arrow})` }}
                  onChange={(e) => {
                    const locationID = e.target.value;
                    redirectTo({
                      pageName: PAGE_NAMES.mobileEmployeeViewProfile,
                      query: { "location-id": locationID },
                    });
                  }}
                  defaultValue={location.id}
                >
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.authSection}`}>
          <button
            className={styles.signOutButton}
            onClick={() =>
              logOut().then(() => {
                redirectTo({ pageName: PAGE_NAMES.main });
              })
            }
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className={styles.signOutIcon}
            />
            Sign Out
          </button>
          {!isAdmin && (
            <button
              onClick={() => setShowDeleteAccountConfirm(true)}
              className={styles.deleteAccountButton}
            >
              <FontAwesomeIcon
                className={styles.deleteAccountIcon}
                icon={faDeleteLeft}
              />
              Delete Account
            </button>
          )}
        </div>
      </div>
      <footer className={styles.footer}>
        <img src={logo} className={styles.logo} alt={"RosterLab logo"}></img>
      </footer>
    </div>
  );
};

export default EmployeeAppMobileProfile;
