import { forwardRef } from "react";
import styles from "./TooltipPopup.module.css";

const TooltipPopup = forwardRef(({ children, style }, ref) => (
  <div style={style} className={styles.tooltip} ref={ref}>
    {children}
  </div>
));

export default TooltipPopup;
