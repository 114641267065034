import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./KioskContainer.module.css";
import { Wizard, useWizard } from "react-use-wizard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronLeft,
  faCircleCheck,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  KIOSK_CLOCK_ACTIONS,
  KIOSK_RESPONSE_TYPE,
  useKioskQuery,
} from "../../../../hooks/useKioskQuery";
import logo from "../../../../assets/images/logo_white.png";
import blueClockImage from "../../../../assets/images/clock1.png";
import orangeClockImage from "../../../../assets/images/clock2.png";
import { DateTime, getShiftTask } from "../../../../utils";
import { useLiveTime } from "../../../../hooks/useLiveTime";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";
import SimpleMobileHeader from "../../../employeeApp/components/mobile/SimpleMobileHeader/SimpleMobileHeader";
import { convertAllocationInShortIdFormToNameForm } from "../../../../utils/modelUtils/allocation";

const STEPS = {
  welcome: 0,
  keypad: 1,
  result: 2,
};

function HeaderLogo({ backButton = null, onBackButtonClick = null }) {
  return (
    <SimpleMobileHeader
      leftButtonContent={backButton}
      onLeftButtonClick={onBackButtonClick}
    >
      <img className={styles.logo} src={logo} alt="Company Logo" />
    </SimpleMobileHeader>
  );
}

function WelcomeScreen() {
  const { goToStep } = useWizard();
  const handleClockAction = (action) => {
    localStorage.setItem("status", action);
    goToStep(STEPS.keypad);
  };

  return (
    <div className={styles.messageScreen}>
      <HeaderLogo />
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome to work</h1>
        <div className={styles.clockDouble}>
          <div className={styles.clockButtonContainer}>
            <button
              className={`${styles.clockButton} ${styles.clockInButton}`}
              onClick={() => handleClockAction(KIOSK_CLOCK_ACTIONS.clockIn)}
            >
              <FontAwesomeIcon
                className={`${styles.icon}`}
                icon={faArrowRightFromBracket}
              />
            </button>
            <span className={styles.clockButtonLabel}>Clock-in</span>
          </div>
          <div className={styles.clockButtonContainer}>
            <button
              className={`${styles.clockButton} ${styles.clockOutButton}`}
              onClick={() => handleClockAction(KIOSK_CLOCK_ACTIONS.clockOut)}
            >
              <FontAwesomeIcon
                className={`${styles.icon} ${styles.rotate}`}
                icon={faArrowRightFromBracket}
              />
            </button>
            <span className={styles.clockButtonLabel}>Clock-out</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Keypad({ user, setSelectedShiftData }) {
  const { goToStep, isLoading } = useWizard();
  const { submitCode } = useKioskQuery(user);
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { date: currentDate, time: currentTime } = useLiveTime();
  const errorDisplayDelayTimerId = useRef(null);

  const triggerErrorMessage = useCallback(
    (errorMessage) => {
      setErrorMessage(errorMessage);
      const timerId = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      errorDisplayDelayTimerId.current = timerId;
    },
    [setErrorMessage]
  );

  const handleNumber = (number) => {
    if (code.length < 4) {
      setCode(code + number);
    }
  };

  useEffect(() => {
    if (code.length > 0) {
      if (errorDisplayDelayTimerId.current) {
        clearTimeout(errorDisplayDelayTimerId.current);
        errorDisplayDelayTimerId.current = null;
      }
      setErrorMessage("");
    }
  }, [code]);

  const handleEnter = () => {
    const result = submitCode(code, localStorage.getItem("status"));
    const { response } = result;

    switch (response) {
      case KIOSK_RESPONSE_TYPE.success:
      case KIOSK_RESPONSE_TYPE.notYetClockedIn:
      case KIOSK_RESPONSE_TYPE.alreadyClockedIn:
      case KIOSK_RESPONSE_TYPE.alreadyClockedOut:
        setSelectedShiftData(result);
        goToStep(STEPS.result);
        break;
      case KIOSK_RESPONSE_TYPE.wrongCode:
        triggerErrorMessage(
          "The staff code you've entered doesn't exist, please try again."
        );
        break;
      case KIOSK_RESPONSE_TYPE.error:
      default:
        triggerErrorMessage("An error occurred, please try again.");
    }
    setCode("");
  };

  if (isLoading) {
    return (
      <div className={styles.centerContenr}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <div className={styles.messageScreen}>
      <HeaderLogo
        onBackButtonClick={() => goToStep(STEPS.welcome)}
        backButton={
          <FontAwesomeIcon icon={faChevronLeft} className={styles.backButton} />
        }
      />
      <div className={styles.content}>
        <div className={styles.display}>
          <div className={styles.labels}>
            <p className={styles.timeLabel}>
              {DateTime.getFormattedTime(currentTime, "12hr")}
            </p>
            <p className={styles.dateLabel}>
              {currentDate.toFormat("longDate")}
            </p>
          </div>
          <div
            className={`${styles.codeDisplay} ${
              errorMessage ? styles.errorCode : undefined
            }`}
          >
            <div
              className={`${styles.dot} ${
                code.length > 0 ? styles.activeDot : undefined
              }`}
            ></div>
            <div
              className={`${styles.dot} ${
                code.length > 1 ? styles.activeDot : undefined
              }`}
            ></div>
            <div
              className={`${styles.dot} ${
                code.length > 2 ? styles.activeDot : undefined
              }`}
            ></div>
            <div
              className={`${styles.dot} ${
                code.length > 3 ? styles.activeDot : undefined
              }`}
            ></div>
          </div>
        </div>
        <div className={styles.interactionContainer}>
          <div className={styles.keypad}>
            {"123456789".split("").map((number) => (
              <button
                className={styles.keypadButton}
                key={number}
                onClick={() => handleNumber(number)}
              >
                {number}
              </button>
            ))}
            <button className={styles.clearButton} onClick={() => setCode("")}>
              <FontAwesomeIcon
                icon={faDeleteLeft}
                className={styles.clearButtonIcon}
              />
            </button>
            <button
              className={styles.keypadButton}
              onClick={() => handleNumber("0")}
            >
              0
            </button>
            <button className={styles.enterButton} onClick={handleEnter}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className={styles.enterButtonIcon}
              />
            </button>
          </div>
          {errorMessage && (
            <div className={styles.bottom}>
              <p className={styles.errorMessage}>{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ResponseScreen({
  selectedShiftData,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills,
}) {
  const { response, time, allocation, timeEntry } = selectedShiftData;
  const { startTime, finishTime } = timeEntry;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const allocationName = convertAllocationInShortIdFormToNameForm(
    allocation,
    shortIdsToEntityNamesDicts
  );

  const [shift, task] = getShiftTask(allocationName);

  const { goToStep } = useWizard();
  const status = localStorage.getItem("status");

  const isNegativeResponse = [
    KIOSK_RESPONSE_TYPE.alreadyClockedIn,
    KIOSK_RESPONSE_TYPE.alreadyClockedOut,
    KIOSK_RESPONSE_TYPE.notYetClockedIn,
  ].includes(response);

  useEffect(() => {
    setTimeout(() => goToStep(STEPS.welcome), 5000);
  }, [goToStep]);

  const getContent = useCallback(() => {
    switch (response) {
      case KIOSK_RESPONSE_TYPE.alreadyClockedIn:
        return {
          title: "You've already clocked in at",
        };
      case KIOSK_RESPONSE_TYPE.alreadyClockedOut:
        return {
          title: "You've already clocked out at",
        };
      case KIOSK_RESPONSE_TYPE.notYetClockedIn:
        return {
          title: "You are not yet clocked in",
        };
      case KIOSK_RESPONSE_TYPE.error:
        return {
          title: "",
        };
    }

    switch (status) {
      case KIOSK_CLOCK_ACTIONS.clockIn:
        return {
          title: `Welcome ${selectedShiftData.employeeName}`,
          message: "You clocked in at",
        };
      case KIOSK_CLOCK_ACTIONS.clockOut:
        return {
          title: `Thanks ${selectedShiftData.employeeName}`,
          message: "You clocked out at",
        };
    }
  }, [selectedShiftData, response, status]);

  const getDescriptionComponent = useCallback(() => {
    const { title, message } = getContent();

    return (
      <div
        className={`${styles.info} ${
          isNegativeResponse ? styles.negativeInfo : undefined
        }`}
      >
        <h1 className={styles.welcomeTitle}>{title}</h1>
        {message && <p className={styles.successLabel}>{message}</p>}
        {time && (
          <p className={`${styles.successLabel} ${styles.boldText}`}>
            {DateTime.getFormattedTime(time, "12hr")}
          </p>
        )}
        <p className={styles.successLabel}>
          {new DateTime().toFormat("longDate")}
        </p>
      </div>
    );
  }, [getContent, time, isNegativeResponse]);

  const getAllocationBoxComponent = useCallback(() => {
    if (!shift && !startTime) {
      return <></>;
    }
    return (
      <div className={styles.details}>
        {shift && (
          <p>
            You are on
            <span
              className={`${styles.boldText} ${styles.spaceBetweenText}`}
            >{`${shift}`}</span>
            shift
            {task && <span className={styles.boldText}>, {task}. </span>}
          </p>
        )}
        {startTime && (
          <p>
            Starting from {DateTime.getFormattedTime(startTime, "12hr")}
            {finishTime &&
              `, ending at ${DateTime.getFormattedTime(finishTime, "12hr")}.`}
          </p>
        )}
      </div>
    );
  }, [finishTime, shift, startTime, task]);

  if (response === KIOSK_RESPONSE_TYPE.error) return <></>;

  return (
    <div className={styles.messageScreen}>
      <HeaderLogo />
      <div className={styles.content}>
        <div className={styles.clockImageContainer}>
          <img
            className={styles.clockImage}
            src={isNegativeResponse ? orangeClockImage : blueClockImage}
            alt="Blue Clock"
          />
        </div>
        {getDescriptionComponent()}
        {getAllocationBoxComponent()}
      </div>
    </div>
  );
}

function KioskContainer({ user }) {
  const { isLoading, shifts, shiftGroups, tasks, subTasks, areas, skills } =
    useKioskQuery(user);
  const [selectedShiftData, setSelectedShiftData] = useState();

  if (isLoading) {
    return (
      <div className={styles.centerContenr}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <Wizard>
      <WelcomeScreen />
      <Keypad user={user} setSelectedShiftData={setSelectedShiftData} />
      <ResponseScreen
        selectedShiftData={selectedShiftData}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        areas={areas}
        skills={skills}
      />
    </Wizard>
  );
}

export default KioskContainer;
