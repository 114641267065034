import { Route } from "react-router";
import { redirect } from "react-router-dom-v5-compat";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  if (!isLoggedIn) {
    redirect("/login");
    return null;
  }

  return <Route exact {...rest} render={() => Component} />;
};

export default PrivateRoute;
