import { useCallback, useRef } from "react";

export function useCache(initialData) {
  const cachedData = useRef(initialData);

  const updateCache = useCallback((data) => {
    cachedData.current = data;
  }, []);

  return [cachedData, updateCache];
}
