import { useState } from "react";
import CreateLocationModal from "../CreateLocationModal/CreateLocationModal";
import { isInteger } from "../../../../utils";

const CreateLocationModalContainer = ({
  handleCancelCreateLocation,
  closeCreateLocationModal,
  locationNames,
  plan,
  ruleTemplates,
  createNewScheduleViewLocation,
  createNewPrototypeLocation,
  createNewScheduleViewLocationByUploading,
}) => {
  const [employeesNumber, setEmployeesNumber] = useState(0);

  const updateEmployeesNumber = (number) => {
    const num = Number(number);
    if (num >= 0 && isInteger(num)) {
      setEmployeesNumber(num);
    }
  };

  return (
    <CreateLocationModal
      plan={plan}
      ruleTemplates={ruleTemplates}
      handleCancelCreateLocation={handleCancelCreateLocation}
      createNewPrototypeLocation={createNewPrototypeLocation}
      locationNames={locationNames}
      createNewScheduleViewLocation={createNewScheduleViewLocation}
      createNewScheduleViewLocationByUploading={
        createNewScheduleViewLocationByUploading
      }
      employeesNumber={employeesNumber}
      updateEmployeesNumber={updateEmployeesNumber}
      closeCreateLocationModal={closeCreateLocationModal}
    />
  );
};

export default CreateLocationModalContainer;
