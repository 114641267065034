export function getBrowserCachedAppVersion() {
  let version = null;
  try {
    const versionString = localStorage?.getItem("version") ?? null;
    if (versionString) {
      version = JSON.parse(versionString);
    }
  } catch (error) {
    console.error("Failed to parse app version:", error);
    // Handle the error as appropriate for your application
  }
  return version;
}

export function setBrowserCachedAppVersion(version) {
  localStorage.setItem("version", JSON.stringify({ version }));
}
