import { DateTime } from "../../../utils";
import { VIEW_RANGE } from "../constants/calendarView";

export const onViewRangeChangeBuilder = (
  value,
  startDate,
  VIEW_RANGE,
  urlUpdater
) => {
  const startDayObj = new DateTime(startDate);
  let resultingFinishDate;
  switch (value) {
    case VIEW_RANGE.month: {
      const monthAway = startDayObj.addMonths(1).subtractDays(1);
      resultingFinishDate = monthAway.toFormat("AWS");
      break;
    }
    case VIEW_RANGE.fortnight: {
      const fortnightAway = startDayObj.addDays(2 * 7);
      resultingFinishDate = fortnightAway.toFormat("AWS");
      break;
    }
    case VIEW_RANGE.week: {
      const weekAway = startDayObj.addDays(7);
      resultingFinishDate = weekAway.toFormat("AWS");
      break;
    }
    case VIEW_RANGE.custom: {
      return;
    }
    default: {
      return;
    }
  }
  urlUpdater(startDate, resultingFinishDate);
};

export const handlePageChange = (
  action,
  startDate,
  finishDate,
  viewRange,
  urlUpdater
) => {
  const startDateObj = new DateTime(startDate);
  const finishDateObj = new DateTime(finishDate);

  let resultingStartDate;
  let resultingFinishDate;

  switch (viewRange) {
    case VIEW_RANGE.month: {
      if (action === "increment") {
        const monthAwayStartDate = startDateObj.addMonths(1);
        const monthAwayFinishDate = new DateTime(monthAwayStartDate)
          .addMonths(1)
          .subtractDays(1);

        resultingStartDate = monthAwayStartDate.toFormat("AWS");
        resultingFinishDate = monthAwayFinishDate.toFormat("AWS");
      } else if (action === "decrement") {
        const monthBeforeStartDate = startDateObj.addMonths(-1);
        const monthBeforeFinishDate = new DateTime(monthBeforeStartDate);
        monthBeforeFinishDate.addMonths(1).subtractDays(1);
        resultingStartDate = monthBeforeStartDate.toFormat("AWS");
        resultingFinishDate = monthBeforeFinishDate.toFormat("AWS");
      }
      break;
    }
    case VIEW_RANGE.fortnight: {
      if (action === "increment") {
        const fortnightAwayStartDate = startDateObj.addDays(2 * 7);
        const fortnightAwayFinishDate = finishDateObj.addDays(2 * 7);
        resultingStartDate = fortnightAwayStartDate.toFormat("AWS");
        resultingFinishDate = fortnightAwayFinishDate.toFormat("AWS");
      } else if (action === "decrement") {
        const fortnightBeforeStartDate = startDateObj.subtractDays(2 * 7);
        const fortnightBeforeFinishDate = finishDateObj.subtractDays(2 * 7);
        resultingStartDate = fortnightBeforeStartDate.toFormat("AWS");
        resultingFinishDate = fortnightBeforeFinishDate.toFormat("AWS");
      }
      break;
    }
    case VIEW_RANGE.week: {
      if (action === "increment") {
        const weekAwayStartDate = startDateObj.addDays(7);
        const weekAwayFinishDate = finishDateObj.addDays(7);
        resultingStartDate = weekAwayStartDate.toFormat("AWS");
        resultingFinishDate = weekAwayFinishDate.toFormat("AWS");
      } else if (action === "decrement") {
        const weekBeforeStartDate = startDateObj.subtractDays(7);
        const weekBeforeFinishDate = finishDateObj.subtractDays(7);
        resultingStartDate = weekBeforeStartDate.toFormat("AWS");
        resultingFinishDate = weekBeforeFinishDate.toFormat("AWS");
      }
      break;
    }
    case VIEW_RANGE.custom: {
      const differenceDays =
        DateTime.getDifferenceInDays(startDateObj, finishDateObj) + 1;
      if (action === "increment") {
        const customAwayStartDate = startDateObj.addDays(differenceDays);
        const customAwayFinishDate = finishDateObj.addDays(differenceDays);
        resultingStartDate = customAwayStartDate.toFormat("AWS");
        resultingFinishDate = customAwayFinishDate.toFormat("AWS");
      } else if (action === "decrement") {
        const customBeforeStartDate = startDateObj.subtractDays(differenceDays);
        const customBeforeFinishDate =
          finishDateObj.subtractDays(differenceDays);
        resultingStartDate = customBeforeStartDate.toFormat("AWS");
        resultingFinishDate = customBeforeFinishDate.toFormat("AWS");
      }
      break;
    }
    default: {
      return;
    }
  }

  if (resultingStartDate && resultingFinishDate) {
    urlUpdater(resultingStartDate, resultingFinishDate);
  }
};

export const detectViewRange = (startDate, finishDate) => {
  const startDateConverted = new DateTime(startDate, false);
  const finishDateConverted = new DateTime(finishDate, false);
  const dateDiff = DateTime.getDifferenceInDays(
    startDateConverted,
    finishDateConverted
  );

  if (dateDiff === 7) {
    return VIEW_RANGE.week;
  }
  if (dateDiff === 14) {
    return VIEW_RANGE.fortnight;
  }

  const dateAfterMonth = new DateTime(startDateConverted)
    .addMonths(1)
    .subtractDays(1);

  if (DateTime.getDifferenceInDays(finishDateConverted, dateAfterMonth) === 0) {
    return VIEW_RANGE.month;
  }
  return VIEW_RANGE.custom;
};
