import { useEffect, useState } from "react";
import * as contentful from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styles from "./EndUserLicenseAgreement.module.css";
import { BLOCKS } from "@contentful/rich-text-types";
import logo from "../../../../assets/images/RLlogo.png";

const renderOptions = () => {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };
};

function EndUserLicenseAgreement() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const client = contentful.createClient({
      space: "i63jkkturl0e",
      accessToken: "Adzv2SdOXCSprbZB5dvfirpXg4UG9jwLlxwEgNoBXew",
    });

    client
      .getEntry("2UhowbALEEDZs5WmrIHvB0")
      .then((entry) => {
        setPage(entry.fields);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!page) {
    return "Loading...";
  }

  return (
    <div className={styles.container}>
      <div className={styles.centerLogo}>
        <img src={logo} className={styles.logo} alt="RosterLab logo"></img>
      </div>
      <h1 className={styles.mainHeader}>{page.title}</h1>
      {documentToReactComponents(page.content, renderOptions())}
    </div>
  );
}

export default EndUserLicenseAgreement;
