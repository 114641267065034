import styles from "./EmployeesAccessModal.module.css";
import Select from "react-select";
import {
  convertSingleOptionToPropForm,
  convertToOptionPropForm,
} from "../../../../utils";
import { useMemo, useState } from "react";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { customConfirmAlert } from "../../../confirm/service/confirm";

const ACCESS_CONTROL_OPTIONS = {
  invite: "Invite selected users",
  resendInvite: "Re-send invitation link to selected users",
  deactivate: "Deactivate selected users",
};

const getOptionMessage = (option) => {
  switch (option) {
    case ACCESS_CONTROL_OPTIONS.invite: {
      return "The invitation will be sent to all new employees with emails attached. They will receive a link to register as an employee account for leave requests and preferences.";
    }
    case ACCESS_CONTROL_OPTIONS.resendInvite: {
      return "We will send a new invitation link to the selected users. Are you sure you would like to continue?";
    }
    case ACCESS_CONTROL_OPTIONS.deactivate: {
      return "Selected users' employee accounts will be deactivated. Are you sure you would like to continue?";
    }
    default: {
      throw new Error(`Invalid employees access option: ${option}`);
    }
  }
};

const EmployeesAccessModal = ({
  toggleEmployeesAccessModal,
  selectedRows,
  handleInvitations,
  handleDeactivations,
  handleResendInvitations,
  removeRegistrations,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    ACCESS_CONTROL_OPTIONS.invite
  );

  const userInfo = useMemo(() => {
    return selectedRows.map((row) => {
      const data = row.data;
      return {
        name: data.name,
        email: data.email,
        employeeID: data.id,
        status: data.status,
      };
    });
  }, [selectedRows]);

  const handleSubmit = async () => {
    toggleEmployeesAccessModal();
    let results = {};
    let message = "";

    if (selectedOption === ACCESS_CONTROL_OPTIONS.invite) {
      results = await handleInvitations(userInfo);
      message = "Following users are invited: ";
    } else if (selectedOption === ACCESS_CONTROL_OPTIONS.resendInvite) {
      results = await handleResendInvitations(userInfo);
      message = "Re-sent invitations to following users: ";
    } else if (selectedOption === ACCESS_CONTROL_OPTIONS.deactivate) {
      results = await handleDeactivations(userInfo, removeRegistrations);
      message = "Deactivated following users: ";
    }

    const successfulEmails = results.successfulUsers.map((user) => user.email);
    if (successfulEmails.length > 0) {
      await customConfirmAlert({
        title: message,
        descriptions: [`${successfulEmails.join(", ")}`],
        hideCancel: true,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2>Employees Access</h2>
        <Select
          options={convertToOptionPropForm(
            Object.values(ACCESS_CONTROL_OPTIONS)
          )}
          onChange={(selected) => setSelectedOption(selected.value)}
          value={convertSingleOptionToPropForm(selectedOption)}
        />
        <p className={styles.message}>{getOptionMessage(selectedOption)}</p>
        <div className={styles.buttons}>
          <BasicButton
            color="#219ec9"
            onClick={handleSubmit}
            hoverColor="#1f91b7"
            customStyle={{
              borderRadius: "10px",
              flex: 1,
            }}
          >
            Go ahead
          </BasicButton>
          <BasicButton
            hoverColor="#219ec9"
            borderColor="#219ec9"
            onClick={toggleEmployeesAccessModal}
            customStyle={{
              borderRadius: "10px",
              flex: 1,
            }}
          >
            Cancel
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default EmployeesAccessModal;
