import styles from "./RuleSearcherModalViewer.module.css";
import SearchRuleBlock from "../SearchRuleBlock/SearchRuleBlock";
import ShadowButton from "../../../../../components/elements/ShadowButton/ShadowButton";

const RuleSearcherModalViewer = ({
  searchTerm,
  setSearchTerm,
  addRules,
  getExportedRules,
  setRuleSearcherOpen,
  possibleRules,
  toggleRuleIsSelected,
  reformattedRules,
  selectedRules,
  title,
}) => {
  let ruleBlocks = [];
  possibleRules.forEach((possibleRule, idx) => {
    ruleBlocks.push(
      <SearchRuleBlock
        key={idx}
        isSelected={selectedRules[possibleRule[2]]}
        toggleIsSelected={() => toggleRuleIsSelected(possibleRule[2])}
        description={reformattedRules[possibleRule[2]].description}
      ></SearchRuleBlock>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.rulesContainer}>
          <div className={styles["input-container"]}>
            <input
              className={styles.input}
              type="text"
              placeholder="Describe the rule here"
              maxLength={256}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </div>
          <span className={styles["input-description"]}>
            Input your key words for customised rules
          </span>
          <div className={styles["rule-blocks-container"]}>
            {searchTerm.length !== 0 && ruleBlocks}
          </div>
        </div>
        <div className={styles["btns-container"]}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={() => {
              addRules(getExportedRules());
              setRuleSearcherOpen(false);
            }}
          >
            Add rules
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            hoverColor="#219ec9"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={() => setRuleSearcherOpen(false)}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

export default RuleSearcherModalViewer;
