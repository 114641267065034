import {
  faChartArea,
  faCircleExclamation,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DemandGraphs from "../DemandGraphs/DemandGraphs";
import styles from "./SolutionAnalysis.module.css";
import arrow from "../../../../../assets/icons/dropdownArrow.svg";
import SolutionStatistics from "../SolutionStatistics/SolutionStatistics";
import { DateTime } from "../../../../../utils";
import GoodnessGraph from "../GoodnessGraph/GoodnessGraph";
import FailedSolutionData from "../FailedSolutionData/FailedSolutionData";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";

const TABS = {
  graph: "graph",
  violations: "violations",
  stats: "stats",
};

const SolutionAnalysis = ({
  locationID,
  rosterID,
  isMainStream,
  periodStartDate,
  selectedDemand,
  setSelectedDemand,
  demands,
  demandData,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  startDate,
  employeeSolution,
  demandSolution,
  ruleSolution,
  failedEmployeeData,
  numDays,
  employeesShiftHours,
  customKeywordsData,
  locationStartDate,
  locationNumDays,
  comparisonData,
  namesToEntityShortIdsDicts,
  shortIdsToEntityNamesDicts,
}) => {
  const [currentTab, setCurrentTab] = useState(TABS.violations);

  const redirect = useRedirect();

  const redirectToIndividualEmployeeView = (employeeID) => {
    if (isMainStream) {
      redirect({
        pageName: PAGE_NAMES.scheduleViewIndividualEmployee,
        query: {
          "location-id": locationID,
          date: periodStartDate,
          "employee-id": employeeID,
        },
      });
    } else {
      redirect({
        pageName: PAGE_NAMES.individualEmployee,
        rosterID,
        employeeID,
      });
    }
  };

  const switchTab = (tab) => {
    setCurrentTab(tab);
  };

  const rerosteredDays = [];

  if (comparisonData) {
    for (let i = 0; i < numDays; i++) {
      if (
        comparisonData.filter((emp) => emp.days[i].isDifferent).length > 0 ||
        demandData.some((demand) => demand.targets[i] !== demand.values[i])
      ) {
        rerosteredDays.push(i);
      }
    }
  }

  const TabButtons = () => {
    return (
      <div className={styles.tabBtns}>
        <BasicButton
          color={currentTab === TABS.violations ? "#219ec9" : "white"}
          borderColor={currentTab === TABS.violations ? null : "#219ec9"}
          hoverColor={currentTab === TABS.violations ? "#1f91b7" : "#f6f8f9"}
          hoverlabelColor="#219ec9"
          onClick={() => switchTab(TABS.violations)}
        >
          <span className={styles.staffingGraphsLabel}>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className={styles.graphIcon}
            />
            Staffing Violations
          </span>
        </BasicButton>
        <BasicButton
          color={currentTab === TABS.graph ? "#219ec9" : "white"}
          borderColor={currentTab === TABS.graph ? null : "#219ec9"}
          hoverColor={currentTab === TABS.graph ? "#1f91b7" : "#f6f8f9"}
          hoverlabelColor="#219ec9"
          onClick={() => switchTab(TABS.graph)}
        >
          <span className={styles.staffingGraphsLabel}>
            <FontAwesomeIcon icon={faChartArea} className={styles.graphIcon} />
            Staffing Graphs
          </span>
        </BasicButton>
        <BasicButton
          color={currentTab === TABS.stats ? "#219ec9" : "white"}
          borderColor={currentTab === TABS.stats ? null : "#219ec9"}
          hoverColor={currentTab === TABS.stats ? "#1f91b7" : "#f6f8f9"}
          hoverlabelColor="#219ec9"
          onClick={() => switchTab(TABS.stats)}
        >
          <span className={styles.staffingGraphsLabel}>
            <FontAwesomeIcon
              icon={faSquarePollHorizontal}
              className={styles.graphIcon}
            />
            Stats
          </span>
        </BasicButton>
      </div>
    );
  };

  return (
    <Layout title="Solution analysis" headerRight={TabButtons}>
      <div className={styles.container}>
        {currentTab === TABS.graph && (
          <div>
            <div className={styles.top}>
              <div className={styles.demandSelectorWrappper}>
                <label className={styles.demandSelectorLabel} htmlFor="demands">
                  Please Select a Demand to Analyse:
                </label>
                <select
                  id="demands"
                  name="demands"
                  className={styles.demandSelector}
                  value={selectedDemand}
                  style={{ backgroundImage: `url(${arrow})` }}
                  onChange={(e) => setSelectedDemand(parseInt(e.target.value))}
                  data-testid="select-demand"
                >
                  {demands
                    .filter((demand) => demand.importance !== "No AI")
                    .map((demand, demandIdx) => {
                      return (
                        <option value={demandIdx} key={demandIdx}>
                          {DateTime.getFormattedTime(
                            demand.startTime,
                            "readable"
                          ) +
                            "-" +
                            DateTime.getFormattedTime(
                              demand.finishTime,
                              "readable"
                            ) +
                            ` ${demand.areas} ${demand.shifts} ${demand.tasks} ${demand.skills} ${demand.areas} (${demand.importance}, ${demand.type})`}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {demands.length > 0 ? (
              <DemandGraphs
                originalDemand={demands[selectedDemand]}
                demandData={demandData}
                numDays={numDays}
                startDate={startDate}
                locationStartDate={locationStartDate}
                locationDefaultNumDays={locationNumDays}
              />
            ) : null}
          </div>
        )}
        {currentTab === TABS.violations && failedEmployeeData && (
          <FailedSolutionData
            failedEmployeeData={failedEmployeeData}
            redirectToIndividualEmployeeView={redirectToIndividualEmployeeView}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            demands={demands}
          />
        )}
        {currentTab === TABS.violations &&
          demandSolution &&
          demandSolution.length > 0 && (
            <GoodnessGraph
              demandData={demandSolution}
              ruleSolution={ruleSolution}
              numDays={numDays}
              startDate={startDate}
              rerosteredDays={rerosteredDays}
            />
          )}
        {currentTab === TABS.stats && employeeSolution && (
          <SolutionStatistics
            shifts={shifts}
            shiftGroups={shiftGroups}
            employeeSolution={employeeSolution}
            start_date={startDate}
            employeesShiftHours={employeesShiftHours}
            customKeywordsData={customKeywordsData}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          />
        )}
      </div>
    </Layout>
  );
};

export default SolutionAnalysis;
