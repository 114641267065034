import { useRef, useState } from "react";
import styles from "./RequestManagerModal.module.css";
import ShadowButton from "../../../../../components/elements/ShadowButton/ShadowButton";
import { useMemo } from "react";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import {
  DatePickerEditor,
  DatePickerRenderer,
} from "../../../../../components/elements/DatePicker/DatePicker";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import {
  allocationDropdownOptionsValueSetter,
  allocationValueFormatter,
  convertToOptionPropForm,
  createContextMenuWithDelete,
  DateTime,
  defaultValueSetter,
  flatOptions,
  getNames,
  lockShiftsOnly,
  onFilterTextBoxChanged,
  preferencesValueFormatter,
  setCellRange,
  suppressEnterKey,
  unlockAllCells,
} from "../../../../../utils";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { processPreferencesCellForClipboard } from "../../../../../utils/agGridUtils/clipboard";

const RequestManagerModal = ({
  toggleRequestManager,
  requests,
  employees,
  addNewRequest,
  removeRequests,
  updateData,
  onSubmitRequests,
  setGridApiToParent,
  requestIssues,
  startDate,
  finishDate,
  type,
  handleCellValueChanged,
  exportToCsv,
  exportToExcel,
  gridApi,
  duplicateSelectedRequests,
  predefinedKeywords,
  getDataFromGrid,
  areas,
  skills,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  shortIdsToEntityNamesDicts,
  shiftOptions,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const employeeNames = useMemo(() => getNames(employees), [employees]);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const handleUpdateToOverview = () => {
    for (const key in requestIssues) {
      if (requestIssues[key].length !== 0) {
        customWarningAlert({
          title: "Warning",
          descriptions: [
            "Please resolve all issues before updating to overview.",
          ],
        });
        return;
      }
    }
    onSubmitRequests();
  };

  const getContextMenuItems = (params) => {
    let contextMenu = createContextMenuWithDelete(() => removeRequests(true));

    if (
      params.column !== null &&
      params.column.colId === "shiftAndShiftGroup" &&
      type === "preferences"
    ) {
      contextMenu = [
        {
          name: "Set critical",
          action: function () {
            setCellRange(gridApi, lockShiftsOnly, "!");
          },
        },
        {
          name: "Set high",
          action: function () {
            setCellRange(gridApi, lockShiftsOnly, "?");
          },
        },
        {
          name: "Set low",
          action: function () {
            setCellRange(gridApi, unlockAllCells);
          },
        },
        "separator",
      ].concat(contextMenu);
    }
    return contextMenu;
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const employeeOptions = useMemo(
    () => convertToOptionPropForm(employeeNames),
    [employeeNames]
  );

  const columnDefs = useMemo(
    () => [
      {
        sortable: true,
        field: "employeeName",
        cellEditor: "dropdownSingleSelector",
        cellEditorParams: {
          width: width / 4,
          options: employeeOptions,
        },
        cellEditorPopup: true,
        width: width / 4,
        cellClassRules: {
          "invalid-cell": (params) => (params.value === "" ? true : false),
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressKeyboardEvent: suppressEnterKey,
        valueSetter: (params) => {
          const newValue = params.newValue;
          if (!employeeNames.includes(newValue)) {
            return false;
          }
          return defaultValueSetter(params);
        },
      },
      {
        sortable: true,
        headerName: "Shift Or Shift Group",
        field: "shiftAndShiftGroup",
        width: width / 4 - 16,
        cellClassRules: {
          "critical-background": (params) => {
            if (params.value === undefined) {
              return false;
            }
            let processedValue = params.value;
            if (params.value.endsWith("!")) {
              processedValue = processedValue.substring(
                0,
                processedValue.length - 1
              );
              if (
                !requestIssues["Invalid Allocations"].includes(
                  processedValue
                ) &&
                params.value.includes("!")
              ) {
                return true;
              }
            }
            return false;
          },
          "high-background": (params) => {
            if (params.value === undefined) {
              return false;
            }
            let processedValue = params.value;
            if (params.value.endsWith("?")) {
              processedValue = processedValue.substring(
                0,
                processedValue.length - 1
              );
              if (
                !requestIssues["Invalid Allocations"].includes(processedValue)
              ) {
                return true;
              }
            }
            return false;
          },
          "special-background": (params) => {
            if (params.value === undefined) {
              return false;
            }
            let processedValue = params.value;
            if (params.value.endsWith("*")) {
              processedValue = processedValue.substring(
                0,
                processedValue.length - 1
              );
              if (
                !requestIssues["Invalid Allocations"].includes(processedValue)
              ) {
                return true;
              }
            }
            return false;
          },
        },
        cellEditor: "dropdownSingleSelector",
        cellEditorParams: {
          width: width / 4 - 16,
          options: shiftOptions,
        },
        cellEditorPopup: true,
        valueSetter: (params) =>
          allocationDropdownOptionsValueSetter(
            params,
            flatOptions(shiftOptions)
          ),
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) => {
          if (type === "preferences") {
            return preferencesValueFormatter(
              params,
              predefinedKeywords,
              shifts,
              shiftGroups,
              tasks,
              subTasks,
              areas,
              skills
            );
          }
          return allocationValueFormatter(
            params,
            predefinedKeywords,
            shortIdsToEntityNamesDicts
          );
        },
      },
      {
        sortable: true,
        field: "from",
        cellEditor: "datePickerEditor",
        cellEditorPopup: true,
        cellRenderer: "datePickerRenderer",
        width: width / 4,
        cellClassRules: {
          "invalid-cell": (params) => {
            const from = new DateTime(params.data.from);
            const to = new DateTime(params.data.to);
            if (
              DateTime.isValidDateString(from.date) &&
              DateTime.isValidDateString(to.date)
            ) {
              if (from.date > to.date) {
                return true;
              }
            }

            if (
              DateTime.getDifferenceInDays(new DateTime(startDate), from) < 0
            ) {
              return true;
            }

            for (const overlapOfEmployee of requestIssues[
              "Overlapping Periods (request IDs)"
            ]) {
              for (const overlap of overlapOfEmployee) {
                if (overlap.id === params.data.id) {
                  return true;
                }
              }
            }

            return params.value === "" ? true : false;
          },
        },
        valueSetter: (params) => {
          const inputDate = params.newValue;
          const ISOFormattedDate = DateTime.convertLocaleToISO(inputDate);
          if (ISOFormattedDate === "Invalid date") {
            showWarningPopupIfNoOthersShowing("Invalid time");
            return false;
          }
          params.data.from = ISOFormattedDate;
          return true;
        },
      },
      {
        sortable: true,
        field: "to",
        cellEditor: "datePickerEditor",
        cellEditorPopup: true,
        cellRenderer: "datePickerRenderer",
        width: width / 4,
        cellClassRules: {
          "invalid-cell": (params) => {
            const from = new DateTime(params.data.from);
            const to = new DateTime(params.data.to);
            if (
              DateTime.isValidDateString(from.date) &&
              DateTime.isValidDateString(to.date)
            ) {
              if (from.date > to.date) {
                return true;
              }
            }

            if (
              DateTime.getDifferenceInDays(to, new DateTime(finishDate)) < 0
            ) {
              return true;
            }

            for (const overlapOfEmployee of requestIssues[
              "Overlapping Periods (request IDs)"
            ]) {
              for (const overlap of overlapOfEmployee) {
                if (overlap.id === params.data.id) {
                  return true;
                }
              }
            }

            return params.value === "" ? true : false;
          },
        },
        valueSetter: (params) => {
          const inputDate = params.newValue;
          const ISOFormattedDate = DateTime.convertLocaleToISO(inputDate);
          if (ISOFormattedDate === "Invalid date") {
            showWarningPopupIfNoOthersShowing("Invalid time");
            return false;
          }
          params.data.to = ISOFormattedDate;
          return true;
        },
      },
    ],
    [
      requestIssues,
      width,
      finishDate,
      startDate,
      shiftOptions,
      employeeOptions,
      employeeNames,
      predefinedKeywords,
      shiftGroups,
      shifts,
      tasks,
      subTasks,
      type,
      areas,
      shortIdsToEntityNamesDicts,
      skills,
    ]
  );

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles["modal-header"]}>
          <h2>
            Manage all{" "}
            {type === "fixedShifts" ? "fixed shifts/leave" : "preferences"}
          </h2>
          <AddRowButton
            rowName={"Request"}
            addSingle={() => addNewRequest(1)}
            customStyle={{
              borderRadius: "10px",
            }}
            addOptions={[
              {
                label: "Add New",
                onClick: () => addNewRequest(1),
              },
              {
                label: "Add Multiple",
                onClick: () => {
                  const num = parseInt(
                    prompt(`How many requests should I add?`, "1")
                  );
                  addNewRequest(num);
                },
              },
            ]}
          />
        </div>
        <div className={styles["grid-container"]} ref={componentRef}>
          <ActionBar
            searchBarSettings={{
              tableName: "fixedShiftsOverview",
              onFilterInputChanged,
            }}
            duplicateSelectedSettings={{
              selectedRows,
              duplicateSelectedRows: duplicateSelectedRequests,
            }}
            deleteSelectedSettings={{
              selectedRows,
              removeSelectedRows: () => removeRequests(false),
            }}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
          <DataEntryTable
            handleCellValueChanged={handleCellValueChanged}
            columnDefs={columnDefs}
            customStyle={{ height: "300px" }}
            rowData={requests}
            updateData={updateData}
            setGridApiToParent={setGridApiToParent}
            getContextMenuItems={getContextMenuItems}
            components={{
              datePickerEditor: DatePickerEditor,
              datePickerRenderer: DatePickerRenderer,
              dropdownSingleSelector: DropdownSingleSelector,
            }}
            gridOptions={{
              onSelectionChanged: (params) => {
                setSelectedRows(params.api.getSelectedNodes());
              },
              suppressRowClickSelection: true,
              rowSelection: "multiple",
            }}
            defaultColDef={{
              filterParams: { newRowsAction: "keep" },
            }}
            getDataFromGrid={getDataFromGrid}
            processCellForClipboard={
              type === "preferences" ? processPreferencesCellForClipboard : null
            }
          />
        </div>
        <div className={styles.btns}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={handleUpdateToOverview}
          >
            Update to overview
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            hoverColor="#219ec9"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={toggleRequestManager}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

export default RequestManagerModal;
