import { useMemo, useEffect, useCallback, useRef } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { useRouteQuery } from "../hooks/useRouteQuery";
import PrintableSolution from "../features/rosterProblems/components/Solutions/PrintableSolution/PrintableSolution";
import {
  RosterGridsLayoutWithoutSidebar,
  RosterGridsLayoutWithSidebar,
} from "../features/grid/components/GridsLayout/GridsLayout";
import EmployeesDataContainer from "../features/rosterProblems/components/Employees/EmployeesDataContainer/EmployeesDataContainer";
import GenerateLocked from "../features/upgradePlan/components/GenerateLocked/GenerateLocked";
import { useSolutionGenerationStore } from "../globalStore/solutionGenerationStore";
import { HEADER_TYPES } from "../components/elements/Header/Header";
import { PAGE_NAMES, useRedirect } from "../utils/routeUtils/redirect";
import {
  subscribeRosterSolutionCreation,
  subscribeRosterSolutionDeleted,
  subscribeRosterSolutionUpdate,
} from "../utils/queryUtils/observers";
import { getFieldsFromRoster } from "../utils/queryUtils/rosterDataGetters";
import { useLocationQuery } from "../hooks/modelQueryHooks/useLocationQuery";
import ScheduleShiftsDataContainer from "../features/scheduleView/components/ScheduleShiftsDataContainer/ScheduleShiftsDataContainer";
import ScheduleTasksDataContainer from "../features/scheduleView/components/ScheduleTasksDataContainer/ScheduleTasksDataContainer";
import ScheduleSkillsDataContainer from "../features/scheduleView/components/ScheduleSkillsDataContainer/ScheduleSkillsDataContainer";
import ScheduleShiftGroupsDataContainer from "../features/scheduleView/components/ScheduleShiftGroupsDataContainer/ScheduleShiftGroupsDataContainer";
import ScheduleRulesDataContainer from "../features/scheduleView/components/ScheduleRulesDataContainer/ScheduleRulesDataContainer";
import ScheduleDemandsDataContainer from "../features/scheduleView/components/ScheduleDemandsDataContainer/ScheduleDemandsDataContainer";
import ScheduleHistoryDataContainer from "../features/scheduleView/components/ScheduleHistoryDataContainer/ScheduleHistoryDataContainer";
import ScheduleFixedShiftsWrapper from "../features/scheduleView/components/ScheduleFixedShiftsWrapper/ScheduleFixedShiftsWrapper";
import SchedulePreferencesWrapper from "../features/scheduleView/components/SchedulePreferencesWrapper/SchedulePreferencesWrapper";
import LoadingPage from "../features/loading/components/LoadingPage/LoadingPage";
import IndividualViewWrapper from "../features/rosterProblems/individualView/components/IndividualViewWrapper/IndividualViewWrapper";
import ScheduleSolutionsDataContainer from "../features/scheduleView/components/ScheduleSolutionsDataContainer/ScheduleSolutionsDataContainer";
import ScheduleRosterDataContainer from "../features/scheduleView/components/ScheduleRosterDataContainer/ScheduleRosterDataContainer";
import { useBareRosterQuery } from "../hooks/modelQueryHooks/useBareModelQueries";
import {
  employeePageViewSetting,
  useDefaultPageViewStore,
} from "../globalStore/defaultViewsStore";
import { interpretCustomKeywordsData } from "../utils/queryUtils/locationDataGetters";
import { useWarningsStore } from "../globalStore/warningsStore";
import { Route } from "react-router";
import { isMobileViewOrApp } from "../globalStore/deviceInfoStore";
import { DateTime } from "../utils";
import ScheduleAreasDataContainer from "../features/scheduleView/components/ScheduleAreasDataContainer/ScheduleAreasDataContainer";

// Prototype view specific pages
const EmployeesTable = (props) => <EmployeesDataContainer {...props} />;

// Generic pages
const RosterPage = (props) => <ScheduleRosterDataContainer {...props} />;
const SkillsTable = (props) => <ScheduleSkillsDataContainer {...props} />;
const TasksTable = (props) => <ScheduleTasksDataContainer {...props} />;
const AreasTable = (props) => <ScheduleAreasDataContainer {...props} />;
const ShiftsTable = (props) => <ScheduleShiftsDataContainer {...props} />;
const ShiftGroupsTable = (props) => (
  <ScheduleShiftGroupsDataContainer {...props} />
);
const RulesTable = (props) => <ScheduleRulesDataContainer {...props} />;
const DemandsTable = (props) => <ScheduleDemandsDataContainer {...props} />;
const HistoryTable = (props) => <ScheduleHistoryDataContainer {...props} />;
const FixedShiftsPage = (props) => <ScheduleFixedShiftsWrapper {...props} />;
const PreferencesPage = (props) => <SchedulePreferencesWrapper {...props} />;
const SolutionsPage = (props) => <ScheduleSolutionsDataContainer {...props} />;
const GenerateLockedPage = (props) => <GenerateLocked {...props} />;

const RosterRoutes = ({ toggleCreateLocationModal, locations }) => {
  const isInitialWarningCalculationDone = useRef(false);
  const { setLatestSolutionGenStatus } = useSolutionGenerationStore();
  const { url } = useRouteMatch();
  const routeQuery = useRouteQuery();

  const { changeDefaultPageView } = useDefaultPageViewStore();

  const redirectTo = useRedirect();
  const queryRosterID = routeQuery.get("roster-id");
  const rosterID = queryRosterID ? queryRosterID : "";

  const { roster, isLoading: isRosterLoading } = useBareRosterQuery(
    rosterID,
    false,
    null,
    true
  );

  const { warnings, updateFullWarnings } = useWarningsStore();

  const { name, startDate, numDays, locationID } = useMemo(
    () => (roster ? getFieldsFromRoster(roster) : {}),
    [roster]
  );

  const finishDate = startDate
    ? new DateTime(startDate).addDays(numDays - 1).toFormat("AWS")
    : null;

  const {
    location,
    globalEmployees,
    customKeywordsData,
    isQueryLoading: isLocationLoading,
  } = useLocationQuery(locations, locationID);

  useEffect(() => {
    if (
      !roster ||
      isInitialWarningCalculationDone.current ||
      !customKeywordsData
    ) {
      return;
    }

    const customKeywords = interpretCustomKeywordsData(customKeywordsData);
    updateFullWarnings(roster, customKeywords);

    isInitialWarningCalculationDone.current = true;
  }, [roster, customKeywordsData, updateFullWarnings]);

  useEffect(() => {
    const solutionCreateSub = subscribeRosterSolutionCreation(
      setLatestSolutionGenStatus,
      rosterID
    );
    const solutionUpdateSub = subscribeRosterSolutionUpdate(
      setLatestSolutionGenStatus,
      rosterID
    );
    const solutionDeleteSub = subscribeRosterSolutionDeleted(
      () => setLatestSolutionGenStatus(null),
      rosterID
    );
    return async () => {
      (await solutionCreateSub)?.unsubscribe();
      (await solutionUpdateSub)?.unsubscribe();
      (await solutionDeleteSub)?.unsubscribe();
    };
  }, [setLatestSolutionGenStatus, rosterID]);

  const redirectToEmployeesIndividualViewPage = useCallback(() => {
    redirectTo({
      pageName: PAGE_NAMES.individualEmployee,
      rosterID,
    });
    changeDefaultPageView(
      employeePageViewSetting.name,
      employeePageViewSetting.possibleValues.individual
    );
  }, [changeDefaultPageView, redirectTo, rosterID]);

  const redirectToEmployeesOverviewPage = useCallback(() => {
    redirectTo({
      pageName: PAGE_NAMES.employeesSkills,
      rosterID,
    });
    changeDefaultPageView(
      employeePageViewSetting.name,
      employeePageViewSetting.possibleValues.overview,
      changeDefaultPageView
    );
  }, [redirectTo, rosterID, changeDefaultPageView]);

  const isMobile = isMobileViewOrApp();

  const RosterGridsLayout = useMemo(
    () =>
      isMobile ? RosterGridsLayoutWithoutSidebar : RosterGridsLayoutWithSidebar,
    [isMobile]
  );

  if (isRosterLoading || isLocationLoading || !location) {
    return <LoadingPage />;
  }

  if (!roster) {
    redirectTo(PAGE_NAMES.main);
    return;
  }

  return (
    <Switch>
      <Route exact path={`${url}/employees`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"Employees"}
          GridComponents={[EmployeesTable, SkillsTable]}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
          redirectToIndividualPage={redirectToEmployeesIndividualViewPage}
        />
      </Route>
      <Route exact path={`${url}/employees-individual`}>
        <IndividualViewWrapper
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          locations={locations}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locationID={locationID}
          startDate={startDate}
          periodStartDate={startDate}
          numDays={numDays}
          pageName={"Employees"}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
          redirectToOverviewPage={redirectToEmployeesOverviewPage}
        />
      </Route>

      <Route exact path={`${url}/areas`}>
        <RosterGridsLayout
          rosterID={rosterID}
          locationID={locationID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          pageName={"Areas"}
          GridComponents={[AreasTable]}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>

      <Route exact path={`${url}/tasks`}>
        <RosterGridsLayout
          rosterID={rosterID}
          locationID={locationID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          pageName={"Tasks"}
          GridComponents={[TasksTable]}
          customStyles={{
            marginBottom: "200px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/shifts`}>
        <RosterGridsLayout
          isRoster={true}
          rosterID={rosterID}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"Shift/Shift Groups"}
          GridComponents={[ShiftsTable, ShiftGroupsTable]}
          customStyles={{
            marginBottom: "200px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/rules`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          location={location}
          startDate={startDate}
          pageName={"Rules"}
          GridComponents={[RulesTable]}
          headerType={HEADER_TYPES.roster}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/demands`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          startDate={startDate}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"Staffing Demands"}
          GridComponents={[DemandsTable]}
          customStyles={{
            marginBottom: "300px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/history`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          startDate={startDate}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"History"}
          GridComponents={[HistoryTable]}
          customStyles={{
            marginBottom: "300px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/fixedshifts`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"Fixed Shifts/Leave"}
          GridComponents={[FixedShiftsPage]}
          customStyles={{
            marginBottom: "300px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
          periodStartDate={startDate}
        />
      </Route>
      <Route exact path={`${url}/preferences`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          numDays={numDays}
          startDate={startDate}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"Preferences"}
          GridComponents={[PreferencesPage]}
          customStyles={{
            marginBottom: "300px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={`${url}/solutions/printable`}>
        <PrintableSolution
          rosterID={rosterID}
          solutionIndex={0}
          startDate={startDate}
          rosterName={name}
          numDays={numDays}
        />
      </Route>
      <Route exact path={`${url}/solutions`}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          locationID={locationID}
          locations={locations}
          location={location}
          roster={roster}
          name={name}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          pageName={"A.I. Solutions"}
          GridComponents={[SolutionsPage]}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          warnings={warnings}
          customKeywordsData={customKeywordsData}
        />
      </Route>
      <Route exact path={[`${url}/roster`, `${url}/shift-view`]}>
        <RosterGridsLayout
          rosterID={rosterID}
          isRoster={true}
          roster={roster}
          warnings={warnings}
          rosterName={name}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          locationID={locationID}
          pageName={"My Roster"}
          GridComponents={[RosterPage]}
          customStyles={{
            marginBottom: "200px",
          }}
          headerType={HEADER_TYPES.roster}
          globalEmployees={globalEmployees}
          location={location}
          customKeywordsData={customKeywordsData}
          periodStartDate={startDate}
          periodFinishDate={finishDate}
        />
      </Route>
      <Route exact path={`${url}/generate-locked`}>
        <RosterGridsLayout
          rosterID={rosterID}
          rosterName={name}
          locations={locations}
          locationID={locationID}
          location={location}
          pageName={"Generate Roster"}
          GridComponents={[GenerateLockedPage]}
          headerType={HEADER_TYPES.roster}
          isScheduleView={false}
          toggleCreateLocationModal={toggleCreateLocationModal}
        />
      </Route>
    </Switch>
  );
};

export default RosterRoutes;
