class RosterRule {
  template: string;
  dominance: string;
  rule: any;
  getDescription: any;
  valueSetter: any;
  importance: string;
  descriptionTemplate: string[];

  constructor(
    template: string,
    dominance: string,
    importance: string,
    descriptionTemplate: string[],
    valueSetter: any,
    rules?: any[]
  ) {
    this.template = template;
    this.dominance = dominance;
    this.descriptionTemplate = descriptionTemplate;
    this.getDescription = (name, value) =>
      descriptionTemplate[0] +
      ` ${name} ` +
      descriptionTemplate[1] +
      (descriptionTemplate.length > 2
        ? ` ${value} ` + descriptionTemplate[2]
        : "");
    this.valueSetter = valueSetter;
    if (rules) {
      this.rule = this.getRuleByTemplate(rules);
    }
    this.importance = importance;
  }

  getRuleByTemplate(rules: any[]) {
    for (let r = 0; r < rules.length; r++) {
      for (let s = 0; s < rules[r].subrules.length; s++) {
        if (rules[r].subrules[s].ruleTemplate === this.template) {
          return rules[r].subrules[s];
        }
      }
    }

    return null;
  }

  getRuleData(value: number, rules: any[]) {
    return {
      descriptionTemplate: this.descriptionTemplate,
      subrule: this.getRuleByTemplate(rules),
      value: this.valueSetter(value),
      dominate: this.dominance,
      importance: this.importance,
    };
  }

  // This is very similar to function for finding rules to clear?
  addInvalidCells(
    invalidCells: any[],
    invalidDays: any[],
    employeeInd: number,
    employeeId: string,
    name: string,
    value: number
  ) {
    invalidDays.forEach((day) => {
      invalidCells.push({
        template: this.template,
        description: this.getDescription(name, value),
        coords: [employeeInd, day],
        employeeId: employeeId,
        importance: this.importance,
      });
    });
  }
}

export default RosterRule;
