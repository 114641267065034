import PropTypes from "prop-types";
import styles from "./SideBar.module.css";
import SideBarItem from "../SideBarItem/SideBarItem";

const SideBar = ({
  items,
  rosterID,
  locationID,
  pageName,
  isScheduleView,
  urlQueryParam,
  periodStartDate,
  warnings,
  frontendSettings,
}) => {
  return (
    <nav className={`${styles.container}`}>
      <ul className={styles.list}>
        {items.map((item, idx) => {
          const iconElement =
            typeof item.icon === "object" ? (
              item.icon
            ) : (
              <img
                src={item.icon}
                alt="menu item"
                style={{
                  ...item.iconStyle,
                }}
              />
            );

          if (idx === 0) {
            if (isScheduleView) {
              return null;
            }
            return (
              <div key={idx} className={styles["first-item-divider"]}>
                <SideBarItem
                  {...item}
                  rosterID={rosterID}
                  locationID={locationID}
                  active={pageName === item.label}
                  urlQueryParam={urlQueryParam}
                  buttonType={item.buttonType}
                  iconElement={iconElement}
                  isScheduleView={isScheduleView}
                  periodStartDate={periodStartDate}
                  warnings={warnings}
                  frontendSettings={frontendSettings}
                />
              </div>
            );
          }
          if (idx === 1) {
            return (
              <div key={idx} className={styles["first-item-divider"]}>
                <SideBarItem
                  {...item}
                  rosterID={rosterID}
                  locationID={locationID}
                  active={pageName === item.label}
                  urlQueryParam={urlQueryParam}
                  buttonType={item.buttonType}
                  iconElement={iconElement}
                  isScheduleView={isScheduleView}
                  periodStartDate={periodStartDate}
                  warnings={warnings}
                  frontendSettings={frontendSettings}
                />
              </div>
            );
          }

          return (
            <div key={idx} className={styles["item-divider"]}>
              <SideBarItem
                {...item}
                rosterID={rosterID}
                locationID={locationID}
                active={pageName === item.label}
                urlQueryParam={urlQueryParam}
                buttonType={item.buttonType}
                iconElement={iconElement}
                isScheduleView={isScheduleView}
                periodStartDate={periodStartDate}
                warnings={warnings}
                frontendSettings={frontendSettings}
              />
            </div>
          );
        })}
      </ul>
    </nav>
  );
};

SideBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      routeTo: PropTypes.string,
      icon: PropTypes.node,
      isGenerateBtn: PropTypes.bool,
    })
  ),
};

export default SideBar;
