import { Redirect, Route } from "react-router-dom";
import { Switch, useRouteMatch } from "react-router-dom";
import EmployeeAppMobileWrapper from "../features/employeeApp/components/mobile/EmployeeAppMobileWrapper/EmployeeAppMobileWrapper";
import EmployeeAppMobileProfile from "../features/employeeApp/components/mobile/EmployeeAppMobileProfile/EmployeeAppMobileProfile";
import {
  EMPLOYEE_APP_CONTENT_VIEW_TYPE,
  useEmployeeAppCurrentView,
} from "../features/employeeApp/hooks/useEmployeeAppCurrentView";
import { useUserStore } from "../globalStore/appStore";
import { useEffect, useMemo, useState } from "react";
import { useEmployeeApplicationQuery } from "../hooks/modelQueryHooks/useEmployeeApplicationQuery";
import EmployeeAppDesktop from "../features/employeeApp/components/desktop/EmployeeAppDesktop/EmployeeAppDesktop";
import MobileSchedulePage from "../features/employeeApp/components/mobile/MobileSchedulePage/MobileSchedulePage";
import MobileRequestsPage from "../features/employeeApp/components/mobile/MobileRequestsPage/MobileRequestsPage";
import MobileOpenShiftsPage from "../features/employeeApp/components/mobile/MobileOpenShiftsPage/MobileOpenShiftsPage";
import NotFoundPage from "../features/allRosters/components/NotFoundPage/NotFoundPage";
import LoadingPage from "../features/loading/components/LoadingPage/LoadingPage";
import { useFirebaseMessageTokenStore } from "../globalStore/firebaseMessageTokenStore";
import { useQueryClient } from "@tanstack/react-query";
import { useEmployeeAllocationsData } from "../features/employeeApp/hooks/useEmployeeAllocationsData";
import { useEmployeeAppActions } from "../features/employeeApp/hooks/useEmployeeAppActions";
import { getLocationPlan } from "../utils/queryUtils/locationDataGetters";
import UnsupportedViewForDevice from "../features/invalidPages/components/UnsupportedViewForDevice/UnsupportedViewForDevice";
import DesktopSurvey from "../features/employeeApp/components/desktop/DesktopSurvey/DesktopSurvey";
import MobileSurvey from "../features/employeeApp/components/mobile/MobileSurvey/MobileSurvey";
import { useSearchParams, useLocation } from "react-router-dom-v5-compat";
import { getDateStringFromParam } from "../utils/routeUtils/routeUtils";
import { PAGE_NAMES, useRedirect } from "../utils/routeUtils/redirect";

const MobileApp = ({
  isUserAdmin,
  userEmployee,
  locations,
  location,
  globalEmployees,
  plan,
}) => {
  const { user } = useUserStore();
  const [isBottomTabOpened, setIsBottomTabOpened] = useState(false);

  const { currentToken } = useFirebaseMessageTokenStore();
  const { url } = useRouteMatch();
  const defaultUrl = `${url}/schedule`;

  const openBottomTab = () => setIsBottomTabOpened(true);
  const closeBottomTab = () => setIsBottomTabOpened(false);
  const toggleBottomTab = () => setIsBottomTabOpened((prev) => !prev);

  const {
    allocations,
    myOpenShifts,
    preferences,
    preferencesRecurring,
    preferenceNumbers,
    preferenceRecurringNumbers,
    requests,
    defaultPreferenceLevel,
    leaveCodes,
    employeeLeaveCounts,
    availableShifts,
    availableShiftGroups,
    leaveKeywordsDict,
    numberOfEmployees,
    customKeywordsUtilObj,
    notesToDisplay,
  } = useEmployeeAllocationsData(location, userEmployee, globalEmployees);

  const {
    updateOpenShifts,
    addRegistrationToGlobalEmployee,
    addNewRequest,
    deleteRequestById,
    updatePreference,
  } = useEmployeeAppActions(location, userEmployee);

  const {
    currentViewData,
    setContentViewType,
    setSelectedDate,
    toggleCalendarViewType,
    setContentViewAndSelectedDate,
  } = useEmployeeAppCurrentView(true);

  useEffect(() => {
    if (userEmployee && currentToken) {
      addRegistrationToGlobalEmployee(userEmployee, currentToken);
    }
  }, [userEmployee, currentToken, addRegistrationToGlobalEmployee]);

  return (
    <Switch>
      <Route path={`${url}/user`}>
        <EmployeeAppMobileProfile
          user={user}
          isAdmin={isUserAdmin}
          userEmployee={userEmployee}
          locations={locations}
          location={location}
          plan={plan}
        />
      </Route>
      <Route path={`${url}/schedule`}>
        <EmployeeAppMobileWrapper
          switchableContentViewTypes={[
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences,
          ]}
          toggleCalendarViewType={toggleCalendarViewType}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          currentViewData={currentViewData}
          shouldShowCalendarTypeSwitcher={true}
          location={location}
        >
          <MobileSchedulePage
            currentViewData={currentViewData}
            setSelectedDate={setSelectedDate}
            allocations={allocations}
            myOpenShifts={myOpenShifts}
            userEmployee={userEmployee}
            location={location}
            preferences={preferences}
            preferencesRecurring={preferencesRecurring}
            preferenceNumbers={preferenceNumbers}
            preferenceRecurringNumbers={preferenceRecurringNumbers}
            defaultPreferenceLevel={defaultPreferenceLevel}
            availableShifts={availableShifts}
            availableShiftGroups={availableShiftGroups}
            isBottomTabOpened={isBottomTabOpened}
            openBottomTab={openBottomTab}
            closeBottomTab={closeBottomTab}
            toggleBottomTab={toggleBottomTab}
            requests={requests}
            leaveCodes={leaveCodes}
            employeeLeaveCounts={employeeLeaveCounts}
            numberOfEmployees={numberOfEmployees}
            updatePreference={updatePreference}
            updateOpenShifts={updateOpenShifts}
            setContentViewAndSelectedDate={setContentViewAndSelectedDate}
            notesToDisplay={notesToDisplay}
          />
        </EmployeeAppMobileWrapper>
      </Route>
      <Route path={`${url}/leave`}>
        <EmployeeAppMobileWrapper
          switchableContentViewTypes={[
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.whosOnLeave,
          ]}
          toggleCalendarViewType={toggleCalendarViewType}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          currentViewData={currentViewData}
          shouldShowCalendarTypeSwitcher={false}
          location={location}
        >
          <MobileRequestsPage
            currentViewData={currentViewData}
            setSelectedDate={setSelectedDate}
            allocations={allocations}
            myOpenShifts={myOpenShifts}
            userEmployee={userEmployee}
            location={location}
            preferences={preferences}
            preferencesRecurring={preferencesRecurring}
            isBottomTabOpened={isBottomTabOpened}
            openBottomTab={openBottomTab}
            closeBottomTab={closeBottomTab}
            toggleBottomTab={toggleBottomTab}
            requests={requests}
            leaveCodes={leaveCodes}
            employeeLeaveCounts={employeeLeaveCounts}
            leaveKeywordsDict={leaveKeywordsDict}
            addNewRequest={addNewRequest}
            deleteRequestById={deleteRequestById}
            setContentViewType={setContentViewType}
            plan={plan}
            updateOpenShifts={updateOpenShifts}
          />
        </EmployeeAppMobileWrapper>
      </Route>
      <Route path={`${url}/open-shifts`}>
        <EmployeeAppMobileWrapper
          switchableContentViewTypes={[]}
          toggleCalendarViewType={toggleCalendarViewType}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          currentViewData={currentViewData}
          shouldShowCalendarTypeSwitcher={false}
          location={location}
        >
          <MobileOpenShiftsPage
            myOpenShifts={myOpenShifts}
            updateOpenShifts={updateOpenShifts}
            userEmployee={userEmployee}
            plan={plan}
          />
        </EmployeeAppMobileWrapper>
      </Route>
      <Route path={`${url}/survey`}>
        <MobileSurvey
          location={location}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          currentViewData={currentViewData}
          userEmployee={userEmployee}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      </Route>
      <Route path={`${url}/unsupported-device`}>
        <UnsupportedViewForDevice />
      </Route>
      <Route path={`${url}/`}>
        <Redirect to={defaultUrl} />
      </Route>
    </Switch>
  );
};

const DesktopApp = ({
  location,
  locations,
  globalEmployees,
  userEmployee,
  isUserAdmin,
  plan,
}) => {
  const { currentViewData, setContentViewType, setSelectedDate } =
    useEmployeeAppCurrentView(true);

  const { currentToken } = useFirebaseMessageTokenStore();
  const { url } = useRouteMatch();
  const defaultUrl = `${url}/calendar`;

  const {
    allocations,
    myOpenShifts,
    preferences,
    preferencesRecurring,
    preferenceNumbers,
    preferenceRecurringNumbers,
    requests,
    defaultPreferenceLevel,
    leaveCodes,
    employeeLeaveCounts,
    availableShifts,
    availableShiftGroups,
    leaveKeywordsDict,
    numberOfEmployees,
    customKeywordsUtilObj,
    notesToDisplay,
  } = useEmployeeAllocationsData(location, userEmployee, globalEmployees);

  const {
    updateOpenShifts,
    addRegistrationToGlobalEmployee,
    addNewRequest,
    deleteRequestById,
    updatePreference,
  } = useEmployeeAppActions(location, userEmployee);

  useEffect(() => {
    if (userEmployee && currentToken) {
      addRegistrationToGlobalEmployee(userEmployee, currentToken);
    }
  }, [userEmployee, currentToken, addRegistrationToGlobalEmployee]);

  return (
    <Switch>
      <Route path={`${url}/calendar`}>
        <EmployeeAppDesktop
          locations={locations}
          location={location}
          currentViewData={currentViewData}
          setContentViewType={setContentViewType}
          setSelectedDate={setSelectedDate}
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          userEmployee={userEmployee}
          isUserAdmin={isUserAdmin}
          updateOpenShifts={updateOpenShifts}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          numberOfEmployees={numberOfEmployees}
          preferenceNumbers={preferenceNumbers}
          preferenceRecurringNumbers={preferenceRecurringNumbers}
          updatePreference={updatePreference}
          defaultPreferenceLevel={defaultPreferenceLevel}
          requests={requests}
          leaveCodes={leaveCodes}
          employeeLeaveCounts={employeeLeaveCounts}
          availableShifts={availableShifts}
          availableShiftGroups={availableShiftGroups}
          deleteRequestById={deleteRequestById}
          leaveKeywordsDict={leaveKeywordsDict}
          addNewRequest={addNewRequest}
          plan={plan}
          notesToDisplay={notesToDisplay}
        />
      </Route>
      <Route path={`${url}/survey`}>
        <DesktopSurvey
          location={location}
          locations={locations}
          isUserAdmin={isUserAdmin}
          userEmployee={userEmployee}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      </Route>
      <Route path={`${url}/`}>
        <Redirect to={defaultUrl} />
      </Route>
    </Switch>
  );
};

const EmployeeAppRoute = ({ isUserAdmin, isMobile }) => {
  const { url } = useRouteMatch();
  const { user, setToCurrentUser } = useUserStore();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const routeLocation = useLocation();

  const redirectTo = useRedirect();

  useEffect(() => {
    if (routeLocation.pathname.startsWith("/employee-view/survey")) {
      const date = getDateStringFromParam(searchParams.get("date"));
      redirectTo({
        pageName: isMobile
          ? PAGE_NAMES.mobileEmployeeViewSurvey
          : PAGE_NAMES.desktopEmployeeViewSurvey,
        query: {
          ...(date && { date }),
        },
      });
    }
  }, [isMobile, redirectTo, routeLocation, searchParams]);

  useEffect(() => {
    setToCurrentUser();
  }, [setToCurrentUser]);

  const {
    userEmployee,
    employees: globalEmployees,
    locationID,
    location,
    locations,
    isQueryLoading,
    isInvalidLocationIDInURL,
    updateIsInvalidLocationIDInURL,
  } = useEmployeeApplicationQuery(user);

  const plan = useMemo(() => {
    if (!location) {
      return null;
    }
    return getLocationPlan(location);
  }, [location]);

  useEffect(() => {
    return () => {
      // Refresh location when going back to admin view to reflect all the change that the admin made in employee-view app
      if (isUserAdmin) {
        // queryClient.invalidateQueries(["location", locationID]);
      }
    };
  }, [isUserAdmin, locationID, queryClient]);

  const defaultUrl = `${url}/${isMobile ? "mobile" : "desktop"}`;

  if (isQueryLoading && !isInvalidLocationIDInURL) {
    return <LoadingPage />;
  }

  const notScheduledInAnyLocation = !locations || locations.length === 0;
  const notScheduledInCurrentLocation = !location || !userEmployee;

  if (notScheduledInAnyLocation && isUserAdmin && isMobile) {
    return redirectTo({
      pageName: PAGE_NAMES.unsupportedDevice,
    });
  }

  if (notScheduledInAnyLocation) {
    const message = isUserAdmin
      ? "You are not scheduled in any location."
      : "No location found, please contact your scheduling admin for access";

    return (
      <NotFoundPage
        message={message}
        redirectPageName={isUserAdmin ? PAGE_NAMES.main : null}
      />
    );
  }

  if (notScheduledInCurrentLocation) {
    return (
      <NotFoundPage
        message="You are not scheduled in this location."
        redirectPageName={
          isUserAdmin ? PAGE_NAMES.main : PAGE_NAMES.desktopEmployeeViewSchedule
        }
        redirectCallback={() => {
          if (isInvalidLocationIDInURL) {
            updateIsInvalidLocationIDInURL(false);
          }
        }}
        returnButtonLabel="Return to main page"
      />
    );
  }

  return (
    <Switch>
      {isMobile ? (
        <Route path={`${url}/mobile`}>
          <MobileApp
            isUserAdmin={isUserAdmin}
            userEmployee={userEmployee}
            locations={locations}
            location={location}
            globalEmployees={globalEmployees}
            plan={plan}
          />
        </Route>
      ) : (
        <Route path={`${url}/desktop`}>
          <DesktopApp
            location={location}
            locations={locations}
            globalEmployees={globalEmployees}
            userEmployee={userEmployee}
            isUserAdmin={isUserAdmin}
            plan={plan}
            notScheduledInAnyLocation={notScheduledInAnyLocation}
            notScheduledInCurrentLocation={notScheduledInCurrentLocation}
          />
        </Route>
      )}
      <Route path={`${url}/`}>
        <Redirect to={defaultUrl} />
      </Route>
    </Switch>
  );
};

export default EmployeeAppRoute;
