import {
  faChevronDown,
  faChevronUp,
  faCog,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LocationSelector.module.css";
import { PAGE_NAMES, useRedirect } from "../../../utils/routeUtils/redirect";
import { useToggleShow } from "../../../hooks/useToggleShow";
import { PLAN } from "../../../features/auth/service/auth";
import { useUserStore } from "../../../globalStore/appStore";

const LocationSelector = ({
  toggleCreateLocationModal = () => {},
  locations,
  location,
  hideLocationCreator,
  isEmployeeView,
}) => {
  const { plan } = useUserStore();

  const redirect = useRedirect();
  const locationName = location.name;
  const { isOpen, toggleDropdown, selectorTriggerRef, selectorRef } =
    useToggleShow();

  const handleLocationSettingClick = (e, locationID) => {
    e.stopPropagation();
    redirect({ pageName: PAGE_NAMES.location, locationID });
  };

  return (
    <div className={styles.container}>
      <div
        ref={selectorTriggerRef}
        className={styles.selectedDisplay}
        onClick={toggleDropdown}
        data-testid="location-selector"
      >
        <p className={styles.title}>{!locationName ? "" : locationName}</p>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronUp} className={styles.chevronIcon} />
        ) : (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={styles.chevronIcon}
          />
        )}
      </div>
      {isOpen && (
        <ul className={styles.selector} ref={selectorRef}>
          <li className={styles.wardSettingSelectionWrapper}>
            <span>Your locations</span>
          </li>
          {locations
            .filter((loc) => loc.name !== "Demo Location")
            .map((loc) => {
              const isCurrentLocation = loc.id === location.id;
              return (
                <li
                  key={loc.id}
                  className={`${styles.wardItem} ${
                    isCurrentLocation && styles.selectedWard
                  }`}
                  onClick={() => {
                    if (isEmployeeView) {
                      redirect({
                        pageName: PAGE_NAMES.desktopEmployeeViewSchedule,
                        query: {
                          "location-id": loc.id,
                        },
                      });
                      return;
                    }
                    if (loc.isScheduleView) {
                      redirect({
                        pageName: PAGE_NAMES.scheduleViewLocation,
                        query: {
                          "location-id": loc.id,
                        },
                      });
                      return;
                    }
                    redirect({
                      pageName: PAGE_NAMES.dashboard,
                      locationID: loc.id,
                    });
                  }}
                  data-testid="location-item"
                >
                  <div className={styles.wardImg}>{loc.name.charAt(0)}</div>
                  <span className={styles.locationName}>{loc.name}</span>
                  <FontAwesomeIcon
                    icon={faCog}
                    className={styles.settingsIcon}
                    onClick={(e) => handleLocationSettingClick(e, loc.id)}
                    data-testid="location-setup-btn"
                  />
                </li>
              );
            })}
          {hideLocationCreator ||
          isEmployeeView ||
          [PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan) ? (
            <div className={styles.spaceBottom}></div>
          ) : (
            <li
              className={styles.addNewWardItem}
              onClick={() => {
                toggleDropdown();
                toggleCreateLocationModal();
              }}
              data-testid="create-location-btn"
            >
              <div className={styles.addIconContainer}>
                <FontAwesomeIcon icon={faPlus} className={styles.addIcon} />
              </div>
              <span>Add a new location</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default LocationSelector;
