import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./TimesheetLocked.module.css";
import timesheetImg from "../../../../assets/images/timesheet-image.png";

const TimesheetLocked = () => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <h1 className={styles.title}>Timesheet</h1>
      <div className={styles.imgContainer}>
        <img className={styles.img} alt="timesheet view" src={timesheetImg} />
      </div>
      <UpgradeBanner
        title={"Unlock Timesheet with RosterLab AI plan!"}
        descriptions={[
          "Transfer to digital timesheet today. Manage your daily changes in a simpler way!",
        ]}
        showBookDemoButton={true}
      />
    </div>
  </div>
);

export default TimesheetLocked;
