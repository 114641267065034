import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  arraysHaveSameContent,
  hasCommonItems,
  strToArrCommaSeparated,
} from "../utils";
import {
  getLocationAreaFiltersSettings,
  useSettingsModelQuery,
} from "./modelQueryHooks/useSettingsModelQuery";
import { useSettingsModelMutation } from "./modelQueryHooks/useSettingsModelMutation";
import { KEYWORD_ALL } from "../constants/keywords";

function applyAgGridFilter(gridApis) {
  for (const gridApi of gridApis) {
    if (!gridApi) {
      continue;
    }

    if (!gridApi.destroyCalled) {
      gridApi.onFilterChanged();
    }
  }
}

/**
 * How this works:
 * gridApi.onFilterChanged() need to run to update the filtered value to AgGrid. (it re-runs doesAreaFilterPass and isExternalFilterPresent)
 */

export function useAreaFilter(gridApis, locationID, columnKey = "areas") {
  const { settings, isLoading } = useSettingsModelQuery();
  const { updateAreaFilterInSettings } = useSettingsModelMutation();

  const isFilterActive = useRef(false); // If true, filter can be applied to AgGrid
  const filteredAreas = useRef([KEYWORD_ALL]); // filtered areas that will be displayed in AgGrid
  const cachedFilteredAreas = useRef([KEYWORD_ALL]); // Cached filtered area in Tanstack Query
  const hasFilteredOnMount = useRef(false);

  const areaFilter = useMemo(() => {
    return settings
      ? getLocationAreaFiltersSettings(settings, locationID)
      : cachedFilteredAreas.current;
  }, [locationID, settings]);

  const updateAgGridFilterStatus = useCallback((filteredValues) => {
    filteredAreas.current = filteredValues;
    isFilterActive.current =
      filteredValues.length > 0 && !filteredValues.includes(KEYWORD_ALL);
  }, []);

  useEffect(() => {
    const isGridApisLoading = gridApis.some(
      (gridApi) => Boolean(gridApi) === false
    );
    if (!settings || isLoading || isGridApisLoading) {
      return;
    }

    const loadedAreaFilters = getLocationAreaFiltersSettings(
      settings,
      locationID
    );
    if (!loadedAreaFilters) {
      return;
    }
    cachedFilteredAreas.current = loadedAreaFilters;

    updateAgGridFilterStatus(loadedAreaFilters);
    if (!hasFilteredOnMount.current) {
      hasFilteredOnMount.current = true;
      applyAgGridFilter(gridApis);
    }
  }, [gridApis, locationID, isLoading, settings, updateAgGridFilterStatus]);

  const saveAreaFilter = useCallback(() => {
    // Do not update if filter not changed
    const hasFilterUpdated = !arraysHaveSameContent(
      cachedFilteredAreas.current,
      filteredAreas.current
    );

    if (!hasFilterUpdated) {
      return;
    }

    cachedFilteredAreas.current = filteredAreas.current;
    updateAreaFilterInSettings(locationID, filteredAreas.current);
  }, [locationID, updateAreaFilterInSettings]);

  const onAreaFilterChanged = useCallback(
    (filteredValues) => {
      updateAgGridFilterStatus(filteredValues);
      applyAgGridFilter(gridApis);
    },
    [gridApis, updateAgGridFilterStatus]
  );

  const doesAreaFilterPass = useCallback(
    (node) => {
      const data = node.data;
      const areasInNode = strToArrCommaSeparated(data[columnKey]);

      if (
        filteredAreas.current.length === 0 ||
        areasInNode.includes(KEYWORD_ALL)
      ) {
        return true;
      }
      return hasCommonItems(areasInNode, filteredAreas.current);
    },
    [columnKey]
  );

  const isExternalFilterPresent = useCallback(() => isFilterActive.current, []);

  return {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue: areaFilter,
  };
}
