import Survey from "../../../components/Survey/Survey";
import EmployeeAppMobileWrapper from "../EmployeeAppMobileWrapper/EmployeeAppMobileWrapper";
import styles from "./MobileSurvey.module.css";

export default function MobileSurvey({
  location,
  setContentViewType,
  setSelectedDate,
  currentViewData,
  userEmployee,
  customKeywordsUtilObj,
}) {
  return (
    <div className={styles.container}>
      <EmployeeAppMobileWrapper
        switchableContentViewTypes={[]}
        toggleCalendarViewType={() => {}}
        setContentViewType={setContentViewType}
        setSelectedDate={setSelectedDate}
        currentViewData={currentViewData}
        shouldShowCalendarTypeSwitcher={false}
        location={location}
      >
        <div className={styles.surveyContainer}>
          <Survey
            userEmployee={userEmployee}
            customKeywordsUtilObj={customKeywordsUtilObj}
          />
        </div>
      </EmployeeAppMobileWrapper>
    </div>
  );
}
