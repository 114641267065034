import { useMemo } from "react";
import styles from "./ScheduleDemandsDataContainer.module.css";
import { TABLE_NAMES } from "../../../../constants";
import {
  changeDemandsLengthToSpecificPeriod,
  getAllDemandsFromGrid,
  getDataInRows,
  getDemandsPeriodSelection,
  parseDemandModelToDemandsGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import DemandsGrid from "../../../rosterProblems/components/Demands/DemandsGrid/DemandsGrid";
import { getNewDemandsTemplate } from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { frontendSettingsToSolverSettings } from "../../../rosterGenerator/service/generateRoster";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";

const ScheduleDemandsDataContainer = ({
  locationID,
  periodStartDate,
  periodNum,
  isRoster,
  rosterID,
  customKeywordsData,
  location,
  periodFinishDate,
  globalEmployees,
}) => {
  const { plan } = useUserStore();

  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });
  const {
    name,
    demands,
    tasks,
    taskBlocks,
    shifts,
    shiftGroups,
    numDays,
    startDate,
    skills,
    subTasks,
    isSnapshot,
    areas,
  } = fields;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  const { warnings } = useWarningsStore();

  const isMainStream = !isRoster && !isSnapshot;

  const shiftTaskDemandsOnly = location.frontendSettings.find(
    (setting) => setting.name === "shiftTaskOnlyDemands"
  );

  const applyDemandUpdates = (updatedDemands) => {
    const updatedRoster = { ...roster, Demands: updatedDemands };
    updateFields(["Demands"], updatedRoster, roster, []);
  };

  const demandsData = useMemo(
    () => parseDemandModelToDemandsGridFormat(demands),
    [demands]
  );

  const demandsWarnings = useMemo(() => {
    return warnings ? warnings.Demands : null;
  }, [warnings]);

  const numDemandValues = demands.length > 0 ? demands[0].values.length : 0;
  const periodSelection = getDemandsPeriodSelection(numDemandValues);

  const addNewDemands = (numItems) => {
    const demandValuesLength =
      demands.length > 0 ? demands[0].values.length : 7;
    addNewModels(
      null,
      false,
      (options) =>
        getNewDemandsTemplate({
          ...options,
          values: Array(demandValuesLength).fill(1),
        }),
      numItems,
      demands,
      applyDemandUpdates,
      false
    );
  };
  const updateDemands = (newGridContent) =>
    updateModels(newGridContent, demands, applyDemandUpdates, false);
  const duplicateDemands = (selectedDemandsInfo) =>
    duplicateModels(
      selectedDemandsInfo,
      [],
      demands,
      applyDemandUpdates,
      null,
      null,
      false
    );
  const removeDemands = (toBeDeletedItems) => {
    removeModels(toBeDeletedItems, demands, applyDemandUpdates, false);
  };
  const reorderDemandsData = (params) =>
    reorderModelsData(
      params,
      getAllDemandsFromGrid,
      demands,
      applyDemandUpdates
    );

  const triggerUpdateFromPeriodChange = async (customPeriodSelection) => {
    const oldDemands = getAllDemandsFromGrid(gridApi, numDays);
    const newDemands = changeDemandsLengthToSpecificPeriod(
      oldDemands,
      customPeriodSelection,
      numDays,
      location.startDate,
      startDate,
      location.defaultNumDays
    );

    applyDemandUpdates(newDemands, false);
  };

  const getDataFromGrid = (gridApi) => getAllDemandsFromGrid(gridApi, numDays);

  const TopControllerComponent = (
    <div className={styles.topComponents}>
      <AreaFilter
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        onMenuClose={saveAreaFilter}
        defaultValue={initialAreaFilterValue}
      />
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"demands"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewDemands}
      updateItemsToDB={updateDemands}
      duplicateItemsToDB={duplicateDemands}
      removeItemsFromDB={removeDemands}
      getDataFromGrid={getDataFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
      undoRedoParams={{
        tableName: TABLE_NAMES.ROSTER_DEMANDS,
        getCustomGridSnapshot: null,
      }}
    >
      <DemandsGrid
        demandsData={demandsData}
        numDays={numDays}
        location={location}
        numDemands={demands.length}
        isSaving={isSaving}
        skills={skills}
        tasks={tasks}
        taskBlocks={taskBlocks}
        shifts={shifts}
        shiftGroups={shiftGroups}
        roster={roster}
        startDate={startDate}
        setGridApiToParent={setGridApi}
        periodSelection={periodSelection}
        isGlobalDemand={false}
        demandsWarnings={demandsWarnings}
        gridApi={gridApi}
        reorderDemandsData={reorderDemandsData}
        triggerUpdateFromPeriodChange={triggerUpdateFromPeriodChange}
        customTopComponent={TopControllerComponent}
        subTasks={subTasks}
        customKeywordsData={customKeywordsData}
        locationSettings={[
          ...location.settings,
          ...frontendSettingsToSolverSettings(location.frontendSettings),
        ]}
        shiftTaskDemandsOnly={shiftTaskDemandsOnly}
        areas={areas}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        isScheduleView={!isRoster}
        rosterName={name}
      />
    </GridActionHandler>
  );
};

export default ScheduleDemandsDataContainer;
