import styles from "../eula/EndUserLicenseAgreement.module.css";
import logo from "../../../../assets/images/RLlogo.png";

function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <div className={styles.centerLogo}>
        <img src={logo} className={styles.logo} alt="RosterLab logo"></img>
      </div>
      <>
        <h1
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
        >
          RosterLab - Privacy Policy
        </h1>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          RosterLab is committed to protecting and respecting your privacy
          whenever you engage with us.
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          This Privacy Policy outlines what personal information we collect, how
          we store, use and share it, and how you can access or correct your
          personal information.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We may update this Privacy Policy from time to time by placing an
          amended version of the Privacy Policy on our website(s).
        </p>
        <h2
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Who we are
        </h2>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          RosterLab is a provider of world leading A.I. technology and software
          that allows its customers to create rostering solutions. When this
          Privacy Policy uses the terms &quot;<b>RosterLab</b>&quot;, &quot;
          <b>we</b>,&quot; &quot;
          <b>our</b>,&quot; or &quot;<b>us</b>
          &quot;, we mean RosterLab Limited. When we say &quot;<b>you</b>&quot;
          or &quot;<b>your</b>
          &quot;, we mean our customers, website visitors, guests, clients or
          consumers.
        </p>
        <h2
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          How we collect and use your information
        </h2>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          The information we may collect about you includes any personal
          information you provide to us when you access our website or use our
          services (for example, your name, profession, organisation details,
          location, contact details, contact request message, and activity logs
          from our website and services). We may also receive personal
          information about you from other users of our software.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We may access, process and/or disclose personal information about you
          or that you provide to us in order to: (a) provide you with our
          services or perform a contract with you; (b) analyse and monitor use
          of our services; (c) inform you of any products, software, services or
          information that we believe you may be interested in; (d) comply with
          the law; (e) protect our rights, property, or the safety of our
          employees, customers or the public; and/or (f) for any other purpose
          we notify to you or that you have otherwise authorised.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Your personal information belongs to you and we will not sell it to
          any third party.
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We will keep any personal data you provide to us confidential and will
          not disclose it to anyone other than our authorised employees, agents,
          subcontractors, officers and directors (or other persons who you have
          authorised to access your information).
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We may disclose and share your personal information when we believe in
          good faith that disclosure is necessary or required by law or
          regulation.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We will retain your personal information for as long as you are using
          our services or have a relationship with us, and for a period of time
          afterwards where we have an ongoing business need to retain it.
        </p>
        <h2
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Cookies
        </h2>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We also collect information when you navigate through our websites and
          services, including what pages you loaded and which links you clicked
          on. This information is used to help us understand how you are using
          our websites and services in order to improve user experience. Some of
          this information is collected using cookies. We use cookies to learn
          more about the way you interact with our content and help us improve
          your experience of using our website.
        </p>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "justify",
          }}
        >
          With most internet browsers, you can erase cookies from your computer
          hard drive, block all cookies, or receive a warning before a cookie is
          stored. If you reject the use of cookies, you will still be able to
          access our website but some of its functions may not work as well.
        </p>
        <h2
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Links to other sites
        </h2>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          We may provide links on our website to other websites. If you use
          these links, you will leave our website and we are not responsible for
          any third party websites, their content or their usage of your
          personal information.
        </p>
        <h2
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Your rights
        </h2>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          You have a right to ask for a copy of any personal information we hold
          about you, and to ask for it to be corrected if you think it is wrong.
          To ask for a copy of your personal information, or to have it
          corrected or deleted from our records, or if you have any questions or
          concerns about the personal information that we hold about you, please
          contact us at{" "}
          <a href="mailto:admin@rosterlab.com" className="a">
            admin@rosterlab.com
          </a>
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          You can choose to not provide us with your personal information or to
          limit the personal information you provide. Where you do this, you may
          not be able to use certain functionality of our website or services.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}
        >
          Depending on your communications preferences, we may send you
          electronic communications for marketing purposes. You can always “opt
          out” of receiving these electronic communications by clicking on the
          “unsubscribe” link at the bottom of any such electronic communication,
          or by contacting us at{" "}
          <a href="mailto:admin@rosterlab.com" className="a">
            admin@rosterlab.com
          </a>
        </p>
      </>
    </div>
  );
}

export default PrivacyPolicy;
