let queue = [];
let isProcessing = false;
let currentProcessPromise = null; // A promise that resolves when all tasks in queue have been processed.

export async function emptyQueue() {
  queue = [];
  if (isProcessing) {
    await currentProcessPromise();
  }
  isProcessing = false;
}

export async function queueTask(task, ...args) {
  const wrappedTask = () => task(...args);

  // Push the task into the queue
  queue.push(wrappedTask);

  // If there's another task already processing, wait until it finishes
  if (isProcessing) {
    await currentProcessPromise; // Wait until all tasks in queue have been processed.
    return;
  }

  // Otherwise, start processing tasks
  isProcessing = true;
  currentProcessPromise = (async () => {
    while (queue.length > 0) {
      const currentTask = queue.shift();
      try {
        await currentTask();
      } catch (err) {
        console.error(err);
        // handle error, retry current task or skip depending on your use-case.
      }
    }
    isProcessing = false;
  })();
  await currentProcessPromise; // Wait until all tasks in queue have been processed.
}

export function getTaskQueueInfo() {
  return {
    queue,
    length: queue.length,
    isProcessing,
  };
}
