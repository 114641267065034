import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

const sendToast = (message, isLonger) => {
  if (isLonger) {
    toast.error(message, { duration: 6000 });
  } else {
    toast.error(message);
  }
};

function useOfflineNotification() {
  const [offlineStartTime, setOfflineStartTime] = useState(null);
  const [timeouts, setTimeouts] = useState([]);

  const clearAllTimeouts = useCallback(() => {
    timeouts.forEach(clearTimeout);
    setTimeouts([]);
  }, [timeouts]);

  const setNotificationTimeout = useCallback(
    (timeInSeconds, isLonger = false) => {
      const timeInMs = timeInSeconds * 1000;
      const timeOffline = offlineStartTime ? Date.now() - offlineStartTime : 0;
      const timeRemaining = timeInMs - timeOffline;

      const message = `You have been offline for more than ${timeInSeconds} seconds. Ensure you go online if you have made any changes so they are saved.`;

      if (timeRemaining > 0) {
        const timeoutId = setTimeout(() => {
          sendToast(message, isLonger);
        }, timeRemaining);
        setTimeouts((prevTimeouts) => [...prevTimeouts, timeoutId]);
      } else {
        sendToast(message, isLonger);
      }
    },
    [offlineStartTime]
  );

  useEffect(() => {
    const setOnline = () => {
      setOfflineStartTime(null);
      clearAllTimeouts();
      toast.dismiss(); // Optionally, you can also dismiss all active toasts when the user comes back online
    };

    const setOffline = () => {
      setOfflineStartTime(Date.now());
    };

    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, [clearAllTimeouts]);

  useEffect(() => {
    if (offlineStartTime != null) {
      [10, 60].forEach((seconds) => {
        setNotificationTimeout(seconds, true);
      });
    }
  }, [offlineStartTime, setNotificationTimeout]);

  return { offlineStartTime };
}

export default useOfflineNotification;
