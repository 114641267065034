import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./SolutionsLocked.module.css";
import demandsGraph from "../../../../assets/images/demandsGraph.png";

const SolutionsLocked = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>A.I Solutions</h1>
    <UpgradeBanner
      title={"Unlock A.I. Solutions with RosterLab AI plans!"}
      descriptions={[
        "Tired of manual rostering? Wish to have your rosters be generated for you within minutes?",
        "RosterLab has developed a world-class AI and is ready to reinvent the way you roster. Our unique algorithms can handle all kinds of complexities within your roster.",
      ]}
      showUpgradeButton={true}
      showBookDemoButton={true}
    />
    <div className={styles.aboutDemandsContainer}>
      <p className={styles.aboutDemands}>
        With A.I. Solutions, we provide a clear view based on your shift demands
        for your AI solutions.
      </p>
      <div className={styles.demandsImgContainer}>
        <img
          src={demandsGraph}
          className={styles.demandsImg}
          alt="demands statistics view"
        />
      </div>
    </div>
  </div>
);

export default SolutionsLocked;
