import styles from "./SolutionGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useSolutionsColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import GoToRosterButton from "../GoToRosterButton/GoToRosterButton";
import { useMemo, useState } from "react";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import {
  createBasicContextMenu,
  DateTime,
  getDatesColumnDefs,
  getDayNumFromColFieldName,
  getMergedOverviewAndRecurring,
  onFilterTextBoxChanged,
  suppressDeleteAndBackspaceKey,
} from "../../../../../utils";
import { preferenceOrFixedShiftMet } from "../../../service/preferencesAndFixedShifts";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import WhyNotThisAllocationModal from "../WhyNotThisAllocation/WhyNotThisAllocation";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import CompareSolutionsToggle from "../CompareSolutionsToggle/CompareSolutionsToggle";
import { ShiftViewSwitcher } from "../../../../scheduleView/components/ScheduleRosterDataContainer/ScheduleRosterDataContainerUtils";
import RosterTableShiftView from "../../../rosteredAllocations/components/RosterTableShiftView/RosterTableShiftView";
import { interpretCustomKeywordsData } from "../../../../../utils/queryUtils/locationDataGetters";
import RerosteringToolTip from "../../../../../components/elements/RerosteringToolTip/RerosteringToolTip";
import { buildNamesToEntityShortIdsDicts } from "../../../service/rosterUtils";
import { convertAllocationInNameFormToShortIdForm } from "../../../../../utils/modelUtils/allocation";
import { KEYWORD_OFF } from "../../../../../constants/keywords";
import { frontendSettingsToSolverSettings } from "../../../../rosterGenerator/service/generateRoster";

const SolutionGrid = ({
  locationID,
  startDate,
  solutionData,
  failedEmployeeData,
  employees,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  skills,
  setGridApiToParent,
  solutionColumns,
  gridApi,
  getSolutionsList,
  getDeleteSolutionBtn,
  getPrintSolutionBtns,
  longestStr,
  selectedSolutionData,
  colorCodes,
  sendSolutionToMyRoster,
  exportToCsv,
  exportToExcel,
  customTopComponent = null,
  isScheduleView,
  roster,
  customKeywordsData,
  comparisonData,
  isShiftView,
  toggleShiftView,
  rules,
  location,
  periodStartDate,
  periodFinishDate,
  isMainStream,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  rosterName,
}) => {
  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const namesToEntityShortIdsDicts = buildNamesToEntityShortIdsDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const shiftViewFormattedEmployees = useMemo(() => {
    return solutionData.map((empData) => {
      const employee = employees.find((emp) => emp.id === empData.id);
      const publishedAllocationFormatData = [];
      const rosteredAllocationFormatData = [];
      for (let i = 0; i < roster.numDays; i++) {
        const allocation = empData[`d${i + 1}`];
        rosteredAllocationFormatData.push(allocation);
        publishedAllocationFormatData.push({
          date: new DateTime(location.startDate).addDays(i).toFormat("AWS"),
          publishedAllocation: allocation,
        });
      }

      return {
        ...employee,
        RosteredAllocations: rosteredAllocationFormatData,
        PublishedAllocations: publishedAllocationFormatData,
      };
    });
  }, [employees, solutionData, roster, location]);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useSolutionsColumnWidthStore();

  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const mergedFixedShifts = useMemo(
    () =>
      employees.map((employee) =>
        getMergedOverviewAndRecurring(
          employee["Allocations"],
          employee["AllocationsRecurring"],
          location.startDate,
          startDate,
          location.defaultNumDays
        )
      ),
    [employees, location, startDate]
  );

  const mergedPreferences = useMemo(
    () =>
      employees.map((employee) =>
        getMergedOverviewAndRecurring(
          employee["Days"],
          employee["DaysRecurring"],
          location.startDate,
          startDate,
          location.defaultNumDays
        )
      ),
    [employees, location, startDate]
  );

  const datesColumnDefs = useMemo(() => {
    let datesColumnDefs = getDatesColumnDefs(solutionColumns.length, startDate);
    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekGroupIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayGroupIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              const offset = getDayNumFromColFieldName(colDef.field);
              const DoW = new DateTime(startDate)
                .addDays(offset)
                .getDayOfWeek("dd");

              return {
                ...colDef,
                editable: false,
                cellClassRules: {
                  weekends: getWeekendsCellStyle,
                  "met-fixed-shift": (params) => {
                    const employee = employees.filter(
                      (emp) => emp.id === params.data.id
                    );

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    const solutionFailed =
                      Object.entries(params.data).filter((val) =>
                        val[1].endsWith("*")
                      ).length > 0;

                    if (params.value === undefined) {
                      return false;
                    }

                    const shortId = convertAllocationInNameFormToShortIdForm(
                      params.value,
                      namesToEntityShortIdsDicts
                    );

                    return (
                      employee.length > 0 &&
                      !solutionFailed &&
                      preferenceOrFixedShiftMet(
                        mergedFixedShifts[employees.indexOf(employee[0])],
                        d,
                        shortId ? shortId : params.value,
                        areas,
                        shifts,
                        shiftGroups,
                        tasks,
                        taskBlocks,
                        employee[0].skills,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      )
                    );
                  },
                  "met-preference": (params) => {
                    const employee = employees.filter(
                      (emp) => emp.id === params.data.id
                    );

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    const notRelevant = ["name", "skills", "id"];
                    const filtered = Object.keys(params.data)
                      .filter((key) => !notRelevant.includes(key))
                      .reduce((obj, key) => {
                        obj[key] = params.data[key];
                        return obj;
                      }, {});

                    const solutionFailed =
                      Object.entries(filtered).filter((val) =>
                        val[1].endsWith("*")
                      ).length > 0;

                    if (params.value === undefined) {
                      return false;
                    }

                    return (
                      employee.length > 0 &&
                      !solutionFailed &&
                      preferenceOrFixedShiftMet(
                        mergedPreferences[employees.indexOf(employee[0])],
                        d,
                        params.value,
                        areas,
                        shifts,
                        shiftGroups,
                        tasks,
                        taskBlocks,
                        employee[0].skills,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      )
                    );
                  },
                  "failed-shift": (params) => {
                    return params.value && params.value.endsWith("*");
                  },
                  "reroster-change": (params) => {
                    if (!comparisonData) return false;

                    const employeeDifferences = comparisonData.filter(
                      (emp) => emp.id === params.data.id
                    )[0];

                    if (!employeeDifferences) return false;

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    return employeeDifferences.days[d].isDifferent;
                  },
                },
                cellStyle: (params) => {
                  let style = {};
                  const colorCodeParams = { ...params };
                  colorCodeParams.value =
                    convertAllocationInNameFormToShortIdForm(
                      params.value,
                      namesToEntityShortIdsDicts
                    ) || params.value;

                  style = {
                    ...style,
                    ...getColorCodedCells(
                      colorCodeParams,
                      colorCodes,
                      shiftGroups,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  };
                  return style;
                },
                tooltipComponent: "tooltipComponent",
                tooltipComponentParams: {
                  comparisonData,
                },
                tooltipValueGetter: (params) =>
                  params.value ? params.value : KEYWORD_OFF,
                suppressMenu: true,
                suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
                width:
                  DoW === "Sa" || DoW === "Su"
                    ? weekendColWidth
                    : weekdayColWidth,
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayGroupIdx_${weekGroupIdx}_${dayGroupIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekGroupIdx}`,
          children: customWeekColDefs,
        };
      }
    );
    return customDatesColumnDefs;
  }, [
    employees,
    areas,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    colorCodes,
    solutionColumns.length,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    comparisonData,
    mergedFixedShifts,
    mergedPreferences,
    namesToEntityShortIdsDicts,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
  ]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Employees",
        groupId: "employeeGroup",
        children: [
          {
            field: "name",
            sortable: true,
            editable: false,
            width: 150,
            pinned: "left",
          },
        ],
      },
      ...datesColumnDefs,
    ],
    [datesColumnDefs]
  );

  const customMoreOptionComponents = getPrintSolutionBtns();

  const rerosterSolution = () =>
    comparisonData && <CompareSolutionsToggle allowSwitch={false} />;

  return (
    <Layout
      title={
        isScheduleView ? "Roster solution" : `Roster solution - ${rosterName}`
      }
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/ai-generate-solutions"}
        />
      )}
      headerRight={
        isScheduleView
          ? null
          : () => (
              <GoToRosterButton
                selectedSolutionData={selectedSolutionData}
                employees={employees}
                handleSendSolution={sendSolutionToMyRoster}
              />
            )
      }
    >
      {modalIsOpen && (
        <WhyNotThisAllocationModal
          show={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          employees={employees}
          shifts={shifts}
          tasks={tasks}
          taskBlocks={taskBlocks}
          numDays={roster.numDays}
          roster={roster}
          customKeywordsData={customKeywordsData}
          shiftGroups={shiftGroups}
          locationSettings={[
            ...location.settings,
            ...frontendSettingsToSolverSettings(location.frontendSettings),
          ]}
          areas={areas}
        />
      )}
      <div className={styles.container}>
        <div className={styles.topLine}>
          <div className={styles.left}>
            {customTopComponent}
            {isScheduleView &&
              false && ( // I'm removing this as an option until it's fixed
                <ShiftViewSwitcher
                  isShiftView={isShiftView}
                  setShowShiftView={toggleShiftView}
                  updateDefaultPageView={false}
                />
              )}
          </div>
          <div className={styles.right}>
            <>
              {!isShiftView ? (
                <BasicButton
                  color="#219ec9"
                  hoverColor="#1f91b7"
                  customStyle={{
                    borderRadius: "10px",
                  }}
                  onClick={() => setModalIsOpen(true)}
                >
                  Why not this allocation?
                </BasicButton>
              ) : (
                <></>
              )}
              {!isShiftView && isScheduleView && (
                <>
                  <GoToRosterButton
                    selectedSolutionData={selectedSolutionData}
                    employees={employees}
                    handleSendSolution={sendSolutionToMyRoster}
                    periodStartDate={periodStartDate}
                    periodFinishDate={periodFinishDate}
                    isMainStream={isMainStream}
                  />
                </>
              )}
            </>
          </div>
        </div>
        {isShiftView ? (
          <>
            <RosterTableShiftView
              locationID={null}
              rosterID={null}
              isScheduleView={isScheduleView}
              demands={selectedSolutionData.demands}
              employees={shiftViewFormattedEmployees}
              numDays={roster.numDays}
              areas={areas}
              shifts={shifts}
              shiftGroups={shiftGroups}
              tasks={tasks}
              taskBlocks={taskBlocks}
              rules={rules}
              updateRosterTable={() => {}}
              startDate={startDate}
              locationStartDate={location.startDate}
              customKeywordsDict={customKeywordsUtilObj}
              predefinedShiftOptions={
                customKeywordsUtilObj.predefinedShiftOptions
              }
              toggleColorCodingModal={() => {}}
              colorCodes={colorCodes}
              readOnly={true}
              hiddenRows={[]}
              setHiddenShiftViewRows={() => {}}
              shouldDefaultViewIncludeToday={false}
              shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            />
          </>
        ) : (
          <>
            <ActionBar
              locationID={locationID}
              searchBarSettings={{
                tableName: "solutions",
                onFilterInputChanged,
              }}
              adjustWidthSettings={{
                longestStr,
                weekdayColWidth,
                weekendColWidth,
                isWDayWEndSeparate,
                setWeekdayColWidth,
                setWeekendColWidth,
                setIsWDayWEndSeparate,
                tableName: "solutions",
              }}
              customComponents={[
                getSolutionsList(),
                getDeleteSolutionBtn(),
                rerosterSolution,
              ]}
              exportSettings={{
                exportToCsv,
                exportToExcel,
              }}
              customMoreOptionComponents={
                customMoreOptionComponents ? customMoreOptionComponents : []
              }
            />
            <DataEntryTable
              columnDefs={columnDefs}
              rowData={solutionData}
              columnHoverHighlight={true}
              getContextMenuItems={getContextMenuItems}
              setGridApiToParent={setGridApiToParent}
              tooltipShowDelay={0}
              tooltipHideDelay={null}
              components={{
                tooltipComponent: RerosteringToolTip,
              }}
              tableName="solutions"
              doesExternalFilterPass={doesAreaFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
            />
            <div className={styles.solutionKey}>
              {failedEmployeeData &&
              Object.keys(failedEmployeeData.issues).length > 0 ? (
                <div className={styles.failedShiftsKey}>
                  Fixed shifts that can&apos;t be satisfied
                </div>
              ) : (
                <>
                  <div className={styles.fixedShiftsKey}>
                    Fulfilled Fixed Shifts
                  </div>
                  <div className={styles.preferencesKey}>
                    Fulfilled Preferences
                  </div>
                </>
              )}
            </div>
            {selectedSolutionData.comments && (
              <div>{selectedSolutionData.comments}</div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default SolutionGrid;
