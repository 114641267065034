import { useMemo, useState } from "react";
import styles from "./ScheduleEmployeesDataContainer.module.css";
import {
  getAllGlobalEmployeesFromGrid,
  getIds,
  getNextDefaultEntityNameNumber,
  getRecurringEmployeeFieldLength,
  getSelectedRowIDs,
  parseScheduleEmployeeObjectsToEmployeesGridFormat,
} from "../../../../utils";
import { useEmployeesAccess } from "../../../auth/service/useEmployeesAccess";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import GlobalEmployeesGrid from "../../../locations/components/GlobalEmployees/GlobalEmployeesGrid/GlobalEmployeesGrid";
import { getDuplicatedEntities } from "../../../../utils/queryUtils/sharedModelDataGetters";
import { getNewScheduleEmployeesTemplate } from "../../../../utils/queryUtils/scheduleDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import { addNewModels } from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import { employeeNeedsNAFixedShitsUpdates } from "../../../rosterProblems/service/fixRoster";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { ONBOARDING_TASK_ID } from "../../../onBoarding/service/onBoarding";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import {
  checkTrialIsInProgress,
  useUserStore,
} from "../../../../globalStore/appStore";
import {
  checkMidTierEmployeeRestriction,
  handleBillingRedirection,
} from "../EmployeeNumberLimitModal/EmployeeNumberLimitModal";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { PLAN } from "../../../auth/service/auth";
import { getNewSkillsTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";

const ScheduleEmployeesDataContainer = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  globalEmployees,
  redirectToIndividualPage,
}) => {
  const { totalGlobalEmployees, maxGlobalEmployees, trialEnd, plan } =
    useUserStore();
  const [columnApi, setColumnApi] = useState(null);
  const { markOnboardingTasksAsCompleted } = useLocationMutation(location);
  const { warnings } = useWarningsStore();

  const isTrialInProgress = checkTrialIsInProgress(trialEnd);

  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
    createScheduleEmployees,
    deleteScheduleEmployees,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  const {
    employees,
    skills,
    tasks,
    shifts,
    shiftGroups,
    areas,
    subTasks,
    numDays,
    name: locationName,
    rules,
    isSnapshot,
  } = fields;

  const isMainStream = !isRoster && !isSnapshot;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const applySkillUpdates = (updatedSkills) => {
    const updatedRoster = { ...roster, Skills: updatedSkills };
    updateFields(["Skills"], updatedRoster, roster);
  };

  const {
    employeesAuthInfo,
    processingUsers,
    handleInvitations,
    handleDeactivations,
    handleResendInvitations,
  } = useEmployeesAccess(locationID);

  const employeesData = useMemo(() => {
    return parseScheduleEmployeeObjectsToEmployeesGridFormat(
      employees,
      employeesAuthInfo,
      processingUsers
    );
  }, [employees, employeesAuthInfo, processingUsers]);

  const employeesWarnings = useMemo(() => {
    return warnings ? warnings.Employees : null;
  }, [warnings]);

  const addNewEmployees = async (numItems) => {
    if ([PLAN.COORDINATOR].includes(plan)) {
      return;
    }

    const resultingEmployeesNumberAfterAdd = totalGlobalEmployees + numItems;
    const extraEmployeesToAdd =
      resultingEmployeesNumberAfterAdd - maxGlobalEmployees;

    if (
      checkMidTierEmployeeRestriction(
        plan,
        isTrialInProgress,
        resultingEmployeesNumberAfterAdd,
        maxGlobalEmployees
      )
    ) {
      if (maxGlobalEmployees === null) {
        return;
      }

      handleBillingRedirection(
        resultingEmployeesNumberAfterAdd,
        extraEmployeesToAdd,
        maxGlobalEmployees
      );
      return;
    }
    const allocationsRecurringLength = getRecurringEmployeeFieldLength(
      employees,
      "AllocationsRecurring"
    );
    const daysRecurringLength = getRecurringEmployeeFieldLength(
      employees,
      "DaysRecurring"
    );
    const employeeNamesInRoster = employees.map((emp) => emp.name);

    const name = "New Employee";
    const nextDefaultEntityNameNumber = getNextDefaultEntityNameNumber(
      name,
      employeeNamesInRoster
    );

    const newScheduleEmployees = getNewScheduleEmployeesTemplate({
      locationID,
      locationName,
      numItems,
      name,
      nextNumberedSuffix: nextDefaultEntityNameNumber,
      allocationsRecurringLength,
      daysRecurringLength,
      numDays,
      rules,
      startDate: periodStartDate,
    });

    const newEmployeeIDs = getIds(newScheduleEmployees);
    const updatedRoster = {
      ...roster,
      Employees: [...employees, ...newScheduleEmployees],
      order: [...roster.order, ...newEmployeeIDs],
    };

    createScheduleEmployees(updatedRoster, newScheduleEmployees);
  };

  const removeRegistrations = async (employeeIDs) => {
    const updatedEmployees = employees.map((employee) => {
      if (employeeIDs.includes(employee.id)) {
        return {
          ...employee,
          registrations: [],
        };
      }
      return employee;
    });
    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };
    const updatedFields = ["registrations"];
    updateFields(["Employees"], updatedRoster, roster, updatedFields);
  };

  const updateEmployees = async (newEmployees) => {
    let updatedEmployees = employees.map((employee) => {
      const targetEmployee = newEmployees.find((e) => e.id === employee.id);
      if (!targetEmployee) {
        return employee;
      }

      return {
        id: employee.id,
        ...employee,
        ...targetEmployee,
      };
    });

    const employeeIDsToSkipFixedShiftsUpdate = [];

    employees.forEach((employee) => {
      const targetUpdatedEmployee = updatedEmployees.find(
        (item) => item.id === employee.id
      );
      if (
        employee.startDate === targetUpdatedEmployee.startDate &&
        employee.finishDate === targetUpdatedEmployee.finishDate
      ) {
        employeeIDsToSkipFixedShiftsUpdate.push(targetUpdatedEmployee.id);
      }
    });

    const {
      shouldUpdateEmployeeFixedShifts,
      updatedRoster: updatedRosterAfterFixedShiftsUpdate,
    } = employeeNeedsNAFixedShitsUpdates(
      roster,
      updatedEmployees,
      employeeIDsToSkipFixedShiftsUpdate
    );

    if (shouldUpdateEmployeeFixedShifts) {
      updatedEmployees = updatedEmployees.map((employee) => {
        const targetEmployeeInfo =
          updatedRosterAfterFixedShiftsUpdate.Employees.find(
            (item) => item.id === employee.id
          );
        if (!targetEmployeeInfo) {
          return employee;
        }
        return {
          ...employee,
          Allocations: targetEmployeeInfo.Allocations,
        };
      });
    }

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };

    const updatedFields = [
      "externalID",
      "FTE",
      "email",
      "finishDate",
      "name",
      "salary",
      "shifts",
      "skills",
      "startDate",
      "areas",
    ];

    if (shouldUpdateEmployeeFixedShifts) {
      updatedFields.push("Allocations");
    }

    updateFields(["Employees"], updatedRoster, roster, updatedFields);
  };

  const removeEmployees = async (toBeDeletedItems) => {
    const deletedEmployeeIDs = getIds(toBeDeletedItems);
    const updatedOrder = roster.order.filter(
      (item) => !deletedEmployeeIDs.includes(item)
    );
    const updatedOpenShifts = roster.OpenShifts
      ? roster.OpenShifts.map((openShift) => {
          const { employeeStates } = openShift;
          const updatedEmployeeStates = employeeStates.filter(
            (state) => !deletedEmployeeIDs.includes(state.employeeID)
          );
          return {
            ...openShift,
            employeeStates: updatedEmployeeStates,
          };
        })
      : [];
    const updatedRoster = {
      ...roster,
      order: updatedOrder,
      OpenShifts: updatedOpenShifts,
      Employees: employees.filter(
        (employee) => !deletedEmployeeIDs.includes(employee.id)
      ),
    };
    deleteScheduleEmployees(deletedEmployeeIDs, updatedRoster);
  };

  const duplicateEmployees = async (selectedEmployeeIDs) => {
    const duplicateEmployees = getDuplicatedEntities(
      employees,
      selectedEmployeeIDs
    );

    const allocationsRecurringLength = getRecurringEmployeeFieldLength(
      employees,
      "AllocationsRecurring"
    );
    const daysRecurringLength = getRecurringEmployeeFieldLength(
      employees,
      "DaysRecurring"
    );

    duplicateEmployees.forEach((emp) => {
      emp.name = `New Employee (based on ${emp.name})`;
      emp.PublishedAllocations = [];
      emp.Requests = [];
      emp.email = "";
      emp.TimeEntries = [];
      emp.globalEmployeeID = emp.id;
      emp.Preferences = [];
      emp.PreferencesRecurring = Array(daysRecurringLength).fill("");
      emp.Allocations = Array(numDays).fill("");
      emp.AllocationsRecurring = Array(allocationsRecurringLength).fill("");
      emp.startDate = periodStartDate || "1970-01-01";
      emp.finishDate = "2038-01-01";
      emp.History = Array(14).fill("");
      emp.externalID = "";
      emp.FTE = 0.0;
      emp.salary = 0.0;
    });
    const newEmployeeIDs = getIds(duplicateEmployees);
    const updatedRoster = {
      ...roster,
      Employees: [...employees, ...duplicateEmployees],
      order: [...roster.order, ...newEmployeeIDs],
    };
    createScheduleEmployees(updatedRoster, duplicateEmployees);
  };

  const reorderEmployeesData = async (params) => {
    const reorderedData = getAllGlobalEmployeesFromGrid(params.api);
    const newViewableEmployeesOrder = getIds(reorderedData);
    const oldOrder = roster.order;
    const updatedOrder = [
      ...newViewableEmployeesOrder,
      ...oldOrder.filter((order) => !newViewableEmployeesOrder.includes(order)),
    ];

    const updatedRoster = {
      ...roster,
      order: updatedOrder,
    };
    updateFields(["order"], updatedRoster, roster);
  };

  const addSkill = async (name) => {
    addNewModels(
      name,
      false,
      (option) => getNewSkillsTemplate({ ...option, existingSkills: skills }),
      1,
      skills,
      applySkillUpdates
    );
  };

  const TopControllerComponent = (
    <div className={styles.topComponents}>
      <AreaFilter
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        onMenuClose={saveAreaFilter}
        defaultValue={initialAreaFilterValue}
      />
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"employees"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  const inviteEmployees = async (selectedUsers) => {
    const results = await handleInvitations(selectedUsers);
    const { successfulUsers } = results;
    if (successfulUsers.length > 0) {
      markOnboardingTasksAsCompleted([ONBOARDING_TASK_ID.inviteEmployees]);
    }
    return results;
  };

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewEmployees}
      updateItemsToDB={updateEmployees}
      duplicateItemsToDB={duplicateEmployees}
      removeItemsFromDB={removeEmployees}
      getDataFromGrid={getAllGlobalEmployeesFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getSelectedRowIDs}
      removeItemsAlertMessage={{
        title: "Are you sure?",
        description: (
          <>
            This will delete the employee(s) everywhere in the system.
            <br />
            If you mean they are no longer employed, but would like to keep the
            history of their past shifts, please set a Finish Date instead.
          </>
        ),
      }}
    >
      <GlobalEmployeesGrid
        isSaving={isSaving}
        locationID={locationID}
        employeesData={employeesData}
        numEmps={employees.length}
        setGridApiToParent={setGridApi}
        addSkill={addSkill}
        employeesWarnings={employeesWarnings}
        gridApi={gridApi}
        reorderEmployeesData={reorderEmployeesData}
        handleInvitations={inviteEmployees}
        handleDeactivations={handleDeactivations}
        handleResendInvitations={handleResendInvitations}
        setColumnApiToParent={setColumnApi}
        columnApi={columnApi}
        periodStartDate={periodStartDate}
        customTopComponent={TopControllerComponent}
        removeRegistrations={removeRegistrations}
        globalEmployees={globalEmployees}
        isSnapshot={isSnapshot}
        redirectToIndividualPage={redirectToIndividualPage}
        shifts={shifts}
        shiftGroups={shiftGroups}
        skills={skills}
        areas={areas}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      />
    </GridActionHandler>
  );
};

export default ScheduleEmployeesDataContainer;
