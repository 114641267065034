import {
  getBrowserCachedAppVersion,
  setBrowserCachedAppVersion,
} from "../../localStorage/versionStorage";

const VERSION_FETCH_URL =
  window.location.hostname === "localhost"
    ? "/version.json"
    : `https://${window.location.host}/version.json`;

export const isNewerVersionAvailable = async () => {
  const currentVersion = getBrowserCachedAppVersion();

  let shouldUpdateVersion = false;
  try {
    const response = await fetch(VERSION_FETCH_URL); // Note the leading slash
    const fetchedVersion = await response.json();

    if (currentVersion === null) {
      shouldUpdateVersion = true;
    } else {
      shouldUpdateVersion = compareVersions(
        currentVersion.version,
        fetchedVersion.version
      );
    }

    if (shouldUpdateVersion) {
      setBrowserCachedAppVersion(fetchedVersion.version);
    }
    return shouldUpdateVersion;
  } catch (error) {
    console.error("Error checking version:", error);
    return false;
  }
};

const compareVersions = (v1, v2) => {
  return v1 !== v2;
};
