import styles from "./RulesTemplateViewer.module.css";
import ImmutableRuleBlock from "../ImmutableRuleBlock/ImmutableRuleBlock";

const RulesTemplateViewer = ({
  rulesList,
  rules,
  isRulesWithExceptionEnabled,
}) => {
  const ruleInputs = rulesList.map((ruleDescription) => {
    const rule = rules.find(
      (fullRule) => fullRule.name === ruleDescription.name
    );

    return (
      <ImmutableRuleBlock
        key={ruleDescription.id}
        rule={rule}
        ruleDescription={ruleDescription}
        isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
      />
    );
  });

  return <div className={styles.container}>{ruleInputs}</div>;
};

export default RulesTemplateViewer;
