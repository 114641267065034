import {
  faChevronLeft,
  faChevronRight,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarBlue from "../../../../../assets/images/darkBlueStar.svg";
import logo from "../../../../../assets/images/rosterlab-logo.png";
import { DateTime, getShiftDurationString } from "../../../../../utils";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import {
  getDefaultPreferenceState,
  getDisplayedOpenShiftInfo,
  isPreferenceSpecial,
} from "../../../service/employeeAppUtil";
import styles from "./MobileWeekView.module.css";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";

const getWeekViewStartDate = (selectedDate) => {
  if (selectedDate.getDayOfWeek() === 1) {
    return selectedDate;
  }
  return DateTime.getPreviousMonday(selectedDate);
};

const getDisplayedDates = (monday) => {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    dates.push(new DateTime(monday).addDays(i));
  }
  return dates;
};

const DateTopBar = ({ displayedDates, selectedDate, setSelectedDate }) => {
  const switchWeek = (isNext) => {
    const daysToAdd = isNext ? 7 : -7;
    setSelectedDate(new DateTime(selectedDate).addDays(daysToAdd));
  };

  return (
    <div className={styles.dateBar}>
      <button
        className={`${styles.switcher} ${styles.left}`}
        onClick={() => switchWeek(false)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {displayedDates.map((date) => {
        const isToday = date.toFormat("AWS") === new DateTime().toFormat("AWS");
        return (
          <div
            key={date.toFormat("AWS")}
            className={`${styles.dateCell} ${isToday && styles.today}`}
          >
            <p>{date.getDayOfWeek("ddd")}</p>
            <p>{date.toFormat("dd")}</p>
          </div>
        );
      })}
      <button
        className={`${styles.switcher} ${styles.right}`}
        onClick={() => switchWeek(true)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

const ScheduleWeekViewCell = ({ allocation, openShiftsOnDay, employeeID }) => {
  if (!allocation && openShiftsOnDay.length === 0) {
    return <div className={styles.cell}></div>; // This prevents flickering when changing date by clicking another cell
  }

  const areaName = allocation?.entityNames.areaName;
  const shiftName = allocation?.entityNames.shiftName;
  const taskName = allocation?.entityNames.taskName;
  const subtaskNames = allocation?.entityNames.subtaskNames;
  const shiftDuration = getShiftDurationString(allocation?.entities.shift);

  const isEmptyCell = !areaName && !shiftName && !taskName && !subtaskNames;

  const { displayedOpenShift, displayedState, pendingShiftNumbers } =
    getDisplayedOpenShiftInfo(employeeID, openShiftsOnDay);

  if (displayedOpenShift && displayedState === "pending") {
    return (
      <div className={`${styles.cell} ${styles.pendingOpenShiftCell}`}>
        <p className={styles.openShiftsLabel}>
          Open shifts ({pendingShiftNumbers})
        </p>
      </div>
    );
  }

  const isOpenShift = allocation?.isOpenShift;
  const { displayedState: displayedOpenShiftState } = getDisplayedOpenShiftInfo(
    employeeID,
    openShiftsOnDay
  );

  return (
    <div
      className={`${styles.cell} ${styles.scheduleCell} ${
        isEmptyCell && styles.emptyCell
      } ${
        isOpenShift ? styles.openShiftCell : styles.publishedAllocationCell
      } ${displayedOpenShiftState === "pending" && styles.pending} ${
        displayedOpenShiftState === "accepted" && styles.accepted
      }`}
    >
      <div className={styles.cellTop}>
        <span className={`${styles.scheduleNameLabel}`}>{areaName}</span>
        <span className={`${styles.scheduleNameLabel}`}>{shiftName}</span>
        <span className={`${styles.scheduleNameLabel}`}>{taskName}</span>
        {subtaskNames &&
          subtaskNames.map((name, idx) => (
            <span key={idx} className={`${styles.scheduleNameLabel}`}>
              {name}
            </span>
          ))}
      </div>
      <span className={styles.scheduleDurationLabel}>
        {shiftDuration.replace("-", "")}
      </span>
      {displayedState === "accepted" && allocation.isOpenShift && (
        <div>
          <p className={styles.acceptedTag}>Accepted</p>
        </div>
      )}
    </div>
  );
};

const PreferencesViewCell = ({
  date,
  preferenceOnDay,
  recurringPreferenceOnDay,
  goToPreferences,
}) => {
  let prefereceToDisplay = null;
  let isRecurring = false;
  if (preferenceOnDay) {
    prefereceToDisplay = preferenceOnDay;
  } else if (recurringPreferenceOnDay) {
    prefereceToDisplay = recurringPreferenceOnDay;
    isRecurring = true;
  }

  const handleCellClick = (date) => {
    goToPreferences(date);
  };

  if (!prefereceToDisplay) {
    return (
      <div className={styles.cell} onClick={() => handleCellClick(date)}></div>
    );
  }

  const isSpecial = isPreferenceSpecial(prefereceToDisplay);
  const state = getDefaultPreferenceState(prefereceToDisplay);

  return (
    <div
      className={`${styles.cell} ${styles.preferenceExistsCell}`}
      onClick={() => handleCellClick(date)}
    >
      <p className={styles.preferencesNameLabel}>
        {prefereceToDisplay.displayedAllocation}
      </p>
      <span
        className={`${styles.state} ${state === "Pending" && styles.pending} ${
          state === "Approved" && styles.approved
        }`}
      >
        {state}
      </span>
      <div className={styles.iconTag}>
        {isRecurring && (
          <>
            <FontAwesomeIcon className={styles.icon} icon={faInfinity} />
            <span className={styles.iconLabel}>Recurring</span>
          </>
        )}
      </div>
      <div className={styles.iconTag}>
        {isSpecial && (
          <>
            <img className={styles.star} src={StarBlue} alt="Grey star" />
            <span className={styles.iconLabel}>Special</span>
          </>
        )}
      </div>
    </div>
  );
};

const RequestsViewCell = ({ date, requestOnDay, goToLeave }) => {
  const handleCellClick = () => {
    goToLeave(date);
  };
  if (!requestOnDay) {
    return <div className={styles.cell} onClick={handleCellClick}></div>;
  }

  const { request, state } = requestOnDay;

  return (
    <div
      className={`${styles.cell} ${styles.leaveCell} ${styles.leaveExistsCell}`}
      onClick={handleCellClick}
    >
      <span className={styles.leaveNameLabel}>{request}</span>
      <span
        className={`${styles.state} ${state === "Pending" && styles.pending} ${
          state === "Approved" && styles.approved
        }`}
      >
        {state}
      </span>
    </div>
  );
};

const WeekSectionRow = ({
  label,
  displayedDates,
  allocations,
  myOpenShifts,
  employeeID,
  CellComponent,
  preferences,
  preferencesRecurring,
  requests,
  locationID,
  goToPreferences,
  goToLeave,
}) => {
  return (
    <div className={styles.section}>
      <p className={styles.weekSectionLabel}>{label}</p>
      <div className={styles.sectionWeekPart}>
        {displayedDates.map((date, idx) => {
          const dow = date.getDayOfWeek("string");
          const openShiftsOnDay = myOpenShifts.filter((openShift) => {
            if (new DateTime().isAfter(openShift.date)) {
              const state = openShift.employeeStates.find(
                (s) => s.employeeID === employeeID
              );
              if (state.state === "pending") {
                return false;
              }
            }
            return openShift.date === date.toFormat("AWS");
          });

          const allocation = allocations.find(
            (allo) => allo.date === date.toFormat("AWS")
          );

          const targetRecurringPreference = preferencesRecurring.find(
            (preference) => preference.dow === dow && preference.allocation
          );
          const targetPreference = preferences.find(
            (preference) => preference.date === date.toFormat("AWS")
          );

          const displayedRequests = requests.filter((request) => {
            const { startDate, finishDate } = request;
            const dateRange = DateTime.getAllDateStringsBetween(
              startDate,
              finishDate,
              true,
              true
            );
            return dateRange.includes(date.toFormat("AWS"));
          });

          const requestOnDay =
            displayedRequests.length === 0 ? null : displayedRequests[0];

          return (
            <div
              key={date.toFormat("AWS")}
              className={styles.weekCell}
              style={{
                ...(idx !== 0 && { borderLeft: "1px solid #a5a5a5" }),
              }}
            >
              <CellComponent
                date={date}
                allocation={allocation}
                openShiftsOnDay={openShiftsOnDay}
                employeeID={employeeID}
                preferenceOnDay={targetPreference}
                recurringPreferenceOnDay={targetRecurringPreference}
                requestOnDay={requestOnDay}
                locationID={locationID}
                goToPreferences={goToPreferences}
                goToLeave={goToLeave}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MobileWeekView = ({
  currentViewData,
  setSelectedDate,
  allocations,
  myOpenShifts,
  userEmployee,
  preferences,
  preferencesRecurring,
  requests,
  locationID,
  openBottomTab,
  setContentViewAndSelectedDate,
}) => {
  const redirectTo = useRedirect();
  const { selectedDate } = currentViewData;

  const monday = getWeekViewStartDate(selectedDate);
  const displayedDates = getDisplayedDates(monday);

  const goToPreferences = (date) => {
    openBottomTab();
    setContentViewAndSelectedDate(
      EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences,
      date
    );
  };

  const goToLeave = (date) => {
    redirectTo({
      pageName: PAGE_NAMES.mobileEmployeeViewLeaveRequests,
      query: {
        "location-id": locationID,
        date: date.toFormat("AWS"),
        "content-view-type": EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave,
      },
    });
  };

  return (
    <div className={styles.container}>
      <DateTopBar
        displayedDates={displayedDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <div className={styles.content}>
        <WeekSectionRow
          displayedDates={displayedDates}
          label="My Published Shifts"
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          employeeID={userEmployee.id}
          CellComponent={ScheduleWeekViewCell}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          requests={requests}
          locationID={locationID}
          goToPreferences={goToPreferences}
          goToLeave={goToLeave}
        />
        <WeekSectionRow
          displayedDates={displayedDates}
          label="My Preferences"
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          employeeID={userEmployee.id}
          CellComponent={PreferencesViewCell}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          requests={requests}
          locationID={locationID}
          goToPreferences={goToPreferences}
          goToLeave={goToLeave}
        />
        <WeekSectionRow
          displayedDates={displayedDates}
          label="My Leave Requests"
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          employeeID={userEmployee.id}
          CellComponent={RequestsViewCell}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          requests={requests}
          locationID={locationID}
          goToPreferences={goToPreferences}
          goToLeave={goToLeave}
        />
        <div className={styles.logoContainer}>
          <img src={logo} />
        </div>
      </div>
    </div>
  );
};

export default MobileWeekView;
