import { useState } from "react";
import styles from "./ConfirmationDialog.module.css";

const ConfirmationDialog = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  closeModal,
  title,
  message,
  confirmationCode = null,
}) => {
  const [inputValue, setInputValue] = useState("");
  const correctCodeEntered = confirmationCode === inputValue;

  return (
    <div className={styles.confirmDeleteWrapper}>
      <div className={styles.confirmDelete}>
        <span className={styles.deleteConfirmTitle}>{title}</span>
        <p className={styles.deleteConfirmDesc}>{message}</p>
        {confirmationCode && (
          <input
            className={styles.codeInput}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
        <div className={styles.btnContainer}>
          <button
            className={`${
              confirmationCode && !correctCodeEntered
                ? styles.disabled
                : styles.enabled
            }`}
            onClick={() => {
              if (confirmationCode) {
                if (correctCodeEntered) {
                  onConfirm();
                }
                return;
              }
              onConfirm();
            }}
          >
            {confirmLabel}
          </button>
          <button className={styles.enabled} onClick={closeModal}>
            {cancelLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
