import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import ModalViewer from "../../../../../components/elements/Modal/ModalViewer";
import { getMostCommonElementInArr } from "../../../../../utils";
import { getFlatRules } from "../../../../rules/service/rules";
import styles from "./RosterAddRestrictionModal.module.css";
import MultiSelectEmployees from "../../../../../components/elements/MultiSelectEmployees/MultiSelectEmployees";
import { KEYWORD_NA } from "../../../../../constants/keywords";

const RosterAddRestrictionModal = ({
  hide,
  isShowing,
  onClickOkay,
  employees,
  shifts,
  shiftGroups,
  tasks,
  updateCustomRulesWithDominance,
  cellClicked,
  minCounts,
  maxCounts,
  setCellClicked,
  restrictionModalProps,
  rosterID,
}) => {
  const [minValue, setMinValue]: [any, any] = useState("");
  const [maxValue, setMaxValue]: [any, any] = useState("");

  const [importance, setImportance] = useState("necessary");
  const [selected, setSelected] = useState(() => {
    const clickedEmployee = restrictionModalProps.data.name;
    return [clickedEmployee];
  });

  const columnID = restrictionModalProps.column.colId;
  const isAllSelected =
    employees.length > 0 && selected.length === employees.length;

  const shiftNames = shifts.map((shift) => shift.name);
  const shiftGroupNames = shiftGroups.map((group) => group.name);
  const columnClicked = cellClicked[1];

  useEffect(() => {
    return () => {
      setCellClicked(null);
    };
  }, [setCellClicked]);

  useEffect(() => {
    const selectedIndices = selected.map((name) =>
      employees.findIndex((emp: { name: string }) => emp.name === name)
    );

    if (columnClicked in minCounts) {
      const minValuesOfSelected = selectedIndices.map((idx) => {
        return minCounts[columnClicked][idx];
      });
      const mostCommonMinValue = getMostCommonElementInArr(minValuesOfSelected);
      if (mostCommonMinValue) {
        setMinValue(mostCommonMinValue);
      }
    }
    if (columnClicked in maxCounts) {
      const maxValuesOfSelected = selectedIndices.map((idx) => {
        return maxCounts[columnClicked][idx];
      });
      const mostCommonMaxValue = getMostCommonElementInArr(maxValuesOfSelected);
      if (mostCommonMaxValue) {
        setMaxValue(mostCommonMaxValue);
      }
    }
  }, [minCounts, maxCounts, columnClicked, employees, selected]);

  let ruleConstraintDescription = <></>;
  if (columnClicked === "total") {
    ruleConstraintDescription = <>hours per roster. </>;
  } else if (columnClicked === "Weekends off") {
    ruleConstraintDescription = <>weekends off per roster.</>;
  } else {
    ruleConstraintDescription = <>{columnClicked} shifts per roster.</>;
  }

  return (
    <ModalViewer
      hide={hide}
      isShowing={isShowing}
      title={"Add Rule"}
      firstBtnLabel={"Create"}
      backgroundColor={"#F5F5F5"}
      onclickOkay={async () => {
        const allRules: { ruleName: string; ruleTemplate: string }[] =
          getFlatRules(shifts, shiftGroups, tasks, rosterID);

        const ruleData: object[] = [];

        if (minValue) {
          const rule = allRules.filter((rule) => {
            if (
              columnID.endsWith("ShiftHours") &&
              shiftGroupNames.includes(columnClicked.replace(" hours", ""))
            ) {
              return (
                rule.ruleTemplate ===
                `numHours;Roster;Minimum;${
                  importance === "necessary" ? "Critical" : "Medium"
                };${columnClicked.replace(" hours", "")}`
              );
            } else if (
              shiftNames.includes(columnClicked) ||
              shiftGroupNames.includes(columnClicked)
            ) {
              return (
                rule.ruleTemplate ===
                `numShifts;Roster;Minimum;${
                  importance === "necessary" ? "Critical" : "Medium"
                };${columnClicked}`
              );
            } else if (columnClicked === "Weekends off") {
              return (
                rule.ruleTemplate ===
                `minimumWeekends;${
                  importance === "necessary" ? "Critical" : "Medium"
                }`
              );
            } else if (columnClicked === "total") {
              return (
                rule.ruleTemplate ===
                `numHours;Roster;Minimum;${
                  importance === "necessary" ? "Critical" : "Medium"
                }`
              );
            }

            return false;
          });

          if (rule.length > 0) {
            ruleData.push({
              ruleName: rule[0].ruleName,
              template: rule[0].ruleTemplate,
              value: minValue,
              dominate: "higher",
              employeeInds: selected.map((name) =>
                employees.findIndex(
                  (emp: { name: string }) => emp.name === name
                )
              ),
            });
          }
        }

        if (maxValue) {
          const rule = allRules.filter((rule) => {
            if (
              columnID.endsWith("ShiftHours") &&
              shiftGroupNames.includes(columnClicked.replace(" hours", ""))
            ) {
              return (
                rule.ruleTemplate ===
                `numHours;Roster;Maximum;${
                  importance === "necessary" ? "Critical" : "Medium"
                };${columnClicked.replace(" hours", "")}`
              );
            } else if (
              shiftNames.includes(columnClicked) ||
              shiftGroupNames.includes(columnClicked)
            ) {
              return (
                rule.ruleTemplate ===
                `numShifts;Roster;Maximum;Critical;${columnClicked}`
              );
            } else if (columnClicked === "total") {
              return (
                rule.ruleTemplate ===
                `numHours;Roster;Maximum;${
                  importance === "necessary" ? "Critical" : "Medium"
                }`
              );
            }

            return false;
          });

          if (rule.length > 0) {
            ruleData.push({
              ruleName: rule[0].ruleName,
              template: rule[0].ruleTemplate,
              value: maxValue,
              dominate: "lower",
              employeeInds: selected.map((name) =>
                employees.findIndex(
                  (emp: { name: string }) => emp.name === name
                )
              ),
            });
          }
        }

        await updateCustomRulesWithDominance(ruleData);
        await onClickOkay();
      }}
    >
      <div>
        <h3 className={styles.subtitle}>Because you selected:</h3>
        <div className={styles.tableContainer}>
          Number of {columnClicked} shifts
        </div>
      </div>
      <div className={styles.smartCheck}>
        <h3 className={styles.subtitle}>Add the following rule:</h3>
        <div className={styles.smartCheckList}>
          <p>
            I want {selected.length > 1 ? "employees" : selected[0]} to work at
            least{" "}
            <input
              type="text"
              maxLength={5}
              size={5}
              placeholder={"0"}
              value={minValue}
              onChange={(event) => setMinValue(event.target.value)}
            ></input>{" "}
            {ruleConstraintDescription}
          </p>
          {columnClicked !== "Weekends off" && (
            <p>
              I want {selected.length > 1 ? "employees" : selected[0]} to work
              at most{" "}
              <input
                type="text"
                maxLength={5}
                size={5}
                placeholder={KEYWORD_NA}
                value={maxValue}
                onChange={(event) => setMaxValue(event.target.value)}
              ></input>{" "}
              {ruleConstraintDescription}
            </p>
          )}
        </div>
      </div>
      <div className={styles.applicationToggles}>
        <div className={styles.applicationToggle}>
          <div>Apply rule to:</div>
          <MultiSelectEmployees
            employeeNames={employees.map((employee) => employee.name)}
            selected={selected}
            setSelected={setSelected}
            isAllSelected={isAllSelected}
          />
        </div>
        <div className={styles.applicationToggle}>
          <div>Importance:</div>
          <Select
            value={importance}
            onChange={(event) => {
              setImportance(event.target.value);
            }}
            style={{ width: 150, backgroundColor: "white" }}
          >
            <MenuItem value="necessary">Necessary</MenuItem>
            <MenuItem value="preference">Preference</MenuItem>
          </Select>
        </div>
      </div>
    </ModalViewer>
  );
};

export default RosterAddRestrictionModal;
