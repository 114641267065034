import create from "zustand";

export const useNotificationStore = create((set) => ({
  requestNotification: [],
  refreshRequestNotification: (allGlobalEmployees) => {
    if (!allGlobalEmployees) return;

    const pendingRequests = [];
    const pendingPreferences = [];
    allGlobalEmployees.forEach((employee) => {
      if (employee.Requests) {
        employee.Requests.forEach((req) => {
          if (req.state === "Pending") {
            pendingRequests.push({
              locationName: employee.locationName,
              locationID: employee.locationID,
            });
          }
        });
      }

      if (employee.Preferences) {
        employee.Preferences.forEach((pref) => {
          if (pref.allocation.endsWith("*")) {
            pendingPreferences.push({
              locationName: employee.locationName,
              locationID: employee.locationID,
            });
          }
        });
      }

      if (employee.PreferencesRecurring) {
        employee.PreferencesRecurring.forEach((pref) => {
          if (pref.endsWith("*")) {
            pendingPreferences.push({
              locationName: employee.locationName,
              locationID: employee.locationID,
            });
          }
        });
      }
    });
    const uniqueRequestLocations = [];
    const uniquePreferenceLocations = [];
    const notifications = [];
    for (const req of pendingRequests) {
      if (!uniqueRequestLocations.includes(req.locationName)) {
        uniqueRequestLocations.push(req.locationName);
      }
    }

    for (const req of pendingPreferences) {
      if (!uniquePreferenceLocations.includes(req.locationName)) {
        uniquePreferenceLocations.push(req.locationName);
      }
    }

    for (const locationName of uniqueRequestLocations) {
      const targetRequests = pendingRequests.filter(
        (req) => req.locationName === locationName
      );
      notifications.push({
        type: "request",
        description: `You have ${targetRequests.length} pending approvals in the leave request page. View now!`,
        locationID: targetRequests[0].locationID,
      });
    }

    for (const locationName of uniquePreferenceLocations) {
      const targetPreferences = pendingPreferences.filter(
        (req) => req.locationName === locationName
      );
      notifications.push({
        type: "preference",
        description: `[ ${locationName} ] You have ${targetPreferences.length} pending preferences in the preference request page. View now!`,
        locationID: targetPreferences[0].locationID,
      });
    }

    set({
      requestNotification: notifications,
    });
  },
}));
