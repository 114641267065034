import { memo } from "react";
import styles from "./PreferencesCell.module.css";
import { isPreferenceSpecial } from "../../../service/employeeAppUtil";
import blueStar from "../../../../../assets/images/star-blue.svg";

const PreferencesCell = memo(
  ({ preferenceOnDay, recurringPreferenceOnDay }) => {
    const dayPreference = preferenceOnDay
      ? preferenceOnDay.displayedAllocation
      : "";
    const recurringPreference = recurringPreferenceOnDay
      ? recurringPreferenceOnDay.displayedAllocation
      : "";

    const isPreferenceOnDaySpecial = preferenceOnDay
      ? isPreferenceSpecial(preferenceOnDay)
      : false;
    const isRecurringPreferenceOnDaySpecial = recurringPreferenceOnDay
      ? isPreferenceSpecial(recurringPreferenceOnDay)
      : false;

    return (
      <div className={styles.cell}>
        <div className={styles.preferecesCell}>
          <div className={styles.rowContainer}>
            {dayPreference && (
              <div className={styles.dayPreferenceLabel}>
                {isPreferenceOnDaySpecial && (
                  <img className={styles.specialIcon} src={blueStar} />
                )}
                <span>{dayPreference}</span>
              </div>
            )}
          </div>
          <div className={styles.rowContainer}>
            {recurringPreference && (
              <div className={styles.recurringPreferenceLabel}>
                {isRecurringPreferenceOnDaySpecial && (
                  <img className={styles.specialIcon} src={blueStar} />
                )}
                {recurringPreference}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
export default PreferencesCell;
