import React, { useCallback, useEffect } from "react";
import {
  exportGridToCsv,
  exportGridToExcel,
  getSelectedRows,
  getUndoRedoGridActionHandler,
  scrollGridToBottom,
} from "../../../../utils";
import { useGridUndoRedo } from "../../../../hooks/useGridUndoRedo";
import {
  customConfirmAlert,
  customWarningAlert,
} from "../../../confirm/service/confirm";

const GridActionHandler = ({
  children,
  gridApi,
  addNewItemToDB = () => {},
  updateItemsToDB = () => {},
  duplicateItemsToDB = () => {},
  removeItemsFromDB = () => {},
  getDataFromGrid,
  getToBeDeletedItems = () => {},
  parseSelectedRowsToDuplicableInfo = () => {},
  undoRedoParams = {
    tableName: null,
    getCustomGridSnapshot: null,
  },
  removeItemsAlertMessage = null,
  disableUndoRedo = false,
  customStyle = {},
  getDeletionWarnings = null,
}) => {
  const {
    handleKeyDownForUndoRedo,
    triggerUndoRedoSnapshotCollection,
    initialiseUndoRedoStack,
    resetUndoRedoStack,
  } = useGridUndoRedo(
    gridApi,
    getUndoRedoGridActionHandler(
      triggerUpdateDataFromUndoRedo,
      undoRedoParams.tableName,
      undoRedoParams.getCustomGridSnapshot
    ),
    5
  );

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      if (isMounted) {
        initialiseUndoRedoStack();
      }
    }, 600);

    return () => {
      isMounted = false;
    };
  }, [gridApi, initialiseUndoRedoStack]);

  const exportToCsv = useCallback(() => {
    exportGridToCsv(gridApi);
  }, [gridApi]);

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  async function triggerUpdateDataFromUndoRedo(customParams) {
    const updatedData = customParams.gridSnapshot;
    updateItemsToDB(updatedData);
  }

  const addData = async (numOfItems) => {
    if (isNaN(numOfItems)) return;
    resetUndoRedoStack();
    await addNewItemToDB(numOfItems);
    scrollGridToBottom(gridApi, 100);
  };

  const removeData = async (hasTriggeredFromContextMenu) => {
    resetUndoRedoStack();
    const toBeDeletedItems = getToBeDeletedItems(hasTriggeredFromContextMenu);

    let shouldWarnDeletion = false;
    let messageTitle = removeItemsAlertMessage
      ? removeItemsAlertMessage.title
      : "Are you sure?";

    let messageDescriptions = removeItemsAlertMessage
      ? [removeItemsAlertMessage.description]
      : [
          `Are you sure to delete the ${toBeDeletedItems.length} selected ${
            toBeDeletedItems.length > 1 ? "items" : "item"
          }?`,
        ];

    if (getDeletionWarnings) {
      const deletionWarnings = getDeletionWarnings(toBeDeletedItems);
      if (deletionWarnings.length > 0) {
        shouldWarnDeletion = true;
        messageTitle = "Check the following before proceeding";
        messageDescriptions.push(...deletionWarnings);
      }
    }

    let response = false;
    if (shouldWarnDeletion) {
      response = await customWarningAlert({
        title: messageTitle,
        descriptions: messageDescriptions,
        showCancelBtn: true,
      });
    } else {
      response = await customConfirmAlert({
        title: messageTitle,
        descriptions: messageDescriptions,
      });
    }

    if (!response) {
      return;
    }
    removeItemsFromDB(toBeDeletedItems);
  };

  const duplicateData = async () => {
    resetUndoRedoStack();
    const selectedRows = getSelectedRows(gridApi);
    const selectedDataInfo = parseSelectedRowsToDuplicableInfo(selectedRows);
    await duplicateItemsToDB(selectedDataInfo);
  };

  const childrenWithProps = React.cloneElement(children, {
    addData,
    removeData,
    updateData: updateItemsToDB,
    duplicateData,
    handleKeyDownForUndoRedo: disableUndoRedo
      ? () => {}
      : handleKeyDownForUndoRedo,
    triggerUndoRedoSnapshotCollection,
    exportToCsv,
    exportToExcel,
    resetUndoRedoStack,
    getDataFromGrid,
  });

  return (
    <div style={{ width: "100%", ...customStyle }}>{childrenWithProps}</div>
  );
};

export default GridActionHandler;
