import { getDayColumnNameRegex } from "../../../../utils/generalUtils/regex";
import { getColorCode } from "../../../colorCoding/service/colorCodeGetter";
import { findMinMaxDemandsPairByShiftName } from "../../../statistics/service/statsValueGetter";
import { allocationFulfilsPreference } from "../../service/preferencesAndFixedShifts";
import { getShiftAndSkillShortIdFromCountStatsRowId } from "./statsColumns";

const getDayIndex = (dayField) => {
  return Number(dayField.slice(1)) - 1;
};

const isDayColumn = (params) => {
  return (
    params.colDef.field.match(getDayColumnNameRegex()) &&
    !params.data.id.startsWith("skill") &&
    !params.data.id.startsWith("shift")
  );
};

export const getSoftFailCellColor = (
  params,
  uncoloredCellContents,
  invalidCellListWithReasons,
  preferencesInfo,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  if (uncoloredCellContents.includes(params.value)) {
    return false;
  }

  const dayIdx = Number(params.colDef.field.slice(1)) - 1;
  const dayColNameRegex = getDayColumnNameRegex();
  if (params.node.rowPinned === "bottom") {
    return false;
  }
  if (
    params.colDef.field.match(dayColNameRegex) &&
    !params.data.id.startsWith("skill") &&
    !params.data.id.startsWith("shift")
  ) {
    if (
      invalidCellListWithReasons.filter(
        (cell) =>
          params.data.id === cell.employeeId &&
          cell.coords[1] === parseInt(params.colDef.field.substring(1)) &&
          cell.importance === "preference"
      ).length > 0
    ) {
      return true;
    }

    const rowID = params.data.id;
    const employeePreferencesInfo = preferencesInfo.find(
      (info) => info.employeeID === rowID
    );
    if (employeePreferencesInfo === undefined) return false;
    const rowPreferences = employeePreferencesInfo.preferences;

    const preferenceCellValue = rowPreferences[dayIdx];

    if (
      preferenceCellValue.endsWith("!") ||
      preferenceCellValue.endsWith("?")
    ) {
      return false;
    }

    if (
      preferenceCellValue &&
      !allocationFulfilsPreference(
        params.value,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        preferenceCellValue,
        params.data.skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }

    return false;
  }
};

export const getHardFailCellColor = (
  params,
  uncoloredCellContents,
  invalidCellListWithReasons,
  preferencesInfo,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  if (uncoloredCellContents.includes(params.value)) {
    return false;
  }
  const dayIdx = Number(params.colDef.field.slice(1)) - 1;
  const dayColNameRegex = getDayColumnNameRegex();
  if (params.node.rowPinned === "bottom") {
    return false;
  }
  if (
    params.colDef.field.match(dayColNameRegex) &&
    !params.data.id.startsWith("skill") &&
    !params.data.id.startsWith("shift")
  ) {
    if (
      invalidCellListWithReasons.filter(
        (cell) =>
          params.data.id === cell.employeeId &&
          cell.coords[1] === parseInt(params.colDef.field.substring(1)) &&
          cell.importance === "necessary"
      ).length > 0
    ) {
      return true;
    }

    const rowID = params.data.id;
    const employeePreferencesInfo = preferencesInfo.find(
      (info) => info.employeeID === rowID
    );
    if (employeePreferencesInfo === undefined) return false;
    const rowPreferences = employeePreferencesInfo.preferences;

    const preferenceCellValue = rowPreferences[dayIdx];

    if (
      !(preferenceCellValue.endsWith("!") || preferenceCellValue.endsWith("?"))
    ) {
      return false;
    }

    if (
      preferenceCellValue &&
      !allocationFulfilsPreference(
        params.value,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        preferenceCellValue.replace("?", "").replace("!", ""),
        params.data.skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }

    return false;
  }
};

export const getPrefMatchingCellColor = (
  params,
  preferencesInfo,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const dayIdx = getDayIndex(params.colDef.field);
  if (params.node.rowPinned === "bottom") {
    return false;
  }
  if (isDayColumn(params)) {
    const rowID = params.data.id;
    const row = preferencesInfo.find((info) => info.employeeID === rowID);

    if (!row) {
      return false;
    }
    const rowPreferences = row.preferences;
    const preferenceCellValue = rowPreferences[dayIdx];

    if (
      allocationFulfilsPreference(
        params.value,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        preferenceCellValue,
        params.data.skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }
  }
};

export const getFixedShiftsAllocationColor = (
  params,
  fixedShiftsInfo,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  isMatchngStyle,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const dayIdx = getDayIndex(params.colDef.field);
  if (params.node.rowPinned === "bottom") {
    return false;
  }

  if (isDayColumn(params)) {
    const rowID = params.data.id;
    const row = fixedShiftsInfo.find((info) => info.employeeID === rowID);

    if (!row) {
      return false;
    }
    const rowFixedShifts = row.fixedShifts;
    const fixedShiftCellValue = rowFixedShifts[dayIdx];

    if (!isMatchngStyle && !fixedShiftCellValue) {
      return false;
    }

    if (
      !isMatchngStyle &&
      !allocationFulfilsPreference(
        params.value,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        fixedShiftCellValue,
        params.data.skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }

    if (
      isMatchngStyle &&
      allocationFulfilsPreference(
        params.value,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        fixedShiftCellValue,
        params.data.skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      return true;
    }
  }
};

export const getColorCodedCells = (
  params,
  colorCodes,
  shiftGroups,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  let style = {};

  if (params.node.rowPinned === "bottom" || !params.value) {
    return {};
  }

  const colorCode = getColorCode(
    colorCodes,
    params.value,
    shiftGroups,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );

  if (colorCode) {
    style.backgroundColor = colorCode;
    if (isDark(colorCode)) {
      style.color = "white";
    }
  }

  return { ...style };
};

export function isDark(color) {
  let r, g, b;

  // Check if the color is in hex format
  if (color[0] === "#") {
    // Convert hex to RGB
    const hex = color.replace("#", "");
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    // Assume the color is in RGBA format
    const parts = color.match(/\d+/g);
    r = parseInt(parts[0], 10);
    g = parseInt(parts[1], 10);
    b = parseInt(parts[2], 10);
  }

  // Calculate luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance < 150;
}

export const getDemandMinMaxCellStyles = (params) => {
  let style = {};

  const rowId = params.data.id;
  const { shiftShortId, skillShortId } =
    getShiftAndSkillShortIdFromCountStatsRowId(rowId);

  if (
    params.node.rowPinned === "bottom" &&
    rowId.startsWith("shift") &&
    !rowId.startsWith("shift-hours")
  ) {
    const minMaxDemandsPair = findMinMaxDemandsPairByShiftName(
      params.context.shiftDemands,
      shiftShortId,
      skillShortId,
      "shiftShortId",
      "skillShortId"
    );

    const maxDemandValues = minMaxDemandsPair.maxDemandValues;
    const minDemandValues = minMaxDemandsPair.minDemandValues;

    const demandValueIdx = Number(params.colDef.field.slice(1));
    const maxDemandValue = maxDemandValues
      ? maxDemandValues[demandValueIdx - 1]
      : null;
    const minDemandValue = minDemandValues
      ? minDemandValues[demandValueIdx - 1]
      : null;

    if (
      minDemandValue !== null &&
      minDemandValue !== undefined &&
      params.value < minDemandValue
    ) {
      style.backgroundColor = "#FCBED8";
    } else if (
      minDemandValue !== null &&
      minDemandValue !== undefined &&
      maxDemandValue !== null &&
      maxDemandValue !== undefined &&
      params.value >= minDemandValue &&
      params.value <= maxDemandValue
    ) {
      style.backgroundColor = "#CAF2E0";
    } else if (
      maxDemandValue !== null &&
      maxDemandValue !== undefined &&
      params.value > maxDemandValue
    ) {
      style.backgroundColor = "#FCBED8";
    } else if (
      (maxDemandValue === null || maxDemandValue === undefined) &&
      minDemandValue !== null &&
      minDemandValue !== undefined
    ) {
      if (params.value >= minDemandValue) {
        style.backgroundColor = "#CAF2E0";
      } else if (params.value < minDemandValue) {
        style.backgroundColor = "#FCBED8";
      }
    } else if (
      (minDemandValue === null || minDemandValue === undefined) &&
      maxDemandValue !== null &&
      maxDemandValue !== undefined
    ) {
      if (params.value <= maxDemandValue) {
        style.backgroundColor = "#CAF2E0";
      } else if (params.value < maxDemandValue) {
        style.backgroundColor = "#FCBED8";
      }
    }
  }
  return style;
};

export const getWeekendsCellStyle = (params) => {
  const { colDef } = params;
  return ["Sa", "Su"].find((name) => colDef.headerName.startsWith(name));
};
