import {
  DateTime,
  deepCopyObject,
  getDuplicatedStringsInArr,
  getNames,
  getShortIds,
  removeDuplicateStrInArr,
  strToArrCommaSeparated,
} from "../../../../utils";
import { gatherRulesData } from "../../../rules/service/rules";
import {
  buildShortIdsToEntityNamesDicts,
  getSubtasks,
} from "../../../rosterProblems/service/rosterUtils";
import { getNoTaskSubtasks } from "../../../rosterProblems/service/preferencesAndFixedShifts";
import {
  getAllocatablesInFixedShifts,
  getAllocatablesInPreferences,
} from "../../../store/service/shared/sharedStoreApi";
import {
  checkInvalidRuleValues,
  getInvalidNumberOfRules,
  getRuleValueRange,
} from "../../../../utils/validationUtils/sharedValidations";
import { convertAllocationInShortIdFormToNameForm } from "../../../../utils/modelUtils/allocation";
import {
  KEYWORD_ALL,
  KEYWORD_NO_TASK,
  KEYWORD_OFF,
  KEYWORD_STUDY,
} from "../../../../constants/keywords";

export function calculateWarnings(
  rosterInView,
  fieldsToCalculate,
  customKeywords = null,
  cachedWarnings = null
) {
  const warnings = deepCopyObject(cachedWarnings) || {};
  const {
    CustomRules,
    Demands,
    ShiftGroups,
    Shifts,
    Skills,
    TaskBlocks,
    Tasks,
    Areas,
    Employees,
    numDays,
  } = rosterInView;

  const subTasks = getSubtasks(Tasks, TaskBlocks);
  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    Areas,
    Shifts,
    ShiftGroups,
    Tasks,
    subTasks,
    Skills
  );

  if (fieldsToCalculate.includes("RosteredAllocations")) {
    if (!customKeywords) {
      throw new Error("Calculating Tasks warnings required custom keyword");
    }
    const rosteredAllocationsWarnings = calculatedRosteredAllocationsWarnings(
      Employees,
      customKeywords
    );
    warnings.RosteredAllocations = rosteredAllocationsWarnings;
  }

  if (fieldsToCalculate.includes("Employees")) {
    const employeesWarnings = calculateEmployeesWarnings(
      Employees,
      Skills,
      Shifts,
      ShiftGroups,
      Areas,
      customKeywords
    );
    warnings.Employees = employeesWarnings;
  }
  if (fieldsToCalculate.includes("Skills")) {
    const skillsWarnings = calculateSkillsWarnings(Skills);
    warnings.Skills = skillsWarnings;
  }
  if (fieldsToCalculate.includes("Tasks")) {
    const tasksWarnings = calculateTasksWarnings(Tasks, Skills);
    warnings.Tasks = tasksWarnings;
  }
  if (fieldsToCalculate.includes("TaskBlocks")) {
    if (!customKeywords) {
      throw new Error("Calculating Tasks warnings required custom keyword");
    }
    const taskBlocksWarnings = calculateTaskBlocksWarnings(
      TaskBlocks,
      Tasks,
      customKeywords
    );
    warnings.TaskBlocks = taskBlocksWarnings;
  }
  if (fieldsToCalculate.includes("Areas")) {
    const areasWarnings = calculateAreasWarnings(
      Areas,
      Skills,
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      customKeywords
    );
    warnings.Areas = areasWarnings;
  }
  if (fieldsToCalculate.includes("Shifts")) {
    if (!customKeywords) {
      throw new Error("Calculating Shifts warnings required custom keyword");
    }
    const shiftsWarnings = calculateShiftsWarnings(
      Shifts,
      Skills,
      customKeywords
    );
    warnings.Shifts = shiftsWarnings;
  }
  if (fieldsToCalculate.includes("ShiftGroups")) {
    if (!customKeywords) {
      throw new Error(
        "Calculating Shift Groups warnings required custom keyword"
      );
    }
    const shiftGroupsWarnings = calculateShiftGroupsWarnings(
      ShiftGroups,
      Areas,
      Shifts,
      Skills,
      Tasks,
      TaskBlocks,
      customKeywords
    );
    warnings.ShiftGroups = shiftGroupsWarnings;
  }
  if (fieldsToCalculate.includes("CustomRules")) {
    const rulesWarnings = calculateRulesWarnings(
      numDays,
      CustomRules,
      Employees,
      Shifts,
      rosterInView
    );
    warnings.CustomRules = rulesWarnings;
  }
  if (fieldsToCalculate.includes("Demands")) {
    const demandsWarnings = calculateDemandsWarnings(
      Demands,
      Shifts,
      ShiftGroups,
      Skills,
      Tasks,
      subTasks,
      Areas
    );
    warnings.Demands = demandsWarnings;
  }
  if (fieldsToCalculate.includes("History")) {
    const { predefinedShiftOptions } = customKeywords;
    const historyWarnings = calculateAllocationsWarnings(
      Employees,
      "History",
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      Areas,
      predefinedShiftOptions,
      ["shifts", "enumerated-combo"],
      false,
      shortIdsToEntityNamesDicts
    );
    warnings.History = historyWarnings;
  }
  if (fieldsToCalculate.includes("Allocations")) {
    const { predefinedShiftOptions } = customKeywords;
    customKeywords;
    const allocationsWarnings = calculateAllocationsWarnings(
      Employees,
      "Allocations",
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      Areas,
      predefinedShiftOptions,
      ["shifts", "shift-groups", "tasks", "sub-tasks", "enumerated-combo"],
      false,
      shortIdsToEntityNamesDicts
    );
    warnings.Allocations = allocationsWarnings;
  }
  if (fieldsToCalculate.includes("AllocationsRecurring")) {
    const { predefinedShiftOptions } = customKeywords;

    const allocationsRecurringWarnings = calculateAllocationsWarnings(
      Employees,
      "AllocationsRecurring",
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      Areas,
      predefinedShiftOptions,
      ["shifts", "shift-groups", "tasks", "sub-tasks", "enumerated-combo"],
      false,
      shortIdsToEntityNamesDicts
    );
    warnings.AllocationsRecurring = allocationsRecurringWarnings;
  }
  if (fieldsToCalculate.includes("Days")) {
    const daysWarnings = calculateAllocationsWarnings(
      Employees,
      "Days",
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      Areas,
      [KEYWORD_OFF],
      [
        "shifts",
        "shift-groups",
        "tasks",
        "sub-tasks",
        "enumerated-combo",
        "enumerated-tasks",
      ],
      true,
      shortIdsToEntityNamesDicts
    );
    warnings.Days = daysWarnings;
  }
  if (fieldsToCalculate.includes("DaysRecurring")) {
    const daysRecurringWarnings = calculateAllocationsWarnings(
      Employees,
      "DaysRecurring",
      Shifts,
      ShiftGroups,
      Tasks,
      TaskBlocks,
      Areas,
      [KEYWORD_OFF],
      [
        "shifts",
        "shift-groups",
        "tasks",
        "sub-tasks",
        "enumerated-combo",
        "enumerated-tasks",
      ],
      true,
      shortIdsToEntityNamesDicts
    );
    warnings.DaysRecurring = daysRecurringWarnings;
  }

  return warnings;
}

/**
 * Warnings getter
 */
const DEFAULT_WARNING = {
  warningType: null,
  displayedWarningType: null,
  entities: null,
  values: null,
  extraInfo: null,
};

function getMissingNamesWarnings(entities) {
  const entitiesWithMissingName = entities.filter(
    ({ name }) => name.trim() === ""
  );

  if (entitiesWithMissingName.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: "missing names",
      entities: entitiesWithMissingName,
    };
  }
  return null;
}

function getDuplicateNamesWarnings(entities) {
  const names = getNames(entities);
  const duplicateNames = getDuplicatedStringsInArr(names);
  const duplicateNameEntities = entities.filter(({ name }) =>
    duplicateNames.includes(name)
  );

  if (duplicateNames.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: "duplicate names",
      entities: duplicateNameEntities,
      values: getDuplicatedStringsInArr(getNames(duplicateNameEntities)),
    };
  }

  return null;
}

function getDateRangeWarnings(entities) {
  const invalidEntities = [];
  for (const entity of entities) {
    const { startDate, finishDate } = entity;
    if (!startDate || !finishDate) {
      continue;
    }
    if (new DateTime(finishDate).isBefore(startDate)) {
      invalidEntities.push(entity);
    }
  }

  if (invalidEntities.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: `invalid date range`,
      entities: invalidEntities,
    };
  }

  return null;
}

function getInvalidChildEntityShortIdValuesWarnings(
  entities,
  fieldName,
  childEntities,
  allowedValues = []
) {
  const invalidValues = [];

  const allowedChildValues = getShortIds(childEntities);

  for (const entity of entities) {
    const childEntityShortIdValues = strToArrCommaSeparated(entity[fieldName]);
    childEntityShortIdValues.forEach((value) => {
      if (
        !allowedChildValues.includes(value) &&
        !allowedValues.includes(value)
      ) {
        invalidValues.push(value);
      }
    });
  }

  if (invalidValues.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: `invalid ${fieldName}`,
      values: invalidValues,
    };
  }
  return null;
}

function getInvalidNameWarnings(entities, invalidNames) {
  const entitiesWithInvalidNames = [];
  for (const entity of entities) {
    if (invalidNames.includes(entity.name)) {
      entitiesWithInvalidNames.push(entity);
    }
  }

  if (entitiesWithInvalidNames.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: `invalid names`,
      entities: entitiesWithInvalidNames,
    };
  }

  return null;
}

function getInvalidStudyLeaveWarnings(employees, customKeywords) {
  const studyLeaveEnabled =
    customKeywords.leaveKeywords.includes(KEYWORD_STUDY);

  for (const employee of employees) {
    if (
      !studyLeaveEnabled &&
      employee.RosteredAllocations &&
      employee.RosteredAllocations.includes(KEYWORD_STUDY)
    ) {
      return {
        ...DEFAULT_WARNING,
        warningType: `invalid Study leave`,
      };
    }
  }
  return null;
}

function getInvalidAllocationWarnings(
  allocations,
  fieldName,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  areas,
  allowedCustomKeywords,
  acceptedInputTypes,
  allowSuffix,
  employee = null,
  shortIdsToEntityNamesDicts
) {
  const { areaNamesDict } = shortIdsToEntityNamesDicts;
  const areaShortIds = getShortIds(areas);
  const allocationsWithoutSuffix = allowSuffix
    ? allocations.map((allocation) => {
        if (
          allocation.endsWith("!") ||
          allocation.endsWith("?") ||
          allocation.endsWith("*")
        ) {
          return allocation.slice(0, -1);
        }
        return allocation;
      })
    : allocations;

  let allowedInputs;
  if (employee === null) {
    allowedInputs = getAllocatablesInPreferences(
      shifts,
      shiftGroups,
      tasks,
      taskBlocks,
      allowedCustomKeywords,
      acceptedInputTypes
    );
  } else {
    allowedInputs = getAllocatablesInFixedShifts(
      employee,
      shifts,
      shiftGroups,
      tasks,
      taskBlocks,
      allowedCustomKeywords
    );
  }

  const invalidAllocations = [];

  for (const allocation of allocationsWithoutSuffix) {
    if (!allocation || areaShortIds.includes(allocation)) {
      continue;
    }

    let allocationShortId = allocation;
    const [area, rest] = allocation.split(":");
    if (area in areaNamesDict) {
      allocationShortId = rest;
    }

    if (
      !allowedInputs.includes(allocationShortId) &&
      !invalidAllocations.includes(allocation)
    ) {
      invalidAllocations.push(allocation);
    }
  }

  const invalidAllocationNames = invalidAllocations.map((allocation) =>
    convertAllocationInShortIdFormToNameForm(
      allocation,
      shortIdsToEntityNamesDicts
    )
  );

  let displayedWarningType = fieldName;

  switch (fieldName) {
    case "Allocations":
      displayedWarningType = "Fixed Shifts";
      break;
    case "AllocationsRecurring":
      displayedWarningType = "Fixed Shifts Recurring";
      break;
    case "Days":
      displayedWarningType = "Preferences";
      break;
    case "DaysRecurring":
      displayedWarningType = "Preferences Recurring";
      break;
  }

  if (invalidAllocations.length > 0) {
    return {
      ...DEFAULT_WARNING,
      warningType: `invalid ${fieldName} input`,
      displayedWarningType: `Invalid allocations in ${displayedWarningType} table${
        employee !== null ? ` (for ${employee.name})` : ""
      }`,
      values: invalidAllocations,
      extraInfo: {
        employeeID: employee ? employee.id : null,
        allocations: invalidAllocations,
        allocationNames: invalidAllocationNames,
      },
    };
  }
  return null;
}

// function

function calculatedRosteredAllocationsWarnings(employees, customKeywords) {
  const warnings = [];
  warnings.push(getInvalidStudyLeaveWarnings(employees, customKeywords));
  return warnings.filter((warning) => warning !== null);
}

function calculateEmployeesWarnings(
  employees,
  skills,
  shifts,
  shiftGroups,
  areas
) {
  const warnings = [];
  warnings.push(getDuplicateNamesWarnings(employees));
  warnings.push(getMissingNamesWarnings(employees));
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(employees, "skills", skills)
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(employees, "areas", areas, [
      KEYWORD_ALL,
    ])
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(
      employees,
      "shifts",
      [...shifts, ...shiftGroups],
      [KEYWORD_ALL]
    )
  );
  warnings.push(getDateRangeWarnings(employees));
  return warnings.filter((warning) => warning !== null);
}

function calculateSkillsWarnings(skills) {
  const warnings = [];
  warnings.push(getDuplicateNamesWarnings(skills));
  warnings.push(getMissingNamesWarnings(skills));
  return warnings.filter((warning) => warning !== null);
}

function calculateTasksWarnings(tasks, skills) {
  const warnings = [];
  warnings.push(getDuplicateNamesWarnings(tasks));
  warnings.push(getMissingNamesWarnings(tasks));
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(tasks, "skills", skills)
  );

  return warnings.filter((warning) => warning !== null);
}

function calculateTaskBlocksWarnings(taskBlocks, tasks, customKeywords) {
  const warnings = [];
  const { annualLeaveKeyword } = customKeywords;
  warnings.push(getDuplicateNamesWarnings(taskBlocks));
  warnings.push(getMissingNamesWarnings(taskBlocks));
  warnings.push(
    getInvalidNameWarnings(taskBlocks, [
      KEYWORD_ALL,
      annualLeaveKeyword,
      KEYWORD_OFF,
    ])
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(taskBlocks, "tasks", tasks, [
      KEYWORD_ALL,
    ])
  );
  return warnings.filter((warning) => warning !== null);
}

function calculateAreasWarnings(
  areas,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks
) {
  const warnings = [];
  warnings.push(getDuplicateNamesWarnings(areas));
  warnings.push(getMissingNamesWarnings(areas));
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(
      areas,
      "shifts",
      [...shifts, ...shiftGroups],
      [KEYWORD_ALL]
    )
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(areas, "skills", skills)
  );

  // Allowed task values
  const allowedTasks = [
    ...getShortIds(tasks),
    ...getSubtasks(tasks, taskBlocks).map((subtask) => subtask.shortId),
    ...getShortIds(taskBlocks).map((item) => `all ${item} tasks`),
    ...getNoTaskSubtasks(taskBlocks).map((subtask) => subtask.shortId),
    KEYWORD_NO_TASK,
  ];
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(
      areas,
      "tasks",
      tasks,
      allowedTasks
    )
  );

  return warnings.filter((warning) => warning !== null);
}

function calculateShiftsWarnings(shifts, skills, customKeywords) {
  const warnings = [];
  const { annualLeaveKeyword } = customKeywords;
  warnings.push(getDuplicateNamesWarnings(shifts));
  warnings.push(getMissingNamesWarnings(shifts));
  warnings.push(
    getInvalidNameWarnings(shifts, [
      KEYWORD_ALL,
      annualLeaveKeyword,
      KEYWORD_OFF,
    ])
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(shifts, "skills", skills)
  );
  return warnings.filter((warning) => warning !== null);
}

function calculateShiftGroupsWarnings(
  shiftGroups,
  areas,
  shifts,
  skills,
  tasks,
  taskBlocks,
  customKeywords
) {
  const warnings = [];
  const { annualLeaveKeyword } = customKeywords;
  warnings.push(getDuplicateNamesWarnings(shiftGroups));
  warnings.push(getMissingNamesWarnings(shiftGroups));
  warnings.push(
    getInvalidNameWarnings(shiftGroups, [
      KEYWORD_ALL,
      annualLeaveKeyword,
      KEYWORD_OFF,
    ])
  );

  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(shiftGroups, "shifts", shifts, [
      KEYWORD_OFF,
    ])
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(shiftGroups, "skills", skills)
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(shiftGroups, "areas", areas)
  );

  // Allowed task values
  const allowedTasks = [
    ...getShortIds(tasks),
    ...getSubtasks(tasks, taskBlocks).map((subtask) => subtask.shortId),
    ...getShortIds(taskBlocks).map((item) => `all ${item} tasks`),
    ...getNoTaskSubtasks(taskBlocks).map((subtask) => subtask.shortId),
    KEYWORD_NO_TASK,
  ];
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(
      shiftGroups,
      "tasks",
      tasks,
      allowedTasks
    )
  );
  return warnings.filter((warning) => warning !== null);
}

function calculateRulesWarnings(
  numDays,
  customRules,
  employees,
  shifts,
  rosterInView
) {
  const warnings = [];

  const data = employees.map((emp) => {
    const ruleValues = emp.RuleValues;
    const rulePairs = {};
    const rulesData = [];
    for (const idx in customRules) {
      const ruleName = customRules[idx].name;
      const ruleTemplate = customRules[idx].template;
      const ruleValue = ruleValues[idx];
      rulePairs[ruleName] = ruleValue;
      const { minValue, maxValue } = getRuleValueRange(
        ruleTemplate,
        numDays,
        shifts
      );
      rulesData.push({ ruleName, ruleTemplate, ruleValue, minValue, maxValue });
    }
    return {
      id: emp.id,
      name: emp.name,
      rulesData,
      ...rulePairs,
    };
  });

  const allRulesData = gatherRulesData(rosterInView);
  const { rulesWithInvalidInput, rulesWithEmptyInput } = checkInvalidRuleValues(
    data,
    customRules,
    allRulesData
  );

  const outOfRangeRuleValues = data
    .map((employeeData) => ({
      employeeID: employeeData.id,
      violatingRules: employeeData.rulesData
        .filter((ruleData) => {
          const { maxValue, minValue, ruleValue } = ruleData;
          let doesViolate = false;
          if (maxValue == null && minValue == null) {
            return false;
          }

          const ruleValueNum = parseFloat(ruleValue);
          if (maxValue && ruleValueNum > maxValue) {
            doesViolate = true;
          }

          if (minValue && ruleValueNum < minValue) {
            doesViolate = true;
          }

          return doesViolate;
        })
        .map((ruleData) => ({
          ...ruleData,
          ruleValue: parseFloat(ruleData.ruleValue),
        })),
    }))
    .filter((employeeData) => employeeData.violatingRules.length > 0);

  const excessRules = getInvalidNumberOfRules(customRules); //TODO: add excess rules function (more than 8 rules)

  rulesWithInvalidInput.length > 0 &&
    warnings.push({
      ...DEFAULT_WARNING,
      warningType: "invalid input",
      values: rulesWithInvalidInput.map(
        ({ ruleName, ruleValue }) =>
          `${ruleName}${ruleValue === null ? "" : `(${ruleValue})`}`
      ),
      extraInfo: rulesWithInvalidInput,
    });

  rulesWithEmptyInput.length > 0 &&
    warnings.push({
      ...DEFAULT_WARNING,
      warningType: "missing input",
      values: ["Missing Input(s)"],
      extraInfo: rulesWithEmptyInput,
    });

  outOfRangeRuleValues.length > 0 &&
    warnings.push({
      ...DEFAULT_WARNING,
      warningType: "value out of range",
      values: ["Some rule values are out of range"],
      extraInfo: outOfRangeRuleValues,
    });

  excessRules.length > 0 &&
    warnings.push({
      ...DEFAULT_WARNING,
      warningType: "too many rules",
      values: excessRules,
    });

  return warnings;
}

function calculateDemandsWarnings(
  demands,
  shifts,
  shiftGroups,
  skills,
  tasks,
  subTasks,
  areas
) {
  const warnings = [];
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(demands, "shifts", [
      ...shifts,
      ...shiftGroups,
    ])
  );
  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(demands, "skills", skills)
  );

  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(
      demands,
      "tasks",
      [...tasks, ...subTasks],
      [KEYWORD_NO_TASK]
    )
  );

  warnings.push(
    getInvalidChildEntityShortIdValuesWarnings(demands, "areas", areas)
  );
  return warnings.filter((warning) => warning !== null);
}

function calculateAllocationsWarnings(
  employees,
  fieldName,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  areas,
  allowedCustomKeywords,
  acceptedInputTypes,
  allowSuffix = false,
  shortIdsToEntityNamesDicts
) {
  const warnings = [];

  if (fieldName === "Allocations" || fieldName === "AllocationsRecurring") {
    for (const employee of employees) {
      warnings.push(
        getInvalidAllocationWarnings(
          removeDuplicateStrInArr(employee[fieldName]),
          fieldName,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          areas,
          allowedCustomKeywords,
          acceptedInputTypes,
          allowSuffix,
          employee,
          shortIdsToEntityNamesDicts
        )
      );
    }
  } else {
    const allocations = [];
    for (const employee of employees) {
      allocations.push(...employee[fieldName]);
    }

    warnings.push(
      getInvalidAllocationWarnings(
        removeDuplicateStrInArr(allocations),
        fieldName,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        areas,
        allowedCustomKeywords,
        acceptedInputTypes,
        allowSuffix,
        null,
        shortIdsToEntityNamesDicts
      )
    );
  }

  return warnings.filter((warning) => warning !== null);
}
