import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import exampleRoster from "../../../assets/premadeRosters/exampleRosters/exampleRoster.json";
import { ALL_TEMPLATES } from "../../../assets/premadeRosters/templateRosters/allTemplates";
import { createUniqueSuffixedName } from "../../../utils";

export const NO_TEMPLATE = "None";

export async function getExampleRoster(locationID, existingRosterNames) {
  const rosterName = exampleRoster.name;
  let newRosterName = createUniqueSuffixedName(existingRosterNames, rosterName);

  const rosterObj = convertJSONToExampleRosterObj(
    exampleRoster,
    newRosterName,
    locationID
  );
  return rosterObj;
}

function convertJSONToExampleRosterObj(JSONRoster, newRosterName, locationID) {
  const baseRoster = _.cloneDeep(JSONRoster);
  const Employees = baseRoster.Employees.map((emp) => {
    return {
      ...emp,
      id: uuidv4(),
    };
  });

  const Demands = baseRoster.Demands.map((demand) => {
    return {
      ...demand,
      id: uuidv4(),
    };
  });

  const Shifts = baseRoster.Shifts.map((shift) => {
    return {
      ...shift,
      id: uuidv4(),
    };
  });

  const ShiftGroups = baseRoster.ShiftGroups.map((group) => {
    return {
      ...group,
      id: uuidv4(),
    };
  });

  const Skills = baseRoster.Skills.map((skill) => {
    return {
      ...skill,
      id: uuidv4(),
    };
  });

  const rosterObj = {
    id: uuidv4(),
    locationID,
    name: newRosterName,
    numDays: baseRoster.numDays,
    startDate: baseRoster.startDate,
    Employees,
    Demands,
    Shifts,
    ShiftGroups,
    CustomRules: baseRoster.CustomRules,
    RuleExceptions: baseRoster.RuleExceptions,
    Skills,
    Tasks: [],
    TaskBlocks: [],
    Statistics: baseRoster.Statistics,
    ColorCodes: baseRoster.ColorCodes,
  };

  return rosterObj;
}

function convertJSONToTemplateRosterObj(
  JSONRoster,
  locationID,
  rosterName,
  numDays,
  startDate
) {
  /**
   * Fields left empty: History, Rules, Rule Exceptions
   */
  const baseRoster = _.cloneDeep(JSONRoster);
  const Employees = baseRoster.Employees.map((emp) => {
    const Allocations = Array(numDays).fill("");
    emp.Allocations.forEach((allo, idx) => {
      if (idx < numDays) {
        Allocations[idx] = allo;
      }
    });

    const Days = Array(numDays).fill("");
    emp.Days.forEach((day, idx) => {
      if (idx < numDays) {
        Days[idx] = day;
      }
    });

    const RosteredAllocations = Array(numDays).fill("");

    return {
      ...emp,
      Allocations,
      Days,
      RosteredAllocations,
      id: uuidv4(),
    };
  });

  const Demands = baseRoster.Demands.map((dem) => {
    let values = [];

    for (let d = 0; d < numDays; d++) {
      values.push(dem.values[d % dem.values.length]);
    }

    return {
      ...dem,
      values,
    };
  });

  const Shifts = baseRoster.Shifts.map((shift) => {
    return {
      ...shift,
      id: uuidv4(),
    };
  });

  const ShiftGroups = baseRoster.ShiftGroups.map((group) => {
    return {
      ...group,
      id: uuidv4(),
    };
  });

  const Skills = baseRoster.Skills.map((skill) => {
    return {
      ...skill,
      id: uuidv4(),
    };
  });

  const rosterObj = {
    id: uuidv4(),
    locationID,
    name: rosterName,
    numDays: numDays,
    startDate: startDate.toFormat("AWS"),
    Employees,
    Demands,
    Shifts,
    ShiftGroups,
    CustomRules: baseRoster.CustomRules,
    RuleExceptions: baseRoster.RuleExceptions,
    Skills,
    Statistics: baseRoster.Statistics,
    ColorCodes: baseRoster.ColorCodes,
    Tasks: baseRoster.Tasks,
    TaskBlocks: baseRoster.TaskBlocks,
  };

  return rosterObj;
}

export function getAllTemplateNames() {
  return [NO_TEMPLATE, ...ALL_TEMPLATES.map((template) => template.name)];
}

function getTemplateJSONByName(templateName) {
  const template = ALL_TEMPLATES.find((t) => t.name === templateName);
  if (!template) {
    return null;
  }
  return template;
}

export function createRosterUsingTemplate(
  templateName,
  customRosterName,
  numWeeks,
  startDate,
  locationID
) {
  const template = getTemplateJSONByName(templateName);
  const rosterObj = convertJSONToTemplateRosterObj(
    template,
    locationID,
    customRosterName,
    numWeeks * 7,
    startDate
  );

  return rosterObj;
}
