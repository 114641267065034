import { useEffect, useMemo, useRef, useState } from "react";
import DataEntryTable from "../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import styles from "./ManageSnapshotsModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  DateTime,
  deepCopyObject,
  getAllSnapshotsInfoFromGrid,
} from "../../../../utils";
import ActionBar from "../../../../components/elements/ActionBar/ActionBar";
import { useQueryClient } from "@tanstack/react-query";

const OpenButton = ({ openSnapshotPage, currentSnapshotId, data }) => {
  const { id } = data;

  if (id === currentSnapshotId) {
    return <p className={styles.inViewLabel}>In view</p>;
  }

  return (
    <div className={styles.rendererContainer}>
      <button
        className={`${styles.rendererButton} ${styles.openButton}`}
        onClick={() => openSnapshotPage(id)}
      >
        Open
      </button>
    </div>
  );
};
const DuplicateButton = ({ duplicateSnapshot, data }) => {
  const { id, locationID } = data;
  const newName = `Copy of ${data.name}`;

  return (
    <div className={styles.rendererContainer}>
      <button
        className={styles.rendererButton}
        onClick={() => duplicateSnapshot(locationID, id, newName)}
      >
        <FontAwesomeIcon className={styles.icon} icon={faCopy} />
      </button>
    </div>
  );
};
const DownloadButton = ({ downloadSnapshot, data }) => {
  const { id } = data;
  return (
    <div className={styles.rendererContainer}>
      <button
        className={styles.rendererButton}
        onClick={() => downloadSnapshot(id)}
      >
        <FontAwesomeIcon className={styles.icon} icon={faDownload} />
      </button>
    </div>
  );
};

const ManageSnapshotsModal = ({
  locationID,
  snapshotRosters,
  openSnapshotPage,
  downloadSnapshot,
  duplicateSnapshot,
  deleteSnapshots,
  updateSnapshots,
  currentSnapshotId,
}) => {
  const queryClient = useQueryClient();

  // This is to update "last updated time" column data
  useEffect(() => {
    queryClient.invalidateQueries(["location", locationID]);
  }, [queryClient, locationID]);

  const [selectedRows, setSelectedRows] = useState([]);
  const componentRef = useRef();

  const columnDefs = useMemo(
    () => [
      {
        sortable: true,
        headerName: "Version name",
        field: "name",
        width: 300,

        showDisabledCheckboxes: true,
        checkboxSelection: ({ data }) => {
          return data.id !== currentSnapshotId;
        },
        headerCheckboxSelection: true,
      },
      {
        sortable: true,
        headerName: "Time created",
        field: "createdAt",
        width: 150,
        valueFormatter: (params) => {
          const { createdAt } = params.data;
          const dateLabel = new DateTime(createdAt).toFormat("version-date");
          const timeLabel = DateTime.getTimeFormatter(createdAt);
          return `${dateLabel} ${timeLabel}`;
        },
        editable: false,
      },
      {
        sortable: true,
        headerName: "Time last updated",
        field: "updatedAt",
        width: 150,
        valueFormatter: (params) => {
          const { updatedAt } = params.data;
          const dateLabel = new DateTime(updatedAt).toFormat("version-date");
          const timeLabel = DateTime.getTimeFormatter(updatedAt);
          return `${dateLabel} ${timeLabel}`;
        },
        editable: false,
      },
      {
        sortable: false,
        headerName: "Open",
        field: "edit",
        cellRenderer: (props) => (
          <OpenButton
            {...props}
            openSnapshotPage={openSnapshotPage}
            currentSnapshotId={currentSnapshotId}
          />
        ),
        editable: false,
      },
      {
        sortable: false,
        field: "duplicate",
        cellRenderer: (props) => (
          <DuplicateButton {...props} duplicateSnapshot={duplicateSnapshot} />
        ),
        editable: false,
      },
      {
        sortable: false,
        field: "download",
        cellRenderer: (props) => (
          <DownloadButton {...props} downloadSnapshot={downloadSnapshot} />
        ),
        editable: false,
      },
    ],
    [downloadSnapshot, duplicateSnapshot, openSnapshotPage, currentSnapshotId]
  );

  const rowData = useMemo(
    () => snapshotRosters.map((snapshot) => deepCopyObject(snapshot)),
    [snapshotRosters]
  );

  return (
    <div ref={componentRef}>
      <ActionBar
        deleteSelectedSettings={{
          selectedRows,
          removeSelectedRows: () => {
            const toBeDeleted = selectedRows
              .map((row) => row.data.id)
              .filter((id) => id !== currentSnapshotId);
            deleteSnapshots(toBeDeleted);
          },
        }}
      />
      <DataEntryTable
        handleCellValueChanged={() => {}}
        columnDefs={columnDefs}
        customStyle={{ height: "300px" }}
        rowData={rowData}
        updateData={(data) => updateSnapshots(data)}
        getContextMenuItems={() => {}}
        gridOptions={{
          onSelectionChanged: (params) => {
            setSelectedRows(params.api.getSelectedNodes());
          },
          suppressRowClickSelection: true,
          rowSelection: "multiple",
        }}
        getDataFromGrid={getAllSnapshotsInfoFromGrid}
      />
    </div>
  );
};

export default ManageSnapshotsModal;
