import { useState } from "react";
import Select from "react-select";

const SelectBox = ({ options, onChange, value }) => {
  const [firstClick, setFirstClick] = useState(true);

  const getBorderColor = () => {
    if (!firstClick && !value) {
      return "red";
    }
    return null;
  };

  return (
    <Select
      options={options}
      onChange={onChange}
      value={value}
      styles={{
        control: (baseStyles) => {
          return {
            ...baseStyles,
            ...(getBorderColor() && { borderColor: getBorderColor() }),
          };
        },
      }}
      onBlur={() => setFirstClick(false)}
    />
  );
};

export default SelectBox;
