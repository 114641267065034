import ModalFrame from "../ModalFrame/ModalFrame";
import Select from "react-select";
import styles from "./OpenShiftsModal.module.css";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  DateTime,
  convertSingleOptionToPropForm,
  convertToNameIdOptionForm,
  convertToShortIdNameOptionForm,
  getOptionValues,
  strToArrCommaSeparated,
} from "../../../../utils";
import { useEffect, useMemo, useState } from "react";
import { CheckboxOption } from "../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import {
  getOpenShiftStatusInfo,
  getOpenShiftTemplate,
} from "../../../../utils/queryUtils/locationDataGetters";
import { components } from "react-select";
import ConfirmBox from "../../../../components/elements/ConfirmBox/ConfirmBox";
import { reactSelectStyles } from "../../../../styles/reactSelectStyles";
import {
  findEntityByShortId,
  findMultipleEntitiesByShortIds,
} from "../../../rosterProblems/service/rosterUtils";
import { KEYWORD_ALL, KEYWORD_NO_TASK } from "../../../../constants/keywords";
import {
  EMPLOYEE_OPTION_STATE,
  getEmployeeStateForOpenShift,
} from "../../service/openShifts";

const getMultiItemReadOnlyInputLabel = (items, pluralItemLabel) => {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0];
  return `${items.length} ${pluralItemLabel} selected`;
};

const SkillsValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props} itemName="skills">
    {children}
  </ValueContainer>
);

const EmployeesValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props} itemName="employees">
    {children}
  </ValueContainer>
);

const ValueContainer = ({ children, itemName, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    values =
      values.length === 1
        ? values[0].props.children
        : `${values.length} ${itemName} selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const OpenShiftsModal = ({
  open,
  onClose,
  skills,
  shifts,
  tasks,
  shiftGroups,
  globalEmployees,
  createOpenShift,
  updateOpenShift,
  openShifts,
  selectedOpenShiftInfo,
  setSelectedOpenShiftInfo,
  deleteOpenShift,
  areas,
  customKeywordsUtilObj,
}) => {
  const isNotReady = globalEmployees.length === 0 || shifts.length === 0;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { date, selectedOpenShiftID, selectedOpenShiftDisplayedInfo } =
    selectedOpenShiftInfo;
  const isUpdateModal = selectedOpenShiftID ? true : false;

  const selectedOpenShift = useMemo(() => {
    const selectedModel = openShifts.find(
      (openShift) => openShift.id === selectedOpenShiftID
    );
    return selectedModel ? selectedModel : null;
  }, [openShifts, selectedOpenShiftID]);

  const isPastDateSelected = selectedOpenShift
    ? new DateTime().isAfter(selectedOpenShift.date)
    : false;

  const areaOptions = useMemo(
    () => convertToShortIdNameOptionForm(areas),
    [areas]
  );
  const shiftOptions = useMemo(
    () => convertToShortIdNameOptionForm(shifts),
    [shifts]
  );
  const skillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );
  const NO_TASK_OPTION = useMemo(
    () => convertSingleOptionToPropForm(KEYWORD_NO_TASK),
    []
  );
  const taskOptions = useMemo(
    () => [NO_TASK_OPTION, ...convertToShortIdNameOptionForm(tasks)],
    [tasks, NO_TASK_OPTION]
  );
  const employeeOptions = useMemo(
    () => convertToNameIdOptionForm(globalEmployees),
    [globalEmployees]
  );

  const getShiftSelectionFromArea = (area, defaultShiftOption) => {
    const areaShifts = strToArrCommaSeparated(area.shifts);

    if (
      areaShifts.length > 0 &&
      !areaShifts.includes(KEYWORD_ALL) &&
      !areaShifts.includes(defaultShiftOption.value)
    ) {
      const chosenShiftOption = shiftOptions.find(
        ({ value }) => value === areaShifts[0]
      );
      if (chosenShiftOption) return chosenShiftOption;
    }
    return defaultShiftOption;
  };

  const getTaskSelectionFromArea = (area, defaultTaskOption) => {
    const areaTasks = strToArrCommaSeparated(area.tasks);

    if (areaTasks.length === 0) {
      return NO_TASK_OPTION;
    }

    if (
      !areaTasks.includes(KEYWORD_ALL) &&
      !areaTasks.includes(defaultTaskOption.value)
    ) {
      const chosenTaskOption = taskOptions.find(
        ({ value }) => value === areaTasks[0]
      );
      return chosenTaskOption;
    }

    return defaultTaskOption;
  };

  const getSkillsSelectionFromArea = (area) => {
    const areaSkills = strToArrCommaSeparated(area.skills);

    if (areaSkills.length === 0 || areaSkills.includes(KEYWORD_ALL)) {
      return [];
    }

    return skillOptions.filter(({ value }) => areaSkills.includes(value));
  };

  const [selectedArea, setSelectedArea] = useState(() => {
    if (isNotReady || areas.length === 0) return null;
    if (!selectedOpenShift) return areaOptions[0];
    const selectedOption = areaOptions.find(
      ({ value }) => value === selectedOpenShift.area
    );
    return selectedOption || areaOptions[0];
  });

  const [selectedShift, setSelectedShift] = useState(() => {
    if (isNotReady) return null;
    if (!selectedOpenShift) {
      if (selectedArea) {
        const selectedAreaEntity = findEntityByShortId(
          areas,
          selectedArea.value
        );
        return getShiftSelectionFromArea(selectedAreaEntity, shiftOptions[0]);
      }
      return shiftOptions[0];
    }

    const selectedOption = shiftOptions.find(
      ({ value }) => value === selectedOpenShift.shift
    );
    if (selectedOption) return selectedOption || shiftOptions[0];
  });

  const [selectedTask, setSelectedTask] = useState(() => {
    if (!selectedOpenShift) {
      if (selectedArea) {
        const selectedAreaEntity = findEntityByShortId(
          areas,
          selectedArea.value
        );
        return getTaskSelectionFromArea(selectedAreaEntity, taskOptions[0]);
      }
      return taskOptions[0];
    }
    const selectedOption = taskOptions.find(
      ({ value }) => value === selectedOpenShift.task
    );
    if (selectedOption) return selectedOption || taskOptions[0];
  });

  const [selectedSkills, setSelectedSkills] = useState(() => {
    if (isNotReady) return [];
    if (!selectedOpenShift) {
      if (selectedArea) {
        const selectedAreaEntity = findEntityByShortId(
          areas,
          selectedArea.value
        );
        return getSkillsSelectionFromArea(selectedAreaEntity);
      }
      return [];
    }
    const openShiftSkills = strToArrCommaSeparated(selectedOpenShift.skills);
    const selectedOptions = skillOptions.filter(({ value }) =>
      openShiftSkills.includes(value)
    );
    return selectedOptions;
  });

  const selectedAreaEntity = useMemo(
    () => findEntityByShortId(areas, selectedArea?.value),
    [areas, selectedArea]
  );
  const selectedShiftEntity = useMemo(
    () => findEntityByShortId(shifts, selectedShift?.value),
    [shifts, selectedShift]
  );
  const selectedSkillsEntities = useMemo(
    () =>
      findMultipleEntitiesByShortIds(skills, getOptionValues(selectedSkills)),
    [skills, selectedSkills]
  );
  const selectedTaskEntity = useMemo(
    () => findEntityByShortId(tasks, selectedTask.value),
    [tasks, selectedTask]
  );

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [numOpenShifts, setNumOpenShifts] = useState(
    selectedOpenShiftDisplayedInfo ? selectedOpenShiftDisplayedInfo.number : 1
  );

  useEffect(() => {
    if (isNotReady) return;

    const notAskedEmployees = globalEmployees.filter((globalEmployee) => {
      const state = getEmployeeStateForOpenShift(
        date,
        globalEmployee,
        shifts,
        shiftGroups,
        openShifts,
        selectedOpenShift,
        selectedAreaEntity,
        selectedShiftEntity,
        selectedSkillsEntities,
        selectedTaskEntity,
        customKeywordsUtilObj
      );

      return state === EMPLOYEE_OPTION_STATE.notAsked;
    });

    setSelectedEmployees(convertToNameIdOptionForm(notAskedEmployees));
  }, [
    isNotReady,
    selectedOpenShift,
    selectedShift,
    selectedSkills,
    selectedTask,
    date,
    globalEmployees,
    openShifts,
    selectedAreaEntity,
    selectedShiftEntity,
    selectedSkillsEntities,
    selectedTaskEntity,
    shifts,
    shiftGroups,
    customKeywordsUtilObj,
  ]);

  const getReasonWhyNotReady = () => {
    if (globalEmployees.length === 0) {
      return "Please add employees to create open shifts";
    }
    if (shifts.length === 0) {
      return "Please add shifts to create open shifts";
    }
  };

  const handleCreate = () => {
    if (selectedEmployees.length === 0) {
      alert("Please select at least one employee");
      return;
    }
    if (numOpenShifts < 1) {
      alert("Num. of open shifts need to be 1 or more");
      return;
    }

    const openShift = getOpenShiftTemplate({
      date,
      shift: selectedShift.value,
      skills: selectedSkills
        .map((selectedSkill) => selectedSkill.value)
        .join(","),
      task: selectedTask.value,
      area: selectedArea ? selectedArea.value : "",
      number: numOpenShifts,
      selectedEmployeeIDs: selectedEmployees.map(
        (selectedEmp) => selectedEmp.value
      ),
    });

    createOpenShift(openShift);
  };

  const handleUpdate = () => {
    if (selectedEmployees.length === 0) {
      alert("Please select at least one employee");
      return;
    }

    const originalEmployeeStates = selectedOpenShift.employeeStates;
    const originalStateEmployeeIDs = originalEmployeeStates.map(
      (state) => state.employeeID
    );
    const selectedEmployeeIDs = selectedEmployees.map(
      (selectedEmp) => selectedEmp.value
    );
    const newEmployeeStates = selectedEmployeeIDs
      .filter((selectedID) => !originalStateEmployeeIDs.includes(selectedID))
      .map((selectedID) => ({
        employeeID: selectedID,
        isTicked: true,
        state: "not-asked",
      }));

    const modifiedEmployeeStates = originalEmployeeStates
      .map((employeeState) => {
        if (!selectedEmployeeIDs.includes(employeeState.employeeID)) {
          return {
            ...employeeState,
            isTicked: false,
          };
        }
        return employeeState;
      })
      .filter((employeeState) => {
        const { employeeID, state } = employeeState;
        if (
          !selectedEmployeeIDs.includes(employeeID) &&
          state === "not-asked"
        ) {
          return false;
        }
        return true;
      });

    const updatedEmployeeStates = [
      ...modifiedEmployeeStates,
      ...newEmployeeStates,
    ];

    const { numAccepted } = getOpenShiftStatusInfo(selectedOpenShift);
    const openShiftUpdatedFields = {
      id: selectedOpenShift.id,
      number: numOpenShifts + numAccepted,
      employeeStates: updatedEmployeeStates,
      isPublished: false,
    };

    updateOpenShift(openShiftUpdatedFields);
  };

  const handleDelete = () => {
    deleteOpenShift(selectedOpenShift.id);
  };

  const onDeleteButtonClick = async () => {
    if (!selectedOpenShift) {
      return;
    }

    setShowConfirmDelete(true);
  };

  const EmployeeOption = ({ label, data, ...props }) => {
    const getLabelComponent = () => {
      const { value: employeeID } = data;

      const targetEmployee = globalEmployees.find(
        (employee) => employee.id === employeeID
      );

      let state = getEmployeeStateForOpenShift(
        date,
        targetEmployee,
        shifts,
        shiftGroups,
        openShifts,
        selectedOpenShift,
        selectedAreaEntity,
        selectedShiftEntity,
        selectedSkillsEntities,
        selectedTaskEntity,
        customKeywordsUtilObj
      );
      let label;
      let color;
      let backgroundColor;

      switch (state) {
        case "not-asked":
          label = "Not asked";
          color = "white";
          backgroundColor = "#5C5C5C";
          break;
        case "working":
          label = "Working";
          color = "black";
          backgroundColor = "#84EAEA";
          break;
        case "ineligible":
          label = "Ineligible";
          color = "black";
          backgroundColor = "#D8D8D8";
          break;
        case "pending":
          label = "Pending";
          color = "black";
          backgroundColor = "#FCF43A";
          break;
        case "accepted":
          label = "Accepted";
          color = "black";
          backgroundColor = "#7FF5A8";
          break;
        case "declined":
          label = "Declined";
          color = "white";
          backgroundColor = "#FF3434";
          break;
      }

      return (
        <span
          style={{
            position: "absolute",
            right: 0,
            backgroundColor,
            color,
            padding: "2px 5px",
            fontSize: "14px",
            width: "80px",
            textAlign: "center",
          }}
        >
          {label}
        </span>
      );
    };

    return (
      <components.Option label={label} {...props}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            onChange={() => null}
            checked={props.isSelected}
            style={{ marginRight: "10px" }}
          />
          <label style={{ margin: "0", marginRight: "10px" }}>{label}</label>
          {getLabelComponent()}
        </div>
      </components.Option>
    );
  };

  const getOpenShiftStatusLabelComponent = () => {
    if (!selectedOpenShift || !selectedOpenShift.isPublished) {
      return (
        <span className={`${styles.status} ${styles.unpublished}`}>
          Unpublished
        </span>
      );
    }

    if (
      selectedOpenShiftDisplayedInfo &&
      selectedOpenShiftDisplayedInfo.isAccepted
    ) {
      return (
        <span className={`${styles.status} ${styles.fulfilled}`}>
          Published - Fulfilled
        </span>
      );
    }

    return (
      <span className={`${styles.status} ${styles.unfulfilled}`}>
        Published - Unfulfilled
      </span>
    );
  };

  let isConfirmButtonDisabled = false;
  if (
    selectedOpenShiftDisplayedInfo &&
    selectedOpenShiftDisplayedInfo.isAccepted
  ) {
    isConfirmButtonDisabled = true;
  }

  const handleAreaSelect = (selectedInput) => {
    setSelectedArea(selectedInput);

    const area = areas.find(({ shortId }) => shortId === selectedInput.value);
    if (!area) {
      return;
    }

    const chosenShiftOption = getShiftSelectionFromArea(area, selectedShift);
    setSelectedShift(chosenShiftOption);

    // apply to task selection
    const chosenTaskOption = getTaskSelectionFromArea(area, selectedTask);
    setSelectedTask(chosenTaskOption);

    // apply to area selection
    const chosenSkillOptions = getSkillsSelectionFromArea(area);
    setSelectedSkills(chosenSkillOptions);
  };

  return (
    <ModalFrame open={open} onClose={onClose} shouldCloseOnOutsideClick={false}>
      {showConfirmDelete && (
        <div className={styles.deleteAlert}>
          <ConfirmBox
            heading="Are you sure?"
            description="Deleted open shifts will also be notified to your staff who have already picked up the shift."
            okLabel="Delete open shift"
            cancelLabel="Cancel"
            onOk={handleDelete}
            onCancel={() => {
              setShowConfirmDelete(false);
            }}
            customStyle={{
              width: "400px",
            }}
          />
        </div>
      )}
      <button className={styles.closeBtn} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Open Shifts</h2>
          <span className={styles.status}>
            {getOpenShiftStatusLabelComponent()}
          </span>
        </div>
        {isNotReady ? (
          <p>{getReasonWhyNotReady()}</p>
        ) : (
          <>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={`${styles.field}`}>
                  <span>Date*</span>
                  {selectedOpenShift ? (
                    <input
                      className={styles.readonlyInput}
                      type="text"
                      readOnly={true}
                      value={new DateTime(date).toFormat("displayed-full")}
                    />
                  ) : (
                    <DatePicker
                      className={styles.datePicker}
                      calendarStartDay={1}
                      dateFormat="dd/MM/yyyy"
                      selected={new DateTime(date).getDate()}
                      style={{ flex: 1, border: "1px solid #219ec9" }}
                      onChange={(date) => {
                        const selectedDate = new DateTime(date).toFormat("AWS");
                        setSelectedOpenShiftInfo({
                          date: selectedDate,
                          selectedOpenShiftID,
                        });
                      }}
                    />
                  )}
                </div>
                <div className={styles.field}>
                  <span>Open shift (shift type)*</span>
                  {selectedOpenShift ? (
                    <input
                      className={styles.readonlyInput}
                      type="text"
                      value={selectedShift.label}
                      readOnly={true}
                    />
                  ) : (
                    <Select
                      label="shift"
                      id="shift"
                      value={selectedShift}
                      styles={reactSelectStyles}
                      options={shiftOptions}
                      onChange={(selectedInput) =>
                        setSelectedShift(selectedInput)
                      }
                      maxMenuHeight={150}
                    />
                  )}
                </div>
              </div>
              <div className={styles.row}>
                {areas.length > 0 && (
                  <div className={styles.field}>
                    <span>Area* </span>
                    {selectedOpenShift ? (
                      <input
                        className={styles.readonlyInput}
                        type="text"
                        value={selectedArea.label}
                        readOnly={true}
                      />
                    ) : (
                      <Select
                        label="areas"
                        id="areas"
                        value={selectedArea}
                        styles={reactSelectStyles}
                        options={areaOptions}
                        onChange={handleAreaSelect}
                        maxMenuHeight={150}
                      />
                    )}
                  </div>
                )}
                <div className={styles.field}>
                  <span>Skills (optional)</span>
                  {selectedOpenShift ? (
                    <input
                      className={styles.readonlyInput}
                      type="text"
                      value={getMultiItemReadOnlyInputLabel(
                        selectedSkills.map((item) => item.label),
                        "skills"
                      )}
                      readOnly={true}
                    />
                  ) : (
                    <Select
                      label="skills"
                      id="skills"
                      styles={reactSelectStyles}
                      options={skillOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(selectedInputs) =>
                        setSelectedSkills(selectedInputs)
                      }
                      hideSelectedOptions={false}
                      components={{
                        Option: CheckboxOption,
                        ValueContainer: SkillsValueContainer,
                      }}
                      value={selectedSkills}
                      maxMenuHeight={150}
                    />
                  )}
                </div>
                <div className={styles.field}>
                  <span>Task (optional)</span>
                  {selectedOpenShift ? (
                    <input
                      className={styles.readonlyInput}
                      type="text"
                      value={selectedTask.label}
                      readOnly={true}
                    />
                  ) : (
                    <Select
                      label="task"
                      id="task"
                      value={selectedTask}
                      styles={reactSelectStyles}
                      options={taskOptions}
                      onChange={(selectedInput) =>
                        setSelectedTask(selectedInput)
                      }
                      maxMenuHeight={150}
                    />
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <span>Accepted by*</span>
                  {isPastDateSelected ||
                  (selectedOpenShiftDisplayedInfo &&
                    selectedOpenShiftDisplayedInfo.isAccepted) ? (
                    <input
                      className={styles.readonlyInput}
                      type="text"
                      value={getMultiItemReadOnlyInputLabel(
                        selectedEmployees.map((item) => item.label),
                        "employees"
                      )}
                      readOnly={true}
                    />
                  ) : (
                    <Select
                      label="employees"
                      id="employees"
                      aria-readonly={true}
                      isMulti
                      options={employeeOptions}
                      closeMenuOnSelect={false}
                      onChange={(selectedInputs) =>
                        setSelectedEmployees(selectedInputs)
                      }
                      hideSelectedOptions={false}
                      styles={reactSelectStyles}
                      components={{
                        Option: EmployeeOption,
                        ValueContainer: EmployeesValueContainer,
                      }}
                      value={selectedEmployees}
                      maxMenuHeight={150}
                    />
                  )}
                </div>
                <div className={`${styles.horizontalField}`}>
                  <div className={styles.openShiftNumFieldContainer}>
                    <span>Num. of open shifts*</span>
                    <input
                      value={numOpenShifts}
                      className={`${
                        selectedOpenShiftDisplayedInfo &&
                        (selectedOpenShiftDisplayedInfo.isAccepted ||
                          isPastDateSelected)
                          ? styles.readonlyInput
                          : styles.openShiftNumInput
                      }`}
                      readOnly={
                        selectedOpenShiftDisplayedInfo &&
                        (selectedOpenShiftDisplayedInfo.isAccepted ||
                          isPastDateSelected)
                          ? true
                          : false
                      }
                      type="number"
                      min={0}
                      onChange={(e) =>
                        setNumOpenShifts(Math.ceil(Number(e.target.value)))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {isPastDateSelected ? (
              <p className={styles.pastOpenShiftLabel}>
                You cannot edit open shifts in the past
              </p>
            ) : (
              <div className={styles.bottom}>
                <p className={styles.note}>
                  * Open shifts will be sent out to staff when
                  &quot;Publish&quot;
                </p>
                <div className={styles.bottomButtons}>
                  {!isConfirmButtonDisabled && (
                    <button
                      className={`${styles.createBtn}`}
                      onClick={selectedOpenShift ? handleUpdate : handleCreate}
                    >
                      {isUpdateModal
                        ? "Update Open Shift(s)"
                        : "Create Open Shift(s)"}
                    </button>
                  )}
                  <button
                    className={styles.deleteBtn}
                    onClick={selectedOpenShift ? onDeleteButtonClick : onClose}
                  >
                    {selectedOpenShift ? "Delete Open Shift" : "Close"}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalFrame>
  );
};

export default OpenShiftsModal;
