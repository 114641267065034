/**
 * Scope: Single Roster (roster fields)
 */
import {
  asyncGenerateRoster,
  checkRoster,
  getRosterSolution,
  rosterSolutionByRoster,
} from "../../graphql/queries";
import {
  createRosterSolution,
  deleteRosterSolution,
} from "../../graphql/mutations";
import { queueTask } from "../performanceUtils/queue";
import { graphqlErrorHandler } from "./generalQueryUtil";
import { getEntitiesByParentId } from "./locationQuery";
import {
  getCurrentAuthUser,
  isUserInCollaboratorGroup,
  isUserInCoordinatorGroup,
  shouldUseCustomAuthLambda,
} from "../../features/auth/service/auth";
import {
  getRosterNoSolutions,
  getRostersWithEmployeesOnly,
  getRosterVersion,
} from "../graphqlUtils/customQueries";
import { updateRosterNoSolutions } from "../graphqlUtils/customMutations";

export async function getRosterModelById(id) {
  const solutionsPromise = fetchAllSolutionsForRoster(id);
  const roster = await graphqlErrorHandler(
    getRosterNoSolutions,
    {
      id,
    },
    (data) => data.getRoster,
    null,
    "getRosterModelById"
  );

  roster.Solutions = {
    items: await solutionsPromise,
  };

  return roster;
}

async function fetchAllSolutionsForRoster(rosterID) {
  let nextToken = null;
  let allSolutions = [];

  do {
    try {
      const response = await graphqlErrorHandler(
        rosterSolutionByRoster,
        {
          rosterID: rosterID,
          nextToken: nextToken,
          filter: {
            _deleted: {
              ne: true,
            },
          },
        },
        (data) => data.rosterSolutionByRoster,
        null,
        "rosterSolutionByRoster"
      );

      const solutions = response.items;
      allSolutions = [...allSolutions, ...solutions];
      nextToken = response.nextToken;
    } catch (error) {
      console.error("Error fetching solutions:", error);
      nextToken = null;
    }
  } while (nextToken);

  return allSolutions;
}

export async function getAllMainStreamRostersForPropagation(locationID) {
  const rosters = await getEntitiesByParentId(
    getRostersWithEmployeesOnly,
    "locationID",
    locationID,
    "getRostersWithEmployeesOnly",
    (data) => data.rosterByLocation,
    {
      and: [
        {
          _deleted: {
            ne: true,
          },
        },
        {
          isSnapshot: {
            ne: true,
          },
        },
      ],
    }
  );

  return rosters.filter(({ isSnapshot }) => !isSnapshot);
}

export async function getRosterVersionById(id) {
  return graphqlErrorHandler(
    getRosterVersion,
    {
      id,
    },
    (data) => data.getRoster._version,
    null,
    "getRosterVersionById"
  );
}

export async function getSolutionModelById(id) {
  return graphqlErrorHandler(
    getRosterSolution,
    {
      id,
    },
    (data) => data.getRosterSolution,
    null,
    "getSolutionModelById"
  );
}

export async function deleteSolutionModel(id) {
  const originalSolution = await getSolutionModelById(id);
  const _version = originalSolution._version;
  return graphqlErrorHandler(
    deleteRosterSolution,
    {
      input: {
        id: id,
        _version,
      },
    },
    (data) => data.deleteRosterSolution,
    null,
    "deleteSolutionModel"
  );
}

export async function deleteSolutionModels(ids) {
  for (const id of ids) {
    await deleteSolutionModel(id);
  }
}

export async function createSolutionModel(solutionInfo, customOwner) {
  const user = await getCurrentAuthUser();
  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(user);

  const canOwnRoster =
    !isUserInCoordinatorGroup(user) && !isUserInCollaboratorGroup(user);

  if (!canOwnRoster && !customOwner) {
    throw new Error(
      "Current logged in user cannot own solution model. Provide a `customOwner` that matches the owner of the location"
    );
  }

  return graphqlErrorHandler(
    createRosterSolution,
    {
      input: {
        ...solutionInfo,
        ...(shouldUseCustomAuthMode && {
          // custom auth lambda requires manual owner insertion
          owner: customOwner || user.session.accessToken.payload.sub,
        }),
      },
    },
    (data) => data.createRosterSolution,
    null,
    "createSolutionModel"
  );
}

export async function updateRosterModel(rosterID, updatedFields) {
  const task = async (rosterID, updatedFields) => {
    const _version = await getRosterVersionById(rosterID);
    return graphqlErrorHandler(
      updateRosterNoSolutions,
      {
        input: {
          id: rosterID,
          ...updatedFields,
          _version,
        },
      },
      (data) => data.updateRoster,
      null,
      "updateRosterModel"
    );
  };

  return await queueTask(task, rosterID, updatedFields);
}

export async function generateRosterSolution(
  rosteringProblem,
  rosterID,
  solutionID,
  email,
  rosterName,
  requestNotifications,
  solveSettings,
  oldSolutionJSON = null
) {
  return graphqlErrorHandler(
    asyncGenerateRoster,
    {
      rosterJSON: JSON.stringify(rosteringProblem),
      rosterID,
      solutionID,
      email,
      name: rosterName,
      requestNotifications,
      solveSettings,
      oldSolutionJSON: JSON.stringify(oldSolutionJSON),
    },
    (data) => ({
      data,
    }),
    null,
    "generateRosterSolution",
    true
  );
}

export async function checkRosterWarnings(rosteringProblem, solveSettings) {
  return graphqlErrorHandler(
    checkRoster,
    {
      rosterJSON: JSON.stringify(rosteringProblem),
      solveSettings: solveSettings,
      checkType: "presolveWarnings",
    },
    (data) => ({
      data,
    }),
    null,
    "checkRosterWarnings",
    true
  );
}

export async function checkShiftGroupAllocations(
  rosteringProblem,
  solveSettings,
  shiftGroupIndex
) {
  return graphqlErrorHandler(
    checkRoster,
    {
      rosterJSON: JSON.stringify(rosteringProblem),
      solveSettings: solveSettings,
      checkType: "shiftGroupAllocations",
      index: shiftGroupIndex,
    },
    (data) => ({
      data,
    }),
    null,
    "checkShiftGroupAllocations",
    true
  );
}

export async function checkDemandAllocations(
  rosteringProblem,
  solveSettings,
  demandIndex
) {
  return graphqlErrorHandler(
    checkRoster,
    {
      rosterJSON: JSON.stringify(rosteringProblem),
      solveSettings: solveSettings,
      checkType: "demandAllocations",
      index: demandIndex,
    },
    (data) => ({
      data,
    }),
    null,
    "checkDemandAllocations",
    true
  );
}

export async function checkWhyNotThisAllocation(
  rosteringProblem,
  solveSettings,
  employeeName,
  shiftName,
  taskName,
  day
) {
  return graphqlErrorHandler(
    checkRoster,
    {
      rosterJSON: JSON.stringify(rosteringProblem),
      solveSettings: solveSettings,
      checkType: "whyNotThisAllocation",
      employeeName,
      shiftName,
      taskName,
      index: day,
    },
    (data) => ({
      data,
    }),
    null,
    "checkWhyNotThisAllocation",
    true
  );
}
