import { rulesManagementSettings } from "../components/GlobalSettings/globalSettingsList";

export const checkRulesWithExceptionEnabled = (frontendSettings) => {
  if (!frontendSettings) {
    return false;
  }
  const settings = frontendSettings;
  const withExceptionsSetting = settings.filter(
    (s) => s.name === rulesManagementSettings[0].name
  );
  if (withExceptionsSetting.length > 0) {
    return true;
  }
  return false;
};
