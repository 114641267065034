import styles from "./IntroductionPage.module.css";
import logo from "../../../../assets/images/logo_coloured.png";
import whiteLogo from "../../../../assets/images/logo_white.png";
import jellyLogo from "../../../../assets/images/jelly-logo.png";
import { useWindowWidth } from "../../../../hooks/useWindowWidth";
import { useState } from "react";
import Plans from "../Plans/Plans";
import StartTrialForm from "../StartTrialForm/StartTrialForm";

const IntroductionPageIPadHeader = ({ headerMessage }) => (
  <div className={styles.mobileHeader}>
    <img src={whiteLogo} alt="RosterLab logo" className={styles.whiteLogo} />
    <h1 className={styles.mobileHeaderTitle}>{headerMessage}</h1>
    <div className={styles.divider}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          className={styles.fill}
        ></path>
      </svg>
    </div>
  </div>
);

const IntroductionPageDesktopHeader = ({ headerMessage }) => {
  return (
    <div className={styles.desktopHeader}>
      <img src={logo} alt="RosterLab logo" className={styles.coloredLogo} />
      <h1 className={styles.desktopHeaderTitle}>{headerMessage}</h1>
    </div>
  );
};

const INTRODUCTION_PAGES = Object.freeze({
  plans: "plans",
  startTrial: "startTrial",
});

const IntroductionPage = ({
  userName,
  ruleTemplates,
  createNewScheduleViewLocation,
  locationNames,
  setShouldShowTrial,
}) => {
  const [page, setPage] = useState(INTRODUCTION_PAGES.plans);
  const IPAD_WIDTH = 1024;
  const { width } = useWindowWidth();
  const isDesktop = IPAD_WIDTH < width;

  const goToStartTrialPage = () => setPage(INTRODUCTION_PAGES.startTrial);

  const getContent = () => {
    if (page === INTRODUCTION_PAGES.plans) {
      return <Plans handleStartFreeTrialButton={goToStartTrialPage} />;
    }
    return (
      <StartTrialForm
        ruleTemplates={ruleTemplates}
        createNewScheduleViewLocation={createNewScheduleViewLocation}
        locationNames={locationNames}
        setShouldShowTrial={setShouldShowTrial}
      />
    );
  };

  const headerMessage =
    page === INTRODUCTION_PAGES.plans
      ? `Hi ${userName}, Welcome to RosterLab!`
      : "Let's set up your first schedule!";

  return (
    <div className={styles.container}>
      {!isDesktop && (
        <IntroductionPageIPadHeader headerMessage={headerMessage} />
      )}
      <div className={styles.contentWrapper}>
        {isDesktop && (
          <IntroductionPageDesktopHeader headerMessage={headerMessage} />
        )}
        <div className={styles.content}>{getContent()}</div>
      </div>
      {isDesktop && (
        <div className={styles.desktopImage}>
          <img src={jellyLogo} className={styles.jelloLogo} />
        </div>
      )}
    </div>
  );
};

export default IntroductionPage;
