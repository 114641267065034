import { KEYWORD_OFF } from "../../constants/keywords";
import { trimAndLowerCase } from "../generalUtils/string";
import { convertAllocationInShortIdFormToNameForm } from "../modelUtils/allocation";
import { splitAllocationSuffix } from "../modelUtils/generalModelUtil";
import { showWarningPopupIfNoOthersShowing } from "../uiUtils/popup";

export function processPreferencesCellForClipboard(params) {
  const possibleSuffix = ["*", "!", "?"];
  const suffix = params.value.charAt(params.value.length - 1);
  const valueFormatter = params.column.getColDef().valueFormatter;

  if (!valueFormatter) {
    return params.value;
  }

  const allocation = valueFormatter(params);
  if (possibleSuffix.includes(suffix)) {
    return `${allocation}${suffix}`;
  }
  return allocation;
}

export const processAllocationsFromClipboard = (
  params,
  addOffs,
  ignoredValues = []
) => {
  const options = params.context.options;
  const allowedShortIdValues = options.map(({ value }) => value);
  const invalidAllocations = [];

  for (let i = 0; i < params.data.length; i++) {
    for (let j = 0; j < params.data[i].length; j++) {
      let cellValue = params.data[i][j].trim();

      if (cellValue === "" && addOffs) {
        params.data[i][j] = KEYWORD_OFF;
      } else if (cellValue !== "") {
        const [allocation, suffix] = splitAllocationSuffix(cellValue);

        if (allowedShortIdValues.includes(allocation)) {
          params.data[i][j] = allocation + suffix;
        } else {
          const matchingOption = options.find(
            ({ label }) =>
              trimAndLowerCase(label) === trimAndLowerCase(allocation)
          );

          if (!matchingOption) {
            if (ignoredValues.includes(cellValue)) {
              params.data[i][j] = "";
            } else {
              invalidAllocations.push(cellValue);
              // Keep the original value for now
            }
          } else {
            params.data[i][j] = matchingOption.value + suffix;
          }
        }
      }
    }
  }

  if (invalidAllocations.length > 0) {
    const warningMessage = `The following allocations do not exist and cannot be pasted into this table: ${invalidAllocations.join(
      ", "
    )}`;
    showWarningPopupIfNoOthersShowing("Invalid allocations", warningMessage);

    return null;
  }

  return params.data;
};

/**
 *
 * @param {*} params
 */
export const processRosteredAllocationFromClipboard = (
  params,
  shortIdsToEntityNamesDicts
) => {
  const value = params.value;
  const displayAreaInAllocationCell =
    params.context.displayAreaInAllocationCell;

  if (displayAreaInAllocationCell || !value) {
    return defaultProcessCellForClipboard(params);
  }

  // When copying, also copy the area field.
  return convertAllocationInShortIdFormToNameForm(
    value,
    shortIdsToEntityNamesDicts
  );
};

export const defaultProcessCellForClipboard = (params) =>
  params.column.getColDef().valueFormatter?.(params) || params.value;
