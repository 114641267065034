/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onCreateLocation(filter: $filter, owner: $owner) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onUpdateLocation(filter: $filter, owner: $owner) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onDeleteLocation(filter: $filter, owner: $owner) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRoster = /* GraphQL */ `
  subscription OnCreateRoster(
    $filter: ModelSubscriptionRosterFilterInput
    $owner: String
  ) {
    onCreateRoster(filter: $filter, owner: $owner) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRoster = /* GraphQL */ `
  subscription OnUpdateRoster(
    $filter: ModelSubscriptionRosterFilterInput
    $owner: String
  ) {
    onUpdateRoster(filter: $filter, owner: $owner) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRoster = /* GraphQL */ `
  subscription OnDeleteRoster(
    $filter: ModelSubscriptionRosterFilterInput
    $owner: String
  ) {
    onDeleteRoster(filter: $filter, owner: $owner) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGlobalEmployee = /* GraphQL */ `
  subscription OnCreateGlobalEmployee(
    $filter: ModelSubscriptionGlobalEmployeeFilterInput
    $owner: String
    $email: String
  ) {
    onCreateGlobalEmployee(filter: $filter, owner: $owner, email: $email) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGlobalEmployee = /* GraphQL */ `
  subscription OnUpdateGlobalEmployee(
    $filter: ModelSubscriptionGlobalEmployeeFilterInput
    $owner: String
    $email: String
  ) {
    onUpdateGlobalEmployee(filter: $filter, owner: $owner, email: $email) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGlobalEmployee = /* GraphQL */ `
  subscription OnDeleteGlobalEmployee(
    $filter: ModelSubscriptionGlobalEmployeeFilterInput
    $owner: String
    $email: String
  ) {
    onDeleteGlobalEmployee(filter: $filter, owner: $owner, email: $email) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRosterSolution = /* GraphQL */ `
  subscription OnCreateRosterSolution(
    $filter: ModelSubscriptionRosterSolutionFilterInput
    $owner: String
  ) {
    onCreateRosterSolution(filter: $filter, owner: $owner) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRosterSolution = /* GraphQL */ `
  subscription OnUpdateRosterSolution(
    $filter: ModelSubscriptionRosterSolutionFilterInput
    $owner: String
  ) {
    onUpdateRosterSolution(filter: $filter, owner: $owner) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRosterSolution = /* GraphQL */ `
  subscription OnDeleteRosterSolution(
    $filter: ModelSubscriptionRosterSolutionFilterInput
    $owner: String
  ) {
    onDeleteRosterSolution(filter: $filter, owner: $owner) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onCreateSettings(filter: $filter, owner: $owner) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onUpdateSettings(filter: $filter, owner: $owner) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings(
    $filter: ModelSubscriptionSettingsFilterInput
    $owner: String
  ) {
    onDeleteSettings(filter: $filter, owner: $owner) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLocationById = /* GraphQL */ `
  subscription OnUpdateLocationById($id: ID!) {
    onUpdateLocationById(id: $id) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGlobalEmployeeByLocationID = /* GraphQL */ `
  subscription OnCreateGlobalEmployeeByLocationID($locationID: ID!) {
    onCreateGlobalEmployeeByLocationID(locationID: $locationID) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGlobalEmployeeByLocationID = /* GraphQL */ `
  subscription OnDeleteGlobalEmployeeByLocationID($locationID: ID!) {
    onDeleteGlobalEmployeeByLocationID(locationID: $locationID) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGlobalEmployeeByLocationID = /* GraphQL */ `
  subscription OnUpdateGlobalEmployeeByLocationID($locationID: ID!) {
    onUpdateGlobalEmployeeByLocationID(locationID: $locationID) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRosterByLocationID = /* GraphQL */ `
  subscription OnCreateRosterByLocationID($locationID: ID!) {
    onCreateRosterByLocationID(locationID: $locationID) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRosterById = /* GraphQL */ `
  subscription OnUpdateRosterById($id: ID!) {
    onUpdateRosterById(id: $id) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRosterSolutionByRosterID = /* GraphQL */ `
  subscription OnCreateRosterSolutionByRosterID($rosterID: ID!) {
    onCreateRosterSolutionByRosterID(rosterID: $rosterID) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRosterSolutionByRosterID = /* GraphQL */ `
  subscription OnUpdateRosterSolutionByRosterID($rosterID: ID!) {
    onUpdateRosterSolutionByRosterID(rosterID: $rosterID) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRosterSolutionByRosterID = /* GraphQL */ `
  subscription OnDeleteRosterSolutionByRosterID($rosterID: ID!) {
    onDeleteRosterSolutionByRosterID(rosterID: $rosterID) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
