import styles from "./TourTooltip.module.css";
import sunnyRosterLab from "../../../../assets/images/sunny-rosterlab.png";

const TourTooltip = ({
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
}) => {
  const { hideBackButton, hideFooter } = step;

  return (
    <div ref={tooltipProps.ref} className={styles.container}>
      <div className={styles.rosterLabProfile}>
        <div className={styles.profileImgContainer}>
          <img
            className={styles.profileImg}
            src={sunnyRosterLab}
            alt="Sunny at RosterLab"
          />
        </div>
        <span className={styles.profileIntro}>Sunny from RosterLab</span>
      </div>
      <div className={styles.content}>
        {step.title && <p className={styles.title}>{step.title}</p>}
        <div className={styles.description}>{step.content}</div>
        {!hideFooter && (
          <div className={styles.footer}>
            <div className={styles.footerLeft}>
              <button
                className={styles.dismissButton}
                onClick={skipProps.onClick}
              >
                Dismiss guide
              </button>
            </div>
            <div className={styles.footerRight}>
              {!hideBackButton && (
                <button
                  className={styles.backButton}
                  onClick={backProps.onClick}
                >
                  {backProps["aria-label"]}
                </button>
              )}
              <button
                className={styles.nextButton}
                onClick={primaryProps.onClick}
              >
                {primaryProps["aria-label"]}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TourTooltip;
