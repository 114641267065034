import { v4 as uuidv4 } from "uuid";
import {
  createUniqueSuffixedName,
  removeExtensionFromFileName,
  removeSuffixInPreferences,
} from "../../../utils";

/**It parses object to a valid roster information to suitable format to create a roster model.
 * @param {*} data - parsed JSON file from file loader
 * @returns - object descibing roster information
 */
const parseRosterInfo = (data, locationID, existingRosterNames) => {
  const employeesInfo = data.Employees.map((item) => {
    return {
      Allocations: item.Allocations,
      AllocationsRecurring: item.AllocationsRecurring,
      Days: removeSuffixInPreferences(item.Days, "*"),
      DaysRecurring: removeSuffixInPreferences(item.DaysRecurring, "*"),
      RosteredAllocations: item.RosteredAllocations,
      History: item.History,
      RuleValues: item.RuleValues,
      id: item.id,
      name: item.name,
      shifts: item.shifts,
      skills: item.skills,
      areas: item.areas,
    };
  });

  const tasksInfo = data.Tasks
    ? data.Tasks.map((item) => {
        return {
          ...item,
          shortId: item.shortId || item.name,
        };
      })
    : [];

  const taskBlocksInfo = data.TaskBlocks
    ? data.TaskBlocks.map((item) => {
        return {
          ...item,
          shortId: item.shortId || item.name,
        };
      })
    : [];

  const areasInfo = data.Areas
    ? data.Areas.map((item) => {
        return {
          ...item,
          shortId: item.shortId,
        };
      })
    : [];

  const shiftsInfo = data.Shifts
    ? data.Shifts.map((item) => {
        return {
          ...item,
          shortId: item.shortId || item.name,
        };
      })
    : [];

  const shiftGroupsInfo = data.ShiftGroups
    ? data.ShiftGroups.map((item) => {
        return {
          ...item,
          shortId: item.shortId || item.name,
        };
      })
    : [];

  const skillsInfo = data.Skills
    ? data.Skills.map((item) => {
        return {
          ...item,
          shortId: item.shortId || item.name,
        };
      })
    : [];

  const demandsInfo = data.Demands.map((item) => {
    return {
      ...item,
    };
  });

  const rosterObj = {
    id: uuidv4(),
    name: data.name ? data.name : `Roster: ${data.startDate}`,
    numDays: data.numDays,
    startDate: data.startDate,
    Employees: employeesInfo,
    Tasks: tasksInfo,
    TaskBlocks: taskBlocksInfo,
    Areas: areasInfo,
    Demands: demandsInfo,
    Shifts: shiftsInfo,
    Skills: skillsInfo,
    RuleExceptions: data.RuleExceptions,
    CustomRules: data.CustomRules,
    ShiftGroups: shiftGroupsInfo,
    Statistics: data.Statistics
      ? data.Statistics
      : {
          employeeShiftCountsToShow: [],
          employeeSkillCountsToShow: [],
          employeeHoursToShow: [],
          dayShiftCountsToShow: [],
          daySkillCountsToShow: [],
          dayShiftSkillCountsToShow: [],
          leaveCountsToShow: [],
          otherSettings: [],
        },
    locationID,
    ColorCodes: data.ColorCodes,
  };

  rosterObj.name = createUniqueSuffixedName(
    existingRosterNames,
    rosterObj.name
  );

  const demands = rosterObj.Demands;
  for (const demand of demands) {
    if (demand.skillType) {
      delete demand.skillType;
    }
  }

  const employees = rosterObj.Employees;
  for (const employee of employees) {
    if (!employee.DaysRecurring) {
      employee.DaysRecurring = Array(7).fill("");
    }
    if (!employee.Allocations) {
      employee.Allocations = Array(rosterObj.numDays).fill("");
    }
    if (!employee.AllocationsRecurring) {
      employee.AllocationsRecurring = Array(7).fill("");
    }
    if (!employee.History) {
      employee.History = Array(14).fill("");
    }
  }

  const shifts = rosterObj.Shifts;

  return {
    ...rosterObj,
    Employees: employees,
    Shifts: shifts,
    updatedAt: new Date(0).toISOString(),
    createdAt: new Date().toISOString(),
  };
};

/**
 * @param {*} file - JSON file to be uploaded as a new roster
 * @param {*} callback - callback called at the end of onload
 */
export const getUploadedRoster = async (
  file,
  locationID,
  existingRosterNames,
  createRoster
) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    const name = removeExtensionFromFileName(file.name);
    const result = { ...JSON.parse(e.target.result), name };
    const invalidFields = getMismatchingFields(result);
    if (invalidFields.length !== 0) {
      console.error("Mismatching fields: " + invalidFields);
      return;
    }

    const rosterInfo = parseRosterInfo(result, locationID, existingRosterNames);
    createRoster(rosterInfo);
  };
  reader.readAsText(file);
};

const getMismatchingFields = (info) => {
  const invalidFields = [];
  const {
    CustomRules,
    Demands,
    Employees,
    ShiftGroups,
    Shifts,
    Skills,
    numDays,
    startDate,
  } = info;

  if (!CustomRules) {
    invalidFields.push("CustomRules");
  }
  if (!Demands) {
    invalidFields.push("Demands");
  }
  if (!Employees) {
    invalidFields.push("Employees");
  }
  if (!ShiftGroups) {
    invalidFields.push("ShiftGroups");
  }
  if (!Shifts) {
    invalidFields.push("Shifts");
  }
  if (!Skills) {
    invalidFields.push("Skills");
  }
  if (!numDays) {
    invalidFields.push("numDays");
  }
  if (!startDate) {
    invalidFields.push("startDate");
  }
  return invalidFields;
};
