import {
  createCustomAuthLambdaToken,
  getCurrentAuthUser,
  getUserGroups,
  isUserInCollaboratorGroup,
  isUserInCoordinatorGroup,
  isUserInEmployeeGroup,
  isUserInKioskGroup,
  shouldUseCustomAuthLambda,
} from "../../features/auth/service/auth";
import {
  onCreateGlobalEmployeeByLocationID,
  onCreateRosterByLocationID,
  onCreateRosterSolutionByRosterID,
  onDeleteRosterSolutionByRosterID,
  onUpdateRosterSolutionByRosterID,
  onUpdateSettings,
} from "../../graphql/subscriptions";
import {
  onDeleteGlobalEmployeeByLocationIDCustom,
  onUpdateGlobalEmployeeByLocationIDCustom,
  onUpdateLocationByIdCustom,
  onUpdateLocationByIdCustomAdmin,
  onUpdateRosterByIdCustom,
} from "../graphqlUtils/customSubscriptions";
import { getGraphqlInfo } from "./generalQueryUtil";
import { generateGraphqlClient } from "./graphqlClient";

async function subscriptionTemplate(
  query,
  fieldName,
  callback,
  variables,
  user
) {
  const { operationType, operationName } = getGraphqlInfo(query);

  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(
    user,
    operationName
  );

  const customAuthParams = {
    authMode: "lambda",
    authToken: createCustomAuthLambdaToken(
      user.session.idToken.toString(),
      operationName,
      operationType,
      variables
    ),
  };

  const queryParams = {
    query,
    ...(shouldUseCustomAuthMode && { ...customAuthParams }),
    variables,
  };

  let graphqlClient = await generateGraphqlClient();

  return graphqlClient.graphql(queryParams).subscribe({
    next: ({ data }) => {
      callback(data[fieldName]);
    },
    error: (error) => console.warn(error),
  });
}

export async function subscribeSettingsChange(callback) {
  const user = await getCurrentAuthUser();

  if (
    isUserInCollaboratorGroup(user) ||
    isUserInCoordinatorGroup(user) ||
    isUserInKioskGroup(user) ||
    isUserInEmployeeGroup(user)
  ) {
    return null;
  }

  return await subscriptionTemplate(
    onUpdateSettings,
    "onUpdateSettings",
    callback,
    {},
    user
  );
}

// Rosterers, Admin, Coordinator users should use subscribeLocationUpdate
export async function subscribeLocationChange(callback, locationID) {
  const user = await getCurrentAuthUser();

  if (
    isUserInKioskGroup(user) ||
    isUserInEmployeeGroup(user) ||
    getUserGroups(user).includes(locationID)
  ) {
    const variables = {
      id: locationID,
    };

    return await subscriptionTemplate(
      onUpdateLocationByIdCustom,
      "onUpdateLocationById",
      callback,
      variables,
      user
    );
  }

  return null;
}

export async function subscribeRosterSolutionCreation(callback, rosterID) {
  const user = await getCurrentAuthUser();

  const variables = {
    rosterID,
  };

  return await subscriptionTemplate(
    onCreateRosterSolutionByRosterID,
    "onCreateRosterSolutionByRosterID",
    callback,
    variables,
    user
  );
}

export async function subscribeRosterSolutionDeleted(callback, rosterID) {
  const user = await getCurrentAuthUser();

  const variables = {
    rosterID,
  };

  return await subscriptionTemplate(
    onDeleteRosterSolutionByRosterID,
    "onDeleteRosterSolutionByRosterID",
    callback,
    variables,
    user
  );
}

export async function subscribeRosterSolutionUpdate(callback, rosterID) {
  const user = await getCurrentAuthUser();

  const variables = {
    rosterID,
  };

  return await subscriptionTemplate(
    onUpdateRosterSolutionByRosterID,
    "onUpdateRosterSolutionByRosterID",
    callback,
    variables,
    user
  );
}

//'Cannot return null for non-nullable type: 'AWSDateTime' within parent 'GlobalEmployee' (/onUpdateGlobalEmployee/createdAt)'
export async function subscribeGlobalEmployeeUpdate(callback, locationID) {
  const user = await getCurrentAuthUser();

  const variables = {
    locationID,
  };

  return await subscriptionTemplate(
    onUpdateGlobalEmployeeByLocationIDCustom,
    "onUpdateGlobalEmployeeByLocationID",
    callback,
    variables,
    user
  );
}

export async function subscribeGlobalEmployeeCreate(callback, locationID) {
  const user = await getCurrentAuthUser();

  const variables = {
    locationID,
  };

  return await subscriptionTemplate(
    onCreateGlobalEmployeeByLocationID,
    "onCreateGlobalEmployeeByLocationID",
    callback,
    variables,
    user
  );
}

export async function subscribeGlobalEmployeeDelete(callback, locationID) {
  const user = await getCurrentAuthUser();

  const variables = {
    locationID,
  };

  return await subscriptionTemplate(
    onDeleteGlobalEmployeeByLocationIDCustom,
    "onDeleteGlobalEmployeeByLocationID",
    callback,
    variables,
    user
  );
}

export async function subscribeLocationUpdate(callback, locationID) {
  const user = await getCurrentAuthUser();

  const variables = {
    id: locationID,
  };

  return await subscriptionTemplate(
    onUpdateLocationByIdCustomAdmin,
    "onUpdateLocationById",
    callback,
    variables,
    user
  );
}

export async function subscribeRosterUpdate(callback, rosterID) {
  const user = await getCurrentAuthUser();
  const variables = {
    id: rosterID,
  };

  return await subscriptionTemplate(
    onUpdateRosterByIdCustom,
    "onUpdateRosterById",
    callback,
    variables,
    user
  );
}

export async function subscribeRosterCreate(callback, locationID) {
  const user = await getCurrentAuthUser();

  const variables = {
    locationID,
  };

  return await subscriptionTemplate(
    onCreateRosterByLocationID,
    "onCreateRosterByLocationID",
    callback,
    variables,
    user
  );
}
