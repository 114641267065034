import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import styles from "./ViewRangeSwitcher.module.css";

const ViewRangeSwitcher = ({
  onForward,
  onBackward,
  viewRangeOptions,
  onViewRangeChange,
  viewRange,
  defaultOption,
  rosterStartDate,
  rosterFinishDate,
  displayedStartDate,
  displayedLastDate,
}) => {
  const shouldDisableLeftButton = useMemo(() => {
    return displayedStartDate === rosterStartDate;
  }, [displayedStartDate, rosterStartDate]);

  const shouldDisableRightButton = useMemo(() => {
    return displayedLastDate === rosterFinishDate;
  }, [displayedLastDate, rosterFinishDate]);

  return (
    <div className={styles.container}>
      <span className={styles.view}>View: </span>
      <button
        className={`${styles["date-arrow"]} ${
          shouldDisableLeftButton ? styles.disabledBtn : ""
        }`}
        onClick={shouldDisableLeftButton ? () => {} : onBackward}
      >
        <FontAwesomeIcon icon={faCaretLeft} />
      </button>
      <select
        className={styles["range-selection"]}
        name="range"
        id="range"
        value={viewRange}
        onChange={onViewRangeChange}
      >
        {defaultOption && <option>{defaultOption}</option>}
        {viewRangeOptions.map((opt, idx) => (
          <option key={idx} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <button
        className={`${styles["date-arrow"]} ${
          shouldDisableRightButton ? styles.disabledBtn : ""
        }`}
        onClick={shouldDisableRightButton ? () => {} : onForward}
      >
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
    </div>
  );
};

export default ViewRangeSwitcher;
