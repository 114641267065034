import create from "zustand";
import { getRostersBySnapshotStartDateRange } from "../utils/queryUtils/locationQuery";

export const useSnapshotsStore = create((set) => ({
  locationID: null,
  snapshots: [],
  periodStartDate: null,
  refreshSnapshots: async (locationID, periodStartDate) => {
    const snapshots = await getRostersBySnapshotStartDateRange(
      locationID,
      true,
      periodStartDate,
      periodStartDate
    );

    set({
      locationID,
      snapshots,
      periodStartDate,
    });
  },
}));
