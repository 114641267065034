import { useSearchParams } from "react-router-dom-v5-compat";
import { DateTime, encodeUrlParamString } from "../../../../utils";
import styles from "./Survey.module.css";
import { getDateStringFromParam } from "../../../../utils/routeUtils/routeUtils";
import { getFormData } from "../../../../utils/flagsUtils/flags";
import { KEYWORD_NA, KEYWORD_OFF } from "../../../../constants/keywords";

function formatStringToParamForm(string) {
  return string.split(" ").join("+");
}

function getPrefilledFormUrl(
  employeeName,
  email,
  allocationDate,
  allocationName,
  isPreferenceMet,
  preference,
  isProductionFrom = false
) {
  const formEntries = getFormData(isProductionFrom).entries;

  const nameParam = formatStringToParamForm(employeeName);
  const emailParam = email;
  const allocationNameParam = allocationName
    ? formatStringToParamForm(allocationName)
    : "";

  const allocationDateParam = allocationDate
    ? allocationDate
    : new DateTime().toFormat("AWS");

  const prefilledData = formEntries
    .map(({ key, entry }) => {
      const data = { entry, param: null };
      if (key === "name") {
        data.param = nameParam;
      }

      if (key === "email") {
        data.param = encodeUrlParamString(emailParam);
      }

      if (key === "shift-date") {
        data.param = allocationDateParam;
      }

      if (key === "shift-name" && allocationNameParam) {
        data.param = allocationNameParam;
      }

      if (key === "is-preference-met") {
        if (preference) {
          data.param = isPreferenceMet ? "Yes" : "No";
        } else {
          data.param = KEYWORD_NA;
        }
      }
      if (key === "preference-name" && preference) {
        data.param = preference;
      }

      return data;
    })
    .filter(({ param }) => param !== null);

  let formURL = getFormData(isProductionFrom).baseUrl;
  prefilledData.forEach((data) => {
    formURL = `${formURL}&entry.${data.entry}=${data.param}`;
  });

  return formURL;
}

function getPrefilledAllocationName(employee, date, customKeywordsUtilObj) {
  const { annualLeaveKeyword } = customKeywordsUtilObj;
  const keywords = [KEYWORD_NA, annualLeaveKeyword, KEYWORD_OFF];

  const publishedAllocations = employee.PublishedAllocations;
  if (!publishedAllocations) {
    return null;
  }

  const allocationOnDate = publishedAllocations.find(
    (allocation) => allocation.date === date
  );

  if (!allocationOnDate) {
    return null;
  }

  const publishedAllocation = allocationOnDate.publishedAllocation;

  if (keywords.includes(publishedAllocation)) {
    return null;
  }

  return publishedAllocation;
}

function getPreferenceOnDate(employee, date) {
  const { Preferences, PreferencesRecurring } = employee;
  const targetPreference = Preferences.find((pref) => pref.date === date);

  if (targetPreference) {
    return targetPreference.allocation;
  }

  const dateObj = new DateTime(date);
  const targetPreferenceRecurringIndex = dateObj.getDayOfWeek(
    "numberWithMondayAs0"
  );
  const targetPreferenceRecurring =
    PreferencesRecurring[targetPreferenceRecurringIndex];

  if (targetPreferenceRecurring) {
    return targetPreferenceRecurring;
  }

  return null;
}

export default function Survey({ userEmployee, customKeywordsUtilObj }) {
  const { name: employeeName, email } = userEmployee;
  const [searchParams] = useSearchParams();

  const allocationDate = getDateStringFromParam(searchParams.get("date"));
  const allocationName = allocationDate
    ? getPrefilledAllocationName(
        userEmployee,
        allocationDate,
        customKeywordsUtilObj
      )
    : null;
  const preference = allocationDate
    ? getPreferenceOnDate(userEmployee, allocationDate)
    : null;

  const isPreferenceMet =
    allocationDate &&
    allocationName &&
    preference &&
    allocationName === preference
      ? true
      : false;

  const preFilledUrl = getPrefilledFormUrl(
    employeeName,
    email,
    allocationDate,
    allocationName,
    isPreferenceMet,
    preference,
    true
  );

  return (
    <div className={styles.container}>
      <iframe className={styles.iframe} src={preFilledUrl}>
        Loading...
      </iframe>
    </div>
  );
}
