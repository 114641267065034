import { Capacitor } from "@capacitor/core";

export function isNativeMobileApp() {
  return Capacitor.isNativePlatform();
}

export function isMobileViewOrApp() {
  // Check if it's not running as an app (using Capacitor)
  const isApp = isNativeMobileApp();
  return isMobileView() || isApp;
}

export function isMobileView() {
  const userAgent = navigator.userAgent;
  return (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    ) && !/iPad/i.test(userAgent)
  );
}

export function getPlatform() {
  return Capacitor.getPlatform();
}
