/**
 * @param {*} columnApis - column apis of the grid
 * @param {*} colNames - field name of the column that we want to hide
 */

export const hideColumnInGrid = (columnApis, colNames) => {
  columnApis.forEach((columnApi) =>
    columnApi.setColumnsVisible(colNames, false)
  );
};
