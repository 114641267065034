import { useCallback } from "react";
import {
  deleteSolutionModel,
  getAllMainStreamRostersForPropagation,
  getRosterModelById,
  updateRosterModel,
} from "../../utils/queryUtils/rosterQuery";
import { convertScheduleEmployeeToRosterEmployee } from "../../utils/queryUtils/scheduleDataGetters";
import {
  getDuplicatedEntities,
  getReorderedEntities,
  updateEmployeesWithOldEmployeeData,
} from "../../utils/queryUtils/sharedModelDataGetters";
import {
  getNextDefaultEntityNameNumber,
  isObjectEmpty,
  DateTime,
  getIds,
  getNextRosterHistoryFromPublishedShifts,
  filterDeletedEntities,
  deepCopyObject,
  getShortIds,
  allElementsAreEmptyStr,
  arraysHaveSameContent,
  getUnexistingRosterEmployeesToCreate,
  sortByDateField,
  pushIfNotExists,
} from "../../utils";
import {
  useBareLocationMutation,
  useBareRosterMutation,
} from "./useBareModelMutations";
import {
  addCompletedOnboardingTasks,
  getCustomKeywordsDataFromFrontendSettings,
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
  publishOpenShifts,
} from "../../utils/queryUtils/locationDataGetters";
import { useQueryClient } from "@tanstack/react-query";
import { getRosterInView } from "./useScheduleQuery";
import { createRosterForScheduleView } from "../../features/scheduleView/service/scheduleViewRoster";
import {
  createRosterModel,
  createRosterModelForMainStreamScheduleView,
  deleteRosterModel,
} from "../../utils/queryUtils/locationQuery";
import { useUserSettingsUpdate } from "../userHooks/useManageUserSettings";
import { getActualNumDays } from "../../utils/queryUtils/monthViewUtils";
import { useUserStore } from "../../globalStore/appStore";
import { PLAN, getStripeCustomerId } from "../../features/auth/service/auth";
import {
  getNewEmployeeModels,
  getUpdatedDataModelFields,
  propagateDeletionToOtherRostersInMainStream,
} from "../../utils/queryUtils/updateData";
import { getSnapshotRosterInfo } from "../../features/scheduleView/service/rosterSnapshot";
import { useWarningsStore } from "../../globalStore/warningsStore";
import { compareTwoObjects } from "../../utils/generalUtils/object";
import {
  getDuplicateSnapshotRoster,
  getRosterStartAndFinishDate,
} from "../../utils/queryUtils/rosterDataGetters";
import { ONBOARDING_TASK_ID } from "../../features/onBoarding/service/onBoarding";

export const ACTION_TYPES = Object.freeze({
  deleteRosterSolution: "delete-roster-solution",
  createScheduleEmployees: "create-schedule-employees",
  deleteScheduleEmployees: "delete-schedule-employees",
  updateFields: "update-fields",
  createSnapshot: "create-snapshot",
  updateGlobalEmployee: "update-global-employee",
});

// Main mutation function
export function useRosterModelMutation({
  isScheduleView,
  locationID,
  rosterID,
}) {
  const queryClient = useQueryClient();
  const { updateNumSeatsBy } = useUserSettingsUpdate();
  const { plan, user } = useUserStore();

  const { updateWarnings, updateFullWarnings } = useWarningsStore();

  const rosterMutation = useBareRosterMutation(rosterID);
  const locationMutation = useBareLocationMutation(locationID);

  const recalculateWarnings = useCallback(
    (
      updatedFieldNames,
      updatedRosterInView,
      previousRosterData,
      updatedEmployeeFieldNames = []
    ) => {
      const { frontendSettings } = updatedRosterInView;
      const customKeywords = interpretCustomKeywordsData(
        getCustomKeywordsDataFromFrontendSettings(frontendSettings)
      );
      const fieldsToCalculate = [...updatedFieldNames];

      updatedEmployeeFieldNames.forEach((fieldName) => {
        fieldName === "History" &&
          pushIfNotExists(fieldsToCalculate, "History");
        fieldName === "Allocations" &&
          pushIfNotExists(fieldsToCalculate, "Allocations");
        fieldName === "AllocationsRecurring" &&
          pushIfNotExists(fieldsToCalculate, "AllocationsRecurring");
        fieldName === "Days" && pushIfNotExists(fieldsToCalculate, "Days");
        fieldName === "DaysRecurring" &&
          pushIfNotExists(fieldsToCalculate, "DaysRecurring");
        fieldName === "RuleValues" &&
          pushIfNotExists(fieldsToCalculate, "CustomRules");
      });

      // Figure out which allocation tables are affect from the field updates
      const updatedEntityShortIds = [];
      for (const field of fieldsToCalculate) {
        const fieldsThatAffectAllocationTables = [
          "Tasks",
          "TaskBlocks",
          "Shifts",
          "ShiftGroups",
        ];

        if (!fieldsThatAffectAllocationTables.includes(field)) {
          continue;
        }

        const previousValues = previousRosterData[field];
        const updatedValues = updatedRosterInView[field];

        const updatedEntities = updatedValues.filter((updatedEntity) => {
          const { shortId } = updatedEntity;
          const matchingPrevEntity = previousValues.find(
            (prevEntity) => prevEntity.shortId === shortId
          );

          if (!matchingPrevEntity) {
            return false;
          }

          if (
            !compareTwoObjects(updatedEntity, {
              fieldsToOmit: matchingPrevEntity,
            })
          ) {
            return true;
          }

          return false;
        });

        updatedEntityShortIds.push(...getShortIds(updatedEntities));
      }

      const allocationTableFields = [
        "History",
        "Allocations",
        "AllocationsRecurring",
        "Days",
        "DaysRecurring",
      ];

      for (const updatedEntityShortId of updatedEntityShortIds) {
        const employees = updatedRosterInView.Employees;
        for (const employee of employees) {
          for (const field of allocationTableFields) {
            if (fieldsToCalculate.includes(field)) {
              continue;
            }
            const affectedAllocations = employee[field].filter((allocation) =>
              allocation.includes(updatedEntityShortId)
            );
            if (
              affectedAllocations.length > 0 &&
              !fieldsToCalculate.includes(field)
            ) {
              pushIfNotExists(fieldsToCalculate, field);
            }
          }
        }
      }

      updateWarnings(updatedRosterInView, fieldsToCalculate, customKeywords);
    },
    [updateWarnings]
  );

  const createSnapshotRoster = useCallback(
    async (snapshotName, rosterInView) => {
      const rosterInfo = getSnapshotRosterInfo(snapshotName, rosterInView);
      const locationModel = queryClient.getQueryData(["location", locationID]);
      const newLocation = {
        ...locationModel,
        Rosters: {
          items: [...locationModel.Rosters.items],
        },
      };
      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.createSnapshot,
        newLocation,
        snapshotRosterInfo: rosterInfo,
        customOwner: locationModel.owner,
      });
      return rosterInfo;
    },
    [locationMutation, locationID, queryClient]
  );

  const updateSnapshots = useCallback(async (updatedSnapshots) => {
    for (const roster of updatedSnapshots) {
      await updateRosterModel(roster.id, { name: roster.name });
    }
  }, []);

  const duplicateSnapshot = useCallback(
    async (locationID, snapshotRosterID, newName) => {
      const locationModel = queryClient.getQueryData(["location", locationID]);
      const snapshot = await getRosterModelById(snapshotRosterID);
      if (!snapshot) {
        return;
      }

      const duplicatedSnapshot = getDuplicateSnapshotRoster(snapshot, newName);
      const newRoster = await createRosterModel(duplicatedSnapshot);
      const allRosters = [...locationModel.Rosters.items, newRoster];

      const resultingLocation = {
        ...locationModel,
        Rosters: {
          items: allRosters,
        },
      };

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateFields,
        newLocation: resultingLocation,
        newLocationFields: {},
        newEmployeesFields: null,
      });
    },
    [locationMutation, queryClient]
  );

  const deleteSnapshots = useCallback(
    async (deletedSnapshotIDs) => {
      const locationModel = queryClient.getQueryData(["location", locationID]);

      for (const rosterID of deletedSnapshotIDs) {
        const deletedRoster = await deleteRosterModel(rosterID);

        const solutions = deletedRoster.Solutions.items;
        const deleteSolutionsPromise = [];
        for (const solution of solutions) {
          deleteSolutionsPromise.push(deleteSolutionModel(solution.id));
        }
        if (deleteSolutionsPromise.length > 0) {
          await Promise.all(deleteSolutionsPromise);
        }
      }

      const allRosters = locationModel.Rosters.items.filter(
        ({ id }) => !deletedSnapshotIDs.includes(id)
      );

      const resultingLocation = {
        ...locationModel,
        Rosters: {
          items: allRosters,
        },
      };

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateFields,
        newLocation: resultingLocation,
        newLocationFields: {},
        newEmployeesFields: null,
      });
    },
    [locationID, locationMutation, queryClient]
  );

  const getUpdatedCompletedOnboardingTasks = useCallback(
    (updatedFieldNames, updatedRosterInView, location) => {
      const newCompletedOnboardingTasks = [];
      if (
        updatedFieldNames.includes("Shifts") &&
        updatedRosterInView.Shifts.length > 0 &&
        !newCompletedOnboardingTasks.includes(ONBOARDING_TASK_ID.createShifts)
      ) {
        newCompletedOnboardingTasks.push(ONBOARDING_TASK_ID.createShifts);
      }
      if (
        updatedFieldNames.includes("CustomRules") &&
        updatedRosterInView.CustomRules.length > 0 &&
        !newCompletedOnboardingTasks.includes(ONBOARDING_TASK_ID.addRules)
      ) {
        newCompletedOnboardingTasks.push(ONBOARDING_TASK_ID.addRules);
      }
      if (
        updatedFieldNames.includes("Demands") &&
        updatedRosterInView.Demands.length > 0 &&
        !newCompletedOnboardingTasks.includes(
          ONBOARDING_TASK_ID.addStaffingNumbers
        )
      ) {
        newCompletedOnboardingTasks.push(ONBOARDING_TASK_ID.addStaffingNumbers);
      }

      if (
        updatedFieldNames.includes("Employees") &&
        !newCompletedOnboardingTasks.includes(ONBOARDING_TASK_ID.addPreferences)
      ) {
        const employees = updatedRosterInView.Employees;
        for (const employee of employees) {
          if (!allElementsAreEmptyStr(employee.Days)) {
            newCompletedOnboardingTasks.push(ONBOARDING_TASK_ID.addPreferences);
            break;
          }
        }
      }

      if (
        updatedFieldNames.includes("Statistics") &&
        !newCompletedOnboardingTasks.includes(
          ONBOARDING_TASK_ID.addLiveCountStatistics
        )
      ) {
        const statistics = updatedRosterInView.Statistics;
        for (const [, value] of Object.entries(statistics)) {
          if (value !== null && value.length > 0) {
            newCompletedOnboardingTasks.push(
              ONBOARDING_TASK_ID.addLiveCountStatistics
            );
          }
        }
      }

      const prevCompletedOnboardingTasks = location
        ? location.completedOnboardingTasks || []
        : [];
      let updatedCompletedOnboardingTasks = [...prevCompletedOnboardingTasks];

      newCompletedOnboardingTasks.forEach((taskID) => {
        if (!updatedCompletedOnboardingTasks.includes(taskID)) {
          updatedCompletedOnboardingTasks.push(taskID);
        }
      });

      if (
        arraysHaveSameContent(
          prevCompletedOnboardingTasks,
          updatedCompletedOnboardingTasks
        )
      ) {
        return null;
      }

      return updatedCompletedOnboardingTasks;
    },
    []
  );

  const updateFields = useCallback(
    async (
      updatedFieldNames,
      updatedRosterInView,
      previousRosterData,
      updatedEmployeeFieldNames = []
    ) => {
      const rosterModel = queryClient.getQueryData([
        "roster",
        updatedRosterInView.id,
      ]);
      const locationModel = queryClient.getQueryData([
        "location",
        updatedRosterInView.locationID,
      ]);

      const {
        resultingLocation,
        resultingGlobalEmployees,
        resultingRoster,
        updatedLocationModelFields,
        updatedRosterModelFields,
        updatedGlobalEmployeeModelsWithUpdatedFields,
        deletedFieldItemShortIds,
      } = getUpdatedDataModelFields(
        isScheduleView,
        previousRosterData,
        updatedRosterInView,
        updatedFieldNames,
        updatedEmployeeFieldNames,
        rosterModel,
        locationModel
      );

      // resulting rosterInView after propagations
      const resultingRosterInView = isScheduleView
        ? getRosterInView(
            resultingLocation,
            resultingGlobalEmployees,
            resultingRoster
          )
        : {
            ...resultingRoster,
            frontendSettings: resultingLocation.frontendSettings,
          };

      // update warnings
      recalculateWarnings(
        updatedFieldNames,
        resultingRosterInView,
        previousRosterData,
        updatedEmployeeFieldNames
      );

      const updatedCompletedOnboardingTasks = [
        PLAN.COORDINATOR,
        PLAN.COLLABORATOR,
      ].includes(plan)
        ? null
        : getUpdatedCompletedOnboardingTasks(
            updatedFieldNames,
            resultingRosterInView,
            locationModel
          );

      const isSnapshot = resultingRosterInView.isSnapshot;
      const isMainStream = !isSnapshot && isScheduleView;

      const updatedOtherRosters = await getUpdatedOtherRosters(
        locationModel,
        rosterModel,
        isMainStream,
        deletedFieldItemShortIds,
        updatedFieldNames,
        previousRosterData,
        resultingRosterInView
      );

      // Update Roster model
      if (!isObjectEmpty(updatedRosterModelFields)) {
        const mutateFnParam = {
          actionType: ACTION_TYPES.updateFields,
          newRoster: resultingRoster,
          newRosterFields: updatedRosterModelFields,
        };
        rosterMutation.mutation.mutate(mutateFnParam);
      }

      // Update Location and Global Employees models
      if (
        !isObjectEmpty(updatedLocationModelFields) ||
        updatedGlobalEmployeeModelsWithUpdatedFields.length > 0 ||
        updatedLocationModelFields
      ) {
        const mutateFnParam = {
          actionType: ACTION_TYPES.updateFields,
          newLocation: {
            ...resultingLocation,
            ...(updatedCompletedOnboardingTasks && {
              completedOnboardingTasks: updatedCompletedOnboardingTasks,
            }),
          },
          newLocationFields: {
            ...updatedLocationModelFields,
            ...(updatedCompletedOnboardingTasks && {
              completedOnboardingTasks: updatedCompletedOnboardingTasks,
            }),
          },
          newEmployeesFields: updatedGlobalEmployeeModelsWithUpdatedFields,
          ...(updatedOtherRosters.length > 0 && {
            updatedOtherRosters,
          }),
        };
        locationMutation.mutation.mutate(mutateFnParam);
      }

      // When other rosters (rosters that is NOT the current rosterInView) are updated, reflect it in the query cache
      if (updatedOtherRosters.length > 0) {
        updatedOtherRosters.forEach((updatedOtherRoster) => {
          const queryKey = ["roster", updatedOtherRoster.id];
          const cachedRosterData = queryClient.getQueryData(queryKey);
          if (cachedRosterData) {
            queryClient.setQueryData(queryKey, () => {
              return {
                ...cachedRosterData,
                ...updatedOtherRoster,
              };
            });
          }
        });
      }
    },
    [
      getUpdatedCompletedOnboardingTasks,
      rosterMutation,
      locationMutation,
      queryClient,
      isScheduleView,
      recalculateWarnings,
      plan,
    ]
  );

  const deleteRosterSolutions = useCallback(
    async (deletedSolutionIDs) => {
      const rosterModel = queryClient.getQueryData(["roster", rosterID]);

      const updatedSolutions = rosterModel.Solutions.items.filter(
        (solution) => !deletedSolutionIDs.includes(solution.id)
      );
      const newRoster = {
        ...rosterModel,
        Solutions: {
          items: updatedSolutions,
        },
      };

      await rosterMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.deleteRosterSolution,
        deletedSolutionIDs,
        newRoster,
      });
    },
    [rosterMutation, rosterID, queryClient]
  );

  const createScheduleEmployees = useCallback(
    async (updatedRosterInView, newScheduleEmployees) => {
      if (plan !== PLAN.AI && getStripeCustomerId(user)) {
        updateNumSeatsBy(newScheduleEmployees.length, true);
      }

      const { isSnapshot, isScheduleView, locationID, order } =
        updatedRosterInView;

      const isMainStream = !isSnapshot && isScheduleView;

      const { newRosterEmployeeModels, newGlobalEmployeeModels } =
        getNewEmployeeModels(isMainStream, newScheduleEmployees);

      const rosterModel = queryClient.getQueryData(["roster", rosterID]);
      const previousRosterEmployees = rosterModel.Employees;

      const resultingRoster = {
        ...rosterModel,
        Employees: [...previousRosterEmployees, ...newRosterEmployeeModels],
      };

      rosterMutation.mutation.mutate({
        actionType: ACTION_TYPES.updateFields,
        newRosterFields: { Employees: resultingRoster.Employees },
        newRoster: resultingRoster,
      });

      if (isMainStream) {
        const locationModel = queryClient.getQueryData([
          "location",
          locationID,
        ]);
        const previousGlobalEmployees = filterDeletedEntities(
          locationModel.Employees.items
        );

        const updatedLocation = addCompletedOnboardingTasks(locationModel, [
          ONBOARDING_TASK_ID.addEmployees,
        ]);

        const resultingLocation = {
          ...updatedLocation,
          Employees: {
            items: [...previousGlobalEmployees, ...newGlobalEmployeeModels],
          },
          order,
        };

        const locationOwner = locationModel.owner;

        locationMutation.mutation.mutate({
          actionType: ACTION_TYPES.createScheduleEmployees,
          newGlobalEmployees: newGlobalEmployeeModels,
          newLocation: resultingLocation,
          locationOwner,
        });
      }

      const { frontendSettings } = updatedRosterInView;
      const customKeywords = interpretCustomKeywordsData(
        getCustomKeywordsDataFromFrontendSettings(frontendSettings)
      );
      updateFullWarnings(updatedRosterInView, customKeywords);
    },
    [
      rosterMutation,
      locationMutation,
      rosterID,
      queryClient,
      updateNumSeatsBy,
      plan,
      user,
      updateFullWarnings,
    ]
  );

  const deleteScheduleEmployees = useCallback(
    async (deletedGlobalEmployeeIDs, updatedRosterInView) => {
      if (plan !== PLAN.AI && getStripeCustomerId(user)) {
        updateNumSeatsBy(deletedGlobalEmployeeIDs.length * -1, false);
      }

      const { isSnapshot, isScheduleView, locationID, order, OpenShifts } =
        updatedRosterInView;

      const rosterModel = queryClient.getQueryData(["roster", rosterID]);
      const updatedRosterEmployees = rosterModel.Employees.filter(
        ({ id, globalEmployeeID }) =>
          !deletedGlobalEmployeeIDs.includes(id) &&
          !deletedGlobalEmployeeIDs.includes(globalEmployeeID)
      );

      const resultingRoster = {
        ...rosterModel,
        Employees: updatedRosterEmployees,
      };

      rosterMutation.mutation.mutate({
        actionType: ACTION_TYPES.updateFields,
        newRosterFields: { Employees: updatedRosterEmployees },
        newRoster: resultingRoster,
      });

      const isMainStream = !isSnapshot && isScheduleView;

      if (isMainStream) {
        const locationModel = queryClient.getQueryData([
          "location",
          locationID,
        ]);

        const updatedGlobalEmployees = locationModel.Employees.items.filter(
          (employee) =>
            !deletedGlobalEmployeeIDs.includes(employee.id) &&
            !employee._deleted
        );

        const resultingLocation = {
          ...locationModel,
          Employees: {
            items: updatedGlobalEmployees,
          },
          order,
          OpenShifts,
        };

        locationMutation.mutation.mutate({
          actionType: ACTION_TYPES.deleteScheduleEmployees,
          deletedGlobalEmployeeIDs,
          newLocation: resultingLocation,
        });
      }

      const { frontendSettings } = updatedRosterInView;
      const customKeywords = interpretCustomKeywordsData(
        getCustomKeywordsDataFromFrontendSettings(frontendSettings)
      );
      updateFullWarnings(updatedRosterInView, customKeywords);
    },
    [
      rosterMutation,
      locationMutation,
      rosterID,
      queryClient,
      updateNumSeatsBy,
      plan,
      user,
      updateFullWarnings,
    ]
  );

  const updateNextRosterHistory = useCallback(
    async (locationModel, updatedGlobalEmployees, updatedRosterInView) => {
      const { annualLeaveKeyword } = interpretCustomKeywordsData(
        getCustomKeywordsDataFromLocation(locationModel)
      );

      const { startDate, numDays } = updatedRosterInView;

      const finishDate = new DateTime(startDate)
        .addDays(numDays - 1)
        .toFormat("AWS");

      const rosters = locationModel.Rosters.items.filter(
        (roster) => !roster._deleted
      );
      const allRosters = [...rosters];

      const nextRosterStartDate = new DateTime(finishDate)
        .addDays(1)
        .toFormat("AWS");

      const nextRoster = rosters.find(
        (roster) =>
          roster.startDate === nextRosterStartDate && !roster.isSnapshot
      );

      const employeesNextHistory = getNextRosterHistoryFromPublishedShifts(
        updatedRosterInView.Employees,
        finishDate
      );

      let nextRosterModel;

      if (nextRoster) {
        nextRosterModel = await getRosterModelById(nextRoster.id);
        const { finishDate: nextRosterFinishDate } =
          getRosterStartAndFinishDate(nextRosterModel);
        const rosterEmployeesToCreate = getUnexistingRosterEmployeesToCreate(
          nextRosterModel,
          updatedGlobalEmployees,
          nextRosterStartDate,
          nextRosterFinishDate
        );
        nextRosterModel.Employees = [
          ...nextRosterModel.Employees,
          ...rosterEmployeesToCreate,
        ];
      } else {
        const rosterInfo = createRosterForScheduleView(
          locationModel.id,
          getActualNumDays(nextRosterStartDate, locationModel.defaultNumDays),
          nextRosterStartDate,
          updatedGlobalEmployees,
          annualLeaveKeyword
        );
        nextRosterModel = await createRosterModel(
          rosterInfo,
          locationModel.owner
        );
        allRosters.push(nextRosterModel);
      }

      const nextRosterInView = getRosterInView(
        locationModel,
        updatedGlobalEmployees,
        nextRosterModel
      );

      const updatedNextRosterEmployees = updateEmployeesWithOldEmployeeData(
        nextRosterInView.Employees,
        employeesNextHistory
      )
        .filter((employee) =>
          getIds(nextRosterInView.Employees).includes(employee.id)
        )
        .map((employee) => convertScheduleEmployeeToRosterEmployee(employee));

      const updatedNextRoster = {
        ...nextRosterModel,
        Employees: updatedNextRosterEmployees,
      };

      const updatedAllRosters = allRosters.map((roster) => {
        if (updatedNextRoster.id === roster.id) {
          return updatedNextRoster;
        }
        return roster;
      });

      await updateRosterModel(nextRosterModel.id, {
        Employees: updatedNextRosterEmployees,
        _version: nextRosterModel._version,
      });

      return updatedAllRosters;
    },
    []
  );

  const publishSchedule = useCallback(
    async (
      updatedGlobalEmployees,
      updatedRosterInView,
      publishStartDate,
      finishDate
    ) => {
      const locationModel = queryClient.getQueryData([
        "location",
        updatedRosterInView.locationID,
      ]);

      const globalEmployeesUpdatedFields = updatedGlobalEmployees.map(
        (employee) => ({
          id: employee.id,
          PublishedAllocations: employee.PublishedAllocations,
        })
      );

      const updatedLocation = addCompletedOnboardingTasks(locationModel, [
        ONBOARDING_TASK_ID.publishSchedules,
      ]);

      const allRosters = await updateNextRosterHistory(
        locationModel,
        updatedGlobalEmployees,
        updatedRosterInView
      );

      const resultingLocation = {
        ...updatedLocation,
        Rosters: {
          items: allRosters,
        },
        Employees: {
          items: updatedGlobalEmployees,
        },
      };

      const updatedOpenShifts = await publishOpenShifts(
        resultingLocation,
        updatedGlobalEmployees,
        publishStartDate,
        finishDate,
        locationModel.Shifts,
        locationModel.Tasks
      );

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateFields,
        newLocation: { ...resultingLocation, OpenShifts: updatedOpenShifts },
        newLocationFields: {
          completedOnboardingTasks: resultingLocation.completedOnboardingTasks,
          OpenShifts: updatedOpenShifts,
        },
        newEmployeesFields: globalEmployeesUpdatedFields,
      });

      return resultingLocation;
    },
    [locationMutation, queryClient, updateNextRosterHistory]
  );

  const updateOpenShifts = useCallback(
    async (updatedOpenShifts, updatedGlobalEmployees = []) => {
      const updatedEmployees = deepCopyObject(updatedGlobalEmployees);
      const locationModel = queryClient.getQueryData(["location", locationID]);
      const resultingLocation = {
        ...locationModel,
        OpenShifts: updatedOpenShifts,
        ...(updatedEmployees.length > 0 && {
          Employees: {
            items: updatedEmployees,
          },
        }),
      };

      const globalEmployeesUpdatedFields = updatedEmployees.map(
        (updatedGlobalEmployee) => ({
          id: updatedGlobalEmployee.id,
          PublishedAllocations: updatedGlobalEmployee.PublishedAllocations,
        })
      );

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateFields,
        newLocation: resultingLocation,
        newLocationFields: { OpenShifts: updatedOpenShifts },
        newEmployeesFields: globalEmployeesUpdatedFields,
      });

      return resultingLocation;
    },
    [locationMutation, locationID, queryClient]
  );

  const createRosterModelForMainStreamInDifferentPeriod = useCallback(
    async (locationID, numDays, startDate) => {
      if (!locationID || !numDays || !startDate) {
        throw new Error("Not enough info");
      }

      const locationModel = queryClient.getQueryData(["location", locationID]);
      const globalEmployees = locationModel.Employees.items;

      const newRoster = await createRosterModelForMainStreamScheduleView(
        locationModel,
        globalEmployees,
        numDays,
        startDate
      );
      const allRosters = [...locationModel.Rosters.items, newRoster];

      const resultingLocation = {
        ...locationModel,
        Rosters: {
          items: allRosters,
        },
      };

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateFields,
        newLocation: resultingLocation,
        newLocationFields: {},
        newEmployeesFields: [],
      });
      return newRoster;
    },
    [locationMutation.mutation, queryClient]
  );

  const updateNote = useCallback(
    async (locationID, globalEmployee, date, note) => {
      const locationModel = queryClient.getQueryData(["location", locationID]);

      let targetPublishedAllocationsExists = false;
      const updatedPublishedAllocations =
        globalEmployee.PublishedAllocations.map((allocation) => {
          if (allocation.date === date) {
            targetPublishedAllocationsExists = true;
            return {
              ...allocation,
              note,
            };
          }
          return allocation;
        });

      if (!targetPublishedAllocationsExists) {
        updatedPublishedAllocations.push({
          date,
          draftAllocation: null,
          publishedAllocation: null,
          isOpenShift: false,
          note,
        });
        sortByDateField(updatedPublishedAllocations, "date", true);
      }

      const updatedGlobalEmployee = {
        ...globalEmployee,
        PublishedAllocations: updatedPublishedAllocations,
      };

      const resultingLocation = {
        ...locationModel,
        Employees: {
          items: locationModel.Employees.items.map((employee) => {
            if (employee.id === globalEmployee.id) {
              return updatedGlobalEmployee;
            }
            return employee;
          }),
        },
      };

      const globalEmployeeUpdatedFields = {
        id: updatedGlobalEmployee.id,
        PublishedAllocations: updatedGlobalEmployee.PublishedAllocations,
        _version: updatedGlobalEmployee._version,
      };

      await locationMutation.mutation.mutateAsync({
        actionType: ACTION_TYPES.updateGlobalEmployee,
        newLocation: resultingLocation,
        globalEmployeeUpdatedFields,
      });

      return updatedGlobalEmployee;
    },
    [locationMutation, queryClient]
  );

  return {
    isMutationLoading: rosterMutation.isLoading || locationMutation.isLoading,
    updateFields,
    deleteRosterSolutions,
    createScheduleEmployees,
    deleteScheduleEmployees,
    publishSchedule,
    updateOpenShifts,
    createSnapshotRoster,
    updateSnapshots,
    duplicateSnapshot,
    deleteSnapshots,
    createRosterModelForMainStreamInDifferentPeriod,
    updateNote,
  };
}

export const reorderModelsData = async (
  params,
  gridGetter,
  models,
  updateFunction
) => {
  const reorderedData = gridGetter(params.api);
  const reorderedDataWithFixedReservedRows = reorderedData.filter(
    (shift) => !shift.id.startsWith("reserved")
  );
  const orderedIdArr = reorderedDataWithFixedReservedRows.map(
    (data) => data.id
  );
  const updatedShifts = getReorderedEntities(models, orderedIdArr);
  updateFunction(updatedShifts);
};

export const addNewModels = (
  name,
  shouldAddSuffixNum,
  modelTemplateFunction,
  numItems,
  models,
  updateFunction,
  defaultValues
) => {
  let nextDefaultShiftNameNumber = null;
  if (shouldAddSuffixNum) {
    const modelNames = models.map((model) => model.name);
    nextDefaultShiftNameNumber = getNextDefaultEntityNameNumber(
      name,
      modelNames
    );
  }
  const newField = modelTemplateFunction({
    name: name,
    numItems,
    nextNumberedSuffix: nextDefaultShiftNameNumber,
    ...defaultValues,
  });
  const updatedFields = [...models, ...newField];
  if (updatedFields) updateFunction(updatedFields, false);

  return updatedFields;
};

export const updateModels = (newGridContent, models, updateFunction) => {
  const updatedModels = models.map((model) => {
    const updatedModel = newGridContent.find((s) => s.id === model.id);
    if (!updatedModel) {
      return model;
    }
    return {
      ...updatedModel,
      id: model.id,
    };
  });
  if (updateFunction) updateFunction(updatedModels);
  return updatedModels;
};

export const duplicateModels = async (
  selectedEntitiesInfo,
  predefinedModelKeywords,
  models,
  updateFunction,
  namePrefix = null,
  entityType = null,
  shouldGenerateShortId = true
) => {
  const duplicatedModelIDs = selectedEntitiesInfo
    .filter((shift) => !predefinedModelKeywords.includes(shift.name))
    .map((shift) => shift.id);
  const duplicatedModels = getDuplicatedEntities(
    models,
    duplicatedModelIDs,
    namePrefix,
    entityType,
    shouldGenerateShortId
  );
  const updatedModels = [...models, ...duplicatedModels];

  updateFunction(updatedModels);
};

export const removeModels = async (
  toBeDeletedItems,
  models,
  updateFunction
) => {
  const toBeDeletedItemIds = toBeDeletedItems.map((item) => item.id);
  const updatedModels = models.filter(
    (shift) => !toBeDeletedItemIds.includes(shift.id)
  );
  updateFunction(updatedModels);
};

export async function getUpdatedOtherRosters(
  locationModel,
  rosterModel,
  isMainStream,
  deletedFieldItemShortIds,
  updatedFieldNames,
  previousRosterData,
  updatedRosterInView
) {
  if (!isMainStream) {
    return [];
  }

  let mainStreamRosters = null;
  let needsToPropagateDeletedEntity = false;
  let needsToAddRosterEmployees = false;

  if (deletedFieldItemShortIds.length > 0) {
    needsToPropagateDeletedEntity = true;
  }

  const prevEmployees = previousRosterData.Employees;
  const updatedEmployees = updatedRosterInView.Employees;

  if (updatedFieldNames.includes("Employees")) {
    for (const employee of updatedEmployees) {
      const targetPrevEmployee = prevEmployees.find(
        (emp) => emp.id === employee.id
      );
      if (!targetPrevEmployee) {
        continue;
      }
      if (
        employee.startDate !== targetPrevEmployee.startDate ||
        employee.finishDate !== targetPrevEmployee.finishDate
      ) {
        needsToAddRosterEmployees = true;
        break;
      }
    }
  }

  if (!needsToPropagateDeletedEntity && !needsToAddRosterEmployees) {
    return [];
  }

  mainStreamRosters = await getAllMainStreamRostersForPropagation(
    locationModel.id
  );
  const otherMainStreamRosters = mainStreamRosters.filter(
    ({ id }) => id !== rosterModel.id
  );

  const updatedOtherRosters = [];

  // Update for delete propagation
  if (needsToPropagateDeletedEntity) {
    const { updatedRosterIds: propagatedOtherRosterIds, resultingRosters } =
      propagateDeletionToOtherRostersInMainStream(
        locationModel,
        otherMainStreamRosters,
        deletedFieldItemShortIds
      );

    propagatedOtherRosterIds.forEach((propagatedRosterId) => {
      const propagatedRoster = resultingRosters.find(
        ({ id }) => id === propagatedRosterId
      );
      updatedOtherRosters.push(propagatedRoster);
    });
  }

  // Update for employee start/finish date changes
  if (needsToAddRosterEmployees) {
    for (const otherRoster of otherMainStreamRosters) {
      const periodStartDate = otherRoster.startDate;
      const periodFinishDate = DateTime.addDaysToDate(
        periodStartDate,
        otherRoster.numDays - 1
      ).toFormat("AWS");

      const rosterEmployeesToCreate = getUnexistingRosterEmployeesToCreate(
        otherRoster,
        updatedEmployees,
        periodStartDate,
        periodFinishDate
      );

      if (rosterEmployeesToCreate.length === 0) {
        continue;
      }

      let targetRoster = updatedOtherRosters.find(
        ({ id }) => id === otherRoster.id
      );
      if (!targetRoster) {
        targetRoster = otherMainStreamRosters.find(
          ({ id }) => id === otherRoster.id
        );
        updatedOtherRosters.push(targetRoster);
      }

      targetRoster.Employees = [
        ...targetRoster.Employees,
        ...rosterEmployeesToCreate,
      ];
    }
  }

  return updatedOtherRosters;
}

export default useRosterModelMutation;
