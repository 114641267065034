export const orderGlobalEmployees = (globalEmployees, order) => {
  const orderedEmployees = [];
  const unorderedEmployees = [];

  order.forEach((empID) => {
    const targetEmp = globalEmployees.find((e) => e.id === empID);
    if (targetEmp) {
      orderedEmployees.push(targetEmp);
    }
  });

  for (let i = 0; i < globalEmployees.length; i++) {
    if (!order.includes(globalEmployees[i].id)) {
      unorderedEmployees.push(globalEmployees[i]);
    }
  }
  return [...orderedEmployees, ...unorderedEmployees];
};
