import { useCallback, useEffect, useMemo, useState } from "react";
import { findBestFitSubRule } from "../../../../rules/service/ruleBuilder";
import RuleBuilderModalViewer from "../RuleBuilderModalViewer/RuleBuilderModalViewer";
import { v4 as uuidv4 } from "uuid";
import { useDisableBodyScrollOnMount } from "../../../hooks/useDisableBodyScrollOnMount";
import { KEYWORD_NA } from "../../../../../constants/keywords";

const RuleBuilderModalContainer = ({
  updateCustomRules,
  handleClose,
  rules,
  rulesData,
  rulesInRoster,
  isRulesWithExceptionEnabled,
  initialRulesInBuilder,
  clearDefaultNewRules,
  plan,
}) => {
  const [rulesList, setRulesList] = useState(() => []);
  const [selectedRule, setSelectedRule] = useState({
    value: rules[0].name,
    label: rules[0].formattedName,
  });
  const [existingRulesList, setExistingRulesList] = useState([]);

  useEffect(() => {
    return () => clearDefaultNewRules();
  }, [clearDefaultNewRules]);

  // Opening the modal
  useDisableBodyScrollOnMount();

  const getValuesPerExistingRules = useCallback(() => {
    const ruleValues = {};
    for (const data of rulesData) {
      for (const [key, value] of Object.entries(data)) {
        if (key !== "id" && key !== "name") {
          if (!ruleValues[key]) {
            ruleValues[key] = [value];
          } else {
            ruleValues[key] = [...ruleValues[key], value];
          }
        }
      }
    }
    return ruleValues;
  }, [rulesData]);

  const getAllExistingRuleInfo = useCallback(() => {
    const valuesPerExistingRules = getValuesPerExistingRules();
    const existingRuleInfo = [];
    for (const customRule of rulesInRoster) {
      for (const rule of rules) {
        const matchingSubruleIdx = rule.subrules.findIndex(
          (s) => s.ruleTemplate === customRule.template
        );
        if (matchingSubruleIdx !== -1) {
          const matchingSubrule = rule.subrules[matchingSubruleIdx];

          let actualName = rulesInRoster.find(
            (r) => r.template === matchingSubrule.ruleTemplate
          ).name;
          if (!actualName) {
            actualName = matchingSubrule.ruleName;
          }
          const allValues = valuesPerExistingRules[actualName];

          if (allValues === null || allValues === undefined) continue;

          let representativeVal;
          if (
            rule.valueType === "integer" ||
            rule.valueType === "half_integer"
          ) {
            const numValues = allValues.map((item) => {
              if (isNaN(item)) {
                return KEYWORD_NA;
              }
              if (rule.valueType === "integer") {
                return parseInt(item);
              } else {
                return parseFloat(item);
              }
            });

            const max = Math.max.apply(null, numValues);
            const min = Math.min.apply(null, numValues);
            if (isNaN(max)) {
              representativeVal = "";
            } else if (max === min) {
              representativeVal = max.toString();
            } else {
              representativeVal = min + "-" + max;
            }
          } else {
            representativeVal = "";
          }

          existingRuleInfo.push({
            id: uuidv4(),
            name: rule.name,
            template: customRule.template,
            ruleName: matchingSubrule.ruleName,
            valueType: rule.valueType,
            defaultValue: "",
            initialDefaultValue: representativeVal,
            subrule: matchingSubruleIdx,
          });
          break;
        }
      }
    }
    return existingRuleInfo;
  }, [rules, rulesInRoster, getValuesPerExistingRules]);

  const previousRulesInfo = useMemo(
    () => getAllExistingRuleInfo(),
    [getAllExistingRuleInfo]
  );

  useEffect(() => {
    const existingRuleTemplates = previousRulesInfo.map((r) => r.template);

    let defaultNewRules = [];
    if (initialRulesInBuilder) {
      defaultNewRules = initialRulesInBuilder.filter(
        (r) => !existingRuleTemplates.includes(r.template)
      );
    }

    setRulesList(defaultNewRules);
    setExistingRulesList(previousRulesInfo);
  }, [
    setExistingRulesList,
    getAllExistingRuleInfo,
    initialRulesInBuilder,
    previousRulesInfo,
  ]);

  // Add rule to the dnd list
  const addRule = (ruleName) => {
    let rule = rules.filter((fullRule) => fullRule.name === ruleName)[0];
    setRulesList([
      ...rulesList,
      {
        id: uuidv4(),
        name: ruleName,
        subrule: 0,
        defaultValue: rule.defaultValue === undefined ? "" : rule.defaultValue,
      },
    ]);
  };

  // Add multiple rules to the dnd list
  const addRules = (newRules) => {
    setRulesList([...rulesList, ...newRules]);
  };

  // Change value in the RuleBlock drop downs
  const changeRuleValue = (rule, id, valueList, isForNewRules = true) => {
    const rulesDisplayed = isForNewRules
      ? [...rulesList]
      : [...existingRulesList];
    const subruleIndex = findBestFitSubRule(rule.subrules, valueList);

    const newRulesList = rulesDisplayed.map((rule) => {
      if (rule.id === id) {
        return {
          ...rule,
          subrule: subruleIndex,
        };
      }
      return rule;
    });

    if (isForNewRules) {
      setRulesList(newRulesList);
    } else {
      setExistingRulesList(newRulesList);
    }
  };

  // Remove btn clicked in the RuleBlock
  const removeRule = (id, isForNewRules = true) => {
    const rulesDisplayed = isForNewRules
      ? [...rulesList]
      : [...existingRulesList];
    const newRulesList = rulesDisplayed.filter((rule) => rule.id !== id);

    if (isForNewRules) {
      setRulesList(newRulesList);
    } else {
      setExistingRulesList(newRulesList);
    }
  };

  const setDefaultValue = (id, value, isForNewRules = true) => {
    let rulesDisplayed = isForNewRules
      ? [...rulesList]
      : [...existingRulesList];

    const newRulesList = rulesDisplayed.map((rule) => {
      if (rule.id === id) {
        return {
          ...rule,
          defaultValue: value,
        };
      }
      return rule;
    });

    if (isForNewRules) {
      setRulesList(newRulesList);
    } else {
      setExistingRulesList(newRulesList);
    }
  };

  return (
    <RuleBuilderModalViewer
      addRule={addRule}
      addRules={addRules}
      rules={rules}
      rulesList={rulesList}
      setRulesList={setRulesList}
      updateCustomRules={updateCustomRules}
      handleClose={handleClose}
      changeRuleValue={changeRuleValue}
      removeRule={removeRule}
      setDefaultValue={setDefaultValue}
      selectedRule={selectedRule}
      setSelectedRule={setSelectedRule}
      existingRulesList={existingRulesList}
      setExistingRulesList={setExistingRulesList}
      isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
      plan={plan}
      previousRulesInfo={previousRulesInfo}
    />
  );
};

export default RuleBuilderModalContainer;
