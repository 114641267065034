import { useEffect, useMemo, useRef } from "react";
import styles from "./PreferencesOverviewGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import ImportanceSelector from "../ImportanceSelector/ImportanceSelector";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import {
  allocationDropdownOptionsValueSetter,
  convertShortIdsCellValueToEntityNames,
  createBasicContextMenu,
  DateTime,
  defaultFilterValueGetter,
  flatOptions,
  getDatesColumnDefs,
  getDayNumFromColFieldName,
  getFilledMonthsArray,
  getGlobalDatesColumnDefs,
  getRequestOptions,
  onFilterTextBoxChanged,
  preferencesValueFormatter,
  suppressEnterKey,
} from "../../../../../utils";
import { KEYWORD_OFF } from "../../../../../constants/keywords";
import { getWeekendsCellStyle } from "../../../rosteredAllocations/service/styleGetters";
import {
  processAllocationsFromClipboard,
  processPreferencesCellForClipboard,
} from "../../../../../utils/agGridUtils/clipboard";

export const preferenceCellClassRules = (warnings, warningType) => {
  return {
    weekends: getWeekendsCellStyle,
    "invalid-cell": (params) => {
      if (!params.value) return false;
      let processedValue = params.value;
      if (
        processedValue.endsWith("!") ||
        processedValue.endsWith("*") ||
        processedValue.endsWith("?")
      ) {
        processedValue = processedValue.slice(0, -1);
      }

      if (warnings) {
        const warning = warnings.find(
          (warning) => warning.warningType === warningType
        );
        if (warning && warning.values.includes(processedValue)) {
          return true;
        }
      }
    },
    "critical-background": (params) => {
      if (!params.value) return false;
      if (params.value.endsWith("!")) {
        return true;
      }
    },
    "high-background": (params) => {
      if (!params.value) return false;
      if (params.value.endsWith("?")) {
        return true;
      }
    },
    "special-background": (params) => {
      if (!params.value) return false;
      if (params.value.endsWith("*")) {
        return true;
      }
    },
    "border-left": (params) => {
      const headerName = params.colDef.headerName;
      if (headerName === "Sa") {
        return true;
      }
      return false;
    },
    "border-right": (params) => {
      const headerName = params.colDef.headerName;
      if (headerName === "Su") {
        return true;
      }
      return false;
    },
  };
};

const ManageButton = ({ onClick }) => {
  return (
    <BasicButton
      color="#219ec9"
      hoverColor="#1f91b7"
      dataTestId="add-btn"
      onClick={onClick}
      customStyle={{
        borderRadius: "10px",
      }}
    >
      Manage all
    </BasicButton>
  );
};

const NoVisibleDateOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
    Date range is out of your current roster model period, click "Clear filter"
    to go back
  </span>`;

const NoGlobalEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
  You have no employees set up for this location
  </span>`;

const NoEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
  You have no employees set up for this roster
  </span>`;

const PreferencesOverviewGrid = ({
  locationID,
  startDate,
  employeesData,
  enumeratedTasks,
  enumeratedShiftTasks,
  dayColumns,
  setGridApiToParent,
  toggleRequestManager,
  updateData,
  setColumnApiToParent,
  isSaving,
  shouldSpecifyWeeksInColHeader,
  overviewWarnings,
  weekdayColWidth,
  weekendColWidth,
  isWDayWEndSeparate,
  setWeekdayColWidth,
  setWeekendColWidth,
  setIsWDayWEndSeparate,
  longestStr,
  isGlobal,
  changePrefLevel,
  suffix,
  approveSpecialRequests,
  showManageBtn = true,
  showWarningBox = true,
  customStyle,
  title = null,
  isIndividualView = false,
  gridApi,
  showActionBar = true,
  exportToCsv,
  exportToExcel,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  customTopComponent,
  shouldInsertOffOnPasteBlank,
  getDataFromGrid,
  isScheduleView,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  skills,
  areas,
  showSkillsColumn = false,
  employeeNames,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const isFirstOverviewHeaderRender = useRef(true);
  useEffect(() => {
    if (gridApi) {
      if (isFirstOverviewHeaderRender.current) {
        isFirstOverviewHeaderRender.current = false;
        return;
      }
      setTimeout(() => {
        gridApi.refreshHeader();
      }, 10);
    }
  }, [startDate, gridApi]);

  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const isWarningPresent = overviewWarnings && overviewWarnings.length > 0;

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const shiftOptions = useMemo(
    () =>
      getRequestOptions(
        [KEYWORD_OFF],
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      ),
    [
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      areas,
      enumeratedTasks,
      enumeratedShiftTasks,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
    ]
  );

  const dateColumnDefs = useMemo(() => {
    if (shouldSpecifyWeeksInColHeader) {
      let datesColumnDefs = getDatesColumnDefs(dayColumns.length, startDate);
      const customDatesColumnDefs = datesColumnDefs.map(
        (weekColDefs, weekGroupIdx) => {
          const customWeekColDefs = weekColDefs.children.map(
            (dayColDefs, dayGroupIdx) => {
              const customDayColDefs = dayColDefs.children.map((colDef) => {
                const offset = getDayNumFromColFieldName(colDef.field);
                const DoW = new DateTime(startDate)
                  .addDays(offset)
                  .getDayOfWeek("dd");

                return {
                  ...colDef,
                  suppressMenu: true,
                  editable: true,
                  width:
                    DoW === "Sa" || DoW === "Su"
                      ? weekendColWidth
                      : weekdayColWidth,
                  cellClassRules: preferenceCellClassRules(
                    overviewWarnings,
                    "invalid Days input"
                  ),
                  cellEditor: "dropdownSingleSelector",
                  cellEditorParams: {
                    width: 80,
                    height: 100,
                    options: shiftOptions,
                  },
                  cellEditorPopup: true,
                  valueFormatter: (params) =>
                    preferencesValueFormatter(
                      params,
                      [KEYWORD_OFF],
                      shifts,
                      shiftGroups,
                      tasks,
                      subTasks,
                      areas,
                      skills
                    ),
                  valueSetter: (params) => {
                    return allocationDropdownOptionsValueSetter(
                      params,
                      flatOptions(shiftOptions)
                    );
                  },
                  suppressKeyboardEvent: suppressEnterKey,
                };
              });
              return {
                ...dayColDefs,
                groupId: `dayColGroup_${weekGroupIdx}_${dayGroupIdx}`,
                children: customDayColDefs,
              };
            }
          );
          return {
            ...weekColDefs,
            groupId: `weekColGroup${weekGroupIdx}`,
            children: customWeekColDefs,
          };
        }
      );
      return customDatesColumnDefs;
    } else {
      const monthsAndDays = getFilledMonthsArray(dayColumns, startDate);
      const noWeekColDefs = getGlobalDatesColumnDefs(monthsAndDays, startDate);

      const customColDefs = noWeekColDefs.map((monthColDefs) => {
        const customMonthColDefs = monthColDefs.children.map((dayColDefs) => {
          const customDayColDefs = dayColDefs.children.map((dowColDefs) => {
            const offset = getDayNumFromColFieldName(dowColDefs.field);
            const DoW = new DateTime(startDate)
              .addDays(offset)
              .getDayOfWeek("dd");
            return {
              ...dowColDefs,
              editable: true,
              suppressMenu: true,
              width:
                DoW === "Sa" || DoW === "Su"
                  ? weekendColWidth
                  : weekdayColWidth,
              cellClassRules: preferenceCellClassRules(
                overviewWarnings,
                "invalid Days input"
              ),
              cellEditor: "dropdownSingleSelector",
              cellEditorParams: {
                suffix,
                width: 80,
                options: shiftOptions,
              },
              cellEditorPopup: true,
              valueFormatter: (params) =>
                preferencesValueFormatter(
                  params,
                  [KEYWORD_OFF],
                  shifts,
                  shiftGroups,
                  tasks,
                  subTasks,
                  areas,
                  skills
                ),
              valueSetter: (params) =>
                allocationDropdownOptionsValueSetter(
                  params,
                  flatOptions(shiftOptions)
                ),
              suppressKeyboardEvent: suppressEnterKey,
            };
          });
          return { ...dayColDefs, children: customDayColDefs };
        });
        return {
          ...monthColDefs,
          children: customMonthColDefs,
        };
      });
      return customColDefs;
    }
  }, [
    overviewWarnings,
    shiftOptions,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    dayColumns,
    shouldSpecifyWeeksInColHeader,
    suffix,
    shiftGroups,
    shifts,
    tasks,
    subTasks,
    areas,
    skills,
  ]);

  const columnDefs = useMemo(() => {
    const employeesColDefs = [
      {
        field: "name",
        sortable: true,
        editable: false,
        width: 150,
        pinned: "left",
      },
    ];

    if (showSkillsColumn && skills) {
      employeesColDefs.push({
        field: "skills",
        sortable: true,
        editable: false,
        width: 100,
        pinned: "left",
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      });
    }

    return [
      {
        headerName: "Employees",
        groupId: "employeesGroup",
        children: employeesColDefs,
      },
      ...dateColumnDefs,
    ];
  }, [dateColumnDefs, skills, showSkillsColumn]);

  const manageButton =
    toggleRequestManager && showManageBtn ? (
      <ManageButton onClick={toggleRequestManager} />
    ) : (
      <></>
    );

  const getImportanceSelector = () => {
    if (!isIndividualView) {
      return () => <ImportanceSelector changePrefLevel={changePrefLevel} />;
    }
  };

  const getApproveAllPendingBtn = () => {
    if (isGlobal) {
      return () => (
        <button
          className={styles.approveStrongBtn}
          onClick={() => {
            approveSpecialRequests();
          }}
        >
          Approve all pending special preferences
        </button>
      );
    }
  };

  return (
    <Layout
      title={title ? title : "Overview"}
      isSubheading={true}
      headerRight={() => {
        if (isIndividualView) {
          return (
            <div className={styles.headerRightWrapper}>
              <ImportanceSelector
                changePrefLevel={changePrefLevel}
                isGlobal={isGlobal}
              />
            </div>
          );
        }
        if (!isScheduleView) {
          return manageButton;
        }
        return null;
      }}
    >
      <div className={styles.container}>
        <div className={styles.topLine}>
          <div className={styles.left}>{customTopComponent}</div>
          <div className={styles.right}>{isScheduleView && manageButton}</div>
        </div>

        {showActionBar && (
          <ActionBar
            locationID={locationID}
            searchBarSettings={{
              tableName: "preferencesOverview",
              onFilterInputChanged,
            }}
            adjustWidthSettings={{
              longestStr,
              weekdayColWidth,
              weekendColWidth,
              isWDayWEndSeparate,
              setWeekdayColWidth,
              setWeekendColWidth,
              setIsWDayWEndSeparate,
              tableName: isGlobal ? "globalPreferences" : "preferences",
            }}
            customComponents={[
              getImportanceSelector(),
              getApproveAllPendingBtn(),
            ]}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
        )}
        <DataEntryTable
          customStyle={customStyle ? customStyle.dataEntryTable : {}}
          columnHoverHighlight={true}
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          processDataFromClipboard={(params) =>
            processAllocationsFromClipboard(
              params,
              shouldInsertOffOnPasteBlank,
              employeeNames
            )
          }
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            onColumnVisible: (params) => {
              const allColumns = params.columnApi.getColumns();
              const visibleColumns = allColumns.filter(
                (item) => item.visible === true
              );
              if (visibleColumns.length === 1) {
                params.api.showNoRowsOverlay();
              } else {
                params.api.hideOverlay();
              }
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMultiRangeSelection: true,
          }}
          context={{
            options: flatOptions(shiftOptions),
          }}
          setGridApiToParent={setGridApiToParent}
          components={{
            dropdownSingleSelector: DropdownSingleSelector,
          }}
          setColumnApiToParent={setColumnApiToParent}
          overlayNoRowsTemplate={
            employeesData.length === 0
              ? isGlobal
                ? NoGlobalEmployeesOverlay
                : NoEmployeesOverlay
              : NoVisibleDateOverlay
          }
          tableName="preferences-overview"
          getDataFromGrid={getDataFromGrid}
          processCellForClipboard={processPreferencesCellForClipboard}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        {isGlobal ? (
          <div className={styles.solutionKey}>
            <div className={styles.specialKey}>
              Special preferences requiring approval
            </div>
          </div>
        ) : null}
      </div>
      <p className={styles["saving-line"]}>
        {isSaving ? "saving..." : "saved"}
      </p>
      {isWarningPresent && showWarningBox && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(overviewWarnings)}
            bottomText={
              "Please change the allocated fixed shift/ tasks or if they are correct, change the restrictions on the employee page."
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default PreferencesOverviewGrid;
