import styles from "./UnsupportedViewForDevice.module.css";
import colorLogo from "../../../../assets/images/logo_coloured.png";
import solutionsFeatureImage from "../../../../assets/images/solutionsFeatureImage.png";
import myRosterFeatureImage from "../../../../assets/images/myRosterFeatureImage.png";
import { useUserStore } from "../../../../globalStore/appStore";
import { getFirstAndLastNameOfUser, logOut } from "../../../auth/service/auth";
import { isMobileViewOrApp } from "../../../../globalStore/deviceInfoStore";
import { useEffect } from "react";
import SimpleMobileHeader from "../../../employeeApp/components/mobile/SimpleMobileHeader/SimpleMobileHeader";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";

const UnsupportedViewForDevice = () => {
  const redirectTo = useRedirect();
  const { user } = useUserStore();
  const { firstName } = getFirstAndLastNameOfUser(user);
  const isMobile = isMobileViewOrApp();

  const onLeftButtonClick = () => {
    logOut().then(() => {
      redirectTo({ pageName: PAGE_NAMES.main });
    });
  };

  useEffect(() => {
    if (!isMobile) {
      redirectTo({ pageName: PAGE_NAMES.main });
    }
  }, [isMobile, redirectTo]);

  return (
    <div className={styles.container}>
      <SimpleMobileHeader
        label="ROSTERLAB"
        onLeftButtonClick={onLeftButtonClick}
        leftButtonContent={<span className={styles.signout}>Sign out</span>}
      />
      <div className={styles.top}>
        <h1>Hi{` ${firstName}`},</h1>
        <p className={styles.section}>
          Our rostering app is designed for{" "}
          <span className={styles.emph}>bigger screens</span>. Please use a{" "}
          <span className={styles.emph}>tablet or bigger screen</span> to
          explore making schedules.
        </p>
        <p className={styles.section}>
          Our mobile app is only available for staff who have been invited by a
          scheduler. This app can be used to view published schedules and add
          requests/preferences.
        </p>
      </div>
      <div className={styles.images}>
        <img className={styles.img} src={solutionsFeatureImage} />
        <img className={styles.img} src={myRosterFeatureImage} />
      </div>
      <footer className={styles.footer}>
        <img src={colorLogo} onClick={() => redirectTo(PAGE_NAMES.main)} />
      </footer>
    </div>
  );
};

export default UnsupportedViewForDevice;
