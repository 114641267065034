import { KEYWORD_OFF } from "../../../constants/keywords";

const RerosteringToolTip = (props) => {
  const { data, colDef, comparisonData } = props;

  if (!comparisonData) return null;
  const employeeDifferences = comparisonData.filter(
    (emp) => emp.id === data.id
  )[0];

  if (!employeeDifferences) return null;

  const d = parseInt(colDef.field.substring(1)) - 1;

  if (!employeeDifferences.days[d].isDifferent) return null;

  let oldAllocation = employeeDifferences.days[d].oldAllocation;
  if (oldAllocation === "-") oldAllocation = KEYWORD_OFF;

  return (
    <div
      style={{
        border: "1px solid black",
        backgroundColor: "white",
        padding: "20px",
      }}
    >
      <p>New Allocation: {props.value}</p>
      <p>Previous Allocation: {oldAllocation}</p>
    </div>
  );
};

export default RerosteringToolTip;
