import { DateTime, isInteger, mod } from "../../../utils";
import { updateAnnualLeaveRequestFixedShiftsToRosterEmployee } from "../../store/service/shared/sharedStoreApi";
import { getStatisticsTemplate } from "../../statistics/service/template";
import { addDays, isWithinInterval, parseISO } from "date-fns";
import { getDefaultDisplayedStatistics } from "../../../constants";
import { normalizeSpecialPreferences } from "../../../utils/queryUtils/sharedModelDataGetters";
import { getActualNumDays } from "../../../utils/queryUtils/monthViewUtils";
import { InvalidUploadedFileError } from "../../../errors/errors";
import { KEYWORD_NA } from "../../../constants/keywords";

/**
 * This function finds the valid roster start date which includes the provided `date`
 * @param {*} locationStartDate
 * @param {*} date
 * @param {*} rosterNumDays
 * @returns
 */
export const inferRosterStartDate = (
  locationStartDate,
  date,
  rosterNumDays
) => {
  const baseDate = new DateTime(locationStartDate);
  const rosterStartDateFromQuery = new DateTime(date);

  if (rosterNumDays > 0) {
    // Week view
    const daysDiff = DateTime.getDifferenceInDays(
      baseDate,
      rosterStartDateFromQuery
    );
    const offset = mod(daysDiff, rosterNumDays);
    const validNearestRosterStartDate =
      rosterStartDateFromQuery.subtractDays(offset);
    return validNearestRosterStartDate.toFormat("AWS");
  } else if (rosterNumDays === -1) {
    // Month view
    let startOfMonth = rosterStartDateFromQuery.getStartDateOfMonth();

    if (baseDate.getDay() <= rosterStartDateFromQuery.getDay()) {
      return startOfMonth.addDays(baseDate.getDay() - 1).toFormat("AWS");
    } else {
      let startOfPreviousMonth = startOfMonth.subtractMonths(1);
      return startOfPreviousMonth
        .addDays(baseDate.getDay() - 1)
        .toFormat("AWS");
    }
  } else {
    throw new Error("Invalid rosterNumDays value");
  }
};

export const getEmployeeRosteredAllocations = (
  employee,
  leaveCodes,
  allDatesInSchedule
) => {
  const allocations = employee.PublishedAllocations;
  const requests = employee.Requests;

  const displayedRequests = requests
    .filter(
      (req) => !req.globalEmployeeID || req.globalEmployeeID === employee.id
    )
    .filter((req) => req.state === "Approved");

  const dayRequest = [];
  displayedRequests.forEach((req) => {
    const dates = DateTime.getAllDatesBetween(
      req.startDate,
      req.finishDate,
      true,
      true
    );
    const dateStrings = dates.map((date) => {
      let leaveCode = leaveCodes.find((code) => code.longname === req.request);
      if (!leaveCode) {
        leaveCode = leaveCodes[0];
      }
      return {
        request: leaveCode.shortname,
        date: date.toFormat("AWS"),
      };
    });

    dayRequest.push(...dateStrings);
  });

  const rosteredAllocations = allDatesInSchedule.map((date) => {
    const request = dayRequest.find((req) => req.date === date);
    if (request) {
      return request.request;
    }
    const allocation = allocations.find((allo) => allo.date === date);

    if (!allocation) {
      return "";
    }
    const { draftAllocation, publishedAllocation } = allocation;
    const displayedAllocation =
      draftAllocation !== undefined && draftAllocation !== null
        ? draftAllocation
        : publishedAllocation
        ? publishedAllocation
        : "";

    return displayedAllocation;
  });

  return rosteredAllocations;
};

export const globalEmployeeToScheduleEmployee = (
  globalEmployee,
  allDatesInRoster,
  rosterNumDays,
  startDate,
  rosterFinishDate,
  annualLeaveKeyword
) => {
  const employeeStartDate = globalEmployee.startDate;
  const employeeFinishDate = globalEmployee.finishDate;

  const startDateIndex = allDatesInRoster.findIndex(
    (date) => date === employeeStartDate
  );
  const finishDateIndex = allDatesInRoster.findIndex(
    (date) => date === employeeFinishDate
  );

  let fixedShifts = Array(rosterNumDays)
    .fill("")
    .map((allo, idx) => {
      if (startDateIndex !== -1 && idx < startDateIndex) {
        return KEYWORD_NA;
      }

      if (finishDateIndex !== -1 && idx > finishDateIndex) {
        return KEYWORD_NA;
      }
      return allo;
    });

  const requests = globalEmployee.Requests;
  for (const request of requests) {
    if (request.state !== "Approved") {
      continue;
    }
    const ALDates = DateTime.getOverlappingDatesGivenTwoRangesInfo(
      startDate,
      rosterFinishDate,
      request.startDate,
      request.finishDate
    );

    if (ALDates.length === 0) {
      continue;
    }

    fixedShifts = updateAnnualLeaveRequestFixedShiftsToRosterEmployee(
      startDate,
      ALDates,
      annualLeaveKeyword,
      fixedShifts,
      true
    );
  }

  const employeeData = {
    id: globalEmployee.id,
    globalEmployeeID: globalEmployee.id,
    Days: Array(rosterNumDays).fill(""),
    DaysRecurring: null,
    Allocations: fixedShifts,
    AllocationsRecurring: null,
    History: Array(14).fill(""),
    RuleValues: null,
    RosteredAllocations: null,
    name: globalEmployee.name,
    skills: null,
    shifts: null,
  };

  // Convert uploadedStartDate to a Date object
  let start = parseISO(startDate);

  // Calculate the finish date
  let finish = addDays(start, rosterNumDays - 1);

  // 3. Create Rosters
  let employeeStart = parseISO(globalEmployee.startDate);
  let employeeFinish = parseISO(globalEmployee.finishDate);

  // Check if the employee's start and finish dates are within the uploaded date range
  if (
    isWithinInterval(start, {
      start: employeeStart,
      end: employeeFinish,
    }) ||
    isWithinInterval(finish, { start: employeeStart, end: employeeFinish })
  ) {
    return employeeData;
  }
  return null;
};

export const createRosterForScheduleView = (
  locationID,
  rosterNumDays,
  startDate,
  globalEmployees = [],
  annualLeaveKeyword
) => {
  const rosterFinishDate = DateTime.addDaysToDate(
    startDate,
    rosterNumDays - 1
  ).toFormat("AWS");
  const allDatesInRoster = DateTime.getAllDatesBetween(
    startDate,
    rosterFinishDate,
    true,
    true
  ).map((date) => date.toFormat("AWS"));

  const employees = [];
  for (const employee of globalEmployees) {
    const employeeData = globalEmployeeToScheduleEmployee(
      employee,
      allDatesInRoster,
      rosterNumDays,
      startDate,
      rosterFinishDate,
      annualLeaveKeyword
    );

    if (employeeData != null) {
      employees.push(employeeData);
    }
  }

  return {
    locationID: locationID,
    name: null,
    numDays: rosterNumDays,
    startDate: startDate,
    Employees: employees,
    Demands: null,
    Shifts: null,
    ShiftGroups: null,
    CustomRules: null,
    RuleExceptions: null,
    Skills: null,
    Tasks: null,
    TaskBlocks: null,
    Statistics: null,
    ColorCodes: null,
    isPublished: false,
    isSnapshot: false,
    snapshotStartDate: `F${startDate}`,
  };
};

const parseUploadedEmployees = (employees, numDays) => {
  if (!(employees instanceof Array)) {
    return [];
  }

  const parsed = employees.map((employee) => {
    return {
      name: employee.name ? employee.name : "",
      email: employee.email ? employee.email : "",
      skills: employee.skills ? employee.skills : "",
      shifts: employee.shifts ? employee.shifts : "",
      salary: employee.salary ? employee.salary : 0,
      areas: employee.areas ? employee.areas : "",
      FTE: employee.FTE ? employee.FTE : 0,
      startDate: "2000-01-01",
      finishDate: employee.finishDate ? employee.finishDate : "2038-01-01",
      History: employee.History ? employee.History : Array(14).fill(""),
      Days: employee.Days ? employee.Days : Array(numDays).fill(""),
      DaysRecurring: employee.DaysRecurring
        ? employee.DaysRecurring
        : Array(7).fill(""),
      Allocations: employee.Allocations
        ? employee.Allocations
        : Array(numDays).fill(""),
      AllocationsRecurring: employee.AllocationsRecurring
        ? employee.AllocationsRecurring
        : Array(7).fill(""),
      RuleValues: employee.RuleValues,
      RosteredAllocations: employee.RosteredAllocations
        ? employee.RosteredAllocations
        : Array(numDays).fill(""),
      PublishedAllocations: employee.PublishedAllocations
        ? employee.PublishedAllocations
        : [],
      Preferences: employee.Preferences ? employee.Preferences : [],
      PreferencesRecurring: employee.PreferencesRecurring
        ? employee.PreferencesRecurring
        : Array(7).fill(""),
      Requests: employee.Requests ? employee.Requests : [],
      TimeEntries: employee.TimeEntries ? employee.TimeEntries : [],
    };
  });

  return parsed;
};

const parseUploadedDemands = (demands) => {
  if (!(demands instanceof Array)) {
    return [];
  }

  const parsed = demands
    .filter(
      (demand) =>
        demand.startTime &&
        demand.finishTime &&
        demand.type &&
        demand.importance &&
        demand.values
    )
    .map((demand) => {
      return {
        ...demand,
      };
    });
  return parsed;
};

const parseUploadedShifts = (shifts) => {
  if (!(shifts instanceof Array)) {
    return [];
  }

  const parsed = shifts
    .filter((shift) => shift.name && shift.startTime && shift.finishTime)
    .map((shift) => ({
      ...shift,
      shortId: shift.shortId || shift.name,
    }));

  return parsed;
};

const parseUploadedShiftGroups = (groups) => {
  if (!(groups instanceof Array)) {
    return [];
  }

  const parsed = groups
    .filter((group) => group.name)
    .map((group) => ({
      ...group,
      shortId: group.shortId || group.name,
    }));

  return parsed;
};

const parseUploadedRules = (rules) => {
  if (!(rules instanceof Array)) {
    return [];
  }

  const parsed = rules
    .filter((rule) => rule.name && rule.template)
    .map((rule) => ({
      name: rule.name,
      template: rule.template,
    }));

  return parsed;
};

const parseUploadedSkills = (skills) => {
  if (!(skills instanceof Array)) {
    return [];
  }

  const parsed = skills
    .filter((skill) => skill.name)
    .map((skill) => ({
      ...skill,
      shortId: skill.shortId || skill.name,
    }));

  return parsed;
};
const parseUploadedTasks = (tasks) => {
  if (!(tasks instanceof Array)) {
    return [];
  }

  const parsed = tasks
    .filter((task) => task.name)
    .map((task) => ({
      ...task,
      shortId: task.shortId || task.name,
    }));

  return parsed;
};
const parseUploadedTaskBlocks = (taskBlocks) => {
  if (!(taskBlocks instanceof Array)) {
    return [];
  }

  const parsed = taskBlocks.map((item) => ({
    ...item,
    shortId: item.shortId || item.name,
  }));

  return parsed;
};
const parseUploadedAreas = (areas) => {
  if (!(areas instanceof Array)) {
    return [];
  }

  const parsed = areas.map((area) => ({
    ...area,
    shortId: area.shortId,
  }));

  return parsed;
};
const parseUploadedStatistics = (statistics) => {
  const stats = getStatisticsTemplate();

  for (const key in stats) {
    if (stats[key]) {
      stats[key] = statistics[key] ? statistics[key] : [];
    }
  }

  return stats;
};
const parseUploadedColorCodes = (colorCodes) => {
  if (!(colorCodes instanceof Array)) {
    return [];
  }

  const codes = colorCodes
    .filter((code) => code.shift && code.color)
    .map((code) => ({
      ...code,
    }));

  return codes;
};

export const parseUploadedSchedule = (uploaded) => {
  const uploadedNumDays = uploaded.numDays;
  const uploadedStartDate = uploaded.startDate;
  if (!uploadedNumDays || !isInteger(uploadedNumDays) || !uploadedStartDate) {
    throw new InvalidUploadedFileError("Selected file contains invalid data");
  }

  const parsed = {
    locationName: uploaded.locationName || "New Location",
    Employees: uploaded.Employees
      ? parseUploadedEmployees(uploaded.Employees, uploadedNumDays)
      : [],
    Demands: uploaded.Demands ? parseUploadedDemands(uploaded.Demands) : [],
    Shifts: uploaded.Shifts ? parseUploadedShifts(uploaded.Shifts) : [],
    ShiftGroups: uploaded.ShiftGroups
      ? parseUploadedShiftGroups(uploaded.ShiftGroups)
      : [],
    CustomRules: uploaded.CustomRules
      ? parseUploadedRules(uploaded.CustomRules)
      : [],
    Skills: uploaded.Skills ? parseUploadedSkills(uploaded.Skills) : [],
    Tasks: uploaded.Tasks ? parseUploadedTasks(uploaded.Tasks) : [],
    TaskBlocks: uploaded.TaskBlocks
      ? parseUploadedTaskBlocks(uploaded.TaskBlocks)
      : [],
    Areas: uploaded.Areas ? parseUploadedAreas(uploaded.Areas) : [],
    Statistics: uploaded.Statistics
      ? parseUploadedStatistics(uploaded.Statistics)
      : getDefaultDisplayedStatistics(),
    ColorCodes: uploaded.ColorCodes
      ? parseUploadedColorCodes(uploaded.ColorCodes)
      : [],
    numDays: uploadedNumDays,
    startDate: uploadedStartDate,
    locationType: uploaded.locationType ? uploaded.locationType : null,
    frontendSettings: uploaded.frontendSettings || null,
    settings: uploaded.settings || null,
  };

  return parsed;
};

export const scheduleRosterToGeneratable = (roster) => {
  const employees = roster.Employees.map((employee) => {
    return {
      ...employee,
      Days: normalizeSpecialPreferences(employee.Days),
      DaysRecurring: normalizeSpecialPreferences(employee.DaysRecurring),
    };
  });
  return {
    ...roster,
    Employees: employees,
  };
};

export function getInferredPeriodStartAndFinishDate(
  locationStartDate,
  baseDate,
  numDays
) {
  const periodStartDate = inferRosterStartDate(
    locationStartDate,
    baseDate,
    numDays
  );

  const periodFinishDate = DateTime.addDaysToDate(
    periodStartDate,
    getActualNumDays(periodStartDate, numDays) - 1
  ).toFormat("AWS");

  return {
    periodStartDate,
    periodFinishDate,
  };
}
