import styles from "./Step.module.css";
import { useWizard } from "react-use-wizard";
import StepTemplate from "./StepTemplate";
import RuleBlock from "../../../../features/rosterProblems/components/RuleGenerator/RuleBlock/RuleBlock";
import {
  checkIsValidRuleValue,
  getStarterRules,
} from "../../../../features/rules/service/rules";
import StepTitle from "./StepTitle";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { getShiftHours } from "../../../../utils";
import { useState } from "react";

const rulesData = getStarterRules();

const Step3 = ({ data, updateData }) => {
  const { nextStep, previousStep } = useWizard();
  const [isInvalidRuleValue, setIsInvalidRuleValue] = useState(false);

  const rule = rulesData.filter(
    (fullRule) => fullRule.name === data.fteRuleCategory
  )[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fteRuleCategory" && data.fteRuleCategory !== value) {
      if (value === "hours") data.fteRuleValue = 80;
      else data.fteRuleValue = 10;
    }

    updateData({ [name]: value });
  };

  const handleRuleValueChange = (val) => {
    if (!val || !checkIsValidRuleValue(val, rule.valueType)) {
      setIsInvalidRuleValue(true);
    } else {
      setIsInvalidRuleValue(false);
    }

    updateData({
      ...data,
      fteRuleValue: val,
    });
  };

  const shiftHours = getShiftHours({
    startTime: data.shiftStart,
    finishTime: data.shiftEnd,
  });

  const hourNotMultiple =
    data.fteRuleCategory === "hours" && data.fteRuleValue % shiftHours !== 0;

  return (
    <StepTemplate
      nextName="Next"
      previousStep={previousStep}
      nextStep={nextStep}
      shouldBlock={hourNotMultiple || isInvalidRuleValue}
    >
      <div className={`${styles.container} ${styles.singleContentContainer}`}>
        <StepTitle
          fontAwesomeIcon={faListCheck}
          text={"Step 2/4 - Generating your first roster"}
        />
        <h1 className={styles.title}>Create your first roster rule</h1>
        <div className={`${styles.paragraphs} ${styles.stepThreeParagraphs}`}>
          <p className={`${styles.paragraph} ${styles.blueText}`}>
            Rules are how you tell us how you would like shifts to be given to
            each staff member.
          </p>
          <p>
            Let&apos;s create your first rule, to specify how often each staff
            member should work. Don&apos;t worry, you can always change this
            rule later, including customising the rule for each individual staff
            member.
          </p>
        </div>
        <div className={styles.constraints}>
          <div className={styles.fteLabel}>
            <label htmlFor="fteRuleCategory">Constrain on:</label>
            <select
              id="fteRuleCategory"
              name="fteRuleCategory"
              value={data.fteRule}
              onChange={handleChange}
            >
              <option value="hours">Hours</option>
              <option value="days on">Days</option>
            </select>
          </div>

          <div className={styles.ruleBlock}>
            <RuleBlock
              rule={rule}
              name={rule.name}
              canChangeDefaultValue={true}
              defaultValue={data.fteRuleValue}
              inputs={rule.subrules[data.fteSubruleIndex].inputs}
              initialDefaultValue={data.fteRuleValue}
              setDefaultValue={handleRuleValueChange}
              changeRuleValue={(event) => {
                const subruleIndex = rule.subrules.findIndex(
                  (subrule) =>
                    subrule.inputs.length === event.length &&
                    subrule.inputs.every(
                      (input, index) => input === event[index]
                    )
                );
                updateData({
                  ...data,
                  fteSubruleIndex: subruleIndex,
                });
              }}
              menuPosition="absolute"
            />
          </div>
          {isInvalidRuleValue && (
            <p className={styles.redText}>
              {data.fteRuleValue ? `${data.fteRuleValue} is invalid.` : ""}{" "}
              Please enter an integer number
            </p>
          )}
        </div>
        <div className={`${styles.paragraphs} ${styles.stepThreeParagraphs}`}>
          <p className={`${styles.paragraph} ${styles.italic}`}>
            Tip: All the rules are optimized for at the same time when we
            generate your roster. That way you can have a preferred rule and a
            hard rule for the same thing.
          </p>
        </div>
        {hourNotMultiple && (
          <p style={{ color: "red" }}>
            Ensure your hours rule is a multiple of your shift length (
            {shiftHours}) so a roster can be generated
          </p>
        )}
      </div>
    </StepTemplate>
  );
};

export default Step3;
