import styles from "./ConfirmBox.module.css";

const ConfirmBox = ({
  heading,
  description,
  okLabel,
  cancelLabel = null,
  onOk,
  onCancel = null,
  customStyle = {},
}) => (
  <div className={styles.container} style={{ ...customStyle }}>
    <p className={styles.heading}>{heading}</p>
    <p className={styles.description}>{description}</p>
    <div className={styles.buttons}>
      <button className={styles.ok} onClick={onOk}>
        {okLabel}
      </button>
      {cancelLabel && (
        <button className={styles.cancel} onClick={onCancel}>
          {cancelLabel}
        </button>
      )}
    </div>
  </div>
);

export default ConfirmBox;
