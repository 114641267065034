/* eslint-disable no-unused-vars */
import { DateTime } from "../dataTypesUtils/DateTime";
import { v4 as uuidv4 } from "uuid";
import { getDefaultDisplayedStatistics } from "../../constants";
import { getNewEntities } from "./sharedModelDataGetters";
import { resizeArray } from "../generalUtils/array";
import { ScheduleEmployeeSchema } from "./scheduleDataGetters";
import {
  getCombinedShiftSubtasks,
  getCombinedSubtasks,
  getSubtasks,
} from "../../features/rosterProblems/service/rosterUtils";
import { parseISO, addDays, format } from "date-fns";
import { getPublicHolidays } from "../publicHolidayUtils/publicHolidays";
import { generatePatternOutput } from "./monthViewUtils";
import { KEYWORD_ALL } from "../../constants/keywords";
import { DEMAND_VALUE_BAU } from "../../constants/demands";

export type EmployeeSchema = {
  id: string;
  globalEmployeeID: string;
  Days: string[];
  DaysRecurring: string[];
  Allocations: string[];
  AllocationsRecurring: string[];
  History: string[];
  RuleValues: string[];
  RosteredAllocations: string[];
  name: string;
  skills: string;
  shifts: string;
  externalID: string;
  areas?: string;
};

export function convertScheduleEmployeeToRosterEmployeeFilteredByFields(
  scheduleEmployee,
  rosterEmployeeFields
) {
  const rosterEmployee =
    convertScheduleEmployeeToRosterEmployee(scheduleEmployee);

  const resultingEmployee = {};

  for (const [key, value] of Object.entries(rosterEmployee)) {
    if (rosterEmployeeFields.includes(key)) {
      resultingEmployee[key] = value;
    } else {
      resultingEmployee[key] = null;
    }
  }
  return {
    ...resultingEmployee,
    id: scheduleEmployee.id,
    name: scheduleEmployee.name,
    globalEmployeeID: scheduleEmployee.globalEmployeeID,
    externalID: scheduleEmployee.externalID,
  };
}

export function convertScheduleEmployeeToRosterEmployee(
  scheduleEmployee: ScheduleEmployeeSchema
): EmployeeSchema {
  return {
    id: scheduleEmployee.id,
    globalEmployeeID: scheduleEmployee.globalEmployeeID,
    Days: scheduleEmployee.Days,
    DaysRecurring: scheduleEmployee.DaysRecurring,
    Allocations: scheduleEmployee.Allocations,
    AllocationsRecurring: scheduleEmployee.AllocationsRecurring,
    History: scheduleEmployee.History,
    RuleValues: scheduleEmployee.RuleValues,
    RosteredAllocations: scheduleEmployee.RosteredAllocations,
    name: scheduleEmployee.name,
    skills: scheduleEmployee.skills,
    shifts: scheduleEmployee.shifts,
    externalID: scheduleEmployee.externalID,
    areas: scheduleEmployee.areas || "",
  };
}

export function getNewEmployeesTemplate(options): EmployeeSchema[] {
  const { name, numItems, nextNumberedSuffix, roster } = options;
  const { CustomRules, numDays } = roster;

  const defaultEmployeeInfo = {
    Days: Array(numDays).fill(""),
    DaysRecurring: Array(14).fill(""),
    Allocations: Array(numDays).fill(""),
    AllocationsRecurring: Array(14).fill(""),
    History: Array(14).fill(""),
    RuleValues: CustomRules ? Array(CustomRules.length).fill("") : [],
    RosteredAllocations: Array(numDays).fill(""),
    skills: "",
    shifts: KEYWORD_ALL,
    externalID: "",
    areas: KEYWORD_ALL,
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultEmployeeInfo,
    null,
    null,
    false
  );
}

export function getNewSkillsTemplate(options) {
  const { nextNumberedSuffix, numItems, name, existingSkills } = options;
  const defaultSkillInfo = {
    description: "New Skills Description",
    type: null,
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultSkillInfo,
    existingSkills,
    "Skill"
  );
}

export function getNewTaskBlocksTemplate(options) {
  const startTimeString = "9:00";
  const finishTimeString = "17:30";

  const { nextNumberedSuffix, numItems, name, existingTaskBlocks } = options;
  const defaultTasksInfo = {
    tasks: KEYWORD_ALL,
    startTime: DateTime.getFormattedTime(startTimeString, "AWS"),
    finishTime: DateTime.getFormattedTime(finishTimeString, "AWS"),
    depth: 2,
    cantCombine: "",
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultTasksInfo,
    existingTaskBlocks,
    "TaskBlock"
  );
}

export function getNewTasksTemplate(options) {
  const { nextNumberedSuffix, numItems, name, existingTasks } = options;
  const defaultTasksInfo = {
    description: "New Task Description",
    includeGeneralStaffing: true,
    skills: "",
    autoAssigned: true,
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultTasksInfo,
    existingTasks,
    "Task"
  );
}

export function getNewAreasTemplate(options) {
  const { nextNumberedSuffix, numItems, name, existingAreas } = options;
  const defaultAreasInfo = {
    shifts: "",
    skills: "",
    tasks: "",
    autoAssigned: true,
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultAreasInfo,
    existingAreas,
    "Area"
  );
}

export function getNewShiftsTemplate(options) {
  const {
    name,
    numItems,
    nextNumberedSuffix,
    startTime,
    finishTime,
    adminUseOnly,
    existingShifts,
  } = options;
  const defaultShiftInfo = {
    startTime: startTime
      ? DateTime.getFormattedTime(startTime, "AWS")
      : DateTime.getFormattedTime("9:00", "AWS"),
    finishTime: finishTime
      ? DateTime.getFormattedTime(finishTime, "AWS")
      : DateTime.getFormattedTime("17:30", "AWS"),
    fulfilsDemand: true,
    autoAssigned: true,
    skill: "",
    adminUseOnly: adminUseOnly ? adminUseOnly : false,
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultShiftInfo,
    existingShifts,
    "Shift"
  );
}

export function getNewShiftGroupsTemplate(options) {
  const { name, numItems, nextNumberedSuffix, shifts, existingShiftGroups } =
    options;

  const defaultShiftGroupInfo = {
    shifts: shifts ? shifts : "",
    inversed: false,
    tasks: "",
    skills: "",
    skillsInversed: false,
    adminUseOnly: true,
    areas: "",
  };

  return getNewEntities(
    numItems,
    name,
    nextNumberedSuffix,
    defaultShiftGroupInfo,
    existingShiftGroups,
    "ShiftGroup"
  );
}

export function getFieldsFromRoster(roster) {
  let solutions = [];
  if (roster?.Solutions) {
    solutions = roster.Solutions.items.filter((solution) => !solution._deleted);
  }

  const areas = roster ? roster.Areas : [];
  const tasks = roster ? roster.Tasks : [];
  const shifts = roster ? roster.Shifts : [];
  const taskBlocks = roster ? roster.TaskBlocks : [];
  const subTasks = getSubtasks(tasks, taskBlocks);
  const enumeratedTasks = getCombinedSubtasks(tasks, taskBlocks);
  const enumeratedShiftTasks = getCombinedShiftSubtasks(
    shifts,
    tasks,
    taskBlocks,
    enumeratedTasks,
    subTasks
  );

  return {
    ...roster,
    employees: roster ? roster.Employees : [],
    skills: roster ? roster.Skills : [],
    shifts: roster ? roster.Shifts : [],
    tasks,
    taskBlocks,
    areas,
    shiftGroups: roster ? roster.ShiftGroups : [],
    rules: roster ? roster.CustomRules : [],
    demands: roster ? roster.Demands : [],
    colorCodes: roster ? roster.ColorCodes : [],
    solutions,
    statistics: roster ? roster.Statistics : getDefaultDisplayedStatistics(),
    isPublished: roster ? roster.isPublished : null,
    frontendSettings: roster ? roster.frontendSettings : [],
    settings: roster ? roster.settings : [],
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
    isSnapshot: roster ? roster.isSnapshot : false,
  };
}

export function modifyNumDaysToRosterFields(roster, numDays) {
  const employees = roster.Employees;
  const demands = roster.Demands;

  const updatedEmployees = employees.map((employee) => {
    const allocations = resizeArray(employee.Allocations, numDays);
    const days = resizeArray(employee.Days, numDays);
    const rosteredAllocations = resizeArray(
      employee.RosteredAllocations,
      numDays
    );
    return {
      ...employee,
      Allocations: allocations,
      Days: days,
      RosteredAllocations: rosteredAllocations,
    };
  });

  const updatedDemands = demands.map((demand) => {
    const values = demand.values;
    if (values.length > 14) {
      return {
        ...demand,
        values: resizeArray(values, numDays, 1),
      };
    }
    return demand;
  });

  return {
    ...roster,
    numDays,
    Employees: updatedEmployees,
    Demands: updatedDemands,
  };
}

export function getRosterSolutionInitialTemplate(rosterID) {
  return {
    id: uuidv4(),
    rosterID,
    status: "Solving - obj: " + 100000000,
    percentageComplete: 5,
  };
}

export function getReRosterSolutionInitialTemplate(rosterID) {
  return {
    id: uuidv4(),
    rosterID,
    status: "Rerostered solution",
    percentageComplete: 100,
  };
}

export function getRosteringProblem(
  roster,
  locationSettings,
  locationStartDate, // Optional
  locationDefaultNumDays // Optional
) {
  if (!locationStartDate) locationStartDate = roster.startDate;
  if (!locationDefaultNumDays) locationDefaultNumDays = roster.numDays;

  const publicHolidayTimezoneSetting = locationSettings.find(
    (setting) => setting.split(";")[0] === "publicHolidayTimezone"
  );

  const publicHolidayTimezone = publicHolidayTimezoneSetting?.split(";")[1];

  const startDate = parseISO(roster.startDate);
  const publicHolidays = getPublicHolidays(
    publicHolidayTimezone,
    startDate,
    roster.numDays
  );

  const rosteringProblem = {
    id: roster.id,
    name: roster.name,
    numDays: roster.numDays,
    startDate: roster.startDate,
    employees: roster.Employees.map((employee) => {
      const {
        PublishedAllocations,
        TimeEntries,
        Preferences,
        PreferencesRecurring,
        email,
        FTE,
        createdAt,
        _version,
        _deleted,
        _lastChangedAt,
        registrations,
        RosteredAllocations,
        owner,
        Requests,
        ...rest
      } = employee;
      return rest;
    }),
    demands: roster.Demands.map((demand) => {
      const { id, values, defaultPHValue, ...rest } = demand;

      if (values.length === roster.numDays || publicHolidays.length === 0) {
        return { ...rest, values };
      }

      let updatedValues = generatePatternOutput(
        values,
        startDate,
        roster.numDays,
        locationStartDate,
        locationDefaultNumDays
      );

      updatedValues = updatedValues.map((value, index) => {
        const currentDate = addDays(startDate, index);
        const formattedDate = format(currentDate, "yyyy-MM-dd");

        const isPublicHoliday = publicHolidays.some(
          (holiday) => holiday.date === formattedDate
        );

        if (isPublicHoliday) {
          if (
            defaultPHValue === undefined ||
            defaultPHValue === DEMAND_VALUE_BAU
          ) {
            return value;
          }
          return defaultPHValue;
        }

        return value;
      });

      return {
        ...rest,
        values: updatedValues,
      };
    }),
    history: roster.History,
    areas: roster.Areas,
    shifts: roster.Shifts.map((shift) => {
      const { id, ...rest } = shift;
      return rest;
    }),
    shiftGroups: roster.ShiftGroups.map((shiftGroup) => {
      const { id, ...rest } = shiftGroup;
      return rest;
    }),
    customRules: roster.CustomRules,
    ruleExceptions: roster.RuleExceptions,
    skills: roster.Skills.map((skill) => {
      const { id, description, ...rest } = skill;
      return rest;
    }),
    tasks: roster.Tasks.map((task) => {
      const { id, description, ...rest } = task;
      return rest;
    }),
    taskBlocks: roster.TaskBlocks.map((taskBlock) => {
      const { id, ...rest } = taskBlock;
      return rest;
    }),
    locationStartDate,
    locationDefaultNumDays,
  };
  return rosteringProblem;
}

export function getRosterStartAndFinishDate(roster) {
  const startDate = roster.startDate;
  const numDays = roster.numDays;
  const finishDate = DateTime.addDaysToDate(startDate, numDays - 1).toFormat(
    "AWS"
  );
  return { startDate, finishDate };
}

export function getDuplicateSnapshotRoster(snapshot, newName) {
  const duplicatedSnapshot = {
    id: uuidv4(),
    locationID: snapshot.locationID,
    name: newName,
    numDays: snapshot.numDays,
    startDate: snapshot.startDate,
    Employees: snapshot.Employees,
    Demands: snapshot.Demands,
    Shifts: snapshot.Shifts,
    ShiftGroups: snapshot.ShiftGroups,
    CustomRules: snapshot.CustomRules,
    Skills: snapshot.Skills,
    Tasks: snapshot.Tasks,
    TaskBlocks: snapshot.TaskBlocks,
    Statistics: snapshot.Statistics,
    ColorCodes: snapshot.ColorCodes,
    isPublished: snapshot.isPublished,
    isSnapshot: snapshot.isSnapshot,
    snapshotStartDate: snapshot.snapshotStartDate,
  };
  return duplicatedSnapshot;
}
