import {
  faArrowCircleRight,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import { useUserStore } from "../../../../globalStore/appStore";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { PLAN, userIsAdmin, userIsInternal } from "../../../auth/service/auth";
import GlobalSettings from "../GlobalSettings/GlobalSettings";
import { downloadLocationSetupAsJSON } from "../../service/downloadLocation";
import styles from "./Location.module.css";
import {
  getFieldsFromLocation,
  getUpdatedLocation,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import { useSettingsModelMutation } from "../../../../hooks/modelQueryHooks/useSettingsModelMutation";

const Location = ({
  locations,
  location,
  deleteLocation,
  globalEmployees,
  customKeywordsData,
}) => {
  const { deleteAreaFilterInSettings } = useSettingsModelMutation();
  const { user, plan } = useUserStore();
  const { updateLocationFields } = useLocationMutation(location);
  const redirect = useRedirect();
  const isAdmin = userIsAdmin(user);
  const isInternalUser = userIsInternal(user);

  const {
    locationID,
    isScheduleView,
    name: locationName,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const [inputValue, setInputValue] = useState(locationName);
  const [isEditingName, setIsEditingName] = useState(false);
  const inputRef = useRef(null);

  useMemo(() => {
    setInputValue(location.name);
  }, [location]);

  const fileInputRef = useRef();

  const handleUpload = async () => {
    const location = locations.find((loc) => loc.id === locationID);
    const { CustomRules, Demands, ShiftGroups, Shifts, Skills } = location;

    if (
      CustomRules.length !== 0 ||
      Demands.length !== 0 ||
      ShiftGroups.length !== 0 ||
      Shifts.length !== 0 ||
      Skills.length !== 0 ||
      globalEmployees.length !== 0
    ) {
      const shouldProceed = await customConfirmAlert({
        title: "Please confirm",
        descriptions: [
          "You already have location settings. Would you like to replace them all?",
        ],
      });
      if (!shouldProceed) {
        return;
      }
    }

    activateFileInput();
  };

  const activateFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
  };

  const handleEditClick = () => {
    setIsEditingName(true);
    inputRef.current.focus();
  };

  const handleSaveClick = () => {
    const nameAlreadyExists = locations.find(
      (loc) => loc.name === inputValue.trim()
    );
    if (
      inputValue.trim() === "" ||
      inputValue === locationName ||
      nameAlreadyExists
    ) {
      setIsEditingName(false);
      setInputValue(locationName);
      return;
    }

    setIsEditingName(false);
    const updatedLocation = getUpdatedLocation(location, {
      name: inputValue.trim(),
    });
    updateLocationFields(["name"], updatedLocation);
  };

  const handleCancelClick = () => {
    setIsEditingName(false);
    setInputValue(locationName);
  };

  const handleDeleteLocation = async () => {
    if (!locations || !locationID || locations.length <= 1) {
      return;
    }
    const response = await customConfirmAlert({
      title: "Please confirm",
      descriptions: ["Are you sure you want to delete this location?"],
    });
    if (!response) {
      return;
    }

    await Promise.all([
      deleteLocation(locationID),
      deleteAreaFilterInSettings(locationID),
    ]);

    redirect({
      pageName: PAGE_NAMES.main,
    });
  };

  const handleGoToRosters = () => {
    if (isScheduleView) {
      redirect({
        pageName: PAGE_NAMES.scheduleViewLocation,
        query: { "location-id": locationID },
      });
      return;
    }
    redirect({ pageName: PAGE_NAMES.dashboard, locationID });
  };

  const shouldShowDeleteLocationBtn =
    locations.length > 1 &&
    (isAdmin || isInternalUser) &&
    plan !== PLAN.COORDINATOR &&
    plan !== PLAN.COLLABORATOR;

  return (
    <>
      {locationName && (
        <div className={styles["ward-wrapper"]}>
          <div className={styles["upper-container"]}>
            <h1 className={styles["page-title"]}>Location Setup - </h1>
            <input
              ref={inputRef}
              className={`${styles["title-input"]} ${
                isEditingName && styles["in-edit"]
              }`}
              style={{
                width: `${inputValue.length + 2}ch`,
              }}
              value={inputValue}
              readOnly={isEditingName ? false : true}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {isEditingName ? (
              <div>
                <BasicButton
                  color="#219ec9"
                  hoverColor="#1f91b7"
                  onClick={handleSaveClick}
                  customStyle={{
                    fontSize: "20px",
                    borderRadius: "10px",
                    width: "130px",
                    textAlign: "center",
                  }}
                >
                  Save
                </BasicButton>
                <button
                  className={styles["cancel-btn"]}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <BasicButton
                color="#219ec9"
                hoverColor="#1f91b7"
                customStyle={{
                  fontSize: "20px",
                  borderRadius: "10px",
                  width: "130px",
                  textAlign: "center",
                }}
                onClick={handleEditClick}
              >
                Edit name
              </BasicButton>
            )}
            {shouldShowDeleteLocationBtn && (
              <span
                className={styles.deleteLocation}
                onClick={handleDeleteLocation}
              >
                Delete location
              </span>
            )}
          </div>
          <div className={styles.content}>
            {!isScheduleView && (
              <nav className={styles.menu}>
                <ul>
                  <div className={styles.fileBtns}>
                    <button className={styles.fileBtn} onClick={handleUpload}>
                      <FontAwesomeIcon
                        icon={faUpload}
                        className={styles.fileIcon}
                      />
                      Upload Location Setup
                    </button>
                    <input
                      ref={fileInputRef}
                      className={styles.fileInput}
                      type="file"
                      accept="application/JSON"
                      name="file"
                      onChange={handleFileInputChange}
                      data-testid="upload-location-input"
                    />
                    <button
                      className={styles.fileBtn}
                      onClick={() =>
                        downloadLocationSetupAsJSON(locationID, locationName)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className={styles.fileIcon}
                      />
                      Download Location Setup
                    </button>
                  </div>
                </ul>
              </nav>
            )}
            <div
              className={`${styles["grid-wrapper"]} ${
                isScheduleView ? styles.scheduleViewGridWrapper : null
              }`}
            >
              <GlobalSettings
                locationID={locationID}
                location={location}
                refreshLocations={() => {}}
                customKeywordsData={customKeywordsData}
              />
              <div className={styles["btn-container"]}>
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  className={styles["arrow-icon"]}
                />
                <button
                  className={styles["go-to-roster-btn"]}
                  onClick={handleGoToRosters}
                >
                  {isScheduleView ? "Go to schedules view" : "Go to rosters"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withHeader(Location);
