import { useMemo, useState } from "react";
import {
  getAllShiftGroupsFromGrid,
  getDataInRows,
  getNames,
  getShortIds,
  parseShiftGroupModelToShiftGroupsGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import ShiftGroupsGrid from "../../../rosterProblems/components/ShiftGroups/ShiftGroupsGrid/ShiftGroupsGrid";
import { getNewShiftGroupsTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import {
  getNoTaskSubtaskLabels,
  getNoTaskSubtaskNames,
} from "../../../rosterProblems/service/preferencesAndFixedShifts";
import { frontendSettingsToSolverSettings } from "../../../rosterGenerator/service/generateRoster";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";

const ScheduleShiftGroupsDataContainer = ({
  location,
  locationID,
  isRoster,
  rosterID,
  customKeywordsData,
}) => {
  const { plan } = useUserStore();
  const [columnApi, setColumnApi] = useState(null);
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });
  const { areas, skills, tasks, shifts, shiftGroups, subTasks, taskBlocks } =
    fields;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const { warnings } = useWarningsStore();

  const applyShiftGroupUpdates = (updatedShiftGroups) => {
    const updatedRoster = { ...roster, ShiftGroups: updatedShiftGroups };
    updateFields(["ShiftGroups"], updatedRoster, roster);
  };

  const shiftGroupsWarnings = useMemo(() => {
    return warnings ? warnings.ShiftGroups : null;
  }, [warnings]);

  const { reservedShiftKeywords } =
    interpretCustomKeywordsData(customKeywordsData);

  const skillNames = useMemo(() => getNames(skills), [skills]);
  const subTaskShortIds = useMemo(() => getShortIds(subTasks), [subTasks]);
  const noTaskShortIds = useMemo(
    () => getNoTaskSubtaskLabels(taskBlocks),
    [taskBlocks]
  );
  const noTaskNames = useMemo(
    () => getNoTaskSubtaskNames(taskBlocks),
    [taskBlocks]
  );
  const taskNames = [...getNames(tasks), ...noTaskNames, ...subTaskShortIds];
  const taskShortIds = useMemo(() => getShortIds(tasks), [tasks]);

  const subtaskOptionValues = [...noTaskShortIds, ...subTaskShortIds];

  const shiftNames = shifts.map((shift) => shift.name);
  const shiftGroupsData = useMemo(
    () => parseShiftGroupModelToShiftGroupsGridFormat(shiftGroups),
    [shiftGroups]
  );

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "ShiftGroup",
      roster,
      shortIdsToEntityNamesDicts
    );

  const addNewShiftGroups = (numItems) =>
    addNewModels(
      "New Shift Group",
      true,
      (option) =>
        getNewShiftGroupsTemplate({
          ...option,
          existingShiftGroups: shiftGroups,
        }),
      numItems,
      shiftGroups,
      applyShiftGroupUpdates
    );
  const updateShiftGroups = (newGridContent) => {
    updateModels(newGridContent, shiftGroups, applyShiftGroupUpdates, true);
  };

  const duplicateShiftGroups = (selectedShiftGroupsInfo) =>
    duplicateModels(
      selectedShiftGroupsInfo,
      [],
      shiftGroups,
      applyShiftGroupUpdates,
      "copy of ",
      "ShiftGroup",
      true
    );
  const removeShiftGroups = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, shiftGroups, applyShiftGroupUpdates, true);
  const reorderShiftGroupsData = (params) =>
    reorderModelsData(
      params,
      getAllShiftGroupsFromGrid,
      shiftGroups,
      applyShiftGroupUpdates
    );

  if (plan === PLAN.COORDINATOR) {
    return <></>;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewShiftGroups}
      updateItemsToDB={updateShiftGroups}
      duplicateItemsToDB={duplicateShiftGroups}
      removeItemsFromDB={removeShiftGroups}
      getDataFromGrid={getAllShiftGroupsFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
      getDeletionWarnings={getDeletionWarnings}
    >
      <ShiftGroupsGrid
        isSaving={isSaving}
        shiftNames={shiftNames}
        skillNames={skillNames}
        taskNames={taskNames}
        areas={areas}
        skills={skills}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        taskBlocks={taskBlocks}
        shiftGroupsData={shiftGroupsData}
        roster={roster}
        gridApi={gridApi}
        setGridApiToParent={setGridApi}
        shiftGroupsWarnings={shiftGroupsWarnings}
        reorderShiftGroupsData={reorderShiftGroupsData}
        reservedShiftKeywords={reservedShiftKeywords}
        setColumnApiToParent={setColumnApi}
        columnApi={columnApi}
        isScheduleView={!isRoster}
        customKeywordsData={customKeywordsData}
        subtaskOptionValues={subtaskOptionValues}
        subTasks={subTasks}
        locationSettings={[
          ...location.settings,
          ...frontendSettingsToSolverSettings(location.frontendSettings),
        ]}
        taskShortIds={taskShortIds}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      />
    </GridActionHandler>
  );
};

export default ScheduleShiftGroupsDataContainer;
