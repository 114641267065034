import {
  CALENDAR_VIEW_TYPE,
  EMPLOYEE_APP_CONTENT_VIEW_TYPE,
} from "../../../hooks/useEmployeeAppCurrentView";
import MobileCalendar from "../MobileCalendar/MobileCalendar";
import MobileWeekView from "../MobileWeekView/MobileWeekView";
import styles from "./MobileSchedulePage.module.css";
import MobileBottomTab from "../MobileBottomTab/MobileBottomTab";
import { getApprovedLeaveOnDay } from "../../../service/employeeAppUtil";

const MobileSchedulePage = ({
  currentViewData,
  setSelectedDate,
  allocations,
  myOpenShifts,
  userEmployee,
  location,
  preferences,
  preferencesRecurring,
  preferenceNumbers,
  preferenceRecurringNumbers,
  defaultPreferenceLevel,
  availableShifts,
  availableShiftGroups,
  isBottomTabOpened,
  openBottomTab,
  closeBottomTab,
  toggleBottomTab,
  requests,
  leaveCodes,
  employeeLeaveCounts,
  numberOfEmployees,
  updatePreference,
  updateOpenShifts,
  setContentViewAndSelectedDate,
  notesToDisplay,
}) => {
  const { selectedDate, calendarViewType, contentViewType } = currentViewData;

  const approvedLeaveOnDay = getApprovedLeaveOnDay(
    selectedDate,
    requests,
    leaveCodes
  );

  if (
    calendarViewType === CALENDAR_VIEW_TYPE.week &&
    contentViewType === EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule
  ) {
    return (
      <div className={styles.container}>
        <MobileWeekView
          currentViewData={currentViewData}
          setSelectedDate={setSelectedDate}
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          userEmployee={userEmployee}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          requests={requests}
          locationID={location.id}
          openBottomTab={openBottomTab}
          setContentViewAndSelectedDate={setContentViewAndSelectedDate}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.calendarContainer} ${
          isBottomTabOpened && styles.bottomTabOpenedCalendarContainer
        }`}
      >
        <MobileCalendar
          currentViewData={currentViewData}
          setSelectedDate={setSelectedDate}
          isBottomTabOpened={isBottomTabOpened}
          openBottomTab={openBottomTab}
          toggleBottomTab={toggleBottomTab}
          allocations={allocations}
          myOpenShifts={myOpenShifts}
          userEmployee={userEmployee}
          location={location}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          preferenceNumbers={preferenceNumbers}
          preferenceRecurringNumbers={preferenceRecurringNumbers}
          defaultPreferenceLevel={defaultPreferenceLevel}
          availableShifts={availableShifts}
          availableShiftGroups={availableShiftGroups}
          requests={requests}
          leaveCodes={leaveCodes}
          employeeLeaveCounts={employeeLeaveCounts}
          numberOfEmployees={numberOfEmployees}
          notesToDisplay={notesToDisplay}
        />
      </div>
      <div
        className={`${styles.bottomTab} ${
          isBottomTabOpened && styles.bottomTabOpened
        }`}
      >
        <MobileBottomTab
          selectedDate={selectedDate}
          closeBottomTab={closeBottomTab}
          contentViewType={contentViewType}
          preferences={preferences}
          preferencesRecurring={preferencesRecurring}
          setSelectedDate={setSelectedDate}
          availableShifts={availableShifts}
          availableShiftGroups={availableShiftGroups}
          defaultPreferenceLevel={defaultPreferenceLevel}
          updatePreference={updatePreference}
          allocations={allocations}
          locationStartDate={location.startDate}
          openLeaveTab={() => {}}
          leaveCountInfo={[]}
          myOpenShifts={myOpenShifts}
          employeeID={userEmployee.id}
          updateOpenShifts={updateOpenShifts}
          approvedLeaveOnDay={approvedLeaveOnDay}
          email={userEmployee.email}
          numberOfEmployees={numberOfEmployees}
          preferenceNumbers={preferenceNumbers}
          preferenceRecurringNumbers={preferenceRecurringNumbers}
          notesToDisplay={notesToDisplay}
        />
      </div>
    </div>
  );
};

export default MobileSchedulePage;
