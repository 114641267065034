import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createLocationModel,
  deleteLocationModel,
  getLocationModel,
  getLocationModelsByOwner,
} from "../../utils/queryUtils/appQuery";
import { useCallback, useMemo } from "react";
import { useDataMutation } from "./useDataMutation";
import {
  createGlobalEmployeeModels,
  createRosterModel,
  deleteGlobalEmployeeModel,
  deleteRosterModel,
} from "../../utils/queryUtils/locationQuery";
import { deleteSolutionModel } from "../../utils/queryUtils/rosterQuery";
import { useUserSettingsUpdate } from "../userHooks/useManageUserSettings";
import { useUserStore } from "../../globalStore/appStore";
import {
  PLAN,
  getStripeCustomerId,
  getUserAttributes,
  getUserGroups,
  isUserInCollaboratorGroup,
  isUserInCoordinatorGroup,
} from "../../features/auth/service/auth";
import {
  getCollaboratorLocationRegex,
  getCoordinatorLocationRegex,
} from "../../utils/generalUtils/regex";
import LogRocket from "logrocket";
import { FIELD_UPDATE_ACTIONS } from "../../constants/queryActions";

async function getGroupLocations(groups, regex) {
  const managingLocationIDs = groups
    .filter((group) => {
      return regex.test(group);
    })
    .map((group) => group.split("_")[0]);

  const locationPromises = [];
  for (const locationID of managingLocationIDs) {
    locationPromises.push(getLocationModel(locationID));
  }
  const managingLocations = await Promise.all(locationPromises);
  return managingLocations.filter((location) => !location._deleted);
}

// Query all locations of a user
async function queryAllLocations(user) {
  const groups = getUserGroups(user);

  const allLocations = [];

  // if a coordinator, get coordinating groups
  LogRocket.log("User data", user);
  if (isUserInCoordinatorGroup(user)) {
    LogRocket.log("User is in coordinator group");
    allLocations.push(
      ...(await getGroupLocations(groups, getCoordinatorLocationRegex()))
    );
  }
  if (isUserInCollaboratorGroup(user)) {
    allLocations.push(
      ...(await getGroupLocations(groups, getCollaboratorLocationRegex()))
    );
  }

  const { sub, username } = getUserAttributes(user);
  allLocations.push(...(await getLocationModelsByOwner(sub, username)));
  return allLocations;
}

// Create a new location (+ create necessary global employees and rosters)
async function createNewLocation(
  newLocationInfo,
  newGlobalEmployeesInfo = [],
  newRostersInfo = []
) {
  const promises = [];
  const locationPromise = createLocationModel(newLocationInfo);
  promises.push(locationPromise);

  if (newGlobalEmployeesInfo.length > 0) {
    const globalEmployeesPromise = createGlobalEmployeeModels(
      newGlobalEmployeesInfo
    );
    promises.push(globalEmployeesPromise);
  } else {
    promises.push(Promise.resolve([]));
  }

  if (newRostersInfo.length > 0) {
    const rosterPromises = newRostersInfo.map((rosterInfo) =>
      createRosterModel(rosterInfo)
    );
    promises.push(...rosterPromises);
  } else {
    promises.push(Promise.resolve([]));
  }

  const [newLocation, newGlobalEmployees, ...newRosters] = await Promise.all(
    promises
  );

  const createdLocation = {
    ...newLocation,
    Employees: {
      items: newGlobalEmployees,
    },
    Rosters: {
      items: newRosters,
    },
  };

  return createdLocation;
}

// Deleate existing location (+ delete related global employees, rosters, and solutions)
async function deleteExistingLocation(locationID) {
  const deletedLocation = await deleteLocationModel(locationID);
  const promises = [];

  const globalEmployeesToDelete = deletedLocation.Employees.items;
  for (const globalEmployee of globalEmployeesToDelete) {
    promises.push(deleteGlobalEmployeeModel(globalEmployee.id));
  }

  const rostersToDelete = deletedLocation.Rosters.items;
  for (const roster of rostersToDelete) {
    const solutionsToDelete = roster.Solutions.items;
    for (const solution of solutionsToDelete) {
      promises.push(deleteSolutionModel(solution.id));
    }
    promises.push(deleteRosterModel(roster.id));
  }

  if (promises.length > 0) {
    await Promise.all(promises);
  }
}

export function useAllLocationsQuery(user) {
  const { plan } = useUserStore();
  const { updateNumSeatsBy } = useUserSettingsUpdate();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["allLocations"],
    queryFn: () => queryAllLocations(user),
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
  });

  const mutation = useDataMutation({
    queryKey: ["allLocations"],
    queryClient,
    mutationFn: async (mutateFnParam) => {
      const { actionType } = mutateFnParam;
      switch (actionType) {
        case FIELD_UPDATE_ACTIONS.create: {
          const {
            newLocationInfo,
            newGlobalEmployeesInfo,
            newRostersInfo,
            oldLocations,
          } = mutateFnParam;

          const newLocation = await createNewLocation(
            newLocationInfo,
            newGlobalEmployeesInfo,
            newRostersInfo
          );

          if (
            newLocation.isScheduleView &&
            plan !== PLAN.AI &&
            getStripeCustomerId(user)
          ) {
            updateNumSeatsBy(newGlobalEmployeesInfo.length, true);
          }

          return [newLocation, ...oldLocations];
        }
        case FIELD_UPDATE_ACTIONS.delete: {
          const { deletedLocationID, oldLocations } = mutateFnParam;
          await deleteExistingLocation(deletedLocationID);
          return oldLocations.filter(({ id }) => id !== deletedLocationID);
        }
      }
    },
    getExpectedResultingData: (mutateFnParam) => {
      const { actionType, oldLocations } = mutateFnParam;
      switch (actionType) {
        case FIELD_UPDATE_ACTIONS.create: {
          const expectedResultingLocations = [
            ...oldLocations,
            mutateFnParam.newLocationInfo,
          ];
          return expectedResultingLocations;
        }
        case FIELD_UPDATE_ACTIONS.delete: {
          const expectedResultingLocations = oldLocations.filter(
            ({ id }) => id !== mutateFnParam.deletedLocationID
          );
          return expectedResultingLocations;
        }
      }
    },
    onSuccess: (updatedData, mutateFnParam) => {
      const { onSuccess } = mutateFnParam;
      onSuccess && mutateFnParam.onSuccess(updatedData);
    },
  });

  const locations = useMemo(() => {
    if (!query.data) {
      return null;
    }
    const locationData = query.data;
    return locationData;
  }, [query]);

  const createLocation = useCallback(
    async (
      newLocationInfo,
      newGlobalEmployeesInfo = null,
      newRostersInfo = null
    ) => {
      return mutation.mutateAsync({
        oldLocations: locations,
        newLocationInfo,
        newGlobalEmployeesInfo: newGlobalEmployeesInfo || [],
        newRostersInfo: newRostersInfo || [],
        actionType: FIELD_UPDATE_ACTIONS.create,
      });
    },
    [locations, mutation]
  );

  const deleteLocation = useCallback(
    (locationID) => {
      return new Promise((resolve) => {
        mutation.mutate({
          oldLocations: locations,
          deletedLocationID: locationID,
          actionType: FIELD_UPDATE_ACTIONS.delete,
          onSuccess: () => {
            resolve();
          },
        });
      });
    },
    [locations, mutation]
  );

  return {
    locations,
    isQueryLoading: query.isLoading,
    isMutationLoading: mutation.isLoading,
    createLocation,
    deleteLocation,
  };
}
