// PublicHolidaySetting.js
import styles from "./PublicHolidaySetting.module.css";

const PublicHolidaySetting = ({
  updateFrontendSettings,
  publicHolidayTimezone,
}) => {
  const options = [
    { value: "None", label: "No Public Holidays" },
    {
      label: "Australia",
      options: [
        { value: "AU-ACT", label: "Australian Capital Territory" },
        { value: "AU-NSW", label: "New South Wales" },
        { value: "AU-NT", label: "Northern Territory" },
        { value: "AU-QLD", label: "Queensland" },
        { value: "AU-SA", label: "South Australia" },
        { value: "AU-TAS", label: "Tasmania" },
        { value: "AU-VIC", label: "Victoria" },
        { value: "AU-WA", label: "Western Australia" },
      ],
    },
    {
      label: "New Zealand",
      options: [
        { value: "NZ-AUK", label: "Auckland Province" },
        { value: "NZ-BOP", label: "Bay of Plenty" },
        { value: "NZ-CAN", label: "Canterbury" },
        { value: "NZ-CAN-Timaru", label: "Timaru District" },
        { value: "NZ-CIT", label: "Chatham Islands" },
        { value: "NZ-GIS", label: "Gisborne" },
        { value: "NZ-HKB", label: "Hawke's Bay" },
        { value: "NZ-MBH", label: "Marlborough" },
        { value: "NZ-MWT", label: "Manawatu-Wanganui" },
        { value: "NZ-NSN", label: "Nelson" },
        { value: "NZ-NTL", label: "Northland" },
        { value: "NZ-OTA", label: "Otago Province" },
        { value: "NZ-STL", label: "Southland" },
        { value: "NZ-TAS", label: "Tasman" },
        { value: "NZ-TKI", label: "Taranaki" },
        { value: "NZ-WKO", label: "Waikato" },
        { value: "NZ-WGN", label: "Wellington Province" },
        { value: "NZ-WTC", label: "Westland" },
        { value: "NZ-WTC-Buller", label: "Buller District" },
      ],
    },
    {
      label: "Hong Kong",
      options: [{ value: "HK", label: "Hong Kong" }],
    },
    {
      label: "Singapore",
      options: [{ value: "SG", label: "Singapore" }],
    },
  ];

  const handleChange = (event) => {
    const value = event.target.value;
    updateFrontendSettings("publicHolidayTimezone", [value]);
  };

  return (
    <div className={styles.container}>
      <label htmlFor="publicHolidayTimezone" className={styles.settingOption}>
        Public Holidays:
      </label>
      <select
        id="publicHolidayTimezone"
        value={publicHolidayTimezone || "None"}
        onChange={handleChange}
        className={styles.optionDropdown}
      >
        {options.map((group) => {
          if (group.options) {
            return (
              <optgroup key={group.label} label={group.label}>
                {group.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            return (
              <option key={group.value} value={group.value}>
                {group.label}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
};

export default PublicHolidaySetting;
