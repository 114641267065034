import { useMemo, useState } from "react";
import {
  getAllEmployeesFromGrid,
  parseEmployeeModelToEmployeesGridFormat,
  getSelectedRows,
  getToBeDeletedRows,
  getNames,
  getNextDefaultEntityNameNumber,
  getDataInRows,
} from "../../../../../utils";
import GridActionHandler from "../../../../grid/components/GridActionHandler/GridActionHandler";
import EmployeesGrid from "../EmployeesGrid/EmployeesGrid";
import {
  getNewEmployeesTemplate,
  getNewSkillsTemplate,
} from "../../../../../utils/queryUtils/rosterDataGetters";
import {
  getDuplicatedEntities,
  getReorderedEntities,
} from "../../../../../utils/queryUtils/sharedModelDataGetters";
import EmployeesViewSwitcher, {
  SWITCHABLE_PAGES,
} from "../../EmployeesViewSwitcher/EmployeesViewSwitcher";
import useStandardDataContainer from "../../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../../loading/components/LoadingPage/LoadingPage";
import { useQueryClient } from "@tanstack/react-query";
import { useWarningsStore } from "../../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../../globalStore/appStore";
import { PLAN } from "../../../../auth/service/auth";
import NotAccessibleView from "../../../../../components/elements/NotAccessibleView/NotAccessibleView";
import { useAreaFilter } from "../../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../../components/elements/AreaFilter/AreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../service/rosterUtils";

const EmployeesDataContainer = ({
  location,
  rosterID,
  redirectToIndividualPage,
  isScheduleView,
  isSnapshot,
}) => {
  const { plan } = useUserStore();
  const queryClient = useQueryClient();

  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
  } = useStandardDataContainer({
    isScheduleView: isScheduleView || false,
    locationID: location.id,
    rosterID: rosterID,
  });

  const { warnings } = useWarningsStore();
  const [columnApi, setColumnApi] = useState(null);
  const {
    name,
    employees,
    skills,
    shifts,
    shiftGroups,
    areas,
    numDays,
    tasks,
    subTasks,
  } = fields;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], location.id);

  const employeeNames = useMemo(() => getNames(employees), [employees]);
  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const skillNames = useMemo(() => getNames(skills), [skills]);

  const employeesData = useMemo(() => {
    return parseEmployeeModelToEmployeesGridFormat(employees);
  }, [employees]);

  const employeesWarnings = useMemo(() => {
    return warnings ? warnings.Employees : null;
  }, [warnings]);

  const applyEmployeesUpdate = (updatedEmployees) => {
    const updatedRoster = { ...roster, Employees: updatedEmployees };
    updateFields(["Employees"], updatedRoster, roster);
  };

  const addNewEmployee = async (numItems) => {
    const name = "New Employee";
    const nextDefaultShiftNameNumber = getNextDefaultEntityNameNumber(
      name,
      employeeNames
    );

    const newEmployees = getNewEmployeesTemplate({
      name,
      numItems,
      nextNumberedSuffix: nextDefaultShiftNameNumber,
      roster,
    });
    const updatedEmployees = [...employees, ...newEmployees];
    applyEmployeesUpdate(updatedEmployees);
  };

  const getToBeDeletedItems = (hasTriggeredFromContextMenu) => {
    if (hasTriggeredFromContextMenu) {
      return getToBeDeletedRows(null, gridApi).map((item) => item.id);
    }
    return getSelectedRows(gridApi).map((item) => item.data.id);
  };

  const removeEmployees = async (toBeDeletedItems) => {
    const updatedEmployees = employees.filter(
      (employee) => !toBeDeletedItems.includes(employee.id)
    );
    applyEmployeesUpdate(updatedEmployees);
  };

  const updateEmployees = async (newEmployees) => {
    const updatedEmployees = employees.map((employee) => {
      const updatedEmployee = newEmployees.find(
        (emp) => emp.id === employee.id
      );
      if (!updatedEmployee) {
        return employee;
      }
      return {
        ...employee,
        name: updatedEmployee.name,
        externalID: updatedEmployee.externalID,
        shifts: updatedEmployee.shifts,
        skills: updatedEmployee.skills,
        areas: updatedEmployee.areas,
      };
    });
    applyEmployeesUpdate(updatedEmployees);
  };

  const duplicateEmployees = async (selectedEmployeesInfo) => {
    const duplicatedEmployeeIDs = selectedEmployeesInfo.map((item) => item.id);
    const duplicateEmployees = getDuplicatedEntities(
      employees,
      duplicatedEmployeeIDs
    );
    duplicateEmployees.forEach((emp) => {
      emp.name = `New Employee (based on ${emp.name})`;
      emp.Allocations = Array(numDays).fill("");
      emp.AllocationsRecurring = Array(emp.AllocationsRecurring.length).fill(
        ""
      );
      emp.Days = Array(numDays).fill("");
      emp.DaysRecurring = Array(emp.DaysRecurring.length).fill("");
      emp.externalID = "";
      emp.History = Array(14).fill("");
      emp.RosteredAllocations = Array(numDays).fill("");
    });
    const updatedEmployees = [...employees, ...duplicateEmployees];
    applyEmployeesUpdate(updatedEmployees);
  };

  const reorderEmployeesData = async (params) => {
    const reorderedData = getAllEmployeesFromGrid(params.api);
    const orderedIdArr = reorderedData.map((item) => item.id);
    const updatedEmployees = getReorderedEntities(employees, orderedIdArr);
    applyEmployeesUpdate(updatedEmployees);
  };

  const createSkillFromEmployeesTable = async (skillName) => {
    const latestSkills = queryClient.getQueryData(["roster", rosterID]).Skills;
    const updatedSkills = [
      ...latestSkills,
      ...getNewSkillsTemplate({
        nextNumberedSuffix: null,
        numItems: 1,
        name: skillName,
        existingSkills: skills,
      }),
    ];
    const updatedRoster = { ...roster, Skills: updatedSkills };
    updateFields(["Skills"], updatedRoster, roster);
  };

  const viewSwitcherComponent = (
    <EmployeesViewSwitcher
      redirect={redirectToIndividualPage}
      currentPage={SWITCHABLE_PAGES.overview}
    />
  );

  const TopControllerComponent = (
    <div>
      <AreaFilter
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        onMenuClose={saveAreaFilter}
        defaultValue={initialAreaFilterValue}
      />
    </div>
  );

  if (
    plan === PLAN.COORDINATOR ||
    (plan === PLAN.COLLABORATOR && !isSnapshot)
  ) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewEmployee}
      updateItemsToDB={updateEmployees}
      duplicateItemsToDB={duplicateEmployees}
      removeItemsFromDB={removeEmployees}
      getDataFromGrid={getAllEmployeesFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
    >
      <EmployeesGrid
        gridApi={gridApi}
        employeesData={employeesData}
        numEmps={employees.length}
        skillNames={skillNames}
        shiftNames={shiftNames}
        shiftGroupNames={shiftGroupNames}
        isSaving={isSaving}
        setGridApiToParent={setGridApi}
        employeesWarnings={employeesWarnings}
        reorderEmployeesData={reorderEmployeesData}
        createSkillFromEmployeesTable={createSkillFromEmployeesTable}
        viewSwitcherComponent={viewSwitcherComponent}
        setEmployeesColumnApiToParent={setColumnApi}
        columnApi={columnApi}
        skills={skills}
        shifts={shifts}
        shiftGroups={shiftGroups}
        showAddBtn={![PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)}
        areas={areas}
        customTopComponent={TopControllerComponent}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        rosterName={name}
        isScheduleView={isScheduleView}
      />
    </GridActionHandler>
  );
};

export default EmployeesDataContainer;
