import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import ModalViewer from "../../../../../components/elements/Modal/ModalViewer";
import MultiSelectEmployees from "../../../../../components/elements/MultiSelectEmployees/MultiSelectEmployees";
import { generateReRoster } from "../../../../rosterGenerator/service/generateRoster";
import styles from "./RerosterModal.module.css";
import { useHistory } from "react-router-dom";
import { useRosterModelQuery } from "../../../../../hooks/modelQueryHooks/useRosterModelQuery";
import {
  containsAllElements,
  DateTime,
  getAreaOptions,
  getIds,
  getNames,
  getOptionValues,
  getShortIds,
  hasCommonItems,
  strToArrCommaSeparated,
} from "../../../../../utils";
import { reactSelectStyles } from "../../../../../styles/reactSelectStyles";
import { ValueContainer } from "../../../../locations/components/PublishCalendarModal/PublishCalendarModal";
import { CheckboxOption } from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

interface RerosterModalProps {
  hide: () => void;
  isShowing: boolean;
  onClickOkay: () => void;
  initialStartDate: string;
  initialFinishDate: string;
  roster: any;
  nextLink: string;
  warnings: any;
  location: any;
  areas: any;
  employees: any;
  initialAreaFilterValue: string[];
}

export const ScheduleViewRerosterModal = (props) => {
  const { roster } = useRosterModelQuery({
    isScheduleView: true,
    locationID: props.locationID,
    rosterID: props.rosterID,
  });

  const isSnapshot = roster.isSnapshot;
  const nextLink = isSnapshot
    ? `/locations/schedule-view/solutions?location-id=${props.locationID}&snapshot-id=${roster.id}`
    : `/locations/schedule-view/solutions?location-id=${props.locationID}&date=${props.initialStartDate}`;

  return <RerosterModal nextLink={nextLink} roster={roster} {...props} />;
};

export const PrototypeRerosterModal = (props) => {
  const { roster } = useRosterModelQuery({
    isScheduleView: false,
    locationID: props.locationID,
    rosterID: props.rosterID,
  });

  const nextLink = "/roster/solutions?roster-id=" + props.rosterID;

  return <RerosterModal roster={roster} nextLink={nextLink} {...props} />;
};

function getSelectableEmployeesInfo(
  allowedAreaShortIds: string[],
  employees: any[],
  areas: any[]
) {
  const areaShortIds = getShortIds(areas);

  if (
    containsAllElements(areaShortIds, allowedAreaShortIds) ||
    allowedAreaShortIds.length === 0 ||
    allowedAreaShortIds.includes(KEYWORD_ALL)
  ) {
    return { selectableEmployees: employees, unSelectableEmployees: [] };
  }

  const selectableEmployees = [];
  const unSelectableEmployees = [];
  for (const employee of employees) {
    const employeeAreaShortIds = strToArrCommaSeparated(employee.areas);

    if (
      employeeAreaShortIds.includes(KEYWORD_ALL) ||
      hasCommonItems(allowedAreaShortIds, employeeAreaShortIds)
    ) {
      selectableEmployees.push(employee);
    } else {
      unSelectableEmployees.push(employee);
    }
  }

  return { selectableEmployees, unSelectableEmployees };
}

const RerosterModal: React.FC<RerosterModalProps> = ({
  hide,
  isShowing,
  initialStartDate,
  initialFinishDate,
  roster,
  warnings,
  nextLink,
  location,
  areas,
  employees,
  initialAreaFilterValue = null,
}) => {
  const history = useHistory();
  const employeeNames = useMemo(() => getNames(employees), [employees]);
  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const [selectedAreaOptions, setSelectedAreaOptions] = useState(() => {
    if (
      !initialAreaFilterValue ||
      initialAreaFilterValue.length === 0 ||
      initialAreaFilterValue.includes(KEYWORD_ALL)
    ) {
      return [selectAllAreasOption];
    }

    return areaOptions.filter(({ value }) =>
      initialAreaFilterValue.includes(value)
    );
  });

  const [unSelectableEmployees, setUnSelectableEmployees] = useState(() => {
    const { unSelectableEmployees } = getSelectableEmployeesInfo(
      initialAreaFilterValue,
      employees,
      areas
    );
    return unSelectableEmployees;
  });

  const initialDate = getStartDate(initialStartDate, initialFinishDate);

  const [startDate, setStartDate] = useState(initialDate);
  const [finishDate, setFinishDate] = useState(initialFinishDate);
  const [selectedEmployees, setSelectedEmployees] = useState(() => {
    const filteredEmployees = employees.filter(
      ({ id }) => !getIds(unSelectableEmployees).includes(id)
    );
    return filteredEmployees;
  });
  const [isTasksOnly, setIsTasksOnly] = useState(false);
  const isAllSelected = selectedEmployees.length === employeeNames.length;

  const updateSelectableEmployees = useCallback(
    (selectedAreaShortIds) => {
      const { unSelectableEmployees } = getSelectableEmployeesInfo(
        selectedAreaShortIds,
        employees,
        areas
      );
      setUnSelectableEmployees(unSelectableEmployees);
      const filteredEmployees = selectedEmployees.filter(
        ({ id }) => !getIds(unSelectableEmployees).includes(id)
      );
      setSelectedEmployees(filteredEmployees);
    },
    [employees, selectedEmployees, areas]
  );

  const handleEmployeeSelect = (selectedEmployeeNames: string) => {
    const selectedEmployees = employees.filter(({ name }) =>
      selectedEmployeeNames.includes(name)
    );
    setSelectedEmployees(selectedEmployees);
  };

  const handleAreaSelect = (selectedInputs, actionMeta) => {
    const { action, option } = actionMeta;

    let selectedOptions = [...selectedInputs];

    switch (action) {
      case "select-option":
        if (option.value === selectAllAreasOption.value) {
          selectedOptions = [selectAllAreasOption];
          break;
        }
        break;
      case "deselect-option":
        if (option.value === selectAllAreasOption.value) {
          selectedOptions = [];
          break;
        }
        if (isSelectAllSelected()) {
          selectedOptions = areaOptions.filter(
            ({ value }) => option.value !== value
          );
          break;
        }
        break;
    }

    setSelectedAreaOptions(selectedOptions);

    let selectedAreaShortIds = getOptionValues(selectedOptions);
    if (
      selectedAreaShortIds.length === 0 ||
      selectedAreaShortIds.includes(KEYWORD_ALL)
    ) {
      selectedAreaShortIds = getShortIds(areas);
    }

    updateSelectableEmployees(selectedAreaShortIds);
  };

  const reroster = async () => {
    const startDateObj = new DateTime(startDate);
    const finishDateObj = new DateTime(finishDate);
    if (
      startDateObj.isAfter(finishDate) ||
      startDateObj.isBefore(initialStartDate) ||
      finishDateObj.isAfter(initialFinishDate)
    ) {
      alert("Invalid date range");
      return;
    }

    const selectedEmployeeNames = getNames(selectedEmployees);
    const isSucceeded = await generateReRoster(
      roster,
      location,
      startDate,
      finishDate,
      selectedEmployeeNames,
      warnings,
      isTasksOnly
    );
    if (isSucceeded) {
      history.push(nextLink);
    }
  };

  const isSelectAllSelected = () => {
    return selectedAreaOptions.some(
      (option) => option.value === selectAllAreasOption.value
    );
  };

  return (
    <ModalViewer
      isShowing={isShowing}
      title="Re-generate"
      firstBtnLabel="Re-generate"
      onclickOkay={reroster}
      hide={hide}
      modalWidth="500px"
      showCloseIcon={false}
    >
      <div className={styles.content}>
        {areas.length > 0 && (
          <div className={styles.row}>
            <p className={styles.label}>Select area(s) to re-roster</p>
            <Select
              options={[selectAllAreasOption, ...areaOptions]}
              styles={reactSelectStyles}
              isMulti
              closeMenuOnSelect={false}
              onChange={handleAreaSelect}
              hideSelectedOptions={false}
              components={{
                Option: CheckboxOption,
                ValueContainer,
              }}
              value={selectedAreaOptions}
              maxMenuHeight={150}
              isSearchable={false}
              isClearable={false}
              isOptionSelected={(option) => {
                if (isSelectAllSelected()) {
                  return true;
                }
                return selectedAreaOptions.some(
                  ({ value }) => value === option.value
                );
              }}
            />
          </div>
        )}
        <div className={styles.row}>
          <p className={styles.label}>Select the period to re-roster</p>
          <div className={styles.dateWrapper}>
            <input
              type="date"
              className={styles.dateInput}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min={initialStartDate}
              max={initialFinishDate}
            />
            <input
              type="date"
              className={styles.dateInput}
              value={finishDate}
              onChange={(e) => setFinishDate(e.target.value)}
              min={initialStartDate}
              max={initialFinishDate}
            />
          </div>
        </div>
        <div className={styles.row}>
          <p className={styles.label}>Select the employees to re-roster:</p>
          <MultiSelectEmployees
            employeeNames={employeeNames}
            selected={getNames(selectedEmployees)}
            setSelected={handleEmployeeSelect}
            isAllSelected={isAllSelected}
            customStyle={{ width: "100%", height: "35px" }}
            disabledInputs={[...getNames(unSelectableEmployees)]}
          />
        </div>

        <div className={styles.row}>
          <span className={styles.label}>Only reroster tasks: </span>
          <input
            type="checkbox"
            title="Tasks only"
            checked={isTasksOnly}
            onChange={() => setIsTasksOnly(!isTasksOnly)}
          />
        </div>
      </div>
    </ModalViewer>
  );
};

function getStartDate(initialStartDate, initialFinishDate) {
  const today = new DateTime(null); // Today's date
  const startDate = new DateTime(initialStartDate);
  const finishDate = new DateTime(initialFinishDate);

  if (
    today.isDateBetweenTwoDates(
      startDate.toFormat("AWS"),
      finishDate.toFormat("AWS"),
      true,
      true
    )
  ) {
    return today.toFormat("AWS");
  } else {
    return startDate.toFormat("AWS");
  }
}
