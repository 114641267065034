import { useRef, useState } from "react";
import styles from "./LeaveSetting.module.css";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import { trimAndLowerCase } from "../../../../utils";
import { KEYWORD_STUDY } from "../../../../constants/keywords";

const LeaveSetting = ({
  customKeywordsData,
  leaveSettingValues,
  updateFrontendSettings,
}) => {
  const { leaveKeywords, annualLeaveKeyword, reservedKeywords } =
    interpretCustomKeywordsData(customKeywordsData);
  const [isALEditing, setIsALEditing] = useState(false);
  const [customALValue, setCustomALValue] = useState(annualLeaveKeyword);
  const [isStudyLeaveChecked, setIsStudyLeaveChecked] = useState(
    leaveKeywords.includes(KEYWORD_STUDY)
  );

  const ALInputRef = useRef(null);

  const addLeaveKeyword = (leaveLabel, customName) => {
    const leaveValues = [...leaveSettingValues];
    const leaveValueIndex = leaveValues.findIndex(
      (item) => item.split(";")[1] === leaveLabel
    );
    if (leaveValueIndex === -1) {
      leaveValues.push(`${customName};${leaveLabel}`);
    } else {
      leaveValues[leaveValueIndex] = `${customName};${leaveLabel}`;
    }

    updateFrontendSettings("annualLeaveKeyword", leaveValues);
  };

  const removeLeaveKeyword = (leaveLabel) => {
    const leaveValues = leaveSettingValues.filter(
      (item) => item.split(";")[1] !== leaveLabel
    );
    updateFrontendSettings("annualLeaveKeyword", leaveValues);
  };

  const startEditingCustomALKeyword = () => {
    setIsALEditing(true);
    ALInputRef.current.focus();
    ALInputRef.current.select();
  };

  const updateCustomALKeyword = () => {
    setIsALEditing(false);
    const providedValueToLowerCase = trimAndLowerCase(customALValue);
    const reservedKeywordsToLowerCase = reservedKeywords.map((word) =>
      word.toLowerCase()
    );

    if (!providedValueToLowerCase) {
      setCustomALValue(annualLeaveKeyword);
      return;
    }
    if (reservedKeywordsToLowerCase.includes(providedValueToLowerCase)) {
      alert(`${reservedKeywords.join(", ")} are reserved names`);
      setCustomALValue(annualLeaveKeyword);
      return;
    }
    if (
      providedValueToLowerCase.includes("-") ||
      providedValueToLowerCase.includes(";")
    ) {
      alert("Invalid characters: '-', ';'");
      setCustomALValue(annualLeaveKeyword);
      return;
    }
    addLeaveKeyword("Annual leave", customALValue);
  };

  const updateStudyLeaveKeyword = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      addLeaveKeyword("Study Leave", KEYWORD_STUDY);
    } else {
      removeLeaveKeyword("Study Leave");
    }
    setIsStudyLeaveChecked(isChecked);
  };

  return (
    <div className={styles.container}>
      <p className={styles.typeHeader}>Leave Shift Names</p>
      <div>
        <input
          id="studyLeaveCheckbox"
          type="checkbox"
          onChange={updateStudyLeaveKeyword}
          checked={isStudyLeaveChecked}
        />
        <label
          className={styles.studyLeaveCheckbox}
          htmlFor={"studyLeaveCheckbox"}
        >
          Use study leave
        </label>
      </div>
      <div>
        <label className={styles.settingOption} htmlFor="AL-name-setting">
          Annual leave keyword
        </label>
        <input
          id="AL-name-setting"
          ref={ALInputRef}
          type="text"
          readOnly={!isALEditing}
          value={customALValue}
          onChange={(e) => setCustomALValue(e.target.value)}
          className={`${styles.optionInput} ${
            isALEditing ? null : styles.inActive
          }`}
        />
        {isALEditing ? (
          <button className={styles.button} onClick={updateCustomALKeyword}>
            OK
          </button>
        ) : (
          <button
            className={styles.button}
            onClick={startEditingCustomALKeyword}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default LeaveSetting;
