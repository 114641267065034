/**
 * This is the base hook for all mutation hooks
 */
import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";

export function useDataMutation({
  queryKey,
  queryClient,
  mutationFn,
  getExpectedResultingData,
  onSuccess = () => {},
  onSettled = () => {},
}) {
  const previousDataRef = useRef();

  const mutation = useMutation({
    mutationFn: async (mutateFnParam) => {
      return await mutationFn(mutateFnParam, previousDataRef.current);
    },
    onMutate: async (mutateFnParam) => {
      await queryClient.cancelQueries({ queryKey: queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      previousDataRef.current = previousData;
      queryClient.setQueryData(queryKey, (oldData) => {
        const expectedData = getExpectedResultingData(mutateFnParam, oldData);
        return expectedData;
      });
      return { previousData };
    },
    onSuccess: (updatedData, mutateFnParam) => {
      onSuccess(updatedData, mutateFnParam);
    },
    onError: (err) => {
      console.error(err);
      queryClient.setQueryData(queryKey, previousDataRef.current);
    },
    onSettled: (arg1NotUsed, arg2NotUsed, mutateFnParam) => {
      //This is unnecessary with an optimistic mutation. No need to query again
      // queryClient.invalidateQueries({ queryKey: queryKey });
      onSettled(mutateFnParam);
    },
  });

  return mutation;
}
