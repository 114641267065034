import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./GlobalEmployeesGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import {
  DatePickerEditor,
  DatePickerRenderer,
} from "../../../../../components/elements/DatePicker/DatePicker";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import DropdownCreatableMultiSelector from "../../../../grid/components/DropdownCreatableMultiSelector/DropdownCreatableMultiSelector";
import {
  DateTime,
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultFilterValueGetter,
  defaultValueSetter,
  dropdownOptionsValueSetter,
  getAreaOptions,
  getShiftAndShiftGroupOptions,
  insertEmptyStrOnPressBackspace,
  onFilterTextBoxChanged,
  strToArrCommaSeparated,
  suppressDeleteAndBackspaceKey,
  suppressEnterKey,
} from "../../../../../utils";
import EmployeesAccessModal from "../../EmployeesAccessModal/EmployeesAccessModal";
import { getEmailRegex } from "../../../../../utils/generalUtils/regex";
import ToggleColumnModal, {
  COLUMN_VISIBILITY_STATUS,
} from "../../../../grid/components/ToggleColumnModal/ToggleColumnModal";
import {
  retrieveGlobalEmployeesColumnOrder,
  retrieveGlobalEmployeesColumnVisibility,
} from "../../../../../localStorage/visibilityStorage";
import EmployeesViewSwitcher, {
  SWITCHABLE_PAGES,
} from "../../../../rosterProblems/components/EmployeesViewSwitcher/EmployeesViewSwitcher";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { USER_STATUS } from "../../../service/employeeAuthInfo";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../../../globalStore/appStore";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { isEmailAlreadyTaken } from "../../../../../utils/queryUtils/globalEmployeeDataGetters";
import { PLAN } from "../../../../auth/service/auth";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

const GlobalEmployeesGrid = ({
  locationID,
  employeesData,
  numEmps,
  setGridApiToParent,
  addData: addEmployees,
  updateData,
  addSkill,
  duplicateData: duplicateEmployees,
  exportToCsv,
  exportToExcel,
  employeesWarnings,
  removeData: removeEmployees,
  gridApi,
  reorderEmployeesData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  handleInvitations,
  handleDeactivations,
  handleResendInvitations,
  setColumnApiToParent,
  columnApi,
  customTopComponent = null,
  isSaving,
  getDataFromGrid,
  removeRegistrations,
  globalEmployees,
  redirectToIndividualPage,
  shifts,
  shiftGroups,
  skills,
  areas,
  doesAreaFilterPass,
  isExternalFilterPresent,
  shortIdsToEntityNamesDicts,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const { manuallyStepThroughTour } = useOnBoardingStore();
  const { plan } = useUserStore();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEmployeesAccessModalOpen, setIsEmployeesAccessModalOpen] =
    useState(false);
  const [isColumnToggleModalOpen, setIsColumnToggleModalOpen] = useState(false);

  const [columnOrder, setColumnOrder] = useState(() => {
    const fullColumns = [
      "name",
      "skills",
      "shifts",
      "areas",
      "externalID",
      "email",
      "status",
      "startDate",
      "finishDate",
      "FTE",
      "salary",
    ];
    const order = retrieveGlobalEmployeesColumnOrder(locationID);
    if (order) {
      if (order.length !== fullColumns.length) {
        return fullColumns;
      }
      return order;
    }
    return fullColumns;
  });

  useEffect(() => {
    if (columnApi) {
      const columnsVisibility =
        retrieveGlobalEmployeesColumnVisibility(locationID);

      if (columnsVisibility) {
        columnsVisibility.forEach((col) => {
          if (col.status === COLUMN_VISIBILITY_STATUS.hidden) {
            columnApi.setColumnVisible(col.colId, false);
          }
        });
        return;
      } else {
        // "FTE" and "Salary" is default hidden
        columnApi.setColumnVisible("FTE", false);
        columnApi.setColumnVisible("salary", false);
      }

      if (plan === PLAN.LITE || plan === PLAN.MID) {
        const hiddenColumnsForFreeTierUsers = ["FTE", "salary"];
        hiddenColumnsForFreeTierUsers.forEach((colId) => {
          columnApi.setColumnVisible(colId, false);
        });
      }
    }
  }, [columnApi, locationID, plan]);

  const toggleEmployeesAccessModal = () => {
    setIsEmployeesAccessModalOpen((prev) => !prev);
  };
  const toggleIsColumnToggleModalOpen = () => {
    setIsColumnToggleModalOpen((prev) => !prev);
  };

  const getContextMenuItems = (params) => {
    return createContextMenuWithDelete(() => {
      removeEmployees(params);
    });
  };

  const componentRef = useRef();

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const { shiftOptions, shiftGroupOptions, selectAllShiftsOption } = useMemo(
    () => getShiftAndShiftGroupOptions(shifts, shiftGroups),
    [shifts, shiftGroups]
  );

  const shiftAndShiftGroupOptions = useMemo(
    () => [
      {
        label: null,
        options: [selectAllShiftsOption],
      },
      {
        label: "",
        options: shiftOptions,
      },
      {
        label: "",
        options: shiftGroupOptions,
      },
    ],
    [shiftOptions, shiftGroupOptions, selectAllShiftsOption]
  );

  const skillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );

  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const areaOptionsWithSelectAll = useMemo(
    () => [
      {
        label: null,
        options: [selectAllAreasOption],
      },
      {
        label: "",
        options: areaOptions,
      },
    ],
    [selectAllAreasOption, areaOptions]
  );

  const checkInvalidDateCell = (params) => {
    const startDate = new DateTime(params.data.startDate).getDate();
    const finishDate = new DateTime(params.data.finishDate).getDate();
    if (startDate > finishDate) {
      return true;
    }
    return false;
  };

  const checkFieldValueIsDuplicate = useCallback(
    (fieldName, newValue) => {
      const values = employeesData.map((emp) => emp[fieldName]);
      return values.includes(newValue);
    },
    [employeesData]
  );

  const columnDefs = useMemo(() => {
    const columns = [
      {
        field: "name",
        sortable: true,
        suppressSizeToFit: true,
        width: 190,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Employee name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            params.newValue &&
            checkFieldValueIsDuplicate("name", params.newValue)
          ) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }
          return defaultValueSetter(params);
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (!params.value) {
              return true;
            }
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
      },
      {
        field: "skills",
        cellClassRules: {
          "invalid-cell": (params) => {
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
        width: 200,
        suppressSizeToFit: true,
        cellEditor: "dropdownCreatableMultiSelector",
        cellEditorParams: {
          options: skillOptions,
          createNewCandidate: (input) => {
            addSkill(input.trim());
          },
        },
        cellEditorPopup: true,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            skillOptions,
            shortIdsToEntityNamesDicts
          ),
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      },
      {
        headerName: "Shifts or Shift Groups",
        field: "shifts",
        width: 200,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (employeesWarnings) {
              const shiftsWarning = employeesWarnings.find(
                ({ warningType }) => warningType === "invalid shifts"
              );
              if (params.value && shiftsWarning) {
                const shiftNameArr = strToArrCommaSeparated(params.value);
                for (const name of shiftNameArr) {
                  if (shiftsWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: shiftAndShiftGroupOptions,
          allowSelectAll: true,
          selectAllOption: selectAllShiftsOption,
        },
        cellEditorPopup: true,
        valueFormatter: (params) => {
          const value = params.value;
          if (value === KEYWORD_ALL) {
            return KEYWORD_ALL;
          }

          const names = convertShortIdsCellValueToEntityNames(value, [
            ...shifts,
            ...shiftGroups,
          ]);

          return names;
        },
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            [selectAllShiftsOption, ...shiftOptions, ...shiftGroupOptions],
            shortIdsToEntityNamesDicts
          ),
        suppressKeyboardEvent: suppressEnterKey,
        filterValueGetter: (params) =>
          defaultFilterValueGetter(
            params,
            "shifts",
            [...shifts, ...shiftGroups],
            [KEYWORD_ALL]
          ),
      },
      {
        sortable: true,
        field: "areas",
        width: 200,
        valueSetter: (params) => {
          return dropdownOptionsValueSetter(
            params,
            [selectAllAreasOption, ...areaOptions],
            shortIdsToEntityNamesDicts
          );
        },
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, areas),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "areas", areas),
        suppressKeyboardEvent: suppressEnterKey,
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: areaOptionsWithSelectAll,
          allowSelectAll: true,
          selectAllOption: selectAllAreasOption,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
        hide: areas.length === 0,
      },
      {
        headerName: "External ID",
        field: "externalID",
        width: 150,
      },
      {
        field: "email",
        width: 150,
        valueSetter: (params) => {
          if (insertEmptyStrOnPressBackspace(params)) {
            return true;
          }
          if (checkFieldValueIsDuplicate("email", params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate email",
              "Please use another email"
            );
            return false;
          }
          if (isEmailAlreadyTaken(globalEmployees, params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Email is already taken",
              "Please use another email"
            );
            return false;
          }
          const emailRegex = getEmailRegex();
          const inputValue = params.newValue.trim();
          if (emailRegex.test(inputValue)) {
            params.data.email = inputValue;
            return true;
          }
          showWarningPopupIfNoOthersShowing(
            "Invalid email",
            `${inputValue} is not a valid email address`
          );
          return false;
        },
        editable: (params) => {
          const status = params.data.status;
          const uneditableStatus = [
            USER_STATUS.PENDING,
            USER_STATUS.ACTIVE,
            USER_STATUS.PROCESSING,
          ];

          if (uneditableStatus.includes(status)) {
            return false;
          }
          return true;
        },
      },
      {
        field: "status",
        sortable: true,
        editable: false,
        suppressSizeToFit: true,
        width: 140,
      },
      {
        field: "startDate",
        sortable: true,
        editable: true,
        suppressSizeToFit: true,
        width: 120,
        cellClassRules: {
          "invalid-cell": checkInvalidDateCell,
        },
        cellEditor: "datePickerEditor",
        cellEditorPopup: true,
        cellRenderer: "datePickerRenderer",
        suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
        valueSetter: (params) => {
          const { finishDate: finishDateStr } = params.data;
          const { newValue } = params;
          const finishDate = new DateTime(finishDateStr);
          const newStartDate = new DateTime(newValue);

          if (newStartDate.isAfter(finishDateStr)) {
            showWarningPopupIfNoOthersShowing(
              "Invalid date range",
              `Start date (${newStartDate.toFormat(
                "displayed-full"
              )}) cannot be after finish date (${finishDate.toFormat(
                "displayed-full"
              )})`
            );
            return false;
          }

          return defaultValueSetter(params);
        },
      },
      {
        field: "finishDate",
        sortable: true,
        editable: true,
        suppressSizeToFit: true,
        width: 120,
        cellClassRules: {
          "invalid-cell": checkInvalidDateCell,
        },
        cellEditor: "datePickerEditor",
        cellEditorPopup: true,
        cellRenderer: "datePickerRenderer",
        suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
        valueSetter: (params) => {
          const { startDate: startDateStr } = params.data;
          const { newValue } = params;
          const startDate = new DateTime(startDateStr);
          const newFinishDate = new DateTime(newValue);

          if (newFinishDate.isBefore(startDateStr)) {
            showWarningPopupIfNoOthersShowing(
              "Invalid date range",
              `Finish date (${newFinishDate.toFormat(
                "displayed-full"
              )}) cannot be before start date (${startDate.toFormat(
                "displayed-full"
              )})`
            );
            return false;
          }

          return defaultValueSetter(params);
        },
      },
      {
        headerName: "FTE",
        field: "FTE",
        width: 70,
      },
      {
        headerName: "Salary",
        field: "salary",
        width: 80,
      },
    ];

    columns.sort((a, b) => {
      return columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field);
    });
    return columns;
  }, [
    shiftAndShiftGroupOptions,
    skillOptions,
    addSkill,
    employeesWarnings,
    checkFieldValueIsDuplicate,
    columnOrder,
    globalEmployees,
    shiftGroups,
    shifts,
    skills,
    selectAllShiftsOption,
    shiftOptions,
    shiftGroupOptions,
    areaOptions,
    areas,
    areaOptionsWithSelectAll,
    selectAllAreasOption,
    shortIdsToEntityNamesDicts,
  ]);

  const reorderColumns = (newOrder) => {
    setColumnOrder(newOrder);
  };

  const pinnedColumnFields = useMemo(() => ["name", "skills", "shifts"], []);

  return (
    <Layout
      title="Employees"
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/employees-skills-and-tasks"}
        />
      )}
    >
      {isEmployeesAccessModalOpen && (
        <EmployeesAccessModal
          toggleEmployeesAccessModal={toggleEmployeesAccessModal}
          selectedRows={selectedRows}
          handleInvitations={handleInvitations}
          handleDeactivations={handleDeactivations}
          handleResendInvitations={handleResendInvitations}
          removeRegistrations={removeRegistrations}
        />
      )}
      {isColumnToggleModalOpen && (
        <ToggleColumnModal
          locationID={locationID}
          toggleIsColumnToggleModalOpen={toggleIsColumnToggleModalOpen}
          columnApi={columnApi}
          pinnedFields={pinnedColumnFields}
          reorderColumns={reorderColumns}
          lockedColumnIds={
            plan === PLAN.LITE || plan === PLAN.MID ? ["FTE", "salary"] : []
          }
        />
      )}
      <div className={styles.container} ref={componentRef}>
        {
          <div className={styles.topLine}>
            <div className={styles.left}>
              {customTopComponent}
              <div className={styles.switcherWrapper}>
                <EmployeesViewSwitcher
                  redirect={redirectToIndividualPage}
                  currentPage={SWITCHABLE_PAGES.overview}
                />
              </div>
            </div>
            <div className={styles.right}>
              {![PLAN.COORDINATOR].includes(plan) && (
                <AddRowButton
                  rowName={"Employee"}
                  addSingle={() => {
                    if (!isCellEditing) addEmployees(1);
                  }}
                  addOptions={[
                    {
                      label: "Add New",
                      onClick: () => addEmployees(1),
                    },
                    {
                      label: "Add Multiple",
                      onClick: () => {
                        const num = parseInt(
                          prompt(`How many employees should I add?`, "1")
                        );
                        addEmployees(num);
                      },
                    },
                  ]}
                  partialButtonTestId="add-employees-button"
                  postAddAction={() => {
                    manuallyStepThroughTour(400);
                  }}
                />
              )}
            </div>
          </div>
        }
        <ActionBar
          searchBarSettings={{
            tableName: "globalEmployees",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateEmployees,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeEmployees(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
          employeesAccessSettings={{
            selectedRows,
            openModal: toggleEmployeesAccessModal,
          }}
          toggleColumnSettings={{
            openModal: toggleIsColumnToggleModalOpen,
          }}
        />
        <DataEntryTable
          tableName="globalEmployees"
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            handleKeyDownForUndoRedo(params.event);
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderEmployeesData(params);
            },
            onCellValueChanged: (params) => {
              triggerUndoRedoSnapshotCollection(params);
            },
            suppressMoveWhenRowDragging: true,
          }}
          context={{
            skills,
            shifts,
            shiftGroups,
            areas,
          }}
          setGridApiToParent={setGridApiToParent}
          components={{
            dropdownMultiSelector: DropdownMultiSelector,
            dropdownCreatableMultiSelector: DropdownCreatableMultiSelector,
            datePickerEditor: DatePickerEditor,
            datePickerRenderer: DatePickerRenderer,
          }}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          enableRowDragAnimation={!isSaving}
          setColumnApiToParent={setColumnApiToParent}
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        <div className={styles["name-line-container"]}>
          <p className={styles["num-line"]}>
            <span className={styles.emph}>Number of employees:</span> {numEmps}
          </p>
        </div>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
        {employeesWarnings && employeesWarnings.length > 0 && (
          <div className={styles["warning-wrapper"]}>
            <WarningDisplay
              title="Following issues were found:"
              displayedWarnings={getDisplayedWarningsInfo(employeesWarnings)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default GlobalEmployeesGrid;
