import { useRef, useMemo, useState, useCallback, useEffect } from "react";
import styles from "./ShiftGroupsGrid.module.css";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import Layout from "../../../../../components/layouts/Layout/Layout";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import Checkbox from "../../../../../components/elements/AggridCheckbox/AggridCheckbox";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultBooleanValueSetter,
  defaultFilterValueGetter,
  dropdownOptionsValueSetter,
  flatOptions,
  getAreaOptions,
  getFulfilledXTaskBlocksOptions,
  getOptionValues,
  getShortIds,
  getTaskOptions,
  onFilterTextBoxChanged,
  strToArrCommaSeparated,
  suppressEnterKey,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import ShiftGroupExplainerModal from "../../ShiftGroupExplainerModal/ShiftGroupExplainerModal";
import HelpButtonCellRenderer from "../../ShiftGroupExplainerModal/HelpButtonCellRenderer";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { isShiftGroupReferencedByAnyArea } from "../../../service/rosterUtils";
import { isValidShiftName } from "../../../../../utils/validationUtils/sharedValidations";
import { KEYWORD_ALL, KEYWORD_OFF } from "../../../../../constants/keywords";

const ShiftGroupsGrid = ({
  shiftGroupsData,
  isSaving,
  shiftNames,
  taskNames,
  areas,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  roster,
  setGridApiToParent,
  addData: addShiftGroups,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateShiftGroups,
  shiftGroupsWarnings,
  removeData: removeShiftGroups,
  gridApi,
  reorderShiftGroupsData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  reservedShiftKeywords,
  setColumnApiToParent,
  columnApi,
  isScheduleView,
  getDataFromGrid,
  customKeywordsData,
  locationSettings,
  subtaskOptionValues,
  subTasks,
  taskShortIds,
  shortIdsToEntityNamesDicts,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpRowData, setHelpRowData] = useState(null);

  const showModal = useCallback(
    (data) => {
      const index = shiftGroupsData.findIndex(
        (rowData) => rowData.id === data.id
      );
      if (index !== -1) {
        setHelpRowData({ data, index });
        setShowHelpModal(true);
      }
    },
    [shiftGroupsData]
  );

  const closeModal = () => {
    setShowHelpModal(false);
  };

  useEffect(() => {
    if (columnApi && !isScheduleView) {
      columnApi.setColumnVisible("adminUseOnly", false);
    }
  }, [columnApi, isScheduleView]);

  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeShiftGroups(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const checkNameIsDuplicate = useCallback(
    (name) => {
      const names = [];
      if (gridApi) {
        gridApi.forEachNode((node) => {
          names.push(node.data.name);
        });
      }
      return names.includes(name);
    },
    [gridApi]
  );

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent =
    shiftGroupsWarnings && shiftGroupsWarnings.length > 0;

  const selectNoneOption = useMemo(
    () => ({ label: KEYWORD_ALL, value: "" }),
    []
  );

  const allShiftOptions = useMemo(() => {
    return [
      { value: KEYWORD_OFF, label: KEYWORD_OFF },
      ...convertToShortIdNameOptionForm(shifts),
    ];
  }, [shifts]);

  const shiftOptions = useMemo(
    () => [
      {
        label: "",
        options: [...allShiftOptions],
      },
    ],
    [allShiftOptions]
  );

  const { areaOptions } = useMemo(() => getAreaOptions(areas), [areas]);

  const {
    noTasksX,
    noTaskOption,
    allXTaskBlocksInfo,
    allXTaskBlocksOptions,
    allTaskOptions,
  } = useMemo(
    () => getTaskOptions(tasks, subTasks, taskBlocks),
    [tasks, subTasks, taskBlocks]
  );

  const taskDataOptions = useMemo(
    () => [
      {
        label: "",
        options: [...allXTaskBlocksOptions],
      },
      {
        label: "",
        options: allTaskOptions,
      },
      {
        label: null,
        options: [noTaskOption],
      },
    ],
    [allTaskOptions, allXTaskBlocksOptions, noTaskOption]
  );

  const allSkillOptions = useMemo(() => {
    return convertToShortIdNameOptionForm(skills);
  }, [skills]);

  const skillOptions = useMemo(
    () => [
      {
        label: "",
        options: allSkillOptions,
      },
    ],
    [allSkillOptions]
  );

  const columnDefs = useMemo(
    () => [
      {
        suppressMovable: true,
        field: "name",
        suppressSizeToFit: true,
        width: Math.max(190, width / 7),
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Shift group name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (checkNameIsDuplicate(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }

          if (
            reservedShiftKeywords
              .map((word) => word.toLowerCase())
              .includes(params.newValue.toLowerCase())
          ) {
            showWarningPopupIfNoOthersShowing(
              `${reservedShiftKeywords.join(", ")} are reserved names`,
              "Please use another name"
            );
            return false;
          }

          if ([...taskNames, ...shiftNames].includes(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Shift group names must be different to any shift or task names",
              "Please use another name"
            );
            return false;
          }
          if (params.newValue.length >= 40) {
            showWarningPopupIfNoOthersShowing(
              "Shift group names cannot be longer than 40 characters",
              "Please use shorter name"
            );
            return false;
          }
          if (!isValidShiftName(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Please use another name",
              `Shift group names cannot include '-' (hyphen), ',' (comma), '\\' (backslash), or ';' (semicolon)`
            );
            return false;
          }
          params.data.name = params.newValue.trim();
          return true;
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
      },
      {
        suppressMovable: true,
        field: "shifts",
        cellClassRules: {
          "invalid-cell": (params) => {
            if (shiftGroupsWarnings) {
              const shiftGroupsWarning = shiftGroupsWarnings.find(
                ({ warningType }) => warningType === "invalid shifts"
              );
              if (params.value && shiftGroupsWarning) {
                const shiftGroupNamesArr = strToArrCommaSeparated(params.value);
                for (const name of shiftGroupNamesArr) {
                  if (shiftGroupsWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
        width: Math.max(200, width / 5),
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          width: 200,
          options: shiftOptions,
          allOptions: allShiftOptions,
          selectNoneOption,
        },
        cellEditorPopup: true,
        valueSetter: (params) => {
          return dropdownOptionsValueSetter(
            params,
            allShiftOptions,
            shortIdsToEntityNamesDicts
          );
        },
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) => {
          const value = params.value;
          return value
            ? convertShortIdsCellValueToEntityNames(value, shifts)
            : KEYWORD_ALL;
        },
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "shifts", shifts),
      },
      {
        width: Math.max(100, width / 14),
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "inversed",
        cellRenderer: "checkboxRenderer",
        valueSetter: defaultBooleanValueSetter,
      },
      {
        width: Math.max(200, width / 7),
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "tasks",
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: taskDataOptions,
          customIsOptionSelected: (option, selectedOptions) => {
            const isSelected = selectedOptions.some(
              ({ value }) => value === option.value
            );
            if (isSelected) {
              return true;
            }

            const selectedValues = selectedOptions.map(
              (option) => option.value
            );

            // Get selected all x task blocks
            const selectedAllXTaskBlockShortIds = [];
            allXTaskBlocksInfo.forEach((info) => {
              const { value, taskBlockShortId } = info;
              if (selectedValues.includes(value)) {
                selectedAllXTaskBlockShortIds.push(taskBlockShortId);
              }
            });

            for (const taskBlockShortId of selectedAllXTaskBlockShortIds) {
              if (option.value.endsWith(` ${taskBlockShortId}`)) {
                return true;
              }
            }
            return false;
          },
          additionalOnSelectHandler: (option, selectedInputs) => {
            let updatedSelectedInputs = [...selectedInputs];
            const allXTaskBlockValues = allXTaskBlocksInfo.map(
              (info) => info.value
            );

            // if "allXTasks" is checked, unSelect all related subtasks
            const { value } = option;
            if (allXTaskBlockValues.includes(value)) {
              const relatedOptions = allXTaskBlocksInfo
                .find((info) => info.value === value)
                .relatedOptions.map(({ value }) => value);

              updatedSelectedInputs = selectedInputs.filter((option) => {
                return !relatedOptions.includes(option.value);
              });
            }

            // If selected option fulfills xTaskBlocks, unselect the option
            const fulfilledXTaskBlocksOptions = getFulfilledXTaskBlocksOptions(
              updatedSelectedInputs,
              allXTaskBlocksInfo
            );

            if (fulfilledXTaskBlocksOptions.length > 0) {
              const xTaskBlocksRelatedOptions = allXTaskBlocksInfo
                .map((info) => info.relatedOptions.map(({ value }) => value))
                .flat();

              updatedSelectedInputs = updatedSelectedInputs.filter((option) => {
                return !xTaskBlocksRelatedOptions.includes(option.value);
              });

              updatedSelectedInputs = [
                ...updatedSelectedInputs,
                ...fulfilledXTaskBlocksOptions.map(({ label, value }) => ({
                  label,
                  value,
                })),
              ];
            }

            return updatedSelectedInputs;
          },
          additionalOnDeselectHandler: (option, selectedInputs) => {
            const { value } = option;
            const selectedValues = getOptionValues(selectedInputs);

            if (subtaskOptionValues.includes(value)) {
              let blockShortId;
              if (value.startsWith("no task ")) {
                blockShortId = value.split(" ")[value.split(" ").length - 1];
              } else {
                const subtask = subTasks.find((s) => s.shortId === value);
                blockShortId = subtask.blockShortId;
              }

              const matchingAllXTaskInfo = allXTaskBlocksInfo.find(
                (info) => info.taskBlockShortId === blockShortId
              );

              if (matchingAllXTaskInfo) {
                const matchingAllXTaskLabel = matchingAllXTaskInfo.value;
                if (!selectedValues.includes(matchingAllXTaskLabel)) {
                  return null;
                }
              }

              // tick all subtasks with blockShortId
              const allSubtasksWithBlockName = [
                ...getShortIds(
                  subTasks.filter((s) => s.blockShortId === blockShortId)
                ),
                `no task ${blockShortId}`,
              ];

              const tickedSubTasks = allSubtasksWithBlockName.filter(
                (s) => s !== value
              );

              // untick all X tasks
              const allXTaskBlockValue = allXTaskBlocksInfo.find(
                ({ taskBlockShortId }) => taskBlockShortId === blockShortId
              )?.value;

              const updatedSelectedInputValues = [...tickedSubTasks];

              selectedInputs.forEach((input) => {
                const { value } = input;
                if (
                  value !== allXTaskBlockValue &&
                  !updatedSelectedInputValues.includes(value)
                ) {
                  updatedSelectedInputValues.push(value);
                }
              });

              return taskDataOptions
                .map(({ options }) => options)
                .flat()
                .filter(({ value }) =>
                  updatedSelectedInputValues.includes(value)
                );
            }
            return null;
          },
          isOptionDisabled: (rowData, option, selectedOptions) => {
            if (taskBlocks.length === 0) return false;

            const selectedValues = selectedOptions.map(
              (option) => option.value
            );

            const allXTaskBlockValues = allXTaskBlocksInfo.map(
              (info) => info.value
            );

            for (const allXTaskBlockValue of allXTaskBlockValues) {
              if (
                selectedValues.includes(allXTaskBlockValue) &&
                taskShortIds.includes(option.value)
              ) {
                return true;
              }
            }

            const taskOptionValues = getShortIds(tasks);

            let isTaskSelected = false;
            let isSubtaskSelected = false;

            selectedValues.forEach((value) => {
              if (taskOptionValues.includes(value)) {
                isTaskSelected = true;
              }

              if (subtaskOptionValues.includes(value)) {
                isSubtaskSelected = true;
              }
            });

            // the option task or subtask???
            const isTaskOption = taskOptionValues.includes(option.value);

            if (isTaskOption && isSubtaskSelected) {
              return true;
            }

            if (!isTaskOption && isTaskSelected) {
              return true;
            }

            return false;
          },
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (shiftGroupsWarnings) {
              const shiftGroupsWarning = shiftGroupsWarnings.find(
                ({ warningType }) => warningType === "invalid tasks"
              );
              if (params.value && shiftGroupsWarning) {
                const taskNamesArr = strToArrCommaSeparated(params.value);
                for (const name of taskNamesArr) {
                  if (shiftGroupsWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
        valueSetter: (params) => {
          if (params.newValue === KEYWORD_ALL) {
            params.data[params.column.colId] = "";
            return true;
          }
          return dropdownOptionsValueSetter(
            params,
            flatOptions(taskDataOptions),
            shortIdsToEntityNamesDicts
          );
        },
        valueFormatter: (params) => {
          const value = params.value;
          if (value === "") {
            return KEYWORD_ALL;
          }

          const valueArr = strToArrCommaSeparated(value);

          const formatted = valueArr.map((val) => {
            const allXTasksLabel = allXTaskBlocksOptions.find(
              (info) => info.value === val
            );
            if (allXTasksLabel) {
              return allXTasksLabel.label;
            }

            if (val === noTaskOption.label) {
              return noTaskOption.value;
            }

            const task = tasks.find((task) => task.shortId === val);
            if (task) {
              return task.name;
            }

            const subTask = subTasks.find((subTask) => subTask.shortId === val);
            if (subTask) {
              return subTask.name;
            }

            const noTaskX = noTasksX.find(({ shortId }) => shortId === val);
            if (noTaskX) {
              return noTaskX.name;
            }

            return val;
          });

          return formatted.join(", ");
        },
        suppressKeyboardEvent: suppressEnterKey,
      },
      {
        width: Math.max(140, width / 14),
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "skillsInversed",
        headerName: "Tasks inversed",
        cellRenderer: "checkboxRenderer",
        valueSetter: defaultBooleanValueSetter,
      },
      {
        width: Math.max(150, width / 7),
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "skills",
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          width: 200,
          options: skillOptions,
          allOptions: allSkillOptions,
          selectNoneOption,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (shiftGroupsWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                shiftGroupsWarnings
              );
            }
          },
        },
        valueFormatter: (params) => {
          const value = params.value;
          return value
            ? convertShortIdsCellValueToEntityNames(value, skills)
            : KEYWORD_ALL;
        },
        valueSetter: (params) => {
          if (params.newValue === KEYWORD_ALL) {
            params.data[params.column.colId] = "";
            return true;
          }
          return dropdownOptionsValueSetter(
            params,
            allSkillOptions,
            shortIdsToEntityNamesDicts
          );
        },
        suppressKeyboardEvent: suppressEnterKey,
      },
      {
        suppressMovable: true,
        field: "areas",
        cellClassRules: {
          "invalid-cell": (params) => {
            if (shiftGroupsWarnings) {
              const shiftGroupsWarning = shiftGroupsWarnings.find(
                ({ warningType }) => warningType === "invalid areas"
              );
              if (params.value && shiftGroupsWarning) {
                const areaNamesArr = strToArrCommaSeparated(params.value);
                for (const name of areaNamesArr) {
                  if (shiftGroupsWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
          "uneditable-cell": (params) => {
            return isShiftGroupReferencedByAnyArea(params.data.shortId, areas);
          },
        },
        width: Math.max(200, width / 5),
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          width: 200,
          options: areaOptions,
        },
        cellEditorPopup: true,
        valueSetter: (params) => {
          return dropdownOptionsValueSetter(
            params,
            areaOptions,
            shortIdsToEntityNamesDicts
          );
        },
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, areas),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "areas", areas),
        editable: (params) => {
          return !isShiftGroupReferencedByAnyArea(params.data.shortId, areas);
        },
        hide: areas.length === 0,
      },
      {
        width: Math.max(145, width / 14),
        suppressMovable: true,
        suppressSizeToFit: true,
        field: "adminUseOnly",
        cellRenderer: "checkboxRenderer",
        valueSetter: defaultBooleanValueSetter,
      },
      {
        width: 80,
        suppressMovable: true,
        suppressSizeToFit: true,
        editable: false,
        sortable: false,
        suppressMenu: true,
        field: "help",
        cellRenderer: "helpButtonCellRenderer",
        cellRendererParams: {
          showModal,
        },
      },
    ],
    [
      width,
      shiftOptions,
      taskDataOptions,
      skillOptions,
      allSkillOptions,
      checkNameIsDuplicate,
      shiftNames,
      taskNames,
      shiftGroupsWarnings,
      allShiftOptions,
      selectNoneOption,
      reservedShiftKeywords,
      subTasks,
      subtaskOptionValues,
      taskBlocks,
      tasks,
      allXTaskBlocksOptions,
      noTaskOption,
      shifts,
      showModal,
      skills,
      allXTaskBlocksInfo,
      noTasksX,
      taskShortIds,
      areaOptions,
      areas,
      shortIdsToEntityNamesDicts,
    ]
  );

  return (
    <Layout
      title="Shift Groups"
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/shifts-shift-groups"}
        />
      )}
      headerRight={() => (
        <AddRowButton
          rowName={"Shift Group"}
          addSingle={() => {
            if (!isCellEditing) addShiftGroups(1);
          }}
          addOptions={[
            {
              label: "Add New",
              onClick: () => addShiftGroups(1),
            },
            {
              label: "Add Multiple",
              onClick: () => {
                const num = parseInt(
                  prompt(`How many shift groups should I add?`, "1")
                );
                addShiftGroups(num);
              },
            },
          ]}
        />
      )}
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles.topLine}>
          <div className={styles.left}></div>
          <div className={styles.right}></div>
        </div>
        <ActionBar
          searchBarSettings={{
            tableName: "shiftGroups",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateShiftGroups,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeShiftGroups(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
        />
        <DataEntryTable
          columnDefs={columnDefs}
          rowData={shiftGroupsData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          components={{
            checkboxRenderer: Checkbox,
            dropdownMultiSelector: DropdownMultiSelector,
            helpButtonCellRenderer: HelpButtonCellRenderer,
          }}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderShiftGroupsData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          enableRowDragAnimation={!isSaving}
          tableName="shift-groups"
          shouldHaveCheckBoxLeftMargin={true}
          setColumnApiToParent={setColumnApiToParent}
          getDataFromGrid={getDataFromGrid}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
        />
        <p className={styles["name-line"]}>
          <span className={styles.emph}>Number of shifts:</span>
          {` ${shiftGroupsData.length}`}
        </p>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(shiftGroupsWarnings)}
          />
        </div>
      )}
      <ShiftGroupExplainerModal
        shiftGroup={helpRowData?.data}
        shiftGroupIndex={helpRowData?.index}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        skills={skills}
        roster={roster}
        taskBlocks={taskBlocks}
        customKeywordsData={customKeywordsData}
        open={showHelpModal}
        onClose={closeModal}
        locationSettings={locationSettings}
      />
    </Layout>
  );
};

export default ShiftGroupsGrid;
