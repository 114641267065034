export const TABLE_NAMES = Object.freeze({
  ROSTER_DEMANDS: "rosterDemands",
  ROSTER_HISTORY: "rosterHistory",
  ROSTER_FIXED_SHIFTS: "rosterFixedShifts",
  ROSTER_PREFERENCES: "rosterPreferences",
  ROSTER_ROSTER: "rosterRoster",
  LOCATION_PREFERENCES: "locationPreferences",
  LOCATION_EMPLOYEES: "locationEmployees",
  LOCATION_DEMANDS: "locationDemands",
  GLOBAL_FIXED_SHIFTS: "globalFixedShifts", // Global fixed shifts is not necessarily bound to location!
});
