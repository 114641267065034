import styles from "./DesktopHeader.module.css";
import logo from "../../../../../assets/images/logo_white.png";
import LocationSelector from "../../../../../components/elements/LocationSelector/LocationSelector";
import UserDropdown from "../../../../../components/elements/UserDropdown/UserDropdown";
import { logOut } from "../../../../auth/service/auth";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";
import { useQueryClient } from "@tanstack/react-query";
import { getIds } from "../../../../../utils";

const AdminHeaderLeft = ({
  locationID,
  redirectToSchedules,
  redirectToMain,
}) => {
  const queryClient = useQueryClient();

  const handleSchedulesRedirect = () => {
    const adminLocations = queryClient.getQueryData(["allLocations"]);
    const adminLocationIDs = getIds(adminLocations);

    if (adminLocationIDs.includes(locationID)) {
      // Refresh location data
      queryClient.invalidateQueries(["location", locationID]);
      redirectToSchedules(locationID);
      return;
    }
    redirectToMain(); // fallback
  };

  return (
    <div className={styles.adminHeaderRight}>
      <button onClick={handleSchedulesRedirect} className={styles.navItem}>
        Schedules
      </button>
    </div>
  );
};

const DesktopHeader = ({ location, locations, isUserAdmin }) => {
  const redirectTo = useRedirect();

  const redirectToSchedules = (locationID) => {
    redirectTo({
      pageName: PAGE_NAMES.scheduleViewLocation,
      query: {
        "location-id": locationID,
      },
    });
  };

  const redirectToMain = () => {
    redirectTo({ pageName: PAGE_NAMES.main });
  };

  return (
    <header className={styles.container}>
      <div className={styles.left}>
        <LocationSelector
          location={location}
          locations={locations}
          isEmployeeView={true}
        />
        {isUserAdmin && (
          <AdminHeaderLeft
            locationID={location.id}
            redirectToSchedules={redirectToSchedules}
            redirectToMain={redirectToMain}
          />
        )}
      </div>
      <div className={styles.middle}>
        <img className={styles.logo} src={logo} alt="Rosterlab logo" />
      </div>
      <div className={styles.right}>
        <UserDropdown
          location={location}
          signOut={async () => {
            logOut().then(() => {
              redirectTo({ pageName: PAGE_NAMES.main });
            });
          }}
          isMobile={false}
          isEmployeeView={true}
        />
      </div>
    </header>
  );
};

export default DesktopHeader;
