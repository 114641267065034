export function isInteger(value) {
  return typeof value === "number" && Number.isInteger(value);
}

export function isNonNegativeInteger(number) {
  return Number.isInteger(number) && number >= 0;
}

export function extractDayNumberFromDayString(dayString) {
  const match = dayString.match(/Day (\d+)/);

  if (match) {
    const number = parseInt(match[1], 10);
    return number;
  } else {
    return NaN;
  }
}

export function findBiggestNumber(...numbers) {
  if (numbers.length === 0) {
    return undefined; // Handle the case where no arguments are provided
  }

  return Math.max(...numbers);
}

export function removeTrailingZeros(numStr) {
  if (numStr.includes(".")) {
    const [integerPart, decimalPart] = numStr.split(".");
    // If the decimal part is all zeros, return the integer part
    if (/^0+$/.test(decimalPart)) {
      return integerPart;
    }
  }
  return numStr;
}
