import { useState } from "react";

export function useShareRosterModal() {
  const [isShareRosterModalOpen, setIsShareRosterModalOpen] = useState(false);
  const [selectedRosterID, setSelectedRosterID] = useState(null);
  const openShareRosterModal = (rosterID) => {
    setIsShareRosterModalOpen(true);
    setSelectedRosterID(rosterID);
  };

  const closeShareRosterModal = () => {
    setIsShareRosterModalOpen(false);
  };

  return {
    selectedRosterID,
    isShareRosterModalOpen,
    openShareRosterModal,
    closeShareRosterModal,
  };
}
