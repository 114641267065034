// dateUtils.js

import Holidays from "date-holidays";
import { parseISO, addDays, isEqual, isAfter, isBefore } from "date-fns";

/**
 * Gets public holidays for a specified region and date range.
 *
 * @param {string} publicHolidayTimezone - The region code (e.g., 'AU-NSW', 'NZ-AUK').
 * @param {string|Date} startDate - The start date of the range in ISO format or Date object.
 * @param {number} numDays - The number of days from the start date to include in the range.
 * @returns {Array} - An array of holiday objects with 'date' and 'name' properties.
 */
export function getPublicHolidays(publicHolidayTimezone, startDate, numDays) {
  if (!publicHolidayTimezone || publicHolidayTimezone === "None") {
    return [];
  }

  // Split the region code (e.g., 'AU-NSW' => ['AU', 'NSW'])
  const regionParts = publicHolidayTimezone.split("-");

  let hd = null;
  if (regionParts.length === 1) {
    hd = new Holidays(regionParts[0]);
  } else if (regionParts.length === 2) {
    hd = new Holidays(regionParts[0], regionParts[1]);
  } else if (regionParts.length === 3) {
    hd = new Holidays(regionParts[0], regionParts[1], regionParts[2]);
  } else {
    // Invalid region code
    return [];
  }

  if (!hd) {
    // Handle invalid region code
    return [];
  }

  // Convert startDate to a Date object if it's a string
  const start = typeof startDate === "string" ? parseISO(startDate) : startDate;
  const end = addDays(start, numDays - 1);

  // Fetch holidays within the date range
  const holidays = hd.getHolidays(start, end);

  // Filter and aggregate holidays by date
  const holidaysByDate = holidays.reduce((acc, holiday) => {
    const holidayDate = parseISO(holiday.date);

    // Check if holidayDate is between start and end (inclusive)
    if (
      (isEqual(holidayDate, start) || isAfter(holidayDate, start)) &&
      (isEqual(holidayDate, end) || isBefore(holidayDate, end))
    ) {
      const dateStr = holiday.date.slice(0, 10); // 'YYYY-MM-DD'
      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(holiday.name);
    }
    return acc;
  }, {});

  // Convert to an array of holiday objects
  return Object.entries(holidaysByDate).map(([date, names]) => ({
    date,
    name: names.join(", "),
  }));
}
