import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import styles from "./PreferencesSideTab.module.css";
import {
  faInfinity,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState } from "react";
import SideTabCard from "../SideTabCard/SideTabCard";
import blueStartImg from "../../../../../assets/images/darkBlueStar.svg";
import { DateTime } from "../../../../../utils";
import {
  checkDateFulfilsRecurringPreference,
  getDefaultPreferenceState,
  getPreferencesSumToDisplay,
  isPreferenceSpecial,
} from "../../../service/employeeAppUtil";
import { KEYWORD_OFF } from "../../../../../constants/keywords";

const PreferencesListView = ({
  selectedDate,
  recurringPreference,
  preference,
  togglePreferencesUpdateTab,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
}) => {
  const prferenceExists = recurringPreference || preference;

  const getPreferenceCard = (preference, isRecurring, showExplanation) => {
    const { displayedAllocation, startTime, finishTime } = preference;
    const emphasizeColor = isRecurring ? "#FA8D30" : "#B07BED";
    const backgroundColor = isRecurring ? "#FCDDC3" : "#EEE0FF";
    const isSpecial = isPreferenceSpecial(preference);

    let duration = "";
    if (startTime && finishTime) {
      const startTimeStr = DateTime.getFormattedTime(startTime, "readable");
      const finishTimStr = DateTime.getFormattedTime(finishTime, "readable");
      duration = `${startTimeStr} - ${finishTimStr}`;
    }

    const { prefNumberToDisplay, isViolation } = getPreferencesSumToDisplay(
      selectedDate,
      preference,
      isRecurring ? preferenceRecurringNumbers : preferenceNumbers,
      numberOfEmployees,
      isRecurring
    );

    const subTitle = isRecurring
      ? "Recurring"
      : "Date Specific (overrules recurring)";

    return (
      <div>
        <p className={styles.subTitle} style={{ color: emphasizeColor }}>
          {subTitle}
        </p>
        <SideTabCard
          emphasizeColor={emphasizeColor}
          backgroundColor={backgroundColor}
        >
          <div className={styles.cardContainer}>
            <div className={styles.cardRow}>
              <span className={styles.preferenceName}>
                {displayedAllocation}
              </span>
              <div className={styles.preferenceTagsContainer}>
                {isRecurring && (
                  <div className={styles.preferenceTag}>
                    <FontAwesomeIcon
                      className={styles.preferenceTypeTagIcon}
                      icon={faInfinity}
                    />
                    <span className={styles.preferenceTypeLabel}>
                      Recurring
                    </span>
                  </div>
                )}
                {isSpecial && (
                  <div className={styles.preferenceTag}>
                    <img
                      className={styles.preferenceTypeTagImg}
                      src={blueStartImg}
                      alt="Grey star"
                    />
                    <span className={styles.preferenceTypeLabel}>
                      Special request
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.cardRow}>
              <span className={styles.duration}>{duration}</span>
            </div>
            <div className={styles.cardRow}>
              <div className={styles.bottomInfoContainer}>
                {getDefaultPreferenceState(preference) && (
                  <p
                    className={`${styles.state} ${
                      styles[getDefaultPreferenceState(preference)]
                    }`}
                  >
                    {getDefaultPreferenceState(preference)}
                  </p>
                )}
                <span
                  className={`${styles.preferenceNumber} ${
                    isViolation ? styles.violation : styles.nonViolation
                  }`}
                >
                  {prefNumberToDisplay}
                </span>
              </div>
            </div>
          </div>
        </SideTabCard>
        {showExplanation && (
          <div className={styles.circleDescription}>
            <span
              className={`${styles.circle} ${
                isViolation ? styles.violation : styles.nonViolation
              }`}
            ></span>
            <span className={styles.circleDescriptionContent}>
              The number of people with this preference request
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.sideTabContainer}>
      {prferenceExists && (
        <div className={styles.section}>
          <span className={styles.sectionTitle}>My Preferences</span>
          {preference &&
            getPreferenceCard(preference, false, recurringPreference == null)}
          {recurringPreference &&
            getPreferenceCard(recurringPreference, true, true)}
        </div>
      )}

      <BasicButton
        color="#219ec9"
        hoverColor="#1f91b7"
        customStyle={{
          borderRadius: "5px",
        }}
        onClick={togglePreferencesUpdateTab}
      >
        <div className={styles.addPreferencesButtonContainer}>
          {prferenceExists ? (
            <>Add/Edit Preferences</>
          ) : (
            <>
              <FontAwesomeIcon icon={faPlusCircle} />
              Add Preferences
            </>
          )}
        </div>
      </BasicButton>
    </div>
  );
};

const PreferencesUpdateView = ({
  togglePreferencesUpdateTab,
  recurringPreference,
  preference,
  handleUpdatePreference,
  setSelectedDate,
  selectedDate,
  availableShifts,
  availableShiftGroups,
}) => {
  const [isDateSpecificSelected, setIsDateSpecificSelected] = useState(true);
  const [isSpecialActivated, setIsSpecialActivated] = useState(false);

  const selectables = useMemo(
    () => [
      { name: KEYWORD_OFF, shortId: KEYWORD_OFF },
      ...availableShifts.map(({ name, shortId }) => ({
        name,
        shortId: shortId ? shortId : name,
      })),
      ...availableShiftGroups.map(({ name, shortId }) => ({
        name,
        shortId: shortId ? shortId : name,
      })),
    ],
    [availableShifts, availableShiftGroups]
  );

  const getButtonStyle = (isDateSpecificButton) => {
    if (isDateSpecificButton) {
      return isDateSpecificSelected
        ? {
            backgroundColor: "#b07bed",
            color: "white",
          }
        : {
            backgroundColor: "transparent",
            color: "#b07bed",
            border: "1px solid #b07bed",
          };
    } else {
      return isDateSpecificSelected
        ? {
            backgroundColor: "transparent",
            color: "#fa8d30",
            border: "1px solid #fa8d30",
          }
        : {
            backgroundColor: "#fa8d30",
            color: "white",
          };
    }
  };

  const updatePreference = (allocationName) => {
    let updatedPreferenceNameOnDay = preference ? preference.allocation : "";
    let updatedRecurringPreferenceNameOnDay = recurringPreference
      ? recurringPreference.allocation
      : "";

    if (isDateSpecificSelected) {
      updatedPreferenceNameOnDay = allocationName;
    } else {
      updatedRecurringPreferenceNameOnDay = allocationName;
    }

    let isDayPreferenceSpecial = preference
      ? isPreferenceSpecial(preference)
      : false;
    let isRecurringPreferenceSpecial = recurringPreference
      ? isPreferenceSpecial(recurringPreference)
      : false;

    if (isDateSpecificSelected) {
      isDayPreferenceSpecial = isSpecialActivated;
    }

    if (!isDateSpecificSelected) {
      isRecurringPreferenceSpecial = isSpecialActivated;
    }

    handleUpdatePreference(
      updatedPreferenceNameOnDay,
      updatedRecurringPreferenceNameOnDay,
      isDayPreferenceSpecial,
      isRecurringPreferenceSpecial
    );
  };

  const goToNextDate = () => {
    setIsSpecialActivated(false);
    setSelectedDate(new DateTime(selectedDate).addDays(1));
  };

  const goToPreviousDate = () => {
    setIsSpecialActivated(false);
    setSelectedDate(new DateTime(selectedDate).subtractDays(1));
  };

  return (
    <div className={styles.sideTabContainer}>
      <div className={styles.section}>
        <span className={styles.sectionTitle}>My Preferences</span>
        <div className={styles.preferenceTypeSelector}>
          <button
            className={styles.selectButton}
            style={getButtonStyle(true)}
            onClick={() => setIsDateSpecificSelected(true)}
          >
            Date specific
          </button>
          <button
            className={styles.selectButton}
            onClick={() => setIsDateSpecificSelected(false)}
            style={getButtonStyle(false)}
          >
            Recurring
          </button>
        </div>
        <div className={styles.availableShiftLabelRow}>
          <span className={styles.subSectionTitle}>Available shifts:</span>
          <button
            className={`${styles.makeSpecialPreferenceButton} ${
              isSpecialActivated && styles.makeSpecialPreferenceButtonActivated
            }`}
            onClick={() => setIsSpecialActivated((prev) => !prev)}
          >
            Make it special
          </button>
        </div>
        <div className={styles.shiftButtonsContainer}>
          <button
            className={`${styles.deleteButton} ${styles.shiftButton}`}
            onClick={() => {
              if (!preference && !recurringPreference) {
                goToPreviousDate();
                return;
              }
              updatePreference("");
              goToPreviousDate();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
            Delete
          </button>
          <button
            className={`${styles.skipButton} ${styles.shiftButton}`}
            onClick={goToNextDate}
          >
            Skip
          </button>
          {selectables.map(({ name, shortId }, idx) => (
            <button
              key={idx}
              className={`${styles.shiftSelectionButton} ${styles.shiftButton}`}
              style={getButtonStyle(isDateSpecificSelected)}
              onClick={() => {
                updatePreference(shortId);
                goToNextDate();
              }}
            >
              {name}
            </button>
          ))}
        </div>
      </div>

      <BasicButton
        color="#219ec9"
        hoverColor="#1f91b7"
        customStyle={{
          borderRadius: "5px",
          fontWeight: "bold",
          fontSize: "1.1em",
        }}
        onClick={togglePreferencesUpdateTab}
      >
        Done
      </BasicButton>
    </div>
  );
};

const PreferencesSideTab = ({
  currentViewData,
  setSelectedDate,
  preferences,
  preferencesRecurring,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
  updatePreference,
  defaultPreferenceLevel,
  availableShifts,
  availableShiftGroups,
  locationStartDate,
}) => {
  const { selectedDate } = currentViewData;
  const targetRecurringPreference = checkDateFulfilsRecurringPreference(
    locationStartDate,
    selectedDate,
    preferencesRecurring
  );

  const targetPreference = preferences.find(
    (preference) => preference.date === selectedDate.toFormat("AWS")
  );

  const [showPreferencesUpdateTab, setShowPreferencesUpdateTab] =
    useState(false);

  const togglePreferencesUpdateTab = () =>
    setShowPreferencesUpdateTab((prev) => !prev);

  const handleUpdatePreference = (
    updatedPreferenceNameOnDay,
    updatedRecurringPreferenceNameOnDay,
    isDayPreferenceSpecial,
    isRecurringPreferenceSpecial
  ) => {
    const updatedData = {
      date: selectedDate.getDate(),
      specialSpecificPreference: isDayPreferenceSpecial,
      specialRecurringPreference: isRecurringPreferenceSpecial,
      selectedSpecificPreference: updatedPreferenceNameOnDay,
      selectedRecurringPreference: updatedRecurringPreferenceNameOnDay,
      defaultPreferenceLevel: defaultPreferenceLevel,
      DoW: selectedDate.getDayOfWeek("number"),
    };
    updatePreference(updatedData);
  };

  if (showPreferencesUpdateTab) {
    return (
      <PreferencesUpdateView
        togglePreferencesUpdateTab={togglePreferencesUpdateTab}
        recurringPreference={targetRecurringPreference}
        preference={targetPreference}
        handleUpdatePreference={handleUpdatePreference}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        availableShifts={availableShifts}
        availableShiftGroups={availableShiftGroups}
      />
    );
  }

  return (
    <PreferencesListView
      selectedDate={selectedDate}
      recurringPreference={targetRecurringPreference}
      preference={targetPreference}
      togglePreferencesUpdateTab={togglePreferencesUpdateTab}
      numberOfEmployees={numberOfEmployees}
      preferenceNumbers={preferenceNumbers}
      preferenceRecurringNumbers={preferenceRecurringNumbers}
    />
  );
};
export default PreferencesSideTab;
