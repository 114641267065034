import styles from "./PreferencesBottomTabContent.module.css";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { KEYWORD_OFF } from "../../../../../constants/keywords";
import { DateTime } from "../../../../../utils";
import StarBlue from "../../../../../assets/images/darkBlueStar.svg";
import StarGray from "../../../../../assets/images/star-grey.svg";
import {
  checkDateFulfilsRecurringPreference,
  getDefaultPreferenceState,
  getPreferencesSumToDisplay,
  isPreferenceSpecial,
} from "../../../service/employeeAppUtil";

const PreferencesUpdater = ({
  date,
  setSelectedDate,
  recurringPreference,
  preference,
  availableShifts,
  availableShiftGroups,
  toggleEditPreference,
  handleUpdatePreference,
}) => {
  const [isDateSpecificSelected, setIsDateSpecificSelected] = useState(true);
  const [isSpecialActivated, setIsSpecialActivated] = useState(false);

  const selectables = useMemo(
    () => [
      { name: KEYWORD_OFF, shortId: KEYWORD_OFF },
      ...availableShifts.map(({ name, shortId }) => ({
        name,
        shortId: shortId ? shortId : name,
      })),
      ...availableShiftGroups.map(({ name, shortId }) => ({
        name,
        shortId: shortId ? shortId : name,
      })),
    ],
    [availableShifts, availableShiftGroups]
  );

  const getButtonStyle = (isDateSpecificButton) => {
    if (isDateSpecificButton) {
      return isDateSpecificSelected
        ? {
            backgroundColor: "#b07bed",
            color: "white",
          }
        : {
            backgroundColor: "transparent",
            color: "#b07bed",
            border: "1px solid #b07bed",
          };
    } else {
      return isDateSpecificSelected
        ? {
            backgroundColor: "transparent",
            color: "#fa8d30",
            border: "1px solid #fa8d30",
          }
        : {
            backgroundColor: "#fa8d30",
            color: "white",
          };
    }
  };

  const updatePreference = (allocationName) => {
    let updatedPreferenceNameOnDay = preference ? preference.allocation : "";
    let updatedRecurringPreferenceNameOnDay = recurringPreference
      ? recurringPreference.allocation
      : "";

    if (isDateSpecificSelected) {
      updatedPreferenceNameOnDay = allocationName;
    } else {
      updatedRecurringPreferenceNameOnDay = allocationName;
    }

    let isDayPreferenceSpecial = preference
      ? isPreferenceSpecial(preference)
      : false;
    let isRecurringPreferenceSpecial = recurringPreference
      ? isPreferenceSpecial(recurringPreference)
      : false;

    if (isDateSpecificSelected) {
      isDayPreferenceSpecial = isSpecialActivated;
    }

    if (!isDateSpecificSelected) {
      isRecurringPreferenceSpecial = isSpecialActivated;
    }

    handleUpdatePreference(
      updatedPreferenceNameOnDay,
      updatedRecurringPreferenceNameOnDay,
      isDayPreferenceSpecial,
      isRecurringPreferenceSpecial
    );
  };

  const goToNextDate = () => {
    setIsSpecialActivated(false);
    setSelectedDate(new DateTime(date).addDays(1));
  };

  const goToPreviousDate = () => {
    setIsSpecialActivated(false);
    setSelectedDate(new DateTime(date).subtractDays(1));
  };

  return (
    <div className={styles.container}>
      <div className={styles.upperUpdate}>
        <div className={styles.preferenceTypeSelector}>
          <button
            className={styles.selectButton}
            style={getButtonStyle(true)}
            onClick={() => setIsDateSpecificSelected(true)}
          >
            Date specific
          </button>
          <button
            className={styles.selectButton}
            onClick={() => setIsDateSpecificSelected(false)}
            style={getButtonStyle(false)}
          >
            Recurring
          </button>
        </div>
        <div className={styles.availableShiftLabelRow}>
          <span className={styles.subSectionTitle}>Available shifts:</span>
          <button
            className={`${styles.makeSpecialPreferenceButton} ${
              isSpecialActivated && styles.makeSpecialPreferenceButtonActivated
            }`}
            onClick={() => setIsSpecialActivated((prev) => !prev)}
          >
            Make it special
          </button>
        </div>
        <div className={styles.shiftButtonsContainer}>
          <button
            className={`${styles.deleteButton} ${styles.shiftButton}`}
            onClick={() => {
              if (!preference && !recurringPreference) {
                goToPreviousDate();
                return;
              }
              updatePreference("");
              goToPreviousDate();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
            Delete
          </button>
          <button
            className={`${styles.skipButton} ${styles.shiftButton}`}
            onClick={goToNextDate}
          >
            Skip
          </button>
          {selectables.map(({ name, shortId }, idx) => (
            <button
              key={idx}
              className={`${styles.shiftSelectionButton} ${styles.shiftButton}`}
              style={getButtonStyle(isDateSpecificSelected)}
              onClick={() => {
                updatePreference(shortId);
                goToNextDate();
              }}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.lowerUpdate}>
        <button className={styles.doneButton} onClick={toggleEditPreference}>
          Done
        </button>
      </div>
    </div>
  );
};

const PreferencesViewer = ({
  date,
  preferenceOnDay,
  recurringPreferenceOnDay,
  toggleEditPreference,
  handleUpdatePreference,
  preferencesRecurringLength,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
}) => {
  const preferenceExistsOnDay = preferenceOnDay || recurringPreferenceOnDay;

  const makePreferenceSpecial = (isRecurring, isSpecialBeingAsked) => {
    const preferenceName = preferenceOnDay ? preferenceOnDay.allocation : "";
    const recurringPreferenceName = recurringPreferenceOnDay
      ? recurringPreferenceOnDay.allocation
      : "";

    let isDayPreferenceSpecial = preferenceOnDay
      ? isPreferenceSpecial(preferenceOnDay)
      : false;
    let isRecurringPreferenceSpecial = recurringPreferenceOnDay
      ? isPreferenceSpecial(recurringPreferenceOnDay)
      : false;

    if (!isRecurring) {
      isDayPreferenceSpecial = isSpecialBeingAsked;
    }

    if (isRecurring) {
      isRecurringPreferenceSpecial = isSpecialBeingAsked;
    }

    handleUpdatePreference(
      preferenceName,
      recurringPreferenceName,
      isDayPreferenceSpecial,
      isRecurringPreferenceSpecial
    );
  };

  const preferenceNumberToDisplay = preferenceOnDay
    ? getPreferencesSumToDisplay(
        date,
        preferenceOnDay,
        preferenceNumbers,
        numberOfEmployees,
        false
      )
    : null;

  const preferenceRecurringNumberToDisplay = recurringPreferenceOnDay
    ? getPreferencesSumToDisplay(
        date,
        recurringPreferenceOnDay,
        preferenceRecurringNumbers,
        numberOfEmployees,
        true
      )
    : null;

  return (
    <div className={styles.container}>
      {preferenceExistsOnDay && (
        <div className={styles.firstRow}>
          <span>Make it as important</span>
        </div>
      )}
      {preferenceOnDay && (
        <div className={styles.secondRow}>
          <div className={styles.rowTop}>
            <div className={styles.labelGroup}>
              <span className={`${styles.daySpecific}`}>Date specific</span>
              {preferenceNumberToDisplay && (
                <span
                  className={`${styles.preferenceNumber} ${
                    preferenceNumberToDisplay.isViolation
                      ? styles.violation
                      : styles.nonViolation
                  }`}
                >
                  {preferenceNumberToDisplay.prefNumberToDisplay}
                </span>
              )}
            </div>
            <button
              className={styles.specialButton}
              onClick={() =>
                makePreferenceSpecial(
                  false,
                  !isPreferenceSpecial(preferenceOnDay)
                )
              }
            >
              <img
                className={styles.starImage}
                src={isPreferenceSpecial(preferenceOnDay) ? StarBlue : StarGray}
              />
            </button>
          </div>
          <div className={styles.rowBottom}>
            <div className={styles.labelGroup}>
              <span className={styles.allocationLabel}>
                {preferenceOnDay.displayedAllocation}
              </span>
              <span
                className={`${styles.state} ${
                  styles[getDefaultPreferenceState(preferenceOnDay)]
                }`}
              >
                {getDefaultPreferenceState(preferenceOnDay)?.substring(0, 1)}
              </span>
            </div>
          </div>
        </div>
      )}
      {recurringPreferenceOnDay && (
        <div className={styles.thirdRow}>
          <div className={styles.rowTop}>
            <div className={styles.labelGroup}>
              <span className={`${styles.recurring}`}>Recurring</span>
              {preferenceRecurringNumberToDisplay && (
                <span
                  className={`${styles.preferenceNumber} ${
                    preferenceRecurringNumberToDisplay.isViolation
                      ? styles.violation
                      : styles.nonViolation
                  }`}
                >
                  {preferenceRecurringNumberToDisplay.prefNumberToDisplay}
                </span>
              )}
              <span className={styles.weeklyLabel}>
                {preferencesRecurringLength === 7 ? "Weekly" : "2 Weekly"}
              </span>
            </div>
            <button
              className={styles.specialButton}
              onClick={() =>
                makePreferenceSpecial(
                  true,
                  !isPreferenceSpecial(recurringPreferenceOnDay)
                )
              }
            >
              <img
                className={styles.starImage}
                src={
                  isPreferenceSpecial(recurringPreferenceOnDay)
                    ? StarBlue
                    : StarGray
                }
              />
            </button>
          </div>
          <div className={styles.rowBottom}>
            <div className={styles.labelGroup}>
              <span className={styles.allocationLabel}>
                {recurringPreferenceOnDay.displayedAllocation}
              </span>
              <span
                className={`${styles.state} ${
                  styles[getDefaultPreferenceState(recurringPreferenceOnDay)]
                }`}
              >
                {getDefaultPreferenceState(recurringPreferenceOnDay)?.substring(
                  0,
                  1
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={styles.forthRow}>
        <button
          className={styles.editPreferencesButton}
          onClick={toggleEditPreference}
        >
          Add/Edit My Preferences
        </button>
      </div>
    </div>
  );
};

const PreferencesBottomTabContent = ({
  date,
  preferences,
  preferencesRecurring,
  setSelectedDate,
  availableShifts,
  availableShiftGroups,
  defaultPreferenceLevel,
  updatePreference,
  locationStartDate,
  numberOfEmployees,
  preferenceNumbers,
  preferenceRecurringNumbers,
}) => {
  const preferencesRecurringLength = preferencesRecurring.length;
  const [isEditPreferenceOpen, setIsEditPreferenceOpen] = useState(false);

  const toggleEditPreference = () => setIsEditPreferenceOpen((prev) => !prev);

  const preferenceOnDay = preferences.find(
    (preference) => preference.date === date.toFormat("AWS")
  );
  const recurringPreferenceOnDay = checkDateFulfilsRecurringPreference(
    locationStartDate,
    date,
    preferencesRecurring
  );

  const handleUpdatePreference = (
    updatedPreferenceNameOnDay,
    updatedRecurringPreferenceNameOnDay,
    isDayPreferenceSpecial,
    isRecurringPreferenceSpecial
  ) => {
    const updatedData = {
      date: date.getDate(),
      specialSpecificPreference: isDayPreferenceSpecial,
      specialRecurringPreference: isRecurringPreferenceSpecial,
      selectedSpecificPreference: updatedPreferenceNameOnDay,
      selectedRecurringPreference: updatedRecurringPreferenceNameOnDay,
      defaultPreferenceLevel: defaultPreferenceLevel,
      DoW: date.getDayOfWeek("number"),
    };
    updatePreference(updatedData);
  };

  if (isEditPreferenceOpen) {
    return (
      <PreferencesUpdater
        date={date}
        setSelectedDate={setSelectedDate}
        recurringPreference={recurringPreferenceOnDay}
        preference={preferenceOnDay}
        availableShifts={availableShifts}
        availableShiftGroups={availableShiftGroups}
        toggleEditPreference={toggleEditPreference}
        handleUpdatePreference={handleUpdatePreference}
      />
    );
  }

  return (
    <PreferencesViewer
      date={date}
      preferenceOnDay={preferenceOnDay}
      recurringPreferenceOnDay={recurringPreferenceOnDay}
      toggleEditPreference={toggleEditPreference}
      handleUpdatePreference={handleUpdatePreference}
      preferencesRecurringLength={preferencesRecurringLength}
      numberOfEmployees={numberOfEmployees}
      preferenceNumbers={preferenceNumbers}
      preferenceRecurringNumbers={preferenceRecurringNumbers}
    />
  );
};

export default PreferencesBottomTabContent;
