import {
  convertAllocationInShortIdFormToNameForm,
  removeAreaInAllocation,
} from "../../../../utils/modelUtils/allocation";

export const rosterAllocationsDataToPrintable = (
  data,
  numDays,
  shortIdsToEntityNamesDicts,
  displayAreaInAllocationCell
) => {
  const printableContent = [];
  data.forEach((data) => {
    const printableRow = Array(numDays).fill("");
    for (const key in data) {
      if (key.startsWith("d")) {
        const idx = Number(key.slice(1));
        let value = data[key];

        if (!displayAreaInAllocationCell) {
          value = removeAreaInAllocation(
            value,
            shortIdsToEntityNamesDicts,
            false
          );
        }

        const cellValue = convertAllocationInShortIdFormToNameForm(
          value,
          shortIdsToEntityNamesDicts
        );
        printableRow[idx] = cellValue;
      }
      if (key === "name") {
        printableRow[0] = data[key];
      }
    }
    printableContent.push(printableRow);
  });
  return printableContent;
};
