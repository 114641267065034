import { useCallback, useState } from "react";
import styles from "./EmployeesGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useMemo } from "react";
import { useRef } from "react";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import DropdownCreatableMultiSelector from "../../../../grid/components/DropdownCreatableMultiSelector/DropdownCreatableMultiSelector";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  createMinimalContextMenu,
  defaultFilterValueGetter,
  defaultValueSetter,
  dropdownOptionsValueSetter,
  getAreaOptions,
  getShiftAndShiftGroupOptions,
  onFilterTextBoxChanged,
  suppressEnterKey,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

const EmployeesGrid = ({
  employeesData,
  numEmps,
  isSaving,
  setGridApiToParent,
  addData: addEmployees,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateEmployees,
  employeesWarnings,
  removeData: removeEmployees,
  gridApi,
  showActionBar = true,
  showAddBtn = true,
  showRowCount = true,
  customStyle = null,
  suppressMenu = false,
  title = null,
  showWarningBox = true,
  setEmployeesColumnApiToParent,
  minimalContextMenu = false,
  isIndividualView = false,
  reorderEmployeesData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  createSkillFromEmployeesTable,
  viewSwitcherComponent = null,
  getDataFromGrid,
  skills,
  shifts,
  shiftGroups,
  areas,
  customTopComponent = null,
  doesAreaFilterPass,
  isExternalFilterPresent,
  shortIdsToEntityNamesDicts,
  rosterName,
  isScheduleView,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const getContextMenuItems = () => {
    if (minimalContextMenu) {
      return createMinimalContextMenu();
    }
    return createContextMenuWithDelete(() => {
      removeEmployees(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent = employeesWarnings && employeesWarnings.length > 0;

  const { shiftOptions, shiftGroupOptions, selectAllShiftsOption } = useMemo(
    () => getShiftAndShiftGroupOptions(shifts, shiftGroups),
    [shifts, shiftGroups]
  );

  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const shiftAndShiftGroupOptions = useMemo(
    () => [
      {
        label: null,
        options: [selectAllShiftsOption],
      },
      {
        label: "",
        options: shiftOptions,
      },
      {
        label: "",
        options: shiftGroupOptions,
      },
    ],
    [shiftOptions, shiftGroupOptions, selectAllShiftsOption]
  );

  const areaOptionsWithSelectAll = useMemo(
    () => [
      {
        label: null,
        options: [selectAllAreasOption],
      },
      {
        label: "",
        options: areaOptions,
      },
    ],
    [selectAllAreasOption, areaOptions]
  );

  let skillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );

  const checkFieldValueIsDuplicate = useCallback(
    (fieldName, newValue) => {
      const values = employeesData.map((emp) => emp[fieldName]);
      if (values.includes(newValue)) {
        return true;
      }
      return false;
    },
    [employeesData]
  );

  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        sortable: true,
        suppressSizeToFit: true,
        width: 190,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Task name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            params.newValue &&
            checkFieldValueIsDuplicate("name", params.newValue)
          ) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }
          return defaultValueSetter(params);
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (!params.value) {
              return true;
            }
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
      },
      {
        field: "skills",
        width: isIndividualView ? width / 4 : (width - 190 - 150) / 3,
        minWidth: 150,
        suppressSizeToFit: true,
        cellEditor: "dropdownCreatableMultiSelector",
        cellEditorParams: {
          options: skillOptions,
          createNewCandidate: (input) => {
            createSkillFromEmployeesTable(input.trim());
          },
          customFormatCreateLabel: (userInput) => `Add Skill "${userInput}"`,
        },
        cellEditorPopup: true,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            skillOptions,
            shortIdsToEntityNamesDicts
          ),
        suppressKeyboardEvent: suppressEnterKey,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      },
      {
        headerName: "Shifts or Shift Groups",
        field: "shifts",
        width: isIndividualView ? width / 4 : (width - 190 - 150) / 3,
        minWidth: 150,
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: shiftAndShiftGroupOptions,
          allowSelectAll: true,
          selectAllOption: selectAllShiftsOption,
        },
        cellEditorPopup: true,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            [selectAllShiftsOption, ...shiftOptions, ...shiftGroupOptions],
            shortIdsToEntityNamesDicts
          ),
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) => {
          const value = params.value;
          if (value === KEYWORD_ALL) {
            return KEYWORD_ALL;
          }

          const names = convertShortIdsCellValueToEntityNames(value, [
            ...shifts,
            ...shiftGroups,
          ]);

          return names;
        },
        filterValueGetter: (params) =>
          defaultFilterValueGetter(
            params,
            "shifts",
            [...shifts, ...shiftGroups],
            [KEYWORD_ALL]
          ),
      },
      {
        sortable: true,
        field: "areas",
        width: isIndividualView ? width / 4 : (width - 190 - 150) / 3 - 2,
        valueSetter: (params) =>
          dropdownOptionsValueSetter(
            params,
            [selectAllAreasOption, ...areaOptions],
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, areas),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "areas", areas),
        suppressKeyboardEvent: suppressEnterKey,
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: areaOptionsWithSelectAll,
          allowSelectAll: true,
          selectAllOption: selectAllAreasOption,
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (employeesWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                employeesWarnings
              );
            }
          },
        },
        hide: areas.length === 0,
      },
      {
        field: "externalID",
        width: isIndividualView ? width / 4 - 2 : 150,
      },
    ],
    [
      employeesWarnings,
      isIndividualView,
      selectAllShiftsOption,
      skillOptions,
      width,
      createSkillFromEmployeesTable,
      shiftGroups,
      shifts,
      skills,
      checkFieldValueIsDuplicate,
      shiftOptions,
      shiftGroupOptions,
      shiftAndShiftGroupOptions,
      areaOptions,
      areas,
      selectAllAreasOption,
      areaOptionsWithSelectAll,
      shortIdsToEntityNamesDicts,
    ]
  );

  const DEFAULT_TITLE = isScheduleView
    ? "Employees"
    : `Employees - ${rosterName}`;

  return (
    <Layout
      title={title || DEFAULT_TITLE}
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/employees-skills-and-tasks"}
        />
      )}
      isSubheading={customStyle ? customStyle.isSubheading : false}
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles.topLine}>
          <div className={styles.left}>
            {customTopComponent && customTopComponent}
            {viewSwitcherComponent && viewSwitcherComponent}
          </div>
          <div className={styles.right}>
            {showAddBtn && (
              <AddRowButton
                rowName={"Employee"}
                addSingle={() => {
                  if (!isCellEditing) addEmployees(1);
                }}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addEmployees(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt(`How many employees should I add?`, "1")
                      );
                      addEmployees(num);
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
        {showActionBar && (
          <ActionBar
            searchBarSettings={{
              tableName: "employees",
              onFilterInputChanged,
            }}
            duplicateSelectedSettings={{
              selectedRows,
              duplicateSelectedRows: duplicateEmployees,
            }}
            deleteSelectedSettings={{
              selectedRows,
              removeSelectedRows: () => removeEmployees(false),
            }}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
        )}
        <DataEntryTable
          customStyle={customStyle ? customStyle.dataEntryTable : {}}
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onGridReady: () => {},
            onRowDragEnd: (params) => {
              reorderEmployeesData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          components={{
            dropdownMultiSelector: DropdownMultiSelector,
            dropdownCreatableMultiSelector: DropdownCreatableMultiSelector,
          }}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
            suppressMenu,
          }}
          setColumnApiToParent={setEmployeesColumnApiToParent}
          enableRowDragAnimation={!isSaving}
          tableName="employees"
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        {showRowCount && (
          <div className={styles["name-line-container"]}>
            <p className={styles["num-line"]}>
              <span className={styles.emph}>Number of employees:</span>{" "}
              {numEmps}
            </p>
          </div>
        )}
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && showWarningBox && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(employeesWarnings)}
          />
        </div>
      )}
    </Layout>
  );
};

export default EmployeesGrid;
