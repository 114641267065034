import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DateTime,
  checkAcceptedOpenShiftIsModified,
  checkAllocationIsLeave,
  checkAllocationIsNAOrLeave,
  convertShortIDsToEntityNames,
  deepCopyObject,
  exportGridToCsv,
  exportGridToExcel,
  getLongestAllocationStringInRowData,
  parseSolutionModelToSolutionsGridFormat,
  removeCustomSuffix,
  sortByDateField,
  strToArrCommaSeparated,
} from "../../../../utils";
import GenerateLoadingBar from "../../../rosterProblems/components/Solutions/GenerateLoadingBar/GenerateLoadingBar";
import PrintableSolutionButton from "../../../rosterProblems/components/Solutions/PrintableSolution/PrintableSolution";
import SolutionGrid from "../../../rosterProblems/components/Solutions/SolutionGrid/SolutionGrid";
import SolutionsList from "../../../rosterProblems/components/Solutions/SolutionsList/SolutionsList";
import {
  useGenerateLoadingProgress,
  useGeneratingSolutionsStatus,
} from "../../../rosterProblems/hooks/useGenerateLoadingProgress";
import { getShiftHoursForEmployees } from "../../../rules/service/rules";
import SolutionsLocked from "../../../upgradePlan/components/SolutionsLocked/SolutionsLocked";
import styles from "./ScheduleSolutionsDataContainer.module.css";
import DeleteSolutionButton from "../../../rosterProblems/components/Solutions/DeleteSolutionButton/DeleteSolutionButton";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import {
  getCustomKeywordsDataFromFrontendSettings,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useQueryClient } from "@tanstack/react-query";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import { useUserStore } from "../../../../globalStore/appStore";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { customWarningAlert } from "../../../confirm/service/confirm";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
} from "../../../rosterProblems/service/rosterUtils";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import SolutionAnalysis from "../../../rosterProblems/components/Solutions/SolutionAnalysis/SolutionAnalysis";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { convertAllocationInNameFormToShortIdForm } from "../../../../utils/modelUtils/allocation";

const ScheduleSolutionsDataContainer = ({
  locationID,
  periodStartDate,
  periodFinishDate,
  customKeywordsData,
  isRoster,
  rosterID,
  location,
  globalEmployees,
  periodNum,
}) => {
  const { isPaidPlan, plan } = useUserStore();
  const [isShiftView, setIsShiftView] = useState(false);

  const toggleShiftView = () => {
    if (isRoster) {
      return;
    }
    setIsShiftView((prev) => !prev);
  };

  const redirect = useRedirect();

  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );

  const { leaveCodes, leaveKeywords } = customKeywordsUtilObj;

  const queryClient = useQueryClient();

  const checkLeave = useCallback(
    (leaveString) => {
      return checkAllocationIsLeave(leaveString, leaveCodes);
    },
    [leaveCodes]
  );

  const {
    isLoadingBarDisplayed,
    handleHideLoadingBar,
    solutionLoadingProgress,
    latestSolutionStatus,
  } = useGenerateLoadingProgress();

  const {
    fields,
    roster,
    isQueryLoading,
    gridApi,
    isSaving,
    setGridApi,
    deleteRosterSolutions,
    updateFields,
    refetchRoster,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  const exportToCsv = useCallback(() => {
    exportGridToCsv(gridApi);
  }, [gridApi]);

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  const {
    name,
    startDate,
    numDays,
    shifts,
    employees,
    shiftGroups,
    tasks,
    taskBlocks,
    areas,
    subTasks,
    demands,
    rules,
    skills,
    colorCodes,
    solutions,
    frontendSettings: globalFrontendSettings,
    isSnapshot,
  } = fields;

  const namesToEntityShortIdsDicts = useMemo(
    () =>
      buildNamesToEntityShortIdsDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const isMainStream = !isRoster && !isSnapshot;

  const rosteringSolutions = useMemo(() => {
    const orderedSolutions = deepCopyObject(solutions);
    sortByDateField(orderedSolutions, "updatedAt", false);
    return orderedSolutions;
  }, [solutions]);

  const selectableSolutions = useMemo(() => {
    return rosteringSolutions.filter(
      (solution) => !solution.rerosteredSolutionID
    );
  }, [rosteringSolutions]);

  const statuses = useGeneratingSolutionsStatus(selectableSolutions);

  const [selectedSolution, setSelectedSolution] = useState(0);
  const [selectedDemand, setSelectedDemand] = useState(0);

  const selectedRosterSolution = useMemo(() => {
    return selectedSolution < selectableSolutions.length
      ? selectableSolutions[selectedSolution]
      : null;
  }, [selectedSolution, selectableSolutions]);

  const comparisonSolution = rosteringSolutions.filter(
    (x) =>
      x.rerosteredSolutionID &&
      selectedRosterSolution &&
      x.rerosteredSolutionID === selectedRosterSolution.id
  )[0];

  const comparisonData = compareRosterSolutions(
    comparisonSolution,
    selectedRosterSolution,
    numDays
  );

  const employeesShiftHours = useMemo(
    () =>
      getShiftHoursForEmployees(
        employees,
        rules,
        shifts,
        shiftGroups,
        interpretCustomKeywordsData(
          getCustomKeywordsDataFromFrontendSettings(globalFrontendSettings)
        )
      ),
    [employees, rules, shifts, shiftGroups, globalFrontendSettings]
  );

  useEffect(() => {
    if (latestSolutionStatus) {
      refetchRoster();
      if (isMainStream) {
        queryClient.invalidateQueries({
          queryKey: ["location", locationID],
        });
      }
    }
  }, [
    queryClient,
    latestSolutionStatus,
    isMainStream,
    periodStartDate,
    locationID,
    refetchRoster,
  ]);

  const solutionData = useMemo(
    () =>
      !selectedRosterSolution
        ? []
        : parseSolutionModelToSolutionsGridFormat(
            selectedRosterSolution,
            checkLeave,
            globalFrontendSettings.find(
              (setting) => setting.name === "showLeaveByDay"
            ),
            employees
          ),
    [selectedRosterSolution, checkLeave, globalFrontendSettings, employees]
  );

  const demandsAI = demands
    .filter((demand) => demand.importance !== "No AI")
    .map((demand) => {
      const nameBasedDemand = getNameBasedDemand(
        demand,
        areas,
        shifts,
        shiftGroups,
        tasks,
        skills
      );

      return nameBasedDemand;
    }); // This is a bit of a hack-around because the rostering engine uses names and not IDs

  const demandData = useMemo(() => {
    if (
      !selectedRosterSolution ||
      demandsAI.length === 0 ||
      !selectedRosterSolution.demands
    ) {
      return [];
    }

    const data = selectedRosterSolution.demands.filter((demand) => {
      const solutionDemandstartTime = new DateTime(
        "2017-05-02T" +
          DateTime.getFormattedTime(demand.timeSpan.split("-")[0], "AWS"),
        true
      ).getDate();
      let solutionDemandfinishTime = new DateTime(
        "2017-05-02T" +
          DateTime.getFormattedTime(demand.timeSpan.split("-")[1], "AWS"),
        true
      ).getDate();
      const demandStartTime = new DateTime(
        "2017-05-02T" + demandsAI[selectedDemand].startTime,
        true
      ).getDate();
      let demandFinishTime = new DateTime(
        "2017-05-02T" + demandsAI[selectedDemand].finishTime,
        true
      ).getDate();

      if (solutionDemandfinishTime < solutionDemandstartTime) {
        solutionDemandfinishTime = new DateTime(
          solutionDemandfinishTime
        ).addDays(1).date;
      }
      if (demandFinishTime < demandStartTime) {
        demandFinishTime = new DateTime(demandFinishTime).addDays(1).date;
      }

      const demandSolTasks = demand.tasks ? demand.tasks : "";

      return (
        (demand.shifts ||
          (solutionDemandstartTime >= demandStartTime &&
            solutionDemandfinishTime <= demandFinishTime)) &&
        removeSpacesFromCommaSeparatedList(demandsAI[selectedDemand].skills) ===
          removeSpacesFromCommaSeparatedList(demand.skills) &&
        removeSpacesFromCommaSeparatedList(demandsAI[selectedDemand].tasks) ===
          removeSpacesFromCommaSeparatedList(demandSolTasks) &&
        removeSpacesFromCommaSeparatedList(demandsAI[selectedDemand].shifts) ===
          removeSpacesFromCommaSeparatedList(demand.shifts)
      );
    });
    return data;
  }, [selectedRosterSolution, demandsAI, selectedDemand]);

  const failedEmployeeData = useMemo(
    () =>
      !selectedRosterSolution
        ? []
        : parseSolutionToFailedDataByIssue(selectedRosterSolution),
    [selectedRosterSolution]
  );

  const solutionColumns = useMemo(() => {
    const newSolutionColumns = [];
    for (let d = 0; d < numDays; d++) {
      let dayName = "d" + d.toString();
      newSolutionColumns.push(dayName);
    }
    return newSolutionColumns;
  }, [numDays]);

  let longestStr = getLongestAllocationStringInRowData(solutionData, [
    "id",
    "name",
    "skills",
  ]);

  const getSolutionsList = useCallback(() => {
    return () => (
      <SolutionsList
        solutions={selectableSolutions}
        onChange={(newSelectedSolution) =>
          setSelectedSolution(newSelectedSolution)
        }
        selected={selectedSolution}
        statuses={statuses}
      />
    );
  }, [selectableSolutions, selectedSolution, statuses]);

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  const getPrintSolutionBtns = () => {
    if (
      selectedRosterSolution &&
      selectedRosterSolution.status.startsWith("Solved")
    ) {
      return [
        () => (
          <PrintableSolutionButton
            solutionData={selectedRosterSolution.employees.map((employee) => [
              employee.name,
              ...employee.days,
            ])}
            startDate={startDate}
            rosterName={startDate}
            numDays={numDays}
            size={"A4"}
            shiftGroups={shiftGroups}
            leaveKeywords={leaveKeywords}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            colorCodes={colorCodes}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        ),
        () => (
          <PrintableSolutionButton
            solutionData={selectedRosterSolution.employees.map((employee) => [
              employee.name,
              ...employee.days,
            ])}
            startDate={startDate}
            rosterName={startDate}
            numDays={numDays}
            size={"A3"}
            shiftGroups={shiftGroups}
            leaveKeywords={leaveKeywords}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            colorCodes={colorCodes}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        ),
      ];
    }
    return null;
  };

  const sendSolutionToMyRosterForRoster = (selectedRosterSolution) => {
    const updatedEmployees = employees.map((employee) => {
      const solutionEmployee = selectedRosterSolution.employees.find(
        (emp) => emp.name === employee.name
      );
      const solutionDays = solutionEmployee.days.map((allo) => {
        const allocation = checkAllocationIsNAOrLeave(allo, leaveCodes);
        if (allocation === "-") {
          return "";
        }
        return allocation;
      });

      const solutionDaysInShortId = solutionDays.map((allocation) =>
        convertAllocationInNameFormToShortIdForm(
          allocation,
          namesToEntityShortIdsDicts
        )
      );

      return {
        ...employee,
        RosteredAllocations: solutionDaysInShortId,
      };
    });

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };

    const redirectToMyRoster = () => {
      if (isSnapshot) {
        redirect({
          pageName: PAGE_NAMES.scheduleViewLocation,
          query: {
            "location-id": locationID,
            date: periodStartDate,
            "snapshot-id": rosterID,
          },
        });
      } else {
        redirect({
          pageName: PAGE_NAMES.roster,
          rosterID,
        });
      }
    };

    updateFields(["Employees"], updatedRoster, roster, []);
    redirectToMyRoster();
  };

  const sendSolutionToMyRosterForSchedule = (selectedRosterSolution) => {
    const employeesData = [];
    const employeesRosteredAllocations = [];

    employees.forEach((employee) => {
      const solutionEmployee = selectedRosterSolution.employees.find(
        (emp) => emp.name === employee.name
      );
      const solutionDays = solutionEmployee.days.map((allo) => {
        const allocation = checkAllocationIsNAOrLeave(allo, leaveCodes);
        if (allocation === "-") {
          return "";
        }
        return allocation;
      });

      const solutionDaysInShortId = solutionDays.map((allocation) =>
        convertAllocationInNameFormToShortIdForm(
          allocation,
          namesToEntityShortIdsDicts
        )
      );

      employeesRosteredAllocations.push({
        employeeID: employee.id,
        RosteredAllocations: solutionDaysInShortId,
      });
      const allDatesInRoster = DateTime.getAllDateStringsBetween(
        periodStartDate,
        periodFinishDate,
        true,
        true
      );
      const publishedAllocations = employee.PublishedAllocations;
      const updatedPublishedAllocations = allDatesInRoster.map((date, idx) => {
        const publishedAllocation = publishedAllocations.find(
          (allo) => allo.date === date
        );
        if (
          (publishedAllocation?.draftAllocation === null ||
            publishedAllocation?.draftAllocation === undefined) &&
          publishedAllocation?.publishedAllocation ===
            solutionDaysInShortId[idx]
        )
          return publishedAllocation;
        const allocation = {
          date,
          draftAllocation: solutionDaysInShortId[idx],
          publishedAllocation: publishedAllocation?.publishedAllocation,
          isOpenShift: false,
          note: publishedAllocation ? publishedAllocation.note : null,
        };
        return allocation;
      });
      employeesData.push({
        employeeID: employee.id,
        allocations: updatedPublishedAllocations,
      });
    });

    const updatedEmployees = employees.map((employee) => {
      const targetEmployeeInSolution = employeesData.find(
        (item) => item.employeeID === employee.id
      );

      if (!targetEmployeeInSolution) {
        return employee;
      }

      const solutionAllocations = targetEmployeeInSolution.allocations;
      const solutionAllocationDates = solutionAllocations.map(
        (item) => item.date
      );
      const originalPublishedAllocations = employee.PublishedAllocations;
      const updatedPublishedAllocations = [
        ...solutionAllocations,
        ...originalPublishedAllocations.filter(
          (item) => !solutionAllocationDates.includes(item.date)
        ),
      ];
      sortByDateField(updatedPublishedAllocations, "date", true);

      const rosteredAllocations = employeesRosteredAllocations.find(
        (item) => item.employeeID === employee.id
      );

      return {
        ...employee,
        PublishedAllocations: updatedPublishedAllocations,
        RosteredAllocations: rosteredAllocations.RosteredAllocations,
      };
    });

    if (checkAcceptedOpenShiftIsModified(employees, updatedEmployees)) {
      customWarningAlert({
        title: "Open Shifts Alert",
        descriptions: [
          "There is accepted open shift(s) in current schedule period.",
        ],
      });
      return;
    }

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };

    const redirectToMyRoster = () => {
      redirect({
        pageName: PAGE_NAMES.scheduleViewLocation,
        query: {
          "location-id": locationID,
          date: periodStartDate,
        },
      });
    };
    updateFields(["Employees"], updatedRoster, roster, [
      "PublishedAllocations",
    ]);
    redirectToMyRoster();
  };

  const sendSolutionToMyRoster = (selectedRosterSolution) => {
    isMainStream
      ? sendSolutionToMyRosterForSchedule(selectedRosterSolution)
      : sendSolutionToMyRosterForRoster(selectedRosterSolution);
  };

  const deleteSolution = (solutionID) => {
    setSelectedSolution(0);
    const solutionsToDeleteById = [];
    solutionsToDeleteById.push(solutionID);
    rosteringSolutions.forEach((sol) => {
      if (sol.rerosteredSolutionID === solutionID) {
        solutionsToDeleteById.push(sol.id);
        localStorage.removeItem(`solutionLoadingProgress_${sol.id}`);
      }
    });
    deleteRosterSolutions(solutionsToDeleteById);
    localStorage.removeItem(`solutionLoadingProgress_${solutionID}`);
  };

  if (!isPaidPlan) {
    return <SolutionsLocked />;
  }

  if (selectableSolutions.length === 0 || statuses.length === 0) {
    if (plan === PLAN.COORDINATOR) {
      return (
        <div className={styles.container}>
          <NotAccessibleView />
        </div>
      );
    }
    return (
      <div className={styles.container}>
        Please press &quot;Generate Roster&quot; to build a roster
      </div>
    );
  }

  const TopControllerComponent = (
    <div className={styles.topController}>
      <AreaFilter
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        onMenuClose={saveAreaFilter}
        defaultValue={initialAreaFilterValue}
      />
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"solutions"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  const getDeleteSolutionBtn = () => {
    if (selectedRosterSolution) {
      return () => (
        <DeleteSolutionButton
          deleteSolution={() => deleteSolution(selectedRosterSolution.id)}
          solveFinished={selectedRosterSolution.status.startsWith("Solved")}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      {isLoadingBarDisplayed && (
        <GenerateLoadingBar
          percentage={solutionLoadingProgress}
          handleClose={handleHideLoadingBar}
        />
      )}
      <SolutionGrid
        locationID={locationID}
        startDate={startDate}
        solutionData={solutionData}
        demandData={demandData}
        failedEmployeeData={failedEmployeeData}
        employees={employees}
        skills={skills}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        taskBlocks={taskBlocks}
        subTasks={subTasks}
        areas={areas}
        setGridApiToParent={setGridApi}
        solutionColumns={solutionColumns}
        gridApi={gridApi}
        getSolutionsList={getSolutionsList}
        getDeleteSolutionBtn={getDeleteSolutionBtn}
        getPrintSolutionBtns={getPrintSolutionBtns}
        longestStr={longestStr}
        selectedSolutionData={selectedRosterSolution}
        colorCodes={colorCodes}
        sendSolutionToMyRoster={sendSolutionToMyRoster}
        exportToCsv={exportToCsv}
        exportToExcel={exportToExcel}
        customTopComponent={TopControllerComponent}
        isScheduleView={!isRoster}
        roster={roster}
        customKeywordsData={customKeywordsData}
        comparisonData={comparisonData}
        isShiftView={isShiftView}
        toggleShiftView={toggleShiftView}
        rules={rules}
        location={location}
        isMainStream={isMainStream}
        periodStartDate={periodStartDate}
        periodFinishDate={periodFinishDate}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        rosterName={name}
      />
      <SolutionAnalysis
        locationID={locationID}
        rosterID={rosterID}
        isMainStream={isMainStream}
        periodStartDate={periodStartDate}
        selectedDemand={selectedDemand}
        setSelectedDemand={setSelectedDemand}
        demands={demandsAI}
        demandData={demandData}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        skills={skills}
        employeeSolution={selectedRosterSolution.employees}
        demandSolution={selectedRosterSolution.demands}
        ruleSolution={selectedRosterSolution.rules}
        startDate={startDate}
        numDays={numDays}
        locationNumDays={
          location.defaultNumDays ? location.defaultNumDays : numDays
        }
        failedEmployeeData={failedEmployeeData}
        employeesShiftHours={employeesShiftHours}
        customKeywordsData={customKeywordsData}
        locationStartDate={location.startDate}
        comparisonData={comparisonData}
        namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      />
    </div>
  );
};

const parseSolutionToFailedDataByIssue = (solution) => {
  const possibleExplanationData = [];
  const employeesWithFixedShiftsViolation = [];
  const employeesWithHistoryViolation = [];

  const issues = {};

  const employees = solution.employees;

  if (!employees) {
    return null;
  }

  for (let i = 0; i < employees.length; i++) {
    const employeeData = employees[i];
    const {
      employeeID: suffixedEmployeeID,
      name,
      days,
      brokenRuleCombos,
      possibleExplanation,
    } = employeeData;

    let hasFixedShiftsConflict = false;
    if (suffixedEmployeeID.endsWith("_fixed")) {
      hasFixedShiftsConflict = true;
    }

    const employeeID = hasFixedShiftsConflict
      ? removeCustomSuffix(suffixedEmployeeID, "_fixed")
      : removeCustomSuffix(suffixedEmployeeID, "_rules");

    if (hasFixedShiftsConflict) {
      employeesWithFixedShiftsViolation.push({ id: employeeID, name, days });
    }

    if (!brokenRuleCombos || brokenRuleCombos.length === 0) {
      continue;
    }

    let key = "";
    const brokenRulesSets = [];
    for (const rulesSet of brokenRuleCombos) {
      const { rules } = rulesSet;
      const brokenRules = [];

      for (const rule of rules) {
        if (rule === "History") {
          employeesWithHistoryViolation.push({ id: employeeID, name });
          continue;
        }
        key += `${rule};`;

        if (rule !== "No patterns were able to be generated.") {
          brokenRules.push(rule);
        }
      }

      if (brokenRules.length > 0) {
        brokenRulesSets.push(brokenRules);
      }
    }

    if (!issues[key]) {
      issues[key] = {
        employees: [],
        brokenRulesSets,
      };
    }

    issues[key].employees.push({
      id: employeeID,
      name,
    });

    if (possibleExplanation) {
      possibleExplanationData.push({
        employeeID,
        employeeName: name,
        possibleExplanation: possibleExplanation.split(
          "Possible explanation (beta): "
        )[1],
      });
    }
  }

  return {
    issues,
    possibleExplanationData,
    employeesWithFixedShiftsViolation,
    employeesWithHistoryViolation,
  };
};

const removeSpacesFromCommaSeparatedList = (commaSeparatedList) => {
  return commaSeparatedList.split(", ").join(",");
};

function compareRosterSolutions(
  comparisonSolution,
  selectedRosterSolution,
  numDays
) {
  if (
    !comparisonSolution ||
    !selectedRosterSolution ||
    !selectedRosterSolution.employees ||
    selectedRosterSolution.status === "Roster failed"
  ) {
    return null;
  }

  return comparisonSolution.employees.map((employee, eIndex) => {
    const id = employee.employeeID;
    const daysDifferences = [];

    for (let dayIndex = 0; dayIndex < numDays; dayIndex++) {
      const isDifferent =
        comparisonSolution.employees[eIndex].days[dayIndex] !==
        selectedRosterSolution.employees[eIndex].days[dayIndex];
      daysDifferences.push({
        isDifferent,
        oldAllocation: comparisonSolution.employees[eIndex].days[dayIndex],
      });
    }

    return { id, days: daysDifferences };
  });
}

const getNameBasedDemand = (
  demand,
  areas,
  shifts,
  shiftGroups,
  tasks,
  skills
) => {
  const nameBasedDemand = JSON.parse(JSON.stringify(demand));

  nameBasedDemand.areas = convertShortIDsToEntityNames(
    strToArrCommaSeparated(demand.areas),
    areas
  ).join(",");
  nameBasedDemand.shifts = convertShortIDsToEntityNames(
    strToArrCommaSeparated(demand.shifts),
    [...shifts, ...shiftGroups]
  ).join(",");
  nameBasedDemand.tasks = convertShortIDsToEntityNames(
    strToArrCommaSeparated(demand.tasks),
    tasks
  ).join(",");
  nameBasedDemand.skills = convertShortIDsToEntityNames(
    strToArrCommaSeparated(demand.skills),
    skills
  ).join(",");

  return nameBasedDemand;
};

export default ScheduleSolutionsDataContainer;
