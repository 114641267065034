import { useEffect, useMemo } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../features/allRosters/components/NotFoundPage/NotFoundPage";
import { useRouteQuery } from "../hooks/useRouteQuery";
import TimesheetView from "../features/locations/components/Timesheet/TimesheetView";
import ScheduleViewRoutes from "./ScheduleViewRoutes";
import ClassicViewRoutes from "./ClassicViewRoutes";
import AdminRedirectRoute from "./AdminRedirectRoute";
import { HEADER_TYPES } from "../components/elements/Header/Header";
import RequestsContainer from "../features/locations/components/RequestsView/RequestsContainer";
import GlobalPreferencesContainer from "../features/locations/components/PreferencesView/GlobalPreferencesContainer";
import Location from "../features/locations/components/Location/Location";
import { PAGE_NAMES } from "../utils/routeUtils/redirect";
import { getFieldsFromLocation } from "../utils/queryUtils/locationDataGetters";
import { useLocationQuery } from "../hooks/modelQueryHooks/useLocationQuery";
import LoadingPage from "../features/loading/components/LoadingPage/LoadingPage";
import { useQueryClient } from "@tanstack/react-query";
import {
  subscribeGlobalEmployeeCreate,
  subscribeGlobalEmployeeDelete,
  subscribeRosterCreate,
} from "../utils/queryUtils/observers";
import {
  handleGlobalEmployeeCreateSubscription,
  handleRosterCreateSubscription,
  handleGlobalEmployeeDeleteSubscription,
} from "../utils/queryUtils/generalQueryUtil";
import GlobalFixedShiftsContainer from "../features/locations/components/FixedShiftsView/GlobalFixedShiftsContainer";
import { Route } from "react-router";
import LandscapePrompt from "../components/elements/LandscapeEnforcer/LandscapeEnforcer";
import { isMobileViewOrApp } from "../globalStore/deviceInfoStore";
import { useUserStore } from "../globalStore/appStore";
import { useSettingsModelQuery } from "../hooks/modelQueryHooks/useSettingsModelQuery";

const LocationRoutes = ({
  toggleCreateLocationModal,
  locations,
  deleteLocation,
  handleCountSeatsTaken,
}) => {
  const { user } = useUserStore();
  const queryClient = useQueryClient();
  const { url } = useRouteMatch();
  const routeQuery = useRouteQuery();
  const periodStartDate = routeQuery.get("date");
  const locationIDFromQuery = routeQuery.get("location-id");
  const isMobile = isMobileViewOrApp();

  const { isLoading: isSettingsLoading } = useSettingsModelQuery();
  const { location, globalEmployees, customKeywordsData, isQueryLoading } =
    useLocationQuery(locations, "", true);

  const { locationID, isScheduleView } = useMemo(
    () => getFieldsFromLocation(location),
    [location]
  );

  useEffect(() => {
    if (locationID) {
      handleCountSeatsTaken(user);
    }
  }, [locationID, user, handleCountSeatsTaken]);

  useEffect(() => {
    if (locationID) {
      const globalEmployeeCreateSub = subscribeGlobalEmployeeCreate(
        (data) =>
          handleGlobalEmployeeCreateSubscription(
            queryClient,
            ["location", locationID],
            data
          ),
        locationID
      );

      const globalEmployeeDeleteSub = subscribeGlobalEmployeeDelete(
        (data) =>
          handleGlobalEmployeeDeleteSubscription(
            queryClient,
            ["location", locationID],
            data
          ),
        locationID
      );

      const rosterCreateSub = subscribeRosterCreate((data) => {
        handleRosterCreateSubscription(
          queryClient,
          ["location", locationID],
          data
        );
      }, locationID);

      return async () => {
        // (await globalEmployeesUpdateSub)?.unsubscribe();
        (await globalEmployeeCreateSub)?.unsubscribe();
        (await globalEmployeeDeleteSub)?.unsubscribe();
        // (await locationUpdateSub)?.unsubscribe();
        (await rosterCreateSub)?.unsubscribe();
      };
    }
  }, [locationID, queryClient]);

  const headerType = isScheduleView
    ? HEADER_TYPES.scheduleViewLocation
    : HEADER_TYPES.classicLocation;

  const locationExistsButNotLoaded = useMemo(() => {
    if (!locationIDFromQuery) {
      return locations.length > 0 && !location;
    }

    return locations.find((loc) => loc.id === locationIDFromQuery) && !location;
  }, [locations, location, locationIDFromQuery]);

  if (isQueryLoading || locationExistsButNotLoaded) {
    return <LoadingPage />;
  }

  if (locations.length === 0) {
    return <NotFoundPage message="No locations" />;
  }

  if (!location) {
    return (
      <NotFoundPage
        message="Invalid Location URL"
        redirectPageName={PAGE_NAMES.main}
      />
    );
  }

  return (
    <>
      {isMobile && <LandscapePrompt active={isMobile} />}
      <Switch>
        <Route path={`${url}/admin-redirect`}>
          <AdminRedirectRoute location={location} baseUrl={url} />
        </Route>
        <Route path={`${url}/schedule-view`}>
          <ScheduleViewRoutes
            location={location}
            locations={locations}
            headerType={headerType}
            toggleCreateLocationModal={toggleCreateLocationModal}
            customKeywordsData={customKeywordsData}
            isSettingsLoading={isSettingsLoading}
          />
        </Route>
        <Route path={`${url}/classic-view`}>
          <ClassicViewRoutes
            toggleCreateLocationModal={toggleCreateLocationModal}
            locations={locations}
            locationID={locationID}
            headerType={headerType}
            location={location}
            globalEmployees={globalEmployees}
            customKeywordsData={customKeywordsData}
          />
        </Route>
        <Route path={`${url}/settings`}>
          <Location
            toggleCreateLocationModal={toggleCreateLocationModal}
            locations={locations}
            locationID={locationID}
            headerType={headerType}
            deleteLocation={deleteLocation}
            location={location}
            globalEmployees={globalEmployees}
            customKeywordsData={customKeywordsData}
          />
        </Route>
        {isScheduleView && (
          <Route path={`${url}/timesheet`}>
            <TimesheetView
              toggleCreateLocationModal={toggleCreateLocationModal}
              locations={locations}
              location={location}
              locationID={locationID}
              headerType={headerType}
              periodStartDate={periodStartDate}
              customKeywordsData={customKeywordsData}
            />
          </Route>
        )}
        {isScheduleView && (
          <Route path={`${url}/requests`}>
            <RequestsContainer
              toggleCreateLocationModal={toggleCreateLocationModal}
              locations={locations}
              locationID={locationID}
              headerType={headerType}
              periodStartDate={periodStartDate}
              globalEmployees={globalEmployees}
              customKeywordsData={customKeywordsData}
              location={location}
            />
          </Route>
        )}
        {isScheduleView && (
          <Route path={`${url}/preferences`}>
            <GlobalPreferencesContainer
              toggleCreateLocationModal={toggleCreateLocationModal}
              locations={locations}
              location={location}
              locationID={locationID}
              headerType={headerType}
              periodStartDate={periodStartDate}
              warnings={null}
              customKeywordsData={customKeywordsData}
              globalEmployees={globalEmployees}
            />
          </Route>
        )}
        {isScheduleView && (
          <Route path={`${url}/fixedshifts`}>
            <GlobalFixedShiftsContainer
              toggleCreateLocationModal={toggleCreateLocationModal}
              locations={locations}
              location={location}
              locationID={locationID}
              headerType={headerType}
              periodStartDate={periodStartDate}
              warnings={null}
              customKeywordsData={customKeywordsData}
              globalEmployees={globalEmployees}
            />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default LocationRoutes;
