import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownButton from "../DropdownButton/DropdownButton";
import styles from "./AddRowButton.module.css";
import { isProcessingBatchUpdate } from "../../../utils";

const AddRowButton = ({
  addSingle,
  addOptions,
  rowName,
  partialButtonTestId = "",
  showDropdown = true,
  postAddAction = null,
}) => (
  <div className={styles.combinationBtn}>
    <button
      className={`${styles.partialButton} ${
        showDropdown ? null : styles.noDropdownBtn
      }`}
      onClick={
        isProcessingBatchUpdate
          ? () => {}
          : () => {
              addSingle();
              if (postAddAction) {
                postAddAction();
              }
            }
      }
      data-tour={partialButtonTestId ? partialButtonTestId : "add-row-btn"}
      data-testid={partialButtonTestId ? partialButtonTestId : "add-row-btn"}
    >
      <FontAwesomeIcon icon={faCirclePlus} />
      <span className={styles.dropdownText}>Add {rowName}</span>
    </button>
    {showDropdown && (
      <DropdownButton
        color="#219ec9"
        listItems={addOptions}
        isPartialButton={true}
      ></DropdownButton>
    )}
  </div>
);

export default AddRowButton;
