import Header from "../../../elements/Header/Header";
import styles from "./withHeader.module.css";

const withHeader = (Page) => (props) => {
  const {
    headerType,
    toggleCreateLocationModal,
    locations,
    periodStartDate,
    hideLocationCreator = false,
    isShareRosterPage = false,
    location,
    globalEmployees,
    isSnapshot,
    isMainstream,
    rosterID,
  } = props; // required props for header
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header
          headerType={headerType}
          toggleCreateLocationModal={toggleCreateLocationModal}
          locations={locations}
          periodStartDate={periodStartDate}
          hideLocationCreator={hideLocationCreator}
          isShareRosterPage={isShareRosterPage}
          location={location}
          globalEmployees={globalEmployees}
          isSnapshot={isSnapshot}
          isMainstream={isMainstream}
          rosterID={rosterID}
        />
      </div>
      <div className={styles["page-container"]}>
        <Page {...props} />
      </div>
    </div>
  );
};

export default withHeader;
