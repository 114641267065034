import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  updateGlobalEmployeeModels,
  updateLocationModel,
} from "../../utils/queryUtils/locationQuery";
import { useDataMutation } from "./useDataMutation";
import { addCompletedOnboardingTasks } from "../../utils/queryUtils/locationDataGetters";
import { PLAN } from "../../features/auth/service/auth";
import { useUserStore } from "../../globalStore/appStore";

const ACTION_TYPES = Object.freeze({
  createGlobalEmployees: "create-global-employees",
  updateGlobalEmployees: "update-global-employees",
  updateLocation: "update-location",
  updateLocationAndGlobalEmployees: "update-location-and-global-employees",
});

export function useLocationMutation(location) {
  const queryClient = useQueryClient();
  const { plan } = useUserStore();
  const locationID = location ? location.id : null;

  const applyUpdatedLocationToAllLocations = useCallback(
    (updatedLocation) => {
      queryClient.setQueryData(["allLocations"], (prevAllLocations) => {
        const updatedAllLocations = prevAllLocations.map((item) => {
          if (item.id === updatedLocation.id) {
            return updatedLocation;
          }
          return item;
        });
        return updatedAllLocations;
      });
    },
    [queryClient]
  );

  const updateLocationHandler = useCallback(
    async (mutateFnParam) => {
      const shouldUpdateGlobalEmployees =
        mutateFnParam.actionType ===
        ACTION_TYPES.updateLocationAndGlobalEmployees;
      if (shouldUpdateGlobalEmployees) {
        await updateGlobalEmployeeModels(mutateFnParam.updatedGlobalEmployees);
      }

      const updatedLocation = mutateFnParam.updatedLocation;
      const updatedFieldNames = mutateFnParam.updatedFieldNames;
      const updatedLocationFields = {};
      for (const fieldName of updatedFieldNames) {
        updatedLocationFields[fieldName] = updatedLocation[fieldName];
      }

      let updatedLocationModel = await updateLocationModel(
        locationID,
        updatedLocationFields
      );
      return updatedLocationModel;
    },
    [locationID]
  );

  const updateGlobalEmployeesHandler = useCallback(
    async (mutateFnParam) => {
      const { updatedGlobalEmployees } = mutateFnParam;
      const updated = await updateGlobalEmployeeModels(updatedGlobalEmployees);
      return {
        ...location,
        Employees: {
          items: [...updated],
        },
      };
    },
    [location]
  );

  const mutation = useDataMutation({
    queryKey: ["location", locationID],
    queryClient,
    mutationFn: (mutateFnParam) => {
      const actionType = mutateFnParam.actionType;
      switch (actionType) {
        case ACTION_TYPES.updateLocation:
          return updateLocationHandler(mutateFnParam);
        case ACTION_TYPES.updateGlobalEmployees:
          return updateGlobalEmployeesHandler(mutateFnParam);
      }
    },
    getExpectedResultingData: (mutateFnParam) => mutateFnParam.updatedLocation,
    onSuccess: (updatedData, mutateFnParam) => {
      if (!mutateFnParam) {
        return;
      }
      if (mutateFnParam.onSuccess) {
        mutateFnParam.onSuccess(mutateFnParam.updatedLocation);
      }
    },
  });

  const updateLocationFields = useCallback(
    (updatedFieldNames, updatedLocation, onSuccess = null) => {
      mutation.mutate({
        actionType: ACTION_TYPES.updateLocation,
        updatedFieldNames,
        updatedLocation,
        onSuccess: (updatedLocation) => {
          if (updatedFieldNames.includes("name")) {
            applyUpdatedLocationToAllLocations(updatedLocation);
          }
          if (onSuccess) {
            onSuccess(updatedLocation);
          }
        },
      });
    },
    [mutation, applyUpdatedLocationToAllLocations]
  );

  const updateGlobalEmployees = useCallback(
    (updatedGlobalEmployees, modifiedFields, updatedLocation) => {
      let strippedEmployees = updatedGlobalEmployees.map((employee) => {
        let strippedEmployee = { id: employee.id };

        modifiedFields.forEach((field) => {
          if (Object.prototype.hasOwnProperty.call(employee, field)) {
            strippedEmployee[field] = employee[field];
          }
        });

        return strippedEmployee;
      });

      mutation.mutate({
        actionType: ACTION_TYPES.updateGlobalEmployees,
        updatedGlobalEmployees: strippedEmployees,
        updatedLocation: updatedLocation,
        modifiedFields,
      });
    },
    [mutation]
  );

  const markOnboardingTasksAsCompleted = (taskIDs) => {
    if (
      plan === PLAN.AI ||
      plan === PLAN.COORDINATOR ||
      plan === PLAN.COLLABORATOR
    )
      return;
    const updatedLocation = addCompletedOnboardingTasks(location, taskIDs);
    updateLocationFields(["completedOnboardingTasks"], updatedLocation);
  };

  return {
    isMutationLoading: mutation.isLoading,
    updateLocationFields,
    updateGlobalEmployees,
    markOnboardingTasksAsCompleted,
  };
}
