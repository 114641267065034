import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./RequestsLocked.module.css";
import requestsModalImg from "../../../../assets/images/leaveRequestsModal.png";
import employeeViewImg from "../../../../assets/images/employeeView.png";

const ModalImg = (
  <div className={styles.imgContainer}>
    <img src={requestsModalImg} className={styles.img} alt="requests view" />
  </div>
);

const RequestsLocked = () => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.left}>
        <h1 className={styles.title}>Leave Requests</h1>
        <UpgradeBanner
          title={"Unlock Leave Requests with RosterLab AI plan!"}
          descriptions={[
            "Full control of the leave requests. Easy manage approvals through the RosterLab system.",
          ]}
          customComponent={ModalImg}
          showBookDemoButton={true}
        />
      </div>
      <div className={styles.right}>
        <img
          src={employeeViewImg}
          className={styles.employeeViewImg}
          alt="employee view"
        />
        <p className={styles.employeeViewImgDesc}>
          No more spending time on collecting employees&apos; requests!
          RosterLab AI plan syncs all employees&apos; requests once they put
          them through our employee mobile app.
        </p>
      </div>
    </div>
  </div>
);

export default RequestsLocked;
