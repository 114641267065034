import { useMemo, useState } from "react";
import styles from "./MobileOpenShiftsPage.module.css";
import { filterPastOpenShifts } from "../../../../../utils/queryUtils/locationDataGetters";
import OpenShiftItem from "../../OpenShiftItem/OpenShiftItem";
import { PLAN } from "../../../../auth/service/auth";
import UpgradePlanComponent from "../../../../../components/elements/UpgradePlanComponent/UpgradePlanComponent";

const MobileOpenShiftsPage = ({
  myOpenShifts,
  updateOpenShifts,
  userEmployee,
  plan,
}) => {
  const [shouldShowPastOpenShifts, setShouldShowPastOpenShifts] =
    useState(false);

  const displayedOpenShifts = useMemo(
    () =>
      shouldShowPastOpenShifts
        ? myOpenShifts
        : filterPastOpenShifts(myOpenShifts),
    [myOpenShifts, shouldShowPastOpenShifts]
  );

  if (plan === PLAN.LITE) {
    return <UpgradePlanComponent />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <p className={styles.title}>Open Shifts</p>
          <button
            className={styles.toggleHistoryButton}
            onClick={() =>
              setShouldShowPastOpenShifts(!shouldShowPastOpenShifts)
            }
          >
            {shouldShowPastOpenShifts ? "Hide History" : "Show History"}
          </button>
        </div>
        <div className={styles.openShifts}>
          {displayedOpenShifts.map((openShift, idx) => {
            return (
              <OpenShiftItem
                key={openShift.id}
                openShift={openShift}
                shouldAddTopSeparator={idx === 0}
                updateOpenShifts={updateOpenShifts}
                employeeID={userEmployee.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MobileOpenShiftsPage;
