export const createMinimalContextMenu = () => {
  return ["copy", "paste"];
};

export const createModestContextMenu = () => {
  return ["copy", "paste", "copyWithHeaders"];
};

export const createContextMenuWithDelete = (removeRow) => {
  return [
    {
      name: "Delete selected rows",
      action: () => {
        removeRow();
      },
    },
    "copy",
    "paste",
    "copyWithHeaders",
  ];
};

export const createBasicContextMenu = () => {
  return ["export", "copy", "paste", "copyWithHeaders"];
};
