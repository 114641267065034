import { DateTime } from "..";

export function getDefaultRedirectUrl(isUserAdmin, isMobile) {
  if (!isUserAdmin || isMobile) {
    return `/employee-view`;
  }
  return `/locations/admin-redirect`;
}

export function getAllSearchParams(searchhParams) {
  const params = {};
  searchhParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

export function getDateStringFromParam(dateParam) {
  let dateString = null;
  try {
    if (dateParam) {
      dateString = new DateTime(dateParam).toFormat("AWS");
    }
  } catch (error) {
    console.log(error);
  }
  return dateString;
}

export function createScheduleViewQueryParam(rosterInView) {
  const locationID = rosterInView.locationID;
  const rosterID = rosterInView.id;
  const periodStartDate = rosterInView.startDate;
  const isSnapshot = rosterInView.isSnapshot;

  let query = `?location-id=${locationID}`;
  const snapshostQuery = `&snapshot-id=${rosterID}`;
  const periodQuery = `&date=${periodStartDate}`;

  if (isSnapshot) {
    query += snapshostQuery;
  } else {
    query += periodQuery;
  }

  return query;
}

export function createPrototypeViewQueryParam(rosterID) {
  return `?roster-id=${rosterID}`;
}
