import EmployeeAppMobileHeader from "../EmployeeAppMobileHeader/EmployeeAppMobileHeader";
import styles from "./EmployeeAppMobileWrapper.module.css";
import EmployeeAppMobileFooter from "../EmployeeAppMobileFooter/EmployeeAppMobileFooter";
import React from "react";

const EmployeeAppMobileWrapper = ({
  children,
  switchableContentViewTypes,
  toggleCalendarViewType,
  setContentViewType,
  setSelectedDate,
  currentViewData,
  shouldShowCalendarTypeSwitcher,
  location,
}) => {
  const { selectedDate, contentViewType, calendarViewType } = currentViewData;

  const childrenWithProps = React.cloneElement(children, {
    selectedDate,
    contentViewType,
    calendarViewType,
  });

  return (
    <div className={styles.container}>
      <EmployeeAppMobileHeader
        location={location}
        contentViewType={contentViewType}
        setContentViewType={setContentViewType}
        calendarViewType={calendarViewType}
        toggleCalendarViewType={toggleCalendarViewType}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        switchableContentViewTypes={switchableContentViewTypes}
        shouldShowCalendarTypeSwitcher={shouldShowCalendarTypeSwitcher}
      />
      <div className={styles.body}>{childrenWithProps}</div>
      <EmployeeAppMobileFooter
        locationID={location.id}
        selectedDate={selectedDate}
        calendarViewType={calendarViewType}
      />
    </div>
  );
};

export default EmployeeAppMobileWrapper;
