import { KEYWORD_OFF } from "../../../constants/keywords";
import {
  DateTime,
  roundFloatToNearestQuarter,
  startTimeFinishTimeDifferenceGetter,
} from "../../../utils";
import { getDayColumnNameRegex } from "../../../utils/generalUtils/regex";
import { extractEntityShortIdsFromAllocation } from "../../../utils/modelUtils/allocation";
import {
  allocationFulfilsPreference,
  allocationFulfilsShiftGroup,
} from "../../rosterProblems/service/preferencesAndFixedShifts";

export const findMinMaxDemandsPairByShiftName = (
  shiftDemands,
  shiftShortId,
  skillShortId,
  shiftNameKey,
  skillNameKey
) => {
  const minMaxDemandsPair = {
    demandStartTime: null,
    demandFinishTime: null,
    shiftShortId,
    skillShortId,
    minDemandValues: null,
    maxDemandValues: null,
    demandDuration: null,
  };

  const selectedDemand = shiftDemands.find(
    (dem) =>
      dem[shiftNameKey] === shiftShortId &&
      ((!skillShortId && !dem[skillNameKey]) ||
        dem[skillNameKey] === skillShortId)
  );

  if (!selectedDemand) {
    return minMaxDemandsPair;
  }

  const selectedDemandType = selectedDemand.type;
  const selectedDemandStartTime = selectedDemand.demandStartTime;
  const selectedDemandFinishTime = selectedDemand.demandFinishTime;
  const selectedDemandValues = selectedDemand.values;
  const selectedDemandDuration = DateTime.getTimeDifferenceInHours(
    selectedDemandStartTime,
    selectedDemandFinishTime
  );

  minMaxDemandsPair.demandStartTime = selectedDemandStartTime;
  minMaxDemandsPair.demandFinishTime = selectedDemandFinishTime;
  minMaxDemandsPair.demandDuration = selectedDemandDuration;

  if (selectedDemandType === "Target") {
    minMaxDemandsPair.minDemandValues = selectedDemandValues;
    minMaxDemandsPair.maxDemandValues = selectedDemandValues;
  } else if (selectedDemandType === "Minimum") {
    minMaxDemandsPair.minDemandValues = selectedDemandValues;
    const pairableDemand = findPairableDemand(
      shiftDemands,
      shiftShortId,
      skillShortId,
      "Maximum",
      selectedDemandStartTime,
      selectedDemandFinishTime
    );
    if (pairableDemand) {
      minMaxDemandsPair.maxDemandValues = pairableDemand.values;
    }
  } else {
    minMaxDemandsPair.maxDemandValues = selectedDemandValues;
    const pairableDemand = findPairableDemand(
      shiftDemands,
      shiftShortId,
      skillShortId,
      "Minimum",
      selectedDemandStartTime,
      selectedDemandFinishTime
    );
    if (pairableDemand) {
      minMaxDemandsPair.minDemandValues = pairableDemand.values;
    }
  }

  return minMaxDemandsPair;

  function findPairableDemand(
    shiftDemands,
    shiftShortId,
    skillShortId,
    type,
    startTime,
    finishTime
  ) {
    const demandFound = shiftDemands.find(
      (dem) =>
        dem.shiftShortId === shiftShortId &&
        (!skillShortId || dem.skillShortId === skillShortId) &&
        dem.type === type &&
        dem.demandStartTime === startTime &&
        dem.demandFinishTime === finishTime
    );

    if (!demandFound) {
      return null;
    }

    return demandFound;
  }
};

export const countShiftsInRowByName = (
  row,
  shiftShortId,
  shortIdsToEntityNamesDicts
) => {
  let totalShiftCount = 0;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }
    const { shiftShortId: shiftShortIdInCell } =
      extractEntityShortIdsFromAllocation(
        row[fieldName],
        shortIdsToEntityNamesDicts
      );

    if (shiftShortId === shiftShortIdInCell) {
      totalShiftCount++;
    }
  }
  return totalShiftCount;
};

export const countShiftsInShiftGroupInRowByName = (
  row,
  shiftGroup,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  let totalShiftCount = 0;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }

    const cellValue = row[fieldName];

    const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
      extractEntityShortIdsFromAllocation(
        cellValue,
        shortIdsToEntityNamesDicts
      );

    if (
      allocationFulfilsShiftGroup(
        shiftGroup,
        areaShortId,
        shiftShortId,
        taskShortId || enumeratedTaskShortId,
        row.skills,
        customKeywordsUtilObj
      )
    ) {
      totalShiftCount++;
    }
  }
  return totalShiftCount;
};

export const getTotalShiftCountsForAnEmployee = (
  row,
  uncountableShiftKeywords,
  shortIdsToEntityNamesDicts
) => {
  let count = 0;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }

    const { shiftShortId } = extractEntityShortIdsFromAllocation(
      row[fieldName],
      shortIdsToEntityNamesDicts
    );

    if (shiftShortId && !uncountableShiftKeywords.includes(shiftShortId)) {
      count++;
    }
  }
  return count;
};

export const getTotalShiftHoursByShiftName = (
  row,
  shiftShortId,
  employeesShiftHours,
  shortIdsToEntityNamesDicts
) => {
  let totalHours = 0;
  const employeeID = row.id;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }

    const { shiftShortId: shiftShortIdInCell } =
      extractEntityShortIdsFromAllocation(
        row[fieldName],
        shortIdsToEntityNamesDicts
      );

    if (shiftShortIdInCell === shiftShortId) {
      totalHours =
        totalHours + employeesShiftHours[employeeID][shiftShortIdInCell];
    }
  }
  return totalHours;
};

export const getTotalShiftHoursByShiftGroup = (
  row,
  shiftGroup,
  employeesShiftHours,
  customKeywordsUtilObj,
  shortIdsToEntityNamesDicts
) => {
  let totalHours = 0;
  const employeeID = row.id;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }

    const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
      extractEntityShortIdsFromAllocation(
        row[fieldName],
        shortIdsToEntityNamesDicts
      );

    if (
      allocationFulfilsShiftGroup(
        shiftGroup,
        areaShortId,
        shiftShortId,
        taskShortId || enumeratedTaskShortId,
        row.skills,
        customKeywordsUtilObj
      ) &&
      employeesShiftHours[employeeID][shiftShortId]
    ) {
      totalHours =
        totalHours +
        roundFloatToNearestQuarter(
          employeesShiftHours[employeeID][shiftShortId]
        );
    }
  }

  return totalHours;
};

export const getTotalShiftHoursForAnEmployee = (
  row,
  employeesShiftHours,
  shortIdsToEntityNamesDicts
) => {
  let totalHours = 0;
  const employeeID = row.id;
  for (const fieldName in row) {
    const dayColNameRegex = getDayColumnNameRegex();
    if (!fieldName.match(dayColNameRegex)) {
      continue;
    }

    const { shiftShortId } = extractEntityShortIdsFromAllocation(
      row[fieldName],
      shortIdsToEntityNamesDicts
    );

    if (shiftShortId && employeesShiftHours[employeeID][shiftShortId]) {
      totalHours =
        totalHours +
        roundFloatToNearestQuarter(
          employeesShiftHours[employeeID][shiftShortId]
        );
    }
  }
  return totalHours;
};

export const getTotalWeekendsForAnEmployee = (row, startDate, numDays) => {
  let numWeekends = 0;

  const startDateTime = new DateTime(startDate);

  for (let d = 0; d < numDays - 1; d++) {
    const date1 = new DateTime(startDateTime).addDays(d);

    if (date1.getDayOfWeek("ddd") === "Sat") {
      const alloc1 = row["d" + (d + 1).toString()];
      const alloc2 = row["d" + (d + 2).toString()];

      if (
        (alloc1 === "" || alloc1 === KEYWORD_OFF) &&
        (alloc2 === "" || alloc2 === KEYWORD_OFF)
      ) {
        numWeekends++;
      }
    }
  }

  return numWeekends;
};

export const getTotalPreferencesMetForAnEmployee = (
  row,
  numDays,
  employee,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  severityToCount,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  let totalPreferencesMet = 0;
  let totalPreferences = 0;

  for (let d = 0; d < numDays; d++) {
    let preference = employee["Days"][d];
    if (preference === "") {
      const recurringLength = employee["DaysRecurring"].length;
      preference = employee["DaysRecurring"][d % recurringLength];
    }

    let fixedShift = employee["Allocations"][d];
    if (fixedShift === "") {
      const recurringLength = employee["AllocationsRecurring"].length;
      fixedShift = employee["AllocationsRecurring"][d % recurringLength];
    }

    const strippedPreference = preference.replace(/[!?]/, "");

    if (preference === "") continue;

    const allocationFulfilsPref = allocationFulfilsPreference(
      row["d" + (d + 1).toString()],
      shifts,
      shiftGroups,
      tasks,
      taskBlocks,
      strippedPreference,
      row.skills,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj
    );

    if (
      !allocationFulfilsPref &&
      fixedShift !== "" &&
      fixedShift !== strippedPreference &&
      (!shifts.map((shift) => shift.name).includes(strippedPreference) ||
        !allocationFulfilsPreference(
          strippedPreference,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          fixedShift,
          row.skills,
          shortIdsToEntityNamesDicts,
          customKeywordsUtilObj
        )) &&
      (!shifts.map((shift) => shift.name).includes(fixedShift) ||
        !allocationFulfilsPreference(
          fixedShift,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          strippedPreference,
          row.skills,
          shortIdsToEntityNamesDicts,
          customKeywordsUtilObj
        ))
    ) {
      continue;
    }

    const severity = preference.endsWith("!")
      ? "Critical"
      : preference.endsWith("?")
      ? "High"
      : "Low";

    if (severityToCount && severityToCount !== severity) {
      continue;
    }

    totalPreferences++;
    totalPreferencesMet += allocationFulfilsPref ? 1 : 0;
  }

  return [totalPreferencesMet, totalPreferences];
};

export const getDayShiftCounts = (params, uncountableShiftKeywords) => {
  const columnKey = params.colDef.field;
  let count = 0;
  params.api.forEachNode((node) => {
    const colData = node.data[columnKey];
    if (colData && !uncountableShiftKeywords.includes(colData)) {
      count++;
    }
  });
  return count;
};

export const getDayShiftHours = (
  params,
  uncountableShiftKeywords,
  employeesShiftHours,
  shortIdsToEntityNamesDicts
) => {
  const columnKey = params.colDef.field;
  let totalHours = 0;

  params.api.forEachNode((node) => {
    const colData = node.data[columnKey];

    if (colData && !uncountableShiftKeywords.includes(colData)) {
      const { shiftShortId } = extractEntityShortIdsFromAllocation(
        colData,
        shortIdsToEntityNamesDicts
      );

      const employeeID = node.id;
      const shiftHours = roundFloatToNearestQuarter(
        employeesShiftHours[employeeID]
          ? employeesShiftHours[employeeID][shiftShortId]
          : 0
      );

      totalHours += isNaN(shiftHours) ? 0 : shiftHours;
    }
  });

  return totalHours;
};

export const getShiftGroupHoursPerColumn = (
  params,
  shiftGroups,
  uncountableShiftKeywords,
  employeesShiftHours,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const columnKey = params.colDef.field;
  let totalHours = 0;

  const shiftGroupShortId = params.data.id?.split("-")[2]?.trim();
  const shiftGroup = shiftGroups.find(
    (group) => group.shortId === shiftGroupShortId
  );

  params.api.forEachNode((node) => {
    const colData = node.data[columnKey];

    const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
      extractEntityShortIdsFromAllocation(colData, shortIdsToEntityNamesDicts);

    if (colData && !uncountableShiftKeywords.includes(colData)) {
      const employeeID = node.id;
      const shiftHours = employeesShiftHours[employeeID][shiftShortId];

      if (
        allocationFulfilsShiftGroup(
          shiftGroup,
          areaShortId,
          shiftGroup,
          taskShortId || enumeratedTaskShortId,
          node.data.skills,
          customKeywordsUtilObj
        )
      ) {
        totalHours = totalHours + roundFloatToNearestQuarter(shiftHours);
      }
    }
  });
  return totalHours;
};

export const getShiftAndSkillCountsPerColumn = (
  params,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const rawEntityData = params.data.id.split("-");
  let count = 0;

  let entityType;
  let entityName1;
  let entityName2;
  const columnKey = params.colDef.field;

  if (rawEntityData.length === 3) {
    entityType = params.data.id.split("-")[0];
    entityName1 = params.data.id.split("-")[2];
  } else {
    entityType = "pair";
    entityName1 = params.data.id.split("-")[1];
    entityName2 = params.data.id.split("-")[3];
  }

  params.api.forEachNode((node) => {
    const colData = node.data[columnKey];
    if (entityType === "skill") {
      const skill = colData.split("-")[1] ? colData.split("-")[1].trim() : null;
      if (skill === entityName1) {
        count++;
      }
    } else if (entityType === "shift") {
      if (
        allocationFulfilsPreference(
          colData,
          params.context.shifts,
          params.context.shiftGroups,
          params.context.tasks,
          params.context.taskBlocks,
          entityName1,
          node.data.skills,
          shortIdsToEntityNamesDicts,
          customKeywordsUtilObj
        )
      ) {
        count++;
      }
    } else {
      if (
        node.data.skills
          .split(",")
          .map((skill) => skill.trim())
          .includes(entityName2)
      ) {
        if (
          allocationFulfilsPreference(
            colData,
            params.context.shifts,
            params.context.shiftGroups,
            params.context.tasks,
            params.context.taskBlocks,
            entityName1,
            params.data.skills,
            shortIdsToEntityNamesDicts,
            customKeywordsUtilObj
          )
        ) {
          count++;
        }
      }
    }
  });

  return count;
};

export const getColumnSum = (params) => {
  const columnKey = params.colDef.field;
  let total = 0;
  params.api.forEachNode((node) => {
    const colData = node.data[columnKey];
    if (colData) {
      total = total + Number(colData);
    }
  });
  return total;
};

export const getTimeDifferenceColumnSum = (params) => {
  let total = 0;

  params.api.forEachNode((node) => {
    const hours = startTimeFinishTimeDifferenceGetter(
      node.data.startTime,
      node.data.finishTime,
      node.data.breakTime
    );
    if (hours && !isNaN(Number(hours))) {
      total = total + Number(hours);
    }
  });
  return total.toFixed(2);
};
