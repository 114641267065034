import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownCollapsibleSelector from "../../../../../components/elements/DropdownCollapsibleSelector/DropdownCollapsibleSelector";
import { convertSingleOptionToPropForm } from "../../../../../utils";
import styles from "./RuleBlock.module.css";
import { PLAN } from "../../../../auth/service/auth";
import DropdownCollapsibleSelectorVirtualised from "../../../../../components/elements/DropdownCollapsibleSelectorVirtualised/DropdownCollapsibleSelector";
import { useRef } from "react";
import useOutsideClick from "../../../../../hooks/useOutsideClick";

const RuleBlock = ({
  plan,
  inputWarnings,
  calculateWarnings = null,
  menuPosition = "absolute",
  ...props
}) => {
  const inputRef = useRef(null);
  const inputWarningFound = inputWarnings
    ? inputWarnings.find(({ name }) => name === props.name)
    : null;

  useOutsideClick(inputRef, () => {
    if (calculateWarnings) {
      calculateWarnings();
    }
  });

  let nounOptions = [];
  for (let i = 0; i < 4; i++) {
    let possibilities = new Set();
    if (i === 3) {
      props.rule.subrules
        .filter((subrule) => subrule.inputs[2] === props.inputs[2])
        .forEach((subrule) => possibilities.add(subrule.inputs[i]));
    } else if (i !== 3 && i !== 2) {
      props.rule.subrules
        .filter((subrule) => subrule.inputs[2] === props.inputs[2])
        .filter((subrule) => subrule.inputs[3] === props.inputs[3])
        .forEach((subrule) => possibilities.add(subrule.inputs[i]));
    } else {
      props.rule.subrules.forEach((subrule) =>
        possibilities.add(subrule.inputs[i])
      );
    }

    let nounOption = [];

    for (let possibility of possibilities) {
      if (
        !props.isRulesWithExceptionEnabled &&
        possibility === "with exception" &&
        i === 0
      ) {
        continue;
      }
      nounOption.push({
        label: possibility,
        value: possibility,
      });
    }
    nounOptions.push(nounOption);
  }

  let valueInput = null;
  if (props.canChangeDefaultValue) {
    valueInput = (
      <>
        <input
          type="text"
          ref={inputRef}
          className={`${styles["text-field"]} ${
            inputWarningFound ? styles.invalidInput : undefined
          }`}
          maxLength={256}
          name="field-3"
          data-name="Field 3"
          id="field-3"
          value={props.defaultValue}
          placeholder={
            props.initialDefaultValue ? props.initialDefaultValue : ""
          }
          onChange={(event) => props.setDefaultValue(event.target.value)}
          required
          data-testid="rule-value-input"
        />{" "}
        {props.inputs[1] === "work proportionally" && "%"}
      </>
    );
  } //{props.inputs[1] === "work proportionally" && "%"}  is a bit of a hack

  let optionalInput = null;

  if (nounOptions[3].length > 1) {
    optionalInput = (
      <div className={styles.dropdownWrapper}>
        <DropdownCollapsibleSelectorVirtualised
          defaultValue={convertSingleOptionToPropForm(props.inputs[3])}
          options={nounOptions[3]}
          onChange={(selectedInput) => {
            const selectedValue = selectedInput.value;
            props.changeRuleValue([
              props.inputs[0],
              props.inputs[1],
              props.inputs[2],
              selectedValue,
            ]);
          }}
          customWrapperStyle={{
            width: 200,
          }}
          id="rule-block-input-3"
          menuPosition={menuPosition}
        />
      </div>
    );
  }

  return (
    <div
      className={styles["rule-block"]}
      ref={props.innerRef}
      {...props.provided?.draggableProps}
      {...props.provided?.dragHandleProps}
    >
      <div className={styles["sentence-text"]}>Employees</div>
      {nounOptions[0].length !== 1 && (
        <div className={styles.dropdownWrapper}>
          <DropdownCollapsibleSelector
            defaultValue={convertSingleOptionToPropForm(props.inputs[0])}
            options={nounOptions[0]}
            onChange={(selectedInput) => {
              const selectedValue = selectedInput.value;
              props.changeRuleValue([
                selectedValue,
                props.inputs[1],
                props.inputs[2],
                props.inputs[3],
              ]);
            }}
            customWrapperStyle={{
              minWidth: 80,
            }}
            id="rule-block-input-0"
            menuPosition={menuPosition}
          />
        </div>
      )}
      {nounOptions[0].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[0][0].value}
        </span>
      )}
      {nounOptions[1].length !== 1 && (
        <div className={styles.dropdownWrapper}>
          <DropdownCollapsibleSelector
            defaultValue={convertSingleOptionToPropForm(props.inputs[1])}
            options={nounOptions[1]}
            onChange={(selectedInput) => {
              const selectedValue = selectedInput.value;
              props.changeRuleValue([
                props.inputs[0],
                selectedValue,
                props.inputs[2],
                props.inputs[3],
              ]);
            }}
            customWrapperStyle={{
              minWidth: 130,
            }}
            id="rule-block-input-1"
            menuPosition={menuPosition}
          />
        </div>
      )}
      {nounOptions[1].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[1][0].value}
        </span>
      )}
      {valueInput}
      <div className={styles["sentence-text"]}>{props.formattedName} </div>
      {nounOptions[2].length !== 1 && (
        <div className={styles.dropdownWrapper}>
          <DropdownCollapsibleSelector
            defaultValue={convertSingleOptionToPropForm(props.inputs[2])}
            options={nounOptions[2]}
            onChange={(selectedInput) => {
              const selectedValue = selectedInput.value;
              props.changeRuleValue([
                props.inputs[0],
                props.inputs[1],
                selectedValue,
                props.inputs[3],
              ]);
            }}
            customWrapperStyle={{
              minWidth: 130,
            }}
            id="rule-block-input-2"
            menuPosition={menuPosition}
          />
        </div>
      )}
      {nounOptions[2].length === 1 && (
        <span className={styles["alternative-text"]}>
          {nounOptions[2][0].value}
        </span>
      )}
      {optionalInput}
      {!props.isChecked && plan === PLAN.LITE ? (
        <FontAwesomeIcon icon={faCircleArrowUp} className={styles.unlockIcon} />
      ) : null}
      {props.removeRule && (
        <button
          className={styles["remove-rule-btn"]}
          onClick={props.removeRule}
        />
      )}
    </div>
  );
};

export default RuleBlock;
