import { useRouteMatch } from "react-router-dom";
import { CapacitorCrisp } from "@capgo/capacitor-crisp";
import styles from "./EmployeeAppMobileFooter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faInbox,
  faQuestionCircle,
  faShuffle,
} from "@fortawesome/free-solid-svg-icons";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import { getPlatform } from "../../../../../globalStore/deviceInfoStore";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";

const EmployeeAppMobileFooter = ({
  locationID,
  selectedDate,
  calendarViewType,
}) => {
  const { url } = useRouteMatch();
  const currentPage = url.split("/")[3];

  const redirectTo = useRedirect();

  const redirectToPage = (
    page,
    date,
    targetContentViewType = null,
    calendarType = null
  ) => {
    redirectTo({
      pageName: page,
      query: {
        ...(targetContentViewType && {
          ["content-view-type"]: targetContentViewType,
        }),
        ...(calendarType && { ["calendar-view-type"]: calendarType }),
        ["date"]: date.toFormat("AWS"),
        "location-id": locationID,
      },
    });
  };

  return (
    <footer className={styles.footer}>
      <button
        className={`${styles.tab} ${
          currentPage === "schedule" ? styles.active : undefined
        }`}
        onClick={() =>
          redirectToPage(
            PAGE_NAMES.mobileEmployeeViewSchedule,
            selectedDate,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule,
            calendarViewType
          )
        }
      >
        <FontAwesomeIcon className={styles.tabIcon} icon={faCalendarDay} />
        <span className={styles.tabLabel}>Schedule</span>
      </button>
      <button
        className={`${styles.tab} ${
          currentPage === "leave" ? styles.active : undefined
        }`}
        onClick={() =>
          redirectToPage(
            PAGE_NAMES.mobileEmployeeViewLeaveRequests,
            selectedDate,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave,
            calendarViewType
          )
        }
      >
        <FontAwesomeIcon className={styles.tabIcon} icon={faInbox} />
        <span className={styles.tabLabel}>Requests</span>
      </button>
      <button
        className={`${styles.tab} ${
          currentPage === "open-shifts" ? styles.active : undefined
        }`}
        onClick={() =>
          redirectToPage(
            PAGE_NAMES.mobileEmployeeViewOpenShifts,
            selectedDate,
            null,
            null
          )
        }
      >
        <FontAwesomeIcon className={styles.tabIcon} icon={faShuffle} />
        <span className={styles.tabLabel}>Open Shifts</span>
      </button>
      {getPlatform() === "ios" ? (
        <button
          className={`${styles.tab}`}
          onClick={() => CapacitorCrisp.openMessenger()}
        >
          <FontAwesomeIcon className={styles.tabIcon} icon={faQuestionCircle} />
          <span className={styles.tabLabel}>Help</span>
        </button>
      ) : (
        <div className={styles.tab}></div>
      )}
    </footer>
  );
};

export default EmployeeAppMobileFooter;
