import {
  getShiftFromAllocation,
  getShiftHoursFromShift,
} from "../../../../../utils";
import { allocationFulfilsPreference } from "../../../service/preferencesAndFixedShifts";

export const getHistoricDaysOnRow = (
  employeeHistory,
  predefinedShiftOptions
) => {
  if (!employeeHistory[employeeHistory.length - 1]) return null;

  let numDays = 0;
  for (let i = employeeHistory.length - 1; i >= 0; i--) {
    if (
      [...Object.values(predefinedShiftOptions), ""].includes(
        employeeHistory[i]
      )
    )
      break;
    numDays++;
  }

  return numDays;
};

export const getHistoricHoursOnRow = (
  employee,
  shifts,
  shiftHoursByEmployee,
  predefinedShiftOptions
) => {
  if (!employee.History[employee.History.length - 1]) return null;

  let numHours = 0;
  for (let i = employee.History.length - 1; i >= 0; i--) {
    if (
      [...Object.values(predefinedShiftOptions), ""].includes(
        employee.History[i]
      )
    )
      break;
    let shift = getShiftFromAllocation(employee.History[i], shifts);
    numHours += getShiftHoursFromShift(shift, employee, shiftHoursByEmployee);
  }

  return numHours;
};

export const getHistoricShiftsRow = (
  employeeHistory,
  shiftName,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  skills,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  if (!employeeHistory[employeeHistory.length - 1]) return null;

  let numShifts = 0;
  for (let i = employeeHistory.length - 1; i >= 0; i--) {
    if (
      allocationFulfilsPreference(
        employeeHistory[i],
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        shiftName,
        skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    )
      numShifts++;
    else {
      return numShifts;
    }
  }

  return numShifts;
};

export const getHistoricLastShift = (employeeHistory, shifts) => {
  if (!employeeHistory[employeeHistory.length - 1]) return null;

  return getShiftFromAllocation(
    employeeHistory[employeeHistory.length - 1],
    shifts
  );
};

export const getHistoricLastAllocation = (employeeHistory) => {
  if (!employeeHistory[employeeHistory.length - 1]) return null;

  return employeeHistory[employeeHistory.length - 1];
};
