import { extractEntityShortIdsFromAllocation } from "../../../utils/modelUtils/allocation";
import { allocationFulfilsShiftGroup } from "../../rosterProblems/service/preferencesAndFixedShifts";

export const getColorCode = (
  colorCodes,
  value,
  shiftGroups,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
    extractEntityShortIdsFromAllocation(value, shortIdsToEntityNamesDicts);

  if (colorCodes) {
    for (const colorCode of colorCodes) {
      const targetShiftGroup = shiftGroups.find(
        ({ shortId }) => shortId === colorCode.shift
      );

      if (targetShiftGroup) {
        const shouldBeColorCoded = allocationFulfilsShiftGroup(
          targetShiftGroup,
          areaShortId,
          shiftShortId,
          taskShortId || enumeratedTaskShortId,
          null,
          customKeywordsUtilObj
        );
        if (shouldBeColorCoded) {
          return colorCode.color;
        }
      } else if (colorCode.shift === shiftShortId) {
        return colorCode.color;
      }
    }
  }
  return null;
};
