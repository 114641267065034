import styles from "./EmployeeSelectDropdown.module.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { convertToNameIdOptionForm } from "../../../../../utils";

const customStyles = {
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "50px",
  }),
  control: (provided) => ({
    ...provided,
    height: "50px",
  }),
};

const DropdownIndicator = () => {
  return (
    <div className={styles.dropdownIconWrapper}>
      <FontAwesomeIcon
        icon={faChevronCircleDown}
        className={styles.dropdownIcon}
      />
    </div>
  );
};

const EmployeeSelectDropdown = ({
  employees,
  selectedEmployee,
  setSelectedEmployee,
}) => {
  const options = convertToNameIdOptionForm(employees);
  return (
    <div className={styles.container}>
      <Select
        options={options}
        styles={customStyles}
        className={styles.dropdown}
        components={{
          DropdownIndicator,
        }}
        onChange={(newValue) => {
          const employeeId = newValue.value;
          const employee = employees.find((emp) => emp.id === employeeId);
          setSelectedEmployee(employee);
        }}
        value={convertToNameIdOptionForm([selectedEmployee])[0]}
      />
    </div>
  );
};

export default EmployeeSelectDropdown;
