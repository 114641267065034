import { AgChartsReact } from "ag-charts-react";
import { DateTime } from "../../../../../utils";
import { DEMAND_OPTIONS_WITH_MINIMUM } from "../../Demands/DemandsGrid/DemandConstants";
import { saveAs } from "file-saver";
import { canAccessExportDemands } from "../../../../../utils/flagsUtils/flags";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import { useUserStore } from "../../../../../globalStore/appStore";

const htmlify = (violationDetails) => {
  let htmlString = "";
  htmlString += "<ul>";
  for (const [severity, details] of Object.entries(violationDetails)) {
    if (details.length === 0) continue;
    htmlString += `<li>${severity}:`;
    htmlString += "<ul>";
    for (const detail of details) {
      htmlString += `<li>${detail}</li>`;
    }
    htmlString += "</ul>";
    htmlString += "</li>";
  }
  htmlString += "</ul>";

  if (htmlString === "<ul></ul>") {
    htmlString = "Congrats, you have no staffing violations on this day!";
  }

  return htmlString;
};

//TODO:

function removeDuplicateDemands(demands) {
  // create an empty result array
  const result = [];

  // iterate through the demands
  for (const demand of demands) {
    // flag to track whether the demand has any duplicate fields
    let hasDuplicates = false;

    // iterate through the other demands
    for (const otherDemand of result) {
      // skip the current demand
      if (demand === otherDemand) continue;

      const [start1, end1] = demand.demandData.timeSpan.split("-");
      const [start2, end2] = otherDemand.demandData.timeSpan.split("-");

      // check if the demand has any duplicate fields with the other demand
      if (
        demand.demandData.skills === otherDemand.demandData.skills &&
        demand.demandData.shifts === otherDemand.demandData.shifts &&
        demand.demandData.tasks === otherDemand.demandData.tasks &&
        demand.demandData.type === otherDemand.demandData.type &&
        demand.demandData.severity === otherDemand.demandData.severity &&
        demand.violation === otherDemand.violation
      ) {
        if (demand.demandData.timeSpan === otherDemand.demandData.timeSpan) {
          hasDuplicates = true;
          break;
        } else if (end1 === start2) {
          otherDemand.demandData.timeSpan = mergeTimespan(
            demand.demandData.timeSpan,
            otherDemand.demandData.timeSpan
          );
          hasDuplicates = true;
          break;
        } else if (end2 === start1) {
          otherDemand.demandData.timeSpan = mergeTimespan(
            otherDemand.demandData.timeSpan,
            demand.demandData.timeSpan
          );
          hasDuplicates = true;
          break;
        }
      }
    }

    // if the demand does not have any duplicates, add it to the result array
    if (!hasDuplicates) result.push(demand);
  }

  // return the result array
  return result;
}

function merge(violationList) {
  let violationListCopy = JSON.parse(JSON.stringify(violationList));

  return removeDuplicateDemands(violationListCopy);
}

function mergeTimespan(ts1, ts2) {
  const [start1, end1] = ts1.split("-");
  const [start2, end2] = ts2.split("-");
  if (end1 === start2) {
    return `${start1}-${end2}`;
  } else {
    return ts1;
  }
}

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

/* eslint-disable no-unused-vars */
function buildViolationDetailsString(demandData, d, violation) {
  const shifts = demandData.shifts ? `${demandData.shifts} shifts` : "";
  const tasks = demandData.tasks ? `${demandData.tasks} tasks` : "";
  const skills = demandData.skills ? `${demandData.skills} skills` : "";
  const areas = demandData.areas ? `${demandData.areas}` : "";
  const timeSpan = demandData.timeSpan;

  let details = "";
  if (shifts) details += ` w/ ${shifts}`;
  if (tasks) details += ` w/ ${tasks}`;
  if (skills) details += ` w/ ${skills}`;
  if (areas) details += ` in ${areas}`;
  if (timeSpan) details += ` at ${timeSpan}`;

  return `(${demandData.values[d]}/${demandData.targets[d]})${details}`;
  // The below code used to show +n or -n if demands weren't met
  // return `${
  //   parseInt(demandData.values[d]) > parseInt(demandData.targets[d]) ? "+" : "-"
  // }${violation}${details}`;
}

function getScalingFactor(demandData, numDays, startDay) {
  const scalingFactor = Array(numDays).fill(0); // initialize with 0s

  for (const demand of demandData) {
    if (!DEMAND_OPTIONS_WITH_MINIMUM.includes(demand.type)) continue; // skip non-minimum demands

    const severityFactor =
      demand.importance === "Critical"
        ? 100
        : demand.importance === "High"
        ? 20
        : demand.importance === "Medium"
        ? 3
        : 1; // calculate severity factor

    for (let d = startDay; d < numDays; d++) {
      if (demand.values[d] > 0)
        scalingFactor[d] += severityFactor * demand.values[d]; // increment scaling factor
    }
  }

  return Math.max(...scalingFactor); // return maximum scaling factor
}

function violationsToGraphData(
  violationsByDay,
  violationDetailsByDay,
  startDate,
  scalingFactor
) {
  return violationsByDay.map((violations, d) => {
    const newDate = new DateTime(startDate);

    return {
      day: newDate.addDays(d).date,
      goodness: scoreViolations(violations, scalingFactor),
      violations: violations,
      violationDetails: htmlify(violationDetailsByDay[d]),
    };
  });
}

function scoreViolations(violations, scalingFactor) {
  // Initialize the score to 100
  let score = 100.0;
  // const scoreModifier = 10;

  const penaltyAmount = {
    Critical: (100 * 100.0) / scalingFactor,
    High: (100 * 20.0) / scalingFactor,
    Medium: (100 * 3.0) / scalingFactor,
    Low: (100 * 1.0) / scalingFactor,
  };

  // Loop through the violations
  for (const [severity, number] of Object.entries(violations)) {
    score -= penaltyAmount[severity] * number;
  }

  // Return the final score
  return Math.max(score, 0);
}

const GoodnessGraph = ({
  demandData,
  numDays,
  startDate,
  rerosteredDays,
  ruleSolution,
}) => {
  const { email } = useUserStore();

  let newNumDays = numDays;
  let newStartDate = startDate;
  let startDay = 0;
  if (rerosteredDays && rerosteredDays.length >= 1) {
    const newStartDay = rerosteredDays[0];
    newNumDays = rerosteredDays[rerosteredDays.length - 1] - newStartDay + 1;
    newStartDate = new DateTime(startDate).addDays(newStartDay).date;
  }

  const violationsByDay = [];
  const violationDetailsByDay = [];

  for (let d = startDay; d < startDay + numDays; d++) {
    const ind = d - startDay;

    violationsByDay.push({ Critical: 0, High: 0, Medium: 0, Low: 0 });
    violationDetailsByDay.push({ Critical: [], High: [], Medium: [], Low: [] });

    for (let i = 0; i < demandData.length; i++) {
      let violation;

      let targetString = demandData[i].targets[d];
      let target;
      if (targetString == "") target = -1;
      else target = parseInt(demandData[i].targets[d]);

      if (target === -1) {
        violation = 0;
      } else if (demandData[i].type === "Minimum") {
        violation = Math.max(target - parseInt(demandData[i].values[d]), 0);
      } else if (demandData[i].type === "Maximum") {
        violation = Math.max(parseInt(demandData[i].values[d]) - target, 0);
      } else {
        violation = Math.abs(target - parseInt(demandData[i].values[d]));
      }

      violationsByDay[ind][demandData[i].importance] += violation;
      if (violation > 0) {
        violationDetailsByDay[ind][demandData[i].importance].push({
          demandData: demandData[i],
          d: d,
          violation: violation,
        });
      }
    }
  }

  for (let d = startDay; d < startDay + numDays; d++) {
    const ind = d - startDay;
    Object.keys(violationDetailsByDay[ind]).forEach((importance) => {
      const allViolations = merge(violationDetailsByDay[ind][importance]);

      violationDetailsByDay[ind][importance] = allViolations.map((demand) =>
        buildViolationDetailsString(
          demand.demandData,
          demand.d,
          demand.violation
        )
      );
    });
  }

  const scalingFactor = Math.max(
    getScalingFactor(demandData, numDays, startDay),
    1
  );

  const checkDateRelevant = (dat) => {
    // Extract the date value from the data for the current point
    const date = new Date(dat);

    // Define the min and max date range
    const minDate = new DateTime(newStartDate).date;
    const maxDate = new DateTime(newStartDate).addDays(newNumDays - 1).date;

    const dateRelevant = date >= minDate && date <= maxDate;

    return dateRelevant;
  };

  function formatter(params) {
    return {
      fill: checkDateRelevant(params.datum[params.xKey]) ? "#323233" : "orange",
    };
  }

  const options = {
    title: {
      text: "Roster Goodness",
    },
    subtitle: {
      text: "Graph of staffing number and skill mix fulfilment by day. 100 represents perfect staffing.",
    },
    data: violationsToGraphData(
      violationsByDay,
      violationDetailsByDay,
      startDate,
      scalingFactor
    ),
    series: [
      {
        type: "area",
        xKey: "day",
        yKey: "goodness",
        yName: "Goodness",
        marker: {
          enabled: true,
          formatter,
        },
        stroke: "#004BAD",
        tooltip: {
          renderer: (params) => {
            return {
              title: params.xValue.toLocaleDateString("en-nz", dateOptions),
              content: checkDateRelevant(params.datum[params.xKey])
                ? params.datum.violationDetails
                : "This day has no changes",
            };
          },
        },
        fill: "#CAF2E0",
      },
    ],
    legend: {
      enabled: false,
    },
    axes: [
      {
        type: "number",
        position: "left",
        max: 100,
        min: 0,
      },
      {
        type: "time",
        position: "bottom",
        title: {
          text: "Day",
          enabled: true,
        },
        nice: false,
      },
    ],
  };

  return (
    <div style={{ height: "100" }}>
      <AgChartsReact options={options} />
      {canAccessExportDemands(email) && (
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          customStyle={{
            borderRadius: "10px",
          }}
          onClick={() => downloadDemandDataAsCSV(demandData, ruleSolution)}
        >
          Download raw data
        </BasicButton>
      )}
    </div>
  );
};

const downloadDemandDataAsCSV = (demandData, ruleSolution) => {
  const numDays = demandData[0].values[0].length;
  // Define the headers for demand data
  const demandHeaders = [
    "Skills",
    "Tasks",
    "Shifts",
    "Type",
    "Time Span",
    "Importance",
    ...Array(numDays)
      .fill(0)
      .map((_, i) => `Value ${i + 1}`),
    ...Array(numDays)
      .fill(0)
      .map((_, i) => `Target ${i + 1}`),
  ];

  // Create CSV content for demand data
  let csvContent = demandHeaders.join(",") + "\n";

  demandData.forEach((item) => {
    const row = [
      item.skills,
      item.tasks,
      item.shifts,
      item.type,
      item.timeSpan,
      item.importance,
      ...item.values,
      ...item.targets,
    ];

    csvContent +=
      row
        .map((cell) => {
          return cell.toString().includes(",") ? `"${cell}"` : cell;
        })
        .join(",") + "\n";
  });

  // Add headers for rule solutions
  const ruleHeaders = [
    "Rule Template",
    "Employee (0-indexed)",
    "from day",
    "to day",
    "by",
  ];
  csvContent += "\n\n" + ruleHeaders.join(",") + "\n";

  // Add rule solution data
  if (ruleSolution !== undefined) {
    ruleSolution.forEach((solution) => {
      if (solution.violations.length === 0) {
        // If no violations, still show the rule template with empty violation data
        csvContent += `${solution.ruleTemplate},,,\n`;
      } else {
        // Add each violation on a separate line
        solution.violations.forEach((violation) => {
          const row = [
            solution.ruleTemplate,
            violation.e,
            violation.d1 === undefined ? "" : violation.d1, // Preserve 0 values
            violation.d2 === undefined ? "" : violation.d2, // Preserve 0 values
            violation.v === undefined ? "" : violation.v, // Preserve 0 values
          ];

          csvContent +=
            row
              .map((cell) => {
                return cell.toString().includes(",") ? `"${cell}"` : cell;
              })
              .join(",") + "\n";
        });
      }
    });
  }

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Generate timestamp for the filename
  const timestamp = new Date().toISOString().replace(/[:.]/g, "-");

  // Save the file
  saveAs(blob, `demand_data_and_rules_${timestamp}.csv`);
};

export default GoodnessGraph;
