import SideBar from "../../../elements/SideBar/SideBar";
import styles from "./withSideBar.module.css";
import { getSideBarItems } from "../../../elements/SideBar/sideBarItems";
import { useEffect, useMemo } from "react";
import { HEADER_TYPES } from "../../../elements/Header/Header";
import { getFieldsFromLocation } from "../../../../utils/queryUtils/locationDataGetters";
import { useDefaultPageViewStore } from "../../../../globalStore/defaultViewsStore";
import OnBoardingCheckList from "../../../../features/onBoarding/components/OnBoardingCheckList/OnBoardingCheckList";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../../globalStore/appStore";
import { PLAN } from "../../../../features/auth/service/auth";
import {
  createPrototypeViewQueryParam,
  createScheduleViewQueryParam,
} from "../../../../utils/routeUtils/routeUtils";
import { useRosterModelQuery } from "../../../../hooks/modelQueryHooks/useRosterModelQuery";
import OnboardingWizard from "../../../elements/OnboardingWizard/OnboardingWizard";
import {
  canAccessOnboardingWizard,
  canOpenOnboardingWizard,
} from "../../../../utils/flagsUtils/flags";

const withSideBar = (Page) => (props) => {
  const { isCheckListOpen, setIsCheckListOpen, isWizardOpen, setIsWizardOpen } =
    useOnBoardingStore();

  const {
    pageName,
    headerType,
    rosterID,
    rosterName,
    periodStartDate,
    warnings,
    location,
  } = props; // Required props for sidebar
  const { plan } = useUserStore();

  const isScheduleView = headerType === HEADER_TYPES.scheduleViewLocation;

  const { locationID, frontendSettings } = useMemo(
    () => getFieldsFromLocation(location),
    [location]
  );

  const { roster: rosterInView } = useRosterModelQuery({
    isScheduleView,
    locationID,
    rosterID,
  });

  useEffect(() => {
    if (
      location.completedOnboardingTasks &&
      location.completedOnboardingTasks.length <= 1
    ) {
      setIsCheckListOpen(true);
    }
  }, [location, setIsCheckListOpen]);

  const mainLabel = isScheduleView ? "Schedule" : rosterName;

  const { defaultViews } = useDefaultPageViewStore();

  const sideBarItems = useMemo(
    () => getSideBarItems(defaultViews, isScheduleView, plan, frontendSettings),
    [defaultViews, isScheduleView, plan, frontendSettings]
  );

  const urlQueryParam = isScheduleView
    ? createScheduleViewQueryParam(rosterInView)
    : createPrototypeViewQueryParam(rosterInView.id);

  const shouldShowOnboardingCheckList =
    (plan === PLAN.LITE || plan === PLAN.MID) &&
    isCheckListOpen &&
    isScheduleView &&
    !rosterInView.isSnapshot;

  return (
    <div className={styles.container}>
      {periodStartDate && isWizardOpen && canAccessOnboardingWizard(plan) && (
        <OnboardingWizard
          rosterID={rosterID}
          closeWizard={() => setIsWizardOpen(false)}
          numEmployees={location.Employees.items.length}
          location={location}
        />
      )}
      <div className={`${styles["sidebar-wrapper-wrapper"]}`}>
        <SideBar
          items={sideBarItems}
          mainLabel={mainLabel}
          rosterID={rosterID}
          locationID={locationID}
          pageName={pageName}
          isScheduleView={isScheduleView}
          urlQueryParam={urlQueryParam}
          periodStartDate={periodStartDate}
          warnings={warnings}
          frontendSettings={frontendSettings}
        />
      </div>
      <div className={styles["page-wrapper"]}>
        <Page
          {...props}
          setWizardOpen={canOpenOnboardingWizard() && setIsWizardOpen}
        />
      </div>
      {shouldShowOnboardingCheckList && (
        <OnBoardingCheckList location={location} plan={plan} />
      )}
    </div>
  );
};

export default withSideBar;
