import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileCalendar from "../MobileCalendar/MobileCalendar";
import styles from "./MobileRequestsPage.module.css";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import UpdateRequestTab from "../UpdateRequestTab/UpdateRequestTab";
import MobileBottomTab from "../MobileBottomTab/MobileBottomTab";
import {
  EDIT_LEAVE_TAB_STATUS,
  useEditLeaveTabStatus,
} from "../../../hooks/useEditLeaveTabStatus";
import {
  getApprovedLeaveOnDay,
  getLeaveCountInfo,
} from "../../../service/employeeAppUtil";
import { useMemo, useState } from "react";
import { DateTime } from "../../../../../utils";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import LeaveListItem from "../../LeaveListItem/LeaveListItem";

const MobileRequestsPage = ({
  currentViewData,
  setSelectedDate,
  allocations,
  myOpenShifts,
  userEmployee,
  location,
  preferences,
  preferencesRecurring,
  isBottomTabOpened,
  openBottomTab,
  closeBottomTab,
  toggleBottomTab,
  requests,
  leaveCodes,
  employeeLeaveCounts,
  leaveKeywordsDict,
  addNewRequest,
  deleteRequestById,
  setContentViewType,
  plan,
  updateOpenShifts,
}) => {
  const { selectedDate, contentViewType } = currentViewData;
  const { openLeaveTab, closeLeaveTab, tabStatus, selectedLeave } =
    useEditLeaveTabStatus(contentViewType);

  const [shouldShowPastRequests, setShouldShowPastRequsts] = useState(false);

  const displayedRequests = useMemo(() => {
    if (shouldShowPastRequests) {
      return requests;
    }

    return requests.filter((request) =>
      new DateTime(request.finishDate).isSameOrAfter(new DateTime())
    );
  }, [requests, shouldShowPastRequests]);

  const deleteRequest = (requestID) => {
    deleteRequestById(userEmployee, requestID);
    closeLeaveTab();
  };

  const leaveCountInfo = getLeaveCountInfo(
    selectedDate,
    employeeLeaveCounts,
    leaveKeywordsDict
  );

  const approvedLeaveOnDay = getApprovedLeaveOnDay(
    selectedDate,
    requests,
    leaveCodes
  );

  if (contentViewType === EMPLOYEE_APP_CONTENT_VIEW_TYPE.whosOnLeave) {
    return (
      <div className={styles.container}>
        <div
          className={`${styles.calendarContainer} ${
            isBottomTabOpened && styles.bottomTabOpenedCalendarContainer
          }`}
        >
          <MobileCalendar
            currentViewData={currentViewData}
            setSelectedDate={setSelectedDate}
            isBottomTabOpened={isBottomTabOpened}
            openBottomTab={openBottomTab}
            toggleBottomTab={toggleBottomTab}
            allocations={allocations}
            myOpenShifts={myOpenShifts}
            userEmployee={userEmployee}
            location={location}
            preferences={preferences}
            preferencesRecurring={preferencesRecurring}
            requests={requests}
            leaveCodes={leaveCodes}
            employeeLeaveCounts={employeeLeaveCounts}
            leaveKeywordsDict={leaveKeywordsDict}
          />
        </div>
        <div
          className={`${styles.bottomTab} ${
            isBottomTabOpened && styles.bottomTabOpened
          }`}
        >
          <MobileBottomTab
            selectedDate={selectedDate}
            closeBottomTab={closeBottomTab}
            contentViewType={contentViewType}
            setContentViewType={setContentViewType}
            employeeLeaveCounts={employeeLeaveCounts}
            locationStartDate={location.startDate}
            openLeaveTab={openLeaveTab}
            leaveCountInfo={leaveCountInfo}
            myOpenShifts={myOpenShifts}
            employeeID={userEmployee.id}
            updateOpenShifts={updateOpenShifts}
            approvedLeaveOnDay={approvedLeaveOnDay}
            email={userEmployee.email}
          />
        </div>
      </div>
    );
  }

  if (
    [EDIT_LEAVE_TAB_STATUS.update, EDIT_LEAVE_TAB_STATUS.create].includes(
      tabStatus
    )
  ) {
    const isForCreation = tabStatus === EDIT_LEAVE_TAB_STATUS.create;
    return (
      <div className={styles.updateRequestTabContainer}>
        <UpdateRequestTab
          closeTab={closeLeaveTab}
          isForCreation={isForCreation}
          selectedDate={selectedDate}
          selectedLeave={isForCreation ? null : selectedLeave}
          deleteRequest={isForCreation ? () => {} : deleteRequest}
          addNewRequest={isForCreation ? addNewRequest : () => {}}
          leaveKeywordsDict={leaveKeywordsDict}
          customContainerStyle={{ padding: "20px" }}
          requests={requests}
          setSelectedDate={setSelectedDate}
          plan={plan}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentHeader}>
        <p className={styles.title}>My Leave Requests</p>
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          customStyle={{
            borderRadius: "5px",
            width: "150px",
            height: "35px",
            fontSize: "1em",
          }}
          onClick={openLeaveTab}
        >
          <div className={styles.addLeaveRequestButton}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Add Request
          </div>
        </BasicButton>
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <button
            className={styles.toggleHistoryButton}
            onClick={() => setShouldShowPastRequsts(!shouldShowPastRequests)}
          >
            {shouldShowPastRequests ? "Hide History" : "Show History"}
          </button>
        </div>
        {displayedRequests.map((leave, idx) => {
          const customContainerStyle = {
            padding: "20px",
            ...(idx !== 0 && { borderTop: "1px solid #00000029" }),
          };

          return (
            <LeaveListItem
              key={leave.id}
              leaveRequest={leave}
              onViewMore={(request) => openLeaveTab(false, request)}
              customContainerStyle={customContainerStyle}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MobileRequestsPage;
