import { enUS, enGB, fr, es, de, it, ja, ko } from "date-fns/locale";

const locales = {
  "en-US": enUS,
  "en-GB": enGB,
  fr,
  es,
  de,
  it,
  ja,
  ko,
};

export const detectUserLocale = () => {
  const userLocale = navigator.language || navigator.userLanguage;
  return userLocale in locales ? userLocale : "en-US";
};

export const getDateFnsLocale = () => {
  const userLocale = detectUserLocale();
  return locales[userLocale];
};
