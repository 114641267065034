import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./UpdateRequestTab.module.css";
import {
  faCalendar,
  faCommentAlt,
  faDeleteLeft,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import arrow from "../../../../../assets/images/solutionDropdown.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getDateFnsLocale } from "../../../../../utils/generalUtils/locale";
import { PLAN } from "../../../../auth/service/auth";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import {
  DateTime,
  getApprovedOrPendingRequestDates,
  hasCommonItems,
} from "../../../../../utils";
import UpgradePlanComponent from "../../../../../components/elements/UpgradePlanComponent/UpgradePlanComponent";

const Section = ({ children, headerIcon, title }) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTop}>
        <FontAwesomeIcon className={styles.icon} icon={headerIcon} />
        <span>{title}</span>
      </div>
      <div className={styles.sectionBottom}>{children}</div>
    </div>
  );
};

const CreateTool = ({ closeTab, handleAddRequest, isDateRangeValid }) => {
  if (!isDateRangeValid) {
    return null;
  }

  return (
    <div className={styles.createControls}>
      <BasicButton
        color={"#219ec9"}
        hoverColor="#1f91b7"
        customStyle={{
          borderRadius: "5px",
          flex: 1,
          fontSize: "0.8em",
          fontWeight: "bold",
          lineHeight: "1em",
        }}
        onClick={() => {
          if (!isDateRangeValid) {
            return;
          }
          handleAddRequest();
          closeTab();
        }}
      >
        Submit my requests
      </BasicButton>
    </div>
  );
};

const UpdateTool = ({ closeTab, deleteRequest, request }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);
  const handleDelete = () => {
    deleteRequest(request.id);
    closeDeleteDialog();
    closeTab();
  };

  return (
    <div className={styles.updateControls}>
      <Dialog open={isDeleteDialogOpen}>
        <DialogTitle id="delete-leave-request">Are you sure</DialogTitle>
        <DialogContent>
          <DialogContentText id="are-you-sure-to-delete-request">
            This will delete the series of leave you&apos;ve put through.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      <button className={styles.goBackButton} onClick={closeTab}>
        Go back
      </button>
      <button className={styles.deleteButton} onClick={openDeleteDialog}>
        <FontAwesomeIcon className={styles.icon} icon={faDeleteLeft} />
        Delete request
      </button>
    </div>
  );
};

const UpdateRequestTab = ({
  closeTab,
  isForCreation,
  selectedDate,
  selectedLeave,
  deleteRequest,
  addNewRequest,
  leaveKeywordsDict,
  customContainerStyle = {},
  setSelectedDate,
  requests,
  plan,
}) => {
  const [myComment, setMyComment] = useState(() =>
    selectedLeave ? selectedLeave.employeeComment : ""
  );
  const [startDate, setStartDate] = useState(() =>
    selectedLeave ? new DateTime(selectedLeave.startDate) : selectedDate
  );
  const [finishDate, setFinishDate] = useState(() =>
    selectedLeave ? new DateTime(selectedLeave.finishDate) : selectedDate
  );
  const [requestType, setRequestType] = useState(
    Object.keys(leaveKeywordsDict)[0]
  );

  if (plan === PLAN.LITE) {
    return <UpgradePlanComponent />;
  }

  const canEditFields = isForCreation;

  const locale = getDateFnsLocale();

  const approvedOrPendingRequestDates =
    getApprovedOrPendingRequestDates(requests);

  const dateRange = DateTime.getAllDateStringsBetween(
    startDate,
    finishDate,
    true,
    true
  );
  const dateRangeContainsAnotherApprovedOrPendingRequest = hasCommonItems(
    approvedOrPendingRequestDates,
    dateRange
  );
  const isDateRangeValid =
    startDate.isSameOrBefore(finishDate) &&
    !dateRangeContainsAnotherApprovedOrPendingRequest;

  const getStateInfo = (leave) => {
    if (!leave) {
      return null;
    }

    switch (leave.state) {
      case "Approved":
        return {
          label: "Leave Approved",
          backgroundColor: "#10D4C2",
        };
      case "Pending":
        return {
          label: "Pending for approval",
          backgroundColor: "#FCF264",
        };
      case "Denied":
        return {
          label: "Denied",
          backgroundColor: "#f6abab",
        };
      default:
        return null;
    }
  };

  const stateInfo = getStateInfo(selectedLeave);

  const desktopDatePickerStyle = {
    ".MuiInputBase-input": {
      padding: "5px",
      ...(!canEditFields && { cursor: "not-allowed" }),
    },
    ".MuiInputBase-root": {
      fontSize: "0.8em",
    },
    ".MuiButtonBase-root": {
      padding: 0,
    },
    ".MuiSvgIcon-root": {
      width: "20px",
      ...(!canEditFields && { display: "none" }),
    },
  };

  const handleAddRequest = () => {
    addNewRequest(startDate, finishDate, myComment, requestType);
    setSelectedDate(startDate);
  };

  return (
    <div className={styles.container} style={customContainerStyle}>
      <div className={styles.contentHeader}>
        <p className={styles.title}>My Leave Requests</p>
        {stateInfo && (
          <span
            style={{
              backgroundColor: stateInfo.backgroundColor,
            }}
            className={styles.status}
          >
            {stateInfo.label}
          </span>
        )}
      </div>
      <div className={styles.content}>
        <Section headerIcon={faInfoCircle} title="Leave Type">
          {canEditFields ? (
            <select
              className={styles.select}
              style={{ backgroundImage: `url(${arrow})` }}
              onChange={(e) => setRequestType(e.target.value)}
              value={requestType}
            >
              {Object.keys(leaveKeywordsDict).map((shortcode, idx) => (
                <option key={idx} value={shortcode}>
                  {shortcode}
                </option>
              ))}
            </select>
          ) : (
            <span className={styles.immutableSelect}>
              {selectedLeave.request}
            </span>
          )}
        </Section>
        <Section headerIcon={faCalendar} title="Dates">
          <div>
            <div className={styles.dateSection}>
              <div className={styles.date}>
                <span>Start Date</span>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale}
                >
                  <DatePicker
                    sx={desktopDatePickerStyle}
                    value={startDate.getDate()}
                    onChange={(date) => setStartDate(new DateTime(date))}
                    readOnly={!canEditFields}
                  />
                </LocalizationProvider>
              </div>
              <div className={styles.date}>
                <span>End Date</span>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale}
                >
                  <DatePicker
                    sx={desktopDatePickerStyle}
                    value={finishDate.getDate()}
                    onChange={(date) => setFinishDate(new DateTime(date))}
                    readOnly={!canEditFields}
                    minDate={startDate.getDate()}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {isForCreation &&
              dateRangeContainsAnotherApprovedOrPendingRequest && (
                <p className={styles.clashingRequestLabel}>
                  Request clashes with another approved or pending request
                </p>
              )}
          </div>
        </Section>
        <Section headerIcon={faCommentAlt} title="My Comments">
          <textarea
            rows="3"
            className={`${styles.commentBox} ${
              !canEditFields && styles.readOnlyCommentBox
            }`}
            placeholder={canEditFields ? "Enter your comment here." : ""}
            onChange={(e) => setMyComment(e.target.value)}
            value={myComment}
            readOnly={!canEditFields}
            style={{
              ...(!canEditFields && { outline: "none" }),
            }}
          />
        </Section>
        {!isForCreation && (
          <Section headerIcon={faCommentAlt} title="Manager's Comments">
            <textarea
              rows="3"
              className={`${styles.commentBox} ${styles.managerComment} ${
                !canEditFields && styles.readOnlyCommentBox
              }`}
              readOnly={true}
              value={
                selectedLeave.adminComment ? selectedLeave.adminComment : ""
              }
            />
          </Section>
        )}
      </div>
      {isForCreation ? (
        <CreateTool
          closeTab={closeTab}
          handleAddRequest={handleAddRequest}
          isDateRangeValid={isDateRangeValid}
        />
      ) : (
        <UpdateTool
          closeTab={closeTab}
          deleteRequest={deleteRequest}
          request={selectedLeave}
        />
      )}
      {isForCreation && (
        <div className={styles.goBackButtonWrapper}>
          <button className={styles.goBackButton} onClick={closeTab}>
            Go back
          </button>
        </div>
      )}
    </div>
  );
};

export default UpdateRequestTab;
