import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { SwatchesPicker } from "react-color";

const ColorPickerEditor = forwardRef((props, ref) => {
  const [color, setColor] = useState(props.value);

  useEffect(() => {
    if (color !== props.value) {
      props.stopEditing();
    }
  }, [color, props]);

  const changeColor = (color) => {
    if (color !== "") {
      setColor(
        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
      );
    }
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return color;
    },
  }));

  return <SwatchesPicker color={color} onChangeComplete={changeColor} />;
});

export default ColorPickerEditor;
