import { getRulesNameDict } from "../../rules/service/rules";
import {
  DateTime,
  checkFirstNElements,
  checkLastNElements,
  deepCopyObject,
  getUnexistingRosterEmployeesToCreate,
} from "../../../utils";
import { getStatisticsTemplate } from "../../statistics/service/template";
import { extendArray } from "../../store/service/shared/sharedStoreApi";
import { updateRosterModel } from "../../../utils/queryUtils/rosterQuery";
import {
  addAutoAssignedToTasks,
  fixColorCodes,
} from "../../locations/service/fixLocation";
import { allEntitiesHaveShortId } from "../../employeeApp/service/employeeAppUtil";
import { addShortIdToEntities } from "../../../utils/modelUtils/shortId";
import {
  checkInvalidAllocationValueExists,
  checkRosteredAllocationsMatchNumDays,
} from "../../../utils/validationUtils/rosterValidations";
import { checkAllShiftGroupsHasAdminUseOnlyInfo } from "../../../utils/validationUtils/sharedValidations";
import { KEYWORD_ALL, KEYWORD_NA } from "../../../constants/keywords";

export const employeeNeedsNAFixedShitsUpdates = (
  roster,
  globalEmployees,
  employeeIDsToSkipFixedShiftsUpdate = []
) => {
  const updatedRoster = deepCopyObject(roster);
  let shouldUpdateEmployeeFixedShifts = false;

  const rosterStartDate = roster.startDate;
  const rosterFinishDate = DateTime.addDaysToDate(
    rosterStartDate,
    roster.numDays - 1
  ).toFormat("AWS");

  for (const employee of globalEmployees) {
    const employeeStartDate = employee.startDate;
    const employeeFinishDate = employee.finishDate;

    const isEmployeeOutsideRoster =
      new DateTime(employeeStartDate).isAfter(rosterFinishDate) ||
      new DateTime(employeeFinishDate).isBefore(rosterStartDate);

    const shouldStartPad = new DateTime(employeeStartDate).isAfter(
      rosterStartDate
    );

    const shouldEndPad = new DateTime(employeeFinishDate).isBefore(
      rosterFinishDate
    );

    if (isEmployeeOutsideRoster) {
      continue;
    }

    if (employeeIDsToSkipFixedShiftsUpdate.includes(employee.id)) {
      continue;
    }

    if (shouldStartPad || shouldEndPad) {
      let targetRosterEmployee = updatedRoster.Employees.find(
        (item) => item.id === employee.id
      );
      if (!targetRosterEmployee) {
        shouldUpdateEmployeeFixedShifts = true;
        targetRosterEmployee = {
          id: employee.id,
          globalEmployeeID: employee.id,
          Days: Array(roster.numDays).fill(""),
          DaysRecurring: null,
          Allocations: Array(roster.numDays).fill(""),
          AllocationsRecurring: null,
          History: Array(14).fill(""),
          RuleValues: null,
          RosteredAllocations: null,
          name: employee.name,
          skills: employee.skills,
          shifts: employee.shifts,
        };
        updatedRoster.Employees.push(targetRosterEmployee);
      }
      let fixedShifts = targetRosterEmployee.Allocations;

      if (shouldStartPad) {
        const numIndicesToPad = DateTime.getDifferenceInDays(
          rosterStartDate,
          employeeStartDate
        );

        const isCorrect = checkFirstNElements(
          fixedShifts,
          numIndicesToPad,
          KEYWORD_NA
        );
        if (!isCorrect) {
          shouldUpdateEmployeeFixedShifts = true;
          fixedShifts = fixedShifts.map((item, idx) => {
            if (idx < numIndicesToPad) {
              return KEYWORD_NA;
            }
            return item;
          });
        }
      }

      if (shouldEndPad) {
        const numIndicesToPad = DateTime.getDifferenceInDays(
          employeeFinishDate,
          rosterFinishDate
        );
        const isCorrect = checkLastNElements(
          fixedShifts,
          numIndicesToPad,
          KEYWORD_NA
        );
        if (!isCorrect) {
          shouldUpdateEmployeeFixedShifts = true;
          fixedShifts = fixedShifts.map((item, idx) => {
            if (idx > roster.numDays - numIndicesToPad - 1) {
              return KEYWORD_NA;
            }
            return item;
          });
        }
      }

      targetRosterEmployee.Allocations = fixedShifts;
    }
  }
  return { shouldUpdateEmployeeFixedShifts, updatedRoster };
};

export function hasIncorrectSpelling(rules) {
  return rules?.some((rule) => rule.template.includes("Crtical"));
}

export function correctSpellingInRules(rules) {
  if (!rules) return rules;

  return rules.map((rule) => {
    if (rule.template.includes("Crtical")) {
      rule.template = rule.template.replace("Crtical", "Critical");
    }
    return rule;
  });
}

const updateFieldInRoster = (roster, fieldsUpdated, fieldToUpdate, data) => {
  roster[fieldToUpdate] = data;
  fieldsUpdated.add(fieldToUpdate);
};

const updateScheduleRoster = (roster, globalEmployees) => {
  const { isSnapshot } = roster;

  let newRoster = JSON.parse(JSON.stringify(roster));
  let fieldsUpdated = new Set();

  const numDays = roster.numDays;

  const periodStartDate = roster.startDate;
  const periodFinishDate = DateTime.addDaysToDate(
    periodStartDate,
    numDays - 1
  ).toFormat("AWS");

  if (!isSnapshot) {
    const rosterEmployeesToCreate = getUnexistingRosterEmployeesToCreate(
      newRoster,
      globalEmployees,
      periodStartDate,
      periodFinishDate
    );

    if (rosterEmployeesToCreate.length > 0) {
      newRoster.Employees = [
        ...newRoster.Employees,
        ...rosterEmployeesToCreate,
      ];
      fieldsUpdated.add("Employees");
    }
  }

  const { shouldUpdateEmployeeFixedShifts, updatedRoster } =
    employeeNeedsNAFixedShitsUpdates(roster, globalEmployees, []);

  if (shouldUpdateEmployeeFixedShifts) {
    newRoster.Employees = updatedRoster.Employees;
  }

  let hasUpdatedEmployees = shouldUpdateEmployeeFixedShifts;

  if (isSnapshot) {
    if (!newRoster.Areas) {
      newRoster.Areas = [];
      fieldsUpdated.add("Areas");
    }

    const employeesWithInvalidAreasExist = newRoster.Employees.some(
      (employee) => employee.areas == null
    );

    if (employeesWithInvalidAreasExist) {
      hasUpdatedEmployees = true;
      const updatedEmployees = newRoster.Employees.map((employee) => {
        return {
          ...employee,
          areas: KEYWORD_ALL,
        };
      });
      newRoster.Employees = updatedEmployees;
    }

    const shouldAddAreasToDemands = newRoster.Demands.some(
      (demand) => demand.areas == null
    );
    if (newRoster.Demands && shouldAddAreasToDemands) {
      const updatedDemands = newRoster.Demands.map((demand) => {
        if (demand.areas == null) {
          return {
            ...demand,
            areas: "",
          };
        }
        return demand;
      });
      newRoster.Demands = updatedDemands;
      fieldsUpdated.add("Demands");
    }

    const shouldAddAreasToShiftGroups = newRoster.ShiftGroups.some(
      (group) => group.areas == null
    );
    if (newRoster.ShiftGroups && shouldAddAreasToShiftGroups) {
      const updatedShiftGroups = newRoster.ShiftGroups.map((group) => {
        if (group.areas == null) {
          return {
            ...group,
            areas: "",
          };
        }
        return group;
      });
      newRoster.ShiftGroups = updatedShiftGroups;
      fieldsUpdated.add("ShiftGroups");
    }
  }

  if (hasUpdatedEmployees) {
    fieldsUpdated.add("Employees");
  }

  if (isSnapshot && newRoster.TaskBlocks === null) {
    newRoster.TaskBlocks = [];
    fieldsUpdated.add("TaskBlocks");
  }

  if (hasIncorrectSpelling(newRoster.CustomRules)) {
    newRoster.CustomRules = correctSpellingInRules(newRoster.CustomRules);
    fieldsUpdated.add("CustomRules");
  }

  return [newRoster, fieldsUpdated];
};

const rosterNeedsUpdate = (roster) => {
  const employeesWithInvalidRosteredAllocations = roster.Employees.filter(
    (emp) => !emp.RosteredAllocations
  );

  const employeesWithInvalidAreas = roster.Employees.filter(
    (emp) => emp.areas === null
  );

  const ruleNameDict = getRulesNameDict(
    roster.Shifts,
    roster.ShiftGroups,
    roster.Tasks,
    roster.id
  );

  const shouldAddAreasToDemands = roster.Demands.some(
    (demand) => demand.areas == null
  );

  const shouldAddAreasToShiftGroups = roster.ShiftGroups.some(
    (group) => group.areas == null
  );

  return (
    !roster.snapshotStartDate ||
    !allEntitiesHaveShortId(roster.Shifts) ||
    !allEntitiesHaveShortId(roster.ShiftGroups) ||
    !allEntitiesHaveShortId(roster.Tasks) ||
    !allEntitiesHaveShortId(roster.TaskBlocks) ||
    !allEntitiesHaveShortId(roster.Skills) ||
    hasIncorrectSpelling(roster.CustomRules) ||
    roster.Employees.some((emp) => {
      return emp.DaysRecurring.length === 0 || !emp.DaysRecurring;
    }) ||
    roster.Tasks.some((task) => task.autoAssigned === null) ||
    roster.Statistics === null ||
    roster.Tasks === null ||
    roster.TaskBlocks === null ||
    roster.Areas === null ||
    roster.Statistics.otherSettings === null ||
    roster.Statistics.dayShiftSkillCountsToShow === null ||
    roster.Statistics.dayHoursToShow === null ||
    roster.Statistics.dayShiftSkillToggleDisplayed === null ||
    roster.RuleExceptions === null ||
    roster.Skills === null ||
    roster.ColorCodes === null ||
    roster.Demands.filter((demand) => demand.tasks === null).length > 0 ||
    roster.ShiftGroups.filter((shiftGroup) => shiftGroup.tasks === null)
      .length > 0 ||
    (roster.Employees[0] && roster.Employees[0].shifts === null) ||
    (roster.Employees[0] && roster.Employees[0].History.length !== 14) ||
    roster.CustomRules.filter(
      (customRule) =>
        customRule.template.startsWith(
          "numShiftChangesPeriod;Fortnight;Minimum"
        ) && customRule.template.split(";").length === 4
    ).length > 0 ||
    roster.CustomRules.some(
      (rule) => ruleNameDict[rule.template] !== rule.name
    ) ||
    !checkAllShiftGroupsHasAdminUseOnlyInfo(roster.ShiftGroups) ||
    employeesWithInvalidRosteredAllocations.length > 0 ||
    employeesWithInvalidAreas.length > 0 ||
    !checkRosteredAllocationsMatchNumDays(roster) ||
    checkInvalidAllocationValueExists(roster.Employees) ||
    roster.Statistics.leaveCountsToShow === null ||
    !checkRosteredAllocationsMatchNumDays(roster) ||
    fixColorCodes(roster.ColorCodes, roster.Shifts, roster.ShiftGroups)
      .hasUpdated ||
    shouldAddAreasToDemands ||
    shouldAddAreasToShiftGroups
  );
};

const updateRosterWithCorrectFields = (roster) => {
  let newRoster = JSON.parse(JSON.stringify(roster));
  let fieldsUpdated = new Set();

  const allShiftGroupsHasAdminUseOnlyInfo =
    checkAllShiftGroupsHasAdminUseOnlyInfo(newRoster.ShiftGroups);
  if (!allShiftGroupsHasAdminUseOnlyInfo) {
    const shiftGroups = newRoster.ShiftGroups;
    const updatedShiftGroups = shiftGroups.map((group) => {
      if (group.adminUseOnly === null) {
        return {
          ...group,
          adminUseOnly: false,
        };
      }
      return group;
    });
    newRoster.ShiftGroups = updatedShiftGroups;
    updateFieldInRoster(
      newRoster,
      fieldsUpdated,
      "ShiftGroups",
      updatedShiftGroups
    );
  }

  const shouldAddAreasToDemands = roster.Demands.some(
    (demand) => demand.areas == null
  );

  if (roster.Demands && shouldAddAreasToDemands) {
    const updatedDemands = newRoster.Demands.map((demand) => {
      if (demand.areas == null) {
        return {
          ...demand,
          areas: "",
        };
      }
      return demand;
    });
    newRoster.Demands = updatedDemands;
    fieldsUpdated.add("Demands");
  }

  const shouldAddAreasToShiftGroups = roster.ShiftGroups.some(
    (group) => group.areas == null
  );

  if (roster.ShiftGroups && shouldAddAreasToShiftGroups) {
    const updatedShiftGroups = newRoster.ShiftGroups.map((group) => {
      if (group.areas == null) {
        return {
          ...group,
          areas: "",
        };
      }
      return group;
    });
    newRoster.ShiftGroups = updatedShiftGroups;
    fieldsUpdated.add("ShiftGroups");
  }

  if (!roster.snapshotStartDate) {
    newRoster.snapshotStartDate = `F${roster.startDate}`;
    fieldsUpdated.add("snapshotStartDate");
  }

  if (roster.Skills && !allEntitiesHaveShortId(roster.Skills)) {
    newRoster.Skills = addShortIdToEntities(roster.Skills);
    fieldsUpdated.add("Skills");
  }

  if (newRoster.Tasks) {
    const hasUpdated = addAutoAssignedToTasks(newRoster.Tasks);
    if (hasUpdated) {
      fieldsUpdated.add("Tasks");
    }
  }

  if (roster.Tasks && !allEntitiesHaveShortId(roster.Tasks)) {
    newRoster.Tasks = addShortIdToEntities(roster.Tasks);
    fieldsUpdated.add("Tasks");
  }

  if (roster.TaskBlocks && !allEntitiesHaveShortId(roster.TaskBlocks)) {
    newRoster.TaskBlocks = addShortIdToEntities(roster.TaskBlocks);
    fieldsUpdated.add("TaskBlocks");
  }

  if (roster.Shifts && !allEntitiesHaveShortId(roster.Shifts)) {
    newRoster.Shifts = addShortIdToEntities(roster.Shifts);
    fieldsUpdated.add("Shifts");
  }

  if (newRoster.ShiftGroups && !allEntitiesHaveShortId(newRoster.ShiftGroups)) {
    newRoster.ShiftGroups = addShortIdToEntities(newRoster.ShiftGroups);
    fieldsUpdated.add("ShiftGroups");
  }

  if (hasIncorrectSpelling(roster.CustomRules)) {
    newRoster.CustomRules = correctSpellingInRules(roster.CustomRules);
    fieldsUpdated.add("CustomRules");
  }

  if (newRoster.ColorCodes) {
    const { updatedColorCodes, hasUpdated } = fixColorCodes(
      newRoster.ColorCodes,
      newRoster.Shifts,
      newRoster.ShiftGroups
    );

    if (hasUpdated) {
      newRoster.ColorCodes = updatedColorCodes;
      fieldsUpdated.add("ColorCodes");
    }
  }

  var ruleInd = newRoster.CustomRules.findIndex(
    (customRule) =>
      customRule.template.startsWith(
        "numShiftChangesPeriod;Fortnight;Minimum"
      ) && customRule.template.split(";").length === 4
  );

  if (ruleInd !== -1) {
    const newRules = newRoster.CustomRules.map((rule) => {
      return { name: rule.name, template: rule.template };
    });
    newRules[ruleInd].template = "numShiftChangesPeriod;Fortnight;Minimum";
    updateFieldInRoster(newRoster, fieldsUpdated, "CustomRules", newRules);
  }

  const ruleNameDict = getRulesNameDict(
    newRoster.Shifts,
    newRoster.ShiftGroups,
    newRoster.Tasks,
    newRoster.id
  );

  if (
    newRoster.CustomRules.some(
      (rule) => ruleNameDict[rule.template] !== rule.name
    )
  ) {
    const newRules = newRoster.CustomRules.map((rule) => {
      return { name: ruleNameDict[rule.template], template: rule.template };
    }).filter((rule) => rule.name);
    updateFieldInRoster(newRoster, fieldsUpdated, "CustomRules", newRules);
  }

  if (
    newRoster.Employees.length > 0 &&
    newRoster.Employees[0].History.length !== 14
  ) {
    const tempEmployees = JSON.parse(JSON.stringify(newRoster.Employees));
    for (const emp of tempEmployees) {
      let newHistory = Array(14).fill("");
      let j = 0;
      for (
        let i = newHistory.length - emp.History.length;
        i < newHistory.length;
        i++
      ) {
        newHistory[i] = emp.History[j];
        j++;
      }
      emp.History = newHistory;
    }
    updateFieldInRoster(newRoster, fieldsUpdated, "Employees", tempEmployees);
  }

  if (newRoster.Employees.some((employee) => employee.areas == null)) {
    const tempEmployees = JSON.parse(JSON.stringify(newRoster.Employees));
    const updatedEmployees = tempEmployees.map((employee) => {
      if (employee.areas == null) {
        return {
          ...employee,
          areas: KEYWORD_ALL,
        };
      }
    });
    updateFieldInRoster(
      newRoster,
      fieldsUpdated,
      "Employees",
      updatedEmployees
    );
  }

  if (newRoster.Skills === null) {
    updateFieldInRoster(newRoster, fieldsUpdated, "Skills", []);
  }

  if (newRoster.TaskBlocks === null) {
    updateFieldInRoster(newRoster, fieldsUpdated, "TaskBlocks", []);
  }

  if (newRoster.Areas === null) {
    updateFieldInRoster(newRoster, fieldsUpdated, "Areas", []);
  }

  if (newRoster.RuleExceptions === null) {
    updateFieldInRoster(newRoster, fieldsUpdated, "RuleExceptions", []);
  }

  if (newRoster.ColorCodes === null) {
    updateFieldInRoster(newRoster, fieldsUpdated, "ColorCodes", []);
  }

  if (newRoster.Demands.filter((demand) => demand.tasks === null).length > 0) {
    const newDemands = JSON.parse(JSON.stringify(newRoster.Demands));
    for (let d = 0; d < newDemands.length; d++) {
      newDemands[d].tasks = "";
    }
    updateFieldInRoster(newRoster, fieldsUpdated, "Demands", newDemands);
  }
  if (
    newRoster.ShiftGroups.filter((shiftGroup) => shiftGroup.tasks === null)
      .length > 0
  ) {
    const newShiftGroups = JSON.parse(JSON.stringify(newRoster.ShiftGroups));
    for (let d = 0; d < newShiftGroups.length; d++) {
      newShiftGroups[d].tasks = "";
    }

    updateFieldInRoster(
      newRoster,
      fieldsUpdated,
      "ShiftGroups",
      newShiftGroups
    );
  }

  if (newRoster.Statistics === null) {
    updateFieldInRoster(
      newRoster,
      fieldsUpdated,
      "Statistics",
      getStatisticsTemplate()
    );
  } else {
    let hasNullField = false;

    for (const key in newRoster.Statistics) {
      if (
        Object.prototype.hasOwnProperty.call(newRoster.Statistics, key) &&
        newRoster.Statistics[key] === null
      ) {
        hasNullField = true;
        break;
      }
    }

    if (hasNullField) {
      const newStatistics = { ...newRoster.Statistics };

      for (const key in newStatistics) {
        if (
          Object.prototype.hasOwnProperty.call(newStatistics, key) &&
          newStatistics[key] === null
        ) {
          newStatistics[key] = [];
        }
      }

      updateFieldInRoster(
        newRoster,
        fieldsUpdated,
        "Statistics",
        newStatistics
      );
    }
  }

  if (
    newRoster.Employees.length > 0 &&
    newRoster.Employees[0].shifts === null
  ) {
    let newEmployees = [];

    for (let i = 0; i < newRoster.Employees.length; i++) {
      if (newRoster.Employees[i].shifts == null) {
        newEmployees.push({ ...newRoster.Employees[i], shifts: "" });
      } else {
        newEmployees.push({
          ...newRoster.Employees[i],
          shifts: newRoster.Employees[i].shifts,
        });
      }
    }

    updateFieldInRoster(newRoster, fieldsUpdated, "Employees", newEmployees);
  }

  if (
    newRoster.Employees.some(
      (emp) => emp.DaysRecurring.length === 0 || !emp.DaysRecurring
    )
  ) {
    let newEmployees = [];

    for (let i = 0; i < newRoster.Employees.length; i++) {
      if (
        newRoster.Employees[i].DaysRecurring.length === 0 ||
        !newRoster.Employees[i].DaysRecurring
      ) {
        newEmployees[i].DaysRecurring = Array(7).fill("");
      } else {
        newEmployees[i].DaysRecurring = newRoster.Employees[i].DaysRecurring;
      }
    }
    fieldsUpdated.add("Employees");
  }

  const employeesIDsWithInvalidRosteredAllocations = newRoster.Employees.filter(
    (emp) => !emp.RosteredAllocations
  ).map((emp) => emp.id);
  if (employeesIDsWithInvalidRosteredAllocations.length > 0) {
    const employees = JSON.parse(JSON.stringify(newRoster.Employees));
    const updatedEmployees = employees.map((emp) => {
      if (employeesIDsWithInvalidRosteredAllocations.includes(emp.id)) {
        return {
          ...emp,
          RosteredAllocations: Array(newRoster.numDays).fill(""),
        };
      }
      return emp;
    });
    newRoster.Employees = updatedEmployees;
    fieldsUpdated.add("Employees");
  }

  if (!checkRosteredAllocationsMatchNumDays(newRoster)) {
    const numDays = newRoster.numDays;

    for (let i = 0; i < newRoster.Employees.length; i++) {
      const employee = newRoster.Employees[i];
      if (employee.RosteredAllocations.length !== numDays) {
        employee.RosteredAllocations = extendArray(
          numDays,
          employee.RosteredAllocations,
          ""
        );
      }
    }
    fieldsUpdated.add("Employees");
  }

  if (checkInvalidAllocationValueExists(newRoster.Employees)) {
    for (let i = 0; i < newRoster.Employees.length; i++) {
      const employee = newRoster.Employees[i];
      employee.Days = employee.Days.map((allo) => (allo === null ? "" : allo));
      employee.DaysRecurring = employee.DaysRecurring.map((allo) =>
        allo === null ? "" : allo
      );
      employee.Allocations = employee.Allocations.map((allo) =>
        allo === null ? "" : allo
      );
      employee.AllocationsRecurring = employee.AllocationsRecurring.map(
        (allo) => (allo === null ? "" : allo)
      );
      employee.RosteredAllocations = employee.RosteredAllocations.map((allo) =>
        allo === null ? "" : allo
      );
      employee.History = employee.History.map((allo) =>
        allo === null ? "" : allo
      );
    }
    fieldsUpdated.add("Employees");
  }

  return [newRoster, fieldsUpdated];
};

export const fixPrototypeRoster = async (roster, frontendSettings) => {
  let newRoster = null;
  let fieldsUpdated = new Set();
  let rosterHasChanged = false;

  if (roster !== undefined) {
    // Fix if the roster is an old version
    const shouldFixRoster = rosterNeedsUpdate(roster);
    if (shouldFixRoster) {
      [newRoster, fieldsUpdated] = updateRosterWithCorrectFields(roster);
      rosterHasChanged = true;
    } else {
      newRoster = roster;
    }

    const shouldCheckLeave = frontendSettings
      .map((item) => item.name)
      .includes("checkLeave");

    const selections = shouldCheckLeave
      ? ["Fixed Shifts", "Preferences"]
      : ["Preferences"];

    let selectionState = {};
    selections.forEach((sel) => (selectionState[sel] = true));

    if (rosterHasChanged) {
      await updateRosterWithCorrectFieldsToDatabase(newRoster, fieldsUpdated);
      return newRoster;
    }
  }
  return null;
};

export const fixScheduleRoster = async (roster, globalEmployees) => {
  // Fix if the roster is an old version
  const [newRoster, fieldsUpdated] = updateScheduleRoster(
    roster,
    globalEmployees
  );

  const hasRosterUpdated = fieldsUpdated.size > 0;

  if (hasRosterUpdated) {
    console.log("fixScheduleRoster: Schedule roster is fixed");
    await updateRosterWithCorrectFieldsToDatabase(newRoster, fieldsUpdated);
    return newRoster;
  }

  return null;
};

async function updateRosterWithCorrectFieldsToDatabase(roster, fieldsUpdated) {
  // Check if fieldsUpdated is not empty
  if (fieldsUpdated.size > 0) {
    let fieldsToUpdate = {};

    // Construct an object with only the fields to be updated
    for (let field of fieldsUpdated) {
      // eslint-disable-next-line no-prototype-builtins
      if (roster.hasOwnProperty(field)) {
        fieldsToUpdate[field] = roster[field];
      }
    }

    return await updateRosterModel(roster.id, {
      id: roster.id,
      ...fieldsToUpdate,
      _version: roster._version,
    });
  }
}
