import { useRouteQuery } from "./useRouteQuery";
import { DateTime } from "../utils";

export function useGetPeriodFromUrl(
  defaultStartDate = null,
  defaultFinishDate = null
) {
  const getDefaultStartDate = () => {
    return defaultStartDate || new DateTime().toFormat("AWS");
  };

  const getDefaultFinishDate = () => {
    return defaultFinishDate || new DateTime().toFormat("AWS");
  };

  const routeQuery = useRouteQuery();
  const startDate = routeQuery.get("start-date")
    ? routeQuery.get("start-date")
    : getDefaultStartDate();
  const finishDate = routeQuery.get("finish-date")
    ? routeQuery.get("finish-date")
    : getDefaultFinishDate();

  return {
    startDate,
    finishDate,
  };
}
