import { useCallback, useEffect, useState } from "react";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "./useEmployeeAppCurrentView";

export const EDIT_LEAVE_TAB_STATUS = {
  create: "create",
  update: "update",
  closed: "closed",
};

export function useEditLeaveTabStatus(contentViewType) {
  const [showUpdateLeaveTab, setShowUpdateLeaveTab] = useState(false);
  const [showCreateLeaveTab, setShowCreateLeaveTab] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);

  useEffect(() => {
    if (contentViewType !== EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave) {
      setShowUpdateLeaveTab(false);
      setShowCreateLeaveTab(false);
    }
  }, [contentViewType]);

  const openLeaveTab = useCallback((isForCreation, existingRequest = null) => {
    if (isForCreation) {
      setShowUpdateLeaveTab(false);
      setShowCreateLeaveTab(true);
      setSelectedLeave(null);
    } else {
      setShowUpdateLeaveTab(true);
      setShowCreateLeaveTab(false);
      setSelectedLeave(existingRequest);
    }
  }, []);

  const closeLeaveTab = useCallback(() => {
    setShowUpdateLeaveTab(false);
    setShowCreateLeaveTab(false);
    setSelectedLeave(null);
  }, []);

  let tabStatus = EDIT_LEAVE_TAB_STATUS.closed;
  if (showCreateLeaveTab) {
    tabStatus = EDIT_LEAVE_TAB_STATUS.create;
  } else if (showUpdateLeaveTab) {
    tabStatus = EDIT_LEAVE_TAB_STATUS.update;
  }

  return {
    openLeaveTab,
    closeLeaveTab,
    tabStatus,
    selectedLeave,
  };
}
