import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./HistoryLocked.module.css";

const HistoryLocked = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>History</h1>
    <UpgradeBanner
      title={"Unlock History with RosterLab AI plans!"}
      descriptions={[
        "With RosterLab Premium, AI will consider your published past roster as history, avoiding breaking any contract and union rules between schedule periods.",
      ]}
      showUpgradeButton={true}
      showBookDemoButton={true}
    />
  </div>
);

export default HistoryLocked;
