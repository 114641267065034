import { useQuery } from "@tanstack/react-query";
import {
  createRosterModel,
  getLocationAndEmployeeModels,
  getLocationFrontendSettingsByLocationID,
} from "../../utils/queryUtils/locationQuery";
import { useMemo } from "react";
import { fixLocation } from "../../features/locations/service/fixLocation";
import { fixGlobalEmployees } from "../../features/locations/service/fixGlobalEmployees";
import { getRosterModelById } from "../../utils/queryUtils/rosterQuery";
import {
  fixPrototypeRoster,
  fixScheduleRoster,
} from "../../features/rosterProblems/service/fixRoster";
import {
  createRosterForScheduleView,
  inferRosterStartDate,
} from "../../features/scheduleView/service/scheduleViewRoster";
import { DateTime } from "../../utils";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../utils/queryUtils/locationDataGetters";
import { orderGlobalEmployees } from "../../features/locations/service/locationsUtil";
import { getActualNumDays } from "../../utils/queryUtils/monthViewUtils";
import { PLAN } from "../../features/auth/service/auth";
import { useUserStore } from "../../globalStore/appStore";

async function createCurrentPeriodRoster(location, globalEmployees) {
  const currentScheduleStartDate = inferRosterStartDate(
    location.startDate,
    new DateTime().toFormat("AWS"),
    location.defaultNumDays
  );
  const existingRosterStartDates = location.Rosters.items.map(
    (r) => r.startDate
  );

  if (!existingRosterStartDates.includes(currentScheduleStartDate)) {
    const rosterInfo = createRosterForScheduleView(
      location.id,
      getActualNumDays(currentScheduleStartDate, location.defaultNumDays),
      currentScheduleStartDate,
      globalEmployees,
      interpretCustomKeywordsData(getCustomKeywordsDataFromLocation(location))
        .annualLeaveKeyword
    );
    const createdRoster = await createRosterModel(rosterInfo, location.owner);
    return {
      ...location,
      Rosters: {
        items: [...location.Rosters.items, createdRoster],
      },
    };
  }

  return location;
}

export function useBareLocationQuery(locationID, enabled = true) {
  const { plan } = useUserStore();

  const getGlobalEmployeesFromLocation = (location) => {
    if (!location || !location.Employees) {
      return [];
    }
    return location.Employees.items.filter((employee) => !employee._deleted);
  };

  const query = useQuery({
    queryKey: ["location", locationID],
    queryFn: async () => {
      if (!locationID) {
        return null;
      }
      let location = await getLocationAndEmployeeModels(locationID);
      if (!location) {
        return null;
      }
      let globalEmployees = getGlobalEmployeesFromLocation(location);
      if (location.isScheduleView) {
        location = await createCurrentPeriodRoster(location, globalEmployees);
      }

      if (plan != PLAN.COORDINATOR) {
        // Do not fix stuff if coordinator because unauthorised to do so
        const fixedLocation = await fixLocation(location, globalEmployees);
        const fixedGlobalEmployees = await fixGlobalEmployees(
          location,
          globalEmployees
        );

        if (fixedLocation) {
          location = fixedLocation;
        }

        if (fixedGlobalEmployees) {
          globalEmployees = fixedGlobalEmployees;
          location.Employees.items = fixedGlobalEmployees;
        }
      }

      return location;
    },
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled,
  });

  const location = useMemo(() => {
    if (!query.data || query.data._deleted) {
      return null;
    }

    return query.data;
  }, [query]);

  const globalEmployees = useMemo(() => {
    const unordered = getGlobalEmployeesFromLocation(location);
    if (unordered.length === 0) {
      return unordered;
    }
    return orderGlobalEmployees(unordered, location.order);
  }, [location]);

  return { location, globalEmployees, isLoading: query.isLoading };
}

export const getRosterQueryFn = async (
  isScheduleView,
  rosterID,
  globalEmployees = null,
  plan = null
) => {
  let roster = null;

  try {
    if (isScheduleView) {
      roster = await getRosterModelById(rosterID);
      if (plan !== PLAN.COORDINATOR) {
        const fixedRoster = await fixScheduleRoster(roster, globalEmployees);
        if (fixedRoster) {
          roster = fixedRoster;
        }
      }
    } else {
      roster = await getFixedPrototypeRoster(rosterID);
    }
  } catch (error) {
    if (error.message.startsWith("Not Authorized")) {
      roster = null;
    }
  }

  return roster;
};

export function useBareRosterQuery(
  rosterID,
  isScheduleView,
  globalEmployees = null,
  enabled
) {
  const { plan } = useUserStore();

  const query = useQuery({
    queryKey: ["roster", rosterID],
    queryFn: () => {
      return getRosterQueryFn(isScheduleView, rosterID, globalEmployees, plan);
    },
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled,
  });

  const roster = useMemo(() => {
    if (query.data) {
      const rosterModel = query.data;
      rosterModel.numDays = getActualNumDays(
        rosterModel.startDate,
        rosterModel.numDays
      );
      return rosterModel;
    }
    return null;
  }, [query]);

  return { roster, isLoading: query.isLoading, refetch: query.refetch };
}

const getFixedPrototypeRoster = async (rosterID) => {
  let roster = await getRosterModelById(rosterID);
  const frontendSettings = await getLocationFrontendSettingsByLocationID(
    roster.locationID
  );
  const fixedRoster = await fixPrototypeRoster(roster, frontendSettings, []);
  if (fixedRoster) {
    roster = fixedRoster;
  }

  roster.frontendSettings = frontendSettings;
  return roster;
};
