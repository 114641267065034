import _ from "lodash";
import { parseISO } from "date-fns";
import {
  allocationToDemands,
  isDemandSatisfiedByAllocation,
} from "./GridBuildingUtils";
import {
  deepCopyObject,
  getIds,
  getShortIds,
  strToArrCommaSeparated,
} from "../../../../../utils";
import { extractEntityShortIdsFromAllocation } from "../../../../../utils/modelUtils/allocation";

/* This function takes in the following inputs:
- An operation type, e.g., "add", "remove", or "swap"
- An employee's single allocation
- a demand to add, remove or swap
- all formatted demands, shifts and taskBlocks
- an optional demand that is required for the swap operation
It outputs a new allocation after the operation is complete

The algorithm essentially assigns each cell in the shift view table a demand.
It uses the set of demands satisfied to construct and allocation in employee view format.
It can also go from employee view format to list of demands satisfied.
Whenever you make a change, it recreates an allocation that has the correct demands added or removed,
but also with the least number of changes to the original set of demands fulfilled.
*/

export function changeGrid(
  type,
  oldAllocation,
  demand,
  demands,
  taskBlocks,
  areas,
  shifts,
  shiftGroups,
  employeeSkills,
  customKeywordsDict,
  swapDemand = null,
  shortIdsToEntityNamesDicts
) {
  let demandToAdd = null;
  let demandToRemove = null;

  switch (type) {
    case "add":
      demandToAdd = demand;
      break;
    case "remove":
      demandToRemove = demand;
      break;
    case "swap":
      demandToAdd = demand;
      demandToRemove = swapDemand;
      break;
  }

  if (demand === undefined) return null;
  const existingDemands = allocationToDemands(
    oldAllocation,
    demands,
    shiftGroups,
    employeeSkills,
    customKeywordsDict
  );

  const newAllocation = determineAllocation(
    demands,
    demandToAdd,
    demandToRemove,
    existingDemands,
    areas,
    shifts,
    taskBlocks,
    shiftGroups,
    employeeSkills,
    customKeywordsDict,
    shortIdsToEntityNamesDicts
  );

  return newAllocation;
}

function determineAllocation(
  demands,
  demandToAdd,
  demandToRemove,
  existingDemands,
  areas,
  shifts,
  blocks,
  shiftGroups,
  employeeSkills,
  customKeywordsDict,
  shortIdsToEntityNamesDicts
) {
  let subtasks = demands.flatMap((demand) => demand.subtasks);
  subtasks = [...new Set(subtasks)];

  const tasks = demands.flatMap((demand) => demand.tasks);

  const allAllocations = generateAllPossibleAllocations(
    areas,
    shifts,
    subtasks,
    blocks,
    tasks
  );

  const areaShortIdsInDemand = demandToAdd
    ? [...strToArrCommaSeparated(demandToAdd.areas)]
    : [];

  const possibleAllocations =
    areaShortIdsInDemand.length === 0
      ? allAllocations
      : allAllocations.filter((allocation) => {
          const { areaShortId } = extractEntityShortIdsFromAllocation(
            allocation,
            shortIdsToEntityNamesDicts
          );
          if (allocation === "") {
            return true;
          }
          return areaShortIdsInDemand.includes(areaShortId);
        });

  let bestAllocation = null;
  let maxSatisfied = -Infinity;
  let minDurationDifference = Infinity;

  for (const currentAllocation of possibleAllocations) {
    let satisfiedCount = 0;
    let allocation = currentAllocation;
    let [area, rest] = allocation.split(":");

    if (!areas.some(({ shortId }) => shortId === area)) {
      area = null;
    }

    if (rest) {
      allocation = rest;
    }

    const shift = allocation.split("-")[0];
    const shiftDuration = getShiftDuration(shift, shifts);

    let subtasksDuration = 0;
    if (allocation.split("-")[1]) {
      const subtasksList = allocation.split("-")[1].split("/");
      subtasksList.forEach((st) => {
        subtasksDuration += getSubtaskDuration(st, blocks);
      });
      satisfiedCount -= subtasksList.length * 0.01;
    }

    if (
      (demandToAdd &&
        !isDemandSatisfiedByAllocation(
          demandToAdd,
          currentAllocation,
          shiftGroups,
          employeeSkills,
          customKeywordsDict
        )) ||
      (demandToRemove &&
        isDemandSatisfiedByAllocation(
          demandToRemove,
          currentAllocation,
          shiftGroups,
          employeeSkills,
          customKeywordsDict
        ))
    ) {
      continue;
    }

    const relevantDemands = allocationToDemands(
      currentAllocation,
      demands,
      shiftGroups,
      employeeSkills,
      customKeywordsDict
    );

    relevantDemands.forEach((demand) => {
      if (getIds(existingDemands).includes(demand.id)) {
        satisfiedCount++;
      } else {
        satisfiedCount--;
      }
    });

    if (
      satisfiedCount > maxSatisfied ||
      (satisfiedCount === maxSatisfied &&
        Math.abs(shiftDuration - subtasksDuration) < minDurationDifference)
    ) {
      maxSatisfied = satisfiedCount;
      minDurationDifference = Math.abs(shiftDuration - subtasksDuration);
      bestAllocation = currentAllocation;
    }
  }

  return bestAllocation;
}

function getShiftDuration(shiftShortId, shifts) {
  for (let s of shifts) {
    if (s.shortId === shiftShortId) {
      const start = parseTime(s.startTime);
      const end = parseTime(s.finishTime);
      return end - start;
    }
  }
  return 0;
}

function getSubtaskDuration(subtask, blocks) {
  const blockName = subtask.split(" ")[1];
  const start = getStartTime(blockName, blocks);
  const end = getFinishTime(blockName, blocks);
  return end - start;
}

function getStartTime(blockName, blocks) {
  for (let block of blocks) {
    if (block.name === blockName) {
      return parseTime(block.startTime);
    }
  }
  return null;
}

function getFinishTime(blockName, blocks) {
  for (let block of blocks) {
    if (block.name === blockName) {
      return parseTime(block.finishTime);
    }
  }
  return null;
}

let cache = {};

function parseTime(timeToParse) {
  if (cache[timeToParse] !== undefined) {
    return cache[timeToParse];
  }
  const parsedTime = parseISO(`1970-01-01T${timeToParse}Z`);
  const hours = parsedTime.getUTCHours();
  const minutes = parsedTime.getUTCMinutes();
  const result = hours * 60 + minutes;
  cache[timeToParse] = result;
  return result;
}

function generateAllPossibleAllocations(
  areas,
  shifts,
  subtasks,
  blocks,
  tasks
) {
  const allocationsWithoutArea = getShortIds(shifts);

  shifts.forEach((shift) => {
    const shiftStartTime = parseTime(shift.startTime);
    const shiftFinishTime = parseTime(shift.finishTime);

    tasks.forEach((task) => {
      allocationsWithoutArea.push(`${shift.shortId}-${task}`);
    });

    subtasks.forEach((subtask1, i) => {
      const block1StartTime = getStartTime(subtask1.blockName, blocks);
      const block1FinishTime = getFinishTime(subtask1.blockName, blocks);

      // Check for single subtask allocation validity
      if (
        shiftStartTime <= block1StartTime &&
        shiftFinishTime >= block1FinishTime
      ) {
        allocationsWithoutArea.push(`${shift.shortId}-${subtask1.shortId}`);
      }

      for (let j = i + 1; j < subtasks.length; j++) {
        // We start j from i + 1 to avoid duplicate combinations
        const block2StartTime = getStartTime(subtasks[j].blockName, blocks);
        const block2FinishTime = getFinishTime(subtasks[j].blockName, blocks);

        // Sort subtasks by start time
        if (
          block1StartTime < block2StartTime &&
          shiftStartTime <= block1StartTime &&
          shiftFinishTime >= block2FinishTime
        ) {
          allocationsWithoutArea.push(
            `${shift.shortId}-${subtask1.shortId}/${subtasks[j].shortId}`
          );
        } else if (
          block1StartTime > block2StartTime &&
          shiftStartTime <= block2StartTime &&
          shiftFinishTime >= block1FinishTime
        ) {
          allocationsWithoutArea.push(
            `${shift.shortId}-${subtasks[j].shortId}/${subtask1.shortId}`
          );
        }
      }
    });
  });

  const allocationsWithArea = [];
  const areaShortIds = getShortIds(areas);
  for (const areaShortId of areaShortIds) {
    for (const allocation of allocationsWithoutArea) {
      allocationsWithArea.push(`${areaShortId}:${allocation}`);
    }
  }

  const allocations =
    areas.length > 0 ? allocationsWithArea : allocationsWithoutArea;

  allocations.push("");

  return allocations;
}

/**
 *
 * @param {*} gridBefore - Grid before update
 * @param {*} gridAfter - Grid after update
 * @param {*} exceptions - Cells to exclude in the form of [{rowName, dayIndex, blockIndex}, ...]
 */
export function detectGridChange(
  employeeID,
  gridBefore,
  gridAfter,
  exceptions,
  hiddenRows
) {
  const differences = [];
  const copiedGridBefore = deepCopyObject(gridBefore);
  const copiedGridAfter = deepCopyObject(gridAfter);

  for (const exception of exceptions) {
    const { rowName, dayIndex, blockIndex } = exception;
    copiedGridBefore[rowName][dayIndex][blockIndex] = null;
    copiedGridAfter[rowName][dayIndex][blockIndex] = null;
  }

  for (const rowName in copiedGridBefore) {
    const rowBefore = copiedGridBefore[rowName];
    const rowAfter = copiedGridAfter[rowName];
    if (_.isEqual(rowBefore, rowAfter) || hiddenRows.includes(rowName)) {
      continue;
    }

    for (const dayIndex in rowBefore) {
      const dayBefore = rowBefore[dayIndex];
      const dayAfter = rowAfter[dayIndex];
      if (_.isEqual(dayBefore, dayAfter)) {
        continue;
      }

      for (const blockIndex in dayBefore) {
        const blockBefore = dayBefore[blockIndex];
        const blockAfter = dayAfter[blockIndex];
        if (_.isEqual(blockBefore, blockAfter)) {
          continue;
        }

        let isAllocationRemoved = true;
        if (blockAfter.includes(employeeID)) {
          isAllocationRemoved = false;
        }

        differences.push({
          blockIndex: Number(blockIndex),
          dayIndex: Number(dayIndex),
          rowName: rowName,
          isAllocationRemoved,
        });
      }
    }
  }

  return differences;
}
