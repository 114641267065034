import { useCallback, useEffect, useState } from "react";
import { getEmailRegex } from "../../../utils/generalUtils/regex";
import {
  getEmployeesAuthInfoInLocationGroup,
  USER_STATUS,
} from "../../locations/service/employeeAuthInfo";
import {
  addEmployeeToGroup,
  addGroup,
  detachUserFromGroup,
  getGroup,
  resendInvitation,
} from "./adminQuery";

export function useEmployeesAccess(locationID) {
  const [employeesAuthInfo, setEmployeesAuthInfo] = useState([]);
  const [processingUsers, setProcessingUsers] = useState([]);

  const updateEmployeesAuthInfo = useCallback(async () => {
    const empsAuthInfo = await getEmployeesAuthInfoInLocationGroup(locationID);
    setEmployeesAuthInfo(empsAuthInfo);
  }, [locationID]);

  useEffect(() => {
    if (locationID) {
      updateEmployeesAuthInfo();
    }
  }, [locationID, updateEmployeesAuthInfo]);

  const handleUserAccessAction = async (
    selectedUsers,
    action,
    checkUserEligibleForAction
  ) => {
    const eligibleUsers = [];
    const successfulUsers = [];
    const failedUsers = [];

    // Select eligible users for actions
    for (const user of selectedUsers) {
      if (checkUserEligibleForAction(user)) {
        eligibleUsers.push(user);
      }
    }

    if (eligibleUsers.length === 0) {
      return {
        successfulUsers,
        failedUsers,
      };
    }

    setProcessingUsers(eligibleUsers.map((user) => user.employeeID));

    for (const user of eligibleUsers) {
      try {
        await action(user);
        successfulUsers.push(user);
      } catch (err) {
        failedUsers.push(user);
      }
    }

    await updateEmployeesAuthInfo();
    setProcessingUsers([]);

    return {
      successfulUsers,
      failedUsers,
    };
  };

  const handleInvitations = async (selectedUsers) => {
    const userGroupRes = await getGroup(locationID);
    const userGroup = await userGroupRes.body.json();
    if (userGroup.type && userGroup.type === "ResourceNotFound") {
      await addGroup(locationID);
    }

    const checkUserCanBeInvited = (user) =>
      user.status === USER_STATUS.NOT_INVITED &&
      getEmailRegex().test(user.email);

    const inviteUser = async (user) => {
      await addEmployeeToGroup(user.email, locationID);
    };

    const results = await handleUserAccessAction(
      selectedUsers,
      inviteUser,
      checkUserCanBeInvited
    );
    return results;
  };

  const handleDeactivations = async (selectedUsers, callback = null) => {
    const employeeIDs = selectedUsers.map((employee) => employee.employeeID);
    const checkUserCanBeDeactivated = (user) => {
      const employeeInLocation = employeesAuthInfo.find(
        (emp) => emp.email === user.email
      );
      if (employeeInLocation) {
        return true;
      }
      return false;
    };

    const deactivateUser = async (user) => {
      await detachUserFromGroup(user.email, locationID);
    };

    const results = handleUserAccessAction(
      selectedUsers,
      deactivateUser,
      checkUserCanBeDeactivated
    );

    if (callback) {
      callback(employeeIDs);
    }
    return results;
  };

  const handleResendInvitations = async (selectedUsers) => {
    const checkUserCanBeReInvited = (user) =>
      user.email && user.status === USER_STATUS.PENDING;
    const resentInviteToUser = async (user) => {
      await resendInvitation(user.email);
    };

    const results = handleUserAccessAction(
      selectedUsers,
      resentInviteToUser,
      checkUserCanBeReInvited
    );
    return results;
  };

  return {
    employeesAuthInfo,
    processingUsers,
    handleInvitations,
    handleDeactivations,
    handleResendInvitations,
    updateEmployeesAuthInfo,
  };
}
