import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./OnBoardingCheckList.module.css";
import {
  faArrowPointer,
  faCaretDown,
  faCircleCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import defaultBanner from "../../../../assets/images/tour-banner-default.png";
import completedBanner from "../../../../assets/images/tour-banner-congrats.svg";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import React, { useEffect, useMemo, useState } from "react";
import {
  getNextIncompleteOnboardingSection,
  getOnboardingTaskIDs,
  onBoardingActivities,
  getOnboardingSections,
} from "../../service/onBoarding";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useOnBoardingStore } from "../../../../globalStore/appStore";
import { useRedirect } from "../../../../utils/routeUtils/redirect";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { containsAllElements } from "../../../../utils";
import TourTooltip from "../TourTooltip/TourTooltip";

let steps = [];
function setSteps(onBoardingActivityID) {
  const activity = onBoardingActivities.find(
    (activity) => activity.id === onBoardingActivityID
  );
  steps = activity.steps;
}

const CollapsibleSection = ({
  onboardingSection,
  setOpenedSectionID,
  openedSectionID,
  redirectAndStartTour,
  completedOnboardingTasks,
  markOnboardingTasksAsCompleted,
}) => {
  const {
    id: sectionID,
    header,
    bodies,
    tasksToBeCompleted,
  } = onboardingSection;

  const isSectionCompleted = tasksToBeCompleted
    ? containsAllElements(tasksToBeCompleted, completedOnboardingTasks)
    : false;

  const { title, fontAwesomeIcon } = header;
  const isOpen = openedSectionID === sectionID;

  return (
    <div className={styles.section}>
      <div
        className={styles.alwaysVisible}
        onClick={() => {
          if (sectionID === openedSectionID) {
            setOpenedSectionID(null);
            return;
          }
          setOpenedSectionID(sectionID);
        }}
      >
        <div className={styles.alwaysVisibleLeftWrapper}>
          <FontAwesomeIcon
            icon={isSectionCompleted ? faCircleCheck : fontAwesomeIcon}
            className={`${styles.sectionIcon} ${
              isSectionCompleted && styles.completedSectionIcon
            }`}
          />
          <p
            className={`${styles.sectionTitle} ${
              isSectionCompleted && styles.completedSectionLabel
            }`}
          >
            {title}
          </p>
        </div>
        <button className={`${styles.expandBtn} ${isOpen && styles.upArrow}`}>
          {<FontAwesomeIcon icon={faCaretDown} />}
        </button>
      </div>
      <div className={`${styles.collapsible} ${!isOpen && styles.collapsed}`}>
        {bodies.map((body, idx) => {
          const { description, todos, button } = body;
          const shouldShowDivider =
            bodies.length > 0 && idx !== bodies.length - 1;
          return (
            <React.Fragment key={idx}>
              <div className={styles.sectionDesc}>{description}</div>
              <ul className={styles.actionList}>
                {todos.map((todo, idx) => {
                  const {
                    title,
                    pageName,
                    tourStartIndex,
                    activityID,
                    taskID,
                  } = todo;

                  const isTaskCompleted =
                    completedOnboardingTasks.includes(taskID);
                  return (
                    <li key={idx} className={styles.actionListItem}>
                      <div className={styles.actionListItemLeft}>
                        <div className={styles.listItemIconContainer}>
                          <FontAwesomeIcon
                            icon={
                              isTaskCompleted ? faCircleCheck : faArrowPointer
                            }
                            className={`${
                              isTaskCompleted
                                ? styles.completedTaskIcon
                                : styles.cursorIcon
                            }`}
                          />
                        </div>
                        <span
                          className={`${
                            isTaskCompleted && styles.completedTaskLabel
                          }`}
                        >
                          {title}
                        </span>
                      </div>
                      <button
                        className={styles.actionListItemBtn}
                        onClick={() => {
                          setSteps(activityID);
                          redirectAndStartTour(pageName, tourStartIndex);
                        }}
                      >
                        Go to
                      </button>
                    </li>
                  );
                })}
              </ul>
              {button && (
                <BasicButton
                  color="#219ec9"
                  hoverColor="#1f91b7"
                  customStyle={{
                    borderRadius: "10px",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (button.newTab) {
                      window.open(button.pageName, "_blank");
                    } else {
                      setSteps(button.activityID);
                      redirectAndStartTour(
                        button.pageName,
                        button.tourStartIndex
                      );
                    }
                    if (button.completedTaskIdOnClick) {
                      markOnboardingTasksAsCompleted([
                        button.completedTaskIdOnClick,
                      ]);
                    }
                  }}
                >
                  {button.label}
                </BasicButton>
              )}
              <div
                className={`${styles.divider} ${
                  shouldShowDivider && styles.visibleDivider
                }`}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const OnBoardingCheckList = ({ location, plan }) => {
  const {
    setIsCheckListOpen,
    tourRunning,
    setIsTourRunning,
    stepIndex,
    setStepIndex,
    setIsActionPerformed,
  } = useOnBoardingStore();
  const [hideCheckList, setHideCheckList] = useState(false);

  useEffect(() => {
    const viewportWidth = window.innerWidth;
    if (tourRunning && viewportWidth <= 1140) {
      setHideCheckList(true);
    } else {
      setHideCheckList(false);
    }
  }, [tourRunning, setIsCheckListOpen]);

  const { markOnboardingTasksAsCompleted } = useLocationMutation(location);
  const completedOnboardingTasks = useMemo(
    () =>
      location.completedOnboardingTasks
        ? location.completedOnboardingTasks
        : [],
    [location]
  );

  const allOnboardingTaskIDs = getOnboardingTaskIDs();

  const completedAllOnboardingTasks = containsAllElements(
    allOnboardingTaskIDs,
    completedOnboardingTasks
  );

  useEffect(() => {
    setOpenedSectionID(
      getNextIncompleteOnboardingSection(completedOnboardingTasks, plan)
    );
  }, [completedOnboardingTasks, plan]);

  const [openedSectionID, setOpenedSectionID] = useState(() => {
    const openedSectionID = getNextIncompleteOnboardingSection(
      completedOnboardingTasks,
      plan
    );
    if (openedSectionID) {
      return openedSectionID;
    }
    return null;
  });

  const redirect = useRedirect();

  const redirectAndStartTour = (pageName, tourStartIndex = 0) => {
    redirect({
      pageName,
      query: {
        "location-id": location.id,
      },
      callback: () => {
        setTimeout(() => {
          setStepIndex(tourStartIndex);
          setIsTourRunning(true);
        }, 400);
      },
    });
  };

  const handleJoyrideCallback = (data) => {
    const { status, index, action, type, step } = data;

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    const { shouldCompleteOnAction } = step.customData;

    if (!shouldCompleteOnAction) {
      setIsActionPerformed(false);
    }

    if (action === ACTIONS.CLOSE) {
      setIsTourRunning(false);
      setStepIndex(0);
      return;
    }

    if (finishedStatuses.includes(status)) {
      setIsTourRunning(false);
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <aside
      className={styles.container}
      style={{
        display: hideCheckList ? "none" : "block",
      }}
    >
      <Joyride
        tooltipComponent={TourTooltip}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={tourRunning}
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        stepIndex={stepIndex}
        locale={{
          last: "OK",
        }}
      />
      <button
        className={styles.closeBtn}
        onClick={() => setIsCheckListOpen(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <p className={styles.title}>Get Started With RosterLab</p>
      <img
        src={completedAllOnboardingTasks ? completedBanner : defaultBanner}
        alt="default tour banner"
      />
      <div className={styles.onboardingSections}>
        {getOnboardingSections(plan).map((onboardingSection) => (
          <CollapsibleSection
            key={onboardingSection.id}
            onboardingSection={onboardingSection}
            setOpenedSectionID={setOpenedSectionID}
            openedSectionID={openedSectionID}
            redirectAndStartTour={redirectAndStartTour}
            completedOnboardingTasks={completedOnboardingTasks}
            markOnboardingTasksAsCompleted={markOnboardingTasksAsCompleted}
          />
        ))}
      </div>
    </aside>
  );
};

export default OnBoardingCheckList;
