import { DateTime } from "..";

// ########## Requet Manager Validators ##########
export const checkAllNamesAreFilledIn = (names) => {
  for (const name of names) {
    if (name === "") {
      return false;
    }
  }
  return true;
};

export const checkAllRequestsHasValidDateRange = (requests) => {
  const dates = requests.map((request) => [request.from, request.to]);
  for (const date of dates) {
    if (
      DateTime.isValidDateString(date[0]) &&
      DateTime.isValidDateString(date[1])
    ) {
      const startDate = new DateTime(date[0]);
      const finishDate = new DateTime(date[1]);

      if (startDate.date > finishDate.date) {
        return false;
      }
    }
  }
  return true;
};

export const checkAllRequestHasValidStartAndFinishDate = (
  requests,
  startDate,
  finishDate
) => {
  const dates = requests.map((request) => [request.from, request.to]);
  const startDateObj = new DateTime(startDate);
  const finishDateObj = new DateTime(finishDate);
  for (const date of dates) {
    if (
      DateTime.isValidDateString(date[0]) &&
      DateTime.isValidDateString(date[1])
    ) {
      const dateObjOne = new DateTime(date[0]);
      const dateObjTwo = new DateTime(date[1]);
      if (
        DateTime.getDifferenceInDays(startDateObj, dateObjOne) < 0 ||
        DateTime.getDifferenceInDays(dateObjOne, finishDateObj) < 0 ||
        DateTime.getDifferenceInDays(startDateObj, dateObjTwo) < 0 ||
        DateTime.getDifferenceInDays(dateObjTwo, finishDateObj) < 0
      ) {
        return false;
      }
    }
  }
  return true;
};

export const checkOverlappingDateRangesInRequestManager = (data) => {
  const overlapData = [];
  for (const key in data) {
    const overlaps = DateTime.getOverlappingDateRanges(data[key]);
    const result = overlaps.map((range) => ({
      ...range,
      to: range.to.date,
      from: range.from.date,
    }));

    if (result.length !== 0) {
      overlapData.push(result);
    }
  }
  return overlapData;
};

// ########## Rostered Allocations Validators ##########
export const checkRosteredAllocationsMatchNumDays = (roster) => {
  const numDays = roster.numDays;
  const employees = roster.Employees;

  for (const emp of employees) {
    if (
      !emp.RosteredAllocations ||
      emp.RosteredAllocations.length !== numDays
    ) {
      return false;
    }
  }

  return true;
};

export const isRosterDateValid = (date) => {
  if (isNaN(date)) {
    return false;
  }
  return true;
};

export const isRosterNameValid = (name) => {
  return name.length <= 36;
};

export const checkInvalidAllocationValueExists = (employees) => {
  for (const employee of employees) {
    const preferences = employee.Days;
    const preferencesRecurring = employee.DaysRecurring;
    const fixedShifts = employee.Allocations;
    const fixedShiftsRecurring = employee.AllocationsRecurring;
    const rosteredAllocations = employee.RosteredAllocations;
    const history = employee.History;

    const allValues = [
      ...preferences,
      ...preferencesRecurring,
      ...fixedShifts,
      ...fixedShiftsRecurring,
      ...rosteredAllocations,
      ...history,
    ];

    if (allValues.includes(null)) {
      return true;
    }
  }
  return false;
};
