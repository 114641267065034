/**
 * Calculates the number of pixels an element is out of the screen horizontally.
 * @param {HTMLElement} element - The HTML element whose out-of-screen pixels need to be calculated.
 * @returns {number} - The total number of pixels the element is out of the screen horizontally.
 */
export function getOutOfScreenPixels(element) {
  const rect = element.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const outOfScreenLeft = Math.max(0, -rect.left);
  const outOfScreenRight = Math.max(0, rect.right - viewportWidth);
  const totalOutOfScreenPixels = outOfScreenLeft + outOfScreenRight;

  return totalOutOfScreenPixels;
}

export function getDistanceBetweenElementLeftAndContainerRight(
  element,
  container
) {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const distance = containerRect.right - elementRect.left;
  return distance;
}
