import { DateTime } from "../dataTypesUtils/DateTime";
import { getRightColumnCellStyles } from "./gridStyleGetters";

export function getDayNumFromColFieldName(colName) {
  let day = parseInt(colName.substring(1));
  return day - 1;
}

export function isDayColumn(colName) {
  return !isNaN(getDayNumFromColFieldName(colName));
}

//
export const getDayColumns = (numDays) => {
  let dayColumns = [];
  for (let i = 0; i < numDays; i++) {
    let dayName = "d" + (i + 1).toString();
    dayColumns.push(dayName);
  }
  return dayColumns;
};

export const getDatesColumnDefs = (numDays, startDate) => {
  const datesColumnDefs = [];
  for (let i = 0; i < numDays; i = i + 7) {
    const weekNum = Math.floor(i / 7) + 1;
    const month = new DateTime(startDate).addDays(i).getMonth();
    const weekColDef = {
      headerName: `${month} - Week ${weekNum}`,
    };

    const dayColDef = [];
    for (let j = i + 1; j <= i + 7 && j <= numDays; j++) {
      const date = new DateTime(startDate).addDays(j - 1);
      const DoW = date.getDayOfWeek("dd");
      const day = date.getDay(0);
      dayColDef.push({
        headerName: day,
        groupId: "dayOfWeekColGroup",
        children: [
          {
            headerName: DoW,
            field: `d${j}`,
            headerClass: (params) => {
              const headerName = params.colDef.headerName;
              const dayOfWeek = headerName.substring(0, 2);
              if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
                return "weekend-header";
              }
            },
            cellClassRules: {
              "saturday-cell": (params) => {
                if (params.colDef.headerName === "Sa") {
                  return true;
                }
              },
              "sunday-cell": (params) => {
                if (params.colDef.headerName === "Su") {
                  return true;
                }
              },
            },
          },
        ],
      });
    }
    weekColDef.children = dayColDef;
    datesColumnDefs.push(weekColDef);
  }
  return datesColumnDefs;
};

export const getGlobalDatesColumnDefs = (monthsAndDays, startDate) => {
  const columns = monthsAndDays.map((month, monthIdx) => {
    return {
      field: month.name,
      groupId: `monthGroup${monthIdx}`,
      children: month.days.map((colName, dayIdx) => {
        const offset = getDayNumFromColFieldName(colName);
        const DoW = new DateTime(startDate).addDays(offset).getDayOfWeek("dd");
        return {
          headerName: new DateTime(startDate).getDay(offset),
          groupId: `dayGroup_${monthIdx}_${dayIdx}`,
          children: [
            {
              headerName: DoW,
              headerClass: (params) => {
                const dayOfWeek = params.colDef.headerName;
                if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
                  return "header";
                }
              },
              field: colName,
            },
          ],
        };
      }),
    };
  });
  return columns;
};

export const generateRightPinnedStatsColumGroup = (
  headerName,
  groupId,
  childEntities,
  childFieldNameSuffix,
  customChildColumn = null,
  childValueGetter,
  minRuleValues,
  maxRuleValues,
  colCustomWidthStore,
  headerNameSuffix = ""
) => {
  if (childEntities.length === 0 && !customChildColumn) {
    return null;
  }
  let children = childEntities.map(({ name, shortId }) => {
    return {
      colId: `${shortId}${childFieldNameSuffix}`,
      field: `${shortId}${childFieldNameSuffix}`,
      headerName: `${name}${headerNameSuffix}`,
      pinned: "right",
      width:
        colCustomWidthStore &&
        colCustomWidthStore[shortId + childFieldNameSuffix]
          ? colCustomWidthStore[shortId + childFieldNameSuffix]
          : 80,
      valueGetter: (params) => childValueGetter(params, shortId),
      sortable: true,
      cellStyle: getRightColumnCellStyles(minRuleValues, maxRuleValues),
    };
  });

  if (customChildColumn) {
    customChildColumn.cellStyle = getRightColumnCellStyles(
      minRuleValues,
      maxRuleValues
    );
    children.push(customChildColumn);
  }

  const colDefs = {
    headerName,
    groupId,
    children,
  };

  return colDefs;
};

export const getDemandsDayColumns = (periodSelection, numDays) => {
  const dayColumns = [];
  if (periodSelection === "whole") {
    for (let i = 1; i < numDays + 1; i++) {
      dayColumns.push(i.toString());
    }
  } else if (periodSelection === "fortnight") {
    for (let i = 1; i <= 14; i++) {
      dayColumns.push(i.toString());
    }
  } else if (periodSelection === "week") {
    for (let i = 1; i <= 7; i++) {
      dayColumns.push(i.toString());
    }
  }
  return dayColumns;
};

export const getHistoryDayColumns = (HISTORY_LENGTH) => {
  let historyColumns = [];
  for (let i = -HISTORY_LENGTH; i <= -1; i++) {
    let dayName = "d" + i.toString();
    historyColumns.push(dayName);
  }
  return historyColumns;
};

export const getRecurringColumns = (recurringLength) => {
  let recurringColumns = [];
  for (let i = 0; i < recurringLength; i++) {
    let dayName = "r" + (i + 1).toString();
    recurringColumns.push(dayName);
  }
  return recurringColumns;
};
