import { useState } from "react";
import { ColorCodingButton } from "../RosterActionComponents/RosterActionComponents";
import styles from "./ShiftViewActionBar.module.css";
import ShiftViewRowFilter from "../ShiftViewRowFilter/ShiftViewRowFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { PLAN } from "../../../../auth/service/auth";
import { useUserStore } from "../../../../../globalStore/appStore";

const SHIFT_VIEW_MODAL_NAMES = {
  rowVisibility: "row-visibility",
};

function ShiftViewActionBar({
  toggleColorCodingModal,
  rowNames,
  updateShiftViewHiddenRows,
  hiddenRows,
  rosterID,
  areas,
  shifts,
  tasks,
  shiftGroups,
}) {
  const [displayedModal, setDisplayedModal] = useState(null);

  const toggleModal = (modalName) => {
    if (displayedModal === modalName) {
      setDisplayedModal(null);
      return;
    }
    setDisplayedModal(modalName);
  };

  const { plan } = useUserStore();

  return (
    <>
      <ShiftViewRowFilter
        rowNames={rowNames}
        isShowing={displayedModal === SHIFT_VIEW_MODAL_NAMES.rowVisibility}
        toggleModal={() => toggleModal(SHIFT_VIEW_MODAL_NAMES.rowVisibility)}
        updateShiftViewHiddenRows={updateShiftViewHiddenRows}
        hiddenRows={hiddenRows}
        rosterID={rosterID}
        areas={areas}
        shifts={shifts}
        tasks={tasks}
        shiftGroups={shiftGroups}
      />
      <div className={`${styles.actionBar} ${styles.fullDoubleBorder}`}>
        {plan !== PLAN.COORDINATOR && (
          <button
            className={styles.button}
            onClick={() => toggleModal(SHIFT_VIEW_MODAL_NAMES.rowVisibility)}
          >
            <FontAwesomeIcon icon={faEye} className={styles.icon} />
            Show / Hide
          </button>
        )}
        <ColorCodingButton
          buttonStyle={{
            color: "black",
            fontWeight: "bold",
          }}
          iconStyle={{
            color: "#219ec9",
            fontSize: "1.4em",
          }}
          onClick={toggleColorCodingModal}
        />
      </div>
    </>
  );
}

export default ShiftViewActionBar;
