import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import ShadowButton from "../../../../../components/elements/ShadowButton/ShadowButton";
import ViewRangeSwitcher from "../../../../../components/elements/ViewRangeSwitcher/ViewRangeSwitcher";
import Layout from "../../../../../components/layouts/Layout/Layout";
import {
  deepCopyObject,
  isPartialEmptyStringFilledArray,
  allElementsAreEmptyStr,
  allElementsAreInteger,
  DateTime,
  parseMinMaxShiftDemandsToGridFormat,
  getShiftDemandFromGrid,
  hideColumnsOutsideViewRangeByDayNums,
  getDemandsDayColumns,
} from "../../../../../utils";
import { getUpdatedDemandsFromStaffingLevelModal } from "../../../../store/service/roster/rosterStoreApi";
import DataEntryTable from "../../../components/DataEntryTable/DataEntryTable";
import styles from "./ShiftStaffingLevelModal.module.css";
import { generatePatternOutput } from "../../../../../utils/queryUtils/monthViewUtils";

const getViewRangeOptions = (numDays) => {
  const maxWeekNum = numDays / 7;
  const options = [];
  for (let week = 1; week <= maxWeekNum; week++) {
    options.push({ label: `Week ${week}`, value: week });
  }
  return options;
};

const getDisplayedStartAndFinishDateNums = (page, range) => {
  const displayedStartDay = range * page - (range - 1);
  const displayedLastDay = range * page;
  return { displayedStartDay, displayedLastDay };
};

const ShiftStaffingLevelModal = ({
  demands,
  demandStartTime,
  demandFinishTime,
  shiftStartTime,
  shiftFinishTime,
  duration,
  toggleModal,
  minDemandValues,
  maxDemandValues,
  startDate,
  numDays,
  selectedWeek,
  refreshRosterGrid,
  rosterStartDate,
  rosterFinishDate,
  locationStartDate,
  isShiftGroup,
  updateDemands,
  shifts,
  shiftGroups,
  reservedShiftKeywords,
  locationDefaultNumDays,
  shift,
  skill,
}) => {
  const shiftShortId = shift ? shift.shortId : null;
  const shiftName = shift ? shift.name : null;
  const skillShortId = skill ? skill.shortId : null;
  const skillName = skill ? skill.name : null;

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [viewRange, setViewRange] = useState(selectedWeek);
  const [isWeeklyRepeating, setIsWeeklyRepeating] = useState(true);
  const [displayedStartDate, setDisplayedStartDate] = useState(null);
  const [displayedLastDate, setDisplayedLastDate] = useState(null);
  const [shouldShowWarningBox, setShouldShowWarningBox] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const viewRangeOptions = getViewRangeOptions(numDays);

  const getRecurringDemandValues = useCallback(() => {
    const visibleColumns = columnApi.getAllDisplayedColumns();
    const visibleDayColIDs = visibleColumns
      .map((col) => col.colId)
      .filter((id) => id !== "shiftName" && id !== "minOrMax");
    const rowData = [];
    gridApi.forEachNode((node) => rowData.push(node));
    const maxCellValues = rowData[0].data;
    const minCellValues = rowData[1].data;

    const recurringMinDemandValues = [];
    const recurringMaxDemandValues = [];

    visibleDayColIDs.forEach((id) =>
      recurringMaxDemandValues.push(maxCellValues[id])
    );
    visibleDayColIDs.forEach((id) =>
      recurringMinDemandValues.push(minCellValues[id])
    );
    return { recurringMinDemandValues, recurringMaxDemandValues };
  }, [columnApi, gridApi]);

  const updateSaveButtonStatus = useCallback(() => {
    const dataFromGrid = getShiftDemandFromGrid(
      gridApi,
      numDays,
      shiftShortId,
      skillShortId
    );
    let applicableMaxDemandValues;
    let applicableMinDemandValues;
    if (isWeeklyRepeating) {
      const { recurringMaxDemandValues, recurringMinDemandValues } =
        getRecurringDemandValues();
      applicableMaxDemandValues = recurringMaxDemandValues;
      applicableMinDemandValues = recurringMinDemandValues;
    } else {
      applicableMaxDemandValues = dataFromGrid[0].values;
      applicableMinDemandValues = dataFromGrid[1].values;
    }

    const partiallyFilledDemandsExist =
      isPartialEmptyStringFilledArray(applicableMaxDemandValues) ||
      isPartialEmptyStringFilledArray(applicableMinDemandValues);

    setIsSaveButtonDisabled(partiallyFilledDemandsExist);
  }, [
    getRecurringDemandValues,
    gridApi,
    isWeeklyRepeating,
    numDays,
    shiftShortId,
    skillShortId,
  ]);

  useEffect(() => {
    if (gridApi && columnApi) {
      updateSaveButtonStatus(gridApi);
    }
  }, [gridApi, columnApi, updateSaveButtonStatus]);

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells({ force: true });
    }
  }, [gridApi, shouldShowWarningBox]);

  /**
   * Returns error type when there is an error. Else, return null
   */
  const updateShiftDemands = () => {
    const dataFromGrid = getShiftDemandFromGrid(
      gridApi,
      numDays,
      shiftShortId,
      skillShortId
    );

    let maxDemandsInfo;
    let minDemandsInfo;

    if (isWeeklyRepeating) {
      const { recurringMaxDemandValues, recurringMinDemandValues } =
        getRecurringDemandValues();

      const resultingMaxDemandValues = generatePatternOutput(
        recurringMaxDemandValues,
        rosterStartDate,
        numDays,
        locationStartDate,
        locationDefaultNumDays
      );
      const resultingMinDemandValues = generatePatternOutput(
        recurringMinDemandValues,
        rosterStartDate,
        numDays,
        locationStartDate,
        locationDefaultNumDays
      );

      maxDemandsInfo = {
        ...dataFromGrid[0],
        values: resultingMaxDemandValues,
      };
      minDemandsInfo = {
        ...dataFromGrid[1],
        values: resultingMinDemandValues,
      };
    } else {
      maxDemandsInfo = dataFromGrid[0];
      minDemandsInfo = dataFromGrid[1];
    }

    if (
      allElementsAreEmptyStr(maxDemandsInfo.values) &&
      allElementsAreEmptyStr(minDemandsInfo.values)
    ) {
      return null;
    }

    const isSettingMaxDemandsOnly =
      allElementsAreEmptyStr(minDemandsInfo.values) &&
      allElementsAreInteger(maxDemandsInfo.values);

    const isSettingMinDemandsOnly =
      allElementsAreEmptyStr(maxDemandsInfo.values) &&
      allElementsAreInteger(minDemandsInfo.values);

    if (isSaveButtonDisabled) {
      return "empty-demand-values";
    }

    // If demand start time does not exist, use shift start time
    const startTime = demandStartTime ? demandStartTime : shiftStartTime;
    const finishTime = demandFinishTime ? demandFinishTime : shiftFinishTime;

    const newMaxDemands = deepCopyObject(maxDemandsInfo);
    const newMinDemands = deepCopyObject(minDemandsInfo);

    if (!isSettingMaxDemandsOnly && !isSettingMinDemandsOnly) {
      for (let i in newMaxDemands.values) {
        if (newMaxDemands.values[i] < newMinDemands.values[i]) {
          const temp = newMaxDemands.values[i];
          newMaxDemands.values[i] = newMinDemands.values[i];
          newMinDemands.values[i] = temp;
        }
      }
    }

    let resultingDemandsInfo = {};

    if (isSettingMaxDemandsOnly) {
      resultingDemandsInfo.maxDemands = newMaxDemands;
    } else if (isSettingMinDemandsOnly) {
      resultingDemandsInfo.minDemands = newMinDemands;
    } else {
      resultingDemandsInfo.maxDemands = newMaxDemands;
      resultingDemandsInfo.minDemands = newMinDemands;
    }

    const updatedDemands = getUpdatedDemandsFromStaffingLevelModal(
      resultingDemandsInfo,
      startTime,
      finishTime,
      isShiftGroup,
      numDays,
      shifts,
      shiftGroups,
      demands,
      reservedShiftKeywords,
      locationStartDate,
      rosterStartDate,
      locationDefaultNumDays,
      shiftShortId,
      skillShortId
    );

    updateDemands(updatedDemands);
    return null;
  };

  const removeShiftDemands = () => {
    const dataFromGrid = getShiftDemandFromGrid(gridApi, numDays, shiftShortId);
    const maxDemandFromGrid = dataFromGrid[0];
    const minDemandFromGrid = dataFromGrid[1];
    const startTime = demandStartTime ? demandStartTime : shiftStartTime;
    const finishTime = demandFinishTime ? demandFinishTime : shiftFinishTime;

    const maxDemandsToRemove = demands.filter(
      (d) =>
        d.shifts === maxDemandFromGrid.shiftShortId &&
        d.type === maxDemandFromGrid.type &&
        d.startTime === startTime &&
        d.finishTime === finishTime
    );

    const minDemandsToRemove = demands.filter(
      (d) =>
        d.shifts === minDemandFromGrid.shiftShortId &&
        d.type === minDemandFromGrid.type &&
        d.startTime === startTime &&
        d.finishTime === finishTime
    );

    const targetDemandsToRemove = demands.filter(
      (d) =>
        d.shifts === minDemandFromGrid.shiftShortId &&
        d.type === "Target" &&
        d.startTime === startTime &&
        d.finishTime === finishTime
    );

    let demandsToDelete = [];
    if (maxDemandsToRemove.length > 0) {
      demandsToDelete = demandsToDelete.concat(maxDemandsToRemove);
    }
    if (minDemandsToRemove.length > 0) {
      demandsToDelete = demandsToDelete.concat(minDemandsToRemove);
    }
    if (targetDemandsToRemove.length > 0) {
      demandsToDelete = demandsToDelete.concat(targetDemandsToRemove);
    }

    if (demandsToDelete.length > 0) {
      const updatedDemands = demands.filter(
        (demand) => !demandsToDelete.includes(demand)
      );
      updateDemands(updatedDemands);
    }
    toggleModal();
  };

  useEffect(() => {
    if (columnApi) {
      const { displayedStartDay, displayedLastDay } =
        getDisplayedStartAndFinishDateNums(viewRange, 7);
      hideColumnsOutsideViewRangeByDayNums(
        columnApi,
        numDays,
        displayedStartDay,
        displayedLastDay
      );
      setDisplayedStartDate(
        DateTime.addDaysToDate(startDate, displayedStartDay - 1).toFormat("AWS")
      );
      setDisplayedLastDate(
        DateTime.addDaysToDate(startDate, displayedLastDay - 1).toFormat("AWS")
      );
    }
  }, [columnApi, viewRange, setViewRange, numDays, startDate]);

  const onSave = () => {
    const errorType = updateShiftDemands();

    if (errorType === "empty-demand-values") {
      setShouldShowWarningBox(true);
      return;
    }

    refreshRosterGrid();
    toggleModal();
  };

  const onDelete = () => {
    removeShiftDemands();
    refreshRosterGrid();
  };

  const onForward = () => {
    if (viewRange < numDays / 7) {
      setViewRange((prev) => prev + 1);
    }
  };
  const onBackward = () => {
    if (viewRange > 1) {
      setViewRange((prev) => prev - 1);
    }
  };

  const setGridApiToParent = (gridApi) => {
    setGridApi(gridApi);
  };
  const setColumnApiToParent = (columnApi) => {
    setColumnApi(columnApi);
  };

  const shiftDemandsData = useMemo(() => {
    return parseMinMaxShiftDemandsToGridFormat(
      shiftShortId,
      minDemandValues,
      maxDemandValues
    );
  }, [shiftShortId, minDemandValues, maxDemandValues]);

  const startTime = demandStartTime
    ? DateTime.getFormattedTime(demandStartTime, "readable")
    : DateTime.getFormattedTime(shiftStartTime, "readable");
  const finishTime = demandFinishTime
    ? DateTime.getFormattedTime(demandFinishTime, "readable")
    : DateTime.getFormattedTime(shiftFinishTime, "readable");

  const dayColumns = getDemandsDayColumns("whole", numDays);

  const demandsColumnDefs = useMemo(() => {
    return dayColumns.map((day) => {
      const dateToShow = new DateTime(startDate).addDays(parseInt(day) - 1);
      return {
        suppressMovable: true,
        width: 76,
        suppressMenu: true,
        field: day,
        headerName: isWeeklyRepeating
          ? dateToShow.toFormat("DOW")
          : dateToShow.toFormat("simple"),
        suppressSizeToFit: true,
        valueSetter: (params) => {
          const convertedToInt = parseInt(params.newValue);
          if (isNaN(convertedToInt)) {
            return false;
          }
          if (parseInt(params.newValue) === parseInt(params.oldValue)) {
            return false;
          }
          params.data[params.colDef.field] = parseInt(convertedToInt);
          return true;
        },
        cellClassRules: {
          "bottom-warning-border": (params) => {
            if (params.context.shouldShowWarningBox) {
              if (params.data.minOrMax === "min") {
                return true;
              }
            }
          },

          "top-warning-border": (params) => {
            if (params.context.shouldShowWarningBox) {
              if (params.data.minOrMax === "max") {
                return true;
              }
            }
          },

          "right-warning-border": (params) => {
            if (params.context.shouldShowWarningBox) {
              if (parseInt(params.colDef.field) % 7 === 0) {
                return true;
              }
            }
          },

          "left-warning-border": (params) => {
            if (params.context.shouldShowWarningBox) {
              if (parseInt(params.colDef.field) % 7 === 1) {
                return true;
              }
            }
          },
        },
      };
    });
  }, [dayColumns, isWeeklyRepeating, startDate]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Staffing levels",
        field: "minOrMax",
        valueFormatter: (params) => {
          if (params.value === "max") {
            return "Max staffing demands";
          }
          if (params.value === "min") {
            return "Min staffing demands";
          }
        },
        editable: false,
        width: 160,
      },
      ...demandsColumnDefs,
    ],
    [demandsColumnDefs]
  );

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <button className={styles.closeBtn} onClick={toggleModal}>
          <FontAwesomeIcon icon={faXmark} className={styles.closeIcon} />
        </button>
        <h3 className={styles.title}>
          Staffing demands on {shiftName} shift {isShiftGroup && "group "}
          {skillName && skillName + " skill"}
        </h3>
        <div className={styles.content}>
          <div className={styles.upper}>
            <span className={`${styles.field}`}>
              <span className={styles.emph}>
                Shift {isShiftGroup && "group"} name
              </span>
              <span className={styles.value}>{shiftName}</span>
            </span>
            {!isShiftGroup && (
              <>
                <span className={`${styles.field}`}>
                  <span className={styles.emph}>Start</span>
                  <span className={styles.value}>{startTime}</span>
                </span>
                <span className={`${styles.field}`}>
                  <span className={styles.emph}>Finish</span>
                  <span className={styles.value}>{finishTime}</span>
                </span>
                <span className={`${styles.field}`}>
                  <span className={styles.emph}>Duration (hours)</span>
                  <span className={styles.value}>{duration}</span>
                </span>
              </>
            )}
          </div>
          <div className={styles.lower}>
            <span className={styles.emph}>Staffing Level</span>
            <div className={styles.rowOne}>
              {isWeeklyRepeating ? (
                <span className={styles.weeklyLabel}>Weekly</span>
              ) : (
                <div className={styles.viewSwitcher}>
                  <ViewRangeSwitcher
                    onForward={onForward}
                    onBackward={onBackward}
                    viewRangeOptions={viewRangeOptions}
                    onViewRangeChange={(e) => {
                      setViewRange(e.target.value);
                    }}
                    viewRange={viewRange}
                    rosterStartDate={rosterStartDate}
                    rosterFinishDate={rosterFinishDate}
                    displayedStartDate={displayedStartDate}
                    displayedLastDate={displayedLastDate}
                  />
                </div>
              )}
              <label className={styles.repeat}>
                <input
                  type="checkbox"
                  checked={isWeeklyRepeating}
                  onChange={(e) => {
                    setIsWeeklyRepeating(e.target.checked);
                    setShouldShowWarningBox(false);
                  }}
                />
                <span>Repeat weekly</span>
              </label>
            </div>
            <div className={styles.rowTwo}>
              <Layout title={""} headerRight={() => null}>
                <div className={styles.gridContainer}>
                  <DataEntryTable
                    customStyle={{
                      height: "110px",
                    }}
                    getCustomRowId={(params) => params.data.minOrMax}
                    columnDefs={columnDefs}
                    rowData={shiftDemandsData}
                    getContextMenuItems={() => {}}
                    onCellKeyDown={() => {}}
                    gridOptions={{
                      onCellValueChanged: () => {
                        updateSaveButtonStatus();
                        setShouldShowWarningBox(false);
                      },
                    }}
                    setGridApiToParent={setGridApiToParent}
                    setColumnApiToParent={setColumnApiToParent}
                    enableRowDragAnimation={false}
                    context={{ shouldShowWarningBox }}
                  />
                </div>
              </Layout>
            </div>
          </div>
        </div>
        {shouldShowWarningBox && (
          <span className={styles.warning}>
            Warning: Please fill [min]/[max] staffing demands
          </span>
        )}
        <div className={styles.btns}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={onSave}
            onBlockedClick={onSave}
            isBlocked={isSaveButtonDisabled}
          >
            Save
          </ShadowButton>
          <div className={styles.deleteBtnWrapper}>
            <ShadowButton
              backgroundColor="white"
              labelColor="#219ec9"
              hoverColor="#219ec9"
              border="1px solid #219ec9"
              shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
              onClick={onDelete}
            >
              Delete Staffing Level
            </ShadowButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftStaffingLevelModal;
