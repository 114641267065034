import Survey from "../../Survey/Survey";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import styles from "./DesktopSurvey.module.css";

export default function DesktopSurvey({
  location,
  locations,
  isUserAdmin,
  userEmployee,
  customKeywordsUtilObj,
}) {
  return (
    <div className={styles.container}>
      <DesktopHeader
        location={location}
        locations={locations}
        isUserAdmin={isUserAdmin}
      />
      <div className={styles.surveyContainer}>
        <Survey
          userEmployee={userEmployee}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      </div>
    </div>
  );
}
