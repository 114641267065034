import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const HelpButtonCellRenderer = ({ showModal, data }) => {
  const buttonStyle = {
    background: "none",
    border: "none",
    padding: "0",
    cursor: "pointer",
    outline: "none",
    color: "#007bff", // Bootstrap primary color, you can change it to your preference
  };

  const iconStyle = {
    fontSize: "18px",
    color: "#007bff", // Bootstrap primary color, you can change it to your preference
  };

  return (
    <button
      onClick={() => showModal(data)}
      style={buttonStyle}
      className="help-button"
    >
      <FontAwesomeIcon icon={faQuestionCircle} style={iconStyle} />
    </button>
  );
};

export default HelpButtonCellRenderer;
