export const checkGlobalEmployeesOrderIsValid = (employees, orderList) => {
  if (!orderList) {
    return false;
  }
  const globalEmployeeIDs = employees.map((emp) => emp.id);
  if (orderList.length !== globalEmployeeIDs.length) {
    return false;
  }

  for (let i = 0; i < globalEmployeeIDs.length; i++) {
    if (!orderList.includes(globalEmployeeIDs[i])) {
      return false;
    }
  }
  return true;
};
