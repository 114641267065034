import { useCallback, useState } from "react";
import styles from "./SettingItem.module.css";

const AdvSettingItem = ({
  setting,
  isEnabled,
  toggleSettings,
  updateFrontendSettings,
  currentSetting,
  defaultNumDays,
}) => {
  const [inputValues, setInputValues] = useState(() => {
    if (currentSetting) {
      return currentSetting.values;
    }
    return setting.inputFormats.map((item) => item.default);
  });

  const attempUpdateSetting = useCallback(
    (values) => {
      if (!isEnabled) {
        return;
      }
      updateFrontendSettings(setting.name, values);
    },
    [setting, isEnabled, updateFrontendSettings]
  );

  return (
    <>
      <input
        id={setting.name}
        type="checkbox"
        onChange={() => {
          toggleSettings(setting.name, inputValues);
        }}
        checked={isEnabled}
      />
      <label className={styles.settingOption} htmlFor={setting.name}>
        {setting.inputFormats.map((format, idx) => {
          return (
            <span key={idx}>
              {setting.title.split("%")[idx]}
              {format.type === "select" ? (
                <select
                  value={inputValues[idx]}
                  className={styles.optionDropdown}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const updatedInputValues = inputValues.map(
                      (item, itemIdx) => {
                        if (itemIdx === idx) {
                          return inputVal;
                        }
                        return item;
                      }
                    );
                    setInputValues(updatedInputValues);
                    attempUpdateSetting(updatedInputValues);
                  }}
                >
                  {format.options.map((item, idx) => {
                    if (item === "%numWeeks") {
                      const value = `${defaultNumDays / 7} weeks`;
                      return (
                        <option key={idx} value={value}>
                          {value}
                        </option>
                      );
                    }
                    return (
                      <option key={idx} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  value={inputValues[idx]}
                  className={`${styles.optionInput} ${
                    format.type === "number" && styles.numberInput
                  }`}
                  type={format.type}
                  onChange={(e) => {
                    const inputVal = String(e.target.value);
                    const updatedInputValues = inputValues.map(
                      (item, itemIdx) => {
                        if (itemIdx === idx) {
                          return inputVal;
                        }
                        return item;
                      }
                    );
                    setInputValues(updatedInputValues);
                    attempUpdateSetting(updatedInputValues);
                  }}
                  {...format.tagSettings}
                />
              )}

              {setting.title.split("%")[setting.inputFormats.length]}
            </span>
          );
        })}
      </label>
    </>
  );
};
export default AdvSettingItem;
