import _ from "lodash";
import { TABLE_NAMES } from "../../constants";
import {
  parseGlobalEmployeeGridDataToModelFormat,
  parseDemandGridDataToModelFormat,
  parseFixedShiftsGridDataToModelFormat,
  parseGlobalPreferencesGridDataToModelFormat,
  parseHistoryGridDataToModelFormat,
  parsePreferencesGridDataToModelFormat,
  parseRosterTableGridDataToModelFormat,
} from "./dataFormatConverter/gridDataToModelFormat";

export const compareTwoGridStates = (preState, postState) => {
  // Find updated rows
  const updatedRows = [];
  preState.forEach((preRow, idx) => {
    const postRow = postState[idx];
    if (!_.isEqual(preRow, postRow)) {
      updatedRows.push({
        preState: preRow,
        postState: postRow,
      });
    }
  });

  // Find updated columns
  let updateInfo = {};
  const keys = Object.keys(preState[0]);
  for (const key of keys) {
    preState.forEach((preRow, idx) => {
      const postRow = postState[idx];
      if (!_.isEqual(preRow[key], postRow[key])) {
        if (!(key in updateInfo)) {
          updateInfo[key] = [];
        }
        const rowUpdateInfo = {
          id: preRow.id,
          oldValue: preRow[key],
          newValue: postRow[key],
        };

        updateInfo[key].push(rowUpdateInfo);
      }
    });
  }

  return updateInfo;
};

const parseGridDataToModelFormat = (tableName, gridData, startDate = null) => {
  switch (tableName) {
    case TABLE_NAMES.LOCATION_EMPLOYEES:
      return parseGlobalEmployeeGridDataToModelFormat(gridData);
    case TABLE_NAMES.LOCATION_DEMANDS:
    case TABLE_NAMES.ROSTER_DEMANDS:
      return parseDemandGridDataToModelFormat(gridData);
    case TABLE_NAMES.ROSTER_HISTORY:
      return parseHistoryGridDataToModelFormat(gridData);
    case TABLE_NAMES.ROSTER_FIXED_SHIFTS:
      return parseFixedShiftsGridDataToModelFormat(gridData);
    case TABLE_NAMES.ROSTER_PREFERENCES:
      return parsePreferencesGridDataToModelFormat(gridData);
    case TABLE_NAMES.LOCATION_PREFERENCES:
      return parseGlobalPreferencesGridDataToModelFormat(gridData, startDate);
    case TABLE_NAMES.ROSTER_ROSTER:
      return parseRosterTableGridDataToModelFormat(gridData);
    default:
      return gridData;
  }
};

export const getUndoRedoGridActionHandler = (
  modelUpdateHandler,
  tableName = null,
  getCustomGridSnapshot = null
) => {
  return async (updatedGridData, updateInfo) => {
    const gridSnapshot = getCustomGridSnapshot
      ? getCustomGridSnapshot()
      : parseGridDataToModelFormat(tableName, updatedGridData);

    const customParams = {
      interactionType: "cellValueChanged",
      updatedColumns: Object.keys(updateInfo),
      updateInfo,
      gridSnapshot,
    };
    await modelUpdateHandler(customParams);
  };
};
