import { useMemo, useState } from "react";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import styles from "./SolutionStatistics.module.css";
import arrow from "../../../../../assets/icons/dropdownArrow.svg";
import {
  getHoursData,
  getShiftData,
  getSLDataByWeek,
  getSLPeriodData,
} from "./StatisticsFunctions";
import { createBasicContextMenu } from "../../../../../utils";
import { interpretCustomKeywordsData } from "../../../../../utils/queryUtils/locationDataGetters";

const makeColumn = (field, width) => {
  return {
    field: field,
    sortable: true,
    editable: false,
    suppressSizeToFit: true,
    width: width,
    ...(field === "name" && { pinned: "left" }),
  };
};

const makeBasicTable = (columnDefs, rowData, tableName) => {
  return (
    <DataEntryTable
      columnDefs={columnDefs}
      rowData={rowData}
      getContextMenuItems={createBasicContextMenu}
      gridOptions={{}}
      tableName={tableName}
      getCustomRowId={(params) => params.data.name}
    />
  );
};

const ShiftDataTable = ({
  shifts,
  shiftGroups,
  employeeSolution,
  start_date,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
  shortIdsToEntityNamesDicts,
}) => {
  const shiftData = getShiftData(
    shifts,
    shiftGroups,
    employeeSolution,
    start_date,
    customKeywordsUtilObj,
    namesToEntityShortIdsDicts,
    shortIdsToEntityNamesDicts
  );

  const shiftColumns = shifts.map((shift) => makeColumn(shift.name, 150));

  const shiftGroupColumns = shiftGroups.map((shiftGroup) =>
    makeColumn(shiftGroup.name, 150)
  );

  let columnDefs = [
    makeColumn("name", 150),
    makeColumn("numWeekendShifts", 150),
  ]
    .concat(shiftColumns)
    .concat(shiftGroupColumns);

  return (
    <div className={styles.container}>
      {makeBasicTable(columnDefs, shiftData, "stats-shift-data")}
    </div>
  );
};

const HoursDataTable = ({
  shifts,
  employeeSolution,
  employeesShiftHours,
  namesToEntityShortIdsDicts,
}) => {
  const hoursData = getHoursData(
    shifts,
    employeeSolution,
    employeesShiftHours,
    namesToEntityShortIdsDicts
  );

  let weekColumns = [];
  for (let w = 0; w < employeeSolution[0].days.length / 7; w++) {
    weekColumns.push(makeColumn("week" + (w + 1), 150));
  }

  let columnDefs = [makeColumn("name", 150)].concat(weekColumns);

  columnDefs.push(makeColumn("totalHours", 150));

  return (
    <div className={styles.container}>
      {makeBasicTable(columnDefs, hoursData, "stats-hours-data")}
    </div>
  );
};

const SLWeeksTable = ({ employeeSolution, leaveKeywords }) => {
  const sl_data_by_week = getSLDataByWeek(employeeSolution, leaveKeywords);

  let weekColumns = [];
  for (let w = 0; w < employeeSolution[0].days.length / 7; w++) {
    weekColumns.push(makeColumn("week" + (w + 1), 150));
  }

  let columnDefs = [makeColumn("name", 150), makeColumn("type", 150)].concat(
    weekColumns
  );

  columnDefs.push(makeColumn("totalHours", 150));

  return (
    <div className={styles.container}>
      {makeBasicTable(columnDefs, sl_data_by_week, "stats-leave-weeks-data")}
    </div>
  );
};

const SLPeriodsTable = ({ employeeSolution, startDate, leaveKeywords }) => {
  const sl_period_data = getSLPeriodData(
    employeeSolution,
    startDate,
    leaveKeywords
  );

  let columnDefs = [
    makeColumn("name", 150),
    makeColumn("type", 150),
    makeColumn("startDate", 150),
    makeColumn("endDate", 150),
    makeColumn("hours", 150),
  ];

  return (
    <div className={styles.container}>
      {makeBasicTable(
        columnDefs,
        sl_period_data.hoursByPeriod,
        "stats-leave-period-data"
      )}
    </div>
  );
};

const tableNames = [
  "Shift data",
  "Hours data",
  "Leave hours by period",
  "Leave hours by week",
];

const SolutionStatistics = ({
  shifts,
  shiftGroups,
  employeeSolution,
  start_date,
  employeesShiftHours,
  customKeywordsData,
  namesToEntityShortIdsDicts,
  shortIdsToEntityNamesDicts,
}) => {
  const [tableSelected, setTableSelected] = useState(0);
  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );
  const { leaveKeywords } = customKeywordsUtilObj;

  const tables = useMemo(() => {
    if (shifts.length === 0) return [];
    else
      return [
        <ShiftDataTable
          key="0"
          employeeSolution={employeeSolution}
          shifts={shifts}
          shiftGroups={shiftGroups}
          start_date={start_date}
          customKeywordsUtilObj={customKeywordsUtilObj}
          namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        />,
        <HoursDataTable
          key="1"
          employeeSolution={employeeSolution}
          shifts={shifts}
          employeesShiftHours={employeesShiftHours}
          namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
        />,
        <SLPeriodsTable
          key="2"
          employeeSolution={employeeSolution}
          startDate={start_date}
          leaveKeywords={leaveKeywords}
        />,
        <SLWeeksTable
          key="3"
          employeeSolution={employeeSolution}
          leaveKeywords={leaveKeywords}
        />,
      ];
  }, [
    shifts,
    shiftGroups,
    employeeSolution,
    start_date,
    employeesShiftHours,
    leaveKeywords,
    namesToEntityShortIdsDicts,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
  ]);

  if (shifts.length === 0) {
    return <></>;
  }

  const tableOptions = [];

  for (let i = 0; i < tableNames.length; i++) {
    tableOptions.push(
      <option label={tableNames[i]} value={i} key={i}>
        {tableNames[i]}
      </option>
    );
  }

  return (
    <div>
      <div className={styles.top}>
        <div className={styles.tableSelectorWrapper}>
          <label className={styles.tableSelectorLabel} htmlFor="demands">
            Please Select a Category Below:
          </label>

          <select
            name="solutions"
            id="solutions"
            value={tableSelected}
            onChange={(event) => {
              setTableSelected(parseInt(event.target.value));
            }}
            className={styles.tableSelector}
            style={{ backgroundImage: `url(${arrow})` }}
            data-testid="stats-type-selector"
          >
            {tableOptions}
          </select>
        </div>
      </div>
      {tables[tableSelected]}
    </div>
  );
};

export default SolutionStatistics;
