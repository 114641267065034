import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import styles from "./PreferencesLocked.module.css";
import preferencesViewImg from "../../../../assets/images/preferencesView.png";
import employeeViewImg from "../../../../assets/images/employeeView.png";

const ModalImg = (
  <div className={styles.imgContainer}>
    <img
      src={preferencesViewImg}
      className={styles.img}
      alt="preferencew view"
    />
  </div>
);

const PreferencesLocked = () => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.left}>
        <h1 className={styles.title}>Preferences</h1>
        <UpgradeBanner
          title={"Unlock Preferences with RosterLab AI plan!"}
          descriptions={[
            `Don't worry, you will still have FULL CONTROL over your
            rosters!`,
            `You can allocate different criticalities to people's preferences. Our
            AI will optimise them around hard rules.`,
          ]}
          customComponent={ModalImg}
          showBookDemoButton={true}
        />
      </div>
      <div className={styles.right}>
        <img
          src={employeeViewImg}
          className={styles.employeeViewImg}
          alt="employee view"
        />
        <p className={styles.employeeViewImgDesc}>
          Considering EVERYONE&apos;s preferences as much as possible while
          balancing your staffing level for your rosters is HARD!
        </p>
        <p className={styles.employeeViewImgDesc}>
          Preferences page allow employees to put through their ideal shift
          patterns and our AI will balance them against contract rules
          automatically for you. Join RosterLab AI, no more dealing with
          rostering pain!
        </p>
      </div>
    </div>
  </div>
);

export default PreferencesLocked;
