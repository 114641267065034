import styles from "./SideTabCard.module.css";

const SideTabCard = ({
  children,
  emphasizeColor = "#23c9ce",
  backgroundColor = "#8ae9e181",
  defaultTextColor = "#333333",
  customStyle,
}) => (
  <div
    className={styles.card}
    style={{
      backgroundColor,
      borderLeft: `3px solid ${emphasizeColor}`,
      color: defaultTextColor,
      ...customStyle,
    }}
  >
    {children}
  </div>
);

export default SideTabCard;
