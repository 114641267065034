import { useEffect, useRef } from "react";
import styles from "./CreateLocationModal.module.css";
import { useState } from "react";
import ShadowButton from "../../../../components/elements/ShadowButton/ShadowButton";
import Select from "react-select";
import {
  convertSingleOptionToPropForm,
  convertToOptionPropForm,
  DateTime,
  createUniqueSuffixedName,
  removeExtensionFromFileName,
} from "../../../../utils";
import {
  EXTENDED_ROSTERING_PERIODS,
  ROSTERING_PERIODS,
} from "../../../../constants/generalConstants";
import DatePicker from "react-datepicker";
import { DEFAULT_LOCATION_NAME } from "../../../../constants";
import JSONUploader from "../../../../components/elements/JSONUploader/JSONUploader";
import { parseUploadedSchedule } from "../../../scheduleView/service/scheduleViewRoster";
import { PLAN } from "../../../auth/service/auth";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import {
  checkTrialIsInProgress,
  useUserStore,
} from "../../../../globalStore/appStore";
import RuleTemplateOptionItem from "../../../rules/components/RuleTemplateOptionItem/RuleTemplateOptionItem";
import { ruleSets } from "../../../rules/service/ruleSets";
import { customWarningAlert } from "../../../confirm/service/confirm";
import {
  checkMidTierEmployeeRestriction,
  confirmRedirectionToBillingPage,
  handleBillingRedirection,
} from "../../../scheduleView/components/EmployeeNumberLimitModal/EmployeeNumberLimitModal";
import { canAccessExtendedRosterPeriods } from "../../../../utils/flagsUtils/flags";
import { InvalidUploadedFileError } from "../../../../errors/errors";

const LOCATION_MODE = Object.freeze({
  default: "Schedules view (Recommended)",
  classic: "Prototyping view",
});

const getDefaultLocationName = (existingLocationNames) =>
  createUniqueSuffixedName(existingLocationNames, DEFAULT_LOCATION_NAME);

const CreateLocationModal = ({
  plan,
  ruleTemplates,
  handleCancelCreateLocation,
  createNewPrototypeLocation,
  locationNames,
  createNewScheduleViewLocation,
  createNewScheduleViewLocationByUploading,
  employeesNumber,
  updateEmployeesNumber,
  closeCreateLocationModal,
}) => {
  const redirectTo = useRedirect();
  const { totalGlobalEmployees, maxGlobalEmployees, trialEnd } = useUserStore();
  const isTrialInProgress = checkTrialIsInProgress(trialEnd);
  const [name, setName] = useState(() => getDefaultLocationName(locationNames));
  const [locationWithSameNameExists, setLocationWithSameNameExists] =
    useState(false);
  const [locationMode, setLocationMode] = useState(LOCATION_MODE.default);
  const [isEditingName, setIsEditingName] = useState(false);
  const [startDate, setStartDate] = useState(DateTime.getNextMonday());
  const [rosteringPeriod, setRosteringPeriod] = useState("6w");
  const [selectedTemplate, setSelectedTemplate] = useState(ruleTemplates[0]);
  const [
    showDuplicateLocationNameWarning,
    setShowDuplicateLocationNameWarning,
  ] = useState(false);
  const nameInputRef = useRef(null);

  useEffect(() => {
    nameInputRef.current.focus();
  }, []);

  const handleCreateLocation = async () => {
    if (!name) {
      return;
    }

    if (isEditingName) {
      setIsEditingName(false);
    }
    if (locationWithSameNameExists) {
      setShowDuplicateLocationNameWarning(true);
      return;
    }

    if (locationMode === LOCATION_MODE.classic) {
      const createdLocation = await createNewPrototypeLocation(name);
      redirectTo({
        pageName: PAGE_NAMES.location,
        locationID: createdLocation.id,
      });
    } else {
      const resultingEmployeesNumberAfterAdd =
        totalGlobalEmployees + employeesNumber;

      const extraEmployeesToAdd =
        resultingEmployeesNumberAfterAdd - maxGlobalEmployees;

      if (
        checkMidTierEmployeeRestriction(
          plan,
          isTrialInProgress,
          resultingEmployeesNumberAfterAdd,
          maxGlobalEmployees
        )
      ) {
        handleBillingRedirection(
          resultingEmployeesNumberAfterAdd,
          extraEmployeesToAdd,
          maxGlobalEmployees,
          () => closeCreateLocationModal()
        );

        return;
      }

      const createdLocation = await createNewScheduleViewLocation(
        name,
        rosteringPeriod,
        startDate,
        employeesNumber,
        selectedTemplate
      );
      redirectTo({
        pageName: PAGE_NAMES.scheduleViewLocation,
        query: {
          "location-id": createdLocation.id,
        },
      });
    }

    closeCreateLocationModal();
  };

  useEffect(() => {
    if (locationNames.includes(name.trim())) {
      setLocationWithSameNameExists(true);
    } else {
      setLocationWithSameNameExists(false);
    }
  }, [name, locationNames]);

  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const fileName = removeExtensionFromFileName(file.name);
      const result = JSON.parse(e.target.result);
      let parsedSchedule;
      try {
        parsedSchedule = parseUploadedSchedule(result);
      } catch (error) {
        if (error.name === InvalidUploadedFileError.name) {
          customWarningAlert({
            title: "Could not upload file",
            descriptions: [error.message],
          });
          return;
        }
        throw error;
      }

      const resultingEmployeesNumberAfterAdd =
        totalGlobalEmployees + parsedSchedule.Employees.length;

      const employeeNumberInFile = parsedSchedule.Employees.length;

      if (
        plan !== PLAN.AI &&
        plan !== PLAN.LITE &&
        !isTrialInProgress &&
        resultingEmployeesNumberAfterAdd > maxGlobalEmployees
      ) {
        const shouldGoToBillingPage = await confirmRedirectionToBillingPage(
          employeeNumberInFile
        );
        if (shouldGoToBillingPage) {
          closeCreateLocationModal();
          redirectTo({
            pageName: PAGE_NAMES.billing,
            query: {
              tab: "billing",
            },
          });
        }
        return;
      }

      let createdLocationName = fileName || name;

      if (fileName && locationNames.includes(fileName)) {
        let nextName = createdLocationName;
        let count = 1;
        do {
          nextName = `${fileName} (${count})`;
          createdLocationName = nextName;
          count++;
        } while (locationNames.includes(nextName));
      }

      const createdLocation = await createNewScheduleViewLocationByUploading(
        createdLocationName,
        parsedSchedule
      );

      closeCreateLocationModal();
      redirectTo({
        pageName: PAGE_NAMES.scheduleViewLocation,
        query: {
          "location-id": createdLocation.id,
        },
      });
    };

    reader.readAsText(file);
  };

  const ruleTemplateOptions = ruleTemplates.map((name) => {
    const ruleSet = ruleSets.find((set) => set.name === name);
    return {
      value: name,
      label: (
        <RuleTemplateOptionItem ruleTemplateName={name} ruleSet={ruleSet} />
      ),
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.top}>
          <span className={styles.title}>Create New Location</span>
        </div>
        <div className={styles.form}>
          <div className={styles.field}>
            <p>Location name:</p>
            <input
              ref={nameInputRef}
              className={styles.nameInput}
              type="text"
              value={name}
              onChange={(e) => {
                if (showDuplicateLocationNameWarning) {
                  setShowDuplicateLocationNameWarning(false);
                }
                setName(e.target.value);
              }}
            />
          </div>
          {plan === PLAN.AI && (
            <div className={styles.field}>
              <p>Location view:</p>
              <Select
                options={convertToOptionPropForm(Object.values(LOCATION_MODE))}
                onChange={(selectedInput) =>
                  setLocationMode(selectedInput.value)
                }
                value={convertSingleOptionToPropForm(locationMode)}
              />
            </div>
          )}
          {locationMode === LOCATION_MODE.default && (
            <div className={styles.field}>
              <p>How long are your rosters:</p>
              <WeekAndMonthSelect
                rosteringPeriod={rosteringPeriod}
                setRosteringPeriod={setRosteringPeriod}
              />
            </div>
          )}
          {locationMode === LOCATION_MODE.default && (
            <div className={`${styles.field} ${styles.dateField}`}>
              <p>Choose a start date for the roster:</p>
              <DatePicker
                id="roster-date"
                calendarStartDay={1}
                selected={startDate.date}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setStartDate(new DateTime(date, false))}
              />
            </div>
          )}
          {locationMode === LOCATION_MODE.default && (
            <div className={styles.field}>
              <p>Number of employees</p>
              <div className={styles.numEmpsContainer}>
                <input
                  className={styles.employeeNumber}
                  type="number"
                  min={0}
                  onChange={(e) => {
                    if (
                      selectedTemplate !== ruleTemplates[0] &&
                      e.target.value < 1
                    ) {
                      return;
                    }
                    updateEmployeesNumber(e.target.value);
                  }}
                  value={employeesNumber}
                />
              </div>
            </div>
          )}
          {locationMode === LOCATION_MODE.default && (
            <div className={styles.field}>
              <p>Select Rule Template:</p>
              <Select
                options={ruleTemplateOptions}
                onChange={(selectedInput) => {
                  if (
                    selectedInput.value !== ruleTemplates[0] &&
                    employeesNumber < 1
                  ) {
                    updateEmployeesNumber(1);
                  }
                  setSelectedTemplate(selectedInput.value);
                }}
                value={convertSingleOptionToPropForm(selectedTemplate)}
              />
            </div>
          )}
          {locationWithSameNameExists && showDuplicateLocationNameWarning && (
            <p className={styles.sameNameWarning}>
              Location with same name already exists
            </p>
          )}
          <div className={styles.uploader}>
            <span>
              Alternatively, you can{" "}
              <JSONUploader
                handleUpload={(file) => {
                  handleUpload(file);
                }}
                InputTrigger={({ onClick }) => (
                  <a className={styles.uploadLink} onClick={onClick}>
                    upload a RosterLab roster model file
                  </a>
                )}
                shouldBlockUpload={async () => {
                  if (locationWithSameNameExists) {
                    setShowDuplicateLocationNameWarning(true);
                    return true;
                  }
                  return false;
                }}
              />
            </span>
          </div>
          <div className={styles.buttons}>
            <ShadowButton
              backgroundColor={"#219ec9"}
              hoverColor={"#1f91b7"}
              labelColor="white"
              border="none"
              shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
              onClick={handleCreateLocation}
              fontSize="20px"
              borderRadius="5px"
            >
              Create Location
            </ShadowButton>
            <ShadowButton
              backgroundColor="white"
              hoverColor="#1f91b7"
              labelColor="#219ec9"
              border="1px solid #219ec9"
              shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
              onClick={handleCancelCreateLocation}
              fontSize="20px"
              borderRadius="5px"
            >
              Cancel
            </ShadowButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WeekAndMonthSelect = ({ rosteringPeriod, setRosteringPeriod }) => {
  const { email } = useUserStore();

  const roster_periods = canAccessExtendedRosterPeriods(email)
    ? EXTENDED_ROSTERING_PERIODS
    : ROSTERING_PERIODS;

  const weekOptions = roster_periods.map((week) => ({
    value: `${week}w`, // e.g., "2w" for 2 weeks
    label: `${week} weeks`, // e.g., "2 weeks"
  }));

  const monthOption = { value: "1m", label: "1 month" };
  const options = [...weekOptions, monthOption];

  // Find the current selection based on rosteringPeriod value
  const currentSelection = options.find(
    (option) => option.value === rosteringPeriod
  );

  const handleChange = (selectedOption) => {
    setRosteringPeriod(selectedOption.value); // Update the parent component's state
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={currentSelection} // Set the current selection based on the value
      isSearchable={true}
      placeholder="Select a duration..."
    />
  );
};

export default CreateLocationModal;
