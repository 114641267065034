import styles from "./Dashboard.module.css";
import Layout from "../../../../components/layouts/Layout/Layout";
import RostersGrid from "../RostersGrid/RostersGrid";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import FileUploader from "../FileUploader/FileUploader";
import { getUploadedRoster } from "../../service/uploadRoster";
import { getExampleRoster } from "../../service/template";
import { customConfirmAlert } from "../../../confirm/service/confirm";

const Dashboard = ({
  locationName,
  downloadRoster,
  deleteRoster,
  rosters,
  isSaving,
  openCreateRosterModal,
  toggleDuplicateRosterModal,
  locationID,
  updateRoster,
  rosterNames,
  createRoster,
}) => {
  const promptDeleteRosterAlert = async (id) => {
    if (
      await customConfirmAlert({
        title: "Please confirm",
        descriptions: ["Are you sure you want to delete this roster?"],
      })
    ) {
      await deleteRoster(id);
    }
  };

  return (
    <Layout title={`Dashboard - ${locationName}`}>
      <div className={styles.content}>
        <div className={styles.upper}>
          <button
            className={styles.createTemplateBtn}
            onClick={async () => {
              const exampleRoster = await getExampleRoster(
                locationID,
                rosterNames
              );
              createRoster(exampleRoster);
            }}
          >
            See what&apos;s possible with this example roster
          </button>
          <div className={styles.topBtnsWrapper}>
            <FileUploader
              handleSubmission={(file, locationID) =>
                getUploadedRoster(file, locationID, rosterNames, createRoster)
              }
              locationID={locationID}
            />
            <BasicButton
              color="#219ec9"
              onClick={openCreateRosterModal}
              hoverColor="#1f91b7"
              customStyle={{
                borderRadius: "10px",
              }}
            >
              Create New
            </BasicButton>
          </div>
        </div>
        <div className={styles.table}>
          <RostersGrid
            downloadRoster={downloadRoster}
            deleteRoster={promptDeleteRosterAlert}
            rosters={rosters}
            toggleDuplicateRosterModal={toggleDuplicateRosterModal}
            updateRoster={updateRoster}
          />
        </div>
        <div className={styles.bottomMsgs}>
          <span className={styles["is-saving-status"]}>
            {isSaving ? "saving..." : "saved"}
          </span>
          <span
            className={styles["roster-num"]}
            data-testid="total-roster-count"
          >
            Showing {rosters.length} rosters
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
