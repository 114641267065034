import { Calendar, ConfigProvider } from "antd";
import dayjs from "dayjs";
import styles from "./DesktopCalendar.module.css";
import {
  DateTime,
  convertToTitleCase,
  getShiftDurationString,
} from "../../../../../utils";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import {
  checkDateFulfilsRecurringPreference,
  getApprovedLeaveOnDay,
  getDisplayedOpenShiftInfo,
  getLeaveCountInfo,
  getPendingLeaveOnDay,
  isPreferenceSpecial,
} from "../../../service/employeeAppUtil";
import blueStar from "../../../../../assets/images/darkBlueStar.svg";
import { getNumWeeksInMonthCalendar } from "../../../../../utils/generalUtils/calendar";
import { useEffect } from "react";
import {
  KEYWORD_STUDY,
  SHORT_NAME_STUDY,
} from "../../../../../constants/keywords";

const CellWrapper = ({
  children,
  isSelectedDate,
  date,
  background = null,
  leftBarColor = null,
  leftBarThickness = null,
  leftBarHeight = null,
  ...props
}) => {
  const day = props.$D;
  const isToday = new DateTime().toFormat("AWS") === date.toFormat("AWS");

  const getCustomCellStyle = (
    isSelectedDate,
    background = null,
    leftBarColor = null
  ) => {
    let cellBackground = null;
    if (isSelectedDate) {
      cellBackground = "#8ae9e181";
    } else if (background) {
      cellBackground = background;
    }

    return {
      cellBackground,
      cellLeftBarColor: leftBarColor,
    };
  };

  const { cellBackground, cellLeftBarColor } = getCustomCellStyle(
    isSelectedDate,
    background,
    leftBarColor
  );

  return (
    <div
      className={`${styles.cell} ${isToday && styles.today}`}
      style={{
        ...(cellBackground && { background: cellBackground }),
      }}
    >
      {cellLeftBarColor && (
        <div
          className={styles.leftBar}
          style={{
            background: cellLeftBarColor,
            width: leftBarThickness ? leftBarThickness : "4px",
            height: leftBarHeight ? leftBarHeight : "100%",
          }}
        />
      )}
      <p className={styles.day}>{day}</p>
      <div className={styles.innerCell}>{children}</div>
    </div>
  );
};

const ScheduleCell = ({
  isSelectedDate,
  date,
  allocation = null,
  openShiftsOnDay,
  employeeID,
  approvedLeave,
  closeShowAllOpenShifts,
  note,
  ...props
}) => {
  const leaveLabel = approvedLeave ? approvedLeave.request : "";
  const { displayedState: displayedOpenShiftState } = getDisplayedOpenShiftInfo(
    employeeID,
    openShiftsOnDay
  );

  const isOpenShift = allocation?.isOpenShift;

  const getStyle = () => {
    let background = null;
    let leftBarColor = null;
    let leftBarThickness = null;
    let leftBarHeight = null;

    if (allocation) {
      leftBarColor = "#23C9CE";
      leftBarThickness = "4px";
      leftBarHeight = "80%";
    }

    if (isOpenShift && displayedOpenShiftState === "accepted") {
      leftBarColor = "#23C9CE";
      leftBarThickness = "4px";
      leftBarHeight = "80%";
    }

    if (isOpenShift && displayedOpenShiftState === "pending") {
      leftBarColor = "#EF396A";
      leftBarThickness = "4px";
      leftBarHeight = "80%";
    }

    if (leaveLabel) {
      leftBarColor = "#1459F4";
      leftBarThickness = "4px";
      leftBarHeight = "80%";
    }

    return {
      background,
      leftBarColor,
      leftBarThickness,
      leftBarHeight,
    };
  };

  const { background, leftBarColor, leftBarThickness, leftBarHeight } =
    getStyle();

  const getCellContent = () => {
    if (leaveLabel) {
      return (
        <div className={styles.cellContent}>
          <p className={`${styles.basicLabel}`}>
            {convertToTitleCase(leaveLabel)}
          </p>
          {note && (
            <div className={styles.noteContainer}>
              <p className={styles.note}>{note}</p>
            </div>
          )}
        </div>
      );
    }

    const { displayedOpenShift, displayedState, pendingShiftNumbers } =
      getDisplayedOpenShiftInfo(employeeID, openShiftsOnDay);

    if (displayedOpenShift && displayedState === "pending") {
      return (
        <div
          className={`${styles.cellContent} ${styles.pendingOpenShiftsCellContent}`}
        >
          <p className={`${styles.basicLabel} ${styles.basicLeaveLabel}`}>
            Open Shifts Available({pendingShiftNumbers})
          </p>
          <button
            className={styles.viewAllButton}
            onClick={closeShowAllOpenShifts}
          >
            View all
          </button>
          {note && (
            <div className={styles.noteContainer}>
              <p className={styles.note}>{note}</p>
            </div>
          )}
        </div>
      );
    }

    if (allocation) {
      const isOpenShift = allocation.isOpenShift;
      const { entityNames, entities } = allocation;
      const { areaName, shiftName, subtaskNames, taskName } = entityNames;
      const shift = entities.shift;

      return (
        <div
          className={`${styles.cellContent} ${styles.publishedShiftCellContent}`}
        >
          {areaName && <p className={styles.allocationName}>{areaName}:</p>}
          <p className={styles.allocationName}>{shiftName}</p>
          {taskName && <p className={styles.subtaskName}>{taskName}</p>}
          {subtaskNames &&
            subtaskNames.length > 0 &&
            subtaskNames.map((subtaskName) => (
              <p className={styles.subtaskName} key={subtaskName}>
                {subtaskName}
              </p>
            ))}
          {!subtaskNames && shift && (
            <p className={styles.allocationDuration}>
              {getShiftDurationString(shift)}
            </p>
          )}
          {isOpenShift && (
            <div className={styles.openShiftInfo}>
              <p className={styles.openShiftLabel}>Open Shift</p>
              <p className={styles.acceptedLabel}>Accepted</p>
            </div>
          )}
          {note && (
            <div className={styles.noteContainer}>
              <p className={styles.note}>{note}</p>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={`${styles.cellContent}`}>
        {note && (
          <div className={styles.noteContainer}>
            <p className={styles.note}>{note}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <CellWrapper
      isSelectedDate={isSelectedDate}
      date={date}
      {...props}
      background={background}
      leftBarColor={leftBarColor}
      leftBarThickness={leftBarThickness}
      leftBarHeight={leftBarHeight}
    >
      {getCellContent()}
    </CellWrapper>
  );
};

const PreferencesCell = ({
  isSelectedDate,
  date,
  preferenceOnDay,
  recurringPreferenceOnDay,
  ...props
}) => {
  const dayPreference = preferenceOnDay
    ? preferenceOnDay.displayedAllocation
    : "";
  const recurringPreference = recurringPreferenceOnDay
    ? recurringPreferenceOnDay.displayedAllocation
    : "";

  return (
    <CellWrapper isSelectedDate={isSelectedDate} date={date} {...props}>
      <div className={styles.preferences}>
        <div className={styles.rowContainer}>
          {dayPreference && (
            <div className={styles.dayPreferenceLabel}>
              <span className={styles.label}>{dayPreference}</span>
              {isPreferenceSpecial(preferenceOnDay) && (
                <div className={styles.special}>
                  <img src={blueStar} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.rowContainer}>
          {recurringPreference && (
            <div className={styles.recurringPreferenceLabel}>
              <span className={styles.label}>{recurringPreference}</span>
              {isPreferenceSpecial(recurringPreferenceOnDay) && (
                <div className={styles.special}>
                  <img src={blueStar} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </CellWrapper>
  );
};

const LeaveCell = ({
  isSelectedDate,
  date,
  approvedLeave,
  pendingLeave,
  employeeLeaveCounts,
  leaveKeywordsDict,
  ...props
}) => {
  const getLeaveInfo = () => {
    if (approvedLeave) {
      return {
        leaveLabel: approvedLeave.request,
        isApproved: true,
      };
    }

    if (pendingLeave) {
      return {
        leaveLabel: pendingLeave.request,
        isApproved: false,
      };
    }

    return {
      leaveLabel: "",
      isApproved: false,
    };
  };
  const { leaveLabel, isApproved } = getLeaveInfo();

  const leaveCountInfo = getLeaveCountInfo(
    date,
    employeeLeaveCounts,
    leaveKeywordsDict
  );

  const leaveCodes = Object.values(leaveKeywordsDict);

  const createLeaveLabelComponents = () => {
    return leaveCodes.map((code, idx) => {
      const targetLeave = leaveCountInfo.find(
        (info) => info.shortName === code
      );

      if (targetLeave) {
        const label =
          targetLeave.shortName === KEYWORD_STUDY
            ? SHORT_NAME_STUDY
            : targetLeave.shortName;
        return (
          <span
            key={`${idx}-${targetLeave.shortName}`}
            className={`${styles.leaveLabelField} ${
              styles[targetLeave.shortName]
            }`}
          >
            {label} {targetLeave.count}
          </span>
        );
      }
      return null;
    });
  };

  return (
    <CellWrapper isSelectedDate={isSelectedDate} date={date} {...props}>
      <div className={styles.leave}>
        {leaveLabel ? (
          <div className={styles.leaveLabelContainer}>
            <span className={styles.leaveLabel}>{leaveLabel}</span>
            {isApproved ? (
              <p className={`${styles.leaveStateLabel} ${styles.approved}`}>
                Approved
              </p>
            ) : (
              <p className={`${styles.leaveStateLabel} ${styles.pending}`}>
                Pending
              </p>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles.labels}>{createLeaveLabelComponents()}</div>
      </div>
    </CellWrapper>
  );
};

const DesktopCalendar = ({
  location,
  currentViewData,
  setSelectedDate,
  allocations,
  myOpenShifts,
  userEmployee,
  preferences,
  preferencesRecurring,
  requests,
  leaveCodes,
  employeeLeaveCounts,
  leaveKeywordsDict,
  closeShowAllOpenShifts,
  notesToDisplay,
}) => {
  const { selectedDate, contentViewType } = currentViewData;
  const locationStartDate = location.startDate;

  const month = selectedDate.getMonth();
  const nextMonth = DateTime.getNextMonth(month);
  const year = selectedDate.getYear();

  const numWeeksInMonthCalendar = getNumWeeksInMonthCalendar(
    year,
    month,
    nextMonth
  );

  useEffect(() => {
    if (numWeeksInMonthCalendar !== 6) {
      document.querySelector(
        ".ant-picker-content tbody tr:nth-child(6)"
      ).style.display = "none";
    } else {
      document.querySelector(
        ".ant-picker-content tbody tr:nth-child(6)"
      ).style.display = "table-row";
    }
  }, [numWeeksInMonthCalendar]);

  return (
    <div className={styles.container}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "rgb(33, 158, 201)",
            colorBgBase: "#f5f5f5",
          },
        }}
      >
        <Calendar
          value={dayjs(selectedDate.getDate())}
          fullCellRender={(props, cellRenderInfo) => {
            const date = new DateTime(props.$d);
            const isSelectedDate =
              selectedDate.toFormat("AWS") === date.toFormat("AWS");

            const approvedLeave = getApprovedLeaveOnDay(
              date,
              requests,
              leaveCodes
            );

            const pendingLeave = getPendingLeaveOnDay(
              date,
              requests,
              leaveCodes
            );

            if (
              contentViewType === EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences
            ) {
              const targetRecurringPreference =
                checkDateFulfilsRecurringPreference(
                  locationStartDate,
                  date,
                  preferencesRecurring
                );

              const targetPreference = preferences.find(
                (preference) => preference.date === date.toFormat("AWS")
              );
              return (
                <PreferencesCell
                  {...props}
                  cellRenderInfo={cellRenderInfo}
                  date={date}
                  isSelectedDate={isSelectedDate}
                  preferenceOnDay={targetPreference}
                  recurringPreferenceOnDay={targetRecurringPreference}
                />
              );
            }
            if (contentViewType === EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave) {
              return (
                <LeaveCell
                  {...props}
                  cellRenderInfo={cellRenderInfo}
                  date={date}
                  isSelectedDate={isSelectedDate}
                  approvedLeave={approvedLeave}
                  pendingLeave={pendingLeave}
                  employeeLeaveCounts={employeeLeaveCounts}
                  leaveKeywordsDict={leaveKeywordsDict}
                />
              );
            }

            const allocation = allocations.find(
              (allo) => allo.date === date.toFormat("AWS")
            );

            const openShiftsOnDay = myOpenShifts.filter((openShift) => {
              if (new DateTime().isAfter(openShift.date)) {
                const state = openShift.employeeStates.find(
                  (s) => s.employeeID === userEmployee.id
                );
                if (state.state === "pending") {
                  return false;
                }
              }
              return openShift.date === date.toFormat("AWS");
            });

            const note = notesToDisplay[date.toFormat("AWS")] || "";

            return (
              <ScheduleCell
                {...props}
                cellRenderInfo={cellRenderInfo}
                date={date}
                isSelectedDate={isSelectedDate}
                allocation={allocation ? allocation : null}
                openShiftsOnDay={openShiftsOnDay}
                employeeID={userEmployee.id}
                approvedLeave={approvedLeave}
                closeShowAllOpenShifts={closeShowAllOpenShifts}
                note={note}
              />
            );
          }}
          onSelect={(selected) => {
            const date = new DateTime(selected.$d);
            setSelectedDate(date);
          }}
          headerRender={() => <></>}
          rootClassName="desktop-calendar"
          mode="month"
        />
      </ConfigProvider>
    </div>
  );
};

export default DesktopCalendar;
