import { KEYWORD_NA, KEYWORD_OFF } from "../../../constants/keywords";
import { DateTime, sortByAlphanumericField } from "../../../utils";
import {
  convertAllocationInShortIdFormToNameForm,
  extractEntityShortIdsFromAllocation,
} from "../../../utils/modelUtils/allocation";
import {
  buildShortIdsToEntityNamesDicts,
  findEntityByShortId,
} from "../../rosterProblems/service/rosterUtils";

export const getDisplayedOpenShiftInfo = (employeeID, openShiftsOnDay) => {
  let displayedOpenShift = null;
  let displayedState = null;
  let pendingShiftNumbers = 0;

  const approvedOpenShift = openShiftsOnDay.find((openShift) => {
    for (const state of openShift.employeeStates) {
      if (state.employeeID === employeeID && state.state === "accepted") {
        return true;
      }
    }
    return false;
  });

  if (approvedOpenShift) {
    displayedOpenShift = approvedOpenShift;
    displayedState = "accepted";
    return { displayedOpenShift, displayedState, pendingShiftNumbers };
  }

  const pendingOpenShifts = openShiftsOnDay.filter((openShift) => {
    for (const state of openShift.employeeStates) {
      if (state.employeeID === employeeID && state.state === "pending") {
        return true;
      }
    }
    return false;
  });

  let pendingOpenShiftExists = false;
  pendingShiftNumbers = pendingOpenShifts.length;
  if (pendingShiftNumbers > 0) {
    pendingOpenShiftExists = true;
  }

  if (pendingOpenShiftExists) {
    displayedOpenShift = pendingOpenShifts[0];
    displayedState = "pending";
  }

  return { displayedOpenShift, displayedState, pendingShiftNumbers };
};

export function getApprovedLeaveOnDay(date, requests, leaveCodes) {
  const leaveNames = leaveCodes.map((code) => code.longname);
  const approvedLeave = requests.find(
    ({ request, state, startDate, finishDate }) =>
      leaveNames.includes(request) &&
      state === "Approved" &&
      DateTime.getAllDateStringsBetween(startDate, finishDate).includes(
        date.toFormat("AWS")
      )
  );
  return approvedLeave;
}

export function getPendingLeaveOnDay(date, requests, leaveCodes) {
  const leaveNames = leaveCodes.map((code) => code.longname);
  const approvedLeave = requests.find(
    ({ request, state, startDate, finishDate }) =>
      leaveNames.includes(request) &&
      state === "Pending" &&
      DateTime.getAllDateStringsBetween(startDate, finishDate).includes(
        date.toFormat("AWS")
      )
  );
  return approvedLeave;
}

export function parseEmployeesLeaveData(employeeLeaveCounts, date) {
  const employeesWithAL = [];
  const employeesWithSL = [];

  const dateString = date.toFormat("AWS");
  const leaveData = employeeLeaveCounts[dateString];
  if (leaveData) {
    if (leaveData.AL) {
      employeesWithAL.push(...leaveData.AL);
    }

    if (leaveData.Study) {
      employeesWithSL.push(...leaveData.Study);
    }
  }

  return { employeesWithAL, employeesWithSL };
}

export function checkDateFulfilsRecurringPreference(
  locationStartDate,
  tobeCheckedDate,
  preferencesRecurring
) {
  const preferencesRecurringLength = preferencesRecurring.length;

  // find n
  const firstMondayOfFirstWeek =
    DateTime.getFirstMondayOfWeekContainingDate(locationStartDate);
  const firstMondayOfWeekContainingCheckedDate =
    DateTime.getFirstMondayOfWeekContainingDate(tobeCheckedDate);

  const differenceBetweenMondays = Math.abs(
    DateTime.getDifferenceInDays(
      firstMondayOfFirstWeek,
      firstMondayOfWeekContainingCheckedDate
    )
  );

  let nthDayFromCurrentWeekMonday = DateTime.getDifferenceInDays(
    firstMondayOfWeekContainingCheckedDate,
    tobeCheckedDate
  );

  let isFirstWeekOfRecurring = true;
  if (
    preferencesRecurringLength === 14 &&
    differenceBetweenMondays % 7 === 0 &&
    differenceBetweenMondays % 14 !== 0
  ) {
    isFirstWeekOfRecurring = false;
    nthDayFromCurrentWeekMonday = nthDayFromCurrentWeekMonday + 7;
  }

  const date = new DateTime(tobeCheckedDate);
  const dow = date.getDayOfWeek("string");

  if (preferencesRecurringLength !== 7 && preferencesRecurringLength !== 14) {
    throw new Error("Recurring Preferences must be either 7 or 14 days long");
  }

  const recurringPreferenceWithMatchingDow = preferencesRecurring.find(
    (preference, idx) =>
      preference.dow === dow &&
      preference.allocation &&
      idx === nthDayFromCurrentWeekMonday
  );

  if (!recurringPreferenceWithMatchingDow) {
    return null;
  }

  if (preferencesRecurringLength === 7) {
    return recurringPreferenceWithMatchingDow;
  } else {
    const firstDateOfPossibleRecurringPreference = new DateTime(
      locationStartDate
    ).getDateWithNearestDow(dow, true);

    const dayOffset = Math.abs(
      DateTime.getDifferenceInDays(firstDateOfPossibleRecurringPreference, date)
    );

    if (isFirstWeekOfRecurring) {
      if (dayOffset % 14 === 0) {
        return recurringPreferenceWithMatchingDow;
      }
    } else {
      if (dayOffset % 7 === 0 && dayOffset % 14 !== 0) {
        return recurringPreferenceWithMatchingDow;
      }
    }

    return null;
  }
}

export const getDefaultPreferenceState = (preference) => {
  const defaultLevel = preference.defaultPreferenceLevel;
  const currentSuffix = preference.suffix;

  if (currentSuffix === "*") {
    if (defaultLevel === "critical") {
      return null;
    }
    return "Pending";
  }

  switch (defaultLevel) {
    case "normal":
      if (currentSuffix === "?" || currentSuffix === "!") {
        return "Approved";
      }
      return null;
    case "high":
      if (currentSuffix === "!") {
        return "Approved";
      }
      return null;
    case "critical":
      return null;
    default:
      return null;
  }
};

export const isPreferenceSpecial = (preference) => {
  const defaultLevel = preference.defaultPreferenceLevel;
  const currentSuffix = preference.suffix;

  if (currentSuffix === "*") {
    return true;
  }

  switch (defaultLevel) {
    case "normal":
      if (currentSuffix === "?" || currentSuffix === "!") {
        return true;
      }
      return false;
    case "high":
      if (currentSuffix === "!") {
        return true;
      }
      return null;
    case "critical":
      return false;
    default:
      return false;
  }
};

/**
 * If leave is part of published allocations, it replace it with null
 */
export const parseAllocation = (
  userEmployee,
  shifts,
  checkAllocationIsLeave,
  shortIdsToEntityNamesDicts
) => {
  const allocations = [];

  const {
    areaNamesDict,
    shiftNamesDict,
    taskNamesDict,
    enumeratedTaskNamesDict,
  } = shortIdsToEntityNamesDicts;

  userEmployee.PublishedAllocations.forEach((item) => {
    const publishedAllocation = item.publishedAllocation;
    const leaveCode = checkAllocationIsLeave(publishedAllocation);

    if (publishedAllocation && !leaveCode) {
      const entityShortIds = extractEntityShortIdsFromAllocation(
        publishedAllocation,
        shortIdsToEntityNamesDicts
      );

      const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
        entityShortIds;

      const entityNames = {
        areaName: areaNamesDict[areaShortId] || areaShortId,
        shiftName: shiftNamesDict[shiftShortId] || shiftShortId,
        taskName: taskNamesDict[taskShortId] || taskShortId,
        subtaskNames: enumeratedTaskNamesDict[enumeratedTaskShortId]
          ? enumeratedTaskNamesDict[enumeratedTaskShortId].subtaskNames
          : enumeratedTaskShortId,
      };

      const shift = findEntityByShortId(shifts, shiftShortId);
      const enumeratedTask =
        enumeratedTaskNamesDict[enumeratedTaskShortId] || null;

      const entities = {
        shift,
        enumeratedTask,
      };

      allocations.push({
        date: item.date,
        allocation: publishedAllocation,
        displayedAllocation: convertAllocationInShortIdFormToNameForm(
          publishedAllocation,
          shortIdsToEntityNamesDicts
        ),
        isOpenShift: item.isOpenShift,
        entityShortIds,
        entityNames,
        entities,
      });
    }
  });

  return allocations;
};

export const parsePreferences = (
  userEmployee,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  defaultPreferenceLevel
) => {
  const allHaveShortId = allEntitiesHaveShortId([
    ...shifts,
    ...shiftGroups,
    ...tasks,
    ...taskBlocks,
  ]);

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const preferences = [];
  userEmployee.Preferences.forEach((element) => {
    let allocationName = element.allocation;
    let suffix = "";

    if (
      allocationName.endsWith("!") ||
      allocationName.endsWith("?") ||
      allocationName.endsWith("*")
    ) {
      suffix = allocationName.slice(-1);
      allocationName = allocationName.slice(0, -1);
    }

    const targetShift = shifts.find(
      (shift) => shift.shortId === allocationName
    );
    const targetShiftGroup = shiftGroups.find(
      (shift) => shift.shortId === allocationName
    );

    let displayedAllocation = allocationName;

    if (allHaveShortId) {
      displayedAllocation = convertAllocationInShortIdFormToNameForm(
        allocationName,
        shortIdsToEntityNamesDicts
      );
      if (targetShiftGroup) {
        displayedAllocation = targetShiftGroup.name;
      }
      if (allocationName === KEYWORD_OFF) {
        displayedAllocation = KEYWORD_OFF;
      }
    }

    preferences.push({
      date: element.date,
      allocation: allocationName,
      displayedAllocation,
      startTime: targetShift ? targetShift.startTime : null,
      finishTime: targetShift ? targetShift.finishTime : null,
      suffix,
      defaultPreferenceLevel,
    });
  });

  return preferences;
};

export const parsePreferenceNumbers = (
  employees,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  defaultPreferenceLevel
) => {
  const preferenceNumbers = {};

  employees.forEach((employee) => {
    const preferences = parsePreferences(
      employee,
      skills,
      shifts,
      shiftGroups,
      tasks,
      taskBlocks,
      subTasks,
      areas,
      defaultPreferenceLevel
    );

    preferences.forEach((preference) => {
      if (preference.date in preferenceNumbers) {
        if (preference.allocation in preferenceNumbers[preference.date]) {
          preferenceNumbers[preference.date][preference.allocation] += 1;
        } else {
          preferenceNumbers[preference.date][preference.allocation] = 1;
        }
      } else {
        preferenceNumbers[preference.date] = { [preference.allocation]: 1 };
      }
    });
  });

  return preferenceNumbers;
};

export const parsePreferenceRecurringNumbers = (
  employees,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  defaultPreferenceLevel
) => {
  const preferencesRecurringLength = Math.max(
    ...employees.map((emp) =>
      emp.PreferencesRecurring ? emp.PreferencesRecurring.length : 0
    )
  );

  const preferenceRecurringNumbers = Array.from(
    { length: preferencesRecurringLength },
    () => ({})
  );

  employees.forEach((employee) => {
    const preferencesRecurring = parsePreferencesRecurring(
      employee,
      skills,
      shifts,
      shiftGroups,
      tasks,
      taskBlocks,
      subTasks,
      areas,
      defaultPreferenceLevel
    );

    preferencesRecurring.forEach((preference, idx) => {
      if (preference && preference.allocation) {
        if (preference.allocation in preferenceRecurringNumbers[idx]) {
          preferenceRecurringNumbers[idx][preference.allocation] += 1;
        } else {
          preferenceRecurringNumbers[idx][preference.allocation] = 1;
        }
      }
    });
  });

  return preferenceRecurringNumbers;
};

export const parsePreferencesRecurring = (
  userEmployee,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  defaultPreferenceLevel
) => {
  const allHaveShortId = allEntitiesHaveShortId([
    ...shifts,
    ...shiftGroups,
    ...tasks,
    ...taskBlocks,
  ]);
  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const preferencesRecurring = userEmployee.PreferencesRecurring.map(
    (name, idx) => {
      let allocationName = name;
      let suffix = "";

      if (
        allocationName.endsWith("!") ||
        allocationName.endsWith("?") ||
        allocationName.endsWith("*")
      ) {
        suffix = allocationName.slice(-1);
        allocationName = allocationName.slice(0, -1);
      }

      const targetShift = shifts.find(
        (shift) => shift.shortId === allocationName
      );
      const targetShiftGroup = shiftGroups.find(
        (shift) => shift.shortId === allocationName
      );

      let displayedAllocation = allocationName;
      if (allHaveShortId) {
        displayedAllocation = convertAllocationInShortIdFormToNameForm(
          allocationName,
          shortIdsToEntityNamesDicts
        );
        if (targetShiftGroup) {
          displayedAllocation = targetShiftGroup.name;
        }
        if (allocationName === KEYWORD_OFF) {
          displayedAllocation = KEYWORD_OFF;
        }
      }

      return {
        dow: DateTime.numToDoWString((idx + 1) % 7),
        allocation: allocationName,
        displayedAllocation,
        startTime: targetShift ? targetShift.startTime : null,
        finishTime: targetShift ? targetShift.finishTime : null,
        suffix,
        defaultPreferenceLevel,
      };
    }
  );

  return preferencesRecurring;
};

export const parseRequests = (userEmployee) => {
  const requests = [];
  userEmployee.Requests.forEach((element) => {
    const requestStartDate = new DateTime(element.startDate);
    const requestFinishDate = new DateTime(element.finishDate);
    const dates = DateTime.getAllDatesBetween(
      requestStartDate,
      requestFinishDate
    );
    dates.forEach((date) => {
      requests.push({
        date: date.toFormat("AWS"),
        allocation: element.request,
        startTime: null,
        finishTime: null,
        state: element.state,
      });
    });
  });

  return requests;
};

export function getLeaveCountInfo(
  selectedDate,
  employeeLeaveCounts,
  leaveKeywordsDict
) {
  const dateString = selectedDate.toFormat("AWS");
  const counts = employeeLeaveCounts[dateString];

  const info = [];
  for (const key in counts) {
    let label = key;
    for (const fullLeaveName in leaveKeywordsDict) {
      if (leaveKeywordsDict[fullLeaveName] === key) {
        label = fullLeaveName;
      }
    }

    info.push({
      shortName: key,
      label,
      count: counts[key].length,
      employees: counts[key],
    });
  }

  sortByAlphanumericField(info, "label");
  return info;
}

/**
 * If accepted open shift exsits, only have that opsn shift
 * OR
 * have the pending open shifts come before declined open shifts
 */
export function filterAndSortOpenShifts(openShifts, employeeID) {
  const pendingOpenShifts = [];
  const decliendOpenShifts = [];

  for (let i = 0; i < openShifts.length; i++) {
    const openShift = openShifts[i];
    const openShiftState = openShift.employeeStates.find(
      (state) => state.employeeID === employeeID
    ).state;

    if (openShiftState === "accepted") {
      return [openShift];
    }

    if (openShiftState === "pending") {
      pendingOpenShifts.push(openShift);
    }

    if (openShiftState === "declined") {
      decliendOpenShifts.push(openShift);
    }
  }

  return [...pendingOpenShifts, ...decliendOpenShifts];
}

export function getPreferencesSumToDisplay(
  selectedDate,
  preference,
  preferenceNumbers,
  numberOfEmployees,
  isRecurring
) {
  if (!preference) {
    return { prefNumberToDisplay: null, isViolation: false };
  }

  const displayedPreferenceName = preference.allocation;
  let prefNumberToDisplay = null;

  if (isRecurring) {
    const dowNum = selectedDate.getDayOfWeek("numberWithMondayAs0");
    const preferenceRecurringNumber =
      preferenceNumbers[dowNum]?.[displayedPreferenceName];

    if (preferenceRecurringNumber) {
      prefNumberToDisplay = preferenceRecurringNumber;
    }
  } else {
    const preferenceNumber =
      preferenceNumbers[selectedDate.toFormat("AWS")]?.[
        displayedPreferenceName
      ];
    if (preferenceNumber) {
      prefNumberToDisplay = preferenceNumber;
    }
  }

  return {
    prefNumberToDisplay,
    isViolation: prefNumberToDisplay
      ? prefNumberToDisplay > numberOfEmployees / 2
      : null,
  };
}

export function allEntitiesHaveShortId(entities) {
  const shortIdsExist = entities.every((entity) => entity.shortId !== null);
  return shortIdsExist;
}

export function isAllocationDisplayedBlankInEmployeeApp(allocation) {
  if (!allocation || allocation == KEYWORD_NA) {
    return true;
  }
  return false;
}

export const getLiveScheduleUrl = (locationID) => {
  const originUrl =
    window.location.hostname === "app.rosterlab.com" ||
    (window.location.hostname === "localhost" &&
      process.env.NODE_ENV !== "development")
      ? "https://app.rosterlab.com"
      : "https://test.dnbf8aqkmb1um.amplifyapp.com";

  const liveURL = `${originUrl}/liveschedule?id=${locationID}&s=all&v=employee`;
  return liveURL;
};
