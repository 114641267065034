import TwoOptionsSwitcher from "../../../../components/elements/TwoOptionsSwitcher/TwoOptionsSwitcher";

export const SWITCHABLE_PAGES = Object.freeze({
  overview: "overview",
  individual: "individual",
});

const EmployeesViewSwitcher = ({ redirect, currentPage }) => {
  return (
    <TwoOptionsSwitcher
      onChange={redirect}
      isChecked={currentPage === SWITCHABLE_PAGES.individual}
      uncheckedLabel="Overview"
      checkedLabel="Individual"
    />
  );
};

export default EmployeesViewSwitcher;
