import { getRosterModelById } from "../../../utils/queryUtils/rosterQuery";

export const downloadJSON = async (json, fileName) => {
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadRosterAsJSON = async (rosterID) => {
  const roster = await getRosterModelById(rosterID);

  const rosterWithoutSolutions = {
    ...roster,
    Solutions: {
      items: [],
    },
  };
  const fileName = `${roster.name}`;

  const json = JSON.stringify({
    ...rosterWithoutSolutions,
    locationType: "prototype",
  });
  downloadJSON(json, fileName);
};
