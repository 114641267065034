import {
  AUTH_STATUS,
  getGroup,
  getUsersInGroup,
} from "../../auth/service/adminQuery";

export const USER_STATUS = {
  NOT_INVITED: "No Invitation Sent",
  PENDING: "Pending",
  ACTIVE: "Active",
  PROCESSING: "Processing",
};

const convertAuthStatusToUserStatus = (authStatus) => {
  switch (authStatus) {
    case AUTH_STATUS.CONFIRMED:
      return USER_STATUS.ACTIVE;
    case AUTH_STATUS.FORCE_CHANGE_PASSWORD:
      return USER_STATUS.PENDING;
    default:
      return USER_STATUS.NOT_INVITED;
  }
};

export const getEmployeesAuthInfoInLocationGroup = async (locationID) => {
  let usersAuthInfo = [];
  let token;

  try {
    const userGroupRes = await getGroup(locationID);
    const userGroup = await userGroupRes.body.json();
    if (userGroup.type && userGroup.type === "ResourceNotFound") {
      return usersAuthInfo;
    }
  } catch (err) {
    return usersAuthInfo;
  }

  do {
    let res;
    try {
      res = await getUsersInGroup(locationID, 60, token);
    } catch (err) {
      console.error(err);
    }

    if (res === undefined) {
      return usersAuthInfo;
    }

    const { NextToken, Users } = res;

    const users = Users.map((u) => {
      const email = u.Attributes.find((attr) => attr.Name === "email").Value;
      const authStatus = u.UserStatus;
      const userStatus = convertAuthStatusToUserStatus(authStatus);
      return {
        email,
        userStatus,
      };
    });
    usersAuthInfo = usersAuthInfo.concat(users);
    token = NextToken;
  } while (token);

  return usersAuthInfo;
};
