import _ from "lodash";

/**
 * Deep compare two objects
 * @param {*} options = null | {fieldsToCompare, fieldsToOmit}
 * - if option is null, it compares all the fields
 * - if option.fieldsToCompare exists, it compares only the fields in option.fieldsToCompare (and igrnores fieldsToOmit)
 * - else if fieldsToOmit exists, it compares all the fields except the fields in option.fieldsToOmit
 */
export function compareTwoObjects(obj1, obj2, options = {}) {
  const { fieldsToCompare, fieldsToOmit } = options;

  if (fieldsToCompare) {
    const filteredObj1 = _.pick(obj1, fieldsToCompare);
    const filteredObj2 = _.pick(obj2, fieldsToCompare);
    return _.isEqual(filteredObj1, filteredObj2);
  } else if (fieldsToOmit) {
    const filteredObj1 = _.omit(obj1, fieldsToOmit);
    const filteredObj2 = _.omit(obj2, fieldsToOmit);
    return _.isEqual(filteredObj1, filteredObj2);
  }

  return _.isEqual(obj1, obj2);
}

export function objectToKeyValuePairsString(object) {
  const keys = Object.keys(object);
  const keyValuePairs = keys
    .map((key) => {
      const value = object[key];
      if (typeof value === "string") {
        return `${key}:${value}`;
      }
      return null;
    })
    .filter((value) => value !== null);

  return keyValuePairs.join(";");
}

export function getSupersetOfKeys(obj1, obj2) {
  // Get keys of both objects and combine them using Set to ensure uniqueness
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Create a Set from the combined keys to remove duplicates
  const supersetKeys = new Set([...keys1, ...keys2]);

  // Convert the Set back into an array
  return Array.from(supersetKeys);
}

export function removeNullFields(obj) {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === null) {
      delete newObj[key];
    }
  });

  return newObj;
}

export function removeFields(obj, fieldsToRemove) {
  const newObj = { ...obj };
  fieldsToRemove.forEach((field) => {
    delete newObj[field];
  });

  return newObj;
}
