import { Authenticator } from "@aws-amplify/ui-react";
import logo from "../../../../assets/images/logo_coloured.png";
import microsoftIcon from "../../../../assets/icons/microsoftLogo.svg";
import styles from "./LoginForm.module.css";
import "../../../../styles/amplifyAuth.css";
import "@aws-amplify/ui-react/styles.css";
import { useLocation as useRouteLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { Capacitor } from "@capacitor/core";
import { I18n } from "aws-amplify/utils";
import { signInWithRedirect } from "aws-amplify/auth";
import { useLocation } from "react-router-dom";
import { App as CapApp } from "@capacitor/app";
import queryString from "query-string";
import { Browser } from "@capacitor/browser";

I18n.putVocabulariesForLanguage("en", {
  "Change Password": "Sign Up", // Tab header
});

// Define the custom provider for Azure AD
const azureAdProvider = {
  custom: "AzureAD", // This should match the OIDC provider ID in Cognito
};

const Header = () => {
  return (
    <div
      className={styles["auth-header"]}
      style={{
        marginTop: Capacitor.isNativePlatform() ? "90px" : "20px",
      }}
    >
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        href={"https://www.rosterlab.com"}
      >
        <img className={styles.logo} src={logo} alt="Rosterlab logo" />
      </a>
      <span className={styles.sentence}>
        {!Capacitor.isNativePlatform() ? (
          "Sign up right now for free!"
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0px 10px",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <p>
              If you are a staff member, please ask your roster admin to invite
              you at <a href="https://www.rosterlab.com">www.rosterlab.com</a>
            </p>
          </div>
        )}
      </span>
    </div>
  );
};

function useDeepLinks() {
  const location = useLocation();
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    // Only set up listener if on native platform
    if (!isNative) return;

    const handleAppUrl = (data) => {
      try {
        const { query } = queryString.parseUrl(data.url);
        const newParams = { ...queryString.parse(location.search), ...query };

        const finalUrl = `${location.pathname}?${queryString.stringify(
          newParams
        )}`;
        console.log("URL: " + finalUrl);
        window.location.replace(finalUrl);

        if (Capacitor.getPlatform() === "ios") {
          Browser.close();
        }
      } catch (error) {
        console.error("Deep link handling error:", error);
      }
    };

    CapApp.addListener("appUrlOpen", handleAppUrl);
    return () => {
      CapApp.removeAllListeners();
    };
  }, [location, isNative]);
}

const LoginPage = ({ children }) => {
  useDeepLinks();
  useEffect(() => {
    if (window.location.pathname === "/azure") {
      // Automatically initiate the Azure AD login
      signInWithRedirect({ provider: azureAdProvider });
    }
  }, []);

  const pathLocation = useRouteLocation();
  const shouldShowSignUp = useMemo(() => {
    if (pathLocation.state && pathLocation.state.showSignUp) {
      return true;
    }
    return false;
  }, [pathLocation.state]);

  const formFields = {
    signUp: {
      email: {
        order: 1,
      },
      password: {
        order: 2,
      },
      confirm_password: {
        order: 3,
      },
      given_name: {
        order: 4,
      },
      family_name: {
        order: 5,
      },
    },
    forceNewPassword: {
      password: {
        order: 1,
      },
      confirm_password: {
        order: 2,
      },
      given_name: {
        order: 3,
      },
      family_name: {
        order: 4,
      },
    },
  };

  // Handle Azure AD login button click
  const handleAzureAdLogin = () => {
    signInWithRedirect({ provider: azureAdProvider });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Authenticator
        initialState={shouldShowSignUp ? "signUp" : "signIn"}
        socialProviders={
          Capacitor.isNativePlatform() ? [] : ["facebook", "google"]
        }
        hideSignUp={Capacitor.isNativePlatform() ? true : false}
        formFields={formFields}
        components={{
          Header,
          SignIn: {
            Header() {
              return (
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "-34px",
                    padding: "0px 32px",
                    marginTop: "30px",
                    zIndex: 10,
                    position: "relative",
                  }}
                >
                  {
                    <button
                      type="button"
                      onClick={handleAzureAdLogin}
                      className={styles["azure-ad-button"]}
                      aria-label="Login with Azure AD"
                    >
                      <img
                        src={microsoftIcon}
                        alt="Azure AD Icon"
                        style={{
                          height: "1em", // Match the height of the text
                          width: "1em", // Ensure it's square
                          verticalAlign: "middle", // Align icon with text
                          marginRight: "0.5em", // Optional: Add some space between the icon and the text
                        }}
                      />
                      Sign In with Microsoft
                    </button>
                  }
                </div>
              );
            },
          },
          SignUp: {
            FormFields() {
              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />
                  {/* Append & require Terms & Conditions field to sign up  */}
                  <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                    By creating an account, you agree to our <br></br>
                    <a href="/eula" target="_blank">
                      End User License Agreement
                    </a>{" "}
                    and{" "}
                    <a href="/privacy" target="_blank">
                      Privacy policy
                    </a>
                  </div>
                </>
              );
            },
          },
          ForceNewPassword: {
            FormFields() {
              return (
                <>
                  <Authenticator.ForceNewPassword.FormFields />
                  <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                    By clicking &quot;Sign Up&quot;, you agree to our <br></br>
                    <a href="/eula" target="_blank">
                      End User License Agreement
                    </a>
                  </div>
                </>
              );
            },
          },
        }}
      >
        {children}
      </Authenticator>
    </div>
  );
};

export default LoginPage;
