import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from "react-router-dom";
import {
  RosterGridsLayoutWithoutSidebar,
  RosterGridsLayoutWithSidebar,
} from "../features/grid/components/GridsLayout/GridsLayout";
import ScheduleEmployeesDataContainer from "../features/scheduleView/components/ScheduleEmployeesDataContainer/ScheduleEmployeesDataContainer";
import ScheduleShiftsDataContainer from "../features/scheduleView/components/ScheduleShiftsDataContainer/ScheduleShiftsDataContainer";
import ScheduleSkillsDataContainer from "../features/scheduleView/components/ScheduleSkillsDataContainer/ScheduleSkillsDataContainer";
import ScheduleTasksDataContainer from "../features/scheduleView/components/ScheduleTasksDataContainer/ScheduleTasksDataContainer";
import ScheduleRulesDataContainer from "../features/scheduleView/components/ScheduleRulesDataContainer/ScheduleRulesDataContainer";
import ScheduleDemandsDataContainer from "../features/scheduleView/components/ScheduleDemandsDataContainer/ScheduleDemandsDataContainer";
import ScheduleHistoryDataContainer from "../features/scheduleView/components/ScheduleHistoryDataContainer/ScheduleHistoryDataContainer";
import ScheduleFixedShiftsWrapper from "../features/scheduleView/components/ScheduleFixedShiftsWrapper/ScheduleFixedShiftsWrapper";
import SchedulePreferencesWrapper from "../features/scheduleView/components/SchedulePreferencesWrapper/SchedulePreferencesWrapper";
import ScheduleSolutionsDataContainer from "../features/scheduleView/components/ScheduleSolutionsDataContainer/ScheduleSolutionsDataContainer";
import ScheduleRosterDataContainer from "../features/scheduleView/components/ScheduleRosterDataContainer/ScheduleRosterDataContainer";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { DateTime } from "../utils";
import {
  getInferredPeriodStartAndFinishDate,
  inferRosterStartDate,
} from "../features/scheduleView/service/scheduleViewRoster";
import GenerateLocked from "../features/upgradePlan/components/GenerateLocked/GenerateLocked";
import ScheduleShiftGroupsDataContainer from "../features/scheduleView/components/ScheduleShiftGroupsDataContainer/ScheduleShiftGroupsDataContainer";
import {
  getFieldsFromLocation,
  getRosterIdByPeriodStartDate,
} from "../utils/queryUtils/locationDataGetters";
import { useSolutionGenerationStore } from "../globalStore/solutionGenerationStore";
import {
  subscribeGlobalEmployeeUpdate,
  subscribeLocationUpdate,
  subscribeRosterSolutionCreation,
  subscribeRosterSolutionDeleted,
  subscribeRosterSolutionUpdate,
  subscribeRosterUpdate,
} from "../utils/queryUtils/observers";
import { useRosterModelQuery } from "../hooks/modelQueryHooks/useRosterModelQuery";
import IndividualViewWrapper from "../features/rosterProblems/individualView/components/IndividualViewWrapper/IndividualViewWrapper";
import LoadingPage from "../features/loading/components/LoadingPage/LoadingPage";
import { useQueryClient } from "@tanstack/react-query";
import { useScheduleRouteQuery } from "../hooks/useScheduleRouteQuery";
import EmployeesDataContainer from "../features/rosterProblems/components/Employees/EmployeesDataContainer/EmployeesDataContainer";
import {
  employeePageViewSetting,
  useDefaultPageViewStore,
} from "../globalStore/defaultViewsStore";
import { PAGE_NAMES, useRedirect } from "../utils/routeUtils/redirect";
import { useWarningsStore } from "../globalStore/warningsStore";
import { getActualNumDays } from "../utils/queryUtils/monthViewUtils";
import { createRosterModelForMainStreamScheduleView } from "../utils/queryUtils/locationQuery";
import { getRosterStartDateAndFinishDate } from "../features/rosterProblems/service/rosterUtils";
import { handleSolutionDeleteSubscription } from "../utils/queryUtils/generalQueryUtil";
import { isMobileViewOrApp } from "../globalStore/deviceInfoStore";
import ScheduleAreasDataContainer from "../features/scheduleView/components/ScheduleAreasDataContainer/ScheduleAreasDataContainer";
import {
  syncGlobalEmployee,
  syncLocation,
  syncRoster,
} from "../utils/modelUtils/sync";

const RosterPage = (props) => <ScheduleRosterDataContainer {...props} />;
const EmployeesTable = (props) =>
  props.isSnapshot ? (
    <EmployeesDataContainer {...props} isSnapshot={true} />
  ) : (
    <ScheduleEmployeesDataContainer {...props} />
  );
const AreaTable = (props) => <ScheduleAreasDataContainer {...props} />;
const SkillsTable = (props) => <ScheduleSkillsDataContainer {...props} />;
const TasksTable = (props) => <ScheduleTasksDataContainer {...props} />;
const ShiftsTable = (props) => <ScheduleShiftsDataContainer {...props} />;
const ShiftGroupsTable = (props) => (
  <ScheduleShiftGroupsDataContainer {...props} />
);
const RulesTable = (props) => <ScheduleRulesDataContainer {...props} />;
const DemandsTable = (props) => <ScheduleDemandsDataContainer {...props} />;
const HistoryTable = (props) => <ScheduleHistoryDataContainer {...props} />;
const FixedShiftsPage = (props) => <ScheduleFixedShiftsWrapper {...props} />;
const PreferencesPage = (props) => <SchedulePreferencesWrapper {...props} />;
const SolutionsPage = (props) => <ScheduleSolutionsDataContainer {...props} />;
const GenerateLockedPage = (props) => <GenerateLocked {...props} />;

const ScheduleViewRoutes = ({
  location,
  locations,
  headerType,
  toggleCreateLocationModal,
  customKeywordsData,
  isSettingsLoading,
}) => {
  const isMounted = useRef(false);
  const isInitialWarningForRosterId = useRef("");
  const queryClient = useQueryClient();
  const { date: dateInUrl, snapshotId } = useScheduleRouteQuery();
  const { url } = useRouteMatch();
  const { changeDefaultPageView } = useDefaultPageViewStore();
  const redirectTo = useRedirect();
  const history = useHistory();

  const {
    locationID,
    name,
    startDate,
    defaultNumDays,
    employees: globalEmployees,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const { updateWarnings } = useWarningsStore();

  let { periodStartDate, periodFinishDate } =
    getInferredPeriodStartAndFinishDate(startDate, dateInUrl, defaultNumDays);

  const numDays = useMemo(
    () =>
      defaultNumDays === -1
        ? getActualNumDays(periodStartDate, location.defaultNumDays)
        : defaultNumDays,
    [periodStartDate, location, defaultNumDays]
  );

  const rosterID =
    snapshotId || getRosterIdByPeriodStartDate(location, periodStartDate);

  const { roster, customKeywords, isQueryLoading } = useRosterModelQuery({
    isScheduleView: true,
    locationID,
    rosterID,
  });

  const isMobile = isMobileViewOrApp();

  const RosterGridsLayout = useMemo(
    () =>
      isMobile ? RosterGridsLayoutWithoutSidebar : RosterGridsLayoutWithSidebar,
    [isMobile]
  );

  useEffect(() => {
    let locationUpdateSub;
    let globalEmployeeUpdateSub;
    let rosterUpdateSub;
    const warningCalculationOptions = {
      updateWarnings,
      customKeywords,
      rosterID,
      locationID,
    };

    if (locationID) {
      locationUpdateSub = subscribeLocationUpdate(
        (data) => syncLocation(locationID, data, warningCalculationOptions),
        locationID
      );

      globalEmployeeUpdateSub = subscribeGlobalEmployeeUpdate(
        (data) =>
          syncGlobalEmployee(locationID, data, warningCalculationOptions),
        locationID
      );
    }

    if (rosterID) {
      rosterUpdateSub = subscribeRosterUpdate((data) => {
        syncRoster(rosterID, data, warningCalculationOptions);
      }, rosterID);
    }

    return async () => {
      (await locationUpdateSub)?.unsubscribe();
      (await globalEmployeeUpdateSub)?.unsubscribe();
      (await rosterUpdateSub)?.unsubscribe();
    };
  }, [
    rosterID,
    queryClient,
    customKeywords,
    locationID,
    roster,
    updateWarnings,
    globalEmployees,
    location,
  ]);

  if (roster && roster.isSnapshot) {
    const { rosterStartDate, rosterFinishDate } =
      getRosterStartDateAndFinishDate(roster);
    periodStartDate = rosterStartDate;
    periodFinishDate = rosterFinishDate;
  }

  const { warnings, updateFullWarnings } = useWarningsStore();

  useEffect(() => {
    if (!roster || isInitialWarningForRosterId.current === roster.id) {
      return;
    }
    updateFullWarnings(roster, customKeywords);

    isInitialWarningForRosterId.current = roster.id;
  }, [roster, customKeywords, updateFullWarnings]);

  useEffect(() => {
    if (location && !getRosterIdByPeriodStartDate(location, periodStartDate)) {
      createRosterModelForMainStreamScheduleView(
        location,
        globalEmployees,
        numDays,
        periodStartDate
      ).then(() => {
        history.go(0);
      });
    }
  }, [location, globalEmployees, periodStartDate, numDays, history]);

  const [schedulePeriodNum, setSchedulePeriodNum] = useState(0);

  const { setLatestSolutionGenStatus } = useSolutionGenerationStore();

  useEffect(() => {
    const solutionCreateSub = subscribeRosterSolutionCreation(
      setLatestSolutionGenStatus,
      rosterID
    );
    const solutionUpdateSub = subscribeRosterSolutionUpdate(
      setLatestSolutionGenStatus,
      rosterID
    );
    const solutionDeleteSub = subscribeRosterSolutionDeleted((data) => {
      setLatestSolutionGenStatus(null);
      handleSolutionDeleteSubscription(queryClient, data);
    }, rosterID);
    return async () => {
      (await solutionCreateSub)?.unsubscribe();
      (await solutionUpdateSub)?.unsubscribe();
      (await solutionDeleteSub)?.unsubscribe();
    };
  }, [setLatestSolutionGenStatus, rosterID, locationID, queryClient]);

  // Purpose: Every time rosterStartDate (which shows different roster) changes, run fixRoster again
  useEffect(() => {
    if (!rosterID) {
      return;
    }
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
  }, [queryClient, rosterID]);

  /**
   * Figure out nth period displayed
   */
  useEffect(() => {
    const currentScheduleStartDate = inferRosterStartDate(
      startDate,
      new DateTime().toFormat("AWS"),
      defaultNumDays
    );
    if (defaultNumDays !== -1) {
      const daysDiff = DateTime.getDifferenceInDays(
        currentScheduleStartDate,
        periodStartDate
      );

      setSchedulePeriodNum(daysDiff / defaultNumDays);
    } else {
      const monthsDifference = DateTime.getDifferenceInMonths(
        currentScheduleStartDate,
        periodStartDate
      );

      setSchedulePeriodNum(monthsDifference);
    }
  }, [startDate, defaultNumDays, periodStartDate]);

  const redirectToEmployeesIndividualViewPage = useCallback(() => {
    changeDefaultPageView(
      employeePageViewSetting.name,
      employeePageViewSetting.possibleValues.individual
    );
    redirectTo({
      pageName: PAGE_NAMES.scheduleViewIndividualEmployee,
      query: {
        "location-id": locationID,
        date: periodStartDate,
        ...(roster.isSnapshot && { "snapshot-id": snapshotId }),
      },
    });
  }, [
    changeDefaultPageView,
    roster,
    locationID,
    periodStartDate,
    redirectTo,
    snapshotId,
  ]);

  const redirectToEmployeesOverviewPage = useCallback(() => {
    changeDefaultPageView(
      employeePageViewSetting.name,
      employeePageViewSetting.possibleValues.overview
    );
    redirectTo({
      pageName: PAGE_NAMES.scheduleViewEmployeesSkills,
      query: {
        "location-id": locationID,
        date: periodStartDate,
        ...(roster.isSnapshot && { "snapshot-id": snapshotId }),
      },
    });
  }, [
    changeDefaultPageView,
    locationID,
    periodStartDate,
    redirectTo,
    roster,
    snapshotId,
  ]);

  if (!periodStartDate || !roster) {
    if (isQueryLoading || isSettingsLoading) {
      return <LoadingPage />;
    }
    return <Redirect to={`${url}/roster?location-id=${locationID}`} />;
  }

  return (
    <Switch>
      <Route path={[`${url}/roster`, `${url}/shift-view`]}>
        <RosterGridsLayout
          GridComponents={[RosterPage]}
          key={rosterID}
          locationID={locationID}
          location={location}
          locationName={name}
          locations={locations}
          headerType={headerType}
          pageName={"My Roster"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          periodNum={schedulePeriodNum}
          rosterID={rosterID}
          customStyles={{}}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/employees`}>
        <RosterGridsLayout
          GridComponents={[EmployeesTable, SkillsTable]}
          locationID={locationID}
          key={rosterID}
          locationName={name}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Employees"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          redirectToIndividualPage={redirectToEmployeesIndividualViewPage}
          isMainstream={!roster.isSnapshot}
          isScheduleView={true}
        />
      </Route>
      <Route path={`${url}/employees-individual`}>
        <IndividualViewWrapper
          isRoster={false}
          key={rosterID}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Employees"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          globalEmployees={globalEmployees}
          warnings={warnings}
          isSnapshot={roster.isSnapshot}
          redirectToOverviewPage={redirectToEmployeesOverviewPage}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/areas`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[AreaTable]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Areas"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/tasks`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[TasksTable]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Tasks"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/shifts`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[ShiftsTable, ShiftGroupsTable]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Shift/Shift Groups"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/rules`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[RulesTable]}
          locationID={locationID}
          locations={locations}
          headerType={headerType}
          pageName={"Rules"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          location={location}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "200px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/demands`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[DemandsTable]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Staffing Demands"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "300px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/history`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[HistoryTable]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"History"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "300px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/fixedshifts`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[FixedShiftsPage]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Fixed Shifts/Leave"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "300px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/preferences`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[PreferencesPage]}
          locationID={locationID}
          locations={locations}
          location={location}
          headerType={headerType}
          pageName={"Preferences"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          rosterID={rosterID}
          periodNum={schedulePeriodNum}
          customStyles={{
            marginBottom: "300px",
          }}
          globalEmployees={globalEmployees}
          customKeywordsData={customKeywordsData}
          warnings={warnings}
          isRoster={false}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route path={`${url}/solutions`}>
        <RosterGridsLayout
          key={rosterID}
          GridComponents={[SolutionsPage]}
          locationID={locationID}
          locations={locations}
          headerType={headerType}
          pageName={"A.I. Solutions"}
          toggleCreateLocationModal={toggleCreateLocationModal}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          location={location}
          rosterID={rosterID}
          customKeywordsData={customKeywordsData}
          globalEmployees={globalEmployees}
          setLatestSolutionGenStatus={setLatestSolutionGenStatus}
          warnings={warnings}
          isRoster={false}
          periodNum={schedulePeriodNum}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
      <Route exact path={`${url}/generate-locked`}>
        <RosterGridsLayout
          key={rosterID}
          rosterID={rosterID}
          locations={locations}
          location={location}
          locationID={location}
          pageName={"Generate Roster"}
          GridComponents={[GenerateLockedPage]}
          headerType={headerType}
          isScheduleView={true}
          globalEmployees={globalEmployees}
          warnings={warnings}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          toggleCreateLocationModal={toggleCreateLocationModal}
          isSnapshot={roster.isSnapshot}
          isMainstream={!roster.isSnapshot}
        />
      </Route>
    </Switch>
  );
};

export default ScheduleViewRoutes;
