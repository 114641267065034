import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

export const AUTH_STATUS = {
  CONFIRMED: "CONFIRMED",
  FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
};

/**
 * Add account to a group
 * Create new account if not exists
 * Create new group if not exists
 */
export async function addEmployeeToGroup(email, groupName) {
  let userExists = false;
  try {
    await registerUser(email);
  } catch (err) {
    const errorObj = JSON.parse(err.response.body);

    if (errorObj.error.code !== "UsernameExistsException") {
      throw err;
    }
    userExists = true;
  }

  let groupExists = true;
  try {
    await getGroup(groupName);
  } catch (err) {
    const errorObj = JSON.parse(err.response.body);
    if (errorObj.error.code === "ResourceNotFoundException") {
      groupExists = false;
    } else {
      throw err;
    }
  }

  if (!groupExists) {
    await addGroup(groupName);
  }

  let userGroups = await getGroupsForUser(email);

  const groupNames = userGroups.Groups.map((group) => group.GroupName);
  if (!groupNames.includes("rosterer") && !userExists) {
    await addUserToGroup(email, "employee");
  }

  await addUserToGroup(email, groupName);
}

export async function getGroup(groupName) {
  let apiName = "AdminQueries";
  let path = "/getGroup";

  let options = {
    queryParams: {
      groupName: groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  let res = await get({ apiName, path, options }).response;

  return res;
}

export async function resetUserpassword(username) {
  let apiName = "AdminQueries";
  let path = "/resetPassword";
  let options = {
    body: {
      username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };
  return post({ apiName, path, options }).response;
}

export async function checkLocationGroupExists(locationID) {
  const res = await getAllGroups();
  const groups = res.Groups;

  if (!groups || groups.length === 0) {
    return false;
  }
  const groupNames = groups.map((group) => group.GroupName);
  if (groupNames.includes(locationID)) {
    return true;
  }
  return false;
}

export async function addGroup(groupName) {
  let apiName = "AdminQueries";
  let path = "/createGroup";
  let options = {
    body: {
      groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  return post({ apiName, path, options }).response;
}

export async function resendInvitation(email) {
  let apiName = "AdminQueries";
  let path = "/resendInvitation";
  let options = {
    body: {
      username: email,
      email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  let res = await post({ apiName, path, options }).response;

  return res;
}

export async function registerUser(email) {
  let apiName = "AdminQueries";
  let path = "/createUser";
  let options = {
    body: {
      username: email,
      email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  let res = await post({ apiName, path, options }).response;

  return res;
}

export async function getUserByUsername(username) {
  let apiName = "AdminQueries";
  let path = "/getUser";
  let options = {
    queryParams: {
      username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };
  const rest = await get({ apiName, path, options }).response;
  return rest;
}

export async function getAllGroups() {
  let apiName = "AdminQueries";
  let path = "/listGroups";
  let options = {
    queryParams: {},
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  const { body, ...rest } = await get({ apiName, path, options }).response;
  const { Groups } = await body.json();
  return { Groups, ...rest };
}

export async function getGroupsForUser(username) {
  let apiName = "AdminQueries";
  let path = "/listGroupsForUser";
  let options = {
    queryParams: {
      username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  const { body, ...rest } = await get({ apiName, path, options }).response;
  const { Groups } = await body.json();
  return { Groups, ...rest };
}

export async function addUserToGroup(username, userGroupName) {
  let apiName = "AdminQueries";
  let path = "/addUserToGroup";
  let options = {
    body: {
      username,
      groupname: userGroupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };
  return post({ apiName, path, options }).response;
}

export async function detachUserFromGroup(username, userGroupName) {
  let apiName = "AdminQueries";
  let path = "/removeUserFromGroup";
  let options = {
    body: {
      username,
      groupname: userGroupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };
  return post({ apiName, path, options }).response;
}

export async function getUsersInGroup(groupname, limit, nextToken) {
  let apiName = "AdminQueries";
  let path = "/listUsersInGroup";
  let options = {
    queryParams: {
      groupname,
      limit: limit,
      ...(nextToken && { token: nextToken }),
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };

  const userData = await get({ apiName, path, options }).response;

  const { body, ...rest } = userData;
  const jsonBody = await body.json();

  const { Users, NextToken } = jsonBody;
  return { NextToken, ...rest, Users };
}

export async function getUser(email) {
  let apiName = "AdminQueries";
  let path = "/getUser";

  let options = {
    queryParams: {
      username: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: (await fetchAuthSession()).tokens.accessToken.toString(),
    },
  };
  const { body } = await get({ apiName, path, options }).response;
  const user = await body.json();
  return user;
}
