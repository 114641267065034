import { useMemo } from "react";
import { strToArrCommaSeparated } from "../../../../../utils";
import AvailableShiftsListDisplayer from "../AvaliableShiftsListDisplayer/AvailableShiftsListDisplayer";
import IndSkillsAndShiftsContainer from "../IndSkillsAndShiftsContainer/IndSkillsAndShiftsContainer";
import styles from "./IndSkillsAndShiftsWrapper.module.css";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

const getShiftsFromGivenShiftGroups = (
  employeeShiftGroupNames,
  shiftGroups,
  shiftNames
) => {
  let shiftNamesFromGroups = [];

  employeeShiftGroupNames.forEach((groupName) => {
    const shiftGroup = shiftGroups.find((group) => group.name === groupName);
    if (shiftGroup) {
      if (shiftGroup.inversed) {
        const inverseNames = strToArrCommaSeparated(shiftGroup.shifts);
        const allOtherShiftNames = shiftNames.filter(
          (name) => !inverseNames.includes(name)
        );
        shiftNamesFromGroups = shiftNamesFromGroups.concat(allOtherShiftNames);
      } else {
        shiftNamesFromGroups = shiftNamesFromGroups.concat(
          strToArrCommaSeparated(shiftGroup.shifts)
        );
      }
    }
  });

  return shiftNamesFromGroups;
};

const getShiftNamesToDisplay = (
  employeeInput,
  shiftNames,
  shiftGroupNames,
  shiftGroups
) => {
  const employeeShifts = employeeInput.filter((item) =>
    shiftNames.includes(item)
  );
  const employeeShiftGroups = employeeInput.filter((item) =>
    shiftGroupNames.includes(item)
  );

  if (employeeShiftGroups.length === 0) {
    return [];
  }

  const shiftsFromEmployeeShiftGroups = getShiftsFromGivenShiftGroups(
    employeeShiftGroups,
    shiftGroups,
    shiftNames
  );

  const uniqueShifts = [
    ...new Set(employeeShifts.concat(shiftsFromEmployeeShiftGroups)),
  ];

  if (uniqueShifts.includes(KEYWORD_ALL)) {
    return [KEYWORD_ALL];
  }

  return uniqueShifts;
};

const IndSkillsAndShiftsWrapper = ({
  rosterID,
  shiftNames,
  shiftGroupNames,
  isSaving,
  selectedEmployee,
  warnings,
  refreshEmployees,
  isMainStream,
  isPublished,
  locationID,
  startDate,
  customKeywordsUtilObj,
  updateEmployeeWithFields,
  addSkillFromEmployeesTable,
  skills,
  shifts,
  shiftGroups,
  areas,
  isRoster,
}) => {
  const shiftNamesToDisplay = useMemo(() => {
    if (!selectedEmployee) {
      return [];
    }

    const employeeInput = strToArrCommaSeparated(selectedEmployee.shifts);
    if (employeeInput.includes(KEYWORD_ALL)) {
      return [KEYWORD_ALL];
    }

    const uniqueNames = getShiftNamesToDisplay(
      employeeInput,
      shiftNames,
      shiftGroupNames,
      shiftGroups
    );
    uniqueNames.sort();

    return uniqueNames;
  }, [selectedEmployee, shiftNames, shiftGroupNames, shiftGroups]);

  return (
    <div className={styles.container}>
      <IndSkillsAndShiftsContainer
        rosterID={rosterID}
        isSaving={isSaving}
        selectedEmployee={selectedEmployee}
        warnings={warnings}
        refreshEmployees={refreshEmployees}
        isMainStream={isMainStream}
        isPublished={isPublished}
        startDate={startDate}
        locationID={locationID}
        customKeywordsUtilObj={customKeywordsUtilObj}
        updateEmployeeWithFields={updateEmployeeWithFields}
        addSkillFromEmployeesTable={addSkillFromEmployeesTable}
        skills={skills}
        shifts={shifts}
        shiftGroups={shiftGroups}
        areas={areas}
        isRoster={isRoster}
      />
      <AvailableShiftsListDisplayer
        employeeName={selectedEmployee.name}
        shiftNamesToDisplay={shiftNamesToDisplay}
      />
    </div>
  );
};

export default IndSkillsAndShiftsWrapper;
