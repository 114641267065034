import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./BillingPage.module.css";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import { useLocationQuery } from "../../../../hooks/modelQueryHooks/useLocationQuery";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import checkIcon from "../../../../assets/icons/checkCircle.svg";
import minusIcon from "../../../../assets/icons/minusCircle.svg";
import { PLAN, getUserAttributes } from "../../../auth/service/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { HEADER_TYPES } from "../../../../components/elements/Header/Header";
import { useUserStore } from "../../../../globalStore/appStore";
import { openSessionUrl, openHubspotChat } from "../../../../utils";
import { useSearchParams } from "react-router-dom-v5-compat";
import { getBillingPortalSession } from "../../../../utils/queryUtils/appQuery";
import { getNumberGlobalEmployeeModels } from "../../../../utils/queryUtils/locationQuery";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import EmployeeSeatsModal from "../EmployeeSeatsModal/EmployeeSeatsModal";

const adminFeatures = [
  {
    labels: [
      <div key="1">Employee Scheduling - Manual</div>,
      <div key="2">
        Employee Scheduling - AI - For <b>simple rosters</b>
      </div>,
      <div key="3">
        Employee Scheduling - AI - For <b>complex rosters</b>
      </div>,
    ],
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 2,
  },
  {
    label: "AI Scheduling - assigning day shifts",
    includedIn: [PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "AI Scheduling - assigning night shifts",
    includedIn: [PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "AI Scheduling - assigning tasks",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "AI Scheduling - assigning multiple tasks per shift",
    includedIn: [PLAN.AI],
    flaggedIn: [],
    lines: 2,
  },
  {
    label: "Live stats on your staffing levels",
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Live schedules",
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Leave management",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    labels: [
      "Staff Preferences Reminders",
      "Staff Preferences (AI will considers for you)",
      "Staff Preferences",
    ],
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [PLAN.LITE],
    lines: 2,
  },
  {
    labels: [
      "Smart rules violation checks",
      "Up to 8 rules for AI generates",
      "Unlimited Rules to describe your roster models",
    ],
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    lines: 2,
  },
  {
    label: "Open shifts",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Re-rostering",
    includedIn: [PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    labels: [
      "Live chat support",
      "Live chat support + 2 free onboarding sessions",
      "Live chat support + ongoing dedicated onboarding",
    ],
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [PLAN.MID, PLAN.AI],
    lines: 2,
  },
];

const employeeFeatures = [
  {
    label: "View published shifts and tasks",
    includedIn: [PLAN.LITE, PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Leave and preferences requests",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Open shifts and shift swaps",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
  {
    label: "Who's on leave",
    includedIn: [PLAN.MID, PLAN.AI],
    flaggedIn: [],
    lines: 1,
  },
];

const getPlanFeatureList = (allFeatures, plan) => {
  const featureList = [];

  allFeatures.forEach((feature) => {
    const ind = [PLAN.LITE, PLAN.MID, PLAN.AI].indexOf(plan);
    featureList.push({
      label: feature.label ? feature.label : feature.labels[ind],
      included: feature.includedIn.includes(plan),
      isFlag: feature.flaggedIn.includes(plan),
      lines: feature.lines,
    });
  });

  return featureList;
};

const FeatureList = ({ features }) => (
  <ul className={styles.container}>
    {features.map((item) => {
      const { label, included, isFlag, lines } = item;
      return (
        <li
          key={label}
          className={styles.featureOption}
          style={{
            height: `${1.3 * lines}rem`,
          }}
        >
          {included ? (
            isFlag ? (
              <FontAwesomeIcon
                icon={faFlag}
                className={styles.flagIcon}
                style={{ color: "#5246E8" }}
              />
            ) : (
              <img
                alt="included features"
                src={checkIcon}
                className={styles.checkIcon}
              />
            )
          ) : (
            <img
              alt="features not included"
              src={minusIcon}
              className={styles.checkIcon}
            />
          )}
          <div
            className={`${styles.label} ${
              included ? styles.included : styles.notIncluded
            }`}
          >
            {label}
          </div>
        </li>
      );
    })}
  </ul>
);

const Plan = ({ totalGlobalEmployees, globalEmployeesNumPerLocation }) => {
  const [billingPortalSession, setBillingPortalSession] = useState(null);
  const { plan } = useUserStore();

  useEffect(() => {
    if (plan === PLAN.MID) {
      getBillingPortalSession().then(({ billingPortalSession }) => {
        setBillingPortalSession(billingPortalSession);
      });
    }
  }, [plan]);

  let liteButton;
  let midButton;

  switch (plan) {
    case PLAN.LITE:
      liteButton = (
        <div className={styles.planBottomBtnWrapper}>
          <button
            className={`${styles.planBottomBtn} ${styles.lightBtn} ${styles.inUseButton}`}
          >
            IN USE
          </button>
        </div>
      );
      midButton = (
        <EmployeeSeatsModal
          totalGlobalEmployees={totalGlobalEmployees}
          globalEmployeesNumPerLocation={globalEmployeesNumPerLocation}
        >
          <div className={styles.planBottomBtnWrapper}>
            <button className={`${styles.planBottomBtn} ${styles.midTierBtn}`}>
              UPGRADE TO STARTER PLAN
            </button>
          </div>
        </EmployeeSeatsModal>
      );
      break;
    case PLAN.MID:
      liteButton = (
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.planBottomBtnWrapper}
        >
          <button
            className={`${styles.planBottomBtn} ${styles.lightBtn}`}
            onClick={() => openSessionUrl(billingPortalSession)}
          >
            MANAGE SUBSCRIPTION
          </button>
        </a>
      );
      midButton = (
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.planBottomBtnWrapper}
        >
          <button
            className={`${styles.planBottomBtn} ${styles.midTierBtn}`}
            onClick={() => openSessionUrl(billingPortalSession)}
          >
            IN USE
          </button>
        </a>
      );
      break;
    case PLAN.AI:
      liteButton = (
        <div className={styles.planBottomBtnWrapper}>
          <button
            className={`${styles.planBottomBtn} ${styles.lightBtn}`}
            onClick={openHubspotChat}
          >
            CONTACT US
          </button>
        </div>
      );
      midButton = (
        <div className={styles.planBottomBtnWrapper}>
          <button
            className={`${styles.planBottomBtn} ${styles.midTierBtn}`}
            onClick={openHubspotChat}
          >
            CONTACT US
          </button>
        </div>
      );
      break;
  }

  return (
    <div className={styles.pricingBottom}>
      <div className={`${styles.pricingWrapper} ${styles.pricingWrapperLeft}`}>
        <div className={`${styles.planContainer} ${styles.planContainerLeft}`}>
          <div className={styles.planDescContainer}>
            <span className={`${styles.planTopLabel}`}>RosterLab Free</span>
            <span className={styles.priceLabel}>$0 PER MONTH FOREVER</span>
            <p className={styles.planDesc}>
              Free platform to write and maintain your rosters.
            </p>
            {liteButton}
            <p className={styles.forLabel}>FOR SCHEDULING ADMIN & MANAGERS:</p>
          </div>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(adminFeatures, PLAN.LITE)}
            />
          </div>
          <p className={styles.forLabel}>FOR EMPLOYEES (APP & DESKTOP VIEWS)</p>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(employeeFeatures, PLAN.LITE)}
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.pricingWrapper} ${styles.pricingWrapperMiddle}`}
      >
        <div className={`${styles.planContainer} ${styles.planContainerLeft}`}>
          <div className={styles.planDescContainer}>
            <span className={`${styles.planTopLabel}`}>RosterLab Starter</span>
            <span className={styles.priceLabel}>
              $5 AUD PER STAFF ON ROSTER PER MONTH
            </span>
            <p className={styles.planDesc}>
              For rosters without night shifts or high complexity.
            </p>
            {midButton}
            <p className={styles.forLabel}>FOR ROSTERERS:</p>
          </div>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(adminFeatures, PLAN.MID)}
            />
          </div>
          <p className={styles.forLabel}>FOR EMPLOYEES (APP & DESKTOP VIEWS)</p>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(employeeFeatures, PLAN.MID)}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.pricingWrapper} ${styles.pricingWrapperRight}`}>
        <div className={`${styles.planContainer} ${styles.planContainerRight}`}>
          <div className={styles.planDescContainer}>
            <span className={`${styles.planTopLabel}`}>
              RosterLab Premium or Enterprise
            </span>
            <span className={styles.priceLabel}>
              $20 AUD PER STAFF ON ROSTER / MONTH{" "}
              <span className={styles.blueText}>
                OR CONTACT US FOR OVER 20 SEATS
              </span>
            </span>
            <p className={styles.planDesc}>
              Talk to us for a free trial. For complex 24/7 rosters with
              complicated rules and staffing requirements.
            </p>
            <a
              href=""
              className={styles.planBottomBtnWrapper}
              onClick={(e) => e.preventDefault()}
            >
              <button
                className={`${styles.planBottomBtn} ${styles.contactBtn}`}
                onClick={openHubspotChat}
              >
                CONTACT US
              </button>
            </a>

            <p className={styles.forLabel}>FOR ROSTERERS:</p>
          </div>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(adminFeatures, PLAN.AI)}
            />
          </div>
          <p className={styles.forLabel}>FOR EMPLOYEES (APP & DESKTOP VIEWS)</p>
          <div className={styles.listContainer}>
            <FeatureList
              features={getPlanFeatureList(employeeFeatures, PLAN.AI)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const UponRequests = () => {
  return (
    <div className={styles.uponRequests}>
      <p className={styles.uponRequestsTitle}>UPON REQUESTS</p>
      <ul className={styles.uponRequestsList}>
        <li>
          <img
            alt="included features"
            src={checkIcon}
            className={styles.requestCheckIcon}
          />
          Integrations
        </li>
        <li>
          <img
            alt="included features"
            src={checkIcon}
            className={styles.requestCheckIcon}
          />
          RPA
        </li>
        <li>
          <img
            alt="included features"
            src={checkIcon}
            className={styles.requestCheckIcon}
          />
          API
        </li>
        <li>
          <img
            alt="included features"
            src={checkIcon}
            className={styles.requestCheckIcon}
          />
          Consulting
        </li>
      </ul>
    </div>
  );
};

const Billing = ({ changeToPlanPage }) => {
  const { plan } = useUserStore();

  const [billingPortalSession, setBillingPortalSession] = useState(null);

  useEffect(() => {
    if (plan === PLAN.MID) {
      getBillingPortalSession().then(({ billingPortalSession }) => {
        setBillingPortalSession(billingPortalSession);
      });
    }
  }, [plan]);

  const linkStyles = {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
  };

  switch (plan) {
    case PLAN.LITE:
      return (
        <div style={{ width: "100%" }}>
          <h2>Billing Overview</h2>
          <p>
            You&apos;re currently on the freemium plan{" "}
            <span style={linkStyles} onClick={changeToPlanPage}>
              (Upgrade now)
            </span>
          </p>
        </div>
      );
    case PLAN.MID:
      return (
        <div style={{ width: "100%" }}>
          <h2>Billing Overview</h2>
          <p>
            You&apos;re currently on the Starter plan{" "}
            <span
              target="_blank"
              rel="noreferrer"
              onClick={() => openSessionUrl(billingPortalSession)}
              style={linkStyles}
            >
              Manage your subscription
            </span>{" "}
            or{" "}
            <span style={linkStyles} onClick={changeToPlanPage}>
              (Upgrade now)
            </span>
          </p>
        </div>
      );
    case PLAN.AI:
      return (
        <div style={{ width: "100%" }}>
          <h2>Billing Overview</h2>
          <p>
            You&apos;re currently on the premium plan. Contact your Rosterlab
            representative to modify your plan details.
          </p>
        </div>
      );
  }
};

const pages = { Plan: "plan", Billing: "billing" };
const getPageLabel = (page) => {
  switch (page) {
    case pages.Plan:
      return "Plans";
    case pages.Billing:
      return "Billing";
  }
};

const BillingPage = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalGlobalEmployees, setTotalGlobalEmployees] = useState(null);
  const [globalEmployeesNumPerLocation, setGlobalEmployeesNumPerLocation] =
    useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    const { sub, username } = getUserAttributes(user);
    getNumberGlobalEmployeeModels(sub, username).then((response) => {
      const { totalEmployees, globalEmployeesNumPerLocation } = response;
      setTotalGlobalEmployees(totalEmployees);
      setGlobalEmployeesNumPerLocation(globalEmployeesNumPerLocation);
    });
  }, [user]);

  const getCurrentPage = useCallback(() => {
    const queryParam = searchParams.get("tab");
    if (queryParam === "billing") {
      return pages.Billing;
    }
    return pages.Plan;
  }, [searchParams]);

  const currentPage = useMemo(() => {
    return getCurrentPage();
  }, [getCurrentPage]);

  const displayPage = () => {
    switch (currentPage) {
      case pages.Plan:
        return (
          <div className={styles.plansContainer}>
            <Plan
              totalGlobalEmployees={totalGlobalEmployees}
              globalEmployeesNumPerLocation={globalEmployeesNumPerLocation}
            />
            <UponRequests />
          </div>
        );
      case pages.Billing:
        return (
          <Billing
            changeToPlanPage={() => {
              setSearchParams({ tab: pages.Plan });
            }}
          />
        );
      default:
        return null;
    }
  };

  const page = displayPage();

  return (
    <div className={styles["ward-wrapper"]}>
      <div className={styles["upper-container"]}>
        <h1 className={styles["page-title"]}>Plans & Billing</h1>
      </div>
      <div className={styles.content}>
        <nav className={styles.menu}>
          <ul>
            {Object.keys(pages).map((pageName) => {
              return (
                <li
                  key={pageName}
                  className={`${styles["menu-item"]} ${
                    currentPage === pages[pageName] &&
                    styles["active-menu-item"]
                  }`}
                  onClick={() => setSearchParams({ tab: pages[pageName] })}
                >
                  {getPageLabel(pages[pageName])}
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={`${styles["grid-wrapper"]}`}>{page}</div>
      </div>
    </div>
  );
};

const BillingPageWithHeader = withHeader(BillingPage);

const BillingPageWrapper = ({ locations, toggleCreateLocationModal }) => {
  const { location, isQueryLoading } = useLocationQuery(locations, "", true);
  const { user, plan } = useUserStore();

  if ([PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <BillingPageWithHeader
      user={user}
      location={location}
      locations={locations}
      headerType={
        location.isScheduleView
          ? HEADER_TYPES.scheduleViewLocation
          : HEADER_TYPES.classicLocation
      }
      toggleCreateLocationModal={toggleCreateLocationModal}
    />
  );
};

export default BillingPageWrapper;
