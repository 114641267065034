import employeesIcon from "../../../assets/icons/employeesIcon.svg";
import shiftGroupsIcon from "../../../assets/icons/shiftsIcon.svg";
import rulesIcon from "../../../assets/icons/rulesIcon.svg";
import demandsIcon from "../../../assets/icons/demandsIcon.svg";
import historyIcon from "../../../assets/icons/historyIcon.svg";
import fixedShiftsIcon from "../../../assets/icons/fixedShiftsIcon.svg";
import preferencesIcon from "../../../assets/icons/preferencesIcon.svg";
import solutionsIcon from "../../../assets/icons/solutionsIcon.svg";
import generateIcon from "../../../assets/icons/generateIcon.svg";
import dashboardIcon from "../../../assets/icons/dashboardIcon.svg";
import rosterIcon from "../../../assets/icons/rosterIcon.svg";
import taskIcon from "../../../assets/icons/list-check-solid.svg";
import areaIcon from "../../../assets/icons/areaIcon.svg";
import {
  employeePageViewSetting,
  myRosterPageViewSetting,
} from "../../../globalStore/defaultViewsStore";
import { PLAN } from "../../../features/auth/service/auth";
import { hasSetting } from "../../../utils/settingsUtils/settingsUtils";

export const SIDEBAR_BUTTON_TYPE = Object.freeze({
  generateButton: "generate",
  dashboardButton: "dashboard",
  commonButtons: "common",
});

export function getSideBarItems(
  defaultViews,
  isScheduleView,
  plan,
  frontendSettings
) {
  const defaultEmployeesPageView = defaultViews[employeePageViewSetting.name];
  const defaultMyRosterPageView = defaultViews[myRosterPageViewSetting.name];

  const dashboard = {
    label: "Dashboard",
    routeTo: "/locations/classic-view/dashboard",
    icon: dashboardIcon,
    testName: "dashboard-nav-btn",
    iconStyle: {
      height: "38px",
      width: "38px",
    },
    expandedIconStyle: {
      marginLeft: "-6px",
      marginRight: "4px",
      marginTop: "4px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.dashboardButton,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [],
    enabledInPrototypeView: true,
  };
  const myRoster = {
    label: "My Roster",
    routeTo: isScheduleView
      ? `/locations/schedule-view/${
          defaultMyRosterPageView ===
          myRosterPageViewSetting.possibleValues.shiftView
            ? "shift-view"
            : "roster"
        }`
      : `/roster/${
          defaultMyRosterPageView ===
          myRosterPageViewSetting.possibleValues.shiftView
            ? "shift-view"
            : "roster"
        }`,
    icon: rosterIcon,
    testName: "roster-nav-btn",
    iconStyle: {
      height: "26px",
      width: "26px",
      marginBottom: "5px",
    },
    expandedIconStyle: {
      marginRight: "10px",
      marginBottom: "2px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [],
    enabledInPrototypeView: true,
  };
  const employees = {
    label: "Employees",
    routeTo:
      defaultEmployeesPageView ===
      employeePageViewSetting.possibleValues.individual
        ? `${
            isScheduleView ? "/locations/schedule-view" : "/roster"
          }/employees-individual`
        : `${
            isScheduleView ? "/locations/schedule-view" : "/roster"
          }/employees`,
    icon: employeesIcon,
    testName: "employees-nav-btn",
    iconStyle: {
      height: "24px",
      width: "24px",
      marginBottom: "2px",
    },
    expandedIconStyle: {
      marginLeft: "2px",
      marginRight: "10px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const areas = {
    label: "Areas",
    routeTo: isScheduleView
      ? "/locations/schedule-view/areas"
      : "/roster/areas",
    icon: areaIcon,
    iconStyle: {
      height: "24px",
      width: "24px",
      marginLeft: "1px",
      marginBottom: "4px",
    },
    expandedIconStyle: {
      marginLeft: "2px",
      marginRight: "11px",
      marginBottom: "1px",
    },
    testName: "tasks-nav-btn",
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const tasks = {
    label: "Tasks",
    routeTo: isScheduleView
      ? "/locations/schedule-view/tasks"
      : "/roster/tasks",
    icon: taskIcon,
    iconStyle: {
      height: "24px",
      width: "24px",
      marginLeft: "1px",
      marginBottom: "4px",
    },
    expandedIconStyle: {
      marginLeft: "2px",
      marginRight: "11px",
      marginBottom: "1px",
    },
    testName: "tasks-nav-btn",
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const shiftsAndShiftGroups = {
    label: "Shift/Shift Groups",
    routeTo: isScheduleView
      ? "/locations/schedule-view/shifts"
      : "/roster/shifts",
    icon: shiftGroupsIcon,
    testName: "shifts-nav-btn",
    iconStyle: {
      height: "35px",
      width: "35px",
      marginBottom: "-4px",
    },
    expandedIconStyle: {
      marginLeft: "-3px",
      marginRight: "6px",
      marginBottom: "0px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const rules = {
    label: "Rules",
    routeTo: isScheduleView
      ? "/locations/schedule-view/rules"
      : "/roster/rules",
    icon: rulesIcon,
    testName: "rules-nav-btn",
    iconStyle: {
      height: "22px",
      width: "22px",
      marginBottom: "4px",
      marginLeft: "2px",
    },
    expandedIconStyle: {
      marginLeft: "4px",
      marginRight: "13px",
      marginBottom: "2px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const demands = {
    label: "Demands",
    routeTo: isScheduleView
      ? "/locations/schedule-view/demands"
      : "/roster/demands",
    icon: demandsIcon,
    testName: "demands-nav-btn",
    iconStyle: {
      height: "32px",
      width: "32px",
      marginBottom: "-3px",
      marginLeft: "1px",
    },
    expandedIconStyle: {
      marginLeft: "-1px",
      marginRight: "10px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const history = {
    label: "History",
    routeTo: isScheduleView
      ? "/locations/schedule-view/history"
      : "/roster/history",
    icon: historyIcon,
    testName: "history-nav-btn",
    iconStyle: {
      height: "34px",
      width: "34px",
      marginTop: "-3px",
      marginBottom: "2px",
      marginLeft: "3px",
    },
    expandedIconStyle: {
      marginLeft: "-1px",
      marginRight: "9px",
    },
    isFullVersionMenu: true,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: true,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const fixedShifts = {
    label: "Fixed Shifts/Leave",
    routeTo: isScheduleView
      ? "/locations/schedule-view/fixedshifts"
      : "/roster/fixedshifts",
    icon: fixedShiftsIcon,
    testName: "fixed-shifts-nav-btn",
    iconStyle: {
      height: "42px",
      width: "42px",
      marginLeft: "9px",
      marginBottom: "-9px",
    },
    expandedIconStyle: {
      marginLeft: "-3px",
      marginRight: "1px",
      marginBottom: "5px",
    },
    isFullVersionMenu: true,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: true,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const preferences = {
    label: "Preferences",
    routeTo: isScheduleView
      ? "/locations/schedule-view/preferences"
      : "/roster/preferences",
    icon: preferencesIcon,
    testName: "preferences-nav-btn",
    iconStyle: {
      height: "32px",
      width: "32px",
      marginLeft: "3px",
      marginBottom: "-1px",
    },
    expandedIconStyle: {
      marginLeft: "1px",
      marginRight: "9px",
      marginBottom: "1px",
    },
    isFullVersionMenu: false,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const solutions = {
    label: "A.I. Solutions",
    routeTo: isScheduleView
      ? "/locations/schedule-view/solutions"
      : "/roster/solutions",
    icon: solutionsIcon,
    testName: "solutions-nav-btn",
    iconStyle: {
      height: "30px",
      width: "30px",
      marginBottom: "-2px",
      marginLeft: "1px",
    },
    expandedIconStyle: {
      marginLeft: "1px",
      marginRight: "10px",
    },
    isFullVersionMenu: true,
    buttonType: SIDEBAR_BUTTON_TYPE.commonButtons,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };
  const generate = {
    label: "Generate Roster",
    routeTo: "/",
    icon: generateIcon,
    testName: "generate-roster-nav-btn",
    iconStyle: {
      marginLeft: "3px",
      height: "27px",
      width: "27px",
      marginBottom: "-4px",
    },
    expandedIconStyle: {
      marginRight: "10px",
    },
    isFullVersionMenu: true,
    buttonType: SIDEBAR_BUTTON_TYPE.generateButton,
    hideWarningCountForFreeUsers: false,
    restrictedPlans: [PLAN.COORDINATOR],
    enabledInPrototypeView: true,
  };

  const isAreaEnabled = hasSetting(frontendSettings, "locationAreas");

  return [
    dashboard,
    myRoster,
    employees,
    areas,
    shiftsAndShiftGroups,
    tasks,
    rules,
    demands,
    history,
    fixedShifts,
    preferences,
    solutions,
    generate,
  ]
    .filter((item) => !item.restrictedPlans.includes(plan))
    .filter((item) => isScheduleView || item.enabledInPrototypeView)
    .filter((item) => item !== areas || isAreaEnabled);
}
