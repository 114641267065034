import {
  getGlobalEmployeeModelsByLocationId,
  getLocationModelById,
} from "../../../utils/queryUtils/locationQuery";

export const downloadLocationSetupAsJSON = async (locationID, locationName) => {
  const location = await getLocationModelById(locationID);
  const globalEmployees = await getGlobalEmployeeModelsByLocationId(locationID);

  const mergedObj = {
    ...location,
    Employees: globalEmployees,
  };
  const fileName = `${locationName}`;
  const json = JSON.stringify(mergedObj);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
