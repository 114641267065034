import { isNativeMobileApp } from "../../globalStore/deviceInfoStore";
import { Network } from "@capacitor/network";

export async function isNetworkConnectionActive() {
  const isNativeMobile = isNativeMobileApp();
  if (isNativeMobile) {
    const { connected } = await Network.getStatus();
    return connected;
  }
  return window.navigator.onLine;
}
