import { useState } from "react";
import { DateTime } from "../../../../../utils";
import { PLAN } from "../../../../auth/service/auth";
import ContentViewTypeSwitcher from "../../ContentViewTypeSwitcher/ContentViewTypeSwitcher";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import EmployeeAppDateSwitcher from "../EmployeeAppDateSwitcher/EmployeeAppDateSwitcher";
import styles from "./EmployeeAppActionBar.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getLiveScheduleUrl } from "../../../service/employeeAppUtil";

const EmployeeAppActionBar = ({
  location,
  currentViewData,
  setContentViewType,
  setSelectedDate,
  showOpenShifts,
  toggleOpenShifts,
  userEmployee,
  plan,
}) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [isSubscribeToCalendarLinkOpen, setIsSubscribeToCalendarLinkOpen] =
    useState(false);
  const { contentViewType, selectedDate, calendarViewType } = currentViewData;

  const getCalendarSubscriptionUrl = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `https://api.rosterlab.com/icalendar?userId=${userEmployee.id}&timezone=${timezone}`;
    return url;
  };

  const closeCalendarSubscriptionDialog = () => {
    setIsSubscribeToCalendarLinkOpen(false);
  };

  const openCalendarSubscriptionDialog = () => {
    setIsSubscribeToCalendarLinkOpen(true);
  };

  const handleCopySubscriptionUrl = async () => {
    const url = getCalendarSubscriptionUrl();
    try {
      await navigator.clipboard.writeText(url);
      setHasCopied(true);
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  return (
    <div className={styles.container}>
      <Dialog open={isSubscribeToCalendarLinkOpen}>
        <DialogTitle id="copy-calendar-subscription-link">
          Subscribe to My Calendar
        </DialogTitle>
        <DialogContent>
          <div className={styles.subscriptionContainer}>
            <input
              className={styles.subscriptionLink}
              value={getCalendarSubscriptionUrl()}
              readOnly={true}
            />
            <button
              className={styles.copySubscriptionLinkButton}
              onClick={handleCopySubscriptionUrl}
            >
              {!hasCopied ? "Copy link" : "Copied"}
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCalendarSubscriptionDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <div className={styles.left}>
        <button
          className={styles.todayButton}
          onClick={() => setSelectedDate(new DateTime())}
        >
          TODAY
        </button>
        <EmployeeAppDateSwitcher
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          calendarViewType={calendarViewType}
        />
      </div>
      <div className={styles.middle}>
        <ContentViewTypeSwitcher
          switchableContentViewTypes={[
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences,
            EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave,
          ]}
          contentViewType={contentViewType}
          setContentViewType={setContentViewType}
          customStyle={{
            height: "40px",
            border: "1px solid #3DBCBB",
          }}
        />
      </div>
      <div className={styles.right}>
        {plan !== PLAN.LITE && (
          <>
            <button
              className={`${styles.viewOpenShiftsButton}  ${
                showOpenShifts && styles.active
              }`}
              onClick={toggleOpenShifts}
            >
              View all open shifts
            </button>
            <button
              className={styles.subscribeButton}
              onClick={openCalendarSubscriptionDialog}
            >
              Subscribe to my calendar
            </button>
            <button
              className={styles.subscribeButton}
              onClick={() => {
                window.open(getLiveScheduleUrl(location.id), "_blank");
              }}
            >
              Live Schedule
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeAppActionBar;
