import {
  faCalendarDay,
  faCircleArrowUp,
  faDownload,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo } from "react";
import { Link, useLocation as useRouteLocation } from "react-router-dom";
import logo from "../../../assets/images/logo_white.png";
import {
  PLAN,
  getLocationIDsInUserGroups,
  getUserGroups,
  logOut,
} from "../../../features/auth/service/auth";
import {
  downloadJSON,
  downloadRosterAsJSON,
} from "../../../features/rosterProblems/service/downloadRoster";
import { useUserStore } from "../../../globalStore/appStore";
import LocationSelector from "../LocationSelector/LocationSelector";
import styles from "./Header.module.css";
import UserDropdown from "../UserDropdown/UserDropdown";
import Notification from "../Notification/Notification";
import { useNotificationStore } from "../../../globalStore/notificationStore";
import { PAGE_NAMES, useRedirect } from "../../../utils/routeUtils/redirect";
import ShareRosterModal from "../../../features/shareRostser/components/ShareRosterModal/ShareRosterModal";
import { useShareRosterModal } from "../../../features/shareRostser/hooks/useShareRosterModal";
import { useToggleShow } from "../../../hooks/useToggleShow";
import { useLocation } from "react-router-dom-v5-compat";
import { isMobileViewOrApp } from "../../../globalStore/deviceInfoStore";
import { getFieldsFromLocation } from "../../../utils/queryUtils/locationDataGetters";
import { getRosterInView } from "../../../hooks/modelQueryHooks/useScheduleQuery";
import {
  getGlobalEmployeeModelsByLocationId,
  getLocationModelById,
} from "../../../utils/queryUtils/locationQuery";
import { getRosterModelById } from "../../../utils/queryUtils/rosterQuery";
import { useQueryClient } from "@tanstack/react-query";
import { getIds } from "../../../utils";
import { getTaskQueueInfo } from "../../../utils/performanceUtils/queue";
import TourChecklistToggleButton from "../../../features/onBoarding/components/TourChecklistToggleButton/TourChecklistToggleButton";
import TopBanner from "../TopBanner/TopBanner";
import {
  customConfirmAlert,
  customWarningAlert,
} from "../../../features/confirm/service/confirm";

export const HEADER_TYPES = Object.freeze({
  roster: "roster",
  classicLocation: "classic-location",
  scheduleViewLocation: "schedule-view-location",
  help: "help",
});

const RosterHeaderRight = ({
  type,
  periodStartDate,
  isMobile,
  location,
  isEmployeeView,
  rosterID,
  isSnapshot,
  isMainStream,
}) => {
  const routeLocation = useLocation();
  const locationID = location.id;
  const currentPath = routeLocation.pathname;

  const { email: userEmail } = useUserStore();

  const {
    openShareRosterModal,
    closeShareRosterModal,
    isShareRosterModalOpen,
  } = useShareRosterModal();

  const shareRosterModal = isShareRosterModalOpen ? (
    <ShareRosterModal
      closeShareRosterModal={closeShareRosterModal}
      selectedLocationID={locationID}
      senderEmail={userEmail}
      periodStartDate={periodStartDate}
      location={location}
      rosterID={rosterID}
    />
  ) : null;

  const downloadRoster = () => {
    downloadRosterAsJSON(rosterID);
  };

  const downloadSchedule = async () => {
    const [globalEmployees, location] = await Promise.all([
      getGlobalEmployeeModelsByLocationId(locationID),
      getLocationModelById(locationID),
    ]);

    if (!rosterID) {
      return;
    }
    const roster = await getRosterModelById(rosterID);
    const rosterInView = getRosterInView(location, globalEmployees, roster);

    const nameInFile = isSnapshot ? rosterInView.name : location.name;

    const rosterInViewWithoutSolutions = {
      ...rosterInView,
      Solutions: { items: [] },
    };

    const locationName = location.name;

    downloadJSON(
      JSON.stringify({
        ...rosterInViewWithoutSolutions,
        locationType: "schedule",
        locationName,
      }),
      `${nameInFile}_${periodStartDate}`
    );
  };

  const getDownloadButton = () => {
    if (type === "roster") {
      if (!rosterID) {
        return null;
      }
    }

    if (type === "schedule") {
      if (!currentPath.startsWith("/locations/schedule-view")) {
        return null;
      }
    }

    return (
      <button
        className={styles.button}
        onClick={type === "roster" ? downloadRoster : downloadSchedule}
        title="Download roster"
      >
        <FontAwesomeIcon icon={faDownload} className={styles.icon} />
      </button>
    );
  };

  if (isMobile) {
    return null;
  }

  return (
    <>
      {shareRosterModal}
      {!isEmployeeView && isMainStream && (
        <button className={styles.button} onClick={openShareRosterModal}>
          <FontAwesomeIcon className={styles.icon} icon={faShareNodes} />
        </button>
      )}
      {getDownloadButton()}
    </>
  );
};

const NavItems = ({
  headerType,
  locationID,
  currentPath,
  requestNotification,
  periodStartDate,
  isEmployeeView,
  queryClient,
  isScheduleView,
  isSnapshot,
  plan,
}) => {
  const { isOpen, toggleDropdown, selectorTriggerRef, selectorRef } =
    useToggleShow();

  const { isPaidPlan } = useUserStore();

  const isClassicLocationModeComponent =
    headerType === HEADER_TYPES.roster ||
    headerType === HEADER_TYPES.classicLocation;
  const isScheduleLocationModeComponent =
    headerType === HEADER_TYPES.scheduleViewLocation;

  let scheduleUrlInfo = {
    pathname: `/locations/schedule-view/roster`,
    search: `?location-id=${locationID}${
      periodStartDate && !isEmployeeView ? `&date=${periodStartDate}` : ""
    }`,
  };

  if (isEmployeeView) {
    const adminLocations = queryClient.getQueryData(["allLocations"]);
    const adminLocationIDs = getIds(adminLocations);

    if (!adminLocationIDs.includes(locationID)) {
      scheduleUrlInfo = {
        pathname: "/",
      };
    }
  }

  const SchedulesItem = (
    <Link
      to={scheduleUrlInfo}
      className={`${styles.navItem} ${
        currentPath === "/locations/schedule-view/roster" &&
        styles.navItemSelected
      }`}
      data-testid="to-schedules-link"
    >
      <span>Schedules</span>
    </Link>
  );

  const DashboardItem = (
    <Link
      to={{
        pathname: `/locations/classic-view/dashboard`,
        search: `?location-id=${locationID}`,
      }}
      className={`${styles.navItem} ${
        currentPath === "/locations/classic-view/dashboard" &&
        styles.navItemSelected
      }`}
      data-testid="to-dashboard-link"
    >
      <span>Dashboard</span>
    </Link>
  );

  const TimeSheetItem = (
    <Link
      to={{
        pathname: `/locations/timesheet`,
        search: `?location-id=${locationID}${
          periodStartDate && !isEmployeeView ? `&date=${periodStartDate}` : ""
        }`,
      }}
      className={`${styles.navItem} ${
        currentPath === "/locations/timesheet" && styles.navItemSelected
      } ${styles.calendarBtn}`}
      data-testid="to-timesheet-link"
    >
      <span>Timesheet</span>
      {!isPaidPlan && (
        <div className={styles.limitedContainer}>
          <FontAwesomeIcon
            icon={faCircleArrowUp}
            className={styles.limitedIcon}
            style={{
              color: "white",
              marginRight: "3px",
            }}
          />
        </div>
      )}
    </Link>
  );

  const RequestsItem = (
    <div
      className={
        styles.navItem +
        " " +
        (currentPath === "/locations/requests" ||
        currentPath === "/locations/preferences"
          ? styles.navItemSelected
          : "")
      }
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
      data-testid="requests-dropdown"
      ref={selectorTriggerRef}
    >
      {requestNotification.filter((item) => item.locationID === locationID)
        .length !== 0 && <div className={styles.requestNotification}></div>}
      <div className={styles.dropdown}>
        <span className={styles.dropBtn}>Requests</span>
        {isOpen && (
          <div className={styles.dropdownContent} ref={selectorRef}>
            {requestNotification.filter(
              (item) =>
                item.locationID === locationID && item.type === "requests"
            ).length !== 0 && (
              <div className={styles.requestNotification}></div>
            )}
            <Link
              to={{
                pathname: `/locations/requests`,
                search: `?location-id=${locationID}${
                  periodStartDate && !isEmployeeView
                    ? `&date=${periodStartDate}`
                    : ""
                }`,
              }}
              className={`${styles.dropbtn} ${styles.navItem} ${
                currentPath === "/locations/requests" && styles.navItemSelected
              } `}
              data-testid="to-leave-requests-link"
            >
              Leave requests
              {!isPaidPlan && (
                <div className={styles.limitedContainer}>
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    className={styles.limitedIcon}
                    style={{
                      color: "#00d4aa",
                    }}
                  />
                </div>
              )}
              {requestNotification.filter(
                (item) =>
                  item.locationID === locationID && item.type === "request"
              ).length !== 0 && (
                <div className={styles.requestNotification}></div>
              )}
            </Link>
            <Link
              to={{
                pathname: `/locations/preferences`,
                search: `?location-id=${locationID}${
                  periodStartDate && !isEmployeeView
                    ? `&date=${periodStartDate}`
                    : ""
                }`,
              }}
              className={`${styles.dropbtn} ${styles.navItem} ${
                currentPath === "/locations/preferences" &&
                styles.navItemSelected
              } `}
              data-testid="to-preferences-link"
            >
              Preferences
              {!isPaidPlan && (
                <div className={styles.limitedContainer}>
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    className={styles.limitedIcon}
                    style={{
                      color: "#00d4aa",
                    }}
                  />
                </div>
              )}
              {requestNotification.filter(
                (item) =>
                  item.locationID === locationID && item.type === "preference"
              ).length !== 0 && (
                <div className={styles.requestNotification}></div>
              )}
            </Link>
            {![PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan) && (
              <Link
                to={{
                  pathname: `/locations/fixedshifts`,
                  search: `?location-id=${locationID}${
                    periodStartDate && !isEmployeeView
                      ? `&date=${periodStartDate}`
                      : ""
                  }`,
                }}
                className={`${styles.dropbtn} ${styles.navItem} ${
                  currentPath === "/locations/fixedshifts" &&
                  styles.navItemSelected
                } `}
                data-testid="to-fixedshifts-link"
              >
                Fixed Shifts
                {!isPaidPlan && (
                  <div className={styles.limitedContainer}>
                    <FontAwesomeIcon
                      icon={faCircleArrowUp}
                      className={styles.limitedIcon}
                      style={{
                        color: "#00d4aa",
                      }}
                    />
                  </div>
                )}
                {requestNotification.filter(
                  (item) =>
                    item.locationID === locationID && item.type === "preference"
                ).length !== 0 && (
                  <div className={styles.requestNotification}></div>
                )}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <nav>
      {isScheduleLocationModeComponent && SchedulesItem}
      {isClassicLocationModeComponent && DashboardItem}
      {!isSnapshot && isScheduleView && !isEmployeeView && TimeSheetItem}
      {!isSnapshot && isScheduleView && !isEmployeeView && RequestsItem}
    </nav>
  );
};

const Header = ({
  headerType,
  toggleCreateLocationModal,
  locations,
  periodStartDate,
  hideLocationCreator = false,
  isShareRosterPage = false,
  location,
  globalEmployees,
  rosterID,
  isSnapshot,
}) => {
  const { plan, setToCurrentUser } = useUserStore();
  const queryClient = useQueryClient();

  const isMobile = isMobileViewOrApp();
  const { requestNotification, refreshRequestNotification } =
    useNotificationStore();
  const currentUrl = useRouteLocation();
  const currentPath = currentUrl.pathname;
  const isEmployeeView = currentPath === "/employee-view";
  const redirectTo = useRedirect();

  const { locationID } = useMemo(
    () => getFieldsFromLocation(location),
    [location]
  );

  const isMainStream = location.isScheduleView && !isSnapshot;

  useEffect(() => {
    refreshRequestNotification(globalEmployees);
  }, [refreshRequestNotification, globalEmployees]);

  useEffect(() => {
    if (window._hsq) {
      window._hsq.push(["setPath", currentPath]);
      window._hsq.push(["trackPageView"]);
    }
  }, [currentPath]);

  const notifications = useMemo(() => {
    const notificationsForRequests = requestNotification.map((item) => {
      return {
        ...item,
        onClick: () => {
          redirectTo({
            pageName:
              item.type === "request"
                ? PAGE_NAMES.requests
                : PAGE_NAMES.preferences,
            locationID: item.locationID,
          });
        },
      };
    });

    return [...notificationsForRequests];
  }, [redirectTo, requestNotification]);

  const redirectToMainPage = useCallback(() => {
    if (!locationID || !location) {
      redirectTo({
        pageName: PAGE_NAMES.main,
      });
      return;
    }

    if (location.isScheduleView) {
      redirectTo({
        pageName: PAGE_NAMES.scheduleViewLocation,
        query: {
          "location-id": location.id,
        },
      });
      return;
    }

    redirectTo({
      pageName: PAGE_NAMES.dashboard,
      locationID: location.id,
    });
  }, [location, locationID, redirectTo]);

  const handleEmployeeViewBtnClick = useCallback(async () => {
    if (isEmployeeView) {
      return;
    }
    const latestUser = await setToCurrentUser();
    const userGroups = getUserGroups(latestUser);
    const locationIDUserGroups = getLocationIDsInUserGroups(latestUser);

    if (!userGroups || locationIDUserGroups.length === 0) {
      customWarningAlert({
        title: "You are not an employee of any location",
        descriptions: [PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)
          ? []
          : [
              <>
                You can add yourself as an employee to this location by adding
                your email in the Employees page.
              </>,
            ],
      });
      return;
    }

    const targetLocationID = locationIDUserGroups.includes(locationID)
      ? locationID
      : locationIDUserGroups[0];

    queryClient.invalidateQueries(["employeeLocation", targetLocationID]);

    redirectTo({
      pageName: PAGE_NAMES.desktopEmployeeViewSchedule,
      query: {
        "location-id": targetLocationID,
      },
    });
  }, [
    isEmployeeView,
    locationID,
    redirectTo,
    setToCurrentUser,
    queryClient,
    plan,
  ]);

  return (
    <div style={{ zIndex: 5 }}>
      <TopBanner />
      <header
        className={`${styles.container} ${isSnapshot && styles.snapshot}`}
      >
        {headerType !== HEADER_TYPES.help && (
          <div className={styles.headerLeft}>
            {!isShareRosterPage && (
              <div className={styles.wardSelectorWrapper}>
                <LocationSelector
                  location={location}
                  toggleCreateLocationModal={toggleCreateLocationModal}
                  locations={locations}
                  hideLocationCreator={hideLocationCreator}
                  isEmployeeView={isEmployeeView}
                />
              </div>
            )}
            {!isMobile && !isShareRosterPage && (
              <NavItems
                headerType={headerType}
                locationID={locationID}
                currentPath={currentPath}
                requestNotification={requestNotification}
                periodStartDate={periodStartDate}
                isEmployeeView={isEmployeeView}
                queryClient={queryClient}
                isScheduleView={location.isScheduleView}
                isSnapshot={isSnapshot}
                plan={plan}
              />
            )}
          </div>
        )}
        <img
          onClick={redirectToMainPage}
          className={styles.logo}
          src={logo}
          alt="Rosterlab logo"
        />
        <div className={styles.headerRight}>
          {!isShareRosterPage && (
            <button
              className={`${styles.button} ${
                isEmployeeView ? styles.isEmployeeView : null
              }`}
              onClick={handleEmployeeViewBtnClick}
              title="Employee view"
            >
              <FontAwesomeIcon icon={faCalendarDay} className={styles.icon} />
            </button>
          )}
          {(headerType === HEADER_TYPES.scheduleViewLocation ||
            headerType === HEADER_TYPES.roster) && (
            <RosterHeaderRight
              location={location}
              type={
                headerType
                  ? headerType.replace("schedule-view-location", "schedule")
                  : "roster"
              }
              periodStartDate={periodStartDate}
              isMobile={isMobile}
              isEmployeeView={isEmployeeView}
              rosterID={rosterID}
              isSnapshot={isSnapshot}
              isMainStream={isMainStream}
            />
          )}
          {headerType === HEADER_TYPES.scheduleViewLocation &&
            (plan === PLAN.LITE || plan === PLAN.MID) && (
              <TourChecklistToggleButton />
            )}
          {!isMobile && !isShareRosterPage && (
            <Notification notifications={notifications} />
          )}
          <div className={styles.userWrapper}>
            <UserDropdown
              signOut={async () => {
                const { length, isProcessing } = getTaskQueueInfo();
                if (length !== 0 || isProcessing) {
                  const shouldLogOut = await customConfirmAlert({
                    title: "Data still saving. Are you sure to log out?",
                  });

                  if (!shouldLogOut) {
                    return;
                  }
                }
                logOut().then(() => {
                  redirectTo({ pageName: PAGE_NAMES.main });
                });
              }}
              isMobile={isMobile}
              location={location}
              isSnapshot={isSnapshot}
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
