import _ from "lodash";
import { deepCopyObject, hasCommonItems } from "../../../utils";
import {
  fetchAuthSession,
  getCurrentUser,
  signOut,
  deleteUser,
} from "aws-amplify/auth";
import {
  getCollaboratorLocationRegex,
  getCoordinatorLocationRegex,
  getKioskLocationRegex,
} from "../../../utils/generalUtils/regex";
import { obfuscateString } from "../../shareRostser/components/ShareRosterModal/ShareRosterModal";
import { objectToKeyValuePairsString } from "../../../utils/generalUtils/object";

const getAuthenticatedUser = async (forceRefresh) => {
  const { username } = await getCurrentUser();
  const { tokens: session } = await fetchAuthSession({ forceRefresh });

  return {
    username,
    session,
  };
};

const getCurrentAuthSession = async () => {
  return fetchAuthSession();
};

export const getCurrentAuthUser = async (forceRefresh = false) => {
  if (forceRefresh) {
    return getAuthenticatedUser(forceRefresh);
  }
  return getAuthenticatedUser();
};

export const refreshUserData = async (forceRefresh = false) => {
  return getCurrentAuthUser(forceRefresh);
};

export const getCurrentAuthUserType = async () => {
  const currentAuthenticatedUser = await getCurrentAuthUser();
  const userType =
    currentAuthenticatedUser.session.accessToken.payload["cognito:groups"];
  return userType;
};

export const getCurrentAuthUserEmail = async () => {
  const session = await getCurrentAuthSession();
  return session.tokens.idToken.payload.email;
};

export const logOut = async () => {
  return signOut();
};

export const getUserEmail = (user) => {
  return user.session.idToken.payload["email"];
};

export const userGetNumSeats = (user) => {
  const seats = user.session.idToken.payload["custom:employee_seats"];
  return seats ? seats : 0;
};

export const getStripeCustomerId = (user) => {
  const stripeCustomerId =
    user.session.idToken.payload["custom:stripeCustomerId"];
  return stripeCustomerId;
};

export const userHasDashboardAccess = (user) => {
  return userIsAdmin(user);
};

export const userIsInternal = (user) => {
  if (!user) {
    return false;
  }
  return (
    user.session.accessToken.payload["cognito:groups"] &&
    user.session.accessToken.payload["cognito:groups"].includes("internal")
  );
};

export const userIsAdmin = (user) => {
  if (!user) {
    return false;
  }

  const userGroups = user.session.accessToken.payload["cognito:groups"];

  if (!userGroups) {
    return true;
  }

  const isCoordinator = isUserInCoordinatorGroup(user);
  const isCollaborator = isUserInCollaboratorGroup(user);

  if (userGroups.includes("employee")) {
    if (isCoordinator || isCollaborator) {
      return true;
    }
    return false;
  }

  return true;
};

/**
 * @param {[string]} adminLocationIDs - only applicable if user is admin
 */
export const userIsSchedulableEmployee = (user, adminLocationIDs = []) => {
  if (!user.session.accessToken.payload["cognito:groups"]) {
    return false;
  }

  // user is admin
  if (user.session.accessToken.payload["cognito:groups"].includes("rosterer")) {
    const adminUserGroups = user.session.accessToken.payload["cognito:groups"];
    if (hasCommonItems(adminUserGroups, adminLocationIDs)) {
      return true;
    }
    return false;
  }

  // user is not admin
  if (user.session.accessToken.payload["cognito:groups"].includes("employee")) {
    return true;
  }

  // internal user
  if (userIsInternal(user)) {
    return true;
  }

  return false;
};

export const PLAN = {
  LITE: "lite",
  MID: "mid",
  AI: "AI",
  INTERNAL: "internal",
  EMPLOYEE: "employee",
  KIOSK: "kiosk",
  COORDINATOR: "coordinator",
  COLLABORATOR: "collaborator",
};

export function getUserAttributes(user) {
  return user.session.accessToken.payload;
}

export const getUserGroups = (user) => {
  if (!user) {
    return [];
  }

  const payload = user.session.accessToken.payload;
  if (!payload || !payload["cognito:groups"]) {
    return [];
  }

  return payload["cognito:groups"];
};

export const isUserInRostererGroup = (user) => {
  if (!user) {
    return null;
  }

  const userGroups = getUserGroups(user);
  if (!userGroups) {
    return null;
  }

  return userGroups.includes("rosterer");
};

export const isUserInInternalGroup = (user) => {
  if (!user) {
    return null;
  }

  const userGroups = getUserGroups(user);
  if (!userGroups) {
    return null;
  }

  return userGroups.includes("internal");
};

export const isUserInKioskGroup = (user) => {
  return checkForRegexGroup(user, getKioskLocationRegex());
};

export function isUserInCoordinatorGroup(user) {
  return checkForRegexGroup(user, getCoordinatorLocationRegex());
}

export function isUserInCollaboratorGroup(user) {
  return checkForRegexGroup(user, getCollaboratorLocationRegex());
}

function checkForRegexGroup(user, regex) {
  if (!user) {
    return false;
  }

  const userGroups = getUserGroups(user);
  if (!userGroups) {
    return null;
  }

  const coordinatorGroup = userGroups.find((group) => regex.test(group));

  return coordinatorGroup;
}

export const isUserInEmployeeGroup = (user) => {
  if (!user) {
    return null;
  }

  const userGroups = getUserGroups(user);
  if (!userGroups) {
    return null;
  }

  return userGroups.includes("employee");
};

export const getLocationIDsInUserGroups = (user) => {
  const userGroups = getUserGroups(user);
  return userGroups
    ? userGroups.filter((group) => {
        const coordinatorLocationRegex = getCoordinatorLocationRegex();
        const kioskLocationRegex = getKioskLocationRegex();

        return (
          !["rosterer", "internal", "employee"].includes(group) &&
          !coordinatorLocationRegex.test(group) &&
          !kioskLocationRegex.test(group)
        );
      })
    : [];
};

export const handleDeleteAccount = async () => {
  try {
    await deleteUser();
  } catch (error) {
    console.log("Error deleting account:", error);
  }
};

export const getFirstAndLastNameOfUser = (user) => {
  const nameInfo = {
    firstName: "",
    lastName: "",
  };

  if (!user || !user.session) {
    return nameInfo;
  }

  const { given_name, family_name } = user.session.idToken.payload;
  if (_.isString(given_name)) {
    nameInfo.firstName = given_name;
  }

  if (_.isString(family_name)) {
    nameInfo.lastName = family_name;
  }

  return nameInfo;
};

export const getNameOfUser = (user) => {
  const { firstName, lastName } = getFirstAndLastNameOfUser(user);
  const nameString = `${firstName ? firstName + " " : ""}${lastName}`;
  return nameString;
};

export const getUserInitials = (user) => {
  const { firstName, lastName } = getFirstAndLastNameOfUser(user);
  const firstLetter = firstName ? firstName.charAt(0) : "";
  const seccondLetter = lastName ? lastName.charAt(0) : "";
  return `${firstLetter}${seccondLetter}`;
};

/**
 * Create authToken used in Custom Authorization Lambda.
 * The purpose of this function: Same type of queries should send identical token so that it gets cached response from custom auth lambda
 *
 * Format:
 * [operation name];[fieldInfo];[fieldInfo];...;AuthModeLambda;[token]
 */
export const createCustomAuthLambdaToken = (
  token,
  operationName,
  operationType,
  variables
) => {
  const copiedVariables = deepCopyObject(variables);

  let variableKeyValueList = "";

  if (operationType === "query") {
    delete copiedVariables.filter;
    delete copiedVariables.nextToken;
    variableKeyValueList = objectToKeyValuePairsString(copiedVariables);
  }

  if (operationType === "mutation") {
    const input = copiedVariables.input;
    const id = input.id;
    const owner = input.owner;

    delete copiedVariables.input._version;
    delete copiedVariables.input.id;
    delete copiedVariables.input.owner;

    const fieldsString = Object.keys(input).join(";");

    variableKeyValueList = `${fieldsString}`;
    if (id) {
      variableKeyValueList += `;id:${id}`;
    }

    if (owner) {
      variableKeyValueList += `;owner:${owner}`;
    }
  }

  if (operationType === "subscription" && variables) {
    if (variables.filter) {
      const filter = variables.filter;
      const fieldsString = Object.keys(filter)
        .map((key) => {
          const value = filter[key];
          if (value.eq) {
            return `${key}:${value.eq}`;
          }
          return null;
        })
        .filter((value) => value !== null)
        .join(";");
      variableKeyValueList += `${fieldsString}`;
    }
    if (variables.owner) {
      variableKeyValueList += `${variableKeyValueList ? ";" : ""}owner:${
        variables.owner
      }`;
    }
  }

  // To go through the custom auth lambda, token needs to be different to standard token.
  // To make sure the TTL is refreshed for different operation, add operation name in front of the token

  const prefix = `${operationName};${variableKeyValueList}`;
  const obfuscatedPrefix = obfuscateString(prefix);
  const lambdaToken = `${obfuscatedPrefix};AuthModeLambda;${token}`;

  return lambdaToken;
};

export const shouldUseCustomAuthLambda = (user, operationName = null) => {
  if (!user) {
    return false;
  }

  const customQueries = [
    "AsyncGenerateRoster",
    "CheckRoster",
    "NotifyUsers",
    "UpdateOpenShifts",
    "ManageSubscriptionSeats",
  ];

  if (customQueries.includes(operationName)) {
    return false;
  }

  const groups = getUserGroups(user);
  if (!groups) {
    return false;
  }

  if (
    isUserInKioskGroup(user) ||
    isUserInCoordinatorGroup(user) ||
    isUserInCollaboratorGroup(user) ||
    isUserInEmployeeGroup(user)
  ) {
    return true;
  }
  return false;
};
