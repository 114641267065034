import { openDemoBookingWindow } from "../../../../utils";
import PlanBox from "../PlanBox/PlanBox";

const Plans = ({ handleStartFreeTrialButton }) => (
  <>
    <PlanBox
      title="Free Plan"
      paragraphs={[
        "Digital rostering for you and your team.",
        "Collect preferences and requests.",
        "Automatically highlight mistakes in your roster.",
        "iOS and Android apps for all of your staff.",
      ]}
      buttonLabel="Get started for free"
      onButtonClick={handleStartFreeTrialButton}
      primaryColor="#25D9DC"
      contrastColor="black"
    />

    <PlanBox
      title="AI Premium Plan"
      paragraphs={[
        "Use AI to generate schedules with complex rules and staffing requirements.",
        "Automatically manage your roster with AI re-rostering and smart open shifts.",
        "Large scale roster management.",
        "Dedicated support and onboarding.",
      ]}
      buttonLabel="Book a demo with our team"
      onButtonClick={openDemoBookingWindow}
      primaryColor="#2D3BEA"
      contrastColor="white"
    />
  </>
);

export default Plans;
