export function filterStringItemsByMatchingLowercaseInput(items, searchInput) {
  // Convert the search input to lowercase for case-insensitive matching
  const searchLower = searchInput.toLowerCase();

  // Use the Array.filter() method to filter items
  const filteredItems = items.filter((item) => {
    // Convert each item to lowercase for case-insensitive matching
    const itemLower = item.toLowerCase();

    // Check if the item contains the search input
    return itemLower.includes(searchLower);
  });

  return filteredItems;
}
