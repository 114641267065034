import styles from "./RulesTemplateModal.module.css";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {
  convertSingleOptionToPropForm,
  convertToOptionPropForm,
  getNames,
} from "../../../../utils";
import { ruleSets } from "../../service/ruleSets";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import RulesTemplateViewer from "../RulesTemplateViewer/RulesTemplateViewer";

const RulesTemplateModal = ({
  open,
  onClose,
  selectedRulesTemplate,
  setSelectedRulesTemplate,
  rules,
  isRulesWithExceptionEnabled,
  toggleBuildRuleModal,
  setInitialRulesInBuilder,
}) => {
  const ruleSet = ruleSets.find((set) => set.name === selectedRulesTemplate);
  const ruleSetsName = getNames(ruleSets);
  const options = convertToOptionPropForm(ruleSetsName);

  const findRuleWithInfo = (rules, customRule) => {
    let subruleIdx = null;
    let matchingRule = null;
    for (const rule of rules) {
      const matchingSubruleIdx = rule.subrules.findIndex(
        (s) => s.ruleTemplate === customRule.template
      );

      if (matchingSubruleIdx !== -1) {
        subruleIdx = matchingSubruleIdx;
        matchingRule = rule;
        break;
      }
    }

    return {
      rule: matchingRule,
      subruleIdx,
    };
  };

  const rulesList = useMemo(() => {
    if (!ruleSet) {
      return [];
    }
    const customRules = ruleSet.CustomRules;
    const ruleValues = ruleSet.RuleValues;

    return customRules.map((customRule, idx) => {
      const { rule, subruleIdx } = findRuleWithInfo(rules, customRule);
      const ruleValue = ruleValues[idx];

      return {
        id: uuidv4(),
        name: rule.name,
        template: customRule.template,
        ruleName: customRule.name,
        valueType: rule.valueType,
        defaultValue: ruleValue,
        initialDefaultValue: ruleValue,
        subrule: subruleIdx,
      };
    });
  }, [ruleSet, rules]);

  const handleCustomize = () => {
    toggleBuildRuleModal();
    setInitialRulesInBuilder(rulesList);
    onClose();
  };

  return (
    <ModalFrame open={open} onClose={onClose} shouldCloseOnOutsideClick={true}>
      <div className={styles.container}>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className={styles.header}>
          <h1 className={styles.title}>Rule Templates</h1>
          <Select
            options={options}
            onChange={(event) => {
              const ruleSetName = event.value;
              setSelectedRulesTemplate(ruleSetName);
            }}
            value={convertSingleOptionToPropForm([selectedRulesTemplate])}
            styles={{
              control: (base) => ({
                ...base,
                width: 350,
              }),
            }}
          />
        </div>
        <div className={styles.descriptions}>
          <p className={`${styles.description} ${styles.emph}`}>
            Adopt this template if you&apos;d like to set X days worked followed
            by Y days off, for example, 4 on 2 off.
          </p>
          <p className={styles.description}>
            You can change/customise the rule values by employees in the Rules
            Table.
          </p>
        </div>
        <div className={styles.ruleSets}>
          <RulesTemplateViewer
            rulesList={rulesList}
            rules={rules}
            isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
          />
        </div>
        <p className={styles.description}>
          You can still add/remove rules after adopting this template. The
          template provides you a quick way to get things started.
        </p>
        <div className={styles.buttons}>
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            onClick={handleCustomize}
            customStyle={{
              borderRadius: "5px",
              fontSize: "18px",
              width: "400px",
            }}
          >
            Customise this template
          </BasicButton>
        </div>
      </div>
    </ModalFrame>
  );
};

export default RulesTemplateModal;
