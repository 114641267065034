import { MODELS } from "../../constants/models";
import { getFieldsToCalculateWarningsFromModelFields } from "../../features/warnings/service/warnings";
import { getQueryClient } from "../../hooks/modelQueryHooks/queryClientStore";
import { getRosterInView } from "../../hooks/modelQueryHooks/useScheduleQuery";
import { debounceLastCallByKey } from "../generalUtils/debounce";
import { removeFields, removeNullFields } from "../generalUtils/object";
import { compareModels } from "./modelCompare";

// prettier-ignore
const fieldsNotToSync = [ "updatedAt", "_version", "_lastChangedAt", "createdAt" ];

/**
 * Compare cached model and new model and return synced model
 */
const getSyncedData = (modelName, cachedData, newData) => {
  const comparableNewData = removeFields(
    removeNullFields(newData),
    fieldsNotToSync
  );

  const syncedData = {
    ...cachedData,
    ...comparableNewData,
  };

  const fieldsToCompare = Object.keys(comparableNewData).filter(
    (key) => !fieldsNotToSync.includes(key)
  );

  let updatedFields = compareModels(
    cachedData,
    comparableNewData,
    fieldsToCompare
  );

  if (modelName === MODELS.ROSTER) {
    // prettier-ignore
    const rosterEmployeeFieldsToCompare = [ "Days", "DaysRecurring", "Allocations", "AllocationsRecurring", "History", "RuleValues", "RosteredAllocations", "name", "skills", "shifts", "areas"];
    if (updatedFields.includes("Employees")) {
      updatedFields = updatedFields.filter((field) => field !== "Employees");
      const cachedRosterEmployees = cachedData.Employees;
      const newRosterEmployees = comparableNewData.Employees;
      if (cachedRosterEmployees.length !== newRosterEmployees.length) {
        updatedFields.push(...rosterEmployeeFieldsToCompare);
      } else {
        for (const cachedEmployee of cachedRosterEmployees) {
          const newEmployee = newRosterEmployees.find(
            (employee) => employee.id === cachedEmployee.id
          );
          if (newEmployee) {
            const fieldsWithDifference = compareModels(
              cachedEmployee,
              newEmployee,
              rosterEmployeeFieldsToCompare
            );
            updatedFields.push(...fieldsWithDifference);
          }
        }
      }
    }
  }

  const result = {
    syncedData,
    shouldSync: updatedFields.length > 0,
    updatedFields,
  };

  return result;
};

const updateWarningsIfNeeded = (
  queryClient,
  updatedFields,
  warningCalculationOptions
) => {
  const { updateWarnings, customKeywords, rosterID, locationID } =
    warningCalculationOptions;

  const fieldsToCalculateWarnings =
    getFieldsToCalculateWarningsFromModelFields(updatedFields);

  if (fieldsToCalculateWarnings.length === 0) {
    return;
  }

  const rosterModel = queryClient.getQueryData(["roster", rosterID]);
  const locationModel = queryClient.getQueryData(["location", locationID]);
  const globalEmployeeModels = locationModel.Employees.items;

  const rosterInView = getRosterInView(
    locationModel,
    globalEmployeeModels,
    rosterModel
  );

  updateWarnings(rosterInView, fieldsToCalculateWarnings, customKeywords);
};

/**
 * @param {*} locationID = location to sync
 * @param {*} newData = data from subscription
 * @param {*} warningCalculationOptions = null to prevent calculating warnings on sync
 */
export const syncLocation = (
  locationID,
  newData,
  warningCalculationOptions = null // {updateWarnings, customKeywords, rosterID}
) => {
  const queryKey = ["location", locationID];
  const queryClient = getQueryClient();
  const cachedLocation = queryClient.getQueryData(queryKey);

  const { shouldSync, syncedData, updatedFields } = getSyncedData(
    MODELS.LOCATION,
    cachedLocation,
    newData
  );

  const sync = () => {
    if (!shouldSync) return;
    queryClient.setQueryData(queryKey, syncedData);

    if (warningCalculationOptions) {
      updateWarningsIfNeeded(
        queryClient,
        updatedFields,
        warningCalculationOptions
      );
    }
  };
  debounceLastCallByKey(`${MODELS.LOCATION}-${syncedData.id}`, sync, 2000)();
};

/**
 * @param {*} locationID = location to sync global employee
 * @param {*} newData = data from subscription
 * @param {*} warningCalculationOptions = null to prevent calculating warnings on sync
 */
export const syncGlobalEmployee = (
  locationID,
  newData,
  warningCalculationOptions = null // {updateWarnings, customKeywords, rosterID}
) => {
  const queryKey = ["location", locationID];
  const queryClient = getQueryClient();
  const cachedLocation = queryClient.getQueryData(queryKey);
  const cachedGlobalEmployee = cachedLocation.Employees.items.find(
    ({ id }) => id === newData.id
  );

  if (!cachedGlobalEmployee) return;

  const { shouldSync, syncedData, updatedFields } = getSyncedData(
    MODELS.GLOBAL_EMPLOYEE,
    cachedGlobalEmployee,
    newData
  );

  const sync = () => {
    if (!shouldSync) return;

    const locationModel = queryClient.getQueryData(queryKey);
    const syncedGlobalEmployees = locationModel.Employees.items.map(
      (globalEmployee) =>
        globalEmployee.id === syncedData.id ? syncedData : globalEmployee
    );
    const syncedLocation = {
      ...locationModel,
      Employees: {
        items: syncedGlobalEmployees,
      },
    };

    queryClient.setQueryData(queryKey, syncedLocation);

    if (warningCalculationOptions) {
      updateWarningsIfNeeded(
        queryClient,
        updatedFields,
        warningCalculationOptions
      );
    }
  };
  debounceLastCallByKey(`${MODELS.LOCATION}-${syncedData.id}`, sync, 2000)();
};

/**
 * @param {*} rosterID = roster to sync
 * @param {*} newData = data from subscription
 * @param {*} warningCalculationOptions = null to prevent calculating warnings on sync
 */
export const syncRoster = (
  rosterID,
  newData,
  warningCalculationOptions = null // {updateWarnings, customKeywords, locationID}
) => {
  const queryKey = ["roster", rosterID];
  const queryClient = getQueryClient();
  const cachedRoster = queryClient.getQueryData(queryKey);

  const { shouldSync, syncedData, updatedFields } = getSyncedData(
    MODELS.ROSTER,
    cachedRoster,
    newData
  );

  const sync = () => {
    if (!shouldSync) return;
    queryClient.setQueryData(queryKey, syncedData);

    if (warningCalculationOptions) {
      updateWarningsIfNeeded(
        queryClient,
        updatedFields,
        warningCalculationOptions
      );
    }
  };
  debounceLastCallByKey(`${MODELS.ROSTER}-${syncedData.id}`, sync, 2000)();
};

export const syncNonAdminAppLocation = (queryKey, newData) => {
  const queryClient = getQueryClient();
  const { location: cachedLocation } = queryClient.getQueryData(queryKey);

  const { shouldSync, syncedData } = getSyncedData(
    MODELS.LOCATION,
    cachedLocation,
    newData
  );

  const sync = () => {
    if (!shouldSync) return;

    const { employees: latestGlobalEmployeeModels } =
      queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, {
      employees: latestGlobalEmployeeModels,
      location: syncedData,
    });
  };
  debounceLastCallByKey(
    `${queryKey[0]}-${MODELS.LOCATION}-${syncedData.id}`,
    sync,
    2000
  )();
};

export const syncNonAdminAppGlobalEmployee = (queryKey, newData) => {
  const queryClient = getQueryClient();
  const { employees: cachedGlobalEmployees } =
    queryClient.getQueryData(queryKey);

  const cachedGlobalEmployee = cachedGlobalEmployees.find(
    ({ id }) => id === newData.id
  );

  if (!cachedGlobalEmployee) return;

  const { shouldSync, syncedData } = getSyncedData(
    MODELS.GLOBAL_EMPLOYEE,
    cachedGlobalEmployee,
    newData
  );

  const sync = () => {
    if (!shouldSync) return;

    const {
      employees: latestGlobalEmployeeModels,
      location: latestLocationModel,
    } = queryClient.getQueryData(queryKey);
    const updatedEmployees = latestGlobalEmployeeModels.map((employee) =>
      employee.id === syncedData.id ? syncedData : employee
    );
    queryClient.setQueryData(queryKey, {
      employees: updatedEmployees,
      location: latestLocationModel,
    });
  };
  debounceLastCallByKey(
    `${queryKey[0]}-${MODELS.GLOBAL_EMPLOYEE}-${syncedData.id}`,
    sync,
    2000
  )();
};
