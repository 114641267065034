import styles from "./EditRequestButton.module.css";

const EditRequestButton = (props) => {
  return (
    <button className={styles.btn} onClick={() => props.setId(props.data.id)}>
      Edit
    </button>
  );
};

export default EditRequestButton;
