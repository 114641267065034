import {
  DEFAULT_DEMANDS_FINISH_TIME,
  DEFAULT_DEMANDS_START_TIME,
} from "../../../../constants";
import {
  DateTime,
  changeDemandValuesToFullLength,
  changeDemandsLengthToSpecificPeriod,
  findAllOverlapingShiftIntervals,
  numberToTwoDecFloat,
  strToArrCommaSeparated,
  twoArraysAreEqual,
} from "../../../../utils";
import { getNewDemandsTemplate } from "../../../../utils/queryUtils/sharedModelDataGetters";

function inferShiftGroupDemandStartAndFinishTime(
  shiftShortId,
  skillShortId,
  shifts,
  shiftGroups,
  demandsInHoldingEntity,
  reservedShiftKeywords
) {
  const preExistingShiftGroupDemands = demandsInHoldingEntity.find(
    (dem) =>
      dem.shifts === shiftShortId &&
      (!skillShortId || skillShortId === dem.skills) &&
      dem.importance === "No AI"
  );

  if (preExistingShiftGroupDemands) {
    return {
      startTime: preExistingShiftGroupDemands.startTime,
      finishTime: preExistingShiftGroupDemands.finishTime,
    };
  }

  const shiftGroup = shiftGroups.find((g) => g.shortId === shiftShortId);
  const shiftNamesBelongToGroup = strToArrCommaSeparated(
    shiftGroup.shifts
  ).filter((value) => !reservedShiftKeywords.includes(value));

  if (shiftNamesBelongToGroup.length === 0) {
    return {
      startTime: DEFAULT_DEMANDS_START_TIME,
      finishTime: DEFAULT_DEMANDS_FINISH_TIME,
    };
  }

  const shiftsInRoster = shifts;
  const shiftsBelongToGroup = shiftNamesBelongToGroup.map((shortId) =>
    shiftsInRoster.find((s) => s.shortId === shortId)
  );

  const allOverallpingShiftInterval =
    findAllOverlapingShiftIntervals(shiftsBelongToGroup);

  return allOverallpingShiftInterval === -1
    ? calculateShiftTimeFromShifts(shiftsBelongToGroup)
    : convertTimeToFloat(allOverallpingShiftInterval);
}

function calculateShiftTimeFromShifts(shifts) {
  const startTimesInNumber = shifts.map((s) =>
    DateTime.convertTimeToNumberRepresentation(s.startTime)
  );
  const finishTimesInNumber = shifts.map((s) =>
    DateTime.convertTimeToNumberRepresentation(s.finishTime)
  );

  return {
    startTime: convertTimeToFloat(Math.min(...startTimesInNumber)),
    finishTime: convertTimeToFloat(Math.max(...finishTimesInNumber)),
  };
}

function convertTimeToFloat(time) {
  return DateTime.convertFloatToTime(numberToTwoDecFloat(time), "AWS");
}

function identicalDemandExists(
  existingDemands,
  demandToBeAdded,
  numDays,
  locationStartDate,
  startDate,
  locationDefaultNumDays
) {
  const {
    shiftName: newDemandShiftName,
    skillName: newDemandSkillName,
    type: newDemandType,
    values: newDemandValues,
  } = demandToBeAdded;

  const identicalDemand = existingDemands.find((demand) => {
    const {
      shifts: oldDemandShifts,
      skills: oldDemandSkills,
      type: oldDemandType,
      values: oldDemandValues,
    } = demand;
    if (
      newDemandShiftName === oldDemandShifts &&
      ((!newDemandSkillName && !oldDemandSkills) ||
        newDemandSkillName === oldDemandSkills) &&
      newDemandType === oldDemandType &&
      twoArraysAreEqual(
        newDemandValues,
        changeDemandValuesToFullLength(
          oldDemandValues,
          numDays,
          locationStartDate,
          startDate,
          locationDefaultNumDays
        )
      )
    ) {
      return true;
    }
    return false;
  });

  if (identicalDemand) {
    return true;
  }
  return false;
}

function getMatchingNoAIDemandIDs(existingDemands, demandToBeAdded) {
  const {
    shiftName: newDemandShiftName,
    skillName: newDemandSkillName,
    type: newDemandType,
  } = demandToBeAdded;

  const ids = existingDemands
    .filter((demand) => {
      const {
        shifts: oldDemandShifts,
        skills: oldDemandSkills,
        type: oldDemandType,
        importance,
      } = demand;

      if (
        importance === "No AI" &&
        oldDemandShifts === newDemandShiftName &&
        ((!newDemandSkillName && !oldDemandSkills) ||
          newDemandSkillName === oldDemandSkills) &&
        oldDemandType === newDemandType
      ) {
        return true;
      }
      return false;
    })
    .map((d) => d.id);

  return ids;
}

export const getUpdatedDemandsFromStaffingLevelModal = (
  demandsToBeAdded,
  demandStartTime,
  demandFinishTime,
  isShiftGroup,
  numDays,
  shifts,
  shiftGroups,
  demands,
  reservedShiftKeywords,
  locationStartDate,
  startDate,
  locationDefaultNumDays,
  shiftShortId,
  skillShortId
) => {
  let demandStartTimeToApply;
  let demandFinishTimeToApply;
  // Get demand start and finish time
  if (isShiftGroup) {
    const {
      startTime: inferredDemandStartTime,
      finishTime: inferredDemandFinishTime,
    } = inferShiftGroupDemandStartAndFinishTime(
      shiftShortId,
      skillShortId,
      shifts,
      shiftGroups,
      demands,
      reservedShiftKeywords
    );
    demandStartTimeToApply = inferredDemandStartTime;
    demandFinishTimeToApply = inferredDemandFinishTime;
  } else {
    demandStartTimeToApply = demandStartTime;
    demandFinishTimeToApply = demandFinishTime;
  }

  // Create demands according to the demands content in the modal
  // Check if identical demand exists. If so, don't create (adding this because we are not deleting all the demands any more)

  const newDemands = [];
  let deletedDemandIDs = [];
  if (
    demandsToBeAdded.maxDemands &&
    !identicalDemandExists(
      demands,
      demandsToBeAdded.maxDemands,
      numDays,
      locationStartDate,
      startDate,
      locationDefaultNumDays
    )
  ) {
    const demandsInfo = demandsToBeAdded.maxDemands;
    const toDelete = getMatchingNoAIDemandIDs(demands, demandsInfo);
    deletedDemandIDs = deletedDemandIDs.concat(toDelete);

    const newDemand = getNewDemandsTemplate({
      numItems: 1,
      skills: skillShortId,
      shifts: shiftShortId,
      importance: "No AI",
      type: "Maximum",
      values: demandsInfo.values,
      startTime: demandStartTimeToApply,
      finishTime: demandFinishTimeToApply,
    })[0];
    newDemands.push(newDemand);
  }

  if (
    demandsToBeAdded.minDemands &&
    !identicalDemandExists(
      demands,
      demandsToBeAdded.minDemands,
      numDays,
      locationStartDate,
      startDate,
      locationDefaultNumDays
    )
  ) {
    const demandsInfo = demandsToBeAdded.minDemands;
    const toDelete = getMatchingNoAIDemandIDs(demands, demandsInfo);
    deletedDemandIDs = deletedDemandIDs.concat(toDelete);

    const newDemand = getNewDemandsTemplate({
      numItems: 1,
      skills: skillShortId,
      shifts: shiftShortId,
      importance: "No AI",
      type: "Minimum",
      values: demandsInfo.values,
      startTime: demandStartTimeToApply,
      finishTime: demandFinishTimeToApply,
    })[0];

    newDemands.push(newDemand);
  }

  const withoutDeletedDemands = demands.filter(
    (demand) => !deletedDemandIDs.includes(demand.id)
  );
  const resizedDemands = changeDemandsLengthToSpecificPeriod(
    withoutDeletedDemands,
    "whole",
    numDays,
    locationStartDate,
    startDate,
    location.defaultNumDays
  );
  const updatedDemands = [...newDemands, ...resizedDemands];
  return updatedDemands;
};
