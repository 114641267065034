import { useMemo, useState } from "react";
import { getFieldsFromRoster } from "../../utils/queryUtils/rosterDataGetters";
import { getSelectedRows, getToBeDeletedRows } from "../../utils";
import { useRosterModelQuery } from "./useRosterModelQuery";
import useRosterModelMutation from "./useRosterModelMutation";
import { updateEmployeesWithOldEmployeeData } from "../../utils/queryUtils/sharedModelDataGetters";

const useStandardDataContainer = ({
  isScheduleView,
  locationID = null,
  rosterID = null,
}) => {
  const [gridApi, setGridApi] = useState();
  const { roster, customKeywords, isQueryLoading, refetchRoster } =
    useRosterModelQuery({
      isScheduleView,
      locationID,
      rosterID,
    });

  const {
    isMutationLoading: isSaving,
    updateFields,
    deleteRosterSolutions,
    createScheduleEmployees,
    deleteScheduleEmployees,
    publishSchedule,
    updateOpenShifts,
    createSnapshotRoster,
    duplicateSnapshot,
    deleteSnapshots,
    updateSnapshots,
    createRosterModelForMainStreamInDifferentPeriod,
    updateNote,
  } = useRosterModelMutation({
    isScheduleView,
    locationID,
    rosterID,
  });

  const fields = useMemo(() => getFieldsFromRoster(roster), [roster]);

  const getToBeDeletedItems = (hasTriggeredFromContextMenu) => {
    if (hasTriggeredFromContextMenu) {
      return getToBeDeletedRows(null, gridApi);
    }
    return getSelectedRows(gridApi).map((item) => item.data);
  };

  return {
    gridApi,
    setGridApi,
    fields,
    roster,
    customKeywords,
    isQueryLoading,
    isSaving,
    updateFields,
    deleteRosterSolutions,
    getToBeDeletedItems,
    createScheduleEmployees,
    deleteScheduleEmployees,
    publishSchedule,
    updateOpenShifts,
    refetchRoster,
    createSnapshotRoster,
    duplicateSnapshot,
    deleteSnapshots,
    updateSnapshots,
    createRosterModelForMainStreamInDifferentPeriod,
    updateNote,
  };
};

export const getAllStandardUpdateFunctions = (roster, updateFields) => {
  const applyOrderUpdates = (updatedOrder) => {
    const updatedRoster = { ...roster, order: updatedOrder };
    updateFields(["order"], updatedRoster, roster);
  };

  const applyShiftUpdates = (updatedShifts) => {
    const updatedRoster = { ...roster, Shifts: updatedShifts };
    updateFields(["Shifts"], updatedRoster, roster);
  };

  const applySkillUpdates = (updatedSkills) => {
    const updatedRoster = { ...roster, Skills: updatedSkills };
    updateFields(["Skills"], updatedRoster, roster);
  };

  const updateColorCodes = (newColorCodes) => {
    const updatedRoster = { ...roster, ColorCodes: newColorCodes };
    updateFields(["ColorCodes"], updatedRoster, roster);
  };

  const updateStatistics = (newStatistics) => {
    const updatedRoster = { ...roster, Statistics: newStatistics };
    updateFields(["Statistics"], updatedRoster, roster, []);
  };

  const updateCustomRulesAndEmployees = (newCustomRules, newEmployees) => {
    const updatedRoster = {
      ...roster,
      CustomRules: newCustomRules,
      Employees: updateEmployeesWithOldEmployeeData(
        roster.Employees,
        newEmployees
      ),
    };
    updateFields(["CustomRules", "Employees"], updatedRoster, roster, [
      "RuleValues",
    ]);
  };

  const addShiftGroup = (newShiftGroup) => {
    const updatedRoster = {
      ...roster,
      ShiftGroups: [...roster.ShiftGroups, newShiftGroup],
    };
    updateFields(["ShiftGroups"], updatedRoster, roster);
  };

  const updateDemands = (updatedDemands) => {
    const updatedRoster = {
      ...roster,
      Demands: updatedDemands,
    };
    updateFields(["Demands"], updatedRoster, roster);
  };

  return {
    applyOrderUpdates,
    applyShiftUpdates,
    applySkillUpdates,
    updateColorCodes,
    updateStatistics,
    updateCustomRulesAndEmployees,
    addShiftGroup,
    updateDemands,
  };
};

export default useStandardDataContainer;
