import styles from "./LoadingPage.module.css";
import logo from "../../../../assets/images/logo_coloured.png";

const LoadingPage = () => {
  return (
    <div className={styles.page} data-testid="loading-page">
      <div className={styles.centerpiece}>
        <img
          className={styles.logo}
          width={"650px"}
          src={logo}
          alt="Rosterlab logo"
        />
        <div className={styles.spinner}></div>
      </div>
    </div>
  );
};

export default LoadingPage;
