import { useQueryClient } from "@tanstack/react-query";
import {
  createRosterModel,
  deleteRosterModel,
} from "../../utils/queryUtils/locationQuery";
import { useCallback } from "react";
import { useDataMutation } from "./useDataMutation";
import {
  deleteSolutionModel,
  getRosterModelById,
  updateRosterModel,
} from "../../utils/queryUtils/rosterQuery";
import { modifyNumDaysToRosterFields } from "../../utils/queryUtils/rosterDataGetters";
import { FIELD_UPDATE_ACTIONS } from "../../constants/queryActions";

export function useAllRostersMutation(location) {
  const locationID = location.id;
  const queryClient = useQueryClient();

  const applyChangesToRosterQuery = useCallback(
    (updatedRoster, shouldUpdateDateSpecificFields) => {
      const queryData = queryClient.getQueryData(["roster", updatedRoster.id]);
      const { name, numDays, startDate } = updatedRoster;
      if (queryData) {
        queryClient.setQueryData(["roster", updatedRoster.id], {
          ...queryData,
          name,
          numDays,
          startDate,
          ...(shouldUpdateDateSpecificFields && {
            Employees: updatedRoster.Employees,
            Demands: updatedRoster.Demands,
          }),
        });
      }
    },
    [queryClient]
  );

  const mutation = useDataMutation({
    queryKey: ["allRosters", locationID],
    queryClient,
    mutationFn: (mutateFnParam) => {
      const { actionType } = mutateFnParam;
      const rosterID = mutateFnParam.rosterID;
      switch (actionType) {
        case FIELD_UPDATE_ACTIONS.create:
          return (async function () {
            await createRosterModel(mutateFnParam.newRosterInfo);
            return mutateFnParam.updatedRosters;
          })();
        case FIELD_UPDATE_ACTIONS.delete:
          return (async function () {
            const deletedRoster = await deleteRosterModel(rosterID);
            // Delete associated solutions
            const solutions = deletedRoster.Solutions.items;
            const deleteSolutionsPromise = [];
            for (const solution of solutions) {
              deleteSolutionsPromise.push(deleteSolutionModel(solution.id));
            }
            if (deleteSolutionsPromise.length > 0) {
              await Promise.all(deleteSolutionsPromise);
            }

            return mutateFnParam.updatedRosters;
          })();
        case FIELD_UPDATE_ACTIONS.update:
          return (async function () {
            let updatedRoster = mutateFnParam.updatedRoster;
            const shouldUpdateDateSpecificFields =
              mutateFnParam.shouldUpdateDateSpecificFields;
            if (shouldUpdateDateSpecificFields) {
              const latestRosterModel = await getRosterModelById(
                updatedRoster.id
              );
              updatedRoster = modifyNumDaysToRosterFields(
                latestRosterModel,
                updatedRoster.numDays
              );
            }
            await updateRosterModel(updatedRoster.id, {
              name: updatedRoster.name,
              startDate: updatedRoster.startDate,
              numDays: updatedRoster.numDays,
              ...(shouldUpdateDateSpecificFields && {
                Employees: updatedRoster.Employees,
                Demands: updatedRoster.Demands,
              }),
            });

            applyChangesToRosterQuery(
              updatedRoster,
              shouldUpdateDateSpecificFields
            );
            return mutateFnParam.updatedRosters;
          })();
      }
    },
    getExpectedResultingData: (mutateFnParam) => mutateFnParam.updatedRosters,
    onSuccess: (updatedData, mutateFnParam) => {
      if (!mutateFnParam) {
        return;
      }
      if (mutateFnParam.onSuccess) {
        mutateFnParam.onSuccess();
      }
    },
  });

  const deleteRoster = useCallback(
    (deletedRosterID, updatedRosters) => {
      mutation.mutate({
        rosterID: deletedRosterID,
        updatedRosters,
        actionType: FIELD_UPDATE_ACTIONS.delete,
      });
    },
    [mutation]
  );

  const createRoster = useCallback(
    (newRosterInfo, updatedRosters, onSuccess) => {
      mutation.mutate({
        newRosterInfo,
        updatedRosters,
        actionType: FIELD_UPDATE_ACTIONS.create,
        onSuccess,
      });
    },
    [mutation]
  );

  const updateRosters = useCallback(
    (updatedRoster, updatedRosters, shouldUpdateDateSpecificFields = false) => {
      mutation.mutate({
        updatedRoster,
        updatedRosters,
        actionType: FIELD_UPDATE_ACTIONS.update,
        shouldUpdateDateSpecificFields,
      });
    },
    [mutation]
  );

  return {
    createRoster,
    deleteRoster,
    updateRosters,
    isMutationLoading: mutation.isLoading,
  };
}
