import { ALL_WARNING_FIELDS } from "../../../constants/warnings";
import { removeDuplicateStrInArr } from "../../../utils";

export const getFieldsToCalculateWarningsFromModelFields = (modelFields) => {
  const fieldsToCalculate = [];

  const employeeInfoFields = [
    "name",
    "skills",
    "shifts",
    "areas",
    "startDate",
    "finishDate",
  ];

  for (const field of modelFields) {
    if (employeeInfoFields.includes(field)) {
      fieldsToCalculate.push("Employees");
      continue;
    }

    if (field === "PublishedAllocations") {
      fieldsToCalculate.push("RosteredAllocations");
      continue;
    }

    if (field === "Preferences") {
      fieldsToCalculate.push("Days");
      continue;
    }

    if (field === "PreferencesRecurring") {
      fieldsToCalculate.push("DaysRecurring");
      continue;
    }

    if (field === "ruleValues" || field === "RuleValues") {
      fieldsToCalculate.push("CustomRules");
      continue;
    }

    fieldsToCalculate.push(field);
  }

  return removeDuplicateStrInArr(fieldsToCalculate).filter((field) =>
    ALL_WARNING_FIELDS.includes(field)
  );
};
