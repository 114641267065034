import { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./WhyNotThisAllocation.module.css";
import {
  convertToNameIdOptionForm,
  convertToShortIdNameOptionForm,
  createArrayFromInteger,
  DateTime,
  getNames,
} from "../../../../../utils";
import { getRosteringProblemFromRoster } from "../../../../rosterGenerator/service/generateRoster";
import { checkWhyNotThisAllocation } from "../../../../../utils/queryUtils/rosterQuery";
import { interpretCustomKeywordsData } from "../../../../../utils/queryUtils/locationDataGetters";
import { getCombinedSubtasks } from "../../../service/rosterUtils";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../../../../components/elements/LoadingSpinner/LoadingSpinner";
import { KEYWORD_NA } from "../../../../../constants/keywords";
import { toFullAllocationString } from "../../../../../utils/modelUtils/allocation";

const selectStyle = {
  control: (baseStyles) => ({
    ...baseStyles,
    borderColor: "#219EC9",
  }),
};

const NO_TASK = "None";

const WhyNotThisAllocationModal = ({
  show,
  handleClose,
  employees,
  shifts,
  tasks,
  taskBlocks,
  areas,
  numDays,
  customKeywordsData,
  roster,
  shiftGroups,
  locationSettings,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );
  const { leaveKeywords } = customKeywordsUtilObj;

  const [apiResponse, setApiResponse] = useState(null);

  const employeeOptions = useMemo(
    () => convertToNameIdOptionForm(employees),
    [employees]
  );

  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);

  const dayOptions = useMemo(
    () =>
      createArrayFromInteger(numDays).map((day) => {
        const date = new DateTime(roster.startDate).addDays(day - 1);
        const weekNum = Math.ceil(day / 7);
        const label = `${date.toFormat(
          "weekday"
        )}, Week ${weekNum} (${date.toFormat("displayed-mid")})`;

        return {
          label,
          value: day,
        };
      }),
    [numDays, roster]
  );

  const areaOptions = useMemo(
    () => convertToShortIdNameOptionForm(areas),
    [areas]
  );

  const shiftOptions = useMemo(
    () => convertToShortIdNameOptionForm(shifts),
    [shifts]
  );

  const taskOptions = useMemo(() => {
    const entities =
      taskBlocks.length > 0 ? getCombinedSubtasks(tasks, taskBlocks) : tasks;
    return [
      { label: NO_TASK, value: "" },
      ...convertToShortIdNameOptionForm(entities),
    ];
  }, [tasks, taskBlocks]);

  const [selectedEmployee, setSelectedEmployee] = useState(
    employeeOptions.length > 0 ? employeeOptions[0] : null
  );
  const [selectedDay, setSelectedDay] = useState(dayOptions[0]);
  const [selectedArea, setSelectedArea] = useState(
    areaOptions.length > 0 ? areaOptions[0] : null
  );
  const [selectedShift, setSelectedShift] = useState(
    shiftOptions.length > 0 ? shiftOptions[0] : null
  );
  const [selectedTask, setSelectedTask] = useState(taskOptions[0]);

  const submitAllocation = async () => {
    if (!selectedEmployee || !selectedShift || isLoading) {
      return;
    }
    setIsLoading(true);
    const rosteringProblem = getRosteringProblemFromRoster(
      roster,
      locationSettings
    );
    const employeeInput = selectedEmployee.label;
    const shiftInput = selectedShift.label;
    const areaInput = selectedArea ? selectedArea.label : "";
    const taskInput = selectedTask.label === NO_TASK ? "" : selectedTask.label;
    const dayInput = selectedDay.value;
    const allocation = toFullAllocationString(areaInput, shiftInput);

    let response;
    try {
      response = await checkWhyNotThisAllocation(
        rosteringProblem,
        ["leaveNames;" + leaveKeywords.join(";")],
        employeeInput,
        allocation,
        taskInput,
        dayInput
      );
    } catch (error) {
      console.error(error);
    }

    const result = response.data.checkRoster;
    setIsLoading(false);
    setApiResponse({
      employeeName: employeeInput,
      shiftName: shiftInput,
      taskName: taskInput,
      day: dayInput,
      result: JSON.parse(result),
    });
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth={false}>
      <div className={styles.container}>
        <button className={styles.closeButton} onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h1 className={styles.title}>Why not this allocation?</h1>
        <div className={styles.innerContainer}>
          <div className={styles.left}>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Employee*</p>
              <Select
                options={employeeOptions}
                onChange={setSelectedEmployee}
                value={selectedEmployee}
                styles={selectStyle}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Day*</p>
              <Select
                options={dayOptions}
                onChange={setSelectedDay}
                value={selectedDay}
                styles={selectStyle}
              />
            </div>
            {areas.length > 0 && (
              <div className={styles.field}>
                <p className={styles.fieldLabel}>Areas*</p>
                <Select
                  options={areaOptions}
                  onChange={setSelectedArea}
                  value={selectedArea}
                  styles={selectStyle}
                />
              </div>
            )}
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Shift*</p>
              <Select
                options={shiftOptions}
                onChange={setSelectedShift}
                value={selectedShift}
                styles={selectStyle}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.fieldLabel}>Task*</p>
              <Select
                options={taskOptions}
                onChange={setSelectedTask}
                value={selectedTask}
                styles={selectStyle}
              />
            </div>
            <div className={styles.submitButtonContainer}>
              <button
                className={styles.submitButton}
                onClick={submitAllocation}
              >
                {isLoading && <LoadingSpinner color="#219ec9" />}
                IS THIS WORKABLE? <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
          {apiResponse && (
            <div className={styles.right}>
              <div className={styles.checkResultContainer}>
                <ApiResponseComponent
                  apiResponse={apiResponse}
                  startDate={roster.startDate}
                  shiftGroupNames={shiftGroupNames}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const ApiResponseComponent = ({ apiResponse, startDate, shiftGroupNames }) => {
  const { employeeName, shiftName, taskName, day, result } = apiResponse;
  const allocationName = `${shiftName}${taskName ? `-${taskName}` : ""}`;

  const warningsDescription = result.warnings;

  const workableDescription = warningsDescription[0] || "";
  const [workableDescription_1, workableDescription_2] =
    workableDescription.split(". ");

  const demandsDescription = warningsDescription[1] || "";
  const rulesDescription = warningsDescription[2] || "";

  const hasShiftChangeRule = rulesDescription.includes("->");
  const workableEmployeesDescription = warningsDescription[3] || "";

  const workableEmployeesInString = workableEmployeesDescription.split(
    "This allocation can be worked by the following employee(s): "
  )[1];

  const workableEmployees = workableEmployeesInString
    ? workableEmployeesInString.split(",")
    : [];

  const date = new DateTime(startDate).addDays(day - 1);

  const short = date.toFormat("displayed-short");
  const weekNum = Math.ceil(day / 7);
  const dow = date.toFormat("weekday");

  return (
    <div className={styles.apiResponse}>
      <p className={`${styles.introduction} ${styles.paragraph}`}>
        You are checking if {employeeName} can work a {shiftName} shift{" "}
        {taskName ? `with ${taskName} ` : ""} on {dow}, Week {weekNum} - {short}
      </p>

      {workableDescription_1.endsWith("workable") ? (
        <p className={styles.paragraph}>{workableDescription_1}</p>
      ) : (
        !workableDescription_1.endsWith("Shift is not A.I") && (
          <p className={styles.paragraph}>
            This shift is not workable due to employee skill or shift based
            restriction
          </p>
        )
      )}
      {workableDescription_2 && (
        <p className={`${styles.bold} ${styles.paragraph}`}>
          {workableDescription_1.endsWith("Shift is not A.I") &&
            `${workableDescription_1}. `}
          {workableDescription_2}
        </p>
      )}
      {demandsDescription && (
        <p className={styles.paragraph}>
          {demandsDescription.startsWith("Demands: ") ? (
            <>
              <span className={`${styles.bold} ${styles.blueText}`}>
                Demands:{" "}
              </span>
              {demandsDescription
                .split("Demands: ")[1]
                .split(", ")
                .map((demandItem, idx) => (
                  <span className={styles.listItem} key={idx}>
                    {removeOnDayPart(demandItem)}
                  </span>
                ))}
            </>
          ) : (
            demandsDescription
          )}
        </p>
      )}
      {rulesDescription && (
        <p className={styles.paragraph}>
          {rulesDescription.startsWith("Rules: ") ? (
            <>
              <span className={`${styles.bold} ${styles.blueText}`}>
                Rules:{" "}
              </span>
              {rulesDescription
                .split("Rules: ")[1]
                .split(", ")
                .map((ruleItem, idx) => {
                  return (
                    <span key={idx} className={styles.listItem}>
                      {colorShiftGroupNameInShiftChangeRule(
                        addSpaceAfterColon(ruleItem),
                        shiftGroupNames
                      )}
                    </span>
                  );
                })}
            </>
          ) : (
            rulesDescription
          )}
        </p>
      )}
      {hasShiftChangeRule && (
        <p>*{allocationName} is in the red shift groups.</p>
      )}
      {workableEmployees.length > 0 && (
        <div>
          <p>This allocation can be worked by the following employee(s):</p>
          <p className={`${styles.paragraph} ${styles.italic}`}>
            {workableEmployees
              .map(addNAIfEndsWithColon)
              .map(addSpaceAfterColon)
              .join(", ")}
          </p>
        </div>
      )}
    </div>
  );
};

/**
 * String modifiers
 */

function addSpaceAfterColon(string) {
  return string.replace(/:(\S)/g, ": $1");
}

function isShiftChangeRule(rule) {
  return rule.includes("->");
}

function colorShiftGroupNameInShiftChangeRule(ruleString, shiftGroupNames) {
  let rule = ruleString;
  if (!isShiftChangeRule(rule)) {
    return <span>{rule}</span>;
  }

  const endsWithLHS = rule.endsWith("(LHS)");
  const endsWithRHS = rule.endsWith("(RHS)");

  if (endsWithLHS || endsWithRHS) {
    rule = rule.replace("(LHS)", "").replace("(RHS)", "");
  }

  const colorLHS = endsWithLHS || (!endsWithLHS && !endsWithRHS);
  const colorRHS = endsWithRHS || (!endsWithLHS && !endsWithRHS);

  const [leftSide, rightSide] = rule.split("->");
  const leftShiftGroupName = shiftGroupNames.find((name) =>
    leftSide.includes(` ${name} `)
  );

  const rightShiftGroupName = shiftGroupNames.find((name) =>
    rightSide.includes(` ${name} `)
  );

  const leftJSX = colorLHS ? (
    colorPartOfStringAndReturnAsJSX(leftSide, leftShiftGroupName)
  ) : (
    <span>{leftSide}</span>
  );
  const rightJSX = colorRHS ? (
    colorPartOfStringAndReturnAsJSX(rightSide, rightShiftGroupName)
  ) : (
    <span>{rightSide}</span>
  );

  return (
    <span>
      {leftJSX} <span>{`->`}</span> {rightJSX}
    </span>
  );
}

function colorPartOfStringAndReturnAsJSX(fullString, partToColor) {
  const [left, right] = fullString.split(partToColor);

  return (
    <span>
      {left}
      {partToColor && <span style={{ color: "red" }}>{partToColor}</span>}
      {right && right}
    </span>
  );
}

function removeOnDayPart(input) {
  return input.replace(/ on day \d+$/, "");
}

function addNAIfEndsWithColon(input) {
  return input.endsWith(":") ? input + KEYWORD_NA : input;
}

export default WhyNotThisAllocationModal;
