/* eslint-disable */
const periodDict = {
  "per roster": "Roster",
  "per week": "Week",
  "per 2 weeks": "Fortnight",
  "per 2 weeks (offset)": "OffsetFortnight",
  "per four weeks": "Four weeks",
  "across all weekends": "AcrossWeekends",
  "per even week": "EvenWeek",
  "in a row": "",
  "per 7 day period": "RollingWeek",
  "after on stretch start (7 days)": "RollingWeekOnstretch",
  "per rolling four weeks": "RollingFourWeeks",
};

const boundDict = {
  "work at least": "Minimum",
  "work at most": "Maximum",
  "have at least": "Minimum",
  "have at most": "Maximum",
  "work exactly": "Equal",
  "have exactly": "Equal",
  "work proportionally": "Proportional",
};

const severityDict = {
  must: "Critical",
  should: "Medium",
  "with exception": "Mostly",
  "must (if able)": "CriticalShould",
};

const dayDict = {
  "": "",
  "starting on a weekday": "Weekdaystart",
  "ending on a weekday": "Weekdayend",
  "containing a weekday": "Weekdaycontain",
  "starting on a weekend": "Weekendstart",
  "ending on a weekend": "Weekendend",
  "containing a weekend": "Weekendcontain",
  "ignoring weekends": "Weekendignore",
};

const nameDayDict = {
  "": "",
  "starting on a weekday": "starting on a weekday",
  "ending on a weekday": "ending on a weekday",
  "containing a weekday": "containing a weekday",
  "starting on a weekend": "starting on a weekend",
  "ending on a weekend": "ending on a weekend",
  "containing a weekend": "containing a weekend",
  "ignoring weekends": "ignoring weekends",
};

const namePeriodDict = {
  "per roster": "per roster",
  "per week": "per week",
  "per 2 weeks": "per 2 weeks",
  "per 2 weeks (offset)": "per 2 weeks (offset)",
  "in a row": "in a row",
  "per 7 day period": "in a 7 day period",
  "after on stretch start (7 days)": "after on stretch start (7 days)",
  "per four weeks": "per four weeks",
  "across all weekends": "across all weekends",
  "per even week": "per even week",
  "per rolling four weeks": "per rolling four weeks",
};

const nameBoundDict = {
  "work at least": "min",
  "work at most": "max",
  "have at least": "min",
  "have at most": "max",
  "work proportionally": "%",
  "work exactly": "",
  "have exactly": "",
};

const nameDict = {
  numDaysOn: "days",
  numDaysOff: "days off",
  onstretchLength: "days",
  offstretchLength: "days off",
  numHours: "hours",
  hoursOnRow: "hours",
  numShiftTypes: "shift types",
};

const nameSeverityDict = {
  must: "",
  should: "preferred",
  "with exception": "with exception",
  "must (if able)": "preferred (critical)",
};

class SubRule {
  constructor(
    name,
    severity,
    bound_type,
    period,
    shift = null,
    day = "",
    dow = null,
    exclusive = true
  ) {
    this.name = name;
    this.severity = severity;
    this.bound_type = bound_type;
    this.period = period;
    this.shift = shift;
    this.day = day;
    this.dow = dow;
    this.exclusive = exclusive;
  }

  get_inputs(self) {
    let input4 = "";
    if (this.day) input4 = this.day;
    else if (this.name === "numHours" && this.shift)
      input4 = `from "${this.shift.name}" shifts`;
    else if (this.name === "numShiftsRow" && !this.exclusive)
      input4 = `(at least 1 from "${this.shift.name}")`;
    else if (this.name === "numShiftsWeekend") input4 = `during the weekend`;
    else if (this.name === "numShiftsLongWeekend")
      input4 = `during the long weekend`;
    else if (this.name === "numShiftsDoubleCountSubtasks")
      input4 = "(count subtasks)";
    else if (this.name === "numDaysOff" && this.dow === "weekend")
      input4 = "during a weekend";

    return [this.severity, this.bound_type, this.period, input4];
  }

  get_rule_template(self) {
    if (this.name === "weekDayOn") {
      return [
        this.name,
        periodDict[this.period],
        boundDict[this.bound_type],
        severityDict[this.severity],
        this.dow,
      ]
        .filter((el) => {
          return el !== "";
        })
        .join(";");
    } else if (
      this.name === "numShifts" ||
      this.name === "numShiftsDoubleCountSubtasks" ||
      this.name === "numTasks" ||
      this.name === "numShiftsWeekend" ||
      this.name === "numShiftsLongWeekend" ||
      (this.name === "numHours" && this.shift)
    ) {
      return [
        this.name,
        periodDict[this.period],
        boundDict[this.bound_type],
        severityDict[this.severity],
        dayDict[this.day],
        this.shift.id,
      ]
        .filter((el) => {
          return el !== "";
        })
        .join(";");
    } else if (this.name === "numShiftsRow") {
      return [
        this.name,
        periodDict[this.period],
        boundDict[this.bound_type],
        severityDict[this.severity],
        dayDict[this.day],
        this.exclusive,
        this.shift.id,
      ]
        .filter((el) => {
          return el !== "";
        })
        .join(";");
    } else if (this.name == "numDaysOff" && this.dow === "weekend") {
      return [
        this.name,
        periodDict[this.period],
        boundDict[this.bound_type],
        severityDict[this.severity],
        this.dow,
      ]
        .filter((el) => {
          return el !== "";
        })
        .join(";");
    } else {
      return [
        this.name,
        periodDict[this.period],
        boundDict[this.bound_type],
        severityDict[this.severity],
        dayDict[this.day],
      ]
        .filter((el) => {
          return el !== "";
        })
        .join(";");
    }
  }

  get_rule_name(self) {
    let ruleName;
    if (
      this.name === "numShifts" ||
      (this.name === "numShiftsRow" && this.exclusive) ||
      this.name === "numShiftsDoubleCountSubtasks"
    ) {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${this.shift.name} shifts ${namePeriodDict[this.period]} ${
        nameDayDict[this.day]
      }${
        this.name === "numShiftsDoubleCountSubtasks" ? " (count subtasks)" : ""
      }`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numTasks") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${this.shift.name} tasks ${namePeriodDict[this.period]} ${
        nameDayDict[this.day]
      }`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numShiftsRow" && !this.exclusive) {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } days ${namePeriodDict[this.period]} w/ ${this.shift.name} shifts`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numShiftsWeekend") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } weekend days ${namePeriodDict[this.period]} w/ ${
        this.shift.name
      } shifts`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numShiftsLongWeekend") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } long weekend days ${namePeriodDict[this.period]} w/ ${
        this.shift.name
      } shifts`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numSkills") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } skills ${namePeriodDict[this.period]} ${nameDayDict[this.day]}`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "weekDayOn") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${this.dow} ${namePeriodDict[this.period]} ${nameDayDict[this.day]}`
        .trim()
        .replace("  ", " ");
    } else if (this.name === "numHours" && this.shift) {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${nameDict[this.name]} ${namePeriodDict[this.period]} ${
        nameDayDict[this.day]
      } from ${this.shift.name} shifts`
        .trim()
        .replace("  ", " ");
    } else if (this.name == "numDaysOff" && this.dow === "weekend") {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${nameDict[this.name]} ${
        namePeriodDict[this.period]
      } during the weekend`
        .trim()
        .replace("  ", " ");
    } else {
      ruleName = `${nameSeverityDict[this.severity]} ${
        nameBoundDict[this.bound_type]
      } ${nameDict[this.name]} ${namePeriodDict[this.period]} ${
        nameDayDict[this.day]
      }`
        .trim()
        .replace("  ", " ");
    }

    return ruleName[0].toUpperCase() + ruleName.substring(1);
  }
}

export default SubRule;
