export const settingsVersionByOwner = /* GraphQL */ `
  query SettingsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const customLocationByOwner = /* GraphQL */ `
  query LocationByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        isScheduleView
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getMinimalLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      startDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const globalEmployeeByLocationForEmployeeApp = /* GraphQL */ `
  query GlobalEmployeeByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalEmployeeByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        Requests {
          startDate
          finishDate
          request
          state
        }
        Preferences {
          date
          allocation
        }
        PreferencesRecurring
        startDate
        finishDate
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const globalEmployeeByLocationForKiosk = /* GraphQL */ `
  query GlobalEmployeeByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalEmployeeByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        code
        TimeEntries {
          date
          approved
          startTime
          finishTime
          breakTime
        }
        PublishedAllocations {
          date
          draftAllocation
          publishedAllocation
          isOpenShift
          note
        }
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const getLocationForEmployeeApp = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      owner
      name
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      startDate
      isScheduleView
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getLocationWithoutRosters = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getLocationDefault = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getLocationForKiosk = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      Shifts {
        id
        shortId
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      Tasks {
        id
        name
        shortId
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getLocationVersion = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      _version
    }
  }
`;

export const getGlobalEmployeeVersion = /* GraphQL */ `
  query GetGlobalEmployee($id: ID!) {
    getGlobalEmployee(id: $id) {
      _version
    }
  }
`;

export const getLocationFrontendSettings = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      frontendSettings {
        name
        values
      }
    }
  }
`;

export const customGlobalEmployeeByLocation = /* GraphQL */ `
  query GlobalEmployeeByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalEmployeeByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _deleted
        locationName
      }
      nextToken
    }
  }
`;

export const rosterDetailsByLocation = /* GraphQL */ `
  query RosterByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        locationID
        updatedAt
        createdAt
        name
        numDays
        startDate
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getGlobalEmployeeAppUser = /* GraphQL */ `
  query GetGlobalEmployee($id: ID!) {
    getGlobalEmployee(id: $id) {
      id
      email
      owner
      name
      locationID
      locationName
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      startDate
      finishDate
      registrations
      externalID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getGlobalEmployeeJustVersion = /* GraphQL */ `
  query GetGlobalEmployee($id: ID!) {
    getGlobalEmployee(id: $id) {
      _version
    }
  }
`;

export const getGlobalEmployeeJustRegistrations = /* GraphQL */ `
  query GetGlobalEmployee($id: ID!) {
    getGlobalEmployee(id: $id) {
      registrations
    }
  }
`;

export const simplifiedRosterByLocationAndSnapshotStartDate = /* GraphQL */ `
  query RosterByLocationAndSnapshotStartDate(
    $locationID: ID!
    $snapshotStartDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByLocationAndSnapshotStartDate(
      locationID: $locationID
      snapshotStartDate: $snapshotStartDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getRostersWithEmployeesOnly = /* GraphQL */ `
  query RosterByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        numDays
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        isSnapshot
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const getRosterVersion = /* GraphQL */ `
  query GetRoster($id: ID!) {
    getRoster(id: $id) {
      _version
    }
  }
`;

export const getRosterNoSolutions = /* GraphQL */ `
  query GetRoster($id: ID!) {
    getRoster(id: $id) {
      id
      owner
      locationID
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      snapshotStartDate
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
