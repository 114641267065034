import Fuse from "fuse.js";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { forwardRef } from "react";
import Select from "react-select/async";
import {
  extractAllOptions,
  parseInputValue,
} from "../DropdownMultiSelector/DropdownMultiSelector";
import { flatAnyLevelOptions, splitAllocationSuffix } from "../../../../utils";

const possibleSuffix = ["*", "!", "?"];

const fuseOptions = {
  includeScore: true, // Optional, if you want to see match scores
  threshold: 0.3, // Adjust threshold to control sensitivity of match
  useExtendedSearch: true, // Enables extended search options
  keys: ["label"],
};

function getCustomStyles(cellHeight) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "27px",
      height: cellHeight,
      boxShadow: "none",
      backgroundColor: "white",
      overflow: "hidden",
      border: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: cellHeight,
      padding: "0 6px",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? isFocused
            ? "#4e9bf8"
            : "#4bacea"
          : isFocused
          ? "#deeaff"
          : undefined,
      };
    },
    groupHeading: (styles, params) => {
      if (params.data.label === "") {
        return {
          ...styles,
          borderBottom: "2px solid #e5eaeb",
          margin: "0px",
          padding: "0px",
        };
      }
      return {
        ...styles,
      };
    },
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "27px",
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };
  return customStyles;
}

const DropdownSingleSelector = forwardRef(
  (
    { options, width, suffix = "", stopEditing, cellHeight = "27px", ...props },
    ref
  ) => {
    const flattenedOptions = useMemo(
      () => flatAnyLevelOptions(options),
      [options]
    );

    const fuse = useMemo(
      () => new Fuse(flattenedOptions, fuseOptions),
      [flattenedOptions]
    );

    const loadOptions = useCallback(
      async (inputValue) => {
        if (!inputValue) {
          return options;
        }
        const filteredOptions = fuse.search(inputValue).map(({ item }) => item);
        return filteredOptions;
      },
      [fuse, options]
    );

    const [allocationWithoutSuffix, existingSuffix] = splitAllocationSuffix(
      props.value || ""
    );

    if (existingSuffix) suffix = existingSuffix;

    const [selected, setSelected] = useState(
      parseInputValue(
        allocationWithoutSuffix,
        extractAllOptions(options),
        false,
        false
      )
    );
    const [showMenu, setShowMenu] = useState(true);
    const refInput = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
      setTimeout(() => {
        if (refInput.current) {
          refInput.current.focus();
        }
      }, 100);
    }, []);

    useEffect(() => {
      if (!isFirstRender.current) {
        stopEditing();
      }
      isFirstRender.current = false;
    }, [selected, stopEditing]);

    const customStyles = useMemo(
      () => getCustomStyles(cellHeight),
      [cellHeight]
    );

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          // If default suffix is set, apply it.
          if (
            selected.value !== "" &&
            !possibleSuffix.includes(selected.value[selected.value.length - 1])
          ) {
            return selected.value + suffix;
          }
          return selected.value;
        },

        isPopup() {
          return true;
        },
      };
    });

    return (
      <div
        style={{ width: `${width}px` }}
        data-testid="dropdown-single-selector"
      >
        <Select
          options={options}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          styles={{
            ...customStyles,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          onChange={(selectedInputs) => {
            setSelected(selectedInputs);
            setShowMenu(false);
          }}
          ref={refInput}
          menuIsOpen={showMenu}
          defaultValue={selected}
          maxMenuHeight={250}
          instanceId="single-selector"
          inputId="dropdown-single-selector"
          defaultOptions={options}
          loadOptions={loadOptions}
        />
      </div>
    );
  }
);

export default DropdownSingleSelector;
