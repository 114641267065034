import { useEffect, useRef, useState } from "react";
import styles from "./RequestsModal.module.css";
import ShadowButton from "../../../../components/elements/ShadowButton/ShadowButton";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "../../../../utils";
import { useUserStore } from "../../../../globalStore/appStore";
import { getNameOfUser } from "../../../auth/service/auth";

const RequestsModal = ({
  employeeNames,
  handleClose,
  locationID,
  requestsData,
  leaveLongNames,
  handleSave,
}) => {
  const { user } = useUserStore();
  const [name, setName] = useState(employeeNames[0]);
  const [leaveType, setLeaveType] = useState(leaveLongNames[0]);
  const [startDate, setStartDate] = useState(
    new DateTime(null, false).toFormat("AWS")
  );
  const [endDate, setEndDate] = useState(
    new DateTime(null, false).toFormat("AWS")
  );
  const [comment, setComment] = useState("");
  const [allActiveRequests, setAllActiveRequests] = useState([]);

  const rageClickDisabled = useRef(false);

  useEffect(() => {
    const asyncSetRequest = async () => {
      const allRequests = requestsData.filter(
        (request) =>
          (request.state === "Approved" && request.employeeName === name) ||
          (request.state === "Pending" && request.employeeName === name)
      );
      setAllActiveRequests(allRequests);
    };

    asyncSetRequest();
  }, [locationID, name, requestsData]);

  let issues = null;

  if (startDate > endDate) {
    issues = "Start date is after end date";
  } else if (
    DateTime.isValidDateString(startDate) &&
    DateTime.isValidDateString(endDate) &&
    allActiveRequests.filter((request) => {
      return (
        new DateTime(startDate).date <= new DateTime(request.finishDate).date &&
        new DateTime(endDate).date >= new DateTime(request.startDate).date
      );
    }).length > 0
  ) {
    issues = "Request already clashes with another approved or pending request";
  } else if (
    !DateTime.isValidDateString(startDate) ||
    !DateTime.isValidDateString(endDate)
  ) {
    issues = "Invalid date(s)";
  }

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Add request</h2>
        <form className={styles.formData}>
          <div className={`${styles.row} ${styles.rowOne}`}>
            <div className={styles.field}>
              <label htmlFor="name">Employee name</label>
              {employeeNames.length === 1 ? (
                employeeNames[0]
              ) : (
                <select
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                >
                  {employeeNames.map((name, idx) => (
                    <option key={idx}>{name}</option>
                  ))}
                </select>
              )}
            </div>

            <div className={styles.field}>
              <label htmlFor="name">Request Type</label>
              <select
                name="name"
                value={leaveType}
                onChange={(e) => setLeaveType(e.target.value)}
                required
              >
                {leaveLongNames.map((longName, idx) => (
                  <option key={idx}>{longName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={`${styles.row} ${styles.rowTwo}`}>
            <div className={styles.field}>
              <label htmlFor="fromDate">From</label>
              <input
                name="fromDate"
                type="date"
                className={issues && styles.hasIssue}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                data-testid="request-start-date"
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="toDate">To</label>
              <input
                name="toDate"
                type="date"
                className={issues && styles.hasIssue}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                data-testid="request-finish-date"
              />
            </div>
          </div>
          <div className={`${styles.row} ${styles.rowThree}`}>
            <div className={styles.field}>
              <label htmlFor="comment">Comment</label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                name="comment"
                type="text"
                label="Comment"
                className={styles.commentTextare}
              ></textarea>
            </div>
          </div>
        </form>
        <p className={styles.issues}>{issues}</p>
        <div className={styles.buttons}>
          <ShadowButton
            backgroundColor="#219ec9"
            hoverColor="#1f91b7"
            labelColor="white"
            border="none"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            isBlocked={issues ? true : false}
            onClick={async () => {
              if (rageClickDisabled.current) {
                return;
              }
              rageClickDisabled.current = true;
              if (!issues) {
                await handleSave({
                  id: uuidv4(),
                  employeeName: name,
                  request: leaveType,
                  startDate: startDate,
                  finishDate: endDate,
                  state: "Approved",
                  employeeComment: "",
                  adminComment: comment,
                  submittedBy: getNameOfUser(user),
                  submittedDate: new DateTime(null, false).toFormat("AWS"),
                });
              }
            }}
          >
            Submit
          </ShadowButton>
          <ShadowButton
            backgroundColor="white"
            hoverColor="#219ec9"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            onClick={handleClose}
          >
            Cancel
          </ShadowButton>
        </div>
      </div>
    </div>
  );
};

export default RequestsModal;
