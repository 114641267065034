export function getStatisticsTemplate() {
  return {
    employeeShiftCountsToShow: [],
    employeeSkillCountsToShow: [],
    employeeHoursToShow: [],
    dayHoursToShow: [],
    dayShiftCountsToShow: [],
    daySkillCountsToShow: [],
    dayShiftSkillCountsToShow: [],
    dayShiftSkillToggleDisplayed: [],
    leaveCountsToShow: [],
    otherSettings: [],
  };
}
