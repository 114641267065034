import TooltipPopup from "../../../../components/elements/TooltipPopup/TooltipPopup";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { openHubspotChat } from "../../../../utils";
import styles from "./NightShiftPopup.module.css";

const NightShiftPopup = () => {
  const redirectTo = useRedirect();

  const redirectToPlans = () => {
    redirectTo({
      pageName: PAGE_NAMES.billing,
      query: {
        tab: "plan",
      },
    });
  };

  return (
    <TooltipPopup>
      <p className={styles.label}>
        Night shifts are not able to be generated with RosterLab Starter, you
        can still use it to allocate manually.{" "}
        <span
          className={`${styles.clickable} ${styles.blueEmph}`}
          onClick={openHubspotChat}
        >
          Chat with us
        </span>{" "}
        if you have a complex schedule with night shifts.{" "}
        <span
          className={`${styles.clickable} ${styles.blueEmph}`}
          onClick={redirectToPlans}
        >
          Upgrade Today!
        </span>
      </p>
    </TooltipPopup>
  );
};

export default NightShiftPopup;
