import { strToArrCommaSeparated } from "../generalUtils/array";

export const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "27px",
    height: "27px",
    boxShadow: "none",
    backgroundColor: "white",
    overflow: "hidden",
    border: "none",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "27px",
    padding: "0 6px",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? isFocused
          ? "#4e9bf8"
          : "#4bacea"
        : isFocused
        ? "#deeaff"
        : undefined,
    };
  },
  groupHeading: (styles, params) => {
    if (params.data.label === "") {
      return {
        ...styles,
        borderBottom: "2px solid #e5eaeb",
        margin: "0px",
        padding: "0px",
      };
    }
    return {
      ...styles,
    };
  },
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "27px",
  }),
};

export const defaultWarningHighlighterCellClassRules = (
  params,
  entitiesWarnings
) => {
  const duplicateNamesWarning = entitiesWarnings.find(
    ({ warningType }) => warningType === "duplicate names"
  );

  if (!params.value) {
    return false;
  }

  if (duplicateNamesWarning && params.colDef.field === "name") {
    const duplicateNames = duplicateNamesWarning.values;
    if (duplicateNames.includes(params.value)) {
      return true;
    }
  }

  const skillsWarning = entitiesWarnings.find(
    ({ warningType }) => warningType === "invalid skills"
  );

  if (skillsWarning && params.colDef.field === "skills") {
    const skillNameArr = strToArrCommaSeparated(params.value);
    for (const name of skillNameArr) {
      if (skillsWarning.values.includes(name)) {
        return true;
      }
    }
  }

  const areasWarning = entitiesWarnings.find(
    ({ warningType }) => warningType === "invalid areas"
  );

  if (areasWarning && params.colDef.field === "areas") {
    const areaNameArr = strToArrCommaSeparated(params.value);
    for (const name of areaNameArr) {
      if (areasWarning.values.includes(name)) {
        return true;
      }
    }
  }

  return false;
};
