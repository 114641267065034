import { compareTwoObjects, getSupersetOfKeys } from "../generalUtils/object";

/**
 * @returns field names that are different between model1 and model2
 */
export function compareModels(model1, model2, fieldsToCompare = null) {
  // Early return if both objects are the same reference
  if (model1 === model2) {
    return [];
  }

  const result = [];
  const keysToCompare = fieldsToCompare || getSupersetOfKeys(model1, model2);

  for (const key of keysToCompare) {
    if (!compareTwoObjects(model1[key], model2[key])) {
      result.push(key);
    }
  }

  return result;
}
