import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Notes.module.css";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import LoadingSpinner from "../../../../../components/elements/LoadingSpinner/LoadingSpinner";

export function Notes({ employeeID, date, saveNote, note }) {
  const [prevContent, setPrevContent] = useState(note);
  const [content, setContent] = useState(note);
  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    setIsSaving(true);
    await saveNote(employeeID, date, content);
    setIsSaving(false);
    setPrevContent(content);
  };

  const hasContentChanged = prevContent !== content;

  return (
    <div className={styles.note}>
      <FontAwesomeIcon icon={faNotesMedical} className={styles.noteIcon} />
      <div className={styles.noteTextAreaWrapper}>
        <textarea
          placeholder="Add notes"
          className={styles.noteTextArea}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        {isSaving ? (
          <LoadingSpinner color="#3caa84" />
        ) : (
          <button
            className={`${styles.saveNotesButton} ${
              hasContentChanged ? styles.editing : ""
            }`}
            onClick={hasContentChanged ? save : () => {}}
          >
            {hasContentChanged ? "Save" : "Saved"}
          </button>
        )}
      </div>
    </div>
  );
}

export function DeleteAllocationButton({ handleDeleteAllocation }) {
  return (
    <button
      className={styles.deleteEmployeeButton}
      onClick={handleDeleteAllocation}
    >
      <div className={styles.deleteEmployeeIcon}></div>
      Delete allocation
    </button>
  );
}

export function ReadOnlyNotes({ note }) {
  return (
    <div className={styles.readonlyNote}>
      <div className={styles.readonlyNoteIconContainer}>
        <FontAwesomeIcon icon={faNotesMedical} className={styles.noteIcon} />
        <p className={styles.readOnlyNoteLabel}>Notes</p>
      </div>
      <p className={styles.readOnlyNoteContent}>{note}</p>
    </div>
  );
}
