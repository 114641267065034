import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { DatePicker as MonthDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTime } from "../../../../../utils";
import styles from "./EmployeeAppDateSwitcher.module.css";
import { CALENDAR_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";

const SelelctedMonthLabel = (props) => {
  const date = props.date;
  const label = date.toFormat("full-month-year-readable");

  if (!props.InputProps) {
    return null;
  }

  const { ref, endAdornment } = props.InputProps;

  return (
    <div className={styles.dateLabel} ref={ref}>
      {label}
      {endAdornment}
    </div>
  );
};

const MonthPickerOpener = (props) => {
  const { children } = props;
  return (
    <button
      onClick={children.props.onClick}
      className={styles.monthPickerOpener}
    />
  );
};

const EmployeeAppDateSwitcher = ({
  selectedDate,
  setSelectedDate,
  calendarViewType,
}) => {
  const isMonthView = calendarViewType === CALENDAR_VIEW_TYPE.month;

  const toNextMonth = () => {
    const updatedDate = isMonthView
      ? new DateTime(selectedDate).addMonths(1)
      : new DateTime(selectedDate).addDays(7);
    setSelectedDate(updatedDate);
  };

  const toPreviousMonth = () => {
    const updatedDate = isMonthView
      ? new DateTime(selectedDate).subtractMonths(1)
      : new DateTime(selectedDate).subtractDays(7);
    setSelectedDate(updatedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={styles.container}>
        <button className={styles.traverseBtn} onClick={toPreviousMonth}>
          <FontAwesomeIcon icon={faCaretLeft} className={styles.traverseIcon} />
        </button>
        <MonthDatePicker
          className={styles.picker}
          views={["month", "year"]}
          value={selectedDate.getDate()}
          onChange={(date) => setSelectedDate(new DateTime(date))}
          sx={{ width: 190, height: 40 }}
          onKeydown={() => {}}
          slots={{
            field: SelelctedMonthLabel,
            inputAdornment: MonthPickerOpener,
          }}
          slotProps={{ field: { date: selectedDate } }}
        />
        <button className={styles.traverseBtn} onClick={toNextMonth}>
          <FontAwesomeIcon
            icon={faCaretRight}
            className={styles.traverseIcon}
          />
        </button>
      </div>
    </LocalizationProvider>
  );
};

export default EmployeeAppDateSwitcher;
