import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSolutionGenerationStore } from "../../../globalStore/solutionGenerationStore";
import { findBiggestNumber, getIds } from "../../../utils";

const INTERVAL = 5000;
const MAX_AUTOMATED_PROGRESS = 20;

function startArtificialSolutionProgressIncrementor(
  solutionID,
  initialProgress,
  onProgressUpdate
) {
  if (initialProgress >= MAX_AUTOMATED_PROGRESS) {
    return null;
  }

  let progress = initialProgress;
  const intervalID = setInterval(() => {
    if (progress >= MAX_AUTOMATED_PROGRESS) {
      clearInterval(intervalID);
    }

    progress = progress + 1;
    onProgressUpdate(solutionID, progress);
  }, INTERVAL);

  return intervalID;
}

export function useGeneratingSolutionsStatus(solutions) {
  const timers = useRef({});

  const handleArtificialProgressIncrement = useCallback(
    (solutionID, progress) => {
      const targetSolution = solutions.find(
        (solution) => solution.id === solutionID
      );

      const rawProgress = targetSolution.percentageComplete;
      const biggerPercentage = findBiggestNumber(rawProgress, progress);

      localStorage.setItem(
        `solutionLoadingProgress_${solutionID}`,
        biggerPercentage
      );

      setStatuses((prev) => {
        return prev.map((status) => {
          if (status.solutionID === solutionID) {
            return {
              ...status,
              progress: biggerPercentage,
            };
          }
          return status;
        });
      });
    },
    [solutions]
  );

  const scanAndParseSolution = useCallback(
    (solution) => {
      const rawProgress = solution.percentageComplete;
      const progress =
        localStorage.getItem(`solutionLoadingProgress_${solution.id}`) ||
        rawProgress;

      const bigger = findBiggestNumber(rawProgress, progress);

      let status = "solved";
      if (solution.status.startsWith("Solving")) {
        status = "solving";

        // Run artificial progress timer if raw progress percentage is lower than 20
        if (bigger < MAX_AUTOMATED_PROGRESS) {
          const timerID = startArtificialSolutionProgressIncrementor(
            solution.id,
            bigger,
            handleArtificialProgressIncrement
          );
          timers.current[solution.id] = timerID;
        }
      } else if (solution.status.startsWith("Roster failed")) {
        status = "failed";
      } else if (solution.status.startsWith("Error")) {
        status = "error";
      }
      return { solutionID: solution.id, status, progress: bigger };
    },
    [handleArtificialProgressIncrement]
  );

  const [statuses, setStatuses] = useState(() =>
    solutions.map(scanAndParseSolution)
  );

  const cancelTimer = useCallback((solutionID) => {
    if (timers.current[solutionID]) {
      clearInterval(timers.current[solutionID]);
      delete timers.current[solutionID];
    }
  }, []);

  useEffect(() => {
    if (solutions.length === 0) {
      setStatuses([]);
      return;
    }

    // Cancel all timer before start
    getIds(solutions).forEach((id) => cancelTimer(id));

    /**
     * Calculate statuses
     * - whenever solutions change
     * - whenever timer increments upto ...
     */
    const parsedSolutions = solutions.map(scanAndParseSolution);
    setStatuses(parsedSolutions);
  }, [solutions, scanAndParseSolution, cancelTimer]);

  return statuses;
}

export function useArtificialProgressIncrementor(
  isGenerating,
  progress,
  setProgress
) {
  const timerRef = useRef(null);

  const initiateTimer = useCallback(() => {
    let intervalID = setInterval(() => {
      setProgress((prev) => {
        return prev + 1;
      });
    }, INTERVAL);

    return intervalID;
  }, [setProgress]);

  useEffect(() => {
    if (progress >= MAX_AUTOMATED_PROGRESS && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [progress]);

  useEffect(() => {
    if (isGenerating) {
      timerRef.current = initiateTimer(setProgress);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [timerRef, isGenerating, setProgress, initiateTimer]);
}

export function useGenerateLoadingProgress() {
  const [isLoadingBarDisplayed, setLoadingBarDisplayed] = useState(false);
  const { isSolutionGenerating, latestSolutionId, latestSolutionStatus } =
    useSolutionGenerationStore();
  const solutions = useMemo(
    () => (latestSolutionStatus ? [latestSolutionStatus] : []),
    [latestSolutionStatus]
  );
  const statuses = useGeneratingSolutionsStatus(solutions);

  const solutionLoadingProgress = statuses[0] ? statuses[0].progress : 5;

  useEffect(() => {
    if (isSolutionGenerating) {
      setLoadingBarDisplayed(true);
    } else {
      setLoadingBarDisplayed(false);
    }
  }, [isSolutionGenerating, latestSolutionId]);

  const handleHideLoadingBar = () => {
    setLoadingBarDisplayed(false);
  };

  return {
    isLoadingBarDisplayed,
    handleHideLoadingBar,
    solutionLoadingProgress,
    latestSolutionStatus,
  };
}
