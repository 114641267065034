import { useRef } from "react";
import styles from "./JSONUploader.module.css";
import { customWarningAlert } from "../../../features/confirm/service/confirm";

const JSONUploader = ({
  handleUpload,
  InputTrigger,
  shouldBlockUpload = null,
}) => {
  const inputRef = useRef();

  const isJSONFile = (fileName) => {
    if (!fileName.endsWith(".json")) {
      return false;
    }
    return true;
  };

  const changeHandler = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    if (!isJSONFile(files[0].name)) {
      customWarningAlert({
        title: "Warning",
        descriptions: ["Please upload a JSON file"],
      });
      return;
    }
    handleUpload(files[0]);
    inputRef.current.value = null;
  };

  const activateInput = async () => {
    if (shouldBlockUpload) {
      const shouldBlock = await shouldBlockUpload();
      if (shouldBlock) {
        return;
      }
    }
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        className={styles.input}
        type="file"
        accept="application/JSON"
        name="file"
        onChange={changeHandler}
        data-testid="upload-roster-input"
      />
      <InputTrigger onClick={activateInput} />
    </>
  );
};

export default JSONUploader;
