import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./MobileCalendar.module.css";
import { DateTime } from "../../../../../utils";
import MonthCalendar from "../MonthCalendar/MonthCalendar";

const getThreeCalendarMonthDates = (selectedDate) => {
  const prevMonth = new DateTime(selectedDate).subtractMonths(1);
  const currentMonth = new DateTime(selectedDate);
  const nextMonth = new DateTime(selectedDate).addMonths(1);
  return [prevMonth, currentMonth, nextMonth];
};

/**
 * There are three months showing at each time. The default view is the month in the middle.
 * For example, if the date is set to October, there will be three months (Sep, Oct, Nov) pre-loaded and the default viewed calendar will be October
 */
const MobileCalendar = ({
  currentViewData,
  setSelectedDate,
  isBottomTabOpened,
  openBottomTab,
  toggleBottomTab,
  allocations,
  myOpenShifts,
  userEmployee,
  location,
  preferences,
  preferencesRecurring,
  requests,
  leaveCodes,
  employeeLeaveCounts,
  leaveKeywordsDict,
  notesToDisplay,
}) => {
  const [rowHeight, setRowHeight] = useState(null);
  const [cellWidth, setSellWidth] = useState(null);

  const { selectedDate } = currentViewData;

  const threeMonthDates = useMemo(
    () => getThreeCalendarMonthDates(selectedDate),
    [selectedDate]
  );
  const frameRef = useRef(null);

  const previousMonthRef = useRef(null);
  const currentMonthRef = useRef(null);
  const nextMonthRef = useRef(null);

  const updateRowHeight = useCallback(() => {
    if (currentMonthRef.current) {
      setRowHeight(currentMonthRef.current.offsetHeight);
    }
  }, []);

  const updateCellWidth = useCallback(() => {
    if (currentMonthRef.current) {
      const PADDING = 6;
      setSellWidth(currentMonthRef.current.offsetWidth / 7 - PADDING);
    }
  }, []);

  useEffect(() => {
    updateCellWidth();
  }, [updateCellWidth]);

  // target: "previous", "current", "next"
  const scrollCalendar = useCallback((target, enableSmooth) => {
    return new Promise((resolve) => {
      if (
        !frameRef.current ||
        !previousMonthRef.current ||
        !currentMonthRef.current ||
        !nextMonthRef.current
      ) {
        return;
      }

      const frameY = frameRef.current.getBoundingClientRect().y;
      const frameScrolledAmount = frameRef.current.scrollTop;

      const previousMonthY =
        previousMonthRef.current.getBoundingClientRect().y +
        frameScrolledAmount;
      const currentMonthY =
        currentMonthRef.current.getBoundingClientRect().y + frameScrolledAmount;
      const nextMonthY =
        nextMonthRef.current.getBoundingClientRect().y + frameScrolledAmount;

      let offset = currentMonthY - frameY;

      if (target === "previous") {
        offset = previousMonthY - frameY;
      } else if (target === "next") {
        offset = nextMonthY - frameY;
      }

      frameRef.current.scrollTo({
        top: offset,
        left: 0,
        ...(enableSmooth && { behavior: "smooth" }),
      });
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }, []);

  const scrollToMiddle = useCallback(
    (enableSmooth) => {
      scrollCalendar("current", enableSmooth).then(() => {
        updateRowHeight();
      });
    },
    [scrollCalendar, updateRowHeight]
  );

  const scrollUp = useCallback(
    (enableSmooth) => {
      scrollCalendar("previous", enableSmooth).then(() => {
        updateRowHeight();
        setSelectedDate(new DateTime(selectedDate).subtractMonths(1));
      });
    },
    [scrollCalendar, setSelectedDate, selectedDate, updateRowHeight]
  );

  const scrollDown = useCallback(
    (enableSmooth) => {
      scrollCalendar("next", enableSmooth).then(() => {
        updateRowHeight();
        setSelectedDate(new DateTime(selectedDate).addMonths(1));
      });
    },
    [scrollCalendar, setSelectedDate, selectedDate, updateRowHeight]
  );

  /**
   * [Effect for selectedDate updated]
   * UseLayoutEffect makes sure that scrolling to middle calendar happens before next rendering.
   * This will prevent cells breifly flashing when swiped to prev/next month
   */
  useLayoutEffect(() => {
    scrollToMiddle(false);
  }, [selectedDate, scrollToMiddle]);

  /**
   * When bottom tab is opened/collapsed, correctly adjust scroll amount
   */
  useEffect(() => {
    setTimeout(() => {
      scrollToMiddle(true);
    }, 500); // 500ms waits for bottom tab to fully open/close before adjusting scroll
  }, [isBottomTabOpened, scrollToMiddle]);

  // Handling swipe
  useEffect(() => {
    let touchStartY = 0;
    let touchEndY = 0;
    const element = frameRef.current;

    function checkDirection() {
      const offset = touchStartY - touchEndY;
      if (offset > 50) {
        return "down";
      } else if (offset < -50) {
        return "up";
      } else if (offset === 0) {
        return "none";
      }
      return "stay";
    }

    function handleTouchStart(e) {
      touchStartY = e.changedTouches[0].screenY;
    }

    function handleTouchEnd(e) {
      touchEndY = e.changedTouches[0].screenY;
      const direction = checkDirection();

      if (direction === "stay") {
        scrollToMiddle(true);
        return;
      }

      if (direction === "up") {
        scrollUp(true);
        return;
      }

      if (direction === "down") {
        scrollDown(true);
        return;
      }
    }

    if (element) {
      element.addEventListener("touchstart", handleTouchStart);
      element.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [
    selectedDate,
    setSelectedDate,
    updateRowHeight,
    scrollDown,
    scrollToMiddle,
    scrollUp,
  ]);

  const monthComponents = threeMonthDates.map((date, idx) => {
    let ref = null;
    let monthType = "current-month";
    switch (idx) {
      case 0:
        ref = previousMonthRef;
        monthType = "previous-month";
        break;
      case 1:
        ref = currentMonthRef;
        monthType = "current-month";
        break;
      case 2:
        ref = nextMonthRef;
        monthType = "next-month";
        break;
    }

    const nextMonth = new DateTime(date).addMonths(1).getMonth();

    return (
      <MonthCalendar
        ref={ref}
        key={date.toFormat("AWS")}
        month={date.getMonth()}
        nextMonth={nextMonth}
        year={date.getYear()}
        currentViewData={currentViewData}
        setSelectedDate={setSelectedDate}
        isBottomTabOpened={isBottomTabOpened}
        openBottomTab={openBottomTab}
        toggleBottomTab={toggleBottomTab}
        allocations={allocations}
        myOpenShifts={myOpenShifts}
        userEmployee={userEmployee}
        location={location}
        preferences={preferences}
        preferencesRecurring={preferencesRecurring}
        requests={requests}
        leaveCodes={leaveCodes}
        employeeLeaveCounts={employeeLeaveCounts}
        monthType={monthType}
        leaveKeywordsDict={leaveKeywordsDict}
        rowHeight={rowHeight}
        cellWidth={cellWidth}
        notesToDisplay={notesToDisplay}
      />
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.dayHeader}>Mon</div>
        <div className={styles.dayHeader}>Tue</div>
        <div className={styles.dayHeader}>Wed</div>
        <div className={styles.dayHeader}>Thu</div>
        <div className={styles.dayHeader}>Fri</div>
        <div className={styles.dayHeader}>Sat</div>
        <div className={styles.dayHeader}>Sun</div>
      </div>
      <div className={styles.calendarFrame} ref={frameRef}>
        {monthComponents}
      </div>
    </div>
  );
};

export default MobileCalendar;
