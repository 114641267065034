export const getRuleSetByName = (name) => {
  const ruleSet = ruleSets.find((template) => template.name === name);
  return ruleSet ? ruleSet : null;
};

export const ruleSets = [
  {
    name: "On/off pattern",
    description: "For example: 3 days worked followed by 3 days off",
    CustomRules: [
      { name: "Days in a row", template: "onstretchLength;Equal;Critical" },
      {
        name: "Days off in a row",
        template: "offstretchLength;Equal;Critical",
      },
      {
        name: "Min hours between shifts",
        template: "timeBetweenShifts;Critical",
      },
      {
        name: "Preferred min hours per two weeks",
        template: "numHours;Fortnight;Minimum;Medium",
      },
      {
        name: "Max hours per two weeks",
        template: "numHours;Fortnight;Maximum;Critical",
      },
      {
        name: "Preferable max shift changes in a rolling fortnight",
        template: "shiftChanges;Medium;RollingFortnight",
      },
    ],
    RuleValues: ["4", "2", "11", "72", "80", "1"],
  },
  {
    name: "Simple flexible day scheduling",
    description:
      "For example, they work 38 hours per week (but only during the day)",
    CustomRules: [
      {
        name: "Min hours between shifts",
        template: "timeBetweenShifts;Critical",
      },
      { name: "Min weekends off", template: "minimumWeekends;Critical" },
      {
        name: "Preferred min days in a row",
        template: "onstretchLength;Minimum;Medium",
      },
      {
        name: "Preferred max days in a row",
        template: "onstretchLength;Maximum;Medium",
      },
      {
        name: "Preferred min days off in a row",
        template: "offstretchLength;Minimum;Medium",
      },
    ],
    RuleValues: ["11", "1", "3", "6", "2"],
  },
];

export function getRuleSetFromRuleTemplates(ruleTemplates) {
  const ruleSet = ruleSets.filter((ruleSet) =>
    ruleSet.CustomRules.map((rule) => rule.template).every(
      (ruleTemplate) =>
        ruleTemplates.includes(ruleTemplate) &&
        ruleSet.CustomRules.length === ruleTemplates.length
    )
  );

  return ruleSet[0];
}
