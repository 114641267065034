import styles from "./DeleteButton.module.css";
import deleteIcon from "../../../../assets/images/icons8-delete-24_1icons8-delete-24.png";

const DeleteButton = ({ deleteRow, ...props }) => (
  <button
    className={styles.btn}
    onClick={() => deleteRow(props.data.id)}
    data-testid="delete-roster-btn"
  >
    <img className={styles.icon} alt="delete button" src={deleteIcon} />
  </button>
);

export default DeleteButton;
