import styles from "./DataEntryTable.module.css";
import { AgGridReact } from "@ag-grid-community/react";
import { getRowId, updateCellChangesToDatabase } from "../../../../utils";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import { defaultProcessCellForClipboard } from "../../../../utils/agGridUtils/clipboard";

const DataEntryTable = ({
  columnDefs,
  updateData,
  rowData,
  customStyle,
  setGridApiToParent,
  setColumnApiToParent,
  gridOptions = {},
  components,
  handleCellValueChanged,
  defaultColDef,
  getContextMenuItems,
  onCellKeyDown,
  aggridStyle,
  sideBar,
  icons,
  context,
  overlayNoRowsTemplate,
  enableRowDragAnimation = false,
  pinnedBottomRowData = [],
  getRowStyle,
  statusBar,
  tooltipShowDelay,
  tooltipHideDelay,
  handleUIColDrag,
  processDataFromClipboard,
  tableName,
  getCustomRowId,
  useIndexAsRowId = false,
  columnHoverHighlight,
  shouldHaveCheckBoxLeftMargin = false,
  getDataFromGrid,
  onToolPanelVisibleChanged = null,
  onCellEditingStarted,
  onCellEditingStopped,
  processCellForClipboard = null,
  isExternalFilterPresent = null,
  doesExternalFilterPass = null,
}) => {
  if (!aggridStyle) {
    aggridStyle = "ag-theme-balham";
  }

  addQuickFilterText(columnDefs);

  const onCellValueChanged = (params) => {
    if (!getDataFromGrid) {
      console.error("No getDataFromGrid provided");
    }

    const updatedData = getDataFromGrid ? getDataFromGrid(params.api) : null;
    updateCellChangesToDatabase((data) => {
      updateData(data, params);
      if (handleCellValueChanged) {
        handleCellValueChanged(params);
      }
    }, updatedData);
  };

  const undoRedoCellEditing = true;
  const undoRedoCellEditingLimit = 20;

  return (
    <div
      className={`${styles.table} ${aggridStyle} ${
        shouldHaveCheckBoxLeftMargin ? "checkbox-left-margin" : null
      }`}
      style={customStyle}
      data-testid={`${tableName}-grid`}
      data-tour={`${tableName}-grid`}
    >
      <AgGridReact
        columnDefs={columnDefs ? columnDefs : undefined}
        cellFlashDelay={200}
        cellFadeDelay={200}
        gridOptions={{
          ...(!useIndexAsRowId && {
            getRowId: (params) => {
              if (getCustomRowId) {
                return getCustomRowId(params);
              }
              return getRowId(params);
            },
          }),
          undoRedoCellEditing: true,
          undoRedoCellEditingLimit: 20,
          stopEditingWhenCellsLoseFocus: true,
          ...gridOptions,
          processCellForClipboard: processCellForClipboard
            ? processCellForClipboard
            : defaultProcessCellForClipboard,
          onGridReady: (params) => {
            if (setGridApiToParent) {
              setGridApiToParent(params.api);
            }
            if (setColumnApiToParent) {
              setColumnApiToParent(params.columnApi);
            }
            if (gridOptions.onGridReady) {
              gridOptions.onGridReady();
            }
          },
          suppressScrollOnNewData: true,
        }}
        undoRedoCellEditing={undoRedoCellEditing}
        undoRedoCellEditingLimit={undoRedoCellEditingLimit}
        rowData={rowData}
        defaultColDef={{
          editable: true,
          resizable: true,
          suppressMovable: true,
          filter: true,
          width: 100,
          ...(defaultColDef && defaultColDef),
          headerCheckboxSelectionFilteredOnly: true,
        }}
        onCellValueChanged={(param) => {
          if (updateData) {
            onCellValueChanged(param);
          }
          if (gridOptions.onCellValueChanged) {
            gridOptions.onCellValueChanged(param);
          }
        }}
        onRowDragEnd={
          gridOptions.onRowDragEnd ? gridOptions.onRowDragEnd : () => {}
        }
        enableRangeSelection={true}
        enableFillHandle={true}
        getContextMenuItems={getContextMenuItems}
        onCellKeyDown={(params) => {
          if (onCellKeyDown) {
            onCellKeyDown(params);
          }
        }}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onColumnResized={(params) => {
          if (handleUIColDrag) {
            handleUIColDrag(params);
          }
        }}
        enterMovesDown={true}
        enterMovesDownAfterEdit={true}
        components={components}
        sideBar={sideBar}
        icons={icons}
        overlayNoRowsTemplate={overlayNoRowsTemplate && overlayNoRowsTemplate}
        rowDragManaged={enableRowDragAnimation}
        pinnedBottomRowData={pinnedBottomRowData}
        context={context}
        getRowStyle={getRowStyle ? getRowStyle : () => {}}
        statusBar={statusBar ? statusBar : {}}
        tooltipShowDelay={tooltipShowDelay}
        tooltipHideDelay={tooltipHideDelay}
        processDataFromClipboard={
          processDataFromClipboard
            ? processDataFromClipboard
            : (params) => params.data
        }
        columnHoverHighlight={columnHoverHighlight}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
      ></AgGridReact>
    </div>
  );
};

// This is a hack to get filtering working within our current model
function addQuickFilterText(columnDefs) {
  columnDefs.forEach((column) => {
    if (column.valueFormatter && !column.filterValueGetter) {
      column.filterValueGetter = (params) => {
        const newParams = {
          ...params,
          value: params.data[params.colDef.field], //filterValueGetter does not have value but valueFormatter requires it.
        };
        return column.valueFormatter(newParams);
      };
    }
    if (column.children) {
      addQuickFilterText(column.children);
    }
  });
}

export default DataEntryTable;
