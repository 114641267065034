import styles from "../eula/EndUserLicenseAgreement.module.css";
import logo from "../../../../assets/images/RLlogo.png";

function DataDeletionPolicy() {
  return (
    <div className={styles.container}>
      <div className={styles.centerLogo}>
        <img src={logo} className={styles.logo} alt="RosterLab logo"></img>
      </div>
      <h1 className={styles.mainHeader}>Data deletion policy</h1>
      <p>
        We will delete all of your data along with your account permanently at
        any user&apos;s request.
      </p>
      <br />
      <p>
        If you wish to delete your account, along with all of your associated
        data, please email us at admin@rosterlab.com
      </p>
    </div>
  );
}

export default DataDeletionPolicy;
