export let isProcessingBatchUpdate = false;

/*
  The intention of this code is to batch the large number of updates
  eg. pasting bunch of fixed shifts in schedule view
*/
let batchedData = null;
export function updateCellChangesToDatabase(updater, updatedData) {
  batchedData = updatedData;

  if (!isProcessingBatchUpdate) {
    isProcessingBatchUpdate = true;

    setTimeout(function () {
      updater(batchedData);
      isProcessingBatchUpdate = false;
      batchedData = null;
    }, 500);
  }
}
