import { useMemo, useState } from "react";
import {
  DateTime,
  getAllShiftsFromGrid,
  getDataInRows,
  getNames,
  isNightShift,
  parseShiftModelToShiftsGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import ShiftsGrid from "../../../rosterProblems/components/Shifts/ShiftsGrid/ShiftsGrid";
import { getNewShiftsTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";

const ScheduleShiftsDataContainer = ({
  location,
  rosterID,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster = false,
  customKeywordsData,
  globalEmployees,
}) => {
  const { plan } = useUserStore();
  const [columnApi, setColumnApi] = useState(null);
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();
  const shiftsWarnings = useMemo(() => {
    return warnings ? warnings.Shifts : null;
  }, [warnings]);

  const {
    name,
    areas,
    tasks,
    shifts,
    skills,
    shiftGroups,
    numDays,
    subTasks,
    isSnapshot,
  } = fields;

  const isMainStream = !isRoster && !isSnapshot;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const applyShiftUpdates = (updatedShifts) => {
    const updatedRoster = { ...roster, Shifts: updatedShifts };
    updateFields(["Shifts"], updatedRoster, roster, []);
  };

  const shiftGroupNames = shiftGroups
    ? shiftGroups.map((shift) => shift.name)
    : [];
  const taskNames = useMemo(() => getNames(tasks), [tasks]);

  const {
    reservedShiftKeywords,
    reservedShiftsGridData,
    predefinedShiftOptions,
  } = interpretCustomKeywordsData(customKeywordsData);

  const shiftsData = useMemo(() => {
    return [
      ...reservedShiftsGridData,
      ...parseShiftModelToShiftsGridFormat(shifts),
    ];
  }, [shifts, reservedShiftsGridData]);

  const addNewShifts = (numItems) =>
    addNewModels(
      "New Shift",
      true,
      (option) =>
        getNewShiftsTemplate({
          ...option,
          existingShifts: shifts,
        }),
      numItems,
      shifts,
      (updatedShifts) => applyShiftUpdates(updatedShifts)
    );
  const updateShifts = (newGridContent, params) => {
    let processedContent = newGridContent;

    if (plan === PLAN.MID) {
      // If in MID plan, "Fulfills Demand" and "A.I. assigned" should be unchecked for night shifts
      const columnChanged = params.colDef.field;

      processedContent = newGridContent.map((row) => {
        if (isNightShift(row.startTime, row.finishTime)) {
          return {
            ...row,
            fulfilsDemand: false,
            autoAssigned: false,
          };
        }

        if (!isNightShift(row.startTime, row.finishTime)) {
          if (["startTime", "finishTime"].includes(columnChanged)) {
            const oldValue = DateTime.getFormattedTime(params.oldValue, "AWS");
            const oldStartTime =
              columnChanged === "startTime" ? oldValue : row.startTime;
            const oldFinishTime =
              columnChanged === "finishTime" ? oldValue : row.finishTime;
            if (isNightShift(oldStartTime, oldFinishTime)) {
              return {
                ...row,
                fulfilsDemand: true,
                autoAssigned: true,
              };
            }
          }
        }
        return row;
      });
    }

    updateModels(processedContent, shifts, applyShiftUpdates);
  };
  const duplicateShifts = (selectedShiftsInfo) =>
    duplicateModels(
      selectedShiftsInfo,
      predefinedShiftOptions,
      shifts,
      applyShiftUpdates,
      "copy of ",
      "Shift",
      true
    );
  const removeShifts = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, shifts, applyShiftUpdates);
  const reorderShiftsData = (params) =>
    reorderModelsData(params, getAllShiftsFromGrid, shifts, applyShiftUpdates);

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "Shift",
      roster,
      shortIdsToEntityNamesDicts
    );

  const getDataFromGrid = (gridApi) => {
    const updatedShifts = getAllShiftsFromGrid(gridApi);
    return updatedShifts.filter((item) => {
      if (predefinedShiftOptions.includes(item.name)) {
        return false;
      }
      return true;
    });
  };

  const TopControllerComponent = (
    <div>
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"shifts"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewShifts}
      updateItemsToDB={updateShifts}
      duplicateItemsToDB={duplicateShifts}
      removeItemsFromDB={removeShifts}
      getDataFromGrid={getDataFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={getDataInRows}
      getDeletionWarnings={getDeletionWarnings}
    >
      <ShiftsGrid
        isSaving={isSaving}
        shiftGroupNames={shiftGroupNames}
        gridApi={gridApi}
        setGridApiToParent={setGridApi}
        reorderShiftsData={reorderShiftsData}
        shiftsData={shiftsData}
        taskNames={taskNames}
        skills={skills}
        shiftsWarnings={shiftsWarnings}
        predefinedShiftKeywords={predefinedShiftOptions}
        reservedShiftKeywords={reservedShiftKeywords}
        reservedShiftSchema={reservedShiftsGridData}
        customTopComponent={TopControllerComponent}
        setColumnApiToParent={setColumnApi}
        columnApi={columnApi}
        isScheduleView={!isRoster}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        rosterName={name}
      />
    </GridActionHandler>
  );
};

export default ScheduleShiftsDataContainer;
