export const ENTITY_REFERENCE_MAP_IN_ROSTER_IN_VIEW = Object.freeze({
  Skill: {
    Employees: { stringFields: ["skills"], dayFields: [], customFields: [] },
    Areas: { stringFields: ["skills"], dayFields: [], customFields: [] },
    Shifts: { stringFields: ["skill"], dayFields: [], customFields: [] },
    ShiftGroups: { stringFields: ["skills"], dayFields: [], customFields: [] },
    Tasks: { stringFields: ["skills"], dayFields: [], customFields: [] },
    Demands: { stringFields: ["skills"], dayFields: [], customFields: [] },
  },
  Area: {
    Employees: {
      stringFields: ["areas"],
      dayFields: [
        "RosteredAllocations",
        "History",
        "Days",
        "DaysRecurring",
        "Allocations",
        "AllocationsRecurring",
      ],
      customFields: [],
    },
    ShiftGroups: { stringFields: ["areas"], dayFields: [], customFields: [] },
    Demands: { stringFields: ["areas"], dayFields: [], customFields: [] },
  },
  Shift: {
    Employees: {
      stringFields: ["shifts"],
      dayFields: [
        "RosteredAllocations",
        "History",
        "Days",
        "DaysRecurring",
        "Allocations",
        "AllocationsRecurring",
      ],
      customFields: [],
    },
    Areas: { stringFields: ["shifts"], dayFields: [], customFields: [] },
    ShiftGroups: { stringFields: ["shifts"], dayFields: [], customFields: [] },
    CustomRules: {
      stringFields: [],
      dayFields: [],
      customFields: ["template"],
    },
    Demands: { stringFields: ["shifts"], dayFields: [], customFields: [] },
  },
  ShiftGroup: {
    Employees: {
      stringFields: ["shifts"],
      dayFields: [
        "Days",
        "DaysRecurring",
        "Allocations",
        "AllocationsRecurring",
      ],
      customFields: [],
    },
    Areas: { stringFields: ["shifts"], dayFields: [], customFields: [] },
    CustomRules: {
      stringFields: [],
      dayFields: [],
      customFields: ["template"],
    },
    Demands: { stringFields: ["shifts"], dayFields: [], customFields: [] },
  },
  Task: {
    Employees: {
      stringFields: [],
      dayFields: [
        "RosteredAllocations",
        "History",
        "Days",
        "DaysRecurring",
        "Allocations",
        "AllocationsRecurring",
      ],
      customFields: [],
    },
    Areas: { stringFields: ["tasks"], dayFields: [], customFields: [] },
    ShiftGroups: { stringFields: ["tasks"], dayFields: [], customFields: [] },
    TaskBlocks: { stringFields: ["tasks"], dayFields: [], customFields: [] },
    CustomRules: {
      stringFields: [],
      dayFields: [],
      customFields: ["template"],
    },
    Demands: { stringFields: ["tasks"], dayFields: [], customFields: [] },
  },
  TaskBlock: {
    Employees: {
      stringFields: [],
      dayFields: [
        "RosteredAllocations",
        "History",
        "Days",
        "DaysRecurring",
        "Allocations",
        "AllocationsRecurring",
      ],
      customFields: [],
    },
    Areas: { stringFields: ["tasks"], dayFields: [], customFields: [] },
    ShiftGroups: { stringFields: ["tasks"], dayFields: [], customFields: [] },
    TaskBlocks: {
      stringFields: ["cantCombine"],
      dayFields: [],
      customFields: [],
    },
  },
});

const DatabaseToAppName = {
  ShiftGroups: "Shift Groups",
  TaskBlocks: "Task Blocks",
  CustomRules: "Custom Rules",
  RosteredAllocations: "My Roster",
  History: "History",
  Days: "Preferences",
  DaysRecurring: "Recurring Preferences",
  Allocations: "Fixed Shifts",
  AllocationsRecurring: "Recurring Fixed Shifts",
};

export const GetAppNamesOfEntities = (entitiesNameList) => {
  if (!Array.isArray(entitiesNameList)) return [];

  return entitiesNameList.map(
    (entityName) => DatabaseToAppName[entityName] || entityName
  );
};
