import styles from "./PlanBox.module.css";

const PlanBox = ({
  title,
  paragraphs,
  buttonLabel,
  onButtonClick,
  primaryColor,
  contrastColor,
}) => {
  const boxStyle = {
    border: `1px solid ${primaryColor}`,
  };

  const buttonStyle = {
    backgroundColor: primaryColor,
    color: contrastColor,
  };

  return (
    <div className={styles.planBox} style={boxStyle}>
      <div className={styles.planBoxTop}>
        <span className={styles.planBoxTitle}>{title}</span>
        <div className={styles.paragraphs}>
          {paragraphs.map((para, idx) => (
            <p key={idx} className={styles.paragraph}>
              {para}
            </p>
          ))}
        </div>
      </div>
      <button
        className={styles.planBoxButton}
        style={buttonStyle}
        onClick={onButtonClick}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default PlanBox;
