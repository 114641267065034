import { useCallback } from "react";
import { useAllLocationsQuery } from "./modelQueryHooks/useAllLocationsQuery";
import {
  getNewPrototypeLocation,
  getNewScheduleViewLocation,
  getUploadedScheduleData,
} from "../utils/queryUtils/appDataGetters";
import { useUserStore } from "../globalStore/appStore";
import { getRuleSetByName } from "../features/rules/service/ruleSets";
import {
  createRosterForScheduleView,
  inferRosterStartDate,
} from "../features/scheduleView/service/scheduleViewRoster";
import { DateTime, generateUniqueKioskCode, getIds } from "../utils";
import { getNewGlobalEmployeesTemplate } from "../utils/queryUtils/locationDataGetters";
import { getActualNumDays } from "../utils/queryUtils/monthViewUtils";
import { ONBOARDING_TASK_ID } from "../features/onBoarding/service/onBoarding";
import { KEYWORD_ALL } from "../constants/keywords";
import { DEFAULT_CUSTOM_KEYWORDS } from "../constants";

export function useCreateLocation(locationNames) {
  const { user, plan } = useUserStore();
  const { createLocation } = useAllLocationsQuery(user);

  const createNewPrototypeLocation = useCallback(
    async (name) => {
      if (name === "" || locationNames.includes(name)) {
        return;
      }
      const locationInfo = getNewPrototypeLocation({ name, plan });
      const allLocations = await createLocation(locationInfo, null, null);

      const createdLocation = allLocations.find(
        (location) => location.id === locationInfo.id
      );

      return createdLocation;
    },
    [createLocation, locationNames, plan]
  );

  const createNewScheduleViewLocation = useCallback(
    async (
      name,
      rosteringPeriod, //this ends with w for weeks or m for months
      startDate,
      employeesNumber,
      ruleTemplateName
    ) => {
      if (name === "" || locationNames.includes(name)) {
        return;
      }

      const numDays = getDefaultNumDays(rosteringPeriod);
      const ruleTemplate = getRuleSetByName(ruleTemplateName);
      const locationInfo = getNewScheduleViewLocation({
        name,
        startDate: startDate.toFormat("AWS"),
        defaultNumDays: numDays,
        plan,
        fields: {
          CustomRules: ruleTemplate ? ruleTemplate.CustomRules : [],
        },
      });

      const periodStartDate = inferRosterStartDate(
        startDate,
        new DateTime().toFormat("AWS"),
        getDefaultNumDays(rosteringPeriod)
      );

      const globalEmployeesInfo = [];
      const registeredKioskCodes = [];
      for (let i = 1; i <= employeesNumber; i++) {
        const kioskCode = generateUniqueKioskCode(registeredKioskCodes);
        registeredKioskCodes.push(kioskCode);

        const employee = getNewGlobalEmployeesTemplate({
          locationID: locationInfo.id,
          locationName: name,
          numItems: 1,
          name: `Employee ${i}`,
          allKeyword: KEYWORD_ALL,
          fields: {
            ruleValues: ruleTemplate ? ruleTemplate.RuleValues : [],
            DaysRecurring: Array(7).fill(""),
            AllocationsRecurring: Array(7).fill(""),
            code: kioskCode,
          },
        })[0];
        globalEmployeesInfo.push(employee);
      }

      const order = getIds(globalEmployeesInfo);
      locationInfo.order = order;
      if (employeesNumber > 0) {
        locationInfo.completedOnboardingTasks = [
          ONBOARDING_TASK_ID.addEmployees,
        ];
      }

      const defaultRosterInfo = createRosterForScheduleView(
        locationInfo.id,
        getActualNumDays(periodStartDate, locationInfo.defaultNumDays),
        periodStartDate,
        globalEmployeesInfo,
        DEFAULT_CUSTOM_KEYWORDS.annualLeave
      );

      const allLocations = await createLocation(
        locationInfo,
        globalEmployeesInfo,
        [defaultRosterInfo]
      );

      const createdLocation = allLocations.find(
        (location) => location.id === locationInfo.id
      );
      return createdLocation;
    },
    [createLocation, locationNames, plan]
  );

  const createNewScheduleViewLocationByUploading = useCallback(
    async (locationName, scheduleData) => {
      const locationStartDate = scheduleData.startDate;
      const today = new DateTime().toFormat("AWS");
      const rosterStartDateThatIncludesToday = inferRosterStartDate(
        locationStartDate,
        today,
        scheduleData.numDays
      );

      const { locationInfo, globalEmployeesInfo, rostersInfo } =
        getUploadedScheduleData(
          locationName,
          scheduleData,
          rosterStartDateThatIncludesToday,
          plan
        );

      const allLocations = await createLocation(
        locationInfo,
        globalEmployeesInfo,
        rostersInfo
      );
      const createdLocation = allLocations.find(
        (location) => location.id === locationInfo.id
      );

      return createdLocation;
    },
    [createLocation, plan]
  );

  return {
    createNewPrototypeLocation,
    createNewScheduleViewLocation,
    createNewScheduleViewLocationByUploading,
  };
}

export const getDefaultNumDays = (rosteringPeriod) => {
  if (rosteringPeriod.endsWith("w")) {
    const weeks = parseInt(rosteringPeriod.slice(0, -1), 10); // Remove 'w' and parse to integer
    return weeks * 7; // Convert weeks to days
  } else if (rosteringPeriod === "1m") {
    return -1; // Special value for month
  } else {
    return 0; // Default or error case
  }
};
