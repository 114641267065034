import {
  GlobalEmployeeSchema,
  getNewGlobalEmployeesTemplate,
} from "./locationDataGetters";
import { EmployeeSchema } from "./rosterDataGetters";

export type ScheduleEmployeeSchema = {
  id: string;
  externalID: string;
  email: string;
  name: string;
  locationID: string;
  locationName: string;
  ruleValues: string[];
  skills: string;
  shifts: string;
  Requests: any[];
  Preferences: any[];
  PreferencesRecurring: string[];
  PublishedAllocations: any[];
  DaysRecurring: string[];
  AllocationsRecurring: string[];
  TimeEntries: any[];
  FTE: number;
  salary: number;
  startDate: string;
  finishDate: string;
  registrations: string[];
  RuleValues: any[] | null;
  globalEmployeeID: string;
  RosteredAllocations: string[];
  Allocations: string[];
  Days: string[];
  History: string[];
  code?: string;
  areas?: string;
};

export function getNewScheduleEmployeesTemplate(
  options
): ScheduleEmployeeSchema[] {
  const {
    locationID,
    locationName,
    numItems,
    name,
    nextNumberedSuffix,
    allocationsRecurringLength,
    daysRecurringLength,
    numDays,
    rules,
    startDate,
  } = options;

  const globalEmployeesOption = {
    locationID,
    locationName,
    numItems,
    name,
    nextNumberedSuffix,
    startDate,
  };

  const globalEmployeesTemplate = getNewGlobalEmployeesTemplate(
    globalEmployeesOption
  );

  const scheduleEmployeesTemplate = globalEmployeesTemplate.map((employee) => ({
    ...employee,
    globalEmployeeID: employee.id,
    externalID: "",
    ruleValues: rules.length === 0 ? [] : Array(rules.length).fill(""),
    RuleValues: rules.length === 0 ? [] : Array(rules.length).fill(""),
    RosteredAllocations: null,
    Allocations: Array(numDays).fill(""),
    AllocationsRecurring: Array(allocationsRecurringLength).fill(""),
    Days: Array(numDays).fill(""),
    DaysRecurring: Array(daysRecurringLength).fill(""),
    PreferencesRecurring: Array(daysRecurringLength).fill(""),
    History: Array(14).fill(""),
    registrations: null,
  }));

  return scheduleEmployeesTemplate;
}

export function convertScheduleEmployeeToRosterEmployee(
  scheduleEmployee: ScheduleEmployeeSchema
): EmployeeSchema {
  return {
    id: scheduleEmployee.id,
    globalEmployeeID: scheduleEmployee.globalEmployeeID,
    Days: scheduleEmployee.Days,
    DaysRecurring: scheduleEmployee.DaysRecurring,
    Allocations: scheduleEmployee.Allocations,
    AllocationsRecurring: scheduleEmployee.AllocationsRecurring,
    History: scheduleEmployee.History,
    RuleValues: scheduleEmployee.RuleValues,
    RosteredAllocations: scheduleEmployee.RosteredAllocations,
    name: scheduleEmployee.name,
    skills: scheduleEmployee.skills,
    shifts: scheduleEmployee.shifts,
    externalID: scheduleEmployee.externalID,
    areas: scheduleEmployee.areas || "",
  };
}

export function convertScheduleEmployeeToGlobalEmployee(
  scheduleEmployee: ScheduleEmployeeSchema
): GlobalEmployeeSchema {
  return {
    id: scheduleEmployee.id,
    externalID: scheduleEmployee.externalID,
    email: scheduleEmployee.email,
    name: scheduleEmployee.name,
    locationID: scheduleEmployee.locationID,
    locationName: scheduleEmployee.locationName,
    ruleValues: scheduleEmployee.RuleValues,
    skills: scheduleEmployee.skills,
    shifts: scheduleEmployee.shifts,
    Requests: scheduleEmployee.Requests,
    Preferences: scheduleEmployee.Preferences,
    PreferencesRecurring: scheduleEmployee.PreferencesRecurring,
    PublishedAllocations: scheduleEmployee.PublishedAllocations,
    DaysRecurring: scheduleEmployee.DaysRecurring,
    AllocationsRecurring: scheduleEmployee.AllocationsRecurring,
    TimeEntries: scheduleEmployee.TimeEntries,
    FTE: scheduleEmployee.FTE,
    salary: scheduleEmployee.salary,
    startDate: scheduleEmployee.startDate,
    finishDate: scheduleEmployee.finishDate,
    registrations: scheduleEmployee.registrations,
    code: scheduleEmployee.code,
    areas: scheduleEmployee.areas || "",
  };
}
