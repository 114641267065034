import styles from "./EditButton.module.css";
import { Link } from "react-router-dom";

const EditButton = (props) => {
  return (
    <Link
      to={{
        pathname: `/roster/roster`,
        search: `?roster-id=${props.data.id}`,
      }}
      style={{ textDecoration: "none" }}
    >
      <button data-testid="edit-button" className={styles.btn}>
        Open
      </button>
    </Link>
  );
};

export default EditButton;
