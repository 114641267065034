import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "../../globalStore/appStore";
import { useMemo } from "react";
import { getUserAttributes } from "../../features/auth/service/auth";
import { fetchUserSettings } from "../../utils/queryUtils/appQuery";
import { KEYWORD_ALL } from "../../constants/keywords";

export function getLocationAreaFiltersSettings(settings, locationID) {
  if (!settings) {
    return [KEYWORD_ALL];
  }
  const areaFilters = settings.areaFilters;
  if (!areaFilters) {
    return [KEYWORD_ALL];
  }

  const locationAreaFilter = areaFilters.find(
    (areaFilter) => areaFilter.locationID === locationID
  );

  if (!locationAreaFilter) {
    return [KEYWORD_ALL];
  }
  return locationAreaFilter.areas;
}

export function useSettingsModelQuery() {
  const { user } = useUserStore();

  const owner = user
    ? `${getUserAttributes(user).sub}::${getUserAttributes(user).username}`
    : null;

  const query = useQuery({
    queryKey: ["settings", owner],
    queryFn: async () => {
      if (!user) {
        return null;
      }
      return fetchUserSettings(user);
    },
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
    enabled: Boolean(owner),
  });

  const settings = useMemo(() => {
    if (!query.data) {
      return null;
    }
    return query.data;
  }, [query]);

  return { settings, isLoading: query.isLoading };
}
