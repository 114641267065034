import { Redirect } from "react-router-dom";

const AdminRedirectRoute = ({ location, baseUrl }) => {
  if (!location) {
    return <></>;
  }

  const locationID = location.id;

  if (location.isScheduleView) {
    return (
      <Redirect
        to={`${baseUrl}/schedule-view/roster?location-id=${locationID}`}
      />
    );
  }

  return (
    <Redirect
      to={`${baseUrl}/classic-view/dashboard?location-id=${locationID}`}
    />
  );
};

export default AdminRedirectRoute;
