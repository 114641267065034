import { useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./ModalFrame.module.css";

export default function ModalFrame({
  open,
  onClose,
  children,
  shouldCloseOnOutsideClick = true,
  customStyle = {},
}) {
  const modalRef = useRef(null);

  // Disable scroll when opened
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  // work out which classes should be applied to the dialog element
  const dialogClasses = useMemo(() => {
    const _arr = [styles["modal"]];
    if (!open) _arr.push(styles["modal--closing"]);

    return _arr.join(" ");
  }, [open]);

  // Eventlistener: trigger onclose when cancel detected
  const onCancel = useCallback(
    (e) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  // Eventlistener: trigger onclose when click outside
  const onClick = useCallback(
    ({ target }) => {
      if (shouldCloseOnOutsideClick) {
        const { current: el } = modalRef;
        if (target === el) onClose();
      }
    },
    [onClose, shouldCloseOnOutsideClick]
  );

  // Eventlistener: trigger close click on anim end
  const onAnimEnd = useCallback(() => {
    const { current: el } = modalRef;
    if (!open) el.close();
  }, [open]);

  // when open changes run open/close command
  useEffect(() => {
    const { current: el } = modalRef;
    if (open) el.showModal();
  }, [open]);

  return (
    <dialog
      style={{ ...customStyle }}
      ref={modalRef}
      className={dialogClasses}
      onClose={onClose}
      onCancel={onCancel}
      onClick={onClick}
      onAnimationEnd={onAnimEnd}
    >
      <div className={styles["modal__container"]}>{children}</div>
    </dialog>
  );
}
