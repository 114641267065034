import Fuse from "fuse.js";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateTime } from "../../../../../utils";
import { inferSearchedShift } from "../../service/allocationSelectorUtil";
import styles from "./AllocationSelector.module.css";
import { Notes } from "../Notes/Notes";
import { getEmployeeNoteOnDay } from "../../../../../utils/queryUtils/globalEmployeeDataGetters";
import {
  convertAllocationInNameFormToShortIdForm,
  convertAllocationInShortIdFormToNameForm,
  extractEntityNamesFromAllocation,
} from "../../../../../utils/modelUtils/allocation";

const initializeSelected = (prevSelected, allOptions) => {
  if (allOptions.includes(prevSelected)) {
    return prevSelected;
  } else {
    return "";
  }
};

const fuseOptions = {
  includeScore: true, // Optional, if you want to see match scores
  threshold: 0.3, // Adjust threshold to control sensitivity of match
  useExtendedSearch: true, // Enables extended search options
};

const AllocationSelector = forwardRef(
  (
    {
      options,
      headerName,
      allowNote = false,
      startDate,
      updateNote,
      shortIdsToEntityNamesDicts,
      namesToEntityShortIdsDicts,
      employeesAllocationNotes = null,
      ...props
    },
    ref
  ) => {
    const employeeID = props.data.id;

    const date =
      props.data.date ||
      new DateTime(startDate)
        .addDays(Number(props.colDef.field.substring(1)) - 1)
        .toFormat("AWS");

    const note = useMemo(
      () =>
        allowNote
          ? getEmployeeNoteOnDay(employeeID, employeesAllocationNotes, date)
          : "",
      [employeeID, date, allowNote, employeesAllocationNotes]
    );

    const allocationOptionLabels = useMemo(
      () => options.map(({ label }) => label),
      [options]
    );

    const fuse = useMemo(
      () => new Fuse(allocationOptionLabels, fuseOptions),
      [allocationOptionLabels]
    );

    const originalCellValue = convertAllocationInShortIdFormToNameForm(
      props.value,
      shortIdsToEntityNamesDicts
    );
    const [inputValue, setInputValue] = useState(originalCellValue);
    const refInput = useRef(null);
    const scrollViewRef = useRef(null);
    const [selected, setSelected] = useState(() =>
      initializeSelected(originalCellValue, allocationOptionLabels)
    );

    if (!headerName) headerName = "Shifts: ";

    useEffect(() => {
      setTimeout(() => {
        if (refInput.current) {
          refInput.current.focus();
          refInput.current.select();
        }
      }, 100);
    }, []);

    const listedOptions = useMemo(() => {
      if (!inputValue) {
        return allocationOptionLabels;
      }
      const filteredOptions = fuse.search(inputValue).map(({ item }) => item);
      return filteredOptions;
    }, [inputValue, allocationOptionLabels, fuse]);

    const handleKeyDown = useCallback(
      (e) => {
        const key = e.key;
        if (key === "ArrowDown") {
          if (!selected) {
            setSelected(listedOptions[0]);
          }
          if (selected) {
            const selectionIdx = listedOptions.findIndex((o) => o === selected);
            if (selectionIdx < listedOptions.length - 1) {
              scrollViewRef.current.scrollBy(0, 26);
              setSelected(listedOptions[selectionIdx + 1]);
            }
          }
        } else if (key === "ArrowUp") {
          if (selected) {
            const selectionIdx = listedOptions.findIndex((o) => o === selected);
            if (selectionIdx > 0) {
              scrollViewRef.current.scrollBy(0, -26);
              setSelected(listedOptions[selectionIdx - 1]);
            }
          }
        }
      },
      [listedOptions, selected]
    );

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const selectOption = (option) => {
      setInputValue(option);
      setSelected(option);
    };

    useEffect(() => {
      const inferredSelected = inferSearchedShift(inputValue, listedOptions);
      if (!inputValue) {
        setSelected("");
        return;
      }
      if (inferredSelected) {
        setSelected(inferredSelected);
      } else {
        setSelected("");
      }
    }, [inputValue, listedOptions]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          const {
            areaName,
            areaNameMapsToEntity,
            shiftGroupName,
            shiftGroupNameMapsToEntity,
            taskName,
            taskNameMapsToEntity,
            skillName,
            skillNameMapsToEntity,
            enumeratedTaskName,
            enumeratedTaskNameMapsToEntity,
            shiftName,
            shiftNameMapsToEntity,
          } = extractEntityNamesFromAllocation(
            inputValue,
            namesToEntityShortIdsDicts
          );

          if (
            (areaName && areaNameMapsToEntity) ||
            (shiftGroupName && shiftGroupNameMapsToEntity) ||
            (taskName && taskNameMapsToEntity) ||
            (skillName && skillNameMapsToEntity) ||
            (enumeratedTaskName && enumeratedTaskNameMapsToEntity)
          ) {
            if (!(shiftName && shiftNameMapsToEntity)) {
              return inputValue;
            }
          }

          const shortIdForm = convertAllocationInNameFormToShortIdForm(
            inputValue,
            namesToEntityShortIdsDicts
          );

          return shortIdForm;
        },

        isPopup() {
          return true;
        },
      };
    });

    return (
      <div className={styles.dropdownWrapper}>
        <input
          className={styles.typable}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value.replace("\\", "_").replace(";", "_"));
          }}
          ref={refInput}
          placeholder="Type your shift here"
        />
        <div className={styles.selector}>
          <p className={styles.header}>{headerName}</p>
          <ul className={styles.options} ref={scrollViewRef}>
            {listedOptions.map((option, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => selectOption(option)}
                  className={selected === option ? styles.selected : ""}
                >
                  {convertAllocationInShortIdFormToNameForm(
                    option,
                    shortIdsToEntityNamesDicts
                  ) || option}
                </li>
              );
            })}
          </ul>
          {allowNote && (
            <div className={styles.notesContainer}>
              <Notes
                employeeID={employeeID}
                date={date}
                saveNote={updateNote}
                note={note}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default AllocationSelector;
