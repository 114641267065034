import { getEmployeeNoteOnDay } from "../../../../utils/queryUtils/globalEmployeeDataGetters";
import styles from "./AllocationCellRenderer.module.css";

export default function AllocationCellRenderer({
  valueFormatted,
  employeesAllocationNotes = null,
  date,
  ...props
}) {
  const employeeId = props.data.id;
  const note = getEmployeeNoteOnDay(employeeId, employeesAllocationNotes, date);

  return (
    <div>
      {note && <div className={styles.tag} />}
      {valueFormatted}
    </div>
  );
}
