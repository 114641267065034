import { useMemo } from "react";
import FixedShiftsRecurringGrid from "../../../components/FixedShifts/FixedShiftsRecurringGrid/FixedShiftsRecurringGrid";
import { getActualInitialStartDate } from "../../../../../utils/queryUtils/monthViewUtils";

const IndFixedShiftsRecurringContainer = ({
  location,
  startDate,
  numDays,
  warnings,
  isSaving,
  employeesData,
  recurringColumns,
  setGridApiToParent,
  setColumnApiToParent,
  updateData,
  predefinedKeywords,
  enumeratedShiftTasks,
  enumeratedTasks,
  locationID,
  getDataFromGrid,
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const recurringWarnings = useMemo(() => {
    return warnings ? warnings.AllocationsRecurring : null;
  }, [warnings]);

  return (
    <FixedShiftsRecurringGrid
      locationID={locationID}
      startDate={getActualInitialStartDate(
        location.isScheduleView ? location.startDate : startDate,
        location.isScheduleView ? location.defaultNumDays : numDays,
        location.isScheduleView
      )}
      employeesData={employeesData}
      isSaving={isSaving}
      recurringColumns={recurringColumns}
      enumeratedTasks={enumeratedTasks}
      setGridApiToParent={setGridApiToParent}
      updateData={updateData}
      recurringWarnings={recurringWarnings}
      weekdayColWidth={70}
      weekendColWidth={70}
      customStyle={{
        isSubheading: true,
        dataEntryTable: {
          height: "110px",
        },
      }}
      title="Fixed Shifts Recurring"
      setColumnApiToParent={setColumnApiToParent}
      showWeekRangeSelector={false}
      showActionBar={false}
      predefinedKeywords={predefinedKeywords}
      enumeratedShiftTasks={enumeratedShiftTasks}
      getDataFromGrid={getDataFromGrid}
      areas={areas}
      shifts={shifts}
      shiftGroups={shiftGroups}
      tasks={tasks}
      subTasks={subTasks}
      shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      customKeywordsUtilObj={customKeywordsUtilObj}
    />
  );
};

export default IndFixedShiftsRecurringContainer;
