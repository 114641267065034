/* eslint no-useless-escape: 0 */
export function getRosterSolutionAllocationRegex(allocationName) {
  return new RegExp(`${allocationName}: ([0-9]+(?:[.][0-9]*)?|.[0-9]+)`);
}

export function getDayColumnNameRegex() {
  return new RegExp(`d[0-9]+`);
}

export function getRecurringDayColumnNameRegex() {
  return new RegExp(`r[0-9]+`);
}

export function getUniqueNamingRegex(name) {
  return new RegExp(`${name} \\(([0-9]*)\\)`);
}

export function getEmailRegex() {
  return new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export function getCoordinatorLocationRegex() {
  return new RegExp("^.*_coordinator$");
}

export function getKioskLocationRegex() {
  return new RegExp("^.*_kiosk$");
}

export function getCollaboratorLocationRegex() {
  return new RegExp("^.*_collaborator$");
}

export function isDateStringRegex(dateString) {
  // Regular expression pattern for "yyyy-mm-dd" format
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  return datePattern.test(dateString);
}

export function getAllocationRegexNoArea() {
  return new RegExp(/^([^:]+)-(.+)$/);
}

export function getAllBlockTaskOptionRegex() {
  return new RegExp("^all\\s+(.+)\\s+tasks$");
}

export function getNoBlockTaskOptionRegex() {
  return new RegExp("^no\\s+task\\s+(.+)$");
}

export function getSolutionLeaveNumberRegex(annualLeaveKeyword) {
  return new RegExp(`^${annualLeaveKeyword}: \\d+$`);
}
