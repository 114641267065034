const stylesUpgrade = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
    height: "50%",
    boxSizing: "border-box",
  },
  content: {
    maxWidth: "80%",
    textAlign: "center",
    color: "#333",
  },
  button: {
    marginTop: "1em",
    padding: "0.5em 1em",
    backgroundColor: "#4CAF50",
    color: "#FFF",
    border: "none",
    borderRadius: "4px",
    fontSize: "1em",
    cursor: "pointer",
  },
  "@media (min-width: 600px)": {
    container: {
      padding: "2em",
    },
    content: {
      maxWidth: "60%",
    },
  },
  "@media (min-width: 900px)": {
    content: {
      maxWidth: "40%",
    },
  },
};

const UpgradePlanComponent = () => (
  <div style={stylesUpgrade.container}>
    <div style={stylesUpgrade.content}>
      <h2>Upgrade Your Plan</h2>
      <p>
        To access the additional features of our rostering app, your rostering
        admin must upgrade from the free tier to a paid plan.
      </p>
      <button style={stylesUpgrade.button}>Upgrade Now</button>
    </div>
  </div>
);

export default UpgradePlanComponent;
