import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { checkStringContainsAllWordsInArr } from "../../../../utils";
import styles from "./ColumnFilterInput.module.css";

let inputCache = "";
export const resetRuleFilterSearchInput = () => {
  inputCache = "";
};

const ColumnFilterInput = ({ columnApi, ruleColNameDescriptionPairs }) => {
  const [inputValue, setInputValue] = useState(inputCache);

  useEffect(() => {
    inputCache = inputValue;
  }, [inputValue]);

  useEffect(() => {
    if (!columnApi) {
      return;
    }
    const colIds = columnApi.getColumns().map((c) => c.colId);
    const lowerCasedInputValue = inputValue.toLowerCase();
    const inputWords = lowerCasedInputValue.split(" ").filter((w) => w !== "");

    for (let i = 0; i < colIds.length; i++) {
      const colId = colIds[i];
      if (colId === "name") {
        continue;
      }

      const ruleNameAndDescPair = ruleColNameDescriptionPairs.find(
        (item) => item.ruleColName === colId
      );

      const description = ruleNameAndDescPair
        ? ruleNameAndDescPair.description.replace("____ ", "")
        : null;

      if (inputWords.length === 0) {
        columnApi.setColumnVisible(colId, true);
        continue;
      }
      if (checkStringContainsAllWordsInArr(inputWords, colId.toLowerCase())) {
        columnApi.setColumnVisible(colId, true);
      } else if (
        description &&
        checkStringContainsAllWordsInArr(inputWords, description.toLowerCase())
      ) {
        columnApi.setColumnVisible(colId, true);
      } else {
        columnApi.setColumnVisible(colId, false);
      }
    }
  }, [columnApi, inputValue, ruleColNameDescriptionPairs]);

  return (
    <div className={styles.inputWrapper}>
      <input
        value={inputValue}
        className={styles.searchInput}
        type="search"
        placeholder="Filter rules"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <button className={styles.searchBtn}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className={styles.searchIcon}
        />
      </button>
    </div>
  );
};

export default ColumnFilterInput;
