import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./GetStartedButton.module.css";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

const GetStartedButton = ({ url, noPaddingBottom = false, setWizardOpen }) => {
  return (
    <div
      className={`${styles.container} ${
        noPaddingBottom ? styles.noPaddingBottom : null
      }`}
    >
      <button
        className={styles.upperBtn}
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        <FontAwesomeIcon icon={faBookOpen} className={styles.upperBtnIcon} />
        How to get started
      </button>
      {setWizardOpen && (
        <button
          className={styles.upperBtn}
          onClick={() => {
            setWizardOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faBookOpen} className={styles.upperBtnIcon} />
          Test onboarding flow
        </button>
      )}
    </div>
  );
};

export default GetStartedButton;
