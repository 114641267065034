import { useEffect, useState } from "react";
import { DateTime } from "../utils";

export function useLiveTime() {
  const [dateTime, setDateTime] = useState(new DateTime(null, true)); // State to store the current time

  useEffect(() => {
    // Function to update the time at the start of the next minute
    const syncInterval = () => {
      const now = new Date();
      const msUntilNextMinute =
        60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

      // Set timeout to synchronize the clock with the beginning of the next minute
      const timeoutId = setTimeout(() => {
        setDateTime(new DateTime(null, true)); // Update the time state to the current time

        // Set interval to update time every minute on the minute
        const intervalId = setInterval(() => {
          setDateTime(new DateTime(null, true));
        }, 60000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, msUntilNextMinute);

      // Return a function to clear the timeout and interval when the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    };

    const clearIds = syncInterval();

    // Cleanup function for both timeout and interval
    return clearIds;
  }, []);

  return {
    date: dateTime,
    time: DateTime.getTimeStringOfDate(dateTime),
  };
}
