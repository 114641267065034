import styles from "./Step.module.css";
import { useWizard } from "react-use-wizard";
import StepTemplate from "./StepTemplate";
import StepTitle from "./StepTitle";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { isNightShift } from "../../../../utils";

const Step2 = ({ data, updateData }) => {
  const { nextStep, previousStep } = useWizard();

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData({ [name]: value });
  };

  const isShiftNightShift = isNightShift(data.shiftStart, data.shiftEnd);

  return (
    <StepTemplate
      previousStep={previousStep}
      nextStep={nextStep}
      nextName="Next"
      shouldBlock={isShiftNightShift}
    >
      <div className={`${styles.container} ${styles.singleContentContainer}`}>
        <StepTitle
          fontAwesomeIcon={faListCheck}
          text={"Step 1/4 - Generating your first roster"}
        />
        <h1 className={styles.title}>Create a shift template</h1>
        <div className={`${styles.paragraphs} ${styles.stepTwoParagraphs}`}>
          <p className={`${styles.paragraph} ${styles.blueText}`}>
            <b>
              Shift templates are the shifts that can be given to your staff.
            </b>
          </p>

          <div className={styles.shiftCreateInputs}>
            <div className={styles.shiftCreateInputItem}>
              <label htmlFor="shiftName">Shift Name</label>
              <input
                id="shiftName"
                name="shiftName"
                type="text"
                value={data.shiftName}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
            <div className={styles.shiftCreateInputItem}>
              <label htmlFor="shiftStart">Start Time</label>
              <input
                id="shiftStart"
                name="shiftStart"
                type="time"
                value={data.shiftStart}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
            <div className={styles.shiftCreateInputItem}>
              <label htmlFor="shiftEnd">End Time</label>
              <input
                id="shiftEnd"
                name="shiftEnd"
                type="time"
                value={data.shiftEnd}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
          </div>
          {isShiftNightShift && (
            <p style={{ color: "red" }}>
              * The AI can&apos;t assign night shifts for RosterLab Starter
              tier. Contact us about Rosterlab Premium if you need night shifts.
            </p>
          )}
          <p className={styles.paragraph}>
            Tip: Shifts, and also in combination with tasks, can be used to
            represent many things such as work location, a task or role to be
            performed during the shift or a combination of these things.
          </p>
        </div>
      </div>
    </StepTemplate>
  );
};

export default Step2;
