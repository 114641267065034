import {
  faBell,
  faFlag,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Notification.module.css";
import PropTypes from "prop-types";

const displayIcon = (type) => {
  switch (type) {
    case "request":
      return (
        <FontAwesomeIcon
          icon={faFlag}
          className={`${styles.icon} ${styles.flagIcon}`}
        />
      );
    case "regenerate":
      return (
        <FontAwesomeIcon
          icon={faArrowsRotate}
          className={`${styles.icon} ${styles.regenerateIcon}`}
        />
      );
    default:
      return null;
  }
};

const Notification = ({ notifications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const notificationBtnRef = useRef();
  const listRef = useRef();

  const notificationNum = notifications.length;

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  const handleClickEvent = useCallback(
    (e) => {
      if (
        notificationBtnRef.current &&
        notificationBtnRef.current.contains(e.target)
      ) {
        if (isOpen) {
          close();
        } else {
          open();
        }
      } else if (listRef.current && !listRef.current.contains(e.target)) {
        close();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickEvent);
    return () => {
      document.removeEventListener("click", handleClickEvent);
    };
  }, [isOpen, handleClickEvent]);

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} ref={notificationBtnRef}>
        {notificationNum > 0 && <span className={styles.active}></span>}
        <FontAwesomeIcon icon={faBell} className={styles.icon} />
      </button>
      {isOpen && (
        <ul className={styles.list} ref={listRef}>
          {notifications.map((item, idx) => (
            <li key={idx} onClick={item.onClick}>
              {displayIcon(item.type)}
              <span className={styles.description}>{item.description}</span>
            </li>
          ))}
          {notifications.length === 0 && (
            <div className={styles.notiPlaceholder}>
              <span>You have no notifications!</span>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

Notification.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Notification;
