import { DateTime } from "../../../../../utils";
import OpenShiftItem from "../../OpenShiftItem/OpenShiftItem";
import AllocationTimeframe from "../../AllocationTimeframe/AllocationTimeframe";
import SideTabCard from "../SideTabCard/SideTabCard";
import styles from "./ScheduleSideTab.module.css";
import RateShiftButton from "../../RateShiftButton/RateShiftButton";
import { canAccessSurvey } from "../../../../../utils/flagsUtils/flags";
import { useMemo } from "react";

const ScheduleSideTab = ({
  currentViewData,
  allocations,
  myOpenShifts,
  userEmployee,
  updateOpenShifts,
  notesToDisplay,
}) => {
  const { selectedDate } = currentViewData;
  const allocation = allocations.find(
    (allo) => allo.date === selectedDate.toFormat("AWS")
  );

  const { email } = userEmployee;

  const note = useMemo(
    () => notesToDisplay[selectedDate.toFormat("AWS")] || "",
    [notesToDisplay, selectedDate]
  );

  const openShiftsOnDay = myOpenShifts.filter((openShift) => {
    if (new DateTime().isAfter(openShift.date)) {
      const state = openShift.employeeStates.find(
        (s) => s.employeeID === userEmployee.id
      );
      if (state.state === "pending") {
        return false;
      }
    }
    return openShift.date === selectedDate.toFormat("AWS");
  });

  return (
    <div className={styles.sideTabContainer}>
      {allocation && (
        <div className={styles.section}>
          <span className={styles.sectionTitle}>My Published Shift</span>
          <AllocationTimeframe allocation={allocation} />
          {canAccessSurvey(email) && <RateShiftButton date={selectedDate} />}
        </div>
      )}
      {openShiftsOnDay.length > 0 && (
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Open Shifts</span>
          <SideTabCard>
            {openShiftsOnDay.map((openShift, idx) => {
              return (
                <OpenShiftItem
                  key={openShift.id}
                  openShift={openShift}
                  shouldAddTopSeparator={idx === 0}
                  updateOpenShifts={updateOpenShifts}
                  employeeID={userEmployee.id}
                  isDisplayedInDayView={true}
                />
              );
            })}
          </SideTabCard>
        </div>
      )}
      {note && (
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Notes from my department</span>
          <SideTabCard
            emphasizeColor="#3CAA84"
            backgroundColor="#3CAA84"
            defaultTextColor="white"
          >
            {note}
          </SideTabCard>
        </div>
      )}
    </div>
  );
};

export default ScheduleSideTab;
