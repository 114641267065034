import styles from "./DeleteSolutionButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

const DeleteSolutionButton = ({ solveFinished, deleteSolution }) => {
  return (
    <button
      title="Delete solution"
      className={styles.btn}
      onClick={deleteSolution}
    >
      <FontAwesomeIcon icon={faDeleteLeft} className={styles.actionIcon} />
      <span>{solveFinished ? "Delete solution" : "Cancel generate"}</span>
    </button>
  );
};

export default DeleteSolutionButton;
