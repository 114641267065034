import { customWarningAlert } from "../../features/confirm/service/confirm";

let isPopupShowing = false;

export function showWarningPopupIfNoOthersShowing(title, msg, delay = 0) {
  if (isPopupShowing) {
    return;
  }

  isPopupShowing = true;

  return new Promise((resolve) => {
    setTimeout(() => {
      customWarningAlert({ title, descriptions: [msg] }).then((response) => {
        isPopupShowing = false;
        resolve(response);
      });
    }, delay);
  });
}
