import DuplicateRosterModal from "./DuplicateRoster/DuplicateRoster";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { DateTime } from "../../../../utils";
import { getDuplicatedRoster } from "../../../../utils/queryUtils/locationDataGetters";

const DuplicateRosterModalContainer = ({
  toggleDuplicateRosterModal,
  roster,
  createRoster,
  location,
}) => {
  const redirect = useRedirect();

  const applyDuplicateRoster = async (
    rosterName,
    startDate,
    numDays,
    dataToCarryOver,
    historyUsage,
    shouldCopyRosteredAllocations
  ) => {
    const duplicateOptions = {
      rosterName,
      startDate,
      numDays,
      dataToCarryOver,
      historyUsage,
      shouldCopyRosteredAllocations,
    };
    const duplicatedRoster = await getDuplicatedRoster(
      roster.id,
      location,
      duplicateOptions
    );
    createRoster(duplicatedRoster);
    return duplicatedRoster;
  };

  const currentDate = new DateTime(roster.startDate).getDate();
  const finishDate = new DateTime(roster.startDate).addDays(
    roster.numDays - 1
  ).date;
  const nextDate = new DateTime(
    finishDate.setDate(finishDate.getDate() + 1)
  ).getDate();

  return (
    <DuplicateRosterModal
      closeModal={toggleDuplicateRosterModal}
      currentDate={currentDate}
      nextDate={nextDate}
      numDays={roster.numDays}
      duplicateRoster={applyDuplicateRoster}
      redirectToCopiedRosterPage={(rosterID) =>
        redirect({ pageName: PAGE_NAMES.roster, rosterID })
      }
    />
  );
};

export default DuplicateRosterModalContainer;
