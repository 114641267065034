import { useHistory } from "react-router-dom";

export const PAGE_NAMES = {
  employeesSkills: "employees-skills",
  dashboard: "dashboard",
  location: "location",
  scheduleViewLocation: "schedule-view-location",
  defaultDashboard: "default-dashboard",
  requests: "requests",
  preferences: "preferences",
  individualEmployee: "individual-employee",
  scheduleViewIndividualEmployee: "schedule-view-individual-employee",
  scheduleViewEmployeesSkills: "schedule-view-employees-skills",
  shifts: "shifts",
  scheduleViewShifts: "schedule-view-shifts",
  scheduleViewRules: "schedule-view-rules",
  scheduleViewDemands: "schedule-view-demands",
  scheduleViewPreferences: "schedule-view-preferences",
  generateLocked: "generateLocked",
  scheduleGenerateLocked: "schedule-generate-locked",
  roster: "roster",
  directSharedRoster: "share",
  directShareRosterAuthCheck: "direct-share",
  unsupportedDevice: "unsupported-device",
  main: "",
  billing: "billing",
  scheduleViewShiftView: "schedule-view-shift-view",
  shiftView: "shift-view",
  myRoster: "my-roster",
  mobileEmployeeViewSchedule: "employee-view-schedule",
  mobileEmployeeViewLeaveRequests: "employee-view-leave-requests",
  mobileEmployeeViewOpenShifts: "employee-view-open-shifts",
  mobileEmployeeViewProfile: "employee-view-profile",
  desktopEmployeeViewSchedule: "desktop-employee-view-schedule",
  mobileEmployeeViewSurvey: "employee-view-survey",
  desktopEmployeeViewSurvey: "desktop-employee-view-survey",
};

const generateQueryString = (queryObj) => {
  const queries = [];
  for (const [key, value] of Object.entries(queryObj)) {
    queries.push(`${key}=${value}`);
  }

  const queryString = queries.join("&");
  return `?${queryString}`;
};

export function useRedirect() {
  const history = useHistory();

  const redirectTo = (redirectionInfo) => {
    const {
      pageName,
      rosterID,
      locationID,
      params,
      callback,
      employeeID,
      query,
    } = redirectionInfo;

    let url = "";
    switch (pageName) {
      case PAGE_NAMES.defaultDashboard:
        url = "/locations/classic-view/dashboard";
        break;
      case PAGE_NAMES.roster:
        url = "/roster/roster?roster-id=" + rosterID;
        break;
      case PAGE_NAMES.myRoster:
        url = "/roster/roster";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.employeesSkills:
        url = "/roster/employees?roster-id=" + rosterID;
        break;
      case PAGE_NAMES.shifts:
        url = "/roster/shifts?roster-id=" + rosterID;
        break;
      case PAGE_NAMES.individualEmployee:
        url = `/roster/employees-individual?roster-id=${rosterID}${
          employeeID ? `&employee-id=${employeeID}` : ""
        }`;
        break;
      case PAGE_NAMES.dashboard:
        url = "/locations/classic-view/dashboard?location-id=" + locationID;
        break;
      case PAGE_NAMES.location:
        url = "/locations/settings?location-id=" + locationID;
        break;
      case PAGE_NAMES.scheduleViewLocation:
        url = "/locations/schedule-view/roster";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewShiftView:
        url = "/locations/schedule-view/shift-view";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.shiftView:
        url = "/roster/shift-view";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewRules:
        url = "/locations/schedule-view/rules";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewDemands:
        url = "/locations/schedule-view/demands";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewPreferences:
        url = "/locations/schedule-view/preferences";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.requests:
        url = "/locations/requests?location-id=" + locationID;
        break;
      case PAGE_NAMES.preferences:
        url = "/locations/preferences?location-id=" + locationID;
        break;
      case PAGE_NAMES.generateLocked:
        url = "/roster/generate-locked";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleGenerateLocked:
        url = "/locations/schedule-view/generate-locked";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.billing:
        url = "/billing";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.directSharedRoster:
        url = "/share?id=" + rosterID;
        break;
      case PAGE_NAMES.directShareRosterAuthCheck:
        url = "/direct-share?id=" + rosterID;
        break;
      case PAGE_NAMES.main:
        url = "/";
        break;
      case PAGE_NAMES.scheduleViewIndividualEmployee:
        url = "/locations/schedule-view/employees-individual";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewShifts:
        url = "/locations/schedule-view/shifts";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.scheduleViewEmployeesSkills:
        url = "/locations/schedule-view/employees";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.mobileEmployeeViewSchedule:
        url = "/employee-view/mobile/schedule"; // Change this to correct url
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.mobileEmployeeViewLeaveRequests:
        url = "/employee-view/mobile/leave"; // Change this to correct url
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.mobileEmployeeViewOpenShifts:
        url = "/employee-view/mobile/open-shifts"; // Change this to correct url
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.mobileEmployeeViewProfile:
        url = "/employee-view/mobile/user"; // Change this to correct url
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.desktopEmployeeViewSchedule:
        url = "/employee-view/desktop/calendar";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.mobileEmployeeViewSurvey:
        url = "/employee-view/mobile/survey";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.desktopEmployeeViewSurvey:
        url = "/employee-view/desktop/survey";
        if (query) {
          url = url + generateQueryString(query);
        }
        break;
      case PAGE_NAMES.unsupportedDevice:
        url = "/unsupported-device";
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (params) {
        history.push(url, params);
      } else {
        history.push(url);
      }
      if (callback) {
        callback();
      }
    }, 100);
  };

  return redirectTo;
}
