import { SwitchField } from "@aws-amplify/ui-react";
import styles from "./AllocationCellAreaToggler.module.css";

export default function AllocationCellAreaToggler({
  onToggle,
  isChecked,
  labelStyle = {},
}) {
  return (
    <div className={styles.container}>
      <SwitchField
        onChange={(event) => onToggle(event.target.checked)}
        isChecked={isChecked}
        label="Display area name"
        isLabelHidden={true}
        trackCheckedColor="#009EC6"
        style={{
          display: "flex",
        }}
      />
      <span className={styles.label} style={labelStyle}>
        Display area name
      </span>
    </div>
  );
}
