import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import styles from "./LeaveSideTab.module.css";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "../../../../../utils";
import LeaveListItem from "../../LeaveListItem/LeaveListItem";
import {
  getLeaveCountInfo,
  parseEmployeesLeaveData,
} from "../../../service/employeeAppUtil";
import SideTabCard from "../SideTabCard/SideTabCard";

const LeaveSideTab = ({
  currentViewData,
  requests,
  openLeaveTab,
  employeeLeaveCounts,
  showAllRequests,
  setShowAllRequests,
  leaveKeywordsDict,
}) => {
  const { selectedDate } = currentViewData;
  const { employeesWithAL, employeesWithSL } = parseEmployeesLeaveData(
    employeeLeaveCounts,
    selectedDate
  );

  const leaveCountInfo = getLeaveCountInfo(
    selectedDate,
    employeeLeaveCounts,
    leaveKeywordsDict
  );

  const toggleShowAllRequests = () => setShowAllRequests((prev) => !prev);

  const requestsCoveringSelectedDate = requests.filter((request) => {
    const { startDate, finishDate } = request;
    const dateRange = DateTime.getAllDateStringsBetween(
      startDate,
      finishDate,
      true,
      true
    );
    return dateRange.includes(selectedDate.toFormat("AWS"));
  });

  const displayedRequests = showAllRequests
    ? requests
    : requestsCoveringSelectedDate;

  const selectedDateIsNotPast = selectedDate.isSameOrAfter(new DateTime());

  const shouldHideWhosOnLeave =
    employeesWithAL.length === 0 && employeesWithSL.length === 0;

  const createEmployeesList = (names) => {
    return (
      <div className={styles.employeesList}>
        {names.map((employeeName, idx) => (
          <span key={idx}>{employeeName}</span>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.sideTabContainer}>
      {displayedRequests.length > 0 && (
        <div className={styles.section}>
          {displayedRequests.map((leave, idx) => {
            const customContainerStyle =
              idx !== 0
                ? {
                    borderTop: "1px solid black",
                    padding: "20px 0px",
                  }
                : { paddingBottom: "20px" };
            return (
              <LeaveListItem
                key={leave.id}
                leaveRequest={leave}
                onViewMore={(request) => openLeaveTab(false, request)}
                customContainerStyle={customContainerStyle}
              />
            );
          })}
        </div>
      )}
      {!shouldHideWhosOnLeave && (
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Who&apos;s on leave?</span>
          <SideTabCard>
            <div className={styles.leaveTypes}>
              {leaveCountInfo.map(({ label, count, employees, shortName }) => (
                <div key={label}>
                  <span className={`${styles.leaveLabel} ${styles[shortName]}`}>
                    {label} {count}
                  </span>
                  {createEmployeesList(employees)}
                </div>
              ))}
            </div>
          </SideTabCard>
        </div>
      )}
      {requestsCoveringSelectedDate.length === 0 && selectedDateIsNotPast && (
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          customStyle={{
            borderRadius: "5px",
          }}
          onClick={() => openLeaveTab(true, null)}
        >
          <div className={styles.addLeaveRequestButton}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Add Leave Request
          </div>
        </BasicButton>
      )}
      <button
        className={styles.viewToggleButton}
        onClick={toggleShowAllRequests}
      >
        {showAllRequests ? "View less" : "View all"}
      </button>
    </div>
  );
};
export default LeaveSideTab;
