import { useCallback, useEffect, useRef, useState } from "react";

/**
 * This hook takes care of closing on outside modal/dropdown click.
 * This hook does not close the modal if selector or the selector trigger is clicked
 */
export function useToggleShow() {
  const [isOpen, setIsOpen] = useState(false);
  const selectorTriggerRef = useRef(null);
  const selectorRef = useRef(null);

  const handleOutsideClick = useCallback(
    (e) => {
      if (selectorRef.current && selectorTriggerRef.current) {
        if (
          !selectorRef.current.contains(e.target) &&
          !selectorTriggerRef.current.contains(e.target)
        ) {
          setIsOpen(false);
        }
      }
    },
    [selectorTriggerRef, selectorRef]
  );

  useEffect(() => {
    if (selectorRef.current && selectorTriggerRef.current) {
      document.addEventListener("mouseup", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [isOpen, selectorRef, selectorTriggerRef, handleOutsideClick]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    toggleDropdown,
    selectorRef,
    selectorTriggerRef,
  };
}
