import styles from "./SettingItem.module.css";

const SettingItem = ({
  setting,
  toggleSettings,
  isEnabled,
  updateCheckLeaveSetting,
}) => {
  const handleToggle = () => {
    if (setting.name === "checkLeave") {
      updateCheckLeaveSetting();
      return;
    }
    toggleSettings(setting.name);
  };

  return (
    <>
      <input
        id={setting.name}
        type="checkbox"
        onChange={handleToggle}
        checked={isEnabled}
      />
      <label className={styles.settingOption} htmlFor={setting.name}>
        {setting.title}
      </label>
    </>
  );
};

export default SettingItem;
