import { DatePicker } from "antd";
import styles from "./EmployeeAppWeekSwitcher.module.css";
import { forwardRef, useRef, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "../../../../utils";
import { isMobileViewOrApp } from "../../../../globalStore/deviceInfoStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const WeekSelectorPanel = ({ props, isMobile, toggleOpen }) => {
  const defaultPanel = props.children;
  const modalRef = useRef(null);

  const handleClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      toggleOpen();
    }
  };

  if (isMobile) {
    return (
      <div className={styles.mobileModal} onClick={handleClick}>
        <div ref={modalRef} className={styles.mobilePanel}>
          {defaultPanel}
        </div>
      </div>
    );
  }

  return defaultPanel;
};

const EmployeeAppWeekSwitcher = ({ date, setDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = isMobileViewOrApp();

  const updateSelectedDate = (selected) => {
    const date = new DateTime(selected.$d);
    setDate(date);
    if (isMobile) {
      toggleOpen();
    }
  };

  const formatDateLabel = (value) => {
    const startDate = dayjs(value).startOf("week");
    const endDate = dayjs(value).endOf("week");
    const monthName1 = startDate.format("MMM");
    const monthName1Full = startDate.format("MMMM");
    const monthName2 = endDate.format("MMM");

    const year = endDate.format("YYYY");

    const monthLabel =
      monthName1 === monthName2
        ? monthName1Full
        : `${monthName1} - ${monthName2}`;

    return `${monthLabel} ${year}`;
  };

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const label = formatDateLabel(dayjs(date.getDate()));

  return (
    <div>
      <DatePicker
        open={isMobile ? isOpen : undefined}
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          padding: 0,
        }}
        className={styles.weekPicker}
        picker="week"
        allowClear={false}
        format={formatDateLabel}
        onChange={updateSelectedDate}
        value={dayjs(date.getDate())}
        suffixIcon={null}
        size="large"
        components={{
          input: forwardRef((props, ref) => (
            <div
              className={styles.container}
              onClick={toggleOpen ? toggleOpen : undefined}
            >
              {/* A bullshit input element which needs to be here just to prevent warning */}
              <input ref={ref} style={{ display: "none" }} />
              <span className={styles.label}>{label}</span>
              <button className={styles.calendarToggleButton}>
                <FontAwesomeIcon
                  className={styles.calendarToggleButtonChev}
                  icon={faChevronDown}
                />
              </button>
            </div>
          )),
        }}
        panelRender={(props) => {
          const { key, ...componentProps } = props;
          return (
            <WeekSelectorPanel
              key={key}
              isMobile={isMobile}
              toggleOpen={isMobile ? toggleOpen : null}
              {...componentProps}
            />
          );
        }}
        popupStyle={
          isMobile
            ? {
                position: "fixed",
                top: 0,
                left: 0,
              }
            : {}
        }
      />
    </div>
  );
};

export default EmployeeAppWeekSwitcher;
