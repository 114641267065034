import { useQueryClient } from "@tanstack/react-query";
import { useDataMutation } from "./useDataMutation";
import { useCallback } from "react";
import { useUserStore } from "../../globalStore/appStore";
import { getUserAttributes } from "../../features/auth/service/auth";
import {
  fetchUserSettingsVersion,
  updateSettingsModel,
} from "../../utils/queryUtils/appQuery";

const ACTION_TYPES = Object.freeze({
  updateSettings: "update-settings",
});

export function useSettingsModelMutation() {
  const { user } = useUserStore();

  const owner = user
    ? `${getUserAttributes(user).sub}::${getUserAttributes(user).username}`
    : null;

  const queryClient = useQueryClient();

  const mutation = useDataMutation({
    queryKey: ["settings", owner],
    queryClient,
    mutationFn: async (mutateFnParam) => {
      const actionType = mutateFnParam.actionType;
      const updatedSettings = mutateFnParam.updatedSettings;
      switch (actionType) {
        case ACTION_TYPES.updateSettings:
          return updateSettingsModel(
            updatedSettings.id,
            { areaFilters: updatedSettings.areaFilters },
            updatedSettings._version
          );
      }
    },
    getExpectedResultingData: (mutateFnParam) => {
      return mutateFnParam.updatedSettings;
    },
  });

  const deleteAreaFilterInSettings = useCallback(
    async (locationID) => {
      const settingsModel = queryClient.getQueryData(["settings", owner]);

      if (settingsModel && !settingsModel.areaFilters) {
        return;
      }

      const locationHasAreaFilters = settingsModel.areaFilters.some(
        (areaFilter) => areaFilter.locationID === locationID
      );

      if (!locationHasAreaFilters) {
        return;
      }

      const updatedAreaFilters = settingsModel.areaFilters.filter(
        (areaFilter) => areaFilter.locationID !== locationID
      );

      const { _version } = await fetchUserSettingsVersion(user);

      const updatedSettings = {
        ...settingsModel,
        areaFilters: updatedAreaFilters,
        _version,
      };

      await mutation.mutateAsync({
        actionType: ACTION_TYPES.updateSettings,
        updatedSettings,
      });
    },
    [mutation, owner, queryClient, user]
  );

  const updateAreaFilterInSettings = useCallback(
    async (locationID, filteredAreas) => {
      const { _version } = await fetchUserSettingsVersion(user);
      const settingsModel = queryClient.getQueryData(["settings", owner]);

      const locationAreaFilter = { locationID, areas: filteredAreas };

      let updatedAreaFilters = settingsModel.areaFilters
        ? [...settingsModel.areaFilters]
        : [];

      const updatableLocationAreaFilterExists = updatedAreaFilters.some(
        (areaFilter) => areaFilter.locationID === locationID
      );

      if (updatableLocationAreaFilterExists) {
        updatedAreaFilters = updatedAreaFilters.map((areaFilter) => {
          if (areaFilter.locationID === locationID) {
            return locationAreaFilter;
          }
          return areaFilter;
        });
      } else {
        updatedAreaFilters = [...updatedAreaFilters, locationAreaFilter];
      }

      const updatedSettings = {
        ...settingsModel,
        areaFilters: updatedAreaFilters,
        _version,
      };

      await mutation.mutateAsync({
        actionType: ACTION_TYPES.updateSettings,
        updatedSettings,
      });
    },
    [mutation, owner, queryClient, user]
  );

  return {
    updateAreaFilterInSettings,
    deleteAreaFilterInSettings,
    isLoading: mutation.isLoading,
  };
}
