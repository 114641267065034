export const updateLocationAdmin = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateRosterNoSolutions = /* GraphQL */ `
  mutation UpdateRoster(
    $input: UpdateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    updateRoster(input: $input, condition: $condition) {
      id
      owner
      locationID
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
