import styles from "./SearchRuleBlock.module.css";

const SearchRuleBlock = (props) => (
  <div className={styles["search-rule-block"]}>
    <div className={styles["text-block-5"]}>{props.description}</div>
    <label className={`${styles["w-checkbox"]} ${styles["checkbox-field-2"]}`}>
      <input
        type="checkbox"
        id="checkbox"
        name="checkbox"
        data-name="Checkbox"
        className={`${styles["w-checkbox-input"]} ${styles.checkbox}`}
        checked={props.isSelected}
        onChange={() => props.toggleIsSelected()}
      />
      <span className={styles["checkbox-label"]}>Checkbox</span>
    </label>
  </div>
);

export default SearchRuleBlock;
