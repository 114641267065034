/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRoster = /* GraphQL */ `
  mutation CreateRoster(
    $input: CreateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    createRoster(input: $input, condition: $condition) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoster = /* GraphQL */ `
  mutation UpdateRoster(
    $input: UpdateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    updateRoster(input: $input, condition: $condition) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoster = /* GraphQL */ `
  mutation DeleteRoster(
    $input: DeleteRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    deleteRoster(input: $input, condition: $condition) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGlobalEmployee = /* GraphQL */ `
  mutation CreateGlobalEmployee(
    $input: CreateGlobalEmployeeInput!
    $condition: ModelGlobalEmployeeConditionInput
  ) {
    createGlobalEmployee(input: $input, condition: $condition) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGlobalEmployee = /* GraphQL */ `
  mutation UpdateGlobalEmployee(
    $input: UpdateGlobalEmployeeInput!
    $condition: ModelGlobalEmployeeConditionInput
  ) {
    updateGlobalEmployee(input: $input, condition: $condition) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGlobalEmployee = /* GraphQL */ `
  mutation DeleteGlobalEmployee(
    $input: DeleteGlobalEmployeeInput!
    $condition: ModelGlobalEmployeeConditionInput
  ) {
    deleteGlobalEmployee(input: $input, condition: $condition) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRosterSolution = /* GraphQL */ `
  mutation CreateRosterSolution(
    $input: CreateRosterSolutionInput!
    $condition: ModelRosterSolutionConditionInput
  ) {
    createRosterSolution(input: $input, condition: $condition) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRosterSolution = /* GraphQL */ `
  mutation UpdateRosterSolution(
    $input: UpdateRosterSolutionInput!
    $condition: ModelRosterSolutionConditionInput
  ) {
    updateRosterSolution(input: $input, condition: $condition) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRosterSolution = /* GraphQL */ `
  mutation DeleteRosterSolution(
    $input: DeleteRosterSolutionInput!
    $condition: ModelRosterSolutionConditionInput
  ) {
    deleteRosterSolution(input: $input, condition: $condition) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
