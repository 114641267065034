/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const asyncGenerateRoster = /* GraphQL */ `
  query AsyncGenerateRoster(
    $rosterJSON: AWSJSON!
    $oldSolutionJSON: AWSJSON
    $rosterID: ID!
    $solutionID: ID!
    $email: String!
    $name: String!
    $requestNotifications: Boolean!
    $solveSettings: [String]
    $context: AWSJSON
  ) {
    asyncGenerateRoster(
      rosterJSON: $rosterJSON
      oldSolutionJSON: $oldSolutionJSON
      rosterID: $rosterID
      solutionID: $solutionID
      email: $email
      name: $name
      requestNotifications: $requestNotifications
      solveSettings: $solveSettings
      context: $context
    )
  }
`;
export const checkRoster = /* GraphQL */ `
  query CheckRoster(
    $rosterJSON: AWSJSON!
    $solveSettings: [String]
    $checkType: String!
    $index: Int
    $shiftName: String
    $taskName: String
    $employeeName: String
    $context: AWSJSON
  ) {
    checkRoster(
      rosterJSON: $rosterJSON
      solveSettings: $solveSettings
      checkType: $checkType
      index: $index
      shiftName: $shiftName
      taskName: $taskName
      employeeName: $employeeName
      context: $context
    )
  }
`;
export const updateOpenShifts = /* GraphQL */ `
  query UpdateOpenShifts(
    $locationID: String!
    $employeeID: ID!
    $openShiftID: ID!
    $state: String!
    $context: AWSJSON
  ) {
    updateOpenShifts(
      locationID: $locationID
      employeeID: $employeeID
      openShiftID: $openShiftID
      state: $state
      context: $context
    )
  }
`;
export const manageSubscriptionSeats = /* GraphQL */ `
  query ManageSubscriptionSeats(
    $action: String
    $numberOfSeats: Int
    $context: AWSJSON
  ) {
    manageSubscriptionSeats(
      action: $action
      numberOfSeats: $numberOfSeats
      context: $context
    )
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      owner
      name
      plan
      defaultNumDays
      settings
      frontendSettings {
        name
        values
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Employees {
        items {
          id
          email
          owner
          name
          code
          locationID
          locationName
          ruleValues
          skills
          shifts
          Requests {
            id
            submittedBy
            submittedDate
            startDate
            finishDate
            request
            state
            employeeComment
            adminComment
          }
          Preferences {
            date
            allocation
          }
          PreferencesRecurring
          PublishedAllocations {
            date
            draftAllocation
            publishedAllocation
            isOpenShift
            note
          }
          DaysRecurring
          AllocationsRecurring
          TimeEntries {
            date
            approved
            startTime
            finishTime
            breakTime
          }
          FTE
          salary
          startDate
          finishDate
          registrations
          externalID
          kioskID
          coordinatorID
          adminID
          areas
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Rosters {
        items {
          id
          owner
          locationID
          snapshotStartDate
          updatedAt
          createdAt
          name
          numDays
          startDate
          Employees {
            id
            globalEmployeeID
            Days
            DaysRecurring
            Allocations
            AllocationsRecurring
            History
            RuleValues
            RosteredAllocations
            name
            skills
            shifts
            externalID
            areas
          }
          Demands {
            id
            skills
            tasks
            type
            values
            importance
            startTime
            finishTime
            shifts
            areas
            defaultPHValue
            conditionalGroup
          }
          Shifts {
            id
            name
            startTime
            finishTime
            fulfilsDemand
            autoAssigned
            skill
            adminUseOnly
            shortId
          }
          ShiftGroups {
            id
            name
            shifts
            inversed
            skills
            tasks
            skillsInversed
            adminUseOnly
            shortId
            areas
          }
          CustomRules {
            name
            template
          }
          RuleExceptions {
            exceptionName
            employeeID
            startDate
            finishDate
            ruleValues
          }
          Skills {
            id
            name
            description
            type
            shortId
          }
          Tasks {
            id
            name
            description
            includeGeneralStaffing
            skills
            shortId
            autoAssigned
          }
          TaskBlocks {
            id
            name
            tasks
            startTime
            finishTime
            depth
            cantCombine
            shifts
            shortId
          }
          Areas {
            id
            name
            shifts
            skills
            tasks
            autoAssigned
            shortId
          }
          Solutions {
            items {
              id
              owner
              updatedAt
              createdAt
              rosterID
              status
              percentageComplete
              employees {
                employeeID
                name
                skills
                brokenRules
                days
                softViolations {
                  ruleName
                  cost
                  day1
                  day2
                }
                brokenRuleCombos {
                  rules
                }
                possibleExplanation
              }
              demands {
                skills
                tasks
                shifts
                areas
                type
                values
                targets
                timeSpan
                importance
              }
              rules {
                ruleTemplate
                violations {
                  e
                  d1
                  d2
                  v
                }
              }
              rerosteredSolutionID
              comments
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          Statistics {
            employeeShiftCountsToShow
            employeeSkillCountsToShow
            employeeHoursToShow
            dayHoursToShow
            dayShiftCountsToShow
            daySkillCountsToShow
            dayShiftSkillCountsToShow {
              shift
              skill
            }
            dayShiftSkillToggleDisplayed {
              shift
              skill
            }
            leaveCountsToShow
            otherSettings
          }
          ColorCodes {
            shift
            color
          }
          isPublished
          isSnapshot
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      order
      ColorCodes {
        shift
        color
      }
      startDate
      isScheduleView
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      OpenShifts {
        id
        date
        shift
        skills
        task
        area
        number
        publishedNumber
        isPublished
        employeeStates {
          employeeID
          state
          isTicked
        }
      }
      completedOnboardingTasks
      shiftViewHiddenRows
      kioskID
      coordinatorID
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        plan
        defaultNumDays
        settings
        frontendSettings {
          name
          values
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Employees {
          items {
            id
            email
            owner
            name
            code
            locationID
            locationName
            ruleValues
            skills
            shifts
            Requests {
              id
              submittedBy
              submittedDate
              startDate
              finishDate
              request
              state
              employeeComment
              adminComment
            }
            Preferences {
              date
              allocation
            }
            PreferencesRecurring
            PublishedAllocations {
              date
              draftAllocation
              publishedAllocation
              isOpenShift
              note
            }
            DaysRecurring
            AllocationsRecurring
            TimeEntries {
              date
              approved
              startTime
              finishTime
              breakTime
            }
            FTE
            salary
            startDate
            finishDate
            registrations
            externalID
            kioskID
            coordinatorID
            adminID
            areas
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Rosters {
          items {
            id
            owner
            locationID
            snapshotStartDate
            updatedAt
            createdAt
            name
            numDays
            startDate
            Employees {
              id
              globalEmployeeID
              Days
              DaysRecurring
              Allocations
              AllocationsRecurring
              History
              RuleValues
              RosteredAllocations
              name
              skills
              shifts
              externalID
              areas
            }
            Demands {
              id
              skills
              tasks
              type
              values
              importance
              startTime
              finishTime
              shifts
              areas
              defaultPHValue
              conditionalGroup
            }
            Shifts {
              id
              name
              startTime
              finishTime
              fulfilsDemand
              autoAssigned
              skill
              adminUseOnly
              shortId
            }
            ShiftGroups {
              id
              name
              shifts
              inversed
              skills
              tasks
              skillsInversed
              adminUseOnly
              shortId
              areas
            }
            CustomRules {
              name
              template
            }
            RuleExceptions {
              exceptionName
              employeeID
              startDate
              finishDate
              ruleValues
            }
            Skills {
              id
              name
              description
              type
              shortId
            }
            Tasks {
              id
              name
              description
              includeGeneralStaffing
              skills
              shortId
              autoAssigned
            }
            TaskBlocks {
              id
              name
              tasks
              startTime
              finishTime
              depth
              cantCombine
              shifts
              shortId
            }
            Areas {
              id
              name
              shifts
              skills
              tasks
              autoAssigned
              shortId
            }
            Solutions {
              items {
                id
                owner
                updatedAt
                createdAt
                rosterID
                status
                percentageComplete
                employees {
                  employeeID
                  name
                  skills
                  brokenRules
                  days
                  possibleExplanation
                }
                demands {
                  skills
                  tasks
                  shifts
                  areas
                  type
                  values
                  targets
                  timeSpan
                  importance
                }
                rules {
                  ruleTemplate
                }
                rerosteredSolutionID
                comments
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            Statistics {
              employeeShiftCountsToShow
              employeeSkillCountsToShow
              employeeHoursToShow
              dayHoursToShow
              dayShiftCountsToShow
              daySkillCountsToShow
              dayShiftSkillCountsToShow {
                shift
                skill
              }
              dayShiftSkillToggleDisplayed {
                shift
                skill
              }
              leaveCountsToShow
              otherSettings
            }
            ColorCodes {
              shift
              color
            }
            isPublished
            isSnapshot
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        order
        ColorCodes {
          shift
          color
        }
        startDate
        isScheduleView
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        OpenShifts {
          id
          date
          shift
          skills
          task
          area
          number
          publishedNumber
          isPublished
          employeeStates {
            employeeID
            state
            isTicked
          }
        }
        completedOnboardingTasks
        shiftViewHiddenRows
        kioskID
        coordinatorID
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        plan
        defaultNumDays
        settings
        frontendSettings {
          name
          values
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Employees {
          items {
            id
            email
            owner
            name
            code
            locationID
            locationName
            ruleValues
            skills
            shifts
            Requests {
              id
              submittedBy
              submittedDate
              startDate
              finishDate
              request
              state
              employeeComment
              adminComment
            }
            Preferences {
              date
              allocation
            }
            PreferencesRecurring
            PublishedAllocations {
              date
              draftAllocation
              publishedAllocation
              isOpenShift
              note
            }
            DaysRecurring
            AllocationsRecurring
            TimeEntries {
              date
              approved
              startTime
              finishTime
              breakTime
            }
            FTE
            salary
            startDate
            finishDate
            registrations
            externalID
            kioskID
            coordinatorID
            adminID
            areas
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Rosters {
          items {
            id
            owner
            locationID
            snapshotStartDate
            updatedAt
            createdAt
            name
            numDays
            startDate
            Employees {
              id
              globalEmployeeID
              Days
              DaysRecurring
              Allocations
              AllocationsRecurring
              History
              RuleValues
              RosteredAllocations
              name
              skills
              shifts
              externalID
              areas
            }
            Demands {
              id
              skills
              tasks
              type
              values
              importance
              startTime
              finishTime
              shifts
              areas
              defaultPHValue
              conditionalGroup
            }
            Shifts {
              id
              name
              startTime
              finishTime
              fulfilsDemand
              autoAssigned
              skill
              adminUseOnly
              shortId
            }
            ShiftGroups {
              id
              name
              shifts
              inversed
              skills
              tasks
              skillsInversed
              adminUseOnly
              shortId
              areas
            }
            CustomRules {
              name
              template
            }
            RuleExceptions {
              exceptionName
              employeeID
              startDate
              finishDate
              ruleValues
            }
            Skills {
              id
              name
              description
              type
              shortId
            }
            Tasks {
              id
              name
              description
              includeGeneralStaffing
              skills
              shortId
              autoAssigned
            }
            TaskBlocks {
              id
              name
              tasks
              startTime
              finishTime
              depth
              cantCombine
              shifts
              shortId
            }
            Areas {
              id
              name
              shifts
              skills
              tasks
              autoAssigned
              shortId
            }
            Solutions {
              items {
                id
                owner
                updatedAt
                createdAt
                rosterID
                status
                percentageComplete
                employees {
                  employeeID
                  name
                  skills
                  brokenRules
                  days
                  possibleExplanation
                }
                demands {
                  skills
                  tasks
                  shifts
                  areas
                  type
                  values
                  targets
                  timeSpan
                  importance
                }
                rules {
                  ruleTemplate
                }
                rerosteredSolutionID
                comments
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            Statistics {
              employeeShiftCountsToShow
              employeeSkillCountsToShow
              employeeHoursToShow
              dayHoursToShow
              dayShiftCountsToShow
              daySkillCountsToShow
              dayShiftSkillCountsToShow {
                shift
                skill
              }
              dayShiftSkillToggleDisplayed {
                shift
                skill
              }
              leaveCountsToShow
              otherSettings
            }
            ColorCodes {
              shift
              color
            }
            isPublished
            isSnapshot
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        order
        ColorCodes {
          shift
          color
        }
        startDate
        isScheduleView
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        OpenShifts {
          id
          date
          shift
          skills
          task
          area
          number
          publishedNumber
          isPublished
          employeeStates {
            employeeID
            state
            isTicked
          }
        }
        completedOnboardingTasks
        shiftViewHiddenRows
        kioskID
        coordinatorID
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const locationByOwner = /* GraphQL */ `
  query LocationByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        plan
        defaultNumDays
        settings
        frontendSettings {
          name
          values
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Employees {
          items {
            id
            email
            owner
            name
            code
            locationID
            locationName
            ruleValues
            skills
            shifts
            Requests {
              id
              submittedBy
              submittedDate
              startDate
              finishDate
              request
              state
              employeeComment
              adminComment
            }
            Preferences {
              date
              allocation
            }
            PreferencesRecurring
            PublishedAllocations {
              date
              draftAllocation
              publishedAllocation
              isOpenShift
              note
            }
            DaysRecurring
            AllocationsRecurring
            TimeEntries {
              date
              approved
              startTime
              finishTime
              breakTime
            }
            FTE
            salary
            startDate
            finishDate
            registrations
            externalID
            kioskID
            coordinatorID
            adminID
            areas
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Rosters {
          items {
            id
            owner
            locationID
            snapshotStartDate
            updatedAt
            createdAt
            name
            numDays
            startDate
            Employees {
              id
              globalEmployeeID
              Days
              DaysRecurring
              Allocations
              AllocationsRecurring
              History
              RuleValues
              RosteredAllocations
              name
              skills
              shifts
              externalID
              areas
            }
            Demands {
              id
              skills
              tasks
              type
              values
              importance
              startTime
              finishTime
              shifts
              areas
              defaultPHValue
              conditionalGroup
            }
            Shifts {
              id
              name
              startTime
              finishTime
              fulfilsDemand
              autoAssigned
              skill
              adminUseOnly
              shortId
            }
            ShiftGroups {
              id
              name
              shifts
              inversed
              skills
              tasks
              skillsInversed
              adminUseOnly
              shortId
              areas
            }
            CustomRules {
              name
              template
            }
            RuleExceptions {
              exceptionName
              employeeID
              startDate
              finishDate
              ruleValues
            }
            Skills {
              id
              name
              description
              type
              shortId
            }
            Tasks {
              id
              name
              description
              includeGeneralStaffing
              skills
              shortId
              autoAssigned
            }
            TaskBlocks {
              id
              name
              tasks
              startTime
              finishTime
              depth
              cantCombine
              shifts
              shortId
            }
            Areas {
              id
              name
              shifts
              skills
              tasks
              autoAssigned
              shortId
            }
            Solutions {
              items {
                id
                owner
                updatedAt
                createdAt
                rosterID
                status
                percentageComplete
                employees {
                  employeeID
                  name
                  skills
                  brokenRules
                  days
                  possibleExplanation
                }
                demands {
                  skills
                  tasks
                  shifts
                  areas
                  type
                  values
                  targets
                  timeSpan
                  importance
                }
                rules {
                  ruleTemplate
                }
                rerosteredSolutionID
                comments
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            Statistics {
              employeeShiftCountsToShow
              employeeSkillCountsToShow
              employeeHoursToShow
              dayHoursToShow
              dayShiftCountsToShow
              daySkillCountsToShow
              dayShiftSkillCountsToShow {
                shift
                skill
              }
              dayShiftSkillToggleDisplayed {
                shift
                skill
              }
              leaveCountsToShow
              otherSettings
            }
            ColorCodes {
              shift
              color
            }
            isPublished
            isSnapshot
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        order
        ColorCodes {
          shift
          color
        }
        startDate
        isScheduleView
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        OpenShifts {
          id
          date
          shift
          skills
          task
          area
          number
          publishedNumber
          isPublished
          employeeStates {
            employeeID
            state
            isTicked
          }
        }
        completedOnboardingTasks
        shiftViewHiddenRows
        kioskID
        coordinatorID
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRoster = /* GraphQL */ `
  query GetRoster($id: ID!) {
    getRoster(id: $id) {
      id
      owner
      locationID
      snapshotStartDate
      updatedAt
      createdAt
      name
      numDays
      startDate
      Employees {
        id
        globalEmployeeID
        Days
        DaysRecurring
        Allocations
        AllocationsRecurring
        History
        RuleValues
        RosteredAllocations
        name
        skills
        shifts
        externalID
        areas
      }
      Demands {
        id
        skills
        tasks
        type
        values
        importance
        startTime
        finishTime
        shifts
        areas
        defaultPHValue
        conditionalGroup
      }
      Shifts {
        id
        name
        startTime
        finishTime
        fulfilsDemand
        autoAssigned
        skill
        adminUseOnly
        shortId
      }
      ShiftGroups {
        id
        name
        shifts
        inversed
        skills
        tasks
        skillsInversed
        adminUseOnly
        shortId
        areas
      }
      CustomRules {
        name
        template
      }
      RuleExceptions {
        exceptionName
        employeeID
        startDate
        finishDate
        ruleValues
      }
      Skills {
        id
        name
        description
        type
        shortId
      }
      Tasks {
        id
        name
        description
        includeGeneralStaffing
        skills
        shortId
        autoAssigned
      }
      TaskBlocks {
        id
        name
        tasks
        startTime
        finishTime
        depth
        cantCombine
        shifts
        shortId
      }
      Areas {
        id
        name
        shifts
        skills
        tasks
        autoAssigned
        shortId
      }
      Solutions {
        items {
          id
          owner
          updatedAt
          createdAt
          rosterID
          status
          percentageComplete
          employees {
            employeeID
            name
            skills
            brokenRules
            days
            softViolations {
              ruleName
              cost
              day1
              day2
            }
            brokenRuleCombos {
              rules
            }
            possibleExplanation
          }
          demands {
            skills
            tasks
            shifts
            areas
            type
            values
            targets
            timeSpan
            importance
          }
          rules {
            ruleTemplate
            violations {
              e
              d1
              d2
              v
            }
          }
          rerosteredSolutionID
          comments
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Statistics {
        employeeShiftCountsToShow
        employeeSkillCountsToShow
        employeeHoursToShow
        dayHoursToShow
        dayShiftCountsToShow
        daySkillCountsToShow
        dayShiftSkillCountsToShow {
          shift
          skill
        }
        dayShiftSkillToggleDisplayed {
          shift
          skill
        }
        leaveCountsToShow
        otherSettings
      }
      ColorCodes {
        shift
        color
      }
      isPublished
      isSnapshot
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRosters = /* GraphQL */ `
  query ListRosters(
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        RuleExceptions {
          exceptionName
          employeeID
          startDate
          finishDate
          ruleValues
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Solutions {
          items {
            id
            owner
            updatedAt
            createdAt
            rosterID
            status
            percentageComplete
            employees {
              employeeID
              name
              skills
              brokenRules
              days
              softViolations {
                ruleName
                cost
                day1
                day2
              }
              brokenRuleCombos {
                rules
              }
              possibleExplanation
            }
            demands {
              skills
              tasks
              shifts
              areas
              type
              values
              targets
              timeSpan
              importance
            }
            rules {
              ruleTemplate
              violations {
                e
                d1
                d2
                v
              }
            }
            rerosteredSolutionID
            comments
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        ColorCodes {
          shift
          color
        }
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRosters = /* GraphQL */ `
  query SyncRosters(
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRosters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        RuleExceptions {
          exceptionName
          employeeID
          startDate
          finishDate
          ruleValues
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Solutions {
          items {
            id
            owner
            updatedAt
            createdAt
            rosterID
            status
            percentageComplete
            employees {
              employeeID
              name
              skills
              brokenRules
              days
              softViolations {
                ruleName
                cost
                day1
                day2
              }
              brokenRuleCombos {
                rules
              }
              possibleExplanation
            }
            demands {
              skills
              tasks
              shifts
              areas
              type
              values
              targets
              timeSpan
              importance
            }
            rules {
              ruleTemplate
              violations {
                e
                d1
                d2
                v
              }
            }
            rerosteredSolutionID
            comments
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        ColorCodes {
          shift
          color
        }
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const rosterByOwner = /* GraphQL */ `
  query RosterByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        RuleExceptions {
          exceptionName
          employeeID
          startDate
          finishDate
          ruleValues
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Solutions {
          items {
            id
            owner
            updatedAt
            createdAt
            rosterID
            status
            percentageComplete
            employees {
              employeeID
              name
              skills
              brokenRules
              days
              softViolations {
                ruleName
                cost
                day1
                day2
              }
              brokenRuleCombos {
                rules
              }
              possibleExplanation
            }
            demands {
              skills
              tasks
              shifts
              areas
              type
              values
              targets
              timeSpan
              importance
            }
            rules {
              ruleTemplate
              violations {
                e
                d1
                d2
                v
              }
            }
            rerosteredSolutionID
            comments
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        ColorCodes {
          shift
          color
        }
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const rosterByLocation = /* GraphQL */ `
  query RosterByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        RuleExceptions {
          exceptionName
          employeeID
          startDate
          finishDate
          ruleValues
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Solutions {
          items {
            id
            owner
            updatedAt
            createdAt
            rosterID
            status
            percentageComplete
            employees {
              employeeID
              name
              skills
              brokenRules
              days
              softViolations {
                ruleName
                cost
                day1
                day2
              }
              brokenRuleCombos {
                rules
              }
              possibleExplanation
            }
            demands {
              skills
              tasks
              shifts
              areas
              type
              values
              targets
              timeSpan
              importance
            }
            rules {
              ruleTemplate
              violations {
                e
                d1
                d2
                v
              }
            }
            rerosteredSolutionID
            comments
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        ColorCodes {
          shift
          color
        }
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const rosterByLocationAndSnapshotStartDate = /* GraphQL */ `
  query RosterByLocationAndSnapshotStartDate(
    $locationID: ID!
    $snapshotStartDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterByLocationAndSnapshotStartDate(
      locationID: $locationID
      snapshotStartDate: $snapshotStartDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        locationID
        snapshotStartDate
        updatedAt
        createdAt
        name
        numDays
        startDate
        Employees {
          id
          globalEmployeeID
          Days
          DaysRecurring
          Allocations
          AllocationsRecurring
          History
          RuleValues
          RosteredAllocations
          name
          skills
          shifts
          externalID
          areas
        }
        Demands {
          id
          skills
          tasks
          type
          values
          importance
          startTime
          finishTime
          shifts
          areas
          defaultPHValue
          conditionalGroup
        }
        Shifts {
          id
          name
          startTime
          finishTime
          fulfilsDemand
          autoAssigned
          skill
          adminUseOnly
          shortId
        }
        ShiftGroups {
          id
          name
          shifts
          inversed
          skills
          tasks
          skillsInversed
          adminUseOnly
          shortId
          areas
        }
        CustomRules {
          name
          template
        }
        RuleExceptions {
          exceptionName
          employeeID
          startDate
          finishDate
          ruleValues
        }
        Skills {
          id
          name
          description
          type
          shortId
        }
        Tasks {
          id
          name
          description
          includeGeneralStaffing
          skills
          shortId
          autoAssigned
        }
        TaskBlocks {
          id
          name
          tasks
          startTime
          finishTime
          depth
          cantCombine
          shifts
          shortId
        }
        Areas {
          id
          name
          shifts
          skills
          tasks
          autoAssigned
          shortId
        }
        Solutions {
          items {
            id
            owner
            updatedAt
            createdAt
            rosterID
            status
            percentageComplete
            employees {
              employeeID
              name
              skills
              brokenRules
              days
              softViolations {
                ruleName
                cost
                day1
                day2
              }
              brokenRuleCombos {
                rules
              }
              possibleExplanation
            }
            demands {
              skills
              tasks
              shifts
              areas
              type
              values
              targets
              timeSpan
              importance
            }
            rules {
              ruleTemplate
              violations {
                e
                d1
                d2
                v
              }
            }
            rerosteredSolutionID
            comments
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Statistics {
          employeeShiftCountsToShow
          employeeSkillCountsToShow
          employeeHoursToShow
          dayHoursToShow
          dayShiftCountsToShow
          daySkillCountsToShow
          dayShiftSkillCountsToShow {
            shift
            skill
          }
          dayShiftSkillToggleDisplayed {
            shift
            skill
          }
          leaveCountsToShow
          otherSettings
        }
        ColorCodes {
          shift
          color
        }
        isPublished
        isSnapshot
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGlobalEmployee = /* GraphQL */ `
  query GetGlobalEmployee($id: ID!) {
    getGlobalEmployee(id: $id) {
      id
      email
      owner
      name
      code
      locationID
      locationName
      ruleValues
      skills
      shifts
      Requests {
        id
        submittedBy
        submittedDate
        startDate
        finishDate
        request
        state
        employeeComment
        adminComment
      }
      Preferences {
        date
        allocation
      }
      PreferencesRecurring
      PublishedAllocations {
        date
        draftAllocation
        publishedAllocation
        isOpenShift
        note
      }
      DaysRecurring
      AllocationsRecurring
      TimeEntries {
        date
        approved
        startTime
        finishTime
        breakTime
      }
      FTE
      salary
      startDate
      finishDate
      registrations
      externalID
      kioskID
      coordinatorID
      adminID
      areas
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGlobalEmployees = /* GraphQL */ `
  query ListGlobalEmployees(
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        owner
        name
        code
        locationID
        locationName
        ruleValues
        skills
        shifts
        Requests {
          id
          submittedBy
          submittedDate
          startDate
          finishDate
          request
          state
          employeeComment
          adminComment
        }
        Preferences {
          date
          allocation
        }
        PreferencesRecurring
        PublishedAllocations {
          date
          draftAllocation
          publishedAllocation
          isOpenShift
          note
        }
        DaysRecurring
        AllocationsRecurring
        TimeEntries {
          date
          approved
          startTime
          finishTime
          breakTime
        }
        FTE
        salary
        startDate
        finishDate
        registrations
        externalID
        kioskID
        coordinatorID
        adminID
        areas
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGlobalEmployees = /* GraphQL */ `
  query SyncGlobalEmployees(
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGlobalEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        owner
        name
        code
        locationID
        locationName
        ruleValues
        skills
        shifts
        Requests {
          id
          submittedBy
          submittedDate
          startDate
          finishDate
          request
          state
          employeeComment
          adminComment
        }
        Preferences {
          date
          allocation
        }
        PreferencesRecurring
        PublishedAllocations {
          date
          draftAllocation
          publishedAllocation
          isOpenShift
          note
        }
        DaysRecurring
        AllocationsRecurring
        TimeEntries {
          date
          approved
          startTime
          finishTime
          breakTime
        }
        FTE
        salary
        startDate
        finishDate
        registrations
        externalID
        kioskID
        coordinatorID
        adminID
        areas
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const globalEmployeeByOwner = /* GraphQL */ `
  query GlobalEmployeeByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalEmployeeByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        owner
        name
        code
        locationID
        locationName
        ruleValues
        skills
        shifts
        Requests {
          id
          submittedBy
          submittedDate
          startDate
          finishDate
          request
          state
          employeeComment
          adminComment
        }
        Preferences {
          date
          allocation
        }
        PreferencesRecurring
        PublishedAllocations {
          date
          draftAllocation
          publishedAllocation
          isOpenShift
          note
        }
        DaysRecurring
        AllocationsRecurring
        TimeEntries {
          date
          approved
          startTime
          finishTime
          breakTime
        }
        FTE
        salary
        startDate
        finishDate
        registrations
        externalID
        kioskID
        coordinatorID
        adminID
        areas
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const globalEmployeeByLocation = /* GraphQL */ `
  query GlobalEmployeeByLocation(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalEmployeeByLocation(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        owner
        name
        code
        locationID
        locationName
        ruleValues
        skills
        shifts
        Requests {
          id
          submittedBy
          submittedDate
          startDate
          finishDate
          request
          state
          employeeComment
          adminComment
        }
        Preferences {
          date
          allocation
        }
        PreferencesRecurring
        PublishedAllocations {
          date
          draftAllocation
          publishedAllocation
          isOpenShift
          note
        }
        DaysRecurring
        AllocationsRecurring
        TimeEntries {
          date
          approved
          startTime
          finishTime
          breakTime
        }
        FTE
        salary
        startDate
        finishDate
        registrations
        externalID
        kioskID
        coordinatorID
        adminID
        areas
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRosterSolution = /* GraphQL */ `
  query GetRosterSolution($id: ID!) {
    getRosterSolution(id: $id) {
      id
      owner
      updatedAt
      createdAt
      rosterID
      status
      percentageComplete
      employees {
        employeeID
        name
        skills
        brokenRules
        days
        softViolations {
          ruleName
          cost
          day1
          day2
        }
        brokenRuleCombos {
          rules
        }
        possibleExplanation
      }
      demands {
        skills
        tasks
        shifts
        areas
        type
        values
        targets
        timeSpan
        importance
      }
      rules {
        ruleTemplate
        violations {
          e
          d1
          d2
          v
        }
      }
      rerosteredSolutionID
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRosterSolutions = /* GraphQL */ `
  query ListRosterSolutions(
    $filter: ModelRosterSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        updatedAt
        createdAt
        rosterID
        status
        percentageComplete
        employees {
          employeeID
          name
          skills
          brokenRules
          days
          softViolations {
            ruleName
            cost
            day1
            day2
          }
          brokenRuleCombos {
            rules
          }
          possibleExplanation
        }
        demands {
          skills
          tasks
          shifts
          areas
          type
          values
          targets
          timeSpan
          importance
        }
        rules {
          ruleTemplate
          violations {
            e
            d1
            d2
            v
          }
        }
        rerosteredSolutionID
        comments
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRosterSolutions = /* GraphQL */ `
  query SyncRosterSolutions(
    $filter: ModelRosterSolutionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRosterSolutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedAt
        createdAt
        rosterID
        status
        percentageComplete
        employees {
          employeeID
          name
          skills
          brokenRules
          days
          softViolations {
            ruleName
            cost
            day1
            day2
          }
          brokenRuleCombos {
            rules
          }
          possibleExplanation
        }
        demands {
          skills
          tasks
          shifts
          areas
          type
          values
          targets
          timeSpan
          importance
        }
        rules {
          ruleTemplate
          violations {
            e
            d1
            d2
            v
          }
        }
        rerosteredSolutionID
        comments
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const rosterSolutionByOwner = /* GraphQL */ `
  query RosterSolutionByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterSolutionByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedAt
        createdAt
        rosterID
        status
        percentageComplete
        employees {
          employeeID
          name
          skills
          brokenRules
          days
          softViolations {
            ruleName
            cost
            day1
            day2
          }
          brokenRuleCombos {
            rules
          }
          possibleExplanation
        }
        demands {
          skills
          tasks
          shifts
          areas
          type
          values
          targets
          timeSpan
          importance
        }
        rules {
          ruleTemplate
          violations {
            e
            d1
            d2
            v
          }
        }
        rerosteredSolutionID
        comments
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const rosterSolutionByRoster = /* GraphQL */ `
  query RosterSolutionByRoster(
    $rosterID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRosterSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterSolutionByRoster(
      rosterID: $rosterID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedAt
        createdAt
        rosterID
        status
        percentageComplete
        employees {
          employeeID
          name
          skills
          brokenRules
          days
          softViolations {
            ruleName
            cost
            day1
            day2
          }
          brokenRuleCombos {
            rules
          }
          possibleExplanation
        }
        demands {
          skills
          tasks
          shifts
          areas
          type
          values
          targets
          timeSpan
          importance
        }
        rules {
          ruleTemplate
          violations {
            e
            d1
            d2
            v
          }
        }
        rerosteredSolutionID
        comments
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      owner
      requestsNotifications
      numSeats
      maxSeats
      trialEnd
      plan
      areaFilters {
        locationID
        areas
      }
      adminID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        requestsNotifications
        numSeats
        maxSeats
        trialEnd
        plan
        areaFilters {
          locationID
          areas
        }
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        requestsNotifications
        numSeats
        maxSeats
        trialEnd
        plan
        areaFilters {
          locationID
          areas
        }
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const settingsByOwner = /* GraphQL */ `
  query SettingsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        requestsNotifications
        numSeats
        maxSeats
        trialEnd
        plan
        areaFilters {
          locationID
          areas
        }
        adminID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notifyUsers = /* GraphQL */ `
  query NotifyUsers(
    $notifications: [Notification]
    $title: String
    $page: String
    $locationID: ID
    $date: AWSDate
    $context: AWSJSON
  ) {
    notifyUsers(
      notifications: $notifications
      title: $title
      page: $page
      locationID: $locationID
      date: $date
      context: $context
    )
  }
`;
