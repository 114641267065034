import { useEffect, useMemo } from "react";
import styles from "./GenerateLoadingBar.module.css";

const sentences = [
  "Our rostering A.I. works from home Mondays, Wednesdays, and Fridays 😛",
  "Searching for hidden rosters in the Caribbean 🏴‍☠️",
  "Handing out typewriters to our rostering monkeys 🐒",
  "Our rostering super computer is powered by 15,387 gerbils 🐹",
  "Our rostering A.I. could beat you at battleship ⚔️",
  "After making this roster, our A.I. will be rewarded with a big box of ice cream 🍦",
  "For optimal rosters, please tell RosterLab all the juicy office gossip 🍿",
  "Studies show that clicking angrily will make the roster generate 2.7% faster 💨",
  "No giraffes were harmed in the making of this roster 🦒",
];

const getRandomSentence = () => {
  return sentences[Math.floor(Math.random() * sentences.length)];
};

const GenerateLoadingBar = ({ percentage, handleClose }) => {
  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        handleClose();
      }, 1500);
    }
  }, [percentage, handleClose]);

  const randomSentence = useMemo(() => getRandomSentence(), []);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.container}>
        <span className={styles.title}>Generating Rosters For You...</span>
        <div className={styles.loadingBarContainer}>
          <div className={styles.loadingBar}>
            <div
              className={styles.loadingBarFill}
              style={{
                width: `${percentage}%`,
              }}
            >
              <div className={styles.loadingBarAnimation}></div>
            </div>
          </div>
          <span className={styles.percentage}>{percentage}%</span>
        </div>
        <p className={styles.description}>{randomSentence}</p>
        <div className={styles.btnContainer}>
          <button
            className={styles.closeBtn}
            onClick={handleClose}
            data-testid="dismiss-generate-loading-btn"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateLoadingBar;
