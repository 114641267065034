import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styles from "./PrintableSolution.module.css";
import logo from "../../../../../assets/images/rosterlab-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { getColorCode } from "../../../../colorCoding/service/colorCodeGetter";
import { DateTime } from "../../../../../utils";
import { isDark } from "../../../rosteredAllocations/service/styleGetters";
import { KEYWORD_NA } from "../../../../../constants/keywords";
import { convertAllocationInNameFormToShortIdForm } from "../../../../../utils/modelUtils/allocation";

export const calendarDataToPrintable = (calendarData, numDays) => {
  const printableData = [];

  calendarData.forEach((emp) => {
    const newEmpData = [emp.name];
    for (let d = 1; d <= numDays; d++) {
      const day = emp["d" + d.toString()];
      newEmpData.push(day ? day.allocation : "");
    }
    printableData.push(newEmpData);
  });

  return printableData;
};

const shorten = (output, leaveKeywords) => {
  for (let l = 0; l < leaveKeywords.length; l++) {
    if (output.startsWith(leaveKeywords[l])) {
      return leaveKeywords[l];
    }
  }

  if (output.startsWith(KEYWORD_NA)) {
    return "";
  }
  if (output === "-") {
    return "";
  }

  return output;
};

const RosterContentTable = ({
  solutionData,
  startDay,
  finishDay,
  startDate,
  nameWidth,
  dayWidth,
  colorCodes,
  shiftGroups,
  leaveKeywords,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
}) => {
  const dates = [];
  for (let i = startDay; i < finishDay; i++) {
    dates.push(new DateTime(startDate).addDays(i).date);
  }

  return (
    <table className={styles.rosterTable}>
      <tbody>
        <tr>
          <th style={{ width: nameWidth }}></th>
          {dates.map((date, idx) => {
            const dayOfWeek = date.getDay();
            const dayClass =
              dayOfWeek === 6
                ? styles.saturday
                : dayOfWeek === 0
                ? styles.sunday
                : "";

            return (
              <th
                key={"date" + idx}
                className={dayClass}
                style={{ width: dayWidth }}
              >
                {new DateTime(date).toFormat("displayed-short")}
              </th>
            );
          })}
        </tr>
        <tr>
          <th style={{ width: nameWidth }}>Name</th>
          {dates.map((date, idx) => {
            const dayOfWeek = date.getDay();
            const dayClass =
              dayOfWeek === 6
                ? styles.saturday
                : dayOfWeek === 0
                ? styles.sunday
                : "";

            return (
              <th
                key={"day of week" + idx}
                className={dayClass}
                style={{ width: dayWidth }}
              >
                {new DateTime(date).getDayOfWeek("ddd")}
              </th>
            );
          })}
        </tr>
        {solutionData.map((employee, idx) => (
          <tr key={"employee" + idx}>
            {employee.map((cell, cellIdx) => {
              let output = cell;
              output = shorten(output, leaveKeywords);
              if (cellIdx === 0) {
                return (
                  <td key={idx + "_" + cellIdx} style={{ width: nameWidth }}>
                    {output}
                  </td>
                );
              } else if (cellIdx >= startDay + 1 && cellIdx < finishDay + 1) {
                const dayOfWeek = new DateTime(startDate)
                  .addDays(cellIdx - 1)
                  .getDayOfWeek("number");
                const dayClass =
                  dayOfWeek === 6
                    ? styles.saturday
                    : dayOfWeek === 0
                    ? styles.sunday
                    : "";

                const cellProps = {
                  key: idx + "_" + cellIdx,
                  className: dayClass,
                  style: { width: dayWidth },
                };

                const outputInShortIdForm =
                  convertAllocationInNameFormToShortIdForm(
                    output,
                    namesToEntityShortIdsDicts
                  );

                const colorCode = getColorCode(
                  colorCodes,
                  outputInShortIdForm,
                  shiftGroups,
                  shortIdsToEntityNamesDicts,
                  customKeywordsUtilObj
                );

                return (
                  <td
                    {...cellProps}
                    key={cellIdx}
                    style={{
                      ...(colorCode && { backgroundColor: colorCode }),
                      ...(colorCode && {
                        color: isDark(colorCode) ? "white" : "black",
                      }),
                    }}
                  >
                    {output}
                  </td>
                );
              } else {
                return null;
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const PrintableSolutionTable = ({
  solutionData,
  startDate,
  rosterName,
  numDays,
  size,
  colorCodes,
  shiftGroups,
  leaveKeywords,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
}) => {
  const maxEmployeesPerPage = size === "A3" ? 28 : 20;
  const totalPagePixelWidth = size === "A3" ? 260 : 145;

  const numEmployeePages = solutionData.length / maxEmployeesPerPage;

  const nameWidth = Math.max(...solutionData.map((emp) => emp[0].length)) + 1;
  const minDayWidth = 3;

  const dayWidth =
    Math.max(
      ...solutionData.map((emp) =>
        Math.max(
          ...emp.slice(1).map((day) => shorten(day, leaveKeywords).length)
        )
      ),
      minDayWidth
    ) + 1;
  const numPixelsPerWeek = dayWidth * 10;

  const maxWeeksPerPage = Math.max(
    1,
    Math.floor((totalPagePixelWidth - nameWidth) / numPixelsPerWeek)
  );
  const numWeeksPages = Math.ceil(numDays / 7 / maxWeeksPerPage);

  const weekSets = [];
  const approxWeeksPerPage = Math.ceil(numDays / 7 / numWeeksPages);
  for (let i = 0; i < numWeeksPages; i++) {
    weekSets.push([
      i * approxWeeksPerPage,
      Math.min(numDays / 7, (i + 1) * approxWeeksPerPage),
    ]);
  }

  const pages = [];
  for (let i = 0; i < numEmployeePages; i++) {
    weekSets.forEach((weekSet, weekIdx) => {
      pages.push(
        <section
          className={styles.A4sheet}
          key={"section" + i + "week" + weekIdx}
        >
          <img src={logo} alt="Rosterlab logo" width={"200px"} />
          <h1>
            {rosterName} - Week {Math.floor(weekSet[0]) + 1}
            {weekSet[0] + 1 === weekSet[1]
              ? ""
              : " to " + Math.ceil(weekSet[1])}
          </h1>
          <RosterContentTable
            solutionData={solutionData.slice(
              i * maxEmployeesPerPage,
              (i + 1) * maxEmployeesPerPage
            )}
            startDay={weekSet[0] * 7}
            finishDay={weekSet[1] * 7}
            startDate={startDate}
            nameWidth={nameWidth + "ch"}
            dayWidth={dayWidth + "ch"}
            colorCodes={colorCodes}
            shiftGroups={shiftGroups}
            leaveKeywords={leaveKeywords}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        </section>
      );
    });
  }

  return pages;
};

const ReactPrintableSolution = React.forwardRef(
  (
    {
      solutionData,
      startDate,
      rosterName,
      numDays,
      size,
      colorCodes,
      shiftGroups,
      leaveKeywords,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
      namesToEntityShortIdsDicts,
    },
    ref
  ) => {
    return (
      <div ref={ref} className={styles.solutionWrapper}>
        <PrintableSolutionTable
          solutionData={solutionData}
          startDate={startDate}
          rosterName={rosterName}
          numDays={numDays}
          size={size}
          colorCodes={colorCodes}
          shiftGroups={shiftGroups}
          leaveKeywords={leaveKeywords}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          customKeywordsUtilObj={customKeywordsUtilObj}
          namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
        />
      </div>
    );
  }
);

export const PrintableSolutionButton = ({
  solutionData,
  startDate,
  rosterName,
  numDays,
  size, //Either A4 or A3
  shiftGroups,
  leaveKeywords,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  colorCodes,
  namesToEntityShortIdsDicts,
}) => {
  const componentRef = useRef();

  if (!size) {
    size = "A4";
  }

  return (
    <>
      <div>
        <ReactToPrint
          pageStyle={`@page {size: ${size} landscape;}`}
          trigger={() => (
            <button className={styles.printButton}>
              <FontAwesomeIcon className={styles.printIcon} icon={faPrint} />
              Print ({size})
            </button>
          )}
          content={() => componentRef.current}
          documentTitle={`${rosterName}`}
        />
        <ReactPrintableSolution
          solutionData={solutionData}
          startDate={startDate}
          rosterName={rosterName}
          numDays={numDays}
          size={size}
          ref={componentRef}
          colorCodes={colorCodes}
          shiftGroups={shiftGroups}
          leaveKeywords={leaveKeywords}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          customKeywordsUtilObj={customKeywordsUtilObj}
          namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
        />
      </div>
    </>
  );
};

export default PrintableSolutionButton;
