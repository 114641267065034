import create from "zustand";

export const DEFAULT_COL_WIDTH = 70;
export const MIN_COL_WIDTH = 40;
export const MAX_COL_WIDTH = 220;

const initialiseColumnWithPresetWidth = (
  colSettingName,
  frontendSettings,
  set
) => {
  const initialWidths = frontendSettings.find((setting) => {
    if (setting.name === colSettingName) {
      return true;
    }
    return false;
  });
  if (initialWidths) {
    set({ weekdayColWidth: parseInt(initialWidths.values[0]) });
    set({ weekendColWidth: parseInt(initialWidths.values[1]) });
  }
};

export const useSolutionsColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },

  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth(
      "solutionsColWidths",
      frontendSettings,
      set
    );
  },
}));

export const useHistoryColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth("historyColWidths", frontendSettings, set);
  },
}));

export const useLiveCalendarColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
}));

export const useFixedShiftsColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth(
      "fixedShiftsColWidths",
      frontendSettings,
      set
    );
  },
}));

export const usePreferencesColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth(
      "preferencesColWidths",
      frontendSettings,
      set
    );
  },
}));

export const useGlobalPreferencesColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth(
      "globalPreferencesColWidths",
      frontendSettings,
      set
    );
  },
}));

export const useGlobalFixedShiftsColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth(
      "globalFixedShiftsColWidths",
      frontendSettings,
      set
    );
  },
}));

export const useCalendarColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth("calendarColWidths", frontendSettings, set);
  },
}));

export const useRosterAllocationsColumnWidthStore = create((set) => ({
  weekdayColWidth: DEFAULT_COL_WIDTH,
  weekendColWidth: DEFAULT_COL_WIDTH,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth("rosterColWidths", frontendSettings, set);
  },
}));

export const useRulesColumnWidthStore = create((set) => ({
  weekdayColWidth: 200,
  weekendColWidth: 200,
  isWDayWEndSeparate: false,
  setWeekdayColWidth: (pixel) => {
    set({ weekdayColWidth: pixel });
  },
  setWeekendColWidth: (pixel) => {
    set({ weekendColWidth: pixel });
  },
  setIsWDayWEndSeparate: (bool) => {
    set({ isWDayWEndSeparate: bool });
  },
  initialiseWithPresetWidth: (frontendSettings) => {
    initialiseColumnWithPresetWidth("rulesColWidths", frontendSettings, set);
  },
}));
