import styles from "./AggridCheckbox.module.css";

const Checkbox = (props) => {
  const checkedHandler = (event) => {
    let checked = event.target.checked;
    let colId = props.column.colId;
    props.node.setDataValue(colId, checked);
  };

  return (
    <div className={styles.cellContainer}>
      <input
        type="checkbox"
        onChange={() => {}}
        onClick={checkedHandler}
        checked={props.value === "true" || props.value === true}
      />
    </div>
  );
};

export default Checkbox;
