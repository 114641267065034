import { convertToTitleCase, DateTime } from "../../../../../utils";
import { canAccessSurvey } from "../../../../../utils/flagsUtils/flags";
import {
  filterAndSortOpenShifts,
  getDisplayedOpenShiftInfo,
} from "../../../service/employeeAppUtil";
import AllocationTimeframe from "../../AllocationTimeframe/AllocationTimeframe";
import SideTabCard from "../../desktop/SideTabCard/SideTabCard";
import OpenShiftItem from "../../OpenShiftItem/OpenShiftItem";
import RateShiftButton from "../../RateShiftButton/RateShiftButton";
import styles from "./ScheduleBottomTabContent.module.css";

const ScheduleBottomTabContent = ({
  date,
  allocations,
  myOpenShifts,
  employeeID,
  updateOpenShifts,
  approvedLeaveOnDay,
  email,
  note,
}) => {
  if (approvedLeaveOnDay) {
    const label = convertToTitleCase(approvedLeaveOnDay.request);
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.section}>
            <div className={styles.leaveLabelContainer}>
              <p className={styles.leaveLabel}>{label}</p>
            </div>
          </div>
          {note && (
            <div className={styles.section}>
              <p className={styles.titleLabel}>Notes from my department</p>
              <SideTabCard
                emphasizeColor="#3CAA84"
                backgroundColor="#3CAA84"
                defaultTextColor="white"
                customStyle={{
                  width: "100%",
                  marginTop: "5px",
                  borderRadius: "5px",
                }}
              >
                <p className={styles.note}>{note}</p>
              </SideTabCard>
            </div>
          )}
        </div>
      </div>
    );
  }

  const allocationOnDay = allocations.find(
    (allocation) => allocation.date === date.toFormat("AWS")
  );

  if (allocationOnDay && !allocationOnDay.isOpenShift) {
    return (
      <div className={styles.container}>
        {canAccessSurvey(email) && <RateShiftButton date={date} />}
        <div className={styles.innerContainer}>
          <div className={styles.section}>
            <p className={styles.titleLabel}>My Published Shift</p>
            <div className={styles.allocationTimeframe}>
              <AllocationTimeframe allocation={allocationOnDay} />
            </div>
          </div>
          {note && (
            <div className={styles.section}>
              <p className={styles.titleLabel}>Notes from my department</p>
              <SideTabCard
                emphasizeColor="#3CAA84"
                backgroundColor="#3CAA84"
                defaultTextColor="white"
                customStyle={{
                  width: "100%",
                  marginTop: "5px",
                  borderRadius: "5px",
                }}
              >
                <p className={styles.note}>{note}</p>
              </SideTabCard>
            </div>
          )}
        </div>
      </div>
    );
  }

  const openShiftsOnDay = myOpenShifts.filter((openShift) => {
    if (new DateTime().isAfter(openShift.date)) {
      const state = openShift.employeeStates.find(
        (s) => s.employeeID === employeeID
      );
      if (state.state === "pending") {
        return false;
      }
    }
    return openShift.date === date.toFormat("AWS");
  });

  if (openShiftsOnDay.length > 0) {
    const { pendingShiftNumbers } = getDisplayedOpenShiftInfo(
      employeeID,
      openShiftsOnDay
    );

    const displayedOpenShifts = filterAndSortOpenShifts(
      openShiftsOnDay,
      employeeID
    );

    return (
      <div className={styles.container}>
        <span className={styles.openShiftLabel}>Open Shift</span>
        <p className={styles.openShiftsAvailableLabel}>
          Open Shifts Available ({pendingShiftNumbers}){" "}
        </p>
        {displayedOpenShifts.map((openShift, idx) => {
          const state = openShift.employeeStates.find(
            (state) => state.employeeID === employeeID
          ).state;

          const isAccepted = state === "accepted";

          return (
            <OpenShiftItem
              key={openShift.id}
              openShift={openShift}
              shouldAddTopSeparator={idx === 0}
              updateOpenShifts={updateOpenShifts}
              employeeID={employeeID}
              isDisplayedInDayView={true}
              customContainerStyle={{
                width: "90%",
                borderLeft: isAccepted
                  ? "10px solid #3DB9D1"
                  : "10px solid #EF396A",
                paddingLeft: "10px",
                borderTop: "none",
              }}
              customBottomPendingButtonsStyle={{
                marginTop: "-15px",
                fontWeight: "bold",
              }}
            />
          );
        })}
        {note && (
          <div className={styles.noteForOpenShiftContainer}>
            <p className={styles.noteForOpenShiftLabel}>
              Notes from my department
            </p>
            <SideTabCard
              emphasizeColor="#3CAA84"
              backgroundColor="#3CAA84"
              defaultTextColor="white"
              customStyle={{
                width: "100%",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <p className={styles.note}>{note}</p>
            </SideTabCard>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {note && (
          <div className={styles.section}>
            <p className={styles.titleLabel}>Notes from my department</p>
            <SideTabCard
              emphasizeColor="#3CAA84"
              backgroundColor="#3CAA84"
              defaultTextColor="white"
              customStyle={{
                width: "100%",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <p className={styles.note}>{note}</p>
            </SideTabCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleBottomTabContent;
