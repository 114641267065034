// Calculate number of warnings for the page of the given label (in sidebar)
export const getActiveWarningsNumForRoster = (warnings, label) => {
  let count = 0;

  if (!warnings) {
    return 0;
  }

  switch (label) {
    case "My Roster": {
      if (warnings.RosteredAllocations) {
        count += warnings.RosteredAllocations.length;
      }
      break;
    }
    case "Employees": {
      if (warnings.Employees) {
        count += warnings.Employees.length;
      }
      break;
    }
    case "Tasks": {
      if (warnings.Tasks) {
        count += warnings.Tasks.length;
      }
      break;
    }
    case "Shift/Shift Groups": {
      if (warnings.ShiftGroups) {
        count += warnings.ShiftGroups.length;
      }
      break;
    }
    case "Rules": {
      if (warnings.CustomRules) {
        count += warnings.CustomRules.length;
      }
      break;
    }
    case "Staffing Demands":
    case "StaffingNumbers": {
      if (warnings.Demands) {
        count += warnings.Demands.length;
      }
      break;
    }
    case "History": {
      if (warnings.History) {
        count += warnings.History.length;
      }
      break;
    }
    case "Fixed Shifts/Leave": {
      if (warnings.Allocations && warnings.AllocationsRecurring) {
        count += warnings.Allocations.length;
        count += warnings.AllocationsRecurring.length;
      }
      break;
    }
    case "Preferences": {
      if (warnings.Days && warnings.DaysRecurring) {
        count += warnings.Days.length;
        count += warnings.DaysRecurring.length;
      }
      break;
    }
    default: {
      break;
    }
  }
  return count;
};
