import { GraphQLQueryError } from "../../errors/errors";
import { emptyQueue } from "../performanceUtils/queue";
import {
  createCustomAuthLambdaToken,
  getCurrentAuthUser,
  shouldUseCustomAuthLambda,
} from "../../features/auth/service/auth";
import LogRocket from "logrocket";
import { signOut } from "aws-amplify/auth";
import { generateGraphqlClient } from "./graphqlClient";

export const handleRosterCreateSubscription = (
  queryClient,
  queryKey,
  newRoster
) => {
  const cachedLocation = queryClient.getQueryData(queryKey);
  const cachedRosters = cachedLocation.Rosters.items;

  if (
    cachedLocation.isScheduleView &&
    newRoster.snapshotStartDate.startsWith("T")
  ) {
    return;
  }
  if (cachedRosters.find(({ id }) => id === newRoster.id)) {
    return;
  }

  queryClient.setQueryData(queryKey, (oldData) => {
    const oldRosters = oldData.Rosters.items;
    return {
      ...oldData,
      Rosters: {
        items: [...oldRosters, newRoster],
      },
    };
  });
};

export const handleGlobalEmployeeCreateSubscription = (
  queryClient,
  queryKey,
  newEmployee
) => {
  if (!newEmployee) {
    return;
  }

  const cachedLocation = queryClient.getQueryData(queryKey);
  const cachedEmployees = cachedLocation.Employees.items;
  if (cachedEmployees.find(({ id }) => newEmployee.id === id)) {
    return;
  }

  const updatedEmployees = [...cachedEmployees, newEmployee];

  queryClient.setQueryData(queryKey, (oldData) => {
    return {
      ...oldData,
      Employees: {
        items: updatedEmployees,
      },
    };
  });
};

export const handleGlobalEmployeeDeleteSubscription = (
  queryClient,
  queryKey,
  removedEmployee
) => {
  if (!removedEmployee) {
    return;
  }

  const cachedLocation = queryClient.getQueryData(queryKey);
  const cachedEmployees = cachedLocation.Employees.items;
  const updatedEmployees = cachedEmployees.filter(
    ({ id }) => removedEmployee.id !== id
  );

  queryClient.setQueryData(queryKey, (oldData) => {
    return {
      ...oldData,
      Employees: {
        items: updatedEmployees,
      },
    };
  });
};

export const handleSolutionDeleteSubscription = (
  queryClient,
  deletedSolution
) => {
  const { rosterID } = deletedSolution;
  const queryKey = ["roster", rosterID];

  const cachedRoster = queryClient.getQueryData(queryKey);

  const updatedSolutions = cachedRoster.Solutions.items.filter(
    ({ id }) => deletedSolution.id !== id
  );

  queryClient.setQueryData(queryKey, (oldData) => {
    return {
      ...oldData,
      Solutions: {
        items: updatedSolutions,
      },
    };
  });
};

/**
 * Given a graphqlQuery, extract type and opertion name
 *  - For example, if I provide createRoster graphQL query, it will return
 *  { operationType: "mutation", operationName: "CreateRoster" }
 * @param {*} query - GraphQL query
 * @returns - { operationType, operationName }
 */
export function getGraphqlInfo(query) {
  const operationRegex = /(query|mutation|subscription)\s+(\w+)/;
  const matches = query.match(operationRegex);
  const operationType = matches[1];
  const operationName = matches[2];

  return {
    operationType,
    operationName,
  };
}

export async function graphqlErrorHandler(
  query,
  variables = null,
  extractFunc,
  filter = null,
  context = null,
  addLogrocketLink = false
) {
  const { operationType, operationName } = getGraphqlInfo(query);
  const user = await getCurrentAuthUser();

  const shouldUseCustomAuthMode = shouldUseCustomAuthLambda(
    user,
    operationName
  );

  const customAuthParams = {
    authMode: "lambda",
    authToken: createCustomAuthLambdaToken(
      user.session.idToken.toString(),
      operationName,
      operationType,
      variables
    ),
  };

  try {
    let logRocketURL = null;

    if (addLogrocketLink) {
      try {
        LogRocket.getSessionURL(function (sessionURL) {
          logRocketURL = sessionURL;
        });
      } catch {
        console.warning("URL could not be found");
      }
    }

    if (logRocketURL) {
      variables = {
        ...variables,
        context: JSON.stringify({
          lr: logRocketURL,
        }),
      };
    }

    if (filter) {
      variables = {
        ...variables,
        filter,
      };
    }

    let graphqlClient = await generateGraphqlClient();

    const queryParams = {
      query,
      ...(variables && { variables }),
      ...(shouldUseCustomAuthMode && { ...customAuthParams }),
    };

    const response = await graphqlClient.graphql(queryParams);

    return extractFunc(response.data);
  } catch (error) {
    if (error.message === "No current user") {
      await emptyQueue();
      return null;
    }

    console.log("error", error, variables);

    // Enhanced error logging
    console.error("GraphQL Error", {
      operationType,
      operationName,
      error,
      variables,
    });

    // Handle 401 errors specifically
    if (error.statusCode === 401) {
      try {
        console.error("401 error. Forcefully refreshing token.");
        await getCurrentAuthUser(true);
      } catch (refreshError) {
        console.error("Cannot forcefully refresh token. Logging out.");
        await signOut()
          .then((data) => console.log(data))
          .catch((err) => console.log(err));
      }
    }

    if (!context) {
      context = `${operationName} ${operationType}`;
    }

    let baseErrorMessage = `GraphQL Error | ${context}`;
    if (error && error.errors[0] && error.errors[0].message) {
      baseErrorMessage = error.errors[0].message
        .replace(/\d/g, "x")
        .replace(
          /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g,
          ""
        )
        .replace(/[A-Za-z\d]{24,}/g, ""); //Get rid of numbers and uuids
      +`| ${context}`;
    }

    // Log error to LogRocket with additional context
    LogRocket.captureException(new Error(baseErrorMessage), {
      extra: {
        operationType,
        operationName,
        error: JSON.stringify(error),
        variables,
        url: window.location.href,
      },
    });

    throw new GraphQLQueryError(
      `${baseErrorMessage}\n error: ${JSON.stringify(error)}`,
      error
    );
  }
}
