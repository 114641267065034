import { useCallback, useMemo } from "react";
import {
  getCustomKeywordsDataFromLocation,
  getFieldsFromLocation,
  getMyOpenShifts,
  interpretCustomKeywordsData,
} from "../../../utils/queryUtils/locationDataGetters";
import {
  checkAllocationIsLeave,
  deepCopyObject,
  sortByDateField,
} from "../../../utils";
import {
  parseAllocation,
  parsePreferences,
  parsePreferenceNumbers,
  parsePreferenceRecurringNumbers,
  parsePreferencesRecurring,
  parseRequests,
} from "../service/employeeAppUtil";
import { getEmployeesAllocationNotes } from "../../../utils/queryUtils/globalEmployeeDataGetters";
import { buildShortIdsToEntityNamesDicts } from "../../rosterProblems/service/rosterUtils";

export function useEmployeeAllocationsData(
  location,
  userEmployee,
  globalEmployees
) {
  const customKeywordsUtilObj = useMemo(() => {
    if (!location) {
      return { leaveCodes: null };
    }
    return interpretCustomKeywordsData(
      getCustomKeywordsDataFromLocation(location)
    );
  }, [location]);

  const { leaveCodes, leaveKeywords } = customKeywordsUtilObj;

  const leaveKeywordsDict = leaveCodes.reduce((acc, curr) => {
    acc[curr.longname] = curr.shortname;
    return acc;
  }, {});

  const checkLeave = useCallback(
    (leaveString) => {
      return checkAllocationIsLeave(leaveString, leaveCodes);
    },
    [leaveCodes]
  );

  const locationFields = getFieldsFromLocation(location);

  const {
    globalSkills: skills,
    globalShifts: shifts,
    globalShiftGroups: shiftGroups,
    globalTasks: tasks,
    globalTaskBlocks: taskBlocks,
    globalAreas: areas,
    openShifts,
    globalFrontendSettings,
    subTasks,
  } = locationFields;

  const availableShifts = shifts.filter((shift) => !shift.adminUseOnly);
  const availableShiftGroups = shiftGroups.filter(
    (group) => !group.adminUseOnly
  );

  const myOpenShifts = useMemo(
    () =>
      getMyOpenShifts(openShifts, userEmployee, skills, tasks, shifts, areas),
    [openShifts, userEmployee, skills, shifts, tasks, areas]
  );

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const allocations = parseAllocation(
    userEmployee,
    shifts,
    checkLeave,
    shortIdsToEntityNamesDicts
  );

  const notesToDisplay = getEmployeesAllocationNotes([userEmployee], false)[
    userEmployee.id
  ];

  const defaultPreferenceLevel = useMemo(() => {
    const defaultPrefLevelSetting = globalFrontendSettings.find(
      (setting) => setting.name === "defaultPreferenceImportance"
    );
    if (!defaultPrefLevelSetting) {
      return "normal";
    }
    return defaultPrefLevelSetting.values[0];
  }, [globalFrontendSettings]);

  const preferences = parsePreferences(
    userEmployee,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    defaultPreferenceLevel
  );

  const preferencesRecurring = parsePreferencesRecurring(
    userEmployee,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    defaultPreferenceLevel
  );

  const preferenceNumbers = parsePreferenceNumbers(
    globalEmployees,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    defaultPreferenceLevel
  );

  const preferenceRecurringNumbers = parsePreferenceRecurringNumbers(
    globalEmployees,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    defaultPreferenceLevel
  );

  const requests = userEmployee.Requests;
  const sortedRequests = deepCopyObject(requests);
  sortByDateField(sortedRequests, "startDate", true);

  const employeeLeaveCounts = useMemo(() => {
    return calculateEmployeeLeaveCounts(globalEmployees, leaveKeywordsDict);
  }, [globalEmployees, leaveKeywordsDict]);

  const numberOfEmployees = globalEmployees.length;

  return {
    allocations,
    myOpenShifts,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    preferences,
    preferencesRecurring,
    preferenceNumbers,
    preferenceRecurringNumbers,
    requests: sortedRequests,
    defaultPreferenceLevel,
    leaveCodes,
    employeeLeaveCounts,
    availableShifts,
    availableShiftGroups,
    leaveKeywordsDict,
    leaveKeywords,
    numberOfEmployees,
    customKeywordsUtilObj,
    notesToDisplay,
    shortIdsToEntityNamesDicts,
  };
}

export const calculateEmployeeLeaveCounts = (employees, leaveKeywordsDict) => {
  const leaveCounts = {};

  for (const e in employees) {
    const parsedRequests = parseRequests(employees[e]);

    parsedRequests.forEach((request) => {
      const { date, allocation, state } = request;

      // Check if the request is approved
      if (
        (state === "Approved" || state === "Pending") &&
        allocation in leaveKeywordsDict
      ) {
        // Initialize the leave count for the date if it doesn't exist
        if (!leaveCounts[date]) {
          leaveCounts[date] = {};
        }

        // Increment the leave count for the allocation type
        if (!leaveCounts[date][leaveKeywordsDict[allocation]]) {
          leaveCounts[date][leaveKeywordsDict[allocation]] = [
            employees[e].name,
          ];
        } else {
          leaveCounts[date][leaveKeywordsDict[allocation]].push(
            employees[e].name
          );
        }
      }
    });
  }

  return leaveCounts;
};
