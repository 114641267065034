import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { logOut } from "../../../auth/service/auth";
import styles from "./NotFoundPage.module.css";

const NotFoundPage = ({
  message,
  redirectPageName = null,
  redirectCallback = null,
  returnButtonLabel = "Return to Dashboard",
}) => {
  const redirectTo = useRedirect();

  return (
    <main className={styles.container}>
      <p className={styles.description}>{message}</p>
      {redirectPageName !== null && (
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          onClick={() => {
            redirectTo({
              pageName: redirectPageName,
              ...(redirectCallback && { callback: redirectCallback }),
            });
          }}
          customStyle={{
            borderRadius: "5px",
            fontSize: "20px",
            width: "250px",
          }}
        >
          {returnButtonLabel}
        </BasicButton>
      )}
      <BasicButton
        color="#219ec9"
        hoverColor="#1f91b7"
        onClick={() => {
          logOut().then(() => {
            redirectTo({ pageName: PAGE_NAMES.main });
          });
        }}
        customStyle={{
          borderRadius: "5px",
          fontSize: "20px",
          width: "250px",
        }}
      >
        Sign out
      </BasicButton>
    </main>
  );
};

export default NotFoundPage;
