import { useRef, useState, useMemo } from "react";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import Layout from "../../../../components/layouts/Layout/Layout";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import {
  convertShortIdsCellValueToEntityNames,
  createBasicContextMenu,
  DateTime,
  defaultFilterValueGetter,
} from "../../../../utils";
import DataEntryTable from "../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import RequestsLocked from "../../../upgradePlan/components/RequestsLocked/RequestsLocked";
import EditRequestButton from "../EditRequestButton/EditRequestButton";
import EditRequestsModal from "./EditRequestsModal";
import RequestsModal from "./RequestsModal";
import styles from "./RequestsView.module.css";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import { useUserStore } from "../../../../globalStore/appStore";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";

const RequestsView = ({
  employeeNames,
  requestsData,
  addNewRequest,
  editRequest,
  setGridApiToParent,
  locationID,
  locationName,
  leaveLongNames,
  isSaving,
  skills,
  areas,
  onAreaFilterChanged,
  doesAreaFilterPass,
  isExternalFilterPresent,
  saveAreaFilter,
  initialAreaFilterValue,
}) => {
  const [requestsModalOpen, setRequestsModalOpen] = useState(false);
  const [editRequestID, setEditRequestID] = useState(null);
  const redirect = useRedirect();
  const { isPaidPlan } = useUserStore();

  const toggleModal = () => {
    setRequestsModalOpen(!requestsModalOpen);
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const getContextMenuItems = () => {
    const contextMenu = createBasicContextMenu();
    return contextMenu;
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "employeeName",
        editable: false,
        width: width / 8,
        sortable: true,
      },
      {
        field: "skills",
        editable: false,
        width: width / 8,
        sortable: true,
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      },
      {
        headerName: "Request Type",
        field: "request",
        editable: false,
        width: width / 8 - 2,
        sortable: true,
      },
      {
        field: "startDate",
        width: width / 8,
        editable: false,
        sortable: true,
        valueFormatter: (params) =>
          new DateTime(params.value).toFormat("displayed-full"),
      },
      {
        field: "finishDate",
        editable: false,
        width: width / 8,
        sortable: true,
        valueFormatter: (params) =>
          new DateTime(params.value).toFormat("displayed-full"),
      },
      {
        headerName: "Creation Date",
        field: "submittedDate",
        editable: false,
        width: width / 8,
        sortable: true,
        valueFormatter: (params) =>
          new DateTime(params.value).toFormat("displayed-full"),
      },
      {
        headerName: "State",
        field: "state",
        width: width / 8 - 2,
        editable: false,
        sortable: true,
      },
      {
        field: "actions",
        editable: false,
        width: width / 8,
        cellRenderer: "editButtonRenderer",
      },
    ],
    [width, skills]
  );

  const WrappedEditButtonRenderer = (props) => (
    <EditRequestButton {...props} setId={setEditRequestID} />
  );

  if (!isPaidPlan) {
    return (
      <div ref={componentRef}>
        <RequestsLocked />
      </div>
    );
  }

  return (
    <Layout
      title={<>Requests - {locationName}</>}
      headerNext={() => (
        <div className={styles.headerNext}>
          <GetStartedButton
            url={
              "https://help.rosterlab.com/fixed-shifts-leave-requests-and-preferences"
            }
            noPaddingBottom={true}
          />
          <AreaFilter
            areas={areas}
            onAreaFilterChanged={onAreaFilterChanged}
            onMenuClose={saveAreaFilter}
            defaultValue={initialAreaFilterValue}
          />
        </div>
      )}
      headerRight={() => (
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          customStyle={{
            borderRadius: "10px",
          }}
          onClick={() => {
            if (employeeNames.length === 0) {
              customConfirmAlert({
                title: "No assignable employees",
                descriptions: [
                  "To add requests, you will need to add employees to your location. Go to the employee setting page?",
                ],
              }).then((isContinue) => {
                if (isContinue) {
                  redirect({ pageName: PAGE_NAMES.location, locationID });
                }
              });

              return;
            }
            toggleModal();
          }}
        >
          Add request
        </BasicButton>
      )}
    >
      {requestsModalOpen ? (
        <RequestsModal
          locationID={locationID}
          handleClose={toggleModal}
          handleSave={(data) => {
            toggleModal();
            addNewRequest(data);
          }}
          employeeNames={employeeNames}
          requestsData={requestsData}
          leaveLongNames={leaveLongNames}
        />
      ) : (
        <></>
      )}
      {editRequestID ? (
        <EditRequestsModal
          requestId={editRequestID}
          handleClose={() => {
            setEditRequestID(null);
          }}
          handleSave={(prevRequest, updatedRequestFields) => {
            setEditRequestID(null);
            editRequest(prevRequest, updatedRequestFields);
          }}
          requestsData={requestsData}
        />
      ) : (
        <></>
      )}
      <div className={styles["grid-container"]} ref={componentRef}>
        <DataEntryTable
          columnDefs={columnDefs}
          customStyle={{
            height: `${window.innerHeight - 270}px`,
            minHeight: `500px`,
          }}
          rowData={requestsData}
          setGridApiToParent={setGridApiToParent}
          getContextMenuItems={getContextMenuItems}
          components={{
            editButtonRenderer: WrappedEditButtonRenderer,
          }}
          aggridStyle={"ag-theme-alpine"}
          gridOptions={{}}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
      </div>
      <div className={styles["name-line-container"]}>
        <p>{isSaving ? "saving..." : "saved"}</p>
        <p className={styles["name-line"]}>
          <span className={styles.emph}>
            Options: {leaveLongNames.join(", ")}
          </span>
        </p>
      </div>
    </Layout>
  );
};

export default RequestsView;
