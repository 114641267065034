import Select from "react-select-virtualized";
import "./DropdownCollapsibleSelector.css";

const DropdownCollapsibleSelectorVirtualised = ({
  options,
  onChange,
  defaultValue,
  menuPosition = "absolute",
}) => {
  return (
    <Select
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      isClearable={false} // This prevents the "x" from being shown
      styles={{
        control: (base) => ({
          ...base,
          minWidth: "300px", // Set your desired minimum width
        }),
      }}
      menuPosition={menuPosition}
    />
  );
};

export default DropdownCollapsibleSelectorVirtualised;
