import _ from "lodash";
import {
  findShortestShift,
  strToArrCommaSeparated,
} from "../../../../../utils";
import { getColorCode } from "../../../../colorCoding/service/colorCodeGetter";
import { extractEntityShortIdsFromAllocation } from "../../../../../utils/modelUtils/allocation";

export function getDemandFromIndex(rowName, blockIndex, demandsGrid) {
  return demandsGrid[rowName][blockIndex];
}

export function getDaysArrayInView(
  numDays,
  numWeeksPerView,
  numIntervalInView
) {
  const fullDaysArray = Array.from(
    { length: numDays },
    (_, i) => `Day ${i + 1}`
  );

  const startingWeekNumInView =
    numWeeksPerView === 1 ? numIntervalInView : numIntervalInView * 2 - 1;

  const endingWeekNumInView =
    numWeeksPerView === 1 ? startingWeekNumInView : startingWeekNumInView + 1;

  const startingIndex = startingWeekNumInView * 7 - 7;
  const finishingIndexPlusOne = endingWeekNumInView * 7;

  const indicesToShow = _.range(
    startingIndex,
    Math.min(numDays, finishingIndexPlusOne)
  );
  return indicesToShow.map((index) => fullDaysArray[index]);
}

export function getColorCodeForRow(
  rowName,
  colorCodes,
  shifts,
  shiftGroups,
  tasks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  const [rowEntityShortIdsStr, rowTaskShortIdsStr] = rowName.split("||");
  const rowEntityShortIds = rowEntityShortIdsStr
    ? strToArrCommaSeparated(rowEntityShortIdsStr)
    : [];
  const rowTaskShortIds = rowTaskShortIdsStr
    ? strToArrCommaSeparated(rowTaskShortIdsStr)
    : [];

  const rowShifts = [];
  const rowTasks = [];

  for (const entityShortId of rowEntityShortIds) {
    const { shiftShortId, shiftGroupShortId } =
      extractEntityShortIdsFromAllocation(
        entityShortId,
        shortIdsToEntityNamesDicts
      );

    if (shiftGroupShortId) {
      const colorCode = colorCodes.find(
        (code) => code.shift === shiftGroupShortId
      );
      if (colorCode) {
        return colorCode.color;
      }
    }

    const matchingShift = shifts.find(
      (shift) => shift.shortId === shiftShortId
    );
    if (matchingShift) {
      rowShifts.push(matchingShift);
      continue;
    }
    const matchingTask = tasks.find((task) => task.shortId === entityShortId);
    rowTasks.push(matchingTask);
  }

  for (const taskShortId of rowTaskShortIds) {
    const matchingTask = tasks.find((task) => task.shortId === taskShortId);
    rowTasks.push(matchingTask);
  }

  const selectedShift = findShortestShift(rowShifts);
  const selectedTask = rowTasks.length > 0 ? rowTasks[0] : null;

  let allocationUsedForRowColoring = "";
  if (selectedShift) {
    allocationUsedForRowColoring = selectedShift.shortId;
    if (selectedTask) {
      allocationUsedForRowColoring = `${allocationUsedForRowColoring}-${selectedTask.shortId}`;
    }
  }

  return getColorCode(
    colorCodes,
    allocationUsedForRowColoring,
    shiftGroups,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );
}

export function isDemandCellFillable(demandsGrid, rowName, blockIndex) {
  return demandsGrid[rowName][blockIndex] !== undefined;
}
