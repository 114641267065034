import { GridApi } from "@ag-grid-community/core";
import {
  DateTime,
  getShiftFromAllocation,
  getShiftHoursFromShift,
  getShiftTask,
} from "../../../utils";
import { getDayColumnNameRegex } from "../../../utils/generalUtils/regex";
import { allocationFulfilsPreference } from "../../rosterProblems/service/preferencesAndFixedShifts";
import {
  getHistoricDaysOnRow,
  getHistoricHoursOnRow,
  getHistoricLastAllocation,
  getHistoricLastShift,
  getHistoricShiftsRow,
} from "../../rosterProblems/rosteredAllocations/components/RosterGrid/historyFunctions";
import { getRosterRulesDict } from "./ruleFunctions";
import { getShiftHoursForEmployees } from "./rules";
import { KEYWORD_NA, KEYWORD_OFF } from "../../../constants/keywords";

const buildRuleDict = (rules: any[]) => {
  const ruleDict = {};
  rules.forEach((rule: { template: string }, ruleIdx: number) => {
    ruleDict[rule.template] = ruleIdx;
  });
  return ruleDict;
};

function getOccurrencesRow(
  array,
  shiftName,
  startDay,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  skills,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  var occurences = [];
  var allOccurencesRow = [];

  var cache = {};
  array.forEach((v, vInd) => {
    if (occurences[occurences.length - 1] !== startDay + vInd - 1) {
      if (occurences.length > 0) {
        allOccurencesRow.push(occurences);
      }
      occurences = [];
    }

    if (v in cache) {
      if (cache[v]) occurences.push(startDay + vInd);
    } else {
      if (
        allocationFulfilsPreference(
          v,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          shiftName,
          skills,
          shortIdsToEntityNamesDicts,
          customKeywordsUtilObj
        )
      ) {
        occurences.push(startDay + vInd);
        cache[v] = true;
      } else {
        cache[v] = false;
      }
    }
  });
  if (occurences.length > 0) {
    allOccurencesRow.push(occurences);
  }
  return allOccurencesRow;
}

function countNumberOfNightsDaysOff(
  array,
  shiftName,
  numDaysOff,
  annualLeaveKeyword
) {
  const offStrings = ["", annualLeaveKeyword];

  if (numDaysOff <= 1) return [];
  const occurences = [];
  for (let d = 0; d < array.length; d++) {
    let foundOccurence = false;

    if (!(array[d] === shiftName && offStrings.includes(array[d + 1]))) {
      continue;
    }
    for (let t = 0; t < numDaysOff - 1; t++) {
      if (!offStrings.includes(array[d + t + 2])) {
        foundOccurence = true;
        break;
      }
    }
    if (foundOccurence) {
      occurences.push(d);
    }
  }

  return occurences;
}

function getOccurrence(
  array,
  shiftName,
  startDay,
  areas,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  skills,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  var occurences = [];
  var cache = {};
  array.forEach((v, vInd) => {
    if (v in cache) {
      if (cache[v]) occurences.push(startDay + vInd);
      return;
    }
    if (
      allocationFulfilsPreference(
        v,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        shiftName,
        skills,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      )
    ) {
      occurences.push(startDay + vInd);
      cache[v] = true;
    } else {
      cache[v] = false;
    }
  });
  return occurences;
}

export const getInvalidCellsFromRules = (
  rules: any[],
  areas: any[],
  shifts: any[],
  shiftGroups: any[],
  tasks: any[],
  taskBlocks: any[],
  employees: any[],
  numDays: number,
  gridApi: GridApi,
  predefinedShiftOptions,
  customKeywordsUtilObj,
  shortIdsToEntityNamesDicts
) => {
  const employeeDataFromGrid = [];
  gridApi.forEachNode((rowNode) => {
    employeeDataFromGrid.push(rowNode.data);
  });

  return getInvalidCellsFromRulesWithOutGridDeps(
    rules,
    areas,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    employees,
    numDays,
    predefinedShiftOptions,
    customKeywordsUtilObj,
    employeeDataFromGrid,
    shortIdsToEntityNamesDicts
  );
};

export const getInvalidCellsFromRulesWithOutGridDeps = (
  rules: any[],
  areas,
  shifts: any[],
  shiftGroups: any[],
  tasks: any[],
  taskBlocks: any[],
  employees: any[],
  numDays: number,
  predefinedShiftOptions,
  customKeywordsUtilObj,
  employeeDataFromGrid,
  shortIdsToEntityNamesDicts
) => {
  const { annualLeaveKeyword } = customKeywordsUtilObj;

  const invalidCells = [];
  const rosterRulesDict = getRosterRulesDict(shifts, shiftGroups);

  function addInvalidCellsIfTemplateExists(template, ...args) {
    if (rosterRulesDict[template]) {
      rosterRulesDict[template].addInvalidCells(...args);
    }
  }

  if (shifts.length === 0 || employees.length === 0 || rules.length === 0)
    return [];

  const shiftHoursByEmployee = getShiftHoursForEmployees(
    employees,
    rules,
    shifts,
    shiftGroups,
    customKeywordsUtilObj
  );

  const maxShiftHours: number = Object.values(shiftHoursByEmployee).reduce(
    (max: number, employeeShifts: any) => {
      const shiftHours = Object.entries(employeeShifts)
        .filter(([key]) => key !== "employee-name")
        .map(([, hours]) => Number(hours));

      return Math.max(max, ...shiftHours);
    },
    0
  ) as number;

  const numWeeks = Math.ceil(numDays / 7);
  const numFortnights = Math.ceil(numDays / 14);

  employeeDataFromGrid.forEach((nodeData, nodeIdx) => {
    let daysOnRow = 0;
    let hoursOnRow = 0;
    let allShifts: string[] = [];
    let shiftInARow: string[] = [];
    let leaveShiftsInOnstretch: string[] = [];
    let daysList: number[] = [];

    let weekDays = new Array(numWeeks).fill(0);
    let weekHours = new Array(numWeeks).fill(0);
    let weekDaysList: number[][] = [];
    let weekShiftList: string[][] = [];
    let fortnightDays = new Array(numFortnights).fill(0);
    let fortnightHours = new Array(numFortnights).fill(0);
    let fortnightDaysList: number[][] = [];

    for (let w = 0; w < numWeeks; w++) {
      weekDaysList.push([]);
      weekShiftList.push([]);
    }
    for (let F = 0; F < numFortnights; F++) {
      fortnightDaysList.push([]);
    }

    //
    let previousShift: {
      name: string;
      startTime: any;
      finishTime: any;
    } | null = null;
    let previousAllocation: string;
    let ruleDict = buildRuleDict(rules); // applied rules
    let templates;
    let employeeValue;
    let onstretchStartDay = 0;

    for (const [cellField, value] of Object.entries(nodeData)) {
      let day = parseInt(cellField.substring(1));
      const dayColNameRegex = getDayColumnNameRegex();
      if (cellField.match(dayColNameRegex)) {
        const shiftKeywords = predefinedShiftOptions as string[];

        const isAllocated =
          value !== "" &&
          value !== "-" &&
          !shiftKeywords.includes(value as string);

        if (value === annualLeaveKeyword) {
          leaveShiftsInOnstretch.push(value as string);
        }

        if (isAllocated) {
          daysOnRow++;
          const shift = getShiftFromAllocation(value, shifts);
          let hours = getShiftHoursFromShift(
            shift,
            employees[nodeIdx],
            shiftHoursByEmployee
          );

          hoursOnRow += hours;

          const shiftNameOrDefault = getShiftTask(value)[0];
          const allocation = value as string;

          daysList.push(day);
          weekShiftList[Math.floor((day - 1) / 7)].push(shiftNameOrDefault);
          shiftInARow.push(allocation);
          allShifts.push(shiftNameOrDefault);
          weekDays[Math.floor((day - 1) / 7)]++;
          weekHours[Math.floor((day - 1) / 7)] += hours;
          weekDaysList[Math.floor((day - 1) / 7)].push(day);
          fortnightDays[Math.floor((day - 1) / 14)]++;
          fortnightHours[Math.floor((day - 1) / 14)] += hours;
          fortnightDaysList[Math.floor((day - 1) / 14)].push(day);

          if (day === 1) {
            previousAllocation = getHistoricLastAllocation(
              employees[nodeIdx].History
            );
            previousShift = getHistoricLastShift(
              employees[nodeIdx].History,
              shifts
            );
          }

          // Next shift bad
          if (previousAllocation) {
            for (let ruleTemplate in ruleDict) {
              let regex =
                /shiftChanges;([^;]*);([^;]*);((\bCritical\b)|(\bMedium\b));Roster/g;
              let result = regex.exec(ruleTemplate);
              if (result) {
                const shiftName1 = result[1];
                const shiftName2 = result[2];
                let template = result[0];

                if (
                  previousAllocation !== allocation &&
                  allocationFulfilsPreference(
                    previousAllocation,
                    shifts,
                    shiftGroups,
                    tasks,
                    taskBlocks,
                    shiftName1,
                    employees[nodeIdx].skills,
                    shortIdsToEntityNamesDicts,
                    customKeywordsUtilObj
                  ) &&
                  allocationFulfilsPreference(
                    value,
                    shifts,
                    shiftGroups,
                    tasks,
                    taskBlocks,
                    shiftName2,
                    employees[nodeIdx].skills,
                    shortIdsToEntityNamesDicts,
                    customKeywordsUtilObj
                  ) &&
                  !allocationFulfilsPreference(
                    value,
                    shifts,
                    shiftGroups,
                    tasks,
                    taskBlocks,
                    shiftName1,
                    employees[nodeIdx].skills,
                    shortIdsToEntityNamesDicts,
                    customKeywordsUtilObj
                  )
                ) {
                  addInvalidCellsIfTemplateExists(
                    template,
                    invalidCells,
                    [day - 1, day],
                    nodeIdx,
                    nodeData.id,
                    nodeData.name,
                    0
                  );
                }
              }
            }
          }

          if (previousShift) {
            // Next shift bad due to hours gap
            templates = [
              `timeBetweenShifts;Critical`,
              `timeBetweenShifts;Medium`,
            ];
            for (let t = 0; t < templates.length; t++) {
              let template = templates[t];
              if (template in ruleDict) {
                employeeValue = parseInt(
                  employees[nodeIdx].RuleValues[ruleDict[template]]
                );
                if (shift) {
                  let hourGap = DateTime.getTimeDiffConsecutiveShifts(
                    previousShift.startTime,
                    previousShift.finishTime,
                    shift.startTime
                  );

                  if (hourGap < employeeValue) {
                    addInvalidCellsIfTemplateExists(
                      template,
                      invalidCells,
                      [day - 1, day],
                      nodeIdx,
                      nodeData.id,
                      nodeData.name,
                      employeeValue
                    );
                  }
                }
              }
            }
          }

          previousAllocation = allocation;
          previousShift = shift;
        } else {
          let modifiedValue = value as string;
          if (modifiedValue === KEYWORD_OFF) modifiedValue = "";
          allShifts.push(modifiedValue);

          weekShiftList[Math.floor((day - 1) / 7)].push(modifiedValue);

          for (let ruleTemplate in ruleDict) {
            // Max shifts row
            let regex = /numShiftsRow;Maximum;Critical;true;(.*)/g;
            let result = regex.exec(ruleTemplate);
            if (result) {
              const shiftName = result[1];
              let template = result[0];

              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              ); // plus because maximum shifts

              const offsetValue = employeeValue + leaveShiftsInOnstretch.length;

              let occurencesRow = getOccurrencesRow(
                shiftInARow,
                shiftName,
                daysList[0],
                areas,
                shifts,
                shiftGroups,
                tasks,
                taskBlocks,
                employees[nodeIdx].skills,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              );

              for (const occurences of occurencesRow) {
                if (
                  (onstretchStartDay > 0 && occurences.length > offsetValue) ||
                  (onstretchStartDay === 0 &&
                    getHistoricShiftsRow(
                      employees[nodeIdx].History,
                      shiftName,
                      shifts,
                      shiftGroups,
                      tasks,
                      taskBlocks,
                      employees[nodeIdx].skills,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ) +
                      occurences.length >
                      offsetValue)
                )
                  addInvalidCellsIfTemplateExists(
                    template,
                    invalidCells,
                    occurences,
                    nodeIdx,
                    nodeData.id,
                    nodeData.name,
                    employeeValue
                  );
              }
            }

            // Min shifts row
            regex = /numShiftsRow;Minimum;Critical;true;(.*)/g;
            result = regex.exec(ruleTemplate);
            if (result) {
              const shiftName = result[1];
              let template = result[0];
              if (
                employees[nodeIdx].RuleValues[ruleDict[template]] !==
                  KEYWORD_NA &&
                daysOnRow > 0
              ) {
                employeeValue = parseInt(
                  employees[nodeIdx].RuleValues[ruleDict[template]]
                );

                const offsetValue =
                  employeeValue - leaveShiftsInOnstretch.length; // minus because minimum shifts

                const historicShiftsRow = getHistoricShiftsRow(
                  employees[nodeIdx].History,
                  shiftName,
                  shifts,
                  shiftGroups,
                  tasks,
                  taskBlocks,
                  employees[nodeIdx].skills,
                  shortIdsToEntityNamesDicts,
                  customKeywordsUtilObj
                );

                let occurencesRow = getOccurrencesRow(
                  shiftInARow,
                  shiftName,
                  daysList[0],
                  areas,
                  shifts,
                  shiftGroups,
                  tasks,
                  taskBlocks,
                  employees[nodeIdx].skills,
                  shortIdsToEntityNamesDicts,
                  customKeywordsUtilObj
                );
                for (const occurences of occurencesRow) {
                  if (
                    (onstretchStartDay > 0 &&
                      occurences.length > 0 &&
                      occurences.length < offsetValue) ||
                    (onstretchStartDay === 0 &&
                      occurences.length > 0 &&
                      historicShiftsRow !== null &&
                      historicShiftsRow + occurences.length < offsetValue)
                  )
                    addInvalidCellsIfTemplateExists(
                      template,
                      invalidCells,
                      occurences,
                      nodeIdx,
                      nodeData.id,
                      nodeData.name,
                      employeeValue
                    );
                }
              }
            }
          }

          // Max hours row
          templates = [
            "hoursOnRow;Maximum;Critical",
            "hoursOnRow;Maximum;Medium",
          ];
          for (let t = 0; t < templates.length; t++) {
            let template = templates[t];
            if (template in ruleDict) {
              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              ); // plus because maximum hours

              const offsetValue =
                employeeValue + leaveShiftsInOnstretch.length * maxShiftHours;

              if (
                (onstretchStartDay > 0 && hoursOnRow > offsetValue) ||
                (onstretchStartDay === 0 &&
                  getHistoricHoursOnRow(
                    employees[nodeIdx],
                    shifts,
                    shiftHoursByEmployee,
                    predefinedShiftOptions
                  ) +
                    hoursOnRow >
                    employeeValue)
              )
                addInvalidCellsIfTemplateExists(
                  template,
                  invalidCells,
                  daysList,
                  nodeIdx,
                  nodeData.id,
                  nodeData.name,
                  employeeValue
                );
            }
          }

          // Min hours row
          templates = [
            "hoursOnRow;Minimum;Critical",
            "hoursOnRow;Minimum;Medium",
          ];
          for (let t = 0; t < templates.length; t++) {
            let template = templates[t];
            if (
              template in ruleDict &&
              employees[nodeIdx].RuleValues[ruleDict[template]] !==
                KEYWORD_NA &&
              hoursOnRow > 0
            ) {
              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              );

              const offsetValue =
                employeeValue - leaveShiftsInOnstretch.length * maxShiftHours;

              const historicHoursOnRow = getHistoricHoursOnRow(
                employees[nodeIdx],
                shifts,
                shiftHoursByEmployee,
                predefinedShiftOptions
              );

              if (
                (onstretchStartDay > 0 && hoursOnRow < offsetValue) ||
                (onstretchStartDay === 0 &&
                  historicHoursOnRow !== null &&
                  historicHoursOnRow + hoursOnRow < offsetValue)
              )
                addInvalidCellsIfTemplateExists(
                  template,
                  invalidCells,
                  daysList,
                  nodeIdx,
                  nodeData.id,
                  nodeData.name,
                  employeeValue
                );
            }
          }

          // Max days row
          templates = [
            "onstretchLength;Maximum;Critical",
            "onstretchLength;Maximum;Medium",
          ];
          for (let t = 0; t < templates.length; t++) {
            let template = templates[t];
            if (template in ruleDict) {
              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              );

              const offsetValue = employeeValue + leaveShiftsInOnstretch.length;

              if (
                (onstretchStartDay > 0 && daysOnRow > offsetValue) ||
                (onstretchStartDay === 0 &&
                  getHistoricDaysOnRow(
                    employees[nodeIdx].History,
                    predefinedShiftOptions
                  ) +
                    daysOnRow >
                    employeeValue)
              ) {
                addInvalidCellsIfTemplateExists(
                  template,
                  invalidCells,
                  daysList,
                  nodeIdx,
                  nodeData.id,
                  nodeData.name,
                  employeeValue
                );
              }
            }
          }

          // Min days row
          templates = [
            "onstretchLength;Minimum;Critical",
            "onstretchLength;Minimum;Medium",
          ];

          for (let t = 0; t < templates.length; t++) {
            let template = templates[t];
            if (
              template in ruleDict &&
              employees[nodeIdx].RuleValues[ruleDict[template]] !==
                KEYWORD_NA &&
              daysOnRow > 0
            ) {
              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              );

              const offsetValue = employeeValue - leaveShiftsInOnstretch.length;

              const historicDaysOnRow = getHistoricDaysOnRow(
                employees[nodeIdx].History,
                predefinedShiftOptions
              )
                ? getHistoricDaysOnRow(
                    employees[nodeIdx].History,
                    predefinedShiftOptions
                  )
                : 0;

              if (
                (onstretchStartDay > 0 && daysOnRow < offsetValue) ||
                (onstretchStartDay === 0 &&
                  historicDaysOnRow + daysOnRow < offsetValue)
              ) {
                addInvalidCellsIfTemplateExists(
                  template,
                  invalidCells,
                  daysList,
                  nodeIdx,
                  nodeData.id,
                  nodeData.name,
                  employeeValue
                );
              }
            }
          }

          // Exact days row
          templates = [
            "onstretchLength;Equal;Critical",
            "onstretchLength;Equal;Medium",
          ];

          for (let t = 0; t < templates.length; t++) {
            let template = templates[t];

            if (
              template in ruleDict &&
              employees[nodeIdx].RuleValues[ruleDict[template]] !==
                KEYWORD_NA &&
              daysOnRow > 0
            ) {
              employeeValue = parseInt(
                employees[nodeIdx].RuleValues[ruleDict[template]]
              );
              const historicDaysOnRow = getHistoricDaysOnRow(
                employees[nodeIdx].History,
                predefinedShiftOptions
              )
                ? getHistoricDaysOnRow(
                    employees[nodeIdx].History,
                    predefinedShiftOptions
                  )
                : 0;

              if (
                (onstretchStartDay > 0 && daysOnRow !== employeeValue) ||
                (onstretchStartDay === 0 &&
                  historicDaysOnRow + daysOnRow !== employeeValue)
              ) {
                addInvalidCellsIfTemplateExists(
                  template,
                  invalidCells,
                  daysList,
                  nodeIdx,
                  nodeData.id,
                  nodeData.name,
                  employeeValue
                );
              }
            }
          }

          daysOnRow = 0;
          onstretchStartDay = day + 1;
          daysList = [];
          hoursOnRow = 0;
          shiftInARow = [];
          leaveShiftsInOnstretch = [];
          previousShift = null;
          previousAllocation = "";
        }
      }
    }

    for (let s = 0; s < shifts.length; s++) {
      let shiftName = shifts[s].name;
      templates = [`daysOffAfterShift;Critical;${shiftName}`];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (template in ruleDict) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          const occurences = countNumberOfNightsDaysOff(
            allShifts,
            shiftName,
            employeeValue,
            annualLeaveKeyword
          );

          for (let o = 0; o < occurences.length; o++) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              Array.from(
                new Array(employeeValue),
                (_x, i) => i + occurences[o] + 2
              ),
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }
    }

    for (let w = 0; w < numWeeks; w++) {
      for (let ruleTemplate in ruleDict) {
        // Max shifts week
        let regex = /numShifts;Week;Maximum;Critical;(.*)/g;
        let result = regex.exec(ruleTemplate);
        if (result) {
          const shiftName = result[1];
          let template = result[0];

          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          let occurences = getOccurrence(
            weekShiftList[w],
            shiftName,
            w * 7 + 1,
            areas,
            shifts,
            shiftGroups,
            tasks,
            taskBlocks,
            employees[nodeIdx].skills,
            shortIdsToEntityNamesDicts,
            customKeywordsUtilObj
          );
          if (occurences.length > employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              occurences,
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }

        // Min shifts week
        regex = /numShifts;Week;Minimum;Critical;(.*)/g;
        result = regex.exec(ruleTemplate);
        if (result) {
          const shiftName = result[1];
          let template = result[0];
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          let occurences = getOccurrence(
            weekShiftList[w],
            shiftName,
            w * 7 + 1,
            areas,
            shifts,
            shiftGroups,
            tasks,
            taskBlocks,
            employees[nodeIdx].skills,
            shortIdsToEntityNamesDicts,
            customKeywordsUtilObj
          );
          if (occurences.length > 0 && occurences.length < employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              occurences,
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Max days week
      templates = [
        "numDaysOn;Week;Maximum;Critical",
        "numDaysOn;Week;Maximum;Medium",
      ];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (template in ruleDict) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (weekDays[w] > employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              weekDaysList[w],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Min days week
      templates = [
        "numDaysOn;Week;Minimum;Critical",
        "numDaysOn;Week;Minimum;Medium",
      ];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (
          template in ruleDict &&
          employees[nodeIdx].RuleValues[ruleDict[template]] !== KEYWORD_NA &&
          weekDays[w] > 0
        ) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (weekDays[w] < employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              weekDaysList[w],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Max hours week
      templates = ["numHours;Week;Maximum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (template in ruleDict) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (weekHours[w] > employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              weekDaysList[w],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Min hours week
      templates = ["numHours;Week;Minimum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (
          template in ruleDict &&
          employees[nodeIdx].RuleValues[ruleDict[template]] !== KEYWORD_NA &&
          weekHours[w] > 0
        ) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (weekHours[w] < employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              weekDaysList[w],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }
    }

    for (let F = 0; F < numFortnights; F++) {
      // Max days week
      templates = ["numDaysOn;Fortnight;Maximum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (template in ruleDict) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (fortnightDays[F] > employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              fortnightDaysList[F],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Min days week
      templates = ["numDaysOn;Fortnight;Minimum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (
          template in ruleDict &&
          employees[nodeIdx].RuleValues[ruleDict[template]] !== KEYWORD_NA &&
          fortnightDays[F] > 0
        ) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (fortnightDays[F] < employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              fortnightDaysList[F],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Max hours fortnight
      templates = ["numHours;Fortnight;Maximum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (template in ruleDict) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (fortnightHours[F] > employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              fortnightDaysList[F],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Min hours fortnight
      templates = ["numHours;Fortnight;Minimum;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (
          template in ruleDict &&
          employees[nodeIdx].RuleValues[ruleDict[template]] !== KEYWORD_NA &&
          fortnightHours[F] > 0
        ) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (fortnightHours[F] < employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              fortnightDaysList[F],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }

      // Exact hours fortnight
      templates = ["numHours;Fortnight;Equal;Critical"];
      for (let t = 0; t < templates.length; t++) {
        let template = templates[t];
        if (
          template in ruleDict &&
          employees[nodeIdx].RuleValues[ruleDict[template]] !== KEYWORD_NA &&
          fortnightHours[F] > 0
        ) {
          employeeValue = parseInt(
            employees[nodeIdx].RuleValues[ruleDict[template]]
          );
          if (fortnightHours[F] !== employeeValue) {
            addInvalidCellsIfTemplateExists(
              template,
              invalidCells,
              fortnightDaysList[F],
              nodeIdx,
              nodeData.id,
              nodeData.name,
              employeeValue
            );
          }
        }
      }
    }
  });

  return invalidCells;
};
