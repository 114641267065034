import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./DropdownButton.module.css";
import PropTypes from "prop-types";

const DropdownButton = ({
  children,
  color,
  listItems,
  isPartialButton = false,
}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const dropdownBtnRef = useRef();
  const listRef = useRef();

  const close = () => {
    setIsListOpen(false);
  };

  const open = () => {
    setIsListOpen(true);
  };

  const handleClickEvent = useCallback(
    (e) => {
      if (dropdownBtnRef.current && dropdownBtnRef.current.contains(e.target)) {
        if (isListOpen) {
          close();
        } else {
          open();
        }
      } else if (listRef.current && !listRef.current.contains(e.target)) {
        close();
      }
    },
    [isListOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickEvent);
    return () => {
      document.removeEventListener("click", handleClickEvent);
    };
  }, [isListOpen, handleClickEvent]);

  return (
    <div className={styles.container}>
      <button
        ref={dropdownBtnRef}
        className={`${styles.btn} ${isPartialButton && styles.partialBtn}`}
        style={{ backgroundColor: color }}
      >
        {children}
        <div
          className={`${styles["down-arrow"]} ${isListOpen && styles.active}`}
        />
      </button>
      {isListOpen && (
        <ul
          className={styles.list}
          style={{ backgroundColor: color }}
          ref={listRef}
        >
          {listItems.map((item, idx) => (
            <li key={idx} onClick={item.onClick} className={styles.item}>
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default DropdownButton;
