import { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import { HEADER_TYPES } from "../components/elements/Header/Header";
import {
  PLAN,
  getFirstAndLastNameOfUser,
  getUserAttributes,
  getUserGroups,
  userIsAdmin,
} from "../features/auth/service/auth";
import BillingPage from "../features/billing/components/BillingPage/BillingPage";
import HelpContentful from "../features/knowledgeBase/components/HelpContentful/HelpContentful";
import LoadingPage from "../features/loading/components/LoadingPage/LoadingPage";
import CreateLocationModalContainer from "../features/locations/components/CreateLocationModalContainer/CreateLocationModalContainer";
import { useConfigureEmployeeNotifications } from "../features/notifications/service/configureEmployeeNotifications";
import { useTopBanner, useUserStore } from "../globalStore/appStore";
import {
  isMobileViewOrApp,
  isNativeMobileApp,
} from "../globalStore/deviceInfoStore";
import { useAllLocationsQuery } from "../hooks/modelQueryHooks/useAllLocationsQuery";
import { getIds, getNames } from "../utils";
import EmployeeAppRoute from "./EmployeeAppRoute";
import LocationRoutes from "./LocationRoutes";
import PrivateRoute from "./PrivateRoute";
import RosterRoutes from "./RosterRoutes";
import { getDefaultRedirectUrl } from "../utils/routeUtils/routeUtils";
import { useManageUserSettings } from "../hooks/userHooks/useManageUserSettings";
import IntroductionPage from "../features/introduction/components/IntroductionPage/IntroductionPage";
import { ruleSets } from "../features/rules/service/ruleSets";
import { useCreateLocation } from "../hooks/useCreateLocation";
import LogRocket from "logrocket";
import { useRouteQuery } from "../hooks/useRouteQuery";
import { canSeeMobileAdminPage } from "../utils/flagsUtils/flags";
import KioskContainer from "../features/kiosk/components/KioskContainer/KioskContainer";
import UnsupportedViewForDevice from "../features/invalidPages/components/UnsupportedViewForDevice/UnsupportedViewForDevice";

const RosterRoutesWithRoutes = withRouter(RosterRoutes);

const AppRoutes = ({ user }) => {
  const { handleCountSeatsTaken } = useManageUserSettings();

  const [isCreateLocationOpen, setIsCreateLocationOpen] = useState(false);
  const [shouldShowTrial, setShouldShowTrial] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);

  const { handleDisplayTrialBanner } = useTopBanner();
  const { userName, plan, trialEnd } = useUserStore();

  const isUserAdmin = userIsAdmin(user);
  const isMobile = isMobileViewOrApp();
  const isNativeApp = isNativeMobileApp();
  const defaultRedirect = getDefaultRedirectUrl(isUserAdmin, isNativeApp);

  const routeQuery = useRouteQuery();
  const locationIDFromQuery = routeQuery.get("location-id");

  const userGroups = getUserGroups(user);

  useEffect(() => {
    handleDisplayTrialBanner(isUserAdmin, plan, trialEnd);
  }, [handleDisplayTrialBanner, isUserAdmin, plan, trialEnd]);

  // Just for logging bugs in logrocket
  useEffect(() => {
    try {
      // eslint-disable-next-line
      const info1 = user.session;
      // eslint-disable-next-line
      const info2 = getUserAttributes(user).sub;
      // eslint-disable-next-line
      const info3 = getFirstAndLastNameOfUser(user).firstName;
    } catch (error) {
      LogRocket.captureException(error, { extra: user });
    }
  }, [user]);

  const { locations, isQueryLoading, isMutationLoading, deleteLocation } =
    useAllLocationsQuery(user);

  const locationNames = useMemo(() => getNames(locations), [locations]);
  const locationIDs = useMemo(() => getIds(locations), [locations]);

  const {
    createNewScheduleViewLocation,
    createNewPrototypeLocation,
    createNewScheduleViewLocationByUploading,
  } = useCreateLocation(locationNames);

  useConfigureEmployeeNotifications(user, locationIDs);

  useEffect(() => {
    if (plan === null || isQueryLoading || isMutationLoading || !locations) {
      setIsAppLoading(true);
      return;
    }
    setIsAppLoading(false);
  }, [plan, isQueryLoading, isMutationLoading, locations]);

  useEffect(() => {
    if (isAppLoading) {
      return;
    }
    const needsTrial =
      isUserAdmin &&
      plan === PLAN.LITE &&
      trialEnd === null &&
      locations.length === 0;

    if (needsTrial) {
      setShouldShowTrial(true);
    }
  }, [isAppLoading, isUserAdmin, locations, plan, trialEnd]);

  const toggleCreateLocationModal = () => {
    setIsCreateLocationOpen((isModalOpenPrev) => !isModalOpenPrev);
  };
  const closeCreateLocationModal = () => {
    setIsCreateLocationOpen(false);
  };

  const templateNames = ["None", ...getNames(ruleSets)];

  if (isAppLoading) {
    return <LoadingPage />;
  }

  if (shouldShowTrial) {
    if (
      isUserAdmin &&
      (isNativeApp || (isMobile && !canSeeMobileAdminPage(user)))
    ) {
      return <UnsupportedViewForDevice />;
    }

    return (
      <IntroductionPage
        userName={userName}
        ruleTemplates={templateNames}
        createNewScheduleViewLocation={createNewScheduleViewLocation}
        locationNames={locationNames}
        setShouldShowTrial={setShouldShowTrial}
      />
    );
  }

  if (plan === PLAN.KIOSK) {
    return <KioskContainer user={user} />;
  }

  if (
    !isQueryLoading &&
    locations.length === 0 &&
    plan !== PLAN.LITE &&
    plan !== PLAN.EMPLOYEE &&
    plan !== PLAN.KIOSK &&
    plan !== PLAN.COORDINATOR &&
    plan !== PLAN.COLLABORATOR
  ) {
    return (
      <CreateLocationModalContainer
        handleCancelCreateLocation={closeCreateLocationModal}
        closeCreateLocationModal={closeCreateLocationModal}
        locationNames={locationNames}
        plan={plan}
        ruleTemplates={templateNames}
        createNewScheduleViewLocation={createNewScheduleViewLocation}
        createNewPrototypeLocation={createNewPrototypeLocation}
        createNewScheduleViewLocationByUploading={
          createNewScheduleViewLocationByUploading
        }
      />
    );
  }

  if (
    isUserAdmin &&
    locationIDFromQuery &&
    locationIDs.length > 0 &&
    !locationIDs.includes(locationIDFromQuery) &&
    !userGroups.includes(locationIDFromQuery)
  ) {
    return <Redirect to={defaultRedirect} />;
  }

  return (
    <>
      {!isMobile && isCreateLocationOpen && (
        <CreateLocationModalContainer
          handleCancelCreateLocation={closeCreateLocationModal}
          closeCreateLocationModal={closeCreateLocationModal}
          locationNames={locationNames}
          plan={plan}
          ruleTemplates={templateNames}
          createNewScheduleViewLocation={createNewScheduleViewLocation}
          createNewPrototypeLocation={createNewPrototypeLocation}
          createNewScheduleViewLocationByUploading={
            createNewScheduleViewLocationByUploading
          }
        />
      )}
      <Switch>
        {!isMobile && isUserAdmin && (
          <PrivateRoute
            path="/roster/help"
            isLoggedIn={true}
            component={<HelpContentful headerType={HEADER_TYPES.help} />}
          />
        )}
        {!isNativeApp && isUserAdmin && (
          <PrivateRoute
            path="/locations"
            isLoggedIn={true}
            component={
              <LocationRoutes
                toggleCreateLocationModal={toggleCreateLocationModal}
                locations={locations}
                deleteLocation={deleteLocation}
                handleCountSeatsTaken={handleCountSeatsTaken}
              />
            }
          />
        )}
        {!isMobile &&
          isUserAdmin &&
          (plan === PLAN.AI || plan === PLAN.COORDINATOR) && (
            <PrivateRoute
              path="/roster"
              isLoggedIn={true}
              component={
                <RosterRoutesWithRoutes
                  toggleCreateLocationModal={toggleCreateLocationModal}
                  locations={locations}
                />
              }
            />
          )}
        <PrivateRoute
          path="/employee-view"
          isLoggedIn={true}
          component={
            <EmployeeAppRoute isUserAdmin={isUserAdmin} isMobile={isMobile} />
          }
        />
        {isUserAdmin && (
          <PrivateRoute
            path="/billing"
            isLoggedIn={true}
            component={
              <BillingPage
                locations={locations}
                toggleCreateLocationModal={toggleCreateLocationModal}
              />
            }
          />
        )}
        <PrivateRoute
          path="/unsupported-device"
          isLoggedIn={true}
          component={<UnsupportedViewForDevice />}
        />
        <PrivateRoute
          path="/"
          isLoggedIn={true}
          component={<Redirect to={defaultRedirect} />}
        />
      </Switch>
    </>
  );
};

export default AppRoutes;
