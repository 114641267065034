export class OpenShiftsAlreadyFulfilledError extends Error {
  constructor(message) {
    super(message);
    this.name = "OpenShiftsAlreadyFulfilledError";
  }
}

export class GraphQLQueryError extends Error {
  constructor(message, data) {
    super(message);
    this.name = "GraphQLQueryError";
    this.data = data;
  }
}

export class LogRocketUserError extends Error {
  constructor(message, data) {
    super(message);
    this.name = "LogRocketUserError";
    this.data = data;
  }
}

export class RosterDoesNotExistError extends Error {
  constructor(message) {
    super(message);
    this.name = "RosterDoesNotExistError";
  }
}

export class InvalidUploadedFileError extends Error {
  constructor(message) {
    super(message);
    this.name = "InvalidUploadedFileError";
  }
}
