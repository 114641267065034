import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { DateTime } from "../../../utils";
import "./customDateRangePickerStyle.css";
import { getDateFnsLocale } from "../../../utils/generalUtils/locale";

const CollapsibleDateRangePicker = ({
  startDate,
  finishDate,
  setStartDate,
  setFinishDate,
  minDate,
  maxDate,
  urlUpdater,
  onDateChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const pickerRef = useRef();
  const calendarIconRef = useRef();

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  const handleClickEvent = useCallback(
    (e) => {
      if (
        calendarIconRef.current &&
        calendarIconRef.current.contains(e.target)
      ) {
        if (isOpen) {
          close();
        } else {
          open();
        }
      } else if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        close();
      }
    },
    [isOpen]
  );

  const handleEnterKeyPress = useCallback((e) => {
    if (e.code === "Enter") {
      close();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickEvent);
    document.addEventListener("keypress", handleEnterKeyPress);
    return () => {
      document.removeEventListener("click", handleClickEvent);
      document.removeEventListener("keypress", handleEnterKeyPress);
    };
  }, [isOpen, handleClickEvent, handleEnterKeyPress]);

  return (
    <div className="date-range-picker-whole-wrapper">
      <button
        ref={calendarIconRef}
        className="calendar-btn"
        data-testid="calendar-icon"
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
      </button>
      <div className={`date-picker-wrapper`} onClick={open} ref={pickerRef}>
        <div
          className={`inner-wrapper ${isOpen ? "opened" : "closed"}`}
          data-testid="date-range"
        >
          <DateRange
            locale={getDateFnsLocale()}
            minDate={minDate && new DateTime(minDate).getDate()}
            maxDate={maxDate && new DateTime(maxDate).getDate()}
            editableDateInputs={true}
            onChange={(item) => {
              const resultingStartDate = new DateTime(
                item.selection.startDate
              ).toFormat("AWS");
              const resultingFinishDate = new DateTime(
                item.selection.endDate
              ).toFormat("AWS");
              if (setStartDate && setFinishDate) {
                setStartDate(resultingStartDate);
                setFinishDate(resultingFinishDate);
              }
              if (
                DateTime.getDifferenceInDays(
                  new DateTime(resultingStartDate),
                  new DateTime(resultingFinishDate)
                ) > 1095
              ) {
                return;
              }
              if (urlUpdater) {
                urlUpdater(resultingStartDate, resultingFinishDate);
              }
              if (onDateChange) {
                onDateChange(item);
              }
            }}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: new DateTime(startDate).getDate(),
                endDate: new DateTime(finishDate).getDate(),
                key: "selection",
              },
            ]}
            dateDisplayFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CollapsibleDateRangePicker);
