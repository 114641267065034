import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import useModal from "../../../../../hooks/useModal";
import {
  PrototypeRerosterModal,
  ScheduleViewRerosterModal,
} from "../RerosterModal/RerosterModal";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const RerosterButton = ({
  initialStartDate,
  initialFinishDate,
  rosterID,
  locationID,
  rosterName,
  isScheduleView,
  warnings,
  location,
  areas,
  employees,
  initialAreaFilterValue,
}) => {
  const { isShowing, toggle } = useModal();

  return (
    <>
      {isScheduleView && isShowing ? (
        <ScheduleViewRerosterModal
          hide={toggle}
          isShowing={isShowing}
          initialStartDate={initialStartDate}
          initialFinishDate={initialFinishDate}
          rosterID={rosterID}
          locationID={locationID}
          rosterName={rosterName}
          isScheduleView={isScheduleView}
          warnings={warnings}
          location={location}
          areas={areas}
          employees={employees}
          initialAreaFilterValue={initialAreaFilterValue}
        />
      ) : (
        <PrototypeRerosterModal
          hide={toggle}
          isShowing={isShowing}
          initialStartDate={initialStartDate}
          initialFinishDate={initialFinishDate}
          rosterID={rosterID}
          locationID={locationID}
          rosterName={rosterName}
          isScheduleView={isScheduleView}
          warnings={warnings}
          location={location}
          areas={areas}
          employees={employees}
          initialAreaFilterValue={initialAreaFilterValue}
        />
      )}
      <BasicButton
        borderColor={"#1D9EC9"}
        hoverColor={"#1D9EC9"}
        customStyle={{ height: "35px", borderRadius: "10px", fontSize: "18px" }}
        onClick={toggle}
      >
        <FontAwesomeIcon
          icon={faArrowsRotate}
          style={{ marginRight: "10px" }}
        />
        Re-roster
      </BasicButton>
    </>
  );
};

export default RerosterButton;
