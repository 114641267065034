import { useCallback, useEffect, useState } from "react";
import { useUserStore } from "../../globalStore/appStore";
import {
  createDefaultUserSettings,
  createNewStripeSubscription,
  fetchUserSettings,
  updateStripeSubscription,
} from "../../utils/queryUtils/appQuery";
import { useMutation } from "@tanstack/react-query";
import { subscribeSettingsChange } from "../../utils/queryUtils/observers";
import { getNumberGlobalEmployeeModels } from "../../utils/queryUtils/locationQuery";
import {
  PLAN,
  getUserAttributes,
  isUserInCollaboratorGroup,
  isUserInCoordinatorGroup,
  isUserInEmployeeGroup,
  isUserInInternalGroup,
  isUserInKioskGroup,
  isUserInRostererGroup,
} from "../../features/auth/service/auth";

/**
 * Get "Settings" model from DB
 */
async function handleUserSettingsCheck(user) {
  if (!user) {
    return null;
  }
  let settings = await fetchUserSettings(user);
  if (settings) {
    return settings;
  }

  settings = await createDefaultUserSettings();
  return settings;
}

/**
 * Get total number of global employees by counting in each location
 */
async function countsTotalSeatsTaken(user) {
  if (!user) {
    throw new Error("Requires `user` to proceed");
  }
  const { sub, username } = getUserAttributes(user);
  const { totalEmployees: totalGlobalEmployees } =
    await getNumberGlobalEmployeeModels(sub, username);

  return { totalGlobalEmployees };
}

const ACTION_TYPES = {
  updateStripeSubscription: "updateStripeSubscription",
  createStripeSubscription: "createStripeSubscription",
};

function useStripeMutation() {
  const mutation = useMutation({
    mutationFn: (mutateFnParam) => {
      const { actionType, employeesNumber } = mutateFnParam;
      return new Promise((resolve, reject) => {
        switch (actionType) {
          case ACTION_TYPES.createStripeSubscription:
            createNewStripeSubscription(employeesNumber).then(resolve);
            return;
          case ACTION_TYPES.updateStripeSubscription:
            updateStripeSubscription(employeesNumber).then(resolve);
            return;
          default:
            reject(new Error("Unknown stripe mutation"));
        }
      });
    },
    onSuccess: (updatedData, mutateFnParam) => {
      if (mutateFnParam.onSuccess) {
        mutateFnParam.onSuccess(updatedData);
      }
    },
  });

  const updateSubscription = (employeesNumber) => {
    return new Promise((resolve) => {
      mutation.mutate({
        actionType: ACTION_TYPES.updateStripeSubscription,
        employeesNumber,
        onSuccess: (updatedSubscription) => {
          resolve(updatedSubscription);
        },
      });
    });
  };

  const createSubscription = (employeesNumber) => {
    return new Promise((resolve) => {
      mutation.mutate({
        actionType: ACTION_TYPES.createStripeSubscription,
        employeesNumber,
        onSuccess: (updatedSubscription) => {
          resolve(updatedSubscription);
        },
      });
    });
  };

  return {
    updateSubscription,
    createSubscription,
    isLoading: mutation.isLoading,
  };
}

// This hook is called once in the upper level in the components heirarchy
export function useManageUserSettings() {
  // This hook can be used once `user` is loaded
  const { user, plan, setUserPlanInfo } = useUserStore();
  const [settings, setSettings] = useState(null);

  /**
   * Figure out the "ACTUAL PLAN" of the user
   */
  const handleUserPlanUpdate = useCallback(
    (user, settings) => {
      // user is not loaded yet
      if (!user) {
        return setUserPlanInfo({ plan: null, isPaidPlan: null });
      }

      let plan = PLAN.LITE;

      if (isUserInRostererGroup(user) || isUserInInternalGroup(user)) {
        plan = PLAN.AI;
      } else if (isUserInKioskGroup(user)) {
        plan = PLAN.KIOSK;
      } else if (isUserInCoordinatorGroup(user)) {
        plan = PLAN.COORDINATOR;
      } else if (isUserInCollaboratorGroup(user)) {
        plan = PLAN.COLLABORATOR;
      } else {
        const { plan: planFromSettings } = settings;

        if (planFromSettings) {
          plan = planFromSettings;
        } else if (isUserInEmployeeGroup(user)) {
          plan = PLAN.EMPLOYEE;
        }
      }

      const paidPlans = [
        PLAN.AI,
        PLAN.MID,
        PLAN.COORDINATOR,
        PLAN.COLLABORATOR,
      ];
      const isPaidPlan = paidPlans.includes(plan);

      const AIPlans = [PLAN.AI, PLAN.COLLABORATOR];

      const isAIPlan = AIPlans.includes(plan);

      setUserPlanInfo({ plan, isPaidPlan, isAIPlan });
    },
    [setUserPlanInfo]
  );

  const handleSettingsUpdate = useCallback(
    (maxSeats, trialEnd) => {
      setUserPlanInfo({ maxGlobalEmployees: maxSeats, trialEnd });
    },
    [setUserPlanInfo]
  );

  const handleCountSeatsTaken = useCallback(
    async (user) => {
      if (!user) {
        return;
      }

      if (
        plan === PLAN.AI ||
        plan === PLAN.EMPLOYEE ||
        plan === PLAN.LITE ||
        plan === null
      ) {
        //No need to do this expensive calculation
        return;
      }

      try {
        const { totalGlobalEmployees } = await countsTotalSeatsTaken(user);
        setUserPlanInfo({
          totalGlobalEmployees,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [setUserPlanInfo, plan]
  );

  useEffect(() => {
    if (!settings) {
      return;
    }

    const { maxSeats, trialEnd } = settings;

    handleSettingsUpdate(maxSeats, trialEnd);
    handleUserPlanUpdate(user, settings);
  }, [settings, user, handleUserPlanUpdate, handleSettingsUpdate]);

  useEffect(() => {
    if (!user) {
      return;
    }

    handleCountSeatsTaken(user);
    // fetch settings for the first time (for first load)
    handleUserSettingsCheck(user).then((result) => {
      if (result) {
        setSettings(result);
      }
    });
  }, [user, handleCountSeatsTaken]);

  // Handle Settings model change from lambda
  useEffect(() => {
    const settingsSubscription = subscribeSettingsChange((s) => {
      setSettings(s);
    });
    return async () => {
      (await settingsSubscription)?.unsubscribe();
    };
  }, [setSettings]);

  return { handleCountSeatsTaken };
}

// This hook can be used anywhere that requires changes to stripe subscription
export function useUserSettingsUpdate() {
  const {
    totalGlobalEmployees,
    maxGlobalEmployees,
    setUserPlanInfo,
    setToCurrentUser,
  } = useUserStore();

  const { updateSubscription, createSubscription, isLoading } =
    useStripeMutation();

  /**
   * Update max seats in Stripe
   * @employeesNumDiff: positive (if added) or negative (if deleted) number
   *
   * Note - AI users should not call think function because they don't need to have stripe subscription.
   */
  const updateMaxSeatsBy = useCallback(
    (employeesNumDiff) => {
      if (employeesNumDiff === 0) {
        return;
      }
      const resultingEmployeesNum = totalGlobalEmployees + employeesNumDiff;
      updateSubscription(resultingEmployeesNum);
    },
    [totalGlobalEmployees, updateSubscription]
  );

  /**
   * Update max seats in Stripe
   */
  const updateMaxSeatsTo = useCallback(
    (maxSeats) => {
      if (maxSeats <= 0) {
        return;
      }
      updateSubscription(maxSeats);
    },
    [updateSubscription]
  );

  /**
   * Update max seats in Stripe
   * Returns: number of employees to cut
   *
   * Note - AI users should not call think function because they don't need to have stripe subscription.
   */
  const updateNumSeatsBy = useCallback(
    (employeesNumDiff, autoIncrementMaxSeatsIfExceeds = false) => {
      if (employeesNumDiff === 0) {
        return 0;
      }

      const resultingEmployeesNum = totalGlobalEmployees + employeesNumDiff;
      setUserPlanInfo({
        totalGlobalEmployees: resultingEmployeesNum,
      });

      if (
        autoIncrementMaxSeatsIfExceeds &&
        maxGlobalEmployees < resultingEmployeesNum
      ) {
        updateMaxSeatsTo(resultingEmployeesNum);
      }
    },
    [
      totalGlobalEmployees,
      setUserPlanInfo,
      maxGlobalEmployees,
      updateMaxSeatsTo,
    ]
  );

  /**
   * Create new subscription in stripe
   */
  const createNewSubscription = useCallback(
    async (employeesNumber) => {
      await createSubscription(employeesNumber);

      // setToCurrentUser is required because Stripe ID needs to be added to thec cognito custom attribute
      await setToCurrentUser();
    },
    [createSubscription, setToCurrentUser]
  );

  return {
    createNewSubscription,
    updateMaxSeatsBy,
    updateNumSeatsBy,
    isLoading,
  };
}
