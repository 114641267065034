import { KEYWORD_NO_TASK } from "../../constants/keywords";
import {
  buildShortIdsToEntityNamesDicts,
  getSubtasks,
  removeSingleShortIdsFromAllocation,
} from "../../features/rosterProblems/service/rosterUtils";
import {
  getDeletedItemsInArray,
  removeItemInArrayAt,
  strToArrCommaSeparated,
  twoArraysAreEqual,
} from "../generalUtils/array";
import { deepCopyObject, isObjectEmpty } from "../generalUtils/general";
import {
  getIds,
  getShortIds,
  splitAllocationSuffix,
} from "../modelUtils/generalModelUtil";
import { convertScheduleEmployeeToRosterEmployeeFilteredByFields } from "./rosterDataGetters";
import {
  convertScheduleEmployeeToGlobalEmployee,
  convertScheduleEmployeeToRosterEmployee,
} from "./scheduleDataGetters";

const MAIN_STREAM_FIELD_OWNERSHIP = {
  locationFields: [
    "ColorCodes",
    "CustomRules",
    "Demands",
    "OpenShifts",
    "ShiftGroups",
    "Shifts",
    "Skills",
    "Statistics",
    "TaskBlocks",
    "Tasks",
    "Areas",
    "completedOnboardingTasks",
    "name",
    "order",
    "settings",
    "shiftViewHiddenRows",
    "frontendSettings",
  ],
  rosterFields: ["isPublished", "isSnapshot", "numDays", "startDate"],
  globalEmployeeFields: [
    "AllocationsRecurring",
    "DaysRecurring",
    "FTE",
    "Preferences",
    "PreferencesRecurring",
    "PublishedAllocations",
    "Requests",
    "RuleValues",
    "TimeEntries",
    "email",
    "externalID",
    "finishDate",
    "locationName",
    "name",
    "registrations",
    "salary",
    "shifts",
    "skills",
    "startDate",
    "areas",
  ],
  rosterEmployeeFields: [
    "Allocations",
    "Days",
    "History",
    "RosteredAllocations",
  ],
};

const SNAPSHOT_FIELD_OWNERSHIP = {
  locationFields: ["shiftViewHiddenRows", "frontendSettings"],
  rosterFields: [
    "ColorCodes",
    "CustomRules",
    "Demands",
    "Employees",
    "ShiftGroups",
    "Shifts",
    "Skills",
    "Statistics",
    "TaskBlocks",
    "Tasks",
    "Areas",
    "isSnapshot",
    "name",
    "numDays",
    "startDate",
  ],
  globalEmployeeFields: [],
  rosterEmployeeFields: [
    "Allocations",
    "AllocationsRecurring",
    "Days",
    "DaysRecurring",
    "History",
    "RosteredAllocations",
    "RuleValues",
    "externalID",
    "name",
    "shifts",
    "skills",
    "areas",
  ],
};

const DELETE_PROPAGATED_FIELDS = {
  Areas: ["skills", "shifts", "tasks"], // TODO: Update
  Tasks: ["skills"],
  TaskBlocks: ["tasks", "shifts"],
  Shifts: ["skill"],
  ShiftGroups: ["skills", "tasks", "shifts", "areas"],
  Demands: ["skills", "tasks", "shifts", "areas"],
  CustomRule: ["name", "template"],
  OpenShifts: ["shift", "skills", "task"],
};

const DELETE_PROPAGATED_EMPLOYEE_FIELDS = {
  commaSeparatedStringFields: ["skills", "shifts", "areas"],
  dayStringFields: [
    "RosteredAllocations",
    "PreferencesRecurring",
    "Allocations",
    "AllocationsRecurring",
    "History",
  ],
  otherFields: ["Preferences", "PublishedAllocations", "RuleValues"],
};

function correctGlobalEmployeeFieldName(fieldName) {
  if (fieldName === "RuleValues") {
    return "ruleValues";
  }
  return fieldName;
}

// Compare if two fields are equal in terms of roster value.
// The two fields must be in same type.
function twoFieldsAreEqual(fieldOne, fieldTwo) {
  if (fieldOne === fieldTwo) {
    return true;
  }

  if (Array.isArray(fieldOne) && Array.isArray(fieldTwo)) {
    if (twoArraysAreEqual(fieldOne, fieldTwo)) {
      return true;
    }
  }

  return false;
}

/**
 * For Main stream update, Returns:
 * - updated roster fields
 * - updated location fields
 * - updated global employees with updated fields
 */
function getMainStreamUpdatedFields(
  previousRosterInView,
  updatedRosterInView,
  updatedRosterInViewFieldNames,
  updatedEmployeeFieldNames,
  deletedFieldItemShortIds
) {
  const {
    locationFields,
    rosterFields,
    globalEmployeeFields,
    rosterEmployeeFields,
  } = MAIN_STREAM_FIELD_OWNERSHIP;

  let updatedLocationModelFields = {};
  let updatedRosterModelFields = {};
  let updatedGlobalEmployeeModelsWithUpdatedFields = [];

  // Get updated rosterInView fields
  for (const fieldName of updatedRosterInViewFieldNames) {
    if (locationFields.includes(fieldName)) {
      updatedLocationModelFields[fieldName] = updatedRosterInView[fieldName];
    }
    if (rosterFields.includes(fieldName)) {
      updatedRosterModelFields[fieldName] = updatedRosterInView[fieldName];
    }
  }

  if (deletedFieldItemShortIds.length > 0) {
    // Propagate deletion to rosterInView fields (excluding Employees)
    updatedLocationModelFields = propagateDeletionToRosterInViewFields(
      deletedFieldItemShortIds,
      previousRosterInView,
      updatedRosterInView,
      updatedLocationModelFields,
      true
    );
  }

  // Get updated employee fields
  if (
    updatedRosterInViewFieldNames.includes("Employees") ||
    deletedFieldItemShortIds.length > 0
  ) {
    const previousEmployees = previousRosterInView.Employees;
    const updatedEmployees = updatedRosterInView.Employees;

    let isRosterModelEmployeeUpdated = false;

    for (const updatedEmployee of updatedEmployees) {
      const previousEmployee = previousEmployees.find(
        (employee) => employee.id === updatedEmployee.id
      );
      const updatedGlobalEmployeeFields = {};

      for (const fieldName of updatedEmployeeFieldNames) {
        const previousField = previousEmployee[fieldName];
        const updatedField = updatedEmployee[fieldName];
        if (globalEmployeeFields.includes(fieldName)) {
          if (!twoFieldsAreEqual(previousField, updatedField)) {
            updatedGlobalEmployeeFields[
              correctGlobalEmployeeFieldName(fieldName)
            ] = updatedEmployee[fieldName];
          }
        }
        if (rosterEmployeeFields.includes(fieldName)) {
          isRosterModelEmployeeUpdated = true;
        }
      }

      if (!isObjectEmpty(updatedGlobalEmployeeFields)) {
        updatedGlobalEmployeeModelsWithUpdatedFields.push({
          ...updatedGlobalEmployeeFields,
          id: updatedEmployee.id,
        });
      }
    }

    if (isRosterModelEmployeeUpdated) {
      const updatedRosterModelEmployees = updatedEmployees.map((employee) =>
        convertScheduleEmployeeToRosterEmployeeFilteredByFields(
          employee,
          rosterEmployeeFields
        )
      );
      updatedRosterModelFields.Employees = updatedRosterModelEmployees;
    }

    if (deletedFieldItemShortIds.length > 0) {
      // Propagate deletion to GlobalEmployee fields
      updatedGlobalEmployeeModelsWithUpdatedFields =
        propagateDeletionToGlobalEmployeeFields(
          deletedFieldItemShortIds,
          updatedRosterInView,
          previousRosterInView,
          updatedGlobalEmployeeModelsWithUpdatedFields
        );

      // Propagate deletion to Roster Employee fields
      updatedRosterModelFields = propagateDeletionToRosterEmployeeFields(
        deletedFieldItemShortIds,
        updatedRosterInView,
        previousRosterInView,
        updatedRosterModelFields,
        true
      );

      // Propagate deletion to CustomRules and ruleValues
      const resultingModels = propagateDeletionToRules(
        deletedFieldItemShortIds,
        updatedRosterInView,
        updatedLocationModelFields,
        updatedGlobalEmployeeModelsWithUpdatedFields,
        true
      );
      updatedLocationModelFields = resultingModels.updatedFields;
      updatedGlobalEmployeeModelsWithUpdatedFields =
        resultingModels.updatedGlobalEmployees;
    }
  }

  return {
    updatedLocationModelFields,
    updatedRosterModelFields,
    updatedGlobalEmployeeModelsWithUpdatedFields,
  };
}

/**
 * For Snapshot/Prototype update, Returns:
 * - updated roster fields
 * - updated location fields
 */
function getSnapshotUpdatedFields(
  previousRosterInView,
  updatedRosterInView,
  updatedRosterInViewFieldNames,
  deletedFieldItemShortIds
) {
  const { locationFields, rosterFields, rosterEmployeeFields } =
    SNAPSHOT_FIELD_OWNERSHIP;

  const updatedLocationModelFields = {};
  let updatedRosterModelFields = {};

  // Get updated rosterInView fields
  for (const fieldName of updatedRosterInViewFieldNames) {
    if (locationFields.includes(fieldName)) {
      updatedLocationModelFields[fieldName] = updatedRosterInView[fieldName];
    }
    if (rosterFields.includes(fieldName)) {
      updatedRosterModelFields[fieldName] = updatedRosterInView[fieldName];
    }
  }

  if (deletedFieldItemShortIds.length > 0) {
    updatedRosterModelFields = propagateDeletionToRosterInViewFields(
      deletedFieldItemShortIds,
      previousRosterInView,
      updatedRosterInView,
      updatedRosterModelFields,
      false
    );
  }

  if (
    updatedRosterInViewFieldNames.includes("Employees") ||
    deletedFieldItemShortIds.length > 0
  ) {
    const updatedEmployees = updatedRosterInView.Employees;
    const updatedRosterModelEmployees = updatedEmployees.map((employee) =>
      convertScheduleEmployeeToRosterEmployeeFilteredByFields(
        employee,
        rosterEmployeeFields
      )
    );
    updatedRosterModelFields.Employees = updatedRosterModelEmployees;

    if (deletedFieldItemShortIds.length > 0) {
      // Propagate deletion to Roster fields
      updatedRosterModelFields = propagateDeletionToRosterEmployeeFields(
        deletedFieldItemShortIds,
        updatedRosterInView,
        previousRosterInView,
        updatedRosterModelFields,
        false
      );

      // Propagate deletion to CustomRules and RuleValues
      const resultingModels = propagateDeletionToRules(
        deletedFieldItemShortIds,
        updatedRosterInView,
        updatedRosterModelFields,
        [],
        false
      );
      updatedRosterModelFields = resultingModels.updatedFields;
    }
  }

  return {
    updatedLocationModelFields,
    updatedRosterModelFields,
  };
}

export function getUpdatedDataModelFields(
  isScheduleView,
  previousRosterInView,
  updatedRosterInView,
  updatedRosterInViewFieldNames,
  updatedEmployeeFieldNames,
  rosterModel,
  locationModel
) {
  const isSnapshot = updatedRosterInView.isSnapshot;
  const isMainStream = !isSnapshot && isScheduleView;

  let updatedFields = null;

  // Find deleted items
  const deletedFieldItemShortIds = [];
  const fieldsWithoutShortId = ["Statistics", "frontendSettings"];
  for (const fieldName of updatedRosterInViewFieldNames) {
    if (fieldsWithoutShortId.includes(fieldName)) {
      continue;
    }
    const prevFieldItems = previousRosterInView[fieldName];
    const prevFieldShortIds = getShortIds(prevFieldItems);

    const updatedFieldItems = updatedRosterInView[fieldName];
    const updatedFieldShortIds = getShortIds(updatedFieldItems);

    const deletedItemShortIds = getDeletedItemsInArray(
      prevFieldShortIds,
      updatedFieldShortIds
    );

    deletedFieldItemShortIds.push(...deletedItemShortIds);
  }

  if (isMainStream) {
    updatedFields = getMainStreamUpdatedFields(
      previousRosterInView,
      updatedRosterInView,
      updatedRosterInViewFieldNames,
      updatedEmployeeFieldNames,
      deletedFieldItemShortIds
    );
  } else {
    updatedFields = getSnapshotUpdatedFields(
      previousRosterInView,
      updatedRosterInView,
      updatedRosterInViewFieldNames,
      deletedFieldItemShortIds
    );
  }

  const {
    updatedLocationModelFields,
    updatedRosterModelFields,
    updatedGlobalEmployeeModelsWithUpdatedFields = [],
  } = updatedFields;

  const updatedGlobalEmployees = locationModel.Employees.items.map(
    (employee) => {
      const updatedGlobalEmployeeFields =
        updatedGlobalEmployeeModelsWithUpdatedFields.find(
          ({ id }) => id === employee.id
        );
      if (updatedGlobalEmployeeFields) {
        return { ...employee, ...updatedGlobalEmployeeFields };
      }
      return employee;
    }
  );

  const resultingLocation = {
    ...locationModel,
    ...updatedLocationModelFields,
    Employees: {
      items: updatedGlobalEmployees,
    },
  };

  const resultingRoster = {
    ...rosterModel,
    ...updatedRosterModelFields,
  };

  return {
    resultingLocation,
    resultingGlobalEmployees: updatedGlobalEmployees,
    resultingRoster,
    updatedLocationModelFields,
    updatedRosterModelFields,
    updatedGlobalEmployeeModelsWithUpdatedFields,
    deletedFieldItemShortIds,
  };
}

/**
 * Given new schecule employees, turn them into global employees and roster employees
 */
export function getNewEmployeeModels(isMainStream, newScheduleEmployees) {
  const newGlobalEmployeeModels = [];
  const newRosterEmployeeModels = [];

  for (const newEmployee of newScheduleEmployees) {
    const { globalEmployee, rosterEmployee } =
      convertScheduleEmployeeToMainStreamModels(newEmployee);
    newRosterEmployeeModels.push(rosterEmployee);
    isMainStream && newGlobalEmployeeModels.push(globalEmployee);
  }

  return {
    newGlobalEmployeeModels,
    newRosterEmployeeModels,
  };
}

export function convertScheduleEmployeeToMainStreamModels(scheduleEmployee) {
  const { globalEmployeeFields, rosterEmployeeFields } =
    MAIN_STREAM_FIELD_OWNERSHIP;

  const globalEmployee =
    convertScheduleEmployeeToGlobalEmployee(scheduleEmployee);
  const rosterEmployee =
    convertScheduleEmployeeToRosterEmployee(scheduleEmployee);

  for (const [key] of Object.entries(globalEmployee)) {
    if (rosterEmployeeFields.includes(key)) {
      globalEmployee[key] = null;
    }
  }

  for (const [key, value] of Object.entries(rosterEmployee)) {
    if (key === "name") {
      rosterEmployee[key] = value;
      continue;
    }
    if (globalEmployeeFields.includes(key)) {
      rosterEmployee[key] = null;
    }
  }

  return { globalEmployee, rosterEmployee };
}

function propagateDeletionToRosterInViewFields(
  deletedEntities,
  previousRosterInView,
  updatedRosterInView,
  updatedModelFields,
  isMainStream
) {
  const rosterInView = deepCopyObject(updatedRosterInView);
  const updatedFields = deepCopyObject(updatedModelFields);

  const { Tasks: prevTasks, TaskBlocks: prevTaskBlocks } = previousRosterInView;

  const prevSubTasks = getSubtasks(prevTasks, prevTaskBlocks);

  for (const [fieldName, subFields] of Object.entries(
    DELETE_PROPAGATED_FIELDS
  )) {
    if (!isMainStream && fieldName === "OpenShifts") {
      continue;
    }
    let hasFieldUpdated = false;
    if (fieldName !== "CustomRule") {
      const fieldItems = deepCopyObject(
        updatedFields[fieldName] || rosterInView[fieldName]
      );
      for (const fieldItem of fieldItems) {
        for (const subField of subFields) {
          const value = fieldItem[subField];
          const valueAsArray = strToArrCommaSeparated(value);
          const updatedArray = valueAsArray.filter((item) => {
            if (deletedEntities.includes(item)) {
              return false;
            }

            const subTaskToBeDeleted = prevSubTasks.find(
              ({ shortId, taskShortId, blockShortId }) => {
                return (
                  shortId === item &&
                  (deletedEntities.includes(taskShortId) ||
                    deletedEntities.includes(blockShortId))
                );
              }
            );

            if (subTaskToBeDeleted) {
              return false;
            }

            return true;
          });
          const updatedValue = updatedArray.join(", ");

          if (value !== updatedValue) {
            if (
              fieldName === "OpenShifts" &&
              subField === "task" &&
              updatedValue === ""
            ) {
              fieldItem[subField] = KEYWORD_NO_TASK;
            } else {
              fieldItem[subField] = updatedValue;
            }
            hasFieldUpdated = true;
          }
        }
      }

      if (hasFieldUpdated) {
        updatedFields[fieldName] = fieldItems;
      }
    }
  }

  return updatedFields;
}

// Used for Main Stream propagation only
function propagateDeletionToGlobalEmployeeFields(
  deletedEntities,
  updatedRosterInView,
  previousRosterInView,
  updatedGlobalEmployeesModels
) {
  const rosterInView = deepCopyObject(updatedRosterInView);
  const rosterInViewEmployees = rosterInView.Employees;
  const updatedGlobalEmployees = deepCopyObject(updatedGlobalEmployeesModels);

  const { globalEmployeeFields } = MAIN_STREAM_FIELD_OWNERSHIP;
  const { commaSeparatedStringFields, dayStringFields, otherFields } =
    DELETE_PROPAGATED_EMPLOYEE_FIELDS;

  const {
    Areas: prevAreas,
    Shifts: prevShifts,
    ShiftGroups: prevShiftGroups,
    Tasks: prevTasks,
    TaskBlocks: prevTaskBlocks,
    Skills: prevSkills,
  } = previousRosterInView;

  const prevSubTasks = getSubtasks(prevTasks, prevTaskBlocks);
  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    prevAreas,
    prevShifts,
    prevShiftGroups,
    prevTasks,
    prevSubTasks,
    prevSkills
  );

  for (const employee of rosterInViewEmployees) {
    let hasFieldUpdated = false;
    const updatedEmployee = updatedGlobalEmployees.find(
      ({ id }) => id === employee.id
    ) || { id: employee.id };

    // Propagate to commaSeparatedStringFields
    for (const fieldName of commaSeparatedStringFields) {
      if (!globalEmployeeFields.includes(fieldName)) {
        continue;
      }
      const value = employee[fieldName];

      const valueAsArray = strToArrCommaSeparated(value);
      const updatedArray = valueAsArray.filter(
        (item) => !deletedEntities.includes(item)
      );
      const updatedValue = updatedArray.join(", ");

      if (value !== updatedValue) {
        updatedEmployee[fieldName] = updatedValue;
        hasFieldUpdated = true;
      }
    }

    // Propagate to dayStringFields
    for (const fieldName of dayStringFields) {
      if (!globalEmployeeFields.includes(fieldName)) {
        continue;
      }

      const updatedArray = employee[fieldName].map((rawValue) => {
        const [value, suffix] = splitAllocationSuffix(rawValue);

        const updatedValue = removeSingleShortIdsFromAllocation(
          value,
          deletedEntities,
          shortIdsToEntityNamesDicts
        );

        if (updatedValue && suffix && fieldName === "PreferencesRecurring") {
          return `${updatedValue}${suffix}`;
        }
        return updatedValue;
      });

      if (!twoArraysAreEqual(employee[fieldName], updatedArray)) {
        updatedEmployee[fieldName] = updatedArray;
        hasFieldUpdated = true;
      }
    }

    // Propagate to other fields
    for (const fieldName of otherFields) {
      if (fieldName === "PublishedAllocations") {
        let isPublishedAllocationUpdated = false;
        const updatedArray = employee[fieldName].map((value) => {
          const { draftAllocation, publishedAllocation } = value;
          const updatedDraftAllocation = !draftAllocation
            ? draftAllocation
            : removeSingleShortIdsFromAllocation(
                draftAllocation,
                deletedEntities,
                shortIdsToEntityNamesDicts
              );
          const updatedPublishedAllocation = !publishedAllocation
            ? publishedAllocation
            : removeSingleShortIdsFromAllocation(
                publishedAllocation,
                deletedEntities,
                shortIdsToEntityNamesDicts
              );

          if (
            updatedDraftAllocation !== draftAllocation ||
            updatedPublishedAllocation !== publishedAllocation
          ) {
            isPublishedAllocationUpdated = true;
          }
          return {
            ...value,
            draftAllocation: updatedDraftAllocation,
            publishedAllocation: updatedPublishedAllocation,
          };
        });

        if (isPublishedAllocationUpdated) {
          updatedEmployee[fieldName] = updatedArray;
          hasFieldUpdated = true;
        }
      }

      if (fieldName === "Preferences") {
        let isPreferencesUpdated = false;
        const updatedArray = employee[fieldName]
          .map((value) => {
            const { allocation: rawAllocation } = value;

            const [allocation, suffix] = splitAllocationSuffix(rawAllocation);

            const updatedAllocation = removeSingleShortIdsFromAllocation(
              allocation,
              deletedEntities,
              shortIdsToEntityNamesDicts
            );

            if (allocation !== updatedAllocation) {
              isPreferencesUpdated = true;
            }

            let resultingAllocation = updatedAllocation;

            if (!updatedAllocation) {
              return null;
            }

            if (suffix) {
              resultingAllocation = `${updatedAllocation}${suffix}`;
            }

            return {
              ...value,
              allocation: resultingAllocation,
            };
          })
          .filter((value) => value != null);

        if (isPreferencesUpdated) {
          updatedEmployee[fieldName] = updatedArray;
          hasFieldUpdated = true;
        }
      }
    }

    if (hasFieldUpdated) {
      if (!getIds(updatedGlobalEmployees).includes(updatedEmployee.id)) {
        updatedGlobalEmployees.push(updatedEmployee);
      }
    }
  }

  return updatedGlobalEmployees;
}

function propagateDeletionToRosterEmployeeFields(
  deletedEntities,
  updatedRosterInView,
  previousRosterInView,
  updatedModelFields,
  isMainStream
) {
  const rosterEmployeeFields = isMainStream
    ? MAIN_STREAM_FIELD_OWNERSHIP.rosterEmployeeFields
    : SNAPSHOT_FIELD_OWNERSHIP.rosterEmployeeFields;
  const { commaSeparatedStringFields, dayStringFields } =
    DELETE_PROPAGATED_EMPLOYEE_FIELDS;

  const rosterInView = deepCopyObject(updatedRosterInView);
  const updatedFields = deepCopyObject(updatedModelFields);
  const updatedEmployees =
    updatedFields.Employees ||
    rosterInView.Employees.map((employee) =>
      convertScheduleEmployeeToRosterEmployeeFilteredByFields(
        employee,
        rosterEmployeeFields
      )
    );

  let hasFieldUpdated = false;

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    previousRosterInView.Areas,
    previousRosterInView.Shifts,
    previousRosterInView.ShiftGroups,
    previousRosterInView.Tasks,
    getSubtasks(previousRosterInView.Tasks, previousRosterInView.TaskBlocks),
    previousRosterInView.Skills
  );

  // Propagate to commaSeparatedStringFields
  for (const employee of updatedEmployees) {
    for (const fieldName of commaSeparatedStringFields) {
      if (!rosterEmployeeFields.includes(fieldName)) {
        continue;
      }

      const value = employee[fieldName];
      const valueAsArray = strToArrCommaSeparated(value);
      const updatedArray = valueAsArray.filter(
        (item) => !deletedEntities.includes(item)
      );
      const updatedValue = updatedArray.join(", ");

      if (value !== updatedValue) {
        employee[fieldName] = updatedValue;
        hasFieldUpdated = true;
      }
    }
    // Propagate to dayStringFields
    for (const fieldName of dayStringFields) {
      if (!rosterEmployeeFields.includes(fieldName)) {
        continue;
      }

      const updatedArray = employee[fieldName].map((value) => {
        return removeSingleShortIdsFromAllocation(
          value,
          deletedEntities,
          shortIdsToEntityNamesDicts
        );
      });

      if (!twoArraysAreEqual(employee[fieldName], updatedArray)) {
        employee[fieldName] = updatedArray;
        hasFieldUpdated = true;
      }
    }
  }

  if (hasFieldUpdated) {
    updatedFields.Employees = updatedEmployees;
  }

  return updatedFields;
}

// Propagate deletion to CustomRules and RuleValues
function propagateDeletionToRules(
  deletedEntities,
  updatedRosterInView,
  updatedModelFields,
  updatedGlobalEmployeesModels = [],
  isMainStream
) {
  // remove such custom rule and get index
  const rosterInView = deepCopyObject(updatedRosterInView);
  const updatedFields = deepCopyObject(updatedModelFields);
  const updatedGlobalEmployees = deepCopyObject(updatedGlobalEmployeesModels);

  const rules = deepCopyObject(
    updatedFields["CustomRules"] || rosterInView["CustomRules"]
  );

  const deletedRuleIndices = [];
  const updatedRules = rules.filter(({ template }, index) => {
    const subTemplates = template.split(";");
    if (deletedEntities.includes(subTemplates[subTemplates.length - 1])) {
      deletedRuleIndices.push(index);
      return false;
    }
    return true;
  });

  const isRuleDeleted = deletedRuleIndices.length > 0;

  if (isRuleDeleted) {
    updatedFields.CustomRules = updatedRules;
  }

  // remove idx in roster employees
  if (!isMainStream && isRuleDeleted) {
    const employees = deepCopyObject(
      updatedFields["Employees"] || rosterInView["Employees"]
    );
    const updatedEmployees = employees.map((employee) => {
      const updatedRuleValues = removeItemInArrayAt(
        employee.RuleValues,
        deletedRuleIndices
      );
      return {
        ...employee,
        RuleValues: updatedRuleValues,
      };
    });

    updatedFields.Employees = updatedEmployees;
  }

  // remove idx in global employees
  if (isMainStream && isRuleDeleted) {
    const rosterInViewEmployees = rosterInView.Employees;
    for (const employee of rosterInViewEmployees) {
      const updatedEmployee = updatedGlobalEmployees.find(
        ({ id }) => id === employee.id
      ) || { id: employee.id };

      const updatedRuleValues = removeItemInArrayAt(
        employee.RuleValues,
        deletedRuleIndices
      );

      updatedEmployee.ruleValues = updatedRuleValues;

      if (!getIds(updatedGlobalEmployees).includes(updatedEmployee.id)) {
        updatedGlobalEmployees.push(updatedEmployee);
      }
    }
  }

  return {
    updatedFields,
    updatedGlobalEmployees,
  };

  // remove idx in ruleValue of globalEmployee
}

export function propagateDeletionToOtherRostersInMainStream(
  location,
  otherMainStreamRosters,
  deletedFieldItemShortIds
) {
  const { dayStringFields } = DELETE_PROPAGATED_EMPLOYEE_FIELDS;
  const updatedRosterIds = [];

  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    location.Areas,
    location.Shifts,
    location.ShiftGroups,
    location.Tasks,
    getSubtasks(location.Tasks, location.TaskBlocks),
    location.Skills
  );

  const propagatedMainStreamRosters = otherMainStreamRosters.map((roster) => {
    const employees = roster.Employees;
    const rosterEmployeeFields =
      MAIN_STREAM_FIELD_OWNERSHIP.rosterEmployeeFields;

    const updatedEmployees = employees.map((employee) => {
      for (const fieldName of dayStringFields) {
        if (!rosterEmployeeFields.includes(fieldName)) {
          continue;
        }

        if (!employee[fieldName]) {
          continue;
        }
        const updatedArray = employee[fieldName].map((value) => {
          return removeSingleShortIdsFromAllocation(
            value,
            deletedFieldItemShortIds,
            shortIdsToEntityNamesDicts
          );
        });

        if (!twoArraysAreEqual(employee[fieldName], updatedArray)) {
          employee[fieldName] = updatedArray;
          if (!updatedRosterIds.includes(roster.id)) {
            updatedRosterIds.push(roster.id);
          }
        }
      }
      return employee;
    });

    return {
      ...roster,
      Employees: updatedEmployees,
    };
  });

  return {
    updatedRosterIds,
    resultingRosters: propagatedMainStreamRosters,
  };
}
