import { useRef, useEffect, useCallback } from "react";

const useDragScroll = (
  containerRef,
  {
    scrollIncrement,
    thresholdTop,
    thresholdRight,
    thresholdBottom,
    thresholdLeft,
  }
) => {
  const isScrolling = useRef(false);

  const isCheckingDragPosition = useRef(false);

  // Modify the scroll function to accept a dynamic scroll increment
  const scroll = useCallback(
    (direction, dynamicIncrement) => {
      const container = containerRef.current;
      if (!container) return;

      if (direction === "up") {
        container.scrollTop -= dynamicIncrement;
      } else if (direction === "down") {
        container.scrollTop += dynamicIncrement;
      } else if (direction === "left") {
        container.scrollLeft -= dynamicIncrement;
      } else if (direction === "right") {
        container.scrollLeft += dynamicIncrement;
      }

      if (isScrolling.current) {
        requestAnimationFrame(() => scroll(direction, dynamicIncrement));
      }
    },
    [containerRef]
  );

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      const container = containerRef.current;
      if (!container) return;

      if (isCheckingDragPosition.current) return;

      isCheckingDragPosition.current = true;

      requestAnimationFrame(() => {
        const rect = container.getBoundingClientRect();

        const distanceToTop = event.clientY - rect.top;
        const distanceToBottom = rect.bottom - event.clientY;
        const distanceToLeft = event.clientX - rect.left;
        const distanceToRight = rect.right - event.clientX;

        let dynamicScrollIncrement = scrollIncrement;

        if (distanceToTop < thresholdTop) {
          dynamicScrollIncrement =
            scrollIncrement *
            (1 + (thresholdTop - distanceToTop) / thresholdTop);
          isScrolling.current = true;
          scroll("up", dynamicScrollIncrement);
        } else if (distanceToBottom < thresholdBottom) {
          dynamicScrollIncrement =
            scrollIncrement *
            (1 + (thresholdBottom - distanceToBottom) / thresholdBottom);
          isScrolling.current = true;
          scroll("down", dynamicScrollIncrement);
        } else if (distanceToLeft < thresholdLeft) {
          dynamicScrollIncrement =
            scrollIncrement *
            (1 + (thresholdLeft - distanceToLeft) / thresholdLeft);
          isScrolling.current = true;
          scroll("left", dynamicScrollIncrement);
        } else if (distanceToRight < thresholdRight) {
          dynamicScrollIncrement =
            scrollIncrement *
            (1 + (thresholdRight - distanceToRight) / thresholdRight);
          isScrolling.current = true;
          scroll("right", dynamicScrollIncrement);
        } else {
          isScrolling.current = false;
        }

        isCheckingDragPosition.current = false;
      });
    },
    [
      containerRef,
      scroll,
      scrollIncrement,
      thresholdTop,
      thresholdRight,
      thresholdBottom,
      thresholdLeft,
    ]
  );

  const onMouseUp = useCallback(() => {
    isScrolling.current = false;
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("dragover", onDragOver);
    }

    // Add global mouseup listener
    window.addEventListener("dragend", onMouseUp);

    return () => {
      if (container) {
        container.removeEventListener("dragover", onDragOver);
      }

      // Remove global mouseup listener
      window.removeEventListener("dragend", onMouseUp);
    };
  }, [containerRef, onDragOver, onMouseUp]);

  return {
    isScrolling,
  };
};

export default useDragScroll;
