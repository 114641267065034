import Fuse from "fuse.js";
import { useCallback, useMemo, useState } from "react";
import {
  reformatRules,
  getKeywordsList,
} from "../../../../rules/service/rules";
import RuleSearcherModalViewer from "../RuleSearcherModalViewer/RuleSearcherModalViewer";
import { v4 as uuidv4 } from "uuid";

const fuseOptions = {
  includeScore: true,
  threshold: 0.3,
  keys: [
    { name: "keyword1" },
    { name: "keyword2" },
    { name: "keyword3" },
    { name: "keyword4" },
    { name: "keyword5" },
  ],
};

const RuleSearcherModal = function ({
  addRules,
  setRuleSearcherOpen,
  title,
  rules,
}) {
  const reformattedRules = reformatRules(rules);
  const rulesKeywordsList = getKeywordsList(reformattedRules);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRules, setSelectedRules] = useState(
    new Array(reformattedRules.length).fill(false) // change length
  );

  // Custom sorting function
  const customSort = useCallback(
    (a, b) => {
      const scoreA = a.score || 0;
      const scoreB = b.score || 0;

      // Check if the search term is included in the rule text (case-insensitive)
      const includesA = a.item.keyword4
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const includesB = b.item.keyword4
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      if (includesA && !includesB) return -1;
      if (!includesA && includesB) return 1;

      return scoreA - scoreB;
    },
    [searchTerm]
  );

  const fuseData = useMemo(
    () =>
      rulesKeywordsList.map((item, index) => ({
        keyword1: item[0],
        keyword2: item[1],
        keyword3: item[2],
        keyword4: item[3],
        keyword5: item[4],
        originalIndex: index,
      })),
    [rulesKeywordsList]
  );

  const fuse = useMemo(() => new Fuse(fuseData, fuseOptions), [fuseData]);
  const toggleRuleIsSelected = (ind) => {
    let cloneSelectedRules = [...selectedRules];
    cloneSelectedRules[ind] = !cloneSelectedRules[ind];
    setSelectedRules(cloneSelectedRules);
  };

  const getExportedRules = () => {
    const exportedRules = [];
    for (let i = 0; i < reformattedRules.length; i++) {
      if (selectedRules[i]) {
        exportedRules.push({
          id: uuidv4(),
          ...reformattedRules[i],
        });
      }
    }
    return exportedRules;
  };

  // Perform search and format results
  const possibleRules = useMemo(() => {
    const results = fuse.search(searchTerm);
    if (results.length === 0) {
      return fuseData
        .slice(0, 10)
        .map((item) => [
          [
            item.keyword1,
            item.keyword2,
            item.keyword3,
            item.keyword4,
            item.keyword5,
          ],
          0,
          item.originalIndex,
        ]);
    }

    results.sort(customSort);
    return results
      .slice(0, 10)
      .map((result) => [
        [
          result.item.keyword1,
          result.item.keyword2,
          result.item.keyword3,
          result.item.keyword4,
          result.item.keyword5,
        ],
        result.score,
        result.item.originalIndex,
      ]);
  }, [searchTerm, fuse, fuseData, customSort]);

  return (
    <RuleSearcherModalViewer
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      addRules={addRules}
      getExportedRules={getExportedRules}
      setRuleSearcherOpen={setRuleSearcherOpen}
      possibleRules={possibleRules}
      toggleRuleIsSelected={toggleRuleIsSelected}
      reformattedRules={reformattedRules}
      selectedRules={selectedRules}
      title={title}
    />
  );
};

export default RuleSearcherModal;
