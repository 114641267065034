import { customConfirmAlert } from "../../../confirm/service/confirm";
import styles from "./RangeSelector.module.css";

const RangeSelector = ({ updateSelection, selection }) => {
  const onUpdate = async (e) => {
    const prevSelection = selection;
    const selectionIput = e.target.value;
    if (prevSelection === "fortnight" && selectionIput === "week") {
      const response = await customConfirmAlert({
        title: "Confirm",
        descriptions: [
          "The recurring fixed shifts that are no longer needed will be deleted. Do you want to continue?",
        ],
      });
      if (!response) {
        return;
      }
    }
    updateSelection(selectionIput, prevSelection);
  };

  return (
    <div>
      <label className={styles.label} htmlFor="period">
        Show
      </label>
      <select
        className={styles.selector}
        required
        name="period"
        id="period"
        onChange={onUpdate}
        value={selection}
      >
        <option value="week">Weekly recurring</option>
        <option value="fortnight">2 Weekly recurring</option>
      </select>
    </div>
  );
};

export default RangeSelector;
