import { useQuery } from "@tanstack/react-query";
import { getRostersByLocationId } from "../../utils/queryUtils/locationQuery";
import { useMemo } from "react";
import { deepCopyObject, sortByDateField } from "../../utils";

export function useAllRostersQuery(location) {
  const locationID = location.id;

  const query = useQuery({
    queryKey: ["allRosters", locationID],
    queryFn: () => getRostersByLocationId(locationID),
    placeholderData: location.Rosters
      ? location.Rosters.items.filter((item) => !item._deleted)
      : [],
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // Always longer than stale time
  });

  const rosters = useMemo(() => {
    if (!query.data) {
      return null;
    }
    const rosterModels = deepCopyObject(query.data);
    sortByDateField(rosterModels, "createdAt");

    return rosterModels;
  }, [query]);

  return {
    rosters,
    isQueryLoading: query.isLoading,
  };
}
