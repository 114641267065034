import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EmployeeAppMobileHeader.module.css";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter } from "../../../../../utils";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";
import EmployeeAppMonthSwitcher from "../../EmployeeAppMonthSwitcher/EmployeeAppMonthSwitcher";
import EmployeeAppWeekSwitcher from "../../EmployeeAppWeekSwitcher/EmployeeAppWeekSwitcher";
import ContentViewTypeSwitcher from "../../ContentViewTypeSwitcher/ContentViewTypeSwitcher";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";

const EmployeeAppMobileHeader = ({
  contentViewType,
  setContentViewType,
  calendarViewType,
  toggleCalendarViewType,
  setSelectedDate,
  selectedDate,
  switchableContentViewTypes,
  shouldShowCalendarTypeSwitcher,
  location,
}) => {
  const redirectTo = useRedirect();
  const isMonthView = calendarViewType === "month";

  const redirectToProfilePage = () => {
    redirectTo({
      pageName: PAGE_NAMES.mobileEmployeeViewProfile,
      query: {
        "location-id": location.id,
      },
    });
  };

  const shouldShowWeekMonthSwitcher =
    shouldShowCalendarTypeSwitcher &&
    contentViewType === EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule;

  return (
    <header className={styles.container}>
      <div className={styles.firstRow}>
        <div className={styles.firstRowLeft}>
          <button
            className={styles.profileButton}
            onClick={redirectToProfilePage}
          >
            <FontAwesomeIcon className={styles.profile} icon={faCircleUser} />
          </button>
          {isMonthView ? (
            <EmployeeAppMonthSwitcher
              date={selectedDate}
              setDate={setSelectedDate}
            />
          ) : (
            <EmployeeAppWeekSwitcher
              date={selectedDate}
              setDate={setSelectedDate}
            />
          )}
        </div>
        <div className={styles.firstRowRight}>
          {shouldShowWeekMonthSwitcher && (
            <button
              className={styles.calendarTypeSwitcher}
              onClick={toggleCalendarViewType}
            >
              {capitalizeFirstLetter(calendarViewType)}
            </button>
          )}
        </div>
      </div>
      {switchableContentViewTypes.length > 0 && (
        <div className={styles.secondRow}>
          <ContentViewTypeSwitcher
            switchableContentViewTypes={switchableContentViewTypes}
            contentViewType={contentViewType}
            setContentViewType={setContentViewType}
          />
        </div>
      )}
    </header>
  );
};

export default EmployeeAppMobileHeader;
