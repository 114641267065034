import { KEYWORD_NA, KEYWORD_OFF } from "../../../../../constants/keywords";
import {
  DateTime,
  getShiftHours,
  roundFloatToNearestQuarter,
} from "../../../../../utils";
import { getSolutionLeaveNumberRegex } from "../../../../../utils/generalUtils/regex";
import {
  convertAllocationInNameFormToShortIdForm,
  extractEntityNamesFromAllocation,
  extractEntityShortIdsFromAllocation,
} from "../../../../../utils/modelUtils/allocation";
import { allocationFulfilsShiftGroup } from "../../../service/preferencesAndFixedShifts";

const countOccurrences = (arr, val) =>
  arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

export function getShiftData(
  shifts,
  shiftGroups,
  employeeSolution,
  start_date,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
  shortIdsToEntityNamesDicts
) {
  const { annualLeaveKeyword } = customKeywordsUtilObj;
  const shift_data = [];

  for (let e = 0; e < employeeSolution.length; e++) {
    let employee_shift_data = { name: employeeSolution[e].name };
    let num_weekend_shifts = 0;

    const shift_counts = {};
    const shift_group_counts = {};

    shifts.forEach((shift) => {
      shift_counts[shift.name] = 0;
    });

    shiftGroups.forEach((shift) => {
      shift_group_counts[shift.name] = 0;
    });

    for (let d = 0; d < employeeSolution[e].days.length; d++) {
      let allocation = employeeSolution[e].days[d];
      allocation = allocation === "-" ? "" : allocation;

      const isLeave =
        getSolutionLeaveNumberRegex(annualLeaveKeyword).test(allocation);

      const allocationShortId = isLeave
        ? annualLeaveKeyword
        : convertAllocationInNameFormToShortIdForm(
            allocation,
            namesToEntityShortIdsDicts
          );

      const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
        extractEntityShortIdsFromAllocation(
          allocationShortId,
          shortIdsToEntityNamesDicts
        );

      const current_date = new DateTime(start_date).addDays(d).date;

      if (allocation !== "") {
        if (current_date.getDay() === 0 || current_date.getDay() === 6) {
          if (!isLeave && ![KEYWORD_NA, KEYWORD_OFF].includes(allocation)) {
            num_weekend_shifts += 1;
          }
        }
        shifts.forEach((shift) => {
          if (allocation === shift.name || shiftShortId === shift.shortId) {
            shift_counts[shift.name] += 1;
          }
        });
        shiftGroups.forEach((shiftGroup) => {
          const fulfilsShiftGroup = allocationFulfilsShiftGroup(
            shiftGroup,
            areaShortId,
            shiftShortId,
            taskShortId || enumeratedTaskShortId,
            null,
            customKeywordsUtilObj
          );

          if (fulfilsShiftGroup) {
            shift_group_counts[shiftGroup.name] += 1;
          }
        });
      }
    }

    const skill_stripped_solution = employeeSolution[e].days.map(
      (x) => x.split("-")[0]
    );

    for (let w = 0; w < skill_stripped_solution.length / 7; w++) {
      shiftGroups.forEach((shiftGroup) => {
        countOccurrences(
          skill_stripped_solution.slice(w * 7, (w + 1) * 7),
          shiftGroup.name
        );
      });
    }

    employee_shift_data.numWeekendShifts = num_weekend_shifts;
    employee_shift_data = Object.assign(
      {},
      employee_shift_data,
      shift_counts,
      shift_group_counts
    );
    // employee_shift_data += proportion_shifts_shiftgroups.values();

    shift_data.push(employee_shift_data);
  }

  return shift_data;
}

export function getHoursData(
  shifts,
  employeeSolution,
  employeesShiftHours,
  namesToEntityShortIdsDicts
) {
  const hours_data = [];

  for (let e = 0; e < employeeSolution.length; e++) {
    const employee_id = employeeSolution[e].employeeID;
    const employee_hours_data = { name: employeeSolution[e].name };

    const skill_stripped_solution = employeeSolution[e].days.map(
      (allocation) => {
        const { shiftName } = extractEntityNamesFromAllocation(
          allocation,
          namesToEntityShortIdsDicts
        );

        return shiftName;
      }
    );

    let total_hours = 0;
    for (let w = 0; w < skill_stripped_solution.length / 7; w++) {
      let week_hours = 0;
      shifts.forEach((shift) => {
        const num_shift_hours = roundFloatToNearestQuarter(
          employee_id in employeesShiftHours
            ? employeesShiftHours[employee_id][shift.shortId]
            : getShiftHours(shift)
        );
        week_hours +=
          countOccurrences(
            skill_stripped_solution.slice(w * 7, (w + 1) * 7),
            shift.name
          ) * num_shift_hours;
      });

      total_hours += week_hours;
      employee_hours_data["week" + (w + 1)] = week_hours;
    }

    employee_hours_data.totalHours = total_hours;
    hours_data.push(employee_hours_data);
  }

  return hours_data;
}

export function startsWithLeaveKeyword(value, leaveKeywords) {
  for (const keyword of leaveKeywords) {
    if (value.startsWith(keyword)) {
      return keyword;
    }
  }
  return null;
}

function getNumberAfterColon(input) {
  const regex = /[A-Za-z0-9]+: ([0-9.]+)/;
  const match = input.match(regex);
  if (match) {
    return match[1];
  } else {
    return 0;
  }
}

export function getSLDataByWeek(employeeSolution, leaveKeywords) {
  const sl_data_by_week = {};

  for (let e = 0; e < employeeSolution.length; e++) {
    const skill_stripped_solution = employeeSolution[e].days.map(
      (x) => x.split("-")[0]
    );
    const name = employeeSolution[e].name;

    for (let w = 0; w < skill_stripped_solution.length / 7; w++) {
      leaveKeywords.forEach((keyword) => {
        const sl_shifts = skill_stripped_solution
          .slice(w * 7, (w + 1) * 7)
          .filter((x) => x.startsWith(keyword));

        let week_leave_hours = 0;

        sl_shifts.forEach((sl_shift) => {
          week_leave_hours += parseFloat(getNumberAfterColon(sl_shift));
        });

        if (!sl_data_by_week[name]) {
          sl_data_by_week[name] = {};
        }
        if (!sl_data_by_week[name][keyword]) {
          sl_data_by_week[name][keyword] = {};
        }

        if (!sl_data_by_week[name][keyword][`week${w + 1}`]) {
          sl_data_by_week[name][keyword][`week${w + 1}`] = 0;
        }
        sl_data_by_week[name][keyword][`week${w + 1}`] += week_leave_hours;
      });
    }
  }
  //flatten the data
  const flattenData = [];
  for (let employee in sl_data_by_week) {
    for (let type in sl_data_by_week[employee]) {
      let weekData = sl_data_by_week[employee][type];
      weekData.name = employee;
      weekData.type = type;
      let totalHours = 0;
      for (let i = 1; i <= Object.keys(weekData).length - 2; i++) {
        totalHours += weekData[`week${i}`];
      }
      weekData.totalHours = totalHours;
      if (totalHours > 0) {
        flattenData.push(weekData);
      }
    }
  }
  return flattenData;
}

export function getSLPeriodData(employeeSolution, startDate, leaveKeywords) {
  const sl_data_by_period = [];
  const sl_period_data = [];

  for (let e = 0; e < employeeSolution.length; e++) {
    const skill_stripped_solution = employeeSolution[e].days.map(
      (x) => x.split("-")[0]
    );

    const sl_periods = getSLPeriods(employeeSolution[e].days, leaveKeywords);
    sl_period_data.push(sl_periods);

    sl_periods.forEach((period) => {
      leaveKeywords.forEach((keyword) => {
        const period_stripped_solution = skill_stripped_solution.slice(
          period[0],
          period[1] + 1
        );
        const sl_shifts = period_stripped_solution.filter((x) =>
          x.startsWith(keyword)
        );
        if (sl_shifts.length > 0) {
          const employee_period_leave_data = {
            name: employeeSolution[e]["name"],
          };

          let period_leave_hours = 0;

          sl_shifts.forEach((sl_shift) => {
            period_leave_hours += parseFloat(getNumberAfterColon(sl_shift));
          });

          employee_period_leave_data.startDate = new DateTime(startDate)
            .addDays(period[0])
            .toFormat("displayed-short");

          employee_period_leave_data.endDate = new DateTime(startDate)
            .addDays(period[1])
            .toFormat("displayed-short");

          employee_period_leave_data.hours = period_leave_hours;
          employee_period_leave_data.type = keyword;

          sl_data_by_period.push(employee_period_leave_data);
        }
      });
    });
  }

  return { hoursByPeriod: sl_data_by_period, periods: sl_period_data };
}

function getSLPeriods(employee_list, leaveKeywords) {
  const sl_periods = [];

  let first_allocation_in_period = "";
  let first_allocation_in_period_index = -1;
  for (let i = 0; i < employee_list.length; i++) {
    let current_allocation = employee_list[i].replace(/: [0-9.]+/, "");
    if (
      startsWithLeaveKeyword(current_allocation, leaveKeywords) &&
      current_allocation !== first_allocation_in_period
    ) {
      first_allocation_in_period_index = i;
    }
    if (startsWithLeaveKeyword(current_allocation, leaveKeywords)) {
      let j = i;
      while (
        j < employee_list.length &&
        startsWithLeaveKeyword(
          employee_list[j].replace(/: [0-9.]+/, ""),
          leaveKeywords
        ) &&
        employee_list[j].replace(/: [0-9.]+/, "") === current_allocation
      )
        j++;
      sl_periods.push([first_allocation_in_period_index, j - 1]);
      i = j - 1;
    }
    first_allocation_in_period = current_allocation;
  }

  return sl_periods;
}
