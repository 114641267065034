import {
  faClone,
  faDeleteLeft,
  faEllipsisV,
  faFileExport,
  faMagnifyingGlass,
  faUserPlus,
  faRotateLeft,
  faRotateRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ActionBar.module.css";
import PropTypes from "prop-types";
import AdjustColumnButton from "../AdjustColumnButton/AdjustColumnButton";
import { useToggleShow } from "../../../hooks/useToggleShow";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../globalStore/appStore";
import { PLAN } from "../../../features/auth/service/auth";

const SearchBar = ({ tableName, onFilterInputChanged }) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        className={styles.searchInput}
        type="text"
        placeholder="Search keywords"
        id={`filter-text-box-${tableName}`}
        onInput={() => onFilterInputChanged(`filter-text-box-${tableName}`)}
      />
      <button className={styles.searchBtn}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className={styles.searchIcon}
        />
      </button>
    </div>
  );
};

const DuplicateSelectedBtn = ({ selectedRows, duplicateSelectedRows }) => {
  return (
    <button
      className={`${styles.actionBtn} ${
        selectedRows.length === 0 && styles.disabled
      }`}
      disabled={selectedRows.length === 0}
      onClick={duplicateSelectedRows}
    >
      <FontAwesomeIcon icon={faClone} className={styles.actionIcon} />
      Duplicate
    </button>
  );
};

const DeleteSelectedBtn = ({ selectedRows, removeSelectedRows }) => {
  return (
    <button
      className={`${styles.actionBtn} ${
        selectedRows.length === 0 && styles.disabled
      }`}
      disabled={selectedRows.length === 0}
      onClick={() => {
        removeSelectedRows();
      }}
    >
      <FontAwesomeIcon icon={faDeleteLeft} className={styles.actionIcon} />
      Delete
    </button>
  );
};

const ExportBtn = ({ exportToExcel }) => {
  return (
    <button className={`${styles.moreBtn}`} onClick={exportToExcel}>
      <FontAwesomeIcon icon={faFileExport} className={styles.actionIcon} />
      Excel
    </button>
  );
};

const EmployeesAccessBtn = ({ selectedRows, openModal }) => {
  const { manuallyStepThroughTour } = useOnBoardingStore();
  return (
    <button
      className={`${styles.actionBtn} ${
        selectedRows.length === 0 && styles.disabled
      }`}
      disabled={selectedRows.length === 0}
      onClick={() => {
        openModal(selectedRows);
        manuallyStepThroughTour(0);
      }}
      data-tour="employee-access-button"
    >
      <FontAwesomeIcon icon={faUserPlus} className={styles.actionIcon} />
      Employees Access
    </button>
  );
};

const UndoRedoBtn = ({ handleUndoRedo }) => (
  <div className={styles.undoRedoBtnContainer}>
    <button className={styles.actionBtn} onClick={() => handleUndoRedo("undo")}>
      <FontAwesomeIcon icon={faRotateLeft} className={styles.undoRedoIcon} />
      Undo
    </button>
    <button className={styles.actionBtn} onClick={() => handleUndoRedo("redo")}>
      <FontAwesomeIcon icon={faRotateRight} className={styles.undoRedoIcon} />
      Redo
    </button>
  </div>
);

const ToggleColumnsBtn = ({ onClick }) => (
  <button className={styles.actionBtn} onClick={onClick}>
    <FontAwesomeIcon icon={faEye} className={styles.actionIcon} />
    Show/Hide
  </button>
);

const ActionBar = ({
  locationID,
  searchBarSettings = null,
  duplicateSelectedSettings = null,
  deleteSelectedSettings = null,
  exportSettings = null,
  adjustWidthSettings = null,
  employeesAccessSettings = null,
  undoRedoSettings = null,
  customComponents = [],
  customMoreOptionComponents = [],
  showMoreOptionLabel = true,
  toggleColumnSettings = null,
}) => {
  const {
    isOpen,
    toggleDropdown,
    selectorTriggerRef: moreOptionsTriggerRef,
    selectorRef: moreOptionsRef,
  } = useToggleShow();
  const { plan } = useUserStore();

  let moreOptions = [];
  if (exportSettings) {
    moreOptions = moreOptions.concat(() => (
      <ExportBtn exportToExcel={exportSettings.exportToExcel} />
    ));
  }
  moreOptions = moreOptions.concat(customMoreOptionComponents);

  return (
    <div className={styles.actionBar}>
      {searchBarSettings && (
        <SearchBar
          tableName={searchBarSettings.tableName}
          onFilterInputChanged={searchBarSettings.onFilterInputChanged}
        />
      )}
      {duplicateSelectedSettings && (
        <DuplicateSelectedBtn
          selectedRows={duplicateSelectedSettings.selectedRows}
          duplicateSelectedRows={
            duplicateSelectedSettings.duplicateSelectedRows
          }
        />
      )}
      {deleteSelectedSettings && (
        <DeleteSelectedBtn
          selectedRows={deleteSelectedSettings.selectedRows}
          removeSelectedRows={deleteSelectedSettings.removeSelectedRows}
        />
      )}
      {undoRedoSettings && (
        <UndoRedoBtn
          handleUndoRedo={undoRedoSettings.handleKeyDownForUndoRedo}
        />
      )}
      {employeesAccessSettings && ![PLAN.COORDINATOR].includes(plan) && (
        <EmployeesAccessBtn
          selectedRows={employeesAccessSettings.selectedRows}
          openModal={employeesAccessSettings.openModal}
        />
      )}
      {customComponents.map((Component, idx) => {
        if (Component) {
          return (
            <div key={idx}>
              <Component />
            </div>
          );
        }
        return null;
      })}
      <div className={styles.rightFixed}>
        {toggleColumnSettings && (
          <ToggleColumnsBtn onClick={toggleColumnSettings.openModal} />
        )}
        {adjustWidthSettings && (
          <AdjustColumnButton
            locationID={locationID}
            longestCellStr={adjustWidthSettings.longestStr}
            weekdayColWidth={adjustWidthSettings.weekdayColWidth}
            weekendColWidth={adjustWidthSettings.weekendColWidth}
            isWDayWEndSeparate={adjustWidthSettings.isWDayWEndSeparate}
            setWeekdayColWidth={adjustWidthSettings.setWeekdayColWidth}
            setWeekendColWidth={adjustWidthSettings.setWeekendColWidth}
            setIsWDayWEndSeparate={adjustWidthSettings.setIsWDayWEndSeparate}
            saveToDatabase={
              ![PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan) &&
              adjustWidthSettings.saveToDatabase
            }
            tableName={adjustWidthSettings.tableName}
            showWeekdayWeekendToggle={
              adjustWidthSettings.showWeekdayWeekendToggle
            }
            isInActionBar={true}
            disableSetForAllPages={adjustWidthSettings.disableSetForAllPages}
          />
        )}
        {exportSettings && (
          <button
            className={styles.moreOptionsToggle}
            ref={moreOptionsTriggerRef}
            onClick={() => {
              toggleDropdown();
            }}
            title="Export"
          >
            <FontAwesomeIcon icon={faEllipsisV} className={styles.moreIcon} />
            {showMoreOptionLabel && (
              <span className={styles.exportLabel}>Export</span>
            )}
          </button>
        )}
      </div>
      {isOpen && (
        <ul ref={moreOptionsRef} className={styles.moreOptions}>
          {moreOptions.map((Component, idx) => (
            <li key={idx} onClick={toggleDropdown}>
              <Component />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ActionBar.propTypes = {
  searchBarSettings: PropTypes.shape({
    tableName: PropTypes.string,
    onFilterInputChanged: PropTypes.func,
  }),
  duplicateSelectedSettings: PropTypes.shape({
    selectedRows: PropTypes.array,
    duplicateSelectedRows: PropTypes.func,
  }),
  deleteSelectedSettings: PropTypes.shape({
    selectedRows: PropTypes.array,
    removeSelectedRows: PropTypes.func,
  }),
  exportSettings: PropTypes.shape({
    exportToCsv: PropTypes.func,
  }),
};

export default ActionBar;
