import styles from "./TwoOptionsSwitcher.module.css";
import { SwitchField } from "@aws-amplify/ui-react";

const TwoOptionsSwitcher = ({
  onChange,
  isChecked,
  uncheckedLabel,
  checkedLabel,
}) => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>{uncheckedLabel}</span>
      <SwitchField
        onChange={onChange}
        isChecked={isChecked}
        isLabelHidden={true}
        trackColor="#009EC6"
        trackCheckedColor="#009EC6"
        style={{
          display: "flex",
        }}
      />
      <span className={styles.label}>{checkedLabel}</span>
    </div>
  );
};

export default TwoOptionsSwitcher;
