import { useMemo, useState } from "react";
import { DateTime, getAreaOptions, getOptionValues } from "../../../../utils";
import styles from "./PublishCalendarModal.module.css";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { customWarningAlert } from "../../../confirm/service/confirm";
import Select, { components } from "react-select";
import { reactSelectStyles } from "../../../../styles/reactSelectStyles";
import { CheckboxOption } from "../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import { KEYWORD_ALL } from "../../../../constants/keywords";

export const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    if (props.getValue().some(({ value }) => value === KEYWORD_ALL)) {
      values = "All areas";
    } else if (values.length === 1) {
      values = values[0].props.children;
    } else if (values.length === props.options.length) {
      values = "All areas";
    } else {
      values = `Multiple areas`;
    }
  } else {
    values = "All areas";
  }

  return (
    <components.ValueContainer {...props}>
      <div className={styles.valueContainer}>
        {values}
        {input}
      </div>
    </components.ValueContainer>
  );
};

const PublishCalendarModal = ({
  togglePublishModal,
  publishCalendar,
  defaultStartDate,
  defaultFinishDate,
  areas,
  initialAreaFilterValue = null,
}) => {
  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );
  const [selectedAreaOptions, setSelectedAreaOptions] = useState(() => {
    if (
      !initialAreaFilterValue ||
      initialAreaFilterValue.length === 0 ||
      initialAreaFilterValue.includes(KEYWORD_ALL)
    ) {
      return [selectAllAreasOption];
    }

    return areaOptions.filter(({ value }) =>
      initialAreaFilterValue.includes(value)
    );
  });
  const [finishDate, setFinishDate] = useState(defaultFinishDate);

  const publish = () => {
    const areasToPublish = areas.length === 0 ? null : getSelectedAreas();
    if (
      new DateTime(defaultStartDate).getDate() >
      new DateTime(finishDate).getDate()
    ) {
      customWarningAlert({
        title: "Invalid start date",
        descriptions: ["Please make sure start date is before finish date"],
      });
      return;
    }

    publishCalendar(defaultStartDate, finishDate, areasToPublish);
    togglePublishModal();
  };

  const getSelectedAreas = () => {
    const selectedAreaShortIds = getOptionValues(selectedAreaOptions);
    if (
      selectedAreaShortIds.includes(KEYWORD_ALL) ||
      selectedAreaShortIds.length === 0
    ) {
      return null; // Area doesn't matter
    }
    return areas.filter(({ shortId }) =>
      selectedAreaShortIds.includes(shortId)
    );
  };

  const isSelectAllSelected = () => {
    return selectedAreaOptions.some(
      (option) => option.value === selectAllAreasOption.value
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Publish my roster</h2>
        <div className={styles.fields}>
          {areas.length > 0 && (
            <div className={styles.field}>
              <span>Select area(s) to publish:</span>
              <Select
                options={[selectAllAreasOption, ...areaOptions]}
                styles={reactSelectStyles}
                isMulti
                closeMenuOnSelect={false}
                onChange={(selectedInputs, actionMeta) => {
                  const { action, option } = actionMeta;
                  switch (action) {
                    case "select-option":
                      if (option.value === selectAllAreasOption.value) {
                        setSelectedAreaOptions([selectAllAreasOption]);
                        break;
                      }
                      setSelectedAreaOptions(selectedInputs);
                      break;
                    case "deselect-option":
                      if (option.value === selectAllAreasOption.value) {
                        setSelectedAreaOptions([]);
                        break;
                      }
                      if (isSelectAllSelected()) {
                        setSelectedAreaOptions(
                          areaOptions.filter(
                            ({ value }) => option.value !== value
                          )
                        );
                        break;
                      }
                      setSelectedAreaOptions(selectedInputs);
                      break;
                    default:
                      setSelectedAreaOptions(selectedInputs);
                  }
                }}
                hideSelectedOptions={false}
                components={{
                  Option: CheckboxOption,
                  ValueContainer,
                }}
                value={selectedAreaOptions}
                maxMenuHeight={150}
                isSearchable={false}
                isClearable={false}
                isOptionSelected={(option) => {
                  if (isSelectAllSelected()) {
                    return true;
                  }
                  return selectedAreaOptions.some(
                    ({ value }) => value === option.value
                  );
                }}
              />
            </div>
          )}
          <div className={`${styles.field}`}>
            <span>Select the period to publish:</span>
            <div className={styles["dates-wrapper"]}>
              <p className={styles["start-date"]}>
                {new DateTime(defaultStartDate).toFormat("displayed-full")}
              </p>
              <input
                type="date"
                className={styles["finish-date"]}
                value={finishDate}
                onChange={(e) => {
                  setFinishDate(e.target.value);
                }}
              />
            </div>
          </div>
          <p className={styles.note}>
            All open shifts will be sent out for pick-up when published.
          </p>
        </div>
        <div className={styles.buttons}>
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            onClick={publish}
            customStyle={{
              borderRadius: "5px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Publish
          </BasicButton>
          <BasicButton
            color="white"
            borderColor="#219ec9"
            labelColor="#219ec9"
            hoverColor="#ebebeb"
            hoverlabelColor="#219ec9"
            onClick={() => togglePublishModal()}
            customStyle={{
              borderRadius: "5px",
              fontSize: "18px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Cancel
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default PublishCalendarModal;
