import { useCallback, useMemo, useRef, useState } from "react";
import styles from "./AreasGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultBooleanValueSetter,
  defaultFilterValueGetter,
  defaultValueSetter,
  dropdownOptionsValueSetter,
  flatOptions,
  getFulfilledXTaskBlocksOptions,
  getOptionValues,
  getShiftAndShiftGroupOptions,
  getShortIds,
  getTaskOptions,
  onFilterTextBoxChanged,
  strToArrCommaSeparated,
  suppressEnterKey,
} from "../../../../../utils";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import Checkbox from "../../../../../components/elements/AggridCheckbox/AggridCheckbox";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { checkCanAddShiftGroupToArea } from "../../../service/rosterUtils";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

export default function AreasGrid({
  areasData,
  numAreas,
  isSaving,
  setGridApiToParent,
  addData: addNewAreas,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateAreas,
  areasWarnings,
  removeData: removeAreas,
  gridApi,
  reorderAreasData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  customTopComponent,
  shifts,
  shiftGroups,
  skills,
  tasks,
  subTasks,
  taskBlocks,
  subtaskOptionValues,
  taskShortIds,
  getDataFromGrid,
  isScheduleView,
  areas,
  shortIdsToEntityNamesDicts,
  rosterName,
}) {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeAreas(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const isWarningPresent = areasWarnings && areasWarnings.length > 0;

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const { shiftOptions, shiftGroupOptions, selectAllShiftsOption } = useMemo(
    () => getShiftAndShiftGroupOptions(shifts, shiftGroups),
    [shifts, shiftGroups]
  );

  const shiftAndShiftGroupOptions = useMemo(
    () => [
      {
        label: null,
        options: [selectAllShiftsOption],
      },
      {
        label: "",
        options: shiftOptions,
      },
      {
        label: "",
        options: shiftGroupOptions,
      },
    ],
    [shiftOptions, shiftGroupOptions, selectAllShiftsOption]
  );

  const skillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );

  const checkFieldValueIsDuplicate = useCallback(
    (fieldName, newValue) => {
      const values = areasData.map((area) => area[fieldName]);
      return values.includes(newValue);
    },
    [areasData]
  );

  const {
    noTasksX,
    noTaskOption,
    allXTaskBlocksInfo,
    allXTaskBlocksOptions,
    allTaskOptions,
  } = useMemo(
    () => getTaskOptions(tasks, subTasks, taskBlocks),
    [tasks, subTasks, taskBlocks]
  );

  const taskDataOptions = useMemo(
    () => [
      {
        label: "",
        options: [...allXTaskBlocksOptions],
      },
      {
        label: "",
        options: allTaskOptions,
      },
      {
        label: null,
        options: [noTaskOption],
      },
    ],
    [allTaskOptions, allXTaskBlocksOptions, noTaskOption]
  );

  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        sortable: true,
        suppressSizeToFit: true,
        width: (width - 120) / 4,
        valueSetter: (params) => {
          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Area name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (
            params.newValue &&
            checkFieldValueIsDuplicate("name", params.newValue)
          ) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }
          return defaultValueSetter(params);
        },
        cellClassRules: {
          "invalid-cell": (params) => {
            if (!params.value) {
              return true;
            }
            if (areasWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                areasWarnings
              );
            }
          },
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
      },
      {
        field: "skills",
        cellClassRules: {
          "invalid-cell": (params) => {
            if (areasWarnings) {
              return defaultWarningHighlighterCellClassRules(
                params,
                areasWarnings
              );
            }
          },
        },
        width: (width - 120) / 4,
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: skillOptions,
          allowSelectAll: true,
        },
        cellEditorPopup: true,
        valueSetter: (params) => {
          if (params.newValue === KEYWORD_ALL) {
            params.data[params.column.colId] = "";
            return true;
          }
          return dropdownOptionsValueSetter(
            params,
            skillOptions,
            shortIdsToEntityNamesDicts
          );
        },
        suppressKeyboardEvent: suppressEnterKey,
        valueFormatter: (params) => {
          if (params.value === "") {
            return KEYWORD_ALL;
          }
          return convertShortIdsCellValueToEntityNames(params.value, skills);
        },
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      },
      {
        headerName: "Shifts or Shift Groups",
        field: "shifts",
        width: (width - 120) / 4,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (areasWarnings) {
              const shiftsWarning = areasWarnings.find(
                ({ warningType }) => warningType === "invalid shifts"
              );
              if (params.value && shiftsWarning) {
                const shiftNameArr = strToArrCommaSeparated(params.value);
                for (const name of shiftNameArr) {
                  if (shiftsWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: shiftAndShiftGroupOptions,
          allowSelectAll: true,
          selectAllOption: selectAllShiftsOption,
          isOptionDisabled: (rowData, option) => {
            const area = areas.find(
              ({ shortId }) => rowData.shortId === shortId
            );
            const matchingShiftGroup = shiftGroups.find(
              ({ shortId }) => shortId === option.value
            );
            if (area && matchingShiftGroup) {
              return !checkCanAddShiftGroupToArea(matchingShiftGroup, area);
            }
            return false;
          },
        },
        cellEditorPopup: true,
        valueFormatter: (params) => {
          if (params.value === "") {
            return KEYWORD_ALL;
          }

          return convertShortIdsCellValueToEntityNames(params.value, [
            ...shifts,
            ...shiftGroups,
          ]);
        },
        valueSetter: (params) => {
          if (params.newValue === KEYWORD_ALL) {
            params.data[params.column.colId] = "";
            return true;
          }
          return dropdownOptionsValueSetter(
            params,
            [selectAllShiftsOption, ...shiftOptions, ...shiftGroupOptions],
            shortIdsToEntityNamesDicts
          );
        },
        suppressKeyboardEvent: suppressEnterKey,
        filterValueGetter: (params) =>
          defaultFilterValueGetter(
            params,
            "shifts",
            [...shifts, ...shiftGroups],
            [KEYWORD_ALL]
          ),
      },
      {
        field: "tasks",
        width: (width - 120) / 4 - 2,
        suppressMovable: true,
        suppressSizeToFit: true,
        cellEditor: "dropdownMultiSelector",
        cellEditorParams: {
          options: taskDataOptions,
          customIsOptionSelected: (option, selectedOptions) => {
            const isSelected = selectedOptions.some(
              ({ value }) => value === option.value
            );
            if (isSelected) {
              return true;
            }

            const selectedValues = selectedOptions.map(
              (option) => option.value
            );

            // Get selected all x task blocks
            const selectedAllXTaskBlockShortIds = [];
            allXTaskBlocksInfo.forEach((info) => {
              const { value, taskBlockShortId } = info;
              if (selectedValues.includes(value)) {
                selectedAllXTaskBlockShortIds.push(taskBlockShortId);
              }
            });

            for (const taskBlockShortId of selectedAllXTaskBlockShortIds) {
              if (option.value.endsWith(` ${taskBlockShortId}`)) {
                return true;
              }
            }
            return false;
          },
          additionalOnSelectHandler: (option, selectedInputs) => {
            let updatedSelectedInputs = [...selectedInputs];
            const allXTaskBlockValues = allXTaskBlocksInfo.map(
              (info) => info.value
            );

            // if "allXTasks" is checked, unSelect all related subtasks
            const { value } = option;
            if (allXTaskBlockValues.includes(value)) {
              const relatedOptions = allXTaskBlocksInfo
                .find((info) => info.value === value)
                .relatedOptions.map(({ value }) => value);

              updatedSelectedInputs = selectedInputs.filter((option) => {
                return !relatedOptions.includes(option.value);
              });
            }

            // If selected option fulfills xTaskBlocks, unselect the option
            const fulfilledXTaskBlocksOptions = getFulfilledXTaskBlocksOptions(
              updatedSelectedInputs,
              allXTaskBlocksInfo
            );

            if (fulfilledXTaskBlocksOptions.length > 0) {
              const xTaskBlocksRelatedOptions = allXTaskBlocksInfo
                .map((info) => info.relatedOptions.map(({ value }) => value))
                .flat();

              updatedSelectedInputs = updatedSelectedInputs.filter((option) => {
                return !xTaskBlocksRelatedOptions.includes(option.value);
              });

              updatedSelectedInputs = [
                ...updatedSelectedInputs,
                ...fulfilledXTaskBlocksOptions.map(({ label, value }) => ({
                  label,
                  value,
                })),
              ];
            }

            return updatedSelectedInputs;
          },
          additionalOnDeselectHandler: (option, selectedInputs) => {
            const { value } = option;
            const selectedValues = getOptionValues(selectedInputs);

            if (subtaskOptionValues.includes(value)) {
              let blockShortId;
              if (value.startsWith("no task ")) {
                blockShortId = value.split(" ")[value.split(" ").length - 1];
              } else {
                const subtask = subTasks.find((s) => s.shortId === value);
                blockShortId = subtask.blockShortId;
              }

              const matchingAllXTaskInfo = allXTaskBlocksInfo.find(
                (info) => info.taskBlockShortId === blockShortId
              );

              if (matchingAllXTaskInfo) {
                const matchingAllXTaskLabel = matchingAllXTaskInfo.value;
                if (!selectedValues.includes(matchingAllXTaskLabel)) {
                  return null;
                }
              }

              // tick all subtasks with blockShortId
              const allSubtasksWithBlockName = [
                ...getShortIds(
                  subTasks.filter((s) => s.blockShortId === blockShortId)
                ),
                `no task ${blockShortId}`,
              ];

              const tickedSubTasks = allSubtasksWithBlockName.filter(
                (s) => s !== value
              );

              // untick all X tasks
              const allXTaskBlockValue = allXTaskBlocksInfo.find(
                ({ taskBlockShortId }) => taskBlockShortId === blockShortId
              )?.value;

              const updatedSelectedInputValues = [...tickedSubTasks];

              selectedInputs.forEach((input) => {
                const { value } = input;
                if (
                  value !== allXTaskBlockValue &&
                  !updatedSelectedInputValues.includes(value)
                ) {
                  updatedSelectedInputValues.push(value);
                }
              });

              return taskDataOptions
                .map(({ options }) => options)
                .flat()
                .filter(({ value }) =>
                  updatedSelectedInputValues.includes(value)
                );
            }
            return null;
          },
          isOptionDisabled: (rowData, option, selectedOptions) => {
            if (taskBlocks.length === 0) return false;

            const selectedValues = selectedOptions.map(
              (option) => option.value
            );

            const allXTaskBlockValues = allXTaskBlocksInfo.map(
              (info) => info.value
            );

            for (const allXTaskBlockValue of allXTaskBlockValues) {
              if (
                selectedValues.includes(allXTaskBlockValue) &&
                taskShortIds.includes(option.value)
              ) {
                return true;
              }
            }

            const taskOptionValues = getShortIds(tasks);

            let isTaskSelected = false;
            let isSubtaskSelected = false;

            selectedValues.forEach((value) => {
              if (taskOptionValues.includes(value)) {
                isTaskSelected = true;
              }

              if (subtaskOptionValues.includes(value)) {
                isSubtaskSelected = true;
              }
            });

            // the option task or subtask???
            const isTaskOption = taskOptionValues.includes(option.value);

            if (isTaskOption && isSubtaskSelected) {
              return true;
            }

            if (!isTaskOption && isTaskSelected) {
              return true;
            }

            return false;
          },
        },
        cellEditorPopup: true,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (areasWarnings) {
              const areasWarning = areasWarnings.find(
                ({ warningType }) => warningType === "invalid tasks"
              );
              if (params.value && areasWarning) {
                const taskNamesArr = strToArrCommaSeparated(params.value);
                for (const name of taskNamesArr) {
                  if (areasWarning.values.includes(name)) {
                    return true;
                  }
                }
              }
            }
          },
        },
        valueSetter: (params) => {
          if (params.newValue === KEYWORD_ALL) {
            params.data[params.column.colId] = "";
            return true;
          }
          return dropdownOptionsValueSetter(
            params,
            flatOptions(taskDataOptions),
            shortIdsToEntityNamesDicts
          );
        },
        valueFormatter: (params) => {
          const value = params.value;
          if (value === "") {
            return KEYWORD_ALL;
          }

          const valueArr = strToArrCommaSeparated(value);

          const formatted = valueArr.map((val) => {
            const allXTasksLabel = allXTaskBlocksOptions.find(
              (info) => info.value === val
            );
            if (allXTasksLabel) {
              return allXTasksLabel.label;
            }

            if (val === noTaskOption.label) {
              return noTaskOption.value;
            }

            const task = tasks.find((task) => task.shortId === val);
            if (task) {
              return task.name;
            }

            const subTask = subTasks.find((subTask) => subTask.shortId === val);
            if (subTask) {
              return subTask.name;
            }

            const noTaskX = noTasksX.find(({ shortId }) => shortId === val);
            if (noTaskX) {
              return noTaskX.name;
            }

            return val;
          });

          return formatted.join(", ");
        },
        suppressKeyboardEvent: suppressEnterKey,
      },
      {
        headerName: "A.I. assigned",
        field: "autoAssigned",
        width: 120,
        cellRenderer: "checkboxRenderer",
        suppressMovable: true,
        suppressSizeToFit: true,
        valueSetter: defaultBooleanValueSetter,
      },
    ],
    [
      shortIdsToEntityNamesDicts,
      selectAllShiftsOption,
      shiftAndShiftGroupOptions,
      shiftGroupOptions,
      shiftGroups,
      shiftOptions,
      shifts,
      skillOptions,
      skills,
      width,
      allXTaskBlocksInfo,
      allXTaskBlocksOptions,
      checkFieldValueIsDuplicate,
      noTaskOption,
      noTasksX,
      subTasks,
      subtaskOptionValues,
      taskBlocks,
      taskDataOptions,
      taskShortIds,
      tasks,
      areasWarnings,
      areas,
    ]
  );

  return (
    <Layout
      title={isScheduleView ? `Areas` : `Areas - ${rosterName}`}
      headerRight={
        isScheduleView
          ? null
          : () => (
              <AddRowButton
                rowName={"Areas"}
                addSingle={() => {
                  if (!isCellEditing) addNewAreas(1);
                }}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addNewAreas(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt(`How many areas should I add?`, "1")
                      );
                      addNewAreas(num);
                    },
                  },
                ]}
                partialButtonTestId="add-ares-button"
                postAddAction={() => {}}
              />
            )
      }
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles.topLine}>
          <div className={styles.left}>{customTopComponent}</div>
          <div className={styles.right}>
            {isScheduleView && (
              <AddRowButton
                rowName={"Areas"}
                addSingle={() => {
                  if (!isCellEditing) addNewAreas(1);
                }}
                addOptions={[
                  {
                    label: "Add New",
                    onClick: () => addNewAreas(1),
                  },
                  {
                    label: "Add Multiple",
                    onClick: () => {
                      const num = parseInt(
                        prompt(`How many areas should I add?`, "1")
                      );
                      addNewAreas(num);
                    },
                  },
                ]}
                partialButtonTestId="add-ares-button"
                postAddAction={() => {}}
              />
            )}
          </div>
        </div>
        <ActionBar
          searchBarSettings={{
            tableName: "area",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateAreas,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeAreas(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
        />
        <DataEntryTable
          columnDefs={columnDefs}
          rowData={areasData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderAreasData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          components={{
            checkboxRenderer: Checkbox,
            dropdownMultiSelector: DropdownMultiSelector,
          }}
          enableRowDragAnimation={!isSaving}
          tableName="areas"
          getDataFromGrid={getDataFromGrid}
          shouldHaveCheckBoxLeftMargin={true}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
        />
        <p className={styles["num-line"]}>
          <span className={styles.emph}>Number of areas:</span> {numAreas}
        </p>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(areasWarnings)}
          />
        </div>
      )}
    </Layout>
  );
}
