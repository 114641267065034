import styles from "./ScheduleHistoryDataContainer.module.css";
import { useMemo } from "react";
import { TABLE_NAMES } from "../../../../constants";
import {
  getAllHistoryFromGrid,
  getHistoryDayColumns,
  getLongestAllocationStringInRowData,
  getNames,
  parseEmployeeModelToHistoryGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import HistoryGrid from "../../../rosterProblems/components/History/HistoryGrid/HistoryGrid";
import { updateEmployeesWithOldEmployeeData } from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import { appSettings } from "../../../locations/components/GlobalSettings/globalSettingsList";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";

const ScheduleHistoryDataContainer = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  customKeywordsData,
  globalEmployees,
}) => {
  const { plan } = useUserStore();
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const {
    name,
    employees,
    skills,
    tasks,
    shifts,
    shiftGroups,
    areas,
    startDate: rosterStartDate,
    frontendSettings,
    subTasks,
    enumeratedTasks,
    numDays,
    isSnapshot,
  } = fields;

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const isMainStream = !isRoster && !isSnapshot;
  const employeeNames = useMemo(() => getNames(employees), [employees]);

  const shouldInsertOffOnPasteBlank = frontendSettings.find(
    (item) => item.name === appSettings[0].name
  )
    ? true
    : false;

  let startDate = periodStartDate;
  if (isMainStream) {
    startDate = rosterStartDate;
  }

  const applyEmployeeUpdates = (updatedEmployees) => {
    const updatedRoster = {
      ...roster,
      Employees: updateEmployeesWithOldEmployeeData(
        employees,
        updatedEmployees
      ),
    };
    updateFields(["Employees"], updatedRoster, roster, ["History"]);
  };

  const historyWarnings = useMemo(() => {
    return warnings ? warnings.History : null;
  }, [warnings]);

  let historyColumns = useMemo(() => getHistoryDayColumns(14), []);

  const { predefinedShiftOptions } =
    interpretCustomKeywordsData(customKeywordsData);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  let employeesData = useMemo(
    () => parseEmployeeModelToHistoryGridFormat(employees, 14, true),
    [employees]
  );

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const getDataFromGrid = (gridApi) =>
    getAllHistoryFromGrid(gridApi, historyColumns);

  const updateHistory = (newEmployees) => {
    applyEmployeeUpdates(newEmployees);
  };

  const TopControllerComponent = (
    <div className={styles.topComponents}>
      <AreaFilter
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        onMenuClose={saveAreaFilter}
        defaultValue={initialAreaFilterValue}
        containerStyle={{ marginBottom: "8px" }}
      />
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"history"}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={() => {}}
      updateItemsToDB={updateHistory}
      duplicateItemsToDB={() => {}}
      removeItemsFromDB={() => {}}
      getDataFromGrid={getDataFromGrid}
      getToBeDeletedItems={() => {}}
      parseSelectedRowsToDuplicableInfo={() => {}}
      undoRedoParams={{
        tableName: TABLE_NAMES.ROSTER_HISTORY,
        getCustomGridSnapshot: null,
      }}
    >
      <HistoryGrid
        locationID={locationID}
        startDate={startDate}
        employeesData={employeesData}
        isSaving={isSaving}
        setGridApiToParent={setGridApi}
        historyColumns={historyColumns}
        historyWarnings={historyWarnings}
        longestStr={longestStr}
        gridApi={gridApi}
        predefinedKeywords={predefinedShiftOptions}
        isScheduleView={!isRoster}
        customTopComponent={TopControllerComponent}
        enumeratedTasks={enumeratedTasks}
        shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
        shifts={shifts}
        tasks={tasks}
        subTasks={subTasks}
        areas={areas}
        employeeNames={employeeNames}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        rosterName={name}
      />
    </GridActionHandler>
  );
};

export default ScheduleHistoryDataContainer;
