import Holidays from "date-holidays";
import { DateTime, getShiftTask, isNightShift } from "../../../../../utils";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";

const BLANK = "";

function getAllocationOnDayOrDayBefore(
  checkedDate,
  allocationOnDate,
  allocationOnDateBefore,
  shifts
) {
  if (
    allocationOnDate?.publishedAllocation ||
    allocationOnDate?.draftAllocation
  ) {
    const [shiftName] = allocationOnDate.draftAllocation
      ? getShiftTask(allocationOnDate.draftAllocation)
      : getShiftTask(allocationOnDate.publishedAllocation);
    const shift = shifts.filter((shift) => shift.name === shiftName);

    if (shift && !isNightShift(shift.startTime, shift.finishTime)) {
      return { date: checkedDate, shift: shiftName, isDayBefore: false };
    }
  }

  if (
    allocationOnDateBefore?.publishedAllocation ||
    allocationOnDateBefore?.draftAllocation
  ) {
    const [shiftName] = allocationOnDateBefore.draftAllocation
      ? getShiftTask(allocationOnDateBefore.draftAllocation)
      : getShiftTask(allocationOnDateBefore.publishedAllocation);
    const shift = shifts.filter((shift) => shift.name === shiftName)[0];

    if (shift && isNightShift(shift.startTime, shift.finishTime)) {
      return { date: checkedDate, shift: shiftName, isDayBefore: true };
    }
  }

  return null;
}

function getEmployeesHolidayWorkedData(
  employees,
  periodStartDate,
  periodFinishDate,
  shifts
) {
  const hd = new Holidays("NZ", "NTL");

  const holidays = hd
    .getHolidays(2024)
    .filter((holiday) => holiday.type && holiday.type != "observance")
    .map(({ date, name }) => ({
      name,
      date: new DateTime(date).toFormat("AWS"),
    }));

  const holidaysWithinRange = holidays.filter(({ date }) =>
    new DateTime(date).isDateBetweenTwoDates(
      periodStartDate,
      periodFinishDate,
      true,
      true
    )
  );

  const employeesHolidayWorkedData = [];

  for (const employee of employees) {
    const employeeInfo = {
      id: employee.id,
      name: employee.name,
      holidays: [],
    };

    const PublishedAllocations = employee.PublishedAllocations;

    for (const holiday of holidaysWithinRange) {
      const baseDate = new DateTime(holiday.date);

      const dayBeforeHoliday = new DateTime(baseDate)
        .subtractDays(1)
        .toFormat("AWS");

      const originalHolidayAlloc = getAllocationOnDayOrDayBefore(
        baseDate,
        PublishedAllocations.find((allo) => allo.date === holiday.date),
        PublishedAllocations.find((allo) => allo.date === dayBeforeHoliday),
        shifts
      );

      const employeeHoliday = {
        date: holiday.date,
        name: holiday.name,
        datesWorked: [],
        shiftWorked: originalHolidayAlloc
          ? originalHolidayAlloc.shift +
            (originalHolidayAlloc.isDayBefore ? " (day before)" : "")
          : "",
      };

      let weeksToLookBack = 6;
      for (let i = 1; i <= weeksToLookBack; i++) {
        const checkedDate = new DateTime(baseDate)
          .subtractDays(i * 7)
          .toFormat("AWS");

        const allocationOnDate = PublishedAllocations.find(
          (allo) => allo.date === checkedDate
        );

        if (
          allocationOnDate &&
          (!allocationOnDate.draftAllocation ||
            allocationOnDate.draftAllocation === "AL") &&
          allocationOnDate.publishedAllocation === "AL"
        ) {
          weeksToLookBack++; // Skip this week by looking one more week back
          continue; // Skip the rest of the current iteration
        }

        const dayBeforeCheckedDate = new DateTime(checkedDate)
          .subtractDays(1)
          .toFormat("AWS");
        const allocationOnDateBefore = PublishedAllocations.find(
          (allo) => allo.date === dayBeforeCheckedDate
        );

        const holidayAlloc = getAllocationOnDayOrDayBefore(
          checkedDate,
          allocationOnDate,
          allocationOnDateBefore,
          shifts
        );

        if (holidayAlloc !== null)
          employeeHoliday.datesWorked.push(holidayAlloc.date);
      }

      employeeInfo.holidays.push(employeeHoliday);
    }
    employeesHolidayWorkedData.push(employeeInfo);
  }

  return { employeesHolidayWorkedData, holidaysWithinRange };
}

export const getCompressedHolidayData = (
  employees,
  periodStartDate,
  periodFinishDate,
  shifts
) => {
  const { holidaysWithinRange, employeesHolidayWorkedData } =
    getEmployeesHolidayWorkedData(
      employees,
      periodStartDate,
      periodFinishDate,
      shifts
    );
  const holidayDates = holidaysWithinRange.map((holiday) => holiday.date);
  const employeeDaysWorked = employeesHolidayWorkedData.map((employee) => {
    return {
      id: employee.id,
      holidayCounts: employee.holidays.map(
        (holiday) =>
          holiday.datesWorked.filter((date) =>
            new DateTime(date).isBefore(periodStartDate)
          ).length
      ),
    };
  });

  return { holidayDates, employeeDaysWorked };
};

function getCSVData(employeesHolidayWorkedData, holidaysWithinRange) {
  const rows = [];

  // Generate first row
  const firstRow = [BLANK];
  holidaysWithinRange.forEach(({ date, name }) => {
    const formattedDate = new DateTime(date).toFormat(
      "day-month-year-readable"
    );
    firstRow.push(`"${name}"`, formattedDate, BLANK);
  });
  rows.push(firstRow);

  rows.push([]);

  if (holidaysWithinRange.length === 0) {
    rows.push(["No public holidays for this period"]);
    return rows;
  }

  rows.push([
    "All nights are showing date of majority hours and not start date",
  ]);

  rows.push([]);

  // Generate second row
  const secondRow = ["Name"];
  holidaysWithinRange.forEach(() => {
    secondRow.push("Number of Days Worked", "Dates Worked", "Shift worked");
  });
  rows.push(secondRow);

  // Generate rest of the rows
  employeesHolidayWorkedData.forEach(({ name }) => {
    const row = [];
    row.push(name, BLANK);
  });

  for (const employeeData of employeesHolidayWorkedData) {
    const row = [];
    const { name, holidays } = employeeData;
    row.push(`"${name}"`);
    holidays.forEach(({ datesWorked, shiftWorked }) => {
      const formatedDatesWorked = datesWorked.map((date) =>
        new DateTime(date).toFormat("displayed-full")
      );
      row.push(
        datesWorked.length,
        `"${formatedDatesWorked.join(", ")}"`,
        shiftWorked
      );
    });

    rows.push(row);
  }
  return rows;
}

function createCSV(rows) {
  let csvContent = "RosterLab,";
  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  // Create blob
  const blob = new Blob([csvContent], { type: "text/csv" });
  const blobUrl = window.URL.createObjectURL(blob);

  // Open blob URL
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}

const ReportGenerator = ({
  employees,
  periodStartDate,
  periodFinishDate,
  shifts,
}) => {
  const handleGenerateReport = () => {
    const { employeesHolidayWorkedData, holidaysWithinRange } =
      getEmployeesHolidayWorkedData(
        employees,
        periodStartDate,
        periodFinishDate,
        shifts
      );

    const csvData = getCSVData(employeesHolidayWorkedData, holidaysWithinRange);
    createCSV(csvData);
  };

  return (
    <BasicButton
      borderColor={"#1D9EC9"}
      hoverColor={"#1D9EC9"}
      customStyle={{ height: "35px", borderRadius: "10px", fontSize: "18px" }}
      onClick={handleGenerateReport}
    >
      Generate Report
    </BasicButton>
  );
};

export default ReportGenerator;
