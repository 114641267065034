import { useHistory } from "react-router-dom";
import SchedulePeriodSwitcher from "../SchedulePeriodSwitcher/SchedulePeriodSwitcher";
import { getRosterIdByPeriodStartDate } from "../../../../utils/queryUtils/locationDataGetters";
import { inferRosterStartDate } from "../../service/scheduleViewRoster";
import { DateTime } from "../../../../utils";
import {
  getActualNumDays,
  getPreviousNumDays,
} from "../../../../utils/queryUtils/monthViewUtils";
import { fixScheduleRoster } from "../../../rosterProblems/service/fixRoster";
import { getRosterModelById } from "../../../../utils/queryUtils/rosterQuery";
import { useQueryClient } from "@tanstack/react-query";

const recursiveDateFinder = (startDate, defaultNumDays, n) => {
  let iterativeStartDate = new DateTime(startDate);
  if (n > 0) {
    for (let i = 0; i < n; i++) {
      const toChange = getActualNumDays(iterativeStartDate, defaultNumDays);
      iterativeStartDate = iterativeStartDate.addDays(toChange);
    }
  } else {
    for (let i = 0; i < -n; i++) {
      const toChange = getPreviousNumDays(iterativeStartDate, defaultNumDays);
      iterativeStartDate = iterativeStartDate.subtractDays(toChange);
    }
  }

  const result = [
    iterativeStartDate.toFormat("AWS"),
    getActualNumDays(iterativeStartDate, defaultNumDays),
  ];

  return result;
};

const TableSchedulePeriodSwitcher = ({
  location,
  periodStartDate,
  periodFinishDate,
  globalEmployees,
  isSaving,
  periodNum,
  pageUrlSlug,
  customContainerStyle = { paddingBottom: "8px" },
  preSwitchPeriodAction = null,
  createRosterModelForMainStreamInDifferentPeriod,
  readOnly = false,
}) => {
  const locationID = location.id;
  const history = useHistory();
  const queryClient = useQueryClient();

  const inferredRosterStartDateFromToday = inferRosterStartDate(
    location.startDate,
    new DateTime().toFormat("AWS"),
    location.defaultNumDays
  );

  const updateDateQueryParam = (startDate) =>
    history.push({
      pathname: `/locations/schedule-view/${pageUrlSlug}`,
      search: `?location-id=${locationID}&date=${startDate}`,
    });

  const traversePeriod = async (baseDate, n) => {
    if (readOnly) {
      return;
    }
    if (preSwitchPeriodAction) {
      preSwitchPeriodAction();
    }

    const [newStartDate, newNumDays] = recursiveDateFinder(
      baseDate,
      location.defaultNumDays,
      n
    );

    const targetRosterID = getRosterIdByPeriodStartDate(location, newStartDate);
    if (!targetRosterID) {
      await createRosterModelForMainStreamInDifferentPeriod(
        location.id,
        newNumDays,
        newStartDate,
        globalEmployees
      );
    } else {
      const cachedRoster = queryClient.getQueryData(["roster", targetRosterID]);
      if (cachedRoster) {
        const roster = await getRosterModelById(targetRosterID);
        const fixedRoster = await fixScheduleRoster(roster, globalEmployees);

        queryClient.setQueryData(["roster", roster.id], {
          ...(fixedRoster ? fixedRoster : roster),
        });
      }
    }
    updateDateQueryParam(newStartDate);
  };

  return (
    <div style={customContainerStyle}>
      <SchedulePeriodSwitcher
        startDate={periodStartDate}
        finishDate={periodFinishDate}
        onForward={() => traversePeriod(new DateTime(periodStartDate), 1)}
        onBackward={() => traversePeriod(new DateTime(periodStartDate), -1)}
        shouldDisableLeftButton={isSaving}
        shouldDisableRightButton={isSaving}
        periodNum={periodNum}
        dropdownItems={[
          {
            label: "Previous schedule period",
            onClick: () =>
              traversePeriod(
                new DateTime(inferredRosterStartDateFromToday),
                -1
              ),
          },
          {
            label: "Current schedule period",
            onClick: () =>
              traversePeriod(new DateTime(inferredRosterStartDateFromToday), 0),
          },
          {
            label: "Next schedule period + 1",
            onClick: () =>
              traversePeriod(new DateTime(inferredRosterStartDateFromToday), 1),
          },
          {
            label: "Next schedule period + 2",
            onClick: () =>
              traversePeriod(new DateTime(inferredRosterStartDateFromToday), 2),
          },
          {
            label: "Next schedule period + 3",
            onClick: () =>
              traversePeriod(new DateTime(inferredRosterStartDateFromToday), 3),
          },
        ]}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TableSchedulePeriodSwitcher;
