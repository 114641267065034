import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useFirebaseMessageTokenStore } from "../globalStore/firebaseMessageTokenStore";
import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { LocalNotifications } from "@capacitor/local-notifications";
import { isNativeMobileApp } from "../globalStore/deviceInfoStore";

const firebaseConfig = {
  apiKey: "AIzaSyDeiisnQyetAM6ud-8E1DqROHEh6keQBx4",
  authDomain: "rosterlab-29c91.firebaseapp.com",
  projectId: "rosterlab-29c91",
  storageBucket: "rosterlab-29c91.appspot.com",
  messagingSenderId: "135156814163",
  appId: "1:135156814163:web:8eab4e8bdc4d4d765011a6",
  measurementId: "G-LDQM6QEGJY",
};

const options = {
  vapidKey:
    "BE9_OJ5BiyWF8nWON8hiCOfEV-19rxn5BSvW4RukTtbMi65jmI0TBk0hXmidsk2uv-AV968ApW1TO9xxUdB1n9w",
};

const firebaseApp = initializeApp(firebaseConfig);

export async function configureFirebaseMessaging() {
  if (isNativeMobileApp()) {
    configureMessagingForMobile();
    return;
  }
  configureMessagingForBrowser();
}

async function configureMessagingForBrowser() {
  if (
    !("serviceWorker" in navigator) ||
    !("PushManager" in window) ||
    !("Notification" in window)
  ) {
    console.log("This browser does not support the necessary APIs.");
    return;
  } else {
    console.log("Browser supports push notifications");
  }

  const messaging = getMessaging(firebaseApp);
  let currentToken;

  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        "BE9_OJ5BiyWF8nWON8hiCOfEV-19rxn5BSvW4RukTtbMi65jmI0TBk0hXmidsk2uv-AV968ApW1TO9xxUdB1n9w",
    });
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    // handle the error
  }

  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    useFirebaseMessageTokenStore.setState({ currentToken });
  } else {
    // Show permission request UI
    console.log(
      "No registration token available. Request permission to generate one."
    );
  }

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);

    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };
    const data = payload.data;

    if (Notification.permission === "granted") {
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = function (event) {
        event.preventDefault();
        if (!data) {
          window.open(location.origin, "_blank");
        } else {
          switch (data.page) {
            case "survey": {
              const date = data.date;
              window.open(
                `${location.origin}/employee-view/survey${
                  date ? `?date=${date}` : ""
                }`,
                "_blank"
              );
              break;
            }
            default: {
              window.open(location.origin, "_blank");
            }
          }
        }
        notification.close();
      };
    }
  });
}

async function configureMessagingForMobile() {
  FirebaseMessaging.requestPermissions().then(
    (status) => "Permission status: " + JSON.stringify(status)
  );

  FirebaseMessaging.addListener("notificationReceived", (event) => {
    const notification = event.notification;
    console.log("notificationReceived :) ", JSON.stringify(notification));

    if (Capacitor.getPlatform() === "android") {
      // Show the notification using the LocalNotifications API
      LocalNotifications.schedule({
        notifications: [
          {
            id: "1",
            title: notification.title,
            body: notification.body,
            schedule: { at: new Date(Date.now() + 1000 * 2) },
            extra: null,
          },
        ],
      });
    }
  });

  FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
    console.log("notificationActionPerformed: ", { event });
    const data = event.notification.data;

    if (data.page === "calendar") {
      // For calendar notifications
      const date = data.date;
      location.replace(
        `${location.origin}/employee-view/mobile/schedule${
          date ? `?date=${date}` : ""
        }`
      );
    } else if (data.page === "openShifts") {
      // For open shift notifications
      location.replace(
        `${location.origin}/employee-view/mobile/open-shifts?locationID=${data.locationID}`
      );
    } else if (data.page === "survey") {
      const date = data.date;
      location.replace(
        `${location.origin}/employee-view/mobile/survey${
          date ? `?date=${date}` : ""
        }`
      );
    } else {
      location.replace(`${location.origin}/employee-view/mobile/schedule`);
    }
  });
  FirebaseMessaging.getToken(options).then((token) => {
    useFirebaseMessageTokenStore.setState({ currentToken: token.token });
  });
}
