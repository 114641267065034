import { getRosterInView } from "./useScheduleQuery";
import {
  getCustomKeywordsDataFromFrontendSettings,
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../utils/queryUtils/locationDataGetters.ts";
import {
  useBareLocationQuery,
  useBareRosterQuery,
} from "./useBareModelQueries";
import { useMemo } from "react";
import { DEFAULT_FRONTEND_SETTINGS } from "../../constants";

/**
 * If schedule view, this hook returns `rosterInView`
 * If prototype view, this hook returns `roster` model
 */
export function useRosterModelQuery({
  isScheduleView,
  locationID = null,
  rosterID = null,
}) {
  const {
    location,
    globalEmployees,
    isLoading: isLocationLoading,
  } = useBareLocationQuery(locationID, isScheduleView);

  const customKeywords = useMemo(() => {
    const customKeywordsData = location
      ? getCustomKeywordsDataFromLocation(location)
      : getCustomKeywordsDataFromFrontendSettings(DEFAULT_FRONTEND_SETTINGS);

    return interpretCustomKeywordsData(customKeywordsData);
  }, [location]);

  const {
    roster,
    isLoading: isRosterLoading,
    refetch: refetchRoster,
  } = useBareRosterQuery(rosterID, isScheduleView, globalEmployees, !!rosterID);

  const rosterInView = useMemo(() => {
    if (!roster) {
      return null;
    }
    if (isScheduleView) {
      return getRosterInView(location, globalEmployees, roster);
    }

    return {
      ...roster,
      ...(location && { frontendSettings: location.frontendSettings }),
    };
  }, [location, globalEmployees, roster, isScheduleView]);

  return {
    roster: rosterInView,
    customKeywords,
    isQueryLoading: isRosterLoading || isLocationLoading,
    refetchRoster,
  };
}
