import create from "zustand";
import { uiStateSettings } from "../features/locations/components/GlobalSettings/globalSettingsList";

export const employeePageViewSetting = uiStateSettings.find(
  (setting) => setting.name === "defaultEmployeesPageView"
);

export const myRosterPageViewSetting = uiStateSettings.find(
  (setting) => setting.name === "defaultMyRosterPageView"
);

export const useDefaultPageViewStore = create((set, get) => {
  return {
    defaultViews: {
      [employeePageViewSetting.name]:
        employeePageViewSetting.possibleValues.overview,
      [myRosterPageViewSetting.name]:
        myRosterPageViewSetting.possibleValues.employeeView,
    },
    changeDefaultPageView: (pageName, view) => {
      set({
        defaultViews: {
          ...get().defaultView,
          [pageName]: view,
        },
      });
    },
  };
});
