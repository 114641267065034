export const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#219ec9",
    minHeight: "36px",
    height: "36px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "36px",
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "36px",
  }),
};
