import { useMemo } from "react";
import {
  extractDataFromGrid,
  getParsedDataInRows,
  parseModelToGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import TaskBlocksGrid from "../../../rosterProblems/components/TaskBlocks/TaskBlocksGrid/TaskBlocksGrid";
import { getNewTaskBlocksTemplate } from "../../../../utils/queryUtils/rosterDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import {
  addNewModels,
  duplicateModels,
  removeModels,
  reorderModelsData,
  updateModels,
} from "../../../../hooks/modelQueryHooks/useRosterModelMutation";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import {
  buildShortIdsToEntityNamesDicts,
  getEntityDeletionWarnings,
} from "../../../rosterProblems/service/rosterUtils";

const ScheduleTaskBlocksDataContainer = ({
  locationID,
  isRoster,
  rosterID,
  reservedShiftKeywords,
}) => {
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    getToBeDeletedItems,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();
  const { areas, skills, taskBlocks, tasks, shifts, shiftGroups, subTasks } =
    fields;
  const { plan } = useUserStore();

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const getDeletionWarnings = (toBeDeletedItems) =>
    getEntityDeletionWarnings(
      toBeDeletedItems,
      "TaskBlock",
      roster,
      shortIdsToEntityNamesDicts
    );

  const applyTaskBlockUpdates = (updatedTaskBlocks) => {
    const updatedRoster = { ...roster, TaskBlocks: updatedTaskBlocks };
    updateFields(["TaskBlocks"], updatedRoster, roster);
  };

  const taskBlocksWarnings = useMemo(() => {
    return warnings ? warnings.TaskBlocks : null;
  }, [warnings]);

  const taskBlocksData = useMemo(
    () => parseModelToGridFormat(taskBlocks),
    [taskBlocks]
  );

  const extractTaskBlocksFromGrid = (gridApi) =>
    extractDataFromGrid(gridApi, ["depth"]);

  const addNewTaskBlocks = (numItems) =>
    addNewModels(
      "New Task Block",
      true,
      (option) =>
        getNewTaskBlocksTemplate({ ...option, existingTaskBlocks: taskBlocks }),
      numItems,
      taskBlocks,
      applyTaskBlockUpdates
    );
  const updateTaskBlocks = (newGridContent) =>
    updateModels(newGridContent, taskBlocks, applyTaskBlockUpdates);
  const duplicateTaskBlocks = (selectedTaskBlocksInfo) =>
    duplicateModels(
      selectedTaskBlocksInfo,
      [],
      taskBlocks,
      applyTaskBlockUpdates,
      "copy of ",
      "TaskBlock",
      true
    );
  const removeTaskBlocks = (toBeDeletedItems) =>
    removeModels(toBeDeletedItems, taskBlocks, applyTaskBlockUpdates);
  const reorderTaskBlocksData = (params) =>
    reorderModelsData(
      params,
      extractTaskBlocksFromGrid,
      taskBlocks,
      applyTaskBlockUpdates
    );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <GridActionHandler
      gridApi={gridApi}
      addNewItemToDB={addNewTaskBlocks}
      updateItemsToDB={updateTaskBlocks}
      duplicateItemsToDB={duplicateTaskBlocks}
      removeItemsFromDB={removeTaskBlocks}
      getDataFromGrid={extractTaskBlocksFromGrid}
      getToBeDeletedItems={getToBeDeletedItems}
      parseSelectedRowsToDuplicableInfo={(rows) =>
        getParsedDataInRows(rows, ["depth"])
      }
      getDeletionWarnings={getDeletionWarnings}
    >
      <TaskBlocksGrid
        taskBlocksData={taskBlocksData}
        numTaskBlocks={taskBlocks.length}
        isSaving={isSaving}
        setGridApiToParent={setGridApi}
        gridApi={gridApi}
        taskBlocksWarnings={taskBlocksWarnings}
        skills={skills}
        reorderTaskBlocksData={reorderTaskBlocksData}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        taskBlocks={taskBlocks}
        reservedShiftKeywords={reservedShiftKeywords}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      />
    </GridActionHandler>
  );
};

export default ScheduleTaskBlocksDataContainer;
