import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startOfWeek, format, parseISO } from "date-fns";
import { useMemo, useRef, useState, useEffect } from "react";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import CollapsibleDateRangePicker from "../../../../components/elements/CollapsibleDateRangePicker/CollapsibleDateRangePicker";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import Layout from "../../../../components/layouts/Layout/Layout";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import {
  convertToOptionPropForm,
  createBasicContextMenu,
  DateTime,
  finishTimeGetter,
  getAllTimeEntriesAndAllocationsFromGrid,
  getAllocationOptions,
  getReservedHeaderRowStyle,
  startTimeFinishTimeDifferenceGetter,
  startTimeGetter,
  timeSetter,
  trimAndLowerCase,
  getAreaOptions,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import DataEntryTable from "../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import {
  getColumnSum,
  getTimeDifferenceColumnSum,
} from "../../../statistics/service/statsValueGetter";
import styles from "./TimesheetView.module.css";
import { redrawBottomPinnedRows } from "../../../../utils";
import DropdownSingleSelector from "../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import AllocationSelector from "../../../rosterProblems/rosteredAllocations/components/AllocationSelector/AllocationSelector";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
  removeSingleShortIdsFromAllocation,
} from "../../../rosterProblems/service/rosterUtils";
import TimesheetLocked from "../../../upgradePlan/components/TimesheetLocked/TimesheetLocked";
import { useLocationQuery } from "../../../../hooks/modelQueryHooks/useLocationQuery";
import {
  getFieldsFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import { useUserStore } from "../../../../globalStore/appStore";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import {
  convertAllocationInShortIdFormToNameForm,
  extractEntityShortIdsFromAllocation,
} from "../../../../utils/modelUtils/allocation";
import { KEYWORD_OFF } from "../../../../constants/keywords";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import {
  syncGlobalEmployee,
  syncLocation,
} from "../../../../utils/modelUtils/sync";
import {
  subscribeGlobalEmployeeUpdate,
  subscribeLocationUpdate,
} from "../../../../utils/queryUtils/observers";
import {
  getLocationAreaFiltersSettings,
  useSettingsModelQuery,
} from "../../../../hooks/modelQueryHooks/useSettingsModelQuery";

const TimesheetDataContainer = ({
  locationID,
  locations,
  customKeywordsData,
}) => {
  const { updateGlobalEmployees } = useLocationMutation(locationID);
  const { isPaidPlan } = useUserStore();
  const customKeywordsUtilObj = interpretCustomKeywordsData(customKeywordsData);
  const { reservedShiftKeywords, predefinedShiftOptions } =
    customKeywordsUtilObj;

  const {
    location,
    globalEmployees: employees,
    isQueryLoading,
  } = useLocationQuery(locations, locationID);

  const { settings } = useSettingsModelQuery();

  const areaFilter = useMemo(
    () => getLocationAreaFiltersSettings(settings, locationID),
    [settings, locationID]
  );

  useEffect(() => {
    let locationUpdateSub;
    let globalEmployeeUpdateSub;

    if (locationID) {
      locationUpdateSub = subscribeLocationUpdate(
        (data) => syncLocation(locationID, data),
        locationID
      );

      globalEmployeeUpdateSub = subscribeGlobalEmployeeUpdate(
        (data) => syncGlobalEmployee(locationID, data),
        locationID
      );
    }

    return async () => {
      (await locationUpdateSub)?.unsubscribe();
      (await globalEmployeeUpdateSub)?.unsubscribe();
    };
  }, [locationID]);

  const [selectedEmployee, setSelectedEmployee] = useState(() =>
    employees.length > 0 ? employees[0].name : ""
  );

  const {
    globalAreas: areas,
    globalSkills: skills,
    globalTasks: tasks,
    globalShifts: shifts,
    globalShiftGroups: shiftGroups,
    globalTaskBlocks: taskBlocks,
    name: locationName,
    subTasks,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const selectAreaOptions = useMemo(() => {
    const { areaOptions } = getAreaOptions(areas);
    return [{ label: "No area", value: "no area" }, ...areaOptions];
  }, [areas]);

  const [timesheetGridApi, setTimesheetGridApi] = useState(null);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([timesheetGridApi], locationID, "area");

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const namesToEntityShortIdsDicts = useMemo(
    () =>
      buildNamesToEntityShortIdsDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );
  const nearbyMonday = format(startOfWeek(new Date()), "yyyy-MM-dd");
  const [startDate, setStartDate] = useState(nearbyMonday);
  const [finishDate, setFinishDate] = useState(
    new DateTime(nearbyMonday).addDays(6).toFormat("AWS")
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const updateDateQueryParam = () => {};
  const employeeNames = employees.map((employee) => employee.name);

  const setTimesheetGridApiToParent = (rulesGridApi) => {
    setTimesheetGridApi(rulesGridApi);
  };

  const rowData = useMemo(() => {
    if (selectedEmployee && employees.length > 0) {
      const allocations = getAllocationsForEmployee(
        employees,
        selectedEmployee,
        startDate,
        finishDate
      );

      return calculateRowData(
        employees,
        allocations,
        shifts,
        selectedEmployee,
        startDate,
        finishDate,
        shortIdsToEntityNamesDicts
      );
    }

    return [];
  }, [
    employees,
    shifts,
    selectedEmployee,
    startDate,
    finishDate,
    shortIdsToEntityNamesDicts,
  ]);

  useEffect(() => {
    if (rowData.length > 0 && timesheetGridApi) {
      redrawBottomPinnedRows(timesheetGridApi);
    }
  }, [rowData, timesheetGridApi]);

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const getContextMenuItems = () => {
    const contextMenu = createBasicContextMenu();
    return contextMenu;
  };

  const allocationOptions = useMemo(() => {
    return getAllocationOptions(
      areas,
      shifts,
      taskBlocks,
      tasks,
      shiftGroups,
      predefinedShiftOptions,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
      areaFilter
    );
  }, [
    areas,
    shifts,
    shiftGroups,
    taskBlocks,
    tasks,
    predefinedShiftOptions,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
    areaFilter,
  ]);

  const columnDefs = useMemo(
    () => [
      {
        field: "date",
        width: width / 9 - 28,
        minWidth: 150,
        editable: false,
        sortable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueFormatter: (params) => {
          if (!params.data) {
            return "";
          }

          if (params.data.id === "reserved_header") {
            return "Totals";
          }
          if (params.data.id === "total_time") {
            return "";
          }
          return new DateTime(params.value).toFormat("displayed-full");
        },
      },
      {
        field: "day",
        width: width / 9,
        minWidth: 150,
        editable: false,
        sortable: true,
        valueGetter: (params) => {
          if (DateTime.isDateString(params.data.date)) {
            return new DateTime(params.data.date).toFormat("weekday");
          }
          return params.data.date;
        },
        valueFormatter: (params) => {
          if (
            !params.data ||
            params.data.id === "reserved_header" ||
            params.data.id === "total_time"
          ) {
            return "";
          }
        },
      },
      {
        field: "area",
        editable: true,
        width: 150,
        sortable: true,
        valueSetter: (params) =>
          areaSetter(params, areas, shortIdsToEntityNamesDicts),
        valueFormatter: (params) => {
          if (!params.data || params.data.id === "reserved_header") {
            return "";
          }
          const value = params.value || "";
          const cellValue = convertAllocationInShortIdFormToNameForm(
            value,
            shortIdsToEntityNamesDicts
          );
          return cellValue || value;
        },
        cellEditor: "dropdownSingleSelector",
        cellEditorParams: {
          width: width / 9,
          options: selectAreaOptions,
          cellHeight: "41px",
        },
        cellEditorPopup: true,
        hide: areas.length === 0,
      },
      {
        field: "publishedShift",
        editable: true,
        width: width / 9 - 10,
        sortable: true,
        cellEditor: "allocationSelector",
        cellEditorParams: {
          options: allocationOptions,
          headerName: "Options:",
          reservedShiftKeywords,
          shortIdsToEntityNamesDicts,
          namesToEntityShortIdsDicts,
        },
        cellEditorPopup: true,
        valueSetter: (params) =>
          publishedShiftSetter(
            params,
            shifts,
            allocationOptions,
            shortIdsToEntityNamesDicts
          ),
        valueFormatter: (params) => {
          if (!params.data || params.data.id === "reserved_header") {
            return "";
          }
          const value = params.value || "";
          const cellValue = convertAllocationInShortIdFormToNameForm(
            value,
            shortIdsToEntityNamesDicts
          );
          return cellValue || value;
        },
      },
      {
        field: "startTime",
        editable: (params) => params.data.publishedShift !== KEYWORD_OFF,
        width: width / 9 - 10,
        sortable: true,
        valueGetter: startTimeGetter,
        valueSetter: timeSetter,
        valueFormatter: (params) => {
          if (
            !params.data ||
            params.data.id === "reserved_header" ||
            params.data.id === "total_time"
          ) {
            return "";
          }
        },
      },
      {
        field: "finishTime",
        editable: (params) => params.data.publishedShift !== KEYWORD_OFF,
        width: width / 9,
        sortable: true,
        valueGetter: finishTimeGetter,
        valueSetter: timeSetter,
        valueFormatter: (params) => {
          if (
            !params.data ||
            params.data.id === "reserved_header" ||
            params.data.id === "total_time"
          ) {
            return "";
          }
        },
      },
      {
        field: "breakTime",
        headerName: "Break Time (mins)",
        editable: (params) => params.data.publishedShift !== KEYWORD_OFF,
        width: width / 9,
        sortable: true,
        valueFormatter: (params) => {
          if (!params.data || params.data.id === "reserved_header") {
            return "";
          }
        },
        valueGetter: (params) => {
          const columnKey = params.colDef.field;
          if (params.data.id !== "total_time") {
            return params.data[columnKey];
          }
          return getColumnSum(params);
        },
      },
      {
        field: "hours",
        editable: false,
        width: width / 9 - 35,
        sortable: true,
        valueGetter: (params) => {
          if (params.data.id === "total_time") {
            return getTimeDifferenceColumnSum(params);
          }
          const shiftHours = startTimeFinishTimeDifferenceGetter(
            params.data.startTime,
            params.data.finishTime,
            params.data.breakTime
          );

          if (isNaN(shiftHours)) return "";
          return shiftHours;
        },
        valueFormatter: (params) => {
          if (!params.data || params.data.id === "reserved_header") {
            return "";
          }
        },
      },
      {
        field: "status",
        editable: (params) => params.data.publishedShift !== KEYWORD_OFF,
        width: width / 9,
        sortable: true,
        cellEditor: "dropdownSingleSelector",
        cellEditorParams: {
          width: 150,
          options: convertToOptionPropForm(["pending", "approved"]),
          cellHeight: "41px",
        },
        cellEditorPopup: true,
      },
    ],
    [
      width,
      shifts,
      shortIdsToEntityNamesDicts,
      namesToEntityShortIdsDicts,
      allocationOptions,
      reservedShiftKeywords,
      selectAreaOptions,
      areas,
    ]
  );

  const getDataFromGrid = (gridApi) => {
    const latestAllocations = getAllocationsForEmployee(
      employees,
      selectedEmployee,
      startDate,
      finishDate
    );

    return getAllTimeEntriesAndAllocationsFromGrid(
      gridApi,
      latestAllocations,
      shifts
    );
  };

  const updateTimesheet = async (newTimeEntriesAndAllocations) => {
    let employee = employees.filter(
      (employee) => employee.name === selectedEmployee
    )[0];
    employee = await modifyTimesheetAndAllocationsToLocation(
      employee,
      startDate,
      finishDate,
      await newTimeEntriesAndAllocations
    );

    updateGlobalEmployees([employee], ["TimeEntries", "PublishedAllocations"]);
  };

  if (!isPaidPlan) {
    return (
      <div className={styles.lockWrapper}>
        <TimesheetLocked />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <Layout
        title={<>Timesheet - {locationName}</>}
        headerNext={() => (
          <GetStartedButton
            url={
              "https://help.rosterlab.com/timesheet-daily-changes-management"
            }
          />
        )}
      >
        <div className={styles["grid-container"]} ref={componentRef}>
          {!isQueryLoading ? (
            <>
              <div className={styles["top"]}>
                <div className={styles["top-left"]}>
                  <AreaFilter
                    areas={areas}
                    onAreaFilterChanged={onAreaFilterChanged}
                    onMenuClose={saveAreaFilter}
                    defaultValue={initialAreaFilterValue}
                  />
                  <div className={styles["date-picker-wrapper"]}>
                    <CollapsibleDateRangePicker
                      startDate={startDate}
                      finishDate={finishDate}
                      setStartDate={setStartDate}
                      setFinishDate={setFinishDate}
                      urlUpdater={updateDateQueryParam}
                    />
                  </div>
                  <span className={styles.view}>Employee: </span>
                  <button
                    className={styles["date-arrow"]}
                    onClick={() => {
                      const selectedIndex = employees.findIndex(
                        ({ name }) => name === selectedEmployee
                      );
                      if (selectedIndex <= 0) return;

                      setSelectedEmployee(employees[selectedIndex - 1].name);
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button>
                  <select
                    className={styles["range-selection"]}
                    name="range"
                    id="range"
                    value={selectedEmployee}
                    onChange={(params) =>
                      setSelectedEmployee(params.target.value)
                    }
                  >
                    {employeeNames.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                  <button
                    className={styles["date-arrow"]}
                    onClick={() => {
                      const selectedIndex = employees.findIndex(
                        ({ name }) => name === selectedEmployee
                      );
                      if (
                        selectedIndex === -1 ||
                        selectedIndex === employees.length - 1
                      )
                        return;

                      setSelectedEmployee(employees[selectedIndex + 1].name);
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                  </button>
                </div>
                <div className={styles["top-right"]}>
                  <button
                    className={styles.exportApproved}
                    onClick={() =>
                      exportTimesheets(
                        employees,
                        employeeNames,
                        startDate,
                        finishDate,
                        shifts,
                        true,
                        shortIdsToEntityNamesDicts
                      )
                    }
                  >
                    Export approved
                  </button>
                  <button
                    onClick={() =>
                      exportTimesheets(
                        employees,
                        employeeNames,
                        startDate,
                        finishDate,
                        shifts,
                        false,
                        shortIdsToEntityNamesDicts
                      )
                    }
                    className={styles.exportApproved}
                  >
                    Export all
                  </button>
                  <BasicButton
                    color="#219ec9"
                    hoverColor="#1f91b7"
                    customStyle={{
                      borderRadius: "10px",
                      height: "30px",
                    }}
                    onClick={() => {
                      selectedRows.forEach((row) => {
                        if (row.data.publishedShift !== KEYWORD_OFF) {
                          row.setDataValue("status", "approved");
                        }
                      });
                    }}
                  >
                    Approve selected
                  </BasicButton>
                </div>
              </div>
              <GridActionHandler
                gridApi={timesheetGridApi}
                addNewItemToDB={() => {}}
                updateItemsToDB={updateTimesheet}
                duplicateItemsToDB={() => {}}
                removeItemsFromDB={() => {}}
                getDataFromGrid={getDataFromGrid}
                getToBeDeletedItems={() => {}}
                parseSelectedRowsToDuplicableInfo={() => {}}
                disableUndoRedo={true}
              >
                <TimesheetGrid
                  columnDefs={columnDefs}
                  rowData={rowData}
                  getContextMenuItems={getContextMenuItems}
                  setGridApiToParent={setTimesheetGridApiToParent}
                  setSelectedRows={setSelectedRows}
                  doesAreaFilterPass={doesAreaFilterPass}
                  isExternalFilterPresent={isExternalFilterPresent}
                />
              </GridActionHandler>
            </>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};

const TimesheetGrid = ({
  updateData,
  columnDefs,
  rowData,
  getContextMenuItems,
  setGridApiToParent,
  setSelectedRows,
  getDataFromGrid,
  doesAreaFilterPass,
  isExternalFilterPresent,
}) => {
  return (
    <DataEntryTable
      columnDefs={columnDefs}
      rowData={rowData}
      updateData={updateData}
      getContextMenuItems={getContextMenuItems}
      aggridStyle={"ag-theme-alpine"}
      gridOptions={{
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        onSelectionChanged: (params) => {
          setSelectedRows(params.api.getSelectedNodes());
        },
      }}
      getCustomRowId={(params) => params.data.date}
      setGridApiToParent={setGridApiToParent}
      pinnedBottomRowData={pinnedBottomRowDataTemplate}
      getRowStyle={getReservedHeaderRowStyle}
      components={{
        dropdownSingleSelector: DropdownSingleSelector,
        allocationSelector: AllocationSelector,
      }}
      getDataFromGrid={getDataFromGrid}
      customStyle={{
        height: `${window.innerHeight - 300}px`,
        minHeight: `500px`,
      }}
      doesExternalFilterPass={doesAreaFilterPass}
      isExternalFilterPresent={isExternalFilterPresent}
    />
  );
};

const modifyTimesheetAndAllocationsToLocation = async (
  employee,
  startDate,
  finishDate,
  newTimeEntriesAndAllocations
) => {
  employee.PublishedAllocations = employee.PublishedAllocations.filter(
    (allocation) => allocation.date < startDate || allocation.date > finishDate
  );
  employee.PublishedAllocations = employee.PublishedAllocations.concat(
    newTimeEntriesAndAllocations.allocations
  );
  employee.PublishedAllocations.sort(
    (a, b) => new DateTime(a.date).getDate() - new DateTime(b.date).getDate()
  );

  if (!employee.TimeEntries) {
    employee.TimeEntries = [];
  }
  employee.TimeEntries = employee.TimeEntries.filter(
    (timeEntry) => timeEntry.date < startDate || timeEntry.date > finishDate
  );
  employee.TimeEntries = employee.TimeEntries.concat(
    newTimeEntriesAndAllocations.timeEntries
  );
  employee.TimeEntries.sort(
    (a, b) => new DateTime(a.date).getDate() - new DateTime(b.date).getDate()
  );

  return employee;
};

export default withHeader(TimesheetDataContainer);

const pinnedBottomRowDataTemplate = [
  {
    id: "reserved_header",
    date: "Totals",
  },
  {
    id: "total_time",
    day: "",
    date: "",
    publishedShift: "",
    startTime: "",
    breakTime: "",
    hours: "",
    status: "",
  },
];

const exportTimesheets = async (
  employees,
  employeeNames,
  startDate,
  finishDate,
  shifts,
  approvedOnly,
  shortIdsToEntityNamesDicts
) => {
  const allData = [];
  const employeesWithPending = [];

  employeeNames.forEach((employeeName) => {
    const allocations = getAllocationsForEmployee(
      employees,
      employeeName,
      startDate,
      finishDate
    );

    let rowData = calculateRowData(
      employees,
      allocations,
      shifts,
      employeeName,
      startDate,
      finishDate,
      shortIdsToEntityNamesDicts
    );

    rowData = rowData.map((dat) => {
      const publishedShift = convertAllocationInShortIdFormToNameForm(
        dat.publishedShift,
        shortIdsToEntityNamesDicts
      );
      const areaName = convertAllocationInShortIdFormToNameForm(
        dat.area,
        shortIdsToEntityNamesDicts
      );
      return {
        employeeName: employeeName,
        ...dat,
        publishedShift,
        area: areaName,
      };
    });

    if (rowData.some((alloc) => alloc.status === "pending")) {
      employeesWithPending.push(employeeName);
    }

    if (approvedOnly) {
      allData.push(...rowData.filter((dat) => dat.status === "approved"));
    } else {
      allData.push(
        ...rowData.filter((dat) => dat.publishedShift !== KEYWORD_OFF)
      );
    }
  });

  if (approvedOnly && employeesWithPending.length > 0) {
    if (
      !(await customConfirmAlert({
        title: "Would you still like to export timesheets",
        descriptions: [
          <>
            The following employees still have unapproved shifts: <br />
            {employeesWithPending.join(", ")}
          </>,
        ],
      }))
    )
      return;
  }

  for (const row of allData) {
    row.startTime = DateTime.getFormattedTime(row.startTime, "readable");
    row.finishTime = DateTime.getFormattedTime(row.finishTime, "readable");
  }

  const fileName =
    "Timesheets " +
    format(parseISO(startDate), "dd-MM-yy") +
    " to " +
    format(parseISO(finishDate), "dd-MM-yy");
  exportData(allData, fileName);
};

export const defaultTimesheetStartTime = "09:00:00.000";
export const defaultTimesheetFinishTime = "17:30:00.000";
export const unplannedLeaveShifts = ["Sick leave"];

const areaSetter = (params, areas, shortIdsToEntityNamesDicts) => {
  if (params.newValue === null) {
    return false;
  }

  const matchingNewArea = areas.find(
    (area) => area.shortId === params.newValue
  );

  const publishedShift = params.data.publishedShift;
  const { areaShortId } = extractEntityShortIdsFromAllocation(
    publishedShift,
    shortIdsToEntityNamesDicts
  );

  const allocationWithoutArea = areaShortId
    ? removeSingleShortIdsFromAllocation(
        publishedShift,
        [params.oldValue],
        shortIdsToEntityNamesDicts
      )
    : publishedShift;

  if (!matchingNewArea) {
    params.data.area = "";
    params.data.publishedShift = allocationWithoutArea;
    return true;
  }

  if (!allocationWithoutArea) {
    params.data.area = matchingNewArea.shortId;
    params.data.publishedShift = matchingNewArea.shortId;
    return true;
  }

  params.data.area = matchingNewArea.shortId;
  params.data.publishedShift = `${matchingNewArea.shortId}:${allocationWithoutArea}`;
  return true;
};

const publishedShiftSetter = (
  params,
  shifts,
  options,
  shortIdsToEntityNamesDicts
) => {
  if (params.newValue === null) {
    return false;
  }

  const matchingOption = options.find(
    (option) =>
      trimAndLowerCase(option.value) === trimAndLowerCase(params.newValue)
  );

  params.data.publishedShift = matchingOption
    ? matchingOption.value
    : params.newValue;

  const { areaShortId, shiftShortId } = extractEntityShortIdsFromAllocation(
    params.newValue,
    shortIdsToEntityNamesDicts
  );

  const shift = shifts.find((shift) => shift.shortId === shiftShortId);

  if (params.newValue === KEYWORD_OFF || !params.newValue) {
    params.data.area = "";
    params.data.startTime = null;
    params.data.finishTime = null;
    params.data.breakTime = null;
    params.data.status = "";
    params.data.publishedShift = KEYWORD_OFF;
  } else if (shift) {
    params.data.area = areaShortId || "";
    params.data.startTime = shift.startTime;
    params.data.finishTime = shift.finishTime;
    params.data.breakTime = 30;
    params.data.status = "pending";
  } else {
    params.data.area = "";
    params.data.startTime = params.data.startTime
      ? params.data.startTime
      : defaultTimesheetStartTime;
    params.data.finishTime = params.data.finishTime
      ? params.data.finishTime
      : defaultTimesheetFinishTime;
    params.data.breakTime = 30;
    params.data.status = "pending";
  }

  return true;
};

const camelToEnglish = (str) => {
  const words = str.split(/(?=[A-Z])/);
  return words.map((word) => word[0].toUpperCase() + word.slice(1)).join(" ");
};

const exportData = (data, fileName) => {
  if (data.length === 0) return;

  const header = Object.keys(data[0]);
  const csv = [header.map((key) => camelToEnglish(key)).join(",")];

  for (const row of data) {
    const rowData = header.map((field) => {
      let value = row[field];
      if (typeof value === "string") {
        // Escape double quotes
        value = value.replace(/"/g, '""');
        // Enclose value in double quotes if it contains commas, line breaks, tabs, or backslashes
        if (
          value.includes(",") ||
          value.includes("\n") ||
          value.includes("\r") ||
          value.includes("\t") ||
          value.includes("\\")
        ) {
          value = `"${value}"`;
        }
      }
      return value;
    });
    csv.push(rowData.join(","));
  }

  const csvData = csv.join("\n");

  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = fileName + ".csv";
  link.href = url;
  link.click();
};

const calculateRowData = (
  globalEmployees,
  allocations,
  shifts,
  selectedEmployee,
  startDate,
  finishDate,
  shortIdsToEntityNamesDicts
) => {
  const rowData = [];

  const selectedEmployeeTimeEntries = globalEmployees.filter(
    (employee) => employee.name === selectedEmployee
  )[0];

  const timeEntries =
    selectedEmployeeTimeEntries && selectedEmployeeTimeEntries.TimeEntries
      ? selectedEmployeeTimeEntries.TimeEntries.filter(
          (timeEntry) =>
            timeEntry.date >= startDate && timeEntry.date <= finishDate
        )
      : [];

  const dates = DateTime.getAllDateStringsBetween(
    startDate,
    finishDate,
    true,
    true
  );

  for (const date of dates) {
    const allocation = allocations.find(
      (allocation) => allocation.date === date
    );

    if (allocation) {
      const { areaShortId, shiftShortId } = extractEntityShortIdsFromAllocation(
        allocation.publishedAllocation,
        shortIdsToEntityNamesDicts
      );

      const shift = shifts.find((shift) => shift.shortId === shiftShortId);
      const timeEntry = timeEntries.find(
        (entry) => entry.date === allocation.date
      );

      const shiftStartTime = shift
        ? shift.startTime
        : defaultTimesheetStartTime;
      const shiftFinishTime = shift
        ? shift.finishTime
        : defaultTimesheetFinishTime;

      const startTime =
        timeEntry && timeEntry.startTime ? timeEntry.startTime : shiftStartTime;
      const finishTime =
        timeEntry && timeEntry.finishTime
          ? timeEntry.finishTime
          : shiftFinishTime;
      const breakTime =
        timeEntry && timeEntry.breakTime ? timeEntry.breakTime : 30;

      rowData.push({
        date: allocation.date,
        area: areaShortId,
        publishedShift: allocation.publishedAllocation,
        startTime: startTime,
        finishTime: finishTime,
        breakTime: breakTime,
        hours: startTimeFinishTimeDifferenceGetter(
          startTime,
          finishTime,
          breakTime
        ),
        status: timeEntry && timeEntry.approved ? "approved" : "pending",
      });
    } else {
      rowData.push({
        date: date,
        area: "",
        publishedShift: KEYWORD_OFF,
        startTime: null,
        finishTime: null,
        breakTime: null,
        hours: 0,
        status: "",
      });
    }
  }

  return rowData;
};

const getAllocationsForEmployee = (
  employees,
  employeeName,
  startDate,
  finishDate
) => {
  const allocations = employees
    .filter((employee) => employee.name === employeeName)
    .map((employee) => employee.PublishedAllocations)[0]
    .filter((allocation) => {
      return (
        allocation.publishedAllocation &&
        allocation.date >= startDate &&
        allocation.date <= finishDate
      );
    });
  return allocations;
};
