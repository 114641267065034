import { useMemo, useState } from "react";
import { DateTime } from "../../../../utils";
import styles from "./ScheduleFileInteractor.module.css";

const options = ["Previous period", "Current period", "Next period"];

const ScheduleFileInteractor = ({
  label,
  buttonLabel,
  buttonClickHandler,
  currentPeriodStartDate,
  numDays,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(options[1]);

  const fromDate = useMemo(() => {
    switch (selectedPeriod) {
      case options[0]:
        return new DateTime(currentPeriodStartDate).subtractDays(numDays);
      case options[1]:
        return new DateTime(currentPeriodStartDate);
      case options[2]:
        return new DateTime(currentPeriodStartDate).addDays(numDays);
      default:
        throw new Error("invalid selection");
    }
  }, [selectedPeriod, currentPeriodStartDate, numDays]);

  const toDate = new DateTime(fromDate).addDays(numDays - 1);

  return (
    <div className={styles.fileInteractor}>
      <div>
        <span className={styles.mainLabel}>{label}</span>
        <select
          className={styles.selector}
          value={selectedPeriod}
          onChange={(e) => setSelectedPeriod(e.target.value)}
        >
          {options.map((item, idx) => (
            <option key={idx}>{item}</option>
          ))}
        </select>
      </div>
      <div>
        <span className={styles.dateDescription}>
          From {fromDate.toFormat("displayed-full")} to{" "}
          {toDate.toFormat("displayed-full")}
        </span>
        <button
          onClick={() => buttonClickHandler(fromDate)}
          className={styles.selectBtn}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default ScheduleFileInteractor;
