import { getDayNumFromColFieldName } from "../agGridUtils/column";
import { DateTime } from "../dataTypesUtils/DateTime";
import { removeDuplicateStrInArr } from "./array";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @param {*} str - String to calculate pixel width
 */
export const getStrWidthInPx = (
  str,
  fontFamily = "sans-serif",
  fontSizeInPx = 12
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = `${fontSizeInPx}px ${fontFamily}`;
  const strWidth = ctx.measureText(str).width;
  const defaultColWidth = strWidth;
  canvas.remove();
  return defaultColWidth;
};

export const getLongestStrInPx = (strArray) => {
  return Math.max(...strArray.map((label) => getStrWidthInPx(label)));
};

export const hasObjectChanged = (pre, post) => {
  return JSON.stringify(pre) !== JSON.stringify(post);
};

export const numberToTwoDecFloat = (number) => {
  return parseFloat(number).toFixed(2);
};

export const checkStringContainsAllWordsInArr = (words, baseString) => {
  for (let i = 0; i < words.length; i++) {
    if (!baseString.includes(words[i])) {
      return false;
    }
  }
  return true;
};

export const deepCopyObject = (obj) => {
  if (!obj) return obj;

  return JSON.parse(JSON.stringify(obj));
};

export const removeSuffix = (str, suffixLength) => {
  return str.slice(0, suffixLength * -1);
};

export const cartesianProduct = (a, b, ...c) => {
  const f = (a, b) => [].concat(...a.map((a) => b.map((b) => [].concat(a, b))));
  return b ? cartesianProduct(f(a, b), ...c) : a;
};

export const lowercaseFirstCharacter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

// Return -1 if such interval does not exist
export const findIntersectionOfAllNumIntervals = (intervals) => {
  const N = intervals.length;

  // First interval
  let l = intervals[0][0];
  let r = intervals[0][1];

  // Check rest of the intervals
  // and find the intersection
  for (let i = 1; i < N; i++) {
    // If no intersection exists
    if (intervals[i][0] > r || intervals[i][1] < l) {
      return -1;
    }

    // Else update the intersection
    else {
      l = Math.max(l, intervals[i][0]);
      r = Math.min(r, intervals[i][1]);
    }
  }
  return [l, r];
};

export const getDefinedOrEmpty = (val) => {
  return val === undefined ? "" : val;
};

export const getFilledMonthsArray = (dayColumns, startDate) => {
  let months = [];
  DateTime.MONTH_NAMES.forEach((monthName) => {
    months.push({ name: monthName, days: [] });
  });

  for (const colName of dayColumns) {
    const date = new DateTime(startDate).getDate();
    const offset = getDayNumFromColFieldName(colName);
    if (offset < 0) {
      date.setDate(date.getDate() + offset + 1);
    } else {
      date.setDate(date.getDate() + offset);
    }
    const month = date.getMonth();
    months[month].days.push(colName);
  }

  months = months.filter((month) => month.days.length > 0);

  months = months.sort(
    (a, b) =>
      parseInt(a.days[0].substring(1)) - parseInt(b.days[0].substring(1))
  );

  return months;
};

export function roundFloatToNearestQuarter(float) {
  return Math.round(float * 4) / 4;
}

export function mod(n, m) {
  return ((n % m) + m) % m;
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function generateUniqueKioskCode(notAllowedCode) {
  const uniqueNotAllowedCode = removeDuplicateStrInArr(
    notAllowedCode.filter((code) => Boolean(code))
  );
  let unique = false;
  let code = "";

  if (uniqueNotAllowedCode.length >= 10000) {
    console.error("");
    return null; // or throw an Error, or another appropriate response
  }

  while (!unique) {
    // Generate a random number between 0 and 9999
    let randomNumber = Math.floor(Math.random() * 10000);

    // Convert it to a four-digit string, padding with zeros if necessary
    code = randomNumber.toString().padStart(4, "0");

    // Check if this code is in the uniqueNotAllowedCode array
    if (!uniqueNotAllowedCode.includes(code)) {
      unique = true;
    }
  }

  return code;
}
