import * as queries from "../../../graphql/queries";
import {
  DateTime,
  deepCopyObject,
  removeDuplicateStrInArr,
  sortByDateField,
} from "../../../utils";
import {
  getGlobalEmployeeModelById,
  getGlobalEmployeeModelsByLocationId,
} from "../../../utils/queryUtils/locationQuery";
import { graphqlErrorHandler } from "../../../utils/queryUtils/generalQueryUtil";
import { isAllocationDisplayedBlankInEmployeeApp } from "../../employeeApp/service/employeeAppUtil";
import { getGlobalEmployeeJustRegistrations } from "../../../utils/graphqlUtils/customQueries";
import { KEYWORD_NO_TASK } from "../../../constants/keywords";

export async function sendFirebaseNotificationsToBrowsers(
  notifications,
  title = "",
  page = "main",
  date = null
) {
  if (!notifications || notifications.length === 0) {
    return null;
  }

  const response = await graphqlErrorHandler(
    queries.notifyUsers,
    {
      page,
      notifications,
      title,
      ...(date && { date }),
    },
    (data) => ({
      data,
    }),
    null,
    "sendNotificationsToOpenShifts-" + page,
    true
  );
  return response;
}

async function getLatestEmployeeRegistrations(locationID, employeeIDsToNotify) {
  /**
   * --------
   * Once the admin app subscribes to changes in employee-view app, this code will no longer be needed
   * (no need to fetch globalEmployees fresh from database)
   *
   * Q: Why do we need fresh globalEmployees from database?
   * A: When new employee logs in for the first time, their registration is not in the admin side until the admin refreshes the page
   */
  const globalEmployees = await getGlobalEmployeeModelsByLocationId(locationID);
  const employeesToNotify = globalEmployees.filter((employee) =>
    employeeIDsToNotify.includes(employee.id)
  );
  // --------

  const employeesRegistrations = [];

  for (const employee of employeesToNotify) {
    if (!employee.registrations) {
      continue;
    }

    let registrations = [];
    for (const registration of employee.registrations) {
      if (registration && !registrations.includes(registration)) {
        registrations.push(registration);
      }
    }
    registrations = removeDuplicateStrInArr(registrations);

    employeesRegistrations.push({ employeeID: employee.id, registrations });
  }

  return employeesRegistrations;
}

export async function sendRequestNotification(
  locationID,
  employeeID,
  request,
  state
) {
  const employeesRegistrations = await getLatestEmployeeRegistrations(
    locationID,
    [employeeID]
  );

  const registrations = [];
  employeesRegistrations.forEach((employeeRegistrations) => {
    registrations.push(...employeeRegistrations.registrations);
  });

  const title = `${request.request} request ${state}`;
  const message = `${new DateTime(request.startDate).toFormat(
    "readable-month-last"
  )} - ${new DateTime(request.finishDate).toFormat("readable-month-last")}`;

  if (registrations.length > 0) {
    await sendFirebaseNotificationsToBrowsers(
      [
        {
          registrations,
          message: message,
        },
      ],
      title,
      "calendar",
      request.startDate
    );
  }
}

export async function sendFirstTimePublishNotifications(
  location,
  publishStartDate,
  publishFinishDate,
  employeesDataToNotify
) {
  const title = `Roster Published - ${location.name}`;
  const message = `${new DateTime(publishStartDate).toFormat(
    "readable-month-last"
  )} - ${new DateTime(publishFinishDate).toFormat("readable-month-last")}`;

  const employeeIDsToNotify = employeesDataToNotify.map(({ id }) => id);
  const employeesRegistrations = await getLatestEmployeeRegistrations(
    location.id,
    employeeIDsToNotify
  );

  const allRegistrations = [];
  employeesRegistrations.forEach(({ registrations }) =>
    allRegistrations.push(...registrations)
  );

  if (allRegistrations.length > 0) {
    await sendFirebaseNotificationsToBrowsers(
      [
        {
          registrations: allRegistrations,
          message: message,
        },
      ],
      title,
      "calendar"
    );
  }
}

export async function sendPublishNotifications(
  location,
  employeesDataToNotify
) {
  const employeeIDsToNotify = employeesDataToNotify.map(({ id }) => id);

  const locationName = location.name;
  const locationID = location.id;
  const employeesRegistrations = await getLatestEmployeeRegistrations(
    locationID,
    employeeIDsToNotify
  );

  const title = `Roster Changed - ${locationName}`;

  const employeeNotifications = employeesRegistrations.map(
    ({ employeeID, registrations }) => {
      const employeeData = employeesDataToNotify.find(
        ({ id }) => id === employeeID
      );

      const changedAllocations = deepCopyObject(
        employeeData.changedAllocations
      );
      sortByDateField(changedAllocations, "date", true);

      const content = [];
      if (employeeData.changedAllocations.length <= 3) {
        for (const changedAllocation of changedAllocations) {
          const {
            date,
            previousPublishedAllocationName,
            updatedPublishedAllocationName,
          } = changedAllocation;
          const formattedDate = new DateTime(date).toFormat(
            "readable-month-last"
          );

          if (
            isAllocationDisplayedBlankInEmployeeApp(
              previousPublishedAllocationName
            )
          ) {
            content.push(
              `${formattedDate} ${updatedPublishedAllocationName} has been added to your roster`
            );
            continue;
          }

          if (
            isAllocationDisplayedBlankInEmployeeApp(
              updatedPublishedAllocationName
            )
          ) {
            content.push(
              `${formattedDate} ${previousPublishedAllocationName} has been removed from your roster`
            );
            continue;
          }

          content.push(
            `${formattedDate} ${previousPublishedAllocationName} has become ${updatedPublishedAllocationName}`
          );
        }
      } else {
        const firstChangeDate = new DateTime(
          changedAllocations[0].date
        ).toFormat("readable-month-last");
        const lastChangeDate = new DateTime(
          changedAllocations[changedAllocations.length - 1].date
        ).toFormat("readable-month-last");

        content.push(
          `Your roster has been changed between ${firstChangeDate} and ${lastChangeDate}`
        );
      }

      return {
        registrations,
        message: content.join("; "),
      };
    }
  );

  if (employeeNotifications.length > 0) {
    await sendFirebaseNotificationsToBrowsers(
      employeeNotifications,
      title,
      "calendar"
    );
  }
}

export async function sendOpenShiftsNotifications(
  location,
  newOpenShiftInvitations
) {
  let employeeInvitations = [];
  newOpenShiftInvitations.forEach((invitation) => {
    const { employee, date, shiftName, taskName } = invitation;

    const dateStr = new DateTime(date).toFormat("readable-month-last");
    const message = `${dateStr} - ${shiftName}${
      taskName === KEYWORD_NO_TASK ? "" : `-${taskName}`
    }`;

    if (!employeeInvitations.find((info) => info.employeeID === employee.id)) {
      employeeInvitations.push({
        employeeID: employee.id,
        messages: [message],
      });
    } else {
      employeeInvitations = employeeInvitations.map((info) => {
        if (info.employeeID === employee.id) {
          return {
            ...info,
            messages: [...info.messages, message],
          };
        }
        return info;
      });
    }
  });

  const employeeNotifications = [];
  const title = `New Open Shift(s) Available`;

  for (const info of employeeInvitations) {
    const employee = await getGlobalEmployeeModelById(
      info.employeeID,
      getGlobalEmployeeJustRegistrations
    );
    const registrations = employee.registrations || [];
    const messages = info.messages;

    employeeNotifications.push({
      registrations,
      message: `${location.name}:\n${messages.join(`\n`)}`,
    });
  }

  await sendFirebaseNotificationsToBrowsers(
    employeeNotifications,
    title,
    "openShifts"
  );
}
