import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./EmployeeAppMonthSwitcher.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "../../../../utils";

/**
 * The selector looks different depending on whether it is on mobile view or desktop view
 */

const SelectedMonthLabel = ({ date, InputProps, ...props }) => {
  const label = date.toFormat("full-month-year-readable");
  const isMobile = !InputProps;

  if (isMobile) {
    return (
      <div className={styles.container} onClick={props.onClick}>
        <span className={styles.label}>{label}</span>
        <FontAwesomeIcon
          className={styles.calendarToggleButtonChev}
          icon={faChevronDown}
        />
      </div>
    );
  }

  const { endAdornment, ref } = InputProps;
  return (
    <div className={styles.container} ref={ref}>
      <span className={styles.label}>{label}</span>
      {endAdornment}
    </div>
  );
};

const CalendarToggleButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.calendarToggleButton}>
      <FontAwesomeIcon
        className={styles.calendarToggleButtonChev}
        icon={faChevronDown}
      />
    </button>
  );
};

/**
 * date - DateTime object
 * setDate(DateTime)
 */
const EmployeeAppMonthSwitcher = ({ date, setDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={["month", "year"]}
        valule={date.getDate()}
        onAccept={(date) => {
          if (date) {
            setDate(new DateTime(date));
          }
        }}
        slots={{
          field: SelectedMonthLabel,
          openPickerButton: CalendarToggleButton,
        }}
        slotProps={{
          field: { date },
        }}
        minDate={new Date(2020, 0, 1)}
        maxDate={new Date(2038, 0, 1)}
        closeOnSelect={true}
      />
    </LocalizationProvider>
  );
};

export default EmployeeAppMonthSwitcher;
