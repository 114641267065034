import {
  changeDemandValuesToFullLength,
  getShortIds,
  strToArrCommaSeparated,
} from "../../../../../utils";
import { allocationFulfilsShiftGroup } from "../../../service/preferencesAndFixedShifts";
import {
  DEMAND_OPTIONS_WITH_MAXIMUM,
  DEMAND_OPTIONS_WITH_MINIMUM,
} from "../../../components/Demands/DemandsGrid/DemandConstants";
import {
  getSubtasks,
  demandOverlapsSubtask,
} from "../../../service/rosterUtils";
import { KEYWORD_ANY } from "../../../../../constants/keywords";

export const SHIFT_VIEW_ANY_LABEL = KEYWORD_ANY;

// Basic log:
export function buildGrid(
  numDays,
  employees,
  demands,
  taskBlocks,
  shiftGroups,
  customKeywordsDict,
  cachedDayGrids = null,
  updatedDayIndices = null
) {
  const dayGrids = [];
  // Get session columns
  const columnData = [KEYWORD_ANY, ...getShortIds(taskBlocks)];

  for (let d = 0; d < numDays; d++) {
    if (cachedDayGrids && updatedDayIndices && !updatedDayIndices.includes(d)) {
      dayGrids.push(cachedDayGrids[d]);
      continue;
    }
    const employeeGrid = [];

    // ----- This code code just fills dayGrids with valid format even if there is no employees
    if (employees.length === 0) {
      const [dayGrid] = demandsToGrid(demands, columnData);
      employeeGrid.push(dayGrid);
      dayGrids.push(employeeGrid);
      continue;
    }
    // ---------

    for (const employee of employees) {
      const [dayGrid, rowIndexMap] = demandsToGrid(demands, columnData);

      fillGridWithEmployeeID(
        allocationToDemands(
          employee.RosteredAllocations[d],
          demands,
          shiftGroups,
          employee.skills,
          customKeywordsDict
        ),
        rowIndexMap,
        employee,
        dayGrid,
        columnData
      );

      employeeGrid.push(dayGrid);
    }

    dayGrids.push(employeeGrid);
  }

  const [transformedGrid, rowData] = transformToGrid(dayGrids);
  const [, , demandsGrid] = demandsToGrid(demands, columnData);

  rowData.sort((a, b) => {
    const minIndexA = getMinIndex(demandsGrid[a], demands);
    const minIndexB = getMinIndex(demandsGrid[b], demands);

    return minIndexA - minIndexB;
  });

  return { grid: transformedGrid, columnData, rowData, demandsGrid, dayGrids };
}

function getMinIndex(demandsList, demands) {
  const demandIndices = demandsList
    .filter(Boolean)
    .map((demand) => demands.findIndex((d) => d.id === demand.id))
    .filter((x) => x >= 0);

  const ind = Math.min(...demandIndices);

  return ind;
}

export const transformDemands = (
  areas,
  demands,
  tasks,
  taskBlocks,
  shifts,
  shiftGroups
) => {
  const allSubtasks = getSubtasks(tasks, taskBlocks);
  const areaShortIds = getShortIds(areas);
  const shiftShortIds = getShortIds(shifts);
  const shiftGroupShortIds = getShortIds(shiftGroups);
  const transformedDemands = demands
    .filter((demand) => {
      return (
        !demand.values.every((value) => value <= 0) &&
        (demand.shifts || demand.shiftGroups || demand.tasks)
      );
    })
    .map((demand) => ({
      id: demand.id,
      areas: strToArrCommaSeparated(demand.areas).filter((area) =>
        areaShortIds.includes(area)
      ),
      shifts: strToArrCommaSeparated(demand.shifts).filter((shift) =>
        shiftShortIds.includes(shift)
      ),
      shiftGroups: strToArrCommaSeparated(demand.shifts).filter((group) =>
        shiftGroupShortIds.includes(group)
      ),
      subtasks:
        taskBlocks.length > 0
          ? strToArrCommaSeparated(demand.tasks)
              .flatMap((task) =>
                transformToSubtask(
                  task.trim(),
                  tasks,
                  allSubtasks,
                  taskBlocks,
                  demand
                )
              )
              .filter(Boolean)
          : [],
      tasks:
        taskBlocks.length === 0 ? strToArrCommaSeparated(demand.tasks) : [],
      values: demand.values,
      type: demand.type,
    }));

  return splitDemandsByBlock(transformedDemands);
};

function splitDemandsByBlock(demands) {
  // If you just have one task, then split the demands into multiple subtasks so it works more as expected

  let newDemands = [];

  for (let demand of demands) {
    let blockShortIdGroups = {};

    for (let subtask of demand.subtasks) {
      if (!blockShortIdGroups[subtask.blockShortId]) {
        blockShortIdGroups[subtask.blockShortId] = [];
      }
      blockShortIdGroups[subtask.blockShortId].push(subtask);
    }

    if (Object.keys(blockShortIdGroups).length === 0) {
      newDemands.push(demand);
      continue;
    }

    for (let blockShortId in blockShortIdGroups) {
      let newDemand = { ...demand };
      newDemand.subtasks = blockShortIdGroups[blockShortId];
      newDemands.push(newDemand);
    }
  }

  return newDemands;
}

function transformToSubtask(
  taskShortId,
  tasks,
  allSubtasks,
  taskBlocks,
  demand
) {
  const subtasks = [
    ...getSubtasks(
      tasks.filter((task) => task.shortId === taskShortId),
      taskBlocks
    ),
    ...allSubtasks.filter((subtask) => subtask.shortId === taskShortId),
  ];

  return subtasks.filter((subtask) => demandOverlapsSubtask(subtask, demand));
}

function transformToGrid(data) {
  // Get unique tasks
  let tasks = new Set();
  data.forEach((day) => {
    day.forEach((employeeData) => {
      employeeData.forEach((employee) => {
        if (employee[0]) tasks.add(employee[0]);
      });
    });
  });
  tasks = [...tasks];

  // Sample length of taskData for initializing the grid
  const sampleTaskDataLength = data[0][0][0].length - 1; // Assuming data is always non-empty and consistent in format

  // Initialize the grid with an extra dimension
  let grid = tasks.map(() => {
    return Array(data.length)
      .fill([])
      .map(() =>
        Array(sampleTaskDataLength)
          .fill([])
          .map(() => [])
      );
  });

  data.forEach((dayData, dayIndex) => {
    dayData.forEach((employeeData) => {
      employeeData.forEach((taskData) => {
        const taskShortId = taskData[0];
        const rowIndex = tasks.indexOf(taskShortId);
        if (rowIndex !== -1) {
          for (let i = 1; i < taskData.length; i++) {
            if (taskData[i]) {
              grid[rowIndex][dayIndex][i - 1].push(taskData[i]);
            }
          }
        }
      });
    });
  });

  if (tasks.length !== grid.length) {
    throw new Error("Tasks and grid arrays must have the same length");
  }

  const gridDict = tasks.reduce((acc, task, index) => {
    acc[task] = grid[index];
    return acc;
  }, {});

  return [gridDict, tasks];
}

function makeRowLabel(
  areaShortIds,
  shiftShortIds,
  subtask,
  taskShortIds,
  allLabels
) {
  let label = "";

  const areaShortIdString =
    areaShortIds.length > 0 ? `${areaShortIds.join(",")}:` : "";

  if (subtask) {
    const taskShortId = subtask.taskShortId;
    const relevantShiftShortIds = shiftShortIds.filter(
      (shiftShortId) =>
        !allLabels.includes(`${areaShortIdString}${shiftShortId}`)
    );
    const ShiftShortIdString = relevantShiftShortIds.join(",");
    label =
      relevantShiftShortIds.join(",") +
      (ShiftShortIdString.length > 0 ? "||" : "") +
      taskShortId;
  } else if (taskShortIds) {
    const relevantShiftShortIds = shiftShortIds.filter(
      (shiftShortId) =>
        !allLabels.includes(`${areaShortIdString}${shiftShortId}`)
    );
    const ShiftShortIdString = relevantShiftShortIds.join(",");

    label =
      relevantShiftShortIds.join(",") +
      (ShiftShortIdString.length > 0 ? "||" : "") +
      taskShortIds.join(",");
  } else {
    label = shiftShortIds.join(",");
  }

  return `${areaShortIdString}${label}`;
}

function demandsToGrid(demandsList, blocks) {
  const grid = [];
  const rowIndexMap = {};
  const demandsGrid = {};

  function setDemandAt(rowId, col, demand) {
    if (!demandsGrid[rowId]) {
      demandsGrid[rowId] = [];
    }

    if (!demandsGrid[rowId][col]) {
      demandsGrid[rowId][col] = {
        minimumValues: Array(demand.values.length).fill(-Infinity),
        maximumValues: Array(demand.values.length).fill(Infinity),
        ...demand,
      };
    }

    for (let i = 0; i < demand.values.length; i++) {
      if (DEMAND_OPTIONS_WITH_MINIMUM.includes(demand.type)) {
        demandsGrid[rowId][col].minimumValues[i] = Math.max(
          demandsGrid[rowId][col].minimumValues[i],
          demand.values[i]
        );
      }

      if (DEMAND_OPTIONS_WITH_MAXIMUM.includes(demand.type)) {
        demandsGrid[rowId][col].maximumValues[i] = Math.min(
          demandsGrid[rowId][col].maximumValues[i],
          demand.values[i]
        );
      }
    }
  }

  const addToGrid = (value) => {
    const newRow = Array(blocks.length + 1).fill(""); // +1 to account for the first column
    newRow[0] = value;
    rowIndexMap[value] = grid.length;
    grid.push(newRow);
  };

  demandsList.forEach((demand) => {
    if (demand.subtasks.length === 0 && demand.tasks.length === 0) {
      const rowId = makeRowLabel(
        [...demand.areas],
        [...demand.shifts, ...demand.shiftGroups]
      );
      if (rowIndexMap[rowId] === undefined) {
        addToGrid(rowId);
      }
      setDemandAt(rowId, 0, demand);
    }
  });

  demandsList.forEach((demand) => {
    if (demand.tasks.length > 0) {
      const rowId = makeRowLabel(
        [...demand.areas],
        [...demand.shifts, ...demand.shiftGroups],
        null,
        demand.tasks,
        Object.keys(rowIndexMap)
      );
      if (rowIndexMap[rowId] === undefined) {
        addToGrid(rowId);
      }
      setDemandAt(rowId, 0, demand);
    }
  });

  demandsList.forEach((demand) => {
    if (demand.subtasks.length > 0) {
      demand.subtasks.forEach((subtask) => {
        // TODO: separate by set of subtasks related to one task block, not by subtasks themselves
        const rowId = makeRowLabel(
          [...demand.areas],
          [...demand.shifts, ...demand.shiftGroups],
          subtask,
          null,
          Object.keys(rowIndexMap)
        );
        const blockIndex = blocks.indexOf(subtask.blockShortId);

        if (rowIndexMap[rowId] === undefined) {
          addToGrid(rowId);
        }
        setDemandAt(rowId, blockIndex, demand);
      });
    }
  });

  return [grid, rowIndexMap, demandsGrid];
}

const fillGridWithEmployeeID = (
  demandsSolution,
  rowIndexMap,
  employee,
  grid,
  columnData
) => {
  // Fill the grid with demand names from demandsSolution
  demandsSolution.forEach((demand) => {
    if (demand.subtasks.length > 0) {
      demand.subtasks.forEach((subtask) => {
        const rowId = makeRowLabel(
          [...demand.areas],
          [...demand.shifts, ...demand.shiftGroups],
          subtask,
          null,
          Object.keys(rowIndexMap)
        );
        const blockShortId = subtask.blockShortId;
        addEmployeeToGrid(
          grid,
          rowIndexMap,
          employee.id,
          rowId,
          columnData,
          blockShortId
        );
      });
    } else if (demand.tasks.length > 0) {
      const rowId = makeRowLabel(
        [...demand.areas],
        [...demand.shifts, ...demand.shiftGroups],
        null,
        demand.tasks,
        Object.keys(rowIndexMap)
      );
      addEmployeeToGrid(grid, rowIndexMap, employee.id, rowId, columnData);
    } else {
      const rowId = makeRowLabel(
        [...demand.areas],
        [...demand.shifts, ...demand.shiftGroups]
      );
      addEmployeeToGrid(grid, rowIndexMap, employee.id, rowId, columnData);
    }
  });

  return grid;
};

const addEmployeeToGrid = (
  grid,
  rowIndexMap,
  employeeID,
  rowId,
  columnData,
  blockShortId = KEYWORD_ANY
) => {
  const colIndex = columnData.indexOf(blockShortId) + 1;
  if (
    colIndex !== -1 &&
    rowIndexMap[rowId] !== null &&
    rowIndexMap[rowId] !== undefined
  ) {
    grid[rowIndexMap[rowId]][colIndex] = employeeID;
  }
};

export function allocationToDemands(
  allocation,
  demandsList,
  shiftGroups,
  employeeSkills,
  customKeywordsDict
) {
  const relevantDemands = [];
  demandsList.forEach(function (demand) {
    if (
      isDemandSatisfiedByAllocation(
        demand,
        allocation,
        shiftGroups,
        employeeSkills,
        customKeywordsDict
      )
    ) {
      relevantDemands.push(demand);
    }
  });

  return relevantDemands;
}

function allShiftsAndShiftGroupsFulfilled(
  demand,
  shiftGroups,
  allocationComponents,
  employeeSkills,
  customKeywordsDict
) {
  return (
    demand.shifts.includes(allocationComponents[0]) ||
    (demand.shiftGroups.length > 0 &&
      demand.shiftGroups.some((shiftGroupShortId) => {
        const shiftGroup = shiftGroups.filter(
          (sg) => sg.shortId === shiftGroupShortId
        )[0];
        if (!shiftGroup) return false;
        return allocationFulfilsShiftGroup(
          shiftGroup,
          null,
          allocationComponents[0],
          allocationComponents[1],
          employeeSkills,
          customKeywordsDict
        );
      }))
  );
}

// Example function to determine if an allocation satisfies a demand
export function isDemandSatisfiedByAllocation(
  demand,
  allocation,
  shiftGroups,
  employeeSkills,
  customKeywordsDict
) {
  if (allocation === "") return false;

  const [areaShortId, rest] = allocation.split(":");
  const areaExistsInAllocation = Boolean(rest);

  const allocationComponents = rest ? rest.split("-") : allocation.split("-");

  if (allocationComponents.length == 1 && demand.subtasks.length > 0) {
    return false;
  }

  if (areaExistsInAllocation) {
    if (demand.areas.length > 0 && !demand.areas.includes(areaShortId)) {
      return false;
    }
  }

  if (demand.subtasks.length === 0 && demand.tasks.length === 0) {
    return (
      demand.shifts.length + demand.shiftGroups.length === 0 ||
      allShiftsAndShiftGroupsFulfilled(
        demand,
        shiftGroups,
        allocationComponents,
        employeeSkills,
        customKeywordsDict
      )
    );
  } else if (demand.tasks.length > 0) {
    return (
      (demand.shifts.length + demand.shiftGroups.length === 0 ||
        allShiftsAndShiftGroupsFulfilled(
          demand,
          shiftGroups,
          allocationComponents,
          employeeSkills,
          customKeywordsDict
        )) &&
      demand.tasks.includes(allocationComponents[1])
    );
  }

  const demandSubtaskShortIds = demand.subtasks.map(
    (subtask) => subtask.shortId
  );
  const allocationSubtasks = allocationComponents[1].split("/");

  const shiftIncluded =
    demand.shifts.length + demand.shiftGroups.length === 0 ||
    allShiftsAndShiftGroupsFulfilled(
      demand,
      shiftGroups,
      allocationComponents,
      employeeSkills,
      customKeywordsDict
    );
  const subtaskIncluded =
    demandSubtaskShortIds.includes(allocationSubtasks[0]) ||
    demandSubtaskShortIds.includes(allocationSubtasks[1]);
  return shiftIncluded && subtaskIncluded;
}

export function getCellDemandValuesInfo(
  demandsGrid,
  rowId,
  dayIndex,
  blockIndex,
  numDays,
  locationStartDate,
  startDate,
  locationDefaultNumDays
) {
  const cellDemand = demandsGrid[rowId][blockIndex];

  if (!cellDemand) {
    return {
      minDemandValue: null,
      maxDemandValue: null,
    };
  }

  const maxDemandValues = cellDemand.maximumValues
    ? changeDemandValuesToFullLength(
        cellDemand.maximumValues,
        numDays,
        locationStartDate,
        startDate,
        locationDefaultNumDays
      )
    : null;
  const minDemandValues = cellDemand.minimumValues
    ? changeDemandValuesToFullLength(
        cellDemand.minimumValues,
        numDays,
        locationStartDate,
        startDate,
        locationDefaultNumDays
      )
    : null;

  return {
    minDemandValue: minDemandValues ? minDemandValues[dayIndex] : null,
    maxDemandValue: maxDemandValues ? maxDemandValues[dayIndex] : null,
  };
}
