import { useSearchParams } from "react-router-dom-v5-compat";
import { DateTime } from "../../../utils";
import { getAllSearchParams } from "../../../utils/routeUtils/routeUtils";

export const EMPLOYEE_APP_CONTENT_VIEW_TYPE = Object.freeze({
  schedule: "schedule",
  preferences: "preferences",
  applyLeave: "applyLeave",
  whosOnLeave: "whosOnLeave",
});

export const CALENDAR_VIEW_TYPE = Object.freeze({
  month: "month",
  week: "week",
});

const getDataFromQueryParams = (query, isMobile) => {
  const data = {
    selectedDate: new DateTime(),
    contentViewType: EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule,
    calendarViewType: CALENDAR_VIEW_TYPE.month,
    isCalendarView: false,
  };

  const date = query.get("date");
  const contentViewType = query.get("content-view-type");
  const calendarViewType = query.get("calendar-view-type");

  if (DateTime.isDateString(date)) {
    data.selectedDate = new DateTime(date);
  }
  if (Object.values(EMPLOYEE_APP_CONTENT_VIEW_TYPE).includes(contentViewType)) {
    data.contentViewType = contentViewType;
  }
  if (
    isMobile &&
    Object.values(CALENDAR_VIEW_TYPE).includes(calendarViewType)
  ) {
    data.calendarViewType = calendarViewType;
  }

  return data;
};

export function useEmployeeAppCurrentView(isMobile) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedDate, contentViewType, calendarViewType } =
    getDataFromQueryParams(searchParams, isMobile);
  const queryParams = getAllSearchParams(searchParams);

  const setQueryParam = (key, value) => {
    setSearchParams({
      ...queryParams,
      [key]: value,
    });
  };

  const setContentViewAndSelectedDate = (type, date) => {
    if (!Object.values(EMPLOYEE_APP_CONTENT_VIEW_TYPE).includes(type)) {
      throw new Error("Such content view type does not exist");
    }
    setSearchParams({
      ...queryParams,
      "content-view-type": type,
      date: date.toFormat("AWS"),
    });
  };

  const setSelectedDate = (date) => setQueryParam("date", date.toFormat("AWS"));

  const setContentViewType = (type) => {
    if (Object.values(EMPLOYEE_APP_CONTENT_VIEW_TYPE).includes(type)) {
      setQueryParam("content-view-type", type);
    }
  };

  const setCalendarViewType = (type) => {
    if (Object.values(CALENDAR_VIEW_TYPE).includes(type)) {
      setQueryParam("calendar-view-type", type);
    }
  };

  const toggleCalendarViewType = () => {
    if (calendarViewType === CALENDAR_VIEW_TYPE.month) {
      setCalendarViewType(CALENDAR_VIEW_TYPE.week);
    } else {
      setCalendarViewType(CALENDAR_VIEW_TYPE.month);
    }
  };

  const currentViewData = {
    selectedDate,
    contentViewType,
    calendarViewType,
  };

  return {
    currentViewData,
    setContentViewType,
    setSelectedDate,
    toggleCalendarViewType,
    setCalendarViewType,
    setContentViewAndSelectedDate,
  };
}
