import { useEffect } from "react";
import { useState } from "react";
import ShadowButton from "../../../../../components/elements/ShadowButton/ShadowButton";
import { DateTime } from "../../../../../utils";
import styles from "./DuplicateRoster.module.css";
import DatePicker from "react-datepicker";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import { prototypeRosterIsMonthView } from "../../../../../utils/queryUtils/monthViewUtils";

const areDatesEqual = (date1, date2) => {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return date1.getTime() === date2.getTime();
};

const DuplicateRoster = ({
  closeModal,
  currentDate,
  nextDate,
  numDays,
  duplicateRoster,
}) => {
  const [rosterName, setRosterName] = useState("");
  const [rosterStartDate, setRosterStartDate] = useState(nextDate);
  const [rosterDuration, setRosterDuration] = useState(numDays);
  const [overallFixedRequests, setOverallFixedRequests] = useState(true);
  const [recurringFixedRequests, setRecurringFixedRequests] = useState(true);
  const [overallPreferences, setOverallPreferences] = useState(true);
  const [recurringPreferences, setRecurringPreferences] = useState(true);

  const [isSolutionAsHistorySelected, setIsSolutionAsHistorySelected] =
    useState(true);
  const [isHistoryAsHistorySelected, setIsHistoryAsHistorySelected] =
    useState(false);
  const [isNoHistorySelected, setIsNoHistorySelected] = useState(false);
  const [copyRosterAllocations, setCopyRosterAllocations] = useState(false);

  const finishDate = new DateTime(rosterStartDate).addDays(
    rosterDuration - 1
  ).date;

  const isMonthView = prototypeRosterIsMonthView(numDays);

  useEffect(() => {
    if (areDatesEqual(rosterStartDate, nextDate)) {
      setHistoryUsage("solution");
    } else if (areDatesEqual(rosterStartDate, currentDate)) {
      setHistoryUsage("history");
    }
  }, [rosterStartDate, currentDate, nextDate]);

  const setCustomStartDate = (date) => {
    setRosterStartDate(new DateTime(date).getDate());
  };

  const handleNewStartDateCheckbox = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setRosterStartDate(nextDate);
    }
  };

  const handleCurrentStartDateCheckBox = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setRosterStartDate(currentDate);
    }
  };

  const setHistoryUsage = (historyUsage) => {
    switch (historyUsage) {
      case "none":
        setIsNoHistorySelected(true);
        setIsHistoryAsHistorySelected(false);
        setIsSolutionAsHistorySelected(false);
        return;
      case "solution":
        setIsNoHistorySelected(false);
        setIsHistoryAsHistorySelected(false);
        setIsSolutionAsHistorySelected(true);
        return;
      case "history":
        setIsNoHistorySelected(false);
        setIsHistoryAsHistorySelected(true);
        setIsSolutionAsHistorySelected(false);
        return;
      default:
        setIsNoHistorySelected(true);
        setIsHistoryAsHistorySelected(false);
        setIsSolutionAsHistorySelected(false);
    }
  };

  const validateInputs = () => {
    if (isMonthView) return "";

    if (isNaN(rosterDuration)) {
      return "Weeks must be a number";
    }

    if (rosterDuration / 7 > 16) {
      return "Weeks cannot be greater than 16";
    }

    if (rosterDuration / 7 < 2) {
      return "Weeks cannot be less than 2";
    }

    if (rosterDuration % 14 !== 0) {
      return "Weeks must be an even number";
    }

    return "";
  };

  const handleCreate = async () => {
    const dataToCarryOver = {
      overallFixedRequests,
      recurringFixedRequests,
      overallPreferences,
      recurringPreferences,
    };
    const errorMessage = validateInputs();
    if (errorMessage !== "") {
      customWarningAlert({ title: "Warning", descriptions: [errorMessage] });
      return;
    }

    let historyUsage = "none";
    if (isSolutionAsHistorySelected) {
      historyUsage = "roster";
    }
    if (isHistoryAsHistorySelected) {
      historyUsage = "history";
    }

    await duplicateRoster(
      rosterName,
      rosterStartDate,
      rosterDuration,
      dataToCarryOver,
      historyUsage,
      copyRosterAllocations
    );
    closeModal();
    return;
  };

  return (
    <div className={styles.container}>
      <div className={styles.duplicateRoster}>
        <h1 className={styles.duplicateRosterHeader}>Duplicate roster</h1>
        <div className={styles.inner}>
          <div className={styles.contents}>
            <div className={styles.leftInputs}>
              <h2 className={styles.inputHeader}>New roster name</h2>
              <input
                type={"text"}
                className={styles.nameInput}
                value={rosterName}
                onChange={(event) => setRosterName(event.target.value)}
                placeholder="Please enter new roster name"
              />
              <h2 className={styles.inputHeader}>New roster period</h2>

              <div className={styles.reminderWords}>
                <h5 className={styles.from}>From</h5>
                <h5 className={styles.to}>To</h5>
                <h5 className={styles.weeks}>Weeks</h5>
              </div>
              <div className={styles.input}>
                <DatePicker
                  id="roster-date"
                  calendarStartDay={1}
                  selected={new DateTime(rosterStartDate).date}
                  dateFormat="dd/MM/yyyy"
                  className={`${styles.dateInput} ${styles.startDate}`}
                  onChange={(date) =>
                    setCustomStartDate(
                      new DateTime(date, false).toFormat("AWS")
                    )
                  }
                />
                <DatePicker
                  id="roster-date"
                  className={`${styles.dateInput} ${styles.finishDate}`}
                  calendarStartDay={1}
                  selected={new DateTime(finishDate).date}
                  dateFormat="dd/MM/yyyy"
                  readOnly={true}
                />
                <input
                  type={"number"}
                  className={
                    styles.durationInput +
                    " " +
                    (isMonthView ? styles.finishDate : "")
                  }
                  value={rosterDuration / 7}
                  onChange={(event) =>
                    setRosterDuration(event.target.value * 7)
                  }
                  min={2}
                  max={16}
                  step={2}
                  readOnly={isMonthView}
                />{" "}
              </div>
              <div className={styles.startDates}>
                <div className={styles.checkboxAlign}>
                  <input
                    id="nextStartDate"
                    type="checkbox"
                    className={styles.checkboxSpace}
                    onChange={handleNewStartDateCheckbox}
                    checked={areDatesEqual(rosterStartDate, nextDate)}
                  />
                  <label htmlFor="nextStartDate">Next start date</label>
                </div>
                <div className={styles.checkboxAlign}>
                  <input
                    id="currentStartDate"
                    type="checkbox"
                    className={styles.checkboxSpace}
                    onChange={handleCurrentStartDateCheckBox}
                    checked={areDatesEqual(rosterStartDate, currentDate)}
                  />
                  <label htmlFor="currentStartDate">Current start date</label>
                </div>
              </div>
            </div>
            <div className={styles.rightHeader}>
              <h2 className={styles.inputHeader}>Data to carry over</h2>
              <div className={styles.specificOptions}>
                <div className={styles.optionWrapper}>
                  <input
                    type="checkbox"
                    checked={overallFixedRequests}
                    onChange={() =>
                      setOverallFixedRequests(!overallFixedRequests)
                    }
                    id="overallFixedRequests"
                  />
                  <label htmlFor="overallFixedRequests">
                    One-off fixed requests
                  </label>
                </div>
                <div className={styles.optionWrapper}>
                  <input
                    type="checkbox"
                    checked={recurringFixedRequests}
                    onChange={() =>
                      setRecurringFixedRequests(!recurringFixedRequests)
                    }
                    id="recurringFixedRequests"
                  />
                  <label htmlFor="recurringFixedRequests">
                    Recurring fixed requests
                  </label>
                </div>
                <div className={styles.optionWrapper}>
                  <input
                    type="checkbox"
                    checked={overallPreferences}
                    onChange={() => setOverallPreferences(!overallPreferences)}
                    id="overallPreferences"
                  />
                  <label htmlFor="overallPreferences">
                    One-off preferences
                  </label>
                </div>
                <div className={styles.optionWrapper}>
                  <input
                    type="checkbox"
                    checked={recurringPreferences}
                    onChange={() =>
                      setRecurringPreferences(!recurringPreferences)
                    }
                    id="recurringPreferences"
                  />
                  <label htmlFor="recurringPreferences">
                    Recurring preferences
                  </label>
                </div>
                <div className={styles.optionWrapper}>
                  <input
                    type="checkbox"
                    checked={copyRosterAllocations}
                    onChange={() =>
                      setCopyRosterAllocations(!copyRosterAllocations)
                    }
                    id="rosterAllocations"
                  />
                  <label htmlFor="rosterAllocations">
                    Duplicate roster allocations
                  </label>
                </div>
              </div>
              <div className={styles.optionWrapper}>
                <input
                  type="checkbox"
                  className={styles.useHistoryCheckbox}
                  checked={isSolutionAsHistorySelected}
                  onChange={() => setHistoryUsage("solution")}
                  id="currentSolutionAsHistory"
                />
                <label htmlFor="currentSolutionAsHistory">
                  Use current roster as history
                </label>
              </div>
              <div className={styles.optionWrapper}>
                <input
                  type="checkbox"
                  className={styles.useHistoryCheckbox}
                  checked={isHistoryAsHistorySelected}
                  onChange={() => setHistoryUsage("history")}
                  id="currentHistoryAsHistory"
                />
                <label htmlFor="currentHistoryAsHistory">
                  Use current roster history as history
                </label>
              </div>
              <div className={styles.optionWrapper}>
                <input
                  type="checkbox"
                  className={styles.useHistoryCheckbox}
                  checked={isNoHistorySelected}
                  onChange={() => setHistoryUsage("none")}
                  id="noHistory"
                />
                <label htmlFor="noHistory">Do not duplicate history</label>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <ShadowButton
              backgroundColor="#4E9BF8"
              hoverColor="#488CDE"
              labelColor="white"
              border="none"
              shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
              onClick={handleCreate}
            >
              Create
            </ShadowButton>
            <ShadowButton
              backgroundColor="white"
              hoverColor="#4E9BF8"
              labelColor="#4E9BF8"
              border="1px solid #4E9BF8"
              shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
              onClick={() => {
                closeModal(null);
              }}
            >
              Cancel
            </ShadowButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicateRoster;
