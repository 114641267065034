import styles from "./OnboardingWizard.module.css";
import { useState } from "react";
import { Wizard } from "react-use-wizard";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4, { getStaffingNumbers } from "./steps/Step4";
import Step5 from "./steps/Step5";
import useStandardDataContainer from "../../../hooks/modelQueryHooks/useStandardDataContainer";
import { getNewShiftsTemplate } from "../../../utils/queryUtils/rosterDataGetters";
import {
  getEmployeesAndCustomRulesAfterRulesUpdate,
  getNewDemandsTemplate,
} from "../../../utils/queryUtils/sharedModelDataGetters";
import { getStarterRules } from "../../../features/rules/service/rules";
import { generateScheduleRoster } from "../../../features/rosterGenerator/service/generateRoster";
import { useHistory } from "react-router-dom";
import { calculateWarnings } from "../../../features/warnings/service/validators/warningsCalculator";
import { ALL_WARNING_FIELDS } from "../../../constants/warnings";

const rulesData = getStarterRules();

const getNewRosterFromOnboardingData = (onboardingData, roster) => {
  const newShift = getNewShiftsTemplate({
    name: onboardingData.shiftName,
    startTime: onboardingData.shiftStart,
    finishTime: onboardingData.shiftEnd,
    existingShifts: [],
    numItems: 1,
  });

  const [minDemandValues, maxDemandValues] = getStaffingNumbers(
    onboardingData.minStaffingNumber,
    onboardingData.maxStaffingNumber,
    onboardingData.staffingFrequency
  );

  const minDemand = getNewDemandsTemplate({
    shifts: newShift.shortId,
    startTime: onboardingData.shiftStart,
    finishTime: onboardingData.shiftEnd,
    values: minDemandValues,
    importance: "Critical",
    type: "Minimum",
    numItems: 1,
  });

  const maxDemand = getNewDemandsTemplate({
    shifts: newShift.shortId,
    startTime: onboardingData.shiftStart,
    finishTime: onboardingData.shiftEnd,
    values: maxDemandValues,
    importance: "High",
    type: "Maximum",
    numItems: 1,
  });

  const rule = rulesData.filter(
    (fullRule) => fullRule.name === onboardingData.fteRuleCategory
  )[0];

  const subrule = rule.subrules[onboardingData.fteSubruleIndex];

  const { updatedEmployees, updatedCustomRules } =
    getEmployeesAndCustomRulesAfterRulesUpdate(
      roster.Employees,
      [
        {
          ruleCustomName: subrule.ruleName,
          template: subrule.ruleTemplate,
          defaultValue: onboardingData.fteRuleValue,
          rulePrevIndex: -1,
        },
      ],
      false
    );

  const updatedRoster = {
    ...roster,
    Employees: updatedEmployees,
    CustomRules: updatedCustomRules,
    Shifts: newShift,
    Demands: [...minDemand, ...maxDemand],
  };

  return updatedRoster;
};

const OnboardingWizard = ({
  location,
  rosterID,
  closeWizard,
  numEmployees,
}) => {
  const locationID = location.id;
  const history = useHistory();

  const { roster, fields, updateFields, customKeywords } =
    useStandardDataContainer({
      isScheduleView: true,
      locationID,
      rosterID,
    });

  const alreadyHasRules = fields.rules.length > 0;

  const [onboardingData, setOnboardingData] = useState({
    shiftName: "Day",
    shiftStart: "09:00:00",
    shiftEnd: "17:00:00",
    staffingFrequency: "everyday",
    minStaffingNumber: 1,
    maxStaffingNumber: 1,
    fteRuleCategory: "hours",
    fteSubruleIndex: 0,
    fteRuleValue: 80,
  });

  const updateOnboardingData = (newData) => {
    setOnboardingData((prevData) => ({ ...prevData, ...newData }));
  };

  const updateRosterAndGenerate = () => {
    const updatedRoster = getNewRosterFromOnboardingData(
      onboardingData,
      roster
    );

    updateFields(
      ["Shifts", "Demands", "Employees", "CustomRules"],
      updatedRoster,
      roster,
      ["RuleValues"]
    );

    const warnings = calculateWarnings(
      roster,
      ALL_WARNING_FIELDS,
      customKeywords
    );

    generateScheduleRoster(updatedRoster, locationID, warnings).then(
      (isSucceeded) => {
        if (isSucceeded) {
          history.push(
            `/locations/schedule-view/solutions?location-id=${updatedRoster.locationID}&date=${updatedRoster.startDate}`
          );
        }
      }
    );

    closeWizard();
  };

  return (
    <div className={styles.container}>
      <dialog open className={styles.dialog}>
        <Wizard>
          <Step1 />
          <Step2 data={onboardingData} updateData={updateOnboardingData} />
          <Step3 data={onboardingData} updateData={updateOnboardingData} />
          <Step4
            data={onboardingData}
            updateData={updateOnboardingData}
            alreadyHasRules={alreadyHasRules}
            numEmployees={numEmployees}
          />
          <Step5
            data={onboardingData}
            alreadyHasRules={alreadyHasRules}
            generateRoster={updateRosterAndGenerate}
          />
        </Wizard>
      </dialog>
    </div>
  );
};

export default OnboardingWizard;
