import Select, { components } from "react-select";
import "./DropdownCollapsibleSelector.css";

const DropdownCollapsibleSelector = ({
  options,
  onChange,
  defaultValue,
  customWrapperStyle = null,
  id,
  menuPosition = "absolute",
}) => {
  const handleHeaderClick = (id) => {
    const node = document.querySelector(`#${id}`).parentElement
      .nextElementSibling;

    const classes = node.classList;
    if (classes.contains("collapsed")) {
      node.classList.remove("collapsed");
    } else {
      node.classList.add("collapsed");
    }
  };

  const CustomGroupHeading = (props) => {
    return (
      <div
        className="group-heading-wrapper"
        onClick={() => handleHeaderClick(props.id)}
        style={
          props.children !== "General rules"
            ? {
                borderTop: "2px solid #e5eaeb",
                paddingTop: "10px",
              }
            : {}
        }
      >
        <components.GroupHeading {...props} />
      </div>
    );
  };

  return (
    <Select
      key={defaultValue.value}
      instanceId={id}
      options={options}
      components={{ GroupHeading: CustomGroupHeading }}
      onChange={onChange}
      defaultValue={defaultValue}
      menuPosition={menuPosition}
      styles={{
        control: (base) => ({
          ...base,
          ...(customWrapperStyle ? customWrapperStyle : {}),
        }),
      }}
    />
  );
};

export default DropdownCollapsibleSelector;
