import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTopBanner } from "../../../globalStore/appStore";
import styles from "./TopBanner.module.css";
import { useCallback, useEffect, useState } from "react";

const TopBanner = () => {
  const { isBannerShowing, hideBanner, message } = useTopBanner();
  const [isOpen, setIsOpen] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState(message);

  const openBanner = useCallback(async () => {
    setDisplayedMessage(message);
    return new Promise((resolve) => {
      setTimeout(() => {
        setIsOpen(true);
        resolve();
      }, 500);
    });
  }, [message]);

  const closeBanner = useCallback(async () => {
    setIsOpen(false);
    return new Promise((resolve) => {
      setTimeout(() => {
        setDisplayedMessage("");
        resolve();
      }, 500);
    });
  }, []);

  useEffect(() => {
    if (isBannerShowing) {
      openBanner();
      return;
    }
    closeBanner();
  }, [isBannerShowing, openBanner, closeBanner]);

  return (
    <div
      className={`${styles.container} ${
        isOpen ? styles.displayed : styles.hidden
      }`}
    >
      <div className={styles.description}>{displayedMessage}</div>
      <button className={styles.closeButton} onClick={hideBanner}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default TopBanner;
