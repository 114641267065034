export const inferSearchedShift = (inputValue, listedOptions) => {
  const inputShiftSkillPair = inputValue.split("-");
  const shift = inputShiftSkillPair[0].trim();
  const skill = inputShiftSkillPair[1] ? inputShiftSkillPair[1].trim() : null;

  const formattedInputValue = skill ? `${shift} - ${skill}` : shift;

  const lowercasedFormattedInput = formattedInputValue.toLowerCase();
  const inferredSelected = listedOptions.find((o) => {
    return (
      o.toLowerCase().includes(lowercasedFormattedInput) ||
      o.split(" - ").join("").toLowerCase().includes(lowercasedFormattedInput)
    );
  });

  return inferredSelected;
};
