import create from "zustand";
import {
  PLAN,
  getNameOfUser,
  getUserEmail,
  getUserInitials,
  refreshUserData,
} from "../features/auth/service/auth";
import { DateTime, isNetworkConnectionActive } from "../utils";
import { Link } from "react-router-dom-v5-compat";

export const useAppVersionStore = create((set) => ({
  newerVersionExist: false,
  setNewerVersionExist: (newerVersionDetected) => {
    set({
      newerVersionExist: newerVersionDetected,
    });
  },
}));

const extractUserInfo = (user) => {
  const email = user ? getUserEmail(user) : null;
  const userName = user ? getNameOfUser(user) : null;
  const userInitials = user ? getUserInitials(user) : null;
  const userUserName = user ? user.username : null;

  return {
    email,
    userName,
    userInitials,
    userUserName,
  };
};

const initialState = {
  user: null,
  email: null,
  userName: null,
  userUserName: null,
  userInitials: null,
  plan: null,
  isPaidPlan: false,
  isAIPlan: false,
  maxGlobalEmployees: null,
  totalGlobalEmployees: null,
  trialEnd: null,
};

export const useUserStore = create((set) => ({
  ...initialState,
  setUser: async (user) => {
    if (user === null) {
      set({ ...initialState });
      return null;
    }
    const userInfo = extractUserInfo(user);
    set({ user: user ? user : null, ...userInfo });
    return user;
  },
  setToCurrentUser: async (forceRefreshIfConnectionActive = true) => {
    const isNetworkActive = await isNetworkConnectionActive();
    const user = await refreshUserData(
      forceRefreshIfConnectionActive && isNetworkActive
    );
    const userInfo = extractUserInfo(user);
    set({ user: user ? user : null, ...userInfo });
    return user;
  },
  setUserPlanInfo: (userInfo) => {
    set({ ...userInfo });
  },
}));

export const getTrialDaysLeft = (trialEnd) => {
  const trialFinishDate = DateTime.convertDateNumberToDateTime(trialEnd);
  const daysLeft = DateTime.getDifferenceInDays(
    new DateTime(),
    trialFinishDate
  );
  return daysLeft;
};

export const checkTrialIsInProgress = (trialEnd) => {
  if (!trialEnd) {
    return false;
  }
  const daysLeft = getTrialDaysLeft(trialEnd);
  return daysLeft > 0;
};

export const getUserPlan = () => {
  return useUserStore.getState().plan;
};

export const getUserUsername = () => {
  return useUserStore.getState().userUserName;
};

export const getPlanDetails = () => {
  const currentUserState = useUserStore.getState();

  return {
    plan: currentUserState.plan,
    isTrialInProgress: checkTrialIsInProgress(currentUserState.trialEnd),
    totalGlobalEmployees: currentUserState.totalGlobalEmployees,
    maxGlobalEmployees: currentUserState.maxGlobalEmployees,
  };
};

export const useOnBoardingStore = create((set, get) => ({
  isCheckListOpen: false,
  setIsCheckListOpen: (isOpen) => {
    set({
      isCheckListOpen: isOpen,
    });
  },
  tourRunning: false,
  setIsTourRunning: (isRunning) => {
    set({
      tourRunning: isRunning,
      isActionPerformed: false,
    });
  },
  stepIndex: 0,
  setStepIndex: (stepIndex) => {
    set({
      stepIndex,
    });
  },
  isActionPerformed: false,
  setIsActionPerformed: (isPerformed) => {
    set({
      isActionPerformed: isPerformed,
    });
  },
  isWizardOpen: false,
  setIsWizardOpen: (isOpen) => {
    set({
      isWizardOpen: isOpen,
    });
  },
  setStepIndexByAction: (stepIndex) => {
    const isActionPerformed = get().isActionPerformed;
    if (!isActionPerformed) {
      set({
        stepIndex,
        isActionPerformed: true,
      });
    }
  },
  manuallyStepThroughTour: (delay) => {
    const tourRunning = get().tourRunning;
    const setStepIndexByAction = get().setStepIndexByAction;
    const stepIndex = get().stepIndex;
    if (tourRunning) {
      setTimeout(() => {
        setStepIndexByAction(stepIndex + 1);
      }, delay);
    }
  },
}));

export const useTopBanner = create((set) => ({
  isBannerShowing: false,
  message: "",
  displayBanner: (message) => {
    set({
      isBannerShowing: true,
      message,
    });
  },
  hideBanner: () => {
    set({
      isBannerShowing: false,
      message: "",
    });
  },
  handleDisplayTrialBanner: (isUserAdmin, plan, trialEnd) => {
    const style = {
      display: "flex",
      gap: "10px",
    };

    const linkStyle = {
      color: "#0000FF",
      fontWeight: "bold",
      textDecoration: "underline",
    };

    if (!isUserAdmin || plan === PLAN.AI || !trialEnd) {
      return set({
        isBannerShowing: false,
        message: "",
      });
    }

    const isTrialInProgress = checkTrialIsInProgress(trialEnd);

    if (plan === PLAN.MID && isTrialInProgress) {
      const daysLeft = getTrialDaysLeft(trialEnd);
      set({
        isBannerShowing: true,
        message: (
          <div style={style}>
            <p>
              You have <b>{daysLeft}</b> days of free trial left for RosterLab
              Starter Plan
            </p>
            <Link to="/billing" style={linkStyle}>
              Choose a Plan
            </Link>
          </div>
        ),
      });
      return;
    }

    if (plan === PLAN.LITE) {
      set({
        isBannerShowing: true,
        message: (
          <div style={style}>
            <p>You are on RosterLab Free (Manual Scheduling)</p>
            <Link to="/billing" style={linkStyle}>
              Choose AI Plans
            </Link>
          </div>
        ),
      });
    }
  },
}));
