import { AgChartsReact } from "ag-charts-react";
import styles from "./DemandGraphs.module.css";
import { DateTime } from "../../../../../utils";
import { generatePatternOutput } from "../../../../../utils/queryUtils/monthViewUtils";

const calculateWeightedAverageForDay = (
  demandData,
  d,
  startDate,
  numDays,
  locationStartDate,
  locationDefaultNumDays
) => {
  if (demandData.length === 0) {
    return 0;
  }

  const valuesList = demandData.map((dat) => {
    let demandValues = dat.values;

    if (locationDefaultNumDays) {
      demandValues = generatePatternOutput(
        dat.values,
        startDate,
        numDays,
        locationStartDate,
        locationDefaultNumDays
      );
    }

    if (demandValues[d] === "") return 0;

    return Math.max(0, parseFloat(demandValues[d]));
  });
  const hoursList = demandData.map((dat) =>
    DateTime.getTimeDiff(
      dat["timeSpan"].split("-")[0],
      dat["timeSpan"].split("-")[1],
      false
    )
  );
  const totalHours = hoursList.reduce((a, b) => a + b, 0);

  let weightedSum = 0;
  for (let i = 0; i < valuesList.length; i++) {
    weightedSum += ((hoursList[i] + 0.0) / totalHours) * valuesList[i];
  }

  return weightedSum;
};

var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const createOptions = (
  originalDemand,
  demandData,
  numDays,
  startDate,
  locationStartDate,
  locationDefaultNumDays
) => {
  const offsetDemandValues = generatePatternOutput(
    originalDemand.values,
    startDate,
    numDays,
    locationStartDate,
    locationDefaultNumDays
  );

  let normaliser = 0;
  for (let d = 0; d < numDays; d++) {
    const weightedValue = calculateWeightedAverageForDay(
      demandData,
      d,
      startDate,
      numDays,
      locationStartDate,
      locationDefaultNumDays
    );
    if (weightedValue > normaliser) {
      normaliser = weightedValue;
    }
  }
  normaliser *= 1.5;
  normaliser = Math.max(2, Math.ceil(normaliser));

  const data = [];
  for (let d = 0; d < numDays; d++) {
    let originalVal = offsetDemandValues[d % offsetDemandValues.length];

    if (originalVal === -1) {
      originalVal = 0;
      if (originalDemand.type === "Maximum") {
        originalVal = normaliser;
      }
    }

    let min = originalDemand.type === "Maximum" ? 0 : originalVal;
    let max = originalDemand.type === "Minimum" ? normaliser : originalVal;

    for (let i = 0; i < demandData.length; i++) {
      const target = parseInt(Math.max(0, demandData[i].targets[d]));
      if (
        demandData[i].type === "Minimum" &&
        originalDemand.type === "Maximum"
      ) {
        if (target > min) min = target;
      } else if (
        demandData[i].type === "Maximum" &&
        originalDemand.type === "Minimum"
      ) {
        if (target < max) {
          max = target;
        }
      }
    }

    let weightedSum = calculateWeightedAverageForDay(demandData, d);

    max = Math.max(min, max);

    const newDate = new DateTime(startDate);

    data.push({
      day: newDate.addDays(d).date,
      actual: weightedSum,
      min: min,
      max: max - min,
      filler: Math.max(0, normaliser - max),
    });
  }

  return {
    data: data,
    series: [
      {
        type: "area",
        xKey: "day",
        yKey: "min",
        yName: "Min",
        tooltip: {
          enabled: false,
        },
        normalizedTo: normaliser,
        stacked: true,
        fill: "#ff00001e",
        stroke: "#f9bbbe",
      },
      {
        type: "area",
        xKey: "day",
        yKey: "max",
        yName: "Ideal",
        tooltip: {
          enabled: false,
        },
        normalizedTo: normaliser,
        stacked: true,
        fill: "white",
        stroke: "#60d1dc",
      },
      {
        type: "area",
        xKey: "day",
        yKey: "filler",
        yName: "Max",
        tooltip: {
          enabled: false,
        },
        normalizedTo: normaliser,
        stacked: true,
        fill: "#a8ebf2",
        stroke: "#60d1dc",
      },
      {
        type: "line",
        xKey: "day",
        yKey: "actual",
        yName: "Actual",
        tooltip: { renderer: renderer },
        stroke: "#004bad",
        marker: {
          size: 8,
          fill: "#292d35",
          stroke: "#292d35",
        },
        strokeWidth: 3,
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        title: {
          text: "Day",
          enabled: true,
        },
        nice: false,
      },
      {
        type: "number",
        position: "left",
        title: {
          text: "Staffing number",
          enabled: true,
        },
      },
    ],
    tooltip: {
      enabled: true,
    },
  };
};

function renderer(params) {
  return {
    title: params.xValue.toLocaleDateString("en-nz", options),
    content: "Staffing number: " + params.yValue.toFixed(1),
  };
}

const DemandGraphs = ({
  originalDemand,
  demandData,
  numDays,
  startDate,
  locationStartDate,
  locationDefaultNumDays,
}) => {
  return (
    <div className={styles.graphWrapper}>
      <AgChartsReact
        options={createOptions(
          originalDemand,
          demandData,
          numDays,
          startDate,
          locationStartDate,
          locationDefaultNumDays
        )}
      />
    </div>
  );
};

export default DemandGraphs;
