import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./MultiSelectShifts.module.css";
import { KEYWORD_ALL } from "../../../constants/keywords";

const MultiSelectShifts = ({
  shiftShortIds,
  shiftGroupShortIds,
  leaveKeywords,
  selected,
  setSelected,
  isAllSelected,
  width = "300px",
  shifts,
  shiftGroups,
}) => {
  const allOptions = [
    ...leaveKeywords,
    ...shiftShortIds,
    ...shiftGroupShortIds,
  ];

  const handleChangeShifts = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === KEYWORD_ALL) {
      setSelected(selected.length === allOptions.length ? [] : allOptions);
      return;
    }
    setSelected(value);
  };

  const numChars = selected.reduce((acc, str) => acc + str.length, 0);

  const getLabel = (value) => {
    let label = "";
    if (leaveKeywords.includes(value)) {
      label = value;
    }

    if (!label) {
      const targetShift = shifts.find(({ shortId }) => shortId === value);
      if (targetShift) {
        label = targetShift.name;
      }
    }

    if (!label) {
      const targetShiftGroup = shiftGroups.find(
        ({ shortId }) => shortId === value
      );
      if (targetShiftGroup) {
        label = targetShiftGroup.name;
      }
    }
    return label;
  };

  return (
    <Select
      multiple
      value={selected}
      onChange={handleChangeShifts}
      renderValue={(selected) => {
        const selectedLabels = selected.map(getLabel);

        if (selected.length === 1) return selectedLabels[0];
        else if (numChars < 25) return selectedLabels.join(", ");
        else if (selected.length === allOptions.length) return "All shifts";
        else return `${selected.length}/${allOptions.length} shifts`;
      }}
      style={{ width, backgroundColor: "white" }}
      MenuProps={{ autoFocus: false }}
    >
      <MenuItem
        value={KEYWORD_ALL}
        classes={{
          root: isAllSelected ? styles.selectedAll : "",
        }}
      >
        <ListItemIcon>
          <Checkbox
            classes={{ indeterminate: styles.indeterminateColor }}
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < allOptions.length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: styles.selectAllText }}
          primary="Select All"
        />
      </MenuItem>
      {allOptions.map((value) => {
        return (
          <MenuItem key={value} value={value}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(value) > -1} />
            </ListItemIcon>
            <ListItemText primary={getLabel(value)} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default MultiSelectShifts;
