import { faGears } from "@fortawesome/free-solid-svg-icons";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import styles from "./ReminderAlert.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ReminderAlert = ({
  show,
  proceed,
  title = "",
  descriptions,
  okLabel = "OK",
}) => {
  return (
    <ModalFrame
      open={show}
      onClose={() => proceed(false)}
      shouldCloseOnOutsideClick={true}
      customStyle={{ borderRadius: "6px" }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon className={styles.icon} icon={faGears} />
          </div>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{title}</p>
          <div className={styles.descriptions}>
            {descriptions.map((description, idx) => (
              <div key={idx} className={styles.description}>
                {description}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <button
            className={`${styles.button} ${styles.okBtn}`}
            onClick={() => proceed(true)}
          >
            {okLabel}
          </button>
        </div>
      </div>
    </ModalFrame>
  );
};
