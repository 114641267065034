import styles from "./Step.module.css";
import { useWizard } from "react-use-wizard";
import StepTemplate from "./StepTemplate";
import { getStarterRules } from "../../../../features/rules/service/rules";
import StepTitle from "./StepTitle";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "../../../../utils";

const rulesData = getStarterRules();

const Step5 = ({ data, generateRoster }) => {
  const { previousStep } = useWizard();

  const rule = rulesData.filter(
    (fullRule) => fullRule.name === data.fteRuleCategory
  )[0];

  const subrule = rule.subrules[data.fteSubruleIndex];

  return (
    <StepTemplate
      previousStep={previousStep}
      nextStep={generateRoster}
      nextName="Generate"
    >
      <div className={`${styles.container} ${styles.singleContentContainer}`}>
        <StepTitle
          fontAwesomeIcon={faListCheck}
          text={"Step 4/4 - Generating your first roster"}
        />
        <h1 className={`${styles.title} ${styles.stepFiveTitle}`}>
          Generate a simple roster!
        </h1>
        <div className={styles.summary}>
          <ul className={styles.summaryList}>
            <li className={styles.summaryListItem}>
              <span>
                You have one <b className={styles.blueText}>shift</b> called{" "}
                <b className={styles.blueText}>{data.shiftName}</b> from{" "}
                <b className={styles.blueText}>
                  {DateTime.getFormattedTime(data.shiftStart, "readable")}
                </b>{" "}
                to{" "}
                <b className={styles.blueText}>
                  {DateTime.getFormattedTime(data.shiftEnd, "readable")}
                </b>
                .
              </span>
            </li>
            <li className={styles.summaryListItem}>
              <span>
                You require between{" "}
                <b className={styles.blueText}>
                  {data.minStaffingNumber} and {data.maxStaffingNumber}
                </b>{" "}
                employees to work the{" "}
                <b className={styles.blueText}>{data.shiftName}</b> shift{" "}
                <b className={styles.blueText}>{data.staffingFrequency}</b>.
              </span>
            </li>
            <li>
              <span>
                Your employees must work{" "}
                <b className={styles.blueText}>
                  {data.fteRuleValue} {subrule.ruleName.toLowerCase()}
                </b>
                .
              </span>
            </li>
          </ul>
        </div>
        <div className={`${styles.paragraphs} ${styles.stepFiveParagraphs}`}>
          <p className={styles.paragraph}>
            This walkthrough has shown you how to set up a bare-bones roster.
            You will need to add further shifts, rules, staffing demands, and
            more using the menu on the left before the roster is tailored to
            your requirements.
          </p>
          <p className={styles.paragraph}>
            For guidance with your more complex requirements, such as skills
            mix, staff availabilities, tasks assignment, fairness rules and many
            more, click on &apos;How to get started&apos; on the top of every
            page.
          </p>
          <p className={styles.paragraph}>
            If you would like <b className={styles.lightBlueText}>help</b> or
            would prefer us set up your roster for you,{" "}
            <b className={styles.lightBlueText}>contact</b> us using the{" "}
            <b className={styles.lightBlueText}>live chat</b> bubble in the
            bottom right.{" "}
          </p>
        </div>
      </div>
    </StepTemplate>
  );
};

export default Step5;
