import {
  isDefaultRuleAppliedToShiftCountCell,
  isShiftCountCellBelowMinRule,
  isShiftCountCellExceedsMaxRule,
} from "./cellColorConditions";

export const getRightColumnCellStyles = (minRuleValues, maxRuleValues) => {
  return (params) => {
    const style = {};
    const headerName = params.colDef.headerName;

    if (
      isDefaultRuleAppliedToShiftCountCell(
        minRuleValues,
        maxRuleValues,
        headerName,
        params.rowIndex
      )
    ) {
      style["backgroundColor"] = "transparent";
    } else if (
      isShiftCountCellBelowMinRule(
        minRuleValues,
        headerName,
        params.rowIndex,
        params.value
      )
    ) {
      style["backgroundColor"] = "#FCBED8";
    } else if (
      isShiftCountCellExceedsMaxRule(
        maxRuleValues,
        headerName,
        params.rowIndex,
        params.value
      )
    ) {
      style["backgroundColor"] = "#FCBED8";
    } else if (params.value === "") {
      style["backgroundColor"] = "white";
    } else {
      style["backgroundColor"] = "#CAF2E0";
    }
    return style;
  };
};

export const getMyRosterRowStyle = (params) => {
  if (params.data.id === "open-shifts") {
    return {
      borderBottom: "1px solid #EF396A",
    };
  }

  return getReservedHeaderRowStyle(params);
};

export const getReservedHeaderRowStyle = (params) => {
  if (params.data.id === "reserved_header") {
    return {
      background: "#F4F7F6",
      borderBottom: "1px solid #219ec9",
    };
  }
};

export const getReservedShiftsRowStyle = (params, reservedKeys) => {
  if (reservedKeys.includes(params.data.id)) {
    return {
      backgroundColor: "#C6E0B4",
      boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.25)",
    };
  }
};
