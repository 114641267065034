import { convertAllocationInShortIdFormToNameForm } from "../modelUtils/allocation";

export const convertToOptionPropForm = (names) => {
  return names.map((name) => {
    return {
      label: name,
      value: name,
    };
  });
};

export const convertSingleOptionToPropForm = (optionValue) => {
  return {
    label: optionValue,
    value: optionValue,
  };
};

export const convertToNameIdOptionForm = (list) => {
  return list.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

export const convertToShortIdNameOptionForm = (entities) => {
  return entities.map(({ name, shortId }) => {
    return {
      label: name,
      value: shortId,
    };
  });
};

export const convertToAllocationShortIdNameOptionForm = (
  allocations, // array of shortIds
  shortIdsToEntityNamesDicts
) => {
  return allocations.map((allocation) => {
    const label = convertAllocationInShortIdFormToNameForm(
      allocation,
      shortIdsToEntityNamesDicts
    );
    return {
      label,
      value: allocation,
    };
  });
};
