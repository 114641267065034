import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DateTime,
  getScheduleRosterDataFromGrid,
  getDayColumns,
  getFullEmployeeAllocationsSummary,
  getLongestAllocationStringInRowData,
  getNames,
  getRosterTableBottomPinnedRowsPlaceholders,
  inferredShiftDemandsToShiftDemandsInfo,
  parseEmployeeModelToRosterGridFormat,
  deepCopyObject,
  getAllRosterDataFromGrid,
  parseOpenShiftsToRosterGridFormat,
  sortByDateField,
  getAllDataIdFromGrid,
  getAllocationOptions,
  getChangedPublishedAllocationsToNotify,
  checkHasNeverBeenPublished,
  getShortIds,
  filterShiftGroupsByAreas,
  filterShiftsByAreas,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import { getMinMaxRuleValuesForStatsCol } from "../../../rules/service/ruleFunctions";
import RosterTable from "../../../rosterProblems/rosteredAllocations/components/RosterTable/RosterTable";
import { getShiftHoursForEmployees } from "../../../rules/service/rules";
import useModal from "../../../../hooks/useModal";
import { TABLE_NAMES } from "../../../../constants";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
  getSubtasks,
} from "../../../rosterProblems/service/rosterUtils";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import {
  getReorderedEntities,
  removeUnmutableModelFields,
  updateEmployeesWithOldEmployeeData,
} from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer, {
  getAllStandardUpdateFunctions,
} from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import {
  getFieldsFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters.ts";
import {
  getPlanDetails,
  useOnBoardingStore,
  useUserStore,
} from "../../../../globalStore/appStore";
import {
  getRosterTopController,
  getScheduleTopController,
  updateScheduleMyRoster,
} from "./ScheduleRosterDataContainerUtils";
import PublishCalendarModal from "../../../locations/components/PublishCalendarModal/PublishCalendarModal";
import {
  getGlobalEmployeeModelsByLocationId,
  getLocationModelById,
  getRostersByLocationId,
  getRostersBySnapshotStartDateRange,
  getRostersBySnapshotStatus,
} from "../../../../utils/queryUtils/locationQuery";
import {
  sendFirebaseNotificationsToBrowsers,
  sendFirstTimePublishNotifications,
  sendPublishNotifications,
} from "../../../notifications/service/notifications";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import OpenShiftsModal from "../../../locations/components/OpenShiftsModal/OpenShiftsModal";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import {
  getEmployeesAllocationNotes,
  getPublishedGlobalEmployees,
  getPublishedOnlyAllocations,
} from "../../../../utils/queryUtils/globalEmployeeDataGetters";
import { useLocation } from "react-router-dom-v5-compat";
import { getInvalidCellsFromRules } from "../../../rules/service/invalidCellsGetter";
import { customWarningAlert } from "../../../confirm/service/confirm";
import {
  checkMidTierEmployeeRestriction,
  handleBillingRedirection,
} from "../EmployeeNumberLimitModal/EmployeeNumberLimitModal";
import {
  retrieveHiddenShiftViewRows,
  saveHiddenShiftViewRows,
} from "../../../../localStorage/visibilityStorage";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { getRosterModelById } from "../../../../utils/queryUtils/rosterQuery";
import { fetchUserSettings } from "../../../../utils/queryUtils/appQuery";
import { useSnapshotsStore } from "../../../../globalStore/snapshotsStore";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import { KEYWORD_ALL } from "../../../../constants/keywords";
import { hideAreaNameInAllocationsSetting } from "../../../locations/components/GlobalSettings/globalSettingsList";

const ScheduleRosterDataContainer = ({
  locationID,
  location,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  customKeywordsData,
  globalEmployees,
  setWizardOpen,
}) => {
  const testRef = useRef(null);
  const { user, plan, email } = useUserStore();
  const { pathname } = useLocation();
  const { refreshSnapshots } = useSnapshotsStore();

  const isShiftView = pathname.endsWith("/shift-view");

  const { manuallyStepThroughTour } = useOnBoardingStore();

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);

  const togglePublishModal = () => {
    if (!isPublishModalOpen) {
      const planDetails = getPlanDetails();
      if (
        checkMidTierEmployeeRestriction(
          planDetails.plan,
          planDetails.isTrialInProgress,
          planDetails.totalGlobalEmployees,
          planDetails.maxGlobalEmployees
        )
      ) {
        handleBillingRedirection(
          planDetails.totalGlobalEmployees,
          planDetails.totalGlobalEmployees - planDetails.maxGlobalEmployees,
          planDetails.maxGlobalEmployees
        );

        return;
      }
    }

    setIsPublishModalOpen((prev) => !prev);
  };
  const [isOpenShiftsModalOpen, setIsOpenShiftsModalOpen] = useState(false);
  const isScheduleView = !isRoster;
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    publishSchedule,
    updateOpenShifts,
    createSnapshotRoster,
    createRosterModelForMainStreamInDifferentPeriod,
    updateNote,
  } = useStandardDataContainer({
    isScheduleView,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const {
    name,
    numDays,
    employees,
    skills,
    tasks,
    taskBlocks,
    areas,
    subTasks,
    enumeratedTasks,
    demands,
    rules,
    shifts,
    shiftGroups,
    statistics,
    colorCodes,
    isPublished,
    order,
    startDate: rosterStartDate,
    isSnapshot,
    frontendSettings,
  } = fields;

  const frontendSettingNames = useMemo(
    () => getNames(frontendSettings),
    [frontendSettings]
  );

  const isMainStream = !isRoster && !isSnapshot;

  const employeesAllocationNotes = useMemo(() => {
    if (isMainStream) {
      return getEmployeesAllocationNotes(employees);
    }
    return null;
  }, [employees, isMainStream]);

  const [shiftViewHiddenRows, setShiftViewHiddenRows] = useState(() =>
    isMainStream
      ? roster.shiftViewHiddenRows
      : retrieveHiddenShiftViewRows(rosterID)
  );

  const { openShifts } = getFieldsFromLocation(location);
  const { updateLocationFields } = useLocationMutation(location);

  // Hidden rows in shift view
  useEffect(() => {
    if (isMainStream) {
      setShiftViewHiddenRows(roster.shiftViewHiddenRows);
    }
  }, [roster, isMainStream]);

  const setShowShiftView = (shouldShow) => {
    const query = isRoster
      ? {
          "roster-id": rosterID,
        }
      : {
          "location-id": locationID,
          date: rosterStartDate,
        };
    if (shouldShow) {
      const pageName = isRoster
        ? PAGE_NAMES.shiftView
        : PAGE_NAMES.scheduleViewShiftView;
      redirect({ pageName, query });
    } else {
      const pageName = isRoster
        ? PAGE_NAMES.myRoster
        : PAGE_NAMES.scheduleViewLocation;
      redirect({ pageName, query });
    }
  };

  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );

  const { annualLeaveKeyword, reservedShiftKeywords, predefinedShiftOptions } =
    customKeywordsUtilObj;

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  const areaFilterProperties = useMemo(
    () => ({
      onAreaFilterChanged,
      saveAreaFilter,
      initialAreaFilterValue,
    }),
    [saveAreaFilter, initialAreaFilterValue, onAreaFilterChanged]
  );

  const {
    applyOrderUpdates,
    updateColorCodes,
    updateStatistics,
    updateCustomRulesAndEmployees,
    addShiftGroup,
    updateDemands,
  } = getAllStandardUpdateFunctions(deepCopyObject(roster), updateFields);

  const [showPublishedCellColor, setShowPublishedCellColor] = useState(true);

  const [columnApi, setColumnApi] = useState(null);
  const [pinnedBottomRowDataTemplate, setPinnedBottomRowDataTemplate] =
    useState([]);
  const { isShowing: isUniqueShiftPopupOpen, toggle: toggleUniqueShiftPopup } =
    useModal();

  const [selectedOpenShiftInfo, setSelectedOpenShiftInfo] = useState({
    date: null,
    selectedOpenShiftID: null,
  });

  const openOpenShiftsModal = (
    selectedDate,
    selectedOpenShiftID = null,
    selectedOpenShiftDisplayedInfo
  ) => {
    setSelectedOpenShiftInfo({
      date: selectedDate,
      selectedOpenShiftID,
      selectedOpenShiftDisplayedInfo,
    });
    setIsOpenShiftsModalOpen(true);
  };

  const displayAreaInAllocationCell = !frontendSettingNames.includes(
    hideAreaNameInAllocationsSetting.name
  );

  const toggleHideAreaSetting = useCallback(() => {
    const settingName = hideAreaNameInAllocationsSetting.name;
    let updatedFrontendSettings;

    if (frontendSettingNames.includes(settingName)) {
      updatedFrontendSettings = frontendSettings.filter(
        (item) => item.name !== settingName
      );
    } else {
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: settingName,
          values: [],
        },
      ];
    }

    const updatedRoster = {
      ...roster,
      frontendSettings: updatedFrontendSettings,
    };

    updateFields(["frontendSettings"], updatedRoster, roster);
  }, [frontendSettings, frontendSettingNames, roster, updateFields]);

  const closeOpenShiftsModal = () => {
    setSelectedOpenShiftInfo(null);
    setIsOpenShiftsModalOpen(false);
  };

  const rosterWarnings = useMemo(() => {
    if (warnings) {
      return [...warnings.RosteredAllocations, ...warnings.Employees];
    }
    return null;
  }, [warnings]);

  const redirect = useRedirect();

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const namesToEntityShortIdsDicts = useMemo(
    () =>
      buildNamesToEntityShortIdsDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const publishedOnlyAllocations = useMemo(() => {
    if (!isMainStream) return null;
    const publishedOnly = getPublishedOnlyAllocations(employees);
    return publishedOnly.map((employeeInfo) => {
      const { employeeID, publishedOnlyAllocations } = employeeInfo;
      return {
        employeeID,
        publishedOnlyAllocations: publishedOnlyAllocations.map((allo) => {
          return {
            header: `d${
              DateTime.getDifferenceInDays(rosterStartDate, allo.date) + 1
            }`,
            ...allo,
          };
        }),
      };
    });
  }, [employees, rosterStartDate, isMainStream]);

  const requestAllocations = useMemo(() => {
    if (!isMainStream) return null;

    const employeesRequestsInfo = employees.map((employee) => {
      const requestsInfo = [];
      const requests = employee.Requests;
      for (const request of requests) {
        const dates = DateTime.getAllDatesBetween(
          request.startDate,
          request.finishDate,
          true,
          true
        );
        for (const date of dates) {
          requestsInfo.push({
            header: `d${
              DateTime.getDifferenceInDays(rosterStartDate, date) + 1
            }`,
            date,
            request: request.request,
            state: request.state,
          });
        }
      }

      return {
        employeeID: employee.id,
        requests: requestsInfo,
      };
    });

    return employeesRequestsInfo;
  }, [employees, rosterStartDate, isMainStream]);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.refreshHeader();
      }, 10);
    }
  }, [rosterStartDate, gridApi]);

  const redirectToShiftsPage = useCallback(() => {
    redirect({
      pageName: PAGE_NAMES.scheduleViewShifts,
      query: {
        "location-id": locationID,
        date: rosterStartDate,
      },
    });
  }, [locationID, rosterStartDate, redirect]);

  const employeesShiftHours = useMemo(
    () =>
      getShiftHoursForEmployees(
        employees,
        rules,
        shifts,
        shiftGroups,
        customKeywordsUtilObj
      ),
    [employees, rules, shifts, shiftGroups, customKeywordsUtilObj]
  );

  const preferencesInfo = useMemo(
    () =>
      getFullEmployeeAllocationsSummary(
        employees,
        TABLE_NAMES.ROSTER_PREFERENCES,
        location.startDate,
        periodStartDate,
        location.defaultNumDays
      ),
    [employees, location, periodStartDate]
  );

  const fixedShiftsInfo = useMemo(
    () =>
      getFullEmployeeAllocationsSummary(
        employees,
        TABLE_NAMES.ROSTER_FIXED_SHIFTS,
        location.startDate,
        periodStartDate,
        location.defaultNumDays
      ),
    [employees, location, periodStartDate]
  );

  const employeesData = useMemo(() => {
    return parseEmployeeModelToRosterGridFormat(employees, isMainStream);
  }, [employees, isMainStream]);

  const openShiftsData = useMemo(() => {
    if (!isMainStream) {
      return [];
    }
    return parseOpenShiftsToRosterGridFormat(
      openShifts,
      numDays,
      periodStartDate
    );
  }, [isMainStream, numDays, openShifts, periodStartDate]);

  useEffect(() => {
    if (isMainStream && gridApi) {
      gridApi.setPinnedTopRowData(openShiftsData);
    }
  }, [openShiftsData, isMainStream, gridApi]);

  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskNames = useMemo(() => getNames(tasks), [tasks]);
  const subtaskNames = useMemo(
    () => getNames(getSubtasks(tasks, taskBlocks)),
    [tasks, taskBlocks]
  );

  const shiftDemands = useMemo(() => {
    return inferredShiftDemandsToShiftDemandsInfo(
      shifts,
      shiftGroups,
      skills,
      demands,
      numDays,
      rosterStartDate,
      location.startDate,
      location.defaultNumDays
    );
  }, [
    shifts,
    shiftGroups,
    skills,
    demands,
    numDays,
    rosterStartDate,
    location.startDate,
    location.defaultNumDays,
  ]);

  useEffect(() => {
    const rows = getRosterTableBottomPinnedRowsPlaceholders(
      shifts,
      shiftGroups,
      skills,
      numDays,
      statistics
    );

    const allShiftAndShiftGroupShortIds = [
      ...getShortIds(shifts),
      ...getShortIds(shiftGroups),
    ];
    const areaFilteredShiftGroups = filterShiftGroupsByAreas(
      initialAreaFilterValue,
      shiftGroups
    );
    const areaFilteredShifts = filterShiftsByAreas(
      initialAreaFilterValue,
      shifts,
      shiftGroups
    );

    const allAreaFilteredShiftAndShiftGroupShortIds = [
      ...getShortIds(areaFilteredShiftGroups),
      ...getShortIds(areaFilteredShifts),
    ];

    if (
      initialAreaFilterValue.length === 0 ||
      initialAreaFilterValue.includes(KEYWORD_ALL)
    ) {
      setPinnedBottomRowDataTemplate(rows);
      return;
    }

    const areaFilteredRows = rows.filter(
      (row) =>
        !allShiftAndShiftGroupShortIds.includes(row.shortId) ||
        allAreaFilteredShiftAndShiftGroupShortIds.includes(row.shortId)
    );

    setPinnedBottomRowDataTemplate(areaFilteredRows);
  }, [
    shifts,
    shiftGroups,
    skills,
    numDays,
    statistics,
    initialAreaFilterValue,
  ]);

  const onToolPanelVisibleChanged = (params) => {
    const isToolPanelDisplayed = params.api.isToolPanelShowing();
    if (!isToolPanelDisplayed) {
      return;
    }
    manuallyStepThroughTour(400);
  };

  const allocationOptions = useMemo(() => {
    return getAllocationOptions(
      areas,
      shifts,
      taskBlocks,
      tasks,
      shiftGroups,
      predefinedShiftOptions,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
      initialAreaFilterValue
    );
  }, [
    areas,
    shifts,
    shiftGroups,
    taskBlocks,
    tasks,
    predefinedShiftOptions,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
    initialAreaFilterValue,
  ]);

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const ruleStatsMinMaxValues = useMemo(() => {
    return getMinMaxRuleValuesForStatsCol(
      rules,
      employees,
      shiftNames,
      shiftGroupNames
    );
  }, [rules, employees, shiftNames, shiftGroupNames]);

  const invalidCellListWithReasons = useMemo(() => {
    let invalidCells = [];

    if (gridApi && !gridApi.destroyCalled) {
      const allNodes = [];
      gridApi.forEachNode((node) => {
        allNodes.push(node);
      });

      if (employees.length !== allNodes.length) {
        return [];
      }

      invalidCells = getInvalidCellsFromRules(
        rules,
        areas,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        employees,
        numDays,
        gridApi,
        predefinedShiftOptions,
        customKeywordsUtilObj,
        shortIdsToEntityNamesDicts
      );
    }
    return invalidCells;
  }, [
    gridApi,
    rules,
    areas,
    shifts,
    shiftGroups,
    tasks,
    employees,
    numDays,
    predefinedShiftOptions,
    taskBlocks,
    customKeywordsUtilObj,
    shortIdsToEntityNamesDicts,
  ]);

  const rosterColumns = getDayColumns(numDays);

  const getDataFromGrid = (gridApi) => {
    if (isMainStream) {
      return getScheduleRosterDataFromGrid(
        gridApi,
        rosterColumns,
        deepCopyObject(employees),
        rosterStartDate,
        numDays,
        annualLeaveKeyword
      );
    } else {
      return getAllRosterDataFromGrid(gridApi, rosterColumns);
    }
  };

  const applyOpenShiftsUpdate = useCallback(
    (updatedOpenShifts, updatedGlobalEmployees = []) => {
      closeOpenShiftsModal();
      updateOpenShifts(updatedOpenShifts, updatedGlobalEmployees);
    },
    [updateOpenShifts]
  );

  const createOpenShift = useCallback(
    (openShift) => {
      const existingOpenShifts = location.OpenShifts ? location.OpenShifts : [];
      const updatedOpenShifts = [openShift, ...existingOpenShifts];
      sortByDateField(updatedOpenShifts, "date", true);
      applyOpenShiftsUpdate(updatedOpenShifts);
    },
    [applyOpenShiftsUpdate, location]
  );

  const updateOpenShift = useCallback(
    (updatedOpenShift) => {
      const existingOpenShifts = location.OpenShifts ? location.OpenShifts : [];
      const updatedOpenShifts = existingOpenShifts.map((openShift) => {
        if (openShift.id === updatedOpenShift.id) {
          return {
            ...openShift,
            number: updatedOpenShift.number,
            employeeStates: updatedOpenShift.employeeStates,
            isPublished: updatedOpenShift.isPublished,
          };
        }
        return openShift;
      });
      applyOpenShiftsUpdate(updatedOpenShifts);
    },
    [applyOpenShiftsUpdate, location]
  );

  const deleteOpenShift = useCallback(
    (openShiftID) => {
      const deletedOpenShift = openShifts.find(
        (openShift) => openShift.id === openShiftID
      );
      const updatedOpenShifts = openShifts.filter(
        (openShift) => openShift.id !== openShiftID
      );
      const date = deletedOpenShift.date;
      const acceptedEmployeeIDs = deletedOpenShift.employeeStates
        .filter((state) => state.state === "accepted")
        .map((state) => state.employeeID);
      const acceptedEmployees = globalEmployees.filter((employee) =>
        acceptedEmployeeIDs.includes(employee.id)
      );
      const deletedShift = shifts.find(
        ({ shortId }) => shortId === deletedOpenShift.shift
      );

      const message = `The "${
        deletedShift ? deletedShift.name : deletedOpenShift.shift
      }" open shift you accepted on ${new DateTime(date).toFormat(
        "displayed-full"
      )} was cancelled by the admin`;

      for (const employee of acceptedEmployees) {
        const registrations = employee.registrations;
        sendFirebaseNotificationsToBrowsers(
          [
            {
              registrations,
              message,
            },
          ],
          "Open shift is cancelled",
          "calendar",
          date
        );
      }
      const updatedGlobalEmployees = globalEmployees.map((employee) => {
        if (acceptedEmployeeIDs.includes(employee.id)) {
          const updatedPublishedAllocations = employee.PublishedAllocations.map(
            (allo) => {
              if (allo.date === date) {
                return {
                  date,
                  draftAllocation: null,
                  publishedAllocation: "",
                  isOpenShift: false,
                  note: allo.note ? allo.note : null,
                };
              }
              return allo;
            }
          );
          return {
            ...employee,
            PublishedAllocations: updatedPublishedAllocations,
          };
        }
        return employee;
      });
      applyOpenShiftsUpdate(updatedOpenShifts, updatedGlobalEmployees);
    },
    [applyOpenShiftsUpdate, openShifts, globalEmployees, shifts]
  );

  const updateRosterTableForRoster = (newEmployees) => {
    const updatedEmployees = employees.map((employee) => {
      const updatedEmployee = newEmployees.find(
        (emp) => emp.id === employee.id
      );
      if (!updatedEmployee) {
        return employee;
      }
      return {
        ...employee,
        RosteredAllocations: updatedEmployee.RosteredAllocations,
      };
    });

    return updatedEmployees;
  };

  const updateRosterTableForSchedule = (newEmployees) => {
    const updatedEmployees = updateScheduleMyRoster(
      employees,
      newEmployees,
      rosterStartDate,
      periodFinishDate
    );
    return updatedEmployees;
  };

  const updateRosterTable = async (newEmployees) => {
    const newShifts = [];
    const shiftsAndGroupNames = [...shiftNames, ...shiftGroupNames];

    newEmployees.forEach((employee) => {
      const allocations = employee.RosteredAllocations;
      allocations.forEach((value) => {
        const allo = value.split("-")[0];
        if (
          allo &&
          !reservedShiftKeywords.includes(allo) &&
          !shiftsAndGroupNames.includes(allo) &&
          !newShifts.includes(allo)
        ) {
          newShifts.push(allo);
        }
      });
    });

    let updatedEmployees;

    if (isMainStream) {
      updatedEmployees = updateRosterTableForSchedule(newEmployees);
    } else {
      updatedEmployees = updateRosterTableForRoster(newEmployees);
    }

    const updatedFields = ["Employees"];
    const updatedRoster = {
      ...roster,
      Employees: updateEmployeesWithOldEmployeeData(
        roster.Employees,
        updatedEmployees
      ),
    };

    updateFields(updatedFields, updatedRoster, roster, [
      "PublishedAllocations",
    ]);
  };

  const reorderRosterEmployeesData = (params) => {
    const orderedIdArr = getAllDataIdFromGrid(params.api);
    const updatedEmployees = getReorderedEntities(employees, orderedIdArr);
    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };
    updateFields(["Employees"], updatedRoster, roster);
  };

  const reorderScheduleEmployeesData = (params) => {
    const orderedIdArrForRosterEmployees = getAllDataIdFromGrid(params.api);
    const orderedIdArrForGlobalEmployees = [...orderedIdArrForRosterEmployees];
    order.forEach((globalEmployeeID) => {
      if (!orderedIdArrForGlobalEmployees.includes(globalEmployeeID)) {
        orderedIdArrForGlobalEmployees.push(globalEmployeeID);
      }
    });
    applyOrderUpdates(orderedIdArrForGlobalEmployees); //change location.order
  };

  const updateAllocationNote = async (employeeID, date, note) => {
    const targetEmployee = globalEmployees.find(({ id }) => id === employeeID);
    await updateNote(locationID, targetEmployee, date, note);
  };

  const reorderEmployeesData = async (params) => {
    if (isMainStream) {
      reorderScheduleEmployeesData(params);
    } else {
      reorderRosterEmployeesData(params);
    }
  };

  const employeeDatesWithAcceptedOpenShiftAllocations = useMemo(() => {
    if (!isMainStream) {
      return null;
    }
    return employees.map((employee) => {
      const openShiftAllocations = employee.PublishedAllocations.filter(
        (allocation) => allocation.isOpenShift
      );
      return {
        employeeID: employee.id,
        acceptedOpenShiftDates: openShiftAllocations.map(
          (allocation) => allocation.date
        ),
        acceptedOpenShiftColIDs: openShiftAllocations.map(
          (allocation) =>
            `d${
              DateTime.getDifferenceInDays(periodStartDate, allocation.date) + 1
            }`
        ),
      };
    });
  }, [employees, periodStartDate, isMainStream]);

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  const TopControllerComponent = isMainStream
    ? getScheduleTopController(
        roster,
        user,
        periodStartDate,
        periodFinishDate,
        warnings,
        gridApi,
        isSaving,
        periodNum,
        togglePublishModal,
        location,
        globalEmployees,
        openShifts,
        isShiftView,
        createRosterModelForMainStreamInDifferentPeriod,
        plan,
        areas,
        areaFilterProperties,
        setShowShiftView
      )
    : getRosterTopController(
        roster,
        rosterStartDate,
        periodFinishDate,
        location,
        warnings,
        isScheduleView,
        periodNum,
        plan,
        areas,
        areaFilterProperties
      );

  const isPublishableRoster = async (publishStartDate) => {
    for (const employee of globalEmployees) {
      const startDate = new DateTime(employee.startDate);
      const finishDate = new DateTime(employee.finishDate);
      if (startDate.isAfter(finishDate)) {
        customWarningAlert({
          title: "Invalid start/finish date",
          descriptions: [
            `${employee.name}'s start date (${startDate.toFormat(
              "displayed-full"
            )}) cannot be after finish date (${finishDate.toFormat(
              "displayed-full"
            )})`,
          ],
        });
        return false;
      }
    }

    if (globalEmployees.length === 0) {
      customWarningAlert({ title: "Please add global employees" });
      return false;
    }

    const rosters = await getRostersByLocationId(locationID);
    const publishedRosters = rosters.filter((r) => r.isPublished);
    if (publishedRosters.length === 0) {
      return true;
    }
    const firstGlobalEmployee = globalEmployees[0];
    const allocations = firstGlobalEmployee.PublishedAllocations;
    const finishDateOfPrevRoster = new DateTime(publishStartDate)
      .subtractDays(1)
      .toFormat("AWS");
    const lastAllo = allocations.find(
      (allo) => allo.date === finishDateOfPrevRoster
    );
    if (lastAllo && lastAllo.publishedAllocation === null) {
      customWarningAlert({
        title: "All dates in previous rosters needs to be published",
      });
      return false;
    }
    return true;
  };

  const updateShiftViewHiddenRows = (shiftViewHiddenRows, rosterId = null) => {
    if (!isMainStream) {
      setShiftViewHiddenRows(shiftViewHiddenRows);
      saveHiddenShiftViewRows(rosterId, shiftViewHiddenRows);
      return;
    }
    const updatedLocation = {
      ...location,
      shiftViewHiddenRows,
    };
    updateLocationFields(["shiftViewHiddenRows"], updatedLocation);
  };

  const publish = async (
    publishStartDate,
    finishDate,
    areasToPublish = null
  ) => {
    if (!isMainStream) {
      return;
    }
    const canBePublished = await isPublishableRoster(publishStartDate);
    if (!canBePublished) {
      return;
    }
    const updatedGlobalEmployees = getPublishedGlobalEmployees(
      globalEmployees,
      publishStartDate,
      finishDate,
      getShortIds(areasToPublish)
    );

    const employeesDataToNotify = getChangedPublishedAllocationsToNotify(
      employees,
      updatedGlobalEmployees,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj
    );

    const updatedEmployees = employees.map((employee) => {
      const targetEmployee = updatedGlobalEmployees.find(
        (updatedEmployee) => updatedEmployee.id === employee.id
      );
      return removeUnmutableModelFields({
        ...employee,
        PublishedAllocations: targetEmployee.PublishedAllocations,
      });
    });

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
    };

    await publishSchedule(
      updatedGlobalEmployees,
      updatedRoster,
      publishStartDate,
      finishDate
    );

    const currentDateFormatted = new DateTime().toFormat("version-date");
    const currentTimeFormatted = DateTime.getCurrentTimeFormatted();
    await createSnapshotRoster(
      `Published roster: ${currentDateFormatted} ${currentTimeFormatted}`,
      roster
    );
    await refreshSnapshots(locationID, periodStartDate);

    const isFirstTimePublish = checkHasNeverBeenPublished(
      employees,
      periodStartDate,
      finishDate
    );
    if (isFirstTimePublish) {
      await sendFirstTimePublishNotifications(
        location,
        publishStartDate,
        finishDate,
        employeesDataToNotify
      );
    } else {
      await sendPublishNotifications(location, employeesDataToNotify);
    }
  };

  return (
    <>
      {email === "jason@rosterlab.com" && (
        <div
          style={{
            position: "fixed",
            top: "80px",
            left: "200px",
            zIndex: 100000,
          }}
        >
          <button
            onClick={() => {
              getRostersBySnapshotStatus(location.id, true, false).then(
                console.log
              );
            }}
          >
            Snapshots
          </button>
          <button
            onClick={() => {
              getRostersBySnapshotStartDateRange(
                location.id,
                true,
                "2024-07-15",
                "2024-07-15"
              ).then(console.log);
            }}
          >
            Snapshots between two dates
          </button>
          <button
            onClick={async () => {
              const location = await getLocationModelById(locationID);
              console.log(location);
            }}
          >
            Location
          </button>
          <button
            onClick={async () => {
              const location = await getLocationModelById(locationID);
              const rosters = location.Rosters.items;
              console.log(rosters);
            }}
          >
            Rosters
          </button>
          <button
            onClick={async () => {
              const ge = await getGlobalEmployeeModelsByLocationId(locationID);
              console.log(ge);
            }}
          >
            GlobalEmployees
          </button>
          <button
            onClick={async () => {
              const location = await getLocationModelById(locationID);
              const rosters = location.Rosters.items;
              const roster = await getRosterModelById(rosters[0].id);
              console.log(roster);
            }}
          >
            Roster/Solution
          </button>
          <button
            onClick={async () => {
              const settings = await fetchUserSettings(user);
              console.log(settings);
            }}
          >
            Settings
          </button>
          <input ref={testRef} />
          <button onClick={() => {}}>Test</button>
        </div>
      )}
      {isOpenShiftsModalOpen && (
        <OpenShiftsModal
          open={true}
          onClose={closeOpenShiftsModal}
          shiftGroups={shiftGroups}
          skills={skills}
          shifts={shifts}
          tasks={tasks}
          taskBlocks={taskBlocks}
          globalEmployees={employees}
          openShifts={openShifts}
          createOpenShift={createOpenShift}
          updateOpenShift={updateOpenShift}
          selectedOpenShiftInfo={selectedOpenShiftInfo}
          setSelectedOpenShiftInfo={setSelectedOpenShiftInfo}
          deleteOpenShift={deleteOpenShift}
          areas={areas}
          customKeywordsUtilObj={customKeywordsUtilObj}
        />
      )}
      {isPublishModalOpen && (
        <PublishCalendarModal
          togglePublishModal={togglePublishModal}
          publishCalendar={publish}
          defaultStartDate={rosterStartDate}
          defaultFinishDate={periodFinishDate}
          areas={areas}
          initialAreaFilterValue={initialAreaFilterValue}
        />
      )}
      <GridActionHandler
        gridApi={gridApi}
        addNewItemToDB={() => {}}
        updateItemsToDB={updateRosterTable}
        duplicateItemsToDB={() => {}}
        removeItemsFromDB={() => {}}
        getDataFromGrid={getDataFromGrid}
        getToBeDeletedItems={() => {}}
        parseSelectedRowsToDuplicableInfo={() => {}}
        undoRedoParams={{
          tableName: TABLE_NAMES.ROSTER_ROSTER,
          getCustomGridSnapshot: null,
        }}
        disableUndoRedo={true}
        customStyle={{ marginBottom: "100px" }}
      >
        <RosterTable
          location={location}
          publishedOnlyAllocations={
            isMainStream ? publishedOnlyAllocations : null
          }
          requestAllocations={isMainStream ? requestAllocations : null}
          isPublished={isMainStream ? isPublished : null}
          showPublishedCellColor={isMainStream ? showPublishedCellColor : null}
          setShowPublishedCellColor={setShowPublishedCellColor}
          updateDemands={updateDemands}
          startDate={rosterStartDate}
          finishDate={periodFinishDate}
          employeesData={employeesData}
          rosterID={roster.id}
          shiftNames={shiftNames}
          taskNames={taskNames}
          enumeratedTasks={enumeratedTasks}
          subtaskNames={subtaskNames}
          skills={skills}
          shifts={shifts}
          shiftGroups={shiftGroups}
          tasks={tasks}
          subTasks={subTasks}
          areas={areas}
          demands={demands}
          employees={employees}
          numDays={numDays}
          updateCustomRulesAndEmployees={updateCustomRulesAndEmployees}
          isSaving={isSaving}
          setGridApiToParent={setGridApi}
          longestStr={longestStr}
          gridApi={gridApi}
          pinnedBottomRowDataTemplate={pinnedBottomRowDataTemplate}
          reorderEmployeesData={reorderEmployeesData}
          setColumnApiToParent={setColumnApi}
          shiftDemands={shiftDemands}
          allocationOptions={allocationOptions}
          isUniqueShiftPopupOpen={isUniqueShiftPopupOpen}
          toggleUniqueShiftPopup={toggleUniqueShiftPopup}
          rosterWarnings={rosterWarnings}
          statistics={statistics}
          preferencesInfo={preferencesInfo}
          fixedShiftsInfo={fixedShiftsInfo}
          ruleStatsMinMaxValues={ruleStatsMinMaxValues}
          invalidCellListWithReasons={invalidCellListWithReasons}
          predefinedShiftOptions={predefinedShiftOptions}
          uncountableShiftKeywords={reservedShiftKeywords}
          columnApi={columnApi}
          colorCodes={colorCodes}
          updateColorCodes={updateColorCodes}
          addShiftGroup={addShiftGroup}
          updateStatistics={updateStatistics}
          employeesShiftHours={employeesShiftHours}
          customTopComponent={TopControllerComponent}
          redirectToShiftsPage={redirectToShiftsPage}
          locationID={locationID}
          isMainStream={isMainStream}
          periodStartDate={periodStartDate}
          customKeywordsUtilObj={customKeywordsUtilObj}
          annualLeaveKeyword={annualLeaveKeyword}
          taskBlocks={taskBlocks}
          openShiftsData={openShiftsData}
          openOpenShiftsModal={openOpenShiftsModal}
          openShifts={openShifts}
          deleteOpenShift={deleteOpenShift}
          rules={rules}
          employeeDatesWithAcceptedOpenShiftAllocations={
            employeeDatesWithAcceptedOpenShiftAllocations
          }
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          updateRosterTable={updateRosterTable}
          isShiftView={isShiftView}
          shiftViewHiddenRows={shiftViewHiddenRows}
          updateShiftViewHiddenRows={updateShiftViewHiddenRows}
          setWizardOpen={setWizardOpen}
          updateNote={updateAllocationNote}
          employeesAllocationNotes={employeesAllocationNotes}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesAreaFilterPass}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          rosterName={name}
          toggleHideAreaSetting={toggleHideAreaSetting}
          displayAreaInAllocationCell={displayAreaInAllocationCell}
          frontendSettings={frontendSettings}
        />
      </GridActionHandler>
    </>
  );
};

export default ScheduleRosterDataContainer;
