import styles from "./ConfirmAlert.module.css";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const ConfirmAlert = ({
  show,
  proceed,
  title = "Are you sure to proceed?",
  descriptions,
  okLabel = "Continue",
  cancelLabel = "Cancel",
  hideCancel = false,
}) => {
  return (
    <ModalFrame
      open={show}
      onClose={() => proceed(false)}
      shouldCloseOnOutsideClick={true}
      customStyle={{ borderRadius: "6px" }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon className={styles.icon} icon={faCheck} />
          </div>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{title}</p>
          <div className={styles.descriptions}>
            {descriptions.map((description, idx) => (
              <div key={idx} className={styles.description}>
                {description}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <button
            className={`${styles.button} ${styles.okBtn}`}
            onClick={() => proceed(true)}
          >
            {okLabel}
          </button>
          {!hideCancel && (
            <button
              className={`${styles.button} ${styles.cancelBtn}`}
              onClick={() => proceed(false)}
            >
              {cancelLabel}
            </button>
          )}
        </div>
      </div>
    </ModalFrame>
  );
};
