import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import styles from "./WhosOnLeaveBottomTabContent.module.css";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../../hooks/useEmployeeAppCurrentView";
import { parseEmployeesLeaveData } from "../../../service/employeeAppUtil";

const WhosOnLeaveBottomTabContent = ({
  date,
  setContentViewType,
  employeeLeaveCounts,
  openLeaveTab,
  leaveCountInfo,
}) => {
  const { employeesWithAL, employeesWithSL } = parseEmployeesLeaveData(
    employeeLeaveCounts,
    date
  );

  const shouldShowLeaveNumbers =
    employeesWithAL.length > 0 || employeesWithSL.length > 0;

  const createEmployeesList = (names) => {
    return (
      <div className={styles.employeesList}>
        {names.map((employeeName, idx) => (
          <span key={idx}>{employeeName}</span>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.titleRow}`}>
        <span className={styles.title}>See who&apos;s on leave</span>
      </div>
      {shouldShowLeaveNumbers && (
        <div className={`${styles.row} ${styles.leaveRow}`}>
          {leaveCountInfo.map(({ count, employees, label, shortName }) => (
            <div key={label} className={styles.leaveColumn}>
              <span className={`${styles.leaveLabel} ${styles[shortName]}`}>
                {label} {count}
              </span>
              {createEmployeesList(employees)}
            </div>
          ))}
        </div>
      )}
      <div className={`${styles.row} ${styles.addButtonRow}`}>
        <BasicButton
          color="#219ec9"
          hoverColor="#1f91b7"
          customStyle={{
            borderRadius: "5px",
            height: "30px",
          }}
          onClick={() => {
            setContentViewType(EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave);
            openLeaveTab(true);
          }}
        >
          <div className={styles.addLeaveRequestButton}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Add Leave Request
          </div>
        </BasicButton>
      </div>
    </div>
  );
};

export default WhosOnLeaveBottomTabContent;
