import { KEYWORD_ALL, KEYWORD_OFF } from "../../../../constants/keywords";
import { DateTime } from "../../../../utils";
import { extractEntityNamesFromAllocation } from "../../../../utils/modelUtils/allocation";
import {
  checkRosterRuleValidity,
  calculateFTE,
} from "../../../rules/service/rules";

const checkAllocation = (
  allocation,
  reservedShiftKeywords,
  namesToEntityShortIdsDicts
) => {
  if (!allocation || reservedShiftKeywords.includes(allocation)) {
    return "";
  }
  const extractedEntityShortIds = extractEntityNamesFromAllocation(
    allocation,
    namesToEntityShortIdsDicts
  );

  const {
    areaName,
    shiftName,
    shiftGroupName,
    taskName,
    enumeratedTaskName,
    areaNameMapsToEntity,
    shiftNameMapsToEntity,
    shiftGroupNameMapsToEntity,
    taskNameMapsToEntity,
    enumeratedTaskNameMapsToEntity,
  } = extractedEntityShortIds;

  let isInvalidAllocation = false;

  if (areaName && !areaNameMapsToEntity) {
    isInvalidAllocation = true;
  }

  if (shiftName && !shiftNameMapsToEntity) {
    isInvalidAllocation = true;
  }

  if (shiftGroupName && !shiftGroupNameMapsToEntity) {
    isInvalidAllocation = true;
  }

  if (taskName && !taskNameMapsToEntity) {
    isInvalidAllocation = true;
  }

  if (enumeratedTaskName && !enumeratedTaskNameMapsToEntity) {
    isInvalidAllocation = true;
  }

  if (isInvalidAllocation) {
    return allocation + " does not exist!";
  }

  return "";
};

const checkAllocations = (
  roster,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts
) => {
  const { reservedShiftKeywords } = customKeywordsUtilObj;
  const employees = roster.Employees;

  for (let e = 0; e < employees.length; e++) {
    for (let d = 0; d < employees[e].Days.length; d++) {
      const day = employees[e].Days[d];
      const shiftAllocation = day
        .replace("!", "")
        .replace("?", "")
        .split("-")[0];
      const check1 = checkAllocation(
        shiftAllocation,
        reservedShiftKeywords,
        namesToEntityShortIdsDicts
      );
      if (check1 !== "") {
        return check1;
      }

      if (employees[e].Allocations !== null) {
        const fixedAllocation = employees[e].Allocations[d];
        const check2 = checkAllocation(
          fixedAllocation,
          reservedShiftKeywords,
          namesToEntityShortIdsDicts
        );
        if (check2 !== "") {
          return check2;
        }
      }
    }
    for (let w = 0; w < roster.numDays / 7; w++) {
      if (employees[e].DaysRecurring !== null) {
        const day = employees[e].DaysRecurring[w];
        if (day !== undefined) {
          const shiftAllocation = day
            .replace("!", "")
            .replace("?", "")
            .split("-")[0];
          const check1 = checkAllocation(
            shiftAllocation,
            reservedShiftKeywords,
            namesToEntityShortIdsDicts
          );
          if (check1 !== "") {
            return check1;
          }
        }
      }

      if (employees[e].AllocationsRecurring !== null) {
        const fixedAllocation = employees[e].AllocationsRecurring[w];
        if (fixedAllocation !== undefined) {
          const check2 = checkAllocation(
            fixedAllocation,
            reservedShiftKeywords,
            namesToEntityShortIdsDicts
          );
          if (check2 !== "") {
            return check2;
          }
        }
      }
    }
  }

  return "";
};

const checkDemandMaxMin = (roster) => {
  for (let d1 = 0; d1 < roster.Demands.length; d1++) {
    let demand1 = roster.Demands[d1];

    for (let d2 = 0; d2 < roster.Demands.length; d2++) {
      let demand2 = roster.Demands[d2];
      if (
        demand1.startTime === demand2.startTime &&
        demand1.finishTime === demand2.finishTime &&
        demand1.skills === demand2.skills &&
        demand1.shifts === demand2.shifts &&
        demand1.tasks === demand2.tasks &&
        demand1.areas === demand2.areas
      ) {
        for (let i = 0; i < roster.numDays; i++) {
          if (demand1.type !== demand2.type) {
            if (demand1.type === "Maximum") {
              if (demand1.values[i] === -1 || demand2.values[i] === -1) {
                continue;
              }
              if (demand1.values[i] < demand2.values[i]) {
                return `Demand ${d1 + 1} has a value less than Demand ${
                  d2 + 1
                } on day ${i + 1}.`;
              }
            }
          } else {
            if (demand1.type === "Target" && demand2.type === "Target") {
              if (demand1.values[i] === -1 || demand2.values[i] === -1) {
                continue;
              }
              if (
                demand1.importance === "No AI" ||
                demand2.importance === "No AI"
              ) {
                continue;
              }
              if (demand1.values[i] !== demand2.values[i]) {
                return `Target Demand ${
                  d1 + 1
                } has a value that conflicts with Demand ${d2 + 1} on day ${
                  i + 1
                }.`;
              }
            }
          }
        }
      }
    }
  }

  return "";
};

const demandImportance = {
  Critical: 1000,
  High: 100,
  Medium: 10,
  Low: 1,
};

const checkDemandHours = (roster) => {
  let fteDemandsDict = {};
  for (let d = 0; d < roster.Demands.length; d++) {
    let demand = roster.Demands[d];
    if (demand.type !== "Minimum" || demand.skills !== "") {
      continue;
    }

    if ((demand.startTime, demand.finishTime) in fteDemandsDict) {
      if (
        demandImportance[demand.importance] >
        demandImportance[fteDemandsDict[(demand.startTime, demand.finishTime)]]
      ) {
        fteDemandsDict[(demand.startTime, demand.finishTime)] = demand;
      }
    } else {
      fteDemandsDict[(demand.startTime, demand.finishTime)] = demand;
    }
  }

  let totalDemandHours = 0;
  for (var demandTimes in fteDemandsDict) {
    let demand = fteDemandsDict[demandTimes];
    let timeDiff = DateTime.getTimeDiff(demand.startTime, demand.finishTime);
    for (let v = 0; v < demand.values.length; v++) {
      totalDemandHours += demand.values[v] * timeDiff;
    }
  }

  let totalEmployeeHours = calculateFTE(roster);

  return totalDemandHours > totalEmployeeHours
    ? `There is at least ${(
        (100 * (totalDemandHours - totalEmployeeHours + 0.0)) /
        totalEmployeeHours
      ).toFixed(2)}% higher demand than available FTE`
    : "";
};

const checkAutoAssignable = (roster) => {
  return roster.Shifts.some((shift) => shift.autoAssigned)
    ? ""
    : "No auto assignable shifts";
};

const checkDemandValues = (roster) => {
  for (let i = 0; i < roster.Demands.length; i++) {
    let demand = roster.Demands[i];
    for (let v = 0; v < demand.values.length; v++) {
      if (demand.values[v] === null) {
        return `Demand ${i + 1} on day ${v + 1} has no value.`;
      } else if (
        demand.values[v] < -1 ||
        demand.values[v] > roster.Employees.length
      ) {
        return `Demand ${i + 1} on day ${
          v + 1
        } must have a value between 0 and ${
          roster.Employees.length
        } (total number of employees) or NA.`;
      }
    }
  }
  return "";
};

const checkIdenticalShiftsAndShiftNames = (roster) => {
  let errorMessageOne = checkShiftNames(roster);
  let errorMessageTwo = checkIdenticalShifts(roster);
  if (errorMessageOne !== "" || errorMessageTwo !== "") {
    return "\n" + errorMessageOne + errorMessageTwo;
  }
  return "";
};

const checkIdenticalShifts = (roster) => {
  const identicalShifts = new Set();
  const shiftsAndShiftGroups = [...roster.Shifts, ...roster.ShiftGroups];
  for (let i = 0; i < shiftsAndShiftGroups.length; i++) {
    for (let j = 0; j < shiftsAndShiftGroups.length; j++) {
      if (i === j) continue;
      if (shiftsAndShiftGroups[i].name === shiftsAndShiftGroups[j].name) {
        identicalShifts.add(shiftsAndShiftGroups[i].name);
      }
    }
  }
  if (identicalShifts.size > 0) {
    let errorMessage = "\n";
    for (let name of identicalShifts) {
      errorMessage += `Identical shifts or shift groups were found with the name: ${name} \n`;
    }
    return errorMessage;
  }

  return "";
};

const checkShiftNames = (roster) => {
  const reservedNames = [KEYWORD_OFF, KEYWORD_ALL];
  const shiftsAndShiftGroups = [...roster.Shifts, ...roster.ShiftGroups];
  for (let i = 0; i < shiftsAndShiftGroups.length; i++) {
    for (let n = 0; n < reservedNames.length; n++) {
      if (shiftsAndShiftGroups[i].name === reservedNames[n]) {
        return `You cannot name a shift or shift group "${reservedNames[n]}"`;
      }
    }
  }
  return "";
};

export const errorChecksList = {
  checkAllocations,
  checkAutoAssignable,
  checkRosterRuleValidity,
  checkDemandValues,
  checkIdenticalShiftsAndShiftNames,
  checkIdenticalShifts,
  checkShiftNames,
};

export const warningChecksList = [checkDemandHours, checkDemandMaxMin];
