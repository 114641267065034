import { useMemo, useState } from "react";
import styles from "./DashboardContainer.module.css";
import { downloadRosterAsJSON } from "../../../rosterProblems/service/downloadRoster";
import CreateRosterModal from "../CreateRosterModal/CreateRosterModal";
import Dashboard from "../Dashboard/Dashboard";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import DuplicateRosterModalContainer from "../DuplicateRosterModal/DuplicateRosterModalContainer";
import { getFieldsFromLocation } from "../../../../utils/queryUtils/locationDataGetters";
import { DateTime, getNames } from "../../../../utils";
import { useAllRostersQuery } from "../../../../hooks/modelQueryHooks/useAllRostersQuery";
import { useAllRostersMutation } from "../../../../hooks/modelQueryHooks/useAllRostersMutation";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";

const DashboardContainer = ({ location }) => {
  const redirect = useRedirect();
  const { locationID, name } = useMemo(
    () => getFieldsFromLocation(location),
    [location]
  );

  const { rosters, isQueryLoading } = useAllRostersQuery(location);
  const {
    deleteRoster: deleteRosterModel,
    createRoster: createRosterModel,
    updateRosters,
    isMutationLoading: isSaving,
  } = useAllRostersMutation(location);

  const rosterNames = getNames(rosters);
  const [showCreateRosterModal, setShowCreateRosterModal] = useState(false);
  const [showDuplicateRosterModal, setShowDuplicateRosterModal] =
    useState(false);

  const [toBeDuplicatedRoster, setToBeDuplicatedRoster] = useState(null);

  const toggleDuplicateRosterModal = (rosterID) => {
    const selectedRoster = rosters.find((roster) => roster.id === rosterID);
    setToBeDuplicatedRoster(selectedRoster);
    setShowDuplicateRosterModal((isOpen) => !isOpen);
  };

  const deleteRoster = async (id) => {
    const updatedRosters = rosters.filter((roster) => roster.id !== id);
    deleteRosterModel(id, updatedRosters);
  };

  const downloadRoster = async (id) => {
    downloadRosterAsJSON(id);
  };

  const updateRoster = async (params) => {
    const { id, name, numWeeks, startDate } = params.data;

    const formattedStartDate = new DateTime(startDate).toFormat("AWS");
    const targetRoster = rosters.find((roster) => roster.id === id);
    const numDays = numWeeks * 7;
    const prevNumDays = targetRoster.numDays;
    const shouldUpdateDateSpecificFields = numDays !== prevNumDays;
    const updatedRoster = {
      ...targetRoster,
      name,
      startDate: formattedStartDate,
      numDays: numDays,
    };

    const updatedRosters = rosters.map((roster) => {
      if (roster.id === id) {
        return updatedRoster;
      }
      return roster;
    });

    updateRosters(
      updatedRoster,
      updatedRosters,
      shouldUpdateDateSpecificFields
    );
  };

  const createRoster = (newRoster) => {
    const rosterToAdd = {
      ...newRoster,
      snapshotStartDate:
        newRoster.snapshotStartDate || `F${newRoster.startDate}`,
    };
    createRosterModel(rosterToAdd, [rosterToAdd, ...rosters], () => {
      redirect({
        pageName: PAGE_NAMES.roster,
        rosterID: rosterToAdd.id,
      });
    });
  };

  const createRosterModal =
    showCreateRosterModal && locationID ? (
      <CreateRosterModal
        location={location}
        rosterNames={rosterNames}
        createRoster={createRoster}
        handleClose={() => setShowCreateRosterModal(false)}
        locationID={locationID}
      />
    ) : null;

  const duplicateRosterModal =
    showDuplicateRosterModal && locationID ? (
      <DuplicateRosterModalContainer
        toggleDuplicateRosterModal={toggleDuplicateRosterModal}
        roster={toBeDuplicatedRoster}
        createRoster={createRoster}
        location={location}
      />
    ) : null;

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.container}>
      {createRosterModal}
      {duplicateRosterModal}
      <div className={styles.dashboardWrapper}>
        <Dashboard
          locationName={name}
          downloadRoster={downloadRoster}
          deleteRoster={deleteRoster}
          rosters={rosters}
          isSaving={isSaving}
          openCreateRosterModal={() => setShowCreateRosterModal(true)}
          toggleDuplicateRosterModal={toggleDuplicateRosterModal}
          locationID={locationID}
          updateRoster={updateRoster}
          rosterNames={rosterNames}
          createRoster={createRoster}
        />
      </div>
    </div>
  );
};

export default withHeader(DashboardContainer);
