import styles from "./FileUploader.module.css";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { useRef } from "react";
import { customWarningAlert } from "../../../confirm/service/confirm";

export default function FileUploader({ handleSubmission, locationID }) {
  const inputRef = useRef();

  const isJSONFile = (fileName) => {
    if (!fileName.endsWith(".json")) {
      return false;
    }
    return true;
  };

  const changeHandler = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    if (!isJSONFile(files[0].name)) {
      customWarningAlert({
        title: "Warning",
        descriptions: ["Please upload a JSON file"],
      });
      return;
    }
    handleSubmission(files[0], locationID);
    inputRef.current.value = null;
  };

  const activateInput = () => {
    inputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <span className={styles["file-description"]}>
        *Please upload json file*
      </span>
      <BasicButton
        hoverColor="#219ec9"
        borderColor="#219ec9"
        onClick={activateInput}
        customStyle={{
          borderRadius: "10px",
        }}
        dataTestId={"upload-roster-btn"}
      >
        Upload Roster
      </BasicButton>
      <input
        ref={inputRef}
        className={styles.input}
        type="file"
        accept="application/JSON"
        name="file"
        onChange={changeHandler}
        data-testid="upload-roster-input"
      />
    </div>
  );
}
