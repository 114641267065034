import styles from "./SaveSnapshotModal.module.css";

export default function SaveSnapshotModal({
  newSnapshotName,
  setNewSnapshotName,
  warningMessage,
  newSnapshotDefaultName,
}) {
  return (
    <div className={styles.saveSnapshotModal}>
      <div className={styles.top}>
        <span className={styles.fieldLabel}>New version name*</span>
        {warningMessage && (
          <p className={styles.warningMessage}>Name already exists!</p>
        )}
      </div>
      <input
        className={styles.versionNameInput}
        value={newSnapshotName}
        onChange={(e) => setNewSnapshotName(e.target.value)}
        placeholder={newSnapshotDefaultName}
      />
      <div className={styles.description}>
        <p>
          Whenever a roster is published, it will be automatically saved as a
          separate version.
        </p>
        <p>
          {`Any edits to the past versions won't interfere with the current version.
          Past versions cannot be brought back as a current version.`}
        </p>
      </div>
    </div>
  );
}
