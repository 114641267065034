import { useCallback, useEffect, useMemo, useState } from "react";
import RequestsView from "./RequestsView";
import styles from "./RequestsContainer.module.css";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import {
  getNames,
  scrollGridToBottom,
  sortByDateField,
} from "../../../../utils";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import {
  getFieldsFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import { updateAnnualLeaveRequestFixedShiftsToAllRelevantRosters } from "../../../store/service/shared/sharedStoreApi";
import { sendRequestNotification } from "../../../notifications/service/notifications";
import { updateRosterModel } from "../../../../utils/queryUtils/rosterQuery";
import { createRosterModelWithStartDateValidation } from "../../../../utils/queryUtils/locationQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import { subscribeGlobalEmployeeUpdate } from "../../../../utils/queryUtils/observers";
import { syncGlobalEmployee } from "../../../../utils/modelUtils/sync";

const RequestsContainer = ({
  toggleCreateLocationModal,
  locations,
  globalEmployees,
  location,
  customKeywordsData,
}) => {
  const queryClient = useQueryClient();
  const [gridApi, setGridApi] = useState(null);
  const { updateGlobalEmployees, isMutationLoading: isSaving } =
    useLocationMutation(location);
  const {
    locationID,
    name,
    globalSkills: skills,
    globalAreas: areas,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  useEffect(() => {
    let globalEmployeeUpdateSub;

    if (locationID) {
      globalEmployeeUpdateSub = subscribeGlobalEmployeeUpdate(
        (data) => syncGlobalEmployee(locationID, data),
        locationID
      );
    }

    return async () => {
      (await globalEmployeeUpdateSub)?.unsubscribe();
    };
  }, [locationID]);

  const { leaveLongNames, leaveCodes, annualLeaveKeyword } =
    interpretCustomKeywordsData(customKeywordsData);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([gridApi], locationID);

  const leaveKeywordsDict = leaveCodes.reduce((acc, curr) => {
    acc[curr.longname] = curr.shortname;
    return acc;
  }, {});

  const employeeNames = useMemo(
    () => getNames(globalEmployees),
    [globalEmployees]
  );

  const requestsData = useMemo(() => {
    const requests = [];
    globalEmployees.forEach((employee) => {
      employee.Requests.forEach((req) => {
        requests.push({
          ...req,
          employeeName: employee.name,
          employeeID: employee.id,
          skills: employee.skills,
          areas: employee.areas,
        });
      });
    });

    sortByDateField(requests, "submittedDate", false);
    return requests;
  }, [globalEmployees]);

  const editRequest = async (prevRequest, updatedRequestFields) => {
    const { state, adminComment } = updatedRequestFields;

    const updatedRequest = {
      id: prevRequest.id,
      submittedBy: prevRequest.submittedBy,
      submittedDate: prevRequest.submittedDate,
      startDate: prevRequest.startDate,
      finishDate: prevRequest.finishDate,
      request: prevRequest.request,
      state: state,
      employeeComment: prevRequest.employeeComment,
      adminComment: adminComment,
    };

    const employeeName = prevRequest.employeeName;
    const targetGlobalEmployee = globalEmployees.find(
      (employee) => employee.id === prevRequest.employeeID
    );

    const { updatedRosters } =
      await updateAnnualLeaveRequestFixedShiftsToAllRelevantRosters(
        location,
        targetGlobalEmployee.id,
        employeeName,
        updatedRequest,
        leaveKeywordsDict,
        globalEmployees,
        annualLeaveKeyword
      );

    for (const updatedRoster of updatedRosters) {
      await updateRosterModel(updatedRoster.id, {
        Employees: updatedRoster.Employees,
      });
    }

    if (updatedRosters.length > 0) {
      applyUpdatedRostersToQueries(updatedRosters);
    }

    const updatedTargetEmployee = {
      ...targetGlobalEmployee,
      Requests: targetGlobalEmployee.Requests.map((request) => {
        if (request.id === updatedRequest.id) {
          return updatedRequest;
        }
        return request;
      }),
    };
    applyRequestsUpdate(updatedTargetEmployee);

    if (state === "Approved" || state === "Denied") {
      await sendRequestNotification(
        locationID,
        prevRequest.employeeID,
        updatedRequest,
        state
      );
    }
  };

  const applyUpdatedRostersToQueries = useCallback(
    (updatedRosters) => {
      updatedRosters.forEach((updatedRoster) => {
        const queryKey = ["roster", updatedRoster.id];
        const previousRosterData = queryClient.getQueryData(queryKey);
        if (previousRosterData) {
          queryClient.setQueryData(queryKey, () => {
            return {
              ...previousRosterData,
              Employees: updatedRoster.Employees,
            };
          });
        }
      });
    },
    [queryClient]
  );

  const applyRequestsUpdate = (
    updatedTargetEmployee,
    updatedRosters = null
  ) => {
    const updatedGlobalEmployees = globalEmployees.map((employee) => {
      if (employee.id === updatedTargetEmployee.id) {
        return updatedTargetEmployee;
      }
      return employee;
    });
    const updatedLocation = {
      ...location,
      Employees: {
        items: updatedGlobalEmployees,
      },
      ...(updatedRosters && {
        Rosters: {
          items: updatedRosters,
        },
      }),
    };

    updateGlobalEmployees(
      [updatedTargetEmployee],
      ["Requests"],
      updatedLocation
    );
  };

  const addNewRequests = async (requestData) => {
    const newRequest = {
      id: requestData.id,
      submittedBy: requestData.submittedBy,
      submittedDate: requestData.submittedDate,
      startDate: requestData.startDate,
      finishDate: requestData.finishDate,
      request: requestData.request,
      state: requestData.state,
      employeeComment: requestData.employeeComment,
      adminComment: requestData.adminComment,
    };

    const employeeName = requestData.employeeName;
    const targetGlobalEmployee = globalEmployees.find(
      (employee) => employee.name === employeeName
    );

    const { updatedRosters, createdRosters } =
      await updateAnnualLeaveRequestFixedShiftsToAllRelevantRosters(
        location,
        targetGlobalEmployee.id,
        employeeName,
        newRequest,
        leaveKeywordsDict,
        globalEmployees,
        annualLeaveKeyword
      );

    const rosterCreatePromises = createdRosters.map((roster) => {
      return createRosterModelWithStartDateValidation(locationID, roster);
    });

    await Promise.all(rosterCreatePromises);

    for (const updatedRoster of updatedRosters) {
      await updateRosterModel(updatedRoster.id, {
        Employees: updatedRoster.Employees,
      });
    }

    if (updatedRosters.length > 0) {
      applyUpdatedRostersToQueries(updatedRosters);
    }

    const updatedTargetEmployee = {
      ...targetGlobalEmployee,
      Requests: [...targetGlobalEmployee.Requests, newRequest],
    };

    applyRequestsUpdate(updatedTargetEmployee, [
      ...location.Rosters.items,
      ...createdRosters,
    ]);
    scrollGridToBottom(gridApi, 100);
  };

  return (
    <div className={styles.container}>
      <RequestsView
        toggleCreateLocationModal={toggleCreateLocationModal}
        locations={locations}
        locationID={locationID}
        employeeNames={employeeNames}
        requestsData={requestsData}
        addNewRequest={addNewRequests}
        setGridApiToParent={setGridApi}
        editRequest={editRequest}
        locationName={name}
        leaveLongNames={leaveLongNames}
        isSaving={isSaving}
        skills={skills}
        areas={areas}
        onAreaFilterChanged={onAreaFilterChanged}
        doesAreaFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        saveAreaFilter={saveAreaFilter}
        initialAreaFilterValue={initialAreaFilterValue}
      />
    </div>
  );
};

export default withHeader(RequestsContainer);
