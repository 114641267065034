export const findBestFitSubRule = (subrules, valueList) => {
  let minValue = 1000;
  let minIndex = -1;
  for (let i = 0; i < subrules.length; i++) {
    let subRuleValue = 0;
    if (subrules[i].inputs[0] !== valueList[0]) {
      subRuleValue++;
    }
    if (subrules[i].inputs[1] !== valueList[1]) {
      subRuleValue += 4;
    }
    if (subrules[i].inputs[2] !== valueList[2]) {
      subRuleValue += 16;
    }
    if (subrules[i].inputs[3] !== valueList[3]) {
      subRuleValue += 8;
    }
    if (subrules[i].inputs[4] !== valueList[4]) {
      subRuleValue += 2;
    }
    if (subRuleValue < minValue) {
      minValue = subRuleValue;
      minIndex = i;
    }
  }
  return minIndex;
};
