import LogRocket from "logrocket";
import { useCallback, useEffect, useState } from "react";
import { useUserStore } from "../../../globalStore/appStore";
import { isBroadcastChannelSupported } from "../../../utils/browserUtils/browserInfo";
import {
  removeAuthListener,
  startAuthListener,
} from "../../../utils/amplifyUtils/amplifyHub";
import {
  getFirstAndLastNameOfUser,
  getNameOfUser,
  getUserAttributes,
  getUserEmail,
  logOut,
} from "./auth";
import { Capacitor } from "@capacitor/core";
import { useQueryClient } from "@tanstack/react-query";
import { CapacitorCrisp } from "@capgo/capacitor-crisp";
import Cookies from "js-cookie";
import { LogRocketUserError } from "../../../errors/errors";
import { isProduction } from "../../../utils/flagsUtils/flags";

function setUpGA(user, plan) {
  const email = getUserEmail(user);

  if (window.gtag) {
    window.gtag("config", "G-KCZHPS54K5", {
      user_id: email,
      user_properties: {
        plan,
      },
    });
  }
}

function setUpLogRocket(user, plan = null) {
  let attributesToLog = {};
  const { firstName, lastName } = getFirstAndLastNameOfUser(user);
  const email = getUserEmail(user);

  if (email && firstName && lastName) {
    attributesToLog = {
      name: firstName + " " + lastName,
      email,
      plan,
    };

    Cookies.set("username", user.username, {
      expires: 7,
      path: "/",
      domain: ".rosterlab.com",
      secure: true, // Cookie will only be sent over HTTPS
      sameSite: "strict", // Cookie will not be sent along with requests initiated by third party websites
    }); // For logrocket on marketing website
    Cookies.set("userEmail", attributesToLog.email, {
      expires: 7, // Cookie will expire in 7 days
      path: "/", // Cookie will be accessible on all pages
      domain: ".rosterlab.com", // Accessible across all subdomains of rosterlab.com
      secure: true, // Cookie will only be sent over HTTPS
      sameSite: "strict", // Cookie will not be sent along with requests initiated by third party websites
    });
    Cookies.set("userFullName", attributesToLog.name, {
      expires: 7, // Same as above
      path: "/",
      domain: ".rosterlab.com", // Accessible across all subdomains of rosterlab.com
      secure: true,
      sameSite: "strict",
    });
  } else {
    LogRocket.captureException(
      new LogRocketUserError("Cannot find user attributes", { user }),
      {
        extra: user
          ? {
              name: getNameOfUser(user),
              email: getUserEmail(user),
              plan,
              user: JSON.stringify(user),
            }
          : null,
      }
    );
  }

  LogRocket.identify(user.username, attributesToLog);

  // If logrocket keeps missing bugs, might need to add this in
  window.addEventListener("unhandledrejection", (event) => {
    LogRocket.captureException(event.reason, {
      extra: {
        stack: event.reason.stack,
      },
    });
  });
}

var numChecks = 0;

async function setUpHubspot(user, plan) {
  // Load crisp script to replace hubspot on ios app since hubspot not supported
  // This is because capacitor://localhost link doesn't work
  loadCrispScript(user);

  // This interval checker is because the script doesn't always load first time
  const maxChecks = 10;
  const intervalId = setInterval(() => {
    numChecks++;
    if (
      window._hsq &&
      window.HubSpotConversations &&
      window.HubSpotConversations.widget
    ) {
      clearInterval(intervalId);
      setupHubspotLogic(user, plan);
    } else if (numChecks >= maxChecks) {
      clearInterval(intervalId);
      console.warn("Hubspot scripts did not load after maximum checks.");
    }
  }, 2000); // Checks every 2000 ms (2 seconds)
}

function loadCrispScript(user) {
  if (Capacitor.getPlatform() === "ios") {
    const { firstName, lastName } = getFirstAndLastNameOfUser(user);
    CapacitorCrisp.configure({
      websiteID: "81ab3833-7fcf-4660-acd2-0c30ccc21209",
    });
    const email = getUserEmail(user);
    CapacitorCrisp.setUser({
      nickname: firstName + " " + lastName,
      email: email,
    });
  }
}

async function setupHubspotLogic(user, plan) {
  const { sub: userId } = getUserAttributes(user);
  const email = getUserEmail(user);
  const { firstName: givenName, lastName: familyName } =
    getFirstAndLastNameOfUser(user);

  if (!email.startsWith("test")) {
    if (window._hsq) {
      if (isProduction) {
        // Only identify in Hubspot if it is in production
        const hubspotStats = {
          user_id: userId,
          subscription_plan: plan,
          firstname: givenName,
          lastname: familyName,
          email: email,
          javascript_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        window._hsq.push(["identify", hubspotStats]);
        window._hsq.push(["trackPageView"]);
        LogRocket.getSessionURL((sessionURL) => {
          window._hsq.push(["identify", { latest_session_url: sessionURL }]);
        });
      }

      if (Capacitor.isNativePlatform()) {
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded) {
          window.HubSpotConversations.widget.refresh();
        } else {
          window.HubSpotConversations.widget.load({ widgetOpen: true });
        }
      }
    }
  }
}

async function updateUserHandler(user, plan) {
  if (user) {
    setUpLogRocket(user, plan);
    if (plan) {
      setUpHubspot(user, plan);
      setUpGA(user, plan);
    }
  }
}

const authBroadcastChannel = isBroadcastChannelSupported()
  ? new BroadcastChannel("auth-channel")
  : null;

if (isBroadcastChannelSupported()) {
  authBroadcastChannel.onmessage = function (e) {
    const message = e.data;
    switch (message) {
      case "signOut":
      case "signIn":
        location.reload();
        break;
      default:
        break;
    }
  };
}

export const useAuthStatusUpdate = () => {
  const { user, setUser, setToCurrentUser, plan } = useUserStore();
  const queryClient = useQueryClient();
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const handleSetToCurrentUser = useCallback(
    async (forceRefreshIfConnectionActive) => {
      let user = null;
      try {
        user = await setToCurrentUser(forceRefreshIfConnectionActive);
      } catch (error) {
        if (error.name !== "UserUnAuthenticatedException") {
          console.log(error);
        }
      } finally {
        setIsLoadingUser(false);
      }
      return user;
    },
    [setToCurrentUser]
  );

  useEffect(() => {
    handleSetToCurrentUser(true);
  }, [handleSetToCurrentUser]);

  useEffect(() => {
    updateUserHandler(user, plan);
  }, [user, plan]);

  const handleUserAuthEvents = useCallback(
    async (data) => {
      const event = data.payload.event;

      if (event === "signedOut") {
        if (isBroadcastChannelSupported()) {
          authBroadcastChannel.postMessage(event);
        }
        await setUser(null);
        queryClient.clear();
      }

      if (event === "signedIn") {
        if (isBroadcastChannelSupported()) {
          authBroadcastChannel.postMessage(event);
        }

        queryClient.clear();
        await handleSetToCurrentUser(true);
      }

      if (event === "tokenRefresh") {
        console.log("auth token has been refreshed.");
        await handleSetToCurrentUser(false);
      }

      if (event === "tokenRefresh_failure") {
        logOut();
      }
    },
    [setUser, queryClient, handleSetToCurrentUser]
  );

  useEffect(() => {
    startAuthListener(handleUserAuthEvents);

    return () => {
      removeAuthListener(handleUserAuthEvents);
    };
  }, [setUser, setToCurrentUser, handleUserAuthEvents, user]);

  return { isLoadingUser };
};
