import PropTypes from "prop-types";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import ModalFrame from "../../../locations/components/ModalFrame/ModalFrame";
import styles from "./EmployeeNumberLimitModal.module.css";
import { getBillingPortalSession } from "../../../../utils/queryUtils/appQuery";
import { openSessionUrl } from "../../../../utils";
import { customConfirmPopup } from "../../../confirm/service/confirm";
import { PLAN } from "../../../auth/service/auth";

export async function handleBillingRedirection(
  resultingEmployeesNumberAfterAdd,
  extraEmployeesToAdd,
  maxGlobalEmployees,
  preBillingPortalAction
) {
  const shouldGoToBillingPage = await confirmRedirectionToBillingPage(
    extraEmployeesToAdd,
    maxGlobalEmployees
  );

  if (shouldGoToBillingPage) {
    if (preBillingPortalAction) preBillingPortalAction();

    const result = await getBillingPortalSession(
      resultingEmployeesNumberAfterAdd
    );

    if (result) {
      openSessionUrl(result.billingPortalSession);
    }
  }
}

export const confirmRedirectionToBillingPage = async (
  numEmployeesToAdd,
  maxGlobalEmployees
) => {
  const shouldGoToBillingPage = await customConfirmPopup(
    EmployeeNumberLimitModal,
    {
      maxSeats: maxGlobalEmployees,
      numEmployeesToAdd,
    }
  );
  return shouldGoToBillingPage;
};

export const checkMidTierEmployeeRestriction = (
  plan,
  isTrialInProgress,
  finalNumberEmployees,
  maxGlobalEmployees
) => {
  return (
    plan !== PLAN.AI &&
    plan !== PLAN.LITE &&
    plan !== PLAN.COLLABORATOR &&
    !isTrialInProgress &&
    finalNumberEmployees > maxGlobalEmployees
  );
};

const EmployeeNumberLimitModal = ({
  maxSeats,
  numEmployeesToAdd,
  proceed,
  show,
}) => {
  return (
    <ModalFrame
      open={show}
      onClose={() => proceed(false)}
      shouldCloseOnOutsideClick={true}
    >
      <div className={styles.container}>
        <p className={styles.title}>
          Would you like to add {numEmployeesToAdd} more employees to your
          subscription?
        </p>
        <p className={styles.description}>
          You are currently subscribing for {maxSeats} seats. By adding{" "}
          {numEmployeesToAdd} employee(s), it will be added to your bill
          pro-rata for the rest of the month.
        </p>
        <div className={styles.buttons}>
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            onClick={() => {
              proceed(true);
            }}
            customStyle={{
              borderRadius: "5px",
              fontSize: "17px",
              fontWeight: "bold",
              flex: 1,
              outline: "none",
            }}
          >
            Modify subscription
          </BasicButton>
          <BasicButton
            color="white"
            borderColor="#219ec9"
            onClick={() => {
              proceed(false);
            }}
            customStyle={{
              borderRadius: "5px",
              fontSize: "17px",
              fontWeight: "bold",
              flex: 1,
              cursor: "pointer",
            }}
          >
            Maybe later
          </BasicButton>
        </div>
      </div>
    </ModalFrame>
  );
};

EmployeeNumberLimitModal.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
};

export default EmployeeNumberLimitModal;
