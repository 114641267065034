import { useEffect } from "react";
import { userIsSchedulableEmployee } from "../../auth/service/auth";
import { useFirebaseMessageTokenStore } from "../../../globalStore/firebaseMessageTokenStore";

/**
 * Configure notification if the user is a scheduleable employee in a location
 */
export function useConfigureEmployeeNotifications(user, locationIDs) {
  const { configureFirebaseMessaging } = useFirebaseMessageTokenStore();

  useEffect(() => {
    if (!locationIDs) {
      return;
    }
    const isSchedulableEmployee = userIsSchedulableEmployee(user, locationIDs);
    if (isSchedulableEmployee) {
      configureFirebaseMessaging();
    }
  }, [user, locationIDs, configureFirebaseMessaging]);
}
