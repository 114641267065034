import { getUniqueNamingRegex } from "./regex";

/**
 * Inputs:
 *  - names: list of string
 *  - candidateName: candidate name
 *
 * Functionality
 *  - If `name` exists in `names`, return a suitable `name(n)`
 */
export const createUniqueSuffixedName = (names, candidateName) => {
  let createdName = candidateName;

  if (names.includes(createdName)) {
    const text = getUniqueNamingRegex(createdName);
    const matches = names.map((name) => {
      const match = name.match(text);
      if (match) {
        return parseInt(match[1]);
      }
      return null;
    });
    createdName += ` (${Math.max(...matches) + 1})`;
  }
  return createdName;
};
