import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_DEMANDS_FINISH_TIME,
  DEFAULT_DEMANDS_START_TIME,
} from "../../constants";
import { deepCopyObject, removeSuffix } from "../generalUtils/general";
import { generateShortIds } from "../modelUtils/shortId";

export function getNewDemandsTemplate(options) {
  const {
    numItems,
    skills,
    tasks,
    type,
    values,
    importance,
    startTime,
    finishTime,
    shifts,
  } = options;

  const demandStartTime = startTime ? startTime : DEFAULT_DEMANDS_START_TIME;
  const demandFinishTime = finishTime
    ? finishTime
    : DEFAULT_DEMANDS_FINISH_TIME;
  const demandType = type ? type : "Minimum";
  const demandSkills = skills ? skills : "";
  const demandTasks = tasks ? tasks : "";
  const demandShifts = shifts ? shifts : "";
  const demandImportance = importance ? importance : "High";

  const defaultDemandsInfo = {
    startTime: demandStartTime,
    finishTime: demandFinishTime,
    skills: demandSkills,
    tasks: demandTasks,
    type: demandType,
    shifts: demandShifts,
    importance: demandImportance,
    values: values ? values : [1, 1, 1, 1, 1, 1, 1],
    defaultPHValue: -1,
    areas: "",
    conditionalGroup: "",
  };

  const newDemands = [];

  for (let i = 0; i < numItems; i++) {
    newDemands.push({
      ...deepCopyObject(defaultDemandsInfo),
      id: uuidv4(),
    });
  }

  return newDemands;
}

export function getNewEntities(
  numItems,
  name,
  nextNumberedSuffix,
  entityInfo,
  existingShifts = null,
  entityType = null,
  shouldGenerateShortId = true
) {
  const shortIds = shouldGenerateShortId
    ? generateShortIds(entityType, existingShifts, numItems)
    : null;

  const newEntities = Array(numItems)
    .fill(null)
    .map((_, idx) => {
      const entityName = nextNumberedSuffix
        ? `${name} ${nextNumberedSuffix + idx}`
        : name;

      const shortId = shortIds ? shortIds[idx] : null;

      return {
        ...entityInfo,
        id: uuidv4(),
        ...(shortId && { shortId }),
        name: entityName,
      };
    });
  return newEntities;
}

export function getReorderedEntities(originalEntities, reorderedEntityIDs) {
  return reorderedEntityIDs.map((id) => {
    return originalEntities.find((item) => item.id === id);
  });
}

export function getDuplicatedEntities(
  originalEntities,
  duplicatedEntityIDs,
  namePrefix = null,
  entityType = null,
  shouldGenerateShortId = false
) {
  const toDuplicate = originalEntities.filter((item) =>
    duplicatedEntityIDs.includes(item.id)
  );

  const shortIds = shouldGenerateShortId
    ? generateShortIds(entityType, originalEntities, toDuplicate.length)
    : null;

  return toDuplicate.map((item, idx) => {
    const shouldAddPrefixToName = item.name && namePrefix;

    const shortId = shortIds ? shortIds[idx] : null;

    return {
      ...item,
      ...(shouldAddPrefixToName && { name: `${namePrefix}${item.name}` }),
      id: uuidv4(),
      ...(shortId && { shortId }),
    };
  });
}

export function getEmployeesAndCustomRulesAfterRulesUpdate(
  employees,
  updatedRules,
  isGlobal
) {
  const key = isGlobal ? "ruleValues" : "RuleValues";
  const updatedEmployees = deepCopyObject(employees);

  const updatedCustomRules = updatedRules.map((rule) => ({
    name: rule.ruleCustomName,
    template: rule.template,
  }));

  for (const employee of updatedEmployees) {
    const ruleValues = employee[key];
    const newRuleValues = Array(updatedRules.length).fill("");

    for (const updatedRuleIdx in updatedRules) {
      const updatedRule = updatedRules[updatedRuleIdx];
      let ruleIdxInPrevRules = updatedRule.rulePrevIndex;

      if (ruleIdxInPrevRules === -1) {
        let newValue;
        if (updatedRule.defaultValue !== "") {
          newValue = updatedRule.defaultValue;
        } else if (isNaN(updatedRule.initialDefaultValue)) {
          newValue = "";
        } else {
          newValue = updatedRule.initialDefaultValue;
        }
        newRuleValues[updatedRuleIdx] = newValue;
      } else if (updatedRule.defaultValue === "") {
        newRuleValues[updatedRuleIdx] = ruleValues[ruleIdxInPrevRules];
      } else {
        newRuleValues[updatedRuleIdx] = updatedRule.defaultValue;
      }
    }
    employee[key] = newRuleValues;
  }

  return {
    updatedEmployees,
    updatedCustomRules,
  };
}

export function getEmployeesAndCustomRulesAfterRulesDelete(
  employees,
  rules,
  deletedRuleName,
  isGlobal
) {
  const deletedRuleIndex = rules.findIndex(
    (rule) => rule.name === deletedRuleName
  );
  const key = isGlobal ? "ruleValues" : "RuleValues";

  const updatedEmployees = employees.map((employee) => {
    const updatedRuleValues = employee[key].filter(
      (_, idx) => idx !== deletedRuleIndex
    );
    return {
      ...employee,
      [key]: updatedRuleValues,
    };
  });
  const updatedCustomRules = rules.filter(
    (rule) => rule.name !== deletedRuleName
  );
  return {
    updatedEmployees,
    updatedCustomRules,
  };
}

export const updateEmployeesWithOldEmployeeData = (
  previousEmployees,
  newEmployees
) => {
  const updatedEmployees = previousEmployees.map((employee) => {
    const employeeWithUpdatedFields = newEmployees.find(
      ({ id }) => employee.id === id
    );

    if (employeeWithUpdatedFields) {
      const updatedEmployee = deepCopyObject(employee);
      for (const [key, value] of Object.entries(employeeWithUpdatedFields)) {
        updatedEmployee[key] = value;
      }
      return updatedEmployee;
    }
    return employee;
  });

  return updatedEmployees;
};

export function removeUnmutableModelFields(model) {
  const updatedModel = deepCopyObject(model);
  delete updatedModel.owner;
  delete updatedModel._lastChangedAt;
  delete updatedModel._deleted;
  delete updatedModel.updatedAt;
  delete updatedModel.createdAt;
  return updatedModel;
}

export const normalizeSpecialPreferences = (preferences) => {
  return preferences.map((item) => {
    if (item.endsWith("*")) {
      return removeSuffix(item, 1);
    }
    return item;
  });
};
