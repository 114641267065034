import { confirmable, createConfirmation } from "react-confirm";
import { ConfirmAlert } from "../components/ConfirmAlert/ConfirmAlert";
import {
  WarningAlert,
  WarningAlertWithComponentDescriptions,
} from "../components/WarningAlert/WarningAlert";
import { ReminderAlert } from "../components/ReminderAlert/ReminderAlert";

export function customConfirmPopup(Component, options = {}) {
  return createConfirmation(confirmable(Component))({
    ...options,
  });
}

export async function customConfirmAlert(options) {
  const { cancelLabel, okLabel, descriptions, title, hideCancel } = options;

  const response = await customConfirmPopup(ConfirmAlert, {
    cancelLabel,
    okLabel,
    descriptions: descriptions ? descriptions : [],
    title,
    hideCancel,
  });
  return response;
}

export async function customWarningAlert(options) {
  const { okLabel, cancelLabel, showCancelBtn, descriptions, title } = options;
  const response = await customConfirmPopup(WarningAlert, {
    okLabel,
    cancelLabel,
    descriptions: descriptions ? descriptions : [],
    title,
    showCancelBtn,
  });
  return response;
}

export async function customWarningAlertWithDescriptionComponents(options) {
  const { okLabel, cancelLabel, showCancelBtn, DescriptionComponents, title } =
    options;

  const response = await customConfirmPopup(
    WarningAlertWithComponentDescriptions,
    {
      okLabel,
      cancelLabel,
      descriptions: DescriptionComponents ? DescriptionComponents : [], // descriptions are array of components
      title,
      showCancelBtn,
    }
  );
  return response;
}

export async function customReminderAlert(options) {
  const { okLabel, descriptions, title } = options;
  const response = await customConfirmPopup(ReminderAlert, {
    okLabel,
    descriptions: descriptions ? descriptions : [],
    title,
  });
  return response;
}
