export const hideColumnsOutsideViewRangeByDayNums = (
  columnApi,
  numDays,
  displayedStartDay,
  displayedLastDay
) => {
  for (let i = 1; i <= numDays; i++) {
    if (displayedStartDay <= i && displayedLastDay >= i) {
      columnApi.setColumnVisible(i.toString(), true);
    } else {
      columnApi.setColumnVisible(i.toString(), false);
    }
  }
};

export const onFilterTextBoxChanged = (gridApi, inputTagID) => {
  gridApi.setQuickFilter(document.getElementById(inputTagID).value);
};
