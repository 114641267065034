import { useMemo } from "react";
import { EMPLOYEE_APP_CONTENT_VIEW_TYPE } from "../../hooks/useEmployeeAppCurrentView";
import styles from "./ContentViewTypeSwitcher.module.css";

const ContentViewTypeSwitcher = ({
  switchableContentViewTypes,
  contentViewType,
  setContentViewType,
  customStyle,
}) => {
  const getLabel = (type) => {
    switch (type) {
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.schedule:
        return "My schedule";
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.preferences:
        return "My preferences";
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.applyLeave:
        return "Apply for leave";
      case EMPLOYEE_APP_CONTENT_VIEW_TYPE.whosOnLeave:
        return "Who's on leave";
      default:
        return "";
    }
  };

  const switcherFocusStyle = useMemo(() => {
    const matchingTypeIndex = switchableContentViewTypes.findIndex(
      (type) => type === contentViewType
    );

    return {
      width: `${100 / switchableContentViewTypes.length}%`,
      transform: `translateX(${matchingTypeIndex * 100}%)`,
    };
  }, [contentViewType, switchableContentViewTypes]);

  return (
    <div className={styles.contentViewTypeSwitcher} style={customStyle}>
      <div className={styles.switcherFocus} style={switcherFocusStyle}>
        <div className={styles.switcherFocusFill} />
      </div>
      {switchableContentViewTypes.map((subPage) => (
        <div key={subPage} className={styles.switcherContainer}>
          <button
            className={`${styles.switcherButton} ${
              subPage === contentViewType && styles.active
            }`}
            onClick={() => setContentViewType(subPage)}
          >
            {getLabel(subPage)}
          </button>
        </div>
      ))}
    </div>
  );
};

export default ContentViewTypeSwitcher;
