import { KEYWORD_NA } from "../../constants/keywords";

export const isDefaultRuleAppliedToShiftCountCell = (
  minRuleValues,
  maxRuleValues,
  headerName,
  rowIndex
) => {
  if (!minRuleValues) {
    if (
      !maxRuleValues ||
      !maxRuleValues[headerName] ||
      !maxRuleValues[headerName][rowIndex] ||
      !maxRuleValues[headerName][rowIndex] === KEYWORD_NA
    ) {
      return true;
    }
  }

  if (!maxRuleValues) {
    if (minRuleValues && minRuleValues[headerName][rowIndex] !== undefined) {
      if (
        minRuleValues[headerName][rowIndex] === KEYWORD_NA ||
        parseInt(minRuleValues[headerName][rowIndex]) === 0
      ) {
        return true;
      }
    }
  }

  if (!minRuleValues[headerName] && !maxRuleValues[headerName]) {
    return true;
  }

  if (
    minRuleValues[headerName] &&
    parseInt(minRuleValues[headerName][rowIndex]) === 0 &&
    !maxRuleValues[headerName]
  ) {
    return true;
  }

  if (
    minRuleValues[headerName] &&
    parseInt(minRuleValues[headerName][rowIndex]) === 0 &&
    maxRuleValues[headerName] &&
    maxRuleValues[headerName][rowIndex] === KEYWORD_NA
  ) {
    return true;
  }

  if (
    minRuleValues[headerName] &&
    minRuleValues[headerName][rowIndex] === KEYWORD_NA &&
    maxRuleValues[headerName] &&
    maxRuleValues[headerName][rowIndex] === KEYWORD_NA
  ) {
    return true;
  }

  if (
    minRuleValues[headerName] &&
    minRuleValues[headerName][rowIndex] === KEYWORD_NA &&
    !maxRuleValues[headerName]
  ) {
    return true;
  }

  return false;
};

export const isShiftCountCellExceedsMaxRule = (
  maxRuleValues,
  headerName,
  rowIndex,
  cellValue
) => {
  if (
    maxRuleValues &&
    maxRuleValues[headerName] &&
    !isNaN(maxRuleValues[headerName][rowIndex]) &&
    parseInt(cellValue) > parseInt(maxRuleValues[headerName][rowIndex])
  ) {
    return true;
  }
  return false;
};

export const isShiftCountCellBelowMinRule = (
  minRuleValues,
  headerName,
  rowIndex,
  cellValue
) => {
  if (
    minRuleValues &&
    minRuleValues[headerName] &&
    !isNaN(minRuleValues[headerName][rowIndex]) &&
    parseInt(cellValue) < parseInt(minRuleValues[headerName][rowIndex])
  ) {
    return true;
  }
  return false;
};
