import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./RateShiftButton.module.css";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { useCallback } from "react";
import { isMobileViewOrApp } from "../../../../globalStore/deviceInfoStore";

export default function RateShiftButton({ date }) {
  const redirectTo = useRedirect();
  const isMobile = isMobileViewOrApp();

  const redirectToSurveyPage = useCallback(() => {
    redirectTo({
      pageName: isMobile
        ? PAGE_NAMES.mobileEmployeeViewSurvey
        : PAGE_NAMES.desktopEmployeeViewSurvey,
      query: {
        date: date.toFormat("AWS"),
      },
    });
  }, [redirectTo, date, isMobile]);

  return (
    <div className={styles.rateShiftButtonContainer}>
      <button className={styles.rateShiftButton} onClick={redirectToSurveyPage}>
        <FontAwesomeIcon icon={faThumbsUp} className={styles.rateIcon} />
        Rate this shift
      </button>
    </div>
  );
}
