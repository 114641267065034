export const ALL_WARNING_FIELDS = [
  "RosteredAllocations",
  "Employees",
  "Skills",
  "Tasks",
  "TaskBlocks",
  "Areas",
  "Shifts",
  "ShiftGroups",
  "CustomRules",
  "Demands",
  "History",
  "Allocations",
  "AllocationsRecurring",
  "Days",
  "DaysRecurring",
];
