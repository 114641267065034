import LogRocket from "logrocket";
import { ONBOARDING_TASK_ID } from "../../features/onBoarding/service/onBoarding";
import { getUserUsername } from "../../globalStore/appStore";

export function sendIndustryInfoToHubspot(industry) {
  if (window._hsq) {
    window._hsq.push(["identify", { industry }]);
    LogRocket.identify(getUserUsername(), { industry });
  }
}

export function recordStatsInHubspot(updatedCompletedTasks, oldCompletedTasks) {
  const onboardingTaskDifferences = updatedCompletedTasks.filter(
    (task) => !oldCompletedTasks.includes(task)
  );

  onboardingTaskDifferences.forEach((task) => {
    LogRocket.track(task);
  });

  const result = updatedCompletedTasks.reduce((acc, taskID) => {
    acc[taskID.replace("-", "_")] = true;
    return acc;
  }, {});
  if (window._hsq) {
    window._hsq.push(["identify", result]);
  }
  LogRocket.identify(getUserUsername(), result);
  if (updatedCompletedTasks.length === ONBOARDING_TASK_ID.length) {
    if (window._hsq) {
      window._hsq.push(["identify", { checklist_complete: true }]);
    }
    LogRocket.identify(getUserUsername(), { checklist_complete: true });
  }
}
