import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import styles from "./ColumnWidthSetter.module.css";
import ShadowButton from "../ShadowButton/ShadowButton";
import {
  MAX_COL_WIDTH,
  MIN_COL_WIDTH,
  useCalendarColumnWidthStore,
  useFixedShiftsColumnWidthStore,
  useGlobalPreferencesColumnWidthStore,
  useHistoryColumnWidthStore,
  usePreferencesColumnWidthStore,
  useRosterAllocationsColumnWidthStore,
  useRulesColumnWidthStore,
  useSolutionsColumnWidthStore,
} from "../../../globalStore/columnWidthStore";
import { getStrWidthInPx } from "../../../utils";

const SLIDER_NUM_STEPS = 10;
const FONT_FAMILY = "sans-serif";
const FONT_SIZE = 12;

const ColumnWidthSetter = ({
  weekdayColWidth,
  weekendColWidth,
  isWDayWEndSeparate,
  setWeekdayColWidth,
  setWeekendColWidth,
  setIsWDayWEndSeparate,
  longestCellStr,
  toggleSetter,
  saveColumnWidthForSingleTable,
  saveColumnWidthForAllTables,
  location,
  showWeekdayWeekendToggle,
  disableSetForAllPages,
}) => {
  const [weekdaySliderValue, setWeekdaySliderValue] = useState(weekdayColWidth);
  const [weekdayInputValue, setWeekdayInputValue] = useState(weekdayColWidth);
  const [weekendSliderValue, setWeekendSliderValue] = useState(weekendColWidth);
  const [weekendInputValue, setWeekendInputValue] = useState(weekendColWidth);

  const {
    setWeekdayColWidth: setSolutionsWeekdayColWidth,
    setWeekendColWidth: setSolutionsWeekendColWidth,
    initialiseWithPresetWidth: initialiseSolutionsWithPresetWidth,
  } = useSolutionsColumnWidthStore();
  const {
    setWeekdayColWidth: setHistoryWeekdayColWidth,
    setWeekendColWidth: setHistoryWeekendColWidth,
    initialiseWithPresetWidth: initialiseHistoryWithPresetWidth,
  } = useHistoryColumnWidthStore();
  const {
    setWeekdayColWidth: setFixedShiftsWeekdayColWidth,
    setWeekendColWidth: setFixedShiftsWeekendColWidth,
    initialiseWithPresetWidth: initialiseFixedShiftsWithPresetWidth,
  } = useFixedShiftsColumnWidthStore();
  const {
    setWeekdayColWidth: setPreferencesWeekdayColWidth,
    setWeekendColWidth: setPreferencesWeekendColWidth,
    initialiseWithPresetWidth: initialisePreferencesWithPresetWidth,
  } = usePreferencesColumnWidthStore();
  const {
    setWeekdayColWidth: setGlobalPreferencesWeekdayColWidth,
    setWeekendColWidth: setGlobalPreferencesWeekendColWidth,
    initialiseWithPresetWidth: initialiseGlobalPreferencesWithPresetWidth,
  } = useGlobalPreferencesColumnWidthStore();
  const {
    setWeekdayColWidth: setCalendarWeekdayColWidth,
    setWeekendColWidth: setCalendarWeekendColWidth,
    initialiseWithPresetWidth: initialiseCalendarWithPresetWidth,
  } = useCalendarColumnWidthStore();
  const {
    setWeekdayColWidth: setRosterAllocationsWeekdayColWidth,
    setWeekendColWidth: setRosterAllocationsWeekendColWidth,
    initialiseWithPresetWidth: initialiseRosterAllocationsWithPresetWidth,
  } = useRosterAllocationsColumnWidthStore();
  const { initialiseWithPresetWidth: initialiseRulesWithPresetWidth } =
    useRulesColumnWidthStore();

  const onSetAllPageClicked = () => {
    setSolutionsWeekdayColWidth(weekdayColWidth);
    setSolutionsWeekendColWidth(weekendColWidth);
    setHistoryWeekdayColWidth(weekdayColWidth);
    setHistoryWeekendColWidth(weekendColWidth);
    setFixedShiftsWeekdayColWidth(weekdayColWidth);
    setFixedShiftsWeekendColWidth(weekendColWidth);
    setPreferencesWeekdayColWidth(weekdayColWidth);
    setPreferencesWeekendColWidth(weekendColWidth);
    setGlobalPreferencesWeekdayColWidth(weekdayColWidth);
    setGlobalPreferencesWeekendColWidth(weekendColWidth);
    setCalendarWeekdayColWidth(weekdayColWidth);
    setCalendarWeekendColWidth(weekendColWidth);
    setRosterAllocationsWeekdayColWidth(weekdayColWidth);
    setRosterAllocationsWeekendColWidth(weekdayColWidth);
    // Rules should not be affected
  };

  useEffect(() => {
    if (location) {
      initialiseSolutionsWithPresetWidth(location.frontendSettings);
      initialiseHistoryWithPresetWidth(location.frontendSettings);
      initialiseFixedShiftsWithPresetWidth(location.frontendSettings);
      initialisePreferencesWithPresetWidth(location.frontendSettings);
      initialiseGlobalPreferencesWithPresetWidth(location.frontendSettings);
      initialiseCalendarWithPresetWidth(location.frontendSettings);
      initialiseRosterAllocationsWithPresetWidth(location.frontendSettings);
      initialiseRulesWithPresetWidth(location.frontendSettings);
    }
  }, [
    location,
    initialiseSolutionsWithPresetWidth,
    initialiseHistoryWithPresetWidth,
    initialiseFixedShiftsWithPresetWidth,
    initialisePreferencesWithPresetWidth,
    initialiseGlobalPreferencesWithPresetWidth,
    initialiseCalendarWithPresetWidth,
    initialiseRosterAllocationsWithPresetWidth,
    initialiseRulesWithPresetWidth,
  ]);

  useEffect(() => {
    setWeekdaySliderValue(weekdayColWidth);
    setWeekdayInputValue(weekdayColWidth);
    setWeekendSliderValue(weekendColWidth);
    setWeekendInputValue(weekendColWidth);
  }, [weekdayColWidth, weekendColWidth]);

  const fitColWidth =
    getStrWidthInPx(longestCellStr, FONT_FAMILY, FONT_SIZE) + 30 > MIN_COL_WIDTH
      ? Math.ceil(getStrWidthInPx(longestCellStr, FONT_FAMILY, FONT_SIZE) + 30)
      : MIN_COL_WIDTH;

  const toggleCheckbox = () => {
    if (isWDayWEndSeparate) {
      applyWeekendColWidth(weekdayColWidth);
    }
    setIsWDayWEndSeparate(!isWDayWEndSeparate);
  };

  const applyWeekdayColWidth = (value) => {
    setWeekdaySliderValue(value);
    setWeekdayInputValue(value);
    setWeekdayColWidth(value);
  };

  const applyWeekendColWidth = (value) => {
    setWeekendSliderValue(value);
    setWeekendInputValue(value);
    setWeekendColWidth(value);
  };

  const validateAndApplyColWidth = (inputVal, isWeekDay) => {
    let apply = isWeekDay ? applyWeekdayColWidth : applyWeekendColWidth;
    if (isNaN(inputVal)) {
      apply(fitColWidth);
    } else if (inputVal < MIN_COL_WIDTH) {
      apply(MIN_COL_WIDTH);
    } else if (inputVal > MAX_COL_WIDTH) {
      apply(MAX_COL_WIDTH);
    } else {
      apply(inputVal);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.setterOneWrapper}`}>
        <button
          className={styles.resetBtn}
          onClick={() => {
            applyWeekdayColWidth(fitColWidth);
            if (!isWDayWEndSeparate) {
              applyWeekendColWidth(fitColWidth);
            }
          }}
        >
          Reset
        </button>
        <div className={styles.sliderWrapper}>
          <Slider
            min={MIN_COL_WIDTH}
            max={MAX_COL_WIDTH}
            aria-label="Small"
            step={SLIDER_NUM_STEPS}
            value={weekdaySliderValue}
            onChange={(e, newValue) => {
              setWeekdaySliderValue(newValue);
              applyWeekdayColWidth(newValue);
              if (!isWDayWEndSeparate) {
                setWeekendSliderValue(newValue);
                applyWeekendColWidth(newValue);
              }
            }}
            onChangeCommitted={() => {
              applyWeekdayColWidth(weekdaySliderValue);
              if (!isWDayWEndSeparate) {
                applyWeekendColWidth(weekendSliderValue);
              }
            }}
            sx={{
              color: "#219ec9",
            }}
          />
          <span className={styles.sliderLabel}>All columns</span>
        </div>
        <input
          className={styles.widthInput}
          type="number"
          value={weekdayInputValue}
          step={SLIDER_NUM_STEPS}
          onChange={(e) => {
            const newValue = parseInt(e.target.value);
            setWeekdayInputValue(newValue);
            if (!isWDayWEndSeparate) {
              setWeekendInputValue(newValue);
            }
          }}
          onBlur={() => {
            const inputVal = parseInt(weekdayInputValue);
            validateAndApplyColWidth(inputVal, true);
          }}
          onMouseUp={() => {
            const inputVal = parseInt(weekdayInputValue);
            validateAndApplyColWidth(inputVal, true);
          }}
          onKeyPress={(e) => {
            if (e.code === "Enter") {
              const inputVal = parseInt(weekdayInputValue);
              validateAndApplyColWidth(inputVal, true);
            }
          }}
        />
      </div>
      {isWDayWEndSeparate && (
        <div className={`${styles.row} ${styles.setterTwoWrapper}`}>
          <button
            className={styles.resetBtn}
            onClick={() => {
              applyWeekendColWidth(fitColWidth);
            }}
          >
            Reset
          </button>
          <div className={styles.sliderWrapper}>
            <Slider
              min={MIN_COL_WIDTH}
              max={MAX_COL_WIDTH}
              aria-label="Small"
              step={SLIDER_NUM_STEPS}
              value={weekendSliderValue}
              disabled={!isWDayWEndSeparate}
              onChange={(e, newValue) => {
                setWeekendSliderValue(newValue);
                applyWeekendColWidth(newValue);
              }}
              onChangeCommitted={() => applyWeekendColWidth(weekendSliderValue)}
              sx={{
                color: "#219ec9",
              }}
            />
            <span className={styles.sliderLabel}>
              Set separate width for weekends
            </span>
          </div>
          <input
            className={styles.widthInput}
            readOnly={!isWDayWEndSeparate}
            type="number"
            value={weekendInputValue}
            step={SLIDER_NUM_STEPS}
            onChange={(e) => {
              const newValue = parseInt(e.target.value);
              setWeekendInputValue(newValue);
            }}
            onBlur={() => {
              const inputVal = parseInt(weekdayInputValue);
              validateAndApplyColWidth(inputVal, false);
            }}
            onMouseUp={() => {
              const inputVal = parseInt(weekdayInputValue);
              validateAndApplyColWidth(inputVal, false);
            }}
            onKeyPress={(e) => {
              if (e.code === "Enter") {
                const inputVal = parseInt(weekdayInputValue);
                validateAndApplyColWidth(inputVal, false);
              }
            }}
          />
        </div>
      )}
      {showWeekdayWeekendToggle && (
        <div className={`${styles.row} ${styles.checkboxGroup}`}>
          <input
            id="weekendCheck"
            type="checkbox"
            checked={isWDayWEndSeparate}
            onChange={toggleCheckbox}
          />
          <label htmlFor="weekendCheck">Set separate width for weekends</label>
        </div>
      )}
      <div className={`${styles.row} ${styles.btns}`}>
        <ShadowButton
          backgroundColor="white"
          hoverColor="#219ec9"
          labelColor="#219ec9"
          border="1px solid #219ec9"
          shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
          fontSize="16px"
          onClick={() => {
            saveColumnWidthForSingleTable([
              String(weekdayInputValue),
              String(weekendInputValue),
            ]);
            toggleSetter();
          }}
        >
          Set for current page
        </ShadowButton>
        {!disableSetForAllPages && (
          <ShadowButton
            backgroundColor="white"
            hoverColor="#219ec9"
            labelColor="#219ec9"
            border="1px solid #219ec9"
            shadow="2px 2px 3px 0 rgba(0, 0, 0, 0.25)"
            fontSize="16px"
            onClick={() => {
              saveColumnWidthForAllTables([
                String(weekdayInputValue),
                String(weekendInputValue),
              ]);
              onSetAllPageClicked();
              toggleSetter();
            }}
          >
            Set for all pages
          </ShadowButton>
        )}
      </div>
    </div>
  );
};

export default ColumnWidthSetter;
