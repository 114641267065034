import styles from "./DownloadRosterButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const DownloadRosterButton = ({ downloadRoster, ...props }) => {
  return (
    <button
      className={styles.btn}
      onClick={() => downloadRoster(props.data.id)}
    >
      <FontAwesomeIcon icon={faDownload} className={styles.icon} />
    </button>
  );
};

export default DownloadRosterButton;
