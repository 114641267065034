import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import LoadingSpinner from "../../../../components/elements/LoadingSpinner/LoadingSpinner";
import {
  DateTime,
  arraysHaveSameContent,
  sortByDateField,
} from "../../../../utils";
import { PLAN, getUserEmail } from "../../../auth/service/auth";
import RerosterButton from "../../../rosterProblems/rosteredAllocations/components/RerosterButton/RerosterButton";
import { getLabel } from "../SchedulePeriodSwitcher/SchedulePeriodSwitcher";
import styles from "./ScheduleRosterDataContainer.module.css";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import TwoOptionsSwitcher from "../../../../components/elements/TwoOptionsSwitcher/TwoOptionsSwitcher";
import {
  myRosterPageViewSetting,
  useDefaultPageViewStore,
} from "../../../../globalStore/defaultViewsStore";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import ReportGenerator from "../../../rosterProblems/rosteredAllocations/components/ReportGenerator/ReportGenerator";
import SnapshotsDropdown from "../SnapshotsDropdown/SnapshotsDropdown";
import {
  canAccessReportGenerator,
  canAccessRerostering,
} from "../../../../utils/flagsUtils/flags";
import { isMobileViewOrApp } from "../../../../globalStore/deviceInfoStore";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";

export const getScheduleTopController = (
  rosterInView,
  user,
  periodStartDate,
  periodFinishDate,
  warnings,
  gridApi,
  isSaving,
  periodNum,
  togglePublishModal,
  location,
  globalEmployees,
  openShifts,
  isShiftView,
  createRosterModelForMainStreamInDifferentPeriod,
  plan,
  areas,
  areaFilterProperties,
  setShowShiftView
) => {
  const { onAreaFilterChanged, saveAreaFilter, initialAreaFilterValue } =
    areaFilterProperties;
  const {
    Employees: employees,
    shifts,
    locationID,
    id: rosterID,
    numDays,
  } = rosterInView;

  const isMobile = isMobileViewOrApp();

  const openShiftsWithinSchedule = openShifts.filter((openShift) => {
    const openShiftDate = new DateTime(openShift.date);
    return openShiftDate.isDateBetweenTwoDates(
      periodStartDate,
      periodFinishDate,
      true,
      true
    );
  });

  const allDatesInPeriod = DateTime.getAllDatesBetween(
    periodStartDate,
    periodFinishDate,
    true,
    true
  ).map((date) => date.toFormat("AWS"));

  const getPublishButtonLabel = () => {
    let draftAllocationExists = false;
    let publishedAllocationExists = false;
    let hasMissingAllocationDates = false;

    for (const employee of employees) {
      if (draftAllocationExists && publishedAllocationExists) {
        break;
      }
      const allocations = employee.PublishedAllocations.filter(({ date }) =>
        new DateTime(date).isDateBetweenTwoDates(
          periodStartDate,
          periodFinishDate,
          true,
          true
        )
      );

      const allocationDates = allocations.map(({ date }) => date);
      if (!arraysHaveSameContent(allDatesInPeriod, allocationDates)) {
        hasMissingAllocationDates = true;
      }

      const draftExists = allocations.some(
        ({ draftAllocation }) => draftAllocation != null
      );
      const publishedExists = allocations.some(
        ({ publishedAllocation }) => publishedAllocation != null
      );

      if (draftExists) {
        draftAllocationExists = true;
      }

      if (publishedExists) {
        publishedAllocationExists = true;
      }
    }

    if (publishedAllocationExists) {
      const unpublishedOpenShiftExists = openShiftsWithinSchedule.some(
        ({ isPublished }) => isPublished === false
      );
      if (
        unpublishedOpenShiftExists ||
        draftAllocationExists ||
        hasMissingAllocationDates
      ) {
        return "Publish changes";
      }

      return "Published";
    } else {
      return "Publish Schedule";
    }
  };

  const getPublishButtonColor = () => {
    switch (getPublishButtonLabel()) {
      case "Publish Schedule":
        return {
          normal: "#1D9EC9",
          hover: "#1f91b7",
        };
      case "Publish Changes":
        return {
          normal: "#004BAD",
          hover: "#1f91b7",
        };
      case "Published":
        return {
          normal: "#00D4C2",
          hover: "#1fb8ab",
        };
      default:
        return {
          normal: "#1D9EC9",
          hover: "#1f91b7",
        };
    }
  };

  return (
    <div className={styles.topControllers}>
      <div className={styles.bottom}>
        <div className={styles.left}>
          <AreaFilter
            areas={areas}
            onAreaFilterChanged={onAreaFilterChanged}
            onMenuClose={saveAreaFilter}
            defaultValue={initialAreaFilterValue}
          />
          <TableSchedulePeriodSwitcher
            location={location}
            periodStartDate={periodStartDate}
            periodFinishDate={periodFinishDate}
            globalEmployees={globalEmployees}
            numDays={numDays}
            isSaving={isSaving}
            periodNum={periodNum}
            pageUrlSlug={isShiftView ? "shift-view" : "roster"}
            customContainerStyle={{}}
            preSwitchPeriodAction={() => {
              if (gridApi) {
                gridApi.clearRangeSelection();
              }
            }}
            createRosterModelForMainStreamInDifferentPeriod={
              createRosterModelForMainStreamInDifferentPeriod
            }
          />
          {isSaving && <LoadingSpinner />}
          <ShiftViewSwitcher
            isShiftView={isShiftView}
            setShowShiftView={setShowShiftView}
          />
        </div>
        <div className={styles.right}>
          {!isMobile && (
            <>
              {![PLAN.COORDINATOR].includes(plan) && (
                <SnapshotsDropdown
                  location={location}
                  rosterID={rosterID}
                  isMainStream={true}
                  periodStartDate={periodStartDate}
                />
              )}
              {canAccessReportGenerator(getUserEmail(user)) && (
                <ReportGenerator
                  employees={employees}
                  periodStartDate={periodStartDate}
                  periodFinishDate={periodFinishDate}
                  shifts={shifts}
                />
              )}
              {canAccessRerostering(plan) && (
                <RerosterButton
                  employees={employees}
                  initialStartDate={periodStartDate}
                  initialFinishDate={periodFinishDate}
                  rosterID={rosterID}
                  locationID={locationID}
                  rosterName={`Roster starting on ${new DateTime(
                    periodStartDate
                  ).toFormat("displayed-full")}`}
                  isScheduleView={true}
                  warnings={warnings}
                  location={location}
                  areas={areas}
                  initialAreaFilterValue={initialAreaFilterValue}
                >
                  Reroster
                </RerosterButton>
              )}
            </>
          )}
          <BasicButton
            dataTestId="publish-schedule-button"
            color={getPublishButtonColor().normal}
            hoverColor={getPublishButtonColor().hover}
            customStyle={{
              height: "35px",
              borderRadius: "10px",
              fontSize: "18px",
            }}
            onClick={() => {
              if (getPublishButtonLabel() === "Published") {
                customConfirmAlert({
                  title: "Already Published",
                  descriptions: [
                    `You've published for ${getLabel(
                      periodNum
                    )} from ${periodStartDate} to ${periodFinishDate}`,
                  ],
                  hideCancel: true,
                });
                return;
              }
              togglePublishModal(true);
            }}
          >
            {getPublishButtonLabel()}
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export const getRosterTopController = (
  rosterInView,
  startDate,
  finishDate,
  location,
  warnings,
  isScheduleView,
  periodNum,
  plan,
  areas,
  areaFilterProperties
) => {
  const { onAreaFilterChanged, saveAreaFilter, initialAreaFilterValue } =
    areaFilterProperties;
  const {
    Employees: employees,
    locationID,
    id: rosterID,
    name: rosterName,
    numDays,
  } = rosterInView;

  return (
    <div className={styles.topControllers}>
      <div className={styles.top}>
        <div className={styles.left}>
          <GetStartedButton
            url="https://help.rosterlab.com/"
            noPaddingBottom={true}
          />
          <AreaFilter
            areas={areas}
            onAreaFilterChanged={onAreaFilterChanged}
            onMenuClose={saveAreaFilter}
            defaultValue={initialAreaFilterValue}
          />
          {isScheduleView && (
            <TableSchedulePeriodSwitcher
              location={location}
              periodStartDate={startDate}
              periodFinishDate={finishDate}
              globalEmployees={null}
              numDays={numDays}
              isSaving={false}
              periodNum={periodNum}
              pageUrlSlug={null}
              customContainerStyle={{ marginLeft: "15px" }}
              preSwitchPeriodAction={() => {}}
              createRosterModelForMainStreamInDifferentPeriod={() => {}}
              readOnly={true}
            />
          )}
        </div>
        <div className={styles.right}>
          {![PLAN.COORDINATOR].includes(plan) && isScheduleView && (
            <SnapshotsDropdown
              location={location}
              rosterID={rosterID}
              isMainStream={false}
              periodStartDate={startDate}
            />
          )}
          {canAccessRerostering(plan) && (
            <RerosterButton
              employees={employees}
              initialStartDate={startDate}
              initialFinishDate={finishDate}
              rosterID={rosterID}
              locationID={locationID}
              rosterName={rosterName}
              isScheduleView={isScheduleView}
              warnings={warnings}
              location={location}
              areas={areas}
              initialAreaFilterValue={initialAreaFilterValue}
            >
              Reroster
            </RerosterButton>
          )}
        </div>
      </div>
    </div>
  );
};

export const updateScheduleMyRoster = (
  employees,
  newEmployeesData,
  periodStartDate,
  periodFinishDate
) => {
  const employeesData = [];
  const allDatesInRoster = DateTime.getAllDatesBetween(
    periodStartDate,
    periodFinishDate,
    true,
    true
  ).map((date) => date.toFormat("AWS"));

  newEmployeesData.forEach((empData) => {
    const employee = employees.find((emp) => emp.id === empData.id);

    const rosteredAllocations = empData.RosteredAllocations;
    const publishedAllocations = employee.PublishedAllocations;

    const publishedAllocationsOutOfRange = publishedAllocations.filter(
      (allocation) => !allDatesInRoster.includes(allocation.date)
    );

    const updatedPublishedAllocationsWithinRange = allDatesInRoster.map(
      (date, idx) => {
        const matchingAllo = publishedAllocations.find(
          (allo) => allo.date === date
        );
        const published = matchingAllo?.publishedAllocation;
        const isOpenShift = matchingAllo ? matchingAllo.isOpenShift : false;
        const note = matchingAllo ? matchingAllo.note : null;
        const allocation = {
          date,
          draftAllocation:
            published === rosteredAllocations[idx]
              ? null
              : rosteredAllocations[idx],
          publishedAllocation: published,
          isOpenShift: isOpenShift ? true : false,
          note,
        };
        return allocation;
      }
    );

    const updatedPublishedAllocations = [
      ...publishedAllocationsOutOfRange,
      ...updatedPublishedAllocationsWithinRange,
    ];
    sortByDateField(updatedPublishedAllocations, "date", true);

    employeesData.push({
      employeeID: employee.id,
      allocations: updatedPublishedAllocations,
    });
  });

  const updatedEmployees = employeesData.map((empData) => {
    const employee = employees.find((emp) => emp.id === empData.employeeID);

    const updatedEmployee = {
      ...employee,
      PublishedAllocations: empData.allocations.sort(function (a, b) {
        return new DateTime(a.date).getDate() - new DateTime(b.date).getDate();
      }),
    };

    return updatedEmployee;
  });

  return updatedEmployees;
};

export const ShiftViewSwitcher = ({
  isShiftView,
  setShowShiftView,
  updateDefaultPageView = true,
}) => {
  const { changeDefaultPageView } = useDefaultPageViewStore();
  return (
    <div className={styles.shiftViewSwitcher}>
      <TwoOptionsSwitcher
        onChange={(e) => {
          const isChecked = e.target.checked;
          setShowShiftView(isChecked);
          if (updateDefaultPageView) {
            const defaultMyRosterView = isChecked
              ? myRosterPageViewSetting.possibleValues.shiftView
              : myRosterPageViewSetting.possibleValues.employeeView;
            changeDefaultPageView(
              myRosterPageViewSetting.name,
              defaultMyRosterView
            );
          }
        }}
        isChecked={isShiftView}
        uncheckedLabel="Employee View"
        checkedLabel="Shift View"
      />
    </div>
  );
};
