import styles from "./NoEmployeesMsgDisplayer.module.css";

const NoEmployeesMsgDisplayer = ({ redirectToEmployeesPage }) => (
  <span className={styles.noEmpMsg}>
    Please add employees in{" "}
    <span className={styles.linkToOverview} onClick={redirectToEmployeesPage}>
      Employee-Overview page
    </span>
  </span>
);

export default NoEmployeesMsgDisplayer;
