import { useMemo } from "react";
import { getIds, getNames, removeSuffix } from "../../../../../utils";
import styles from "./FailedSolutionData.module.css";
import { canAccessLongFormFailExplanation } from "../../../../../utils/flagsUtils/flags";
import { DEMAND_OPTIONS_WITH_MAXIMUM } from "../../Demands/DemandsGrid/DemandConstants";

const FailedSolutionData = ({
  failedEmployeeData,
  redirectToIndividualEmployeeView,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  demands,
}) => {
  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskNames = useMemo(() => getNames(tasks), [tasks]);
  const subTaskNames = useMemo(() => getNames(subTasks), [subTasks]);

  let isPossibleExplanationAssigned = false;
  const {
    issues,
    possibleExplanationData,
    employeesWithFixedShiftsViolation,
    employeesWithHistoryViolation,
  } = failedEmployeeData;

  const issueKeys = Object.keys(issues);

  return (
    <div className={styles.failedSolutionData}>
      {issueKeys.map((key, index) => {
        const issue = issues[key];
        const employeeIdsInIssue = issue.employees.map(({ id }) => id);
        let possibleExplanation = null;

        if (
          !isPossibleExplanationAssigned &&
          possibleExplanationData &&
          possibleExplanationData[0]
        ) {
          if (
            employeeIdsInIssue.includes(possibleExplanationData[0].employeeID)
          ) {
            possibleExplanation =
              possibleExplanationData[0].possibleExplanation;
            isPossibleExplanationAssigned = true;
          }
        }

        return (
          <IssueBlock
            key={key}
            issueNum={index + 1}
            totalIssuesNum={issueKeys.length}
            brokenRulesSets={issue.brokenRulesSets}
            employees={issue.employees}
            possibleExplanation={possibleExplanation}
            redirectToIndividualEmployeeView={redirectToIndividualEmployeeView}
            employeesWithFixedShiftsViolation={
              employeesWithFixedShiftsViolation
            }
            employeesWithHistoryViolation={employeesWithHistoryViolation}
            shiftNames={shiftNames}
            shiftGroupNames={shiftGroupNames}
            taskNames={taskNames}
            subTaskNames={subTaskNames}
            demands={demands}
          />
        );
      })}
    </div>
  );
};

const IssueBlock = ({
  issueNum,
  totalIssuesNum,
  brokenRulesSets,
  employees,
  possibleExplanation,
  redirectToIndividualEmployeeView,
  employeesWithFixedShiftsViolation,
  employeesWithHistoryViolation,
  shiftNames,
  shiftGroupNames,
  taskNames,
  subTaskNames,
  demands,
}) => {
  const fixedShiftsViolatingEmployees =
    employeesWithFixedShiftsViolation.filter((employee) =>
      getIds(employees).includes(employee.id)
    );

  const historyViolatingEmployees = employeesWithHistoryViolation.filter(
    (employee) => getIds(employees).includes(employee.id)
  );

  const hasRulesViolation = brokenRulesSets.length > 0;
  const hasFixeShiftViolation = fixedShiftsViolatingEmployees.length > 0;
  const hasHistoryViolation = historyViolatingEmployees.length > 0;

  const issueReasons = useMemo(
    () =>
      getIssueReasons(
        brokenRulesSets,
        fixedShiftsViolatingEmployees,
        historyViolatingEmployees,
        shiftNames,
        shiftGroupNames,
        taskNames,
        subTaskNames,
        demands
      ),
    [
      brokenRulesSets,
      demands,
      fixedShiftsViolatingEmployees,
      historyViolatingEmployees,
      shiftGroupNames,
      shiftNames,
      subTaskNames,
      taskNames,
    ]
  );

  const getIssueContent = () => {
    if (hasRulesViolation) {
      return (
        <div className={styles.contentContainer}>
          {brokenRulesSets.map((rules, idx) => (
            <div key={idx} className={styles.contentList}>
              {idx > 0 && <p className={styles.separator}>or</p>}
              <p className={styles.rulesSetTitle}>This set of rules:</p>
              <ul>
                {rules.map((rule, ruleIndex) => (
                  <li
                    key={ruleIndex}
                    className={`${`${styles.contentListItem} ${styles.numberedListItem}`}`}
                  >
                    {rule}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {fixedShiftsViolatingEmployees.length > 0 && (
            <div className={styles.contentList}>
              <p className={styles.separator}>or</p>
              <ul>
                <li
                  className={`${styles.contentListItem} ${styles.darkBlueText}`}
                >
                  Fixed shifts
                </li>
              </ul>
            </div>
          )}
          {historyViolatingEmployees.length > 0 && (
            <div className={styles.contentList}>
              <p className={styles.separator}>or</p>
              <ul>
                <li className={`${styles.contentListItem} ${styles.goldText}`}>
                  History
                </li>
              </ul>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.contentContainer}>
        <p className={styles.contentTitle}>
          The following factors might have contributed to this issue:
        </p>
        <ul>
          <li className={styles.contentListItem}>
            Restriction based on shift, skill, and staffing demands in this
            roster{(hasFixeShiftViolation || hasHistoryViolation) && "; or"}
          </li>
          {hasFixeShiftViolation && (
            <li className={`${styles.contentListItem} ${styles.darkBlueText}`}>
              Fixed shifts{hasHistoryViolation && "; or"}
            </li>
          )}
          {hasHistoryViolation && (
            <li className={`${styles.contentListItem} ${styles.goldText}`}>
              History
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className={styles.issueBlock}>
      <div className={styles.issueBlockTop}>
        <p className={styles.issueBlockTitle}>
          ISSUE {issueNum}/{totalIssuesNum}:
        </p>
      </div>
      <div className={styles.issueBlockBottom}>
        <div className={styles.issueBlockBottomLeft}>{getIssueContent()}</div>
        <div className={styles.issueBlockBottomRight}>
          <div className={styles.employeeButtonsContainer}>
            <p className={styles.employeeButtonsLabel}>Affected Employees:</p>
            <div className={styles.employeeButtons}>
              {employees.map(({ name, id }, idx) => {
                const isSecondLastElement = idx === employees.length - 2;
                const isLastElement = idx === employees.length - 1;

                let conjunction = ",";
                if (isSecondLastElement) {
                  conjunction = " and";
                }
                if (isLastElement) {
                  conjunction = "";
                }

                const isFixedShiftViolatingEmployee =
                  fixedShiftsViolatingEmployees.find(
                    (fixedShiftsEmployee) => id === fixedShiftsEmployee.id
                  );

                const isHistoryViolatingEmployee =
                  historyViolatingEmployees.find(
                    (historyEmployee) => id === historyEmployee.id
                  );

                return (
                  <div key={id} className={`${styles.employeeButtonContainer}`}>
                    <button
                      className={`${styles.employeeButton} ${
                        isFixedShiftViolatingEmployee
                          ? styles.fixedShiftViolatingEmployee
                          : ""
                      } ${
                        isHistoryViolatingEmployee
                          ? styles.historyViolatingEmployee
                          : ""
                      }`}
                      onClick={() => redirectToIndividualEmployeeView(id)}
                    >
                      {name}
                    </button>
                    {conjunction}
                  </div>
                );
              })}
            </div>
          </div>
          {(hasFixeShiftViolation || hasHistoryViolation) && (
            <div className={styles.legends}>
              {hasFixeShiftViolation && (
                <div className={styles.legendExplainer}>
                  <p>Some might be related to</p>
                  <span className={styles.fixedShiftViolatingEmployee}>
                    fixed shifts
                  </span>
                </div>
              )}
              {hasHistoryViolation && (
                <div className={styles.legendExplainer}>
                  <p>Some might be related to</p>
                  <span className={styles.historyViolatingEmployee}>
                    history
                  </span>
                </div>
              )}
            </div>
          )}

          {canAccessLongFormFailExplanation() && (
            <ResolveInstruction
              employees={employees}
              reasons={[
                ...issueReasons.fixedShiftsReasons,
                ...issueReasons.historyReasons,
                ...issueReasons.otherReasons,
                "RosterLab support is also happy to help you resolve this issue. Reach out to us through the chat bubble in the bottom right.",
              ]}
              title="Here is a workflow to help with resolving this issue"
            />
          )}
          {possibleExplanation && (
            <ResolveInstruction
              employees={employees}
              reasons={[possibleExplanation]}
              title="Possible Explanation (Beta)"
            />
          )}
        </div>
      </div>
    </div>
  );
};

function getViolatingFixedShiftTypes(
  fixedShiftsViolatingEmployees,
  shiftGroupNames,
  shiftNames,
  subTaskNames,
  taskNames
) {
  let fixedShiftsHasShiftGroup = false;
  let fixedShiftsHasShift = false;
  let fixedShiftsHasTask = false;
  let fixedShiftsHasSubtask = false;

  for (const employee of fixedShiftsViolatingEmployees) {
    for (const day of employee.days) {
      if (!day) {
        continue;
      }

      const allocation = removeSuffix(day, 1);
      const [shiftNamePart, taskNamePart] = allocation.split("-");

      if (!fixedShiftsHasShiftGroup) {
        if (shiftGroupNames.includes(shiftNamePart)) {
          fixedShiftsHasShiftGroup = true;
          continue;
        }
      }

      if (!fixedShiftsHasShift) {
        if (shiftNames.includes(shiftNamePart)) {
          fixedShiftsHasShift = true;
          continue;
        }
      }

      if (!fixedShiftsHasTask) {
        if (
          taskNames.includes(shiftNamePart) ||
          taskNames.includes(taskNamePart)
        ) {
          fixedShiftsHasTask = true;
          continue;
        }
      }

      if (!fixedShiftsHasSubtask) {
        if (
          subTaskNames.includes(shiftNamePart) ||
          subTaskNames.includes(taskNamePart)
        ) {
          fixedShiftsHasSubtask = true;
          continue;
        }
      }
    }
  }

  return {
    fixedShiftsHasShiftGroup,
    fixedShiftsHasShift,
    fixedShiftsHasTask,
    fixedShiftsHasSubtask,
  };
}

function getIssueReasons(
  brokenRulesSets,
  fixedShiftsViolatingEmployees,
  historyViolatingEmployees,
  shiftNames,
  shiftGroupNames,
  taskNames,
  subTaskNames,
  demands
) {
  const fixedShiftsReasons = [];
  const historyReasons = [];
  const otherReasons = [];

  const hasRulesViolation = brokenRulesSets.length > 0;
  const hasFixedShiftsViolation = fixedShiftsViolatingEmployees.length > 0;
  const hasHistoryViolation = historyViolatingEmployees.length > 0;
  const hasTasks = taskNames.length > 0;

  const criticalMaxOrTargetDemands = demands.filter(
    (demand) =>
      demand.importance === "Critical" &&
      DEMAND_OPTIONS_WITH_MAXIMUM.includes(demand.type)
  );

  const {
    fixedShiftsHasShiftGroup,
    fixedShiftsHasShift,
    fixedShiftsHasTask,
    fixedShiftsHasSubtask,
  } = getViolatingFixedShiftTypes(
    fixedShiftsViolatingEmployees,
    shiftGroupNames,
    shiftNames,
    subTaskNames,
    taskNames
  );

  if (hasFixedShiftsViolation) {
    fixedShiftsReasons.push(
      "A part of this issue is the fixed shifts. Ensure that the highlighted fixed shifts are correct."
    );
  }

  if (fixedShiftsHasShiftGroup) {
    fixedShiftsReasons.push(
      "For fixed shifts that are a shift group, check if the shift group is configured correctly. You can use the (?) under help on the shift group page to check what the shift group includes."
    );
  }

  if (fixedShiftsHasShift || fixedShiftsHasTask || fixedShiftsHasSubtask) {
    fixedShiftsReasons.push(
      "For fixed shifts that are a shift or task, check if it can be given a corresponding shift or task on that day."
    );
  }

  if (hasHistoryViolation) {
    historyReasons.push("Check on the history page if the history is correct.");
  }

  if (hasRulesViolation && (hasFixedShiftsViolation || hasHistoryViolation)) {
    otherReasons.push(
      "Imagine a roster pattern [with the fixed shift] [ from the start of the roster given the history]. See if any of the rules conflicts with this roster pattern."
    );
  }

  if (brokenRulesSets.length === 2) {
    otherReasons.push(
      "It is possible that the 2 sets of rules contradict each other. If this is the case all the rules in one set will have to be removed or changed."
    );
  }

  if (!hasRulesViolation && hasFixedShiftsViolation) {
    otherReasons.push(
      "Check if the staff member has the correct skills and shifts available to them on the employee page."
    );
  }

  let hasCriticalMaxOrTargetDemandWithZeroValue = false;

  for (const demand of criticalMaxOrTargetDemands) {
    if (demand.values.some((value) => value === 0)) {
      hasCriticalMaxOrTargetDemandWithZeroValue = true;
      break;
    }
  }

  if (hasCriticalMaxOrTargetDemandWithZeroValue) {
    otherReasons.push(
      "Target or Maximum demands with a value of 0 can prevent a specific shift or task from being assigned that is necessary for this employee's roster."
    );
  }

  if (hasTasks) {
    otherReasons.push(
      "If the task is not referenced by any demands, it will not be assignable."
    );
  }

  if (hasCriticalMaxOrTargetDemandWithZeroValue || hasTasks) {
    otherReasons.push(
      "This may be in conflict with the [fixed shifts,] [history], [shift patterns forced by the rules]."
    );
  }

  if (hasCriticalMaxOrTargetDemandWithZeroValue || hasTasks) {
    otherReasons.push(
      "Lastly the roster model itself can forbid a shift from being generated that is necessitated by the Fixed Shifts, History, or Rule(s)."
    );
  }

  return {
    fixedShiftsReasons,
    historyReasons,
    otherReasons,
  };
}

const ResolveInstruction = ({ reasons, title }) => {
  return (
    <div className={styles.howToResolveContainer}>
      <details className={styles.instructionCollapsibleWidget}>
        <summary>{title}</summary>
        <ul className={styles.instructionsList}>
          {reasons.map((message, idx) => (
            <li className={styles.instructionsListItem} key={idx}>
              {message}
            </li>
          ))}
        </ul>
      </details>
    </div>
  );
};

export default FailedSolutionData;
