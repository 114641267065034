import { useCallback, useState } from "react";
import styles from "./SkillsGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useContainerDimensions } from "../../../../../hooks/useContainerDimensions";
import { useRef } from "react";
import { useMemo } from "react";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import {
  createContextMenuWithDelete,
  defaultValueSetter,
  onFilterTextBoxChanged,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { isValidSkillName } from "../../../../../utils/validationUtils/sharedValidations";

const SkillsGrid = ({
  skillsData,
  numSkills,
  isSaving,
  setGridApiToParent,
  addData: addNewSkills,
  updateData,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateSkills,
  removeData: removeSkills,
  gridApi,
  reorderSkillsData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  getDataFromGrid,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeSkills(true);
    });
  };

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const checkNameIsDuplicate = useCallback(
    (name) => {
      const names = [];
      if (gridApi) {
        gridApi.forEachNode((node) => {
          names.push(node.data.name);
        });
      }
      return names.includes(name);
    },
    [gridApi]
  );

  const columnDefs = useMemo(
    () => [
      {
        suppressMovable: true,
        field: "name",
        suppressSizeToFit: true,
        width: 250,
        valueSetter: (params) => {
          if (checkNameIsDuplicate(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Duplicate name",
              "Please use another name"
            );
            return false;
          }

          if (params.newValue == null || params.newValue === "") {
            showWarningPopupIfNoOthersShowing(
              "Skill name cannot be blank",
              "Please use another name"
            );
            return false;
          }

          if (!isValidSkillName(params.newValue)) {
            showWarningPopupIfNoOthersShowing(
              "Please use another name",
              `Skill names cannot include '-' (hyphen) or ',' (comma)`
            );
            return false;
          }
          params.data.name = params.newValue.trim();
          return true;
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        rowDrag: true,
      },
      {
        suppressMovable: true,
        field: "description",
        width: width - 250 - 2,
        valueSetter: defaultValueSetter,
      },
    ],
    [checkNameIsDuplicate, width]
  );

  return (
    <Layout
      title="Skills"
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/employees-skills-and-tasks"}
        />
      )}
    >
      <div className={styles.container} ref={componentRef}>
        <div className={styles.topLine}>
          <div className={styles.left}>
            <span className={styles.subtitle}>
              This table involves entering certain qualifications that your
              staff have
            </span>
          </div>
          <div className={styles.right}>
            <AddRowButton
              rowName="Skill"
              addSingle={() => {
                if (!isCellEditing) addNewSkills(1);
              }}
              addOptions={[
                {
                  label: "Add New",
                  onClick: () => addNewSkills(1),
                },
                {
                  label: "Add Multiple",
                  onClick: () => {
                    const num = parseInt(
                      prompt("How many skills should I add?", "1")
                    );
                    addNewSkills(num);
                  },
                },
              ]}
            />
          </div>
        </div>
        <ActionBar
          searchBarSettings={{
            tableName: "skills",
            onFilterInputChanged,
          }}
          duplicateSelectedSettings={{
            selectedRows,
            duplicateSelectedRows: duplicateSkills,
          }}
          deleteSelectedSettings={{
            selectedRows,
            removeSelectedRows: () => removeSkills(false),
          }}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
        />
        <DataEntryTable
          columnDefs={columnDefs}
          rowData={skillsData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderSkillsData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          enableRowDragAnimation={!isSaving}
          tableName="skills"
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
        />
        <p className={styles["num-line"]}>
          <span className={styles.emph}>Number of skills:</span> {numSkills}
        </p>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
    </Layout>
  );
};

export default SkillsGrid;
