import styles from "./NumRulesLimitInfo.module.css";
import { MID_TIER_RULE_NUMBER_LIMIT } from "../../../../constants/generalConstants";
import { useToggleShow } from "../../../../hooks/useToggleShow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import TooltipPopup from "../../../../components/elements/TooltipPopup/TooltipPopup";
import { openHubspotChat } from "../../../../utils";

const NumRulesLimitInfo = ({ numRules }) => {
  const { isOpen, toggleDropdown, selectorTriggerRef, selectorRef } =
    useToggleShow();

  const isOverLimit = numRules > MID_TIER_RULE_NUMBER_LIMIT;

  return (
    <div className={styles.numRulesLimitInfo}>
      <div
        className={styles.numRulesLimitContainer}
        ref={selectorTriggerRef}
        onClick={toggleDropdown}
      >
        <FontAwesomeIcon
          style={{
            ...(isOverLimit && { color: "red" }),
          }}
          icon={faCircleInfo}
          className={styles.numRulesLimitIcon}
        />
        <p
          className={styles.numRulesLimit}
          style={{
            ...(isOverLimit && { color: "red" }),
          }}
        >
          {numRules}/{MID_TIER_RULE_NUMBER_LIMIT} rules
        </p>
      </div>
      {isOpen && (
        <TooltipPopup
          ref={selectorRef}
          style={{
            zIndex: "1",
            position: "absolute",
            width: "700px",
            bottom: "-60px",
            right: "-350px",
          }}
        >
          <p className={styles.numRulesLimitTooltip}>
            You can add up to {MID_TIER_RULE_NUMBER_LIMIT} rules for RosterLab
            Starter.{" "}
            <span
              style={{
                color: "#219EC6",
              }}
            >
              Having a complex roster and need more rules?{" "}
              <b
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  toggleDropdown();
                  openHubspotChat();
                }}
              >
                Chat with our team now!
              </b>
            </span>
          </p>
        </TooltipPopup>
      )}
    </div>
  );
};

export default NumRulesLimitInfo;
