import { DateTime } from "../dataTypesUtils/DateTime";
import { chunkArray } from "./array";

export function getMonthCalendarContent(year, monthName) {
  const outerArray = [
    Array(7).fill(null),
    Array(7).fill(null),
    Array(7).fill(null),
    Array(7).fill(null),
    Array(7).fill(null),
    Array(7).fill(null),
  ];

  const dowIndexArr = [6, 0, 1, 2, 3, 4, 5];

  const monthIndex = DateTime.getMonthIndex(monthName);
  const firstDateOfMonth = new DateTime(new Date(year, monthIndex, 1));
  const numDaysInMonth = firstDateOfMonth.getDaysInMonth();

  //   Fill in current month info into calendar
  let weekNumIndex = 0;
  for (let i = 0; i < numDaysInMonth; i++) {
    const date = new DateTime(firstDateOfMonth).addDays(i);
    const dowIndex = dowIndexArr[date.getDayOfWeek()];
    outerArray[weekNumIndex][dowIndex] = date;

    if (dowIndex === 6) {
      weekNumIndex++;
    }
  }

  let flatDates = outerArray.flat();

  //   Fill in previous month info into calendar
  let numEmptyDaysAtFront = 0;
  for (let i = 0; i < flatDates.length; i++) {
    if (flatDates[i] !== null) {
      break;
    }
    numEmptyDaysAtFront++;
  }

  const prevMonthDaysToAdd = [];
  if (numEmptyDaysAtFront > 0) {
    for (let i = 1; i <= numEmptyDaysAtFront; i++) {
      const prevMonthDate = new DateTime(firstDateOfMonth).subtractDays(i);
      prevMonthDaysToAdd.unshift(prevMonthDate);
    }
  }

  //   Fill in next month info into calendar

  let numEmptyDaysAtEnd = 0;
  for (let i = flatDates.length - 1; i >= 0; i--) {
    if (flatDates[i] !== null) {
      break;
    }
    numEmptyDaysAtEnd++;
  }

  const nextMonthDaysToAdd = [];
  if (numEmptyDaysAtEnd > 0) {
    for (let i = 0; i < numEmptyDaysAtEnd; i++) {
      const prevMonthDate = new DateTime(firstDateOfMonth)
        .addMonths(1)
        .addDays(i);
      nextMonthDaysToAdd.push(prevMonthDate);
    }
  }

  const currentMonthDays = [
    ...prevMonthDaysToAdd,
    ...flatDates.filter((day) => day !== null),
    ...nextMonthDaysToAdd,
  ];

  const calendarForm = chunkArray(currentMonthDays, 7);
  return calendarForm;
}

export function getMonthCalendarWithoutNextMonthDates(
  year,
  monthName,
  nextMontName
) {
  const fullMonthCalendarContent = getMonthCalendarContent(year, monthName);
  const calendarContent = [];

  for (let i = 0; i < fullMonthCalendarContent.length; i++) {
    const week = fullMonthCalendarContent[i];
    const lastDayOfWeek = week[week.length - 1];
    const monthOfLastDayOfWeek = lastDayOfWeek.getMonth();
    if (monthOfLastDayOfWeek === nextMontName) {
      break;
    }
    calendarContent.push(week);
  }

  return calendarContent;
}

export function getNumWeeksInMonthCalendar(
  year,
  currentMonthName,
  nextMonthName
) {
  const calendar = getMonthCalendarContent(year, currentMonthName);

  let numWeeks = 0;
  for (let i = 0; i < calendar.length; i++) {
    const week = calendar[i];

    if (week[0].getMonth() === nextMonthName) {
      break;
    }

    numWeeks++;
  }

  return numWeeks;
}
