import { v4 as uuidv4 } from "uuid";
import {
  DateTime,
  deepCopyObject,
  multiplyArray,
  strToArrCommaSeparated,
  getShiftsTasksCombo,
  getNames,
  getEnumeratedShiftTaskCombo,
  getShortIds,
  removeDuplicateStrInArr,
} from "../../../../utils";
import {
  getCombinedSubtasks,
  getSubtasks,
} from "../../../rosterProblems/service/rosterUtils";
import dayjs from "dayjs";
import {
  createRosterForScheduleView,
  inferRosterStartDate,
} from "../../../scheduleView/service/scheduleViewRoster";
import { getRosterStartAndFinishDate } from "../../../../utils/queryUtils/rosterDataGetters";
import { getActualNumDays } from "../../../../utils/queryUtils/monthViewUtils";
import { getRostersBySnapshotStartDateRange } from "../../../../utils/queryUtils/locationQuery";
import {
  KEYWORD_ALL,
  KEYWORD_NO_TASK,
  KEYWORD_OFF,
} from "../../../../constants/keywords";

export const extendArray = (numDays, arr, placeholder) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr.length < numDays) {
      if (placeholder === undefined) {
        arr = arr.concat(
          multiplyArray(arr.slice(0, 7), (numDays - arr.length) / 7)
        );
      } else {
        arr = arr.concat(multiplyArray([placeholder], numDays - arr.length));
      }
    }
    if (arr.length > numDays) {
      arr.splice(numDays, arr.length - numDays);
    }
  }
  return arr;
};

export const getDemandIDsWithNonEmptyUniquifyingFields = (object) => {
  const { Demands } = object;
  const demandIDs = [];
  Demands.forEach((item) => {
    if (item.shifts !== "" || item.skills !== "" || item.tasks !== "") {
      demandIDs.push(item.id);
    }
  });
  return demandIDs;
};
/**
 * Find id that is in the 'before' array but not in the 'after' array
 * @param {*} before - Array of ID of demands with shifts or skills
 * @param {*} after - Array of ID of demands with shifts or skills
 */
export const getDemandIDsToDelete = (before, after) => {
  const toDelete = [];

  for (const id of before) {
    if (!after.includes(id)) {
      toDelete.push(id);
    }
  }
  return toDelete;
};

export const updateAnnualLeaveRequestFixedShiftsToRosterEmployee = (
  rosterStartDate,
  ALDates,
  ALKeyword,
  fixedShiftsOverviewTemplate,
  isRequestApproved
) => {
  const fixedShiftsOverview = fixedShiftsOverviewTemplate.map((allo, idx) => {
    const allocationDate = new DateTime(rosterStartDate)
      .addDays(idx)
      .toFormat("AWS");
    if (ALDates.includes(allocationDate)) {
      if (isRequestApproved) {
        return ALKeyword;
      } else {
        return "";
      }
    }
    return allo;
  });
  return fixedShiftsOverview;
};

const getInversedShifts = (beforeInversed, shiftNames) => {
  let inversed = shiftNames.filter((s) => !beforeInversed.includes(s));
  if (!beforeInversed.includes(KEYWORD_OFF)) {
    inversed = [...inversed, KEYWORD_OFF];
  }

  return inversed;
};

const getInversedTasks = (beforeInversed, taskNames) => {
  let inversed = taskNames.filter((t) => !beforeInversed.includes(t));
  if (!beforeInversed.includes(KEYWORD_NO_TASK)) {
    inversed = [...inversed, KEYWORD_NO_TASK];
  }
  if (inversed.length === 0) {
    return [KEYWORD_NO_TASK];
  }
  return inversed;
};

const getEmployeeWorkableAllocationsForShift = (
  targetShiftShortId,
  taskShortIds
) => {
  let employeeAllocatables = [targetShiftShortId];

  const shiftTaskCombo = getShiftsTasksCombo(
    [targetShiftShortId],
    taskShortIds,
    [KEYWORD_OFF],
    [KEYWORD_NO_TASK]
  );
  employeeAllocatables = [
    ...employeeAllocatables,
    ...shiftTaskCombo,
    ...taskShortIds,
  ];
  return employeeAllocatables;
};

export const getWorkableAllocationsForShiftGroup = (
  shiftGroups,
  targetShiftGroupShortId,
  shiftShortIds,
  taskShortIds,
  tasks,
  taskBlocks,
  onlyFullAllocations = false
) => {
  let employeeAllocatables = [];

  const targetShiftGroup = shiftGroups.find(
    (group) => group.shortId === targetShiftGroupShortId
  );

  if (!onlyFullAllocations) {
    employeeAllocatables.push(targetShiftGroupShortId);
  }

  const isShiftsInversed = targetShiftGroup.inversed;
  const isTasksInversed = targetShiftGroup.skillsInversed;

  const shiftsInGroup = strToArrCommaSeparated(targetShiftGroup.shifts);
  const tasksInGroup =
    targetShiftGroup.tasks === ""
      ? [...taskShortIds, KEYWORD_NO_TASK]
      : strToArrCommaSeparated(targetShiftGroup.tasks);

  const workableShifts = isShiftsInversed
    ? getInversedShifts(shiftsInGroup, shiftShortIds)
    : shiftsInGroup;

  let workableTasks = isTasksInversed
    ? getInversedTasks(tasksInGroup, taskShortIds)
    : tasksInGroup;

  if (workableTasks.includes(KEYWORD_NO_TASK)) {
    employeeAllocatables = [...employeeAllocatables, ...workableShifts];
  }

  const workableEnumeratedTasks = getNames(
    getCombinedSubtasks(
      tasks.filter((task) => workableTasks.includes(task.shortId)),
      taskBlocks
    )
  );

  const workableSubtasks = getNames(
    getSubtasks(
      tasks.filter((task) => workableTasks.includes(task.shortId)),
      taskBlocks
    )
  );

  const allowedShiftTaskCombo = getEnumeratedShiftTaskCombo(
    workableShifts.filter((shift) => shift !== KEYWORD_OFF),
    [
      ...workableTasks.filter((t) => t !== KEYWORD_NO_TASK),
      ...workableEnumeratedTasks,
      ...workableSubtasks,
    ]
  );

  employeeAllocatables = [...employeeAllocatables, ...allowedShiftTaskCombo];

  if (!onlyFullAllocations) {
    employeeAllocatables = [
      ...employeeAllocatables,
      ...workableTasks.filter((t) => t !== KEYWORD_NO_TASK),
      ...workableSubtasks,
      ...workableEnumeratedTasks,
    ];
  }

  return employeeAllocatables;
};

export const updateAnnualLeaveRequestFixedShiftsToAllRelevantRosters = async (
  location,
  employeeID,
  employeeName,
  request,
  leaveCodesDict,
  globalEmployees,
  annualLeaveKeyword
) => {
  const locationID = location.id;
  const numDays = location.defaultNumDays;

  const updatedRosters = [];
  const rostersToBeCreated = [];

  const {
    startDate: requestStartDate,
    finishDate: requestFinishDate,
    state,
    request: requestLeaveLongName,
  } = request;

  const isRequestApproved = state === "Approved";

  // Get all rosters within dates
  const rosters = await getAllRostersWithinDates(
    locationID,
    requestStartDate,
    requestFinishDate,
    numDays === -1 ? 31 : numDays // 31 is the max number of days in a month
  );

  // Get rosters to be created
  const allRequestDates = DateTime.getAllDateStringsBetween(
    requestStartDate,
    requestFinishDate,
    true,
    true
  );

  allRequestDates.forEach((date) => {
    const belongingRoster = rosters.find((roster) => {
      const { startDate: rosterStartDate, finishDate: rosterFinishDate } =
        getRosterStartAndFinishDate(roster);

      const isDateInRoster = new DateTime(date).isDateBetweenTwoDates(
        rosterStartDate,
        rosterFinishDate,
        true,
        true
      );
      return isDateInRoster;
    });

    const rostersToCreateStartDates = rostersToBeCreated.map(
      (r) => r.startDate
    );

    if (!belongingRoster) {
      const inferredRosterStartDate = inferRosterStartDate(
        location.startDate,
        date,
        location.defaultNumDays
      );

      const newRoster = createRosterForScheduleView(
        location.id,
        getActualNumDays(inferredRosterStartDate, location.defaultNumDays),
        inferredRosterStartDate,
        globalEmployees,
        annualLeaveKeyword
      );

      newRoster.id = uuidv4();

      if (!rostersToCreateStartDates.includes(newRoster.startDate)) {
        rostersToBeCreated.push(newRoster);
      }
    }
  });

  const createdRosters = rostersToBeCreated.map((roster) => {
    const { startDate: rosterStartDate } = getRosterStartAndFinishDate(roster);
    const rosterEmployees = deepCopyObject(roster.Employees);

    const updatedEmployees = rosterEmployees.map((employee) => {
      if (employee.id !== employeeID) {
        return employee;
      }

      const Allocations = employee.Allocations.map((allo, idx) => {
        const indexDate = new DateTime(rosterStartDate)
          .addDays(idx)
          .toFormat("AWS");
        if (allRequestDates.includes(indexDate)) {
          return leaveCodesDict[requestLeaveLongName];
        }
        return "";
      });

      return { ...employee, Allocations };
    });

    return {
      ...roster,
      Employees: updatedEmployees,
    };
  });

  // For each roster, update the roster and save it
  for (const roster of rosters) {
    const updatedRoster = updateAnnualLeaveRequestFixedShiftsToRoster(
      roster,
      employeeID,
      employeeName,
      requestStartDate,
      requestFinishDate,
      leaveCodesDict[requestLeaveLongName],
      isRequestApproved
    );

    if (updatedRoster) {
      updatedRosters.push(updatedRoster);
    }
  }
  return {
    updatedRosters,
    createdRosters,
  };
};

const updateAnnualLeaveRequestFixedShiftsToRoster = (
  roster,
  employeeID,
  employeeName,
  requestStartDate,
  requestFinishDate,
  ALKeyword,
  isRequestApproved = true
) => {
  const rosterStartDate = roster.startDate;
  const rosterNumDays = roster.numDays;
  const rosterFinishDate = new DateTime(rosterStartDate)
    .addDays(rosterNumDays - 1)
    .toFormat("AWS");

  const allEmployees = deepCopyObject(roster.Employees);
  let targetEmployee = allEmployees.find((emp) => emp.id === employeeID);
  if (!targetEmployee) {
    targetEmployee = {
      id: employeeID,
      globalEmployeeID: employeeID,
      Days: null,
      DaysRecurring: null,
      Allocations: Array(rosterNumDays).fill(""),
      AllocationsRecurring: null,
      History: Array(14).fill(""),
      RuleValues: null,
      RosteredAllocations: null,
      name: employeeName,
      skills: null,
      shifts: null,
    };
  }

  const overlappingDates = DateTime.getOverlappingDatesGivenTwoRangesInfo(
    rosterStartDate,
    rosterFinishDate,
    requestStartDate,
    requestFinishDate
  );

  if (overlappingDates.length === 0) {
    return;
  }

  const targetEmployeeFixedShiftsOverview = targetEmployee.Allocations;

  const fixedShiftsOverview =
    updateAnnualLeaveRequestFixedShiftsToRosterEmployee(
      rosterStartDate,
      overlappingDates,
      ALKeyword,
      targetEmployeeFixedShiftsOverview,
      isRequestApproved
    );

  targetEmployee.Allocations = fixedShiftsOverview;

  const rosterEmployees = roster.Employees;
  const targetEmployeeExistsInRoster = rosterEmployees.find(
    (emp) => emp.id === targetEmployee.id
  );

  if (!targetEmployeeExistsInRoster) {
    roster.Employees = [...roster.Employees, targetEmployee];
    return roster;
  }

  roster.Employees = roster.Employees.map((emp) => {
    if (emp.id === targetEmployee.id) {
      return targetEmployee;
    }
    return emp;
  });

  return roster;
};

export async function getAllRostersWithinDates(
  locationID,
  startDate,
  endDate,
  numDays
) {
  // Subtracting and adding the maximum number of days will give you a range of start dates for potential intersecting rosters
  const minStartDate = dayjs(startDate)
    .subtract(numDays, "day")
    .format("YYYY-MM-DD");
  const maxStartDate = dayjs(endDate).format("YYYY-MM-DD");

  const rosters = await getRostersBySnapshotStartDateRange(
    locationID,
    false,
    minStartDate,
    maxStartDate,
    true
  );

  return rosters;
}

export const getAllocatablesInPreferences = (
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  allowedCustomKeywords,
  acceptedInputTypes
) => {
  const subTasks = getSubtasks(tasks, taskBlocks);

  const shiftShortIds = getShortIds(shifts);
  const shiftGroupsShortIds = getShortIds(shiftGroups);
  const taskShortIds = getShortIds(tasks);
  const subTaskShortIds = getShortIds(subTasks);
  const enumeratedTaskShortIds = getShortIds(
    getCombinedSubtasks(tasks, taskBlocks)
  );

  const enumeratedCombo = getEnumeratedShiftTaskCombo(shiftShortIds, [
    ...taskShortIds,
    ...subTaskShortIds,
    ...enumeratedTaskShortIds,
  ]);

  const allowedInputs = [...allowedCustomKeywords];
  if (acceptedInputTypes.includes("shifts")) {
    allowedInputs.push(...shiftShortIds);
  }
  if (acceptedInputTypes.includes("shift-groups")) {
    allowedInputs.push(...shiftGroupsShortIds);
  }
  if (acceptedInputTypes.includes("tasks")) {
    allowedInputs.push(...taskShortIds);
  }
  if (acceptedInputTypes.includes("sub-tasks")) {
    allowedInputs.push(...subTaskShortIds);
  }
  if (acceptedInputTypes.includes("enumerated-combo")) {
    allowedInputs.push(...enumeratedCombo);
  }
  if (acceptedInputTypes.includes("enumerated-tasks")) {
    allowedInputs.push(...enumeratedTaskShortIds);
  }

  return allowedInputs;
};

export const getAllocatablesInFixedShifts = (
  employee,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  allowedShiftKeywords
) => {
  let employeeAllocatables;
  const shiftShortIds = getShortIds(shifts);
  const shiftGroupShortIds = getShortIds(shiftGroups);
  const employeeSkills = strToArrCommaSeparated(employee.skills);
  const possibleTasks = tasks
    ? tasks.filter((task) => {
        if (!task.skills) {
          return true;
        }
        const skillRequirements = strToArrCommaSeparated(task.skills);
        const isDoableTask = skillRequirements.some((skill) =>
          employeeSkills.includes(skill)
        );
        return isDoableTask;
      })
    : [];
  const taskShortIds = getShortIds(possibleTasks);
  const enumeratedTaskShortIds = getShortIds(
    getCombinedSubtasks(possibleTasks, taskBlocks)
  );
  const subTaskShortIds = getShortIds(getSubtasks(possibleTasks, taskBlocks));
  const combinedTaskNamesList = [
    ...taskShortIds,
    ...subTaskShortIds,
    ...enumeratedTaskShortIds,
  ];
  const shiftTaskNames = getEnumeratedShiftTaskCombo(shiftShortIds, [
    ...combinedTaskNamesList,
    [KEYWORD_OFF],
    [KEYWORD_NO_TASK],
  ]);

  const employeeShiftsAndGroups = strToArrCommaSeparated(employee.shifts);
  employeeAllocatables = [...employeeShiftsAndGroups, ...allowedShiftKeywords];

  if (employeeShiftsAndGroups.includes(KEYWORD_ALL)) {
    employeeAllocatables = [
      ...employeeAllocatables,
      ...shiftShortIds,
      ...shiftGroupShortIds,
      ...shiftTaskNames,
      ...taskShortIds,
      ...subTaskShortIds,
      ...enumeratedTaskShortIds,
    ];
    return employeeAllocatables;
  }

  for (const workableShortId of employeeShiftsAndGroups) {
    const isShiftGroup = shiftGroupShortIds.includes(workableShortId);

    if (isShiftGroup) {
      employeeAllocatables = [
        ...employeeAllocatables,
        ...getWorkableAllocationsForShiftGroup(
          shiftGroups,
          workableShortId,
          shiftShortIds,
          taskShortIds,
          tasks,
          taskBlocks
        ),
      ];
    } else {
      employeeAllocatables = [
        ...employeeAllocatables,
        ...getEmployeeWorkableAllocationsForShift(
          workableShortId,
          combinedTaskNamesList
        ),
      ];
    }
  }

  employeeAllocatables = [...employeeAllocatables, ...shiftGroupShortIds];

  employeeAllocatables = removeDuplicateStrInArr(employeeAllocatables);

  return employeeAllocatables;
};
