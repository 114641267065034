import create from "zustand";
import { configureFirebaseMessaging } from "../config/firebase";

export const useFirebaseMessageTokenStore = create((set, get) => ({
  currentToken: null,
  setToken: (currentToken) => set(() => ({ currentToken })),
  configureFirebaseMessaging: () => {
    if (get().currentToken) {
      return;
    }
    configureFirebaseMessaging();
  },
}));
