import { memo } from "react";
import styles from "./ScheduleCell.module.css";
import { getDisplayedOpenShiftInfo } from "../../../service/employeeAppUtil";
import {
  getAdjustedEntities,
  getPublishedShiftCellEntities,
} from "../../../service/cellEntities";

const ScheduleCell = memo(
  ({
    allocation,
    openShiftsOnDay,
    employeeID,
    rowHeight,
    cellWidth,
    approvedLeaveShortName,
    isBottomTabOpened,
    note,
  }) => {
    if (approvedLeaveShortName) {
      return (
        <div className={`${styles.cell} ${styles.leaveCell}`}>
          <p className={styles.leaveLabel}>{approvedLeaveShortName}</p>
          {note && !isBottomTabOpened && <p className={styles.note}>{note}</p>}
          {note && isBottomTabOpened && <div className={styles.tag} />}
        </div>
      );
    }

    const { displayedOpenShift, displayedState, pendingShiftNumbers } =
      getDisplayedOpenShiftInfo(employeeID, openShiftsOnDay);

    if (displayedOpenShift && displayedState === "pending") {
      return (
        <div className={`${styles.cell} ${styles.openShiftsCell}`}>
          <p className={styles.openShiftsLabel}>
            Open shifts ({pendingShiftNumbers})
          </p>
          {note && !isBottomTabOpened && <p className={styles.note}>{note}</p>}
          {note && isBottomTabOpened && <div className={styles.tag} />}
        </div>
      );
    }

    if (!allocation) {
      return (
        <div className={styles.cell}>
          {note && !isBottomTabOpened && <p className={styles.note}>{note}</p>}
          {note && isBottomTabOpened && <div className={styles.tag} />}
        </div>
      ); // This prevents flickering when changing date by clicking another cell
    }

    const publishedShiftEntities = getPublishedShiftCellEntities(allocation);

    const getPublishedShiftCellContent = () => {
      if (!rowHeight) {
        return null;
      }

      const LINE_HEIGHT = 14;
      const maxHeight = rowHeight - 18;
      const displayedEntities = getAdjustedEntities(
        publishedShiftEntities,
        maxHeight,
        LINE_HEIGHT,
        cellWidth
      );

      const subtasksExistsInFullEntities =
        publishedShiftEntities.filter((entity) => entity.name === "subtasks")
          .length > 0;
      const subtasksExistsInDisplayedEntities =
        displayedEntities.filter((entity) => entity.name === "subtasks")
          .length > 0;

      const subtaskExistsButHiddenDueToCellHeight =
        subtasksExistsInFullEntities && !subtasksExistsInDisplayedEntities;

      const smallHeightMobile = rowHeight < 80 && !isBottomTabOpened;

      return (
        <div className={`${styles.cell} ${styles.publishedShiftCell}`}>
          {displayedEntities.map((entity) => {
            const { name, contents, lines } = entity;

            if (lines === 0) {
              return null;
            }

            if (subtaskExistsButHiddenDueToCellHeight && name === "shiftName") {
              const shiftName = contents[0];
              return (
                <p
                  key={name}
                  className={`${styles.content} ${styles[`content-${name}`]}`}
                  style={{
                    lineClamp: lines,
                    WebkitLineClamp: lines,
                  }}
                >
                  {shiftName}...
                </p>
              );
            }

            return (
              <div
                key={name}
                className={styles.entity}
                style={{
                  marginBottom: smallHeightMobile ? "0px" : "5px",
                }}
              >
                {contents.map((content, index) => {
                  return (
                    <p
                      key={index}
                      className={`${styles.content} ${
                        styles[`content-${name}`]
                      }`}
                      style={{
                        lineClamp: lines,
                        WebkitLineClamp: lines,
                      }}
                    >
                      {content}
                    </p>
                  );
                })}
              </div>
            );
          })}
          {allocation.isOpenShift && !isBottomTabOpened && (
            <div>
              <p className={styles.acceptedTag}>Accepted</p>
            </div>
          )}
          {note && !isBottomTabOpened && <p className={styles.note}>{note}</p>}
          {note && isBottomTabOpened && <div className={styles.tag} />}
        </div>
      );
    };

    if (publishedShiftEntities) {
      return getPublishedShiftCellContent();
    }

    return <></>;
  }
);

export default ScheduleCell;
