import styles from "./colorPickerCells.module.css";

const ColorPickerRenderer = ({ value: colorCode }) => {
  return (
    <button className={styles.colorPickerRendererContainer}>
      <div
        className={styles.colorPickerRenderer}
        style={{
          backgroundColor: colorCode,
        }}
      ></div>
    </button>
  );
};

export default ColorPickerRenderer;
