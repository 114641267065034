import { useMemo } from "react";
import PreferencesRecurringGrid from "../../../components/Preferences/PreferencesRecurringGrid/PreferencesRecurringGrid";
import { getActualInitialStartDate } from "../../../../../utils/queryUtils/monthViewUtils";

const IndPreferencesRecurringContainer = ({
  location,
  startDate,
  numDays,
  isSaving,
  employeesData,
  recurringColumns,
  updateData,
  setGridApiToParent,
  warnings,
  setColumnApiToParent,
  changePrefLevel,
  enumeratedTasks,
  enumeratedShiftTasks,
  locationID,
  getDataFromGrid,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
}) => {
  const recurringWarnings = useMemo(() => {
    return warnings ? warnings.DaysRecurring : null;
  }, [warnings]);

  return (
    <PreferencesRecurringGrid
      locationID={locationID}
      startDate={getActualInitialStartDate(
        location.isScheduleView ? location.startDate : startDate,
        location.isScheduleView ? location.defaultNumDays : numDays,
        location.isScheduleView
      )}
      employeesData={employeesData}
      isSaving={isSaving}
      recurringColumns={recurringColumns}
      setGridApiToParent={setGridApiToParent}
      updateData={updateData}
      recurringWarnings={recurringWarnings}
      weekdayColWidth={70}
      weekendColWidth={70}
      isGlobal={false}
      customStyle={{
        isSubheading: true,
        dataEntryTable: {
          height: "110px",
        },
      }}
      title="Preferences Recurring"
      setColumnApiToParent={setColumnApiToParent}
      isIndividualView={true}
      changePrefLevel={changePrefLevel}
      showColAdjuster={false}
      enumeratedTasks={enumeratedTasks}
      enumeratedShiftTasks={enumeratedShiftTasks}
      getDataFromGrid={getDataFromGrid}
      shifts={shifts}
      shiftGroups={shiftGroups}
      tasks={tasks}
      subTasks={subTasks}
      areas={areas}
      skills={skills}
      shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
      customKeywordsUtilObj={customKeywordsUtilObj}
    />
  );
};

export default IndPreferencesRecurringContainer;
