import { TABLE_NAMES } from "../../constants";
import { DateTime } from "../dataTypesUtils/DateTime";
import { findCommonElements } from "../generalUtils/array";
import { duplicateArr, strToArrCommaSeparated } from "../generalUtils/array";
import {
  deepCopyObject,
  findIntersectionOfAllNumIntervals,
} from "../generalUtils/general";
import { getGlobalEmployeesWithinPeriod } from "../queryUtils/globalEmployeeDataGetters";
import { getOpenShiftStatusInfo } from "../queryUtils/locationDataGetters";
import {
  generatePatternOutput,
  generatePatternOutputReverse,
} from "../queryUtils/monthViewUtils";
import { getRosterSolutionAllocationRegex } from "../generalUtils/regex";
import { convertAllocationInShortIdFormToNameForm } from "./allocation";
import { KEYWORD_ALL, KEYWORD_NA, KEYWORD_OFF } from "../../constants/keywords";

/**
 * General model related utils
 */
export function getNames(objects) {
  if (!objects) return [];
  return objects.map((object) => object.name);
}

export function getShortIds(objects) {
  if (!objects) return [];
  return objects.map((object) => object.shortId);
}

export function getIds(objects) {
  if (!objects) return [];
  return objects.map((object) => object.id);
}

export const getNextDefaultEntityNameNumber = (
  defaulEntitytNamePrefix,
  entityNames
) => {
  const defaultEntityNames = entityNames
    .filter((name) => {
      return name.match(new RegExp(`${defaulEntitytNamePrefix} [0-9]+`));
    })
    .map((name) => {
      return Number(name.replace(`${defaulEntitytNamePrefix} `, ""));
    });

  const nextDefaultEntityNameNumber =
    defaultEntityNames.length === 0 ? 1 : Math.max(...defaultEntityNames) + 1;

  return nextDefaultEntityNameNumber;
};

export function getShiftHours(shift) {
  const startTime = shift.startTime;
  const finishTime = shift.finishTime;

  const hours = DateTime.getTimeDifferenceInHours(startTime, finishTime);
  return hours;
}

export const getShiftHoursFromShift = (
  shift,
  employee,
  shiftHoursByEmployee
) => {
  let hours = 0;
  if (!shift) return hours;
  if (
    employee.id in shiftHoursByEmployee &&
    shift.shortId in shiftHoursByEmployee[employee.id]
  ) {
    hours = shiftHoursByEmployee[employee.id][shift.shortId];
  }

  return hours;
};

export const getShiftStartAndFinishTime = (shifts, shortId) => {
  const targetShift = shifts.find((s) => s.shortId === shortId);
  return {
    startTime: targetShift.startTime,
    finishTime: targetShift.finishTime,
  };
};

/**
 * Allocation related utils that are globally used
 */
export const getShiftFromAllocation = (allocation, shifts) => {
  const shift = shifts.filter((shift) => {
    const shiftTaskPair = getShiftTask(allocation);
    const shiftName = shiftTaskPair[0];
    return shift.shortId === shiftName;
  })[0];
  return shift;
};

export const getShiftTask = (val) => {
  if (!val) return ["", null];
  const shiftTaskPair = val.toString().split("-");
  const shiftName = shiftTaskPair[0].trim();
  const taskName = shiftTaskPair[1] ? shiftTaskPair[1].trim() : null;
  return [shiftName, taskName];
};

const possibleSuffix = ["*", "!", "?"];

export const isPreferenceSuffixed = (preferenceName) => {
  return possibleSuffix.includes(preferenceName.slice(-1));
};

export const splitAllocationSuffix = (preferenceName) => {
  let suffix = "";
  let allocationWithoutSuffix = preferenceName;

  if (possibleSuffix.includes(preferenceName.slice(-1))) {
    suffix = preferenceName.slice(-1);
    allocationWithoutSuffix = preferenceName.slice(0, -1);
  }

  return [allocationWithoutSuffix, suffix];
};

const checkEmployeeHasRosteredAllocation = (employee) => {
  const rosteredAllocations = employee.RosteredAllocations;
  return rosteredAllocations.some((allo) => allo !== "");
};

export const checkEmployeesHaveRosteredAllocation = (employees) => {
  for (const employee of employees) {
    if (checkEmployeeHasRosteredAllocation(employee)) {
      return true;
    }
  }
  return false;
};

export const getEntityByShortId = (shortId, entities) => {
  const targetEntity = entities.find((s) => s.shortId === shortId);

  if (targetEntity) {
    return targetEntity;
  }
  return null;
};

export const getShiftTaskByShortId = (shortId, shifts, tasks) => {
  const [shiftShortId, taskShortId] = getShiftTask(shortId);

  const targetShift = shifts.find((s) => s.shortId === shiftShortId);
  const targetTask = tasks.find((s) => s.shortId === taskShortId);

  if (targetShift && targetTask) {
    return [targetShift, targetTask];
  }
  return null;
};

const removeSuffixInAllocations = (allocationsArr) => {
  const allocationsWithoutSuffix = allocationsArr.map((shift) => {
    if (shift === null || shift === undefined) return "";
    if (shift.endsWith("!") || shift.endsWith("?") || shift.endsWith("*")) {
      return shift.slice(0, -1);
    } else {
      return shift;
    }
  });
  return allocationsWithoutSuffix;
};

export const getMergedOverviewAndRecurring = (
  overviewArr,
  recurringArr,
  locationStartDate,
  rosterStartDate,
  locationDefaultNumDays
) => {
  const numDays = overviewArr.length;

  const fortnightRecurring =
    recurringArr.length === 14 ? recurringArr : duplicateArr(recurringArr, 2);

  const fullLengthRecurring = generatePatternOutput(
    fortnightRecurring,
    rosterStartDate,
    numDays,
    locationStartDate,
    locationDefaultNumDays
  );

  const resultingRreferences = [];
  for (let i = 0; i < overviewArr.length; i++) {
    if (overviewArr[i]) {
      resultingRreferences[i] = overviewArr[i];
    } else if (fullLengthRecurring[i]) {
      resultingRreferences[i] = fullLengthRecurring[i];
    } else {
      resultingRreferences[i] = "";
    }
  }

  return resultingRreferences;
};

export const getFullEmployeeAllocationsSummary = (
  employees,
  allocationTypes,
  locationStartDate,
  rosterStartDate,
  locationDefaultNumDays
) => {
  const isRosterPreferences =
    allocationTypes === TABLE_NAMES.ROSTER_PREFERENCES;

  const overviewKey = isRosterPreferences ? "Days" : "Allocations";
  const recurringKey = isRosterPreferences
    ? "DaysRecurring"
    : "AllocationsRecurring";
  const infoKey = isRosterPreferences ? "preferences" : "fixedShifts";

  const info = [];

  for (const employee of employees) {
    const overview = removeSuffixInAllocations(employee[overviewKey]);
    const recurring = removeSuffixInAllocations(employee[recurringKey]);
    info.push({
      employeeID: employee.id,
      [infoKey]: getMergedOverviewAndRecurring(
        overview,
        recurring,
        locationStartDate,
        rosterStartDate,
        locationDefaultNumDays
      ),
    });
  }
  return info;
};

/**
 * Convert overview index form to compressed form (grouped if dates are sequencial)
 * @param {*} overviewDataWithIndex - overview data in index form
 * @returns - compressed form
 */
export function compressRequests(overviewDataWithIndex) {
  let groupedAllocations = [];

  for (const allosIdx in overviewDataWithIndex) {
    const allocations = overviewDataWithIndex[allosIdx];
    let tempStorage = [];
    for (const alloIdx in allocations) {
      if (
        parseInt(alloIdx) === allocations.length - 1 &&
        allocations[alloIdx].allocation !== ""
      ) {
        tempStorage.push([
          allocations[alloIdx].name,
          allocations[alloIdx].index,
          allocations[alloIdx].allocation,
        ]);
        groupedAllocations.push(tempStorage);
        continue;
      } else if (
        parseInt(alloIdx) === allocations.length - 1 &&
        allocations[alloIdx].allocation === ""
      ) {
        groupedAllocations.push(tempStorage);
        continue;
      }
      if (allocations[alloIdx].allocation === "") {
        groupedAllocations.push(tempStorage);
        tempStorage = [];
        continue;
      }
      if (parseInt(alloIdx) === 0) {
        tempStorage.push([
          allocations[alloIdx].name,
          allocations[alloIdx].index,
          allocations[alloIdx].allocation,
        ]);

        continue;
      }
      if (
        allocations[alloIdx].allocation ===
        allocations[parseInt(alloIdx) - 1].allocation
      ) {
        tempStorage.push([
          allocations[alloIdx].name,
          allocations[alloIdx].index,
          allocations[alloIdx].allocation,
        ]);
      } else {
        groupedAllocations.push(tempStorage);
        tempStorage = [];
        tempStorage.push([
          allocations[alloIdx].name,
          allocations[alloIdx].index,
          allocations[alloIdx].allocation,
        ]);
      }
    }
  }
  groupedAllocations = groupedAllocations.filter((item) => item.length !== 0);
  return groupedAllocations;
}

/**
 * @param {*} employees [{ Days: [String], DaysRecurring: [String], id: String, name: String}]
 * @param {*} allocationField "Days" or "Allocations"
 * @returns - overview data in index form
 */
export const convertEmployeeOverviewDataToIndexForm = (
  employees,
  allocationField
) => {
  const allAllocations = employees.map((emp) => {
    return { allocations: emp[allocationField], name: emp.name };
  });
  const allAllocationsWithIdxData = [];

  for (const employeeAllocations of allAllocations) {
    const allocationsWithIndex = employeeAllocations.allocations.map(
      (allocation, index) => {
        return { index, allocation, name: employeeAllocations.name };
      }
    );
    allAllocationsWithIdxData.push(allocationsWithIndex);
  }

  return allAllocationsWithIdxData;
};

const inferSingleShiftOrGroupDemands = (
  demands,
  numDays,
  rosterStartDate,
  locationStartDate,
  locationDefaultNumDays
) => {
  const inferableShiftDemands = [];
  for (const demand of demands) {
    const shifts = strToArrCommaSeparated(demand.shifts);
    const skills = strToArrCommaSeparated(demand.skills);
    if (shifts.length === 1 && skills.length <= 1 && demand.tasks === "") {
      inferableShiftDemands.push({
        shiftShortId: shifts[0],
        skillShortId: skills[0],
        values: generatePatternOutput(
          demand.values,
          rosterStartDate,
          numDays,
          locationStartDate,
          locationDefaultNumDays
        ),
        type: demand.type,
        demandStartTime: demand.startTime,
        demandFinishTime: demand.finishTime,
      });
    }
  }
  return inferableShiftDemands;
};

export const inferredShiftDemandsToShiftDemandsInfo = (
  shifts,
  shiftGroups,
  skills,
  demands,
  numDays,
  rosterStartDate,
  locationStartDate,
  locationDefaultNumDays
) => {
  const inferredSingleShiftDemands = inferSingleShiftOrGroupDemands(
    demands,
    numDays,
    rosterStartDate,
    locationStartDate,
    locationDefaultNumDays
  );

  const demandsInfo = inferredSingleShiftDemands.map((item) => {
    const entityShortId = item.shiftShortId;
    const shift = shifts.find((s) => s.shortId === entityShortId);
    const shiftGroup = shiftGroups.find((s) => s.shortId === entityShortId);
    const skill = skills.find((s) => s.shortId === item.skillShortId);
    if (shift) {
      return {
        shiftID: shift.id,
        skillID: skill ? skill.id : null,
        shiftStartTime: shift.startTime,
        shiftFinishTime: shift.finishTime,
        ...item,
      };
    } else if (shiftGroup) {
      return {
        shiftID: shiftGroup.id,
        skillID: skill ? skill.id : null,
        shiftStartTime: null,
        shiftFinishTime: null,
        ...item,
      };
    }
    return null;
  });

  return demandsInfo.filter((item) => item !== null);
};

export const getRosterTableBottomPinnedRowsPlaceholders = (
  shifts,
  shiftGroups,
  skills,
  numDays,
  statistics
) => {
  const placeholderData = [];
  shifts.forEach((s) => {
    const singleRowData = {
      name: s.name,
      id: `shift-counts-${s.shortId}`,
      shortId: s.shortId,
    };
    for (let i = 1; i <= numDays; i++) {
      singleRowData[`d${i}`] = "";
    }
    placeholderData.push(singleRowData);
  });

  shiftGroups.forEach((s) => {
    const singleRowData = {
      name: s.name,
      id: `shift-counts-${s.shortId}`,
      shortId: s.shortId,
    };
    const shiftGroupHoursData = {
      name: `${s.name} (hours)`,
      id: `shift-hours-${s.shortId}`,
      shortId: s.shortId,
    };
    for (let i = 1; i <= numDays; i++) {
      singleRowData[`d${i}`] = "";
      shiftGroupHoursData[`d${i}`] = "";
    }
    placeholderData.push(singleRowData);
    placeholderData.push(shiftGroupHoursData);
  });

  const totalDayShiftCountsData = {
    name: "Total counts",
    id: "totalDayShiftCounts",
  };

  const totalDayShiftHoursData = {
    name: "Total hours",
    id: "totalDayShiftHours",
  };

  for (let i = 1; i <= numDays; i++) {
    totalDayShiftCountsData[`d${i}`] = "";
    totalDayShiftHoursData[`d${i}`] = "";
  }
  placeholderData.push(totalDayShiftCountsData);
  placeholderData.push(totalDayShiftHoursData);

  skills.forEach((s) => {
    const singleRowData = {
      name: s.name,
      id: `skill-${s.shortId}`,
      shortId: s.shortId,
    };
    for (let i = 1; i <= numDays; i++) {
      singleRowData[`d${i}`] = "";
    }
    placeholderData.push(singleRowData);
  });

  skills.forEach((s) => {
    [...shifts, ...shiftGroups].forEach((shiftOrShiftGroup) => {
      const singleRowData = {
        name: shiftOrShiftGroup.name + "(" + s.name + ")",
        id: `shift-${shiftOrShiftGroup.shortId}-skill-${s.shortId}`,
        shortId: `${shiftOrShiftGroup.shortId}(${s.shortId})`,
      };
      for (let i = 1; i <= numDays; i++) {
        singleRowData[`d${i}`] = "";
      }
      placeholderData.push(singleRowData);
    });
  });

  const headerRow = {
    id: "reserved_header",
    name: "",
    d1: "Total Counts of shifts by day",
  };

  const allRows = [headerRow, ...placeholderData];
  return filterRosterTableBottomPinnedRows(allRows, statistics);
};

const filterRosterTableBottomPinnedRows = (allRows, statistics) => {
  const filteredRows = allRows.filter((row) => {
    if (
      row.id === "reserved_header" &&
      (statistics.dayShiftCountsToShow.length > 0 ||
        statistics.daySkillCountsToShow.length > 0 ||
        statistics.dayShiftSkillCountsToShow.length > 0 ||
        statistics.dayHoursToShow.length > 0)
    ) {
      return true;
    }
    if (
      row.id === "totalDayShiftCounts" &&
      statistics.dayShiftCountsToShow.includes("overall")
    ) {
      return true;
    }
    if (
      row.id === "totalDayShiftHours" &&
      statistics.dayHoursToShow.includes("overall")
    ) {
      return true;
    }
    // THe row IDs are messed up. Should be more descriptive
    const toBeDisplayedShiftsIDs = statistics.dayShiftCountsToShow.map(
      (name) => `shift-counts-${name}`
    );
    const toBeDisplayedSkillsIDs = statistics.daySkillCountsToShow.map(
      (name) => `skill-${name}`
    );
    const toBeDisplayedShiftSkillIDs = statistics.dayShiftSkillCountsToShow.map(
      (pair) => `shift-${pair.shift}-skill-${pair.skill}`
    );
    const toBeDisplayedShiftGroupHoursIDs = statistics.dayHoursToShow.map(
      (name) => `shift-hours-${name}`
    );
    return (
      toBeDisplayedShiftsIDs.includes(row.id) ||
      toBeDisplayedSkillsIDs.includes(row.id) ||
      toBeDisplayedShiftSkillIDs.includes(row.id) ||
      toBeDisplayedShiftGroupHoursIDs.includes(row.id)
    );
  });

  return filteredRows;
};

// With given shifts, find start-finish time interval that overlaps with all the shifts.
// If such interval does not exist, return -1
export const findAllOverlapingShiftIntervals = (shifts) => {
  const shiftTimeIntervalsInNumberFormat = shifts
    .filter((s) => s)
    .map((s) => {
      let startTimeInNumber = DateTime.convertTimeToNumberRepresentation(
        s.startTime
      );
      let finishTimeInNumber = DateTime.convertTimeToNumberRepresentation(
        s.finishTime
      );
      return [startTimeInNumber, finishTimeInNumber];
    });

  const allOverallpingInterval = findIntersectionOfAllNumIntervals(
    shiftTimeIntervalsInNumberFormat
  );

  return allOverallpingInterval;
};

export const getRecurringEmployeeFieldLength = (employees, fieldName) => {
  for (const employee of employees) {
    const length = employee[fieldName].length;
    if (length === 14) {
      return 14;
    }
  }
  return 7;
};

export const changeDemandValuesToFullLength = (
  demandValues,
  numDays,
  locationStartDate,
  startDate,
  locationDefaultNumDays
) => {
  const wholeArray = generatePatternOutput(
    demandValues,
    startDate,
    numDays,
    locationStartDate,
    locationDefaultNumDays
  );

  return wholeArray;
};

export const changeDemandsLengthToSpecificPeriod = (
  demands,
  period,
  numDays = null,
  locationStartDate,
  rosterStartDate,
  locationDefaultNumDays
) => {
  const updatedDemands = deepCopyObject(demands);

  if (period === "week") {
    for (const demand of updatedDemands) {
      if (demand.values.length > 14) {
        for (const demand of updatedDemands) {
          demand.values = generatePatternOutputReverse(
            demand.values,
            rosterStartDate,
            7,
            locationStartDate,
            locationDefaultNumDays
          );
        }
      } else {
        const weeekArray = new Array(7).fill(1);
        for (let i = 0; i < 7; i++) {
          weeekArray[i] = demand.values[i];
        }
        demand.values = weeekArray;
      }
    }
    return updatedDemands;
  }

  if (period === "fortnight") {
    for (const demand of updatedDemands) {
      if (demand.values.length > 14) {
        demand.values = generatePatternOutputReverse(
          demand.values,
          rosterStartDate,
          14,
          locationStartDate,
          locationDefaultNumDays
        );
      } else {
        const fortnightArray = new Array(14).fill(1);

        for (let j = 0; j < 2; j++) {
          for (let i = 0; i < 7; i++) {
            fortnightArray[j * 7 + i] = demand.values[i];
          }
        }

        demand.values = fortnightArray;
      }
    }
  }

  if (period === "whole") {
    if (!numDays) {
      throw new Error(`numDays argument needed when setting period to "whole"`);
    }

    for (const demand of updatedDemands) {
      demand.values = generatePatternOutput(
        demand.values,
        rosterStartDate,
        numDays,
        locationStartDate,
        locationDefaultNumDays
      );
    }
  }

  return updatedDemands;
};

export const convertManageRequestValuesToAllocations = (
  requests,
  employeesData,
  field,
  rosterStartDate,
  rosterFinishDate
) => {
  const newEmployees = deepCopyObject(employeesData);

  if (field !== "Allocations" && field !== "Days") {
    throw new Error("field should be either Allocations or Days");
  }

  // delete all Allocations
  for (const emp of newEmployees) {
    emp[field] = Array(emp[field].length).fill("");
  }

  // Replace Allocations with the data in the request manager modal
  for (const request of requests) {
    if (
      !DateTime.isValidDateString(request.from) ||
      !DateTime.isValidDateString(request.to)
    ) {
      continue;
    }

    if (
      DateTime.getDifferenceInDays(
        new DateTime(rosterStartDate),
        new DateTime(request.from)
      ) < 0 ||
      DateTime.getDifferenceInDays(
        new DateTime(request.to),
        new DateTime(rosterFinishDate)
      ) < 0 ||
      DateTime.getDifferenceInDays(
        new DateTime(request.from),
        new DateTime(request.to)
      ) < 0
    ) {
      continue;
    }
    const startIdx = DateTime.getDifferenceInDays(
      new DateTime(rosterStartDate),
      new DateTime(request.from)
    );
    const endIdx = DateTime.getDifferenceInDays(
      new DateTime(rosterStartDate),
      new DateTime(request.to)
    );
    const targetEmployee = newEmployees.find(
      (item) => item.name === request.employeeName
    );
    if (targetEmployee) {
      for (let i = startIdx; i <= endIdx; i++) {
        targetEmployee[field][i] = request.shiftAndShiftGroup;
      }
    }
  }

  return newEmployees;
};

export function getShiftsTasksCombo(
  shiftNames,
  taskNames,
  skippedShiftNames = [],
  skippedTaskNames = []
) {
  const combo = [];
  for (const shiftName of shiftNames) {
    if (skippedShiftNames.includes(shiftName)) {
      continue;
    }
    for (const taskName of taskNames) {
      if (skippedTaskNames.includes(taskName)) {
        continue;
      }
      combo.push(`${shiftName}-${taskName}`);
    }
  }
  return combo;
}

export const getDemandsPeriodSelection = (numDemandValues) => {
  let periodSelection = "week";

  if (numDemandValues === 14) {
    periodSelection = "fortnight";
  } else if (numDemandValues === 7) {
    periodSelection = "week";
  } else if (numDemandValues > 14) {
    periodSelection = "whole";
  }

  return periodSelection;
};

export function checkAllocationIsLeave(leaveString, leaveCodes) {
  let leave = "";

  [...leaveCodes, { shortname: KEYWORD_NA }].forEach((leaveCode) => {
    if (
      leaveString === leaveCode.shortname ||
      getRosterSolutionAllocationRegex(leaveCode.shortname).test(leaveString)
    ) {
      leave = leaveCode.shortname;
    }
  });

  return leave;
}

const checkAllocationIsLeaveGlobal = (leaveString, leaveCodes) => {
  let leave = "";

  [...leaveCodes, { shortname: KEYWORD_NA }].forEach((leaveCode) => {
    if (
      leaveString === leaveCode.shortname ||
      getRosterSolutionAllocationRegex(leaveCode.shortname).test(leaveString)
    ) {
      leave = leaveCode.shortname;
    }
  });

  return leave;
};

export const checkAllocationIsNAOrLeave = (alloc, leaveCodes) => {
  if (
    alloc === KEYWORD_NA ||
    getRosterSolutionAllocationRegex(KEYWORD_NA).test(alloc)
  ) {
    return KEYWORD_NA;
  }
  const leaveAlloc = checkAllocationIsLeaveGlobal(alloc, leaveCodes);
  if (leaveAlloc) {
    return leaveAlloc;
  }
  return alloc;
};

export function getNextRosterHistoryFromPublishedShifts(
  employees,
  rosterFinishDate
) {
  const historyStartDate = new DateTime(rosterFinishDate)
    .subtractDays(13)
    .toFormat("AWS");

  const historyDates = DateTime.getAllDateStringsBetween(
    historyStartDate,
    rosterFinishDate,
    true,
    true
  );

  const employeesHistory = employees.map((employee) => {
    const updatedHistory = historyDates.map((date) => {
      const dateAllocation = employee.PublishedAllocations.find(
        (allocation) => allocation.date === date
      );
      if (dateAllocation && dateAllocation.publishedAllocation) {
        return dateAllocation.publishedAllocation;
      }
      return KEYWORD_OFF;
    });
    return {
      id: employee.id,
      name: employee.name,
      History: updatedHistory,
    };
  });
  return employeesHistory;
}

export const sortOpenShifts = (openShifts) => {
  openShifts.sort(function (a, b) {
    if (a.isPublished && !b.isPublished) {
      return 1;
    } else if (!a.isPublished && b.isPublished) {
      return -1;
    }

    if (a.displayedInfo.isAccepted && !b.displayedInfo.isAccepted) {
      return 1;
    } else if (!a.displayedInfo.isAccepted && b.displayedInfo.isAccepted) {
      return -1;
    }

    const { publishedNumber: publishedNumberA, numDeclined: numDeclinedA } =
      getOpenShiftStatusInfo(a);
    const { publishedNumber: publishedNumberB, numDeclined: numDeclinedB } =
      getOpenShiftStatusInfo(b);

    if (
      publishedNumberA === numDeclinedA &&
      publishedNumberB !== numDeclinedB
    ) {
      return 1;
    } else if (
      publishedNumberA !== numDeclinedA &&
      publishedNumberB === numDeclinedB
    ) {
      return -1;
    }

    return 0;
  });
};

export function doesEmployeeHaveLeaveOnDay(employee, date) {
  const requests = employee.Requests;

  for (const request of requests) {
    const startDate = request.startDate;
    const finishDate = request.finishDate;
    const includesRequestOnTheDay = new DateTime(date).isDateBetweenTwoDates(
      startDate,
      finishDate,
      true,
      true
    );
    if (
      includesRequestOnTheDay &&
      (request.state === "Approved" || request.state === "pending")
    ) {
      return true;
    }
  }
  return false;
}

export function checkAcceptedOpenShiftIsModified(
  prevEmployees,
  updatedEmplyees
) {
  for (const prevEmployee of prevEmployees) {
    const targetUpdatedEmployee = updatedEmplyees.find(
      (employee) => employee.id === prevEmployee.id
    );

    const openShiftAllocations = prevEmployee.PublishedAllocations.filter(
      (allocation) => allocation.isOpenShift
    );

    for (const openShiftAllocation of openShiftAllocations) {
      const { date } = openShiftAllocation;
      const updatedAllocationOnDate =
        targetUpdatedEmployee.PublishedAllocations.find(
          (allocation) => allocation.date === date
        );

      if (updatedAllocationOnDate.draftAllocation !== null) {
        return true;
      }
    }
  }
  return false;
}

export function getDatesWhereOpenShiftIsPendingAndPublishedAllocationExists(
  globalEmployees,
  openShifts
) {
  const globalEmployeesCopied = deepCopyObject(globalEmployees);
  const openShiftsCopied = deepCopyObject(openShifts);
  const employeesInfo = [];

  for (const employee of globalEmployeesCopied) {
    const pendingOpenShiftDates = openShiftsCopied
      .filter((openShift) => {
        const { employeeStates } = openShift;
        const targetEmployeeState = employeeStates.find(
          (state) => state.employeeID === employee.id
        );
        if (targetEmployeeState && targetEmployeeState.state === "pending") {
          return true;
        }
        return false;
      })
      .map((openShift) => openShift.date);

    const datesWithPublishedAllocations = employee.PublishedAllocations.filter(
      (allocation) => allocation.publishedAllocation
    ).map((allocation) => allocation.date);

    const datesWhereOpenShiftIsPendingAndPublishedAllocationExists =
      findCommonElements(datesWithPublishedAllocations, pendingOpenShiftDates);

    employeesInfo.push({
      employeeID: employee.id,
      name: employee.name,
      // dates where it has both pending open shift and published open shift
      conflictingDates:
        datesWhereOpenShiftIsPendingAndPublishedAllocationExists,
    });
  }

  return employeesInfo;
}

export function findShortestShift(shifts) {
  if (shifts.length === 0) {
    return null;
  }

  // Initialize variables to store the shortest duration and the corresponding shift
  let shortestDuration = Infinity;
  let shortestShift = null;

  // Loop through each shift in the array
  for (const shift of shifts) {
    // Parse the start and finish times as Date objects
    const startTime = new Date(`1970-01-01T${shift.startTime}`);
    const finishTime = new Date(`1970-01-01T${shift.finishTime}`);

    // Calculate the duration in milliseconds
    const duration = finishTime - startTime;

    // Check if this shift has a shorter duration than the current shortest
    if (duration < shortestDuration) {
      shortestDuration = duration;
      shortestShift = shift;
    }
  }

  return shortestShift;
}

export function getApprovedOrPendingRequestDates(requests) {
  const dates = [];
  const approvedOrPendingRequests = requests.filter((request) => {
    return request.state === "Pending" || request.state === "Approved";
  });

  approvedOrPendingRequests.forEach((request) => {
    const { startDate, finishDate } = request;
    const dateStrings = DateTime.getAllDateStringsBetween(
      startDate,
      finishDate,
      true,
      true
    );
    dates.push(...dateStrings);
  });

  return dates;
}

export const removeSuffixInPreferences = (preferences, suffix) => {
  return preferences.map((pref) => {
    if (pref.endsWith(suffix)) {
      return pref.substring(0, pref.length - 1);
    }
    return pref;
  });
};

export const removePendingSuffixedPreferencesFromEmployees = (employees) => {
  return employees.map((employee) => {
    const { Days, DaysRecurring } = employee;
    return {
      ...employee,
      Days: removeSuffixInPreferences(Days, "*"),
      DaysRecurring: removeSuffixInPreferences(DaysRecurring, "*"),
    };
  });
};

export const getPreferenceSuffix = (importanceLevel) => {
  switch (importanceLevel) {
    case "normal":
      return "";
    case "high":
      return "?";
    case "critical":
      return "!";
    default:
      return "";
  }
};

export const getShiftDurationString = (shift) => {
  if (!shift) return "";
  return getTimeDurationString(shift.startTime, shift.finishTime);
};

export const getTimeDurationString = (startTime, finishTime) => {
  const formatTime = (timeString) =>
    DateTime.getFormattedTime(timeString, "readable");
  return `${formatTime(startTime)} - ${formatTime(finishTime)}`;
};

export function isNightShift(shiftStartTime, shiftFinishTime) {
  let startTime = new Date("2011-04-20T" + shiftStartTime);
  let finishTime = new Date("2011-04-20T" + shiftFinishTime);
  let nightBoundary = new Date("2011-04-20T01:00:00.00");

  if (
    startTime <= nightBoundary ||
    (startTime > finishTime && finishTime > nightBoundary)
  ) {
    return true;
  }
  return false;
}

export function filterDeletedEntities(entities) {
  return entities.filter((entity) => !entity._deleted);
}

export function convertShortIDsToEntityNames(shortIds, entities) {
  const names = shortIds.map((shortId) => {
    const entity = entities.find((ent) => ent.shortId === shortId);
    if (entity) {
      return entity.name;
    }
    return shortId;
  });

  return names;
}

export function getUnexistingRosterEmployeesToCreate(
  roster,
  globalEmployees,
  periodStartDate,
  periodFinishDate
) {
  const numDays = roster.numDays;
  const globalEmployeesWithinPeriod = getGlobalEmployeesWithinPeriod(
    globalEmployees,
    periodStartDate,
    periodFinishDate
  );
  const rosterEmployees = roster.Employees;
  const rosterEmployeeIds = getIds(rosterEmployees);

  const rosterEmployeesToCreate = globalEmployeesWithinPeriod
    .filter((globalEmployee) => !rosterEmployeeIds.includes(globalEmployee.id))
    .map((globalEmployee) => {
      return {
        id: globalEmployee.id,
        globalEmployeeID: globalEmployee.id,
        Days: Array(numDays).fill(""),
        DaysRecurring: null,
        Allocations: Array(numDays).fill(""),
        AllocationsRecurring: null,
        History: Array(14).fill(""),
        RuleValues: null,
        RosteredAllocations: null,
        name: globalEmployee.name,
        skills: null,
        shifts: null,
        externalID: null,
      };
    });
  return rosterEmployeesToCreate;
}

export function getEntityNameByShortId(entities, shortId) {
  const targetEntity = entities.find((entity) => entity.shortId === shortId);
  if (targetEntity) {
    return targetEntity.name;
  }
  return null;
}

/**
 * Only send notification when
 *  Allocation before and allocation after is "displayed differently"
 */
export function getChangedPublishedAllocationsToNotify(
  previousEmployees,
  updatedEmployees,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) {
  const { annualLeaveKeyword, studyKeyword } = customKeywordsUtilObj;
  const changedEmployeesPublishedAllocations = [];
  for (const previousEmployee of previousEmployees) {
    const updatedEmployee = updatedEmployees.find(
      ({ id }) => id === previousEmployee.id
    );
    if (!updatedEmployee) {
      continue;
    }

    const changedAllocations = [];
    const previousPublishedAllocations = previousEmployee.PublishedAllocations;
    const updatedPublishedAllocations = updatedEmployee.PublishedAllocations;

    for (const prevAllocation of previousPublishedAllocations) {
      const updatedAllocationOnSameDay = updatedPublishedAllocations.find(
        ({ date }) => date === prevAllocation.date
      );

      const prevPublishedAllocation = prevAllocation.publishedAllocation;
      const updatedPublishedAllocation =
        updatedAllocationOnSameDay.publishedAllocation;

      // not applicable allocation is shown blank in employee pap
      if (!prevPublishedAllocation || prevPublishedAllocation === KEYWORD_NA) {
        if (
          !updatedPublishedAllocation ||
          updatedPublishedAllocation === KEYWORD_NA
        ) {
          continue;
        }
      }

      /**
       * Q: Why do we skip when prev published allocation is "AL" and updated published allocation is ""?
       * A: When you add "AL" manually to the My Roster table, it is saved as "AL" for the cell value.
       * If there is already a AL Request on that day, it turns the saved "AL" into "" when you publish.
       */
      if (
        [annualLeaveKeyword, studyKeyword].includes(prevPublishedAllocation) &&
        updatedPublishedAllocation === ""
      ) {
        continue;
      }

      if (prevPublishedAllocation !== updatedPublishedAllocation) {
        changedAllocations.push({
          date: updatedAllocationOnSameDay.date,
          updatedPublishedAllocationShortId: updatedPublishedAllocation,
          previousPublishedAllocationShortId: prevPublishedAllocation,
          updatedPublishedAllocationName:
            convertAllocationInShortIdFormToNameForm(
              updatedPublishedAllocation,
              shortIdsToEntityNamesDicts
            ),
          previousPublishedAllocationName:
            convertAllocationInShortIdFormToNameForm(
              prevPublishedAllocation,
              shortIdsToEntityNamesDicts
            ),
        });
      }
    }

    if (changedAllocations.length > 0) {
      changedEmployeesPublishedAllocations.push({
        id: updatedEmployee.id,
        name: updatedEmployee.name,
        changedAllocations,
      });
    }
  }

  return changedEmployeesPublishedAllocations;
}

// Check if a period has never been published before
export function checkHasNeverBeenPublished(
  globalEmployees,
  periodStartDate,
  periodFinishDate
) {
  let hasNeverBeenPublished = true;
  for (const employee of globalEmployees) {
    const employeeHasBeenPublished = employee.PublishedAllocations.some(
      ({ publishedAllocation, date }) =>
        new DateTime(date).isDateBetweenTwoDates(
          periodStartDate,
          periodFinishDate,
          true,
          true
        ) && publishedAllocation
    );

    if (employeeHasBeenPublished) {
      hasNeverBeenPublished = false;
      break;
    }
  }

  return hasNeverBeenPublished;
}

export function filterShiftGroupsByAreas(areaFilter, shiftGroups) {
  if (areaFilter.length === 0 || areaFilter.includes(KEYWORD_ALL)) {
    return shiftGroups;
  }

  return shiftGroups.filter((shiftGroup) => {
    const shiftAreas = strToArrCommaSeparated(shiftGroup.areas);
    return (
      shiftAreas.length === 0 ||
      shiftAreas.some((area) => areaFilter.includes(area))
    );
  });
}

export function filterShiftsByAreas(areaFilter, shifts, shiftGroups) {
  if (areaFilter.length === 0 || areaFilter.includes(KEYWORD_ALL)) {
    return shifts;
  }

  const shiftIdsInShiftGroups = [];

  for (const shiftGroup of shiftGroups) {
    const shiftsInShiftGroups = strToArrCommaSeparated(
      shiftGroup.shifts
    ).filter(({ shortId }) => shortId !== KEYWORD_OFF);
    shiftIdsInShiftGroups.push(...shiftsInShiftGroups);
  }

  const areaFilteredShiftGroups = filterShiftGroupsByAreas(
    areaFilter,
    shiftGroups
  );

  const shiftsInAreaFilteredShiftGroups = shifts
    .filter(({ shortId }) => !shiftIdsInShiftGroups.includes(shortId))
    .map(({ shortId }) => shortId);

  for (const shiftGroup of areaFilteredShiftGroups) {
    const shiftIds = strToArrCommaSeparated(shiftGroup.shifts);
    shiftsInAreaFilteredShiftGroups.push(...shiftIds);
  }

  return shifts.filter((shift) =>
    shiftsInAreaFilteredShiftGroups.includes(shift.shortId)
  );
}
