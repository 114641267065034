import { getAllocationRegexNoArea } from "../generalUtils/regex";
import { splitAllocationSuffix } from "./generalModelUtil";
/**
 * FILE DESCRIPTION: This file contains function that interprets allocations data
 */

function createExtractedTemplate(shouldUseName = false) {
  const fieldType = shouldUseName ? "Name" : "ShortId";
  return {
    [`area${fieldType}`]: null,
    [`shift${fieldType}`]: null,
    [`shiftGroup${fieldType}`]: null,
    [`task${fieldType}`]: null,
    [`enumeratedTask${fieldType}`]: null,
    [`skill${fieldType}`]: null,
    suffix: null,
    [`area${fieldType}MapsToEntity`]: false,
    [`shift${fieldType}MapsToEntity`]: false,
    [`shiftGroup${fieldType}MapsToEntity`]: false,
    [`task${fieldType}MapsToEntity`]: false,
    [`enumeratedTask${fieldType}MapsToEntity`]: false,
    [`skill${fieldType}MapsToEntity`]: false,
  };
}

/**
 * This function extracts areaShortId, shiftShortId, shiftGroupShortId, taskShortId, enumeratedTaskShortId, suffix from an allocation based on the format of the allocation.
 * If the extracted shortId is from an existing entity, the corresponding boolean flag (e.g. areaShortIdMapsToEntity) will be set to true.
 * If the 'task' part of the allocation is a single task, it will be extracted as `taskShortId`. But if it is an enumerated task, it will be extracted as `enumeratedTaskShortId`.
 */
export function extractEntityShortIdsFromAllocation(
  allocationInShortIdForm,
  shortIdsToEntityNamesDicts
) {
  const extracted = createExtractedTemplate(false);

  if (!allocationInShortIdForm) {
    return extracted;
  }

  const {
    areaNamesDict,
    shiftNamesDict,
    shiftGroupNamesDict,
    taskNamesDict,
    enumeratedTaskNamesDict,
    skillNamesDict,
  } = shortIdsToEntityNamesDicts;

  const splitted = splitAllocationSuffix(allocationInShortIdForm);
  let allocation = splitted[0];
  const suffix = splitted[1];

  if (allocation in skillNamesDict) {
    extracted.skillShortId = allocation;
    extracted.skillShortIdMapsToEntity = true;
  }

  if (suffix) {
    extracted.suffix = suffix;
  }

  if (!allocation) {
    return extracted;
  }

  if (allocation in areaNamesDict) {
    extracted.areaShortId = allocation;
    extracted.areaShortIdMapsToEntity = true;
    return extracted;
  }

  const [area, rest] = allocation.split(":");
  if (rest) {
    // "area" part of string AND the "rest" of string exists in the allocation
    extracted.areaShortId = area;
    allocation = rest;
    if (area in areaNamesDict) {
      extracted.areaShortIdMapsToEntity = true;
    }
  }

  if (allocation in shiftNamesDict) {
    extracted.shiftShortId = allocation;
    extracted.shiftShortIdMapsToEntity = true;
  }

  if (allocation in shiftGroupNamesDict) {
    extracted.shiftGroupShortId = allocation;
    extracted.shiftGroupShortIdMapsToEntity = true;
  }

  if (allocation in taskNamesDict) {
    extracted.taskShortId = allocation;
    extracted.taskShortIdMapsToEntity = true;
  }

  if (allocation in enumeratedTaskNamesDict) {
    extracted.enumeratedTaskShortId = allocation;
    extracted.enumeratedTaskShortIdMapsToEntity = true;
  }

  const allocationRegexWithoutArea = getAllocationRegexNoArea();
  if (allocationRegexWithoutArea.test(allocation)) {
    const match = allocation.match(allocationRegexWithoutArea);
    const shift = match[1];
    const task = match[2];

    extracted.shiftShortId = shift;
    if (shift in shiftNamesDict) {
      extracted.shiftShortIdMapsToEntity = true;
    }

    if (task in taskNamesDict) {
      extracted.taskShortId = task;
      extracted.taskShortIdMapsToEntity = true;
    } else if (task in enumeratedTaskNamesDict) {
      extracted.enumeratedTaskShortId = task;
      extracted.enumeratedTaskShortIdMapsToEntity = true;
    } else {
      extracted.taskShortId = task;
    }
  }

  // If there is an allocation that does not fulfil any of the entity, regard it as a shift shortId
  if (
    !extracted.shiftShortId &&
    !extracted.shiftGroupShortId &&
    !extracted.taskShortId &&
    !extracted.enumeratedTaskShortId &&
    !extracted.shiftShortIdMapsToEntity &&
    !extracted.shiftGroupShortIdMapsToEntity &&
    !extracted.taskShortIdMapsToEntity &&
    !extracted.enumeratedTaskShortIdMapsToEntity &&
    allocation
  ) {
    extracted.shiftShortId = allocation;
  }

  return extracted;
}

export function extractEntityNamesFromAllocation(
  allocationInNameForm,
  namesToEntityShortIdsDicts
) {
  const extracted = createExtractedTemplate(true);
  if (!allocationInNameForm) {
    return extracted;
  }

  const {
    areaNamesDict,
    shiftNamesDict,
    shiftGroupNamesDict,
    taskNamesDict,
    enumeratedTaskNamesDict,
    skillNamesDict,
  } = namesToEntityShortIdsDicts;

  const splitted = splitAllocationSuffix(allocationInNameForm);
  let allocation = splitted[0];

  if (allocation in skillNamesDict) {
    extracted.skillName = allocation;
    extracted.skillNameMapsToEntity = true;
  }

  const suffix = splitted[1];

  if (suffix) {
    extracted.suffix = suffix;
  }

  if (!allocation) {
    return extracted;
  }

  if (allocation in areaNamesDict) {
    extracted.areaName = allocation;
    extracted.areaNameMapsToEntity = true;
    return extracted;
  }

  const [area, rest] = allocation.split(":");

  if (rest) {
    extracted.areaName = area;
    allocation = rest;
    if (area in areaNamesDict) {
      extracted.areaNameMapsToEntity = true;
    }
  }

  if (allocation in shiftNamesDict) {
    extracted.shiftName = allocation;
    extracted.shiftNameMapsToEntity = true;
  }

  if (allocation in shiftGroupNamesDict) {
    extracted.shiftGroupName = allocation;
    extracted.shiftGroupNameMapsToEntity = true;
  }

  if (allocation in taskNamesDict) {
    extracted.taskName = allocation;
    extracted.taskNameMapsToEntity = true;
  }

  if (allocation in enumeratedTaskNamesDict) {
    extracted.enumeratedTaskName = allocation;
    extracted.enumeratedTaskNameMapsToEntity = true;
  }

  const allocationRegexWithoutArea = getAllocationRegexNoArea();
  if (allocationRegexWithoutArea.test(allocation)) {
    const match = allocation.match(allocationRegexWithoutArea);
    const shift = match[1];
    const task = match[2];

    extracted.shiftName = shift;
    if (shift in shiftNamesDict) {
      extracted.shiftNameMapsToEntity = true;
    }

    if (task in taskNamesDict) {
      extracted.taskName = task;
      extracted.taskNameMapsToEntity = true;
    } else if (task in enumeratedTaskNamesDict) {
      extracted.enumeratedTaskName = task;
      extracted.enumeratedTaskNameMapsToEntity = true;
    } else {
      extracted.taskName = task;
    }
  }

  // If there is an allocation that does not fulfil any of the entity, regard it as a shift name
  if (
    !extracted.shiftName &&
    !extracted.shiftGroupName &&
    !extracted.taskName &&
    !extracted.enumeratedTaskName &&
    !extracted.shiftNameMapsToEntity &&
    !extracted.shiftGroupNameMapsToEntity &&
    !extracted.taskNameMapsToEntity &&
    !extracted.enumeratedTaskNameMapsToEntity &&
    allocation
  ) {
    extracted.shiftName = allocation;
  }

  return extracted;
}

export function toAllocationString(
  areaShortId = null,
  shiftShortId = null,
  taskShortId = null,
  enumeratedTaskShortId = null
) {
  let allocation = "";
  if (areaShortId) {
    allocation = `${areaShortId}`;
    if (shiftShortId || taskShortId || enumeratedTaskShortId) {
      allocation = `${allocation}:`;
    }
  }
  if (shiftShortId) {
    allocation = `${allocation}${shiftShortId}`;
  }
  if (taskShortId) {
    allocation = `${allocation}${shiftShortId ? "-" : ""}${taskShortId}`;
  }
  if (enumeratedTaskShortId) {
    allocation = `${allocation}${
      shiftShortId ? "-" : ""
    }${enumeratedTaskShortId}`;
  }
  return allocation;
}

export function toFullAllocationString(
  areaName = null,
  allocation = null,
  suffix = null
) {
  let fullAllocation = allocation ? allocation : "";

  if (areaName) {
    if (allocation) {
      fullAllocation = `${areaName}:${allocation}`;
    } else {
      fullAllocation = areaName;
    }
  }
  if (suffix) {
    fullAllocation = `${fullAllocation}${suffix}`;
  }
  return fullAllocation;
}

export function convertAllocationInNameFormToShortIdForm(
  allocationInNameForm,
  namesToEntityShortIdsDicts
) {
  if (!allocationInNameForm) return "";

  const {
    areaNamesDict,
    shiftNamesDict,
    shiftGroupNamesDict,
    taskNamesDict,
    enumeratedTaskNamesDict,
    skillNamesDict,
  } = namesToEntityShortIdsDicts;

  // slit area with the rest of the allocation
  const splitted = splitAllocationSuffix(allocationInNameForm);
  let allocation = splitted[0];
  const suffix = splitted[1];

  if (allocation in areaNamesDict) {
    const areaName = areaNamesDict[allocation];
    return toFullAllocationString(areaName, null, suffix);
  }

  const [area, rest] = allocation.split(":");
  let areaShortId = null;
  if (area in areaNamesDict) {
    allocation = rest;
    areaShortId = areaNamesDict[area];
  }

  // Check if allocation (without area) represents single entity
  if (allocation in shiftNamesDict) {
    const shiftShortId = shiftNamesDict[allocation];
    return toFullAllocationString(areaShortId, shiftShortId, suffix);
  }

  if (allocation in shiftGroupNamesDict) {
    const shiftGroupShortId = shiftGroupNamesDict[allocation];
    return toFullAllocationString(areaShortId, shiftGroupShortId, suffix);
  }

  if (allocation in taskNamesDict) {
    const taskShortId = taskNamesDict[allocation];
    return toFullAllocationString(areaShortId, taskShortId, suffix);
  }

  if (allocation in enumeratedTaskNamesDict) {
    const enumeratedTaskShortId = enumeratedTaskNamesDict[allocation].shortId;
    return toFullAllocationString(areaShortId, enumeratedTaskShortId, suffix);
  }

  if (allocation in skillNamesDict) {
    return skillNamesDict[allocation];
  }

  // The allocation is in `${shift}-${taskInEnumeration}` format
  const allocationRegexWithoutArea = getAllocationRegexNoArea();
  if (allocationRegexWithoutArea.test(allocation)) {
    const match = allocation.match(allocationRegexWithoutArea);
    const shiftName = match[1];
    const taskName = match[2];

    const shiftShortId = shiftNamesDict[shiftName];
    const enumeratedTaskShortId = enumeratedTaskNamesDict[taskName]
      ? enumeratedTaskNamesDict[taskName].shortId
      : taskNamesDict[taskName];

    if (shiftShortId && enumeratedTaskShortId) {
      return toFullAllocationString(
        areaShortId,
        `${shiftShortId}-${enumeratedTaskShortId}`,
        suffix
      );
    }
  }

  // unknown allocation
  return allocationInNameForm;
}

export function convertAllocationInShortIdFormToNameForm(
  allocationInShortIdForm,
  shortIdsToEntityNamesDicts
) {
  if (!allocationInShortIdForm) return "";

  const {
    areaNamesDict,
    shiftNamesDict,
    shiftGroupNamesDict,
    taskNamesDict,
    enumeratedTaskNamesDict,
    skillNamesDict,
  } = shortIdsToEntityNamesDicts;

  const {
    areaShortId,
    shiftShortId,
    shiftGroupShortId,
    taskShortId,
    enumeratedTaskShortId,
    suffix,
  } = extractEntityShortIdsFromAllocation(
    allocationInShortIdForm,
    shortIdsToEntityNamesDicts
  );

  if (allocationInShortIdForm in skillNamesDict) {
    return skillNamesDict[allocationInShortIdForm];
  }

  const areaName = areaNamesDict[areaShortId] || areaShortId;
  const shiftName = shiftNamesDict[shiftShortId] || shiftShortId;
  const shiftGroupName =
    shiftGroupNamesDict[shiftGroupShortId] || shiftGroupShortId;
  const taskName = taskNamesDict[taskShortId] || taskShortId;
  const enumeratedTaskName =
    enumeratedTaskNamesDict[enumeratedTaskShortId]?.name ??
    enumeratedTaskShortId;

  let areaSectionOfName = "";
  let shiftAndGroupSectionOfName = "";
  let tasksSectionOfName = "";

  if (areaName) areaSectionOfName = areaName;
  if (shiftName) shiftAndGroupSectionOfName = shiftName;
  if (shiftGroupName) shiftAndGroupSectionOfName = shiftGroupName;
  if (taskName) tasksSectionOfName = taskName;
  if (enumeratedTaskName) tasksSectionOfName = enumeratedTaskName;

  // Combine sections to form an allocation
  let allocation = "";

  if (shiftAndGroupSectionOfName) {
    allocation = shiftAndGroupSectionOfName;
  }

  if (tasksSectionOfName) {
    allocation = allocation
      ? `${allocation}-${tasksSectionOfName}`
      : tasksSectionOfName;
  }

  if (areaSectionOfName) {
    allocation = allocation
      ? `${areaSectionOfName}:${allocation}`
      : areaSectionOfName;
  }

  if (suffix) {
    allocation = `${allocation}${suffix}`;
  }

  return allocation;
}

export function isEntityShortIdInAllocation(
  entityShortId,
  allocationInShortIdForm,
  shortIdsToEntityNamesDicts
) {
  if (!entityShortId || !allocationInShortIdForm) {
    return false;
  }

  const {
    areaShortId,
    shiftShortId,
    shiftGroupShortId,
    taskShortId,
    enumeratedTaskShortId,
  } = extractEntityShortIdsFromAllocation(
    allocationInShortIdForm,
    shortIdsToEntityNamesDicts
  );

  if (
    [areaShortId, shiftShortId, shiftGroupShortId, taskShortId].includes(
      entityShortId
    )
  ) {
    return true;
  }

  if (enumeratedTaskShortId && enumeratedTaskShortId.includes(entityShortId)) {
    return true;
  }

  return false;
}

export function removeAreaInAllocation(
  allocationInShortIdForm,
  shortIdsToEntityNamesDicts,
  shouldReturnInNameForm = false
) {
  const { areaShortIdMapsToEntity } = extractEntityShortIdsFromAllocation(
    allocationInShortIdForm,
    shortIdsToEntityNamesDicts
  );

  let shortIdForm = allocationInShortIdForm;

  if (areaShortIdMapsToEntity) {
    shortIdForm = allocationInShortIdForm.split(":")[1] || "";
  }

  if (shouldReturnInNameForm) {
    return convertAllocationInShortIdFormToNameForm(
      shortIdForm,
      shortIdsToEntityNamesDicts
    );
  }

  return shortIdForm;
}
