import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_CUSTOM_KEYWORDS,
  getDefaultDisplayedStatistics,
  getDefaultFrontendSettings,
} from "../../constants";
import { DateTime } from "../dataTypesUtils/DateTime";
import { createRosterForScheduleView } from "../../features/scheduleView/service/scheduleViewRoster";
import { sortByDateField } from "../generalUtils/sort";
import { addDays, isWithinInterval, parseISO } from "date-fns";
import { getUserPlan } from "../../globalStore/appStore";
import { convertScheduleEmployeeToMainStreamModels } from "./updateData";
import { KEYWORD_OFF, KEYWORD_ON } from "../../constants/keywords";

const getOnShiftGroup = () => {
  return {
    id: uuidv4(),
    name: KEYWORD_ON,
    shifts: KEYWORD_OFF,
    inversed: true,
    tasks: "",
    skills: "",
    skillsInversed: false,
    adminUseOnly: false,
    shortId: KEYWORD_ON,
    areas: "",
  };
};

export function getNewPrototypeLocation({ name, plan }) {
  return {
    id: uuidv4(),
    name,
    defaultNumDays: null,
    settings: [],
    frontendSettings: getDefaultFrontendSettings(plan),
    Demands: [],
    Areas: [],
    Shifts: [],
    ShiftGroups: [getOnShiftGroup()],
    CustomRules: [],
    Skills: [],
    Tasks: [],
    TaskBlocks: [],
    order: [],
    ColorCodes: null,
    startDate: null,
    isScheduleView: false,
    Statistics: getDefaultDisplayedStatistics(),
    plan: getUserPlan() || null,
    shiftViewHiddenRows: [],
    _version: 1,
  };
}

export function getNewScheduleViewLocation({
  name,
  startDate,
  defaultNumDays,
  plan,
  fields = {},
}) {
  const id = uuidv4();
  return {
    id,
    name,
    defaultNumDays,
    settings: [],
    frontendSettings: getDefaultFrontendSettings(plan),
    Demands: [],
    Shifts: [],
    ShiftGroups: [getOnShiftGroup()],
    CustomRules: [],
    Skills: [],
    Tasks: [],
    TaskBlocks: [],
    Areas: [],
    order: [],
    ColorCodes: [],
    OpenShifts: [],
    startDate,
    isScheduleView: true,
    Statistics: getDefaultDisplayedStatistics(),
    completedOnboardingTasks: [],
    plan: getUserPlan() || null,
    shiftViewHiddenRows: [],
    _version: 1,
    ...fields,
  };
}

export function getUploadedScheduleData(
  locationName,
  uploadedScheduleData,
  rosterStartDateThatIncludesToday,
  plan
) {
  const {
    Employees: uploadedEmployees,
    Demands: uploadedDemands,
    Shifts: uploadedShifts,
    ShiftGroups: uploadedShiftGroups,
    CustomRules: uploadedRules,
    Skills: uploadedSkills,
    Tasks: uploadedTasks,
    TaskBlocks: uploadedTaskBlocks,
    Areas: uploadedAreas,
    Statistics: uploadedStatistics,
    ColorCodes: uploadedColorCodes,
    numDays: uploadedNumDays,
    startDate: uploadedStartDate,
    locationType: locationTypeOfUploadedRoster,
    frontendSettings: uploadedFrontendSettings,
    settings: uploadedSettings,
  } = uploadedScheduleData;

  // 1. Get location to be created
  const locationInfo = {
    id: uuidv4(),
    name: locationName,
    defaultNumDays: uploadedNumDays % 7 == 0 ? uploadedNumDays : -1, // Kind of a hack. if you're uploading Feburary might not work
    settings: uploadedSettings || [],
    frontendSettings:
      uploadedFrontendSettings || getDefaultFrontendSettings(plan),
    Demands: uploadedDemands.map((demand) => ({
      ...demand,
      id: uuidv4(),
    })),
    Shifts: uploadedShifts.map((shift) => ({
      ...shift,
      id: uuidv4(),
    })),
    ShiftGroups: uploadedShiftGroups.map((group) => ({
      ...group,
      id: uuidv4(),
    })),
    CustomRules: uploadedRules,
    Skills: uploadedSkills.map((skill) => ({ ...skill, id: uuidv4() })),
    Tasks: uploadedTasks.map((task) => ({ ...task, id: uuidv4() })),
    TaskBlocks: uploadedTaskBlocks.map((taskBlock) => ({
      ...taskBlock,
      id: uuidv4(),
    })),
    Areas: uploadedAreas.map((area) => ({ ...area, id: uuidv4() })),
    order: [],
    ColorCodes: uploadedColorCodes,
    startDate: uploadedStartDate,
    isScheduleView: true,
    Statistics: uploadedStatistics,
    completedOnboardingTasks: [],
    _version: 1,
  };

  // 2. Get GlobalEmployeesInfo
  const globalEmployeesInfo = [];
  const toBeFilteredRosterEmployees = [];
  for (const employee of uploadedEmployees) {
    let publishedAllocations = [];
    if (locationTypeOfUploadedRoster === "prototype") {
      const rosteredAllocations = employee.RosteredAllocations;
      for (const alloIdx in rosteredAllocations) {
        if (rosteredAllocations[alloIdx]) {
          const date = new DateTime(uploadedStartDate).addDays(
            parseInt(alloIdx)
          );

          publishedAllocations.push({
            date: date.toFormat("AWS"),
            draftAllocation: rosteredAllocations[alloIdx],
            publishedAllocation: null,
            isOpenShift: false,
            note: null,
          });
        }
      }
    } else {
      publishedAllocations = employee.PublishedAllocations.filter((allo) => {
        const allocationDate = new DateTime(allo.date);
        if (
          allocationDate.isSameOrAfter(uploadedStartDate) &&
          allocationDate.isSameOrBefore(
            new DateTime(uploadedStartDate).addDays(uploadedNumDays - 1)
          )
        ) {
          return true;
        }
        return false;
      }).map((allo) => {
        const { date, draftAllocation, publishedAllocation } = allo;
        let draft = "";

        if (draftAllocation !== null) {
          draft = draftAllocation;
        } else if (publishedAllocation) {
          draft = publishedAllocation;
        }

        return {
          date,
          draftAllocation: draft,
          publishedAllocation: null,
          isOpenShift: false,
          note: null,
        };
      });
    }

    const employeeData = {
      id: uuidv4(),
      email: employee.email,
      name: employee.name,
      locationID: locationInfo.id,
      locationName,
      ruleValues: employee.RuleValues,
      skills: employee.skills,
      shifts: employee.shifts,
      areas: employee.areas,
      Requests: employee.Requests.map((request) => ({
        ...request,
        globalEmployeeID: undefined,
      })),
      Preferences: employee.Preferences,
      PreferencesRecurring: employee.PreferencesRecurring,
      PublishedAllocations: publishedAllocations,
      DaysRecurring: employee.DaysRecurring,
      AllocationsRecurring: employee.AllocationsRecurring,
      TimeEntries: employee.TimeEntries,
      FTE: employee.FTE,
      salary: employee.salary,
      startDate: employee.startDate,
      finishDate: employee.finishDate,
      registrations: [],
      _version: 1,
    };
    globalEmployeesInfo.push(employeeData);
    toBeFilteredRosterEmployees.push({
      globalEmployeeID: employeeData.id,
      ...employee,
    });
  }

  locationInfo.order = globalEmployeesInfo.map((emp) => emp.id);

  // Convert uploadedStartDate to a Date object
  let start = parseISO(uploadedStartDate);

  // Calculate the finish date
  let finish = addDays(start, uploadedNumDays);

  // 3. Create Rosters
  const rosterEmployees = toBeFilteredRosterEmployees
    .filter((employee) => {
      let employeeStart = parseISO(employee.startDate);
      let employeeFinish = parseISO(employee.finishDate);

      // Check if the employee's start and finish dates are within the uploaded date range
      return (
        isWithinInterval(start, {
          start: employeeStart,
          end: employeeFinish,
        }) ||
        isWithinInterval(finish, { start: employeeStart, end: employeeFinish })
      );
    })
    .map((employee) => {
      const { rosterEmployee } =
        convertScheduleEmployeeToMainStreamModels(employee);
      return {
        ...rosterEmployee,
        id: employee.globalEmployeeID,
      };
    });

  const rostersInfo = [];
  if (
    new DateTime(rosterStartDateThatIncludesToday).isBefore(
      uploadedStartDate
    ) ||
    new DateTime(rosterStartDateThatIncludesToday).isAfter(uploadedStartDate)
  ) {
    const rosterInfo = createRosterForScheduleView(
      locationInfo.id,
      uploadedNumDays,
      rosterStartDateThatIncludesToday,
      globalEmployeesInfo,
      DEFAULT_CUSTOM_KEYWORDS.annualLeave
    );
    rostersInfo.push({
      ...rosterInfo,
      id: uuidv4(),
      isSnapshot: false,
    });
  }
  const newRosterInfo = {
    id: uuidv4(),
    locationID: locationInfo.id,
    name: null,
    numDays: uploadedNumDays,
    startDate: uploadedStartDate,
    Employees: rosterEmployees,
    Demands: null,
    Shifts: null,
    ShiftGroups: null,
    CustomRules: null,
    RuleExceptions: null,
    Skills: null,
    Tasks: null,
    TaskBlocks: null,
    Statistics: null,
    ColorCodes: null,
    isPublished: false,
    isSnapshot: false,
    snapshotStartDate: `F${uploadedStartDate}`,
  };
  rostersInfo.push(newRosterInfo);
  sortByDateField(rostersInfo, "startDate", true);

  return {
    locationInfo,
    globalEmployeesInfo,
    rostersInfo,
  };
}
