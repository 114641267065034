import { forwardRef } from "react";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { DateTime } from "../../../utils";
import styles from "./DatePicker.module.css";

const MIN_YEAR = 1970;
const MAX_YEAR = 2038;

export const DatePickerEditor = forwardRef(
  ({ customStyle = {}, ...props }, ref) => {
    const convertToDate = (value) => {
      let date;
      if (DateTime.isValidDateString(value)) {
        date = new DateTime(value);
      } else {
        date = new DateTime(null, false);
      }
      return date.toFormat("AWS");
    };

    const refInput = useRef(null);
    const originalDate = convertToDate(props.value);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          const date = refInput.current.value;

          if (!DateTime.isValidDateString(date)) {
            return originalDate;
          }

          const [year, month, day] = date.split("-").map(Number);

          let adjustedYear = year;
          if (year >= 0 && year <= 99) {
            adjustedYear = 2000 + year;
          }

          if (adjustedYear < MIN_YEAR) {
            adjustedYear = MIN_YEAR;
          }

          if (adjustedYear > MAX_YEAR) {
            adjustedYear = MAX_YEAR;
          }

          const formattedDateString = `${adjustedYear
            .toString()
            .padStart(4, "0")}-${month.toString().padStart(2, "0")}-${day
            .toString()
            .padStart(2, "0")}`;

          return formattedDateString;
        },
      };
    });

    return (
      <input
        style={{ height: "25px", ...customStyle }}
        type="date"
        defaultValue={originalDate}
        ref={refInput}
        className={styles["date-input"]}
      />
    );
  }
);

export const DatePickerRenderer = forwardRef((props, ref) => {
  const [date, setDate] = useState(props.value);
  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        setDate(params.value);
      },
    };
  });
  let displayedOutput;
  if (date === "") {
    displayedOutput = "Please enter date";
  } else {
    const dateObj = new DateTime(date);
    displayedOutput = dateObj.toFormat("displayed-full");
  }

  return <p>{displayedOutput}</p>;
});
