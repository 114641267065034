import { useMemo, useState } from "react";
import styles from "./DemandsGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import AddRowButton from "../../../../../components/elements/AddRowButton/AddRowButton";
import DropdownMultiSelector from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import GridCellWithPlaceholderText from "../../../../grid/components/GridCellWithPlaceholderText/GridCellWithPlaceholderText";
import {
  convertShortIdsCellValueToEntityNames,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  DateTime,
  defaultFilterValueGetter,
  dropdownOptionsValueSetter,
  finishTimeGetter,
  flatOptions,
  getAreaOptions,
  getDemandsDayColumns,
  getShiftAndShiftGroupOptions,
  onFilterTextBoxChanged,
  startTimeGetter,
  strToArrCommaSeparated,
  suppressEnterKey,
  timeSetter,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../../../globalStore/appStore";
import ShiftGroupExplainerModal from "../../ShiftGroupExplainerModal/ShiftGroupExplainerModal";
import HelpButtonCellRenderer from "../../ShiftGroupExplainerModal/HelpButtonCellRenderer";
import { customConfirmAlert } from "../../../../confirm/service/confirm";
import { getActualInitialStartDate } from "../../../../../utils/queryUtils/monthViewUtils";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import { DEMAND_OPTIONS } from "./DemandConstants";
import { getPublicHolidays } from "../../../../../utils/publicHolidayUtils/publicHolidays";
import {
  KEYWORD_ALL,
  KEYWORD_ANY,
  KEYWORD_BAU,
  KEYWORD_NA,
  KEYWORD_NO_TASK,
} from "../../../../../constants/keywords";
import { canAccessConditionalDemands } from "../../../../../utils/flagsUtils/flags";
import { getWeekendsCellStyle } from "../../../rosteredAllocations/service/styleGetters";
import {
  DEMAND_VALUE_BAU,
  DEMAND_VALUE_NA,
} from "../../../../../constants/demands";

const typeOptions = DEMAND_OPTIONS.map((option) => ({
  label: option,
  value: option,
}));

const importanceOptions = [
  {
    label: "Critical",
    value: "Critical",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "No AI",
    value: "No AI",
  },
];

const DemandsGrid = ({
  numDays,
  demandsData,
  numDemands,
  isSaving,
  skills,
  tasks,
  taskBlocks,
  shifts,
  shiftGroups,
  roster,
  startDate,
  location,
  setGridApiToParent,
  addData: addDemand,
  periodSelection,
  updateData,
  isGlobalDemand,
  exportToCsv,
  exportToExcel,
  duplicateData: duplicateDemands,
  removeData: removeDemands,
  demandsWarnings,
  gridApi,
  reorderDemandsData,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  triggerUpdateFromPeriodChange,
  resetUndoRedoStack,
  customTopComponent = null,
  getDataFromGrid,
  subTasks,
  customKeywordsData,
  locationSettings,
  shiftTaskDemandsOnly,
  areas,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  isScheduleView,
  rosterName,
}) => {
  const [isCellEditing, setIsCellEditing] = useState(false);
  const { manuallyStepThroughTour } = useOnBoardingStore();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpRowData, setHelpRowData] = useState(null);
  const { email } = useUserStore();

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const showModal = (params) => {
    setHelpRowData({ data: params.data, index: params.rowIndex });
    setShowHelpModal(true);
  };

  const closeModal = () => {
    setShowHelpModal(false);
  };

  const isWarningPresent = demandsWarnings && demandsWarnings.length > 0;

  const dayColumns = getDemandsDayColumns(periodSelection, numDays);

  const allSkillOptions = useMemo(
    () => convertToShortIdNameOptionForm(skills),
    [skills]
  );

  const allTaskOptions = useMemo(() => {
    return convertToShortIdNameOptionForm(tasks);
  }, [tasks]);

  const allSubTaskOptions = useMemo(() => {
    return convertToShortIdNameOptionForm(subTasks);
  }, [subTasks]);

  const { areaOptions } = useMemo(() => getAreaOptions(areas), [areas]);

  const skillOptions = useMemo(
    () => [
      {
        label: "",
        options: allSkillOptions,
      },
    ],
    [allSkillOptions]
  );

  const noTaskOption = useMemo(
    () => ({
      value: KEYWORD_NO_TASK,
      label: KEYWORD_NO_TASK,
    }),
    []
  );

  const taskOptions = useMemo(
    () => [
      {
        label: "",
        options: [noTaskOption, ...allTaskOptions, ...allSubTaskOptions],
      },
    ],
    [allTaskOptions, allSubTaskOptions, noTaskOption]
  );

  const { shiftOptions, shiftGroupOptions, selectNoneOptionShifts } = useMemo(
    () => getShiftAndShiftGroupOptions(shifts, shiftGroups),
    [shifts, shiftGroups]
  );

  const selectAllOption = useMemo(
    () => ({ label: KEYWORD_ALL, value: "" }),
    []
  );

  const shiftAndShiftGroupOptions = useMemo(
    () => [
      {
        label: null,
        options: [selectNoneOptionShifts],
      },
      {
        label: null,
        options: shiftOptions,
      },
      {
        label: "",
        options: shiftGroupOptions,
      },
    ],
    [selectNoneOptionShifts, shiftOptions, shiftGroupOptions]
  );

  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      removeDemands(true);
    });
  };

  const publicHolidayTimezone = location.frontendSettings.find(
    (setting) => setting.name === "publicHolidayTimezone"
  )?.values[0];

  const publicHolidays = useMemo(() => {
    return getPublicHolidays(publicHolidayTimezone, startDate, numDays);
  }, [publicHolidayTimezone, startDate, numDays]);

  const datesColumnDefs = useMemo(() => {
    const dateColumns = dayColumns.map((day) => {
      let dateToShow;
      if (dayColumns.length === numDays) {
        dateToShow = new DateTime(startDate).addDays(parseInt(day) - 1);
      } else {
        dateToShow = new DateTime(
          getActualInitialStartDate(
            location.isScheduleView ? location.startDate : startDate,
            location.isScheduleView ? location.defaultNumDays : numDays,
            location.isScheduleView
          )
        ).addDays(parseInt(day) - 1);
      }

      return {
        width: 80,
        suppressMenu: true,
        field: day,
        headerName:
          dayColumns.length < numDays
            ? dateToShow.getDayOfWeek("dd")
            : dateToShow.toFormat("simple"),
        suppressSizeToFit: true,
        cellClassRules: {
          weekends: getWeekendsCellStyle,
          "invalid-cell": (params) => {
            if (parseInt(params.value) < DEMAND_VALUE_NA) {
              return true;
            } else if (isNaN(parseInt(params.value))) {
              return true;
            }
            return false;
          },
        },
        headerClass: (params) => {
          const headerName = params.colDef.headerName;
          const dayOfWeek = headerName.substring(0, 2);
          if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
            return "header";
          }
        },
        cellStyle: (params) => {
          const headerName = params.colDef.headerName;
          const dayOfWeek = headerName.substring(0, 2);
          if (dayOfWeek === "Sa") {
            return { borderLeft: "1px solid #b8cce4" };
          }
          if (dayOfWeek === "Su") {
            return { borderRight: "1px solid #b8cce4" };
          }
        },
        valueSetter: (params) => {
          if (
            typeof params.newValue === "string" &&
            params.newValue.toLowerCase() === KEYWORD_NA.toLocaleLowerCase()
          ) {
            params.data[params.colDef.field] = DEMAND_VALUE_NA;
            return true;
          }
          const convertedToInt = parseInt(params.newValue);
          if (isNaN(convertedToInt)) {
            return false;
          }
          if (parseInt(params.newValue) === parseInt(params.oldValue)) {
            return false;
          }
          params.data[params.colDef.field] = parseInt(convertedToInt);
          return true;
        },
        valueFormatter: (params) => {
          if (params.value === DEMAND_VALUE_NA) {
            return KEYWORD_NA;
          }
          return params.value;
        },
      };
    });

    const publicHolidayColumn =
      publicHolidays.length == 0 || periodSelection === "whole"
        ? null
        : {
            suppressMovable: true,
            width: 100,
            suppressMenu: true,
            field: "publicHoliday",
            headerName: `Public holiday${
              publicHolidays.length > 1 ? "s" : ""
            } (${publicHolidays.map((ph) => ph.name).join(", ")})`,
            suppressSizeToFit: true,
            cellClassRules: {
              "invalid-cell": (params) => {
                const value = parseInt(params.value);
                return isNaN(value) || value < DEMAND_VALUE_BAU;
              },
            },
            valueSetter: (params) => {
              const newValue = params.newValue.toString().trim();
              if (newValue.toLowerCase() === KEYWORD_NA.toLowerCase()) {
                params.data[params.colDef.field] = DEMAND_VALUE_NA;
                return true;
              }
              if (newValue.toLowerCase() === KEYWORD_BAU.toLowerCase()) {
                params.data[params.colDef.field] = DEMAND_VALUE_BAU;
                return true;
              }

              const value = parseInt(newValue);
              if (isNaN(value)) {
                return false;
              }
              params.data[params.colDef.field] = value;
              return true;
            },
            valueFormatter: (params) => {
              if (params.value === DEMAND_VALUE_NA) {
                return KEYWORD_NA;
              }
              if (params.value === DEMAND_VALUE_BAU) {
                return KEYWORD_BAU;
              }
              return params.value;
            },
          };
    if (publicHolidayColumn) return [publicHolidayColumn, ...dateColumns];
    return dateColumns;
  }, [
    dayColumns,
    publicHolidays,
    periodSelection,
    numDays,
    startDate,
    location.isScheduleView,
    location.startDate,
    location.defaultNumDays,
  ]);

  const columnDefs = useMemo(() => {
    const startEndTimeColumns = [];

    if (!shiftTaskDemandsOnly) {
      startEndTimeColumns.push({
        field: "startTime",
        width: 160,
        rowDrag: true,
        valueGetter: startTimeGetter,
        valueSetter: timeSetter,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      });
      startEndTimeColumns.push({
        field: "finishTime",
        width: 110,
        valueGetter: finishTimeGetter,
        valueSetter: timeSetter,
      });
    }

    return [
      {
        headerName: "Staffing",
        groupId: "staffingGroup",
        children: [
          ...startEndTimeColumns,
          {
            ...(shiftTaskDemandsOnly && {
              rowDrag: true,
              checkboxSelection: true,
              headerCheckboxSelection: true,
            }),
            field: "shifts",
            headerName: "Shifts or Shift Groups",
            cellClassRules: {
              "invalid-cell": (params) => {
                if (demandsWarnings) {
                  const demandsWarning = demandsWarnings.find(
                    ({ warningType }) => warningType === "invalid shifts"
                  );
                  if (params.value && demandsWarning) {
                    const skillNamesArr = strToArrCommaSeparated(params.value);
                    for (const name of skillNamesArr) {
                      if (demandsWarning.values.includes(name)) {
                        return true;
                      }
                    }
                  }
                }
              },
            },
            minWidth: 170,
            cellEditor: "dropdownMultiSelector",
            cellEditorParams: {
              options: shiftAndShiftGroupOptions,
              selectNoneOption: selectNoneOptionShifts,
              selectAllOption,
            },
            cellEditorPopup: true,
            valueFormatter: (params) => {
              if (params.value === "") {
                return KEYWORD_ALL;
              }
              return convertShortIdsCellValueToEntityNames(params.value, [
                ...shifts,
                ...shiftGroups,
              ]);
            },
            valueSetter: (params) => {
              if (params.newValue === KEYWORD_ALL) {
                params.data[params.column.colId] = "";
                return true;
              }
              return dropdownOptionsValueSetter(
                params,
                [
                  ...shiftOptions,
                  ...shiftGroupOptions,
                  shortIdsToEntityNamesDicts,
                ],
                shortIdsToEntityNamesDicts
              );
            },
            suppressKeyboardEvent: suppressEnterKey,
            filterValueGetter: (params) =>
              defaultFilterValueGetter(params, "shifts", [
                ...shifts,
                ...shiftGroups,
              ]),
          },
          {
            field: "skills",
            cellClassRules: {
              "invalid-cell": (params) => {
                if (demandsWarnings) {
                  return defaultWarningHighlighterCellClassRules(
                    params,
                    demandsWarnings
                  );
                }
              },
            },
            minWidth: 150,
            cellRenderer: "gridCellWithPlaceholderText",
            cellRendererParams: {
              defaultValue: "none required",
            },
            cellEditor: "dropdownMultiSelector",
            cellEditorParams: {
              options: skillOptions,
            },
            cellEditorPopup: true,
            valueFormatter: (params) =>
              convertShortIdsCellValueToEntityNames(params.value, skills),
            valueSetter: (params) =>
              dropdownOptionsValueSetter(
                params,
                allSkillOptions,
                shortIdsToEntityNamesDicts
              ),
            suppressKeyboardEvent: suppressEnterKey,
            filterValueGetter: (params) =>
              defaultFilterValueGetter(params, "skills", skills),
          },
          {
            suppressMovable: true,
            field: "tasks",
            cellClassRules: {
              "invalid-cell": (params) => {
                if (demandsWarnings) {
                  const demandsWarning = demandsWarnings.find(
                    ({ warningType }) => warningType === "invalid tasks"
                  );
                  if (params.value && demandsWarning) {
                    const skillNamesArr = strToArrCommaSeparated(params.value);
                    for (const name of skillNamesArr) {
                      if (demandsWarning.values.includes(name)) {
                        return true;
                      }
                    }
                  }
                }
              },
            },
            minWidth: 150,
            cellRenderer: "gridCellWithPlaceholderText",
            cellRendererParams: {
              defaultValue: KEYWORD_ANY,
            },
            cellEditor: "dropdownMultiSelector",
            cellEditorParams: {
              options: taskOptions,
            },
            cellEditorPopup: true,
            valueSetter: (params) =>
              dropdownOptionsValueSetter(
                params,
                flatOptions(taskOptions),
                shortIdsToEntityNamesDicts
              ),
            valueFormatter: (params) => {
              const value = params.value;
              const arrValue = strToArrCommaSeparated(value);
              const names = arrValue.map((cellValue) => {
                const task = tasks.find((t) => t.shortId === cellValue);
                if (task) {
                  return task.name;
                }

                const subTask = subTasks.find((st) => st.shortId === cellValue);
                if (subTask) {
                  return subTask.name;
                }

                return cellValue;
              });
              return names.join(", ");
            },
            suppressKeyboardEvent: suppressEnterKey,
            filterValueGetter: (params) =>
              defaultFilterValueGetter(params, "tasks", tasks),
          },
          {
            sortable: true,
            field: "areas",
            width: 190,
            valueSetter: (params) => {
              return dropdownOptionsValueSetter(
                params,
                areaOptions,
                shortIdsToEntityNamesDicts
              );
            },
            valueFormatter: (params) =>
              convertShortIdsCellValueToEntityNames(params.value, areas),
            filterValueGetter: (params) =>
              defaultFilterValueGetter(params, "areas", areas),
            suppressKeyboardEvent: suppressEnterKey,
            cellRenderer: "gridCellWithPlaceholderText",
            cellRendererParams: {
              defaultValue: KEYWORD_ANY,
            },
            cellEditor: "dropdownMultiSelector",
            cellEditorParams: {
              options: areaOptions,
            },
            cellEditorPopup: true,
            cellClassRules: {
              "invalid-cell": (params) => {
                if (demandsWarnings) {
                  return defaultWarningHighlighterCellClassRules(
                    params,
                    demandsWarnings
                  );
                }
              },
            },
            hide: areas.length === 0,
          },
          {
            suppressMovable: true,
            width: 110,
            cellEditor: "dropdownSingleSelector",
            cellEditorParams: {
              width: 110,
              options: typeOptions,
            },
            cellEditorPopup: true,
            field: "type",
            suppressSizeToFit: true,
            valueSetter: (params) => {
              const allowedOptions = DEMAND_OPTIONS;
              if (allowedOptions.includes(params.newValue)) {
                params.data[params.column.colId] = params.newValue;
                return true;
              }
              return false;
            },
            suppressKeyboardEvent: suppressEnterKey,
          },
          {
            cellEditor: "dropdownSingleSelector",
            cellEditorParams: {
              width: 110,
              options: importanceOptions,
            },
            cellEditorPopup: true,
            field: "importance",
            width: 110,
            valueSetter: (params) => {
              const allowedOptions = [
                "Critical",
                "High",
                "Medium",
                "Low",
                "No AI",
              ];
              if (allowedOptions.includes(params.newValue)) {
                params.data[params.column.colId] = params.newValue;
                return true;
              }
              return false;
            },
            suppressKeyboardEvent: suppressEnterKey,
          },
          {
            field: "conditionalGroup",
            width: 110,
            hide: !canAccessConditionalDemands(email),
          },
          {
            width: 80,
            suppressMovable: true,
            suppressSizeToFit: true,
            editable: false,
            sortable: false,
            suppressMenu: true,
            field: "help",
            cellRenderer: "helpButtonCellRenderer",
            cellRendererParams: (params) => ({
              showModal: () => showModal(params),
            }),
          },
        ],
      },
      {
        headerName: "Number of staff",
        groupId: "staffNumGrop",
        children: datesColumnDefs,
      },
    ];
  }, [
    shortIdsToEntityNamesDicts,
    allSkillOptions,
    demandsWarnings,
    selectNoneOptionShifts,
    shiftAndShiftGroupOptions,
    skillOptions,
    taskOptions,
    datesColumnDefs,
    shiftTaskDemandsOnly,
    shiftGroups,
    shifts,
    skills,
    subTasks,
    tasks,
    shiftOptions,
    shiftGroupOptions,
    areaOptions,
    areas,
    selectAllOption,
    email,
  ]);

  return (
    <Layout
      title={
        isScheduleView ? "Staffing Demands" : `Staffing Demands - ${rosterName}`
      }
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/staffing-numbers-demands"}
        />
      )}
    >
      <div className={styles.upperTopLine}>
        <span className={styles.subtitle}>
          This table involves entering how many staff are working at certain
          times.
        </span>
      </div>
      <div className={styles.topLine}>
        <div className={styles.left}>
          {customTopComponent}
          <div className={styles["period-selector"]}>
            <label htmlFor="period">Show</label>
            <select
              required
              name="period"
              id="period"
              value={periodSelection}
              onChange={async (e) => {
                const val = e.target.value;
                const previousPeriodSelection = periodSelection;
                if (
                  previousPeriodSelection === "week" ||
                  (previousPeriodSelection === "fortnight" &&
                    val === "whole") ||
                  (await customConfirmAlert({
                    title: "Confirm",
                    descriptions: [
                      "The staffing demands that are no longer needed will be deleted. Do you want to continue?",
                    ],
                  }))
                ) {
                  triggerUpdateFromPeriodChange(val);
                  resetUndoRedoStack();
                }
              }}
            >
              <option value="week">Weekly recurring</option>
              <option value="fortnight">2 Weekly recurring</option>
              {!isGlobalDemand && <option value="whole">Whole roster</option>}
            </select>
          </div>
        </div>
        <div className={styles.right}>
          <AddRowButton
            rowName={"Demand"}
            addSingle={() => {
              if (!isCellEditing) addDemand(1);
            }}
            addOptions={[
              {
                label: "Add New",
                onClick: () => addDemand(1),
              },
              {
                label: "Add Multiple",
                onClick: () => {
                  const num = parseInt(
                    prompt(`How many demands should I add?`, "1")
                  );
                  addDemand(num);
                },
              },
            ]}
            partialButtonTestId="add-demands-button"
            postAddAction={() => {
              manuallyStepThroughTour(0);
            }}
          />
        </div>
      </div>
      <ActionBar
        searchBarSettings={{
          tableName: "demands",
          onFilterInputChanged,
        }}
        duplicateSelectedSettings={{
          selectedRows,
          duplicateSelectedRows: duplicateDemands,
        }}
        deleteSelectedSettings={{
          selectedRows,
          removeSelectedRows: () => removeDemands(false),
        }}
        exportSettings={{
          exportToCsv,
          exportToExcel,
        }}
      />
      <div className={styles.container}>
        <DataEntryTable
          columnHoverHighlight={true}
          columnDefs={columnDefs}
          rowData={demandsData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            onSelectionChanged: (params) => {
              setSelectedRows(params.api.getSelectedNodes());
            },
            onRowDragEnd: (params) => {
              reorderDemandsData(params);
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMoveWhenRowDragging: true,
          }}
          setGridApiToParent={setGridApiToParent}
          components={{
            dropdownMultiSelector: DropdownMultiSelector,
            dropdownSingleSelector: DropdownSingleSelector,
            gridCellWithPlaceholderText: GridCellWithPlaceholderText,
            helpButtonCellRenderer: HelpButtonCellRenderer,
          }}
          defaultColDef={{
            filterParams: { newRowsAction: "keep" },
          }}
          enableRowDragAnimation={!isSaving}
          tableName="demands"
          shouldHaveCheckBoxLeftMargin={true}
          getDataFromGrid={getDataFromGrid}
          customStyle={{
            height: `${window.innerHeight - 370}px`,
            minHeight: `500px`,
          }}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        <div className={styles["desc-wrapper"]}>
          <p className={styles["num-line"]}>
            <span className={styles.emph}>Number of demands:</span> {numDemands}
          </p>
        </div>
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(demandsWarnings)}
          />
        </div>
      )}
      <ShiftGroupExplainerModal
        demand={helpRowData?.data}
        demandIndex={helpRowData?.index}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        skills={skills}
        roster={roster}
        taskBlocks={taskBlocks}
        customKeywordsData={customKeywordsData}
        open={showHelpModal}
        onClose={closeModal}
        locationSettings={locationSettings}
      />
    </Layout>
  );
};

export default DemandsGrid;
