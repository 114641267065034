import { getRequestsForEmployeeInRosterRange } from "../../features/locations/service/requests";
import {
  defaultTimesheetFinishTime,
  defaultTimesheetStartTime,
} from "../../features/locations/components/Timesheet/TimesheetView";
import { DateTime } from "../dataTypesUtils/DateTime";
import { getDefinedOrEmpty } from "../generalUtils/general";
import { getShiftTask } from "../modelUtils/generalModelUtil";
import { KEYWORD_OFF } from "../../constants/keywords";

/**
 * get All demands that is currently in the demands grid
 * @param {*} gridApi - grid api provided by Ag Grid
 * @param {*} numDays - number of days that the roster covers
 * @returns - array of all demands
 */
export const getAllDemandsFromGrid = (gridApi, numDays) => {
  const allDemands = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const demand = rowNode.data;
    const allDaysInDemand = [];

    for (let i = 0; i < numDays; i++) {
      if (!Object.keys(demand).includes(`${i + 1}`)) {
        continue;
      } else {
        allDaysInDemand.push(parseInt(demand[i + 1]));
      }
    }

    allDemands.push({
      id: demand.id,
      startTime: DateTime.getFormattedTime(demand.startTime, "AWS"),
      finishTime: DateTime.getFormattedTime(demand.finishTime, "AWS"),
      shifts: getDefinedOrEmpty(demand.shifts),
      skills: getDefinedOrEmpty(demand.skills),
      tasks: getDefinedOrEmpty(demand.tasks),
      type: demand.type,
      importance: demand.importance,
      values: allDaysInDemand,
      defaultPHValue: getDefinedOrEmpty(demand.publicHoliday),
      areas: demand.areas || "",
      conditionalGroup: demand.conditionalGroup || "",
    });
  });

  return allDemands;
};

/**
 * get All shifts that is currently in the demands grid
 * @param {*} gridApi - grid api provided by Ag Grid
 * @returns array of all shifts
 */
export const getAllShiftsFromGrid = (gridApi) => {
  const allShifts = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const shift = rowNode.data;

    allShifts.push({
      id: shift.id,
      name: shift.name,
      startTime: DateTime.getFormattedTime(shift.startTime, "AWS"),
      finishTime: DateTime.getFormattedTime(shift.finishTime, "AWS"),
      fulfilsDemand: shift.fulfilsDemand,
      autoAssigned: shift.autoAssigned,
      skill: shift.skill,
      adminUseOnly: shift.adminUseOnly,
      shortId: shift.shortId || shift.name,
    });
  });

  return allShifts;
};

export const getAllSkillsFromGrid = (gridApi) => {
  const allSkills = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const skill = rowNode.data;
    allSkills.push({
      id: skill.id,
      name: skill.name,
      description: skill.description,
      type: skill.type,
      shortId: skill.shortId || skill.name,
    });
  });

  return allSkills;
};

export const getAllTasksFromGrid = (gridApi) => {
  const allTasks = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const task = rowNode.data;
    allTasks.push({
      id: task.id,
      name: task.name,
      description: task.description,
      includeGeneralStaffing: task.includeGeneralStaffing,
      skills: task.skills,
      shortId: task.shortId || task.name,
      autoAssigned: task.autoAssigned,
    });
  });

  return allTasks;
};

export const getAllAreasFromGrid = (gridApi) => {
  const allAreas = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }

  gridApi.forEachNode((rowNode) => {
    const area = rowNode.data;
    allAreas.push({
      id: area.id,
      name: area.name,
      shifts: area.shifts,
      skills: area.skills,
      tasks: area.tasks,
      autoAssigned: area.autoAssigned,
      shortId: area.shortId,
    });
  });

  return allAreas;
};

//TODO: use more generalised formats for other functions
export const extractDataFromGrid = (gridApi, numericFields) => {
  const allData = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destroyed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const copy = JSON.parse(JSON.stringify(rowNode.data));

    for (const prop in copy) {
      if (numericFields && numericFields.includes(prop)) {
        copy[prop] = Number(copy[prop]);
      }
    }

    allData.push(copy);
  });
  return allData;
};

export const getAllRequestsFromGrid = (gridApi) => {
  const allRequests = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const request = rowNode.data;
    allRequests.push({
      id: request.id,
      employeeName: request.employeeName,
      shiftAndShiftGroup: request.shiftAndShiftGroup,
      from: request.from,
      to: request.to,
    });
  });

  return allRequests;
};

export const getAllSnapshotsInfoFromGrid = (gridApi) => {
  const allSnapshotInfo = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const snapshot = rowNode.data;
    allSnapshotInfo.push({
      id: snapshot.id,
      locationID: snapshot.locationID,
      name: snapshot.name,
      numDays: snapshot.numDays,
      startDate: snapshot.startDate,
      updatedAt: snapshot.updatedAt,
    });
  });

  return allSnapshotInfo;
};

/**
 * get All shift groups that is currently in the demands grid
 * @param {*} gridApi - grid api provided by Ag Grid
 * @returns array of all shifts
 */
export const getAllShiftGroupsFromGrid = (gridApi) => {
  const allShiftGroups = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const shiftGroup = rowNode.data;

    allShiftGroups.push({
      id: shiftGroup.id,
      name: getDefinedOrEmpty(shiftGroup.name),
      shifts: getDefinedOrEmpty(shiftGroup.shifts),
      inversed: getDefinedOrEmpty(shiftGroup.inversed),
      tasks: getDefinedOrEmpty(shiftGroup.tasks),
      skills: getDefinedOrEmpty(shiftGroup.skills),
      skillsInversed: getDefinedOrEmpty(shiftGroup.skillsInversed),
      adminUseOnly: shiftGroup.adminUseOnly,
      shortId: shiftGroup.shortId || getDefinedOrEmpty(shiftGroup.name),
      areas: getDefinedOrEmpty(shiftGroup.areas),
    });
  });
  return allShiftGroups;
};

export const getAllTimeEntriesAndAllocationsFromGrid = (
  gridApi,
  existingAllocations,
  shifts
) => {
  const allTimeEntries = [];
  let allAllocations = JSON.parse(JSON.stringify(existingAllocations));
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const timeEntry = {};
    let allocation = allAllocations.find(
      (allocation) => allocation.date === rowNode.data.date
    );
    if (!allocation && rowNode.data.publishedShift !== KEYWORD_OFF) {
      allocation = {
        date: rowNode.data.date,
        draftAllocation: null,
        publishedAllocation: rowNode.data.publishedShift,
        note: null,
      };
      allAllocations.push(allocation);
    } else if (allocation && rowNode.data.publishedShift === KEYWORD_OFF) {
      allAllocations = allAllocations.filter((alloc) => alloc !== allocation);
      allocation = null;
    }

    if (allocation) {
      const [shiftName] = getShiftTask(rowNode.data.publishedShift);

      const shift = shifts.find((shift) => shift.name === shiftName);
      if (allocation.publishedAllocation !== rowNode.data.publishedShift) {
        allocation.publishedAllocation = rowNode.data.publishedShift;
        return;
      }

      const shiftStartTime = shift
        ? shift.startTime
        : defaultTimesheetStartTime;
      const shiftFinishTime = shift
        ? shift.finishTime
        : defaultTimesheetFinishTime;

      if (
        DateTime.getFormattedTime(rowNode.data.startTime, "AWS") !==
        shiftStartTime
      ) {
        timeEntry.startTime = DateTime.getFormattedTime(
          rowNode.data.startTime,
          "AWS"
        );
      }
      if (
        DateTime.getFormattedTime(rowNode.data.finishTime, "AWS") !==
        shiftFinishTime
      ) {
        timeEntry.finishTime = DateTime.getFormattedTime(
          rowNode.data.finishTime,
          "AWS"
        );
      }

      if (rowNode.data.breakTime !== 30) {
        timeEntry.breakTime = parseInt(rowNode.data.breakTime);
      }
      timeEntry.approved = rowNode.data.status === "approved";
      if (Object.keys(timeEntry).length > 1 || timeEntry.approved) {
        allTimeEntries.push({ ...timeEntry, date: rowNode.data.date });
      }
    }
  });
  return { timeEntries: allTimeEntries, allocations: allAllocations };
};

export const getAllRulesFromGrid = (gridApi, ruleNames) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const rules = [];
    for (let r = 0; r < ruleNames.length; r++) {
      if (ruleNames[r] in employee) {
        rules.push(getDefinedOrEmpty(employee[ruleNames[r]]));
      }
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      RuleValues: rules,
    });
  });
  return allEmployees;
};

export const getAllHistoryFromGrid = (gridApi, historyColumns) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const history = [];
    for (let d = 0; d < historyColumns.length; d++) {
      let data = employee[historyColumns[d]];
      history.push(getDefinedOrEmpty(data));
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      History: history,
    });
  });
  return allEmployees;
};

export const getAllRosterDataFromGrid = (gridApi, rosterColumns) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const roster = [];
    for (let d = 0; d < rosterColumns.length; d++) {
      let data = employee[rosterColumns[d]];
      roster.push(getDefinedOrEmpty(data));
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      RosteredAllocations: roster,
      skills: employee.skills,
    });
  });
  return allEmployees;
};

export const getScheduleRosterDataFromGrid = (
  gridApi,
  rosterColumns,
  prevEmployees,
  startDate,
  numDays,
  annualLeaveKeyword
) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;
    const prevEmployee = prevEmployees.find(
      (emp) => emp.id === employee.id || emp.globalEmployeeID === employee.id
    );
    const previousRosteredAllocations = prevEmployee.RosteredAllocations;
    const employeeRequests = prevEmployee.Requests;

    const requestArr = getRequestsForEmployeeInRosterRange(
      employeeRequests,
      startDate,
      numDays,
      ["Approved"],
      annualLeaveKeyword
    );

    for (let d = 0; d < rosterColumns.length; d++) {
      let data = employee[rosterColumns[d]];
      if (!requestArr[d]) {
        previousRosteredAllocations[d] = getDefinedOrEmpty(data);
      } else {
        previousRosteredAllocations[d] = "";
      }
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      RosteredAllocations: previousRosteredAllocations,
      skills: employee.skills,
    });
  });

  return allEmployees;
};

export const getAllPreferencesFromGrid = (
  gridApi,
  dayColumns,
  recurringColumns
) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const days = [];
    for (let d = 0; d < dayColumns.length; d++) {
      let data = employee[dayColumns[d]];
      days.push(getDefinedOrEmpty(data));
    }

    const recurringDays = [];
    for (let d = 0; d < recurringColumns.length; d++) {
      let data = employee[recurringColumns[d]];
      recurringDays.push(getDefinedOrEmpty(data));
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      Days: days,
      DaysRecurring: recurringDays,
    });
  });
  return allEmployees;
};

export const getAllGlobalAllocationsFromGrid = (
  gridApi,
  dayColumns,
  recurringColumns,
  startDate,
  isPreferences = true
) => {
  const keys = isPreferences
    ? ["Preferences", "PreferencesRecurring"]
    : ["FixedShifts", "FixedShiftsRecurring"];
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const allocations = [];
    for (let d = 0; d < dayColumns.length; d++) {
      let data = employee[dayColumns[d]];

      allocations.push({
        date: new DateTime(startDate).addDays(d).toFormat("AWS"),
        allocation: data ? data : null,
      });
    }

    const recurringAllocations = [];
    for (let d = 0; d < recurringColumns.length; d++) {
      let data = employee[recurringColumns[d]];
      recurringAllocations.push(getDefinedOrEmpty(data));
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      [keys[0]]: allocations,
      [keys[1]]: recurringAllocations,
    });
  });
  return allEmployees;
};

export const getAllFixedShiftsFromGrid = (
  gridApi,
  dayColumns,
  recurringColumns
) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;

    const days = [];
    for (let d = 0; d < dayColumns.length; d++) {
      let data = employee[dayColumns[d]];
      days.push(getDefinedOrEmpty(data));
    }

    const recurringDays = [];
    for (let d = 0; d < recurringColumns.length; d++) {
      let data = employee[recurringColumns[d]];
      recurringDays.push(getDefinedOrEmpty(data));
    }

    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      Allocations: days,
      AllocationsRecurring: recurringDays,
    });
  });
  return allEmployees;
};

export const getAllDataIdFromGrid = (gridApi) => {
  const allIds = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    allIds.push(rowNode.data.id);
  });
  return allIds;
};

export const getAllEmployeesFromGrid = (gridApi) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;
    allEmployees.push({
      id: employee.id,
      externalID: getDefinedOrEmpty(employee.externalID),
      name: getDefinedOrEmpty(employee.name),
      skills: getDefinedOrEmpty(employee.skills),
      shifts: getDefinedOrEmpty(employee.shifts),
      areas: getDefinedOrEmpty(employee.areas),
    });
  });
  return allEmployees;
};

export const getAllGlobalEmployeesFromGrid = (gridApi) => {
  const allEmployees = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const employee = rowNode.data;
    const startDate = new DateTime(employee.startDate, false);
    const finishDate = new DateTime(employee.finishDate, false);
    allEmployees.push({
      id: employee.id,
      name: getDefinedOrEmpty(employee.name),
      externalID: getDefinedOrEmpty(employee.externalID),
      email: getDefinedOrEmpty(employee.email),
      skills: getDefinedOrEmpty(employee.skills),
      shifts: getDefinedOrEmpty(employee.shifts),
      FTE: parseFloat(getDefinedOrEmpty(employee.FTE)),
      salary: parseFloat(getDefinedOrEmpty(employee.salary)),
      startDate: startDate.toFormat("AWS"),
      finishDate: finishDate.toFormat("AWS"),
      areas: getDefinedOrEmpty(employee.areas),
    });
  });
  return allEmployees;
};

export const getShiftDemandFromGrid = (
  gridApi,
  numDays,
  shiftShortId,
  skillShortId = undefined
) => {
  const demandData = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }

  gridApi.forEachNode((rowNode) => {
    const shiftDemandRowData = rowNode.data;
    const isMaxDemand = rowNode.data.minOrMax === "max";
    const allDaysInDemand = [];

    for (let i = 0; i < numDays; i++) {
      if (Number.isNaN(parseInt(shiftDemandRowData[i + 1]))) {
        allDaysInDemand.push("");
      } else {
        allDaysInDemand.push(parseInt(shiftDemandRowData[i + 1]));
      }
    }

    if (isMaxDemand) {
      demandData[0] = {
        shiftShortId,
        skillShortId,
        type: "Maximum",
        values: allDaysInDemand,
      };
    } else {
      demandData[1] = {
        shiftShortId,
        skillShortId,
        type: "Minimum",
        values: allDaysInDemand,
      };
    }
  });

  return demandData;
};

export const getAllColorCodesFromGrid = (gridApi) => {
  const allColorCodes = [];
  if (gridApi.destroyCalled) {
    throw new Error("Grid is destoryed unexpectedly");
  }
  gridApi.forEachNode((rowNode) => {
    const colorCode = rowNode.data;
    allColorCodes.push({
      shift: colorCode.shift,
      color: colorCode.color,
    });
  });
  return allColorCodes;
};
