import { useEffect, useState } from "react";
import styles from "./NightShiftUpgradeCell.module.css";
import { createPortal } from "react-dom";
import { useToggleShow } from "../../../../hooks/useToggleShow";
import NightShiftPopup from "../NightShiftPopup/NightShiftPopup";

const NightShiftUpgradeCell = () => {
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const { isOpen, toggleDropdown, selectorRef, selectorTriggerRef } =
    useToggleShow();

  useEffect(() => {
    if (selectorTriggerRef.current) {
      const { x, y } = selectorTriggerRef.current.getBoundingClientRect();
      setTooltipPosition({ x, y });
    }
  }, [selectorTriggerRef]);

  return (
    <div
      ref={selectorTriggerRef}
      className={styles.label}
      onClick={toggleDropdown}
    >
      Upgrade for night shifts
      {isOpen &&
        createPortal(
          <div
            ref={selectorRef}
            style={{
              position: "fixed",
              top: `${tooltipPosition.y + 70}px`,
              left: `${tooltipPosition.x}px`,
              zIndex: 30000000,
            }}
          >
            <NightShiftPopup />
          </div>,
          document.body
        )}
    </div>
  );
};

export default NightShiftUpgradeCell;
